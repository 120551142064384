import { useSelector } from 'react-redux';
import { includes } from 'lodash';

import { Feature } from 'src/interfaces';

import palette from 'src/theme/palette';

import {
  VehicleType,
  TRANSPARENT_COLOR,
  LEGEND_VEHICLE_TYPES_COLORS,
  selectEditorLegendForm,
  hexToArray,
  useVehicleTypesMap,
} from 'src/cluster/common';

const useLegendStopGenerate: (selectedStopId?: number) => {
  getLineColor: (f: Feature) => number[];
  getFillColor: (f: Feature) => number[];
} = (selectedStopId) => {
  const vehicleTypesMap = useVehicleTypesMap();

  function checkArrayByType(array: number[], type: VehicleType): boolean {
    return array.includes(vehicleTypesMap[type]);
  }

  function includesTrackless(ids: number[]) {
    return ids.some((id) => !includes([
      vehicleTypesMap[VehicleType.water],
      vehicleTypesMap[VehicleType.suburban],
      vehicleTypesMap[VehicleType.metro],
      vehicleTypesMap[VehicleType.tramway],
    ], id));
  }

  const {
    isTracklessStop,
    isSuburbanStop,
    isWaterStop,
    isMetroStop,
    isTramwayStop,
    isBusNetwork,
    isTramwayNetwork,
    isTrolleybusNetwork,
    isSuburbanNetwork,
    isWaterNetwork,
    communicationType,
    regularTransportationType,
  } = useSelector(selectEditorLegendForm);

  const canFilteredNetworks = isBusNetwork ||
    isTramwayNetwork ||
    isTrolleybusNetwork ||
    isSuburbanNetwork ||
    isWaterNetwork;

  const getLineColor = (feature: Feature) => {
    const {
      id,
      vehicleTypes = [],
      routeVehicleTypes = [],
      communicationTypes = [],
      regularTransportationTypes = [],
    } = feature.properties || {};

    if (!id) return TRANSPARENT_COLOR;

    if (canFilteredNetworks) {
      /** Filtered routes if regularTransportationType or communicationType */
      const routes: number[] = regularTransportationType || communicationType
        ? routeVehicleTypes.filter((vhId: number) => (
          (!regularTransportationType || regularTransportationTypes[vhId]?.includes(regularTransportationType))
          && (!communicationType || communicationTypes[vhId]?.includes(communicationType))
        ))
        : [...routeVehicleTypes];

      /** Networks */
      if (isWaterNetwork && routes.includes(vehicleTypesMap[VehicleType.water])) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.water].hex;
      }
      if (isSuburbanNetwork && routes.includes(vehicleTypesMap[VehicleType.suburban])) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.suburban].hex;
      }
      if (isTramwayNetwork && routes.includes(vehicleTypesMap[VehicleType.tramway])) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.tramway].hex;
      }
      if (isTrolleybusNetwork && routes.includes(vehicleTypesMap[VehicleType.trolleybus])) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.trolleybus].hex;
      }
      if (isBusNetwork && routes.includes(vehicleTypesMap[VehicleType.bus])) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.bus].hex;
      }
    }

    /** Stops */
    if (isWaterStop && includes(vehicleTypes, vehicleTypesMap[VehicleType.water])) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.water].hexDark;
    }
    if (isSuburbanStop && includes(vehicleTypes, vehicleTypesMap[VehicleType.suburban])) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.suburban].hexDark;
    }
    if (isMetroStop && includes(vehicleTypes, vehicleTypesMap[VehicleType.metro])) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.metro].hexDark;
    }
    if (isTramwayStop && includes(vehicleTypes, vehicleTypesMap[VehicleType.tramway])) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.tramway].hexDark;
    }
    if (isTracklessStop && includesTrackless(vehicleTypes)) {
      return hexToArray(palette.grey.A200);
    }

    return TRANSPARENT_COLOR;
  };

  const getFillColor = (feature: Feature) => {
    const {
      id,
      vehicleTypes = [],
      communicationTypes = [],
      routeVehicleTypes = [],
      regularTransportationTypes = [],
    } = feature.properties || {};

    if (!id) return TRANSPARENT_COLOR;
    if (selectedStopId === id) return hexToArray(palette.grey[50]);

    if (canFilteredNetworks) {
      /** Filtered routes if regularTransportationType or communicationType */
      const routes = regularTransportationType || communicationType
        ? routeVehicleTypes.filter((vhId: number) => (
          (!regularTransportationType || regularTransportationTypes[vhId]?.includes(regularTransportationType))
          && (!communicationType || communicationTypes[vhId]?.includes(communicationType))
        ))
        : [...routeVehicleTypes];
      if (
        (isWaterNetwork && checkArrayByType(routes, VehicleType.water))
        || (isSuburbanNetwork && checkArrayByType(routes, VehicleType.suburban))
        || (isTramwayNetwork && checkArrayByType(routes, VehicleType.tramway))
        || (isTrolleybusNetwork && checkArrayByType(routes, VehicleType.trolleybus))
        || (isBusNetwork && checkArrayByType(routes, VehicleType.bus))
      ) {
        return hexToArray(palette.common.white);
      }
    }
    if (
      (isWaterStop && checkArrayByType(vehicleTypes, VehicleType.water))
      || (isSuburbanStop && checkArrayByType(vehicleTypes, VehicleType.suburban))
      || (isMetroStop && checkArrayByType(vehicleTypes, VehicleType.metro))
      || (isTramwayStop && checkArrayByType(vehicleTypes, VehicleType.tramway))
      || (isTracklessStop && includesTrackless(vehicleTypes))
    ) {
      return hexToArray(palette.common.white);
    }

    return hexToArray(palette.common.white, 0);
  };

  return { getLineColor, getFillColor };
};

export default useLegendStopGenerate;
