/* eslint-disable max-len */
import React from 'react';

const UsersSidebar = () => (
  <svg viewBox="0 0 17 16" >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="currentColor" strokeWidth="2">
        <path d="M16.5592696,14.0760763 L2.42725538,14.0516817 C2.72524594,13.3897514 3.09286743,12.7330748 3.53359721,12.1189179 C4.93580329,10.164944 6.77726048,9.02258301 9.17004395,9.02258301 C11.8496095,9.02258301 13.8772378,10.185265 15.3908801,12.1591193 C15.858548,12.7689783 16.2464615,13.419969 16.5592691,14.0760763 L16.5592696,14.0760763 Z" />
        <path d="M12.1316663,14.0311186 L6.8041048,14.0233099 C7.4387291,13.3640069 8.21929727,12.9866943 9.19458008,12.9866943 C10.4489936,12.9866943 11.4034017,13.3729063 12.1316663,14.0311186 Z" />
        <circle cx="9" cy="5" r="4" />
      </g>
    </g>
  </svg>
);

export default UsersSidebar;
