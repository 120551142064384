import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  root: {
    position: 'relative',
    width: '100%',
    overflowX: 'auto',
  },
  container: {
    maxHeight: 248,
  },
}));
