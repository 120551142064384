import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => createStyles({
  popup: {
    padding: spacing(6),
    position: 'fixed',
    right: 50,
    bottom: 100,
    borderRadius: '10px',
    filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1))',
  },
  anchor: {
    width: 30,
    height: 30,
    marginBottom: spacing(6),
    cursor: 'pointer',
  },
}));
