import {
  ceil, floor, max, min, range, round,
} from 'lodash';

export function getRangeByMinMax(minimum: number, maximum: number, minLength = 10): number[] {
  const precision = -(ceil(maximum - minimum).toString().length - 2);
  const minValue = floor(minimum, precision);
  const maxValue = ceil(maximum, precision);
  const step = round((maxValue - minValue) / minLength, precision);
  return step > 0 ? range(minValue, maxValue, step) : [maxValue];
}

export function getRange(data: number[], minLength = 10): number[] {
  const minValueRaw = min(data) || 0;
  const maxValueRaw = max(data) || 0;
  return getRangeByMinMax(minValueRaw, maxValueRaw, minLength);
}
