import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => createStyles({
  category: {
    display: 'flex',
    alignItems: 'center',
  },

  firstLevel: {
    textTransform: 'uppercase',
    fontWeight: 700,
    lineHeight: '38px',
  },

  secondLevel: {
    fontWeight: 700,
    lineHeight: '38px',
    paddingLeft: (level: number) => spacing(level * 4),
  },
}));
