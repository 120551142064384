import { makeStyles } from '@material-ui/core';

import { getPrimaryColumnStyles } from 'src/cluster/dashboard-table';

export default makeStyles(({ palette, spacing }) => ({
  primaryColumn: getPrimaryColumnStyles(spacing),

  secondaryColumn: {
    width: '15%',
  },

  negative: {
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    color: palette.success.main,
    marginRight: spacing(2),

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: -18,
      top: 3,
      width: 12,
      height: 12,
      borderWidth: 6,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderTopColor: palette.success.main,
    },
  },

  positive: {
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    marginRight: spacing(2),

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: -18,
      top: -3,
      width: 12,
      height: 12,
      borderWidth: 6,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderBottomColor: palette.text.primary,
    },
  },
}));
