import React, { FC } from 'react';
import {
  Collapse, createStyles, makeStyles,
} from '@material-ui/core';

import { CollapseToggle } from 'src/components';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      display: 'block',
      marginTop: 2,
    },
  });
});

interface Props {
  collapsed: boolean;
  caption?: string;
  progress?: React.ReactElement;
  buttonsGroup?: React.ReactElement;
  toggle(): void;
}
interface Props {
  collapsed: boolean;
  caption?: string;
  progress?: React.ReactElement;
  buttonsGroup?: React.ReactElement;
  toggle(): void;
}

const CollapseWithToggle: FC<Props> = (props) => {
  const {
    children, collapsed, caption, toggle, progress, buttonsGroup,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CollapseToggle
        collapsed={collapsed}
        toggle={toggle}
        caption={caption}
        progress={progress}
        buttonsGroup={buttonsGroup}
      />
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

export default CollapseWithToggle;
