import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette }) => createStyles({
  root: {
    position: 'relative',
    display: 'flex',
    height: '100%',
  },

  sidebar: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: 300,
    borderWidth: 0,
    borderColor: palette.grey[400],
    borderStyle: 'solid',
    borderRightWidth: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  map: {
    flexShrink: 0,
    flexGrow: 1,
  },
}));
