import { useTranslation } from 'react-i18next';

import { SelectMenuOption } from 'src/interfaces';

import { EXPORT_TYPES_DICT, ExportType } from 'src/cluster/editor-common';

const useEditorExportFileTypeOptions = (): SelectMenuOption[] => {
  const { t } = useTranslation();
  return Object.values(ExportType).map((type) => ({
    id: type,
    value: type,
    label: t(EXPORT_TYPES_DICT[type]),
  }));
};

export default useEditorExportFileTypeOptions;
