export interface AuthInfoForm {
  username: string;
  permissions: any[];
  forecastParams: any[];
  errors: string;
}

export default {
  defaults: {
    username: undefined,
    permissions: [],
    forecastParams: [],
    errors: '',
  },
  onRouteChangeReset: {
    errors: true,
  },
};
