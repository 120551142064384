import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import { AC_DASH_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/common';
import { acDashGraphFormActions } from 'src/cluster/dashboard-common';

import ModalDashGraphVersion from './ModalDashGraphVersion';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setYear: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(acDashGraphFormActions.resetField('scenarioId'));
    dispatch(acDashGraphFormActions.changeField('year', Number(event.target.value)));
  },
  setScenario: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(acDashGraphFormActions.changeField('scenarioId', Number(event.target.value)));
  },
  close: () => {
    dispatch(modals.actions.showModal(false, AC_DASH_GRAPH_VERSION_MODAL_NAME));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(ModalDashGraphVersion));
