import { SelectMenuOption } from 'src/interfaces';

import useBalanceMapScenarios from './useBalanceMapScenarios';

const useBalanceMapScenarioOptions = (): SelectMenuOption[] => {
  const scenarios = useBalanceMapScenarios();
  return scenarios.map((scenario) => ({
    id: `${scenario.id}`,
    value: scenario.id,
    label: scenario.name,
  }));
};

export default useBalanceMapScenarioOptions;
