import React, { FC, useState, useCallback } from 'react';
import classNames from 'classnames';

import { DataGrid } from 'src/components';

import { useAcVehicleTypeName } from 'src/cluster/common';
import {
  AcDistRoutesModel,
  useDistRouteConfigLeft,
  useDistRouteConfigRight,
} from 'src/cluster/distribution-table';

import { sortNumberRoute } from 'src/helpers';
import useStyles from './styles';

interface OwnProps {
  data: AcDistRoutesModel[];
  showDistributionModal(record: AcDistRoutesModel): void;
}

const DistributionRouteDataGrid: FC<OwnProps> = (props) => {
  const { data, showDistributionModal } = props;

  sortNumberRoute(data);

  const classes = useStyles();

  const getVehicleTypeName = useAcVehicleTypeName();
  const columnsLeft = useDistRouteConfigLeft(getVehicleTypeName, classes);
  const columnsRight = useDistRouteConfigRight(getVehicleTypeName, classes);

  const [hovered, setHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);

  const onMouseEnter = (e: any) => {
    setHovered(true);
    setHoveredId(e.id);
  };
  const onMouseLeave = () => {
    setHovered(false);
    setHoveredId(null);
  };

  const changeStyleLeft = useCallback(
    (record) => {
      const { id } = record;
      return classNames(classes.routeRowBorder, id === hoveredId ? classes.hoveredBorder : '');
    },
    [hovered, hoveredId],
  );
  const changeStyleRight = useCallback(
    (record) => {
      const { id } = record;
      return classNames(classes.routeRow, id === hoveredId ? classes.hovered : '');
    },
    [hovered, hoveredId],
  );

  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.leftTableWrapper}>
          <DataGrid<AcDistRoutesModel>
            columns={columnsLeft}
            body={data}
            headCellClassName={classes.headerCell}
            rowClassName={changeStyleLeft}
            cellClassName={classes.cell}
            onRowClick={showDistributionModal}
            onRowEnter={onMouseEnter}
            onRowLeave={onMouseLeave}
          />
        </div>
        <div className={classes.middleTableWrapper}>
          <DataGrid<AcDistRoutesModel>
            cellClassName={classes.cell}
            columns={columnsRight}
            body={data}
            headCellClassName={classes.headerCellFixed}
            rowClassName={changeStyleRight}
            onRowClick={showDistributionModal}
            onRowEnter={onMouseEnter}
            onRowLeave={onMouseLeave}
          />
        </div>
      </div>
    </>
  );
};

export default DistributionRouteDataGrid;
