import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectAcProjectListForm } from 'src/cluster/common';
import { selectAcProjects } from 'src/cluster/projects';
import { AppState } from 'src/reducer';
import MainMenu from './MainMenu';

const mapStateToProps = (state: AppState) => ({
  selectedProjectForm: selectAcProjectListForm(state),
  projects: selectAcProjects(state),
});

export default connect(mapStateToProps, () => ({}))(withRouter(MainMenu));
