/* eslint-disable max-len */
import React from 'react';

const Wallet = () => (
  <svg viewBox="0 0 405.867 405.867">
    <g>
      <path d="M389.887,0H58.973C26.996,0,0.98,26.016,0.98,57.993v287.823c0,33.112,26.938,60.051,60.051,60.051h328.855   c8.284,0,15-6.716,15-15V15C404.887,6.716,398.171,0,389.887,0z M58.973,30h315.914v55.985h-23.314V57.993c0-5.523-4.478-10-10-10   H32.834C36.869,37.483,47.061,30,58.973,30z M331.572,85.985h-272.6c-11.912,0-22.104-7.483-26.139-17.992h298.738V85.985z    M61.031,375.867c-16.57,0-30.051-13.481-30.051-30.051V108.761c8.305,4.598,17.846,7.224,27.992,7.224h315.914v74.778h-63.772   c-30.417,0-55.163,24.746-55.163,55.163s24.746,55.163,55.163,55.163h63.772v74.778H61.031z M374.887,281.089h-63.772   c-19.389,0-35.163-15.774-35.163-35.163c0-19.389,15.774-35.163,35.163-35.163h63.772V281.089z" />
      <path d="M297.166,245.922c0,3.95,1.601,7.82,4.391,10.61s6.66,4.39,10.609,4.39c3.95,0,7.811-1.6,10.601-4.39   c2.8-2.79,4.399-6.66,4.399-10.61c0-3.95-1.6-7.81-4.399-10.6c-2.79-2.8-6.65-4.4-10.601-4.4c-3.949,0-7.819,1.6-10.609,4.4   C298.767,238.112,297.166,241.982,297.166,245.922z" />
    </g>
  </svg>
);

export default Wallet;
