import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },

  scrollWrapper: {
    width: 1733,
    height: 400,
  },

  overflow: {
    minHeight: 400,
  },

  titleH6: {
    fontSize: 14,
    fontWeight: 700,
    padding: theme.spacing(6, 0, 2, 0),
  },

  title: {
    fontWeight: 500,
    marginBottom: 16,
  },

  line: {
    marginLeft: 8,
    marginRight: 8,
  },

  subTitle: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
    fontWeight: 300,
  },

  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
    paddingBottom: '10px',
  },

  field: {
    padding: '0 10px 15px',
    flexBasis: '100%',
  },

  buttons: {
    display: 'flex',
    margin: '0',
    marginTop: 30,
    justifyContent: 'flex-end',
  },

  button: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    margin: '0 10px',
  },

  slider: {
    minWidth: 250,
    '& > #fullRemote': {
      margin: '20px auto',
    },
  },

  subTitleBold: {
    fontWeight: 400,
    marginLeft: 8,
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    height: '55vh',
  },
  totalPassengerTraffic: {
    maxWidth: 40,
  },
  leftTableWrapper: {
    width: '100%',
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },
  middleTableWrapper: {
    width: '100%',
    height: '100%',
  },
}));
