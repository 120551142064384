import { VIDEO_ROUTE_NUMBERS_ENDPOINT } from 'src/modules/video/common';

export default {
  endpoint: VIDEO_ROUTE_NUMBERS_ENDPOINT,
  transformArrayResponse: (response: any) => {
    return {
      data: response.map((routeNumber: any) => ({ name: routeNumber.routeNumber, id: routeNumber.id })),
    };
  },
  defaults: {
    id: undefined,
    name: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
