import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, shape }: Theme) => {
  return createStyles({
    root: {
      width: 24,
      height: 24,
      color: palette.grey[400],
      backgroundColor: palette.common.white,
      padding: 0,
      outline: 0,
      borderWidth: 1,
      borderColor: palette.grey[400],
      borderStyle: 'solid',
      borderRadius: shape.borderRadius,
      cursor: 'pointer',

      '&:disabled': {
        cursor: 'default',
      },

      '&> svg': {
        maxWidth: '100%',
        maxHeight: '100%',
        verticalAlign: 'bottom',
      },
    },

    checked: {
      color: palette.common.white,
      backgroundColor: palette.grey[400],
      borderColor: 'transparent',
    },
  });
});
