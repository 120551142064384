import React, { FC } from 'react';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';

import { Button } from 'src/components';

import useStyles from './styles';

type OwnerProps = {
  className?: string;

  label?: React.ReactNode;

  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
  useFileNameAsLabel?: boolean;

  fullWidth?: boolean;

  isUploading: boolean;

  onChange: (files: FileList | null) => void;
  selectedFiles?: File[];
}

type Props = OwnerProps & WithTranslate;

const FileInputLink: FC<Props> = (props) => {
  const {
    className,
    accept,
    multiple,
    disabled = false,
    isUploading,
    selectedFiles,
    useFileNameAsLabel,
    fullWidth = false,
    onChange,
  } = props;

  const classes = useStyles();

  let { label } = props;
  if (useFileNameAsLabel && selectedFiles && selectedFiles.length && selectedFiles[0].name) {
    label = selectedFiles[0].name;
  }

  return (
    <label className={clsx(classes.root, className)}>
      <Button
        color="primary"
        variant="link"
        disabled={disabled || isUploading}
        fullWidth={fullWidth}
      >
        {label}
      </Button>
      <input
        className={classes.fileInput}
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled || isUploading}
        onChange={(e) => onChange(e.target.files)}
      />
    </label>
  );
};

export default FileInputLink;
