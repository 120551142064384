import React from 'react';

const SettingsIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4807 6.89101L12.8177 6.61231C12.7236 6.31812 12.5981 6.03942 12.4569 5.76071L13.4296 4.41365C13.5708 4.21236 13.5551 3.93366 13.3825 3.76334L12.049 2.44724C11.8764 2.27692 11.594 2.24595 11.39 2.40079L10.0251 3.36076C9.7584 3.22141 9.46032 3.09754 9.16223 3.00464L8.89552 1.37887C8.84845 1.13113 8.6445 0.960815 8.39348 0.960815H6.51083C6.25981 0.960815 6.05586 1.13113 6.00879 1.37887L5.72639 3.02013C5.42831 3.11303 5.14591 3.2369 4.86351 3.37625L3.49859 2.41627C3.29464 2.27692 3.01224 2.2924 2.83966 2.46272L1.50612 3.77882C1.33354 3.94914 1.30217 4.22784 1.45905 4.42913L2.43176 5.7762C2.29056 6.03942 2.16505 6.33361 2.07091 6.62779L0.423596 6.89101C0.172576 6.93746 0 7.13875 0 7.38649V9.24451C0 9.49225 0.172576 9.69354 0.423596 9.73999L2.0866 10.0187C2.18074 10.3129 2.30625 10.5916 2.44744 10.8703L1.47474 12.2174C1.33354 12.4186 1.34923 12.6973 1.52181 12.8677L2.85535 14.1838C3.02793 14.3541 3.31033 14.385 3.51428 14.2302L4.8792 13.2702C5.14591 13.4096 5.444 13.5335 5.74208 13.6264L6.02448 15.2676C6.07155 15.5153 6.2755 15.6857 6.52652 15.6857H8.40917C8.66019 15.6857 8.86414 15.5153 8.91121 15.2676L9.17792 13.6109C9.476 13.518 9.7584 13.3941 10.0408 13.2548L11.4057 14.2147C11.6097 14.3541 11.8921 14.3386 12.0646 14.1683L13.3982 12.8522C13.5708 12.6819 13.6021 12.4032 13.4453 12.2019L12.4726 10.8548C12.6138 10.5916 12.7393 10.2974 12.8334 10.0032L14.4964 9.7245C14.7474 9.67805 14.92 9.47677 14.92 9.22903V7.38649C14.9043 7.13875 14.7317 6.93746 14.4807 6.89101ZM7.45216 10.9632C5.96172 10.9632 4.76938 9.77095 4.76938 8.3155C4.76938 6.84456 5.97741 5.66781 7.45216 5.66781C8.9269 5.66781 10.1349 6.84456 10.1349 8.3155C10.1349 9.78644 8.94259 10.9632 7.45216 10.9632Z"
      fill="currentColor"
    />
  </svg>

);

export default SettingsIcon;
