import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Maybe } from 'monet';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import {
  AddIcon, AddStopIcon, Button, CursorIcon, EditIcon, GearIcon, MoveIcon, RemoveIcon, RouteIcon,
} from 'src/components';

import { AcEditorGraphForm, AcStopsModel } from 'src/cluster/common';
import { AcEditorPageForm, EditorMode } from 'src/cluster/editor-map';

import { HeadToolbarButton, HeadToolbarMessage } from './components';

import useClasses from './styles';

export interface OwnProps {
  pageForm: AcEditorPageForm;
  graphForm: AcEditorGraphForm;
  selectedStop: Maybe<Model<AcStopsModel>>;
  showMessage(message: ReactNode): void;
  clearMessage(): void;
  createNewRoute(): void;
  enableViewMode(): void;
  enableAddPointMode(): void;
  enableRemovePointMode(): void;
  enableGraphMode(): void;
  enableModifyMode(): void;
  enableAddStopMode(): void;
  enableMoveStopMode(): void;
}

type Props = OwnProps & WithTranslate;

const EditorHeadToolbar: FC<Props> = (props) => {
  const {
    pageForm: { editorMode, message, vehicleTypeId },
    graphForm: { year, scenarioId },
    selectedStop,
    t,
    showMessage,
    clearMessage,
    createNewRoute,
    enableViewMode,
    enableAddPointMode,
    enableRemovePointMode,
    enableGraphMode,
    enableModifyMode,
    enableAddStopMode,
    enableMoveStopMode,
    Trans,
  } = props;

  const classes = useClasses();

  const handleMoveStopClick = () => {
    enableMoveStopMode();
    showMessage(
      <Trans
        i18nKey="modules.editor.messages.addStopHelp"
        components={{ stop: <span className={classes.stop} /> }}
      />,
    );
  };

  const canMoveStop = selectedStop.map(stop => stop.routeDirections.length === 0).orJust(false);

  const isViewMode = editorMode === EditorMode.view;
  const isModifyMode = editorMode === EditorMode.modify || editorMode === EditorMode.addStop;
  const isModifyStopMode = editorMode === EditorMode.modifyStop || editorMode === EditorMode.moveStop;
  const isModifyRouteMode = editorMode === EditorMode.addPoint || editorMode === EditorMode.removePoint;

  const commonDisabled = !year || !scenarioId;

  return (
    <section className={clsx(classes.root, !isViewMode && classes.showBar)}>
      <div className={classes.topBar}>
        <span className={classes.label}>{t('modules.editor.captions.editMode')}</span>
        <Button
          variant="link"
          disabled={commonDisabled}
          onClick={enableViewMode}
        >
          {t('modules.editor.captions.finishEditing')}
        </Button>
      </div>
      <div className={classes.bottomBar}>
        <HeadToolbarMessage
          message={message}
          onClick={clearMessage}
        />
        {isModifyMode && (
          <div className={classes.buttonGroup}>
            <HeadToolbarButton
              caption={t('modules.editor.captions.selector')}
              icon={<CursorIcon />}
              active={editorMode === EditorMode.modify}
              disabled={commonDisabled}
              onClick={enableModifyMode}
            />
            <HeadToolbarButton
              caption={t('modules.editor.captions.addRouteTitle')}
              icon={<RouteIcon />}
              active={editorMode === EditorMode.addPoint}
              disabled={commonDisabled || !vehicleTypeId}
              onClick={createNewRoute}
            />
            <HeadToolbarButton
              caption={t('modules.editor.captions.addStopTitle')}
              icon={<AddStopIcon />}
              active={editorMode === EditorMode.addStop}
              disabled={commonDisabled}
              onClick={enableAddStopMode}
            />
          </div>
        )}
        {isModifyStopMode && (
          <div className={classes.buttonGroup}>
            <HeadToolbarButton
              caption={t('modules.editor.captions.move')}
              icon={<MoveIcon />}
              active={editorMode === EditorMode.moveStop}
              disabled={commonDisabled || !canMoveStop}
              onClick={handleMoveStopClick}
            />
          </div>
        )}
        {isModifyRouteMode && (
          <div className={classes.buttonGroup}>
            <HeadToolbarButton
              caption={t('modules.editor.captions.add')}
              icon={<AddIcon />}
              active={editorMode === EditorMode.addPoint}
              disabled={commonDisabled}
              onClick={enableAddPointMode}
            />
            <HeadToolbarButton
              caption={t('modules.editor.captions.remove')}
              icon={<RemoveIcon />}
              active={editorMode === EditorMode.removePoint}
              disabled={commonDisabled}
              onClick={enableRemovePointMode}
            />
          </div>
        )}
        {isViewMode && (
          <div className={classes.buttonGroup}>
            <HeadToolbarButton
              caption={t('modules.editor.captions.editingSpeeds')}
              icon={<GearIcon />}
              active={editorMode === EditorMode.graph}
              disabled={commonDisabled}
              onClick={enableGraphMode}
            />
            <HeadToolbarButton
              caption={t('modules.editor.captions.editMode')}
              icon={<EditIcon />}
              active={editorMode === EditorMode.modify}
              disabled={commonDisabled}
              onClick={enableModifyMode}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default EditorHeadToolbar;
