import { api, forms, RestifyId } from 'redux-restify';

import modals from 'src/modals';
import i18n from 'src/i18n';

import {
  CALCULATIONS_ENDPOINTS,
  CALCULATIONS_MODEL_NAMES,
  CALCULATIONS_PAGES_FORM_NAMES,
} from 'src/modules/shipments/calculations/constants';

import { downloadFile } from 'src/helpers/file-download';
import { ThunkActionResult } from 'src/reducer';
import { Systems } from 'src/constants';
import { EXPORT_CALCULATION_MODAL } from 'src/modules/cities/calculations';
import { PageProjectForm, PROJECTS_PAGES_FORM_NAMES } from 'src/modules/general/projects';
import { ExportCityCalculationForm } from '../forms/exportCityCalculation.form';
import { CITIES_CALCULATION_MAP_MODAL, EXPORT_TYPES_DICT, PageCalculationForm } from '../constants';

export const getCalculationsActions = (type: Systems) => {
  const modelName = CALCULATIONS_MODEL_NAMES[type];
  const calculationPageFormName = CALCULATIONS_PAGES_FORM_NAMES[type];
  const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[type];
  const calculationsEndpoint = CALCULATIONS_ENDPOINTS[type];
  return {
    startCalculation: (): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
      const state = getState();
      const calculationPageForm = forms.selectors[calculationPageFormName].getFormWithNulls<PageCalculationForm>(state);
      const pageProjectForm = forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state);
      return dispatch(forms.actions.sendQuickForm({
        baseConfig: 'baseEditCalculationForm',
        model: modelName,
        values: {
          routesProject: pageProjectForm.selectedProject,
          trafficLimit: (calculationPageForm.trafficLimit / 100),
          maxAlternativeRoutes: (calculationPageForm.maxAlternativeRoutes),
        },
      }));
    },
    exportCalculation: (id: RestifyId): ThunkActionResult<void> => (dispatch) => {
      dispatch(modals.actions.showModal(true, EXPORT_CALCULATION_MODAL, {
        calculationId: id,
        system: type,
      }));
    },
    showCalculationMap: (id: RestifyId): ThunkActionResult<void> => (dispatch) => {
      dispatch(modals.actions.showModal(true, CITIES_CALCULATION_MAP_MODAL, {
        calculationId: id,
        system: type,
      }));
    },
    downloadCalculationExport: (id: RestifyId): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
      const state = getState();
      const exportForm = forms.selectors.exportCityCalculationForm.getFormWithNulls<ExportCityCalculationForm>(state);
      return dispatch(api.actions.callGet({
        url: `${calculationsEndpoint}${id}/export/`,
        query: exportForm,
        isBinary: true,
      })).then((res: any) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: 'octet/stream',
          });
          if (exportForm.type) {
            const fileName = i18n.t(EXPORT_TYPES_DICT[exportForm.type]);
            downloadFile(blob, `${fileName}.xlsx`);
          }
        }
      });
    },
  };
};
