import React, { FC, Fragment } from 'react';
import { TreeItem } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';

import { VehicleType, AcRouteDirection } from 'src/cluster/common';
import {
  AcEditorPageForm, EditorMode, EditorTreeCaption, PointAddingDirection,
} from 'src/cluster/editor-map';

import AddPointDropIcon from 'src/components/icons/AddPointDropIcon';
import { RouteDirectionFooter, RouteDirectionHeader } from './components';

import {
  useCommonStyles, useListItemStyles, useLongNameStyles, useTreeItemStyles,
} from './styles';
import AddPointIcon from '../../../../../components/icons/AddPointIcon';

interface OwnProps {
  selectedNodeId?: number;
  selectedNodeIndex?: number;
  pageForm: AcEditorPageForm;
  isOnlyDirection: boolean;
  direction: AcRouteDirection;
  variantId: number;
  vehicleTypeName?: VehicleType;
  setSelectedStop(id: number): void;
  setAddIndex(index?: number): void;
  setPointAddingDirection(addingDirection?: PointAddingDirection): void;
  addDirection(variantId: number): void;
  deleteDirection(variantId: number, directionId: number): void;
  swapDirections(variantId: number): void;
}

type Props = OwnProps & WithTranslate;

const RouteDirection: FC<Props> = (props) => {
  const {
    pageForm: {
      editorMode,
      directionId,
      addIndex,
    },
    isOnlyDirection,
    setAddIndex,
    direction,
    variantId,
    vehicleTypeName,
    t,
    setSelectedStop,
    addDirection,
    deleteDirection,
    swapDirections,
    selectedNodeId,
    selectedNodeIndex,
    setPointAddingDirection,
  } = props;

  const {
    id, nodes, length, longName, direction: isForward,
  } = direction;
  const isSelected = directionId === id;

  const classes = useCommonStyles({ vehicleTypeName });
  const longNameClasses = useLongNameStyles();
  const treeItemClasses = useTreeItemStyles({ vehicleTypeName, isSelected });

  const createDoubleClickHandler = (stopId: number | null) => () => {
    if (stopId) {
      setSelectedStop(Number(stopId));
    }
  };

  const handleAddDirection = () => {
    addDirection(variantId);
  };

  const handleDeleteDirection = () => {
    deleteDirection(variantId, id);
  };

  const handleSwapDirections = () => {
    swapDirections(variantId);
  };
  const isModifyingAddPoint = [EditorMode.addPoint].includes(editorMode);
  const isModifying = [EditorMode.addPoint, EditorMode.removePoint].includes(editorMode);
  const stopClasses = useListItemStyles({ vehicleTypeName });

  // We use this hack to display correct node index to user,
  // if needed we can rewrite map to handle realIndex and userIndex
  let hiddenNodesCount = 0;

  return (
    <TreeItem
      nodeId={`direction@@${id}@@${variantId}`}
      classes={treeItemClasses}
      label={(
        <RouteDirectionHeader
          isModifying={isModifying}
          isForward={isForward}
          isOnlyDirection={isOnlyDirection}
          addDirection={handleAddDirection}
          deleteDirection={handleDeleteDirection}
          swapDirections={handleSwapDirections}
        />
      )}
    >
      {id > 0 && (
        <EditorTreeCaption
          label={t('systems.agglomeration.captions.routeDirectionTitle')}
          className={classes.directionTitle}
        />
      )}
      <Typography classes={longNameClasses}>{longName}</Typography>
      {isModifying && (
        <EditorTreeCaption
          label={t('modules.editor.captions.listOfObjects')}
          className={classes.listOfObjects}
        />
      )}
      {nodes.map((stop, index) => {
        if (!isModifying && !stop.stopId) {
          hiddenNodesCount += 1;
          return null;
        }
        const nodeId = `node@@${stop.nodeId}@@${index}`;
        const shouldShowAddIcons = stop.nodeId === selectedNodeId && index === selectedNodeIndex && isModifyingAddPoint;
        return (
          <TreeItem
            nodeId={nodeId}
            data-scroll-to={nodeId}
            key={nodeId}
            classes={stopClasses}
            onDoubleClick={createDoubleClickHandler(Number(stop.stopId))}
            label={(
              <Fragment>
                {
                  shouldShowAddIcons && (
                    <div
                      className={classes.selectedPointWrapperTop}
                    >
                      <span className={classes.selectedPointIconWrapper}
                      >
                        {addIndex === index ? (
                          <span className={classes.selectedPointIconWrapper}>
                            <span className={classes.AddPointDropIcon} onClick={(e) => {
                              e.stopPropagation();
                              setAddIndex(undefined);
                              setPointAddingDirection(undefined);
                            }}>
                              <AddPointDropIcon />
                            </span>

                            <hr className={classes.redDashedLine} />
                          </span>
                        ) :
                          (
                            <span className={classes.selectedPointIconWrapper}
                            >
                              <span
                                className={classes.addPointIcon}

                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAddIndex(index);
                                  setPointAddingDirection(PointAddingDirection.before);
                                }}
                              >
                                <AddPointIcon />
                              </span>
                              <hr className={classes.redDashedLineHidden} />
                            </span>
                          )}
                      </span>
                    </div>
                  )
                }
                <span className={classes.count}>
                  {index + 1 - hiddenNodesCount}
                </span>
                <span className={stop.stopId ? classes.greenCircle : classes.purpleCircle} />
                <span className={classes.name}>
                  {stop.stopId ? stop.stopName : t('modules.editor.legendNodes.referencePoint')}
                </span>

                {
                  shouldShowAddIcons && (
                    <div className={classes.selectedPointWrapperBottom}>
                      <span style={{
                        marginRight: '4px', display: 'flex', alignItems: 'center', width: '100%',
                      }}
                      >
                        {addIndex === index + 1 ? (
                          <span className={classes.selectedPointIconWrapper}
                          >
                            <span className={classes.AddPointDropIcon} onClick={(e) => {
                              e.stopPropagation();
                              setAddIndex(undefined);
                              setPointAddingDirection(undefined);
                            }}>
                              <AddPointDropIcon />
                            </span>

                            <hr className={classes.redDashedLine} />
                          </span>
                        ) :
                          (
                            <span className={classes.selectedPointIconWrapper}
                            >
                              <span className={classes.addPointIcon} onClick={(e) => {
                                e.stopPropagation();
                                setAddIndex(index + 1);
                                setPointAddingDirection(PointAddingDirection.after);
                              }}>
                                <AddPointIcon />
                              </span>
                              <hr className={classes.redDashedLineHidden} />
                            </span>
                          )}
                      </span>
                    </div>
                  )
                }

              </Fragment>
            )}
          />
        );
      })}
      {!isModifying && id > 0 && <RouteDirectionFooter length={length} id={id} />}
    </TreeItem>
  );
};

export default RouteDirection;
