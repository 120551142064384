import { useSelector } from 'react-redux';

import { SelectValue } from 'src/interfaces';

import { useAcIntervalOptions, useAcYearOptions } from 'src/cluster/common';
import { selectDistGraphForm, useAcDistScenarioOptions } from 'src/cluster/distribution-common';

const useAcDistGraphParams = (): [SelectValue, SelectValue, SelectValue, boolean] => {
  const {
    year, scenarioId, intervalId, day,
  } = useSelector(selectDistGraphForm);

  const yearOptions = useAcYearOptions();
  const scenarioOptions = useAcDistScenarioOptions();
  const intervalOptions = useAcIntervalOptions();

  const yearValue = yearOptions.find(yearItem => yearItem.value === year);
  const scenarioValue = scenarioOptions.find(scenario => scenario.value === scenarioId);
  const intervalValue = intervalOptions.find(interval => interval.value === intervalId);

  return [
    yearValue ? yearValue.value : '',
    scenarioValue ? scenarioValue.value : '',
    intervalValue ? intervalValue.value : '',
    day,
  ];
};

export default useAcDistGraphParams;
