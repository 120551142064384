export default {
  defaults: {
    selectedPoint: undefined,
    selectedRoute: undefined,
    expandedRoute: undefined,
    selectedEdge: undefined,

    transportScenarios: [],
    macroeconomicScenario: undefined,
    year: undefined,

    transportScenariosActual: [],
    macroeconomicScenarioActual: undefined,
    yearActual: undefined,
  },
  onRouteChangeReset: {
    selectedRoute: true,
    expandedRoute: true,

    transportScenariosActual: true,
    macroeconomicScenarioActual: true,
    yearActual: true,
  },
};
