import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema, getUrl, RouteScheme,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';

import { ProjectSelector, selectAcProjectListForm } from 'src/cluster/common';
import { selectAcProjects, projectListFormActions } from 'src/cluster/projects';
import { EditorPage } from 'src/cluster/editor-map';
import { RegistriesPage } from 'src/cluster/editor-table';

type Props = WithTranslate & {
  onChange: () => void;
  match?: any;
};

const EditorRoot: FC<Props> = (props) => {
  const { t, onChange, match } = props;

  return (
    <PageWithHeader
      basePath={['agglomeration', 'editor']}
      menuRenderers={{
        editor: {
          label: t('modules.editor.captions.title'),
        },
        registries: {
          label: t('modules.registries.captions.title'),
        },
      }}
      headerChildren={(
        <Route path={getUrl(urlSchema.agglomeration.editor)}>
          <ProjectSelector
            selectProjects={selectAcProjects}
            selectProjectForm={selectAcProjectListForm}
            formActions={projectListFormActions}
            onChange={onChange}
          />
        </Route>
      )}
    >
      <RouteScheme
        prevMatch={match}
        basePath={['agglomeration', 'editor']}
        renderers={{
          editor: {
            component: EditorPage,
          },
          registries: {
            component: RegistriesPage,
          },
        }}
      />
    </PageWithHeader>
  );
};

export default EditorRoot;
