import { push } from 'connected-react-router';
import { api, forms } from 'redux-restify';

import i18n from 'src/i18n';
import { PERMISSIONS_ENDPOINT } from 'src/api-url-schema';
import { STATE_REPLACE_ACTION } from 'src/constants';
import {
  setToken,
  clearStorage,
  clearToken,
} from 'src/storage';

import { LOGIN_PAGE_URL } from './constants';

export const login = (nextUrl = '/') => (dispatch: any, getState: any) => {
  const authFormState = getState().forms.authForm;
  dispatch(forms.actions.authForm.submit())
    .then(({ data, status }: any) => {
      if (status === 200) {
        setToken(data.token);
        dispatch(forms.actions.authInfoForm.resetField('errors'));
        dispatch(forms.actions.authInfoForm.changeField('username', data.username));
        dispatch(forms.actions.authInfoForm.changeField('permissions', data.permissions));
        dispatch(push(nextUrl));
      }
    })
    .catch(({ data }: any) => {
      const errors = data && data.detail || i18n.t('common.messages.defaultError');
      dispatch(forms.actions.authForm.changeField('username', authFormState.username));
      dispatch(forms.actions.authForm.changeField('password', authFormState.password));
      dispatch(forms.actions.authInfoForm.changeField('errors', errors));
    });
};

export const getPermissions = () => (dispatch: any) => {
  const url = PERMISSIONS_ENDPOINT;
  dispatch(api.actions.callGet({ url }))
    .then(({ data, status }: any) => {
      if (status === 200) {
        dispatch(forms.actions.authInfoForm.changeField('permissions', data.permissions));
      }
    })
    .catch(({ data }: any) => {
      const errors = data && data.detail || i18n.t('common.messages.defaultError');
      dispatch(forms.actions.authInfoForm.changeField('errors', errors));
    });
};

export const logoutFront = () => (dispatch: any) => {
  clearStorage();
  clearToken();
  dispatch({
    type: STATE_REPLACE_ACTION,
    state: {},
  });
  dispatch(push(LOGIN_PAGE_URL));
};

export const logout = () => async (dispatch: any) => {
  dispatch(logoutFront());
};
