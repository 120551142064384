import React, { FC } from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},

  label: {},

  unit: {
    color: theme.palette.grey[600],
    textTransform: 'initial',
    fontWeight: 'normal',
  },
}));

interface Props {
  className?: string;
  unit: string;
  classes?: {
    root?: string;
    label?: string;
    unit?: string;
  };
}

const WithUnit: FC<Props> = (props) => {
  const {
    className, children, unit, classes = {},
  } = props;
  const nativeClasses = useStyles();
  return (
    <span className={clsx(nativeClasses.root, classes.root, className)}>
      <span className={clsx(nativeClasses.label, classes.label)}>{children}</span>
      &nbsp;
      <span className={clsx(nativeClasses.unit, classes.unit)}>
        (
        {unit}
        )
      </span>
    </span>
  );
};

export default WithUnit;
