import { EDGES_ENDPOINT } from 'src/api-url-schema';

const edge = {
  id: undefined,
  traffic: undefined,
  trafficPass: undefined,
  distance: undefined,
  fuelCost: undefined,
  time: undefined,
  timeMoney: undefined,
  fare: undefined,
  ttc: undefined,
  bandwidth: undefined,
  firstNode: [],
  secondNode: [],
};

export default {
  endpoint: EDGES_ENDPOINT,
  parent: 'agglomerationCalculations',
  defaults: {
    id: undefined,
    pedestrian: edge,
    car: edge,
    tat: edge,
    metro: edge,
    suburban: edge,
    express: edge,
    taxi: edge,
    cargo: edge,
  },
  allowIdRequests: false,
  pagination: false,
};
