import { api } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import { objectToCamel } from 'src/helpers';
import modals from 'src/modals';
import i18n from 'src/i18n';

import { selectAcProjectListForm, AC_PROJECTS_ENDPOINT } from 'src/cluster/common';
import { selectEditorGraphForm, editorFormActions, entitiesEditorFormActions } from 'src/cluster/editor-common';
import {
  FIND_PATH_ENDPOINT,
  EMPTY_REVERSE_DIRECTION,
  EMPTY_DIRECTION,
  selectEntitiesEditorForm,
  selectEditorPageForm,
} from 'src/cluster/editor-map';

export function findPath(firstNodeId: number, lastNodeId: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { vehicleTypeId } = selectEditorPageForm(state);
    const { year, scenarioId } = selectEditorGraphForm(state);
    const request = {
      url: `${AC_PROJECTS_ENDPOINT}${selectedProject}/${FIND_PATH_ENDPOINT}`,
      query: {
        firstNodeId,
        lastNodeId,
        scenarioId,
        year,
        vehicleTypeId,
      },
    };
    try {
      dispatch(editorFormActions.changeField('isWaiting', true));
      const { data } = await dispatch(api.actions.callGet(request));
      return objectToCamel(data);
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      dispatch(editorFormActions.resetField('isWaiting'));
    }
  };
}

export function addDirection(variantId: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }
    const updatedVariants = editableRoute.variants.map((variant) => {
      if (variant.id === variantId) {
        const newDirection = variant.routeDirections.length && variant.routeDirections[0].direction
          ? EMPTY_REVERSE_DIRECTION
          : EMPTY_DIRECTION;
        return {
          ...variant,
          routeDirections: [
            ...variant.routeDirections,
            newDirection,
          ],
        };
      }
      return variant;
    });
    const updatedRoute = {
      ...editableRoute,
      variants: updatedVariants,
    };
    dispatch(editorFormActions.changeField('directionId', -1));
    dispatch(editorFormActions.changeField('variantId', variantId));
    dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}

export function deleteDirection(variantId: number, directionId: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.editor.captions.deleteRouteVariantDirTitle'),
      text: i18n.t('modules.editor.messages.deleteRouteVariantDirInfo'),
      acceptButtonText: i18n.t('common.captions.delete'),
      declineButtonText: i18n.t('common.captions.cancel'),
      onAccept: () => {
        const updatedVariants = editableRoute.variants.map((variant) => {
          if (variant.id === variantId) {
            return {
              ...variant, routeDirections: variant.routeDirections.filter(direction => direction.id !== directionId),
            };
          }
          return variant;
        });

        const updatedRoute = {
          ...editableRoute, variants: updatedVariants,
        };

        dispatch(editorFormActions.resetField('directionId'));
        dispatch(editorFormActions.changeField('variantId', variantId));
        dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
        dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
      },
    }));
  };
}

export function swapDirections(variantId: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }
    const updatedVariants = editableRoute.variants.map((variant) => {
      if (variant.id === variantId) {
        return {
          ...variant,
          routeDirections: variant.routeDirections.map((direction) => ({
            ...direction,
            direction: !direction.direction,
          })),
        };
      }
      return variant;
    });
    const updatedRoute = {
      ...editableRoute,
      variants: updatedVariants,
    };
    dispatch(editorFormActions.changeField('variantId', variantId));
    dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}
