import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import { IndicatorsTask } from 'src/modules/mth/indicators';

import AggloIndicatorsSubtask from '../AggloIndicatorsSubtask';
import AggloIndicatorsItem from '../AggloIndicatorsItem';

import style from './index.module.css';

interface Props {
  task: IndicatorsTask;
}

const AggloIndicatorsTask: FC<Props> = (props: Props) => {
  const {
    task: {
      name,
      subtasks = [],
    },
  } = props;

  const indicatorsCount = subtasks.reduce((acc, subtask) => acc + subtask.indicators.length, 0);
  const [subtasksHead, ...subtasksTail] = subtasks;
  const [indicatorsHead, ...indicatorsTail] = subtasksHead.indicators;

  return (
    <Fragment>
      <tr className={classNames(indicatorsTail.length === 0 && style.bottomRow)}>
        <td className={style.topTaskCell}>
          {indicatorsHead.no}
        </td>
        <td
          rowSpan={indicatorsCount}
          className={style.taskCell}
        >
          {name}
        </td>
        <td
          rowSpan={indicatorsTail.length + 1}
          className={style.subtaskCell}
        >
          {subtasksHead.name}
        </td>
        <AggloIndicatorsItem indicator={indicatorsHead} />
      </tr>
      {indicatorsTail.map((indicator, index) => (
        <tr
          key={indicator.no}
          className={classNames(index === indicatorsTail.length - 1 && style.bottomRow)}
        >
          <td className={style.topTaskCell}>
            {indicator.no}
          </td>
          <AggloIndicatorsItem indicator={indicator} />
        </tr>
      ))}
      {subtasksTail.map((cell) => (
        <AggloIndicatorsSubtask
          key={cell.name}
          subtask={cell}
        />
      ))}
    </Fragment>
  );
};

export default AggloIndicatorsTask;
