import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import { useDispatch } from 'react-redux';

import { useSystemsContext } from 'src/constants';
import {
  FORECAST_FORM_NAMES, FORECAST_MODEL_NAMES, ForecastModel, ForecastChartRecord,
  PageForecastForm, ForecastTableRecord,
} from 'src/modules/matrix/forecast';
import { useRestifyForm, useRestifyModel } from 'src/helpers';
import actions from 'src/modules/matrix/forecast/actions';
import {
  PageProjectForm, ProjectModel, PROJECTS_MODEL_NAMES, PROJECTS_PAGES_FORM_NAMES,
} from 'src/modules/general/projects';

import style from './index.module.css';

import ForecastToolbar from '../ForecastToolbar';
import ForecastChart from '../ForecastChart';
import ForecastTable from '../ForecastTable';

interface Props {
  className?: string;
}

const MatrixForecastPage: React.FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const forecastModelName = FORECAST_MODEL_NAMES[currentSystem];
  const projectsModelName = PROJECTS_MODEL_NAMES[currentSystem];
  const [
    pageForecastForm,
    pageForecastFormActions,
  ] = useRestifyForm<PageForecastForm>(FORECAST_FORM_NAMES[currentSystem]);
  const [projectsEntities] = useRestifyModel<ProjectModel>(PROJECTS_MODEL_NAMES[currentSystem]);
  const [pageProjectForm] = useRestifyForm<PageProjectForm>(PROJECTS_PAGES_FORM_NAMES[currentSystem]);
  const currentProjectId = Number(projectsEntities.getById(pageProjectForm.selectedProject).id);
  const [forecastEntities, forecastActions] = useRestifyModel<ForecastModel>(forecastModelName);

  const forecastApiConfig = {
    parentEntities: {
      [projectsModelName]: currentProjectId,
    },
  };

  const [forecast, forecastIsLoading] = useMemo(() => (
    currentProjectId
      ? [forecastEntities.getArray(forecastApiConfig), forecastEntities.getIsLoadingArray(forecastApiConfig)]
      : [[], false]
  ), [forecastEntities]);
  const isLoading = Boolean(currentProjectId) && (forecastIsLoading || pageForecastForm.isForecastBuilding);

  const data = forecast.filter((item) => {
    return !(
      pageForecastForm.correspondences.length > 0
        && !pageForecastForm.correspondences.includes(`${item.fromCity} - ${item.toCity}`)
    );
  });
  const chartData = useMemo(() => data.reduce((acc: ForecastChartRecord[], item: ForecastModel) => {
    // temporary workaround
    if (item.year < 2022) {
      return acc;
    }

    const yearIndex = acc.findIndex(n => n.year === item.year);
    if (yearIndex >= 0) {
      if (pageForecastForm.allTransport) {
        const currentValue: number = acc[yearIndex].allTransport || 0;
        // eslint-disable-next-line no-param-reassign
        acc[yearIndex] = {
          ...acc[yearIndex],
          allTransport: currentValue + (pageForecastForm.hsw ? item.trafficHsw : item.trafficNoHsw),
        };
      } else {
        const transportType = camelCase(item.type);
        const currentValue: number = acc[yearIndex][transportType] || 0;
        const newValue = currentValue + (pageForecastForm.hsw ? item.trafficHsw : item.trafficNoHsw);
        // eslint-disable-next-line no-param-reassign
        acc[yearIndex] = {
          ...acc[yearIndex],
          [transportType]: newValue,
        };
      }
      return acc;
    }

    if (pageForecastForm.allTransport) {
      acc.push({
        year: item.year,
        allTransport: pageForecastForm.hsw ? item.trafficHsw : item.trafficNoHsw,
      });
    } else {
      acc.push({
        year: item.year,
        [camelCase(item.type)]: pageForecastForm.hsw ? item.trafficHsw : item.trafficNoHsw,
      });
    }

    return acc;
  }, []), [forecast, pageForecastForm]);

  const tableData = useMemo(() => data.reduce((acc: ForecastTableRecord[], item: ForecastModel) => {
    // temporary workaround
    if (item.year < 2022) {
      return acc;
    }

    const typeIndex = acc.findIndex(n => n.type === camelCase(item.type));
    if (typeIndex >= 0) {
      const currentValue: number = acc[typeIndex][item.year] || 0;
      const newValue = currentValue + (pageForecastForm.hsw ? item.trafficHsw : item.trafficNoHsw);
      // eslint-disable-next-line no-param-reassign
      acc[typeIndex] = {
        ...acc[typeIndex],
        [item.year]: newValue,
      };
      return acc;
    }

    acc.push({
      type: item.type,
      [item.year]: pageForecastForm.hsw ? item.trafficHsw : item.trafficNoHsw,
    });

    return acc;
  }, [
    { type: 'airTransport' },
    { type: 'busTransport' },
    { type: 'motorTransport' },
    { type: 'speedMotorTransport' },
    { type: 'railSuburbanTransport' },
    { type: 'railPlatzTransport' },
    { type: 'railCoupeTransport' },
    { type: 'railSpeedTransport' },
    { type: 'railHighSpeedTransport' },
  ]), [forecast, pageForecastForm]);

  const handleBuildClick = useCallback(async () => {
    if (currentProjectId) {
      dispatch(pageForecastFormActions.changeField('isForecastBuilding', true));
      dispatch(actions[currentSystem].deleteForecast(currentProjectId));
      dispatch(forecastActions.clearData());
      await dispatch(actions[currentSystem].buildForecast(currentProjectId));
      const timer = setInterval(async () => {
        const entities: ForecastModel[] = await dispatch(forecastActions.loadData(forecastApiConfig));
        if (entities.length > 0) {
          clearInterval(timer);
          dispatch(pageForecastFormActions.changeField('isForecastBuilding', false));
        }
      }, 10000);
    }
  }, [currentSystem]);

  const [showAirTransport, setShowAirTransport] = useState(true);
  const [showBusTransport, setShowBusTransport] = useState(true);
  const [showMotorTransport, setShowMotorTransport] = useState(true);
  const [showSpeedMotorTransport, setShowSpeedMotorTransport] = useState(true);
  const [showRailSuburbanTransport, setShowRailSuburbanTransport] = useState(true);
  const [showRailPlatzTransport, setShowRailPlatzTransport] = useState(true);
  const [showRailCoupeTransport, setShowRailCoupeTransport] = useState(true);
  const [showRailSpeedTransport, setShowRailSpeedTransport] = useState(true);
  const [showRailHighSpeedTransport, setShowRailHighSpeedTransport] = useState(true);

  return (
    <div className={classNames(style.root, className)}>
      <ForecastToolbar {...{
        data: forecast,
        chartData,
        currentProjectId,
        form: pageForecastForm,
        formActions: pageForecastFormActions,
        onBuildClick: handleBuildClick,
      }} />
      {
        (chartData.length > 0 || isLoading) && (
          <ForecastChart {...{
            data: chartData,
            isLoading,
            showAirTransport,
            showBusTransport,
            showMotorTransport,
            showRailCoupeTransport,
            showRailHighSpeedTransport,
            showRailPlatzTransport,
            showRailSpeedTransport,
            showRailSuburbanTransport,
            showSpeedMotorTransport,
          }} />
        )
      }
      {
        (tableData.length > 0 || isLoading) && (
          <ForecastTable {...{
            data: tableData,
            isLoading,
            showAirTransport,
            showBusTransport,
            showMotorTransport,
            showRailCoupeTransport,
            showRailHighSpeedTransport,
            showRailPlatzTransport,
            showRailSpeedTransport,
            showRailSuburbanTransport,
            showSpeedMotorTransport,
            setShowAirTransport,
            setShowBusTransport,
            setShowMotorTransport,
            setShowRailCoupeTransport,
            setShowRailHighSpeedTransport,
            setShowRailPlatzTransport,
            setShowRailSpeedTransport,
            setShowRailSuburbanTransport,
            setShowSpeedMotorTransport,
          }} />
        )
      }
    </div>
  );
};

export default MatrixForecastPage;
