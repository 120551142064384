export const AC_MAP_LEGEND_FORM_NAME = 'acMapLegendForm';
export const AC_GEOJSON_FORM_NAME = 'acGeojsonForm';
export const AC_PROJECT_LIST_FORM_NAME = 'acProjectListForm';

export const AC_DATA_PAGE_FORM_NAME = 'acDataPageForm';

export const TTC_CALC_PAGE_FORM_NAME = 'acTtcCalcPageForm';

export const AC_FORECAST_PAGE_FORM_NAME = 'acForecastPageForm';

export const EDITOR_PAGE_FORM_NAME = 'acEditorPageForm';
export const ENTITIES_EDITOR_FORM_NAME = 'acEntitiesEditorForm';
export const EDITOR_GRAPH_FORM_NAME = 'acEditorGraphForm';
export const EDITOR_COPY_NETWORK_FORM_NAME = 'acEditorCopyNetworkForm';

export const AC_REGISTRY_FILTERS_NAME = 'acRegistryFiltersForm';
export const AC_REGISTRY_PAGE_FORM_NAME = 'acRegistryPageForm';

export const AC_DISTRIBUTION_PAGE_FORM_NAME = 'acDistributionTablePageForm';
export const AC_DISTRIBUTION_MAP_PAGE_FORM_NAME = 'acDistributionMapPageForm';
