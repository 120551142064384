import React, { PureComponent } from 'react';
import classNames from 'classnames';

import style from './index.module.css';

import { INNER_INPUT_TYPES, LABEL_POSITION_TYPES, ERROR_TYPES } from './constants';

export interface SwitchProps {
  className?: string;
  labelClassName?: string;
  disabledLabelClassName?: string;

  type?: string;
  disabled: boolean;
  stopPropagation: boolean;

  switched: boolean;
  switchedComponent: React.ReactNode;
  unSwitchedComponent?: React.ReactNode;
  label?: React.ReactNode;
  labelPosition: string;
  secondLabel?: React.ReactNode;
  secondLabelPosition: string;

  errors: string[];
  showTextErrors: boolean;
  validate: {
    checkOnBlur?: boolean;
    required?: boolean;
  };

  onChange: (value: boolean) => void;
  onValid: () => void;
  onInvalid: (error: string[]) => void;
}

interface State {
  innerErrors: string[];
  wasBlur: boolean;
}

class EnhancedSwitch extends PureComponent<SwitchProps, State> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    disabled: false,
    stopPropagation: false,
    className: '',
    labelClassName: '',
    disabledLabelClassName: '',
    type: INNER_INPUT_TYPES.checkbox,
    labelPosition: LABEL_POSITION_TYPES.right,
    secondLabelPosition: LABEL_POSITION_TYPES.left,
    errors: [],
    showTextErrors: true,
    validate: {},

    onChange: () => undefined,
    onValid: () => undefined,
    onInvalid: () => undefined,
  }

  public constructor(props: SwitchProps) {
    super(props);

    this.validate = this.validate.bind(this);
    this.onValidationUpdate = this.onValidationUpdate.bind(this);

    this.state = {
      innerErrors: [],
      wasBlur: false,
    };
  }

  public componentDidMount() {
    this.validate(this.props.switched);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: SwitchProps) {
    if (nextProps.switched !== this.props.switched) {
      this.validate(nextProps.switched);
    }
  }

  public onValidationUpdate() {
    const { disabled } = this.props;
    const { innerErrors } = this.state;

    if (!disabled) {
      if (innerErrors.length) {
        this.props.onInvalid(innerErrors);
      } else {
        this.props.onValid();
      }
    }
  }

  public validate(value: boolean) {
    const innerErrors = this.props.validate.required && !value ?
      [ERROR_TYPES.required] : [];
    this.setState({ innerErrors }, this.onValidationUpdate);
  }

  public render() {
    const {
      className,
      labelClassName,
      disabledLabelClassName,
      disabled,
      stopPropagation,
      type,
      switched,
      switchedComponent,
      unSwitchedComponent,
      label,
      secondLabel,
      labelPosition,
      secondLabelPosition,
      errors,
      showTextErrors,

      onChange,
    } = this.props;

    const currentErrors = this.props.validate.checkOnBlur && !this.state.wasBlur ? [] : errors;

    return (
      <div {...{
        tabIndex: 0,
        role: 'switch',
        className: classNames(style.root, className, disabled && style.disabled),
        onClick: !disabled
          ? ((e) => {
            if (stopPropagation) {
              e.stopPropagation();
            }
            onChange(!switched);
          })
          : undefined,
        onBlur: !disabled
          ? (() => this.setState({ wasBlur: true }))
          : undefined,
        onFocus: !disabled
          ? (() => this.setState({ wasBlur: false }))
          : undefined,
      }} >
        <input {...{
          type,
          disabled,
          className: style.innerInput,
          checked: switched,
          onChange: () => undefined, // suppress react warning
        }} />
        {label &&
          <label
            className={classNames(
              labelPosition === LABEL_POSITION_TYPES.right ? style.labelRight : style.labelLeft,
              labelClassName,
              disabled && style.disabledLabel,
              disabled && disabledLabelClassName,
            )}
          >
            {label}
          </label>}
        {secondLabel &&
          <label
            className={classNames(
              secondLabelPosition === LABEL_POSITION_TYPES.right ? style.labelRight : style.labelLeft,
              labelClassName,
              disabled && style.disabledLabel,
              disabled && disabledLabelClassName,
            )}
          >
            {secondLabel}
          </label>}
        <div className={style.component}>
          {switched && switchedComponent}
          {!switched && (unSwitchedComponent || switchedComponent)}
        </div>
        {showTextErrors &&
          <div className={style.errors}>
            {currentErrors.map((error, index) => (
              <div className={style.errorText} key={index}>
                {error}
              </div>
            ))}
          </div>}
      </div>
    );
  }
}

export default EnhancedSwitch;
