import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AC_DATA_FORECAST_MODAL_NAME, buildRPM } from 'src/cluster/balance-data';
import { AC_DIST_GRAPH_VERSION_MODAL_NAME, loadMatrixForecast, selectMatrixForecast } from 'src/cluster/common';
import { acDistGraphFormActions, selectDistGraphForm } from 'src/cluster/distribution-common';
import { refetchRPMAllData } from 'src/cluster/distribution-table/store';

import { withTranslate } from 'src/i18n';
import modals, { WithModalProps } from 'src/modals';
import { AppState } from 'src/reducer';

import ModalProcessDataCalc from './ModalProcessDataCalc';

const mapStateToProps = (state: AppState) => ({
  matrixForecastEntities: selectMatrixForecast(state),
  graphForm: selectDistGraphForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  loadMatrixForecast: () => dispatch(loadMatrixForecast()),
  setLoadingCalc: (flag: boolean) => dispatch(acDistGraphFormActions.changeField('isCalculate', flag)),
  refetchData: () => dispatch(refetchRPMAllData()),
  close: () => {
    dispatch(closeAction);
  },
  openMatrixSettingsModal(activeOptions: Map<string, boolean>) {
    dispatch(modals.actions.showModal(true, AC_DATA_FORECAST_MODAL_NAME, { activeOptions }));
  },
  openDateYear() {
    dispatch(modals.actions.showModal(true, AC_DIST_GRAPH_VERSION_MODAL_NAME));
  },
  calculateRPM: () => {
    dispatch(buildRPM());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalProcessDataCalc));
