import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';

import { AcBalanceDataFactorsModel } from 'src/cluster/common';
import { selectDataFactors } from 'src/cluster/balance-common';

const useFactorOptions = (): SelectMenuOption[] => {
  const [factors] = useSelector(selectDataFactors);

  return useMemo(() => factors.map((factor: AcBalanceDataFactorsModel) => {
    const [label] = factor.name.split(', ');
    return ({
      id: String(factor.id),
      value: factor.id,
      label,
    });
  }), [JSON.stringify(factors)]);
};

export default useFactorOptions;
