import { ReactNode } from 'react';

import { AcDistRoutesModel } from 'src/cluster/distribution-table';
import { isString, isNumber } from './type-guards';

export function sortOptionsByDefault<T extends { label: ReactNode }>(options: T[]): T[] {
  return options.sort((a: T, b: T) => {
    const labelA = a.label;
    const labelB = b.label;
    if (isNumber(labelA) && isNumber(labelB)) {
      return labelA - labelB;
    }
    if (!isString(labelA)) {
      return 1;
    }
    if (!isString(labelB)) {
      return -1;
    }
    return labelA.localeCompare(labelB);
  });
}

export function sortNumberRoute(data: AcDistRoutesModel[]) {
  return data.sort((a, b) => a.variantNumber.localeCompare(b.variantNumber, undefined, { numeric: true, sensitivity: 'base' }));
}

export function sortOptionsByNumber<T extends { label: ReactNode }>(options: T[]): T[] {
  return options.sort((a: T, b: T) => {
    const labelA = a.label;
    const labelB = b.label;
    if (isNumber(labelA) && isNumber(labelB)) {
      return labelA - labelB;
    }
    if (!isString(labelA)) {
      return 1;
    }
    if (!isString(labelB)) {
      return -1;
    }
    const numberA = Number.parseInt(labelA, 10);
    const numberB = Number.parseInt(labelB, 10);

    if (numberA === numberB) {
      return labelA.localeCompare(labelB);
    }
    return numberA - numberB;
  });
}
