import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export default makeStyles(({ spacing, palette, shape }: Theme) => ({
  drawer: {
    width: 400,
    flexShrink: 0,
    '& h6': {
      marginBottom: spacing(1),
    },
  },
  drawerPaper: {
    width: 400,
    zIndex: 1600,
    padding: spacing(19, 0, 4),
    color: palette.common.black,
  },
  legend: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  closeButton: {
    width: 14,
    color: palette.text.primary,
  },
  vehicleIconSmall: {
    marginRight: spacing(1),
    '& svg': {
      width: 16,
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',

    '&> *': {
      paddingRight: spacing(1),
    },
  },
  iconWithBorder: {
    '& svg': {
      border: `1px solid ${palette.grey[400]}`,
      borderRadius: shape.borderRadius,
      color: palette.grey[400],
    },
  },
  filter: {
    width: 215,
    marginBottom: spacing(2),

    '& label': {
      fontSize: 12,
    },
  },
  transformIcon: {
    transform: 'rotate(135deg)',
  },
  clear: {
    textDecoration: 'none',
  },
}));
