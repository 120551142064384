import { useMemo } from 'react';

import { getRangeByMinMax } from 'src/helpers';

import { TRAFFIC_COLOR } from 'src/cluster/distribution-map';

const useHeatRange = () => {
  return useMemo(() => getRangeByMinMax(0, 1.2, 6).map((value, i, arr) => (
    {
      from: value,
      to: arr[i + 1] || Infinity,
      value: TRAFFIC_COLOR[i + 1],
    }
  )), []);
};

export default useHeatRange;
