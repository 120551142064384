import React from 'react';

const CheckboxCheckedIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.890625" y="0.801758" width="17" height="17" rx="2.5" stroke="#E5293C" />
    <path
      d="M4.39062 9.46842L8.55729 12.8018L14.3906 5.30176"
      stroke="#E5293C"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxCheckedIcon;
