import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, zIndex }) => createStyles({
  root: {
    height: '100%',
  },

  withLegend: {
    '& $rightTools': {
      bottom: 70,
    },
  },

  tools: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing(5),
    zIndex: zIndex.drawer,
  },

  rightTools: {
    bottom: 25,
    right: 10,
  },

  bottomTools: {
    bottom: 25,
    right: 10,
  },

  legendToggle: {
    '& span': {
      fontSize: 12,
      textTransform: 'uppercase',
    },
  },
}));
