import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RestifyId } from 'redux-restify';
import classNames from 'classnames';

import { useRestifyForm } from 'src/helpers';
import { GEOJSON_ENDPOINT, GRAPH_ENDPOINT, POLYGONS_ENDPOINT } from 'src/api-url-schema';

import { ROUTES_PAGES_FORM_NAME } from 'src/modules/mth/calculations';
import {
  AGGLOMERATION_EDGE_TYPES, VIEW_MODES, PageCalculationsRoutesForm, AggloMap, AggloMapFilter,
} from 'src/modules/mth/common';

import {
  selectCurrentCalculationNodes,
  selectCurrentTransportType,
  selectEndpoint,
  selectCurrentProjectRoutes,
  selectSelectedRouteEdges,
} from '../../selectors';

import style from './index.module.css';

function useGeojsonUrls(calculationId: RestifyId): string[] {
  const { apiHost, apiPrefix, endpoint } = useSelector(selectEndpoint);
  const currentTransportType = useSelector(selectCurrentTransportType);

  const calculationEndpoint = `${apiHost}${apiPrefix}${endpoint}${calculationId}/`;
  const graphEndpoint = `${calculationEndpoint}${GRAPH_ENDPOINT}${GEOJSON_ENDPOINT}`;
  const polygonsUrl = `${calculationEndpoint}${POLYGONS_ENDPOINT}${GEOJSON_ENDPOINT}`;
  const graphUrl = `${graphEndpoint}?transport_type=${currentTransportType}`;

  return [polygonsUrl, graphUrl];
}

interface Props {
  className?: string;
  calculationId: RestifyId;
  resetActualParams(): void;
}

const AggloCalcMap: React.FC<Props> = (props: Props) => {
  const { calculationId, resetActualParams } = props;

  const [nodes, nodesIsLoading] = useSelector(selectCurrentCalculationNodes);
  const [routes, overshot, routesIsLoading, hasErrors] = useSelector(selectCurrentProjectRoutes);
  const [selectedEdges, selectedEdgesIsLoading] = useSelector(selectSelectedRouteEdges);
  const isLoading = nodesIsLoading || routesIsLoading;
  const [isSourceLoading, setIsSourceLoading] = useState<boolean>(true);

  const [form, formActions] = useRestifyForm<PageCalculationsRoutesForm>(ROUTES_PAGES_FORM_NAME);
  const [polygonsUrl, graphUrl] = useGeojsonUrls(calculationId);

  useEffect(() => {
    if (hasErrors) {
      resetActualParams();
    }
  }, [hasErrors]);

  const showGraph = form.viewMode === VIEW_MODES.graph;
  const isFullHeight = !!(form.selectedEdges || form.isStartSelected || form.isFinishSelected) && (
    !showGraph || form.transportType === AGGLOMERATION_EDGE_TYPES.auto
  );

  const cancelSourceLoading = () => {
    setIsSourceLoading(false);
  };

  return (
    <div className={style.root}>
      <AggloMap {...{
        graphUrl,
        polygonsUrl,
        form,
        formActions,
        nodes,
        routes,
        overshot,
        isLoading,
        onSourceData: setIsSourceLoading,
      }} />
      <AggloMapFilter {...{
        className: classNames(style.filter, isFullHeight && style.fullHeight),
        nodes,
        routes,
        selectedEdges,
        selectedEdgesIsLoading,
        form,
        formActions,
        showFilter: true,
        isLoading,
        isSourceLoading,
        cancelSourceLoading,
      }} />
    </div>
  );
};

export default AggloCalcMap;
