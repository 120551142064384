import { writeStorage } from './storage';

const addStorageWriter = (store: any) => {
  const handleVisibilityChange = () => {
    if (document.hidden) {
      writeStorage(store);
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange, false);
  // Workaround for clearing localstorage from devtools
  if (process.env.PROD) {
    window.onunload = () => {
      writeStorage(store);
    };
  }
};

export default addStorageWriter;
