import React from 'react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import { useDispatch } from 'react-redux';

import { RestifyId } from 'redux-restify';

import modalsStyle from 'src/styles/modals.module.css';

import { useCurrentVocabulary, getVocabularyValue, WithTranslate } from 'src/i18n';
import { useRestifyModel, useRestifyForm } from 'src/helpers';
import { Systems } from 'src/constants';
import { WithModalProps } from 'src/modals';

import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import PButton from 'src/components/deprecated/PButton';
import PInput, { INPUT_TYPES } from 'src/components/deprecated/PInput';
import MultiValueInput from 'src/components/etc/MultiValueInput';
import style from './index.module.css';

import { ExportCalculationForm } from '../../forms/exportCalculation.form';
import {
  EXPORT_TYPES,
  EXPORT_TYPES_DICT,
  CALCULATION_FILTERS_MODEL_NAMES,
  CALCULATIONS_MODEL_NAMES,
  CalculationFilters,
} from '../../constants';
import actions from '../../actions';

export interface ExportCalculationModalProps {
  calculationId: RestifyId;
  system: Systems;
}

type Props = ExportCalculationModalProps & WithModalProps & WithTranslate & {
  className?: string;
}

const ModalExportCalculation: React.FC<Props> = ({
  className,
  calculationId,
  system,
  cancelAction,
  submitAction,
  t,
}) => {
  const dispatch = useDispatch();
  const [edgeInputKey, setEdgeInputKey] = React.useState(Math.random());
  const [form, formActions] = useRestifyForm<ExportCalculationForm>('exportCalculationForm');
  React.useEffect(() => {
    dispatch(formActions.resetForm());
    return () => {
      dispatch(formActions.resetForm());
    };
  }, []);
  const [calculationFiltersEntities] = useRestifyModel<CalculationFilters>(CALCULATION_FILTERS_MODEL_NAMES[system]);
  const [currentLocale, vocabulary] = useCurrentVocabulary();

  const currentFilters = calculationFiltersEntities.getById('', {
    parentEntities: {
      [CALCULATIONS_MODEL_NAMES[system]]: calculationId,
    },
  });
  const isLoading = calculationFiltersEntities.getIsLoadingById('', {
    parentEntities: {
      [CALCULATIONS_MODEL_NAMES[system]]: calculationId,
    },
  });
  const needFilters = form.type === EXPORT_TYPES.graph || form.type === EXPORT_TYPES.routes;

  return (
    <div className={classNames(style.root, className)}>
      <div className={style.headerLabel}>
        {t('common.captions.exportType')}
      </div>
      <div className={style.selectsRow}>
        <PSelect {...{
          className: style.select,
          items: Object.keys(EXPORT_TYPES_DICT).map(type => ({
            value: type,
            label: t(EXPORT_TYPES_DICT[type]),
          })),
          values: form.type === undefined ? undefined : [form.type],
          onChange: values => dispatch(formActions.changeField('type', values[0])),
        }} />
      </div>
      <div className={needFilters ? style.filtersShown : style.filtersHidden}>
        <div className={style.label}>
          {t('common.captions.parameters')}
        </div>
        <div className={style.filtersRow}>
          <MultiValueInput {...{
            className: style.select,
            onChange: (values: DropDownValue[]) => dispatch(formActions.changeField('from', values)),
            values: form.from,
            renderValue: (v: DropDownValue) => getVocabularyValue(currentLocale, vocabulary, String(v)),
          }}>
            <PSelect {...{
              label: t('common.captions.from'),
              items: isLoading ? [] : currentFilters.from.filter(node => !form.from.includes(node)).map(node => ({
                value: node,
                label: getVocabularyValue(currentLocale, vocabulary, node),
              })),
              onChange: values => dispatch(formActions.changeField('from', form.from.concat(values.toString()))),
            }} />
          </MultiValueInput>
          <MultiValueInput {...{
            onChange: (values: DropDownValue[]) => dispatch(formActions.changeField('to', values)),
            className: style.select,
            values: form.to,
            renderValue: (v: DropDownValue) => getVocabularyValue(currentLocale, vocabulary, String(v)),
          }}>
            <PSelect {...{
              label: t('common.captions.to'),
              items: isLoading ? [] : currentFilters.to.filter(node => !form.to.includes(node)).map(node => ({
                value: node,
                label: getVocabularyValue(currentLocale, vocabulary, node),
              })),
              onChange: values => dispatch(formActions.changeField('to', form.to.concat(values.toString()))),
            }} />
          </MultiValueInput>
          <MultiValueInput {...{
            onChange: (values: DropDownValue[]) => dispatch(formActions.changeField('edge', values)),
            className: style.select,
            values: form.edge,
          }}>
            <PInput {...{
              type: INPUT_TYPES.int,
              autoFocus: true,
              label: t('systems.shipments.captions.edges'),
              key: edgeInputKey,
              formatted: false,
              onEnter: (value: any) => {
                if (value) {
                  dispatch(formActions.changeField('edge', uniq(form.edge.concat(value.toString()))));
                  setEdgeInputKey(Math.random());
                }
              },
              placeholder: t('systems.shipments.captions.edgesInputPlaceholder'),
            }} />
          </MultiValueInput>
        </div>
      </div>
      <div className={modalsStyle.buttonsContainer}>
        <PButton {...{
          className: modalsStyle.button,
          onClick: cancelAction,
          type: 'white',
        }}>
          {t('common.captions.cancel')}
        </PButton>
        <PButton {...{
          className: modalsStyle.button,
          disabled: !form.type,
          onClick: () => {
            dispatch(actions[system].downloadCalculationExport(calculationId)).then(() => {
              submitAction();
            });
          },
        }}>
          {t('common.captions.export')}
        </PButton>
      </div>
    </div>
  );
};

export default ModalExportCalculation;
