import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import PButton from 'src/components/deprecated/PButton';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  status?: string;
  logout(): void;
}

const NotFound: FC<Props> = (props) => {
  const {
    className = '',
    status = '404',
    logout,
    t,
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      <div className={style.messageWrapper}>
        <span className={style.status}>{status}</span>
        <span className={style.message}>
          {t('common.messages.unavailable')}
        </span>
        <span className={style.message}>
          {t('common.messages.logInAgain')}
        </span>
        <PButton
          type="white"
          className={style.button}
          onClick={logout}
        >
          {t('common.captions.logout')}
        </PButton>
      </div>
    </div>
  );
};

export default NotFound;
