import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getRangeByMinMax } from 'src/helpers';

import { selectEdgesExtreme, TRAFFIC_WIDTH } from 'src/cluster/distribution-map';

const useWidthRange = () => {
  const [min, max] = useSelector(selectEdgesExtreme);

  const isEqual = min === max;

  return useMemo(() => {
    return getRangeByMinMax(isEqual ? 0 : min, max, 6).map((value, i, arr) => ({
      from: value,
      to: arr[i + 1] || Infinity,
      value: TRAFFIC_WIDTH[i + 1],
    }));
  }, [min, max]);
};

export default useWidthRange;
