import React, { FC } from 'react';
import ReactNumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

import { WithTranslate } from 'src/i18n';

function getThousandSeparator(locale: string) {
  return locale === 'ru' ? ' ' : ',';
}

function getDecimalSeparator(locale: string) {
  return locale === 'ru' ? ',' : '.';
}

interface OwnProps {
  name: string;
  step?: number;
  allowNegative?: boolean;
  decimalScale?: number;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  inputRef: (instance: ReactNumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

type Props = OwnProps & WithTranslate & NumberFormatProps;

const NumberInput: FC<Props> = (props) => {
  const {
    inputRef,
    step = 1,
    allowNegative,
    decimalScale,
    thousandSeparator,
    decimalSeparator,
    prefix,
    suffix,
    locale,
    onChange,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t, setLocale, toggleLocale, Trans,
    ...rest
  } = props;

  const handleValueChange = (values: NumberFormatValues) => {
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  };

  return (
    <ReactNumberFormat
      {...rest}
      allowNegative={allowNegative}
      allowLeadingZeros={false}
      fixedDecimalScale={false}
      allowedDecimalSeparators={[',', '.']}
      thousandSeparator={thousandSeparator ?? getThousandSeparator(locale)}
      decimalSeparator={decimalSeparator ?? getDecimalSeparator(locale)}
      thousandsGroupStyle="thousand"
      decimalScale={decimalScale}
      isNumericString
      prefix={prefix}
      suffix={suffix}
      step={step}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
    />
  );
};

export default NumberInput;
