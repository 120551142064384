import { TtcCalcPageForm } from 'src/cluster/common';

const defaults: TtcCalcPageForm = {
  year: undefined,
  scenarioId: undefined,
  intervalId: undefined,
  siteFromId: undefined,
  siteToId: undefined,
  regionFromId: undefined,
  regionToId: undefined,
  isExporting: false,
  isSites: true,

  exportUrl: undefined,
  filename: undefined,
  progress: undefined,

  pageSize: 10,
  currentPage: 1,
};

export default { defaults };
