/* eslint-disable max-len */
import React from 'react';

const LangRu = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M10.037 0C4.54366 0 0.0745688 4.46935 0.0745688 9.96272C0.0745688 11.865 0.61267 13.7104 1.63205 15.3088L0.0182649 19.5483C-0.021842 19.6534 0.00541002 19.7719 0.0871665 19.8491C0.139871 19.899 0.208516 19.9252 0.277931 19.9252C0.316239 19.9252 0.35506 19.9175 0.391568 19.901L4.34081 18.1335C6.01811 19.3063 7.98437 19.9252 10.0373 19.9252C15.5309 19.9252 20 15.4558 20 9.96272C19.9997 4.46909 15.5306 0 10.037 0ZM10.037 18.4551C8.24173 18.4551 6.52407 17.8998 5.0702 16.8488C4.86555 16.7012 4.62413 16.623 4.37218 16.623C4.20301 16.623 4.03924 16.658 3.8855 16.7269L2.41465 17.3853L3.05585 15.6998C3.19237 15.3427 3.14815 14.9396 2.9381 14.621C2.02695 13.2355 1.54516 11.6248 1.54516 9.96246C1.54516 7.69796 2.42931 5.56638 4.03538 3.9603C5.6412 2.35423 7.77304 1.46982 10.0375 1.46982C14.7205 1.46982 18.5302 5.27972 18.5302 9.96246C18.5297 14.6455 14.72 18.4551 10.037 18.4551Z"
      fill="#currentColor"
    />
    <path
      d="M8.53095 7.2583C8.73534 7.34623 8.90863 7.47555 9.05054 7.64626C9.16804 7.78689 9.2611 7.94243 9.32975 8.11314C9.39839 8.28386 9.43259 8.47848 9.43259 8.69701C9.43259 8.96053 9.366 9.21994 9.23308 9.47472C9.10016 9.72951 8.88034 9.90973 8.57414 10.0151C8.83021 10.118 9.01172 10.2643 9.11842 10.454C9.22511 10.6435 9.27859 10.9327 9.27859 11.322V11.6947C9.27859 11.9482 9.28887 12.1202 9.30918 12.2107C9.33978 12.3539 9.41099 12.4593 9.52334 12.527V12.6663H8.24301C8.20778 12.5432 8.18284 12.4442 8.16768 12.3688C8.1376 12.2133 8.12114 12.0537 8.11883 11.8907L8.11137 11.3747C8.10649 11.0206 8.04556 10.7846 7.92781 10.6666C7.81031 10.5486 7.58998 10.4897 7.26707 10.4897H6.13431V12.6666H5V7.11536H7.65528C8.0345 7.12281 8.32656 7.17063 8.53095 7.2583ZM6.13353 8.07947V9.57088H7.38174C7.62958 9.57088 7.81571 9.5408 7.93963 9.48038C8.15893 9.37497 8.26871 9.16647 8.26871 8.85512C8.26871 8.51858 8.16253 8.2926 7.95043 8.17716C7.83114 8.11186 7.65246 8.07921 7.41387 8.07921H6.13353V8.07947Z"
      fill="#currentColor"
    />
    <path
      d="M10.5439 7.11536H11.7227V10.5247C11.7227 10.906 11.7677 11.1844 11.8577 11.3603C11.9978 11.6713 12.303 11.8266 12.7732 11.8266C13.2411 11.8266 13.545 11.6713 13.6851 11.3603C13.7751 11.1844 13.8201 10.906 13.8201 10.5247V7.11536H14.9992V10.5273C14.9992 11.1173 14.9079 11.5767 14.7246 11.9056C14.3837 12.5082 13.7335 12.8095 12.7732 12.8095C11.8137 12.8095 11.1615 12.5082 10.8183 11.9056C10.6352 11.5767 10.5439 11.1173 10.5439 10.5273V7.11536Z"
      fill="#currentColor"
    />
  </svg>
);

export default LangRu;
