import { isNumber, isObject, isString } from 'src/helpers';
import {
  AcRegCarriersModel,
  AcRegRoutesResultsModel,
  AcRegSeasonsModel,
  AcRegVehicleClassModel,
} from 'src/cluster/editor-table';

export function isAcRegRoutesResultsModel(record: unknown): record is AcRegRoutesResultsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    routeNumber,
    carrier,
    routeLongName,
  } = record as Partial<AcRegRoutesResultsModel>;

  return isNumber(id)
    && isString(routeNumber)
    && isString(carrier)
    && isString(routeLongName);
}

export function isAcRegCarriersModel(record: unknown): record is AcRegCarriersModel {
  if (!isObject(record)) {
    return false;
  }

  const { id, name } = record as Partial<AcRegCarriersModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRegCarriersModel.');
  }

  return isValidModel;
}

export function isAcRegSeasonsModel(record: unknown): record is AcRegSeasonsModel {
  if (!isObject(record)) {
    return false;
  }

  const { id, name } = record as Partial<AcRegSeasonsModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRegSeasonsModel.');
  }

  return isValidModel;
}

export function isAcRegVehicleClassesModel(record: unknown): record is AcRegVehicleClassModel {
  if (!isObject(record)) {
    return false;
  }

  const { id, name } = record as Partial<AcRegVehicleClassModel>;

  const isValidModel = isNumber(id) && isString(name);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRegVehicleClassModel.');
  }

  return isValidModel;
}
