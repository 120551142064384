import { Theme, makeStyles, fade } from '@material-ui/core';

export default makeStyles(({ spacing, palette }: Theme) => ({
  tableWrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  totalPassengerTraffic: {
    maxWidth: 40,
  },
  leftTableWrapper: {
    width: '50%',
    borderRight: `1px solid ${palette.grey[500]}`,
  },
  middleTableWrapper: {
    width: '50%',
    overflowX: 'scroll',
    height: '100%',
  },
  cell: {
    borderBottomColor: palette.grey[300],
    color: palette.grey[700],
    height: 45,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
  },
  routeRow: {
    textAlign: 'right',
    width: 200,
    height: 55,
    '&:hover': {
      cursor: 'pointer',
      background: palette.grey[50],
      '&> td:first-of-type::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: 3,
        backgroundColor: palette.secondary.main,
      },
    },
  },
  first: {
    fontSize: 14,
  },
  headerCellFixed: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'fixed',
    left: 'auto!important',
    height: 50,
    whiteSpace: 'nowrap',
    borderBottomColor: `${palette.grey[400]}!important`,
  },
  headerCell: {
    paddingTop: 4,
    paddingBottom: 4,
    left: 'auto!important',
    height: 50,
    whiteSpace: 'nowrap',
    borderBottomColor: `${palette.grey[400]}!important`,
    paddingLeft: 24,
    paddingRight: 24,
  },
  bigSize: {
    fontSize: 14,
    textAlign: 'left',
  },
  title: {
    display: 'block',
    textAlign: 'left',
  },
  titleVisible: {
    display: 'block',
    textAlign: 'left',
    visibility: 'hidden',
  },
  taRight: {
    textAlign: 'right',
  },

  actions: {
    backgroundColor: palette.common.white,
    boxShadow: '-2px 0px 7px rgba(51, 59, 70, 0.15)',
  },
  lastColumn: {
    paddingRight: spacing(20),
    fontSize: 14,
  },
  variantRow: {
    backgroundColor: fade(palette.warning.light, 0.3),
  },
  cellClassName: {
    color: palette.text.primary,
    borderLeft: '1px solid #DFE3E9',
    textAlign: 'right',
    '&:first-child': {
      borderLeft: 0,
    },
    '&:nth-child(2)': {
      textAlign: 'left',
    },
    '&:last-child': {
      paddingRight: spacing(4),
    },
  },
  variantHead: {
    padding: spacing(1.5),
    borderLeft: '1px solid #DFE3E9',
    verticalAlign: 'top',
    fontSize: 14,
    backgroundColor: palette.warning.light,
    '&:first-child': {
      borderLeft: 0,
    },
  },
  variantHeadBottom: {
    fontSize: 14,
    borderTop: '1px solid #DFE3E9',
    padding: spacing(0.25, 0.5),
    '&:first-child': {
      borderLeft: '1px solid #DFE3E9',
      padding: spacing(0.25, 0.5),
    },
  },
}));
