import { AcProjectsModel } from 'src/cluster/projects/index';
import { ProcessStatus } from 'src/types';

export function isErroneous(record: AcProjectsModel) {
  return record.loadStatus === ProcessStatus.ERROR;
}

export function isSuccessful(record: AcProjectsModel) {
  return record.loadStatus === ProcessStatus.SUCCESS;
}
