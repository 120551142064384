import { AcEditorPageForm, EditorMode } from 'src/cluster/editor-map';

const defaults: AcEditorPageForm = {
  vehicleTypeId: undefined,
  routeId: undefined,
  directionId: undefined,
  variantId: undefined,
  nodeId: undefined,
  nodeIndex: undefined,
  edgeId: undefined,
  stopId: undefined,
  stopOrder: undefined,

  editorMode: EditorMode.view,
  message: undefined,
  isLoading: false,
  isWaiting: false,

  canBePositioned: true,
};

export default { defaults };
