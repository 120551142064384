import React, { Fragment, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Collapse, Size, TableRow } from '@material-ui/core';

import { isFunc } from 'src/helpers';
import { DataGridColumnProps } from 'src/components';

import GridArrow from '../GridArrow';
import GridCell from '../GridCell';
import TableCell from '../TableCell';

import useStyles from './styles';

interface Props<T> {
  body: T[];
  row: T;
  columns: DataGridColumnProps<T>[];
  expandable?: boolean | ((record: T) => boolean);
  expand?: (record: T) => ReactNode;
  rowClassName?: string | ((record: T) => string);
  cellClassName?: string | ((record: T) => string);
  size?: Size;
  actionComponent?: (row: T) => ReactNode;
  actionClass?: string | ((record: T) => string);
  onClick?: (row: T) => void;
  onMouseEnter?: (row: T) => void;
  onMouseLeave?: (row: T) => void;
  onExpandClick?: (row: T) => void;
}

function GridRow<T extends Record<string, any>>(props: Props<T>) {
  const {
    columns,
    body,
    row,
    expandable,
    expand,
    size,
    rowClassName,
    cellClassName,
    actionComponent,
    actionClass,
    onClick,
    onExpandClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const actualRowClassName = rowClassName && (isFunc(rowClassName) ? rowClassName(row) : rowClassName);
  const actualCellClassName = cellClassName && (isFunc(cellClassName) ? cellClassName(row) : cellClassName);
  const canExpand = expandable && isFunc(expandable) ? expandable(row) : expandable;

  const toggle = () => {
    if (isFunc(onExpandClick) && !open) {
      onExpandClick(row);
    }

    setOpen(!open);
  };

  const renderCells = columns && columns.map((column: DataGridColumnProps<T>, index: number) => {
    const {
      model,
      editable,
      className,
      onCancel,
      onSubmit,
    } = column;

    const hasArrow = index === 0 && canExpand;
    const hasInput = isFunc(editable) ? editable(row, index, body) : editable;
    const hasActions = actionComponent && index === columns.length - 1;

    return (
      <GridCell<T>
        key={index}
        className={clsx(
          actualCellClassName,
          hasArrow && classes.cellWithArrow,
          hasActions && classes.cellWithActions,
          className,
        )}
        index={index}
        model={model}
        row={row}
        body={body}
        size={size}
        hasInput={!!hasInput}
        onCancel={onCancel}
        onSubmit={onSubmit}
      >
        {isFunc(model) && model(row, index, body)}
        {hasArrow && (
          <GridArrow
            open={open}
            toggle={toggle}
          />
        )}
        {hasActions && actionComponent && (
          <div className={clsx(classes.actionContainer, actionClass)}>
            {actionComponent(row)}
          </div>
        )}
      </GridCell>
    );
  });

  const handleClick = () => {
    if (isFunc(onClick)) {
      onClick(row);
    }
  };

  const handleMouseEnter = () => {
    if (isFunc(onMouseEnter)) {
      onMouseEnter(row);
    }
  };
  const handleMouseLeave = () => {
    if (isFunc(onMouseLeave)) {
      onMouseLeave(row);
    }
  };

  return (
    <Fragment>
      <TableRow
        className={clsx(classes.row, actualRowClassName)}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {renderCells}
      </TableRow>
      {canExpand && (
        <TableRow>
          <TableCell colSpan={columns.length} className={classes.collapseCell} size={size}>
            {expand && (
              <Collapse in={open} timeout="auto" unmountOnExit>
                {expand(row)}
              </Collapse>
            )}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}

export default GridRow;
