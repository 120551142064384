import { featureCollection, lineString, point } from '@turf/turf';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { makeGeoJsonPoints } from 'src/helpers';
import palette from 'src/theme/palette';

import {
  NodeModel,
  AcForecastCorrespondenceModel,
  selectCurrentProjectNodes,
  makeHeatMapStyleLayer,
  makeMapStyleEdgeWithScaleLayer,
} from 'src/cluster/common';
import {
  MapModes,
  ViewModes,
  selectAcBalanceMapPageForm,
  selectPolygonsGeojsonEndpoint,
  selectEdgesGeojsonEndpoint,
  selectStopsGeojsonEndpoint,
  useFactorScale,
  useTrafficScales,
  useCorrespondences,
  useBalanceMapData,
} from 'src/cluster/balance-map';
import { useInteractiveLayers } from 'src/cluster/common/hooks/layers';

function makeGeoJsonEdges(flows: AcForecastCorrespondenceModel[], nodes: NodeModel[]) {
  return {
    type: 'geojson',
    data: featureCollection(flows.map((flow) => {
      const nodeFrom = nodes.find((node) => node.id === flow.regionFromId);
      const nodeTo = nodes.find((node) => node.id === flow.regionToId);
      const firstPoint = nodeFrom ? [nodeFrom.lng, nodeFrom.lat] : [0, 0];
      const secondPoint = nodeTo ? [nodeTo.lng, nodeTo.lat] : [0, 0];
      return lineString(
        [firstPoint, secondPoint],
        {
          isStar: true,
          traffic: flow.traffic,
          regionFrom: flow.regionFromName,
          regionTo: flow.regionToName,
          centerId: flow.regionFromId,
          year: flow.year,
        },
      );
    })),
  };
}

const pointExtras = {
  cluster: true,
  clusterMaxZoom: 14,
  clusterRadius: 50,
};

const useBalanceMapStyle = (locale: string, centerId?: number, hoveredId?: string | number) => {
  const mapForm = useSelector(selectAcBalanceMapPageForm);

  const [nodes] = useSelector(selectCurrentProjectNodes);
  const factorValues = useBalanceMapData();
  const correspondences = useCorrespondences();

  const polygonsUrl = useSelector(selectPolygonsGeojsonEndpoint);
  const edgesUrl = useSelector(selectEdgesGeojsonEndpoint);
  const stopsUrl = useSelector(selectStopsGeojsonEndpoint);

  const {
    viewMode,
    mapMode,
  } = mapForm;

  const center = nodes.find((node: NodeModel) => node.id === centerId);

  const [trafficFlowValues, trafficFlowWidthScale, trafficFlowColorScale] = useTrafficScales();

  const factorScale = useFactorScale();

  const sources = {
    center: {
      type: 'geojson',
      data: featureCollection(center ? [
        point(
          [center.lng, center.lat],
          {
            isPoint: true, name: center.name, nodeId: center.id,
          },
        ),
      ] : []),
    },
    points: makeGeoJsonPoints(nodes, pointExtras),
    star: makeGeoJsonEdges(correspondences, nodes),
    polygons: {
      type: 'geojson',
      data: polygonsUrl || featureCollection([]),
    },
    edges: {
      type: 'geojson',
      data: edgesUrl || featureCollection([]),
    },
    stops: {
      type: 'geojson',
      data: stopsUrl || featureCollection([]),
    },
  };

  const trafficFlowFromValues = mapMode === MapModes.group
    ? uniqBy(correspondences.map((flow) => ({
      region: flow.regionFromId,
      value: 1,
    })), 'region')
    : [];

  const trafficFlowToValues = mapMode === MapModes.group
    ? uniqBy(correspondences.map((flow) => ({
      region: flow.regionToId,
      value: 1,
    })), 'region')
    : [];

  const isData = viewMode === ViewModes.data;
  const isForecast = viewMode === ViewModes.forecast;
  const isStar = isForecast && mapMode === MapModes.star;
  const isHeat = isForecast && mapMode === MapModes.heat;
  const isGroup = isForecast && mapMode === MapModes.group;

  const [districtsLayer, ...interactiveLayers] = useInteractiveLayers();

  const layers = [
    districtsLayer,
    makeHeatMapStyleLayer(
      'heat-map-data',
      isData,
      factorValues,
      factorScale,
    ),
    makeHeatMapStyleLayer(
      'heat-map-forecast',
      isHeat,
      trafficFlowValues,
      trafficFlowColorScale,
    ),
    makeHeatMapStyleLayer(
      'heat-map-polygons-from',
      isGroup,
      trafficFlowFromValues,
      [{ from: 0, to: Infinity, value: '#e7d88b' }],
    ),
    makeHeatMapStyleLayer(
      'heat-map-polygons-to',
      isGroup,
      trafficFlowToValues,
      [{ from: 0, to: Infinity, value: palette.error.light }],
    ),
    ...interactiveLayers,
    {
      id: 'hovered',
      filter: ['==', 'region_id', hoveredId || -1],
      layout: {
        visibility: 'visible',
      },
      source: 'polygons',
      type: 'line',
      paint: {
        'line-color': '#ffcb21',
        'line-width': 3.5,
        'line-opacity': 0.6,
      },
    },
    makeMapStyleEdgeWithScaleLayer(
      'star',
      'star',
      isStar,
      [
        'all',
        ['>', 'traffic', 0],
        ['==', 'centerId', centerId ?? -1],
      ],
      trafficFlowWidthScale,
    ),
    {
      id: 'center',
      filter: ['all'],
      layout: {
        visibility: isData || isGroup ? 'none' : 'visible',
      },
      source: 'center',
      type: 'circle',
      paint: {
        'circle-color': palette.common.white,
        'circle-radius': isStar ? 12 : 8,
        'circle-stroke-width': 5,
        'circle-stroke-color': isStar ? '#ffcb21' : palette.error.main,
      },
    },
  ];

  return { sources, layers };
};

export default useBalanceMapStyle;
