import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { SelectMenuOption } from 'src/interfaces';

import { SelectorType } from 'src/cluster/common';

function useGenerateItems(selector: SelectorType): SelectMenuOption[] {
  const options = useSelector(selector);

  return useMemo(() => (
    options.map((opt) => opt.map((value) => ({
      id: `${value.id}`,
      value: value.id,
      label: value.name,
    }))).orJust([])
  ), [options]);
}

export default useGenerateItems;
