import { ChangeEvent, useEffect, useMemo } from 'react';

import {
  AcDataToolbarConfig,
  AcPageBalanceDataForm,
  hasToResetSelect,
  setDefaultValue,
  setMaxValue,
  useAcIntervalOptions,
  useAcMunicipalitiesOptions,
  useAcNodesOptions,
  useAcYearOptions,
  useAcFilterModesOptions,
} from 'src/cluster/common';
import { AcPageBalanceDataFormEnum, useDataScenarios, useRegionTypes } from 'src/cluster/balance-data';
import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';
import { StrictDict } from 'src/types';

const useDataToolbarFields = (
  pageForm: AcPageBalanceDataForm,
  onChangeField: (field: AcPageBalanceDataFormEnum, value: SelectValue | number[]) => void,
): StrictDict<AcPageBalanceDataFormEnum, AcDataToolbarConfig> => {
  const {
    filterMode,
    regions = [],
    municipalities = [],
    year,
    scenarioId,
    intervalId,
    regionType,
  } = pageForm;

  const nodesItems = useAcNodesOptions();
  const municipalitiesItems = useAcMunicipalitiesOptions();
  const yearsItems = useAcYearOptions();
  const scenariosItems = useDataScenarios();
  const modes = useAcFilterModesOptions();
  const regionItems = useRegionTypes();
  const intervalItems = useAcIntervalOptions();

  /** Set default year filter */
  useEffect(() => {
    if (hasToResetSelect(yearsItems, Number(year))) {
      setMaxValue((val: SelectValue) => onChangeField(AcPageBalanceDataFormEnum.year, val), yearsItems);
    }
  }, [yearsItems]);

  /** Set default scenario filter */
  useEffect(() => {
    if (hasToResetSelect(scenariosItems, Number(scenarioId))) {
      setDefaultValue((val: SelectValue) => onChangeField(AcPageBalanceDataFormEnum.scenarioId, val), scenariosItems);
    }
  }, [scenariosItems]);

  /** set default interval filter */
  useEffect(() => {
    if (hasToResetSelect(intervalItems, Number(intervalId))) {
      setDefaultValue((val: SelectValue) => onChangeField(AcPageBalanceDataFormEnum.intervalId, val), intervalItems);
    }
  }, [intervalItems]);

  return useMemo(() => {
    const selectedMunicipality = municipalitiesItems.filter(item => municipalities.includes(Number(item.value)));
    const selectedRegions = nodesItems.filter(item => regions.includes(Number(item.value)));
    const regionValue = regionItems.find(opt => opt.value === regionType);
    const scenarioValue = scenariosItems.find(opt => opt.value === scenarioId);
    const intervalValue = intervalItems.find(opt => opt.value === intervalId);

    return {
      [AcPageBalanceDataFormEnum.year]: {
        label: 'common.captions.year',
        items: yearsItems,
        value: year,
        command: (event: ChangeEvent<{ value: string }>) => {
          const { value } = event.target;
          onChangeField(AcPageBalanceDataFormEnum.year, value);
        },
      },
      [AcPageBalanceDataFormEnum.scenarioId]: {
        label: 'common.captions.scenario',
        multiple: false,
        items: scenariosItems,
        selected: scenarioValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField(AcPageBalanceDataFormEnum.scenarioId, value);
        },
      },
      [AcPageBalanceDataFormEnum.intervalId]: {
        label: 'common.captions.interval',
        multiple: false,
        items: intervalItems,
        selected: intervalValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField(AcPageBalanceDataFormEnum.intervalId, value);
        },
      },
      [AcPageBalanceDataFormEnum.filterMode]: {
        label: 'systems.balance.captions.territorialCell',
        items: modes,
        value: [filterMode],
        command: (event: ChangeEvent<{ value: string }>) => {
          const { value } = event.target;
          onChangeField(AcPageBalanceDataFormEnum.filterMode, value);
        },
      },
      [AcPageBalanceDataFormEnum.regions]: {
        label: 'common.captions.name',
        items: nodesItems,
        selected: selectedRegions,
        command: (values: AutocompleteOption) => {
          const data = Array.isArray(values) ? values.map(item => item.value as number) : [];
          onChangeField(AcPageBalanceDataFormEnum.regions, data);
        },
      },
      [AcPageBalanceDataFormEnum.municipalities]: {
        label: 'common.captions.name',
        items: municipalitiesItems,
        selected: selectedMunicipality,
        command: (values: AutocompleteOption) => {
          const data = Array.isArray(values) ? values.map(item => item.value as number) : [];
          onChangeField(AcPageBalanceDataFormEnum.municipalities, data);
        },
      },
      [AcPageBalanceDataFormEnum.regionType]: {
        label: 'systems.balance.captions.districtType',
        items: regionItems,
        multiple: false,
        selected: regionValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField(AcPageBalanceDataFormEnum.regionType, value);
        },
      },
    };
  }, [pageForm]);
};

export default useDataToolbarFields;
