import React from 'react';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import style from '../index.module.css';
import { TransportTypeDict } from '../../../constants';

const typeIcons: any = {
  air: ICONS_TYPES.transportTypeAir,
  sea: ICONS_TYPES.transportTypeSea,
  rail: ICONS_TYPES.transportTypeRail,
  auto: ICONS_TYPES.transportTypeAuto,
};

interface Props {
  types: TransportTypeDict;
  totalDistance: number;
}

const TransportTypeIcons: React.FC<Props> = ({
  types, totalDistance,
}) => {
  const typePercent: any = {};
  let percentSum = 0;
  Object.keys(types)
    .sort((a, b) => types[b] - types[a])// sort by distance
    .filter(type => !!typeIcons[type])// exclude types without icons
    .forEach((type, index) => {
      typePercent[type] = type === 'air' ? 100 : Math.round((types[type] / totalDistance) * 100);
      percentSum += typePercent[type];
      if (index === Object.keys(type).length) {
        // increase last number to match 100% in total
        typePercent[type] += 100 - percentSum;
      }
    });
  return (
    <span className={style.transportTypeIcons}>
      {Object.keys(typePercent)
        .map((type, index) => (
          <span className={style.transportIconWrapper} key={index}>
            <PIcon {...{
              type: typeIcons[type],
              className: style.transportIcon,
            }} />
            <span className={style.transportIconPercent}>
              {typePercent[type]}
              %
            </span>
          </span>
        ))}
    </span>
  );
};

export default TransportTypeIcons;
