/* eslint-disable max-len */
import React from 'react';

const Italic = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M268.5,352.9c1.1-3.6,2.1-7.2,3-10.9c0.2-0.6,16.2-62.4,33.2-127C334.3,104,341.4,81.7,343,78.3  c5.5-11.4,14.4-19.9,27.2-26.2c11.1-5.5,25.1-9,41.9-10.6c4.9-0.5,9.6-3.2,13-7.6c3-4,4.9-9,4.9-13.9c0-10.9-8.8-19.8-19.8-19.8  H185.9c-10.9,0-19.8,8.9-19.8,19.8v0.7c0,9.7,7,18,16.6,19.5c12.8,2.1,30.2,4.9,44,11.4c7.1,3.3,12.5,7.3,16.2,12  c4.1,5.2,6.2,11.3,6.2,18.3c0,13.3-3.9,26.7-7.7,39.7c-0.8,2.7-1.6,5.5-2.4,8.3c-4.8,17.3-12,43.5-20.2,73.8  c-19,69.8-42.6,156.8-48.6,173.3c-4,11.2-18.9,37.6-70,41.4c-5,0.4-9.7,2.6-13,6.3c-3.4,3.7-5.3,8.4-5.3,13.4v0.1  c0,10.9,8.8,19.8,19.8,19.8h224.4c10.9,0,19.8-8.9,19.8-19.8v-0.2c0-10-7.4-18.4-17.3-19.6c-2-0.3-3.8-0.4-5.8-0.7  c-19.7-2.2-44.1-5.1-54.5-18.5c-4.8-6.3-6.3-14.4-4.7-25C264.4,367,266.5,360.2,268.5,352.9z" />
  </svg>
);

export default Italic;
