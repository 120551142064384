export const AC_PROJECTS_ENDPOINT = 'agglomeration-cluster/projects/';

export const VEHICLE_CLASSES_ENDPOINT = 'vehicle-classes/';
export const VEHICLE_TYPES_ENDPOINT = 'vehicle-types/';
export const AC_YEARS_ENDPOINT = 'balance-years/';
export const AC_SCENARIOS_ENDPOINT = 'scenarios/';
export const AC_INTERVALS_ENDPOINT = 'intervals/';
export const AC_BEHAVIOR_TYPES_ENDPOINT = 'behavior-types/';
export const AC_BASE_SCENARIO_ENDPOINT = 'base-scenario/';
export const AC_MATRIX_FORECAST_ENDPOINT = 'matrix-forecast/';
export const AC_SITES_ENDPOINT = 'sites/';
export const AC_REGIONS_ENDPOINT = 'regions/';
export const AC_COMMUNICATION_TYPES_ENDPOINT = 'communication-types/';
export const AC_REGULAR_TRANSPORTATION_TYPES_ENDPOINT =
  'regular-transportation-types/';
export const AC_ROUTES_VARIANTS_ENDPOINT = 'route-variants/';
export const AC_ROUTES_DIRECTIONS_ENDPOINT = 'route-directions/';
export const AC_BALANCE_ENDPOINT = 'balance/';
export const ROUTES_ENDPOINT = 'routes/';
export const STOPS_ENDPOINT = 'stops/';
export const AC_COPY_REQUEST_ENDPOINT = 'copy-requests/';

export const DISTRICTS_CENTERS_GEOJSON_ENDPOINT = 'region-centers/geojson/';
export const DISTRICTS_GEOJSON_ENDPOINT = 'regions/geojson/';
export const NODES_GEOJSON_ENDPOINT = 'nodes/geojson/';
export const EDGES_GEOJSON_ENDPOINT = 'edges/geojson/';
export const EXPORT_GRAVITY_TUNER = 'gravity-tuner/';
