import { api, forms } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import { downloadFile } from 'src/helpers/file-download';
import { TRAFFIC_PROJECTS_ENDPOINT } from 'src/api-url-schema';

import { PageProjectForm } from 'src/modules/general/projects';
import { asyncSelectCurrentProjectRoutes, MAP_PAGES_FORM_NAME } from 'src/modules/traffic/map';
import { PageRoutesForm } from '../interfaces';

export const findRoutes = (): ThunkActionResult<ReturnType<typeof asyncSelectCurrentProjectRoutes>> => (
  dispatch,
  getState,
) => {
  let state = getState();
  const routesPageForm = forms.selectors[MAP_PAGES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state);
  dispatch(forms.actions[MAP_PAGES_FORM_NAME].changeSomeFields({
    transportScenarioActual: routesPageForm.transportScenario,
    macroeconomicScenarioActual: routesPageForm.macroeconomicScenario,
    yearActual: routesPageForm.year,
  }));
  state = getState();
  return asyncSelectCurrentProjectRoutes(state);
};

export const exportData = (): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const pageProjectForm = forms.selectors.pageTrafficProjectsForm.getFormWithNulls<PageProjectForm>(state);
  const id = pageProjectForm.selectedProject;
  const routesPageForm = forms.selectors[MAP_PAGES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state);
  return dispatch(api.actions.callGet({
    url: `${TRAFFIC_PROJECTS_ENDPOINT}${id}/export/`,
    query: {
      transportScenario: routesPageForm.transportScenarioActual,
      macroeconomicScenario: routesPageForm.macroeconomicScenarioActual,
      year: routesPageForm.yearActual,
    },
    isBinary: true,
  })).then((res: any) => {
    if (res.status === 200) {
      const blob = new Blob([res.data], {
        type: 'octet/stream',
      });
      downloadFile(blob, 'traffic.xlsx');
    }
  });
};
