import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import { AC_EDITOR_GRAPH_VERSION_MODAL_NAME, ModalEditorGraphFilters } from 'src/cluster/editor-common';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.small,
  };
};

export default registerModal(
  AC_EDITOR_GRAPH_VERSION_MODAL_NAME,
  stateToProps,
)(ModalEditorGraphFilters);
