import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { api, forms } from 'redux-restify';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { STATIC_API_NAME, noSlashEnforceUrl } from 'src/static-api-url-schema';

import { Vocabulary } from 'src/types';
import { AppState } from 'src/reducer';
import { AVAILABLE_LOCALES, WithTranslate } from 'src/i18n';

import {
  DEFAULT_LOCALE,
  DEFAULT_VOCABULARY_LOCALE,
  VocabularyContext,
} from '../../constants';

import { LocaleServiceForm } from '../../forms/localeService.form';
import withTranslate from '../../hoc/withTranslate';

export interface OwnProps {
  localeServiceForm: LocaleServiceForm;
  apiActions: any;
}

type Props = OwnProps & WithTranslate;

interface State {
  vocabulary: Vocabulary;
  locale: string;
  localeDataLoaded: boolean;
  localeMessagesLoaded: boolean;
}

class LocalizeServiceProvider extends PureComponent<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
  }

  public constructor(props: Props) {
    super(props);

    this.state = {
      vocabulary: {},
      locale: DEFAULT_LOCALE,
      localeDataLoaded: false,
      localeMessagesLoaded: false,
    };

    this.loadLocales = this.loadLocales.bind(this);
  }

  public componentDidMount() {
    this.loadLocales();
  }

  public componentDidUpdate(prevProps: Props) {
    this.loadLocales(prevProps);
  }

  private loadLocales(prevProps?: Props) {
    const {
      localeServiceForm,
      apiActions,
      setLocale,
    } = this.props;

    const { selectedLocale } = localeServiceForm;

    if (prevProps && selectedLocale === prevProps.localeServiceForm.selectedLocale) {
      return;
    }
    moment.locale(selectedLocale);
    this.setState({
      localeDataLoaded: false,
      localeMessagesLoaded: false,
    });

    const urls: string[] = [];
    if (selectedLocale !== DEFAULT_VOCABULARY_LOCALE) {
      urls.push(`/static/locales/${localeServiceForm.selectedLocale}/vocabulary.json`);
    }
    const requests = urls.map(url => {
      return apiActions.callGet({
        apiName: STATIC_API_NAME,
        url,
        getEntityUrl: noSlashEnforceUrl,
      });
    });
    Promise.all(requests)
      .then((argResponses) => {
        const responses = [...argResponses];
        let vocabularyData = {};
        if (selectedLocale !== DEFAULT_VOCABULARY_LOCALE) {
          vocabularyData = responses.shift().data;
        }
        this.setState((state) => ({
          vocabulary: vocabularyData,
          localeMessagesLoaded: true,
          locale: state.localeDataLoaded ? selectedLocale : DEFAULT_LOCALE,
        }));
        if (AVAILABLE_LOCALES.includes(selectedLocale)) {
          setLocale(selectedLocale);
        }
      });
  }

  public render() {
    const { children } = this.props;
    return (
      <VocabularyContext.Provider value={this.state.vocabulary}>
        {children}
      </VocabularyContext.Provider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  localeServiceForm: forms.selectors.localeServiceForm.getForm<LocaleServiceForm>(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  apiActions: bindActionCreators((api.actions as any), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(LocalizeServiceProvider));
