/* eslint-disable max-len */
import React from 'react';

const Examinations = () => (
  <svg viewBox="0 0 30 30" >
    <path d="M20.2,10.2l-3.5,4.4c0.2,1.3-0.5,2.6-1.7,3.2c-1.2,0.6-2.6,0.4-3.5-0.6c-0.9-1-1.2-2.4-0.6-3.6  c0.6-1.2,1.9-1.9,3.2-1.7l4.3-3.5c0.5-0.5,1.3-0.5,1.7,0S20.6,9.7,20.2,10.2z M0,14.3c-0.2,3.9,1.2,7.8,4,10.5  c0.5,0.5,1.4,0.5,1.9,0l2-2.1c0.3-0.3,0.4-0.7,0.4-1.1S8,21,7.7,20.7c-0.5-0.3-1.2-0.3-1.6,0.2L5,22c-1.3-1.6-2.1-3.6-2.3-5.7h1.8  c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7v-0.6c0-0.6-0.4-1-1-1H2.7C2.9,11.6,3.7,9.6,5,8l1.3,1.3c0.4,0.4,1,0.4,1.4,0  l0.4-0.4c0.2-0.2,0.3-0.5,0.3-0.7S8.3,7.6,8.1,7.4L6.8,6.1c1.6-1.3,3.6-2.1,5.6-2.4v1.9c0,0.6,0.4,1,1,1H14c0.6,0,1-0.4,1-1V3.8  c5,0.6,9,4.6,9.7,9.7c1,0.7,1.9,1.5,2.7,2.5c0-0.3,0-0.6,0-0.9c0-7.6-5.9-13.8-13.3-14S0.4,6.7,0,14.3L0,14.3z M14.8,23.2h-3.5  c-0.1,0-0.3,0.1-0.4,0.2l-2.9,3c-0.2,0.2-0.3,0.6-0.2,0.9c0.1,0.3,0.4,0.5,0.7,0.5H16c-0.3-0.6-0.5-1.3-0.5-2  c-0.1-0.9,0-1.7,0.2-2.6L14.8,23.2z M25.9,29c5.2-0.8,4.2-5.3,3.4-7.3c-0.1-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.3,0.1-0.4,0.2  c-0.5,0.7-1.1,1.3-1.9,1.7c0.5-5-2.5-7.1-4-7.9c-0.1-0.1-0.3-0.1-0.5,0S22,15.6,22,15.8c0.1,2.1-1.8,4.8-2.9,6.2  c-0.8,1-1.2,2.2-1.1,3.5c0.3,2.9,3.1,3.4,4,3.5h0c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.2c-0.2-1.5,0.4-3,1.5-4  c0.1-0.1,0.3-0.1,0.5,0c1.1,1,1.6,2.5,1.5,4c0,0.1,0,0.1,0.1,0.2C25.8,29,25.9,29,25.9,29L25.9,29z" />
  </svg>
);

export default Examinations;
