import * as format from 'src/helpers';

import { EMAIL_REGEXP } from 'src/constants';
import { StrictDict } from 'src/types';

export enum InputTypes {
  text = 'text',
  int = 'int',
  percent = 'percent',
  float = 'float',
  multi = 'multi',
  wysiwyg = 'wysiwyg',
  password = 'password',
  phone = 'phone',
  phoneWithExt = 'phoneWithExt',
  money = 'money',
  moneyNumber = 'moneyNumber',
  email = 'email',
  date = 'date',
  url = 'url',
  color = 'color',
  search = 'search',
  time = 'time',
}

export const INPUT_TYPES = { ...InputTypes };

export const INNER_INPUT_TYPES: Partial<StrictDict<InputTypes, InputTypes>> = {
  [INPUT_TYPES.text]: INPUT_TYPES.text,
  [INPUT_TYPES.int]: INPUT_TYPES.text,
  [INPUT_TYPES.percent]: INPUT_TYPES.text,
  [INPUT_TYPES.float]: INPUT_TYPES.text,
  [INPUT_TYPES.password]: INPUT_TYPES.password,
  [INPUT_TYPES.phone]: INPUT_TYPES.text,
  [INPUT_TYPES.phoneWithExt]: INPUT_TYPES.text,
  [INPUT_TYPES.money]: INPUT_TYPES.text,
  [INPUT_TYPES.moneyNumber]: INPUT_TYPES.text,
  [INPUT_TYPES.email]: INPUT_TYPES.text,
  [INPUT_TYPES.date]: INPUT_TYPES.text,
  [INPUT_TYPES.url]: INPUT_TYPES.text,
  [INPUT_TYPES.color]: INPUT_TYPES.text,
  [INPUT_TYPES.search]: INPUT_TYPES.text,
  [INPUT_TYPES.time]: INPUT_TYPES.text,
};

export const VALIDATION_FORMATS: Partial<StrictDict<InputTypes, RegExp>> = {
  [INPUT_TYPES.email]: EMAIL_REGEXP,
  [INPUT_TYPES.url]: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  [INPUT_TYPES.color]: /^#?((\d|[a-f]){3}|(\d|[a-f]){6})?$/i,
  [INPUT_TYPES.time]: /([0-1]\d[0-5]\d)|(2[0-3][0-5]\d)/,
};

export const ERROR_TYPES: Record<string, string> = {
  email: 'Введите корректный email!',
  color: 'Введите корректный hex-код!',
  url: 'Введите корректный url!',
  format: 'Вы ввели неправильное значение!',
  maxLen: 'Максимальная длина: ',
  minLen: 'Минимальная длина: ',
  required: 'Это поле обязательно!',
};

export const FLOAT_MAX_FRACTIONALS = 20;
export const MONEY_MAX_FRACTIONALS = 2;
export const formatToFunctions: {
  [key: string]: Function;
} = {
  [INPUT_TYPES.float]: (value: string, maxFractionals: number = FLOAT_MAX_FRACTIONALS) => {
    const valueString = value?.toString();
    let number = format.toNumber(value, maxFractionals);
    if (maxFractionals > 0 && (valueString.endsWith(',') || valueString.endsWith('.'))) {
      number = `${number},`;
    }
    return number;
  },
  [INPUT_TYPES.int]: (value: string) => formatToFunctions[INPUT_TYPES.float](value, 0),
  [INPUT_TYPES.percent]: (value: string) => `${formatToFunctions[INPUT_TYPES.float](value, 0)}%`,
  [INPUT_TYPES.money]: (value: string) => formatToFunctions[INPUT_TYPES.float](value, MONEY_MAX_FRACTIONALS),
  [INPUT_TYPES.moneyNumber]: (value: string) => formatToFunctions[INPUT_TYPES.float](value, MONEY_MAX_FRACTIONALS),
  [INPUT_TYPES.phone]: format.toPhone,
  [INPUT_TYPES.phoneWithExt]: format.toPhone,
  [INPUT_TYPES.date]: format.toDate,
  [INPUT_TYPES.color]: (value: string) => {
    return value.replace(/#/ig, '');
  },
  [INPUT_TYPES.time]: format.toTime,
};

export const formatFromFunctions: {
  [key: string]: Function;
} = {
  [INPUT_TYPES.float]: (value: string, toType = true, maxFractionals: number = FLOAT_MAX_FRACTIONALS) => {
    const formatted = format.fromNumber(value, maxFractionals);
    if (!toType) return formatted;
    return formatted === '-' ? 0 : +formatted;
  },
  [INPUT_TYPES.int]: (value: string, toType = true) => {
    return formatFromFunctions[INPUT_TYPES.float](value, toType, 0);
  },
  [INPUT_TYPES.percent]: (value: string, toType = true) => {
    return formatFromFunctions[INPUT_TYPES.float](value.replace('%', ''), toType, 0);
  },
  [INPUT_TYPES.money]: (value: string) => formatFromFunctions[INPUT_TYPES.float](value, false, MONEY_MAX_FRACTIONALS),
  [INPUT_TYPES.moneyNumber]: (value: string, toType = true) => {
    return formatFromFunctions[INPUT_TYPES.float](value, toType, MONEY_MAX_FRACTIONALS);
  },
  [INPUT_TYPES.phone]: format.fromPhone,
  [INPUT_TYPES.phoneWithExt]: (value: string) => format.fromPhone(value),
  [INPUT_TYPES.date]: format.fromDate,
  [INPUT_TYPES.color]: (value: string) => `#${value.replace(/#/ig, '')}`,
  [INPUT_TYPES.time]: format.fromTime,
};

export const formatLengthFunctions: {
  [key: string]: Function;
} = {
};

const intRegexp = /[\d\u002d]/;
const floatRegexp = /[\d\u002c\u002d\u002e]/;

export const includeForTypes: {
  [key: string]: RegExp;
} = {
  [INPUT_TYPES.int]: intRegexp,
  [INPUT_TYPES.percent]: intRegexp,
  [INPUT_TYPES.float]: floatRegexp,
  [INPUT_TYPES.phone]: /\d/,
  [INPUT_TYPES.phoneWithExt]: /\d/,
  [INPUT_TYPES.money]: floatRegexp,
  [INPUT_TYPES.moneyNumber]: floatRegexp,
  [INPUT_TYPES.date]: /\d/,
  [INPUT_TYPES.color]: /[\da-f]/i,
  [INPUT_TYPES.time]: /\d/,
};

export const excludeForTypes: {
  [key: string]: RegExp;
} = {};

export const ROW_HEIGHT = 18;

export const DEFAULT_MAX_ROWS = 5;

export const FULL_ZERO_TIME = formatToFunctions[INPUT_TYPES.time]('000000000');

export const checkTime = (time: string) => {
  const fullTime = `${time}${FULL_ZERO_TIME.substr(time.length)}`;
  const fullTimeSplit = fullTime.split(':');
  const maxHours = 24;
  const maxOther = 60;
  const validateTimeArray = (new Array(fullTimeSplit.length)).fill(maxOther);
  validateTimeArray[0] = maxHours;

  return fullTimeSplit.reduce((memo: boolean, curr: string, i: number) => {
    return memo && curr.length >= 0 && curr < validateTimeArray[i];
  }, true);
};
