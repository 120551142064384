import React from 'react';

const ArrowRightIcon = () => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.40835 0.50934L4.40836 0.509327C4.75316 0.163601 5.31177 0.16353 5.65661 0.509282C6.00178 0.855375 6.00205 1.41633 5.65656 1.76275L3.85751 3.56591L3.43191 3.99248H4.03449H11.312C11.7986 3.99248 12.1944 4.3883 12.1944 4.87845C12.1944 5.36843 11.7983 5.76442 11.312 5.76442H4.01378H3.4112L3.8368 6.191L5.65656 8.01492C6.00174 8.36102 6.00181 8.92227 5.65661 9.26839C5.3118 9.61411 4.75348 9.61439 4.40836 9.26834L4.40835 9.26833L0.39842 5.24831C0.200568 5.04996 0.200486 4.72779 0.39842 4.52936L0.221421 4.3528L0.39842 4.52935L4.40835 0.50934Z"
      fill="currentColor"
      stroke="transparent"
      strokeWidth="0.5"
    />
  </svg>
);

export default ArrowRightIcon;
