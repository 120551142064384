export const CHECK_RED = 'red';
export const CHECK_BLACK = 'black';

export const CHECK_COLORS = {
  [CHECK_RED]: CHECK_RED,
  [CHECK_BLACK]: CHECK_BLACK,
};

export const CHECK_BOX = 'checkbox';
export const CHECK_TOGGLE = 'toggle';

export const CHECK_VIEW_TYPES = {
  [CHECK_BOX]: CHECK_BOX,
  [CHECK_TOGGLE]: CHECK_TOGGLE,
};
