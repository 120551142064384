import React, { FC, Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import { App } from 'src/app';
import { LocalizeServiceProvider } from 'src/i18n';

import { AppState } from 'src/reducer';
import theme from 'src/theme';

const CloseButton = ({ closeToast }: any) => (
  <PIcon
    type={ICONS_TYPES.clear}
    onClick={closeToast}
    size={22}
  />
);

interface Props {
  isAuthenticated: boolean;
  store: AppState;
  history: any;
  getPermissions(): void;
}

const Root: FC<Props> = (props) => {
  const {
    isAuthenticated,
    store,
    history,
    getPermissions,
  } = props;

  useEffect(() => {
    if (isAuthenticated) {
      getPermissions();
    }
  }, [isAuthenticated]);

  return (
    <Suspense fallback="...">
      <DndProvider backend={HTML5Backend}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer closeButton={<CloseButton />} />
          <Provider store={store}>
            <LocalizeServiceProvider>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </LocalizeServiceProvider>
          </Provider>
        </MuiThemeProvider>
      </DndProvider>
    </Suspense>
  );
};

export default Root;
