import React, { ReactNode, useMemo } from 'react';

import i18n from 'src/i18n';
import { DataGridMultiColumnProps } from 'src/components/common/DataGrid';
import {
  VehicleIcon,
  useGetIdFromList,
  selectTransportationTypes,
  selectCommunicationTypes,
} from 'src/cluster/common';
import {
  AcRegRoutesResultsModel,
  TopColumns,
  selectRegSeasons,
  useVehicleClassesOptions,
} from 'src/cluster/editor-table';

import { useVehicleTypeName } from 'src/cluster/editor-map';
import { SelectValue } from 'src/interfaces';

const useRegistryRouteConfig = (
  topColumns: TopColumns[],
  classes: Record<string, string>,
): {
    type: TopColumns;
    title?: ReactNode;
    children?: DataGridMultiColumnProps[];
    headClassName?: string;
    className?: string;
    model?: (
    row: AcRegRoutesResultsModel,
    index: number,
    data: AcRegRoutesResultsModel[]
    ) => ReactNode;
  }[][] => {
  const getVehicleTypeName = useVehicleTypeName();
  const transportTypesOptions = useGetIdFromList(selectTransportationTypes);
  const communicationTypeOptions = useGetIdFromList(selectCommunicationTypes);
  const seasonOptions = useGetIdFromList(selectRegSeasons);
  const vehicleClassesOptions = useVehicleClassesOptions();

  const mergeVehicleClassesByName = useMemo(() => {
    const options: {label: ReactNode; value: SelectValue; id: number[]}[] = [];
    vehicleClassesOptions.forEach((item) => {
      const menuIndex = options.findIndex((nameItem) => nameItem.value === item.value);
      if (menuIndex !== -1) {
        options[menuIndex].id.push(parseInt(item.id, 10));
        return undefined;
      }

      options.push({ ...item, id: [parseInt(item.id, 10)] });
      return undefined;
    });

    return options;
  }, [vehicleClassesOptions]);

  return useMemo(() => {
    const translate = 'modules.registries.columns.';
    const getTitleShort = (vc: SelectValue) => `modules.registries.vehicleClasses.${vc}Short`;

    const vehicleClassBody = mergeVehicleClassesByName.map((item) => ({
      type: TopColumns.vehicleClasses,
      className: classes.bigSize,
      model: (result: AcRegRoutesResultsModel) => {
        return (
          result.vehicleClasses.find(
            (vc) => item.id.includes(vc.vehicleClassId),
          )?.count || 0
        );
      },
    }));

    let columns: {
      type: TopColumns;
      title?: ReactNode;
      children?: DataGridMultiColumnProps[];
      headClassName?: string;
      className?: string;
      model?: (
        row: AcRegRoutesResultsModel,
        index: number,
        data: AcRegRoutesResultsModel[]
      ) => ReactNode;
    }[] = [
      {
        type: TopColumns.vehicleType,
        title: i18n.t(translate + TopColumns.vehicleType),
        className: classes.first,
        model: (result: AcRegRoutesResultsModel) => (
          <VehicleIcon type={getVehicleTypeName(result.vehicleTypeId)} />
        ),
      },
      {
        type: TopColumns.routeNumber,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.routeNumber),
        model: (result: AcRegRoutesResultsModel) => result.routeNumber,
      },
      {
        type: TopColumns.routeLongName,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.routeLongName),
        model: (result: AcRegRoutesResultsModel) => result.routeLongName,
      },
      {
        type: TopColumns.regularTransportationType,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.regularTransportationType),
        model: (result: AcRegRoutesResultsModel) => transportTypesOptions(result.regularTransportationType),
      },
      {
        type: TopColumns.communicationType,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.communicationType),
        model: (result: AcRegRoutesResultsModel) => communicationTypeOptions(result.communicationType),
      },
      {
        type: TopColumns.season,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.season),
        model: (result: AcRegRoutesResultsModel) => seasonOptions(result.season),
      },
      {
        type: TopColumns.vehicleClasses,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.vehicleClasses),
        children: mergeVehicleClassesByName.map(({ value }) => ({
          title: i18n.t(getTitleShort(value)),
          headClassName: classes.bigSize,
        })),
      },
      {
        type: TopColumns.carrier,
        title: i18n.t(translate + TopColumns.carrier),
        className: classes.lastColumn,
        model: (result: AcRegRoutesResultsModel) => result.carrier,
      },
    ];

    columns = columns.filter((col) => topColumns.includes(col.type));

    const bodyColumns = [...columns];
    const index = bodyColumns.findIndex(
      (col) => col.type === TopColumns.vehicleClasses,
    );
    if (index !== -1) {
      bodyColumns.splice(index, 1, ...vehicleClassBody);
    }

    return [columns, bodyColumns];
  }, [topColumns, getVehicleTypeName]);
};

export default useRegistryRouteConfig;
