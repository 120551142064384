import { createSelector } from 'reselect';
import { Maybe, Just, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  createFormSelector,
  isArray,
  isNumber,
} from 'src/helpers';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_DATA_MODEL_NAME,
  AC_DATA_REGION_TYPES_MODEL_NAME,
  AC_DATA_PAGE_FORM_NAME,
  BalanceFilterModes,
  AcBalanceDataModel,
  AcBalanceScenariosModel,
  AcRegionTypeModel,
  AcPageBalanceDataForm,
  isAcScenariosModel,
} from 'src/cluster/common';

import { selectMatrixForecastParams, selectAcProjectListForm } from 'src/cluster/common/store/selectors';

export const selectAcDataEntity = createEntitySelector<AcBalanceDataModel>(AC_DATA_MODEL_NAME);
export const selectAcDataRegionEntity = createEntitySelector<AcRegionTypeModel>(AC_DATA_REGION_TYPES_MODEL_NAME);
export const selectAcDataScenariosEntity = createEntitySelector<AcBalanceScenariosModel>(AC_SCENARIOS_MODEL_NAME);

export const selectAcDataPageForm = createFormSelector<AcPageBalanceDataForm>(AC_DATA_PAGE_FORM_NAME);

export const selectPageForm = createSelector(
  selectAcDataPageForm,
  (pageDataForm: AcPageBalanceDataForm) => pageDataForm,
);

export const selectBalanceData = createSelector(
  [
    selectAcProjectListForm,
    selectAcDataPageForm,
    selectMatrixForecastParams,
    selectAcDataEntity,
  ],
  (
    pageProjectsForm,
    pageDataForm,
    matrixForecastParams,
    dataEntities,
  ): [Maybe<Model<AcBalanceDataModel>[]>, number] => {
    const {
      currentPage,
      pageSize,
      regions,
      filterMode,
      regionType,
      municipalities,
      year,
      scenarioId,
      intervalId,
    } = pageDataForm;
    const { selectedProject } = pageProjectsForm;
    const [matrixForecastId] = matrixForecastParams;

    if (!isNumber(selectedProject) || !isNumber(year) || !isNumber(scenarioId) || !isNumber(intervalId)) {
      return [Nothing(), 0];
    }
    const dataApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        page: currentPage,
        pageSize,
        regionId: filterMode === BalanceFilterModes.node ? regions : undefined,
        municipalityId: filterMode === BalanceFilterModes.municipality ? municipalities : undefined,
        regionType,
        year,
        scenarioId,
        intervalId,
        matrixForecastId,
      },
    };
    const entities = dataEntities.getArray(dataApiConfig);
    if (!isArray(entities)) {
      return [Nothing(), 0];
    }
    const count = dataEntities.getCount(dataApiConfig);

    return [Just(entities), count];
  },
);

export const selectCurrentProjectRegionTypes = createSelector(
  [
    selectAcProjectListForm,
    selectAcDataPageForm,
    selectAcDataRegionEntity,
  ],
  (pageProjectsForm, pageDataForm, regionTypesEntities): [AcRegionTypeModel[], boolean] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
      ];
    }
    const yearsApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      regionTypesEntities.getArray(yearsApiConfig),
      regionTypesEntities.getIsLoadingArray(yearsApiConfig),
    ];
  },
);

export const selectDataScenarios = createSelector(
  [
    selectAcProjectListForm,
    selectAcDataPageForm,
    selectAcDataScenariosEntity,
  ],
  (pageProjectsForm, pageDataForm, scenariosEntities): [AcBalanceScenariosModel[], boolean, number] => {
    if (!pageProjectsForm.selectedProject || !pageDataForm.year) return [[], false, 0];

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
      filter: {
        year: pageDataForm.year,
      },
    };

    const scenarios = scenariosEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios) || !isAcScenariosModel(scenarios[0])) return [[], false, 0];

    return [
      scenarios,
      scenariosEntities.getIsLoadingArray(scenariosApiConfig),
      scenariosEntities.getCount(scenariosApiConfig),
    ];
  },
);
