import { NodeModel } from 'src/modules/cities/routes/constants';

import { NODES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: NODES_ENDPOINT,
  parent: 'citiesCalculations',
  idField: (model: NodeModel) => `${model.lat}_${model.lng}`,
  defaults: {
    id: undefined,
    lat: undefined,
    lng: undefined,
    name: undefined,
  },
  convertToCamelCase: false,
  allowIdRequests: false,
  pagination: false,
};
