import React, { FC } from 'react';
import get from 'lodash/get';

import { WithTranslate } from 'src/i18n';
import { checkPermissionForRoute, MenuRenderers } from 'src/pages';
import { AuthInfoForm } from 'src/auth/forms/authInfo.form';

import CollapseMenuItem from 'src/pages/components/CollapseMenuItem';
import { union } from 'lodash';
import urlSchema, {
  urlSchemaConfig,
  HEADER_SHOW,
  HEADER_HIDE,
  getUrl,
} from '../../url-schema';

import SideMenuItem from '../SideMenuItem';

interface Props extends WithTranslate {
  className?: string;

  basePath?: string[];
  menuRenderers: MenuRenderers;

  authInfoForm: AuthInfoForm;
  match: any;
}

const SideMenu: FC<Props> = (props) => {
  const {
    className,
    basePath = [],
    menuRenderers = {},
    authInfoForm,
  } = props;

  const currentSchemaConfig = get(
    urlSchemaConfig,
    ([] as string[]).concat(basePath)
      .map(name => [name, 'pages'])
      .reduce((a, b) => a.concat(b), []),
    urlSchemaConfig,
  );
  const currentBaseUrl = get(urlSchema, basePath, urlSchema);

  const routeToRenderedKeysReduceFunc = (schemaConfig: any, prevKey?: any) => (memo: any, key: any) => {
    const currentConfig = schemaConfig[key];
    // For dynamic extract urls for scheme
    if (!currentConfig) return memo;
    const currentUrl = currentBaseUrl[key] ? getUrl(currentBaseUrl[key]) : undefined;
    if (
      !checkPermissionForRoute(currentUrl, authInfoForm.permissions, currentConfig.permissions)
      || currentConfig.header === HEADER_HIDE
    ) {
      return memo;
    }

    if (currentConfig.header === HEADER_SHOW) {
      return [...memo, prevKey ? [].concat(prevKey, key) : key];
    }
    return memo;
  };
  const headerKeysToRender = Object.keys(currentSchemaConfig).reduce(
    routeToRenderedKeysReduceFunc(currentSchemaConfig),
    [],
  );

  return (
    <nav className={className}>
      {headerKeysToRender.map((key: string) => {
        const renderer = get(menuRenderers, key);
        if (!renderer) return null;

        const { icon, label } = renderer;
        let headerSubKeysToRender: string[] = Object.keys(renderer.pages || {}).reduce(
          routeToRenderedKeysReduceFunc(currentSchemaConfig[key].pages),
          [],
        );
        // @ts-ignore
        const withDynamicsPages = Object.keys(renderer.pages || {}).filter(page => renderer?.pages[page]?.name);
        headerSubKeysToRender = union(headerSubKeysToRender, withDynamicsPages);
        return headerSubKeysToRender.length > 0
          ? (
            <CollapseMenuItem
              key={key}
              label={label}
              baseUrl={currentBaseUrl}
              navKey={key}
              subKeys={headerSubKeysToRender}
              pages={renderer.pages}
            />
          )
          : (
            <SideMenuItem
              key={key}
              navKey={key}
              baseUrl={currentBaseUrl}
              label={label}
              icon={icon}
            />
          );
      })}
    </nav>
  );
};

export default SideMenu;
