import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RestifyFormActions } from 'redux-restify';
import { startAgglomerationCalculation } from '../../actions';
import { PageAgglomerationCalculationForm } from '../../interfaces';

interface Handlers {
  handleTrafficLimitChange: (value: any) => void;
  handleMaxAlternativeRoutesChange: (value: any) => void;
  handleStartCalculationClick: () => void;
}

export const useHandlers = (form: PageAgglomerationCalculationForm, formActions: RestifyFormActions): Handlers => {
  const dispatch = useDispatch();

  const handleTrafficLimitChange = useCallback(
    (value) => dispatch(formActions.changeField('trafficLimit', value)),
    [dispatch, formActions],
  );

  const handleMaxAlternativeRoutesChange = useCallback(
    (value) => dispatch(formActions.changeField('maxAlternativeRoutes', value)),
    [dispatch, formActions],
  );

  const handleStartCalculationClick = useCallback(
    () => dispatch(startAgglomerationCalculation()),
    [dispatch, formActions],
  );

  return {
    handleTrafficLimitChange,
    handleMaxAlternativeRoutesChange,
    handleStartCalculationClick,
  };
};
