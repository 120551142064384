import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';
import { withTranslate } from 'src/i18n';

import {
  routeEditorFormSelectors, addVariant, changeRouteNumber, createRoute, updateRoute, copyRoute,
} from 'src/cluster/editor-map';

import EditorRoute from './EditorRoute';

const mapStateToProps = (state: AppState) => ({
  isDirty: routeEditorFormSelectors.getIsDirty(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  changeRouteNumber: (value: string) => {
    dispatch(changeRouteNumber(value));
  },
  addVariant: () => {
    dispatch(addVariant());
  },
  copyRoute: () => {
    dispatch(copyRoute());
  },
  saveRoute: (create = false) => {
    dispatch(create ? createRoute() : updateRoute());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(EditorRoute));
