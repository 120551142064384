import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import { selectDashScenarios } from 'src/cluster/dashboard-common';

const useAcDashScenarioOptions = (): SelectMenuOption[] => {
  const scenarios = useSelector(selectDashScenarios).orJust([]);
  return scenarios.map((scenario) => ({
    id: `${scenario.id}`,
    value: scenario.id,
    label: scenario.name,
  }));
};

export default useAcDashScenarioOptions;
