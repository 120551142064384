import { maxBy } from 'lodash';

import { SelectMenuOption } from 'src/interfaces';
import { isNumber, isString } from 'src/helpers';
import i18n from 'src/i18n';

export function useGenerateOption(option: SelectMenuOption | string | number, translate: string) {
  return isNumber(option) || isString(option) ? {
    id: `${option}`,
    value: option,
    label: translate ? i18n.t(translate + option) : option,
  } : option;
}

export function useGenerateOptions(options: SelectMenuOption[] | string[] | number[], translate = '') {
  return [...options].map(opt => useGenerateOption(opt, translate));
}

export function hasToResetSelect(items: SelectMenuOption[], current: number) {
  return items.length === 0 ? !!current : (!current || !items.find(item => item.value === current));
}

export function setDefaultValue(setItem: Function, items: SelectMenuOption[]) {
  if (items.length > 0) {
    setItem(items[0].value);
  } else {
    setItem([undefined]);
  }
}

export function setMaxValue(setItem: Function, items: SelectMenuOption[]) {
  if (items.length > 0) {
    const max = maxBy(items, (i) => i.value);
    setItem(max ? max.value : undefined);
  } else {
    setItem([undefined]);
  }
}

export function getSelectValue<T>(value: T): [T] | undefined {
  return value === undefined ? undefined : [value];
}

export function arrayToHex(r: number, g: number, b: number) {
  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function hexToArray(hex: string, alpha = 1): [number, number, number, number] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    Number.parseInt(result[1], 16),
    Number.parseInt(result[2], 16),
    Number.parseInt(result[3], 16),
    alpha * 255,
  ] : [0, 0, 0, alpha * 255];
}
