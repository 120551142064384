import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import { ModalErrorDataShow } from '../../view/components';
import { ERROR_DATA_SHOW_MODAL } from '../../constants';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.medium,
  };
};

export default registerModal(
  ERROR_DATA_SHOW_MODAL,
  stateToProps,
)(ModalErrorDataShow);
