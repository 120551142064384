export enum TopColumns {
  vehicleType = 'vehicleType',
  routeNumber = 'routeNumber',
  routeLongName = 'routeLongName',
  regularTransportationType = 'regularTransportationType',
  communicationType = 'communicationType',
  season = 'season',
  vehicleClasses = 'vehicleClasses',
  carrier = 'carrier',
}

export enum BottomColumns {
  variantNumber = 'variantNumber',
  variantName = 'variantName',
  intervals = 'intervals',
  tariff = 'tariff',
  lengthForward = 'lengthForward',
  lengthBackward = 'lengthBackward',
  tripNumberForward = 'tripNumberForward',
  tripNumberBackward = 'tripNumberBackward',
}

export enum AcRegistryFiltersEnum {
  vehicleTypeId = 'vehicleTypeId',
  routeNumber = 'routeNumber',
  regularTransportationType = 'regularTransportationType',
  communicationType = 'communicationType',
  season = 'season',
  carrier = 'carrier',
}
