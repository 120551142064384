import { AC_SITES_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_SITES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
