import { createSelector } from 'reselect';

import {
  api, forms, GetByIdConfig, RestifyModel,
} from 'redux-restify';

import { AppState } from 'src/reducer';
import { NodeModel, RouteModel } from 'src/modules/general/common';
import { Writable } from 'src/types';
import { PageProjectForm } from 'src/modules/general/projects';

import { PageCalculationsRoutesForm } from 'src/modules/mth/common/interfaces';
import {
  CALCULATIONS_PAGES_FORM_NAME, EDGE_TYPES,
  TRANSPORT_TYPES,
  VIEW_MODES,
} from 'src/modules/mth/common/constants';
import {
  EdgeRoutesForm,
  PageAgglomerationCalculationForm,
} from 'src/modules/mth/calculations/interfaces';
import {
  EdgeModel,
  EdgeRoute,
  EdgeRoutesModel,
  OvershotModel,
  RouteEdgeModel,
  RoutesAndOvershotModel,
} from '../../common/interfaces';
import {
  EDGE_ROUTES_FORM_NAME,
  EDGE_ROUTES_MODEL_NAME,
  ROUTES_AND_OVERSHOT_CALCULATION_MODEL_NAME,
  ROUTES_PAGES_FORM_NAME,
  CALCULATIONS_MODEL_NAME,
  EDGES_CALCULATIONS_MODEL_NAME,
  NODES_CALCULATIONS_MODEL_NAME,
} from '../constants';

function isEmptyObject(obj: object) {
  return JSON.stringify(obj) === JSON.stringify({});
}

function findSubstring(substring?: string) {
  return (route: EdgeRoute) => {
    const search = substring && substring.toLowerCase();
    if (!search || search === '') {
      return true;
    }
    return route.origin.toLowerCase().includes(search) || route.destination.toLowerCase().includes(search);
  };
}

function hasCorrectRouteParams(
  pageProjectForm: PageProjectForm,
  pageCalculationsRoutesForm: PageCalculationsRoutesForm,
) {
  return (
    typeof pageProjectForm.selectedCalculation === 'number'
    && pageCalculationsRoutesForm.viewMode === VIEW_MODES.correspondences
    && pageCalculationsRoutesForm.actualFrom !== undefined
    && pageCalculationsRoutesForm.actualTo !== undefined
  );
}

function getRoutesApiConfig(
  pageProjectForm: PageProjectForm,
  pageCalculationForm: PageAgglomerationCalculationForm,
  pageRoutesForm: PageCalculationsRoutesForm,
): GetByIdConfig | undefined {
  if (!hasCorrectRouteParams(pageProjectForm, pageRoutesForm)) {
    return undefined;
  }

  const {
    trafficLimit,
    maxAlternativeRoutes,
  } = pageCalculationForm;

  const {
    actualFrom,
    actualTo,
  } = pageRoutesForm;

  return {
    parentEntities: {
      [CALCULATIONS_MODEL_NAME]: Number(pageProjectForm.selectedCalculation),
    },
    query: {
      firstNode: actualFrom,
      secondNode: actualTo,
      trafficLimit: trafficLimit / 100,
      maxAlternativeRoutes,
    },
  };
}

export const selectEndpoint = createSelector(
  (state) => api.selectors.entityManager.agglomerationCalculations.getEndpoint(state),
  (endpoint) => endpoint,
);

export const selectCurrentTransportType = createSelector(
  (state: AppState) => (
    forms.selectors[ROUTES_PAGES_FORM_NAME].getFormWithNulls<PageCalculationsRoutesForm>(state)
  ),
  (pageRoutesForm) => String(pageRoutesForm.transportType),
);

export const selectCurrentCalculationNodes = createSelector(
  [
    (state: AppState) => forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => api.selectors.entityManager[NODES_CALCULATIONS_MODEL_NAME].getEntities<NodeModel>(state),
  ],
  (pageProjectForm, nodesEntities): [RestifyModel<NodeModel>[], boolean] => {
    if (!pageProjectForm.selectedCalculation) {
      return [[], false];
    }
    const nodesApiConfig = {
      parentEntities: {
        [CALCULATIONS_MODEL_NAME]: Number(pageProjectForm.selectedCalculation),
      },
    };
    const currentNodes = nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A');
    const isLoading = nodesEntities.getIsLoadingArray(nodesApiConfig);
    return [currentNodes, isLoading];
  },
);

export const selectSelectedRouteEdges = createSelector(
  [
    (state: AppState) => forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => (
      forms.selectors[ROUTES_PAGES_FORM_NAME].getFormWithNulls<PageCalculationsRoutesForm>(state)
    ),
    (state: AppState) => api.selectors.entityManager[EDGES_CALCULATIONS_MODEL_NAME].getEntities<RouteEdgeModel>(state),
  ],
  (pageProjectForm, pageRoutesForm, edgeEntities): [RestifyModel<RouteEdgeModel>[], boolean] => {
    if (!pageProjectForm.selectedCalculation || !pageRoutesForm.selectedEdges) {
      return [[], false];
    }
    const edgesApiConfig = {
      parentEntities: {
        [CALCULATIONS_MODEL_NAME]: Number(pageProjectForm.selectedCalculation),
      },
      filter: {
        id: pageRoutesForm.selectedEdges,
      },
    };
    const selectedEdges = edgeEntities.getArray(edgesApiConfig);
    const isLoading = edgeEntities.getIsLoadingArray(edgesApiConfig);
    return [selectedEdges, isLoading];
  },
);

const getRoutesSelectorsConst = [
  (state: AppState) => forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
  (state: AppState) => (
    forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageAgglomerationCalculationForm>(state)
  ),
  (state: AppState) => (forms.selectors[ROUTES_PAGES_FORM_NAME].getFormWithNulls<PageCalculationsRoutesForm>(state)),
  (state: AppState) => api.selectors.entityManager[ROUTES_AND_OVERSHOT_CALCULATION_MODEL_NAME]
    .getEntities<RoutesAndOvershotModel>(state),
  (state: AppState) => state?.api?.entityManager?.[ROUTES_AND_OVERSHOT_CALCULATION_MODEL_NAME]?.loadErrorEntities,
] as const;

const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

export const selectCurrentProjectRoutes = createSelector(
  getProjectRoutesSelectors,
  (
    pageProjectForm, pageCalculationsForm, pageRoutesForm, routesEntities, loadErrorEntities,
  ): [RouteModel[], OvershotModel[], boolean, boolean] => {
    const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageCalculationsForm, pageRoutesForm);
    if (!routesApiConfig) {
      return [[], [], false, false];
    }
    const routesAndOvershot = routesEntities.getById('', routesApiConfig);
    const isLoading = routesEntities.getIsLoadingById('', routesApiConfig);
    const hasErrors = !isEmptyObject(loadErrorEntities);
    const routes = isLoading ? [] : routesAndOvershot.routes.filter((route: RouteModel) => {
      return route.traffic.every((edge: EdgeModel) => {
        return edge.transportType === EDGE_TYPES.pedestrian
          || (pageRoutesForm.showTransportTypes && pageRoutesForm.showTransportTypes[edge.transportType]);
      });
    });
    const overshot = isLoading ? [] : routesAndOvershot.overshot;
    return [
      routes,
      overshot,
      isLoading,
      hasErrors,
    ];
  },
);

export const asyncSelectCurrentProjectRoutes = createSelector(
  getProjectRoutesSelectors,
  async (pageProjectForm, pageCalculationsForm, pageRoutesForm, routesEntities) => {
    const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageCalculationsForm, pageRoutesForm);
    if (!routesApiConfig) {
      return Promise.reject();
    }
    return routesEntities.asyncGetById('', routesApiConfig);
  },
);

export const selectEdgeRoutes = createSelector(
  [
    (state: AppState) => forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => (
      forms.selectors[ROUTES_PAGES_FORM_NAME].getFormWithNulls<PageCalculationsRoutesForm>(state)
    ),
    (state: AppState) => forms.selectors[EDGE_ROUTES_FORM_NAME].getFormWithNulls<EdgeRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[EDGE_ROUTES_MODEL_NAME].getEntities<EdgeRoutesModel>(state),
  ],
  (projectForm, routesForm, edgeRoutesForm, edgeRoutesEntities): {
    edgeRoutes: EdgeRoute[];
    edgesRoutesIsLoading: boolean;
    count: number;
  } => {
    if (
      !projectForm.selectedCalculation
      || !routesForm.selectedEdges
      || !edgeRoutesForm.transportType
      || !edgeRoutesForm.edgeId
    ) {
      return { edgeRoutes: [], edgesRoutesIsLoading: false, count: 0 };
    }
    const edgeApiConfig = {
      query: {
        transportType: edgeRoutesForm.transportType,
      },
      parentEntities: {
        [CALCULATIONS_MODEL_NAME]: Number(projectForm.selectedCalculation),
      },
    };
    const allEdgeRoutes: EdgeRoutesModel = edgeRoutesEntities.getById(edgeRoutesForm.edgeId, edgeApiConfig);
    const filteredEdgRoutes = allEdgeRoutes.routes
      ? allEdgeRoutes.routes
        .filter(findSubstring(edgeRoutesForm.search))
        .sort((a: EdgeRoute, b: EdgeRoute) => {
          if (edgeRoutesForm.transportType === TRANSPORT_TYPES.cargo) {
            return b.traffic - a.traffic;
          }

          return b.trafficPass - a.trafficPass;
        })
      : [];
    const { pageSize = 1, currentPage = 10 } = edgeRoutesForm;
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    const edgeRoutes = filteredEdgRoutes
      ? filteredEdgRoutes.slice(start, end)
      : [];
    const edgesRoutesIsLoading = edgeRoutesEntities.getIsLoadingById(edgeRoutesForm.edgeId, edgeApiConfig);
    const count = filteredEdgRoutes.length;
    return { edgeRoutes, edgesRoutesIsLoading, count };
  },
);
