import React from 'react';

const RouteIcon = () => (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 5.54585C17.751 5.54585 17.517 5.60149 17.297 5.69095L14.899 3.54949C14.958 3.31604 15 3.07385 15 2.81858C15 1.30658 13.886 0.0913086 12.5 0.0913086C11.114 0.0913086 10 1.30658 10 2.81858C10 3.25058 10.1 3.65313 10.262 4.01531L7.353 7.76585C7.237 7.74404 7.121 7.72767 7 7.72767C6.821 7.72767 6.652 7.7604 6.488 7.8084L3.925 5.0124C3.97 4.83458 4 4.65022 4 4.45495C4 3.24622 3.108 2.27313 2 2.27313C0.892 2.27313 0 3.24622 0 4.45495C0 5.66367 0.892 6.63676 2 6.63676C2.179 6.63676 2.348 6.60404 2.512 6.55604L5.075 9.35204C5.03 9.52985 5 9.71422 5 9.90949C5 11.1182 5.892 12.0913 7 12.0913C8.108 12.0913 9 11.1182 9 9.90949C9 9.65095 8.952 9.40767 8.877 9.17749L11.79 5.42258C12.017 5.49458 12.252 5.54585 12.5 5.54585C12.911 5.54585 13.292 5.42913 13.633 5.23931L16.032 7.38076C16.016 7.49422 16 7.60876 16 7.72767C16 8.9364 16.892 9.90949 18 9.90949C19.108 9.90949 20 8.9364 20 7.72767C20 6.51895 19.108 5.54585 18 5.54585Z"
      fill="currentColor"
    />
  </svg>
);

export default RouteIcon;
