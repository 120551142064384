import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';

import { completeExportingNetwork, ExportType } from 'src/cluster/editor-common';

import ModalEditorExportNetwork from './ModalEditorExportNetwork';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  completeExport: (type: ExportType) => {
    dispatch(completeExportingNetwork(type));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(ModalEditorExportNetwork));
