export enum ViewModes {
  data = 'data',
  forecast = 'forecast',
}

export enum MapModes {
  star = 'star',
  heat = 'heat',
  group = 'group',
}
