import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import { AC_REGISTRY_TABLE_FILTERS_MODAL, RegistryModalTableFilters } from 'src/cluster/editor-table';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.medium,
  };
};

export default registerModal(
  AC_REGISTRY_TABLE_FILTERS_MODAL,
  stateToProps,
)(RegistryModalTableFilters);
