import React, { FC } from 'react';
import {
  createStyles, makeStyles, TableCell as MuiTableCell, TableCellProps, Theme,
} from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

const overrideTableCellStyles = makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    padding: spacing(4),
    fontFamily: ffSourceSansPro,
    backgroundColor: 'inherit',
    color: 'inherit',
    fontWeight: 'normal',
    fontSize: 12,
    verticalAlign: 'middle',
    lineHeight: 1,
  },
  sizeSmall: {
    padding: spacing(3, 4),
    fontSize: 10,
    lineHeight: 1,

    '&:last-child': {
      paddingRight: 0,
    },
  },
}), { name: 'MuiTableCell' });

const TableCell: FC<TableCellProps> = (props) => {
  overrideTableCellStyles();

  return <MuiTableCell {...props} />;
};
export default TableCell;
