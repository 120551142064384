import { createFormActions } from 'src/helpers';
import modals from 'src/modals';
import { ThunkActionResult } from 'src/reducer';

import { AcDashboardTablePageForm, AC_DASH_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/common';
import { selectDashGraphForm } from 'src/cluster/dashboard-common';
import {
  AC_DASH_TABLE_CUSTOMIZE_MODAL_NAME,
  AC_DASH_TABLE_PAGE_FORM_NAME,
  selectDashTablePageForm,
} from 'src/cluster/dashboard-table';

export const dashTablePageFormActions = createFormActions<AcDashboardTablePageForm>(AC_DASH_TABLE_PAGE_FORM_NAME);

export function initDashboardTable(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const { year, scenarioId } = selectDashGraphForm(getState());

    if (!year || !scenarioId) {
      dispatch(modals.actions.showModal(true, AC_DASH_GRAPH_VERSION_MODAL_NAME));
    }
  };
}

export function changeHiddenCategories(name: string, checked: boolean): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const { hiddenCategories } = selectDashTablePageForm(getState());
    if (checked) {
      if (!hiddenCategories) {
        dispatch(dashTablePageFormActions.changeField('hiddenCategories', []));
      } else if (hiddenCategories.includes(name)) {
        dispatch(dashTablePageFormActions.changeField('hiddenCategories', hiddenCategories.filter(n => n !== name)));
      }
    } else if (!hiddenCategories) {
      dispatch(dashTablePageFormActions.changeField('hiddenCategories', [name]));
    } else if (!hiddenCategories.includes(name)) {
      dispatch(dashTablePageFormActions.changeField('hiddenCategories', [...hiddenCategories, name]));
    }
  };
}

export function showCustomizeModal(): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(modals.actions.showModal(true, AC_DASH_TABLE_CUSTOMIZE_MODAL_NAME));
  };
}
