import React, { FC, useState } from 'react';
import {
  FormControlLabel, Grid, Radio, RadioGroup,
} from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { SubmitButtons, TrackPoint } from 'src/cluster/common';

import useStyles from './styles';

export interface OwnProps {
  nodes: TrackPoint[];
}

export interface InjectedProps {
  onDeletePoint(node: TrackPoint): void;
  onCloseModal(): void;
}

type Props = OwnProps & InjectedProps & WithTranslate;

const EditorModalSelectNode: FC<Props> = (props) => {
  const {
    t,
    nodes,
    onDeletePoint,
    onCloseModal,
  } = props;

  const classes = useStyles();
  const [index, setNodeId] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => setNodeId(Number(value));
  const handleSelected = () => {
    onDeletePoint(nodes[index]);
    onCloseModal();
  };

  return (
    <div className={classes.root}>
      <RadioGroup
        name="viewMode"
        value={String(index)}
        onChange={handleChange}
      >
        {nodes.map((node, i) => (
          <FormControlLabel
            key={`${node.nodeId}_${i}`}
            value={String(i)}
            control={<Radio color="primary" size="small" />}
            label={`${t(
              'modules.editor.messages.nodePositionSelect',
              { stopName: node.stopName, order: node.order + 1 },
            )}`}
          />))}
      </RadioGroup>
      <Grid container direction="row" justify="flex-end">
        <SubmitButtons
          className={classes.submitButtons}
          submitCaption={t('common.captions.delete')}
          onCancel={onCloseModal}
          onSubmit={handleSelected}
        />
      </Grid>
    </div>
  );
};

export default EditorModalSelectNode;
