import React, { FC } from 'react';
import { Maybe } from 'monet';

import { DataGridContainer } from 'src/components';
import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import { ForecastCorrespondencesTable, ForecastCorrespondencesToolbar } from 'src/cluster/balance-forecast';
import {
  AcBalanceForecastForm,
  AcForecastCorrespondenceModel,
  AcProjectForm,
  useCallOnChange,
} from 'src/cluster/common';

import useStyles from './styles';

interface Props extends WithTranslate {
  pageForm: AcBalanceForecastForm;
  correspondencesResults: [Maybe<Model<AcForecastCorrespondenceModel>[]>, number];
  isLoading: boolean;
  projectForm: AcProjectForm;
  resetPageForm(): void;
  onPageChange(value: number): void;
  onPageSizeChange(value: number): void;
}

const ForecastPage: FC<Props> = (props) => {
  const {
    t,
    pageForm: {
      pageSize = 20,
      currentPage = 1,
    },
    projectForm: { selectedProject },
    correspondencesResults: [correspondences, count],
    isLoading,
    resetPageForm,
    onPageChange,
    onPageSizeChange,
  } = props;
  const classes = useStyles();

  useCallOnChange(resetPageForm);

  return (
    <div className={classes.root}>
      <ForecastCorrespondencesToolbar />
      <DataGridContainer
        isLoading={isLoading}
        data={correspondences}
        message={!selectedProject ? t('modules.projects.captions.projectNotSelected') : undefined}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        count={count}
      >
        {(data: AcForecastCorrespondenceModel[]) => (
          <ForecastCorrespondencesTable data={data} interval={1} />
        )}
      </DataGridContainer>
    </div>
  );
};

export default ForecastPage;
