import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { selectCurrentProjectNodes } from 'src/cluster/common';

import DistributionMap from './DistributionMap';

const mapStateToProps = (state: AppState) => ({
  nodesEntities: selectCurrentProjectNodes(state),
});

export default connect(mapStateToProps)(withTranslate(DistributionMap));
