import { useSelector } from 'react-redux';

import { SelectValue } from 'src/interfaces';

import { useAcIntervalOptions, useAcYearOptions } from 'src/cluster/common';
import { selectEditorGraphForm, useEditorScenarioOptions } from 'src/cluster/editor-common';

const useEditorGraphParams = (): [SelectValue, SelectValue, SelectValue] => {
  const { year, scenarioId, intervalId } = useSelector(selectEditorGraphForm);

  const yearOptions = useAcYearOptions();
  const scenarioOptions = useEditorScenarioOptions();
  const intervalOptions = useAcIntervalOptions();

  const yearValue = yearOptions.find(yearItem => yearItem.value === year);
  const scenarioValue = scenarioOptions.find(scenario => scenario.value === scenarioId);
  const intervalValue = intervalOptions.find(interval => interval.value === intervalId);

  return [
    yearValue ? yearValue.value : '',
    scenarioValue ? scenarioValue.value : '',
    intervalValue ? intervalValue.value : '',
  ];
};

export default useEditorGraphParams;
