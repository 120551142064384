import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';

import { AcRoutesModel } from 'src/cluster/common';
import { selectDistributionMapRoutesByVehicleType } from 'src/cluster/distribution-map';

const useDistributionMapRoutes = (): Model<AcRoutesModel>[] => {
  const routes = useSelector(selectDistributionMapRoutesByVehicleType);
  return routes.orJust([]);
};

export default useDistributionMapRoutes;
