/* eslint-disable max-len */
import React from 'react';

const ExclamationMark = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path d="M22 11C22 17.0867 17.0867 22 11 22C4.91333 22 0 17.0867 0 11C0 4.91333 4.91333 0 11 0C17.0867 0 22 4.91333 22 11ZM9.53333 5.20667L10.1933 12.1C10.2667 12.3933 10.34 12.98 10.9267 12.98H11.1467C11.7333 12.98 11.88 12.3933 11.9533 12.0267L12.4667 5.28C12.54 4.76667 12.4667 4.4 12.2467 4.10667C12.0267 3.81333 11.66 3.66667 11.2933 3.66667H10.78C10.34 3.66667 10.0467 3.81333 9.82667 4.03333C9.53333 4.32667 9.46 4.76667 9.53333 5.20667ZM12.54 16.6467C12.54 15.7667 12.0267 15.2533 11.1467 15.2533H10.8533C9.97333 15.2533 9.46 15.7667 9.46 16.6467V16.8667C9.46 17.7467 9.97333 18.26 10.8533 18.26H11.1467C12.0267 18.26 12.54 17.7467 12.54 16.8667V16.6467Z" fill="white"
    />
  </svg>
);

export default ExclamationMark;
