import qhistory from 'qhistory';
import { createBrowserHistory } from 'history';
import { parse, stringify } from 'qs';

export default qhistory(
  createBrowserHistory({
    basename: process.env.REACT_APP_BASE_URL,
  }),
  stringify,
  parse,
);
