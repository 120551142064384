import { useSelector } from 'react-redux';
import { lighten } from '@material-ui/core';

import {
  VEHICLE_TYPES_COLORS,
  VehicleType,
  selectEditorLegendForm,
  useAcVehicleTypeId,
} from 'src/cluster/common';

const useInteractiveStops = () => {
  const {
    isSuburbanStop,
    isWaterStop,
    isMetroStop,
    isTramwayStop,
  } = useSelector(selectEditorLegendForm);

  const getVehicleTypeId = useAcVehicleTypeId();
  const suburbanVehicleTypeId = getVehicleTypeId(VehicleType.suburban);
  const waterVehicleTypeId = getVehicleTypeId(VehicleType.water);
  const metroVehicleTypeId = getVehicleTypeId(VehicleType.metro);
  const tramwayVehicleTypeId = getVehicleTypeId(VehicleType.tramway);

  return [
    {
      id: 'suburban-stops',
      vehicleId: suburbanVehicleTypeId,
      visible: !!suburbanVehicleTypeId && isSuburbanStop,
      color: lighten(VEHICLE_TYPES_COLORS[VehicleType.suburban], 0.5),
    },
    {
      id: 'water-stops',
      vehicleId: waterVehicleTypeId,
      visible: !!waterVehicleTypeId && isWaterStop,
      color: lighten(VEHICLE_TYPES_COLORS[VehicleType.water], 0.5),
    },
    {
      id: 'metro-stops',
      vehicleId: metroVehicleTypeId,
      visible: !!metroVehicleTypeId && isMetroStop,
      color: lighten(VEHICLE_TYPES_COLORS[VehicleType.metro], 0.5),
    },
    {
      id: 'tramway-stops',
      vehicleId: tramwayVehicleTypeId,
      visible: !!tramwayVehicleTypeId && isTramwayStop,
      color: lighten(VEHICLE_TYPES_COLORS[VehicleType.tramway], 0.5),
    },
  ];
};

export default useInteractiveStops;
