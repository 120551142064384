import { combineReducers, Action } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import reduceReducers from 'reduce-reducers';
import { connectRouter } from 'connected-react-router';
import { ThunkAction } from 'redux-thunk';

import { api, forms } from 'redux-restify';

import modals from 'src/modals';

import { STATE_REPLACE_ACTION } from 'src/constants';

export type AppState = any
export type ThunkActionResult<T> = ThunkAction<T, AppState, null, Action<string>>

const getRootReducer = (history: any) => {
  return enableBatching(reduceReducers<any>(
    (state: any, action: {
      type: string;
    }): any => {
      if (action.type === STATE_REPLACE_ACTION) {
        return {};
      }
      return state;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore: https://github.com/reduxjs/redux/issues/2709
    combineReducers<any>({
      [api.constants.NAME]: api.getRestifyApiReducer(),
      [forms.constants.NAME]: forms.getRestifyFormReducer(),
      [modals.constants.NAME]: modals.reducer,
      router: connectRouter(history),
    }),
  ));
};

export default getRootReducer;
