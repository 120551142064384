import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MAIN_MENU_WIDTH } from 'src/theme/constants';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  pagination: {
    position: 'absolute',
    zIndex: 20,
    left: 0,
    right: 0,
    bottom: 0,
    height: spacing(15),
    backgroundColor: palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0, 10),
    boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',

    '& $select': {
      marginLeft: 15,
      width: 70,
    },
  },

  fixed: {
    position: 'fixed',
    left: MAIN_MENU_WIDTH,
  },

  pageSize: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
  },

  select: {},

  page: {
    backgroundColor: 'transparent',
    border: 0,
    color: palette.grey[600],
    userSelect: 'none',
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 0,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',

    $disabled: {
      cursor: 'initial',
    },

    '&:not(:first-child)': {
      marginLeft: spacing(2),
    },
  },

  arrowIcon: {
    color: palette.text.primary,
  },

  iconHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },

  currentPage: {
    fontWeight: 'bold',
    color: palette.primary.main,
    borderBottomColor: palette.primary.main,
  },

  pages: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  summaryCount: {
    position: 'relative',
    zIndex: 2,
  },
}));
