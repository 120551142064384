import { useSelector } from 'react-redux';
import { selectCurrentProjectNodes } from 'src/cluster/common';

export const useAcNodesName = (): (id: number) => string => {
  const [nodes] = useSelector(selectCurrentProjectNodes);

  return (id: number) => {
    const record = nodes.find((node) => node.id === id);
    return record ? record.name : '-';
  };
};
export default useAcNodesName;
