import React, { useMemo } from 'react';

import i18n from 'src/i18n';
import { isNil } from 'src/helpers';
import { VehicleIcon, VehicleType } from 'src/cluster/common';
import { NumberFormat, WithUnit } from 'src/components';
import { UNITS } from 'src/constants';
import {
  AcDistRoutesModel,
  AcDistTableFieldsConfig,
  TopColumns,
} from 'src/cluster/distribution-table';

const useDistRouteConfigLeft = (
  getVehicleTypeName: (vehicleId?: number) => VehicleType | undefined,
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistRoutesModel>[] => {
  return useMemo(() => {
    const translate = 'systems.distribution.columns.';
    return [
      {
        type: TopColumns.vehicleType,
        title: i18n.t(translate + TopColumns.vehicleType),
        className: classes.first,
        model: (result: AcDistRoutesModel) => <VehicleIcon type={getVehicleTypeName(result.vehicleTypeId)} />,
      },
      {
        type: TopColumns.routeNumber,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.routeNumber),
        model: (result: AcDistRoutesModel) => result.variantNumber,
      },
      {
        type: TopColumns.routeLongName,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.routeLongName),
        model: (result: AcDistRoutesModel) => result.variantName,
      },
      {
        type: TopColumns.distance,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.length)}>
            {i18n.t(translate + TopColumns.distance)}
          </WithUnit>
        ),
        model: (result: AcDistRoutesModel) => (
          <div className={classes.taRight}>
            {isNil(result.distance) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.distance}
                locale="ru"
              />
            )}
          </div>
        ),
      },
    ];
  }, [getVehicleTypeName]);
};

export default useDistRouteConfigLeft;
