import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { LegendType } from '../../types';
import { DrawerLegendCheckbox } from '../../interfaces/common';
import { selectEditorLegendForm } from '../../store';

const useLegendSrnSpeed = (
  setLegendCheckbox: (key: LegendType, checked: boolean) => void,
): DrawerLegendCheckbox => {
  const { isAverageTravelSpeedCar } = useSelector(selectEditorLegendForm);

  return {
    type: LegendType.isAverageTravelSpeedCar,
    iconProps: {},
    checked: isAverageTravelSpeedCar,
    onChange: (event: ChangeEvent<{}>, checked: boolean) => {
      setLegendCheckbox(LegendType.isAverageTravelSpeedCar, checked);
    },
  };
};

export default useLegendSrnSpeed;
