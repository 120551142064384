export const STATIC_API_NAME = 'static';
export const STATIC_API_HOST =
  process.env.STATIC_API_HOST || `//${typeof window !== 'undefined' ? window.location.host : ''}`;

export const STATIC_API_PREFIX = process.env.PUBLIC_URL;

export const STATIC_ALLOWED_NO_TOKEN_ENDPOINTS = [
  /.*/,
];

export const noSlashEnforceUrl = ({
  apiHost,
  apiPrefix,
  modelEndpoint,
  entityId,
  specialAction,
}: any) => {
  let slashAfterId = '';
  if (entityId && (typeof entityId === 'number' || typeof entityId === 'string' && !entityId.endsWith('/'))) {
    slashAfterId = '/';
  }
  const baseUrl = `${modelEndpoint}${entityId || ''}${slashAfterId}${specialAction || ''}`;
  return `${apiHost}${apiPrefix}${baseUrl}`;
};
