import React, { FC, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { SubmitButtons } from 'src/cluster/common';
import { AcRegRoutesResultsModel, RegistryVariant } from 'src/cluster/editor-table';
import {
  RegistryEditRouteFields,
  RegistryEditVariant,
  RegistryEditVehicleClasses,
} from './components';

import useStyles, { useTitleStyles } from './styles';

export interface OwnProps {
  record: AcRegRoutesResultsModel;
}

export interface InjectedProps {
  onSaveRoute(route: AcRegRoutesResultsModel): void;
  onCloseModal(): void;
}

type Props = OwnProps & InjectedProps & WithTranslate;

const RegistryModalRouteEditing: FC<Props> = (props) => {
  const {
    t,
    record,
    onSaveRoute,
    onCloseModal,
  } = props;

  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const [route, setRoute] = useState<AcRegRoutesResultsModel>(record);
  const { vehicleClasses, variants, ...routeFields } = route;

  const onChangeFields = (fields: Partial<AcRegRoutesResultsModel>) => {
    setRoute({ ...route, ...fields });
  };

  const onChangeVariant = (index: number, variant: RegistryVariant) => {
    if (variants) {
      variants.splice(index, 1, variant);
      onChangeFields({ variants });
    }
  };

  const handleSaveRoute = () => {
    const variantsFiltered = route.variants?.filter(variant => !variant.deleted) || [];
    onSaveRoute({ ...route, variants: variantsFiltered });
  };

  return (
    <div className={classes.root}>
      <RegistryEditRouteFields routeFields={routeFields} onChangeFields={onChangeFields} />
      <RegistryEditVehicleClasses
        vehicleClasses={vehicleClasses}
        titleClasses={titleClasses}
        onChangeFields={onChangeFields}
      />
      <Divider />
      {!!variants?.length && (
        <>
          <Typography classes={titleClasses} variant="body2">
            {t('modules.registries.columns.variants')}
          </Typography>
          {variants.map((variant, i) => (
            <RegistryEditVariant
              key={i}
              variant={variant}
              titleClasses={titleClasses}
              count={variants.filter(v => !v.deleted).length}
              onChangeVariant={(v) => onChangeVariant(i, v)}
            />
          ))}
        </>
      )}
      <Grid container direction="row" justify="flex-end">
        <SubmitButtons
          className={classes.submitButtons}
          isLoading={false}
          disabled={false}
          onCancel={onCloseModal}
          onSubmit={handleSaveRoute}
        />
      </Grid>
    </div>
  );
};

export default RegistryModalRouteEditing;
