import React, { FC } from 'react';
import classNames from 'classnames';
import { RestifyModel } from 'redux-restify';

import {
  AggloIndicatorsTable, IndicatorsItem, IndicatorsModel, IndicatorsSubtask, PageAggloIndicatorsForm,
} from 'src/modules/mth/indicators';

import AggloIndicatorsToolbar from '../AggloIndicatorsToolbar';

import style from './index.module.css';

interface Props {
  className?: string;
  entities: [RestifyModel<IndicatorsModel>, boolean];
  pageForm: PageAggloIndicatorsForm;
}

const AggloRoutesPage: FC<Props> = (props: Props) => {
  const {
    className,
    entities: [{ tasks = [], years = [], indicatorsCatalog = {} }, isLoading],
    pageForm,
  } = props;

  const {
    search, taskFilter, subtaskFilter, indicatorTypeFilter,
  } = pageForm;

  const filteredByTextTasks = !search
    ? tasks
    : tasks.map((task) => {
      if (
        task.name.toLowerCase().includes(search)
        || task.no.toLowerCase().includes(search)
      ) {
        return task;
      }
      return {
        ...task,
        subtasks: task.subtasks.map((subtask) => {
          if (
            subtask.name.toLowerCase().includes(search)
            || subtask.no.toLowerCase().includes(search)
          ) {
            return subtask;
          }
          return {
            ...subtask,
            indicators: subtask.indicators.map((indicator) => {
              if (
                indicator.name.toLowerCase().includes(search)
                || indicator.no.toLowerCase().includes(search)
              ) {
                return indicator;
              }
              return null;
            }).filter(v => v) as IndicatorsItem[],
          };
        }).filter(v => v.indicators.length > 0),
      };
    }).filter(v => v.subtasks.length > 0);

  const filteredByTaskTasks = !taskFilter
    ? filteredByTextTasks
    : filteredByTextTasks.filter((task) => {
      if (taskFilter) {
        return task.no === taskFilter;
      }
      return true;
    });

  const filteredBySubtaskTasks = !subtaskFilter
    ? filteredByTaskTasks
    : filteredByTaskTasks.map((task) => {
      return {
        ...task,
        subtasks: task.subtasks.map((subtask) => {
          if (subtask.no === subtaskFilter) {
            return subtask;
          }
          return null;
        }).filter(v => v) as IndicatorsSubtask[],
      };
    }).filter(v => v.subtasks.length > 0);

  const filteredTasks = !indicatorTypeFilter
    ? filteredBySubtaskTasks
    : filteredBySubtaskTasks.map((task) => {
      return {
        ...task,
        subtasks: task.subtasks.map((subtask) => {
          return {
            ...subtask,
            indicators: subtask?.indicators.map((indicator) => {
              if (indicator && indicator.type === indicatorTypeFilter) {
                return indicator;
              }
              return null;
            }).filter(v => v) as IndicatorsItem[],
          };
        }).filter(v => v.indicators.length > 0),
      };
    }).filter(v => v.subtasks.length > 0);

  return (
    <div className={classNames(style.root, className)}>
      <AggloIndicatorsToolbar
        isLoading={isLoading}
        pageForm={pageForm}
        tasks={filteredByTextTasks}
        indicatorTypes={indicatorsCatalog}
      />
      <AggloIndicatorsTable
        tasks={filteredTasks}
        years={years}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AggloRoutesPage;
