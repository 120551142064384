/* eslint-disable max-len */
import React from 'react';

const Questionnaire = () => {
  return (
    <svg viewBox="0 0 503.6 503.6">
      <g>
        <path d="M334.9,75.5h24.2c14.3,0,25.2,10.9,25.2,25.2v51.8l16.8-30.7v-21.1c0-23.5-18.5-42-42-42h-24.2 C335.1,64.4,334.7,69.9,334.9,75.5z"
        />
        <path d="M384.3,332.6v129.1c0,14.3-10.9,25.2-25.2,25.2H90.5c-14.3,0-25.2-10.9-25.2-25.2V100.7c0-14.3,10.9-25.2,25.2-25.2h25.2 V58.8H90.5c-23.5,0-42,18.5-42,42v360.9c0,23.5,18.5,42,42,42h268.6c23.5,0,42-18.5,42-42V302.4L384.3,332.6z"
        />
        <path d="M132.5,109.1c0,9.2,7.6,16.8,16.8,16.8h151.1c9.2,0,16.8-7.6,16.8-16.8l0-58.8c0-9.2-7.6-16.8-16.8-16.8h-25.2v-8.4 C275.2,10.9,264.3,0,250,0h-50.4c-14.3,0-25.2,11.8-25.2,25.2v8.4h-25.2c-9.2,0-16.8,7.6-16.8,16.8L132.5,109.1z M149.3,50.4h33.6 c5,0,8.4-3.4,8.4-8.4V25.2c0-5,3.4-8.4,8.4-8.4H250c5,0,8.4,4.2,8.4,8.4V42c0,5,3.4,8.4,8.4,8.4h33.6v58.8H149.3V50.4z"
        />
        <path d="M158.8,436.2H95.5v-63.3h63.3V436.2z M109.5,422.2h35.3v-35.3h-35.3V422.2z M158.8,331.2H95.5v-63.3h63.3V331.2z M109.5,317.2h35.3v-35.3h-35.3V317.2z M251.8,306.5h-72.5v-14h72.5V306.5z M300.3,226.2h-121v-14h121V226.2z M158.8,226.2H95.5 v-63.3h63.3V226.2z M109.5,212.2h35.3v-35.3h-35.3V212.2z M334.9,176.9H179.3v-14h155.6V176.9z"
        />
      </g>
      <g>
        <path d="M462.7,164l6.5-11.3L419,123.6l-24.4,42.8c1.4,1,37.8,22.5,37.8,22.5L318.7,385.6l-26.3-15.2l106.1-186.1l-10.9-6.9 L277,369.6c-0.5,0.9-0.8,1.9-0.9,3c0,0,0,0,0,0.1l2.2,54.1l46.8-25.8c0,0,0,0,0.1-0.1c0.8-0.6,1.6-1.3,2.1-2.3l124-214.8L413,161.7 l11.1-19.3L462.7,164z M287.8,408.9l1.5-24.3l18.8,10.9L287.8,408.9z"
        />
      </g>
    </svg>
  );
};

export default Questionnaire;
