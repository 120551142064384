import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => createStyles({
  tableWrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },

  spaceWrap: {
    display: 'inline-block',
    width: 20,
    textAlign: 'center',
  },

  unitWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
  },

  shortTitle: {
    display: 'flex',
    flexDirection: 'column',
  },

  titleUnit: {
    color: '#333B46',
    marginTop: 6,
    display: 'block',
    fontWeight: 500,
    cursor: 'pointer',
    padding: theme.spacing(0, 4),
    margin: theme.spacing(0, -4),
    textTransform: 'uppercase',
    fontSize: 10,
  },

  tooltipAnchor: {
    padding: theme.spacing(0, 4),
    margin: theme.spacing(0, -4),
  },

  leftTableWrapper: {
    width: '50%',
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },

  middleTableWrapper: {
    width: '50%',
    overflowX: 'scroll',
    height: '100%',
  },

  edit: {
    middleTableWrapper: {
      width: 'calc(70% - 180px)',
    },
  },

  rightTableWrapper: {
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
  },

  editable: {
    flexDirection: 'row-reverse',
  },

  headerCell: {
    paddingTop: 4,
    paddingBottom: 4,
    left: 'auto!important',
    height: 50,
    whiteSpace: 'nowrap',
    borderBottomColor: `${theme.palette.grey[400]}!important`,
    paddingLeft: 24,
    paddingRight: 24,
  },

  headerCellFixed: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'fixed',
    left: 'auto!important',
    height: 50,
    whiteSpace: 'nowrap',
    borderBottomColor: `${theme.palette.grey[400]}!important`,
  },

  headerCellAction: {
    height: 50,
    borderBottomColor: `${theme.palette.grey[400]}!important`,
    paddingTop: 0,
    paddingBottom: 0,
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 90,
    height: 45,
  },

  btnAdd: {
    maxWidth: 51,
  },

  deletedRow: {
    '& $cell': {
      backgroundColor: theme.palette.grey[200],
      borderBottomColor: theme.palette.grey[500],
      color: theme.palette.grey[700],
      height: 45,
    },

    '& $editable': {
      '& > div': {
        backgroundColor: 'transparent',
      },
    },

    '& $deleteButton': {
      fontFamily: ffSourceSansPro,
      width: 32,
      height: 32,
      padding: 0,
    },
  },

  cell: {
    backgroundColor: theme.palette.common.white,
    borderBottomColor: theme.palette.grey[300],
    color: theme.palette.grey[700],
    height: 45,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
  },

  actionCell: {
    backgroundColor: theme.palette.common.white,
    borderBottomColor: theme.palette.grey[500],
    color: theme.palette.grey[700],
    height: 45,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
  },

  numberColumn: {
    width: 10,
    textAlign: 'right',
  },

  correspondenceColumn: {
    minWidth: 150,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  tooltipInner: {
    cursor: 'pointer',
  },

  createButtonWrapper: {
    textAlign: 'right',
  },
  headTable: {
    height: 45,
  },

  createButton: {
    marginLeft: 'auto',
    padding: '2px 20px',
    fontWeight: 'bold',
  },

  deleteButton: {
    width: 32,
    height: 32,
    padding: 0,
    border: '1px solid transparent',
    borderRadius: theme.spacing(1),
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    outline: 0,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#e52941',
      color: `${theme.palette.common.white}`,
      border: `1px dotted ${theme.palette.primary.main}`,
    },

    '&:active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },

    '&:focus:not(:hover):not(:active)': {
      border: `1px dotted ${theme.palette.primary.main}`,
    },
  },

  units: {
    color: `${theme.palette.grey[600]}`,
    textTransform: 'none',
    fontWeight: 'normal',
  },
}));
