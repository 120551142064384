import React, { ChangeEvent, FC, useState } from 'react';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import {
  Button, SelectField, ToolbarBlock, ToolbarControl, ToolbarControls,
} from 'src/components';
import { isString } from 'src/helpers';

import { ExportType, useEditorExportFileTypeOptions } from 'src/cluster/editor-common';

import useStyles from './styles';

export interface InjectedProps {
  setYear(event: ChangeEvent<{ value: unknown }>): void;
  completeExport(type: ExportType): void;
}

type Props = InjectedProps & WithTranslate;

const ModalEditorExportNetwork: FC<Props> = (props) => {
  const {
    t,
    completeExport,
  } = props;

  const classes = useStyles();

  const exportTypes = useEditorExportFileTypeOptions();

  const [type, setType] = useState(exportTypes[0].value as ExportType);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isString(value) && value in ExportType) {
      setType(value as ExportType);
    }
  };

  const handleExportClick = () => completeExport(type);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t('systems.editor.captions.exportingNetwork')}</Typography>
      </div>
      <ToolbarBlock className={classes.container}>
        <ToolbarControls>
          <ToolbarControl size="full-width">
            <SelectField
              label={t('common.captions.fileType')}
              value={type}
              options={exportTypes}
              fullWidth
              onChange={handleChange}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <div className={classes.footer}>
        <Button onClick={handleExportClick}>{t('common.captions.export')}</Button>
      </div>
    </div>
  );
};

export default ModalEditorExportNetwork;
