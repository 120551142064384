import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import getRootReducer from './reducer';

export const sagaMiddleware = createSagaMiddleware();

const configStore = (history: any, initialState: object) => {
  const middlewareList = [
    routerMiddleware(history),
    sagaMiddleware,
    thunkMiddleware,
  ];

  const middlewares = applyMiddleware(...middlewareList);
  const devTool = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
  const store = createStore(
    getRootReducer(history),
    initialState,
    compose(
      middlewares,
      devTool ? devTool() : (f: any) => f,
    ),
  );

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configStore;
