import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import { AcRoutesVariantsModel } from 'src/cluster/distribution-table';
import { selectDistVariants } from 'src/cluster/distribution-table/store/selectors';

const useDistRoutesNumbers = (): SelectMenuOption[] => {
  const variants = useSelector(selectDistVariants);

  return useMemo(() => {
    return variants.orJust([]).sort((a, b) => {
      const numberA = Number.parseInt(a.variantNumber, 10);
      const numberB = Number.parseInt(b.variantNumber, 10);
      if (numberA === numberB) {
        return a.variantNumber.localeCompare(b.variantNumber);
      }
      return numberA - numberB;
    }).map((stop: AcRoutesVariantsModel) => ({
      id: `${stop.id}`,
      value: stop.id,
      label: stop.variantNumber,
    }));
  }, [variants]);
};

export default useDistRoutesNumbers;
