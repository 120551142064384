import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Typography,
} from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import { Button } from 'src/components';
import { Nullable, ProcessStatus } from 'src/types';
import {
  MatrixForecastModel,
  useMatrixForecastStatusUpdate,
} from 'src/cluster/common';
import { isNumber } from 'lodash';
import useStyles from './styles';

interface OwnProps {
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
}

interface InjectedProps extends WithTranslate {
  refetchData: () => void;
  calculateRPM: () => void;
  openDateYear: () => void;
  close: () => void;
  openMatrixSettingsModal: (activeOptions: Map<string, boolean>) => void;
  loadMatrixForecast: () => void;
  setLoadingCalc(f: boolean): void;
}

type Props = OwnProps & InjectedProps;

let wasCalculateInSameSession = false;
let wasShowModal = false;
let lastSelectOptionsReCalculate = new Map<string, boolean>();

const ModalProcessDataCalc: FC<Props> = (props) => {
  const {
    t,
    close,
    openMatrixSettingsModal,
    openDateYear,
    setLoadingCalc,
    matrixForecastEntities,
    refetchData,
    calculateRPM,
    loadMatrixForecast,
  } = props;

  const [activeOptions, setActiveOptions] = useState<Map<string, boolean>>(
    new Map(),
  );

  const lastStatus = useRef<string | undefined>(undefined);

  const [entity, isLoading] = matrixForecastEntities;
  const { status, progress } = entity?.routesDistribution || {};

  useMatrixForecastStatusUpdate(
    loadMatrixForecast,
    isLoading && status === ProcessStatus.SUCCESS,
    (entity?.routesDistribution || {}) as MatrixForecastModel,
    3000,
  );

  useEffect(() => {
    if (
      status === ProcessStatus.QUEUED ||
      status === ProcessStatus.IN_PROGRESS
    ) {
      wasCalculateInSameSession = true;
      wasShowModal = false;
    }

    lastStatus.current = status;
  }, [status, progress]);

  const getStatus = (statusEntity: ProcessStatus | undefined) => {
    const changeStatusAfterSwipeScreen =
      wasCalculateInSameSession && status === ProcessStatus.SUCCESS;

    if (changeStatusAfterSwipeScreen && !wasShowModal) {
      wasShowModal = true;
      wasCalculateInSameSession = false;
      return 2;
    }

    if (
      statusEntity === ProcessStatus.IN_PROGRESS ||
      statusEntity === ProcessStatus.QUEUED
    ) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (status !== ProcessStatus.SUCCESS) return;
    setLoadingCalc(false);
  }, [status]);

  const activeStep = useMemo(() => getStatus(status), [status]);

  const makeActiveOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setActiveOptions((map) => {
      let res = map.set(name, checked);
      if (name === 'TTC' && checked) res = res.set('Matrix', true);
      if (name === 'Matrix' && map?.get('TTC')) res.set('Matrix', true);
      lastSelectOptionsReCalculate = new Map(res);
      return new Map(res);
    });
  };

  const classes = useStyles();

  const handleCancel = () => close();

  const handleNextStep = () => {
    const needMatrixSettings = activeOptions.get('Matrix');

    if (needMatrixSettings) {
      openMatrixSettingsModal(activeOptions);
      close();
      return;
    }

    setLoadingCalc(true);
    calculateRPM();
    close();
  };

  return (
    <div className={classes.root}>
      {activeStep === 0 && (
        <ChooseOptions
          t={t}
          matrixForecastEntities={matrixForecastEntities}
          handleNextStep={handleNextStep}
          handleCancel={handleCancel}
          activeOptions={activeOptions}
          makeActiveOptions={makeActiveOptions}
        />
      )}
      {activeStep === 1 && <DataProcess t={t} progress={progress || 0} />}
      {activeStep === 2 && (
        <ResultList
          status={status}
          refetchData={refetchData}
          openDateYear={openDateYear}
          t={t}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

const CalculateDataOption = ({
  status,
  name,
  label,
  lastCalculate,
  description,
  onChange,
  disable,
}: {
  status: boolean;
  disable?: boolean;
  name: string;
  label: string;
  description?: string;
  lastCalculate: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      style={{
        alignItems: 'flex-start',
        marginLeft: '-3px',
        marginBottom: '10px',
      }}
      control={
        <Checkbox
          disabled={disable}
          className={classes.calcDataCheckbox}
          checked={status}
          name={name}
          onChange={onChange}
        />
      }
      label={
        <div className={classes.calcDataLabel}>
          {label && <span className={classes.calcDataLabelTitle}>{label}</span>}
          {description && (
            <span className={classes.calcDataLabelDescription}>
              {description}
            </span>
          )}
          {lastCalculate && (
            <span className={classes.calcDataLastResult}>{lastCalculate}</span>
          )}
        </div>
      }
    />
  );
};

const ChooseOptions = ({
  t,
  makeActiveOptions,
  activeOptions,
  handleCancel,
  handleNextStep,
  matrixForecastEntities: [entity],
}: {
  t: any;
  makeActiveOptions: (e: any) => void;
  activeOptions: Map<string, boolean>;
  handleCancel: () => void;
  handleNextStep: () => void;
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
}) => {
  const classes = useStyles();

  const lastTtcDate = entity?.ttc.lastCalculationDate;
  const lastMatrixDate = entity?.matrix.lastCalculationDate;
  const lastRPMDate = entity?.routesDistribution.lastCalculationDate;
  return (
    <>
      <Typography variant="h5">
        {t('systems.distribution.modals.calculate.title')}
      </Typography>
      <Typography style={{ marginBottom: '16px' }}>
        {t('systems.distribution.modals.calculate.subtitle')}
      </Typography>

      <FormControl>
        <FormGroup>
          <CalculateDataOption
            onChange={makeActiveOptions}
            disable
            status
            lastCalculate={
              lastRPMDate
                ? `Последний расчет:  ${new Date(
                  lastRPMDate,
                ).toLocaleDateString('ru-RU')}`
                : ''
            }
            description={t(
              'systems.distribution.modals.calculate.rpmDescription',
            )}
            label={t('systems.distribution.modals.calculate.rpm')}
            name="RPM"
          />
          <CalculateDataOption
            onChange={makeActiveOptions}
            status={Boolean(activeOptions.get('Matrix'))}
            lastCalculate={
              lastMatrixDate
                ? `Последний расчет: ${new Date(
                  lastMatrixDate,
                ).toLocaleDateString('ru-RU')}`
                : ''
            }
            label={t('systems.distribution.modals.calculate.matrix')}
            name="Matrix"
          />
          <CalculateDataOption
            onChange={makeActiveOptions}
            status={Boolean(activeOptions.get('TTC'))}
            lastCalculate={
              lastTtcDate
                ? `Последний расчет: ${new Date(lastTtcDate).toLocaleDateString(
                  'ru-RU',
                )}`
                : ''
            }
            label={t('systems.distribution.modals.calculate.ttc')}
            name="TTC"
          />
        </FormGroup>
      </FormControl>

      <div className={classes.calcDataButtons}>
        <Button
          onClick={handleCancel}
          className={classes.calcDataButton}
          variant="outlined"
        >
          {t('systems.distribution.modals.calculate.cancel')}
        </Button>
        <Button onClick={handleNextStep} className={classes.calcDataButton}>
          {t('systems.distribution.modals.calculate.next')}
        </Button>
      </div>
    </>
  );
};

const ResultList = ({
  handleCancel,
  t,
  openDateYear,
  refetchData,
}: {
  t: any;
  status?: ProcessStatus;
  openDateYear: () => void;
  handleCancel: () => void;
  refetchData: () => void;
}) => {
  const classes = useStyles();
  const hasMatrix = lastSelectOptionsReCalculate?.get('Matrix');
  const hasTTC = lastSelectOptionsReCalculate?.get('TTC');

  useEffect(() => {
    refetchData();
    return () => {
      openDateYear();
    };
  }, []);

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '16px' }}>
        {t('systems.distribution.modals.calculate.calculateDone')}
      </Typography>
      <ul className={classes.resultList}>
        {hasTTC && (
          <li className={classes.resultListItem}>
            Совокупные транспортные затраты
          </li>
        )}
        {hasMatrix && (
          <li className={classes.resultListItem}>Матрица корреспонденций</li>
        )}
        <li className={classes.resultListItem}>Распределение по маршрутам</li>
      </ul>
      <div className={classes.calcDataButtons}>
        <Button onClick={handleCancel} className={classes.calcDataButton}>
          {t('systems.distribution.modals.calculate.close')}
        </Button>
      </div>
    </>
  );
};

const DataProcess = ({ t, progress = 0 }: { t: any; progress: number }) => {
  const percent = isNumber(progress)
    ? Math.round(progress * 10000) / 100
    : undefined;
  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '15px' }}>
        {t('systems.distribution.modals.calculate.calculateTitle')}
      </Typography>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>
          {t('systems.distribution.modals.calculate.calculateComplete')}
        </Typography>
        <Typography>
          {percent}
          %
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={percent} />
    </>
  );
};

export default ModalProcessDataCalc;
