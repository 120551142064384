import { Scale } from 'src/cluster/common';
import palette from 'src/theme/palette';

function getTrafficWidth(scale: Scale<number>, maxWidth: number) {
  return [
    'case',
    ['!', ['has', 'traffic']],
    1,
    ...scale.reduce<any[]>((memo, item) => {
      return memo.concat([
        [
          'all',
          ['>=', ['get', 'traffic'], item.from],
          ['<', ['get', 'traffic'], item.to],
        ],
        maxWidth * item.value,
      ]);
    }, []),
    1,
  ];
}

export function makeMapStylePointLayer(
  id: string,
  visible: boolean,
  filter: any[],
  color: string,
  width = 3,
  opacity = 1,
) {
  return {
    id,
    filter,
    source: 'points',
    type: 'circle',
    layout: {
      visibility: visible ? 'visible' : 'none',
    },
    paint: {
      'circle-color': '#fff',
      'circle-radius': 6,
      'circle-opacity': opacity,
      'circle-stroke-width': width,
      'circle-stroke-color': color || palette.text.primary,
    },
  };
}

export function makeMapStyleStopLayer(
  id: string,
  visibility: boolean,
  filter: any[],
  paint: object,
) {
  return {
    id,
    filter,
    source: 'stops',
    type: 'circle',
    layout: {
      visibility: visibility ? 'visible' : 'none',
    },
    paint,
  };
}

export function makeMapStyleEdgeLayer(
  id: string,
  visibility: boolean,
  filter: any[],
  paint: object,
) {
  return {
    id,
    filter,
    source: 'edges',
    type: 'line',
    layout: {
      visibility: visibility ? 'visible' : 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint,
  };
}

export function makeMapStyleEdgeWithScaleLayer(
  id: string,
  source: string,
  visibility: boolean,
  filter: any[],
  scale: Scale<number>,
) {
  return {
    id,
    filter,
    source,
    type: 'line',
    layout: {
      visibility: visibility ? 'visible' : 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-color': '#843ef3',
      'line-width': getTrafficWidth(scale, 10),
    },
  };
}
