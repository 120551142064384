export interface ProjectForm {
  id?: undefined;
  file?: File;
  name?: string;
  errors?: string;
}

export default {
  defaults: {
    id: undefined,
    file: undefined,
    name: undefined,
    errors: undefined,
  },
  submitExclude: {
    errors: true,
  },
};
