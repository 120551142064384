import React, {
  createElement, FC, useState,
} from 'react';

import { MapLegendDrawer } from 'src/cluster/common';

import useStyles from './styles';

export interface Props<F> {
  pageForm: F;
  sidebar: FC<{ pageForm: F }>;
  map: FC<{ pageForm: F; showLegend(): void }>;
  legend?: FC<{ pageForm: F }>;
}

function MapPage<F>(props: Props<F>) {
  const {
    pageForm,
    sidebar,
    map,
    legend,
  } = props;

  const classes = useStyles();

  const [show, setShow] = useState(false);

  const showLegend = () => setShow(true);
  const toggleLegend = () => setShow(value => !value);

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        {createElement(sidebar, { pageForm })}
      </div>
      <div className={classes.map}>
        {createElement(map, { pageForm, showLegend })}
      </div>
      <MapLegendDrawer
        isShowLegend={show}
        variableContent={legend && createElement(legend, { pageForm })}
        toggleDrawer={toggleLegend}
      />
    </div>
  );
}

export default MapPage;
