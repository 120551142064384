import { AcDistPageForm, DistFilterModes } from 'src/cluster/common';

const defaults: AcDistPageForm = {
  filterMode: DistFilterModes.route,
  vehicleTypeId: undefined,
  routeVariantId: undefined,

  stopId: undefined,
  vehicleTypeIdStop: undefined,

  forwardDirectionId: undefined,
  backwardDirectionId: undefined,
  isExporting: false,

  pageSize: 10,
  currentPage: 1,
};

export default { defaults };
