import max from 'lodash/max';
import { MacroeconomicsModel, MacroeconomicsRecord } from 'src/modules/matrix/macroeconomics';
import { toPercentValue } from 'src/helpers/deprecated/format';

export function toYearRange(acc: MacroeconomicsRecord[], item: MacroeconomicsRecord): MacroeconomicsRecord[] {
  const index: number = acc.findIndex((v: MacroeconomicsRecord) => v.year === item.year);
  if (index < 0) {
    const newItem: MacroeconomicsRecord = {
      year: item.year,
      macroeconomics: item.macroeconomics,
      mobilityIncrease: item.mobilityIncrease,
      nonInflationaryIncomeGrowth: item.nonInflationaryIncomeGrowth,
    };
    return acc.concat(newItem);
  }
  const result = [...acc];
  result[index] = {
    year: acc[index].year,
    macroeconomics: max([acc[index].macroeconomics, item.macroeconomics]) || 0,
    mobilityIncrease: max([acc[index].mobilityIncrease, item.mobilityIncrease]) || 0,
    nonInflationaryIncomeGrowth: max([acc[index].nonInflationaryIncomeGrowth, item.nonInflationaryIncomeGrowth]) || 0,
  };
  return result;
}

export function toPercents(item: MacroeconomicsModel): MacroeconomicsRecord {
  return {
    year: item.year || 0,
    macroeconomics: toPercentValue(item.macroeconomics || 0),
    mobilityIncrease: toPercentValue(item.mobilityIncrease || 0),
    nonInflationaryIncomeGrowth: toPercentValue(item.nonInflationaryIncomeGrowth || 0),
  };
}
