import { maxBy, minBy } from 'lodash';
import { Either, Left, Right } from 'monet';
import {
  PathEdge,
  PathSegment,
  TrackPoint,
  AcRouteDirection,
  isPathEdges,
} from 'src/cluster/common';
import { isNumber } from 'src/helpers';

class Pathfinder {
  private direction: AcRouteDirection;

  private newEdges: Either<Error, PathEdge[]>;

  private readonly findPathSegment: (prevNodeId: number, nextNodeId: number) => any;

  constructor(direction: AcRouteDirection, findPathSegment: (prevNodeId: number, nextNodeId: number) => any) {
    this.direction = direction;
    this.newEdges = Right([]);
    this.findPathSegment = findPathSegment;
  }

  public async deletePoint(node: TrackPoint): Promise<AcRouteDirection> {
    const { nodeId, order } = node;
    if (!isNumber(order) || !nodeId) return this.direction;

    const [prevNodeId, nextNodeId] = this.getExtremeNodesOfBreak(order);
    const isEnding = prevNodeId === nodeId || nextNodeId === nodeId;
    if (isEnding) {
      this.newEdges = Right([]);
    } else {
      await this.fetchNewEdges(prevNodeId, nextNodeId);
    }
    return this.removeNodeFromDirection(node, prevNodeId, nextNodeId).toPromise();
  }

  public async addPoint(node: TrackPoint, index?: number): Promise<AcRouteDirection> {
    if (this.direction.nodes.length === 0) {
      return this.addNodeToDirection(node, this.direction.nodes.length);
    }

    if (index === 0) {
      return this.addPointAtStart(node);
    }
    if (index === undefined || index === this.direction.nodes.length) {
      return this.addPointAtEnd(node);
    }
    if (index >= -1 && index <= this.direction.nodes.length - 1) {
      return this.addPointInMiddle(node, index);
    }
    return Promise.reject(Error(`Invalid index while adding point: index = ${index}.`));
  }

  private async addPointAtStart(node: TrackPoint): Promise<AcRouteDirection> {
    const nextNodeId = this.direction.nodes[0].nodeId;
    await this.fetchNewEdges(node.nodeId, nextNodeId);

    return this.newEdges.cata(
      (e) => Promise.reject(e),
      (value) => {
        const newSegment = {
          firstNode: node.nodeId,
          lastNode: nextNodeId,
          edges: value,
          order: 0,
        };
        this.addNodeToDirection(node, 0);
        this.addSegmentToDirection(newSegment, 0);

        return Promise.resolve(this.direction);
      });
  }

  private async addPointAtEnd(node: TrackPoint): Promise<AcRouteDirection> {
    const prevNodeId = this.direction.nodes[this.direction.nodes.length - 1].nodeId;
    await this.fetchNewEdges(prevNodeId, node.nodeId);

    return this.newEdges.cata(
      (e) => Promise.reject(e),
      (value) => {
        const newSegment = {
          firstNode: prevNodeId,
          lastNode: node.nodeId,
          edges: value,
          order: 0,
        };
        this.addNodeToDirection(node, this.direction.nodes.length);
        this.addSegmentToDirection(newSegment, this.direction.path.length);

        return Promise.resolve(this.direction);
      });
  }

  private async addPointInMiddle(
    node: TrackPoint,
    index: number,
  ): Promise<AcRouteDirection> {
    const prevNodeId = this.direction.nodes[index - 1].nodeId;
    const nextNodeId = this.direction.nodes[index].nodeId;

    this.removeSegmentFromDirection(prevNodeId, nextNodeId);
    await this.fetchNewEdges(prevNodeId, node.nodeId);

    this.newEdges = this.newEdges.map(
      (value) => {
        const firstNewSegment = {
          firstNode: prevNodeId, lastNode: node.nodeId, edges: value, order: 0,
        };

        this.addNodeToDirection(node, index);
        this.addSegmentToDirection(firstNewSegment, index - 1);
        return [];
      });

    await this.fetchNewEdges(node.nodeId, nextNodeId);

    return this.newEdges.cata(
      (e) => Promise.reject(e),
      (value) => {
        const secondNewSegment = {
          firstNode: node.nodeId,
          lastNode: nextNodeId,
          edges: value,
          order: 0,
        };

        this.addSegmentToDirection(secondNewSegment, index);
        return Promise.resolve(this.direction);
      });
  }

  private getExtremeNodesOfBreak(order: number) {
    const minOrderNode = minBy(this.direction.nodes, (o) => o.order) || this.direction.nodes[0];
    const maxOrderNode = maxBy(this.direction.nodes, (o) => o.order) || this.direction.nodes[0];
    const prevNode = this.direction.nodes.reduce((current, node) => {
      return node.order > current.order && node.order < order ? node : current;
    }, minOrderNode);
    const nextNode = this.direction.nodes.reduce((current, node) => {
      return node.order < current.order && node.order > order ? node : current;
    }, maxOrderNode);
    const prevNodeId = prevNode?.nodeId;
    const nextNodeId = nextNode?.nodeId;
    return [prevNodeId, nextNodeId];
  }

  private async fetchNewEdges(prevNodeId: number, nextNodeId: number) {
    const edges = await this.findPathSegment(prevNodeId, nextNodeId);
    if (isPathEdges(edges)) {
      this.newEdges = this.newEdges.map(() => edges);
    } else {
      this.newEdges = Left(new Error('Failed to get new route segment.'));
    }
  }

  private removeSegmentFromDirection(firstNodeId: number, lastNodeId: number) {
    const newPath = this.direction.path
      .sort((a, b) => a.order - b.order)
      .filter((edge) => !(edge.firstNode === firstNodeId && edge.lastNode === lastNodeId));

    this.direction = {
      ...this.direction,
      path: newPath,
    };
  }

  private addNodeToDirection(newNode: TrackPoint, index: number) {
    const newNodes = [...this.direction.nodes];
    newNodes
      .sort((a, b) => a.order - b.order)
      .splice(index, 0, newNode);

    this.direction = {
      ...this.direction,
      nodes: newNodes
        .map((node, i) => ({
          ...node,
          order: i,
        })),
    };

    return this.direction;
  }

  private addSegmentToDirection(newSegment: PathSegment, index: number) {
    const newPath = [...this.direction.path];
    newPath
      .sort((a, b) => a.order - b.order)
      .splice(index, 0, newSegment);

    this.direction = {
      ...this.direction,
      path: newPath.map((segment, i) => ({
        ...segment,
        order: i,
      })),
    };
  }

  private removeNodeFromDirection(
    nodeToDel: TrackPoint, prevNodeId: number, nextNodeId: number,
  ): Either<Error, AcRouteDirection> {
    const newNodes = this.direction.nodes
      .sort((a, b) => a.order - b.order)
      .filter(node => !(node.nodeId === nodeToDel.nodeId && node.order === nodeToDel.order))
      .map((n, index) => ({ ...n, order: index }));

    return this.newEdges.cata(
      (e: Error) => Left(e),
      (value) => {
        const newPath = this.direction.path
          .sort((a, b) => a.order - b.order)
          .filter((edge) => {
            const isStartingWithNodeToDel = edge.firstNode === nodeToDel.nodeId && edge.lastNode === nextNodeId;
            const isTrailingEdgeToDel = (
              nodeToDel.nodeId === nextNodeId &&
              edge.firstNode === prevNodeId &&
              edge.lastNode === nodeToDel.nodeId
            );
            return !isStartingWithNodeToDel && !isTrailingEdgeToDel;
          })
          .map((edge) => {
            if (edge.firstNode === prevNodeId && edge.lastNode === nodeToDel.nodeId) {
              return {
                ...edge,
                lastNode: nextNodeId,
                edges: value,
              };
            }
            return edge;
          });

        this.direction = {
          ...this.direction, path: newPath, nodes: newNodes,
        };

        return Right(this.direction);
      },
    );
  }
}

export default Pathfinder;
