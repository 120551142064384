import { forms } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import { Systems } from 'src/constants';
import { PageProjectForm, PROJECTS_PAGES_FORM_NAMES } from 'src/modules/general/projects';
import { MACROECONOMICS_FORM_NAMES, MACROECONOMICS_MODEL_NAMES, PageMacroeconomicsForm } from '../constants';

export const getMacroeconomicsActions = (type: Systems) => {
  const modelName = MACROECONOMICS_MODEL_NAMES[type];
  const macroeconomicsPageFormName = MACROECONOMICS_FORM_NAMES[type];
  return {
    applyCorrections: (): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
      const state = getState();
      const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[type];
      const macroeconomicsPageForm =
        forms.selectors[macroeconomicsPageFormName].getFormWithNulls<PageMacroeconomicsForm>(state);
      const pageProjectForm = forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state);
      return dispatch(forms.actions.sendQuickForm({
        baseConfig: 'baseGrpCorrectionForm',
        model: modelName,
        method: 'PATCH',
        specialAction: 'correction',
        values: {
          project: pageProjectForm.selectedProject,
          percent: macroeconomicsPageForm.grpCorrection,
        },
      }));
    },
  };
};
