import { createSelector } from 'reselect';

import { featureCollection } from 'src/helpers';
import { GEOJSON_ENDPOINT } from 'src/api-url-schema';

import { AC_REGIONS_ENDPOINT } from 'src/cluster/common';

import { selectAcProjectListForm, selectGeojsonForm, selectProjectEndpoint } from './common';

export const selectDistrictGeojson = createSelector(
  selectGeojsonForm,
  (geojsonForm) => geojsonForm.districts ?? featureCollection([]),
);

export const selectNodeGeojson = createSelector(
  selectGeojsonForm,
  (geojsonForm) => geojsonForm.nodes ?? featureCollection([]),
);

export const selectEdgeGeojson = createSelector(
  selectGeojsonForm,
  (geojsonForm) => geojsonForm.edges ?? featureCollection([]),
);

export const selectPolygonsGeojsonEndpoint = createSelector(
  [
    selectProjectEndpoint,
    selectAcProjectListForm,
  ],
  (projectEntities, pageProjectsForm) => {
    const { apiHost, apiPrefix, endpoint } = projectEntities;
    if (!pageProjectsForm.selectedProject) {
      return undefined;
    }
    const projectEndpoint = `${apiHost}${apiPrefix}${endpoint}`;

    return `${projectEndpoint}${pageProjectsForm.selectedProject}/${AC_REGIONS_ENDPOINT}${GEOJSON_ENDPOINT}`;
  },
);
