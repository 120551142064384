import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema,
  RouteScheme,
  getUrl,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';
import { Systems, SystemsContext } from 'src/constants';

import { ProjectSelectorDropDown } from 'src/modules/general/projects';
import { MatrixProjectsPage } from 'src/modules/matrix/projects';
import { MatrixMacroPage } from 'src/modules/matrix/macroeconomics';
import { MatrixTransportPage } from 'src/modules/matrix/transport';
import { MatrixForecastPage } from 'src/modules/matrix/forecast';

interface Props extends WithTranslate {
  match?: any;
}

const MatrixRoot: FC<Props> = (props) => {
  const {
    match,
    t,
  } = props;

  return (
    <SystemsContext.Provider value={Systems.matrix}>
      <PageWithHeader
        basePath={['matrix']}
        menuRenderers={{
          projects: {
            label: t('modules.projects.captions.title'),
          },
          macroeconomics: {
            label: t('modules.macroeconomics.captions.title'),
          },
          transport: {
            label: t('modules.transport.captions.title'),
          },
          forecast: {
            label: t('modules.forecast.captions.title'),
          },
        }}
        headerChildren={(
          <Route path={getUrl(urlSchema.matrix.projects)}>
            {({ match: currentMatch }) => {
              if (currentMatch) return null;
              return <ProjectSelectorDropDown />;
            }}
          </Route>
        )}
      >
        <RouteScheme
          prevMatch={match}
          basePath={['matrix']}
          renderers={{
            projects: {
              component: MatrixProjectsPage,
            },
            macroeconomics: {
              component: MatrixMacroPage,
            },
            transport: {
              component: MatrixTransportPage,
            },
            forecast: {
              component: MatrixForecastPage,
            },
          }}
        />
      </PageWithHeader>
    </SystemsContext.Provider>
  );
};

export default MatrixRoot;
