import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { isUndefined } from 'src/helpers';
import { PageNavLink } from 'src/pages';

import useStyles from './styles';

interface Props extends WithTranslate {
  className?: string;
  label: string;
  subLabel?: string;
  icon?: ReactNode;
  navKey: string | string[];
  baseUrl: string;
}

const HeaderMenu: FC<Props> = (props) => {
  const {
    className,
    label,
    subLabel,
    icon,
    navKey,
    baseUrl,
    t,
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <PageNavLink
        navKey={navKey}
        baseUrl={baseUrl}
        activeClassName={classes.active}
        className={classes.url}
      >
        <div className={classes.linkContent}>
          {icon && <span className={classes.icon}>{icon}</span>}
          <div>
            {t(label)}
            {!isUndefined(subLabel) && <div>{t(subLabel)}</div>}
          </div>
        </div>
      </PageNavLink>
    </div>
  );
};

export default HeaderMenu;
