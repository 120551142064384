import { useSelector } from 'react-redux';

import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';

import {
  LegendType,
  DrawerLegendSelect,
  useGenerateItems,
  selectEditorLegendForm,
  selectTransportationTypes,
  selectCommunicationTypes,
} from 'src/cluster/common';

const useTransportTypesOptions = () => useGenerateItems(selectTransportationTypes);
const useCommunicationTypesOptions = () => useGenerateItems(selectCommunicationTypes);

const useLegendTypes = (
  setLegendField: (key: LegendType, val: SelectValue) => void,
): DrawerLegendSelect[] => {
  const {
    isBusNetwork,
    isTramwayNetwork,
    isTrolleybusNetwork,
    isSuburbanNetwork,
    isWaterNetwork,
    regularTransportationType,
    communicationType,
  } = useSelector(selectEditorLegendForm);

  const canFiltered = isBusNetwork ||
    isTramwayNetwork ||
    isTrolleybusNetwork ||
    isSuburbanNetwork ||
    isWaterNetwork;

  const transportTypesOptions = useTransportTypesOptions();
  const communicationTypeOptions = useCommunicationTypesOptions();

  const transportTypeValue = transportTypesOptions.find(opt => opt.value === regularTransportationType);
  const communicationTypeValue = communicationTypeOptions.find(opt => opt.value === communicationType);

  return [
    {
      label: LegendType.regularTransportationType,
      multiple: false,
      disabled: !canFiltered,
      items: transportTypesOptions,
      selected: transportTypeValue,
      command: (val: AutocompleteOption) => {
        const { value } = val as SelectMenuOption || {};
        setLegendField(LegendType.regularTransportationType, value);
      },
    },
    {
      label: LegendType.communicationType,
      multiple: false,
      disabled: !canFiltered,
      items: communicationTypeOptions,
      selected: communicationTypeValue,
      command: (val: AutocompleteOption) => {
        const { value } = val as SelectMenuOption || {};
        setLegendField(LegendType.communicationType, value);
      },
    },
  ];
};

export default useLegendTypes;
