import { ModelDefaults } from 'src/types';
import { AcVehicleClassesModel, VEHICLE_CLASSES_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

const defaults: ModelDefaults<AcVehicleClassesModel> = {
  id: undefined,
  name: undefined,
  vehicleTypeId: undefined,
  sits: undefined,
  area: undefined,
  capacity: undefined,
};

export default {
  endpoint: VEHICLE_CLASSES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults,
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
