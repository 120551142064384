import { createSelector } from 'reselect';
import { forms } from 'redux-restify';

import { getToken } from 'src/storage';

import { AuthInfoForm } from './forms/authInfo.form';

export const selectIsAuthenticated = createSelector(
  state => forms.selectors.authInfoForm.getForm(state),
  (authInfoForm: AuthInfoForm) => {
    const token = getToken();
    return !!(
      token &&
      token !== 'null' &&
      authInfoForm.permissions &&
      authInfoForm.username &&
      authInfoForm.permissions.length
    );
  },
);
