import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layer } from 'deck.gl';

import { FeatureCollection } from 'src/interfaces';
import { isNumber } from 'src/helpers';

import { selectAcProjectListForm } from 'src/cluster/common';
import { selectEditorGraphForm } from 'src/cluster/editor-common';
import {
  loadEdges,
  loadNodes,
} from 'src/cluster/editor-map';

import useEdgeLayer from './useEdgeLayer';
import usePointsLayers from './usePointsLayers';
import useDirectionLayer from './useDirectionLayer';

const useEditorLayers = (): Layer<FeatureCollection>[] => {
  const dispatch = useDispatch();
  const { selectedProject } = useSelector(selectAcProjectListForm);
  const { year, scenarioId, intervalId } = useSelector(selectEditorGraphForm);

  const roadLayer = useEdgeLayer();
  const directionLayer = useDirectionLayer();
  const [nodeLayer, stopLayer] = usePointsLayers();

  const [currentProject, setCurrentProject] = useState<number>();
  const [currentYear, setCurrentYear] = useState<number>();
  const [currentScenario, setCurrentScenario] = useState<number>();
  const [currentInterval, setCurrentInterval] = useState<number>();

  useEffect(() => {
    let canSetState = true;

    const isParamsValid = isNumber(selectedProject) && isNumber(year) && isNumber(scenarioId);
    const isParamsNew = currentProject !== selectedProject
      || currentYear !== year
      || currentScenario !== scenarioId;

    if (isParamsValid && isParamsNew) {
      dispatch(loadNodes());
    }

    const isIntervalValid = isNumber(intervalId);
    const isIntervalNew = currentInterval !== intervalId;

    if (isParamsValid && isIntervalValid && (isParamsNew || isIntervalNew)) {
      dispatch(loadEdges());
    }

    if (canSetState) {
      setCurrentProject(selectedProject);
      setCurrentYear(year);
      setCurrentScenario(scenarioId);
      setCurrentInterval(intervalId);
    }

    return () => {
      canSetState = false;
    };
  }, [selectedProject, year, scenarioId, intervalId]);

  return [
    roadLayer,
    nodeLayer,
    stopLayer,
    directionLayer,
  ];
};

export default useEditorLayers;
