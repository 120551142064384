import React, { FC, useEffect } from 'react';
import { Maybe } from 'monet';

import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';

import { AcDashboardTablePageForm } from 'src/cluster/common';
import { AcDashCategoriesModel, AcDashIndicatorResultsModel } from 'src/cluster/dashboard-common';
import { DashboardDataGrid, DashTableToolbar } from 'src/cluster/dashboard-table';

import useStyles from './styles';

export interface InjectedProps extends WithTranslate {
  pageForm: AcDashboardTablePageForm;
  categories: Model<AcDashCategoriesModel>[];
  baseIndicators: Maybe<Model<AcDashIndicatorResultsModel>[]>;
  forecastIndicators: Maybe<Model<AcDashIndicatorResultsModel>[]>;
  onInit(): void;
}

const DashTablePage: FC<InjectedProps> = (props) => {
  const {
    categories, baseIndicators, forecastIndicators, onInit,
  } = props;

  const classes = useStyles();

  useEffect(onInit, []);

  return (
    <div className={classes.root}>
      <DashTableToolbar />
      <DashboardDataGrid
        categories={categories}
        baseIndicators={baseIndicators.orJust([])}
        forecastIndicators={forecastIndicators.orJust([])}
        isLoading={false}
      />
    </div>
  );
};

export default DashTablePage;
