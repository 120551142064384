import React from 'react';

const CursorIcon = () => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.590332 0L1.59033 18.5L6.59033 13L10.5903 18.5L12.0903 17.5L8.09033 12L15.5903 11L0.590332 0Z"
      fill="currentColor"
    />
  </svg>
);

export default CursorIcon;
