import React, { useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { WithTranslate } from 'src/i18n';
import { useRestifyForm } from 'src/helpers';

import PTable from 'src/components/deprecated/PTable';
import PInput from 'src/components/deprecated/PInput';
import PIcon from 'src/components/deprecated/PIcon';
import NumberFormat from 'src/components/etc/NumberFormat';
import Pagination from 'src/components/etc/Pagination';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import { UNITS } from 'src/modules/general/common';

import { EdgeRoute } from '../../../common/interfaces';
import { ICON_TYPES } from '../../../common/constants';
import { EDGE_ROUTES_FORM_NAME } from '../../constants';
import { selectEdgeRoutes } from '../../selectors';
import { EdgeRoutesForm } from '../../interfaces';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  transportType: string;
  edgeId: number;
}

const ModalAggloCalcEdgeRoutes: React.FC<Props> = (props: Props) => {
  const {
    className,
    transportType,
    edgeId,
    t,
    locale,
  } = props;
  const dispatch = useDispatch();
  const [form, formActions] = useRestifyForm<EdgeRoutesForm>(EDGE_ROUTES_FORM_NAME);
  const { edgeRoutes, edgesRoutesIsLoading, count } = useSelector(selectEdgeRoutes);

  useEffect(() => {
    dispatch(formActions.changeField('transportType', transportType));
    dispatch(formActions.changeField('edgeId', edgeId));
    return () => {
      dispatch(formActions.resetField('currentPage'));
      dispatch(formActions.resetField('search'));
      dispatch(formActions.resetField('transportType'));
    };
  }, []);

  useEffect(() => {
    const { pageSize, currentPage } = form;
    if (pageSize * currentPage > count) {
      dispatch(formActions.resetField('currentPage'));
    }
  }, [count, form.pageSize]);

  return (
    <div className={classNames(style.root, className)}>
      <div className={style.header}>
        <h2 className={style.title}>{t('common.captions.correspondences')}</h2>
        <PInput {...{
          className: style.search,
          value: form.search,
          placeholder: t('common.captions.search'),
          onChange: (value) => dispatch(formActions.changeField('search', value)),
        }} />
      </div>
      <LoadingBlockContainer {...{
        isBlocked: edgesRoutesIsLoading,
        componentProps: {
          style: { minHeight: form.pageSize * 44 + 105 },
        },
      }}>
        <Pagination {...{
          className: style.pagination,
          fixed: false,
          availablePageSizes: [5, 10, 15],
          pageSize: form.pageSize,
          currentPage: form.currentPage,
          count,
          onPageChange: (page: number) => dispatch(formActions.changeField('currentPage', page)),
          onPageSizeChange: (pageSize: number) => {
            dispatch(formActions.resetField('currentPage'));
            dispatch(formActions.changeField('pageSize', pageSize));
          },
        }}>
          <PTable {...{
            className: style.table,
            header: [
              {
                title: t('common.captions.correspondence'),
                model: (model: EdgeRoute) => (
                  <Fragment>
                    <PIcon {...{
                      className: style.icon,
                      type: ICON_TYPES[model.transportType],
                    }} />
                    {`${model.origin} - ${model.destination}`}
                  </Fragment>
                ),
              },
              {
                className: style.alignRight,
                title: (
                  <span>
                    {t('systems.mth.captions.traffic')}
                    &nbsp;
                    <span className={style.units}>
                      (
                      {t(UNITS.vehicles)}
                      )
                    </span>
                  </span>
                ),
                model: (model: EdgeRoute) => (
                  <NumberFormat {...{
                    value: model.traffic,
                    locale,
                    decimalScale: 2,
                    zeroPlaceholder: '-',
                  }} />
                ),
              },
              {
                className: style.alignRight,
                title: (
                  <span>
                    {t('systems.mth.captions.passengerFlow')}
                    &nbsp;
                    <span className={style.units}>
                      (
                      {t(UNITS.peoplePerHour)}
                      )
                    </span>
                  </span>
                ),
                model: (model: EdgeRoute) => (
                  <NumberFormat {...{
                    value: model.trafficPass,
                    locale,
                    decimalScale: 2,
                    zeroPlaceholder: '-',
                  }} />
                ),
              },
            ],
            body: edgeRoutes,
          }} />
        </Pagination>
      </LoadingBlockContainer>
    </div>
  );
};

export default ModalAggloCalcEdgeRoutes;
