import {
  ComponentType, createElement, FC, useCallback,
} from 'react';
import { TFunction } from 'i18next';

import { useTranslation, Trans } from 'react-i18next';

import { Locale } from 'src/types';

export interface WithTranslate {
  locale: Locale;
  t: TFunction;
  setLocale(locale: Locale): void;
  toggleLocale(): void;
  Trans: typeof Trans;
}

const withTranslate = <P extends WithTranslate>(WrappedComponent: ComponentType<P>) => {
  const ComponentWithTranslate: FC<Omit<P, keyof WithTranslate>> = (originalProps) => {
    const [t, i18n] = useTranslation();

    const locale: Locale = i18n.language === 'ru' ? 'ru' : 'en';
    const setLocale: (locale: Locale) => void = (newLocale: Locale) => i18n.changeLanguage(newLocale);

    const toggleLocale = useCallback(() => {
      const newLocale = i18n.language === 'ru' ? 'en' : 'ru';
      i18n.changeLanguage(newLocale);
    }, [i18n]);

    const props = {
      ...originalProps,
      locale,
      t,
      setLocale,
      toggleLocale,
      Trans,
    } as P & WithTranslate;

    return createElement(WrappedComponent, props);
  };

  return ComponentWithTranslate;
};

export default withTranslate;
