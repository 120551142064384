import { Model } from 'src/interfaces';

import {
  AC_ROUTES_MODEL_NAME,
  AC_STOPS_MODEL_NAME,
  AcStopsModel,
  AcRoutesModel,
  AcRouteVariant,
  AcRouteDirection,
} from 'src/cluster/common';

export const AC_EDGE_SPEEDS_MODEL_NAME = 'acEdgeSpeeds';
export const AC_EDGE_SPEED_CHANGES_MODEL_NAME = 'acEdgeSpeedChanges';
export const AC_REGISTRY_ROUTES_MODEL_NAME = 'acRegistryRoutes';

export const FIND_PATH_ENDPOINT = 'find-path/';
export const EXPORT_VARIANT_ENDPOINT = 'route-variants/';
export const EDGE_SPEEDS_ENDPOINT = 'edge_speeds/';
export const EDGE_SPEED_CHANGES_ENDPOINT = 'edge_speed_changes/';

export const AC_EDITOR_SELECT_NODE_MODAL = 'selectNodeModal';

export const MIN_EDGE_SPEED = 5;

export const LEGEND_NODES = [
  {
    title: 'modules.editor.legendNodes.selectedStop',
    props: {
      height: 36,
      width: 36,
      border: 6,
      borderRadius: '50%',
      bgcolor: 'common.white',
    },
  },
  {
    title: 'modules.editor.legendNodes.stoppingOnTheRoute',
    props: {
      height: 24,
      width: 24,
      border: 4,
      borderRadius: '50%',
      bgcolor: 'common.white',
    },
  },
  {
    title: 'modules.editor.legendNodes.stop',
    props: {
      height: 13,
      width: 13,
      border: 3,
      borderRadius: '50%',
      bgcolor: 'common.white',
    },
  },
  {
    title: 'modules.editor.legendNodes.selectedAnchorPoint',
    props: { height: 13, width: 13, borderRadius: '50%' },
  },
  {
    title: 'modules.editor.legendNodes.referencePoint',
    props: { height: 9, width: 9, borderRadius: '50%' },
  },
  {
    title: 'modules.editor.legendNodes.srnNode',
    props: {
      height: 9,
      width: 9,
      border: 1,
      borderRadius: '50%',
      borderColor: '#bfbfbf',
      bgcolor: '#dbdbdb',
    },
  },
];

export const EMPTY_STOP: Model<AcStopsModel> = {
  $loading: false,
  $error: false,
  $old: false,
  $modelType: AC_STOPS_MODEL_NAME,
  id: -1,
  nodeId: -1,
  stopName: '',
  vehicleTypes: [],
  routeDirections: [],
  coordinates: [0, 0],
  routeVehicleTypes: [],
  communicationTypes: [],
  regularTransportationTypes: [],
};

export const EMPTY_DIRECTION: AcRouteDirection = {
  id: -1,
  path: [],
  nodes: [],
  direction: true,
  length: 0,
  longName: '',
};

export const EMPTY_REVERSE_DIRECTION: AcRouteDirection = {
  id: -2,
  path: [],
  nodes: [],
  direction: false,
  length: 0,
  longName: '',
};

export const EMPTY_VARIANT: AcRouteVariant = {
  id: -1,
  variantNumber: '',
  routeDirections: [EMPTY_DIRECTION],
};

export const EMPTY_ROUTE: Model<AcRoutesModel> = {
  $loading: false,
  $error: false,
  $old: false,
  $modelType: AC_ROUTES_MODEL_NAME,
  id: -1,
  isCircle: false,
  routeNumber: '',
  routeLongName: '-',
  vehicleTypeId: -1,
  vehicleClasses: [],
  variants: [EMPTY_VARIANT],
};
