import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useButtonStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '20px',
    boxShadow: 'none',
    borderRadius: '5px',
    minWidth: theme.spacing(12),
    textTransform: 'none',
    fontWeight: 600,
  },
}));

export const useLinkStyles = makeStyles(({ spacing }) => createStyles({
  root: ({ disabled }: { disabled: boolean }) => ({
    cursor: disabled ? 'default' : 'pointer',
    display: 'inline-flex',
    gap: spacing(1),
    textTransform: 'none',
    minWidth: 'initial',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: 'none',
    padding: 0,
    border: 0,
  }),
}));

export const useIconStyles = makeStyles(() => createStyles({
  root: {
    display: 'inline-flex',
    textTransform: 'none',
    minWidth: 'initial',
    lineHeight: '20px',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: 'none',
    padding: 0,
    border: 0,
  },

  label: {
    textTransform: 'uppercase',
  },

  textPrimary: {
    '&:hover': {
      backgroundColor: 'transparent',
      textTransform: 'uppercase',

    },
  },
}));
