import React, { FC } from 'react';
import clsx from 'clsx';
import {
  FormControl,
  SelectProps,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  withStyles,
} from '@material-ui/core';

import { CommonInput, CommonLabel } from 'src/components';
import { SelectMenuOption } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';

import { sortOptionsByDefault } from 'src/helpers';
import useStyles, { menuItemStyles, useSelectStyles, useMenuClasses } from './styles';

const MenuItem = withStyles(menuItemStyles)(MuiMenuItem);

interface OwnProps {
  label?: string;
  options: SelectMenuOption[];
  size?: 'normal' | 'slim';
  hidden?: boolean;
  sort?: ((options: SelectMenuOption[]) => SelectMenuOption[]) | false;
  disableEmpty?: boolean;
  emptyLabel?: string;
}

export type Props = SelectProps & OwnProps & WithTranslate;

const SelectField: FC<Props> = (props) => {
  const {
    id,
    className,
    label,
    value = '',
    options,
    open,
    input = <CommonInput />,
    variant = 'outlined',
    multiple,
    disabled,
    displayEmpty = true,
    disableEmpty = true,
    emptyLabel,
    fullWidth,
    hidden,
    sort = sortOptionsByDefault,
    t,
    onChange,
    onOpen,
    onClose,
  } = props;

  const classes = useStyles();
  const menuClasses = useMenuClasses();
  const selectClasses = useSelectStyles(props);

  const { length } = options;

  const currentValue = length === 0 ? '' : value;
  const showNoOptions = length === 0;
  const showNotSelected = emptyLabel || (length && value === '');

  const sortedOptions = sort ? sort(options) : options;

  return (
    <FormControl fullWidth={fullWidth} className={clsx(hidden && classes.hidden, className)}>
      {label && (
        <CommonLabel
          shrink
          htmlFor={id}
        >
          {label}
        </CommonLabel>
      )}
      <MuiSelect
        id={id}
        value={currentValue}
        input={input}
        variant={variant}
        open={open}
        multiple={multiple}
        disabled={disabled || length === 0}
        displayEmpty={displayEmpty}
        MenuProps={{
          transitionDuration: 200,
          classes: menuClasses,
        }}
        classes={selectClasses}
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
      >
        {showNoOptions && (
          <MenuItem value="" disabled>
            <span>{t('components.captions.noOptions')}</span>
          </MenuItem>
        )}
        {showNotSelected && (
          <MenuItem value="" disabled={disableEmpty}>
            <span>{emptyLabel ?? t('components.captions.notSelected')}</span>
          </MenuItem>
        )}
        {sortedOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.value}
          >
            <span>{option.label}</span>
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default SelectField;
