import { useSelector } from 'react-redux';

import { SelectorType } from '../../interfaces/common';

const useGetIdFromList = (selector: SelectorType): (id: number) => string => {
  const list = useSelector(selector);
  return (id: number) => {
    return list.map((items) => {
      return items.find((type) => type.id === id)?.name ?? '-';
    }).orJust('-');
  };
};

export default useGetIdFromList;
