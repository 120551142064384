import { featureCollection } from '@turf/turf';
import { NodeModel, RouteModel } from 'src/modules/general/common';
import { emptyCollection, makeGeoJsonPoints } from 'src/helpers';
import {
  makeGeoJsonEdgesWithVirtualEdges,
  makeGeoJsonEdgesPointsWithVirtualEdges,
} from './geojson';

export const getMapStyleSourcesWithVirtualEdges = (
  currentRoutes: RouteModel[],
  startPolygonUrl?: string,
  finishPolygonUrl?: string,
) => {
  return {
    points: emptyCollection,
    edges: makeGeoJsonEdgesWithVirtualEdges(currentRoutes, {}),
    edgesPoints: makeGeoJsonEdgesPointsWithVirtualEdges(currentRoutes),
    polygons: emptyCollection,
    startPolygon: {
      type: 'geojson',
      data: startPolygonUrl || featureCollection([]),
    },
    finishPolygon: {
      type: 'geojson',
      data: finishPolygonUrl || featureCollection([]),
    },
  };
};

export const getMapStyleSourceByUrl = (
  geojsonUrl: string | undefined,
  polygonsUrl: string | undefined,
  currentNodes: NodeModel[],
  pointsExtras: any,
) => {
  return {
    points: makeGeoJsonPoints(currentNodes, pointsExtras),
    edges: {
      type: 'geojson',
      data: geojsonUrl || featureCollection([]),
    },
    edgesPoints: emptyCollection,
    polygons: {
      type: 'geojson',
      data: polygonsUrl || featureCollection([]),
    },
    startPolygon: emptyCollection,
    finishPolygon: emptyCollection,
  };
};

export const getMapStylePolygonLayer = (visible: boolean, selectedRegionName = '') => {
  return [
    {
      id: 'polygons',
      filter: ['all'],
      layout: {
        visibility: 'visible',
      },
      source: 'polygons',
      type: 'fill',
      paint: { 'fill-color': 'transparent', 'fill-outline-color': '#909090', 'fill-opacity': 0.5 },
    },
    {
      id: 'start-polygon',
      filter: ['all'],
      layout: {
        visibility: visible ? 'none' : 'visible',
      },
      source: 'startPolygon',
      type: 'fill',
      paint: { 'fill-color': 'rgba(255, 128, 128, 0.7)', 'fill-outline-color': '#333b46' },
    },
    {
      id: 'finish-polygon',
      filter: ['all'],
      layout: {
        visibility: visible ? 'none' : 'visible',
      },
      source: 'finishPolygon',
      type: 'fill',
      paint: { 'fill-color': 'rgba(204, 14, 208, 0.7)', 'fill-outline-color': '#333b46' },
    },
    {
      id: 'selected-polygon',
      filter: ['==', 'name', selectedRegionName],
      layout: {
        visibility: visible ? 'visible' : 'none',
      },
      source: 'polygons',
      type: 'fill',
      paint: { 'fill-color': 'rgba(124,68,250,0.7)', 'fill-outline-color': '#333b46' },
    },
  ];
};
