import { AcBalanceDataModel } from '../common';

export function isRecordValid(record: AcBalanceDataModel) {
  return record.regionId
    && (record.year > 1900 && record.year < 2100)
    && record.factors.every((f) => f.value >= 0);
}

export function areRecordsValid(newData: AcBalanceDataModel[]) {
  return newData.every(isRecordValid);
}
