import React, { ReactNode, Fragment, FC } from 'react';
import classNames from 'classnames';

import { VocabularyEntity, WithTranslate } from 'src/i18n';

import { toNumber } from 'src/helpers';

import { DEFAULT_NUMBER_ACCURACY } from 'src/constants';
import {
  EdgeModel,
  EDGE_TYPES,
  unitsMessages,
  typeMessages,
} from '../../constants';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  model?: EdgeModel;
  onMouseOver?: (event: any) => void;
  onMouseOut?: (event: any) => void;
}

const EdgePopup: FC<Props> = ({
  className,
  model,
  onMouseOver,
  onMouseOut,
  t,
}) => {
  if (!model) return null;
  const isPort = model.type === EDGE_TYPES.port;
  const isBorderCross = model.type === EDGE_TYPES.autoBorderCross || model.type === EDGE_TYPES.railBorderCross;

  let title = t('modules.routes.captions.routeInfo');
  if (isPort) {
    title = t('systems.shipments.captions.portInfo');
  } else if (isBorderCross) {
    title = t('systems.shipments.captions.borderCrossInfo');
  }

  const renderTableLine = (text: ReactNode, content: ReactNode) => (
    <tr className={style.row}>
      <td className={style.column}>
        {text}
      </td>
      <td className={style.column}>
        {content}
      </td>
    </tr>
  );
  const renderTableColumnValue = (value: ReactNode, message: string) => (
    <span className={style.columnValue}>
      <span>
        {value}
      </span>
      <span>
        &nbsp;
      </span>
      <span>{message}</span>
    </span>
  );
  const renderNumber = (value: number) => toNumber(value, DEFAULT_NUMBER_ACCURACY);
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div {...{
      className: classNames(style.root, className),
      onMouseOver,
      onMouseOut,
    }}>
      <div className={style.title}>
        {title}
      </div>
      <table className={style.table}>
        <tbody>
          {isPort && (
            <Fragment>
              {renderTableLine(
                t('common.captions.name'),
                <VocabularyEntity value={model.name} />,
              )}
              {renderTableLine(
                t('systems.shipments.captions.time'),
                renderTableColumnValue(model.time, t(unitsMessages.time)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.rate'),
                renderTableColumnValue(renderNumber(model.tariff), t(unitsMessages.cost)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.capacity'),
                renderTableColumnValue(model.capacity, t(unitsMessages.capacity)),
              )}
            </Fragment>
          )}
          {isBorderCross && (
            <Fragment>
              {renderTableLine(
                t('common.captions.name'),
                <VocabularyEntity value={model.name} />,
              )}
              {renderTableLine(
                t('systems.shipments.captions.time'),
                renderTableColumnValue(model.time, t(unitsMessages.time)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.capacity'),
                renderTableColumnValue(model.capacity, t(unitsMessages.capacity)),
              )}
            </Fragment>
          )}
          {!isBorderCross && !isPort && (
            <Fragment>
              {renderTableLine(
                t('systems.shipments.captions.type'),
                t(typeMessages[model.type]),
              )}
              {renderTableLine(
                t('common.captions.name'),
                <VocabularyEntity value={model.name} />,
              )}
              {renderTableLine(
                t('systems.shipments.captions.country'),
                <VocabularyEntity value={model.county} />,
              )}
              {renderTableLine(
                t('systems.shipments.captions.length'),
                renderTableColumnValue(model.distance, t(unitsMessages.length)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.unitCost'),
                renderNumber(model.tariffHour),
              )}
              {renderTableLine(
                t('systems.shipments.captions.rate'),
                renderTableColumnValue(renderNumber(model.tariff), t(unitsMessages.rate)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.speed'),
                renderTableColumnValue(renderNumber(model.speed), t(unitsMessages.speed)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.time'),
                renderTableColumnValue(renderNumber(model.time), t(unitsMessages.time)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.costPerKm'),
                renderTableColumnValue(renderNumber(model.costPerKm), t(unitsMessages.unitTimeCost)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.timeCost'),
                renderTableColumnValue(renderNumber(model.timeCost), t(unitsMessages.cost)),
              )}
              {renderTableLine(
                t('systems.shipments.captions.cargoCost'),
                renderNumber(model.totalCost),
              )}
            </Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EdgePopup;
