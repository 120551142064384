import React from 'react';

const WaterIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7988 14.3745C4.02129 14.2317 3.29602 13.8657 2.7156 13.3135C1.95366 12.5887 1.49975 11.5985 1.44789 10.5481L1.00562 10.5699L0.449109 10.5974L0.00683594 10.6193C0.0234863 10.9565 0.0707888 11.2892 0.147041 11.6135C0.391933 12.6553 0.935422 13.6114 1.72113 14.3589C2.75148 15.3391 4.1194 15.8854 5.54152 15.8846C6.96365 15.8839 8.33098 15.3361 9.36028 14.3548C10.1452 13.6065 10.6877 12.6498 10.9314 11.6078C11.0073 11.2833 11.0543 10.9506 11.0706 10.6133L10.6283 10.592L10.0717 10.5651L9.62944 10.5437C9.57871 11.5942 9.12586 12.5848 8.3647 13.3105C7.78396 13.8642 7.0577 14.2312 6.27896 14.3743V8.27965H8.49946V6.7245H6.27896V5.03457C7.14065 4.71486 7.75911 3.85067 7.75911 2.83467C7.75911 1.54509 6.76475 0.5 5.53854 0.5C4.31233 0.5 3.31797 1.54517 3.31797 2.83467C3.31797 3.85067 3.93643 4.71486 4.7988 5.03457V6.72442H2.57823V8.27957H4.7988V14.3745ZM4.79888 2.83467C4.79888 3.26284 5.13073 3.61157 5.53862 3.61157C5.9465 3.61157 6.27896 3.26276 6.27903 2.83467C6.27903 2.40531 5.94657 2.0565 5.53862 2.0565C5.13073 2.0565 4.79888 2.40523 4.79888 2.83467Z"
      fill="currentColor"
    />
  </svg>
);

export default WaterIcon;
