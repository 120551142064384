import { useEffect } from 'react';
import WebMercatorViewport from 'viewport-mercator-project';
import { LinearInterpolator } from 'react-map-gl';

import { MAP_BOUND_PADDING } from 'src/constants';
import { CommonEdgeModel, CommonNodeModel, CommonRouteModel } from 'src/interfaces';
import palette from 'src/theme/palette';
import { mergeAndConcatArrays } from 'src/helpers/nested-objects';

import {
  bbox, featureCollection, lineString, point,
} from './geojson';
import streetsMapStyle from './streets-map-style';

interface SetViewportProps {
  (currentViewport: (currentViewport: any) => any): void;
}

export const getExtendedMapStyle = (style: any, locale = 'en') => {
  return mergeAndConcatArrays({}, streetsMapStyle(locale), style);
};

export const useMapFittedToBounds = (
  currentRoutes: any[], currentRoutesIsLoading: boolean,
  viewport: any, setViewport: SetViewportProps,
) => {
  useEffect(() => {
    if (!currentRoutesIsLoading && currentRoutes.length) {
      const summaryRoute = currentRoutes.reduce<number[][]>((memo, route) => {
        return memo.concat(route.traffic.map((edge: any) => edge.firstNode));
      }, []);
      if (!summaryRoute.length) {
        return;
      }
      const [minLng, minLat, maxLng, maxLat] = bbox(lineString(summaryRoute));
      const newViewport = new WebMercatorViewport(viewport);
      try {
        const {
          latitude,
          longitude,
          zoom,
        } = newViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: MAP_BOUND_PADDING,
        });
        setViewport(currentViewport => ({
          ...currentViewport,
          latitude,
          longitude,
          zoom,
          transitionInterpolator: new LinearInterpolator(),
          transitionDuration: 1000,
        }));
      } catch (e) {
        console.error('Error fitting map to bounds', e);
      }
    }
  }, [currentRoutes, currentRoutesIsLoading]);
};

export const makeMapStylePointLayer = (
  id: string,
  visible: boolean,
  filter: any[],
  color: any,
  width = 3,
) => {
  return {
    id,
    filter,
    layout: {
      visibility: visible ? 'visible' : 'none',
    },
    paint: {
      'circle-color': '#fff',
      'circle-radius': 6,
      'circle-opacity': 0.5,
      'circle-stroke-width': width,
      'circle-stroke-color': color || palette.text.primary,
    },
    type: 'circle',
    source: 'points',
  };
};

export const makeMapStyleEdgeLayer = (id: string, filter: any[], paint: any) => {
  return {
    id,
    filter,
    source: 'edges',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint,
  };
};

export const makeMapStyleClusterLayers = (
  visible: boolean,
  color = palette.text.primary,
  width = 3,
) => {
  return [{
    id: 'clusters',
    type: 'circle',
    source: 'points',
    filter: ['has', 'point_count'],
    layout: {
      visibility: visible ? 'visible' : 'none',
    },
    paint: {
      'circle-color': '#fff',
      'circle-stroke-width': width,
      'circle-stroke-color': color,
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        15, 10,
        20, 20,
        25,
      ],
    },
  },
  {
    id: 'cluster-count',
    type: 'symbol',
    source: 'points',
    filter: ['has', 'point_count'],
    layout: {
      visibility: visible ? 'visible' : 'none',
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  }];
};

export function makeGeoJsonPoints(nodes: CommonNodeModel[], pointsExtras = {}) {
  return {
    type: 'geojson',
    ...pointsExtras,
    data: featureCollection(nodes.map((node, nodeIndex) => (
      point(
        [node.lng, node.lat],
        {
          isPoint: true, name: node.name, nodeIndex, nodeId: node.id,
        },
      )
    ))),
  };
}

export function makeGeoJsonRouteEdges(routes: CommonRouteModel[], edgesExtras: any) {
  return {
    type: 'geojson',
    ...edgesExtras,
    data: featureCollection(routes.reduce<any[]>((memo, route, routeIndex) => {
      const aggregatedEdges = route.traffic.reduce<any[]>((trafficMemo, edge) => {
        const edgePath = edge.detailed && edge.detailed.length ? edge.detailed : [edge.firstNode, edge.secondNode];
        const routeEdges = [];
        for (let detailedIndex = 0; detailedIndex < edgePath.length - 1; detailedIndex += 1) {
          const firstNode = edgePath[detailedIndex];
          const secondNode = edgePath[detailedIndex + 1];
          routeEdges.push(lineString(
            [firstNode, secondNode],
            { isEdge: true, routeIndex, ...edge },
          ));
        }
        return trafficMemo.concat(routeEdges);
      }, []);
      return memo.concat(aggregatedEdges);
    }, [])),
  };
}

export function makeGeoJsonRouteEdgesPoints(currentRoutes: CommonRouteModel[]) {
  return {
    type: 'geojson',
    data: featureCollection(currentRoutes.reduce<any[]>((memo: any[], route: CommonRouteModel, routeIndex) => {
      const points = route.traffic.reduce<any[]>((pointsMemo: any[], edge: CommonEdgeModel, edgeIndex: any) => {
        if (edgeIndex === 0) {
          const firstPoint = point(edge.firstNode, {
            ...edge,
            routeIndex,
            isCorrespondence: true,
          });
          pointsMemo.push(firstPoint);
        }
        return pointsMemo;
      }, []);
      if (route.traffic.length) {
        const lastEdge = route.traffic[route.traffic.length - 1];
        points.push(point(lastEdge.secondNode, {
          ...lastEdge,
          routeIndex,
          isCorrespondence: true,
        }));
      }
      return memo.concat(points);
    }, [])),
  };
}

export const makeMapStyleSources = (
  currentNodes: any[],
  currentRoutes: any[],
  pointsExtras: any,
  edgesExtras: any,
) => {
  return {
    points: makeGeoJsonPoints(currentNodes, pointsExtras),
    edges: makeGeoJsonRouteEdges(currentRoutes, edgesExtras),
    edgesPoints: makeGeoJsonRouteEdgesPoints(currentRoutes),
  };
};
