import { ProcessStatus, StrictDict } from 'src/types';

import { FileType, FileTypeDictRecord } from './types';

export const FILE_TYPES = Object.values(FileType);

export const PROJECT_STATUSES_DICT: StrictDict<ProcessStatus, string> = {
  [ProcessStatus.QUEUED]: 'modules.projects.statuses.queued',
  [ProcessStatus.IN_PROGRESS]: 'modules.projects.statuses.inProgress',
  [ProcessStatus.SUCCESS]: 'modules.projects.statuses.success',
  [ProcessStatus.ERROR]: 'modules.projects.statuses.error',
};

export const FILE_TYPE_DICT: StrictDict<FileType, FileTypeDictRecord> = {
  [FileType.graph]: {
    name: 'Графы сети',
    extension: '.geojson',
  },
  [FileType.stops]: {
    name: 'Остановочные пункты',
    extension: '.geojson',
  },
  [FileType.sites]: {
    name: 'Пункты',
    extension: '.geojson',
  },
  [FileType.siteBorders]: {
    name: 'Границы пунктов',
    extension: '.geojson',
  },
  [FileType.districts]: {
    name: 'Центроиды транспортных районов',
    extension: '.geojson',
  },
  [FileType.districtBorders]: {
    name: 'Транспортные районы',
    extension: '.geojson',
  },
  [FileType.zones]: {
    name: 'Центроиды зон PM',
    extension: '.geojson',
  },
  [FileType.zoneBorders]: {
    name: 'Зоны РМ',
    extension: '.geojson',
  },
  [FileType.editorSpreadsheets]: {
    name: 'Файл Excel для модуля Редактор',
    extension: '.xlsx',
  },
  [FileType.balanceSpreadsheets]: {
    name: 'Файл Excel для модуля Матрица пассажиропотоков',
    extension: '.xlsx',
  },
};

export const FILE_TYPES_ITEMS = FILE_TYPES.map((type) => ({
  id: type,
  value: type,
  label: FILE_TYPE_DICT[type]?.name,
}));
