/* eslint-disable max-len */
import React from 'react';

const ArrowSingle = () => (
  <svg viewBox="0 0 6 9" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.8113 8.71687L5.68833 5.18489C5.68833 5.18433 5.68893 5.18433 5.68953 5.18377C6.10349 4.80626 6.10349 4.19416 5.68953 3.81666L1.8113 0.283548C1.39674 -0.0945168 0.724428 -0.0945168 0.310469 0.283548C-0.10349 0.661051 -0.10349 1.27316 0.310469 1.65122L3.43829 4.50021L0.310469 7.3492C-0.10349 7.7267 -0.10349 8.33937 0.310469 8.71687C0.724428 9.09438 1.39674 9.09438 1.8113 8.71687Z" fill="currentColor"
    />
  </svg>
);

export default ArrowSingle;
