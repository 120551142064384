import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(2, 4),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    caption: {
      fontWeight: 600,
      fontSize: 12,
      color: theme.palette.text.primary,
      fontFamily: ffSourceSansPro,
      minWidth: 80,
    },
    icons: {
      '&> *': {
        display: 'inline-flex',
        justifyContent: 'center',
        width: `calc(22px + 2 * ${theme.spacing(0.5)}px)`,
        margin: 'auto',
      },
    },
  });
});
