/* eslint-disable max-len */
import React from 'react';

const Methodology = () => (
  <svg viewBox="0 0 144.083 144">
    <path d="M117.67,16.731H26.829c-7.713,0-13.988,6.274-13.988,13.987v84.563c0,7.713,6.275,13.987,13.988,13.987h90.841  c7.713,0,13.988-6.274,13.988-13.987V30.719C131.659,23.006,125.383,16.731,117.67,16.731z M125.659,115.281  c0,4.404-3.584,7.987-7.988,7.987H26.829c-4.404,0-7.988-3.583-7.988-7.987V51.654h106.818V115.281z M125.659,45.654H18.841V30.719  c0-4.404,3.584-7.987,7.988-7.987h90.841c4.404,0,7.988,3.583,7.988,7.987V45.654z" />
    <rect x="28.005" y="32.051" width="8.734" height="6" />
    <rect x="42.133" y="32.051" width="8.734" height="6" />
    <rect x="56.261" y="32.051" width="8.734" height="6" />
    <path d="M72,80.501c5.024,0,9.264-3.39,10.573-8H108.5v-6H82.573c-1.309-4.61-5.549-8-10.573-8c-5.024,0-9.264,3.39-10.573,8H35.5v6  h25.927C62.736,77.111,66.976,80.501,72,80.501z M72,64.501c2.756,0,5,2.243,5,5s-2.244,5-5,5c-2.757,0-5-2.243-5-5  S69.243,64.501,72,64.501z" />
    <path d="M53.666,99.501c5.024,0,9.264-3.39,10.573-8H108.5v-6H64.239c-1.31-4.61-5.549-8-10.573-8s-9.264,3.39-10.573,8H35.5v6  h7.593C44.402,96.111,48.642,99.501,53.666,99.501z M53.666,83.501c2.757,0,5,2.243,5,5s-2.243,5-5,5s-5-2.243-5-5  S50.909,83.501,53.666,83.501z" />
    <path d="M89.999,118.501c5.025,0,9.264-3.39,10.574-8h7.928v-6h-7.928c-1.311-4.61-5.549-8-10.574-8c-5.023,0-9.264,3.39-10.572,8  H35.5v6h43.926C80.735,115.111,84.975,118.501,89.999,118.501z M89.999,102.501c2.758,0,5,2.243,5,5s-2.242,5-5,5  c-2.756,0-5-2.243-5-5S87.243,102.501,89.999,102.501z" />
  </svg>
);

export default Methodology;
