import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import { AC_DASH_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/dashboard-table';

import DashGraphSettings from './DashGraphSettings';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showModal: () => dispatch(modals.actions.showModal(true, AC_DASH_GRAPH_VERSION_MODAL_NAME)),
});

export default connect(null, mapDispatchToProps)(withTranslate(DashGraphSettings));
