import React, { FC, useState } from 'react';
import { Maybe } from 'monet';

import { ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { CollapseWithToggle, DataGridContainer } from 'src/components';

import { AcProjectListForm } from 'src/cluster/common';
import {
  ProjectsGrid, AcProjectsModel, ProjectCreator, useProjectStatusUpdate,
} from 'src/cluster/projects';

import useStyles from './styles';

interface InjectedProps {
  projects: Maybe<AcProjectsModel[]>;
  count: number;
  isLoading: boolean;
  form: AcProjectListForm;
  onPageChange(pageNumber: number): void;
  onPageSizeChange(pageSize: number): void;
  changeStatusFilter(loadStatus: ProcessStatus): void;
  select(id: number): void;
  rename(id: number, name: string): void;
  remove(project: AcProjectsModel): void;
  copy(project: AcProjectsModel): void;
  reloadProject(project: AcProjectsModel): void;
}

type Props = InjectedProps & WithTranslate;

const ProjectsPage: FC<Props> = (props) => {
  const {
    projects,
    count,
    isLoading,
    form: {
      pageSize, currentPage, selectedProject, loadStatus,
    },
    t,
    onPageChange,
    onPageSizeChange,
    changeStatusFilter,
    select,
    remove,
    rename,
    copy,
    reloadProject,
  } = props;

  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(value => !value);

  useProjectStatusUpdate(projects, reloadProject);

  return (
    <section className={classes.root}>
      <div className={classes.projectCreateContainer}>
        <CollapseWithToggle
          collapsed={collapsed}
          toggle={toggle}
          caption={t('modules.projects.captions.createNewProject')}
        >
          <ProjectCreator />
        </CollapseWithToggle>
      </div>
      <DataGridContainer
        isLoading={isLoading}
        data={projects}
        className={classes.projects}
        pageSize={pageSize}
        currentPage={currentPage}
        count={count}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {(data: AcProjectsModel[]) => (
          <ProjectsGrid
            data={data}
            loadStatus={loadStatus}
            selectedProject={selectedProject}
            changeStatusFilter={changeStatusFilter}
            select={select}
            remove={remove}
            rename={rename}
            copy={copy}
          />
        )}
      </DataGridContainer>
    </section>
  );
};

export default ProjectsPage;
