import { FORECAST_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: FORECAST_ENDPOINT,
  parent: 'matrixProjects',
  transformArrayResponse: (response: any) => ({
    data: Array.isArray(response.result) ? response.result.map((item: object, index: number) => ({
      id: index,
      ...item,
    })) : [],
  }),
  defaults: {
    id: undefined,
    type: undefined,
    year: undefined,
    toCity: undefined,
    fromCity: undefined,
    trafficHsw: undefined,
    trafficNoHsw: undefined,
  },
  allowIdRequests: false,
  clearPagesOnRouteChange: false,
  pagination: false,
};
