import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { isNumber, isString } from 'src/helpers';

import { acBalanceMapFormActions } from 'src/cluster/balance-map';

import BalanceMapFilter from './BalanceMapFilter';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setYear: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(acBalanceMapFormActions.resetFields(['scenarioId', 'intervalId']));
      dispatch(acBalanceMapFormActions.changeField('year', value));
    }
  },
  setScenario: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(acBalanceMapFormActions.resetField('intervalId'));
      dispatch(acBalanceMapFormActions.changeField('scenarioId', value));
    }
  },
  setInterval: (value: number) => {
    dispatch(acBalanceMapFormActions.changeField('intervalId', value));
  },
  setFactor: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isString(value)) {
      dispatch(acBalanceMapFormActions.changeField('factor', value));
    }
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(BalanceMapFilter));
