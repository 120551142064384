import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, transitions }) => createStyles({
  container: {
    position: 'relative',
    padding: spacing(4),

    '&:hover $buttons': {
      opacity: 1,
    },
  },

  buttons: {
    position: 'absolute',
    top: spacing(4),
    right: spacing(4),
    opacity: 0,
    transition: `opacity ${transitions.duration.standard}`,
  },

  select: {
    marginBottom: 15,

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
