import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';
import { AcScenariosModel } from 'src/cluster/common';
import { selectBalanceMapScenariosByYear } from 'src/cluster/balance-map';

const useBalanceMapScenarios = (): Model<AcScenariosModel>[] => {
  const scenarios = useSelector(selectBalanceMapScenariosByYear);
  return scenarios.orJust([]);
};

export default useBalanceMapScenarios;
