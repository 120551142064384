import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Feature, FeatureCollection, Geometry, Point, Position, Properties,
} from 'src/interfaces';
import { isNumber } from 'src/helpers';
import palette from 'src/theme/palette';

import { selectEditorLegendForm, hexToArray, TrackPoint } from 'src/cluster/common';
import { editorFormActions } from 'src/cluster/editor-common';
import {
  EditorMode,
  PickInfo,
  selectEditorPageForm,
  selectEntitiesEditorForm,
  changeStopNodeId,
  createLayer,
  makeNewStop,
  addPoint,
} from 'src/cluster/editor-map';

const useNodeLayer = (nodeGeoJson: FeatureCollection<Geometry, Properties>, index: number) => {
  const dispatch = useDispatch();
  const [hoveredId, setHoveredId] = useState<number>();

  const {
    editorMode, isWaiting, addIndex, addingDirection,
  } = useSelector(selectEditorPageForm);
  const { editableStop } = useSelector(selectEntitiesEditorForm);
  const { isSrnLine } = useSelector(selectEditorLegendForm);

  const handleClick = ({ object }: PickInfo<Feature<Point>>) => {
    dispatch(editorFormActions.changeField('canBePositioned', false));
    const { properties, geometry } = object;
    if (!properties || !geometry) return;
    const nodeId = properties.id;
    const vehicleTypes = properties.vehicleTypes ?? [];
    const coordinates = geometry.coordinates as Position;

    if (!isNumber(nodeId) || !coordinates) return;
    if (editorMode === EditorMode.addPoint) {
      const node: TrackPoint = {
        nodeId,
        coordinates,
        stopId: properties?.stopId || null,
        stopName: properties?.stopName || null,
        order: 0,
      };
      dispatch(addPoint(node, Number(addIndex) ? addIndex : index, addingDirection),
      );
    } else if (editorMode === EditorMode.moveStop) {
      dispatch(changeStopNodeId(nodeId, coordinates, vehicleTypes));
    } else if (editorMode === EditorMode.addStop) {
      dispatch(makeNewStop(nodeId, coordinates, vehicleTypes));
    }
  };

  const handleHover = ({ object }: PickInfo<Feature<Point>>) => {
    if (editorMode !== EditorMode.moveStop && editorMode !== EditorMode.addStop) {
      setHoveredId(undefined);
      return;
    }
    if (editorMode === EditorMode.moveStop) {
      console.log('handleHover', object);
    }

    const stopId = object?.properties?.stopId;
    const id = object?.properties?.id;
    if (isNumber(id) && !isNumber(stopId)) {
      setHoveredId(id);
    } else {
      setHoveredId(undefined);
    }
  };

  const pickable = !isWaiting && (
    editorMode === EditorMode.addStop
    || editorMode === EditorMode.moveStop
    || editorMode === EditorMode.addPoint
  );

  const canSetStop = (
    editorMode === EditorMode.moveStop
    || editorMode === EditorMode.addStop
    || editorMode === EditorMode.addPoint
  ) && !editableStop?.routeDirections.length;

  return createLayer({
    id: 'nodes',
    data: nodeGeoJson,
    visible: isSrnLine,
    pickable,
    pointRadiusMinPixels: 1,
    pointRadiusMaxPixels: 40,
    selectedFeatureIndexes: [],
    pickingRadius: 20,
    getLineWidth: (feature: Feature) => {
      if (!canSetStop) return 1;
      if (hoveredId && hoveredId === feature.properties?.id) return 3;
      return 2;
    },
    getRadius: (feature: Feature) => {
      if (!canSetStop) return 3;
      if (hoveredId && hoveredId === feature.properties?.id) return 8;
      return 5;
    },
    getLineColor: () => {
      return canSetStop ? hexToArray(palette.success.main) : hexToArray(palette.grey.A200);
    },
    getFillColor: () => hexToArray(palette.grey[300]),
    onClick: handleClick,
    onHover: handleHover,
  });
};

export default useNodeLayer;
