import React, {
  FC, useCallback, Fragment, ChangeEvent, useEffect, useState,
} from 'react';
import { ButtonGroup } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { usePreloaderTimer } from 'src/helpers';
import {
  SelectField, Autocomplete, LoadingBlockContainer, Button, WithUnit, NumberFormat, Slider,
} from 'src/components';

import { DropDownValue } from 'src/components/deprecated/PSelect';
import { ToolbarBlock, ToolbarControl, ToolbarControls } from 'src/components/toolbar';

import { AutocompleteOption } from 'src/interfaces';
import {
  BalanceFilterModes,
  AcBalanceTrafficSumModel,
  useAcYearOptions,
  useAcIntervalOptions,
  useAcNodesOptions,
  useAcMunicipalitiesOptions,
  useAcFilterModesOptions,
} from 'src/cluster/common';
import { AcBalanceMapPageForm, MapModes, useBalanceMapScenarioOptions } from 'src/cluster/balance-map';

import { UNITS } from 'src/constants';
import useStyles from './styles';

export interface Props extends WithTranslate {
  isForecastLoading?: boolean;
  totalTraffic?: AcBalanceTrafficSumModel;
  pageForm: AcBalanceMapPageForm;
  isLoading?: boolean;
  isSourceLoading?: boolean;
  setStarMode(): void;
  setHeatMapMode(): void;
  setGroupMode(): void;
  setFilterModeFrom(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setFilterModeTo(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setRegionFrom(values: DropDownValue[]): void;
  setRegionTo(values: DropDownValue[]): void;
  setMunicipalityFrom(values: DropDownValue[]): void;
  setMunicipalityTo(values: DropDownValue[]): void;
  setScenario(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setYear(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setMinTraffic(value: number): void;
  setRegion(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
  setInterval(event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void;
}

const BalanceMapForecastFilter: FC<Props> = (props) => {
  const {
    isForecastLoading = false,
    pageForm,
    totalTraffic,
    isLoading = false,
    isSourceLoading = false,
    locale,
    t,
    setRegion,
    setStarMode,
    setHeatMapMode,
    setGroupMode,
    setFilterModeFrom,
    setFilterModeTo,
    setRegionFrom,
    setRegionTo,
    setMunicipalityFrom,
    setMunicipalityTo,
    setYear,
    setScenario,
    setInterval,
    setMinTraffic,
  } = props;

  const {
    filterModeFrom = MapModes.star,
    filterModeTo = MapModes.star,
    regionFrom = [],
    regionTo = [],
    municipalityFrom = [],
    municipalityTo = [],
    scenarioId,
    year,
    intervalId,
    mapMode,
    region,
    minTraffic = 0,
  } = pageForm;

  const classes = useStyles();

  const modes = useAcFilterModesOptions();
  const nodesItems = useAcNodesOptions();
  const municipalitiesItems = useAcMunicipalitiesOptions();

  const yearsOptions = useAcYearOptions();
  const scenariosOptions = useBalanceMapScenarioOptions();
  const intervalOptions = useAcIntervalOptions();

  const selectedRegionsFrom = regionFrom ? nodesItems.filter(item => regionFrom.includes(Number(item.value))) : [];
  const selectedRegionsTo = regionTo ? nodesItems.filter(item => regionTo.includes(Number(item.value))) : [];

  const selectedMunicipalityFrom = municipalityFrom ?
    municipalitiesItems.filter(item => municipalityFrom.includes(Number(item.value))) : [];
  const selectedMunicipalityTo = municipalityTo ?
    municipalitiesItems.filter(item => municipalityTo.includes(Number(item.value))) : [];

  const isLoadingByTime = usePreloaderTimer(isSourceLoading);

  const isOnlySourceLoading = isLoadingByTime && !isLoading && !isForecastLoading;

  const handleRegionFromChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setRegionFrom(values.map(item => item.value as number));
    } else {
      setRegionFrom([]);
    }
  }, [regionFrom]);

  const handleRegionToChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setRegionTo(values.map(item => item.value as number));
    } else {
      setRegionTo([]);
    }
  }, [regionTo]);

  const handleMunicipalityFromChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setMunicipalityFrom(values.map(item => item.value as number));
    } else {
      setMunicipalityFrom([]);
    }
  }, [municipalityFrom]);

  const handleMunicipalityToChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setMunicipalityTo(values.map(item => item.value as number));
    } else {
      setMunicipalityTo([]);
    }
  }, [municipalityTo]);

  const [baseYearChosen, setBaseYearChosen] = useState(false);

  useEffect(() => {
    const scenarioItem = scenariosOptions.length > 0 && scenariosOptions.filter(item => item.value === scenarioId)[0];
    if (scenarioItem && scenarioItem.label === 'Базовый год') {
      setBaseYearChosen(true);
    } else {
      setBaseYearChosen(false);
    }
  }, [scenarioId, scenariosOptions]);

  const isDisableYearByRegionsFrom = filterModeFrom === BalanceFilterModes.node
    ? selectedRegionsFrom.length === 0 :
    selectedMunicipalityFrom.length === 0;

  const isDisableYearByRegionsTo = filterModeFrom === BalanceFilterModes.node
    ? selectedRegionsTo.length === 0 :
    selectedMunicipalityTo.length === 0;

  const isDisableYear = isDisableYearByRegionsFrom && isDisableYearByRegionsTo;

  return (
    <LoadingBlockContainer
      className={classes.root}
      isBlocked={isLoadingByTime}
      caption={isOnlySourceLoading ? t('common.captions.cancel') : undefined}
    >
      <ToolbarControls>
        <ButtonGroup
          color="primary"
          size="large"
          className={classes.buttonGroup}
        >
          <Button
            variant={mapMode === MapModes.star ? 'contained' : 'outlined'}
            onClick={setStarMode}
          >
            {t('systems.balance.mapModes.star')}
          </Button>
          <Button
            variant={mapMode === MapModes.heat ? 'contained' : 'outlined'}
            onClick={setHeatMapMode}
          >
            {t('systems.balance.mapModes.heat')}
          </Button>
          <Button
            variant={mapMode === MapModes.group ? 'contained' : 'outlined'}
            onClick={setGroupMode}
          >
            {t('systems.balance.mapModes.group')}
          </Button>
        </ButtonGroup>
      </ToolbarControls>
      {mapMode === MapModes.group ? (
        <Fragment>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('systems.balance.captions.territorialCell')}
                options={modes}
                value={filterModeFrom}
                fullWidth
                onChange={setFilterModeFrom}
                className={classes.select}
              />

            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.select}>
            <ToolbarControl size="full-width">
              {filterModeFrom === BalanceFilterModes.node ? (
                <Autocomplete
                  multiple
                  label={t('common.captions.from')}
                  placeholder={t('components.captions.select')}
                  limitTags={8}
                  options={nodesItems}
                  selected={selectedRegionsFrom}
                  onChange={handleRegionFromChange}
                  className={classes.select}
                />

              ) : (
                <Autocomplete
                  multiple
                  label={t('common.captions.from')}
                  placeholder={t('components.captions.select')}
                  limitTags={8}
                  options={municipalitiesItems}
                  selected={selectedMunicipalityFrom}
                  onChange={handleMunicipalityFromChange}
                  className={classes.select}
                />
              )}
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('systems.balance.captions.territorialCell')}
                options={modes}
                value={filterModeTo}
                fullWidth
                onChange={setFilterModeTo}
                className={classes.select}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.select}>
            <ToolbarControl size="full-width">
              {filterModeTo === BalanceFilterModes.node ? (
                <Autocomplete
                  multiple
                  label={t('common.captions.to')}
                  placeholder={t('components.captions.select')}
                  limitTags={8}
                  options={nodesItems}
                  selected={selectedRegionsTo}
                  onChange={handleRegionToChange}
                  className={classes.select}
                />
              ) : (
                <Autocomplete
                  multiple
                  label={t('common.captions.to')}
                  placeholder={t('components.captions.select')}
                  limitTags={8}
                  options={municipalitiesItems}
                  selected={selectedMunicipalityTo}
                  onChange={handleMunicipalityToChange}
                  className={classes.select}
                />
              )}
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('common.captions.year')}
                options={yearsOptions}
                value={year}
                fullWidth
                onChange={setYear}
                disabled={isDisableYear}
                className={classes.select}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('common.captions.scenario')}
                options={scenariosOptions}
                value={scenarioId}
                fullWidth
                onChange={setScenario}
                disabled={!year || isDisableYear}
                className={classes.select}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                className={classes.select}
                label={t('common.captions.interval')}
                options={intervalOptions}
                value={intervalId}
                fullWidth
                sort={false}
                disabled={!scenarioId || isDisableYear}
                onChange={setInterval}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls>
            <ToolbarControl>
              <div className={classes.total}>
                <div>
                  <span>{t('systems.balance.captions.baseYearPassengerTrafficTotal')}</span>
                  {': '}
                  <span>
                    {totalTraffic ?
                      (
                        <WithUnit unit={t(UNITS.people)}>
                          <NumberFormat
                            value={totalTraffic.trafficBaseYearTotal || totalTraffic.trafficForecastTotal}
                            locale={locale}
                            decimalScale={0}
                          />
                        </WithUnit>
                      )
                      : '...'}
                  </span>
                </div>
                <div>
                  <span>
                    {' '}
                    {t(`systems.balance.captions.${baseYearChosen ? 'baseYearPassengerTrafficOT' : 'forecastYearPassengerTrafficPublic'}`)}
                  </span>
                  {': '}
                  <span>
                    {totalTraffic ? (
                      <WithUnit unit={t(UNITS.people)}>
                        <NumberFormat
                          value={totalTraffic.trafficForecastPublic}
                          locale={locale}
                          decimalScale={0}
                        />
                      </WithUnit>
                    ) : '...'}
                  </span>
                </div>
                <div>
                  <span>
                    {t(`systems.balance.captions.${baseYearChosen ? 'baseYearPassengerTrafficLT' : 'forecastYearPassengerTrafficPrivate'}`)}
                  </span>
                  {': '}
                  <span>
                    {totalTraffic ? (
                      <WithUnit unit={t(UNITS.people)}>
                        <NumberFormat
                          value={totalTraffic.trafficForecastPrivate}
                          locale={locale}
                          decimalScale={0}
                        />
                      </WithUnit>
                    ) : '...'}
                  </span>
                </div>
                {!baseYearChosen && (
                  <>
                    <div>
                      <span>{t('systems.balance.captions.forecastYearPassengerTrafficTotal')}</span>
                      {': '}
                      <span>
                        {totalTraffic ? (
                          <WithUnit unit={t(UNITS.people)}>
                            <NumberFormat
                              value={totalTraffic.trafficForecastTotal}
                              locale={locale}
                              decimalScale={0}
                            />
                          </WithUnit>
                        ) : '...'}
                      </span>
                    </div>
                    <div>
                      <span>{t('systems.balance.captions.increaseInPassengerTraffic')}</span>
                      {': '}
                      <span>
                        {totalTraffic ? (
                          <WithUnit unit="%">
                            <NumberFormat
                              value={(totalTraffic.trafficIncrease ?? 0) * 100}

                              locale={locale}
                              decimalScale={0}
                            />
                          </WithUnit>
                        ) : '...'}
                      </span>
                    </div>
                  </>)}
              </div>
            </ToolbarControl>
          </ToolbarControls>
        </Fragment>
      ) : (
        <Fragment>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('common.captions.year')}
                options={yearsOptions}
                value={year}
                fullWidth
                onChange={setYear}
                className={classes.select}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('common.captions.scenario')}
                options={scenariosOptions}
                value={scenarioId}
                fullWidth
                onChange={setScenario}
                className={classes.select}
              />

            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.tightSelect}>
            <ToolbarControl size="full-width">
              <SelectField
                className={classes.select}
                label={t('common.captions.interval')}
                options={intervalOptions}
                value={intervalId}
                fullWidth
                sort={false}
                onChange={setInterval}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarControls className={classes.select}>
            <ToolbarControl size="full-width">
              <SelectField
                label={t('systems.balance.captions.firstDistrict')}
                options={nodesItems}
                value={region}
                fullWidth
                onChange={setRegion}
                className={classes.select}
              />
            </ToolbarControl>
          </ToolbarControls>
          <ToolbarBlock title={t('systems.balance.captions.viewSettings')}>
            <ToolbarControls className={classes.select}>
              <ToolbarControl size="full-width">
                <Slider
                  name="minTraffic"
                  label={t('systems.balance.captions.hideTrafficFlows', { value: minTraffic })}
                  value={minTraffic}
                  max={100}
                  suffix="%"
                  onChange={setMinTraffic}
                />
              </ToolbarControl>
            </ToolbarControls>
          </ToolbarBlock>
        </Fragment>
      )}
    </LoadingBlockContainer>
  );
};

export default BalanceMapForecastFilter;
