import React from 'react';

const InfoIcon = ({ active = false }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15" fill="white" stroke={active ? '#E5293C' : '#C4C4C4'} />
    <path
      d="M14.8817 20.4121V13.9121H17.1177V20.4121H14.8817ZM15.9997 13.1061C15.6357 13.1061 15.3367 13.0021 15.1027 12.7941C14.8687 12.5861 14.7517 12.3174 14.7517 11.9881C14.7517 11.6588 14.8687 11.3901 15.1027 11.1821C15.3367 10.9741 15.6357 10.8701 15.9997 10.8701C16.3637 10.8701 16.6627 10.9741 16.8967 11.1821C17.1307 11.3901 17.2477 11.6588 17.2477 11.9881C17.2477 12.3174 17.1307 12.5861 16.8967 12.7941C16.6627 13.0021 16.3637 13.1061 15.9997 13.1061Z"
      fill="#333B46"
    />
  </svg>
);

export default InfoIcon;
