import React, { FC } from 'react';
import clsx from 'clsx';

import { Button } from 'src/components';

import useClasses from './styles';

type Props = {
  className?: string;
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
  onClick: () => void;
}

const MapButton: FC<Props> = (props) => {
  const {
    children,
    className,
    variant = 'outlined',
    disabled = false,
    onClick,
  } = props;

  const classes = useClasses();

  return (
    <Button
      variant={variant}
      className={clsx(classes.root, className)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default MapButton;
