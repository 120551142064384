import i18n from 'src/i18n';
import { isString, isObject, isArray } from 'src/helpers';

const getErrorWithKey = (key: string, value: any, defaultMessage?: string) => {
  let message = '';
  if (isString(value)) message = defaultMessage ?? value;
  else if (isArray(value)) {
    value.flatMap(val => {
      if (isString(val)) message += val;
      else if (isObject(val)) {
        Object.entries(val).flatMap(([k, v]) => {
          message += getErrorWithKey(k, v, defaultMessage);
        });
      }
    });
  }
  return `${key}: [${message}]`;
};

export const errorHandler = (err?: { data: any; status: number }, defaultMessage?: string): string => {
  if (!err) return '';
  const { data, status } = err;

  let error = '';
  if (status >= 400 && data) {
    if (isString(data)) error = data;
    else if (isObject(data)) {
      Object.entries(data).flatMap(([key, value]) => {
        error += getErrorWithKey(key, value, defaultMessage);
      });
    }
  }

  return error ?? '-';
};

const getErrorKeys = (key: string, value: any, translate: string) => {
  let result = i18n.t(translate + key);
  if (isArray(value)) {
    value.flatMap(val => {
      if (isObject(val)) {
        result += ': ';
        Object.entries(val).flatMap(([k, v]) => {
          result += getErrorKeys(k, v, translate);
        });
      }
    });
  }
  return result;
};

export const requiredErrorKeyHandler = (translate: string, err?: { data: any; status: number }): string => {
  if (!err) return '';
  const { data, status } = err;

  let error = '';
  if (status >= 400 && data) {
    if (isString(data)) error = data;
    else if (isObject(data)) {
      Object.entries(data).flatMap(([key, value], i) => {
        if (i > 0) error += ', ';
        error += getErrorKeys(key, value, translate);
      });
    }
  }

  return `[${error}]`;
};
