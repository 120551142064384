import React from 'react';

export enum Systems {
  shipments = 'shipments',
  cities = 'cities',
  matrix = 'matrix',
  traffic = 'traffic',
  mth = 'mth',
}

export const DEFAULT_SYSTEMS_VALUES = {
  [Systems.shipments]: '',
  [Systems.cities]: '',
  [Systems.matrix]: '',
  [Systems.traffic]: '',
  [Systems.mth]: '',
};

export const SystemsContext = React.createContext(Systems.shipments);

export const useSystemsContext = () => React.useContext(SystemsContext);
