import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => {
  return createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
    },

    direction: {
      fontSize: 12,
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      paddingRight: theme.spacing(1),
    },

    action: {
      marginLeft: 'auto',
      marginRight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },

    delete: {
      fontFamily: ffSourceSansPro,
      fontSize: 10,
      lineHeight: 1,
    },
  });
});
