import React, { FC } from 'react';
import classNames from 'classnames';

import { MinusIcon, PlusIcon } from 'src/components';

import { MapButton } from 'src/cluster/common';

import useStyles from './styles';

interface Props {
  className?: string;
  zoomIn: () => void;
  zoomOut: () => void;
}

const AgglomerationMapZoom: FC<Props> = (props) => {
  const { className, zoomIn, zoomOut } = props;
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      <MapButton
        className={classes.button}
        onClick={zoomIn}
      >
        <PlusIcon />
      </MapButton>
      <MapButton
        className={classes.button}
        onClick={zoomOut}
      >
        <MinusIcon />
      </MapButton>
    </div>
  );
};

export default AgglomerationMapZoom;
