import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      height: '100%',
    },
    tree: {
      position: 'relative',
      overflowY: 'auto',
      height: 'calc(100% - 35px)',
      zIndex: 1,
    },
    editing: {
      '& $tree': {
        height: 'calc(100% - 138px)',
      },
    },
    footer: {
      position: 'sticky',
      bottom: 0,
      zIndex: 2,
    },
    button: {
      fontFamily: ffSourceSansPro,
      fontSize: 10,
      marginLeft: 'auto',
      lineHeight: 1,
    },
    submitButtons: {
      padding: theme.spacing(2, 3),
      backgroundColor: theme.palette.common.white,
    },
  });
});

export const useStylesGrid = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(2, 10, 3),
      backgroundColor: theme.palette.common.white,
    },
  });
});
