import React, { FC } from 'react';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import {
  DataGridColumnProps,
  DataGrid,
  NumberFormat,
  WithUnit,
} from 'src/components';

import { AcDashIndicatorResultsModel, CategoryIndicator } from 'src/cluster/dashboard-common';
import { translateCategoryName, translateUnit } from 'src/cluster/dashboard-table';

import SubindicatorsPartitionBy from '../SubindicatorsPartitionBy';

import useStyles from './styles';

interface OwnProps {
  level: number;
  indicators: CategoryIndicator[];
  baseIndicators: Model<AcDashIndicatorResultsModel>[];
  forecastIndicators: Model<AcDashIndicatorResultsModel>[];
}

type Props = OwnProps & WithTranslate;

const IndicatorDataGrid: FC<Props> = (props) => {
  const {
    level, indicators, baseIndicators, forecastIndicators, locale,
  } = props;

  const classes = useStyles(level);

  const subcategoryColumns: DataGridColumnProps<CategoryIndicator>[] = [
    {
      model: (indicator) => (
        <span>
          {translateCategoryName(indicator.name)}
          <WithUnit unit={translateUnit(indicator.unit)} />
        </span>
      ),
      className: classes.primaryColumn,
    },
    {
      model: (indicator) => {
        const result = baseIndicators.find((baseInd) => baseInd.indicatorId === indicator.id);
        return (
          <NumberFormat
            value={result?.value ?? '-'}
            locale={locale}
            decimalScale={indicator.unit === 'passenger' ? 0 : 2}
          />
        );
      },
      className: classes.secondaryColumn,
    },
    {
      model: (indicator) => {
        const result = forecastIndicators.find((forecastInd) => forecastInd.indicatorId === indicator.id);
        return (
          <NumberFormat
            value={result?.value ?? '-'}
            locale={locale}
            decimalScale={indicator.unit === 'passenger' ? 0 : 2}
          />
        );
      },
      className: classes.secondaryColumn,
    },
    {
      model: (indicator) => {
        const baseResult = baseIndicators.find((baseInd) => baseInd.indicatorId === indicator.id);
        const forecastResult = forecastIndicators.find((baseInd) => baseInd.indicatorId === indicator.id);
        if (!baseResult || !forecastResult || !baseResult.value || !forecastResult.value) {
          return '-';
        }

        const value = 100 * (forecastResult.value - baseResult.value) / baseResult.value;
        return (
          <span className={value > 0 ? classes.positive : classes.negative}>
            <NumberFormat
              value={Math.abs(value)}
              locale={locale}
              decimalScale={0}
              suffix="%"
            />
          </span>
        );
      },
      className: classes.secondaryColumn,
    },
  ];

  return (
    <DataGrid<CategoryIndicator>
      hideHeader
      body={indicators}
      columns={subcategoryColumns}
      expandable={(indicator) => !!indicator.subindicatorsPartitionBy.length}
      expand={(indicator) => {
        if (!indicator.subindicatorsPartitionBy.length) {
          return null;
        }
        const entityNames = indicator.subindicatorsPartitionBy[0].split('.');
        return (
          <SubindicatorsPartitionBy
            level={level + 1}
            indicator={indicator}
            currentEntityId={null}
            currentEntityName={null}
            nextEntityNames={entityNames}
            baseIndicators={baseIndicators}
            forecastIndicators={forecastIndicators}
          />
        );
      }}
    />
  );
};

export default IndicatorDataGrid;
