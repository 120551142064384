import React, { CSSProperties, FC } from 'react';

import palette from 'src/theme/palette';
import {
  BusIcon,
  AutomobileIcon,
  TrolleybusIcon,
  TramwayIcon,
  MetroIcon,
  PedestrianIcon,
  UnknownVehicleIcon,
  SuburbanIcon,
  WaterIcon,
  RopeWayIcon,
  MonorailIcon,
  FunicularIcon,
} from 'src/components';
import { VehicleType, VEHICLE_TYPES_COLORS } from 'src/cluster/common';

interface Props {
  type?: VehicleType;
  className?: string;
  style?: CSSProperties;
}

const VEHICLE_MAP: Record<VehicleType, JSX.Element> = ({
  [VehicleType.bus]: <BusIcon />,
  [VehicleType.auto]: <AutomobileIcon />,
  [VehicleType.trolleybus]: <TrolleybusIcon />,
  [VehicleType.tramway]: <TramwayIcon />,
  [VehicleType.metro]: <MetroIcon />,
  [VehicleType.pedestrian]: <PedestrianIcon />,
  [VehicleType.suburban]: <SuburbanIcon />,
  [VehicleType.water]: <WaterIcon />,
  [VehicleType.ropeway]: <RopeWayIcon />,
  [VehicleType.funicular]: <FunicularIcon />,
  [VehicleType.monorail]: <MonorailIcon />,
  [VehicleType.all]: <UnknownVehicleIcon />,
});

const VehicleIcon: FC<Props> = (props) => {
  const {
    type, className, style,
  } = props;
  if (type && type in VehicleType) {
    const cssProperties = {
      color: VEHICLE_TYPES_COLORS[type],
      ...style,
    };
    return (
      <span
        className={className}
        style={cssProperties}
      >
        {VEHICLE_MAP[type]}
      </span>
    );
  }

  return (
    <span style={{ color: palette.grey[400] }}>
      <UnknownVehicleIcon />
    </span>
  );
};

export default VehicleIcon;
