import React from 'react';
import classNames from 'classnames';
import ReactMapGL, {
  InteractiveMap,
  NavigationControl,
  Popup,
} from 'react-map-gl';
import { useSelector, useDispatch } from 'react-redux';
import { RestifyId } from 'redux-restify';

import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import {
  getExtendedMapStyle,
  EDGE_TRAFFIC_COLOR,
  INACTIVE_ROUTE_COLOR,
  SELECTED_EDGE_COLOR,
  ROUTES_PAGES_FORM_NAMES,
  PageRoutesForm,
} from 'src/modules/cities/routes/constants';

import {
  useMapFittedToBounds,
  makeMapStyleSources,
  makeMapStyleEdgeLayer,
  makeMapStylePointLayer,
  makeMapStyleClusterLayers,
} from 'src/helpers';
import { VocabularyEntity } from 'src/i18n';
import palette from 'src/theme/palette';

import { useRestifyForm } from 'src/helpers/deprecated/restify';

import {
  usePopup,
  useSystemsContext,
  toFilterValue,
} from 'src/constants';

import MapFilterPanel from '../CitiesMapFilterPanel';
import CitiesCalcTable from '../CitiesCalcTable';
import CitiesMapLegend from './CitiesMapLegend';
import selectors from '../../selectors';

import style from './index.module.css';

interface Props {
  className?: string;
  calculationId: RestifyId;
}

const CalculationRoutesMap: React.FC<Props> = ({
  className, calculationId,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [viewport, setViewport] = React.useState({
    latitude: 59.8,
    longitude: 30,
    zoom: 7,
  });
  const [nodePopup, setNodePopup] = usePopup<string>();
  const mapRef = React.useRef<InteractiveMap | null>(null);
  const currentNodes = useSelector(selectors[currentSystem].getCurrentProjectNodes);
  const {
    currentRoutes,
    currentRoutesIsLoading,
  } = useSelector(selectors[currentSystem].getCurrentProjectRoutes);
  const [
    routesPageForm,
    routesPageFormActions,
  ] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);

  useMapFittedToBounds(currentRoutes, currentRoutesIsLoading, viewport, setViewport);

  const mapStyle = React.useMemo(() => {
    const pointExtras = {
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    };
    const sources = makeMapStyleSources(currentNodes, currentRoutes, pointExtras, {});
    const isSelectedRouteFilter = [
      '==',
      ['get', 'routeIndex'],
      toFilterValue(routesPageForm.selectedRoute),
    ];

    const isSelectedRoutePointFilter = [
      'any',
      ['==', ['get', 'name'], toFilterValue(routesPageForm.from)],
      ['==', ['get', 'name'], toFilterValue(routesPageForm.to)],
    ];
    const isSelectedEdgeFilter = [
      '==',
      'edgeId',
      toFilterValue(routesPageForm.selectedEdge),
    ];
    const routeLoadColors = [
      { from: 0, to: 0.3, color: EDGE_TRAFFIC_COLOR[6] },
      { from: 0.3, to: 0.4, color: EDGE_TRAFFIC_COLOR[5] },
      { from: 0.4, to: 0.55, color: EDGE_TRAFFIC_COLOR[4] },
      { from: 0.55, to: 0.7, color: EDGE_TRAFFIC_COLOR[3] },
      { from: 0.7, to: 0.9, color: EDGE_TRAFFIC_COLOR[2] },
      { from: 0.9, to: 0.9, color: EDGE_TRAFFIC_COLOR[1] },
    ];
    const allRoutesColors = [
      'case',
      ...routeLoadColors.reduce<any[]>((memo, item) => {
        return memo.concat([
          [
            'all',
            ['>=', ['get', 'trafficLoad'], item.from],
            ['<', ['get', 'trafficLoad'], item.to],
          ],
          item.color,
        ]);
      }, []),
      EDGE_TRAFFIC_COLOR[1],
    ];
    const inactiveRoutesColor = routesPageForm.selectedRoute === undefined ? allRoutesColors : INACTIVE_ROUTE_COLOR;

    const layers: any[] = [
      makeMapStyleEdgeLayer('routes-hover', ['all'],
        { 'line-color': 'transparent', 'line-width': 3 }),
      makeMapStyleEdgeLayer('routes-inactive', ['!', isSelectedRouteFilter],
        { 'line-color': inactiveRoutesColor, 'line-width': 3 }),
      makeMapStyleEdgeLayer('routes-selected', isSelectedRouteFilter,
        { 'line-color': allRoutesColors, 'line-width': 3 }),
      makeMapStyleEdgeLayer('edges-selected', isSelectedEdgeFilter,
        { 'line-color': SELECTED_EDGE_COLOR, 'line-width': 3 }),
      ...makeMapStyleClusterLayers(true),
      makeMapStylePointLayer(
        'points-unclustered',
        true,
        ['!', ['has', 'point_count']],
        ['case', isSelectedRoutePointFilter, EDGE_TRAFFIC_COLOR[1], palette.text.primary],
      ),
    ];
    return getExtendedMapStyle({
      sources,
      layers,
    });
  }, [currentNodes, currentRoutes]);
  const showRouteTable = routesPageForm.from && routesPageForm.to;
  const mapWrapperClassNames = classNames(
    style.mapWrapper,
    showRouteTable && style.mapWrapperWithTable,
  );
  return (
    <LoadingBlockContainer {...{
      className: classNames(style.root, className),
      isBlocked: currentRoutesIsLoading,
    }}>
      <CitiesMapLegend />

      <MapFilterPanel {...{
        currentNodes,
        currentRoutes,
        calculationId,
      }} />

      {showRouteTable && (
        <CitiesCalcTable />
      )}
      <div className={mapWrapperClassNames}>
        <ReactMapGL {...{
          height: '100%',
          width: '100%',
          // This is needed for cursor change
          interactiveLayerIds: [
            'routes-hover',
            'routes-selected',
            'points-unclustered',
          ],
          onMouseMove: (e) => {
            if (!mapRef.current) return;
            const features = mapRef.current.queryRenderedFeatures(e.point);
            if (!features) return;
            const cityFeature = features.find(f => f.properties && f.properties.isPoint);
            if (cityFeature && cityFeature.properties) {
              const { geometry }: any = cityFeature;
              setNodePopup({
                content: cityFeature.properties.name,
                lat: geometry.coordinates[1],
                lng: geometry.coordinates[0],
              });
            } else {
              setNodePopup({
                content: undefined,
                lat: 0,
                lng: 0,
              });
            }
          },
          onClick: (e) => {
            if (!mapRef.current) return;
            const features = mapRef.current.queryRenderedFeatures(e.point);
            const edgeFeature = features.find(f => f.properties && f.properties.isEdge);

            if (currentRoutes.length === 1) {
              // select route if it's the only one
              dispatch(routesPageFormActions.changeField('selectedRoute', 0));
            }
            if (edgeFeature && edgeFeature.properties) {
              dispatch(routesPageFormActions.changeField('selectedEdges', edgeFeature.properties.edgeId));
            } else {
              dispatch(routesPageFormActions.resetField('selectedEdges'));
            }
          },
          ref: mapRef,
          ...viewport,
          mapStyle,
          onViewportChange: (newViewport) => {
            setViewport(newViewport);
          },
          containerStyle: { height: '100%', width: '100%' },
          onLoad: (event: any) => event.target.resize(),
        }}>
          {
            nodePopup.content &&
            <Popup {...{
              latitude: nodePopup.lat,
              longitude: nodePopup.lng,
              closeButton: false,
              closeOnClick: false,
              anchor: 'left',
            }}>
              <div>
                <VocabularyEntity value={nodePopup.content} />
              </div>
            </Popup>
          }
          <div className={style.zoomControl}>
            <NavigationControl showCompass={false} />
          </div>
        </ReactMapGL>
      </div>
    </LoadingBlockContainer>
  );
};

export default CalculationRoutesMap;
