import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';
import { AppState } from 'src/reducer';
import { isNull, isNumber, isUndefined } from 'src/helpers';
import { AC_EXPORT_SELECTION_MODAL_NAME, selectMatrixForecast, selectMatrixForecastParams } from 'src/cluster/common';
import {
  selectTtcPageForm,
  ttcPageFormActions,
  exportCalculation,
  AcTtcCalcPageFormEnum,
} from 'src/cluster/balance-ttc';

import { buildTTC } from 'src/cluster/balance-data';
import TtcCalcToolbar from './TtcCalcToolbar';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectTtcPageForm(state),
  matrixForecastEntities: selectMatrixForecast(state),
  matrixForecastParams: selectMatrixForecastParams(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showExportsModal: () => {
    dispatch(modals.actions.showModal(true, AC_EXPORT_SELECTION_MODAL_NAME, {
      exportData: (isFull?: boolean, isModelParams?: boolean) => dispatch(exportCalculation(isFull, isModelParams)),
    }));
  },
  calculateTTC: () => {
    dispatch(buildTTC());
  },
  setYear: (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(ttcPageFormActions.resetFields(['scenarioId', 'currentPage']));
    if (!isUndefined(event.target)) {
      dispatch(ttcPageFormActions.changeField('year', Number(event.target.value)));
    }
    if (isNumber(event)) {
      dispatch(ttcPageFormActions.changeField('year', event));
    }
  },
  setScenario: (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(ttcPageFormActions.resetField('currentPage'));
    if (!isUndefined(event.target)) {
      dispatch(ttcPageFormActions.changeField('scenarioId', Number(event.target.value)));
    }
    if (isNumber(event)) {
      dispatch(ttcPageFormActions.changeField('scenarioId', event));
    }
  },
  setInterval: (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(ttcPageFormActions.resetField('currentPage'));
    if (!isUndefined(event.target)) {
      dispatch(ttcPageFormActions.changeField('intervalId', Number(event.target.value)));
    }
    if (isNumber(event)) {
      dispatch(ttcPageFormActions.changeField('intervalId', event));
    }
  },
  onChangeField: (field: AcTtcCalcPageFormEnum, value?: number | boolean) => {
    dispatch(ttcPageFormActions.resetField('currentPage'));
    if (!isNull(value)) {
      dispatch(ttcPageFormActions.changeField(field, value));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(TtcCalcToolbar));
