import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  root: {
    height: '100%',
    overflowX: 'visible',
  },
  tableBody: {
    position: 'relative',
    zIndex: 1,
  },
}));
