import { api, forms } from 'redux-restify';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Systems } from 'src/constants';
import { ThunkActionResult } from 'src/reducer';
import { TRANSPORT_MODEL_NAMES, TransportModel } from 'src/modules/matrix/transport/constants';
import { MATRIX_TRANSPORT_ENDPOINT } from 'src/api-url-schema';

export const getTransportActions = (type: Systems) => {
  const modelName = TRANSPORT_MODEL_NAMES[type];
  return {
    editTransportField: (id: number, entity: TransportModel): ThunkActionResult<void> => (
      (dispatch: ThunkDispatch<any, null, Action<string>>) => {
        dispatch(api.actions.entityManager[modelName].updateById(id, entity));
        dispatch(api.actions.callPatch({
          url: `${MATRIX_TRANSPORT_ENDPOINT}${id}`,
          data: entity,
          skipLoadsManager: true,
        }));
      }
    ),
    editTransportFieldOld: (field: string, id: number, value: number): ThunkActionResult<Promise<any>> => (
      (dispatch: ThunkDispatch<any, null, Action<string>>) => (
        dispatch(forms.actions.sendQuickForm({
          model: modelName,
          values: {
            id,
            [field]: value,
          },
        }))
      )
    ),
  };
};
