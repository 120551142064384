import React from 'react';

const ExcelIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 16H15V2H11" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8989 0L0.013916 2.89331L0 14.844L10.8342 17.8923L10.8989 0Z"
      fill="currentColor"
    />
    <path d="M3.5 6L7.5 11.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7.5 5.5L3.5 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ExcelIcon;
