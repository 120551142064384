import { useSelector } from 'react-redux';

import { isNumber } from 'src/helpers';
import palette from 'src/theme/palette';

import {
  SPEED_COLORS,
  VEHICLE_TYPES_COLORS,
  VehicleType,
  selectEditorLegendForm,
  useAcVehicleTypeId,
  useAcVehicleTypes,
  makeMapStyleEdgeLayer,
  makeMapStylePointLayer,
  makeMapStylePolygonLayer,
  makeMapStylePolygonBorderLayer,
  makeMapStyleStopLayer,
} from 'src/cluster/common';

import useNetworkVisibility from './useNetworkVisibility';
import useSpeedRange from './useSpeedRange';
import useInteractiveLines from './useInteractiveLines';
import useInteractiveStops from './useInteractiveStops';

const useInteractiveLayers = () => {
  const {
    isSrnLine,
    isTracklessStop,
    isEdgesOfTransportAreas,
    isDistrictCentroids,
    isAverageTravelSpeedCar,
    regularTransportationType,
    communicationType,
  } = useSelector(selectEditorLegendForm);

  const vehicleTypes = useAcVehicleTypes();
  const range = useSpeedRange();
  const getVehicleTypeId = useAcVehicleTypeId();
  const isNetworkVisible = useNetworkVisibility();
  const autoVehicleTypeId = getVehicleTypeId(VehicleType.auto);
  const busVehicleTypeId = getVehicleTypeId(VehicleType.bus);

  const lines = useInteractiveLines();
  const stops = useInteractiveStops();

  return [
    makeMapStylePolygonLayer(isEdgesOfTransportAreas),
    makeMapStylePolygonBorderLayer(isEdgesOfTransportAreas),
    makeMapStylePointLayer(
      'points-unclustered',
      isDistrictCentroids,
      ['!', ['has', 'point_count']],
      '#e7d88b',
      5,
      0.6,
    ),
    makeMapStyleEdgeLayer(
      'srn-line',
      isSrnLine,
      ['all'],
      {
        'line-color': 'hsl(0, 0%, 26%)',
        'line-width': 0.6,
      },
    ),
    ...lines.map((line) => {
      return makeMapStyleEdgeLayer(
        line.id,
        line.visible,
        ['in', line.vehicleId, ['get', 'vehicle_types']],
        {
          'line-color': line.color,
          'line-width': line.width,
          'line-dasharray': line.dashArray,
        },
      );
    }),
    makeMapStyleStopLayer(
      'trackless-stops',
      isTracklessStop,
      ['in', busVehicleTypeId, ['get', 'vehicle_types']],
      {
        'circle-color': palette.common.white,
        'circle-radius': 4,
        'circle-stroke-width': 3,
        'circle-stroke-color': palette.grey[400],
      },
    ),
    ...stops.map((line) => {
      return makeMapStyleStopLayer(
        line.id,
        line.visible,
        ['in', line.vehicleId, ['get', 'vehicle_types']],
        {
          'circle-color': palette.common.white,
          'circle-radius': 4,
          'circle-stroke-width': 3,
          'circle-stroke-color': line.color,
        },
      );
    }),
    makeMapStyleEdgeLayer(
      'average-speed-line',
      isNumber(autoVehicleTypeId) && isAverageTravelSpeedCar,
      ['has', ['to-string', autoVehicleTypeId], ['get', 'vehicle_speeds']],
      {
        'line-color': [
          'case',
          ...range.reduce<any[]>((memo, item) => {
            return memo.concat([
              [
                'all',
                ['>', ['get', ['to-string', autoVehicleTypeId], ['get', 'vehicle_speeds']], item.from],
                ['<=', ['get', ['to-string', autoVehicleTypeId], ['get', 'vehicle_speeds']], item.to],
              ],
              item.value,
            ]);
          }, []),
          SPEED_COLORS[1],
        ],
        'line-width': 5,
        'line-opacity': 0.8,
      },
    ),
    ...vehicleTypes.map((vehicleType) => {
      return makeMapStyleEdgeLayer(
        `${vehicleType.name}-network-edges`,
        isNetworkVisible(vehicleType.name),
        ['all',
          ['in', vehicleType.id, ['get', 'route_vehicle_types']],
          regularTransportationType ? [
            'in', regularTransportationType,
            ['get', ['to-string', vehicleType.id], ['get', 'regular_transportation_types']],
          ] : true,
          communicationType ? [
            'in', communicationType,
            ['get', ['to-string', vehicleType.id], ['get', 'communication_types']],
          ] : true,
        ],
        {
          'line-color': VEHICLE_TYPES_COLORS[vehicleType.name],
          'line-width': 3,
        },
      );
    }),
    ...vehicleTypes.map((vehicleType) => {
      return makeMapStyleStopLayer(
        `${vehicleType.name}-network-stops`,
        isNetworkVisible(vehicleType.name),
        ['all',
          ['in', vehicleType.id, ['get', 'route_vehicle_types']],
          regularTransportationType ? [
            'in', regularTransportationType,
            ['get', ['to-string', vehicleType.id], ['get', 'regular_transportation_types']],
          ] : true,
          communicationType ? [
            'in', communicationType,
            ['get', ['to-string', vehicleType.id], ['get', 'communication_types']],
          ] : true,
        ],
        {
          'circle-color': palette.common.white,
          'circle-radius': 4,
          'circle-stroke-width': 3,
          'circle-stroke-color': VEHICLE_TYPES_COLORS[vehicleType.name],
        },
      );
    }),
  ];
};

export default useInteractiveLayers;
