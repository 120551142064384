import { useReducer } from 'react';

import { AcBalanceDataModel } from 'src/cluster/common';

const CREATE = 'CREATE';
const UNCREATE = 'UNCREATE';
const RESET = 'RESET';

const initialCreateState: AcBalanceDataModel[] = [];

const creatingReducer = (
  state: AcBalanceDataModel[],
  { type, payload }: { type: string; payload?: AcBalanceDataModel },
) => {
  switch (type) {
    case CREATE: {
      if (!payload) {
        throw new Error(`Unexpected payload: ${payload}`);
      }
      const record = state.find((item: AcBalanceDataModel) => item.id === payload.id);
      if (record) {
        return state.map((item: AcBalanceDataModel) => {
          if (item.id === record.id) {
            return {
              ...record,
              ...payload,
            };
          }

          return item;
        });
      }
      return [...state, payload];
    }
    case UNCREATE: {
      if (!payload) {
        throw new Error(`Unexpected payload: ${payload}`);
      }
      return state.filter((item: AcBalanceDataModel) => item.id !== payload.id);
    }
    case RESET: {
      return initialCreateState;
    }
    default: {
      throw new Error(`Unexpected action: ${type}`);
    }
  }
};

function resetAction() {
  return {
    type: RESET,
  };
}

export default function useEdit(): [AcBalanceDataModel[], () => void] {
  const [state, dispatch] = useReducer(creatingReducer, initialCreateState);

  const reset = () => dispatch(resetAction());

  return [state, reset];
}
