import { useEffect, useState } from 'react';

export const usePreloaderTimer = (isLoading: boolean) => {
  const [preloader, setPreloader] = useState(false);
  useEffect(() => {
    const timer = isLoading
      ? setTimeout(() => {
        setPreloader(true);
      }, 1000)
      : null;
    return () => {
      setPreloader(false);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  return preloader;
};
