import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';
import { EDGE_SPEEDS_ENDPOINT } from 'src/cluster/editor-map';

export default {
  endpoint: EDGE_SPEEDS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    vehicleTypeId: undefined,
    intervalId: undefined,
    scenarioId: undefined,
    edgeId: undefined,
    speed: undefined,
    speedDedicatedLane: undefined,
    dedicatedLane: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
