import { forms } from 'redux-restify';
import { connect } from 'react-redux';
import { AppState } from 'src/reducer';

import {
  VIDEO_PAGE_FORM_NAME,
  PageVideoForm,
  selectVideoData,
} from 'src/modules/video/common';

import VideoPage from './VideoPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: forms.selectors[VIDEO_PAGE_FORM_NAME].getForm<PageVideoForm>(state),
  entities: selectVideoData(state),
});

export default connect(mapStateToProps)(VideoPage);
