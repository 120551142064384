import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCellStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',

    '&:first-of-type': {
      paddingLeft: theme.spacing(5),
    },
  },
}));

export const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
  cellWithArrow: {
    '&:first-of-type': {
      paddingLeft: spacing(11),
    },
  },
  multipleCellTop: {
    padding: spacing(2, 4),
    borderBottom: 'none',
    height: 30,
  },
  multipleCellBottom: {
    padding: spacing(4),
    top: 30,
    '&:first-of-type': {
      paddingLeft: spacing(4),
    },
  },
}));
