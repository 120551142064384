import React, { FC } from 'react';
import classNames from 'classnames';
import { WithId } from 'redux-restify';

import tooltipStyle from 'src/styles/tooltip.module.css';

import PTable from 'src/components/deprecated/PTable';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import ChartToggle from 'src/components/etc/ChartToggle';
import Tooltip from 'src/components/etc/Tooltip';

import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';
import { getVocabularyValue, useCurrentVocabulary, WithTranslate } from 'src/i18n';
import NumberFormat from 'src/components/etc/NumberFormat';
import { MacroeconomicsModel } from '../../constants';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  buttons?: any;
  data: WithId<MacroeconomicsModel>[];
  isLoading: boolean;
  showMacroeconomics: boolean;
  showMobilityIncrease: boolean;
  showIncomeGrowth: boolean;
  onExport: () => void;
  onMacroeconomicsToggle: () => void;
  onMobilityIncreaseToggle: () => void;
  onIncomeGrowthToggle: () => void;
}

const MacroTable: FC<Props> = (props) => {
  const {
    className,
    data,
    isLoading,
    showMacroeconomics,
    showMobilityIncrease,
    showIncomeGrowth,
    onExport,
    onMacroeconomicsToggle,
    onMobilityIncreaseToggle,
    onIncomeGrowthToggle,
    t,
    Trans,
  } = props;

  const [currentLocale, vocabulary] = useCurrentVocabulary();

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading,
      className: classNames(style.root, className),
    }}>
      <PTable<WithId<MacroeconomicsModel>> {...{
        hoverable: true,
        header: [
          {
            title: t('systems.matrix.captions.year'),
            model: (item) => item.year,
          },
          {
            title: t('systems.matrix.captions.center'),
            model: (item) => getVocabularyValue(currentLocale, vocabulary, String(item.region)),
            className: style.centerColumn,
          },
          {
            title: (
              <ChartToggle
                className={style.chartToggle}
                color="#0066fe"
                isOn={showMacroeconomics}
                toggle={onMacroeconomicsToggle}
              >
                {t('systems.matrix.captions.macroeconomics')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t('systems.matrix.units.percentsToThePreviousYear')}
                  )
                </span>
              </ChartToggle>
            ),
            model: (item) => (
              <NumberFormat {...{
                locale: currentLocale,
                value: (item.macroeconomics || 0) * 100,
                decimalScale: 2,
                suffix: '%',
                zeroPlaceholder: '-',
              }} />
            ),
            className: style.alignRight,
          },
          {
            title: (
              <ChartToggle
                className={style.chartToggle}
                color="#069b1e"
                isOn={showMobilityIncrease}
                toggle={onMobilityIncreaseToggle}
              >
                {t('systems.matrix.captions.mobilityIncrease')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t('systems.matrix.units.percentsToThePreviousYear')}
                  )
                </span>
              </ChartToggle>
            ),
            model: (item) => (
              <NumberFormat {...{
                locale: currentLocale,
                value: (item.mobilityIncrease || 0) * 100,
                decimalScale: 2,
                suffix: '%',
                zeroPlaceholder: '-',
              }} />
            ),
            className: classNames(style.alignRight),
          },
          {
            title: (
              <ChartToggle
                className={style.chartToggle}
                color="#c46b00"
                isOn={showIncomeGrowth}
                toggle={onIncomeGrowthToggle}
              >
                {t('systems.matrix.captions.nonInflationaryIncomeGrowth')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t('systems.matrix.units.percentsToThePreviousYear')}
                  )
                </span>
              </ChartToggle>
            ),
            model: (item) => (
              <NumberFormat {...{
                locale: currentLocale,
                value: (item.nonInflationaryIncomeGrowth || 0) * 100,
                decimalScale: 2,
                suffix: '%',
                zeroPlaceholder: '-',
              }} />
            ),
            className: style.alignRight,
          },
          {
            title: (
              <ExportToExcelButton {...{
                size: 20,
                onClick: onExport,
              }} />
            ),
            model: () => null,
            className: style.exportColumn,
          },
          {
            title: (
              <Tooltip align="right">
                <span className={tooltipStyle.tooltipAnchor}>?</span>
                <div className={tooltipStyle.tooltipPopup}>
                  <span className={style.tooltipHeader}>{t('systems.matrix.captions.chartDisplayControl')}</span>
                  <Trans
                    i18nKey="systems.matrix.messages.macroTooltip"
                    components={{ button: <ChartToggle color="#069b1e" isOn /> }}
                  />
                </div>
              </Tooltip>
            ),
            model: () => null,
            className: style.tooltipColumn,
          },
        ],
        body: data,
      }} />
    </LoadingBlockContainer>
  );
};

export default MacroTable;
