import React, {
  ChangeEvent, FC, useRef, useState,
} from 'react';
import { TreeView } from '@material-ui/lab';

import { Model } from 'src/interfaces';
import { ChevronDownIcon, ChevronRightIcon } from 'src/components';

import { AcEdgeSpeed } from 'src/cluster/editor-map';

import { EditorEdgeTreeItem } from './components';

interface Props {
  isModifying: boolean;
  speeds: Model<AcEdgeSpeed>[];
  onChange(id: number, values: Model<AcEdgeSpeed>): void;
}

const EditorEdgeTreeView: FC<Props> = (props) => {
  const {
    isModifying,
    speeds,
    onChange,
  } = props;

  const treeRef = useRef<any>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState('');

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: ChangeEvent<{}>, nodeId: string) => {
    setSelected(nodeId);
  };

  return (
    <TreeView
      ref={treeRef}
      defaultCollapseIcon={<ChevronDownIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {speeds.map((edgeSpeed) => (
        <EditorEdgeTreeItem
          key={edgeSpeed.id}
          isModifying={isModifying}
          edgeSpeed={edgeSpeed}
          onChange={onChange}
        />
      ))}
    </TreeView>
  );
};

export default EditorEdgeTreeView;
