import React, { FC, useMemo } from 'react';
import { Divider } from '@material-ui/core';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import { GearIcon } from 'src/components';

import { SubmitButtons, useAcVehicleTypeLabel } from 'src/cluster/common';
import {
  EditorTreeHeader,
  EditorVehicleList,
  AcEdgeSpeed,
  EditorEdgeTreeView,
  EditorDedicatedLaneToggles,
  isAcEdgeSpeed, MIN_EDGE_SPEED,
} from 'src/cluster/editor-map';

import useStyles from './styles';

interface OwnProps {
  isModifying: boolean;
  canModify: boolean;
  isLoading: boolean;
  edge: Model<{ id: number }>;
  speeds: Model<AcEdgeSpeed>[];
  startEditing(): void;
  hideEdge(): void;
  cancel(): void;
  modify(id: number, values: Model<AcEdgeSpeed>): void;
  save(): void;
}

type Props = OwnProps & WithTranslate;

const EditorGraphEdge: FC<Props> = (props) => {
  const {
    isModifying,
    canModify,
    isLoading,
    edge,
    speeds,
    t,
    startEditing,
    hideEdge,
    cancel,
    modify,
    save,
  } = props;

  const classes = useStyles();

  const getVehicleTypeLabel = useAcVehicleTypeLabel();

  const sortedSpeeds = useMemo(() => {
    return speeds.sort((a, b) => {
      const aLabel = getVehicleTypeLabel(a.vehicleTypeId);
      const bLabel = getVehicleTypeLabel(b.vehicleTypeId);
      return aLabel.localeCompare(bLabel);
    });
  }, [speeds, getVehicleTypeLabel]);

  const types = sortedSpeeds.map(speed => speed.vehicleTypeId);

  const handleDedicatedLaneToggle = (id: number) => {
    const values = sortedSpeeds.find(speed => speed.id === id);
    if (isAcEdgeSpeed(values)) {
      modify(id, {
        ...values,
        dedicatedLane: !values.dedicatedLane,
      });
    }
  };

  const handleModifySpeed = (id: number, values: Model<AcEdgeSpeed>) => {
    if (isAcEdgeSpeed(values)) {
      modify(id, values);
    }
  };

  const isValid = sortedSpeeds.reduce((acc, speed) => acc && (
    speed.dedicatedLane
      ? (speed.speedDedicatedLane >= MIN_EDGE_SPEED)
      : (speed.speed >= MIN_EDGE_SPEED)
  ), true);

  return (
    <section className={classes.root}>
      <EditorTreeHeader
        title={t('modules.editor.captions.edgeOptions')}
        canModify={canModify}
        icon={<GearIcon />}
        leave={isModifying ? undefined : hideEdge}
        edit={isModifying ? undefined : startEditing}
      />
      <div className={classes.id}>{`ID ${edge.id}`}</div>
      <EditorVehicleList types={types} />
      <EditorDedicatedLaneToggles
        isModifying={isModifying}
        speeds={sortedSpeeds}
        onToggle={handleDedicatedLaneToggle}
      />
      <Divider />
      <div className={classes.treeContainer}>
        <EditorEdgeTreeView
          isModifying={isModifying}
          speeds={sortedSpeeds}
          onChange={handleModifySpeed}
        />
      </div>
      <Divider />
      {isModifying && (
        <SubmitButtons
          className={classes.submitButtons}
          isLoading={isLoading}
          disabled={!isValid}
          onCancel={cancel}
          onSubmit={save}
        />
      )}
    </section>
  );
};

export default EditorGraphEdge;
