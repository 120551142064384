import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';

import { AppState } from 'src/reducer';
import { selectRouteTripById } from 'src/modules/video/common';
import { selectVideoDataById } from 'src/modules/video/common/selectors/selectors';
import ModalVideoRouteView from './ModalVideoRouteView';

const mapStateToProps = (state: AppState) => ({
  trip: selectRouteTripById(state),
  route: selectVideoDataById(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  close: () => dispatch(closeAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalVideoRouteView));
