import { MatrixForecastModel } from 'src/cluster/common';
import { useEffect } from 'react';
import { Nullable, ProcessStatus } from 'src/types';

const useMatrixForecastStatusUpdate = <T extends MatrixForecastModel>(
  loadMatrixForecast: () => void,
  isLoading: boolean,
  matrixForecast: Nullable<T>,
  queryInterval = 10000,
) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (
        !isLoading
        && matrixForecast
        && matrixForecast.status
        && [ProcessStatus.QUEUED, ProcessStatus.IN_PROGRESS].includes(matrixForecast.status)
      ) {
        loadMatrixForecast();
      }
    }, queryInterval);

    return () => clearInterval(timer);
  }, [JSON.stringify(matrixForecast)]);
};

export default useMatrixForecastStatusUpdate;
