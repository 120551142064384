import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ spacing, palette, shadows }: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'sticky',
      top: 0,
      backgroundColor: palette.common.white,
      boxShadow: shadows[1],
      padding: spacing(2, 4),
      zIndex: 2,

      '&> *': {
        lineHeight: '16px',
      },
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
    },

    title: {
      fontWeight: 'bold',
      fontSize: 12,
      color: palette.grey[800],
      paddingRight: spacing(1),
      fontFamily: ffSourceSansPro,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 14,
        height: 14,
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: palette.success.dark,
        borderRadius: '50%',
        verticalAlign: 'text-bottom',
        marginRight: spacing(2),
      },
    },

    button: {
      fontSize: 10,
      marginLeft: 'auto',
    },

    tooltipAnchor: {
      cursor: 'pointer',
    },
  });
});
