import { createSelector } from 'reselect';
import {
  createEntitySelector,
  createFormSelector,
} from 'src/helpers';
import { Model } from 'src/interfaces';

import {
  AC_FORECAST_PAGE_FORM_NAME,
  AC_FORECAST_CORRESPONDENCES_MODEL_NAME,
  AC_FORECAST_TRAFFIC_SUM_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_DATA_FACTORS_MODEL_NAME,
  AcBalanceScenariosModel,
  AcBalanceTrafficSumModel,
  AcBalanceForecastForm,
  AcForecastCorrespondenceModel,
  AcBalanceDataFactorsModel,
} from 'src/cluster/common';

import { selectAcProjectListForm } from '../../../../cluster/common/store/selectors/common';

export const selectAcForecastPageForm = createFormSelector<AcBalanceForecastForm>(AC_FORECAST_PAGE_FORM_NAME);
export const selectAcDataFactorsEntity = createEntitySelector<AcBalanceDataFactorsModel>(AC_DATA_FACTORS_MODEL_NAME);

export const selectAcCorrespondencesEntity =
  createEntitySelector<AcForecastCorrespondenceModel>(AC_FORECAST_CORRESPONDENCES_MODEL_NAME);
export const selectAcTrafficSumEntity =
  createEntitySelector<AcBalanceTrafficSumModel>(AC_FORECAST_TRAFFIC_SUM_MODEL_NAME);
export const selectAcForecastScenariosEntity = createEntitySelector<AcBalanceScenariosModel>(AC_SCENARIOS_MODEL_NAME);

export const selectDataFactors = createSelector(
  [
    selectAcProjectListForm,
    selectAcDataFactorsEntity,
  ],
  (pageProjectsForm, factorsEntities): [Model<AcBalanceDataFactorsModel>[], boolean, number] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
        0,
      ];
    }
    const factorsApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      factorsEntities.getArray(factorsApiConfig),
      factorsEntities.getIsLoadingArray(factorsApiConfig),
      factorsEntities.getCount(factorsApiConfig),
    ];
  },
);
