import React, { FC, Fragment } from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';

import { MenuRenderers, SideMenuItem } from 'src/pages';
import { ChevronRightIcon } from 'src/components';

import useStyles from './styles';

interface Props {
  label: string;
  baseUrl: string;
  navKey: string;
  subKeys: string[];
  pages?: MenuRenderers;
  location: {
    pathname: string;
  };
}

const CollapseMenuItem: FC<Props> = (props) => {
  const {
    label,
    baseUrl,
    navKey,
    subKeys,
    pages,
    location: { pathname },
  } = props;

  const classes = useStyles();

  const [currentNavKey] = pathname.split('/').filter((chunk: string) => chunk !== '');

  const isOpen = navKey === currentNavKey;

  return (
    <Fragment>
      <SideMenuItem
        className={clsx(classes.parent, isOpen && classes.opened)}
        navKey={navKey}
        baseUrl={baseUrl}
        label={label}
        icon={<ChevronRightIcon />}
      />
      <Collapse in={navKey === currentNavKey} timeout="auto" unmountOnExit>
        {subKeys.map((subKey: string) => {
          const subRenderer = get(pages, subKey);
          if (!subRenderer) return null;
          return (
            <SideMenuItem
              className={classes.child}
              key={subKey}
              navKey={[navKey, subKey]}
              baseUrl={baseUrl}
              {...subRenderer}
            />
          );
        })}
      </Collapse>
    </Fragment>
  );
};

export default CollapseMenuItem;
