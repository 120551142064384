import React, { FC } from 'react';
import { Maybe } from 'monet';
import clsx from 'clsx';
import { CircularProgress, Typography } from '@material-ui/core';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import { Button, ChevronLeftIcon } from 'src/components';

import { AcStopsModel, AcRoutesModel } from 'src/cluster/common';
import {
  AcEdgeSpeed,
  AcEdgeSpeedChange,
  AcEditorPageForm,
  EditorFilters,
  EditorGraphEdge,
  EditorGraphOptions,
  EditorMode,
  EditorRoute,
  EditorStop,
} from 'src/cluster/editor-map';

import useClasses from './styles';

const LoadingContainer = () => {
  const classes = useClasses();
  return (
    <div className={classes.loading}>
      <CircularProgress thickness={7} />
    </div>
  );
};

export interface OwnProps {
  pageForm: AcEditorPageForm;
  selectedRoute: Maybe<Model<AcRoutesModel>>;
  selectedStop: Maybe<Model<AcStopsModel>>;
  selectedEdge: Maybe<Model<{ id: number }>>;
  edgeSpeeds: Maybe<Model<AcEdgeSpeed>[]>;
  edgeSpeedChanges: Maybe<Model<AcEdgeSpeedChange>[]>;
  enableViewMode(): void;
  enableModifyRouteMode(): void;
  enableModifyStopMode(): void;
  enableModifyEdgeMode(): void;
  leaveEdge(): void;
  modifyEdgeSpeed(id: number, values: Model<AcEdgeSpeed>): void;
  modifyEdgeSpeedChange(id: number, values: Model<AcEdgeSpeedChange>): void;
  saveEdgeSpeeds(): void;
  saveEdgeSpeedChanges(): void;
  cancelEdgeSpeedChanges(): void;
  deleteRoute(id: number, num: string): void;
}

type Props = OwnProps & WithTranslate;

const EditorSidebar: FC<Props> = (props) => {
  const {
    pageForm: {
      editorMode,
      directionId,
      isLoading,
      routeId,
      nodeId,
      nodeIndex,
      stopId,
      edgeId,
    },
    selectedStop,
    selectedEdge,
    selectedRoute,
    edgeSpeeds,
    edgeSpeedChanges,
    t,
    enableViewMode,
    enableModifyRouteMode,
    enableModifyEdgeMode,
    leaveEdge,
    modifyEdgeSpeed,
    modifyEdgeSpeedChange,
    saveEdgeSpeeds,
    saveEdgeSpeedChanges,
    cancelEdgeSpeedChanges,
    deleteRoute,
  } = props;

  const classes = useClasses();

  const isViewMode = EditorMode.view === editorMode;
  const isModifyRouteMode = editorMode === EditorMode.addPoint || editorMode === EditorMode.removePoint;
  const isModifyStopMode = editorMode === EditorMode.modifyStop || editorMode === EditorMode.moveStop;
  const isModifyEdgeMode = EditorMode.modifyEdge === editorMode;
  const isGraphMode = editorMode === EditorMode.graph || editorMode === EditorMode.modifyEdge;
  const showFilters = !isGraphMode;
  const showEdge = edgeId;
  const showStop = stopId && !edgeId;
  const showRoute = routeId && !stopId && !edgeId;
  const showTree = showEdge || showStop || showRoute;

  if (!showFilters && !showEdge) {
    return edgeSpeedChanges.map((speedChanges) => (
      <EditorGraphOptions
        isLoading={isLoading}
        isModifying={isGraphMode}
        speedChanges={speedChanges}
        cancel={cancelEdgeSpeedChanges}
        modify={modifyEdgeSpeedChange}
        save={saveEdgeSpeedChanges}
      />
    )).orJust(<LoadingContainer />);
  }

  return (
    <section className={classes.root}>
      {showFilters ? (
        <div className={classes.filterContainer}>
          <EditorFilters />
        </div>
      ) : (
        <div className={classes.graphToggleContainer}>
          <Button
            variant="icon"
            className={classes.icon}
            onClick={leaveEdge}
          >
            <ChevronLeftIcon />
          </Button>
          <Typography variant="subtitle1">
            {t('modules.editor.captions.editingSpeeds')}
          </Typography>
        </div>
      )}
      <div className={clsx(classes.treeContainer, !showFilters && classes.edgeTree, showTree && classes.show)}>
        {showEdge && edgeSpeeds.ap(selectedEdge.map((edge) => (speeds) => (
          <EditorGraphEdge
            isLoading={isLoading}
            isModifying={isModifyEdgeMode}
            canModify={isViewMode || isGraphMode}
            edge={edge}
            speeds={speeds}
            startEditing={enableModifyEdgeMode}
            hideEdge={leaveEdge}
            cancel={enableViewMode}
            modify={modifyEdgeSpeed}
            save={saveEdgeSpeeds}
          />
        ))).orJust(<LoadingContainer />)}
        {showStop && selectedStop.map((stop) => (
          <EditorStop
            isLoading={isLoading}
            isModifying={isModifyStopMode}
            canModify={isViewMode}
            stop={stop}
            directionId={directionId}
          />
        )).orJust(<LoadingContainer />)}
        {showRoute && selectedRoute.map((route) => (
          <EditorRoute
            isLoading={isLoading}
            isModifying={isModifyRouteMode}
            enableViewMode={enableViewMode}
            enableModifyMode={enableModifyRouteMode}
            selectedRoute={route}
            selectedNodeId={nodeId}
            selectedNodeIndex={nodeIndex}
            deleteRoute={deleteRoute}
          />
        )).orJust(<LoadingContainer />)}
      </div>
    </section>
  );
};

export default EditorSidebar;
