import { registerModal, MODAL_SIZES } from 'src/modals';

import { AppState } from 'src/reducer';
import { ModalAggloCalcMap, CalculationMapModalProps } from '../components';

import { CALCULATION_MAP_MODAL } from '../constants';

const stateToProps = (state: AppState, props: CalculationMapModalProps) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.full,
  };
};

export default registerModal(
  CALCULATION_MAP_MODAL,
  stateToProps,
)(ModalAggloCalcMap);
