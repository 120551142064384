import { AC_REGISTRY_SEASONS_ENDPOINT } from 'src/cluster/editor-table';
import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_REGISTRY_SEASONS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
  },
  pagination: false,
  allowIdRequests: false,
};
