import React, {
  Children, FC, ReactNode, useCallback, useState,
} from 'react';
import classNames from 'classnames';

import PClickOutside from 'src/components/deprecated/PClickOutside';

import style from './index.module.css';

interface Props {
  className?: string;
  align?: 'left' | 'right' | 'center';
  children: [ReactNode, ReactNode];
}

const Tooltip: FC<Props> = (props) => {
  const {
    className,
    align = 'center',
    children,
  } = props;
  const [caption, popup] = Children.toArray(children);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const handleEnter = useCallback(() => setPopupOpen(true), [setPopupOpen]);
  const handleLeave = useCallback(() => setPopupOpen(false), [setPopupOpen]);
  return (
    <PClickOutside {...{
      onClick: () => setPopupOpen(false),
    }}>
      <div
        className={classNames(style.root, className)}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      >
        <div className={style.anchorMargin}>
          <button className={style.anchor}>
            {caption}
          </button>
        </div>
        <div className={classNames(isPopupOpen ? style.popup : style.hiddenPopup, style[align])}>
          {popup}
        </div>
      </div>
    </PClickOutside>
  );
};

export default Tooltip;
