import { useSelector } from 'react-redux';

import { selectDistributionMapSelectedRoute } from 'src/cluster/distribution-map';

const useDistributionMapRouteVariantNumber = (variantId?: number): string => {
  const selectedRoute = useSelector(selectDistributionMapSelectedRoute).orUndefined();

  if (!variantId) return '?';

  if (!selectedRoute || !selectedRoute.variants) return '?';

  return selectedRoute.variants.find(variant => variant.id === variantId)?.variantNumber ?? '?';
};

export default useDistributionMapRouteVariantNumber;
