import { useReducer } from 'react';
import { AcBalanceTtcModel } from 'src/cluster/common';

const EDIT = 'EDIT';
const RESET = 'RESET';

const initialEditState: AcBalanceTtcModel[] = [];

const editingReducer = (
  state: AcBalanceTtcModel[],
  { type, payload }: { type: string; payload?: AcBalanceTtcModel },
) => {
  switch (type) {
    case EDIT: {
      if (!payload) {
        throw new Error(`Unexpected payload: ${payload}`);
      }
      const record = state.find((item: AcBalanceTtcModel) => item.id === payload.id);
      if (record) {
        return state.map((item: AcBalanceTtcModel) => {
          if (item.id === record.id) {
            return {
              ...record,
              ...payload,
            };
          }

          return item;
        });
      }
      return [...state, payload];
    }
    case RESET: {
      return initialEditState;
    }
    default: {
      throw new Error(`Unexpected action: ${type}`);
    }
  }
};

function editAction(payload: AcBalanceTtcModel) {
  return {
    type: EDIT,
    payload,
  };
}

function resetAction() {
  return {
    type: RESET,
  };
}

export default function useEdit(): [AcBalanceTtcModel[], (record: AcBalanceTtcModel) => void, () => void] {
  const [state, dispatch] = useReducer(editingReducer, initialEditState);

  const edit = (record: AcBalanceTtcModel) => {
    dispatch(editAction(record));
  };

  const reset = () => dispatch(resetAction());

  return [state, edit, reset];
}
