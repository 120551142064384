import React, { FC } from 'react';
import { WithId } from 'redux-restify';

import { Tooltip } from '@material-ui/core';
import { DataGrid } from 'src/components';

import { WithTranslate } from 'src/i18n';

import { PenIcon } from 'src/components/icons';
import { AcBalanceTtcModel } from 'src/cluster/common';

import NumberFormat from 'src/components/etc/NumberFormat';
import WithUnit from 'src/components/common/WithUnit';
import { UNITS } from 'src/constants';
import { AcPageBalanceTtcForm } from 'src/cluster/ttc';

import useStyles from './styles';

interface Props extends WithTranslate {
  message?: string;
  data: AcBalanceTtcModel[];
  isLoading?: boolean;
  isEdit: boolean;
  pageForm: AcPageBalanceTtcForm;
  edited: AcBalanceTtcModel[];
  created: AcBalanceTtcModel[];
  editFlow(payload: WithId<Partial<AcBalanceTtcModel>>): void;
  createFlow(payload: WithId<Partial<AcBalanceTtcModel>>): void;
  showEditModal(record: Partial<AcBalanceTtcModel>, cb: Function): void;
}

const TtcDataGrid: FC<Props> = (props: Props) => {
  const {
    showEditModal,
    data,
    isEdit,
    edited,
    created,
    locale,
    t,
    editFlow,
    createFlow,
  } = props;

  const classes = useStyles();

  const ttcList = isEdit ? [...created, ...data.map((item) => {
    const editedTtc = edited.find((editedItem) => editedItem.id === item.id);
    return { ...item, ...editedTtc };
  })] : data;

  const makeEditHandler = (model: AcBalanceTtcModel) => () => showEditModal(model, model.isNew ? createFlow : editFlow);

  const header = [
    {
      title: t('systems.balance.captions.firstDistrict'),
      model: (model: AcBalanceTtcModel) => model.regionFromName,
      className: classes.correspondenceColumn,
    },
    {
      title: t('systems.balance.captions.secondDistrict'),
      model: (model: AcBalanceTtcModel) => model.regionToName,
      className: classes.correspondenceColumn,
    },
    {
      title: t('common.captions.year'),
      model: (model: AcBalanceTtcModel) => (
        <Tooltip title={`${t('systems.balance.captions.source')}: ${model.source}`} arrow>
          <span className={classes.tooltipInner}>{model.year}</span>
        </Tooltip>
      ),
      className: classes.numberColumn,
      formatted: false,
    },
    {
      title: (
        <WithUnit unit={t(UNITS.costRub)}>
          {t('systems.balance.captions.ttcBase')}
        </WithUnit>
      ),
      model: (model: AcBalanceTtcModel) => {
        return (
          <NumberFormat
            value={model.ttcBase}
            locale={locale}
            decimalScale={0}
          />
        );
      },
      className: classes.numberColumn,
    },
    {
      title: (
        <WithUnit unit={t(UNITS.costRub)}>
          {t('systems.balance.captions.ttcForecast')}
        </WithUnit>
      ),
      model: (model: AcBalanceTtcModel) => {
        return (
          <NumberFormat
            value={model.ttcForecast}
            locale={locale}
            decimalScale={0}
          />
        );
      },
      className: classes.numberColumn,
    },
    {
      title: (
        <WithUnit unit="%">
          {t('systems.balance.captions.dynamics')}
        </WithUnit>
      ),
      model: (model: AcBalanceTtcModel) => {
        return (
          <NumberFormat
            value={model.ttcFromBase}
            locale={locale}
            decimalScale={2}
          />
        );
      },
      className: classes.numberColumn,
    },
    {
      title: null,
      model: (model: AcBalanceTtcModel) => (
        isEdit && (
          <button
            className={classes.deleteButton}
            onClick={makeEditHandler(model)}
          >
            <PenIcon />
          </button>
        )
      ),
      className: classes.lastColumn,
    },
  ];

  return (
    <DataGrid<AcBalanceTtcModel>
      headCellClassName={classes.headerCellFixed}
      cellClassName={classes.cell}
      body={ttcList}
      columns={header}
    />
  );
};

export default TtcDataGrid;
