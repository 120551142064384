import React from 'react';
import moment from 'moment';
import { padStart } from 'lodash';

const HOURS_IN_DAY = 24;

interface Props {
  value: number;
  unit?: 'h' | 'm' | 's';
  format?: string;
}

const TimeFormat: React.FC<Props> = ({ value, unit = 'h' }) => {
  const duration = moment.duration(value, unit);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  const hoursString = (HOURS_IN_DAY * days + hours).toString();
  const minutesString = padStart(minutes.toString(), 2, '0');
  const timeString = `${hoursString}:${minutesString}`;

  return <span>{timeString}</span>;
};

export default TimeFormat;
