import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import {
  selectEditorCopyNetworkForm,
  copyingNetworkFormActions,
  cancelCopyingNetwork,
  doCopyingNetwork,
  confirmCopyingNetwork,
  completeCopyingNetwork,
  switchToNewRouteNetwork,
} from 'src/cluster/editor-common';

import ModalEditorCopyNetwork from './ModalEditorCopyNetwork';

const mapStateToProps = (state: AppState) => ({
  form: selectEditorCopyNetworkForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setYear: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(copyingNetworkFormActions.resetField('scenarioId'));
    dispatch(copyingNetworkFormActions.changeField('year', Number(event.target.value)));
  },
  setScenario: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(copyingNetworkFormActions.changeField('scenarioId', Number(event.target.value)));
  },
  copy: () => {
    dispatch(doCopyingNetwork());
  },
  cancel: () => {
    dispatch(cancelCopyingNetwork());
  },
  confirm: () => {
    dispatch(confirmCopyingNetwork());
  },
  complete: () => {
    dispatch(completeCopyingNetwork());
  },
  switchToNewNetwork: () => {
    dispatch(switchToNewRouteNetwork());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalEditorCopyNetwork));
