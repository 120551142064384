import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette }) => ({
  root: {
    position: 'relative',
  },

  customize: {
    color: palette.text.primary,
  },

  export: {
    color: palette.text.primary,
    textTransform: 'uppercase',
  },
}));
