import { api } from 'redux-restify';

import {
  createFormActions,
  createModelActions,
  downloadFile,
  getFilename,
  isNumber,
  isUndefined,
} from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';
import { BinaryApiResponse } from 'src/interfaces';

import {
  AC_MATRIX_FORECAST_ENDPOINT,
  AC_PROJECTS_ENDPOINT,
  AcBalanceForecastForm,
  BalanceFilterModes,
  selectMatrixForecastParams,
  selectAcProjectListForm,
  AC_FORECAST_PAGE_FORM_NAME,
  AC_FORECAST_CORRESPONDENCES_MODEL_NAME,
  AC_FORECAST_TRAFFIC_SUM_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  EXPORT_GRAVITY_TUNER,
} from 'src/cluster/common';
import { selectAcForecastPageForm } from 'src/cluster/balance-common';
import {
  CORRESPONDENCES_ENDPOINT,
  getCorrespondencesFilter,
} from 'src/cluster/balance-forecast';
import { Nothing } from 'monet';

export const forecastPageFormActions = createFormActions<AcBalanceForecastForm>(
  AC_FORECAST_PAGE_FORM_NAME,
);

export const forecastCorrespondencesEntityManager = createModelActions(
  AC_FORECAST_CORRESPONDENCES_MODEL_NAME,
);
export const forecastTrafficSumEntityManager = createModelActions(
  AC_FORECAST_TRAFFIC_SUM_MODEL_NAME,
);

export function reloadBalanceForecast(): ThunkActionResult<any> {
  return (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState()) || {};
    const [forecastId] = selectMatrixForecastParams(getState()) || [];
    if (!isNumber(selectedProject) || !isNumber(forecastId)) return;
    const pageForecastForm = selectAcForecastPageForm(getState()) || {};
    const filter = getCorrespondencesFilter(pageForecastForm);
    if (isUndefined(filter)) return;
    const forecastCorrespondencesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter,
    };
    dispatch(forecastCorrespondencesEntityManager.loadData(forecastCorrespondencesApiConfig));
  };
}

export function handleSwitch(): ThunkActionResult<any> {
  return (dispatch, getState) => {
    const state = getState();
    const {
      filterModeFrom,
      filterModeTo,
      regionFrom,
      regionTo,
      municipalityFrom,
      municipalityTo,
    } = selectAcForecastPageForm(state);
    let regionFromNew = regionFrom;
    let regionToNew = regionTo;
    let municipalityFromNew = municipalityFrom;
    let municipalityToNew = municipalityTo;

    if (filterModeFrom === BalanceFilterModes.node) regionToNew = regionFrom;
    else if (filterModeFrom === BalanceFilterModes.municipality) { municipalityToNew = municipalityFrom; }
    if (filterModeTo === BalanceFilterModes.node) regionFromNew = regionTo;
    else if (filterModeTo === BalanceFilterModes.municipality) { municipalityFromNew = municipalityTo; }

    dispatch(
      forecastPageFormActions.changeSomeFields({
        filterModeFrom: filterModeTo,
        filterModeTo: filterModeFrom,
        regionFrom: regionFromNew,
        regionTo: regionToNew,
        municipalityFrom: municipalityFromNew,
        municipalityTo: municipalityToNew,
      }),
    );
  };
}

export function exportForecast(
  isFull?: boolean,
  isModelParams?: boolean,
): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId] = selectMatrixForecastParams(state);

    if (!isNumber(selectedProject) || !isNumber(forecastId)) {
      return Promise.reject();
    }

    let query = isFull
      ? {}
      : getCorrespondencesFilter(selectAcForecastPageForm(state));
    if (isModelParams) query = {};

    if (isUndefined(query)) return Promise.reject();

    const projectUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}`;
    const otherExportUrl = `${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/${CORRESPONDENCES_ENDPOINT}`;
    const url = `${projectUrl}/${!isModelParams ? otherExportUrl : EXPORT_GRAVITY_TUNER}export/`;
    const config = {
      url,
      isBinary: true,
      query,
    };

    try {
      dispatch(forecastPageFormActions.changeField('isExporting', true));
      const { data, status, api: xhr }: BinaryApiResponse = await dispatch(
        api.actions.callGet(config),
      );
      if (status === 200 && data !== undefined) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadFile(blob, getFilename(xhr));
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      dispatch(forecastPageFormActions.resetField('isExporting'));
    }
  };
}
