import { AGGLOMERATION_CALCULATIONS_ENDPOINT } from 'src/api-url-schema';
import { StrictDict } from 'src/types';
import { ExportTypes } from 'src/modules/mth/common/types';

export const CALCULATION_MAP_MODAL = 'agglomerationCalculationMapModal';
export const EXPORT_CALCULATION_MODAL = 'exportAgglomerationCalculationModal';

export const ROUTES_PAGES_FORM_NAME = 'pageAgglomerationCalculationsRoutesForm';

export const NODES_CALCULATIONS_MODEL_NAME = 'agglomerationCalculationsNodes';
export const EDGES_CALCULATIONS_MODEL_NAME = 'agglomerationCalculationsEdges';
export const ROUTES_CALCULATION_MODEL_NAME = 'agglomerationCalculationsRoutes';
export const ROUTES_AND_OVERSHOT_CALCULATION_MODEL_NAME = 'agglomerationCalculationsRoutesAndOvershot';
export const CALCULATIONS_MODEL_NAME = 'agglomerationCalculations';
export const EDGE_ROUTES_MODEL_NAME = 'agglomerationCalculationsEdgeRoutes';
export const CALCULATIONS_PAGES_FORM_NAME = 'pageAgglomerationCalculationsForm';
export const EDGE_ROUTES_FORM_NAME = 'edgeRoutesForm';
export const CALCULATIONS_ENDPOINTS = AGGLOMERATION_CALCULATIONS_ENDPOINT;

export const EXPORT_TYPES = { ...ExportTypes };

export const EXPORT_TYPES_DICT: StrictDict<ExportTypes, string> = {
  [EXPORT_TYPES.origin]: 'systems.mth.exportTypes.origin',
  [EXPORT_TYPES.graph]: 'systems.mth.exportTypes.graph',
  [EXPORT_TYPES.fullResult]: 'systems.mth.exportTypes.fullResult',
  [EXPORT_TYPES.trafficSum]: 'systems.mth.exportTypes.trafficSum',
  [EXPORT_TYPES.timeSum]: 'systems.mth.exportTypes.timeSum',
};
