import { Theme, makeStyles, fade } from '@material-ui/core';

export default makeStyles(({ spacing, palette }: Theme) => ({
  routeRow: {
    '&:hover': {
      background: palette.grey[50],
      '&> td:first-of-type::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: 3,
        backgroundColor: palette.secondary.main,
      },
    },
  },
  first: {
    paddingLeft: '44px !important',
    fontSize: 14,
  },
  bigSize: {
    fontSize: 14,
  },
  actions: {
    backgroundColor: palette.common.white,
    boxShadow: '-2px 0px 7px rgba(51, 59, 70, 0.15)',
  },
  lastColumn: {
    paddingRight: spacing(20),
    fontSize: 14,
  },
  variantRow: {
    backgroundColor: fade(palette.warning.light, 0.3),
  },
  cellClassName: {
    color: palette.text.primary,
    borderLeft: '1px solid #DFE3E9',
    textAlign: 'right',
    '&:first-child': {
      borderLeft: 0,
    },
    '&:nth-child(2)': {
      textAlign: 'left',
    },
    '&:last-child': {
      paddingRight: spacing(4),
    },
  },
  variantHead: {
    padding: spacing(1.5),
    borderLeft: '1px solid #DFE3E9',
    verticalAlign: 'top',
    fontSize: 14,
    backgroundColor: palette.warning.light,
    '&:first-child': {
      borderLeft: 0,
    },
  },
  variantHeadBottom: {
    fontSize: 14,
    borderTop: '1px solid #DFE3E9',
    padding: spacing(0.25, 0.5),
    '&:first-child': {
      borderLeft: '1px solid #DFE3E9',
      padding: spacing(0.25, 0.5),
    },
  },
}));
