import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RestifyId } from 'redux-restify';

import { SelectMenuOption } from 'src/interfaces';

import {
  AcBalanceDataFactorsModel,
  AcBalanceScenariosModel,
  AcRegionTypeModel,
} from 'src/cluster/common';
import { selectDataFactors } from 'src/cluster/balance-common';
import { selectCurrentProjectRegionTypes, selectDataScenarios } from 'src/cluster/balance-data';

export const useFactors = (): { id: RestifyId; name: string }[] => {
  const [factors] = useSelector(selectDataFactors);

  return useMemo(() => factors.map((factor: AcBalanceDataFactorsModel) => ({
    id: factor.id,
    name: factor.name,
  })), [JSON.stringify(factors)]);
};

export const useRegionTypes = (): SelectMenuOption[] => {
  const [regionTypes] = useSelector(selectCurrentProjectRegionTypes);

  return useMemo(() => regionTypes.map((region: AcRegionTypeModel) => ({
    id: String(region.id),
    value: region.name,
    label: region.name,

  })), [JSON.stringify(regionTypes)]);
};

export const useDataScenarios = (): SelectMenuOption[] => {
  const [scenarios] = useSelector(selectDataScenarios);

  return useMemo(() => scenarios.map((type: AcBalanceScenariosModel) => ({
    id: String(type.id),
    value: type.id,
    label: type.name,
  })), [JSON.stringify(scenarios)]);
};
