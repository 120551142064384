import React, { FC, Fragment } from 'react';
import { Typography, Box } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import {
  LEGEND_NODES,
  AcEditorPageForm,
  useVehicleColor,
} from 'src/cluster/editor-map';

import useClasses from './styles';

export interface OwnProps {
  pageForm: AcEditorPageForm;
}

type Props = OwnProps & WithTranslate;

const EditorMapLegend: FC<Props> = ({ pageForm, t }) => {
  const classes = useClasses();
  const { vehicleTypeId } = pageForm;
  const [vehicleColor, vehicleColorDark] = useVehicleColor(vehicleTypeId);

  return (
    <Fragment>
      <Box mb={5}>
        <Typography component="p" className={classes.caption}>
          {t('modules.editor.captions.transportNodes')}
        </Typography>
        {LEGEND_NODES.map((node) => (
          <Box display="flex" alignItems="center" mt={2} key={node.title}>
            <Box width={36} mr={2}>
              <Box
                m="0 auto"
                boxSizing="border-box"
                bgcolor={vehicleColorDark}
                borderColor={vehicleColor}
                {...node.props}
              />
            </Box>
            <Box className={classes.title}>{t(node.title)}</Box>
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};

export default EditorMapLegend;
