import { AC_COMMUNICATION_TYPES_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_COMMUNICATION_TYPES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
  },
  pagination: false,
  allowIdRequests: false,
};
