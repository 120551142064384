import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => createStyles({
  progress: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  percentage: {
    fontFamily: ffSourceSansPro,
    marginLeft: theme.spacing(4),
  },
  queued: {
    color: theme.palette.warning.dark,
  },
  success: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  error: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },
}));
