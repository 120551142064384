import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      padding: spacing(4),
      overflowY: 'auto',
    },

    header: {
      marginBottom: spacing(4),
    },

    list: {
      '&> div': {
        marginBottom: spacing(4),
      },
    },
  });
});
