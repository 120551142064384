import { makeStyles } from '@material-ui/core';

import { ffOxygen } from 'src/theme/typography';

export default makeStyles(() => ({
  root: {
    overflowY: 'auto',
  },

  primaryColumn: {
    width: '55%',
    fontFamily: ffOxygen,
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  secondaryColumn: {
    width: '15%',
  },
}));
