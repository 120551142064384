import React, { FC } from 'react';
import PropTypes, { ReactComponentLike } from 'prop-types';
import clsx from 'clsx';

import LoadingIndicator from 'src/components/etc/LoadingIndicator';

import useStyles from './styles';

interface Props {
  className?: string;
  label?: string;
  caption?: string;
  isBlocked: boolean;
  blocking?: boolean;
  component?: ReactComponentLike;
  componentProps?: Record<string, unknown>;
  onClick?: () => void;
}

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isBlocked: PropTypes.bool.isRequired,
  blocking: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.elementType,
  componentProps: PropTypes.shape({}),
  onClick: PropTypes.func,
};

const defaultProps = {
  label: undefined,
  isBlocked: false,
  blocking: true,
};

const LoadingBlockContainer: FC<Props> = (props) => {
  const {
    className,
    label,
    caption,
    isBlocked,
    blocking = true,
    children,
    componentProps,
    component,
    onClick,
  } = props;

  const classes = useStyles();

  const Component = component || 'div';

  const rootClassName = clsx(
    classes.root,
    className,
  );

  const hasButton = caption && onClick;

  return (
    <Component
      {...componentProps}
      className={rootClassName}
    >
      {isBlocked && (
        <div className={clsx(classes.blockingArea, blocking && classes.blocking)}>
          <div className={classes.labelWrapper}>
            {label && <span className={classes.label}>{label}</span>}
            <div className={clsx(classes.indicator, hasButton && classes.indicatorWithButton)}>
              <LoadingIndicator />
              {hasButton && (
                <button
                  type="button"
                  className={classes.button}
                  onClick={onClick}
                >
                  {caption}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={clsx(classes.content, isBlocked && classes.blocked)}>
        {children}
      </div>
    </Component>
  );
};

LoadingBlockContainer.propTypes = propTypes;
LoadingBlockContainer.defaultProps = defaultProps;

export default LoadingBlockContainer;
