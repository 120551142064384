import { VIDEO_CITIES_ENDPOINT } from 'src/modules/video/common';

export default {
  endpoint: VIDEO_CITIES_ENDPOINT,
  idField: () => Math.random(),
  transformArrayResponse: (response: string[]) => {
    return {
      data: response.map((name: string) => ({ name })),
    };
  },
  defaults: {
    id: undefined,
    name: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
