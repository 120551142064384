import React, { FC, useEffect } from 'react';

import { MapPage } from 'src/cluster/common';
import {
  AcEditorPageForm,
  EditorSidebar,
  EditorMap,
  EditorMapLegend,
} from 'src/cluster/editor-map';

export interface InjectedProps {
  pageForm: AcEditorPageForm;
  onInit(): void;
}

type Props = InjectedProps;

const EditorPage: FC<Props> = (props) => {
  const { pageForm, onInit } = props;

  useEffect(onInit, []);

  return (
    <MapPage<AcEditorPageForm>
      pageForm={pageForm}
      sidebar={EditorSidebar}
      map={EditorMap}
      legend={EditorMapLegend}
    />
  );
};

export default EditorPage;
