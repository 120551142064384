import React, {
  FC, useState, useRef, useCallback,
} from 'react';
import { Feature, Point } from 'src/interfaces';
import classNames from 'classnames';
import { InteractiveMap } from 'react-map-gl';
import { WebMercatorViewportOptions } from 'viewport-mercator-project';

import { usePopup } from 'src/constants';

import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import { WithTranslate } from 'src/i18n';
import { Map } from 'src/modules/general/common';
import {
  GeoStop, Stop, VideoMapLegend, VideoRouteTripsModel,
} from 'src/modules/video/common';

import { useMapFittedToNodes, useMapStyle } from './hooks';
import VideoRouteMapPopup from './components/VideoRouteMapPopup';

import style from './index.module.css';

export interface OwnProps {
  className?: string;
  trip?: VideoRouteTripsModel;
  geoStops?: GeoStop[];
  stops?: Stop[];
}

export type Props = OwnProps & WithTranslate;

const VideoRouteMap: FC<Props> = (props) => {
  const {
    className,
    trip,
    geoStops = [],
    stops = [],
    locale,
  } = props;

  const [popup, setPopup] = usePopup<Stop>();
  const [viewport, setViewport] = useState<WebMercatorViewportOptions>({
    latitude: 53.25,
    longitude: 50.15,
    zoom: 7,
  });

  useMapFittedToNodes(geoStops, false, viewport, setViewport);

  const mapRef = useRef<InteractiveMap | null>(null);
  const mapStyle = useMapStyle(geoStops || [], trip?.geometries ? trip.geometries[0].geometry : undefined, locale);

  const handleMouseMove = useCallback((e): void => {
    if (!mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point);
    if (!features) return;
    const pointFeature = features.find(f => f.properties?.isPoint) as Feature<Point> | undefined;
    if (pointFeature?.properties) {
      const { geometry, properties } = pointFeature;
      const hoveStop = stops.find((stop) => stop.stopId === properties.stopId);
      setPopup({
        content: hoveStop,
        lat: geometry?.coordinates[1] || 0,
        lng: geometry?.coordinates[0] || 0,
      });
    } else {
      setPopup({
        content: undefined,
        lat: 0,
        lng: 0,
      });
    }
  }, [stops]);

  return (
    <LoadingBlockContainer
      className={classNames(style.root, className)}
      isBlocked={!trip}
    >
      <Map
        mapRef={mapRef}
        interactiveLayerIds={[
          'points',
          'first-point',
          'last-point',
          'edges',
        ]}
        mapStyle={mapStyle}
        viewport={viewport}
        setViewport={setViewport}
        legend={<VideoMapLegend />}
        onMouseMove={handleMouseMove}
      >
        {popup.content && (
          <VideoRouteMapPopup
            lat={popup.lat}
            lng={popup.lng}
            stop={popup.content}
          />
        )}
      </Map>
    </LoadingBlockContainer>
  );
};

export default VideoRouteMap;
