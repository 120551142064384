import React, {
  ChangeEvent, FC, Fragment, useEffect,
} from 'react';
import {
  ButtonGroup, Divider, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Button, Checkbox, SelectField } from 'src/components';
import { sortOptionsByNumber } from 'src/helpers';

import {
  VehicleType,
  MatrixForecastProgress,
  useAcVehicleTypeLabel,
  useAcVehicleTypeName,
  useSortedByLabelVehicleTypes,
} from 'src/cluster/common';
import {
  AcDistributionMapPageForm,
  MapModes,
  ViewModes,
  useDistributionMapRoutesOptions,
  useDistributionMapRouteVariantsOptions,
  useDistributionVehicleTypesOptions,
  DistGraphSettings,
} from 'src/cluster/distribution-map';

import { VehicleTypeCheckbox } from './components';

import useStyles from './styles';

export interface Props extends WithTranslate {
  pageForm: AcDistributionMapPageForm;
  changeMapMode(value: MapModes): void;
  changeViewMode(value: string): void;
  changeShowEdges(event: ChangeEvent<{ value: unknown }>, checked: boolean): void;
  changeShowStops(event: ChangeEvent<{ value: unknown }>, checked: boolean): void;
  changeVehicleTypeIds(values: number[]): void;
  changeVehicleTypeId(event: ChangeEvent<{ value: unknown }>): void;
  changeRouteId(event: ChangeEvent<{ value: unknown }>): void;
  changeVariantId(event: ChangeEvent<{ value: unknown }>): void;
}

const DistributionMapSidebar: FC<Props> = (props) => {
  const {
    pageForm: {
      mapMode,
      viewMode,
      showEdges = false,
      showStops = false,
      vehicleTypeIds = [],
      vehicleTypeId,
      routeId,
      variantId,
    },
    t,
    changeMapMode,
    changeViewMode,
    changeShowEdges,
    changeShowStops,
    changeVehicleTypeIds,
    changeVehicleTypeId,
    changeRouteId,
    changeVariantId,
  } = props;

  const classes = useStyles();

  const vehicleTypes = useSortedByLabelVehicleTypes();
  const vehicleTypeOptions = useDistributionVehicleTypesOptions();
  const routesOptions = useDistributionMapRoutesOptions();
  const variantsOptions = useDistributionMapRouteVariantsOptions();
  const getVehicleName = useAcVehicleTypeName();
  const getVehicleLabel = useAcVehicleTypeLabel();

  const handleViewModeChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    changeViewMode(value);
  };

  const setDiagramMode = () => changeMapMode(MapModes.diagram);

  const setHeatMode = () => {
    changeMapMode(MapModes.heat);
    if (vehicleTypeIds.length > 1 && vehicleTypes.length) {
      changeVehicleTypeIds([vehicleTypes[0].id]);
    }
  };

  const createCheckedHandler = (id: number) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (mapMode === MapModes.heat) {
      changeVehicleTypeIds([id]);
    } else if (checked) {
      changeVehicleTypeIds(vehicleTypeIds.concat(id));
    } else {
      const newValue = vehicleTypeIds.filter(value => value !== id);
      changeVehicleTypeIds(newValue);
    }
  };

  useEffect(() => {
    if (vehicleTypes.length && !vehicleTypeIds.length) {
      changeVehicleTypeIds([vehicleTypes[0].id]);
    }
  }, [vehicleTypes.length]);

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <MatrixForecastProgress />
        <DistGraphSettings canShowModal />
      </div>
      <Divider />
      <div className={classes.block}>
        <ButtonGroup
          color="primary"
          size="large"
          className={classes.buttonGroup}
        >
          <Button
            variant={mapMode === MapModes.diagram ? 'contained' : 'outlined'}
            onClick={setDiagramMode}
          >
            {t('systems.distribution.mapModes.diagram')}
          </Button>
          <Button
            variant={mapMode === MapModes.heat ? 'contained' : 'outlined'}
            onClick={setHeatMode}
          >
            {t('systems.distribution.mapModes.heat')}
          </Button>
        </ButtonGroup>
        {mapMode === MapModes.diagram && (
          <Fragment>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showEdges}
                  name="showEdges"
                  onChange={changeShowEdges}
                />
              )}
              label={t('systems.distribution.captions.byInterStopRuns')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showStops}
                  name="showEdges"
                  onChange={changeShowStops}
                />
              )}
              label={t('systems.distribution.captions.byStoppingPoints')}
            />
          </Fragment>
        )}
        <Divider />
        <RadioGroup
          name="viewMode"
          value={viewMode}
          onChange={handleViewModeChange}
        >
          <FormControlLabel
            value={ViewModes.byVehicles}
            control={<Radio color="primary" size="small" />}
            label={`${t('systems.distribution.captions.byVehicleTypes')}`}
          />
          <div className={classes.group}>
            {vehicleTypes.map((type) => (
              <VehicleTypeCheckbox
                key={type.name}
                name={getVehicleName(type.id)}
                label={getVehicleLabel(type.id)}
                checked={vehicleTypeIds.includes(type.id)}
                disabled={viewMode !== ViewModes.byVehicles}
                onChange={createCheckedHandler(type.id)}
              />
            ))}
            <VehicleTypeCheckbox
              name={VehicleType.all}
              label={`${t('systems.distribution.captions.allVehicleTypes')}`}
              checked={vehicleTypeIds.includes(-1)}
              disabled={viewMode !== ViewModes.byVehicles}
              onChange={createCheckedHandler(-1)}
            />
          </div>
          <FormControlLabel
            value={ViewModes.byRoutes}
            control={<Radio color="primary" size="small" />}
            label={`${t('systems.distribution.captions.bySeparateRoutes')}`}
          />
          <div className={classes.group}>
            <div className={classes.subGroup}>
              <SelectField
                className={classes.select}
                value={vehicleTypeId}
                options={vehicleTypeOptions}
                disabled={viewMode !== ViewModes.byRoutes}
                onChange={changeVehicleTypeId}
              />
              <span className={classes.selectLabel}>
                {t('systems.distribution.columns.vehicleType')}
              </span>
            </div>
            <div className={classes.subGroup}>
              <SelectField
                className={classes.select}
                value={routeId}
                options={routesOptions}
                disabled={viewMode !== ViewModes.byRoutes}
                sort={sortOptionsByNumber}
                onChange={changeRouteId}
              />
              <span className={classes.selectLabel}>
                {t('systems.distribution.columns.route')}
              </span>
            </div>
            <div className={classes.subGroup}>
              <SelectField
                className={classes.select}
                value={variantId}
                options={variantsOptions}
                disabled={viewMode !== ViewModes.byRoutes}
                onChange={changeVariantId}
              />
              <span className={classes.selectLabel}>
                {t('systems.distribution.columns.routeVariant')}
              </span>
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default DistributionMapSidebar;
