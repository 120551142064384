import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';

import { AcVehicleTypesModel, selectVehicleTypes } from 'src/cluster/common';

const useVehicleTypes = (): Model<AcVehicleTypesModel>[] => {
  const vehicleTypes = useSelector(selectVehicleTypes);
  return vehicleTypes.orJust([]);
};

export default useVehicleTypes;
