/* eslint-disable max-len */
import React from 'react';

const ArrowDown = () => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none">
    <path d="M0 0L6 6L12 0L0 0Z" fill="currentColor" />
  </svg>
);

export default ArrowDown;
