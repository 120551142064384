import { createSelector } from 'reselect';

import {
  api,
  forms,
} from 'redux-restify';

import {
  PROJECTS_PAGES_FORM_NAMES,
  PROJECTS_MODEL_NAMES,
  PageProjectForm,
} from 'src/modules/general/projects';

import { AppState } from 'src/reducer';

import { Systems } from 'src/constants';
import { Writable } from 'src/types';
import {
  NODES_MODEL_NAMES,
  ROUTES_MODEL_NAMES,
  ROUTES_PAGES_FORM_NAMES,
  NodeModel,
  RouteModel,
  PageRoutesForm,
} from '../constants';

export const getRoutesSelectors = (type: Systems) => {
  const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[type];
  const pageRoutesFormName = ROUTES_PAGES_FORM_NAMES[type];
  const projectsModelName = PROJECTS_MODEL_NAMES[type];
  const nodesModelName = NODES_MODEL_NAMES[type];
  const routesModelName = ROUTES_MODEL_NAMES[type];
  const getCurrentProjectNodes = createSelector(
    [
      (state) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
      (state) => api.selectors.entityManager[nodesModelName].getEntities<NodeModel>(state),
    ],
    (pageProjectForm, nodesEntities) => {
      if (!pageProjectForm.selectedProject) {
        return [];
      }
      const nodesApiConfig = {
        parentEntities: {
          [projectsModelName]: pageProjectForm.selectedProject,
        },
      };
      return nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A');
    },
  );

  const getRoutesApiConfig = (
    pageProjectForm: PageProjectForm,
    pageRoutesForm: PageRoutesForm,
  ) => {
    if (!pageProjectForm.selectedProject) {
      return undefined;
    }
    if (!pageRoutesForm || (!pageRoutesForm.from && !pageRoutesForm.to && !pageRoutesForm.transportType)) {
      return undefined;
    }
    return {
      parentEntities: {
        [projectsModelName]: pageProjectForm.selectedProject,
      },
      filter: {
        firstNode: pageRoutesForm ? pageRoutesForm.from : '',
        secondNode: pageRoutesForm ? pageRoutesForm.to : '',
        transportType: pageRoutesForm ? pageRoutesForm.transportType : '',
        maxAlternativeRoutes: pageRoutesForm ? pageRoutesForm.maxAlternativeRoutes : 10,
      },
    };
  };

  const getRoutesSelectorsConst = [
    (state: AppState) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[routesModelName].getEntities<RouteModel>(state),
  ] as const;
  const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

  const getCurrentProjectRoutes = createSelector(
    getProjectRoutesSelectors,
    (pageProjectForm, pageRoutesForm, routesEntities) => {
      const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm);
      if (!routesApiConfig) {
        return {
          currentRoutes: [],
          currentRoutesIsLoading: false,
        };
      }
      return {
        currentRoutes: routesEntities.getArray(routesApiConfig),
        currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
      };
    },
  );

  return {
    getCurrentProjectNodes,
    getCurrentProjectRoutes,
  };
};
