import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema,
  RouteScheme,
  getUrl,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';
import { Systems, SystemsContext } from 'src/constants';

import { ProjectSelectorDropDown } from 'src/modules/general/projects';
import { AggloProjectsPage } from 'src/modules/mth/projects';
import { AggloCalcPage } from 'src/modules/mth/calculations';
import { AggloRoutesPage } from 'src/modules/mth/routes';
import { AggloIndicatorsPage } from 'src/modules/mth/indicators';

interface Props extends WithTranslate {
  match?: any;
}

const MthRoot: FC<Props> = (props) => {
  const {
    match,
    t,
  } = props;

  return (
    <SystemsContext.Provider value={Systems.mth}>
      <PageWithHeader
        basePath={['mth']}
        menuRenderers={{
          projects: {
            label: t('modules.projects.captions.title'),
          },
          calculations: {
            label: t('modules.calculations.captions.title'),
          },
          routeAnalysis: {
            label: t('modules.routes.captions.title'),
          },
          indicators: {
            label: t('modules.indicators.captions.title'),
          },
        }}
        headerChildren={(
          <Route path={getUrl(urlSchema.mth.projects)}>
            {({ match: currentMatch }) => {
              if (currentMatch) return null;
              return <ProjectSelectorDropDown />;
            }}
          </Route>
        )}
      >
        <RouteScheme
          prevMatch={match}
          basePath={['mth']}
          renderers={{
            projects: {
              component: AggloProjectsPage,
            },
            calculations: {
              component: AggloCalcPage,
            },
            routeAnalysis: {
              component: AggloRoutesPage,
            },
            indicators: {
              component: AggloIndicatorsPage,
            },
          }}
        />
      </PageWithHeader>
    </SystemsContext.Provider>
  );
};

export default MthRoot;
