import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';

import { DISTRIBUTION_STOPS_DAY_ENDPOINT } from 'src/cluster/distribution-table';

export default {
  endpoint: DISTRIBUTION_STOPS_DAY_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    stopId: undefined,
    stopName: undefined,
    vehicleTypes: undefined,
    latitude: undefined,
    longitude: undefined,
    passengerTraffic: undefined,
    boardingTripBegin: undefined,
    boardingTransfer: undefined,
    boardingOverall: undefined,
    getOffTripEnd: undefined,
    getOffTransfer: undefined,
    getOffOverall: undefined,
    transfer: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  allowIdRequests: false,
};
