import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { AutocompleteOption, SelectMenuOption } from 'src/interfaces';
import { useGenerateOptions } from 'src/cluster/common';
import { Autocomplete, Submit } from 'src/components';
import {
  TOP_FIXED_COLUMNS,
  BOTTOM_FIXED_COLUMNS,
  TOP_COLUMNS,
  BOTTOM_COLUMNS,
  BottomColumns,
  TopColumns,
  AcRegistryPageForm,
} from 'src/cluster/editor-table';

import useStyles from './styles';

export interface InjectedProps {
  tableSettings: Partial<AcRegistryPageForm>;
  setTableSettings(top: TopColumns[], bottom: BottomColumns[]): void;
}

type Props = InjectedProps & WithTranslate;

const RegistryModalTableSettings: FC<Props> = (props) => {
  const { t, tableSettings: { topLevel, bottomLevel }, setTableSettings } = props;
  const translate = 'modules.registries.columns.';
  const topOptions = useGenerateOptions(TOP_COLUMNS, translate);
  const fixedTopOptions = useGenerateOptions(TOP_FIXED_COLUMNS, translate);
  const bottomOptions = useGenerateOptions(BOTTOM_COLUMNS, translate);
  const fixedBottomOptions = useGenerateOptions(BOTTOM_FIXED_COLUMNS, translate);

  const topItems = topLevel ? useGenerateOptions(topLevel, translate) : [];
  const bottomItems = bottomLevel ? useGenerateOptions(bottomLevel, translate) : [];

  const [topColumns, setTopColumns] = React.useState<SelectMenuOption[]>(topItems);
  const [bottomColumns, setBottomColumns] = React.useState<SelectMenuOption[]>(bottomItems);

  const classes = useStyles();

  const onChangeTop = (val: AutocompleteOption) => setTopColumns(val as SelectMenuOption[]);
  const onChangeBottom = (val: AutocompleteOption) => setBottomColumns(val as SelectMenuOption[]);
  const setSettings = () => {
    const top = topColumns.map(col => col.value as TopColumns);
    const bottom = bottomColumns.flatMap(col => col.value as BottomColumns);
    setTableSettings(top, bottom);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        placeholder={t(
          'modules.registries.captions.selectedColumns',
          { count: topColumns?.length || 0 },
        )}
        label={t('modules.registries.captions.topLevel')}
        options={topOptions}
        selected={topColumns}
        fixedOptions={fixedTopOptions}
        className={classes.topLevel}
        onChange={onChangeTop}
      />
      <Autocomplete
        placeholder={t(
          'modules.registries.captions.selectedColumns',
          { count: bottomColumns?.length || 0 },
        )}
        label={t('modules.registries.captions.bottomLevel')}
        limitTags={8}
        options={bottomOptions}
        selected={bottomColumns}
        fixedOptions={fixedBottomOptions}
        className={classes.bottomLevel}
        onChange={onChangeBottom}
      />
      <Grid container direction="row" justify="flex-end">
        <Submit
          color="primary"
          variant="contained"
          fullWidth
          isLoading={false}
          disabled={false}
          className={classes.btn}
          onClick={setSettings}
        >
          {t('common.captions.apply')}
        </Submit>
      </Grid>
    </div>
  );
};

export default RegistryModalTableSettings;
