import { useSelector } from 'react-redux';

import { AcRouteVariant } from 'src/cluster/common';
import { selectDistributionMapSelectedRoute } from 'src/cluster/distribution-map';

const useDistributionMapRouteVariants = (): AcRouteVariant[] => {
  const route = useSelector(selectDistributionMapSelectedRoute);
  return route.map((value) => value.variants).orJust([]);
};

export default useDistributionMapRouteVariants;
