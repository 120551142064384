import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
    },

    link: {},

    url: {
      display: 'flex',
      transition: 'none',
      height: '100%',
      width: '100%',
      color: theme.palette.grey[700],

      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },

      '& div, & a': {
        transition: 'none',
      },
    },

    active: {
      position: 'relative',
      color: theme.palette.common.black,
      fontWeight: 'bold',

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -1,
        left: '0',
        right: '0',
        backgroundColor: theme.palette.secondary.main,
        height: 3,
      },
    },

    linkContent: {
      display: 'flex',
      height: '100%',
      width: '100%',
      padding: theme.spacing(0, 5),
      fontSize: 14,
      alignItems: 'center',
      textAlign: 'center',
    },

    icon: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 22,
      height: 22,
      marginRight: 10,
      flex: '0 0 auto',
    },
  });
});
