import React from 'react';

const Confirm = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M8,16.2l-4.8-4.8L1,13.7l7,7L23,5.7l-2.2-2.2L8,16.2z" />
    </svg>
  );
};

export default Confirm;
