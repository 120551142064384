import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },

  row: {
    fontStyle: 'italic',
    color: theme.palette.grey[800],
    fontSize: 12,
    lineHeight: '12px',
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(2, 4, 3, 28),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[400],
  },

  firstColumn: {
    width: 86,
  },

  icon: {
    color: theme.palette.primary.main,
    verticalAlign: 'top',
    paddingTop: 10,
  },

  message: {
    padding: 10,

    '&> span :first-child': {
      display: 'block',
    },

    '&> span :last-child': {
      display: 'flex',
      marginTop: theme.spacing(2),
      justifyContent: 'flex-end',
    },
  },

  modalLink: {
    textStyle: 'italic',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
