import React, { FC, useRef } from 'react';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import InteractiveMap from 'react-map-gl';

import { Position } from 'src/interfaces';
import { usePopup } from 'src/constants';
import { WithTranslate } from 'src/i18n';

import { Map, NodeModel } from 'src/cluster/common';
import {
  AcDistributionMapPageForm,
  useDistributionMapStyle,
  useDistributionOnMouseMove,
} from 'src/cluster/distribution-map';

import DistributionMapPopup from './components/DistributionMapPopup';

export interface OwnProps {
  pageForm: AcDistributionMapPageForm;
  nodesEntities: [NodeModel[], boolean];
  showLegend(): void;
}

type Props = OwnProps & WithTranslate;

const DistributionMap: FC<Props> = (props) => {
  const {
    nodesEntities: [nodes, isNodesLoading = false],
    showLegend,
  } = props;

  const isLoading = isNodesLoading;

  const mapRef = useRef<InteractiveMap | null>(null);

  const [popup, setPopup] = usePopup<MapboxGeoJSONFeature>();

  const [mapStyle, interactiveLayerIds] = useDistributionMapStyle(nodes);

  const positions = nodes.reduce<Position[]>(
    (memo, node) => {
      return memo.concat([[node.lng, node.lat]]);
    },
    [],
  );

  const handleMouseMove = useDistributionOnMouseMove(mapRef, setPopup, !!popup);

  return (
    <Map
      mapRef={mapRef}
      isBlocked={isLoading}
      canBeFitted={!isLoading}
      mapStyle={mapStyle}
      interactiveLayerIds={interactiveLayerIds}
      positionsForFit={positions}
      showLegend={showLegend}
      onMouseMove={handleMouseMove}
    >
      {popup.content && (
        <DistributionMapPopup
          feature={popup.content}
          lng={popup.lng}
          lat={popup.lat}
        />
      )}
    </Map>
  );
};

export default DistributionMap;
