import { VIDEO_VEHICLE_MAKE_MODELS_ENDPOINT } from 'src/modules/video/common';

export default {
  endpoint: VIDEO_VEHICLE_MAKE_MODELS_ENDPOINT,
  defaults: {
    id: undefined,
    name: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
