import React, {
  FC,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import classNames from 'classnames';
import clsx from 'clsx';

import { Autocomplete, Button, SelectField } from 'src/components';

import { ExcelIcon } from 'src/components/icons';

import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components/toolbar';

import {
  useAcIntervalOptions,
  useAcMunicipalitiesOptions,
  useAcFilterModesOptions,
  useAcNodesOptions,
  hasToResetSelect,
  setMaxValue,
  setDefaultValue,
  useCallOnChange,
  FILTER_MODES,
} from 'src/cluster/common';

import {
  useScenarios,
  useBehaviorTypes,
  useTtcYears,
} from 'src/cluster/ttc';
import { AutocompleteOption } from 'src/interfaces';
import useStyles from './styles';
import { Props } from './interfaces';

const BalanceTtcToolbar: FC<Props> = (props) => {
  const {
    className,
    pageForm: {
      filterModeFrom,
      filterModeTo,
      regionFrom = [],
      regionTo = [],
      municipalityFrom = [],
      municipalityTo = [],
      year,
      scenario,
      behaviorType,
      interval,
      isEdit,
    },
    t,
    isSavable,
    switchToEdit,
    switchToList,
    resetPageForm,
    setFilterModeFrom,
    setFilterModeTo,
    setRegionFrom,
    setRegionTo,
    setMunicipalityFrom,
    setMunicipalityTo,
    setYear,
    setScenario,
    setBehaviorType,
    setInterval,
    saveAll,
    exportFlows,
  } = props;

  const classes = useStyles();

  useCallOnChange(resetPageForm);

  const nodesItems = useAcNodesOptions();
  const municipalitiesItems = useAcMunicipalitiesOptions();
  const yearsItems = useTtcYears();
  const behaviorTypesItems = useBehaviorTypes();
  const scenariosItems = useScenarios();
  const modes = useAcFilterModesOptions();
  const intervalOptions = useAcIntervalOptions();

  const selectedRegionsFrom = regionFrom ? nodesItems.filter(item => regionFrom.includes(Number(item.value))) : [];
  const selectedRegionsTo = regionTo ? nodesItems.filter(item => regionTo.includes(Number(item.value))) : [];

  const selectedMunicipalityFrom = municipalityFrom ?
    municipalitiesItems.filter(item => municipalityFrom.includes(Number(item.value))) : [];
  const selectedMunicipalityTo = municipalityTo ?
    municipalitiesItems.filter(item => municipalityTo.includes(Number(item.value))) : [];

  // set default year filter
  useEffect(() => {
    if (hasToResetSelect(yearsItems, Number(year))) {
      setMaxValue(setYear, yearsItems);
    }
  }, [yearsItems]);

  // set default scenario filter
  useEffect(() => {
    if (hasToResetSelect(scenariosItems, Number(scenario))) {
      setDefaultValue(setScenario, scenariosItems);
    }
  }, [scenariosItems, year]);

  // set default behavior type filter

  useEffect(() => {
    if (hasToResetSelect(behaviorTypesItems, Number(behaviorType))) {
      setDefaultValue(setBehaviorType, behaviorTypesItems);
    }
  }, [behaviorTypesItems]);

  // set default interval filter
  useEffect(() => {
    if (hasToResetSelect(intervalOptions, Number(interval))) {
      setMaxValue(setInterval, intervalOptions);
    }
  }, [intervalOptions]);

  const handleRegionFromChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setRegionFrom(values.map(item => item.value as number));
    } else {
      setRegionFrom([]);
    }
  }, [regionFrom]);

  const handleRegionToChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setRegionTo(values.map(item => item.value as number));
    } else {
      setRegionTo([]);
    }
  }, [regionTo]);

  const handleMunicipalityFromChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setMunicipalityFrom(values.map(item => item.value as number));
    } else {
      setMunicipalityFrom([]);
    }
  }, [municipalityFrom]);

  const handleMunicipalityToChange = useCallback((values: AutocompleteOption) => {
    if (Array.isArray(values)) {
      setMunicipalityTo(values.map(item => item.value as number));
    } else {
      setMunicipalityTo([]);
    }
  }, [municipalityTo]);

  return (
    <Toolbar className={classNames(classes.root, className)}>
      <ToolbarBlock
        title={t('common.captions.filter')}
        size="x-small"
      >
        <ToolbarControls position="top">
          <ToolbarControl>
            <SelectField
              label={t('systems.balance.captions.territorialCell')}
              options={modes}
              value={filterModeFrom}
              fullWidth
              onChange={setFilterModeFrom}
              className={classes.tightSelect}
            />

          </ToolbarControl>
          <ToolbarControl>
            {filterModeFrom === FILTER_MODES.node ? (

              <Autocomplete
                multiple
                label={t('common.captions.from')}
                placeholder={t('components.captions.select')}
                limitTags={8}
                options={nodesItems}
                selected={selectedRegionsFrom}
                onChange={handleRegionFromChange}
                className={classes.select}
              />
            ) : (
              <Autocomplete
                multiple
                label={t('common.captions.from')}
                placeholder={t('components.captions.select')}
                limitTags={8}
                options={municipalitiesItems}
                selected={selectedMunicipalityFrom}
                onChange={handleMunicipalityFromChange}
                className={classes.select}
              />
            )}
          </ToolbarControl>
          <ToolbarControl>
            <SelectField
              label={t('systems.balance.captions.territorialCell')}
              options={modes}
              value={filterModeTo}
              fullWidth
              onChange={setFilterModeTo}
              className={classes.select}
            />

          </ToolbarControl>
          <ToolbarControl>
            {filterModeTo === FILTER_MODES.node ? (

              <Autocomplete
                multiple
                label={t('common.captions.to')}
                placeholder={t('components.captions.select')}
                limitTags={8}
                options={nodesItems}
                selected={selectedRegionsTo}
                onChange={handleRegionToChange}
                className={classes.select}
              />

            ) : (
              <Autocomplete
                multiple
                label={t('common.captions.to')}
                placeholder={t('components.captions.select')}
                limitTags={8}
                options={municipalitiesItems}
                selected={selectedMunicipalityTo}
                onChange={handleMunicipalityToChange}
                className={classes.select}
              />
            )}
          </ToolbarControl>
        </ToolbarControls>
        <ToolbarControls position="bottom">
          <ToolbarControl>
            <SelectField
              label={t('common.captions.year')}
              options={yearsItems}
              value={year}
              fullWidth
              onChange={setYear}
              className={classes.tightSelect}
            />
          </ToolbarControl>
          <ToolbarControl>
            <SelectField
              label={t('common.captions.scenario')}
              options={scenariosItems}
              value={scenario}
              fullWidth
              onChange={setScenario}
              className={classes.select}

            />

          </ToolbarControl>
          <ToolbarControl>
            <SelectField
              label={t('systems.balance.captions.behaviorType')}
              options={behaviorTypesItems}
              value={behaviorType}
              fullWidth
              onChange={setBehaviorType}
              className={classes.select}
            />
          </ToolbarControl>
          <ToolbarControl>
            <SelectField
              className={classes.select}
              label={t('common.captions.interval')}
              options={intervalOptions}
              value={interval}
              fullWidth
              sort={false}
              onChange={setInterval}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock size="large">
        <ToolbarControls
          align="right"
          position="top"
        >
          <button
            onClick={exportFlows}
            className={classes.btnWrapper}
          >
            <span className={clsx(classes.iconButtonRed)}>
              <ExcelIcon />
            </span>
            <span className={classes.exportCaption}>
              {t('common.captions.export')}
            </span>
          </button>
        </ToolbarControls>
        <ToolbarControls
          align="right"
          position="bottom"
          className={classes.buttonControls}
        >
          {isEdit ? (
            <Fragment>
              <ToolbarControl>
                <Button
                  className={classes.link}
                  variant="text"
                  onClick={switchToList}
                >
                  {t('common.captions.cancel')}
                </Button>
              </ToolbarControl>
              <ToolbarControl>
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={!isSavable}
                  onClick={saveAll}
                >
                  {t('common.captions.save')}
                </Button>
              </ToolbarControl>
            </Fragment>
          ) : (
            <ToolbarControl>
              <Button
                className={classes.link}
                variant="text"
                onClick={switchToEdit}
              >
                {t('common.captions.edit')}
              </Button>
            </ToolbarControl>
          )}
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default BalanceTtcToolbar;
