import { useWidthRange } from 'src/cluster/distribution-map';

const useEdgePaint = () => {
  const range = useWidthRange();

  return (getFilter: (from: number, to: number) => any[], color: string) => ({
    'line-color': color,
    'line-width': [
      'case',
      ...range.reduce<any[]>((memo, item) => {
        return memo.concat([getFilter(item.from, item.to), item.value]);
      }, []), 1,
    ],
    'line-offset': [
      'case',
      ...range.reduce<any[]>((memo, item) => {
        return memo.concat([getFilter(item.from, item.to), item.value / 2]);
      }, []), 1,
    ],
  });
};

export default useEdgePaint;
