/* eslint-disable max-len */
import React from 'react';

const ArrowUp = () => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none">
    <path d="M0 6L6 0L12 6L0 6Z" fill="currentColor" />
  </svg>
);

export default ArrowUp;
