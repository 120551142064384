import { CompositeLayer } from 'deck.gl';
import { CompositeLayerProps } from '@deck.gl/core/lib/composite-layer';
import type { PickInfo } from '@deck.gl/core/lib/deck';
import { darken, rgbToHex } from '@material-ui/core';

import { Feature, FeatureCollection } from 'src/interfaces';

import palette from 'src/theme/palette';

import { hexToArray } from 'src/cluster/common';
import { createLayer } from 'src/cluster/editor-map';

export interface DirectionLayerProps<D> extends CompositeLayerProps<D> {
  pointsData: D;
  edgesData: D;
  editable: boolean;
  color: string;
  selectedNodeId?: number;
  selectedStopId?: number;
  selectedPointIndexes?: number[];
  onClick: ({ object }: PickInfo<any>, e: any) => void;
}

class DirectionLayer extends CompositeLayer<FeatureCollection, DirectionLayerProps<FeatureCollection>> {
  renderLayers() {
    return [
      createLayer({
        id: 'direction-edges',
        data: this.props.edgesData,
        visible: this.props.visible,
        pickable: this.props.pickable,
        getLineWidth: () => 3,
        getFillColor: () => hexToArray(this.props.color),
        getLineColor: () => hexToArray(this.props.color),
      }),
      createLayer({
        id: 'direction-points',
        data: this.props.pointsData,
        visible: this.props.visible,
        pickable: this.props.pickable,
        selectedFeatureIndexes: this.props.selectedPointIndexes,
        getLineWidth: (feature: Feature) => {
          const { nodeId, stopId } = feature.properties || {};
          if (this.props.selectedNodeId) {
            return (this.props.selectedNodeId === nodeId) ? 6 : 3;
          }
          if (this.props.selectedStopId) {
            return (this.props.selectedStopId === stopId) ? 6 : 3;
          }

          return 3;
        },
        getRadius: (feature: Feature) => {
          const { nodeId, stopId } = feature.properties || {};
          if (this.props.selectedNodeId) {
            return (this.props.selectedNodeId === nodeId) ? 8 : 5;
          }
          if (this.props.selectedStopId) {
            return (this.props.selectedStopId === stopId) ? 13 : 10;
          }

          return stopId ? 10 : 5;
        },
        getFillColor: (feature: Feature) => {
          if (this.props.editable) {
            return feature.properties?.stopId
              ? hexToArray(palette.common.white)
              : hexToArray(rgbToHex(darken(this.props.color, 0.25)));
          }
          return feature.properties?.stopId
            ? hexToArray(palette.common.white)
            : hexToArray(palette.common.white, 0);
        },
        getLineColor: (feature: Feature) => {
          if (this.props.editable && feature.properties?.order === 0) {
            return hexToArray(palette.primary.main);
          }
          if (this.props.editable) {
            return feature.properties?.stopId
              ? hexToArray(this.props.color)
              : hexToArray(palette.grey[500]);
          }
          return feature.properties?.stopId
            ? hexToArray(this.props.color)
            : hexToArray(palette.common.white, 0);
        },
      }),
    ];
  }
}

export default DirectionLayer;
