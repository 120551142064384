/* eslint-disable max-len */
import React from 'react';

const ArrowRight = () => (
  <svg width="7" height="14" viewBox="0 0 7 14" fill="none">
    <path d="M0 0L7 7L0 14V0Z" fill="currentColor" />
  </svg>
);

export default ArrowRight;
