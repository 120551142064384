import React from 'react';

const MoveIcon = () => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4739 10.4275H19.4111L17.6299 8.7507L19.3161 7.1633L23.9709 11.5454L19.3161 15.9275L17.6299 14.3401L19.4111 12.6633H13.4739V18.2527L15.2551 16.5759L16.9412 18.1633L12.2865 22.5454L7.6317 18.1633L9.31786 16.5759L11.099 18.2527V12.6633H5.16182L6.94298 14.3401L5.25682 15.9275L0.602051 11.5454L5.25682 7.1633L6.94298 8.7507L5.16182 10.4275H11.099V4.83809L9.31786 6.51492L7.6317 4.92752L12.2865 0.54541L16.9412 4.92752L15.2551 6.51492L13.4739 4.83809V10.4275Z"
      fill="currentColor"
    />
  </svg>
);

export default MoveIcon;
