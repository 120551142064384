import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UNITS } from 'src/constants';

import { selectDistGraphForm } from 'src/cluster/distribution-common';

const useDistributionUnit = (): string => {
  const { t } = useTranslation();
  const { day } = useSelector(selectDistGraphForm);
  return day ? t(UNITS.people) : t(UNITS.peoplePerHour);
};

export default useDistributionUnit;
