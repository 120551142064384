import { useSelector } from 'react-redux';
import { selectAcSites } from 'src/cluster/common';

export const useAcSiteName = (): (id: number) => string => {
  const sites = useSelector(selectAcSites);

  return (id: number) => {
    return sites.map((value) => {
      const record = value.find((site) => {
        return site.id === id;
      });

      return record ? record.name : '-';
    }).orJust('-');
  };
};
export default useAcSiteName;
