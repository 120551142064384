import React, { FC, Fragment } from 'react';

import { WithTranslate } from 'src/i18n';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import {
  SELECTED_ROUTE_COLOR,
  AIR_ROUTE_COLOR,
  SEA_ROUTE_COLOR,
  RAIL_ROUTE_COLOR,
  AUTO_ROUTE_COLOR,
} from '../../index';

import style from './index.module.css';

const ShipMapLegend: FC<WithTranslate> = ({ t }) => {
  return (
    <Fragment>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div className={style.routePoint} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.routePoint')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div className={style.correspondencePoint} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.correspondencePoint')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <PIcon {...{
            type: ICONS_TYPES.mapBorderCross,
            className: style.marker,
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.mapBorderCross')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <PIcon {...{
            type: ICONS_TYPES.mapPort,
            className: style.marker,
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.port')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <PIcon {...{
            type: ICONS_TYPES.airPort,
            className: style.marker,
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.airport')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div {...{
            className: style.selectedRouteLine,
            style: {
              borderColor: SELECTED_ROUTE_COLOR,
            },
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.selectedRoute')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div {...{
            className: style.routeLine,
            style: {
              borderColor: AIR_ROUTE_COLOR,
            },
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.airRoute')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div {...{
            className: style.routeLine,
            style: {
              borderColor: SEA_ROUTE_COLOR,
            },
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.seaRoute')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div {...{
            className: style.routeLine,
            style: {
              borderColor: RAIL_ROUTE_COLOR,
            },
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.railRoute')}
        </div>
      </div>
      <div className={style.legendEntry}>
        <div className={style.legendIcon}>
          <div {...{
            className: style.routeLine,
            style: {
              borderColor: AUTO_ROUTE_COLOR,
            },
          }} />
        </div>
        <div className={style.legendTitle}>
          {t('systems.shipments.captions.autoRoute')}
        </div>
      </div>
    </Fragment>
  );
};

export default ShipMapLegend;
