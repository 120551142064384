import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { AppState } from 'src/reducer';

import { AC_FORECAST_PAGE_FORM_NAME, selectMatrixForecast } from 'src/cluster/common';
import { selectAcForecastPageForm } from 'src/cluster/balance-common';
import { buildForecast, buildRPM } from 'src/cluster/balance-data';

import { forms } from 'redux-restify';
import { DISTRIBUTION_GRAPH_FORM_NAME } from 'src/cluster/distribution-common';
import ModalDataForecast from './ModalDataForecast';

const mapStateToProps = (state: AppState) => ({
  pageForecastForm: selectAcForecastPageForm(state),
  matrixForecastEntities: selectMatrixForecast(state),
});
const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  close: () => dispatch(closeAction),
  setLoadingForecast: () => dispatch(forms.actions[AC_FORECAST_PAGE_FORM_NAME].changeField('isCalculate', true)),
  setLoadingRmp: (flag: boolean) => dispatch(forms.actions[DISTRIBUTION_GRAPH_FORM_NAME].changeField('isCalculate', flag)),
  buildForecast: (fullRemote: number, buildTTC: boolean) => dispatch(buildForecast(fullRemote, buildTTC)),
  buildRPM: (ttc: boolean, matrix: boolean, percent: number) => dispatch(buildRPM(ttc, matrix, percent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalDataForecast));
