import React, {
  SyntheticEvent, FC, ElementType, Fragment, ReactNode,
} from 'react';
import { CircularProgress } from '@material-ui/core';

import { Button } from 'src/components';

type Props = {
  className?: string;
  disabled?: boolean;
  caption?: ReactNode;
  isLoading: boolean;
  variant?: 'contained' | 'outlined' | 'text' | 'link' | 'icon';
  size?: 'small' | 'medium' | 'large';
  textLabel?: string;
  fullWidth?: boolean;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  component?: ElementType;
  onClick?: (e: SyntheticEvent) => void;
}

const Submit: FC<Props> = (props) => {
  const {
    children,
    disabled,
    isLoading,
    ...rest
  } = props;

  return (
    <Button
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? (
        <Fragment>
          <CircularProgress
            size={20}
            thickness={7}
            value={100}
            color="primary"
          />
        </Fragment>
      ) : children}
    </Button>
  );
};

export default Submit;
