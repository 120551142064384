import React, {
  ReactChild, ReactChildren, useEffect, useState,
} from 'react';
import { Maybe } from 'monet';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import Pagination from 'src/components/etc/Pagination';

import useStyles from './styles';

interface OwnProps<T> {
  data: Maybe<T[]>;
  className?: string;
  message?: string;
  isLoading?: boolean;
  loadingLabel?: string;
  currentPage: number;
  pageSize: number;
  count: number;
  onPageChange(value: number): void;
  onPageSizeChange(value: number): void;
  children(data: object[]): ReactChildren | ReactChild;
}

type Props<T> = OwnProps<T> & WithTranslate;

function DataGridContainer<T extends Record<string, any>>(props: Props<T>) {
  const {
    children,
    data,
    className,
    message,
    isLoading = false,
    count,
    pageSize,
    currentPage,
    t,
    onPageChange,
    onPageSizeChange,
  } = props;

  const [currentCount, setCurrentCount] = useState(count);

  const classes = useStyles();

  useEffect(() => {
    if (!isLoading) {
      setCurrentCount(count);
    }
  }, [isLoading, count]);

  const hasNoData = !isLoading && count === 0;

  return (
    <div className={classNames(classes.root, hasNoData && classes.hasNoData, className)}>
      <Pagination
        disabled={isLoading}
        pageSize={pageSize}
        currentPage={currentPage}
        count={currentCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {data.map((just) => children(just)).orJust(children([]))}
        {(message || hasNoData) && (
          <div className={classes.message}>
            {message || t('common.captions.noData')}
          </div>
        )}
        {(isLoading && count === 0) && (
          <div className={classes.message}>
            {t('common.captions.noData')}
          </div>
        )}
      </Pagination>
    </div>
  );
}

export default DataGridContainer;
