import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';

import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import { IndicatorsTask } from 'src/modules/mth/indicators';

import AggloIndicatorsTask from './components/AggloIndicatorsTask';

import style from './index.module.css';

interface OwnProps {
  className?: string;
  tasks: IndicatorsTask[];
  years: number[];
  isLoading: boolean;
}

type Props = OwnProps & WithTranslate;

const AggloIndicatorsTable: FC<Props> = (props) => {
  const {
    className,
    tasks,
    isLoading,
    years,
    t,
  } = props;

  return (
    <LoadingBlockContainer
      isBlocked={isLoading}
      className={classNames(style.root, className)}
    >
      <table className={style.table}>
        <thead>
          <tr>
            <th>{t('common.captions.numberSymbol')}</th>
            <th>{t('modules.indicators.captions.task')}</th>
            <th>{t('modules.indicators.captions.subtask')}</th>
            <th>{t('modules.indicators.captions.indicatorsAndIndexes')}</th>
            <th className={style.typeColumn}>{t('modules.indicators.captions.indicatorType')}</th>
            {years.map(year => <th key={year}>{year}</th>)}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <AggloIndicatorsTask
              key={task.name}
              task={task}
            />
          ))}
        </tbody>
      </table>
    </LoadingBlockContainer>
  );
};

export default AggloIndicatorsTable;
