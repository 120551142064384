import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    padding: spacing(6, 3, 0),
  },

  header: {
    paddingRight: spacing(7),
  },

  container: {
    padding: spacing(4, 0),

    '&:not(:last-of-type)': {
      margin: 0,
    },
  },

  select: {
    marginBottom: spacing(4),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 0,
    gap: spacing(3),
  },

  warning: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing(3),
    color: palette.grey[700],
  },

  icon: {
    width: 20,
    color: palette.warning.main,
  },

  status: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: spacing(3),
  },

  preloader: {
    width: 20,
    height: 20,
  },

  success: {
    color: palette.success.main,
  },

  error: {
    color: palette.secondary.main,
  },

  buttons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: spacing(3),

    '& button': {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
  },
}));
