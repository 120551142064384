import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';

import { MapPage } from 'src/cluster/common';
import {
  AcBalanceMapPageForm,
  BalanceMap,
  BalanceMapSidebar,
  BalanceMapLegend,
} from 'src/cluster/balance-map';

export interface InjectedProps {
  pageForm: AcBalanceMapPageForm;
}

type Props = InjectedProps & WithTranslate;

const BalanceMapPage: FC<Props> = (props) => {
  const { pageForm } = props;

  return (
    <MapPage<AcBalanceMapPageForm>
      pageForm={pageForm}
      sidebar={BalanceMapSidebar}
      map={BalanceMap}
      legend={BalanceMapLegend}
    />
  );
};

export default BalanceMapPage;
