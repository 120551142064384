import React, {
  ChangeEvent, FC, useState,
} from 'react';
import {
  Box, Collapse, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { UNITS } from 'src/constants';
import { WithTranslate } from 'src/i18n';
import {
  Button,
  ChevronRightIcon,
  TextField,
  TrashIcon,
} from 'src/components';
import { useAcIntervalOptions } from 'src/cluster/common';
import { RegistryVariant, useEditVariantConfig } from 'src/cluster/editor-table';

import useStyles from './styles';

export interface OwnProps {
  variant: RegistryVariant;
  count: number;
  titleClasses: Record<'root', string>;
  onChangeVariant(variant: RegistryVariant): void;
}

type Props = OwnProps & WithTranslate;

const RegistryEditVariant: FC<Props> = (props) => {
  const {
    t,
    variant,
    count,
    titleClasses,
    onChangeVariant,
  } = props;

  const classes = useStyles();

  const { intervals, ...variantProps } = variant;
  const intervalsOptions = useAcIntervalOptions();

  const onChangeField = (field: string, val: unknown) => {
    onChangeVariant({ ...variant, [field]: val });
  };

  const onChangeInterval = (id: number, i: number, event: ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    if (!Number.isNaN(Number(value))) {
      const index = intervals.findIndex(vc => vc.intervalId === id);
      if (index !== -1) {
        intervals[index].intervalValue = Number(value) || 0;
      } else {
        intervals.splice(i, 0, {
          intervalId: id,
          intervalName: intervalsOptions[i].label as string,
          intervalValue: Number(value) || 0,
        });
      }
      onChangeField('intervals', intervals);
    }
  };

  const fields = useEditVariantConfig(variantProps, onChangeField, classes);

  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(value => !value);

  let intervalsCol = [[...intervalsOptions]];
  const cutIndex = Math.ceil(intervalsOptions.length / 2);
  if (intervalsOptions.length > 2) {
    intervalsCol = [
      intervalsOptions.slice(0, cutIndex),
      intervalsOptions.slice(cutIndex),
    ];
  }

  return (
    <div className={clsx(variant.deleted && classes.deleted)}>
      <Box display="flex" flexWrap="wrap">
        <Button
          variant="icon"
          className={clsx(classes.btn, collapsed && classes.collapsed)}
          onClick={toggle}
        >
          <ChevronRightIcon />
        </Button>
        {fields.map(field => (
          <TextField
            key={field.label}
            label={t(field.label) + (field.unit ? `, ${t(field.unit)}` : '') + (field.dot ?? '')}
            value={field.value}
            type={field.type}
            readOnly={variant.deleted}
            className={field.className}
            onChange={field.onChange}
          />
        ))}
        {(count > 1 || variant.deleted) && (
          <Button
            variant={variant.deleted ? 'link' : 'icon'}
            onClick={() => onChangeField('deleted', !variant.deleted)}
            className={clsx(variant.deleted && classes.restore)}
          >
            {variant.deleted ? t('common.captions.restore') : <TrashIcon />}
          </Button>
        )}
      </Box>
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <Box ml={6}>
          <Typography classes={titleClasses}>
            {t('modules.registries.columns.gapsAndIntervals')}
          </Typography>
          <Grid container wrap="nowrap">
            {intervalsCol.map((col, i) => (
              <Grid key={i} direction="column" container className={classes.grid}>
                {col.map((interval, n) => (
                  <Grid key={interval.id}>
                    <TextField
                      label={t('modules.registries.columns.gap')}
                      value={interval.label}
                      readOnly
                      className={clsx(classes.field, classes.intervalField)}
                    />
                    <TextField
                      label={`${t('modules.registries.columns.intervals')}, ${t(UNITS.timeMinutes)}`}
                      value={intervals?.find(int => int.intervalId === Number(interval.id))?.intervalValue || 0}
                      type="tel"
                      readOnly={variant.deleted}
                      className={clsx(classes.field, classes.intervalFieldMini)}
                      onChange={val => onChangeInterval(Number(interval.id), (i === 0 ? n : n + cutIndex), val)}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </div>
  );
};

export default RegistryEditVariant;
