import React from 'react';

import { WithTranslate } from 'src/i18n';
import { WithUnit } from 'src/components';
import { UNITS } from 'src/constants';

import { useHeatRange } from 'src/cluster/distribution-map';

import useStyles from './styles';

const DistributionHeatMapLegend = (props: WithTranslate) => {
  const { t } = props;

  const classes = useStyles();
  const heatRange = useHeatRange();

  return (
    <div className={classes.root}>
      <WithUnit
        unit={t(UNITS.coefficient)}
        className={classes.label}
      >
        {t('systems.distribution.mapModes.heat')}
      </WithUnit>
      <div className={classes.desc}>
        {t('systems.distribution.captions.trafficCoefficientDesc')}
      </div>
      <div className={classes.diagram}>
        {heatRange.map((item) => (
          <div
            key={item.value}
            className={classes.item}
          >
            {item.from.toFixed(1)}
          </div>
        ))}
        <div className={classes.item}>{1.2}</div>
      </div>
    </div>
  );
};

export default DistributionHeatMapLegend;
