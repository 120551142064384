import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import modals from 'src/modals';
import i18n from 'src/i18n/i18n';

import { ERROR_DATA_SHOW_MODAL } from 'src/cluster/projects';

import ErrorGrid from './ErrorGrid';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showModal: (error: string) => {
    dispatch(modals.actions.showModal(true, ERROR_DATA_SHOW_MODAL, {
      title: i18n.t('common.statuses.error'),
      errorMessage: error,
    }));
  },
});
export default connect(null, mapDispatchToProps)(ErrorGrid);
