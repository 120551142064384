import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  button: {
    minWidth: 110,
    pointerEvents: 'initial',

    '&.MuiButton-outlined $caption': {
      color: palette.text.primary,
    },
  },

  icon: {
    width: 18,
    height: 18,
    padding: spacing(1),
    boxSizing: 'content-box',

    '&> svg': {
      width: '100%',
      height: '100%',
    },
  },

  caption: {
    maxWidth: 90,
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '14px',
  },
}));
