import { useSelector } from 'react-redux';

import { Nullable } from 'src/types';
import { Position } from 'src/interfaces';
import { isNumber } from 'src/helpers';

import { selectNodeGeojson } from 'src/cluster/common';
import { selectEditorPageForm } from 'src/cluster/editor-map';

const usePositionForFit = (): Nullable<Position> => {
  const nodeGeoJson = useSelector(selectNodeGeojson);
  const { nodeId } = useSelector(selectEditorPageForm);

  if (!isNumber(nodeId)) {
    return null;
  }

  const selectedPoint = nodeGeoJson.features.find((feature) => Number(feature.properties?.id) === nodeId);

  if (!selectedPoint?.geometry) {
    return null;
  }

  return selectedPoint.geometry.coordinates as Position;
};

export default usePositionForFit;
