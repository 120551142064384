import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import { AC_REGISTRY_TABLE_SETTINGS_MODAL, RegistryModalTableSettings } from 'src/cluster/editor-table';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.half,
  };
};

export default registerModal(
  AC_REGISTRY_TABLE_SETTINGS_MODAL,
  stateToProps,
)(RegistryModalTableSettings);
