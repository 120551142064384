import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  root: {
    margin: 0,
  },
  label: {
    lineHeight: '18px',
  },
}));

export const useComponentStyle = makeStyles(() => createStyles({
  disabled: {
    opacity: 0.5,
  },
}));
