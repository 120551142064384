import { VIDEO_TRAFFIC_SUM_ENDPOINT } from 'src/modules/video/common';

export default {
  endpoint: VIDEO_TRAFFIC_SUM_ENDPOINT,
  idField: () => 1,
  defaults: {
    passengerCount: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
