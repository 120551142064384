import React from 'react';

import PButton from 'src/components/deprecated/PButton';
import PSlider from 'src/components/deprecated/PSlider';

import {
  CALCULATIONS_PAGES_FORM_NAMES,
  PageCalculationForm,
} from 'src/modules/shipments/calculations';
import {
  Toolbar, ToolbarBlock, ToolbarControl, ToolbarControls,
} from 'src/components/toolbar';
import { useRestifyForm } from 'src/helpers';
import { useSystemsContext } from 'src/constants';

import { WithTranslate } from 'src/i18n';
import { useHandlers } from './hooks';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const ShipmentsCalcToolbar: React.FC<Props> = ({
  className, t,
}) => {
  const currentSystem = useSystemsContext();
  const [
    form,
    formActions,
  ] = useRestifyForm<PageCalculationForm>(CALCULATIONS_PAGES_FORM_NAMES[currentSystem]);

  const {
    handleLengthPercentMaxChange,
    handleUniqPercentMaxChange,
    handleStartCalculationClick,
  } = useHandlers(currentSystem, form, formActions);

  return (
    <Toolbar className={className}>
      <ToolbarBlock title={t('common.captions.calculation')}>
        <ToolbarControls>
          <ToolbarControl>
            <PSlider {...{
              name: 'lengthPercentMax',
              onChange: handleLengthPercentMaxChange,
              label: t('systems.shipments.captions.lengthPercentMax'),
              value: form.lengthPercentMax,
            }} />
          </ToolbarControl>
          <ToolbarControl>
            <PSlider {...{
              name: 'uniqPercentMax',
              onChange: handleUniqPercentMaxChange,
              label: t('systems.shipments.captions.uniqPercentMax'),
              value: form.uniqPercentMax,
            }} />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock>
        <ToolbarControls>
          <PButton {...{
            className: style.button,
            onClick: handleStartCalculationClick,
          }}>
            {t('common.captions.start')}
          </PButton>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default ShipmentsCalcToolbar;
