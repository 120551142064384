import React from 'react';
import classNames from 'classnames';
import { RoutesTable } from 'src/modules/shipments/routes';
import style from './index.module.css';
import ShipmentsRoutesToolbar from '../ShipmentsRoutesToolbar';
import ShipmentsRoutesMap from '../ShipmentsRoutesMap/ShipmentsRoutesMap';

interface Props {
  className?: string;
}

const PageRoutes: React.FC<Props> = ({
  className,
}) => (
  <div className={classNames(style.root, className)}>
    <ShipmentsRoutesToolbar className={style.toolbar} />
    <ShipmentsRoutesMap className={style.map} />
    <RoutesTable className={style.routesTable} />
  </div>
);

export default PageRoutes;
