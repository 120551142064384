import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { selectDirectionsByIds } from 'src/cluster/editor-common';

import IgnoredRouteDirectionList from './IgnoredRouteDirectionList';

const mapStateToProps = (state: AppState) => ({
  directions: selectDirectionsByIds(state),
});

export default connect(mapStateToProps)(withTranslate(IgnoredRouteDirectionList));
