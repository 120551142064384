import app from 'src/app';
import i18n from 'src/i18n/i18n';
import modals from 'src/modals';
import { requiredErrorKeyHandler } from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';
import { SelectValue } from 'src/interfaces';

import {
  AC_PROJECTS_MODEL_NAME,
  routesEntityManager,
  selectAcProjectListForm,
} from 'src/cluster/common';
import {
  AC_EDITOR_GRAPH_VERSION_MODAL_NAME,
  selectEditorGraphForm,
  editorFormActions,
  entitiesEditorFormActions,
  registryPageFormActions,
  routesRegEntityManager,
  carriersRegEntityManager,
  registryFiltersActions,
} from 'src/cluster/editor-common';
import {
  AC_REGISTRY_ROUTE_EDITING_MODAL,
  AcRegistryFiltersEnum,
  AcRegistryFilters,
  AcRegRoutesResultsModel,
  selectAcRegistryPageForm,
  selectRegistryFilters,
  isAcRegRoutesResultsModel,
} from 'src/cluster/editor-table';

export function initRegistries(): ThunkActionResult<any> {
  return (dispatch, getState) => {
    const { registryId } = selectAcRegistryPageForm(getState());

    if (!registryId) {
      dispatch(registryPageFormActions.changeField('registryId', 1));
    }

    const { year, scenarioId } = selectEditorGraphForm(getState());

    if (!year || !scenarioId) {
      dispatch(modals.actions.showModal(true, AC_EDITOR_GRAPH_VERSION_MODAL_NAME));
    }
  };
}

export function loadRegistryRoute(id: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      query: {}, // Fix loadsManager records without hash
    };

    const result = await dispatch(routesRegEntityManager.loadById(id, routeApiConfig));
    dispatch(routesRegEntityManager.refresh(id, result));

    return result;
  };
}

export function deleteRegistryRoute(id: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    try {
      const { status } = await dispatch(routesRegEntityManager.delete(id, routeApiConfig));
      if (status >= 400) {
        dispatch(app.actions.toast.error(i18n.t('modules.registries.messages.deleteRouteError', { id })));
        return Promise.reject();
      }
      dispatch(routesRegEntityManager.clearPages());
      dispatch(routesEntityManager.clearData());
      dispatch(carriersRegEntityManager.clearData());
      dispatch(editorFormActions.resetField('routeId'));
      dispatch(entitiesEditorFormActions.resetField('editableRoute'));
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export function setFilterAction(val: SelectValue, field: AcRegistryFiltersEnum): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { mainFilters } = selectRegistryFilters(state);
    const filters: Partial<AcRegistryFilters> = mainFilters ? { ...mainFilters } : {};

    if (field === AcRegistryFiltersEnum.vehicleTypeId) {
      delete filters[AcRegistryFiltersEnum.routeNumber];
    }
    if (!val) delete filters[field];
    else filters[field] = val;

    dispatch(registryPageFormActions.changeField('currentPage', 1));
    dispatch(registryFiltersActions.changeField('mainFilters', filters));
  };
}

export function saveRegistryRoute(route: AcRegRoutesResultsModel): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    try {
      const { data } = await dispatch(routesRegEntityManager.patch(route.id, route, routeApiConfig));
      if (!isAcRegRoutesResultsModel(data)) {
        dispatch(app.actions.toast.error(i18n.t('modules.registries.messages.updateRouteError', { id: route.id })));
        return Promise.reject();
      }
      dispatch(modals.actions.showModal(false, AC_REGISTRY_ROUTE_EDITING_MODAL));
      dispatch(routesEntityManager.clearData());
      dispatch(carriersRegEntityManager.clearData());
      return Promise.resolve();
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('common.validation.requiredWithFields', {
        fields: requiredErrorKeyHandler('modules.registries.columns.', err),
      }), 10000));
      return Promise.reject(err);
    }
  };
}
