import React, {
  Fragment,
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
} from 'react';

import PButton from 'src/components/deprecated/PButton';
import PSelect from 'src/components/deprecated/PSelect';

import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components/toolbar';
import PSlider from 'src/components/deprecated/PSlider';

import { usePreloaderTimer } from 'src/helpers';
import { INPUT_TYPES } from 'src/components/deprecated/PInput';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import PCheckbox from 'src/components/deprecated/PCheckbox';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';
import { hasAggloRoutesFilters } from 'src/modules/mth/routes/helpers';
import {
  AGGLOMERATION_EDGE_TYPES,
  TRANSPORT_TYPE_ITEMS,
  VIEW_MODES,
  VIEW_MODE_ITEMS,
} from '../../../common/constants';
import { Props } from './interfaces';
import { useNodes } from './hooks';

import style from './index.module.css';

const AggloRoutesToolbar: FC<Props> = (props: Props) => {
  const {
    isLoading = false,
    isSourceLoading = false,
    form,
    setShowToolbar,
    setCurrentValues,
    cancelSourceLoading,
    onViewModeChange,
    onFromChange,
    onToChange,
    onTransportTypeChange,
    onMaxBandwidthChange,
    onMaxAlternativeRoutesChange,
    onMaxPedestrianTimeChange,
    onHasLongPedestrianChange,
    onCalculateRouteClick,
    t,
  } = props;

  useEffect(() => {
    setCurrentValues();
  }, []);

  const { fromItems, toItems } = useNodes();

  const viewModesItems = useMemo(() => Object.values(VIEW_MODES).map(mode => ({
    value: mode,
    label: t(VIEW_MODE_ITEMS[mode]),
  })), [t]);

  const transportTypesItems = useMemo(() => Object.keys(TRANSPORT_TYPE_ITEMS)
    .filter((key) => key !== AGGLOMERATION_EDGE_TYPES.car
      && key !== AGGLOMERATION_EDGE_TYPES.cargo
      && key !== AGGLOMERATION_EDGE_TYPES.taxi)
    .map((key) => ({
      value: key,
      label: t(TRANSPORT_TYPE_ITEMS[key]),
    })), [t]);

  const isLoadingByTime = usePreloaderTimer(isSourceLoading);

  const isOnlySourceLoading = isLoadingByTime && !isLoading;

  const isButtonDisabled = !hasAggloRoutesFilters(form);

  const showCorrespondences = form.viewMode === VIEW_MODES.correspondences;

  const { showToolbar } = form;

  const handleToggle = (event: ChangeEvent<object>) => {
    event.stopPropagation();
    setShowToolbar(!showToolbar);
  };

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading || isLoadingByTime,
      caption: isOnlySourceLoading ? t('common.captions.cancel') : undefined,
      onClick: isOnlySourceLoading ? cancelSourceLoading : undefined,
    }}>
      <Toolbar className={style.root}>
        <Fragment>
          {showCorrespondences && (
            <div className={style.toggleWrapper}>
              <button
                type="button"
                className={style.toggle}
                onClick={handleToggle}
              >
                <div className={style.toggleIcon}>
                  <PIcon {...{
                    type: ICONS_TYPES.arrow,
                    rotate: showToolbar ? ROTATE_TYPES.down : ROTATE_TYPES.up,
                    className: style.expandIcon,
                  }} />
                </div>
              </button>
            </div>
          )}
        </Fragment>
        <ToolbarBlock title={showCorrespondences && showToolbar ? ' ' : undefined}>
          <ToolbarControls position="top">
            <ToolbarControl size="full-width">
              <PSelect {...{
                label: t('systems.mth.captions.viewMode'),
                className: style.select,
                items: viewModesItems,
                values: form.viewMode ? [form.viewMode] : undefined,
                onChange: onViewModeChange,
              }} />
            </ToolbarControl>
          </ToolbarControls>
        </ToolbarBlock>
        {showCorrespondences
          ? (
            <Fragment>
              {showToolbar
                ? (
                  <Fragment>
                    <ToolbarBlock title={t('systems.mth.captions.routesSettings')}>
                      <ToolbarControls position="top">
                        <ToolbarControl>
                          <PSelect {...{
                            label: t('common.captions.from'),
                            className: style.select,
                            items: fromItems,
                            values: form.from === undefined
                              ? undefined
                              : [form.from],
                            isClearable: true,
                            onChange: onFromChange,
                          }} />
                        </ToolbarControl>
                        <ToolbarControl>
                          <PSelect {...{
                            label: t('common.captions.to'),
                            className: style.select,
                            items: toItems,
                            values: form.to === undefined
                              ? undefined
                              : [form.to],
                            isClearable: true,
                            onChange: onToChange,
                          }} />
                        </ToolbarControl>
                        <ToolbarControl>
                          <PCheckbox {...{
                            className: style.checkbox,
                            label: t('systems.mth.captions.longPedestrianRoutes'),
                            value: Boolean(form.hasLongPedestrian),
                            onChange: onHasLongPedestrianChange,
                          }} />
                        </ToolbarControl>
                      </ToolbarControls>
                      <ToolbarControls position="bottom" className={style.bottomRow}>
                        <ToolbarControl>
                          <PSlider {...{
                            label: t('systems.mth.captions.maxBandwidth'),
                            name: 'maxBandwidth',
                            value: form.trafficLimit || 100,
                            onChange: onMaxBandwidthChange,
                          }} />
                        </ToolbarControl>
                        <ToolbarControl>
                          <PSlider {...{
                            label: t('systems.mth.captions.maxAlternativeRoutes'),
                            name: 'maxAlternativeRoutes',
                            value: form.maxAlternativeRoutes,
                            valueType: INPUT_TYPES.int,
                            min: 0,
                            max: 5,
                            onChange: onMaxAlternativeRoutesChange,
                          }} />
                        </ToolbarControl>
                        <ToolbarControl>
                          <PSlider {...{
                            label: t('systems.mth.captions.maxPedestrianRouteTime'),
                            name: 'maxPedestrianTime',
                            value: form.maxPedestrianTime,
                            valueType: INPUT_TYPES.int,
                            min: 1,
                            max: 90,
                            disabled: form.hasLongPedestrian,
                            onChange: onMaxPedestrianTimeChange,
                          }} />
                        </ToolbarControl>
                      </ToolbarControls>
                    </ToolbarBlock>
                    <ToolbarBlock className={style.buttonBlock}>
                      <ToolbarControls align="right" position="bottom" className={style.bottomRow}>
                        <ToolbarControl>
                          <PButton {...{
                            className: style.button,
                            disabled: isButtonDisabled,
                            onClick: onCalculateRouteClick,
                          }}>
                            {t('systems.mth.captions.calculateRoute')}
                          </PButton>
                        </ToolbarControl>
                      </ToolbarControls>
                    </ToolbarBlock>
                  </Fragment>
                )
                : (
                  <Fragment>
                    <ToolbarBlock>
                      <ToolbarControls position="top">
                        <ToolbarControl>
                          <PSelect {...{
                            label: t('common.captions.from'),
                            className: style.select,
                            items: fromItems,
                            values: form.from === undefined
                              ? undefined
                              : [form.from],
                            isClearable: true,
                            onChange: onFromChange,
                          }} />
                        </ToolbarControl>
                        <ToolbarControl>
                          <PSelect {...{
                            label: t('common.captions.to'),
                            className: style.select,
                            items: toItems,
                            values: form.to === undefined
                              ? undefined
                              : [form.to],
                            isClearable: true,
                            onChange: onToChange,
                          }} />
                        </ToolbarControl>
                      </ToolbarControls>
                    </ToolbarBlock>
                    <ToolbarBlock className={style.buttonBlock}>
                      <ToolbarControls align="right" position="bottom" className={style.bottomRow}>
                        <ToolbarControl>
                          <PButton {...{
                            className: style.button,
                            disabled: isButtonDisabled,
                            onClick: onCalculateRouteClick,
                          }}>
                            {t('systems.mth.captions.calculateRoute')}
                          </PButton>
                        </ToolbarControl>
                      </ToolbarControls>
                    </ToolbarBlock>
                  </Fragment>
                )}
            </Fragment>
          )
          : (
            <ToolbarBlock>
              <ToolbarControls position="top">
                <ToolbarControl>
                  <PSelect {...{
                    label: t('systems.mth.captions.transportType'),
                    className: style.select,
                    items: transportTypesItems,
                    values: form.transportType === undefined
                      ? undefined
                      : [form.transportType],
                    onChange: onTransportTypeChange,
                  }} />
                </ToolbarControl>
              </ToolbarControls>
            </ToolbarBlock>
          )}
      </Toolbar>
    </LoadingBlockContainer>
  );
};

export default AggloRoutesToolbar;
