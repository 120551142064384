import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RestifyFormActions } from 'redux-restify';
import { Systems } from 'src/constants';
import calculations, { PageCalculationForm } from 'src/modules/shipments/calculations';

interface Handlers {
  handleLengthPercentMaxChange: (values: any) => void;
  handleUniqPercentMaxChange: (values: any) => void;
  handleStartCalculationClick: () => void;
}

export const useHandlers = (
  currentSystem: Systems, form: PageCalculationForm, formActions: RestifyFormActions,
): Handlers => {
  const dispatch = useDispatch();

  const handleLengthPercentMaxChange = useCallback(
    (value) => dispatch(formActions.changeField('lengthPercentMax', value)),
    [dispatch, formActions],
  );

  const handleUniqPercentMaxChange = useCallback(
    (value) => dispatch(formActions.changeField('uniqPercentMax', value)),
    [dispatch, formActions],
  );

  const handleStartCalculationClick = useCallback(
    () => dispatch(calculations.actions[currentSystem].startCalculation()),
    [dispatch, formActions],
  );

  return {
    handleLengthPercentMaxChange,
    handleUniqPercentMaxChange,
    handleStartCalculationClick,
  };
};
