import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UNITS } from 'src/constants';
import { NumberFormat, WithUnit } from 'src/components';
import { isNil } from 'src/helpers';

import { VehicleType } from 'src/cluster/common';
import {
  AcDistRoutesDayModel,
  AcDistTableFieldsConfig,
  TopColumns,
} from 'src/cluster/distribution-table';

const useDistRouteDayConfigRight = (
  getVehicleTypeName: (vehicleId?: number) => VehicleType | undefined,
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistRoutesDayModel>[] => {
  const { i18n: { language }, t } = useTranslation();

  function getLabel(key: TopColumns) {
    const fullKey = `systems.distribution.columns.${key}`;
    return t(fullKey);
  }

  return useMemo(() => {
    return [
      {
        type: TopColumns.passengerTrafficTotal,
        title: (
          <WithUnit unit={t(UNITS.people)}>
            {getLabel(TopColumns.passengerTrafficTotal)}
          </WithUnit>
        ),
        className: classes.right,
        model: (result: AcDistRoutesDayModel) => (
          <div className={classes.taRight}>
            {isNil(result.passengerTrafficTotal) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.passengerTrafficTotal}
                locale={language}
              />
            )}
          </div>
        ),
      },
      {
        type: TopColumns.peakFillingPerVehicleAbs,
        className: classes.right,
        title: (
          <WithUnit unit={t(UNITS.people)}>
            {getLabel(TopColumns.peakFillingPerVehicleAbs)}
          </WithUnit>
        ),
        model: (results: AcDistRoutesDayModel) => (
          <div className={classes.taRight}>
            {isNil(results.peakFillingPerVehicleAbs) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={results.peakFillingPerVehicleAbs}
                locale={language}
              />
            )}
          </div>
        ),
      },
    ];
  }, [getVehicleTypeName]);
};

export default useDistRouteDayConfigRight;
