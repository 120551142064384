import React, { FC } from 'react';
import classNames from 'classnames';
import { RestifyId } from 'redux-restify';
import { Maybe } from 'monet';

import { WithTranslate } from 'src/i18n';

import { Model } from 'src/interfaces';

import { DataGridContainer } from 'src/components';
import { AcBalanceTtcModel, AcProjectForm } from 'src/cluster/common';
import { AcPageBalanceTtcForm, areRecordsValid } from 'src/cluster/ttc';
import { useCreate, useDelete, useEdit } from './hooks';

import { BalanceTtcTable } from '../../components';
import { TtcToolbar } from '../../containers';

import useStyles from './styles';

interface OwnProps {
  className?: string;
  count: number;
  isLoading: boolean;
  ttcEntities: Maybe<Model<AcBalanceTtcModel>[]>;
  projectForm: AcProjectForm;
  pageForm: AcPageBalanceTtcForm;
  exportTtcData(id: RestifyId): Promise<any>;
  updateMany(flows: AcBalanceTtcModel[]): Promise<any>;
  deleteMany(ids: RestifyId[]): Promise<any>;
  createMany(flows: AcBalanceTtcModel[]): Promise<any>;
  switchToList(): void;
  warningNotify(): void;
  successNotify(): void;
  onPageChange(value: number): void;
  onPageSizeChange(value: number): void;
  showEditModal(record: AcBalanceTtcModel, cb: Function): void;
}

type Props = OwnProps & WithTranslate;

const BalanceTtcPage: FC<Props> = (props) => {
  const {
    className,
    ttcEntities,
    pageForm,
    count,
    isLoading,
    projectForm: { selectedProject },
    exportTtcData,
    updateMany,
    deleteMany,
    createMany,
    switchToList,
    warningNotify,
    successNotify,
    onPageChange,
    onPageSizeChange,
    showEditModal,
  } = props;

  const classes = useStyles();

  const {
    pageSize,
    currentPage,
    isEdit,
  } = pageForm;

  const [deleted, /* deleteRecord */, /* undeleteRecord */, resetDeleted] = useDelete();
  const [edited, editRecord, resetEdited] = useEdit();
  const [created, createRecord, /* uncreateRecord */, resetCreated] = useCreate();

  const isSavable = deleted.length + edited.length + created.length > 0
    && areRecordsValid(created)
    && areRecordsValid(edited);

  const handleSaveAll = () => {
    if (isSavable) {
      Promise.all([
        deleteMany(deleted).then(resetDeleted),
        updateMany(edited).then(resetEdited),
        createMany(created).then(resetCreated),
      ]).then(switchToList)
        .then(successNotify)
        .catch(warningNotify);
    }
  };

  const handleResetAll = () => {
    resetDeleted();
    resetEdited();
    resetCreated();
  };

  const handleExport = () => {
    if (selectedProject !== undefined) {
      exportTtcData(selectedProject).catch((err) => console.error(err));
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      <TtcToolbar
        pageForm={pageForm}
        isSavable={isSavable}
        saveAll={handleSaveAll}
        resetAll={handleResetAll}
        exportFlows={handleExport}
      />
      <DataGridContainer
        isLoading={isLoading}
        data={ttcEntities}
        className={className}
        pageSize={pageSize}
        currentPage={currentPage}
        count={count}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {(data: AcBalanceTtcModel[]) => (
          <BalanceTtcTable
            edited={edited}
            isEdit={isEdit}
            created={created}
            editFlow={editRecord}
            createFlow={createRecord}
            pageForm={pageForm}
            data={data}
            showEditModal={showEditModal}
          />
        )}
      </DataGridContainer>

    </div>
  );
};

export default BalanceTtcPage;
