/* eslint-disable max-len */
import React from 'react';

const ArrowLeft = () => (
  <svg width="7" height="14" viewBox="0 0 7 14" fill="none">
    <path d="M7 0L0 7L7 14V0Z" fill="currentColor" />
  </svg>
);

export default ArrowLeft;
