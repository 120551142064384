import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAcVehicleClasses, VEHICLE_CLASSES_DICT } from 'src/cluster/common';
import { IndicatorPartition } from 'src/cluster/dashboard-table';

const useVehicleTypesForPartition = (): IndicatorPartition[] => {
  const vehicleTypes = useAcVehicleClasses();

  const [t, { language }] = useTranslation();
  return useMemo(() => {
    return vehicleTypes
      .map((type) => ({
        id: type.id,
        name: t(VEHICLE_CLASSES_DICT[type.name]),
        entityName: 'vehicle_class',
      }));
  }, [vehicleTypes, language]);
};

export default useVehicleTypesForPartition;
