import { createSelector } from 'reselect';

import {
  api,
  forms,
} from 'redux-restify';

import { AppState } from 'src/reducer';

import { Writable } from 'src/types';
import { PageProjectForm } from 'src/modules/general/projects';
import { PROJECTS_PAGE_FORM_NAME, PROJECTS_MODEL_NAME } from 'src/modules/traffic/projects';
import {
  MACRO_CHOICE_MODEL_NAME,
  ROUTES_MODEL_NAME,
  MAP_PAGES_FORM_NAME,
  NODES_MODEL_NAME,
  PageRoutesForm,
  NodeModel,
  RouteModel,
  MacroChoicesModel,
} from 'src/modules/traffic/map';
import { FULL_GEOJSON_ENDPOINT } from 'src/api-url-schema';

export const selectGeojsonEndpoint = createSelector(
  [
    (state) => api.selectors.entityManager.trafficProjects.getEndpoint(state),
    (state) => forms.selectors[PROJECTS_PAGE_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
  ],
  ({ apiHost, apiPrefix, endpoint }, pageProjectsForm) => {
    if (!pageProjectsForm.selectedProject) {
      return undefined;
    }
    const projectEndpoint = `${apiHost}${apiPrefix}${endpoint}`;
    return `${projectEndpoint}${pageProjectsForm.selectedProject}/${FULL_GEOJSON_ENDPOINT}`;
  },
);

const hasRoutesFilters = (pageRoutesForm: PageRoutesForm): pageRoutesForm is Required<PageRoutesForm> => {
  return (
    pageRoutesForm.macroeconomicScenarioActual !== undefined
    && pageRoutesForm.transportScenarioActual !== undefined
    && pageRoutesForm.yearActual !== undefined
  );
};

const getRoutesApiConfig = (
  pageProjectForm: PageProjectForm,
  pageRoutesForm: PageRoutesForm,
  projectsModelName: string,
) => {
  if (
    !pageProjectForm.selectedProject ||
    !hasRoutesFilters(pageRoutesForm)
  ) {
    return undefined;
  }
  return {
    parentEntities: {
      [projectsModelName]: pageProjectForm.selectedProject,
    },
    filter: {
      macroeconomicScenario: pageRoutesForm.macroeconomicScenarioActual,
      transportScenario: pageRoutesForm.transportScenarioActual,
      year: pageRoutesForm.yearActual,
    },
  };
};

export const selectCurrentProjectNodes = createSelector(
  [
    (state) => forms.selectors[PROJECTS_PAGE_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
    (state) => api.selectors.entityManager[NODES_MODEL_NAME].getEntities<NodeModel>(state),
  ],
  (pageProjectForm, nodesEntities) => {
    if (!pageProjectForm.selectedProject) {
      return [];
    }
    const nodesApiConfig = {
      parentEntities: {
        [PROJECTS_MODEL_NAME]: pageProjectForm.selectedProject,
      },
    };
    return nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A');
  },
);

export const selectProjectChoices = createSelector(
  [
    (state: AppState) => forms.selectors[PROJECTS_PAGE_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => forms.selectors[MAP_PAGES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[MACRO_CHOICE_MODEL_NAME].getEntities<MacroChoicesModel>(state),
  ],
  (pageProjectForm, pageRoutesForm, choices): MacroChoicesModel => {
    const emptyChoices = {
      macroeconomicScenarios: [],
      transportScenarios: [],
      years: [],
    };
    if (!pageProjectForm.selectedProject) {
      return emptyChoices;
    }
    const apiConfig = {
      parentEntities: {
        [PROJECTS_MODEL_NAME]: pageProjectForm.selectedProject,
      },
      query: {
        macroScenario: pageRoutesForm.macroeconomicScenario,
        transportScenario: pageRoutesForm.transportScenario,
      },
    };
    const macroChoices = choices.getById('', apiConfig);
    if (choices.getIsLoadingById('', apiConfig)) {
      return emptyChoices;
    }
    return macroChoices;
  },
);

const getRoutesSelectorsConst = [
  (state: AppState) => forms.selectors[PROJECTS_PAGE_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
  (state: AppState) => forms.selectors[MAP_PAGES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state),
  (state: AppState) => api.selectors.entityManager[ROUTES_MODEL_NAME].getEntities<RouteModel>(state),
] as const;
const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

export const getCurrentProjectRoutes = createSelector(
  getProjectRoutesSelectors,
  (pageProjectForm, pageRoutesForm, routesEntities) => {
    const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm, PROJECTS_MODEL_NAME);
    if (!routesApiConfig) {
      return {
        currentRoutes: [],
        currentRoutesIsLoading: false,
      };
    }
    return {
      currentRoutes: routesEntities.getArray(routesApiConfig),
      currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
    };
  },
);

export const asyncSelectCurrentProjectRoutes = createSelector(
  getProjectRoutesSelectors,
  async (pageProjectForm, pageRoutesForm, routesEntities) => {
    const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm, PROJECTS_MODEL_NAME);
    if (!routesApiConfig) {
      return {
        currentRoutes: [],
        currentRoutesIsLoading: false,
      };
    }
    return {
      currentRoutes: await routesEntities.asyncGetArray(routesApiConfig),
      currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
    };
  },
);
