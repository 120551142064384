import React, { ChangeEvent, FC } from 'react';

import {
  Paper, Table, TableBody, TableContainer,
} from '@material-ui/core';

import { FileRecord, FileType, FILE_TYPES_ITEMS } from 'src/cluster/projects';

import ProjectFileListItem from './components/ProjectFileListItem';

import useStyles from './styles';

type Props = {
  files: FileRecord[];
  isUploading: boolean;
  createFileChangeHandler: (type?: FileType) => (fileList: FileList | null) => void;
  setType: (name: string, value: FileType) => void;
  deleteFile: (name: string) => void;
}

const ProjectFileList: FC<Props> = (props) => {
  const {
    files,
    isUploading,
    createFileChangeHandler,
    setType,
    deleteFile,
  } = props;

  const classes = useStyles();

  const createDeleteHandler = (name?: string) => {
    return (): void => {
      if (name) {
        deleteFile(name);
      }
    };
  };

  const createTypeChangeHandler = (name?: string) => {
    return (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
      if (name) {
        setType(name, event.target.value as FileType);
      }
    };
  };

  const actualFileTypesItems = FILE_TYPES_ITEMS.filter((filesTypesItem) => {
    if (filesTypesItem.value === FileType.graph) return true;
    const currentFile = files.find((file) => filesTypesItem.value === file.type);
    return !currentFile?.file;
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table>
          <TableBody>
            {files.map((item, index) => {
              const handleTypeChange = createTypeChangeHandler(item.name);
              const handleFileChange = createFileChangeHandler(item.type);
              const handleDeleteClick = createDeleteHandler(item.name);
              return (
                <ProjectFileListItem
                  key={String(item.name || item.type)}
                  index={index}
                  type={item.type}
                  fileTypesItems={actualFileTypesItems}
                  name={item.name}
                  isUploading={isUploading}
                  attached={!!item.file}
                  changeFile={handleFileChange}
                  changeType={handleTypeChange}
                  deleteFile={handleDeleteClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProjectFileList;
