import React from 'react';
import classNames from 'classnames';
import ReactMapGL, {
  InteractiveMap,
  NavigationControl,
  Popup,
} from 'react-map-gl';
import { useSelector, useDispatch } from 'react-redux';

import CitiesMapLegend from 'src/modules/cities/calculations/components/CitiesCalcRoutesMap/CitiesMapLegend';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import {
  useMapFittedToBounds,
  makeMapStyleSources,
  makeMapStyleEdgeLayer,
  makeMapStylePointLayer,
  getExtendedMapStyle,
} from 'src/helpers';

import { useCurrentVocabulary, VocabularyEntity } from 'src/i18n';

import { useRestifyForm } from 'src/helpers/deprecated/restify';

import {
  usePopup,
  useSystemsContext,
  toFilterValue,
} from 'src/constants';
import EdgePopup from '../EdgePopup';

import selectors from '../../selectors';
import {
  SELECTED_ROUTE_COLOR,
  ROUTES_PAGES_FORM_NAMES,
  PageRoutesForm,
  EdgeModel,
  INACTIVE_ROUTE_COLOR,
  EDGE_TRAFFIC_COLOR,
} from '../../constants';

import style from './index.module.css';

interface Props {
  className?: string;
}

const RoutesMap: React.FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [locale] = useCurrentVocabulary();
  const [viewport, setViewport] = React.useState({
    latitude: 59.8,
    longitude: 30,
    zoom: 7,
  });
  const [nodePopup] = usePopup<string>();
  const [edgePopup, setEdgePopup] = usePopup<EdgeModel>();
  const [markerPopup] = usePopup<EdgeModel>();
  const mapRef = React.useRef<InteractiveMap | null>(null);
  const currentNodes = useSelector(selectors[currentSystem].getCurrentProjectNodes);
  const {
    currentRoutes,
    currentRoutesIsLoading,
  } = useSelector(selectors[currentSystem].getCurrentProjectRoutes);
  const [routesPageForm, routesPageFormActions]
    = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);

  useMapFittedToBounds(currentRoutes, currentRoutesIsLoading, viewport, setViewport);

  const mapStyle = React.useMemo(() => {
    const sources = makeMapStyleSources(currentNodes, currentRoutes, { cluster: false }, {});

    const isSelectedRoutePointFilter = [
      'any',
      ['==', ['get', 'name'], toFilterValue(routesPageForm.from)],
      ['==', ['get', 'name'], toFilterValue(routesPageForm.to)],
    ];
    const isSelectedRouteFilter = [
      '==',
      ['get', 'routeIndex'],
      toFilterValue(routesPageForm.selectedRoute),
    ];
    const layers: any[] = [
      makeMapStyleEdgeLayer('routes-hover', ['all'],
        { 'line-color': 'transparent', 'line-width': 3 }),
      makeMapStyleEdgeLayer('routes-inactive', ['!', isSelectedRouteFilter],
        { 'line-color': INACTIVE_ROUTE_COLOR, 'line-width': 3 }),
      makeMapStyleEdgeLayer('selected-routes', isSelectedRouteFilter,
        { 'line-color': SELECTED_ROUTE_COLOR, 'line-width': 1 }),
      makeMapStylePointLayer(
        'points-unclustered',
        routesPageForm.selectedRoute !== undefined,
        isSelectedRoutePointFilter,
        EDGE_TRAFFIC_COLOR[1],
      ),
    ];
    return getExtendedMapStyle({
      sources,
      layers,
    }, locale);
  }, [currentRoutes, routesPageForm.selectedRoute]);
  return (
    <LoadingBlockContainer {...{
      className: classNames(style.root, className),
      isBlocked: currentRoutesIsLoading,
    }}>
      <CitiesMapLegend />

      <ReactMapGL {...{
        height: '100%',
        width: '100%',
        // This is needed for cursor change
        interactiveLayerIds: [
          'routes-hover',
          'selected-routes',
        ],
        onMouseMove: (e) => {
          if (!mapRef.current) return;
          const features = mapRef.current.queryRenderedFeatures(e.point);
          if (!features) return;

          const edgeFeature = features.find(f => f.properties && f.properties.isEdge);
          if (edgeFeature && edgeFeature.properties) {
            const { geometry }: any = edgeFeature;
            const latCenter = (geometry.coordinates[0][1] + geometry.coordinates[1][1]) / 2;
            const lngCenter = (geometry.coordinates[0][0] + geometry.coordinates[1][0]) / 2;
            setEdgePopup({
              content: (edgeFeature.properties as any),
              lat: latCenter,
              lng: lngCenter,
            });
          } else {
            setEdgePopup({
              content: undefined,
              lat: 0,
              lng: 0,
            });
          }
        },
        onClick: (e) => {
          if (!mapRef.current) return;
          const features = mapRef.current.queryRenderedFeatures(e.point);
          const edgeFeature = features.find(f => f.properties && f.properties.isEdge);
          if (edgeFeature && edgeFeature.properties) {
            dispatch(routesPageFormActions.changeField('selectedRoute', edgeFeature.properties.routeIndex));
          }
        },
        ref: mapRef,
        ...viewport,
        mapStyle,
        onViewportChange: (newViewport) => setViewport(newViewport),
      }}>
        {
          nodePopup.content &&
          <Popup {...{
            latitude: nodePopup.lat,
            longitude: nodePopup.lng,
            closeButton: false,
            closeOnClick: false,
            anchor: 'top',
          }}>
            <div>
              <VocabularyEntity value={nodePopup.content} />
            </div>
          </Popup>
        }
        {
          (markerPopup.content || edgePopup.content) &&
          <Popup {...{
            className: style.popup,
            latitude: markerPopup.content ? markerPopup.lat : edgePopup.lat,
            longitude: markerPopup.content ? markerPopup.lng : edgePopup.lng,
            closeButton: false,
            closeOnClick: false,
            anchor: 'right',
          }}>
            <EdgePopup {...{
              model: markerPopup.content || edgePopup.content,
            }} />
          </Popup>
        }
        <div className={style.zoomControl}>
          <NavigationControl showCompass={false} />
        </div>
      </ReactMapGL>
    </LoadingBlockContainer>
  );
};

export default RoutesMap;
