import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';
import { selectEditorScenarios } from 'src/cluster/editor-map';
import { AcScenariosModel } from 'src/cluster/common';

const useEditorScenarios = (): Model<AcScenariosModel>[] => {
  const scenarios = useSelector(selectEditorScenarios);
  return scenarios.orJust([]);
};

export default useEditorScenarios;
