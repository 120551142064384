import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import max from 'lodash/max';
import min from 'lodash/min';
import map from 'lodash/map';
import range from 'lodash/range';
import {
  CartesianGrid,
  ComposedChart,
  Line, LineProps,
  ResponsiveContainer,
  Tooltip,
  TooltipPayload,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import NumberFormat from 'src/components/etc/NumberFormat';
import { WithTranslate, TFunction } from 'src/i18n';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import style from './index.module.css';

import { MacroeconomicsModel, MacroeconomicsRecord } from '../../constants';

const messages = {
  macroeconomics: 'systems.matrix.captions.macroeconomics',
  mobilityIncrease: 'systems.matrix.captions.mobilityIncrease',
  nonInflationaryIncomeGrowth: 'systems.matrix.captions.nonInflationaryIncomeGrowth',
};

type DataKey = 'macroeconomics' | 'mobilityIncrease' | 'nonInflationaryIncomeGrowth'

interface ExtTooltipPayload extends TooltipPayload {
  dataKey: DataKey;
  value: number;
}

interface Props extends WithTranslate {
  className?: string;
  buttons?: any;
  data: MacroeconomicsRecord[];
  isLoading: boolean;
  showMacroeconomics: boolean;
  showMobilityIncrease: boolean;
  showIncomeGrowth: boolean;
}

const getYTicks = (data: MacroeconomicsModel[]) => {
  const values = data.flatMap(n => ([n.macroeconomics, n.mobilityIncrease, n.nonInflationaryIncomeGrowth]));
  const minValue = Math.floor(min(values) || 100);
  const maxValue = Math.ceil(max(values) || 100);
  return range(minValue, maxValue, 1);
};

const renderLineLabel = (locale: string, t: TFunction) => (payload: ExtTooltipPayload) => {
  const { dataKey, color, value } = payload;
  const title = t(messages[dataKey]);
  return (
    <p key={dataKey} style={{ color }}>
      <span>{title}</span>
      {': '}
      <NumberFormat
        value={value}
        locale={locale}
        decimalScale={2}
        suffix="%"
        zeroPlaceholder="-"
      />
    </p>
  );
};

const renderTooltip = (locale: string, t: TFunction) => (props: TooltipProps) => {
  const { label, payload } = props;
  return (
    <div className={style.tooltip}>
      <p className={style.tooltipLabel}>{label}</p>
      {map(payload, renderLineLabel(locale, t))}
    </div>
  );
};

const MacroChart: FC<Props> = (props) => {
  const {
    className,
    data,
    isLoading,
    showMacroeconomics,
    showMobilityIncrease,
    showIncomeGrowth,
    locale,
    t,
  } = props;

  const lines: {[key: string]: LineProps} = useMemo(() => ({
    macroeconomics: {
      legendType: 'rect',
      name: 'Macroeconomics',
      dataKey: 'macroeconomics',
      stroke: '#0066fe',
      strokeWidth: 3,
      dot: {
        r: 4, stroke: '#0066fe', fill: '#ffffff', strokeWidth: 3,
      },
      activeDot: {
        r: 6, stroke: '#0066fe', fill: '#ffffff', strokeWidth: 4,
      },
      isAnimationActive: false,
    },
    mobilityIncrease: {
      legendType: 'rect',
      name: 'Transport mobility increase',
      dataKey: 'mobilityIncrease',
      stroke: '#069b1e',
      strokeWidth: 3,
      dot: {
        r: 4, stroke: '#069b1e', fill: '#ffffff', strokeWidth: 3,
      },
      activeDot: {
        r: 6, stroke: '#069b1e', fill: '#ffffff', strokeWidth: 4,
      },
      isAnimationActive: false,
    },
    nonInflationaryIncomeGrowth: {
      legendType: 'rect',
      name: 'Non-inflationary income growth',
      dataKey: 'nonInflationaryIncomeGrowth',
      stroke: '#c46b00',
      strokeWidth: 3,
      dot: {
        r: 4, stroke: '#c46b00', fill: '#ffffff', strokeWidth: 3,
      },
      activeDot: {
        r: 6, stroke: '#c46b00', fill: '#ffffff', strokeWidth: 4,
      },
      isAnimationActive: false,
    },
  }), [locale]);

  const tooltipContent = useMemo(() => renderTooltip(locale, t), [locale, t]);

  const yTicks = useMemo(() => getYTicks(data), [data]);

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading,
      className: classNames(style.root, className),
    }}>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={data}>
          <XAxis
            domain={['dataMin', 'dataMax']}
            padding={{ left: 20, right: 20 }}
            dataKey="year"
            angle={30}
            height={48}
            tickMargin={20}
            tickSize={0}
          />
          <YAxis
            allowDecimals={false}
            type="number"
            domain={['dataMin', 'dataMax']}
            padding={{ top: 20, bottom: 20 }}
            tickSize={0}
            tickMargin={10}
            ticks={yTicks}
          />
          <CartesianGrid />
          <Tooltip content={tooltipContent} />
          {showMacroeconomics && <Line {...lines.macroeconomics} />}
          {showMobilityIncrease && <Line {...lines.mobilityIncrease} />}
          {showIncomeGrowth && <Line {...lines.nonInflationaryIncomeGrowth} />}
        </ComposedChart>
      </ResponsiveContainer>
    </LoadingBlockContainer>
  );
};

export default MacroChart;
