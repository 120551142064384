import React, { FC } from 'react';
import {
  FormHelperText, FormHelperTextProps, withStyles, createStyles,
} from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

interface OwnProps {
  errorText?: string;
}

type Props = OwnProps & FormHelperTextProps;

const ErrorText: FC<Props> = (props) => {
  const {
    id,
    errorText,
    ...rest
  } = props;

  return (
    <FormHelperText id={id} error {...rest}>
      {errorText}
    </FormHelperText>
  );
};

const styles = createStyles({
  root: {
    position: 'relative',
    fontFamily: ffSourceSansPro,
    fontStyle: 'italic',
    fontSize: 10,
    lineHeight: '13px',
    marginTop: 0,
  },
});

export default withStyles(styles)(ErrorText);
