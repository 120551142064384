import { useReducer } from 'react';

import { AcBalanceDataFactorsValue } from 'src/cluster/common';

const EDIT = 'EDIT';

const factorsReducer = (
  state: AcBalanceDataFactorsValue[],
  { type, payload }: { type: string; payload?: AcBalanceDataFactorsValue },
) => {
  switch (type) {
    case EDIT: {
      if (!payload) {
        throw new Error(`Unexpected payload: ${payload}`);
      }
      return state.map((model) => {
        if (model.id !== payload.id) return model;
        return {
          ...model,
          value: payload.value,
        };
      });
    }
    default: {
      throw new Error(`Unexpected action: ${type}`);
    }
  }
};

function editAction(payload: AcBalanceDataFactorsValue) {
  return {
    type: EDIT,
    payload,
  };
}

export default function useFactorsEdit(initialState: AcBalanceDataFactorsValue[]): [
  AcBalanceDataFactorsValue[],
  (factor: any) => void,
] {
  const [state, dispatch] = useReducer(factorsReducer, initialState);

  const edit = (factor: AcBalanceDataFactorsValue) => {
    dispatch(editAction(factor));
  };

  return [state, edit];
}
