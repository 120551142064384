import React from 'react';

const ArrowRightAndLeftIcon = () => (

  <svg width="25" height="22" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.000300407 4.12556L-0.000435829 2.71287C-0.000300407 2.63469 0.0627651 2.57162 0.140952 2.57148L5.86664 2.57155L5.17035 0.808496C5.14711 0.749423 5.16595 0.681959 5.21677 0.643799C5.26745 0.605505 5.33751 0.605637 5.38799 0.64466L8.85104 3.30706C8.88581 3.3337 8.90625 3.37519 8.90625 3.41901C8.90625 3.46297 8.88581 3.50433 8.85104 3.5311L5.38793 6.19356C5.36256 6.21308 5.33219 6.22293 5.30175 6.22287C5.27178 6.22287 5.24195 6.21341 5.21664 6.19436C5.16596 6.1562 5.14704 6.08867 5.17035 6.02967L5.86677 4.26674L0.140955 4.26681C0.0628338 4.26674 -0.000300407 4.20361 -0.000300407 4.12556Z"
      fill="currentColor"
      stroke="transparent"
      strokeWidth="0.5" />
    <path d="M14.1542 9.51813L14.1543 8.10545C14.1542 8.02726 14.0911 7.9642 14.0129 7.96406L8.28722 7.96413L8.98351 6.20107C9.00675 6.142 8.98791 6.07454 8.93709 6.03638C8.88641 5.99808 8.81635 5.99822 8.76587 6.03724L5.30282 8.69964C5.26806 8.72628 5.24761 8.76777 5.24761 8.81159C5.24761 8.85555 5.26806 8.89691 5.30282 8.92368L8.76593 11.5861C8.7913 11.6057 8.82167 11.6155 8.85211 11.6154C8.88208 11.6154 8.91191 11.606 8.93722 11.5869C8.9879 11.5488 9.00682 11.4813 8.98351 11.4222L8.28709 9.65932L14.0129 9.65939C14.091 9.65932 14.1542 9.59619 14.1542 9.51813Z"
      fill="currentColor"
      stroke="transparent"
      strokeWidth="0.5" />
  </svg>
);

export default ArrowRightAndLeftIcon;
