import React, { FC } from 'react';

import { Nullable, ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import { DataProcessingRecords } from 'src/components';
import { MatrixForecastModel, useMatrixForecastStatusUpdate } from 'src/cluster/common';
import { isNumber } from 'src/helpers';

interface Props extends WithTranslate {
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  loadMatrixForecast(): void;
}

const MatrixForecastProgress: FC<Props> = (props) => {
  const {
    matrixForecastEntities: [matrixForecast, isLoading],
    t,
    loadMatrixForecast,
  } = props;

  useMatrixForecastStatusUpdate(loadMatrixForecast, isLoading, matrixForecast);

  const { progress, status } = matrixForecast || {};
  const percent = isNumber(progress) ? Math.round(progress * 10000) / 100 : undefined;
  let message;
  let showPercent = true;
  if (status === ProcessStatus.QUEUED) {
    showPercent = false;
    message = t('systems.balance.messages.calculationStart');
  }

  return (
    <DataProcessingRecords percent={percent} message={message} showPercent={showPercent} />
  );
};

export default MatrixForecastProgress;
