import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';
import {
  AC_DISTRIBUTION_MODAL_DATA_CALC,
  ModalProcessDataCalc,
} from 'src/cluster/distribution-table';
import { Dispatch } from 'redux';

const stateToProps = (state: AppState, props: any) => ({
  ...props,
  fitContent: true,
  size: MODAL_SIZES.small,
});

const dispatchToProps = (dispatch: Dispatch<any>) => ({
  afterClose: () => ({}),
});

export default registerModal(
  AC_DISTRIBUTION_MODAL_DATA_CALC,
  stateToProps,
  dispatchToProps,
)(ModalProcessDataCalc);
