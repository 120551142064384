import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => ({
  root: {
    position: 'relative',
  },
  filter: {
    width: 215,
    marginBottom: spacing(3),

    '& label': {
      fontSize: 12,
    },
  },
}));
