import React from 'react';

const CheckMark = () => (
  <svg viewBox="0 0 19 15" fill="none">
    <path
      d="M16.9735 0.149725L6.04288 11.3559L2.02658 7.23834C1.83186 7.03871 1.51613 7.03871 1.32136 7.23834L0.146042 8.44329C-0.0486808 8.64292 -0.0486808 8.96661 0.146042 9.16629L5.69025 14.8503C5.88497 15.0499 6.2007 15.0499 6.39546 14.8503L18.854 2.07767C19.0487 1.87804 19.0487 1.55435 18.854 1.35468L17.6786 0.149725C17.4839 -0.049908 17.1682 -0.049908 16.9735 0.149725Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckMark;
