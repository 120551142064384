import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getRangeByMinMax } from 'src/helpers';

import { selectStopsExtreme, TRAFFIC_RADIUS } from 'src/cluster/distribution-map';

const useRadiusRange = () => {
  const [min, max] = useSelector(selectStopsExtreme);

  const isEqual = min === max;

  return useMemo(() => getRangeByMinMax(isEqual ? 0 : min, max, 5).map((value, i, arr) => ({
    from: value,
    to: arr[i + 1] || Infinity,
    value: TRAFFIC_RADIUS[i + 1],
  })), [min, max]);
};

export default useRadiusRange;
