import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslate } from 'src/i18n';
import { clearProjectData } from 'src/cluster/common';
import { onDistributionMapProjectChange } from 'src/cluster/distribution-map';

import DistributionRoot from './DistributionRoot';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  clearCache: () => {
    dispatch(clearProjectData());
    dispatch(onDistributionMapProjectChange());
  },
});

export default connect(null, mapDispatchToProps)(withRouter<any, any>(withTranslate(DistributionRoot)));
