import { RouteModel } from 'src/modules/general/common';
import { PageAgglomerationForm, RouteAggregation, RouteWithAggregation } from 'src/modules/mth/common/interfaces';

export function getAggregatedRoutes(
  currentRoutes: RouteModel[],
  pageRoutesForm: PageAgglomerationForm,
): RouteWithAggregation[] {
  return currentRoutes.map((route: RouteModel) => {
    return {
      id: route.id,
      from: pageRoutesForm.from ? pageRoutesForm.from : '',
      to: pageRoutesForm.to ? pageRoutesForm.to : '',
      weight: route.weight,
      trafficPass: route.trafficPass,
      trafficSummary: route.trafficSummary,
      aggregation: route.traffic.reduce<RouteAggregation>((memo, item) => {
        const isNewSegment = !memo.prevItem || memo.prevItem.transportType !== item.transportType;
        memo.distanceTypes[item.transportType] = (memo.distanceTypes[item.transportType] || 0) + item.distance;
        memo.timeTypes[item.transportType] = (memo.timeTypes[item.transportType] || 0) + item.time;

        if (!memo.segmentTypes.length) {
          memo.segmentTypes.push({
            transportType: item.transportType,
            distance: 0,
            time: 0,
          });
        }
        let lastSegmentType = memo.segmentTypes[memo.segmentTypes.length - 1];
        if (lastSegmentType.transportType !== item.transportType) {
          memo.segmentTypes.push({
            transportType: item.transportType,
            distance: 0,
            time: 0,
          });
          lastSegmentType = memo.segmentTypes[memo.segmentTypes.length - 1];
        }
        lastSegmentType.distance += item.distance;
        lastSegmentType.time += item.time;
        const newSegment = isNewSegment ? { ...item } : memo.segments[memo.segments.length - 1];
        if (!isNewSegment) {
          newSegment.distance += item.distance;
          newSegment.time += item.time;
          newSegment.timeMoney += item.timeMoney;
          newSegment.weight += item.edgeId === -1 ? item.weight : 0;
          newSegment.ttc += item.weight;
        } else {
          newSegment.weight = item.edgeId === -1 ? item.weight : 0;
          newSegment.ttc = item.weight;
        }

        const oldSegments = isNewSegment ? memo.segments : memo.segments.slice(0, -1);
        const newSegments = [...oldSegments, newSegment];
        return {
          summary: {
            distance: memo.summary.distance + item.distance,
            time: memo.summary.time + item.time,
            timeMoney: memo.summary.timeMoney + item.timeMoney,
            traffic: item.traffic > 0 ? item.traffic : memo.summary.traffic,
            trafficPass: item.trafficPass > 0 ? item.trafficPass : memo.summary.trafficPass,
          },
          segments: newSegments,
          distanceTypes: memo.distanceTypes,
          timeTypes: memo.timeTypes,
          segmentTypes: memo.segmentTypes,
          prevItem: item,
        };
      }, {
        summary: {
          distance: 0,
          time: 0,
          timeMoney: 0,
          traffic: 0,
          trafficPass: 0,
        },
        segments: [],
        distanceTypes: {},
        timeTypes: {},
        segmentTypes: [],
      }),
    };
  });
}
