import React, { FC, Fragment } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { WithTranslate } from 'src/i18n';

import { NumberFormat } from 'src/components';
import { useStylesGrid } from './styles';

interface OwnProps {
  length: number;
  id: number;
}

type Props = OwnProps & WithTranslate;

const RouteDirectionFooter: FC<Props> = (props) => {
  const {
    length,
    id,
    t,
  } = props;

  const { title, ...gridClasses } = useStylesGrid();

  return (
    <Fragment>
      <Divider />
      <Grid container classes={gridClasses}>
        <Grid item xs={6} classes={gridClasses}>
          <div className={title}>
            {t('modules.editor.captions.lengthOfRoute')}
          </div>
        </Grid>
        <Grid item xs={6} classes={gridClasses}>
          <div className={title}>
            <NumberFormat value={length} decimalScale={1} suffix={` ${t('common.units.length')}`} />
          </div>
        </Grid>
        <Grid item xs={6} classes={gridClasses}>ID:</Grid>
        <Grid item xs={6} classes={gridClasses}>{id}</Grid>
      </Grid>
    </Fragment>
  );
};

export default RouteDirectionFooter;
