import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAcProjectListForm } from 'src/cluster/common';

const useCallOnChange = (callback: Function) => {
  const { selectedProject } = useSelector(selectAcProjectListForm);
  const [id, setId] = useState(selectedProject);

  useEffect(() => {
    if (selectedProject !== id) {
      callback();
      setId(selectedProject);
    }
  }, [selectedProject]);
};

export default useCallOnChange;
