import { SHIPMENTS_PROJECTS_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: SHIPMENTS_PROJECTS_ENDPOINT,
  defaults: {
    id: undefined,
    created: undefined,
    lastUsedDate: undefined,
    name: undefined,
    user: undefined,
    loadStatus: undefined,
    loadProgress: undefined,
    loadErrorMessage: undefined,
  },
  allowIdRequests: false,
  pageSize: 1000,
};
