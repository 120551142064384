import React, { ReactElement } from 'react';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  className?: string;
  children: ReactElement | string | (ReactElement | string)[];
  wrap?: boolean;
}

const Toolbar: React.FC<Props> = ({
  className,
  children,
  wrap = false,
}) => {
  return (
    <div className={classNames(style.root, wrap && style.wrap, className)}>
      {children}
    </div>
  );
};

export default Toolbar;
