import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';

import { runFindRoutesSaga, setCurrentFiltersFromActual } from 'src/modules/mth/routes/actions';
import { DropDownValue } from 'src/components/deprecated/PSelect';
import { OwnProps } from './interfaces';
import AggloRouteToolbar from './AggloRouteToolbar';

const mapDispatchToProps = (dispatch: Dispatch<any>, { form, formActions }: OwnProps) => ({
  setShowToolbar: (value: boolean) => dispatch(formActions.changeField('showToolbar', value)),
  setCurrentValues: () => dispatch(setCurrentFiltersFromActual(form)),
  onViewModeChange: ([value]: DropDownValue[]) => {
    dispatch(formActions.resetField('selectedEdges'));
    dispatch(formActions.changeField('viewMode', value));
  },
  onFromChange: (values: DropDownValue[]) => dispatch(formActions.changeField('from', values[0])),
  onToChange: (values: DropDownValue[]) => dispatch(formActions.changeField('to', values[0])),
  onMaxBandwidthChange: (value: number) => dispatch(formActions.changeField('trafficLimit', value)),
  onMaxPedestrianTimeChange: (value: number) => dispatch(formActions.changeField('maxPedestrianTime', value)),
  onHasLongPedestrianChange: (value: boolean) => dispatch(formActions.changeField('hasLongPedestrian', value)),
  onTransportTypeChange: (values: DropDownValue[]) => {
    dispatch(formActions.resetField('selectedEdges'));
    dispatch(formActions.changeField('transportType', values[0]));
  },
  onMaxAlternativeRoutesChange: (value: number) => dispatch(formActions.changeField('maxAlternativeRoutes', value)),
  onCalculateRouteClick: async () => dispatch(runFindRoutesSaga()),
});

export default connect(null, mapDispatchToProps)(withTranslate(AggloRouteToolbar));
