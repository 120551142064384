import React, { useMemo } from 'react';

import i18n from 'src/i18n';
import {
  AcDistDirectionEdgesModel,
  AcDistTableFieldsConfig,
  ModalTopColumns,
} from 'src/cluster/distribution-table';
import { NumberFormat, WithUnit } from 'src/components';
import { UNITS } from 'src/constants';
import { useAcVehicleTypeName } from 'src/cluster/common';
import { isNil } from 'src/helpers';

const useDirectionRouteConfig = (
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistDirectionEdgesModel>[] => {
  const getVehicleTypeName = useAcVehicleTypeName();
  return useMemo(() => {
    const translate = 'systems.distribution.columns.';
    return [
      {
        type: ModalTopColumns.interstopFerry,
        title: i18n.t(translate + ModalTopColumns.interstopFerry),
        className: classes.first,
        model: (result: AcDistDirectionEdgesModel) => (
          <>
            {result.firstStopName}
            <span> &mdash; </span>
            {result.lastStopName}
          </>
        ),
      },
      {
        type: ModalTopColumns.boardingSum,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.people)}>
            {i18n.t(translate + ModalTopColumns.boardingSum)}
          </WithUnit>
        ),
        model: (result: AcDistDirectionEdgesModel) => (
          <div className={classes.taRight}>
            {isNil(result.boardingSum) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.boardingSum}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: ModalTopColumns.boardingPerVehicle,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.people)}>
            {i18n.t(translate + ModalTopColumns.boardingPerVehicle)}
          </WithUnit>
        ),
        model: (result: AcDistDirectionEdgesModel) => (
          <div className={classes.taRight}>
            {isNil(result.boardingPerVehicle) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.boardingPerVehicle}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: ModalTopColumns.getOffSum,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.people)}>
            {i18n.t(translate + ModalTopColumns.getOffSum)}
          </WithUnit>
        ),
        model: (result: AcDistDirectionEdgesModel) => (
          <div className={classes.taRight}>
            {isNil(result.getOffSum) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.getOffSum}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: ModalTopColumns.getOffPerVehicle,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.people)}>
            {i18n.t(translate + ModalTopColumns.getOffPerVehicle)}
          </WithUnit>
        ),
        model: (result: AcDistDirectionEdgesModel) => (
          <div className={classes.taRight}>
            {isNil(result.getOffPerVehicle) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.getOffPerVehicle}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: ModalTopColumns.passengerTraffic,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.peoplePerHour)}>
            {i18n.t(translate + ModalTopColumns.passengerTraffic)}
          </WithUnit>
        ),
        model: (result: AcDistDirectionEdgesModel) => (
          <div className={classes.taRight}>
            {isNil(result.passengerTraffic) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.passengerTraffic}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: ModalTopColumns.fillingTheVehicle,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.peopleOfTheStandard)}>
            {i18n.t(translate + ModalTopColumns.fillingTheVehicle)}
          </WithUnit>
        ),
        model: (result: AcDistDirectionEdgesModel) => (
          <>
            {isNil(result.fillingPerVehicleAbs) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.fillingPerVehicleAbs}
                locale="ru"
              />
            )}
            <span> / </span>
            {isNil(result.fillingPerVehicleNorm) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.fillingPerVehicleNorm}
                locale="ru"
              />
            )}
          </>
        ),
      },
    ];
  }, [getVehicleTypeName]);
};

export default useDirectionRouteConfig;
