import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';

import { getUrl, PageManagerContext } from 'src/pages';
import { isArray } from 'src/helpers';

interface Props {
  baseUrl: string;
  navKey: string | string[];
  className?: string;
  activeClassName?: string;
  children: any;
  newWindow?: boolean;
  extraLinkUrl?: string;
}

const PageNavLink: FC<Props> = (props) => {
  const {
    baseUrl,
    navKey,
    className,
    activeClassName,
    children,
    extraLinkUrl,
    newWindow,
  } = props;

  const key = isArray(navKey) ? navKey.join('.') : navKey;
  return (
    <PageManagerContext.Consumer>
      {({ match }) => {
        const urlMapObject = get(baseUrl, navKey);
        const to = urlMapObject
          ? getUrl(urlMapObject, match.params)
          : { pathname: extraLinkUrl };
        return (
          <NavLink
            key={key}
            className={className}
            activeClassName={activeClassName}
            to={to}
            target={newWindow ? '_blank' : ''}
          >
            {children}
          </NavLink>
        );
      }}
    </PageManagerContext.Consumer>
  );
};

export default PageNavLink;
