import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing }: Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',

    '&:not(:last-of-type)': {
      marginBottom: spacing(2),
    },

    '&> p:nth-of-type(2n + 1):not(:last-of-type)': {
      marginRight: spacing(1),
    },

    '&> p:nth-of-type(2n):not(:last-of-type)': {
      marginRight: spacing(4),
    },

    '& $alert': {
      color: palette.secondary.main,
    },
  },

  toggle: {
    marginRight: spacing(1),
  },

  alert: {},
}));

export const useCaptionStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      textTransform: 'uppercase',
      color: theme.palette.grey[400],
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '12px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
});

export const useValueStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      textTransform: 'uppercase',
      color: theme.palette.grey[700],
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
});
