import React, { FC } from 'react';
import classNames from 'classnames';

import { DataGrid } from 'src/components';
import { useAcVehicleTypeName } from 'src/cluster/common/';
import {
  AcDistStopsModel,
  useDistPointConfigLeft,
  useDistPointConfigDown,
  useDistPointConfigTransfer,
  useDistPointConfigBoarding,
} from 'src/cluster/distribution-table';

import useStyles from './styles';

interface OwnProps {
  data: AcDistStopsModel[];
}

const DistributionPointDataGrid: FC<OwnProps> = (props) => {
  const { data } = props;

  const classes = useStyles();
  const getVehicleTypeName = useAcVehicleTypeName();

  const columnsLeft = useDistPointConfigLeft(getVehicleTypeName, classes);
  const columnsBoarding = useDistPointConfigBoarding(getVehicleTypeName, classes);
  const columnsDown = useDistPointConfigDown(getVehicleTypeName, classes);
  const columnsTransfer = useDistPointConfigTransfer(getVehicleTypeName, classes);

  return (
    <>
      <div className={classNames(classes.tableWrapper)}>
        <div className={classes.leftTableWrapper}>
          <DataGrid<AcDistStopsModel>
            columns={columnsLeft}
            body={data}
            headCellClassName={classes.headerCell}
            rowClassName={classes.routeRow}
            cellClassName={classes.cell}
          />
        </div>
        <div className={classes.middleTableWrapper}>
          <span className={classes.leftBorder}>
            <DataGrid<AcDistStopsModel>
              cellClassName={classes.cell}
              columns={columnsBoarding}
              body={data}
              headCellClassName={classes.headerCellFixed}
              rowClassName={classes.routeRow}
            />
          </span>
          <span className={classes.leftBorder}>
            <DataGrid<AcDistStopsModel>
              cellClassName={classes.cell}
              columns={columnsDown}
              body={data}
              headCellClassName={classes.headerCellFixed}
              rowClassName={classes.routeRow}
            />
          </span>
          <span className={classes.leftBorder}>
            <DataGrid<AcDistStopsModel>
              cellClassName={classes.cell}
              columns={columnsTransfer}
              body={data}
              headCellClassName={classes.headerCellFixed}
              rowClassName={classes.routeRow}
            />
          </span>
        </div>

      </div>
    </>
  );
};

export default DistributionPointDataGrid;
