import { api, forms, RestifyId } from 'redux-restify';

import modals from 'src/modals';
import i18n from 'src/i18n';

import { downloadFile } from 'src/helpers/file-download';
import { ThunkActionResult } from 'src/reducer';
import { Systems } from 'src/constants';
import {
  CALCULATIONS_ENDPOINTS,
  CALCULATIONS_MODEL_NAMES,
  CALCULATIONS_PAGES_FORM_NAMES,
  EXPORT_CALCULATION_MODAL,
  EXPORT_TYPES,
  EXPORT_TYPES_DICT,
  PageCalculationForm,
} from '../constants';
import { ExportCalculationForm } from '../forms/exportCalculation.form';

export const getCalculationsActions = (type: Systems) => {
  const modelName = CALCULATIONS_MODEL_NAMES[type];
  const calculationPageFormName = CALCULATIONS_PAGES_FORM_NAMES[type];
  const calculationsEndpoint = CALCULATIONS_ENDPOINTS[type];
  return {
    startCalculation: (): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
      const state = getState();
      const calculationPageForm = forms.selectors[calculationPageFormName].getFormWithNulls<PageCalculationForm>(state);
      return dispatch(forms.actions.sendQuickForm({
        baseConfig: 'baseEditCalculationForm',
        model: modelName,
        values: {
          originality: 1 - (calculationPageForm.uniqPercentMax / 100),
          margin: 1 + (calculationPageForm.lengthPercentMax / 100),
        },
      }));
    },
    exportCalculation: (id: RestifyId): ThunkActionResult<void> => (dispatch) => {
      dispatch(modals.actions.showModal(true, EXPORT_CALCULATION_MODAL, {
        calculationId: id,
        system: type,
      }));
    },
    downloadCalculationExport: (id: RestifyId): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
      const state = getState();
      const exportForm = forms.selectors.exportCalculationForm.getFormWithNulls<ExportCalculationForm>(state);
      const actionType = (
        exportForm.type !== EXPORT_TYPES.origin &&
        exportForm.type !== EXPORT_TYPES.fullResult
      ) ? 'callPost' : 'callGet';
      return dispatch(api.actions[actionType]({
        url: `${calculationsEndpoint}${id}/export/`,
        query: exportForm,
        isBinary: true,
      })).then((res: any) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: 'octet/stream',
          });
          if (exportForm.type) {
            const fileName = i18n.t(EXPORT_TYPES_DICT[exportForm.type]);
            downloadFile(blob, `${fileName}.xlsx`);
          }
        }
      });
    },
  };
};
