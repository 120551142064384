import React from 'react';

import { Vocabulary } from 'src/types';

import i18n from './i18n';
import { VocabularyContext, DEFAULT_VOCABULARY_LOCALE } from './constants';

export const useCurrentVocabulary = (): [string, Vocabulary] => {
  const vocabulary = React.useContext(VocabularyContext);
  return [i18n.language, vocabulary];
};

export const getVocabularyValue = (selectedLocale: string, vocabulary: Vocabulary, value: string) => {
  let renderValue;
  const splitValue = value.split('_');
  if (selectedLocale !== DEFAULT_VOCABULARY_LOCALE) {
    // Vocabulary is not loaded yet
    if (!Object.keys(vocabulary).length) {
      renderValue = splitValue.join(' / ');
    } else {
      renderValue = splitValue.map(v => vocabulary[v] || v).join(' / ');
    }
  } else {
    renderValue = splitValue.join(' / ');
  }
  return renderValue;
};
