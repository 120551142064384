import { AcRegistryPageForm, BOTTOM_COLUMNS, TOP_COLUMNS } from 'src/cluster/editor-table';

const defaults: AcRegistryPageForm = {
  registryId: undefined,
  topLevel: TOP_COLUMNS,
  bottomLevel: BOTTOM_COLUMNS,

  pageSize: 10,
  currentPage: 1,
};

export default {
  defaults,
  values: {
    topLevel: TOP_COLUMNS,
    bottomLevel: BOTTOM_COLUMNS,
  },
};
