import React, { FC } from 'react';
import ReactNumberFormat, { NumberFormatProps } from 'react-number-format';

import { isNil, isString } from 'src/helpers';

function isInfinitesimal(value: number | string | undefined, minValue: number): boolean {
  if (typeof value === 'string' || typeof value === 'undefined') {
    return false;
  }
  return value > 0 && value < minValue;
}

function getMinValue(decimalScale?: number) {
  return decimalScale ? (10 ** -decimalScale) / 2 : -Infinity;
}

function getThousandSeparator(locale: string) {
  return locale === 'ru' ? ' ' : ',';
}

function getDecimalSeparator(locale: string) {
  return locale === 'ru' ? ',' : '.';
}

function getActualValue(minValue: number, value?: number | string) {
  return isInfinitesimal(value, minValue) ? '~ 0' : value;
}

interface Props extends NumberFormatProps {
  locale?: string;
  zeroPlaceholder?: string;
}

const NumberFormat: FC<Props> = ({
  value,
  locale = 'ru',
  fixedDecimalScale = true,
  displayType = 'text',
  decimalScale = 2,
  zeroPlaceholder,
  ...rest
}) => {
  if (isNil(value)) {
    return <span>-</span>;
  }

  const minValue = getMinValue(decimalScale);
  const actualValue = getActualValue(minValue, value);

  if (isString(actualValue)) {
    return <span>{actualValue}</span>;
  }

  const thousandSeparator = getThousandSeparator(locale);
  const decimalSeparator = getDecimalSeparator(locale);
  return (
    <ReactNumberFormat
      {...rest}
      value={actualValue || zeroPlaceholder || 0}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
    />
  );
};

export default NumberFormat;
