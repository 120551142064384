/* eslint-disable max-len */
import React from 'react';

const Billing = () => {
  return (
    <svg viewBox="0 0 150 150">
      <path d="M123,60.3h1.5c1.1,0,2-0.9,2-2s-0.9-2-2-2H123c-1.1,0-2,0.9-2,2S121.9,60.3,123,60.3z" />
      <path d="M39.2,56.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S40.3,56.3,39.2,56.3z" />
      <path d="M51.6,56.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S52.7,56.3,51.6,56.3z" />
      <path d="M98.2,60.3h2.9c1.1,0,2-0.9,2-2s-0.9-2-2-2h-2.9c-1.1,0-2,0.9-2,2S97.1,60.3,98.2,60.3z" />
      <path d="M76.3,56.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S77.4,56.3,76.3,56.3z" />
      <path d="M88.7,56.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S89.8,56.3,88.7,56.3z" />
      <path d="M64,56.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2H64c1.1,0,2-0.9,2-2S65.1,56.3,64,56.3z" />
      <path d="M110.6,60.3h2.9c1.1,0,2-0.9,2-2s-0.9-2-2-2h-2.9c-1.1,0-2,0.9-2,2S109.5,60.3,110.6,60.3z" />
      <path d="M26.8,56.3h-1.5c-1.1,0-2,0.9-2,2s0.9,2,2,2h1.5c1.1,0,2-0.9,2-2S27.9,56.3,26.8,56.3z" />
      <path d="M124.5,118.3H123c-1.1,0-2,0.9-2,2s0.9,2,2,2h1.5c1.1,0,2-0.9,2-2S125.6,118.3,124.5,118.3z" />
      <path d="M88.7,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S89.8,118.3,88.7,118.3z" />
      <path d="M101.1,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S102.2,118.3,101.1,118.3z" />
      <path d="M113.5,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S114.6,118.3,113.5,118.3z" />
      <path d="M76.3,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S77.4,118.3,76.3,118.3z" />
      <path d="M39.2,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S40.3,118.3,39.2,118.3z" />
      <path d="M51.6,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.9c1.1,0,2-0.9,2-2S52.7,118.3,51.6,118.3z" />
      <path d="M64,118.3h-2.9c-1.1,0-2,0.9-2,2s0.9,2,2,2H64c1.1,0,2-0.9,2-2S65.1,118.3,64,118.3z" />
      <path d="M26.8,118.3h-1.5c-1.1,0-2,0.9-2,2s0.9,2,2,2h1.5c1.1,0,2-0.9,2-2S27.9,118.3,26.8,118.3z" />
      <path d="M132.5,47.5h-6.6V34.1c0-1.4-1.1-2.5-2.5-2.5H115l-1.4-4.5c-0.3-1-0.9-1.8-1.8-2.3c-0.9-0.5-1.9-0.6-2.9-0.3l-13.3,4     l-5.5-8.9c-1.1-1.8-3.5-2.3-5.2-1.2L63.3,31.8v-0.1H25.4C19.7,31.6,15,36.3,15,42v0.1v0.1v79.6c0,5.8,4.6,10.5,10.3,10.5h107.2     c1.4,0,2.5-1.1,2.5-2.5V50C135,48.7,133.9,47.5,132.5,47.5z M130,98.9h-22.3c-1.1,0-2-0.9-2-2v-14c0-1.1,0.9-2,2-2H130V98.9z      M120.9,36.6v10.9h-1.1l-3.3-10.9H120.9z M109.8,28.4l5.7,19.1h-8.1L97.8,32L109.8,28.4z M86.8,21.8l16,25.7H45.4L63,36.6h0.2     v-0.1L86.8,21.8z M20,42c0-3,2.4-5.4,5.4-5.4h30l-5.6,3.5H25.3c-1.1,0-2,0.9-2,2s0.9,2,2,2h18.1l-5.6,3.5H25.4     c-3,0-5.4-2.4-5.4-5.4V42L20,42z M25.3,127.2c-2.9,0-5.3-2.5-5.3-5.5V51c1.6,1,3.4,1.5,5.4,1.5h97.9h6.6v24.4h-22.3     c-3.3,0-6,2.7-6,6v14c0,3.3,2.7,6,6,6H130v24.4H25.3z" />
      <path d="M114.7,95.9c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6S111.4,95.9,114.7,95.9z M114.7,87.9c1.1,0,2,0.9,2,2s-0.9,2-2,2     s-2-0.9-2-2S113.6,87.9,114.7,87.9z" />
    </svg>
  );
};

export default Billing;
