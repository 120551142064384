import React from 'react';

const CrossIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1 11L11 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default CrossIcon;
