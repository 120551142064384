import { ChangeEvent, useMemo } from 'react';

import { BottomColumns, RegistryVariant } from 'src/cluster/editor-table';
import { UNITS } from 'src/constants';
import clsx from 'clsx';

const useEditVariantConfig = (
  variant: Partial<RegistryVariant>,
  onChangeField: (field: BottomColumns, val: unknown) => void,
  classes: Record<string, string>,
): {
    label: string;
    unit?: string;
    dot?: string;
    value: string | number;
    type: string;
    className: string;
    onChange(e: ChangeEvent<{ value: unknown }>): void;
  }[] => {
  return useMemo(() => {
    const translate = 'modules.registries.columns.';
    const {
      variantNumber = '',
      variantName = '',
      tariff = 0,
      tripNumberForward = 0,
      tripNumberBackward = 0,
    } = variant;

    return [
      {
        label: translate + BottomColumns.variantNumber,
        value: variantNumber,
        type: 'text',
        className: classes.field,
        onChange: (e: ChangeEvent<{ value: string }>) => {
          const { value } = e.target;
          onChangeField(BottomColumns.variantNumber, value);
        },
      },
      {
        label: translate + BottomColumns.variantName,
        value: variantName,
        type: 'text',
        className: clsx(classes.field, classes.fieldBig),
        onChange: (e: ChangeEvent<{ value: string }>) => {
          const { value } = e.target;
          onChangeField(BottomColumns.variantName, value);
        },
      },
      {
        label: translate + BottomColumns.tariff,
        unit: UNITS.costRub,
        dot: '.',
        value: tariff,
        type: 'tel',
        className: classes.field,
        onChange: (e: ChangeEvent<{ value: string }>) => {
          const { value } = e.target;
          if (!Number.isNaN(Number(value))) {
            onChangeField(BottomColumns.tariff, Number(value));
          }
        },
      },
      {
        label: translate + BottomColumns.tripNumberForward,
        unit: UNITS.items,
        value: tripNumberForward,
        type: 'tel',
        className: clsx(classes.field, classes.fieldMiddle),
        onChange: (e: ChangeEvent<{ value: string }>) => {
          const { value } = e.target;
          if (!Number.isNaN(Number(value))) {
            onChangeField(BottomColumns.tripNumberForward, Number(value));
          }
        },
      },
      {
        label: translate + BottomColumns.tripNumberBackward,
        unit: UNITS.items,
        value: tripNumberBackward,
        type: 'tel',
        className: clsx(classes.field, classes.fieldMiddle),
        onChange: (e: ChangeEvent<{ value: string }>) => {
          const { value } = e.target;
          if (!Number.isNaN(Number(value))) {
            onChangeField(BottomColumns.tripNumberBackward, Number(value));
          }
        },
      },
    ];
  }, [variant]);
};

export default useEditVariantConfig;
