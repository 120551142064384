import { registerModal, MODAL_SIZES } from 'src/modals';
import { AppState } from 'src/reducer';

import { ModalBalanceShapesShow } from 'src/cluster/shapes/';
import { AC_SHAPES_MODAL } from '../../constants';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.medium,
  };
};

export default registerModal(
  AC_SHAPES_MODAL,
  stateToProps,
)(ModalBalanceShapesShow);
