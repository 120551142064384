import React from 'react';

const ExcelAltIcon = () => (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1123 14.6899H13.579V2.75195H10.1123" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0247 1.04663L0.59104 3.5138L0.578979 13.7043L9.96864 16.3037L10.0247 1.04663Z"
      stroke="currentColor"
    />
    <path d="M3.6123 6.16284L7.07897 10.8528" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7.07897 5.73657L3.6123 10.4265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ExcelAltIcon;
