import React, {
  FC, Fragment, useMemo, useRef, useState,
} from 'react';
import { WebMercatorViewportOptions } from 'viewport-mercator-project';
import classNames from 'classnames';
import {
  InteractiveMap,
  Marker,
} from 'react-map-gl';
import { useSelector } from 'react-redux';
import {
  lineString,
  featureCollection,
  along,
  length,
} from '@turf/turf';

import { makeMapStyleEdgeLayer, useRestifyForm, getExtendedMapStyle } from 'src/helpers';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import { Map } from 'src/modules/general/common';

import { PageRoutesForm } from 'src/modules/shipments/routes';

import {
  selectCurrentProjectNodes,
  getCurrentProjectRoutes,
  MAP_PAGES_FORM_NAME,
  AUTO_ROUTE_COLOR,
  RAIL_ROUTE_COLOR,
  SEA_ROUTE_COLOR,
  AIR_ROUTE_COLOR,
  AUTO_ROUTE_BACK_COLOR,
  RAIL_ROUTE_BACK_COLOR,
  SEA_ROUTE_BACK_COLOR, AIR_ROUTE_BACK_COLOR,
} from 'src/modules/traffic/map';

import { WithTranslate } from 'src/i18n';
import TrafficMapLegend from './components/TrafficMapLegend';

import style from './index.module.css';

export const makeMapStylePathLayer = (id: string, filter: any[], paint: any) => {
  return {
    id,
    filter,
    source: 'paths',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint,
  };
};

interface Props extends WithTranslate {
  className?: string;
  geojsonUrl?: string;
}

const TrafficRoutesMap: FC<Props> = ({
  className,
  geojsonUrl,
  locale,
}) => {
  const [viewport, setViewport] = useState<WebMercatorViewportOptions>({
    latitude: 40,
    longitude: 80,
    zoom: 1.5,
  });
  const mapRef = useRef<InteractiveMap | null>(null);
  const currentNodes = useSelector(selectCurrentProjectNodes);
  const {
    currentRoutes,
    currentRoutesIsLoading,
  } = useSelector(getCurrentProjectRoutes);
  const [routesPageForm] = useRestifyForm<PageRoutesForm>(MAP_PAGES_FORM_NAME);

  const getMapStyleSources = (
    nodes: any[],
    routes: any[],
    pointsExtras: any,
    edgesExtras: any,
  ) => {
    return {
      edges: {
        type: 'geojson',
        ...edgesExtras,
        data: featureCollection(routes.reduce<any[]>((memo, route, routeIndex) => {
          const routeEdges = [];
          routeEdges.push(lineString(
            route.traffic.map((node: any) => (
              [node.lat, node.lng]),
            ),
            {
              isEdge: true,
              edgeType: route.edgeType,
              routeIndex,
              ...route,
            },
          ));
          return memo.concat(routeEdges);
        }, [])),
      },
      paths: {
        type: 'geojson',
        data: geojsonUrl || featureCollection([]),
      },
    };
  };

  const mapStyle = useMemo(() => {
    const sources = getMapStyleSources(currentNodes, currentRoutes, { cluster: false }, {});
    const isOffsetRouteFilter = [
      '==',
      ['get', 'offset'],
      -1,
    ];
    const otlkColor = [
      'case',
      ['get', 'isOtlk'],
      '#8529D3',
      ['==', ['get', 'edgeType'], 'auto'],
      AUTO_ROUTE_COLOR,
      ['==', ['get', 'edgeType'], 'rail'],
      RAIL_ROUTE_COLOR,
      ['==', ['get', 'edgeType'], 'sea'],
      SEA_ROUTE_COLOR,
      ['==', ['get', 'edgeType'], 'air'],
      AIR_ROUTE_COLOR,
      '#D04C38',
    ];
    const otlkBackColor = [
      'case',
      ['get', 'isOtlk'],
      '#b48ac0',
      ['==', ['get', 'edgeType'], 'auto'],
      AUTO_ROUTE_BACK_COLOR,
      ['==', ['get', 'edgeType'], 'rail'],
      RAIL_ROUTE_BACK_COLOR,
      ['==', ['get', 'edgeType'], 'sea'],
      SEA_ROUTE_BACK_COLOR,
      ['==', ['get', 'edgeType'], 'air'],
      AIR_ROUTE_BACK_COLOR,
      '#E7AFA3',
    ];
    const routeLoadWidths = [
      { from: 0, to: 30000, width: 2 },
      { from: 30000, to: 50000, width: 4 },
      { from: 50000, to: 100000, width: 6 },
      { from: 100000, to: 200000, width: 8 },
      { from: 200000, to: 300000, width: 10 },
    ];
    const allRoutesWidths = [
      'case',
      ...routeLoadWidths.reduce<any[]>((memo, item) => {
        return memo.concat([
          [
            'all',
            ['>=', ['get', 'freight'], item.from],
            ['<', ['get', 'freight'], item.to],
          ],
          item.width,
        ]);
      }, []),
      12,
    ];
    const allRoutesOffset = [
      'case',
      isOffsetRouteFilter,
      ['/', allRoutesWidths, 2],
      ['/', allRoutesWidths, 2], // edges are located in opposite routeDirections
    ];
    const layers: any[] = [
      makeMapStylePathLayer('sea-paths', ['all'],
        {
          'line-color': ['case', ['==', ['get', 'edgeType'], 'sea'], SEA_ROUTE_COLOR, 'transparent'],
          'line-width': ['case', ['==', ['get', 'edgeType'], 'sea'], 1, 0],
          'line-dasharray': [2, 2],
        }),
      makeMapStylePathLayer('air-paths', ['all'],
        {
          'line-color': ['case', ['==', ['get', 'edgeType'], 'air'], AIR_ROUTE_BACK_COLOR, 'transparent'],
          'line-width': ['case', ['==', ['get', 'edgeType'], 'air'], 1, 0],
        }),
      makeMapStylePathLayer('rail-paths', ['all'],
        {
          'line-color': ['case', ['==', ['get', 'edgeType'], 'rail'], RAIL_ROUTE_BACK_COLOR, 'transparent'],
          'line-width': ['case', ['==', ['get', 'edgeType'], 'rail'], 1, 0],
        }),
      makeMapStylePathLayer('auto-paths', ['all'],
        {
          'line-color': ['case', ['==', ['get', 'edgeType'], 'auto'], AUTO_ROUTE_BACK_COLOR, 'transparent'],
          'line-width': ['case', ['==', ['get', 'edgeType'], 'auto'], 1, 0],
        }),
      makeMapStyleEdgeLayer('routes-hover', ['all'],
        {
          'line-color': 'transparent',
          'line-width': allRoutesWidths,
          'line-offset': allRoutesOffset,
        }),
      makeMapStyleEdgeLayer('selected-routes', ['!', isOffsetRouteFilter],
        {
          'line-color': otlkColor,
          'line-width': allRoutesWidths,
          'line-offset': allRoutesOffset,
        }),
      makeMapStyleEdgeLayer('selected-routes-back', isOffsetRouteFilter,
        {
          'line-color': otlkBackColor,
          'line-width': allRoutesWidths,
          'line-offset': allRoutesOffset,
        }),
    ];
    return getExtendedMapStyle({
      sources,
      layers,
    }, locale);
  }, [currentRoutes, routesPageForm.selectedRoute, locale]);

  const routesFreight: Record<string, any> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const route of currentRoutes) {
    const line = lineString(
      route.traffic.map(
        (node: any) => [node.lat, node.lng],
      ),
    );
    const center = along(line, length(line) / 2);
    if (!center.geometry) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const roundFreight = (route.freight / 1000).toFixed(1);
    const longitude = center.geometry.coordinates[0].toFixed(4);
    const latitude = center.geometry.coordinates[1].toFixed(4);
    const directions: {[direction: string]: string} = { South: 'North', East: 'West' };
    const key = `${latitude}_${longitude}`;
    if (routesFreight[key] === undefined) {
      routesFreight[key] = {
        edgeType: route.edgeType,
        lat: latitude,
        lon: longitude,
        isOtlk: route.isOtlk,
        direction: route.offset > 0 ? route.direction : directions[route.direction],
      };
    }

    if (route.offset > 0) {
      routesFreight[key].forth = roundFreight;
    } else {
      routesFreight[key].back = roundFreight;
    }
  }

  return (
    <LoadingBlockContainer
      className={classNames(style.root, className)}
      isBlocked={currentRoutesIsLoading}
    >
      <Map
        mapRef={mapRef}
        viewport={viewport}
        setViewport={setViewport}
        mapStyle={mapStyle}
        legend={<TrafficMapLegend />}
      >
        <Fragment>
          {currentNodes && currentNodes.map(node => {
            const latitude = node.lat;
            const longitude = node.lng;
            let iconType = ICONS_TYPES.trafficNodeSea;
            if (node.nodeType === 'Ж/Д узел') {
              iconType = ICONS_TYPES.trafficNodeRail;
            }
            return (
              <Marker
                key={node.id}
                latitude={latitude}
                longitude={longitude}
                offsetLeft={-22 / 2}
                offsetTop={-22 / 2}
              >
                <PIcon
                  type={iconType}
                  size={22}
                  className={style.marker}
                />
              </Marker>
            );
          })}
          {Object.keys(routesFreight).map(freightKey => {
            const route = routesFreight[freightKey];
            const longitude = parseFloat(route.lon);
            const latitude = parseFloat(route.lat);
            let forthDirection; let
              backDirection;
            if (route.direction === 'North' || route.direction === 'South') {
              forthDirection = ICONS_TYPES.arrowUp;
              backDirection = ICONS_TYPES.arrowDown;
            } else if (route.direction === 'West' || route.direction === 'East') {
              forthDirection = ICONS_TYPES.arrowLeft;
              backDirection = ICONS_TYPES.arrowRight;
            }
            const markerClassName = classNames(
              style.routeFreightInfo,
              {
                [style.routeFreightInfoOtlk]: route.isOtlk,
                [style.routeFreightInfoAir]: route.edgeType === 'air',
                [style.routeFreightInfoSea]: route.edgeType === 'sea',
                [style.routeFreightInfoAuto]: route.edgeType === 'auto',
                [style.routeFreightInfoRail]: route.edgeType === 'rail',
              });
            const arrowClassName = classNames(
              style.routeFreightArrow,
              {
                [style.routeFreightArrowOtlk]: route.isOtlk,
                [style.routeFreightArrowAir]: route.edgeType === 'air',
                [style.routeFreightArrowSea]: route.edgeType === 'sea',
                [style.routeFreightArrowAuto]: route.edgeType === 'auto',
                [style.routeFreightArrowRail]: route.edgeType === 'rail',
              });
            const arrowBackClassName = classNames(
              style.routeFreightArrow,
              style.routeFreightArrowBack,
              {
                [style.routeFreightArrowBackOtlk]: route.isOtlk,
                [style.routeFreightArrowBackAir]: route.edgeType === 'air',
                [style.routeFreightArrowBackSea]: route.edgeType === 'sea',
                [style.routeFreightArrowBackAuto]: route.edgeType === 'auto',
                [style.routeFreightArrowBackRail]: route.edgeType === 'rail',
              });
            return (
              <Marker
                key={`${latitude}_${longitude}`}
                latitude={latitude}
                longitude={longitude}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <div className={markerClassName}>
                  {route.forth && (
                    <div className={style.routeFreightBlock}>
                      <PIcon
                        type={forthDirection}
                        size={10}
                        className={arrowClassName}
                      />
                      { route.forth }
                    </div>
                  )}
                  {route.back && (
                    <div className={style.routeFreightBlock}>
                      <PIcon
                        type={backDirection}
                        size={10}
                        className={arrowBackClassName}
                      />
                      {route.back}
                    </div>
                  )}
                </div>
              </Marker>
            );
          })}
        </Fragment>
      </Map>
    </LoadingBlockContainer>
  );
};

export default TrafficRoutesMap;
