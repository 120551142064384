import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import { selectRegistries } from 'src/cluster/editor-table';

const useRegistriesOptions = (): SelectMenuOption[] => {
  const registries = useSelector(selectRegistries);

  return useMemo(() => (
    registries.map((registry) => registry.map((value) => ({
      id: `${value.id}`,
      value: value.id,
      label: value.name,
    }))).orJust([])
  ), [registries]);
};

export default useRegistriesOptions;
