/* eslint-disable max-len */
import React from 'react';

const EditorQuoteBlock = () => (
  <svg viewBox="0 0 15 10" >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M9,0 L9,6.01556396 L10.977478,6.01556396 C10.977478,6.01556396 11.0167847,6.66906738 11.0167847,7.68670654 C11.0167847,8.7043457 9.98620605,10.0124512 9.98620605,10.0124512 C9.98620605,10.0124512 14.0361999,9.02007996 13.9997706,4.99977061 C13.9633414,0.979461265 13.9997706,0 13.9997706,0 L9,0 Z" />
        <path d="M0,0 L0,6.01556396 L1.97747803,6.01556396 C1.97747803,6.01556396 2.01678467,6.66906738 2.01678467,7.68670654 C2.01678467,8.7043457 0.986206055,10.0124512 0.986206055,10.0124512 C0.986206055,10.0124512 5.03619987,9.02007996 4.99977061,4.99977061 C4.96334135,0.979461265 4.99977061,0 4.99977061,0 L0,0 Z" />
      </g>
    </g>
  </svg>
);

export default EditorQuoteBlock;
