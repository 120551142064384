import React from 'react';
import classNames from 'classnames';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import style from './index.module.css';

interface Props {
  className?: string;
  showTraffic: boolean;
  toggle: () => void;
}

const AggloMapToggle = (props: Props) => {
  const { className, showTraffic, toggle } = props;
  return (
    <div className={classNames(style.root, className)}>
      <button {...{
        type: 'button',
        className: classNames(style.button, style.buttonBus),
        onClick: toggle,
      }}>
        <PIcon {...{
          type: showTraffic ? ICONS_TYPES.busDisabled : ICONS_TYPES.bus,
          className: style.icon,
        }} />
      </button>
      <button {...{
        type: 'button',
        className: classNames(style.button, style.buttonTraffic),
        onClick: toggle,
      }}>
        <PIcon {...{
          type: showTraffic ? ICONS_TYPES.traffic : ICONS_TYPES.trafficDisabled,
          className: style.icon,
        }} />
      </button>
    </div>
  );
};

export default AggloMapToggle;
