import { api } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import { BinaryApiResponse } from 'src/interfaces';
import {
  createFormActions,
  isNumber,
  downloadFile,
  getFilename,
  createModelActions,
  getFilenameFromPath,
  objectToLowerSnake,
} from 'src/helpers';
import {
  selectTtcPageForm,
  TTC_REGIONS_ENDPOINT,
  TTC_SITES_ENDPOINT,
} from 'src/cluster/balance-ttc';
import {
  AC_PROJECTS_ENDPOINT,
  AC_MATRIX_FORECAST_ENDPOINT,
  TTC_CALC_PAGE_FORM_NAME,
  TTC_SITES_MODEL_NAME,
  TTC_REGIONS_MODEL_NAME,
  TtcCalcPageForm,
  selectMatrixForecastParams,
  selectAcProjectListForm,
  AC_PROJECTS_MODEL_NAME,
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  EXPORT_GRAVITY_TUNER,
} from 'src/cluster/common';
import { ProcessStatus } from 'src/types';
import app from 'src/app';

export const ttcPageFormActions = createFormActions<TtcCalcPageForm>(
  TTC_CALC_PAGE_FORM_NAME,
);

export const ttcSitesEntityManager = createModelActions(TTC_SITES_MODEL_NAME);
export const ttcRegionsEntityManager = createModelActions(
  TTC_REGIONS_MODEL_NAME,
);

function doExportCalculationNew(
  url: string,
  filename: string,
  filters: Record<string, any>,
  single?: boolean,
): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch(ttcPageFormActions.changeField('isExporting', true));

      const method = single ? 'callGet' : 'callPost';
      const { data, status } = await dispatch(
        api.actions[method]({ url, data: filters }),
      );

      if ([200, 201].includes(status) && data !== undefined) {
        const {
          id, status: processStatus, progress, error,
        } = data;

        if (processStatus === ProcessStatus.ERROR) {
          dispatch(app.actions.toast.error(error));
          dispatch(
            ttcPageFormActions.resetSomeFields([
              'exportUrl',
              'filename',
              'progress',
              'isExporting',
            ]),
          );
        } else if (processStatus === ProcessStatus.SUCCESS) {
          dispatch(
            ttcPageFormActions.resetSomeFields([
              'exportUrl',
              'filename',
              'progress',
              'isExporting',
            ]),
          );
          downloadFile(
            data.file,
            `${filename}_${getFilenameFromPath(data.file)}`,
          );
        } else {
          if (!single) {
            dispatch(
              ttcPageFormActions.changeField('exportUrl', `${url}${id}/`),
            );
            dispatch(ttcPageFormActions.changeField('filename', filename));
          }
          dispatch(ttcPageFormActions.changeField('progress', progress));
        }

        return Promise.resolve();
      }
      dispatch(ttcPageFormActions.resetField('isExporting'));
      return Promise.reject();
    } catch (err) {
      dispatch(ttcPageFormActions.resetField('isExporting'));
      return Promise.reject(err);
    }
  };
}

export function getExportStatus(): ThunkActionResult<any> {
  return (dispatch, getState) => {
    const state = getState();
    const { filename, exportUrl } = selectTtcPageForm(state);
    if (!exportUrl || !filename) {
      return Promise.reject();
    }
    return dispatch(doExportCalculationNew(exportUrl, filename, {}, true));
  };
}

export function exportCalculation(
  isFull?: boolean,
  isModelParams?: boolean,
): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId, calculationId] = selectMatrixForecastParams(state);
    const {
      isSites,
      year,
      scenarioId,
      intervalId,
      siteFromId,
      siteToId,
      regionFromId,
      regionToId,
    } = selectTtcPageForm(state);

    if (!isNumber(selectedProject) || !isNumber(forecastId)) {
      return Promise.reject();
    }

    let data = {};
    if (!isFull) {
      if (!isNumber(year) || !isNumber(scenarioId) || !isNumber(intervalId)) {
        return Promise.reject();
      }

      data = objectToLowerSnake({
        year,
        scenarioId,
        intervalId,
        calculationId,
        siteFromId: isSites ? siteFromId : undefined,
        siteToId: isSites ? siteToId : undefined,
        regionFromId: !isSites ? regionFromId : undefined,
        regionToId: !isSites ? regionToId : undefined,
      });
    }

    const startUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}`;
    const endpoint = `/${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/${isSites
      ? TTC_SITES_ENDPOINT : TTC_REGIONS_ENDPOINT}`;
    const url = `${startUrl}${!isModelParams ? endpoint : `/${EXPORT_GRAVITY_TUNER}`}export/`;

    const start = isSites ? 'sites' : 'regions';
    const filename = `${start}_${forecastId}`;

    return dispatch(doExportCalculationNew(url, filename, data));
  };
}

function doExportCalculation(
  url: string,
  filename: string,
  extension?: 'xlsx' | 'geojson',
): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const {
      isSites,
      year,
      scenarioId,
      intervalId,
      siteFromId,
      siteToId,
      regionFromId,
      regionToId,
    } = selectTtcPageForm(getState());

    if (!isNumber(year) || !isNumber(scenarioId) || !isNumber(intervalId)) {
      return Promise.reject();
    }

    const config = {
      url,
      isBinary: true,
      query: {
        year,
        scenarioId,
        intervalId,
        siteFromId: isSites ? siteFromId : undefined,
        siteToId: isSites ? siteToId : undefined,
        regionFromId: !isSites ? regionFromId : undefined,
        regionToId: !isSites ? regionToId : undefined,
        extension,
      },
    };

    try {
      dispatch(ttcPageFormActions.changeField('isExporting', true));
      const { data, status, api: xhr }: BinaryApiResponse = await dispatch(
        api.actions.callGet(config),
      );
      if (status === 200 && data !== undefined) {
        const blob = new Blob([data], { type: 'octet/stream' });
        downloadFile(blob, `${filename}_${getFilename(xhr)}`);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      dispatch(ttcPageFormActions.resetField('isExporting'));
    }
  };
}

export function exportCalculationItem(
  fromId: number,
  toId: number,
  extension: 'xlsx' | 'geojson',
): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const [forecastId] = selectMatrixForecastParams(state);
    const { isSites } = selectTtcPageForm(state);

    if (!isNumber(selectedProject) || !isNumber(forecastId)) {
      return Promise.reject();
    }

    const startUrl = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/`;
    const endpoint = isSites ? TTC_SITES_ENDPOINT : TTC_REGIONS_ENDPOINT;
    const url = `${startUrl}${endpoint}${fromId}/${toId}/export/`;

    const start = isSites ? 'sites' : 'regions';
    const filename = `${start}_${fromId}_${toId}`;
    return dispatch(doExportCalculation(url, filename, extension));
  };
}

export function reloadTtcData(): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const {
      year,
      scenarioId,
      intervalId,
      siteFromId,
      siteToId,
      pageSize,
      currentPage,
    } = selectTtcPageForm(getState());
    const [forecastId] = selectMatrixForecastParams(getState());

    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        siteFromId,
        siteToId,
      },
    };

    dispatch(ttcSitesEntityManager.loadData(apiConfig));
    dispatch(ttcRegionsEntityManager.loadData(apiConfig));
  };
}
