import { Id } from 'src/types';
import { FeatureCollection, Feature } from 'src/interfaces';

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isArray(value: unknown): value is Array<unknown> {
  return Array.isArray(value);
}

export function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object';
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function isNil(value: unknown): value is null | undefined {
  return isNull(value) || isUndefined(value);
}

export function isNotNil(value: unknown): value is Exclude<unknown, null | undefined> {
  return !isNil(value);
}

export function isId(value: unknown): value is Id {
  return typeof value === 'number';
}

export function isFunc(value: unknown): value is Function {
  return typeof value === 'function';
}

export function isFeature(value: unknown): value is Feature {
  if (!isObject(value)) {
    return false;
  }

  const { type, geometry, properties } = value as Feature;

  return type === 'Feature' && isObject(geometry) && isObject(properties);
}

export function isFeatureCollection(value: unknown): value is FeatureCollection {
  if (!isObject(value)) {
    return false;
  }

  const { type, features } = value as FeatureCollection;

  return type === 'FeatureCollection' && isArray(features);
}
