import React, { FC, ChangeEvent } from 'react';
import { FormControlLabel } from '@material-ui/core';

import { Checkbox } from 'src/components';

export interface Props {
  name?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange(event: ChangeEvent<HTMLInputElement>, checked: boolean): void;
}

const VehicleTypeCheckbox: FC<Props> = (props) => {
  const {
    name,
    label,
    checked = false,
    disabled = false,
    onChange,
  } = props;

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={onChange}
        />
      )}
      label={label}
    />
  );
};

export default VehicleTypeCheckbox;
