import {
  isArray, isNumber, isObject, isString,
} from 'src/helpers';

import { AcProjectsModel } from 'src/cluster/projects/interfaces';

export function isAcProjectsModel(record: unknown): record is AcProjectsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
    sourceFiles,
  } = record as Partial<AcProjectsModel>;

  return isNumber(id)
    && isString(name)
    && isArray(sourceFiles);
}
