import React, { FC, Fragment } from 'react';
import { Tooltip } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { UNITS } from 'src/constants';
import { isNil } from 'src/helpers';
import {
  NumberFormat, WithUnit, DataGrid, QuestionMark,
} from 'src/components';

import { AcForecastCorrespondenceModel } from 'src/cluster/common';

import useStyles from './styles';

interface Props extends WithTranslate {
  data: AcForecastCorrespondenceModel[];
  interval: number;
}

const ForecastCorrespondencesTable: FC<Props> = (props) => {
  const { locale, t, data } = props;

  const classes = useStyles();

  const header = [
    {
      title: t('systems.balance.captions.firstDistrict'),
      model: (model: AcForecastCorrespondenceModel) => model.regionFromName,
    },
    {
      title: t('systems.balance.captions.secondDistrict'),
      model: (model: AcForecastCorrespondenceModel) => model.regionToName,
    },
    {
      title: (
        <Fragment>
          <WithUnit unit={t(UNITS.costRub)}
          >
            {t('modules.ttcCalculations.captions.title')}
          </WithUnit>
          <span className={classes.titleUnit}>
            {t('systems.balance.captions.baseYear')}
            <span className={classes.spaceWrap}>/</span>
            {t('systems.balance.captions.forecastYear')}
          </span>
        </Fragment>
      ),
      model: (result: AcForecastCorrespondenceModel) => {
        return (
          <Fragment>
            <Tooltip title={`${t('systems.balance.captions.baseYear')}`} arrow>
              <span className={classes.tooltipAnchor}>
                <NumberFormat
                  fixedDecimalScale={false}
                  decimalScale={2}
                  value={result.baseTtc ?? result.forecastTtc}
                  locale={locale}
                />
              </span>
            </Tooltip>
            <span className={classes.spaceWrap}>/</span>
            <Tooltip title={`${t('systems.balance.captions.forecastYear')}`} arrow>
              <span className={classes.tooltipAnchor}>
                <NumberFormat
                  fixedDecimalScale={false}
                  decimalScale={2}
                  value={result.baseTtc && result.forecastTtc}
                  locale={locale}
                />
              </span>
            </Tooltip>
          </Fragment>
        );
      },
    },
    {
      title: (
        <Fragment>
          <WithUnit unit={t(UNITS.people)}>
            {t('systems.balance.captions.passengerTraffic')}
          </WithUnit>
          <span className={classes.titleUnit}>
            {t('modules.ttcCalculations.captions.public')}
            <span className={classes.spaceWrap}>/</span>
            {t('modules.ttcCalculations.captions.private')}
            <span className={classes.spaceWrap}>/</span>
            {t('modules.ttcCalculations.captions.total')}
          </span>
        </Fragment>
      ),
      model: (model: AcForecastCorrespondenceModel) => {
        return (
          <Fragment>
            <Tooltip title={`${t('modules.ttcCalculations.captions.public')}`} arrow>
              <span>
                {isNil(model.trafficPass) ?
                  (
                    <Tooltip
                      title={`${t('modules.ttcCalculations.captions.noRouteFound')}`}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  ) : (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={model.trafficPass}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
            <span className={classes.spaceWrap}>/</span>
            <Tooltip title={`${t('modules.ttcCalculations.captions.private')}`} arrow>
              <span>
                {isNil(model.trafficCar) ?
                  (
                    <Tooltip
                      title={`${t('modules.ttcCalculations.captions.noRouteFound')}`}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  ) : (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={model.trafficCar}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
            <span className={classes.spaceWrap}>/</span>
            <Tooltip title={`${t('modules.ttcCalculations.captions.total')}`} arrow>
              <span>
                {isNil(model.traffic) ?
                  (
                    <Tooltip
                      title={`${t('modules.ttcCalculations.captions.noRouteFound')}`}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  ) : (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={model.traffic}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
          </Fragment>
        );
      },
    },
    {
      title: (
        <Tooltip title={`${t('systems.balance.messages.increasePassengerTraffic')}`} arrow>
          <div className={classes.increaseTitle}>
            <div className={classes.increaseBlock}>
              <span className={classes.questionMark}><QuestionMark /></span>
              {t('systems.balance.captions.increase')}
            </div>
            <WithUnit unit="%">
              {t('systems.balance.captions.inPassengerTraffic')}
            </WithUnit>
          </div>
        </Tooltip>
      ),
      model: (model: AcForecastCorrespondenceModel) => (
        <NumberFormat
          value={model.trafficIncrease * 100}
          locale={locale}
          decimalScale={2}
        />
      ),
    },
  ];

  return (
    <DataGrid
      headCellClassName={classes.headerCell}
      body={data}
      columns={header}
    />
  );
};

export default ForecastCorrespondencesTable;
