import React from 'react';

import { Locale, Vocabulary } from 'src/types';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_SELECTED_LOCALE = 'ru';
export const DEFAULT_VOCABULARY_LOCALE = 'ru';

export const AVAILABLE_LOCALES: Locale[] = [
  'ru',
  'en',
];

export const VocabularyContext = React.createContext<Vocabulary>({});
