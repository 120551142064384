import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: 15,
    transform: 'translateY(-50%)',
  },

  toggle: {
    border: 0,
    outline: 0,
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  arrow: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    overflow: 'hidden',
    width: 16,
    height: 16,
    display: 'block',
    transform: 'rotate(-90deg)',
    transition: 'all .3s ease',

  },

  arrowDown: {
    textAlign: 'center',
    overflow: 'hidden',
    width: 16,
    height: 16,
    display: 'block',
    transform: 'rotate(0deg)',
    transition: 'all .3s ease',
  },

}));
