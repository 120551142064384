import { RestifyForeignKeysArray } from 'redux-restify';

import { ROUTES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: ROUTES_ENDPOINT,
  parent: 'agglomerationProjects',
  defaults: {
    routes: new RestifyForeignKeysArray('agglomerationRoutes'),
    overshot: new RestifyForeignKeysArray('agglomerationOvershot'),
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
