import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import { AC_DIST_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/common';
import { ModalDistributionGraphFilters } from 'src/cluster/distribution-common';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.small,
  };
};

export default registerModal(
  AC_DIST_GRAPH_VERSION_MODAL_NAME,
  stateToProps,
)(ModalDistributionGraphFilters);
