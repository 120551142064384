import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { WithTranslate } from 'src/i18n';
import { useSystemsContext } from 'src/constants';
import PButton from 'src/components/deprecated/PButton';
import PSlider from 'src/components/deprecated/PSlider';
import { useRestifyForm } from 'src/helpers';
import { INPUT_TYPES } from 'src/components/deprecated/PInput';

import CitiesCalcTable from 'src/modules/cities/calculations/components/CitiesCalcTable';
import calculations, {
  PageCalculationForm,
  CALCULATIONS_PAGES_FORM_NAME,
} from 'src/modules/cities/calculations';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const CitiesCalcPage: React.FC<Props> = ({
  className, t,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [
    pageCalculationsForm,
    pageCalculationsFormActions,
  ] = useRestifyForm<PageCalculationForm>(CALCULATIONS_PAGES_FORM_NAME);
  const [/**/, pageFormActions] = useRestifyForm<PageCalculationForm>(CALCULATIONS_PAGES_FORM_NAME);

  useEffect(() => {
    dispatch(pageFormActions.changeField('trafficLimit', 50));
    dispatch(pageFormActions.changeField('maxAlternativeRoutes', 10));
  }, []);

  return (
    <div className={classNames(style.root, className)}>
      <div className={pageLayoutStyle.toolbar}>
        <div className={pageLayoutStyle.toolbarBlock}>
          <div className={pageLayoutStyle.toolbarTitle}>
            {t('modules.calculations.captions.title')}
          </div>
          <div className={pageLayoutStyle.toolbarControls}>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSlider {...{
                name: 'trafficLimit',
                onChange: (value) => {
                  dispatch(pageCalculationsFormActions.changeField('trafficLimit', value));
                },
                label: t('systems.cities.captions.maxBandwidth'),
                value: pageCalculationsForm.trafficLimit,
                min: 1,
              }} />
            </div>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSlider {...{
                name: 'maxAlternativeRoutes',
                onChange: (value) => {
                  dispatch(pageCalculationsFormActions.changeField('maxAlternativeRoutes', value));
                },
                label: t('systems.cities.captions.maxAlternativeRoutes'),
                value: pageCalculationsForm.maxAlternativeRoutes,
                valueType: INPUT_TYPES.int,
                min: 1,
                max: 50,
              }} />
            </div>
          </div>
        </div>
        <div className={classNames(pageLayoutStyle.toolbarBlock, style.buttonBlock)}>
          <div className={pageLayoutStyle.toolbarControls}>
            <PButton {...{
              className: style.button,
              onClick: () => {
                dispatch(calculations.actions[currentSystem].startCalculation());
              },
            }}>
              {t('common.captions.start')}
            </PButton>
          </div>
        </div>
      </div>
      <CitiesCalcTable className={style.calculationsTable} />
    </div>
  );
};

export default CitiesCalcPage;
