import { createSelector } from 'reselect';

import {
  api,
  forms, GetByIdConfig,
  RestifyModel,
} from 'redux-restify';

import {
  PageProjectForm,
  PROJECTS_MODEL_NAMES,
  PROJECTS_PAGES_FORM_NAMES,
} from 'src/modules/general/projects';
import { AppState } from 'src/reducer';
import { Systems } from 'src/constants';
import { Writable } from 'src/types';
import { NodeModel, RouteModel } from 'src/modules/general/common';
import { PROJECTS_PAGES_FORM_NAME } from 'src/modules/mth/projects';

import {
  EDGES_MODEL_NAME,
  NODES_MODEL_NAME,
  ROUTES_AND_OVERSHOT_MODEL_NAME,
  PAGE_ROUTES_FORM_NAME,
} from '../constants';
import {
  OvershotModel,
  PageRoutesForm,
  RouteEdgeModel, RoutesAndOvershotModel,
} from '../../common/interfaces';

function isEmptyObject(obj: object) {
  return JSON.stringify(obj) === JSON.stringify({});
}

function hasAggloRoutesActualFilters(pageRoutesForm: PageRoutesForm): pageRoutesForm is Required<PageRoutesForm> {
  return Boolean(
    pageRoutesForm.actualFrom !== undefined
    && pageRoutesForm.actualTo !== undefined
    && pageRoutesForm.actualTrafficLimit !== undefined
    && pageRoutesForm.actualMaxAlternativeRoutes !== undefined
    && pageRoutesForm.actualHasLongPedestrian !== undefined
    && pageRoutesForm.uuid !== undefined,
  );
}

const getRoutesApiConfig = (
  pageProjectForm: PageProjectForm,
  pageRoutesForm: PageRoutesForm,
  projectsModelName: string,
): GetByIdConfig | undefined => {
  if (!pageProjectForm.selectedProject || !hasAggloRoutesActualFilters(pageRoutesForm)) {
    return undefined;
  }
  const {
    actualFrom,
    actualTo,
    actualTrafficLimit,
    actualMaxAlternativeRoutes,
    actualHasLongPedestrian,
    actualMaxPedestrianTime,
    uuid,
  } = pageRoutesForm;
  return {
    parentEntities: {
      [projectsModelName]: pageProjectForm.selectedProject,
    },
    query: {
      firstNode: actualFrom,
      secondNode: actualTo,
      trafficLimit: actualTrafficLimit / 100,
      maxAlternativeRoutes: actualMaxAlternativeRoutes,
      maxPedestrianTime: actualMaxPedestrianTime,
      hasLongPedestrian: actualHasLongPedestrian,
      queryUuid: uuid,
    },
  };
};

const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[Systems.mth];
const projectsModelName = PROJECTS_MODEL_NAMES[Systems.mth];

export const selectEndpoint = createSelector(
  (state) => api.selectors.entityManager.agglomerationProjects.getEndpoint(state),
  (endpoint) => endpoint,
);

export const selectCurrentTransportType = createSelector(
  (state: AppState) => forms.selectors[PAGE_ROUTES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state),
  (pageRoutesForm) => String(pageRoutesForm.transportType),
);

export const selectCurrentProjectId = createSelector(
  (state) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
  (pageProjectForm) => Number(pageProjectForm.selectedProject),
);

export const selectCurrentProjectNodes = createSelector(
  [
    (state) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
    (state) => api.selectors.entityManager[NODES_MODEL_NAME].getEntities<NodeModel>(state),
  ],
  (pageProjectForm, nodesEntities): [NodeModel[], boolean] => {
    if (!pageProjectForm.selectedProject) {
      return [
        [],
        false,
      ];
    }
    const nodesApiConfig = {
      parentEntities: {
        [projectsModelName]: pageProjectForm.selectedProject,
      },
    };
    return [
      nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A'),
      nodesEntities.getIsLoadingArray(nodesApiConfig),
    ];
  },
);

const getRoutesSelectorsConst = [
  (state: AppState) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
  (state: AppState) => forms.selectors[PAGE_ROUTES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state),
  (state: AppState) => api.selectors.entityManager[ROUTES_AND_OVERSHOT_MODEL_NAME]
    .getEntities<RoutesAndOvershotModel>(state),
  (state: AppState) => state?.api?.entityManager?.[ROUTES_AND_OVERSHOT_MODEL_NAME]?.loadErrorEntities,
] as const;

const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

export const selectCurrentProjectRoutes = createSelector(
  getProjectRoutesSelectors,
  (
    pageProjectForm, pageRoutesForm, routesEntities, loadErrorEntities,
  ): [RouteModel[], OvershotModel[], boolean, boolean] => {
    const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm, projectsModelName);
    if (!routesApiConfig) {
      return [[], [], false, false];
    }
    const routesAndOvershot = routesEntities.getById('', routesApiConfig);
    const isLoading = routesEntities.getIsLoadingById('', routesApiConfig);
    const hasErrors = !isEmptyObject(loadErrorEntities);
    return [
      isLoading ? [] : routesAndOvershot.routes,
      isLoading ? [] : routesAndOvershot.overshot,
      isLoading,
      hasErrors,
    ];
  },
);

export const selectSelectedRouteEdges = createSelector(
  [
    (state: AppState) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => forms.selectors[PAGE_ROUTES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[EDGES_MODEL_NAME].getEntities<RouteEdgeModel>(state),
  ],
  (pageProjectForm, pageRoutesForm, edgeEntities): [RestifyModel<RouteEdgeModel>[], boolean] => {
    if (!pageRoutesForm.selectedEdges || !pageProjectForm.selectedProject) {
      return [[], false];
    }
    const edgesApiConfig = {
      parentEntities: {
        [projectsModelName]: pageProjectForm.selectedProject,
      },
      filter: {
        id: pageRoutesForm.selectedEdges,
      },
    };
    const selectedEdges = edgeEntities.getArray(edgesApiConfig);
    const isLoading = edgeEntities.getIsLoadingArray(edgesApiConfig);
    return [selectedEdges, isLoading];
  },
);

export const selectAggloProjectPageForm = createSelector(
  (state: AppState) => forms.selectors[PROJECTS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
  (form) => form,
);

export const selectAggloRoutesPageForm = createSelector(
  (state: AppState) => forms.selectors[PAGE_ROUTES_FORM_NAME].getFormWithNulls<PageRoutesForm>(state),
  (form) => form,
);
