import { RestifyArray } from 'redux-restify';

import { ROUTES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: ROUTES_ENDPOINT,
  parent: 'agglomerationCalculations',
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    correspondenceType: undefined,
    trafficSummary: undefined,
    weight: undefined,
    time: undefined,
    distance: undefined,
    traffic: new RestifyArray({
      defaults: {
        edgeId: undefined,
        firstNode: undefined,
        secondNode: undefined,
        distance: undefined,
        speed: undefined,
        traffic: undefined,
        trafficLoad: undefined,
        trafficPass: undefined,
        weight: undefined,
        fuelCost: undefined,
        time: undefined,
        timeMoney: undefined,
        fare: undefined,
        transportType: undefined,
      },
    }),
    startRegion: {
      polygonUrl: undefined,
      name: undefined,
      parking: undefined,
      population: undefined,
      trafficPass: undefined,
      workplaces: undefined,
    },
    finishRegion: {
      polygonUrl: undefined,
      name: undefined,
      parking: undefined,
      population: undefined,
      trafficPass: undefined,
      workplaces: undefined,
    },
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
