import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';
import { RestifyEntityList } from 'redux-restify';

import { AppState } from 'src/reducer';
import { Model } from 'src/interfaces';
import { isArray, isNumber } from 'src/helpers';

import {
  TypeGuard,
  AcBehaviorTypesModel,
  AcIntervalsModel,
  AcCommunicationsModel,
  AcTransportationsModel,
  AcScenariosModel,
  AcSitesModel,
  AcVehicleClassesModel,
  AcVehicleTypesModel,
  AcYearsModel,
  AcCopyRequestModel, CopyingType,
} from 'src/cluster/common';

import {
  isAcBehaviorTypesModel,
  isAcIntervalsModel,
  isAcCommunicationsModel,
  isAcCopyRequestsModel,
  isAcScenariosModel,
  isAcSitesModel,
  isAcVehicleClassesModel,
  isAcVehicleTypesModel,
  isAcYearsModel,
  isAcTransportationsModel,
} from 'src/cluster/common/type-guards';

import { createEntityListSelector, getApiConfigWithProject } from 'src/cluster/common/helpers';

import { RequestStatus } from 'src/types';
import {
  selectSiteEntities,
  selectVehicleTypeEntities,
  selectTransportationEntities,
  selectAcProjectListForm,
  selectYearEntities,
  selectScenarioEntities,
  selectIntervalEntities,
  selectBehaviorTypeEntities,
  selectVehicleClassesEntities,
  selectCommunicationEntities,
  selectCopyRequestEntities,
} from './common';

function createProjectEntityListSelector<T>(
  selectEntities: (state: AppState) => RestifyEntityList<T>,
  isValidModel: TypeGuard,
) {
  return createSelector(
    [
      selectAcProjectListForm,
      selectEntities,
    ],
    (projectsListForm, entities): Maybe<Model<T>[]> => {
      const { selectedProject } = projectsListForm;

      if (!isNumber(selectedProject)) {
        return Nothing();
      }

      const entitiesArray = entities.getArray(getApiConfigWithProject(selectedProject));

      if (!isArray(entitiesArray)) {
        return Nothing();
      }

      if (!isValidModel(entitiesArray[0])) {
        return Nothing();
      }

      return Just(entitiesArray);
    },
  );
}

export const selectCopyingProjectInProgress = createEntityListSelector<AcCopyRequestModel>(
  selectCopyRequestEntities, isAcCopyRequestsModel, {
    type: CopyingType.project,
    status: RequestStatus.IN_PROGRESS,
  },
);

export const selectAcYears = createProjectEntityListSelector<AcYearsModel>(
  selectYearEntities, isAcYearsModel,
);

export const selectAcScenarios = createProjectEntityListSelector<AcScenariosModel>(
  selectScenarioEntities, isAcScenariosModel,
);

export const selectAcIntervals = createProjectEntityListSelector<AcIntervalsModel>(
  selectIntervalEntities, isAcIntervalsModel,
);

export const selectAcBehaviorTypes = createProjectEntityListSelector<AcBehaviorTypesModel>(
  selectBehaviorTypeEntities, isAcBehaviorTypesModel,
);

export const selectAcSites = createProjectEntityListSelector<AcSitesModel>(
  selectSiteEntities, isAcSitesModel,
);

export const selectVehicleClasses = createProjectEntityListSelector<AcVehicleClassesModel>(
  selectVehicleClassesEntities, isAcVehicleClassesModel,
);

export const selectVehicleTypes = createProjectEntityListSelector<AcVehicleTypesModel>(
  selectVehicleTypeEntities, isAcVehicleTypesModel,
);

export const selectCommunicationTypes = createProjectEntityListSelector<AcCommunicationsModel>(
  selectCommunicationEntities, isAcCommunicationsModel,
);

export const selectTransportationTypes = createProjectEntityListSelector<AcTransportationsModel>(
  selectTransportationEntities, isAcTransportationsModel,
);
