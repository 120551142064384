import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import deepEqual from 'deep-equal';

import PCheckbox from 'src/components/deprecated/PCheckbox';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';

import { isDefAndNotNull } from 'src/helpers';
import EditableCell from 'src/components/etc/EditableCell';
import { INPUT_TYPES, InputTypes } from 'src/components/deprecated/PInput';
import style from './index.module.css';

const equal = (newItem: any, oldArray: any) => {
  if (isDefAndNotNull(newItem.id)) return oldArray.findIndex((item: any) => item.id === newItem.id);
  return oldArray.findIndex((item: any) => deepEqual(newItem, item));
};

export interface TableHeaderItem<T> {
  title?: React.ReactNode;
  className?: string | ((row: T, index: number, data: T[]) => string);
  show?: boolean;
  model: (row: T, index: number, data: T[]) => React.ReactNode;
  summaryModel?: React.ReactNode;
  rowSpan?: (row: T, index: number, data: T[]) => number | undefined;
  name?: string;
  sortable?: boolean;
  sort?: (a: any, b: any) => number;
  editable?: boolean | ((row: T, index: number, data: T[]) => boolean);
  error?: string | ((row: T, index: number, data: T[]) => string | undefined);
  editableClassName?: string | ((row: T, index: number, data: T[]) => string);
  always?: boolean;
  icon?: React.ReactNode;
  type?: InputTypes;
  formatted?: boolean;
  onChange?: (val: any, row: any) => void;
}

interface Props<T> {
  header: TableHeaderItem<T>[];
  selectedItems?: any[];
  body: T[];
  checking: boolean;
  onCheckedChange: (checked: any[]) => void;
  className: string;
  rowRef?: (node: any, row: T, index: number, data: T[]) => void;
  headerClassName: string;
  headerCellClassName?: string;
  tableCellClassName?: string;
  rowClassName: string | ((row: T, index: number, data: T[]) => string | boolean | (string | boolean)[]);
  rowKey?: (row: T, index: number, data: T[]) => string;
  rowExtraCell?: (row: T, index: number, data: T[]) => React.ReactNode;
  onRowClick: (row: T, index: number, data: T[]) => void;
  hoverable?: boolean;
  headerHoveredComponent?: React.ReactNode;
  hoveredComponent?: (row: T, index: number, data: T[]) => React.ReactNode;
  expandable?: boolean;
  isExpanded?: (row: T, index: number, data: T[]) => boolean;
  isNew?: (row: T, index: number, data: T[]) => boolean;
  onExpanded?: (row: T, index: number, data: T[]) => void;

  sortingColumn?: string;
  sortingOrder?: -1 | 1;
  onSort: (itemName?: string, sortingOrder?: -1 | 1) => void;
}

interface State {
  check: any[];
}

class PTable<T = any> extends PureComponent<Props<T>, State> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    header: [],
    body: [],
    checking: false,
    onCheckedChange: () => undefined,
    onRowClick: () => undefined,
    onSort: () => undefined,
    className: '',
    rowClassName: '',
    headerClassName: '',
  }

  public constructor(props: Props<T>) {
    super(props);
    this.state = {
      check: props.body.map((item, index) => {
        if (props.selectedItems) {
          return props.selectedItems.includes(this.getRowKey(item, index, props.body));
        }
        return false;
      }),
    };
    this.getCheckedCount = this.getCheckedCount.bind(this);
    this.getChecked = this.getChecked.bind(this);
    this.checkItem = this.checkItem.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.getRowKey = this.getRowKey.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
    if (nextProps.checking &&
      (this.props.body !== nextProps.body || this.props.selectedItems !== nextProps.selectedItems)
    ) {
      this.setState((state) => ({
        check: nextProps.body.map((item, index) => {
          if (nextProps.selectedItems) {
            return nextProps.selectedItems.includes(this.getRowKey(item, index, nextProps.body));
          }
          const equalIndex = equal(item, this.props.body);
          if (equalIndex > -1) return state.check[equalIndex];
          return false;
        }),
      }));
    }
  }

  private getRowKey(row: any, r: number, body: any[]) {
    return this.props.rowKey ? this.props.rowKey(row, r, body) : (row.id || r);
  }

  private getCheckedCount() {
    return this.state.check.reduce((a, b) => +a + +b, 0);
  }

  private getChecked() {
    return this.state.check
      .map((item, i) => (item ? { index: i, edgeItem: this.props.body[i] } : null))
      .filter(item => item !== null);
  }

  private checkItem(index: number) {
    this.setState((state) => {
      const check = state.check.slice();
      check[index] = !check[index];
      return {
        check,
      };
    }, () => this.props.onCheckedChange(this.getChecked()));
  }

  private checkAll() {
    const check = this.props.body.length === this.getCheckedCount();
    this.setState(
      { check: this.props.body.map(() => !check) },
      () => this.props.onCheckedChange(this.getChecked()),
    );
  }

  public render() {
    const {
      header,
      body,
      checking,
      isNew,
      className,
      rowRef,
      hoverable,
      rowExtraCell,
      hoveredComponent,
      headerHoveredComponent,
      headerClassName,
      headerCellClassName,
      tableCellClassName,
      rowClassName,
      onRowClick,
      isExpanded,
      expandable,
      sortingColumn,
      sortingOrder = 1,
      onExpanded,
      onSort,
    } = this.props;
    const { check } = this.state;
    let editableCount = 0;

    return (
      <table className={classNames(style.table, className)}>
        <thead>
          <tr className={classNames(
            headerClassName,
            style.headerRow,
            headerHoveredComponent && style.hoverableComponentRow,
          )}>
            {checking && (
              <th {...{
                padding: 'checkbox',
                component: 'th',
                className: classNames(style.checkCell, headerCellClassName, style.tableCell, tableCellClassName),
              }}>
                <PCheckbox {...{
                  value: this.getCheckedCount() === check.length,
                  onChange: this.checkAll,
                }} />
              </th>
            )}
            {header
              .filter(item => item.show !== false)
              .map((item, i, array) => {
                const handleSort = onSort ? () => onSort(item.name, sortingOrder) : () => undefined;
                const sortingButtonClassName = classNames(
                  style.sortingButton,
                  // eslint-disable-next-line no-nested-ternary
                  item.name === sortingColumn
                    ? (sortingOrder < 0 ? style.sortingButtonUp : style.sortingButtonDown)
                    : undefined,
                );
                return (
                  <th {...{
                    component: 'th',
                    key: i,
                    className: classNames(item.className, headerCellClassName, style.tableCell, tableCellClassName),
                  }}>
                    {
                      item.sortable
                        ? (
                          <div className={style.sortableHeader}>
                            {item.title || ''}
                            <button {...{
                              type: 'button',
                              className: sortingButtonClassName,
                              onClick: handleSort,
                            }} />
                          </div>
                        )
                        : item.title
                    }
                    {
                      i === array.length - 1 && headerHoveredComponent &&
                        <div className={style.hoveredComponent}>
                          {headerHoveredComponent}
                        </div>
                    }
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className={style.tbody}>
          {body
            .sort((a, b) => {
              if (!sortingColumn) {
                return 1;
              }
              const sortCol: TableHeaderItem<T> | undefined = header.find(
                (col) => col.name === sortingColumn,
              );
              if (!sortCol || !sortCol.sortable || typeof sortCol.sort !== 'function') {
                return 1;
              }
              return sortingOrder * sortCol.sort(a, b);
            })
            .map((row, r) => {
              const additionalClassname = typeof rowClassName === 'function'
                ? rowClassName(row, r, body)
                : rowClassName;
              let arrayClassname = Array.isArray(additionalClassname)
                ? additionalClassname
                : [additionalClassname];
              arrayClassname = arrayClassname.map(item => {
                return item === true ? style.selectedRow : item;
              });
              const extraCell = rowExtraCell && rowExtraCell(row, r, body);
              return (
                <Fragment key={this.getRowKey(row, r, body)}>
                  <tr {...{
                    onClick: () => onRowClick(row, r, body),
                    ref: (node) => {
                      if (rowRef) {
                        rowRef(node, row, r, body);
                      }
                    },
                    className: classNames(
                      style.tableRow,
                      (hoveredComponent || expandable) && style.hoverableComponentRow,
                      hoverable && style.hoverableRow,
                      ...arrayClassname,
                    ),
                  }}>
                    {checking && (
                      <td className={classNames(style.checkCell, style.tableCell, tableCellClassName)}>
                        <PCheckbox {...{
                          value: check[r],
                          onChange: () => this.checkItem(r),
                        }} />
                      </td>
                    )}
                    {header
                      .filter(item => item.show !== false)
                      .map((item, i, array) => {
                        let rowSpan;
                        if (item.rowSpan) {
                          rowSpan = item.rowSpan(row, r, body);
                          if (!rowSpan) return null;
                        }
                        const itemClassName = typeof item.className === 'function'
                          ? item.className(row, r, body)
                          : item.className;
                        const editableClassName = typeof item.editableClassName === 'function'
                          ? item.editableClassName(row, r, body)
                          : item.editableClassName;
                        const currentIsExpanded = isExpanded && isExpanded(row, r, body);
                        const currentIsNew = isNew && isNew(row, r, body);
                        const editable = !item.editable || typeof item.editable === 'boolean'
                          ? item.editable
                          : item.editable(row, r, body);

                        if (editable) editableCount += 1;

                        const error = !item.error || typeof item.error === 'string'
                          ? item.error
                          : item.error(row, r, body);

                        return (
                          <td {...{
                            key: i,
                            className: classNames(itemClassName, style.tableCell, tableCellClassName),
                            rowSpan,
                          }}>
                            {editable ? (
                              <EditableCell {...{
                                className: editableClassName,
                                type: item.type || INPUT_TYPES.text,
                                formatted: item.formatted,
                                node: item.model(row, r, body),
                                row,
                                icon: item.icon,
                                always: item.always,
                                error,
                                autoFocus: editableCount === 1,
                                onChange: item.onChange,
                              }} />
                            ) : item.model(row, r, body)}

                            {i === 0 && expandable && onExpanded && (
                              <div
                                className={
                                  currentIsExpanded
                                    ? style.expandedComponent
                                    : style.expandedComponentHidden
                                }
                              >
                                <PIcon {...{
                                  type: ICONS_TYPES.arrow,
                                  rotate: currentIsExpanded ? ROTATE_TYPES.down : ROTATE_TYPES.up,
                                  className: style.expandIcon,
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    onExpanded(row, r, body);
                                  },
                                }} />
                              </div>
                            )}

                            {i === 0 && currentIsNew && (
                              <div className={style.expandedComponent}>
                                <PIcon {...{
                                  type: ICONS_TYPES.newLabel,
                                  className: style.newIcon,
                                }} />
                              </div>
                            )}

                            {i === array.length - 1 && hoveredComponent && (
                              <div className={style.hoveredComponent}>
                                {hoveredComponent(row, r, body)}
                              </div>
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  {extraCell && (
                    <tr>
                      <td className={style.extraCell} colSpan={header.length + (checking ? 1 : 0)}>
                        {extraCell}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          {header.some(item => item.summaryModel) && (
            <tr {...{
              className: classNames(
                style.tableRow,
                style.summaryRow,
              ),
            }}>
              {header.filter(item => item.show !== false).map((item, i) => {
                return (
                  <td {...{
                    key: i,
                    className: classNames(style.tableCell, tableCellClassName, item.className),
                  }}>
                    {item.summaryModel}
                  </td>
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default PTable;
