import { AppState } from 'src/reducer';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';

import { selectDistGraphForm } from 'src/cluster/distribution-common';
import { selectPageForm } from 'src/cluster/distribution-map';

import DistributionMapPopup from './DistributionMapPopup';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectPageForm(state),
  graphForm: selectDistGraphForm(state),
});

export default connect(mapStateToProps)(withTranslate(DistributionMapPopup));
