/* eslint-disable max-len */
import React from 'react';

const Funnel = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M447.9,240.6L303.4,416c-9.2,11-14.2,24.9-14.3,39.2v41.1c0,8.7-7,15.7-15.7,15.7h-35.7c-8.7,0-15.7-7-15.7-15.7V455  c0-14.3-5-28.1-14.2-39.1L64.4,240.8c-3.7-6.2-5.7-13.4-5.7-20.6c0-37.6,49.5-57.5,104.5-67.5c-1.6,5.2-3,10.1-4.4,15.7  c-2.8,9.4-3,19.4-0.7,29c-31.4,6.9-51.2,16.2-56.1,22.9c8.9,12.1,65.9,32.6,154.1,32.6s145.3-20.5,154.1-32.6  c-5.2-7.4-29.3-18.1-67.3-25.1c7.4-12.5,11.7-26.6,12.4-41.2c52.3,10.2,98,30.1,98,66.2C453.4,227.3,451.5,234.4,447.9,240.6z   M261.8,161.9c-4,1.7-8.4,2.6-12.7,2.6c-1.6,0-3.1-0.1-4.7-0.3c-10-1.1-19.8-4-28.8-8.6c-2.1-1.3-4.6-2-7.1-2.2c-6.2,0-8.1,6.1-9,9  c-1.6,5.2-3,10.5-4.6,16c-2.6,9.5-0.8,13.5,8.2,17.8l0,0c9.1,4.2,18.8,7,28.8,8.3l3.5,0.6c0,1,0,3.1,0,3.8v10.5  c-0.4,6.1,4.1,11.4,10.2,11.8c0.4,0,0.8,0,1.2,0h7.3h8.4c5.7,0.5,10.8-3.7,11.3-9.5c0.1-0.8,0.1-1.5,0-2.3c0-1.4,0-2.7,0-4.1v-0.9  c0-3.1,0-6.3,0-9.5c0-2.2,0-2.9,3-3.7c11.5-3,21.8-9.6,29.4-18.9c9.9-12,14-27.8,11.1-43.1c-3.2-16.3-13.6-30.3-28.2-38.1  c-7.9-4.2-16.1-7.9-24.4-11.2l-3.4-1.1c-4.9-1.9-9.5-4.4-13.7-7.6c-2.5-1.9-3.9-4.9-3.6-8c0.6-3.2,2.9-5.8,6-6.8  c2.4-0.9,4.9-1.4,7.4-1.5h3.8c9.7,0,19.3,2.2,28.1,6.4c2.1,1.1,4.4,1.7,6.8,1.8c1.1,0,2.2-0.2,3.2-0.6c3-1.7,5-4.6,5.7-7.9  c1.4-4.3,2.6-8.7,3.9-13l1.5-5.2c2.1-5.7-0.9-12-6.6-14.1c-0.1,0-0.1,0-0.2-0.1c-7.3-3.2-14.9-5.5-22.8-6.8  c-5.7-0.9-5.7-0.9-5.7-6.6C275,3,273.5,0,257.9,0H250c-9.1,0.3-12.2,3.4-12.5,12.6c0,1.9,0,3.9,0,5.8v3.1c0,5.2,0,5.2-5.2,7.1  c-23.3,8.4-35.7,24.3-37.1,47c-1.2,19.6,7.7,34.9,27.3,46.6c7.1,4.1,14.5,7.6,22.1,10.5c3.2,1.3,6.4,2.6,9.6,4  c3.7,1.5,7.3,3.5,10.5,6c5.1,2.8,6.9,9.1,4.2,14.2C267.3,159.4,264.8,161.3,261.8,161.9z" />
  </svg>
);

export default Funnel;
