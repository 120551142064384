import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  createFormSelector,
  isArray,
  isNumber,
  isUndefined,
} from 'src/helpers';

import {
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  TTC_SITES_MODEL_NAME,
  TTC_REGIONS_MODEL_NAME,
  TTC_CALC_PAGE_FORM_NAME,
  AcScenariosModel,
  TtcCalcPageForm,
  TtcSitesModel,
  TtcRegionsModel,
  isAcScenariosModel,
  isTtcSitesModel,
  isTtcRegionsModel,
} from 'src/cluster/common';

import { selectMatrixForecastParams, selectAcProjectListForm } from 'src/cluster/common/store/selectors';

export const selectScenarioEntities = createEntitySelector<AcScenariosModel>(AC_SCENARIOS_MODEL_NAME);
export const selectTtcSitesEntities = createEntitySelector<TtcSitesModel>(TTC_SITES_MODEL_NAME);
export const selectTtcRegionsEntities = createEntitySelector<TtcRegionsModel>(TTC_REGIONS_MODEL_NAME);

export const selectTtcPageForm = createFormSelector<TtcCalcPageForm>(TTC_CALC_PAGE_FORM_NAME);

export const selectTtcSitesResults = createSelector(
  [
    selectAcProjectListForm,
    selectTtcPageForm,
    selectTtcSitesEntities,
    selectMatrixForecastParams,
  ],
  (
    projectListForm,
    ttcPageForm,
    ttcSitesEntities,
    matrixForecastParams,
  ): [Maybe<Model<TtcSitesModel>[]>, number] => {
    const { selectedProject } = projectListForm;
    const {
      year,
      scenarioId,
      intervalId,
      siteFromId,
      siteToId,
      pageSize,
      currentPage,
    } = ttcPageForm;
    const [forecastId] = matrixForecastParams;

    if (
      !isNumber(selectedProject)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || !isNumber(intervalId)
      || !isNumber(forecastId)
    ) {
      return [Nothing(), 0];
    }

    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        siteFromId,
        siteToId,
      },
    };

    const ttcResults = ttcSitesEntities.getArray(apiConfig);

    if (!isArray(ttcResults) || !ttcResults.length) {
      return [Nothing(), 0];
    }

    if (!isTtcSitesModel(ttcResults[0])) {
      return [Nothing(), 0];
    }

    const count = ttcSitesEntities.getCount(apiConfig);

    return [Just(ttcResults), count];
  },
);

export const selectTtcRegionsResults = createSelector(
  [
    selectAcProjectListForm,
    selectTtcPageForm,
    selectTtcRegionsEntities,
    selectMatrixForecastParams,
  ],
  (
    projectListForm,
    ttcPageForm,
    ttcRegionsEntities,
    matrixForecastParams,
  ): [Maybe<Model<TtcRegionsModel>[]>, number] => {
    const { selectedProject } = projectListForm;
    const {
      year,
      scenarioId,
      intervalId,
      regionFromId,
      regionToId,
      pageSize,
      currentPage,
    } = ttcPageForm;
    const [forecastId] = matrixForecastParams;

    if (
      !isNumber(selectedProject)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || !isNumber(intervalId)
      || !isNumber(forecastId)
    ) return [Nothing(), 0];

    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        regionFromId,
        regionToId,
      },
    };

    const ttcResults = ttcRegionsEntities.getArray(apiConfig);

    if (!isArray(ttcResults) || !ttcResults.length) {
      return [Nothing(), 0];
    }

    if (!isTtcRegionsModel(ttcResults[0])) {
      return [Nothing(), 0];
    }

    const count = ttcRegionsEntities.getCount(apiConfig);

    return [Just(ttcResults), count];
  },
);

export const selectTtcScenarios = createSelector(
  [
    selectAcProjectListForm,
    selectTtcPageForm,
    selectScenarioEntities,
  ],
  (projectsListForm, ttcPageForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = ttcPageForm;

    if (!isNumber(selectedProject) || !isNumber(year)) return Nothing();

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios) || !isAcScenariosModel(scenarios[0])) return Nothing();

    return Just(scenarios);
  },
);
