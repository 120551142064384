import React, { useEffect, Fragment, FC } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { api, forms } from 'redux-restify';
import { LinearProgress } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { useRestifyForm } from 'src/helpers';
import { useSystemsContext } from 'src/constants';

import { FileInput, Button } from 'src/components';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import ErrorBox from 'src/components/etc/ErrorBox';

import { PROJECTS_ENDPOINTS, getProjectFormName } from '../../constants';
import { ProjectForm } from '../../forms/baseEditProject.form';

import actions from '../../actions';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const ProjectCreator: FC<Props> = (props: Props) => {
  const { className, t } = props;
  const currentSystem = useSystemsContext();
  const projectFormName = getProjectFormName(currentSystem);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions[currentSystem].createProjectForm());
    return () => {
      dispatch(forms.actions.deleteForm(projectFormName));
    };
  }, []);

  const [projectForm, projectFormActions] = useRestifyForm<ProjectForm>(projectFormName);
  const isLoading = useSelector(api.selectors.loadsManager.getIsUploadingUrl(PROJECTS_ENDPOINTS[currentSystem]));
  const progressValue = useSelector(api.selectors.loadsManager.getLoadingProgress(PROJECTS_ENDPOINTS[currentSystem]));

  // Wait, while project form is created
  if (!projectForm) return null;

  const handleFileChange = (files: FileList | null) => {
    const currentFile = files && files[0];
    dispatch(projectFormActions.changeField('file', currentFile));
    dispatch(projectFormActions.resetField('errors'));
    if (currentFile) {
      dispatch(projectFormActions.changeField('name', currentFile.name.split('.').slice(0, -1).join('')));
    }
  };

  const handleClearClick = () => dispatch(projectFormActions.resetField('file'));

  const handleSaveClick = () => {
    (dispatch(actions[currentSystem].saveProject()) as any).then(() => {
      dispatch(projectFormActions.resetField('errors'));
      dispatch(projectFormActions.resetField('file'));
    });
  };

  return (
    <div className={classNames(style.root, className)}>
      {!projectForm.file && (
        <Fragment>
          <div className={style.dragFileMessage}>
            <div>{t('modules.projects.captions.dragFileMessagePart1')}</div>
            <div>{t('modules.projects.captions.dragFileMessagePart2')}</div>
          </div>
          <div className={style.fileFormatMessage}>
            {t('modules.projects.captions.fileFormatMessage')}
          </div>
        </Fragment>
      )}
      {projectForm.file && (
        <Fragment>
          <ErrorBox className={projectForm.errors ? style.error : style.hiddenError}>
            {projectForm.errors}
          </ErrorBox>
          <div className={style.fileName}>
            <div>{projectForm.file.name}</div>
            {!projectForm.errors && (
              <PIcon {...{
                type: ICONS_TYPES.clear,
                className: style.clearFileIcon,
                onClick: handleClearClick,
              }} />
            )}
          </div>
          <div className={isLoading ? style.progressBar : style.progressBarHidden}>
            <LinearProgress variant="determinate" value={progressValue} />
          </div>
        </Fragment>
      )}
      {(!projectForm.file || projectForm.errors) && (
        <FileInput
          className={style.button}
          useProgressAsLabel={false}
          useFileNameAsLabel={false}
          label={t('modules.projects.captions.browse')}
          selectedFiles={projectForm.file ? [projectForm.file] : []}
          isUploading={isLoading}
          loadingProgress={progressValue}
          accept=".xlsx"
          onChange={handleFileChange}
        />
      )}
      {projectForm.file && !projectForm.errors && (
        <Button
          className={style.button}
          onClick={handleSaveClick}
        >
          {t('modules.projects.captions.upload')}
        </Button>
      )}
    </div>
  );
};

export default ProjectCreator;
