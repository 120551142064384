import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  locales: {
    marginLeft: spacing(4),
    display: 'flex',
  },

  locale: {
    '&:not(:last-child):after': {
      content: '"/"',
      margin: '0 2px',
    },
  },

  localeIcon: {
    width: 20,
    height: 20,
  },
}));
