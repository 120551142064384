import React from 'react';

export const NAME = 'auth';

export const LOGIN_PAGE_URL = '/login';
export const RECOVERY_PAGE_URL = '/recovery';
export const NEW_PROJECT_PAGE_URL = '/freight';
export const FREIGHT_PAGE_URL = '/freight/projects';

export const defaultAuthManagerContext = {
};

export const AuthManagerContext = React.createContext(defaultAuthManagerContext);
