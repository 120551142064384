import { ModelDefaults } from 'src/types';
import { AC_PROJECTS_MODEL_NAME, AcVehicleTypesModel, VEHICLE_TYPES_ENDPOINT } from 'src/cluster/common';

const defaults: ModelDefaults<AcVehicleTypesModel> = {
  id: undefined,
  name: undefined,
  isPublic: undefined,
  hasRoutes: undefined,
};

export default {
  endpoint: VEHICLE_TYPES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults,
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
