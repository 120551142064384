import React from 'react';

const Agglomeration = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="3" transform="rotate(-90 10 10)" fill="none" stroke="currentColor" strokeWidth="3" />
      <path d="M12 8L17.25 2.75L19 1" stroke="currentColor" strokeWidth="2" />
      <path d="M8 8L1 1" stroke="currentColor" strokeWidth="2" />
      <path d="M10 13V21" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default Agglomeration;
