import { ThunkActionResult } from 'src/reducer';
import { forms } from 'redux-restify';

import { Systems } from 'src/constants';
import { ROUTES_PAGES_FORM_NAMES } from 'src/modules/shipments/routes';
import { CALCULATIONS_PAGES_FORM_NAMES } from 'src/modules/shipments/calculations';

export function onShipmentProjectChange(): ThunkActionResult<any> {
  return (dispatch) => {
    dispatch(forms.actions[CALCULATIONS_PAGES_FORM_NAMES[Systems.shipments]].resetForm());
    dispatch(forms.actions[ROUTES_PAGES_FORM_NAMES[Systems.shipments]].resetForm());
  };
}
