import React from 'react';

const MinusIcon = () => (
  <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1881 3.15339C13.3807 3.15339 13.5383 2.93087 13.5383 2.65889V1.34021C13.5383 1.06823 13.3807 0.845703 13.1881 0.845703H0.811701C0.619049 0.845703 0.461426 1.06823 0.461426 1.34021V2.65889C0.461426 2.93087 0.619049 3.15339 0.811701 3.15339H13.1881Z"
      fill="currentColor"
    />
  </svg>
);

export default MinusIcon;
