import React, { FC } from 'react';
import clsx from 'clsx';
import { Button, Submit } from 'src/components';
import { WithTranslate } from 'src/i18n';

import useStyles from './styles';

interface OwnProps {
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  cancelCaption?: string;
  submitCaption?: string;
  onCancel(): void;
  onSubmit(): void;
}

type Props = OwnProps & WithTranslate;

const SubmitButtons: FC<Props> = (props) => {
  const {
    className,
    isLoading = false,
    disabled = false,
    cancelCaption,
    submitCaption,
    t,
    onCancel,
    onSubmit,
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        color="primary"
        variant="outlined"
        fullWidth
        onClick={onCancel}
      >
        {cancelCaption || t('common.captions.cancel')}
      </Button>
      <Submit
        color="primary"
        variant="contained"
        fullWidth
        isLoading={isLoading}
        disabled={disabled}
        onClick={onSubmit}
      >
        {submitCaption || t('common.captions.save')}
      </Submit>
    </div>
  );
};

export default SubmitButtons;
