import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { AcRegRoutesResultsModel, saveRegistryRoute } from 'src/cluster/editor-table';

import RegistryModalRouteEditing from './RegistryModalRouteEditing';

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  onSaveRoute: (route: AcRegRoutesResultsModel) => dispatch(saveRegistryRoute(route)),
  onCloseModal: () => dispatch(closeAction),
});

export default connect(null, mapDispatchToProps)(withTranslate(RegistryModalRouteEditing));
