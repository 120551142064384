import React, { FC } from 'react';
import classNames from 'classnames';
import { RestifyId } from 'redux-restify';

import { WithModalProps } from 'src/modals';
import { Systems } from 'src/constants';
import AggloCalcMap from '../AggloCalcMap';

import style from './index.module.css';

export interface CalculationMapModalProps {
  calculationId: RestifyId;
  system: Systems;
}

interface Props extends CalculationMapModalProps, WithModalProps {
  className?: string;
}

const ModalAggloCalcMap: FC<Props> = ({
  className,
  calculationId,
}) => {
  return (
    <div className={classNames(style.root, className)}>
      <AggloCalcMap {...{
        className: style.map,
        calculationId,
      }} />
    </div>
  );
};

export default ModalAggloCalcMap;
