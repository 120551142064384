import React from 'react';

const BusIcon = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.56055 8.17383H5.56055V7.17383H4.56055V8.17383ZM5.06055 0.173828C4.40394 0.173828 3.75376 0.303157 3.14713 0.55443C2.5405 0.805704 1.98931 1.174 1.52501 1.63829C0.587331 2.57598 0.0605469 3.84775 0.0605469 5.17383C0.0605469 6.49991 0.587331 7.77168 1.52501 8.70936C1.98931 9.17365 2.5405 9.54195 3.14713 9.79323C3.75376 10.0445 4.40394 10.1738 5.06055 10.1738C6.38663 10.1738 7.6584 9.64704 8.59608 8.70936C9.53376 7.77168 10.0605 6.49991 10.0605 5.17383C10.0605 4.51722 9.93122 3.86704 9.67994 3.26041C9.42867 2.65378 9.06037 2.10259 8.59608 1.63829C8.13179 1.174 7.58059 0.805704 6.97396 0.55443C6.36734 0.303157 5.71716 0.173828 5.06055 0.173828ZM5.06055 9.17383C2.85555 9.17383 1.06055 7.37883 1.06055 5.17383C1.06055 2.96883 2.85555 1.17383 5.06055 1.17383C7.26555 1.17383 9.06055 2.96883 9.06055 5.17383C9.06055 7.37883 7.26555 9.17383 5.06055 9.17383ZM5.06055 2.17383C4.53011 2.17383 4.02141 2.38454 3.64633 2.75961C3.27126 3.13469 3.06055 3.6434 3.06055 4.17383H4.06055C4.06055 3.90861 4.1659 3.65426 4.35344 3.46672C4.54098 3.27919 4.79533 3.17383 5.06055 3.17383C5.32576 3.17383 5.58012 3.27919 5.76765 3.46672C5.95519 3.65426 6.06055 3.90861 6.06055 4.17383C6.06055 5.17383 4.56055 5.04883 4.56055 6.67383H5.56055C5.56055 5.54883 7.06055 5.42383 7.06055 4.17383C7.06055 3.6434 6.84983 3.13469 6.47476 2.75961C6.09969 2.38454 5.59098 2.17383 5.06055 2.17383Z"
      fill="currentColor"
    />
  </svg>
);

export default BusIcon;
