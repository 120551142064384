import React, {
  ReactElement, RefObject, useCallback, useEffect,
} from 'react';
import classNames from 'classnames';
import ReactMapGL, { PointerEvent } from 'react-map-gl';
import { WebMercatorViewportOptions } from 'viewport-mercator-project';

import MapZoom from '../MapZoom';
import MapLegend from '../MapLegend';

import style from './index.module.css';

interface Props {
  mapRef: RefObject<ReactMapGL>;
  children?: ReactElement | (ReactElement | undefined | null | false)[];
  className?: string;
  mapStyle?: object;
  interactiveLayerIds?: string[];
  controls?: ReactElement | ReactElement[];
  legend?: ReactElement | ReactElement[];
  controlsPosition?: 'top' | 'bottom';
  viewport: WebMercatorViewportOptions;
  setViewport: (viewport: WebMercatorViewportOptions) => void;
  onSourceData?: (isLoading: boolean) => void;
  onMouseMove?: (e: PointerEvent) => void;
  onClick?: (e: PointerEvent) => void;
}

const Map: React.FC<Props> = (props: Props) => {
  const {
    mapRef,
    children,
    className,
    mapStyle,
    interactiveLayerIds,
    controls,
    legend,
    controlsPosition = 'bottom',
    viewport,
    setViewport,
    onSourceData,
    onMouseMove,
    onClick,
  } = props;

  const map = mapRef.current?.getMap();

  const handleSourceData = useCallback((e) => {
    if (typeof onSourceData === 'function') {
      onSourceData(!e.isSourceLoaded);
    }
  }, [onSourceData]);

  useEffect(() => {
    if (map) {
      map.on('sourcedata', handleSourceData);
    }
    return () => {
      if (map) {
        map.off('sourcedata', handleSourceData);
      }
    };
  }, [map]);

  const zoomIn = useCallback(() => {
    if (viewport.zoom && viewport.zoom <= 20) {
      setViewport({
        ...viewport,
        zoom: viewport.zoom + 1,
      });
    }
  }, [viewport, setViewport]);

  const zoomOut = useCallback(() => {
    if (viewport.zoom && viewport.zoom >= 2) {
      setViewport({
        ...viewport,
        zoom: viewport.zoom - 1,
      });
    }
  }, [viewport, setViewport]);

  const handleViewportChange = useCallback((newViewport): void => {
    setViewport(newViewport);
  }, []);

  const handleLoad = useCallback((event) => event.target.resize(), []);

  return (
    <div className={classNames(style.root, className)}>
      <ReactMapGL
        height="100%"
        width="100%"
        interactiveLayerIds={interactiveLayerIds}
        ref={mapRef}
        mapStyle={mapStyle}
        {...viewport}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onViewportChange={handleViewportChange}
        onLoad={handleLoad}
      >
        {children}
      </ReactMapGL>
      <div className={classNames(style.controls, controlsPosition === 'top' && style.controlsTop)}>
        {controls}
        {legend && (
          <MapLegend
            className={style.control}
            content={legend}
            position={controlsPosition}
          />
        )}
        <MapZoom
          className={style.control}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
        />
      </div>
    </div>
  );
};

export default Map;
