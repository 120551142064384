import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { AcPageBalanceShapeForm, AcProjectForm, AC_PROJECT_LIST_FORM_NAME } from 'src/cluster/common';

import { AC_SHAPES_PAGE_FORM_NAME, selectAcShapesFactors, selectBalanceShapes } from 'src/cluster/shapes';
import BalanceShapesPage from './BalanceShapesPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: forms.selectors[AC_SHAPES_PAGE_FORM_NAME].getForm<AcPageBalanceShapeForm>(state),
  projectForm: forms.selectors[AC_PROJECT_LIST_FORM_NAME].getForm<AcProjectForm>(state),
  entities: selectBalanceShapes(state),
  factors: selectAcShapesFactors(state),
});

export default connect(mapStateToProps)(withTranslate(BalanceShapesPage));
