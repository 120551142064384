import { useEffect } from 'react';
import { LinearInterpolator } from 'react-map-gl';

import { Nullable } from 'src/types';
import { Position } from 'src/interfaces';
import { WebMercatorViewportOptions } from 'viewport-mercator-project';

interface SetViewportProps {
  (currentViewport: (currentViewport: any) => any): void;
}

const useMapFittedToPosition = (
  position: Nullable<Position>,
  canBePositioned: boolean,
  viewport: WebMercatorViewportOptions,
  setViewport: SetViewportProps,
) => {
  const hasSizes = viewport.height && viewport.width;
  useEffect(() => {
    if (!hasSizes || !position || !position.length || !canBePositioned) return;

    const [longitude, latitude] = position;
    try {
      setViewport(currentViewport => ({
        ...currentViewport,
        longitude,
        latitude,
        zoom: 13,
        transitionInterpolator: new LinearInterpolator(['longitude', 'latitude', 'zoom']),
        transitionDuration: 1000,
      }));
    } catch (e) {
      console.error('Error fitting map to bounds', e);
    }
  }, [
    hasSizes,
    JSON.stringify(position),
    canBePositioned,
  ]);
};

export default useMapFittedToPosition;
