import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ChangeEvent } from 'react';

import { withTranslate } from 'src/i18n';
import { isNull, isNumber, isUndefined } from 'src/helpers';

import { balanceTtcFormActions } from 'src/cluster/ttc';

import { VIEW_MODES } from 'src/cluster/common';
import { OwnProps } from './interfaces';

import BalanceTtcToolbar from './BalanceTtcToolbar';

const mapDispatchToProps = (dispatch: Dispatch<any>, { resetAll }: OwnProps) => ({
  switchToEdit: () => {
    dispatch(balanceTtcFormActions.changeField('isEdit', true));
  },
  switchToList: () => {
    resetAll();
    dispatch(balanceTtcFormActions.changeField('isEdit', false));
  },
  switchToMap: () => {
    dispatch(balanceTtcFormActions.changeField('viewMode', VIEW_MODES.map));
  },
  resetPageForm: () => {
    dispatch(balanceTtcFormActions.resetFields(['regionFrom', 'regionTo', 'municipalityFrom', 'municipalityTo']));
  },
  setFilterModeFrom: (event: ChangeEvent<{ value: number }>) => {
    if (!isUndefined(event.target)) {
      dispatch(balanceTtcFormActions.changeField('filterModeFrom', event.target.value));
    }
  },
  setFilterModeTo: (event: ChangeEvent<{ value: number }>) => {
    if (!isUndefined(event.target)) {
      dispatch(balanceTtcFormActions.changeField('filterModeTo', event.target.value));
    }
  },
  setRegionFrom: (values: number[]) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    if (!isNull(values)) {
      dispatch(balanceTtcFormActions.changeField('regionFrom', values));
    }
  },
  setRegionTo: (values: number[]) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    if (!isNull(values)) {
      dispatch(balanceTtcFormActions.changeField('regionTo', values));
    }
  },
  setMunicipalityFrom: (values: number[]) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    dispatch(balanceTtcFormActions.changeField('municipalityFrom', values));
  },
  setMunicipalityTo: (values: number[]) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    dispatch(balanceTtcFormActions.changeField('municipalityTo', values));
  },
  setYear: (event: ChangeEvent<{ value: number }>) => {
    dispatch(balanceTtcFormActions.resetFields(['scenario']));
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    if (!isUndefined(event.target)) {
      dispatch(balanceTtcFormActions.changeField('year', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(balanceTtcFormActions.changeField('year', event));
    }
  },
  setScenario: (event: ChangeEvent<{ value: number }>) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    if (!isUndefined(event.target)) {
      dispatch(balanceTtcFormActions.changeField('scenario', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(balanceTtcFormActions.changeField('scenario', event));
    }
  },
  setBehaviorType: (event: ChangeEvent<{ value: number }>) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    if (!isUndefined(event.target)) {
      dispatch(balanceTtcFormActions.changeField('behaviorType', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(balanceTtcFormActions.changeField('behaviorType', event));
    }
  },
  setInterval: (event: ChangeEvent<{ value: number }>) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', 1));
    if (!isUndefined(event.target)) {
      dispatch(balanceTtcFormActions.changeField('interval', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(balanceTtcFormActions.changeField('interval', event));
    }
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(BalanceTtcToolbar));
