import { isNumber, isObject, isString } from 'src/helpers';
import {
  AcDistDirectionEdgesModel,
  AcDistRoutesDayModel,
  AcDistRoutesModel,
  AcDistStopsModel,
  AcRoutesVariantsModel,
} from 'src/cluster/distribution-table/interfaces';

export function isAcDistRoutesModel(record: unknown): record is AcDistRoutesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    vehicleTypeId,
    variantNumber,
    variantName,
  } = record as Partial<AcDistRoutesModel>;

  const isValidModel = isNumber(id)
    && isNumber(vehicleTypeId)
    && isString(variantNumber)
    && isString(variantName);

  if (!isValidModel) {
    console.warn('The record is not a valid AcDistRoutesModel.');
  }

  return isValidModel;
}

export function isAcDistRoutesDayModel(record: unknown): record is AcDistRoutesDayModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    vehicleTypeId,
    variantNumber,
    variantName,
  } = record as Partial<AcDistRoutesDayModel>;

  const isValidModel = isNumber(id)
    && isNumber(vehicleTypeId)
    && isString(variantNumber)
    && isString(variantName);

  if (!isValidModel) {
    console.warn('The record is not a valid AcDistRoutesDayModel.');
  }

  return isValidModel;
}

export function isAcDistDirectionEdgeModel(record: unknown): record is AcDistDirectionEdgesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    firstStopName,
    lastStopName,
  } = record as Partial<AcDistDirectionEdgesModel>;

  const isValidModel = isNumber(id)
    && isString(firstStopName)
    && isString(lastStopName);

  if (!isValidModel) {
    console.warn('The record is not a valid AcDistDirectionEdgesModel.');
  }

  return isValidModel;
}

export function isAcDistStopsModel(record: unknown): record is AcDistStopsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    passengerTraffic,
    transfer,
  } = record as Partial<AcDistStopsModel>;

  const isValidModel = isNumber(id)
    && isNumber(passengerTraffic)
    && isNumber(transfer);

  if (!isValidModel) {
    console.warn('The record is not a valid AcDistStopsModel.');
  }

  return isValidModel;
}

export function isAcRoutesVariantsModel(record: unknown): record is AcRoutesVariantsModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    variantName,
    variantNumber,
  } = record as Partial<AcRoutesVariantsModel>;

  const isValidModel = isNumber(id)
    && isString(variantName)
    && isString(variantNumber);

  if (!isValidModel) {
    console.warn('The record is not a valid AcRoutesVariantsModel.');
  }

  return isValidModel;
}
