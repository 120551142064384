/* eslint-disable max-len */
import React from 'react';

import logo from 'src/assets/images/logo.svg';

const Logo = () => (
  <img src={logo} alt="logo" />
);

export default Logo;
