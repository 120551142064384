import { Systems } from 'src/constants';
import { StrictDict } from 'src/types';
import { getCalculationsActions } from './actions';

const actions: StrictDict<Systems, ReturnType<typeof getCalculationsActions>> =
  Object.values(Systems).reduce((memo, value) => ({
    ...memo,
    [value]: getCalculationsActions(value),
  }), {} as any);

export default actions;
