import React from 'react';
import classNames from 'classnames';

import AggloCalcToolbar from '../AggloCalcToolbar';
import AggloCalcTable from '../AggloCalcTable';

import style from './index.module.css';

interface Props {
  className?: string;
}

const AggloCalcPage: React.FC<Props> = ({
  className,
}) => (
  <div className={classNames(style.root, className)}>
    <AggloCalcToolbar />
    <AggloCalcTable />
  </div>
);

export default AggloCalcPage;
