import React, { PureComponent } from 'react';
import classNames from 'classnames';
import deepEqual from 'deep-equal';

import i18n from 'src/i18n';

import PLabel from 'src/components/deprecated/PLabel';

import { SELECT_TYPES, ERROR_TYPES } from './constants';
import DropDown, { DropDownProps } from './components/DropDown';
import { DropDownValue } from './components/DropDown/constants';

import style from './index.module.css';

export interface SelectProps extends Partial<DropDownProps> {
  className?: string;
  labelClassName?: string;
  type: string;
  values: DropDownValue[];
  disabled?: boolean;
  validate: {
    required?: boolean;
    checkOnBlur?: boolean;
  };
  errors: any[];
  showTextErrors: boolean;
  label?: any;
  isClearable?: boolean;

  onInvalid: Function;
  onValid: Function;
}

interface State {
  wasBlur: boolean;
}

type Props = SelectProps

/**
 * @deprecated  Use <SelectField> instead.
 */
class PSelect extends PureComponent<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    type: SELECT_TYPES.dropdown,
    values: [],

    errors: [],
    validate: {},
    showTextErrors: true,

    onValid: () => undefined,
    onInvalid: () => undefined,
  }

  public constructor(props: Props) {
    super(props);
    this.state = {
      wasBlur: false,
    };
    this.handleValidate = this.handleValidate.bind(this);
    this.toggleBlur = this.toggleBlur.bind(this);
    this.getSelectComponent = this.getSelectComponent.bind(this);
  }

  public componentDidMount() {
    this.handleValidate(this.props.values);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.values, this.props.values)) {
      this.handleValidate(this.props.values);
    }
  }

  private getSelectComponent() {
    const {
      type,
      isClearable,
    } = this.props;

    const generalProps = {
      ...this.props,
      className: undefined,
      onBlur: this.toggleBlur,
      isClearable,
      onFocus: () => this.toggleBlur(false),
    };

    switch (type) {
      case SELECT_TYPES.filterDropdown:
        /* @ts-ignore */ // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        return <DropDown {...generalProps} showSearch />;
      default:
        /* @ts-ignore */ // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        return <DropDown {...generalProps} />;
    }
  }

  private handleValidate(values: DropDownValue[]) {
    const {
      validate: {
        required,
      },
      disabled,
      onValid,
      onInvalid,
    } = this.props;

    if (!disabled) {
      const errors = required && !values.length ? [i18n.t(ERROR_TYPES.required)] : [];

      if (errors.length) {
        onInvalid(errors);
      } else {
        onValid();
      }
    }
  }

  private toggleBlur(wasBlur = true) {
    this.setState({ wasBlur });
  }

  public render() {
    const {
      className,

      labelClassName,
      label,
      errors,
      validate,
      showTextErrors,
    } = this.props;

    const currentErrors = validate.checkOnBlur && !this.state.wasBlur ? [] : errors;
    const hasErrors = !!currentErrors.length;

    return (
      <div className={className}>
        <div className={style.root}>
          {!!label && (
            <PLabel
              className={classNames(labelClassName, style.label)}
              required={validate.required}
              label={label}
            />
          )}
          {this.getSelectComponent()}
          {showTextErrors && hasErrors && (
            <div className={style.errors}>
              {currentErrors.map((error, index) => (
                <div className={style.errorText} key={index}>
                  {error}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PSelect;
