import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';

import { selectEditorCopyingNetworkScenariosByYear } from 'src/cluster/editor-common';

const useEditorCopyNetworkScenarioOptions = (): SelectMenuOption[] => {
  const scenarios = useSelector(selectEditorCopyingNetworkScenariosByYear);
  return scenarios.orJust([]).map((scenario) => ({
    id: `${scenario.id}`,
    value: scenario.id,
    label: scenario.name,
  }));
};

export default useEditorCopyNetworkScenarioOptions;
