import React from 'react';

const TrafficNodeSea = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" transform="rotate(-90 11 11)" fill="white" stroke="#1A66B7" strokeWidth="2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5704 16.4348C9.94563 16.3201 9.36282 16.026 8.89641 15.5823C8.28429 14.9999 7.91957 14.2045 7.87775 13.3607L7.87772 13.3601L7.71851 13.3679L6.87893 13.4094L6.71973 13.4172C6.73645 13.756 6.79166 14.089 6.88269 14.4104C7.09835 15.1719 7.51506 15.8684 8.09728 16.4223C8.92524 17.21 10.0245 17.649 11.1672 17.6483C12.31 17.6477 13.4088 17.2076 14.2359 16.419C14.8175 15.8645 15.2335 15.1676 15.4483 14.4058C15.5388 14.085 15.5936 13.7526 15.6101 13.4146L15.6102 13.4125L15.451 13.4048L14.6114 13.3643L14.4522 13.3566C14.4114 14.2007 14.0475 14.9967 13.4359 15.5799C12.9692 16.0248 12.3856 16.3197 11.7598 16.4347V11.5372H13.5442V10.2875H11.7598V8.92956C12.4523 8.67264 12.9492 7.97821 12.9492 7.16178C12.9492 6.12551 12.1502 5.28571 11.1648 5.28571C10.1795 5.28571 9.38046 6.12558 9.38046 7.16178C9.38046 7.97821 9.87743 8.67264 10.5704 8.92956V10.2875H8.78602V11.5371H10.5704V16.4348ZM10.5705 7.16178C10.5705 7.50585 10.8371 7.78608 11.1649 7.78608C11.4927 7.78608 11.7598 7.50578 11.7599 7.16178C11.7599 6.81676 11.4927 6.53647 11.1649 6.53647C10.8371 6.53647 10.5705 6.8167 10.5705 7.16178Z" fill="#333B46" />
  </svg>
);

export default TrafficNodeSea;
