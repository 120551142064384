import { TrafficValue } from 'src/cluster/balance-map';

import useCorrespondencesFromCenter from './useCorrespondencesFromCenter';

const useTrafficValuesFromCenter = (): TrafficValue[] => {
  const correspondences = useCorrespondencesFromCenter();
  return correspondences.map((flow) => ({
    region: flow.regionToId,
    value: flow.traffic,
  }));
};

export default useTrafficValuesFromCenter;
