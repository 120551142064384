import React from 'react';

const Confirm = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM17.2333 6.30667L10.56 17.0867C10.34 17.4533 9.9 17.7467 9.46 17.7467C9.09333 17.7467 8.72667 17.5267 8.43333 17.2333L4.32667 12.1C4.03333 11.66 4.03333 11.0733 4.4 10.7067C4.84 10.2667 5.5 10.2667 5.86667 10.6333L9.38667 13.7867L15.62 5.20667C15.9133 4.76667 16.5733 4.69333 17.0133 4.98667C17.4533 5.20667 17.5267 5.86667 17.2333 6.30667Z" fill="white" />
    </svg>
  );
};

export default Confirm;
