import React, {
  FC, MouseEvent, useState, Fragment,
} from 'react';

import { WithTranslate } from 'src/i18n';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import {
  createStyles, Fade, makeStyles, Popover as MuiPopover,
} from '@material-ui/core';

import useStyles from './styles';

export const overridePopover = makeStyles((theme) => createStyles({
  root: {
    borderRadius: 0,
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
    opacity: 1,
    boxShadow: theme.shadows[1],
  },
}), { name: 'MuiPopover' });

interface Props extends WithTranslate {
  users: string[];
  userFilter: any;
  setUserFilter: any;
}

const UserFilter: FC<Props> = (props) => {
  const {
    users,
    userFilter,
    setUserFilter,
    t,
  } = props;

  const classes = useStyles();

  overridePopover();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Fragment>
      {
        !!users.length &&
        <button
          className={classes.toggle}
          onClick={handleClick}
        >
          <PIcon
            className={userFilter ? classes.filterIconSelected : classes.filterIcon}
            type={ICONS_TYPES.people}
          />
        </button>
      }
      <MuiPopover
        id="filter-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 28,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
      >
        <div className={classes.popup}>
          <div className={classes.popupTitle}>
            {t('components.captions.filterByUser')}
          </div>
          {users.map(user => (
            <div
              className={userFilter === user ? classes.userFilerSelectedItem : classes.userFilerItem}
              key={user}
              onClick={() => {
                if (userFilter !== user) { setUserFilter(user); } else {
                  setUserFilter(undefined);
                }
                handleClose();
              }}
            >
              {userFilter === user &&
              <PIcon
                type={ICONS_TYPES.clear}
                className={classes.clearFilterIcon}
              />}
              {user}
            </div>
          ))}

        </div>

      </MuiPopover>
    </Fragment>
  );
};

export default UserFilter;
