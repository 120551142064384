import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import { toNumber } from 'src/helpers';
import { DEFAULT_NUMBER_ACCURACY } from 'src/constants';
import { unitsMessages } from 'src/modules/shipments/routes/constants';
import { EdgeModel } from '../../constants';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  model?: EdgeModel;
  onMouseOver?: (event: any) => void;
  onMouseOut?: (event: any) => void;
}

const EdgePopup: FC<Props> = ({
  className,
  model,
  onMouseOver,
  onMouseOut,
  t,
}) => {
  if (!model) return null;

  const title = t('modules.routes.captions.routeInfo');

  const renderTableLine = (text: React.ReactNode, content: React.ReactNode) => (
    <tr className={style.row}>
      <td className={style.column}>
        {text}
      </td>
      <td className={style.column}>
        {content}
      </td>
    </tr>
  );
  const renderTableColumnValue = (value: React.ReactNode, message: string) => (
    <span className={style.columnValue}>
      <span>{value}</span>
      <span>
        &nbsp;
      </span>
      <span>{message}</span>
    </span>
  );
  const renderNumber = (value: number) => toNumber(value, DEFAULT_NUMBER_ACCURACY);
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div {...{
      className: classNames(style.root, className),
      onMouseOver,
      onMouseOut,
    }}>
      <div className={style.title}>
        {title}
      </div>
      <table className={style.table}>
        <tbody>
          <Fragment>
            {renderTableLine(
              t('modules.routes.captions.id'),
              renderNumber(model.edgeId),
            )}
            {renderTableLine(
              t('common.captions.traffic'),
              renderTableColumnValue(model.traffic, t(unitsMessages.vehicles)),
            )}
            {renderTableLine(
              t('systems.cities.captions.length'),
              renderTableColumnValue(model.distance, t(unitsMessages.length)),
            )}
            {renderTableLine(
              t('systems.cities.captions.fuelCost'),
              renderTableColumnValue(renderNumber(model.fuelCost), t(unitsMessages.costRub)),
            )}
            {renderTableLine(
              t('systems.cities.captions.timeHour'),
              renderTableColumnValue(renderNumber(model.time), t(unitsMessages.time)),
            )}
            {renderTableLine(
              t('systems.cities.captions.timeMoney'),
              renderTableColumnValue(renderNumber(model.timeMoney), t(unitsMessages.costRub)),
            )}
            {renderTableLine(
              t('systems.cities.captions.fare'),
              renderTableColumnValue(renderNumber(model.fare), t(unitsMessages.costRub)),
            )}
            {renderTableLine(
              t('systems.cities.captions.ttc'),
              renderTableColumnValue(renderNumber(model.weight), t(unitsMessages.costRub)),
            )}
          </Fragment>
        </tbody>
      </table>
    </div>
  );
};

export default EdgePopup;
