import {
  createStyles, makeStyles,
} from '@material-ui/core';

export default makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));
