import { registerModal, MODAL_SIZES } from 'src/modals';

import { AppState } from 'src/reducer';
import ModalCalculationMap, { CalculationMapModalProps } from '../components/ModalCalculationMap';

import { CITIES_CALCULATION_MAP_MODAL } from '../constants';

const stateToProps = (state: AppState, props: CalculationMapModalProps) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.full,
  };
};

export default registerModal(
  CITIES_CALCULATION_MAP_MODAL,
  stateToProps,
)(ModalCalculationMap);
