import { StrictDict } from 'src/types';

import { ExportType } from 'src/cluster/editor-common';

export const EXPORT_TYPES_DICT: StrictDict<ExportType, string> = {
  [ExportType.directions]: 'systems.editor.exportTypes.directions',
  [ExportType.stops]: 'systems.editor.exportTypes.stops',
  [ExportType.excel]: 'systems.editor.exportTypes.excel',
  [ExportType.graph]: 'systems.editor.exportTypes.graph',
  [ExportType.graphTime]: 'systems.editor.exportTypes.graphTime',
};

export const EXPORT_TYPE_URLS_DICT: StrictDict<ExportType, string> = {
  [ExportType.directions]: 'routes/export/?extension=geojson',
  [ExportType.stops]: 'stops/export/',
  [ExportType.excel]: 'routes/export/',
  [ExportType.graph]: 'graph-export/',
  [ExportType.graphTime]: 'graph-speed-export/',
};
