import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import {
  Toolbar,
  ToolbarBlock,
  ToolbarControls,
} from 'src/components';
import {
  DistributionRouteFilter,
  DistributionTableToolbarTitle,
  DistributionPointFilter,
} from 'src/cluster/distribution-table';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import useStyles from './styles';

export interface InjectedProps {
  filterMode: string;
  setFilterMode(val: any): void;
}

type Props = InjectedProps & WithTranslate;

export enum FilterModes {
  route = 'route',
  point = 'point',
}
const DistributionTableToolbar: FC<Props> = (props) => {
  const {
    filterMode,
    t,
    setFilterMode,
  } = props;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterMode((event.target as HTMLInputElement).value);
  };

  return (
    <Toolbar className={classes.root}>
      <ToolbarBlock size="x-large" title={(<DistributionTableToolbarTitle />)}>
        <ToolbarControls>
          <div>
            <RadioGroup
              className={classes.radioGroup}
              name="viewMode"
              value={filterMode}
              onChange={handleChange}
            >
              <FormControlLabel
                value={FilterModes.route}
                control={<Radio color="primary" size="small" />}
                label={t('systems.distribution.captions.byRoutes')}
              />
              <FormControlLabel
                value={FilterModes.point}
                control={<Radio color="primary" size="small" />}
                label={t('systems.distribution.captions.byStoppingPoints')}
              />
            </RadioGroup>
            {filterMode === FilterModes.route && (
              <DistributionRouteFilter />
            )}
            {filterMode === FilterModes.point && (
              <DistributionPointFilter />
            )}
          </div>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default DistributionTableToolbar;
