/* eslint-disable max-len */
import React from 'react';

const TransportTypeAir = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.87659 15.1476C7.90301 15.1541 7.92774 15.158 7.95513 15.1658L9.75731 15.9893C9.93864 16.0392 10.12 15.9042 10.1219 15.7163C10.1348 14.7318 10.1731 14.6516 10.0046 14.5394L8.62665 13.6257C8.52386 13.5583 8.15925 13.3927 8.1915 13.2739C8.4424 12.311 8.72749 9.87925 8.80507 9.19004C8.81016 9.14592 8.83537 9.10664 8.87319 9.08386C8.91101 9.06083 8.95755 9.05719 8.99877 9.07319L14.8586 11.367C14.9028 11.384 14.9524 11.3786 14.9912 11.352C15.03 11.3253 15.0538 11.2812 15.0538 11.2342V10.3076C15.0538 9.91585 14.8581 9.5498 14.5331 9.33161L9.15513 5.72653C9.07465 5.67174 9.02689 5.58035 9.02834 5.48411V2.81211C9.03828 2.04121 8.48386 0.0113939 7.62931 0C7.61162 0 7.56919 0 7.52677 0C7.4841 0 7.44144 0 7.42374 0C6.56944 0.0113939 6.0155 2.04121 6.0252 2.81211V5.48411C6.02689 5.58035 5.97865 5.67174 5.89841 5.72653L0.520726 9.33186C0.195151 9.55004 0 9.9161 0 10.3079V11.2344C0 11.2814 0.0235151 11.3255 0.0623029 11.3522C0.101333 11.3786 0.150788 11.3842 0.194666 11.3672L6.05453 9.07343C6.09574 9.05743 6.14205 9.06107 6.18011 9.0841C6.21817 9.10689 6.24338 9.14616 6.24823 9.19028C6.32605 9.87949 6.61114 12.3112 6.86204 13.2741C6.89429 13.3929 6.52968 13.5585 6.42689 13.6259L5.04896 14.5396C4.88047 14.6521 4.91878 14.7323 4.93187 15.7166C4.93356 15.9044 5.1149 16.0395 5.29623 15.9895L7.09816 15.166C7.1258 15.1583 7.15053 15.1544 7.17695 15.1478L7.87659 15.1476Z" fill="#4BD4FF" />
  </svg>
);

export default TransportTypeAir;
