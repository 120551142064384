import React, { FC, useEffect } from 'react';
import { Maybe } from 'monet';

import { Model } from 'src/interfaces';
import { DataGridContainer } from 'src/components';
import { WithTranslate } from 'src/i18n';

import {
  DistFilterModes,
  AcDistGraphForm,
  AcProjectForm,
  AcDistPageForm,
  MatrixForecastModel,
} from 'src/cluster/common';
import {
  AcDistRoutesModel,
  AcDistStopsModel,
  AcDistRoutesDayModel,
  DistributionTableToolbar,
  DistributionRouteDataGrid,
  DistributionRouteDayDataGrid,
  DistributionPointDataGrid,
} from 'src/cluster/distribution-table';

import { Nullable } from 'src/types';
import useStyles from './styles';

export interface InjectedProps extends WithTranslate {
  pageForm: AcDistPageForm;
  graphForm: AcDistGraphForm;
  projectForm: AcProjectForm;
  resultsInfo: [Maybe<Model<AcDistRoutesModel | AcDistRoutesDayModel>[]>, number];
  resultsStopsInfo: [Maybe<Model<AcDistStopsModel>[]>, number];
  matrixEntity: [Nullable<Model<MatrixForecastModel>>, boolean];
  isLoading: boolean;
  onInit(): void;
  onPageChange(pageNumber: number): void;
  onPageSizeChange(pageSize: number): void;
  openCalcModal: () => void;
  openDateModal: () => void;
  showDistributionModal(record: AcDistRoutesModel): void;
  setFilterMode(mode: DistFilterModes): void;
}

const DistributionTablePage: FC<InjectedProps> = (props) => {
  const {
    pageForm: {
      filterMode,
      currentPage,
      pageSize,
    },
    graphForm: {
      day,
    },
    projectForm: { selectedProject },
    matrixEntity: [matrixInfo],
    isLoading,
    resultsInfo,
    resultsStopsInfo: [stops, countStops],
    openCalcModal,
    t,
    onInit,
    onPageChange,
    onPageSizeChange,
    showDistributionModal,
    setFilterMode,
  } = props;

  const classes = useStyles();

  useEffect(onInit, []);

  const [routes, countRoutes] = resultsInfo;

  const results = filterMode === DistFilterModes.route ? routes : stops;
  const count = filterMode === DistFilterModes.route ? countRoutes : countStops;

  useEffect(() => {
    if (!matrixInfo) return;
    const calculateStatus = matrixInfo.routesDistribution.status;
    const lastCalculateStatus = matrixInfo.routesDistribution.lastCalculationDate;

    if (!lastCalculateStatus || !calculateStatus) {
      openCalcModal();
    }
  }, [matrixInfo?.routesDistribution.status]);

  const getRoutesData = () => {
    return day
      ? (data: AcDistRoutesDayModel[]) => <DistributionRouteDayDataGrid data={data} />
      : (data: AcDistRoutesModel[]) => (
        <DistributionRouteDataGrid
          data={data}
          showDistributionModal={showDistributionModal}
        />
      );
  };

  return (
    <div className={classes.root}>
      <DistributionTableToolbar
        filterMode={filterMode}
        setFilterMode={setFilterMode}
      />
      <DataGridContainer
        data={results}
        currentPage={currentPage}
        pageSize={pageSize}
        count={count}
        message={!selectedProject ? t('modules.projects.captions.projectNotSelected') : undefined}
        isLoading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {filterMode === DistFilterModes.route
          ? getRoutesData()
          : (data: AcDistStopsModel[]) => <DistributionPointDataGrid data={data} />}
      </DataGridContainer>
    </div>
  );
};

export default DistributionTablePage;
