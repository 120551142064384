import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    paddingBottom: spacing(4),
  },

  warning: {
    color: palette.secondary.main,
  },

  list: {
    margin: spacing(2, 0),
    maxHeight: 200,
    overflowY: 'scroll',
    color: palette.grey[700],
    fontSize: 12,
  },

  type: {
    display: 'flex',
  },

  label: {
    flexBasis: '20%',
    textTransform: 'uppercase',
  },

  routes: {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: '80%',
  },

  route: {
    flexBasis: '33.333%',
  },

  number: {
    display: 'inline-box',
    width: 40,
  },

  direction: {
    display: 'inline-box',
    textTransform: 'lowercase',
  },

  divider: {
    margin: spacing(2, 0),
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 100,
  },
}));
