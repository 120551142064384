import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => createStyles({
  root: {
    paddingTop: spacing(6),
  },

  header: {
    paddingBottom: spacing(2),
  },

  search: {
    display: 'flex',
    justifyContent: 'space-between',

    '&> p': {
      lineHeight: 2.5,
    },
  },

  list: {
    height: 650,
    padding: spacing(2),
    overflowY: 'auto',
  },
}));
