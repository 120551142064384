import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';
import { maxBy } from 'lodash';

import { Model } from 'src/interfaces';
import {
  isArray, isNumber, isUndefined, serializeQuery,
} from 'src/helpers';
import { GEOJSON_ENDPOINT } from 'src/api-url-schema';

import {
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  AC_REGIONS_ENDPOINT,
  AC_PROJECTS_MODEL_NAME,
  BalanceFilterModes,
  AcBalanceDataModel,
  AcForecastCorrespondenceModel,
  isAcForecastCorrespondenceModel,
  selectMatrixForecastParams,
  selectProjectEndpoint,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { selectAcProjectUrl } from 'src/cluster/projects';
import { selectAcCorrespondencesEntity } from 'src/cluster/balance-common';
import {
  EDGES_GEOJSON_ENDPOINT,
  STOPS_GEOJSON_ENDPOINT,
  AcBalanceMapPageForm,
  MapModes,
} from 'src/cluster/balance-map';

import { selectAcBalanceMapPageForm, selectAcDataEntity, selectAcProjectPageForm } from './common';

export function getCorrespondencesFilter(form: AcBalanceMapPageForm) {
  const {
    mapMode,
    filterModeTo,
    filterModeFrom,
    regionFrom,
    regionTo,
    municipalityFrom,
    municipalityTo,
    year,
    scenarioId,
    intervalId,
    region,
  } = form;

  if (!isNumber(year) || !isNumber(scenarioId) || !isNumber(intervalId)) return undefined;

  if (mapMode === MapModes.group) {
    return {
      page: 1,
      pageSize: 9999999,
      regionFromId: filterModeFrom === BalanceFilterModes.node ? regionFrom : undefined,
      regionToId: filterModeTo === BalanceFilterModes.node ? regionTo : undefined,
      municipalityFromId: filterModeFrom === BalanceFilterModes.municipality ? municipalityFrom : undefined,
      municipalityToId: filterModeTo === BalanceFilterModes.municipality ? municipalityTo : undefined,
      scenarioId,
      year,
      intervalId,
    };
  }

  return {
    page: 1,
    pageSize: 9999999,
    scenarioId,
    year,
    intervalId,
    regionFromId: region,
  };
}

export const selectBalanceMapData = createSelector(
  [
    selectAcProjectPageForm,
    selectAcBalanceMapPageForm,
    selectAcDataEntity,
  ],
  (pageProjectsForm, balanceMapPageForm, dataEntities): [AcBalanceDataModel[], boolean, number] => {
    if (!pageProjectsForm.selectedProject || !balanceMapPageForm.year) {
      return [
        [],
        false,
        0,
      ];
    }
    const dataApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
      filter: {
        page: 1,
        pageSize: 1000,
        year: balanceMapPageForm.year,
        scenarioId: balanceMapPageForm.scenarioId,
      },
    };

    const clearData = dataEntities.getArray(dataApiConfig).reduce<AcBalanceDataModel[]>(
      (memo, record) => memo.concat([{
        ...record,
        factors: record.factors.filter((f) => f.value > 0),
      }]), []);

    return [
      clearData,
      dataEntities.getIsLoadingArray(dataApiConfig),
      clearData.length,
    ];
  },
);

export const selectBalanceMapCorrespondences = createSelector(
  [
    selectAcProjectListForm,
    selectAcBalanceMapPageForm,
    selectMatrixForecastParams,
    selectAcCorrespondencesEntity,
  ],
  (
    projectListForm,
    balanceMapPageForm,
    matrixForecastParams,
    forecastCorrespondencesEntities,
  ): Maybe<Model<AcForecastCorrespondenceModel>[]> => {
    const { selectedProject } = projectListForm;
    const { mapMode, minTraffic } = balanceMapPageForm;
    const [forecastId] = matrixForecastParams;

    if (!isNumber(selectedProject) || !isNumber(forecastId)) return Nothing();

    const filter = getCorrespondencesFilter(balanceMapPageForm);
    if (isUndefined(filter)) return Nothing();

    const isRegionFromEmpty = Array.isArray(filter.regionFromId) && filter.regionFromId.length === 0;
    const isRegionToEmpty = Array.isArray(filter.regionToId) && filter.regionToId.length === 0;
    const isRegionToEmptyForNotFromType = isNumber(filter.regionFromId);
    if (isRegionFromEmpty && isRegionToEmpty && mapMode === MapModes.group) return Nothing();
    if (!isRegionToEmptyForNotFromType && mapMode !== MapModes.group) return Nothing();

    const forecastCorrespondencesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter,
    };

    const entities = forecastCorrespondencesEntities.getArray(forecastCorrespondencesApiConfig);

    if (!isArray(entities) || !isAcForecastCorrespondenceModel(entities[0])) Nothing();

    const maxTrafficCorr = maxBy(entities, corr => corr.traffic);
    const preparedEntities = (!maxTrafficCorr || mapMode === MapModes.group)
      ? entities
      : entities.filter((corr) => {
        const trafficPercent = (corr.traffic / maxTrafficCorr.traffic) * 100;
        return trafficPercent >= minTraffic;
      });

    return Just(preparedEntities);
  },
);

export const selectPolygonsGeojsonEndpoint = createSelector(
  [
    selectProjectEndpoint,
    selectAcProjectPageForm,
  ],
  (projectEntities, pageProjectsForm) => {
    const { apiHost, apiPrefix, endpoint } = projectEntities;
    if (!pageProjectsForm.selectedProject) {
      return undefined;
    }
    const projectEndpoint = `${apiHost}${apiPrefix}${endpoint}`;

    return `${projectEndpoint}${pageProjectsForm.selectedProject}/${AC_REGIONS_ENDPOINT}${GEOJSON_ENDPOINT}`;
  },
);

export const selectEdgesGeojsonEndpoint = createSelector(
  [
    selectAcProjectUrl,
    selectAcBalanceMapPageForm,
  ],
  (projectsUrl, pageForm): string | undefined => {
    if (!projectsUrl) {
      return undefined;
    }

    const { scenarioId, year, intervalId } = pageForm;

    if (
      !isNumber(scenarioId)
      || !isNumber(year)
      || !isNumber(intervalId)
    ) {
      return undefined;
    }

    const edgesUrl = `${projectsUrl}${EDGES_GEOJSON_ENDPOINT}`;

    const query = serializeQuery({
      scenarioId,
      year,
      intervalId,
    });

    return `${edgesUrl}?${query}`;
  },
);

export const selectStopsGeojsonEndpoint = createSelector(
  [
    selectAcProjectUrl,
    selectAcBalanceMapPageForm,
  ],
  (projectsUrl, pageForm): string | undefined => {
    if (!projectsUrl) {
      return undefined;
    }

    const { scenarioId, year, intervalId } = pageForm;

    if (
      !isNumber(scenarioId)
      || !isNumber(year)
      || !isNumber(intervalId)
    ) {
      return undefined;
    }

    const stopsUrl = `${projectsUrl}${STOPS_GEOJSON_ENDPOINT}`;

    const query = serializeQuery({
      scenarioId,
      year,
      intervalId,
    });

    return `${stopsUrl}?${query}`;
  },
);
