import React from 'react';

const Excel = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 17H16V3H12"
      stroke="#E5293C"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="#E5293C"
      fill="none"
      d="M11.8989 1L1.01392 3.89331L1 15.844L11.8342 18.8923L11.8989 1Z"
    />
    <path
      d="M4.5 7L8.5 12.5"
      stroke="#E5293C"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.5 6.5L4.5 12"
      stroke="#E5293C"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>

);

export default Excel;
