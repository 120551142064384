import { BalanceFilterModes } from 'src/cluster/common';

export default {
  defaults: {
    name: undefined,
    filterModeFrom: BalanceFilterModes.node,
    filterModeTo: BalanceFilterModes.node,

    mapRegion: undefined,
    isExporting: false,

    regionFrom: undefined,
    regionTo: undefined,
    municipalityFrom: undefined,
    municipalityTo: undefined,

    year: undefined,
    scenario: undefined,
    interval: undefined,

    pageSize: 20,
    currentPage: 1,
  },
};
