import React, { ChangeEvent, FC } from 'react';
import { Typography } from '@material-ui/core';

import { Checkbox } from 'src/components';

import { translateCategoryName } from 'src/cluster/dashboard-table';

import useStyles from './styles';

type Props = {
  level: number;
  name: string;
  checked: boolean;
  setHiddenCategories(value: ChangeEvent<HTMLInputElement>, checked: boolean): void;
}

const IndicatorItem: FC<Props> = (props) => {
  const {
    level, name, checked, setHiddenCategories,
  } = props;

  const classes = useStyles(level);

  return (
    <div className={classes.root}>
      <Checkbox name={name} checked={checked} onChange={setHiddenCategories} />
      <Typography>{translateCategoryName(name)}</Typography>
    </div>
  );
};

export default IndicatorItem;
