import React from 'react';
import clsx from 'clsx';
import { TableRow, TableHead } from '@material-ui/core';

import { isFunc } from 'src/helpers';
import { DataGridColumnProps } from 'src/components';

import TableCell from '../TableCell';

import { useStyles, useCellStyles } from './styles';

interface HeadProps<T> {
  columns: DataGridColumnProps<T>[];
  expandable?: boolean | ((record: T) => boolean);
  multiple?: boolean;
  headClassName?: string;
  headCellClassName?: string;
}

type Props<T> = HeadProps<T>;

function GridHead<T extends Record<string, any>>(props: Props<T>) {
  const {
    columns,
    expandable = false,
    multiple = false,
    headCellClassName,
    headClassName,
  } = props;

  const cellClasses = useCellStyles();
  const classes = useStyles();

  const canExpand = expandable && isFunc(expandable) ? false : expandable;

  return (
    <TableHead className={headClassName}>
      <TableRow>
        {columns.map((item, index: number) => {
          const hasArrow = index === 0 && canExpand;
          return (
            <TableCell
              classes={cellClasses}
              className={clsx(
                item.headClassName,
                hasArrow && classes.cellWithArrow,
                !!item.children && classes.multipleCellTop,
                headCellClassName,
              )}
              scope="col"
              colSpan={item.children ? item.children.length : 1}
              rowSpan={item.children ? 1 : 2}
              key={`thc-${index}`}
            >
              {item.title}
            </TableCell>
          );
        })}
      </TableRow>
      {multiple && (
        <TableRow>
          {columns.map((item) => (
            !!item.children && (
              item.children.map((child, i) => {
                return (
                  <TableCell
                    classes={cellClasses}
                    className={clsx(child.headClassName, classes.multipleCellBottom, headCellClassName)}
                    scope="col"
                    key={`thc-${i}`}
                  >
                    {child.title}
                  </TableCell>
                );
              })
            )))}
        </TableRow>
      )}
    </TableHead>
  );
}

export default GridHead;
