import { RestifyId } from 'redux-restify';
import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';

export const TRANSPORT_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: 'pageMatrixTransportForm',
};

export const TRANSPORT_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: 'matrixTransport',
};

export interface TransportModel {
  id?: RestifyId;
  type: string;
  year?: number;
  price: number;
  time: number;
  frequency: number;
  fromCity: string;
  toCity: string;
  comfortElasticity: number;
  hsw: number;
}

export interface PageTransportForm {
  yearFrom: number;
  yearTo: number;
  correspondences: string;
  types: string;
  hsw: number;
  pageSize: number;
  currentPage: number;
}
