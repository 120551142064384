import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema,
  RouteScheme,
  getUrl,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';

import { ProjectSelector, selectAcProjectListForm } from 'src/cluster/common';
import { projectListFormActions, selectAcProjects } from 'src/cluster/projects';
import { DataPage } from 'src/cluster/balance-data';
import { ForecastPage } from 'src/cluster/balance-forecast';
import { TtcCalcPage } from 'src/cluster/balance-ttc/';
import { BalanceMapPage } from 'src/cluster/balance-map';

interface Props extends WithTranslate {
  match?: any;
  onChange: () => void;
}

const BalanceRoot: FC<Props> = (props) => {
  const { match, t, onChange } = props;

  return (
    <PageWithHeader
      basePath={['agglomeration', 'balance']}
      menuRenderers={{
        data: {
          label: t('modules.data.captions.title'),
          subLabel: t('modules.data.captions.subTitle'),
        },
        ttc: {
          label: t('modules.ttc.captions.title'),
          subLabel: t('modules.ttc.captions.subTitle'),
        },
        forecast: {
          label: t('modules.forecast.captions.passengerFlows'),
        },
        map: {
          label: t('modules.map.captions.title'),
        },
      }}
      headerChildren={(
        <Route path={getUrl(urlSchema.agglomeration.balance)}>
          <ProjectSelector
            selectProjects={selectAcProjects}
            selectProjectForm={selectAcProjectListForm}
            formActions={projectListFormActions}
            onChange={onChange}
          />
        </Route>
      )}
    >
      <RouteScheme
        prevMatch={match}
        basePath={['agglomeration', 'balance']}
        renderers={{
          data: {
            component: DataPage,
          },
          ttc: {
            component: TtcCalcPage,
          },
          forecast: {
            component: ForecastPage,
          },
          map: {
            component: BalanceMapPage,
          },
        }}
      />
    </PageWithHeader>
  );
};

export default BalanceRoot;
