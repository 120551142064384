import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { isNull } from 'src/helpers';
import modals from 'src/modals';
import { SelectValue } from 'src/interfaces';
import { AC_EXPORT_SELECTION_MODAL_NAME } from 'src/cluster/common';
import {
  AC_DATA_FORECAST_MODAL_NAME,
  AcPageBalanceDataFormEnum,
  dataEntityManager,
  dataFormActions,
  exportData,
} from 'src/cluster/balance-data';

import { OwnProps } from './interfaces';
import DataToolbar from './DataToolbar';

const mapDispatchToProps = (dispatch: Dispatch<any>, { resetAll }: OwnProps) => ({
  switchToEdit: () => dispatch(dataFormActions.changeField('isEdit', true)),
  switchToList: () => {
    resetAll();
    dispatch(dataFormActions.changeField('isEdit', false));
  },
  resetPageForm: () => {
    resetAll();
    dispatch(dataFormActions.resetFields(['regions', 'municipalities']));
  },
  onChangeField: (field: AcPageBalanceDataFormEnum, value: SelectValue | number[]) => {
    dispatch(dataFormActions.resetField('currentPage'));
    dispatch(dataEntityManager.clearData());
    if (!isNull(value)) {
      dispatch(dataFormActions.changeField(field, value));
    }
  },
  showForecastModal: () => {
    dispatch(modals.actions.showModal(true, AC_DATA_FORECAST_MODAL_NAME));
  },
  showExportsModal: () => {
    dispatch(modals.actions.showModal(true, AC_EXPORT_SELECTION_MODAL_NAME, {
      exportData: (isFull?: boolean) => dispatch(exportData(isFull)),
    }));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(DataToolbar));
