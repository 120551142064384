import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import { WithTranslate } from 'src/i18n';
import { toNumber, useRestifyForm } from 'src/helpers';

import PTable from 'src/components/deprecated/PTable';

import { unitsMessages } from 'src/modules/shipments/routes/constants';
import { useSystemsContext, DEFAULT_NUMBER_ACCURACY } from 'src/constants';

import selectors from '../../selectors';
import {
  ROUTES_PAGES_FORM_NAMES,
  PageRoutesForm,
  RouteModel,
} from '../../constants';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const RoutesTable: FC<Props> = ({
  className,
  t,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const {
    currentRoutes,
    currentRoutesIsLoading,
  } = useSelector(selectors[currentSystem].getCurrentProjectRoutes);

  const [
    routesPageForm,
    routesPageFormActions,
  ] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);

  // Scroll to selected route
  const rowRefs = React.useRef<any[]>([]);
  useEffect(() => {
    if (routesPageForm.selectedRoute !== undefined) {
      const currentRow = rowRefs.current[routesPageForm.selectedRoute];
      if (currentRow) {
        scrollIntoViewIfNeeded(currentRow, {
          scrollMode: 'if-needed',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [routesPageForm.selectedRoute]);

  if (routesPageForm.selectedRoute === undefined || !currentRoutes.length || currentRoutesIsLoading) return null;
  const renderNumber = (value: number) => toNumber(value, DEFAULT_NUMBER_ACCURACY);

  return (
    <div className={classNames(style.root, className)}>
      <PTable<RouteModel> {...{
        className: style.table,
        rowClassName: (item, index) => {
          return [
            routesPageForm.selectedRoute === index,
            routesPageForm.expandedRoute === index && style.expandedRow,
            style.row,
          ];
        },
        headerCellClassName: style.headerCell,
        tableCellClassName: style.tableCell,
        onRowClick: (item, index) => {
          dispatch(routesPageFormActions.resetField('expandedRoute'));
          if (routesPageForm.selectedRoute === index) {
            dispatch(routesPageFormActions.resetField('selectedRoute'));
          } else {
            dispatch(routesPageFormActions.changeField('selectedRoute', index));
          }
        },

        rowRef: (node, item, index) => {
          if (node) {
            rowRefs.current[index] = node;
          }
        },
        header: [
          {
            title: t('common.captions.from'),
            model: () => routesPageForm.from,
          },
          {
            title: t('common.captions.to'),
            model: () => routesPageForm.to,
          },
          {
            title: (
              <span>
                {t('systems.cities.captions.time')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(unitsMessages.timeMinutes)}
                  )
                </span>
              </span>
            ),
            className: style.beforeRowSpanColumn,
            model: (model) => renderNumber(model.time * 60),
          },
          {
            title: (
              <span>
                {t('systems.cities.captions.length')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(unitsMessages.length)}
                  )
                </span>
              </span>
            ),
            className: style.typeColumn,
            model: (model) => renderNumber(model.distance),
          },
          {
            title: (
              <span>
                {t('systems.cities.captions.ttcShort')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(unitsMessages.costRub)}
                  )
                </span>
              </span>
            ),
            model: (model) => renderNumber(model.weight),
          },
          {
            title: (
              <span>
                {t('systems.cities.captions.traffic')}
                &nbsp;
                <span className={style.units}>
                  (
                  {unitsMessages.vehicles}
                  )
                </span>
              </span>
            ),
            model: (item) => (
              <span>
                {renderNumber(item.trafficSummary)}
              </span>
            ),
          },
        ],
        body: currentRoutes.sort((a, b) => {
          return b.trafficSummary - a.trafficSummary;
        }),
      }} />
    </div>
  );
};

export default RoutesTable;
