import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    position: 'relative',
    display: 'block',
  },
  filter: {
    width: 215,
    margin: spacing(3, 0),

    '& label': {
      fontSize: 12,
    },
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 18,
    marginBottom: 14,
    textTransform: 'uppercase',
    color: palette.grey[700],
    '& label': {
      marginRight: 50,
      '& span': {
        fontWeight: 500,
      },
    },
  },
}));
