import { ModelDefaults } from 'src/types';
import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';
import { AC_INDICATOR_RESULTS_ENDPOINT, AcDashIndicatorResultsModel } from 'src/cluster/dashboard-common';

const defaults: ModelDefaults<AcDashIndicatorResultsModel> = {
  id: undefined,
  indicatorId: undefined,
  year: undefined,
  scenarioId: undefined,
  intervalId: undefined,
  vehicleTypeId: undefined,
  vehicleClassId: undefined,
  value: undefined,
  subindicators: undefined,
};

export default {
  endpoint: AC_INDICATOR_RESULTS_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME],
  idField: () => Math.random(),
  defaults,
  clearPagesOnRouteChange: false,
  pagination: false,
};
