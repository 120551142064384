import { MATRIX_MACROECONOMICS_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: MATRIX_MACROECONOMICS_ENDPOINT,
  name: 'данные по макроэкономике',
  defaults: {
    id: undefined,
    year: undefined,
    macroeconomics: undefined,
    mobilityIncrease: undefined,
    nonInflationaryIncomeGrowth: undefined,
    region: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
