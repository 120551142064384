import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    parent: {
      '& span': {
        transition: 'transform 200ms',
      },
    },

    opened: {
      '& span': {
        transform: 'rotate(90deg)',
      },
    },

    child: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      boxSizing: 'border-box',
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.text.primary,
      borderTopWidth: 1,

      '&:last-of-type': {
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.text.primary,
        borderBottomWidth: 1,
      },
    },
  });
});
