/* eslint-disable max-len */
import React from 'react';

const MapBorderCross = () => (
  <svg viewBox="0 0 21 26" fill="none">
    <path d="M10.3288 0.132446C4.62402 0.132446 0 4.75686 0 10.4612C0 18.3055 10.3288 25.1324 10.3288 25.1324C10.3288 25.1324 20.6575 18.3055 20.6575 10.4612C20.6575 4.75686 16.0335 0.132446 10.3288 0.132446ZM10.3288 18.4889C5.89517 18.4889 2.30142 14.8952 2.30142 10.4612C2.30142 6.02761 5.89522 2.43347 10.3288 2.43347C14.7623 2.43347 18.3569 6.02761 18.3569 10.4612C18.3569 14.8952 14.7624 18.4889 10.3288 18.4889Z" fill="white"
    />
    <path d="M10.3288 18.4889C5.89517 18.4889 2.30142 14.8952 2.30142 10.4612C2.30142 6.02761 5.89522 2.43347 10.3288 2.43347C14.7623 2.43347 18.3569 6.02761 18.3569 10.4612C18.3569 14.8952 14.7624 18.4889 10.3288 18.4889Z" fill="white"
    />
    <path d="M10.3288 0.132446C4.62402 0.132446 0 4.75686 0 10.4612C0 18.3055 10.3288 25.1324 10.3288 25.1324C10.3288 25.1324 20.6575 18.3055 20.6575 10.4612C20.6575 4.75686 16.0335 0.132446 10.3288 0.132446ZM10.3288 18.4889C5.89517 18.4889 2.30142 14.8952 2.30142 10.4612C2.30142 6.02761 5.89522 2.43347 10.3288 2.43347C14.7623 2.43347 18.3569 6.02761 18.3569 10.4612C18.3569 14.8952 14.7624 18.4889 10.3288 18.4889Z" fill="#D23341"
    />
    <line x1="6.5" y1="10.6324" x2="14.5" y2="10.6324" stroke="#D23341" strokeWidth="2" strokeLinecap="round"
    />
  </svg>
);

export default MapBorderCross;
