import React, { ChangeEvent, FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { CrossIcon, FileInputLink, SelectField } from 'src/components';
import { FILE_TYPE_DICT, FileType } from 'src/cluster/projects';

import FileTypeLabel from '../FileTypeLabel';

import useStyles from './styles';

interface Props {
  type?: FileType;
  fileTypesItems: {id: FileType; value: FileType; label: string}[];
  index: number;
  name?: string;
  isUploading: boolean;
  attached: boolean;
  changeFile: (fileList: (FileList | null)) => void;
  changeType: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  deleteFile: () => void;
}

const ProjectFileListItem: FC<Props> = (props) => {
  const {
    index,
    fileTypesItems,
    type,
    name,
    isUploading,
    attached,
    changeFile,
    changeType,
    deleteFile,
  } = props;

  const classes = useStyles();
  const isGraph = type === FileType.graph;
  return (
    <TableRow className={classes.root}>
      <TableCell width="50%" className={classes.cell}>
        {type ? <FileTypeLabel type={type} /> : (
          <SelectField
            options={fileTypesItems}
            size="slim"
            onChange={changeType}
          />
        )}
        {isGraph && name && index === 0 && (
          <FileInputLink
            className={classes.graphFileInput}
            label="Добавить файлы"
            useFileNameAsLabel={false}
            isUploading={isUploading}
            accept={type && FILE_TYPE_DICT[type]?.extension}
            multiple
            onChange={changeFile}
          />
        )}
      </TableCell>
      <TableCell width="55%" className={classes.cell}>
        {name || (
          <FileInputLink
            label={isGraph ? 'Добавить файлы' : 'Добавить файл'}
            useFileNameAsLabel={false}
            isUploading={isUploading}
            accept={type && FILE_TYPE_DICT[type]?.extension}
            multiple={isGraph}
            onChange={changeFile}
          />
        )}
      </TableCell>
      <TableCell width="5%" className={classes.cell}>
        {attached && (
          <button
            className={classes.deleteIcon}
            onClick={deleteFile}
          >
            <CrossIcon />
          </button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProjectFileListItem;
