import { ExportTypes } from 'src/modules/matrix/forecast';

export interface ExportForecastForm {
  type?: ExportTypes;
}

export default {
  defaults: {
    type: undefined,
  },
};
