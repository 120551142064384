import React, { FC } from 'react';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import { Maybe } from 'monet';

import { WithTranslate } from 'src/i18n';
import { isNumber, isString } from 'src/helpers';

import { AcRouteDirectionModel, useSortedByLabelVehicleTypes } from 'src/cluster/common';

import { useVehicleTypeLabel } from 'src/cluster/editor-map';
import useStyles from './styles';

type Props = WithTranslate & {
  directions: Maybe<AcRouteDirectionModel[]>;
};

export function sortByVariantNumber<T extends AcRouteDirectionModel>(directions: T[]): T[] {
  return directions.sort((a: T, b: T) => {
    const labelA = a.variantNumber;
    const labelB = b.variantNumber;
    if (isNumber(labelA) && isNumber(labelB)) {
      return labelA - labelB;
    }
    if (!isString(labelA)) {
      return 1;
    }
    if (!isString(labelB)) {
      return -1;
    }
    const numberA = Number.parseInt(labelA, 10);
    const numberB = Number.parseInt(labelB, 10);

    if (numberA === numberB) {
      return labelA.localeCompare(labelB);
    }
    return numberA - numberB;
  });
}

const LoadingContainer = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress thickness={7} />
    </div>
  );
};

const IgnoredRouteDirectionList: FC<Props> = (props) => {
  const { directions, t } = props;

  const classes = useStyles();

  const vehicleTypes = useSortedByLabelVehicleTypes();
  const getVehicleTypeLabel = useVehicleTypeLabel();

  return (
    <div className={classes.root}>
      <Typography className={classes.warning}>
        {t('systems.editor.messages.confirmWarning')}
        :
      </Typography>
      {directions.map((value) => (
        <div className={classes.list}>
          {vehicleTypes.map((type) => {
            const vehicleTypeDirections = sortByVariantNumber(
              value.filter((direction) => {
                return direction.vehicleTypeId === type.id;
              }),
            );
            if (vehicleTypeDirections.length === 0) {
              return null;
            }

            return (
              <div key={type.name} className={classes.type}>
                <div className={classes.label}>{getVehicleTypeLabel(type.id)}</div>
                <div className={classes.routes}>
                  {vehicleTypeDirections.map((direction) => (
                    <div key={direction.id} className={classes.route}>
                      <span className={classes.number}>{direction.variantNumber}</span>
                      <span className={classes.direction}>
                        (
                        {direction.direction
                          ? t('systems.agglomeration.captions.forward')
                          : t('systems.agglomeration.captions.backward')}
                        )
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )).orJust(<LoadingContainer />)}
      <Divider className={classes.divider} />
      <Typography>{t('systems.editor.messages.confirmQuestion')}</Typography>
    </div>
  );
};

export default IgnoredRouteDirectionList;
