import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import { selectCurrentProjectMunicipalities } from 'src/cluster/common';
import { getVocabularyValue, useCurrentVocabulary } from 'src/i18n';

const useAcMunicipalitiesOptions = (): SelectMenuOption[] => {
  const [municipalities] = useSelector(selectCurrentProjectMunicipalities);
  const [locale, vocabulary] = useCurrentVocabulary();

  return useMemo(() => municipalities.map(n => ({
    id: String(n.id),
    value: n.id,
    label: getVocabularyValue(locale, vocabulary, n.name),
  })), [JSON.stringify(municipalities), locale, vocabulary]);
};

export default useAcMunicipalitiesOptions;
