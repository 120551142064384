import { useMemo } from 'react';

import { makeMapStyleEdgeLayer, VEHICLE_TYPES_COLORS, VehicleType } from 'src/cluster/common';
import { useVehicleTypeName } from 'src/cluster/editor-map';

import useEdgePaint from './useEdgePaint';

const useDiagramByVehicleTypesLayers = (
  ids: number[],
  visible: boolean,
  deps: any[],
) => {
  const getEdgePaint = useEdgePaint();

  const getVehicleTypeName = useVehicleTypeName();

  return useMemo(() => {
    const allTypesLayer = makeMapStyleEdgeLayer(
      'edges-total-vehicle-types',
      visible && ids.includes(-1),
      ['has', 'passenger_traffic_total'],
      getEdgePaint((from: number, to: number) => [
        'all',
        ['>', ['get', 'passenger_traffic_total'], from],
        ['<=', ['get', 'passenger_traffic_total'], to],
      ], VEHICLE_TYPES_COLORS.all),
    );

    const layers = ids.filter(id => !!getVehicleTypeName(id)).map((id) => {
      const color = VEHICLE_TYPES_COLORS[getVehicleTypeName(id) ?? VehicleType.all];
      const name = getVehicleTypeName(id);
      return makeMapStyleEdgeLayer(
        `edges-by-vehicle-type-${name}`,
        visible,
        ['has', ['to-string', id], ['get', 'vehicle_type_traffic']],
        getEdgePaint((from: number, to: number) => [
          'all',
          ['>', ['get', ['to-string', id], ['get', 'vehicle_type_traffic']], from],
          ['<=', ['get', ['to-string', id], ['get', 'vehicle_type_traffic']], to],
        ], color),
      );
    });

    const allTypesTransparentLayer = makeMapStyleEdgeLayer(
      'edges-total-vehicle-types-transparent',
      visible && ids.includes(-1),
      ['has', 'passenger_traffic_total'],
      {
        'line-color': 'transparent',
        'line-width': 20,
        'line-offset': 10,
      },
    );

    const transparentLayers = ids.filter(id => !!getVehicleTypeName(id)).map((id) => {
      const name = getVehicleTypeName(id);
      return makeMapStyleEdgeLayer(
        `edges-by-vehicle-type-${name}-transparent`,
        visible,
        ['has', ['to-string', id], ['get', 'vehicle_type_traffic']],
        {
          'line-color': 'transparent',
          'line-width': 20,
          'line-offset': 10,
        },
      );
    });

    return [allTypesLayer, ...layers, allTypesTransparentLayer, ...transparentLayers];
  }, [...deps, visible, getEdgePaint]);
};

export default useDiagramByVehicleTypesLayers;
