import React, { FC, useState } from 'react';
import classNames from 'classnames';

import i18n, { WithTranslate } from 'src/i18n';
import { Button } from 'src/components';
import PInput, { INPUT_TYPES } from 'src/components/deprecated/PInput';
import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import {
  AcBalanceTtcModel, ValidationMessages, getSelectValue, useAcNodesOptions,
} from 'src/cluster/common';

import useStyles from './styles';

function validate(record: AcBalanceTtcModel): [boolean, ValidationMessages] {
  const fieldMessages = {
    regionToId: record.regionToId
      ? undefined
      : i18n.t('modules.ttc.validation.nonEmpty'),
    regionFromId: record.regionFromId
      ? undefined
      : i18n.t('modules.ttc.validation.nonEmpty'),
    year: (Number(record.year) > 1900 && record.year < 2100)
      ? undefined
      : i18n.t('modules.ttc.validation.year'),
    ttcBase: Number(record.ttcBase) >= 0
      ? undefined
      : i18n.t('modules.ttc.validation.nonNegative'),
    ttcForecast: Number(record.ttcBase) >= 0
      ? undefined
      : i18n.t('modules.ttc.validation.nonNegative'),
    source: (Number(record.source.length) > 0)
      ? undefined
      : i18n.t('modules.ttc.validation.nonEmpty'),
  };

  const areFieldsValid = Object.values(fieldMessages).every((message) => !message);

  return [areFieldsValid, fieldMessages];
}

interface Props extends WithTranslate {
  className?: string;
  record: AcBalanceTtcModel;
  onEdit: (record: AcBalanceTtcModel) => void;
  close: () => void;
}

const ModalTtcEdit: FC<Props> = (props) => {
  const {
    className,
    record,
    onEdit,
    close,
    t,
  } = props;
  const classes = useStyles();

  const nodesItems = useAcNodesOptions();

  const [regionFromId, setRegionFromId] = useState<number | undefined>(record.regionFromId);
  const [regionToId, setRegionToId] = useState<number | undefined>(record.regionToId);
  const [year, setYear] = useState<number>(record.year);
  const [source, setSource] = useState<string>(record.source);
  const [ttcBase, setTtcBase] = useState<number>(record.ttcBase);
  const [ttcForecast, setTtcForecast] = useState<number>(record.ttcForecast);

  const handleRegionFromChange = ([value]: DropDownValue[]) => {
    setRegionFromId(value ? Number(value) : undefined);
  };

  const handleRegionToChange = ([value]: DropDownValue[]) => {
    setRegionToId(value ? Number(value) : undefined);
  };

  const handleYearChange = (value: string) => {
    const newValue = Number(value) || 0;
    setYear(newValue);
  };

  const handleSourceChange = (value: string) => {
    setSource(value);
  };

  const handleTccBaseChange = (value: number) => {
    setTtcBase(value);
  };

  const handleTccForecastChange = (value: number) => {
    setTtcForecast(value);
  };

  const handleSave = () => {
    const nodeItemFrom = nodesItems.find((item) => item.value === regionFromId);
    const regionFromName = nodeItemFrom ? String(nodeItemFrom.label) : undefined;
    const nodeItemTo = nodesItems.find((item) => item.value === regionToId);
    const regionToName = nodeItemTo ? String(nodeItemTo.label) : undefined;
    onEdit({
      ...record,
      regionFromId,
      regionToId,
      regionFromName,
      regionToName,
      year,
      source,
      ttcBase,
      ttcForecast,
    });
    close();
  };

  const [isValid, errors] = validate({
    ...record,
    regionFromId,
    regionToId,
    year,
    source,
    ttcBase,
    ttcForecast,
  });

  const isChanged = record.regionFromId !== regionFromId
    || record.regionToId !== regionToId
    || record.year !== year
    || record.source !== source
    || record.ttcBase !== ttcBase
    || record.ttcForecast !== ttcForecast;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.fields}>
        <div className={classes.field}>
          <PSelect
            label={t('systems.balance.captions.firstDistrict')}
            className={classNames(errors.regionFrom && classes.errorSelect)}
            errors={errors.regionFrom ? [String(errors.regionFrom)] : undefined}
            items={nodesItems}
            values={getSelectValue(regionFromId)}
            disabled
            onChange={handleRegionFromChange}
          />
        </div>
        <div className={classes.field}>
          <PSelect
            label={t('systems.balance.captions.secondDistrict')}
            errors={errors.regionTo ? [String(errors.regionTo)] : undefined}
            items={nodesItems}
            className={classNames(errors.regionTo && classes.errorSelect)}
            values={getSelectValue(regionToId)}
            disabled
            onChange={handleRegionToChange}
          />
        </div>
        <div className={classes.field}>
          <PInput
            label={t('common.captions.year')}
            type={INPUT_TYPES.int}
            errors={errors.year ? [String(errors.year)] : undefined}
            formatted={false}
            value={year}
            disabled
            onChange={handleYearChange}
          />
        </div>
        <div className={classes.field}>
          <PInput
            label={t('systems.balance.captions.source')}
            type={INPUT_TYPES.text}
            errors={errors.source ? [String(errors.source)] : undefined}
            value={source}
            onChange={handleSourceChange}
          />
        </div>
        <div className={classes.field}>
          <PInput
            type={INPUT_TYPES.float}
            errors={errors.ttcBase ? [String(errors.ttcBase)] : undefined}
            label={t('systems.balance.captions.ttcBase')}
            value={ttcBase}
            onChange={handleTccBaseChange}
          />
        </div>
        <div className={classes.field}>
          <PInput
            type={INPUT_TYPES.float}
            errors={errors.ttcForecast ? [String(errors.ttcForecast)] : undefined}
            label={t('systems.balance.captions.ttcForecast')}
            value={ttcForecast}
            onChange={handleTccForecastChange}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={close}
        >
          {t('common.captions.cancel')}
        </Button>
        <Button
          className={classes.button}
          onClick={handleSave}
          disabled={!isValid || !isChanged}
        >
          {t('common.captions.save')}
        </Button>

      </div>
    </div>
  );
};

export default ModalTtcEdit;
