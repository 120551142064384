import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { useSystemsContext } from 'src/constants';
import style from './index.module.css';

import MacroeconomicsToolbar from '../MacroToolbar';
import MacroeconomicsChart from '../MacroChart';
import MacroeconomicsTable from '../MacroTable';
import { useHandlers, useValues } from './hooks';

interface Props {
  className?: string;
}

const MatrixMacroPage: React.FC<Props> = (props: Props) => {
  const { className } = props;
  const currentSystem = useSystemsContext();

  const {
    entities,
    project,
    form,
    formActions,
  } = useValues(currentSystem);

  const {
    applyCorrection,
    convertToChartData,
    exportData,
  } = useHandlers(currentSystem);

  const [macroeconomics, isLoading] = useMemo(() => (
    project
      ? [entities.getArray({ filter: { project } }), entities.getIsLoadingArray({ filter: { project } })]
      : [[], false]
  ), [entities]);

  const data = useMemo(() => macroeconomics.filter((item) => {
    if (!item.year || !item.region) {
      return false;
    }
    return !(
      (form.yearFrom && item.year < form.yearFrom)
      || (form.yearTo && item.year > form.yearTo)
      || (form.regions.length > 0 && !form.regions.includes(item.region))
    );
  }), [entities, form.yearTo, form.yearFrom, form.regions]);

  const [showMacroeconomics, setShowMacroeconomics] = useState(true);
  const [showMobilityIncrease, setShowMobilityIncrease] = useState(true);
  const [showIncomeGrowth, setShowIncomeGrowth] = useState(true);

  const handleMacroeconomicsToggle = useCallback(
    () => setShowMacroeconomics((value: boolean) => !value),
    [setShowMacroeconomics],
  );

  const handleMobilityIncreaseToggle = useCallback(
    () => setShowMobilityIncrease((value: boolean) => !value),
    [setShowMobilityIncrease],
  );

  const handleIncomeGrowthToggle = useCallback(
    () => setShowIncomeGrowth((value: boolean) => !value),
    [setShowIncomeGrowth],
  );

  const chartData = useMemo(() => convertToChartData(data), [data]);

  const handleExport = useCallback(() => {
    exportData(chartData, 'chart_export.csv');
  }, [chartData]);

  const table = useMemo(() => (
    <MacroeconomicsTable
      data={data}
      isLoading={isLoading}
      showMacroeconomics={showMacroeconomics}
      showMobilityIncrease={showMobilityIncrease}
      showIncomeGrowth={showIncomeGrowth}
      onMacroeconomicsToggle={handleMacroeconomicsToggle}
      onMobilityIncreaseToggle={handleMobilityIncreaseToggle}
      onIncomeGrowthToggle={handleIncomeGrowthToggle}
      onExport={handleExport}
    />
  ), [
    data,
    isLoading,
    showMacroeconomics,
    showMobilityIncrease,
    showIncomeGrowth,
    handleMacroeconomicsToggle,
    handleMobilityIncreaseToggle,
    handleIncomeGrowthToggle,
    handleExport,
  ]);

  return (
    <div className={classNames(style.root, className)}>
      <MacroeconomicsToolbar
        macroeconomics={macroeconomics}
        formActions={formActions}
        form={form}
        applyCorrection={applyCorrection}
      />
      <MacroeconomicsChart
        data={chartData}
        isLoading={isLoading}
        showMacroeconomics={showMacroeconomics}
        showMobilityIncrease={showMobilityIncrease}
        showIncomeGrowth={showIncomeGrowth}
      />
      {table}
    </div>
  );
};

export default MatrixMacroPage;
