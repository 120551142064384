import React, { FC } from 'react';
import { RestifyId, RestifyModel } from 'redux-restify';
import { Maybe } from 'monet';

import { WithTranslate } from 'src/i18n';
import { DataGridContainer } from 'src/components';
import { Model } from 'src/interfaces';

import {
  DataToolbar,
  DataDataGrid,
  areRecordsValid,
} from 'src/cluster/balance-data';

import {
  AcBalanceDataModel,
  AcBalanceDataFactorsModel,
  AcProjectForm,
  AcPageBalanceDataForm,
} from 'src/cluster/common';
import { useCreate, useDelete, useEdit } from './hooks';
import useStyles from './styles';

export interface Props extends WithTranslate {
  pageForm: AcPageBalanceDataForm;
  projectForm: AcProjectForm;
  balanceEntities: [Maybe<Model<AcBalanceDataModel>[]>, number];
  factors: [RestifyModel<AcBalanceDataFactorsModel>[], boolean, number];
  count: number;
  isLoading: boolean;
  updateData(data: AcBalanceDataModel[]): Promise<any>;
  deleteData(ids: RestifyId[]): Promise<any>;
  createData(data: AcBalanceDataModel[]): Promise<any>;
  switchToList(): void;
  warningNotify(): void;
  successNotify(): void;
  onPageChange(value: number): void;
  onPageSizeChange(value: number): void;
  showEditModal(record: Partial<AcBalanceDataModel>, cb: Function): void;
}

const DataPage: FC<Props> = (props) => {
  const {
    t,
    isLoading,
    balanceEntities: [entities, count],
    factors,
    pageForm,
    projectForm: { selectedProject },
    pageForm: {
      pageSize,
      currentPage,
      isEdit,
    },
    updateData,
    deleteData,
    createData,
    switchToList,
    warningNotify,
    successNotify,
    onPageSizeChange,
    onPageChange,
    showEditModal,
  } = props;

  const classes = useStyles();

  const [deleted, deleteRecords, resetDeleted] = useDelete();
  const [edited, editRecords, resetEdited] = useEdit();
  const [created, resetCreated] = useCreate();

  const isSavable = deleted.length + edited.length + created.length > 0
    && areRecordsValid(created)
    && areRecordsValid(edited);

  const handleSaveAll = () => {
    Promise.all([
      deleteData(deleted).then(resetDeleted),
      updateData(edited).then(resetEdited),
      createData(created).then(resetCreated),
    ]).then(switchToList)
      .then(successNotify)
      .catch(warningNotify);
  };

  const handleResetAll = () => {
    resetDeleted();
    resetEdited();
    resetCreated();
  };

  return (
    <div className={classes.root}>
      <DataToolbar
        pageForm={pageForm}
        isSavable={isSavable}
        saveAll={handleSaveAll}
        resetAll={handleResetAll}
      />
      <DataGridContainer
        isLoading={isLoading}
        data={entities}
        message={!selectedProject ? t('modules.projects.captions.projectNotSelected') : undefined}
        currentPage={currentPage}
        pageSize={pageSize}
        count={count}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {(data: AcBalanceDataModel[]) => (
          <DataDataGrid
            data={data}
            factors={factors}
            isEdit={isEdit}
            deleted={deleted}
            created={created}
            edited={edited}
            deleteData={deleteRecords}
            editData={editRecords}
            showEditModal={showEditModal}
          />
        )}
      </DataGridContainer>
    </div>
  );
};

export default DataPage;
