import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ChangeEvent } from 'react';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { isNumber } from 'src/helpers';

import { acBalanceMapFormActions, MapModes, selectTotalTraffic } from 'src/cluster/balance-map';

import BalanceMapForecastFilter from './BalanceMapForecastFilter';

const mapStateToProps = (state: AppState) => ({
  totalTraffic: selectTotalTraffic(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setRegion: (event: any) => {
    dispatch(acBalanceMapFormActions.changeField('region', event.target.value));
  },
  setStarMode: () => {
    dispatch(acBalanceMapFormActions.changeField('mapMode', MapModes.star));
  },
  setHeatMapMode: () => {
    dispatch(acBalanceMapFormActions.changeField('mapMode', MapModes.heat));
  },
  setGroupMode: () => {
    dispatch(acBalanceMapFormActions.changeField('mapMode', MapModes.group));
  },
  setFilterModeFrom: (event: ChangeEvent<{ value: number }>) => {
    if (event.target) {
      dispatch(acBalanceMapFormActions.changeField('filterModeFrom', event.target.value));
    }
  },
  setFilterModeTo: (event: ChangeEvent<{ value: number }>) => {
    if (event.target) {
      dispatch(acBalanceMapFormActions.changeField('filterModeTo', event.target.value));
    }
  },
  setRegionFrom: (values: number[]) => {
    if (values) {
      dispatch(acBalanceMapFormActions.changeField('regionFrom', values));
    }
  },
  setRegionTo: (values: number[]) => {
    if (values) {
      dispatch(acBalanceMapFormActions.changeField('regionTo', values));
    }
  },
  setMunicipalityFrom: (values: number[]) => {
    dispatch(acBalanceMapFormActions.changeField('municipalityFrom', values));
  },
  setMunicipalityTo: (values: number[]) => {
    dispatch(acBalanceMapFormActions.changeField('municipalityTo', values));
  },
  setYear: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(acBalanceMapFormActions.changeField('year', value));
    }
  },
  setScenario: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(acBalanceMapFormActions.changeField('scenarioId', value));
    }
  },
  setInterval: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(acBalanceMapFormActions.changeField('intervalId', value));
    }
  },
  setMinTraffic: (value: number) => {
    dispatch(acBalanceMapFormActions.changeField('minTraffic', value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(BalanceMapForecastFilter));
