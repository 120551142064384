import React from 'react';

const Video = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1103 5.1C13.02 5.1 13.8925 5.46139 14.5358 6.10466C15.179 6.74794 15.5404 7.62041 15.5404 8.53014C15.5404 9.43987 15.179 10.3123 14.5358 10.9556C13.8925 11.5989 13.02 11.9603 12.1103 11.9603C11.2006 11.9603 10.3281 11.5989 9.6848 10.9556C9.04153 10.3123 8.68014 9.43987 8.68014 8.53014C8.68014 7.62041 9.04153 6.74794 9.6848 6.10466C10.3281 5.46139 11.2006 5.1 12.1103 5.1ZM12.1103 13.6753C15.9006 13.6753 18.9706 15.2103 18.9706 17.1055V18.8206H5.25V17.1055C5.25 15.2103 8.31998 13.6753 12.1103 13.6753Z"
        fill="currentColor"
      />
      <path
        d="M7 1H1V6.5M17.5 1H23V6.5M1 17.5V23H7M17.5 23H23V17.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Video;
