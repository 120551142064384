import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({
  palette,
  spacing,
  shape,
  shadows,
}: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    '& $clearButton': {
      fontSize: 10,
      textDecoration: 'none',
      color: palette.info.dark,
    },

    '& $selectIndicator': {
      cursor: 'pointer',
      position: 'absolute',
      width: 12,
      height: 7.5,
      right: 12,
      top: 'calc(50% - 6.5px/2)',
      color: palette.text.primary,
      pointerEvents: 'none',

      '&:disabled': {
        color: palette.grey[500],
      },
    },
  },

  label: {
    paddingBottom: spacing(2),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  inputWrapper: {
    position: 'relative',
    border: `1px solid ${palette.grey[300]}`,
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    display: 'flex',
    flexWrap: 'wrap',

    '&:hover': {
      borderColor: palette.grey[300],
    },

    '&.focused': {
      borderColor: palette.grey[300],
      boxShadow: shadows[1],
    },

    '& input': {
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: '16px',
      boxSizing: 'border-box',
      padding: spacing(2, 6, 2, 1.5),
      minWidth: 30,
      flexGrow: 1,
      border: 0,
      borderRadius: shape.borderRadius,
      margin: 0,
      outline: 0,
      '&::placeholder': {
        fontWeight: 400,
        color: palette.grey[600],
      },
    },
  },

  listBox: {
    width: '100%',
    marginTop: spacing(0.5),
    padding: 0,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: palette.common.white,
    overflow: 'auto',
    maxHeight: 250,
    borderRadius: '6px',
    border: 0,
    boxShadow: shadows[4],
    boxSizing: 'border-box',
    zIndex: 6000,
  },

  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2),
    margin: spacing(1),
    borderRadius: '6px',
    position: 'relative',
    color: palette.text.primary,
    cursor: 'pointer',
    '&.multiple': {
      paddingRight: spacing(12),
    },

    '&:hover': {
      backgroundColor: palette.primary.light,
      '& svg': {
        color: palette.primary.light,
      },
    },

    '& span': {
      flexGrow: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& svg': {
      position: 'absolute',
      color: palette.common.white,
      width: 10,
      right: 15,
      top: 'calc(50% - 10/2)',
      cursor: 'pointer',
    },

    '&[data-focus="true"]&[aria-selected="false"]': {
      backgroundColor: palette.secondary.main,
      cursor: 'pointer',
      color: palette.common.white,
      '& svg': {
        color: 'transparent',
      },
    },

    '&[aria-selected="true"]': {
      backgroundColor: palette.primary.light,
      color: palette.text.primary,
      '& svg': {
        color: palette.text.primary,
      },
      '&:hover': {
        '& svg': {
          color: palette.text.primary,
        },
      },
    },

    '&[aria-disabled="true"]': {
      display: 'none',
    },
  },

  chipWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },

  clearButton: {},

  selectIndicator: {},
}));
