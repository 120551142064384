import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCommonStyles = makeStyles(({ palette, spacing }: Theme) => {
  return createStyles({
    label: {
      fontSize: 12,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    speedLabel: {},
    value: {
      color: palette.text.primary,
      fontWeight: 'bold',
    },
    input: {
      width: 165,
    },
    unit: {
      color: palette.grey[600],
    },
    editing: {
      '& $unit': {
        lineHeight: '36px',
        fontSize: 14,
        color: palette.text.primary,
      },

      '& $speedLabel': {
        marginBottom: spacing(2),
      },
    },
  });
});

export const useGridStyles = makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    container: {
      backgroundColor: palette.common.white,
      padding: spacing(2.5, 0, 2.5, 4),
    },

    item: {
      fontSize: 12,
    },
  });
});
