import React from 'react';

const Traffic = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="5.99979" cy="5.9617" rx="5.14286" ry="5.1099" fill="#E5293C" />
    <path d="M12 5.96154C12 5.17866 11.8448 4.40345 11.5433 3.68016C11.2417 2.95687 10.7998 2.29968 10.2426 1.7461C9.68549 1.19251 9.02405 0.753391 8.2961 0.453795C7.56815 0.154199 6.78793 -4.40319e-07 6 -4.06098e-07C5.21207 -3.71877e-07 4.43185 0.1542 3.7039 0.453795C2.97595 0.753391 2.31451 1.19252 1.75736 1.7461C1.20021 2.29968 0.758251 2.95687 0.456723 3.68016C0.155194 4.40345 -6.88831e-08 5.17866 0 5.96154L0.857143 5.53572C1.28571 4.68407 1.40306 4.35052 1.71429 3.83242C2.0569 3.26207 2.19439 3.05816 2.63614 2.61925C3.07789 2.18033 3.60232 1.83216 4.17949 1.59462C4.75666 1.35708 5.37527 1.23482 6 1.23482C6.62473 1.23482 7.24333 1.35708 7.82051 1.59462C8.39768 1.83216 8.92211 2.18033 9.36386 2.61925C9.80561 3.05816 10.156 3.57923 10.3951 4.15271C10.7143 4.68407 10.7143 4.68407 11.1429 5.53572L12 5.96154Z" fill="#333B46" />
  </svg>
);

export default Traffic;
