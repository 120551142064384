export const ROUTES_MODEL_NAME = 'trafficRoutes';
export const MACRO_CHOICE_MODEL_NAME = 'trafficMacroChoice';
export const MAP_PAGES_FORM_NAME = 'pageTrafficMapForm';
export const NODES_MODEL_NAME = 'trafficNodes';

export const SEA_ROUTE_COLOR = '#0066fe';
export const SEA_ROUTE_BACK_COLOR = '#77b0ff';
export const AIR_ROUTE_COLOR = '#00d9c2';
export const AIR_ROUTE_BACK_COLOR = '#35ffeb';
export const RAIL_ROUTE_COLOR = '#d52a36';
export const RAIL_ROUTE_BACK_COLOR = '#ef5663';
export const AUTO_ROUTE_COLOR = '#069b1e';
export const AUTO_ROUTE_BACK_COLOR = '#39dc56';
