import { api, forms, RestifyId } from 'redux-restify';
import { ThunkActionResult } from 'src/reducer';
import { FORECAST_ENDPOINT, MATRIX_PROJECTS_ENDPOINT } from 'src/api-url-schema';
import i18n from 'src/i18n';
import { downloadFile } from 'src/helpers/file-download';
import modals from 'src/modals';
import { Systems } from 'src/constants';
import { FORECAST_EXPORT_MODAL, EXPORT_TYPES_DICT, ForecastChartRecord } from 'src/modules/matrix/forecast';
import { ExportForecastForm } from 'src/modules/matrix/forecast/forms/exportForecast.form';

export const getForecastActions = (type: Systems) => {
  return {
    deleteForecast: (id: number): ThunkActionResult<Promise<any>> => (dispatch) => {
      return dispatch(api.actions.callDel({
        url: `${MATRIX_PROJECTS_ENDPOINT}${id}/${FORECAST_ENDPOINT}`,
      }));
    },
    buildForecast: (id: number): ThunkActionResult<Promise<any>> => (dispatch) => {
      return dispatch(api.actions.callPost({
        url: `${MATRIX_PROJECTS_ENDPOINT}${id}/${FORECAST_ENDPOINT}`,
      }));
    },
    exportForecast: (id: RestifyId, data: ForecastChartRecord[]): ThunkActionResult<void> => (dispatch) => {
      dispatch(modals.actions.showModal(true, FORECAST_EXPORT_MODAL, {
        forecastId: id,
        data,
        system: type,
      }));
    },
    downloadForecastExport: (id: RestifyId): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
      const state = getState();
      const exportForm = forms.selectors.exportForecastForm.getFormWithNulls<ExportForecastForm>(state);
      return dispatch(api.actions.callGet({
        url: `${MATRIX_PROJECTS_ENDPOINT}${id}/${FORECAST_ENDPOINT}excel/`,
        isBinary: true,
      })).then((res: any) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: 'octet/stream',
          });
          if (exportForm.type) {
            const fileName = i18n.t(EXPORT_TYPES_DICT[exportForm.type]);
            downloadFile(blob, `${fileName}.xlsx`);
          }
        }
      });
    },
  };
};
