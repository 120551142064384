import React from 'react';

import { SegmentType } from 'src/modules/mth/common/interfaces';
import { TRANSPORT_TYPE_COLORS } from 'src/modules/mth/common/constants';

import style from './index.module.css';

interface Props {
  quantity: 'time' | 'distance';
  segmentTypes: SegmentType[];
  total: number;
}
const PERCENT_BAR_WIDTH = 100;
const PERCENT_MIN = 0.05;

const AggloTransportTypePercent: React.FC<Props> = ({
  quantity = 'distance', segmentTypes, total,
}) => {
  const segmentTypesPercents = (segmentTypes
    .filter((segmentType: SegmentType) => !!TRANSPORT_TYPE_COLORS[segmentType.transportType])
    .reduce((memo: SegmentType[], segment: SegmentType) => {
      // collapse segments with same type
      const prev = memo.length ? memo[memo.length - 1] : undefined;
      if (prev && prev.transportType === segment.transportType) {
        prev[quantity] += segment[quantity];
      } else {
        memo.push(segment);
      }
      return memo;
    }, [])
    .map((segmentType: SegmentType) => {
      return {
        ...segmentType,
        percent: total === 0 ? 0 : segmentType[quantity] / total,
      };
    })
  );
  // round up small values
  for (let i = 0; i < segmentTypesPercents.length; i += 1) {
    let roundDiff = 0;
    if (segmentTypesPercents[i].percent < PERCENT_MIN) {
      roundDiff = PERCENT_MIN - segmentTypesPercents[i].percent;
      segmentTypesPercents[i].percent = PERCENT_MIN;
      let max = 0;
      let maxJ = 0;
      for (let j = 0; j < segmentTypesPercents.length; j += 1) {
        // find max and substract diff used to round small segment up
        if (j !== i && segmentTypesPercents[j].percent > max) {
          max = segmentTypesPercents[j].percent;
          maxJ = j;
        }
        if (j === (segmentTypesPercents.length - 1)) {
          segmentTypesPercents[maxJ].percent -= roundDiff;
        }
      }
    }
  }
  return (
    <span className={style.root}>
      {segmentTypesPercents
        .map((segmentType, index, segments) => {
          const segmentClasses = [style.percent];
          const segmentStyle = {
            width: `${segmentType.percent * PERCENT_BAR_WIDTH}%`,
            backgroundColor: TRANSPORT_TYPE_COLORS[segmentType.transportType],
          };
          if (index === 0) {
            segmentClasses.push(style.percentLeft);
          }
          if (index === (segments.length - 1)) {
            segmentClasses.push(style.percentRight);
          }
          return (
            <span
              className={segmentClasses.join(' ')}
              style={segmentStyle}
              key={index}
            />
          );
        })}
    </span>
  );
};

export default AggloTransportTypePercent;
