import React, { PureComponent } from 'react';

import RawInput, { InnerInputProps } from './RawInput';
import {
  VALIDATION_FORMATS,
  INPUT_TYPES,
  InputTypes,
  ERROR_TYPES,
  formatToFunctions,
  formatFromFunctions,
  formatLengthFunctions,
  includeForTypes,
  excludeForTypes,
} from './constants';

export interface InputProps extends Partial<InnerInputProps> {
  type: InputTypes;
  formatted?: boolean;
  value?: string | number;

  validate: {
    checkOnBlur?: boolean;
    required?: boolean;
    maxLen?: number;
    minLen?: number;
    format?: RegExp | {
      regexp: RegExp;
      error: string;
    };
    include?: string[] | RegExp;
    exclude?: string[] | RegExp;
  };
}

/**
 * @deprecated  Use <TextField> instead.
 */
class PInput extends PureComponent<InputProps> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    type: INPUT_TYPES.text,
    validate: {},
  }

  public constructor(props: InputProps) {
    super(props);
    this.getInputSettings = this.getInputSettings.bind(this);
  }

  private getInputSettings() {
    const {
      type,
      formatted = true,
      value,
      validate,
    } = this.props;

    const properties = {
      ...validate,
      formatValue: formatted && formatToFunctions[type] || ((v: string): string => v),
      getValue: formatted && formatFromFunctions[type] || ((v: string): string => v),
      type,
      formatted,
      minLen: validate.minLen || (formatted ? (formatLengthFunctions[type] || (() => 0)) : () => 0)(value),
      include: validate.include || includeForTypes[type],
      exclude: validate.exclude || excludeForTypes[type],
    };

    if (!properties.format) {
      const validateRegexp = VALIDATION_FORMATS[type];
      if (validateRegexp) {
        properties.format = {
          regexp: validateRegexp,
          error: ERROR_TYPES[type],
        };
      }
    }

    return properties;
  }

  public render() {
    return (
      <RawInput {...{
        ...this.props,
        validate: undefined,
        settings: this.getInputSettings(),
      }} />
    );
  }
}

export { InputTypes, INPUT_TYPES } from './constants';

export default PInput;
