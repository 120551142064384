import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: palette.grey[800],
  },
  filter: {
    width: 180,
    marginBottom: spacing(7),
  },
  btn: {
    width: 190,
  },
}));
