export default {
  defaults: {
    selectedRoute: undefined,
    expandedRoute: undefined,

    lengthPercentMax: 10,
    uniqPercentMax: 10,
    from: undefined,
    to: undefined,

    lengthPercentMaxActual: undefined,
    uniqPercentMaxActual: undefined,
    fromActual: undefined,
    toActual: undefined,

    transportScenarios: [],
    goodsGroup: undefined,
    macroeconomicScenario: undefined,
    year: undefined,

    transportScenariosActual: [],
    goodsActual: undefined,
    macroeconomicScenarioActual: undefined,
    yearActual: undefined,
  },
  onRouteChangeReset: {
    selectedRoute: true,
    expandedRoute: true,
    lengthPercentMaxActual: true,
    uniqPercentMaxActual: true,
    fromActual: true,
    toActual: true,
    goodsActual: true,
    transportScenariosActual: true,
    macroeconomicScenarioActual: true,
    yearActual: true,
  },
};
