import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import {
  VIDEO_PAGE_FORM_NAME,
  VIDEO_ROUTE_VIEW_MODAL_NAME,
  VideoVehicleOnRoutesModel,
} from 'src/modules/video/common';

import { OwnProps } from './interfaces';
import VideoTable from './VideoTable';

const mapDispatchToProps = (dispatch: Dispatch<any>, { pageForm }: OwnProps) => ({
  setSelected: (model: VideoVehicleOnRoutesModel) => {
    if (pageForm.selectedRow === model.id) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('selectedRow'));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('selectedRow', model.id));
    }
  },
  showModal: (record: VideoVehicleOnRoutesModel) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('selectedTrip', record.routeTrip.id));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('selectedRoute', record.id));
    dispatch(modals.actions.showModal(true, VIDEO_ROUTE_VIEW_MODAL_NAME));
  },
  onPageChange: (value: number) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('pageSize', value));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(VideoTable));
