import { MODAL_SIZES, registerModal } from 'src/modals';
import { AppState } from 'src/reducer';

import { AC_DASH_TABLE_CUSTOMIZE_MODAL_NAME, ModalDashTableCustomize } from 'src/cluster/dashboard-table';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.medium,
  };
};

export default registerModal(
  AC_DASH_TABLE_CUSTOMIZE_MODAL_NAME,
  stateToProps,
)(ModalDashTableCustomize);
