import React, { ChangeEvent, FC } from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  Checkbox,
  Switch,
  Radio,
  FormControlLabelProps,
  CheckboxProps,
  SwitchProps,
  RadioProps, Box,
} from '@material-ui/core';

import { CheckboxCheckedIcon, CheckboxIcon } from 'src/components';

import useStyle, { useComponentStyle } from './styles';

type InjectedProps = {
  label: string;
  component?: any;
  className?: string;
  beforeIcon?: React.ReactNode;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  viewType?: 'checkbox' | 'switch' | 'radio';
  checked: boolean;
  onChange?: (event: ChangeEvent<{}>, checked: boolean) => void;
  componentProps?: CheckboxProps | SwitchProps | RadioProps;
}

type Props = InjectedProps & Partial<FormControlLabelProps>;

const controlComponents = {
  checkbox: Checkbox,
  switch: Switch,
  radio: Radio,
};

const FormControlLabel: FC<Props> = (props) => {
  const {
    viewType = 'checkbox',
    component: Component = controlComponents[viewType],
    className,
    beforeIcon,
    icon = <CheckboxIcon />,
    label,
    checked,
    checkedIcon = <CheckboxCheckedIcon />,
    componentProps = {},
    onChange,
    ...other
  } = props;

  const classes = useStyle();
  const componentClasses = useComponentStyle();

  const control = (
    <Component
      {...componentProps}
      checked={checked}
      classes={componentClasses}
      className={className}
      icon={icon}
      name={label}
      checkedIcon={checkedIcon}
      onChange={onChange}
    />
  );

  return (
    <Box display="flex" alignItems="center" color="text.primary">
      {beforeIcon}
      <MuiFormControlLabel
        {...other}
        classes={classes}
        control={control}
        label={label}
      />
    </Box>
  );
};

export default FormControlLabel;
