import {
  createStyles, InputBase, Theme, withStyles,
} from '@material-ui/core';

const CommonInput = withStyles((theme: Theme) => createStyles({
  root: {
    'label + &': {
      marginTop: 26,
    },
  },
  input: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    fontSize: 14,
    width: '100%',
    height: 36,
    lineHeight: '36px',
    padding: theme.spacing(0, 2),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'Oxygen', 'SourceSansPro', 'sans-serif',
    ].join(','),

    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },

    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },

    '&:focus': {
      boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.3)',
    },
  },
  error: {
    '& input': {
      borderColor: theme.palette.error.main,
      borderStyle: 'solid',
      borderWidth: 1,
    },
  },
}))(InputBase);

export default CommonInput;
