import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import {
  createFormSelector, isArray, isNumber,
} from 'src/helpers';
import { Model } from 'src/interfaces';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_PROJECT_LIST_FORM_NAME,
  AC_DISTRIBUTION_MAP_PAGE_FORM_NAME as MAP_PAGE_FORM_NAME,
  AcProjectForm,
  AcRoutesModel,
  AcScenariosModel,
  isAcScenariosModel,
  isAcRoutesModel,
} from 'src/cluster/common';
import {
  selectRouteEntities,
  selectScenarioEntities,
} from 'src/cluster/editor-map';
import {
  DISTRIBUTION_GEOJSON_FORM_NAME as GEOJSON_FORM_NAME,
} from 'src/cluster/distribution-common';
import {
  AcDistributionMapPageForm,
  AcDistributionGeojsonForm,
  isAcDistributionExtreme,
} from 'src/cluster/distribution-map';

export const selectAcProjectPageForm = createFormSelector<AcProjectForm>(AC_PROJECT_LIST_FORM_NAME);
export const selectDistributionMapPageForm = createFormSelector<AcDistributionMapPageForm>(MAP_PAGE_FORM_NAME);
export const selectDistributionGeojsonForm = createFormSelector<AcDistributionGeojsonForm>(GEOJSON_FORM_NAME);
import { selectDistGraphForm } from 'src/cluster/distribution-common/store/selectors';
import { selectAcProjectListForm } from 'src/cluster/common/store/selectors/common';
export const selectDistScenarios = createSelector(
  [
    selectAcProjectListForm,
    selectDistGraphForm,
    selectScenarioEntities,
  ],
  (projectsListForm, distGraphForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = distGraphForm;

    if (!isNumber(selectedProject) || !isNumber(year)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios)) {
      return Nothing();
    }

    if (!isAcScenariosModel(scenarios[0])) {
      return Nothing();
    }

    return Just(scenarios);
  },
);

export const selectPageForm = createSelector(
  selectDistributionMapPageForm,
  (pageDataForm: AcDistributionMapPageForm) => pageDataForm,
);

export const selectEdgesExtreme = createSelector(
  selectDistributionGeojsonForm,
  ({ edgesExtreme }): [number, number] => {
    if (!isAcDistributionExtreme(edgesExtreme) || edgesExtreme.maximum === 0) {
      return [0, 10000];
    }
    const { minimum, maximum } = edgesExtreme;
    return [minimum, maximum];
  },
);

export const selectStopsExtreme = createSelector(
  selectDistributionGeojsonForm,
  ({ stopsExtreme }): [number, number] => {
    if (!isAcDistributionExtreme(stopsExtreme) || stopsExtreme.maximum === 0) {
      return [0, 10000];
    }
    const { minimum, maximum } = stopsExtreme;
    return [minimum, maximum];
  },
);

export const selectDistributionMapRoutesByVehicleType = createSelector(
  [
    selectAcProjectListForm,
    selectDistGraphForm,
    selectDistributionMapPageForm,
    selectRouteEntities,
  ],
  (projectForm, graphForm, pageForm, routesEntities): Maybe<Model<AcRoutesModel>[]> => {
    const { selectedProject } = projectForm;
    const { year, scenarioId } = graphForm;
    const { vehicleTypeId } = pageForm;
    if (!isNumber(selectedProject) || !isNumber(vehicleTypeId) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        vehicleTypeId,
        year,
        scenarioId,
      },
    };

    const routes = routesEntities.getArray(routeApiConfig);
    const isLoading = routesEntities.getIsLoadingArray(routeApiConfig);

    if (isLoading || !isArray(routes)) {
      return Nothing();
    }

    if (routes.length > 0 && !isAcRoutesModel(routes[0])) {
      return Nothing();
    }

    return Just(routes);
  },
);

export const selectDistributionMapSelectedRoute = createSelector(
  [
    selectAcProjectListForm,
    selectDistributionMapPageForm,
    selectRouteEntities,
  ],
  (projectForm, pageForm, routeEntities): Maybe<Model<AcRoutesModel>> => {
    const { selectedProject } = projectForm;

    const { routeId } = pageForm;

    if (!isNumber(routeId) || !isNumber(selectedProject)) {
      return Nothing();
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const route = routeEntities.getById(routeId, routeApiConfig);

    // FIXME: maybe an issue of redux-restify
    // const isLoading = routeEntities.getIsLoadingById(routeId, routeApiConfig);
    const isLoading = route.$loading;

    if (isLoading || !isAcRoutesModel(route)) {
      return Nothing();
    }

    return Just(route);
  },
);
