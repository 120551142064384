import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  toggle: {
    position: 'absolute',
    top: 15,
    right: 24,
  },
}));
