import mergeWith from 'lodash/mergeWith';

import { isPureObject } from './deprecated/def';

export const reduceObject = (
  predicate: (key: string, value: any, obj: any) => boolean,
  recursive = true,
) => (obj: any): any => {
  if (!isPureObject(obj)) return obj;
  if (Array.isArray(obj)) {
    return obj.map(item => reduceObject(predicate, recursive)(item));
  }
  return Object.keys(obj).reduce((memo, key) => {
    if (predicate(key, obj[key], obj)) return memo;
    return {
      ...memo,
      [key]: recursive ? reduceObject(predicate, recursive)(obj[key]) : obj[key],
    };
  }, {});
};

const arraysCustomizer = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
};

export const mergeAndConcatArrays = (...args: any[]) => {
  return (mergeWith as any)(...args, arraysCustomizer);
};
