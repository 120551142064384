import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import ModalVideoRouteView from '../components/ModalVideoRouteView';

import { VIDEO_ROUTE_VIEW_MODAL_NAME } from '../constants';

import style from './index.module.css';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.large,
    className: style.root,
  };
};

export default registerModal(
  VIDEO_ROUTE_VIEW_MODAL_NAME,
  stateToProps,
)(ModalVideoRouteView);
