import { RestifyId } from 'redux-restify';

import { ProcessStatus, StrictDict } from 'src/types';
import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';
import {
  SHIPMENTS_PROJECTS_ENDPOINT,
  CITIES_PROJECTS_ENDPOINT,
  MATRIX_PROJECTS_ENDPOINT,
  TRAFFIC_PROJECTS_ENDPOINT,
  AGGLOMERATION_PROJECTS_ENDPOINT,
} from 'src/api-url-schema';

export const PROJECTS_ENDPOINTS = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: SHIPMENTS_PROJECTS_ENDPOINT,
  [Systems.cities]: CITIES_PROJECTS_ENDPOINT,
  [Systems.matrix]: MATRIX_PROJECTS_ENDPOINT,
  [Systems.traffic]: TRAFFIC_PROJECTS_ENDPOINT,
  [Systems.mth]: AGGLOMERATION_PROJECTS_ENDPOINT,
};

export const PROJECTS_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsProjects',
  [Systems.cities]: 'citiesProjects',
  [Systems.matrix]: 'matrixProjects',
  [Systems.traffic]: 'trafficProjects',
  [Systems.mth]: 'agglomerationProjects',
};

export const PROJECTS_PAGES_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'pageShipmentsProjectsForm',
  [Systems.cities]: 'pageCitiesProjectsForm',
  [Systems.matrix]: 'pageMatrixProjectsForm',
  [Systems.traffic]: 'pageTrafficProjectsForm',
  [Systems.mth]: 'pageAgglomerationProjectsForm',
};

export const PROJECT_STATUSES_DICT: StrictDict<ProcessStatus, string> = {
  [ProcessStatus.QUEUED]: 'modules.projects.statuses.queued',
  [ProcessStatus.IN_PROGRESS]: 'modules.projects.statuses.inProgress',
  [ProcessStatus.SUCCESS]: 'modules.projects.statuses.success',
  [ProcessStatus.ERROR]: 'modules.projects.statuses.error',
};

export const getProjectFormName = (system: Systems, id?: RestifyId) => `${id || 'new'}Edit${system}ProjectForm`;
