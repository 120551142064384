import { forms } from 'redux-restify';

import { ThunkActionResult } from 'src/reducer';
import { Systems } from 'src/constants';
import {
  ROUTES_PAGES_FORM_NAMES,
  PageRoutesForm,
} from '../constants';

import selectors from '../selectors';

export const getRoutesActions = (type: Systems) => {
  const pageFormName = ROUTES_PAGES_FORM_NAMES[type];
  const currentSelectors = selectors[type];
  const actions = {
    findRoutes: (): ThunkActionResult<ReturnType<typeof currentSelectors['asyncGetCurrentProjectRoutes']>> => (
      dispatch,
      getState,
    ) => {
      let state = getState();
      const routesPageForm = forms.selectors[pageFormName].getFormWithNulls<PageRoutesForm>(state);
      dispatch(forms.actions[pageFormName].changeSomeFields({
        lengthPercentMaxActual: routesPageForm.lengthPercentMax,
        uniqPercentMaxActual: routesPageForm.uniqPercentMax,
        fromActual: routesPageForm.from,
        toActual: routesPageForm.to,
        transportScenariosActual: routesPageForm.transportScenarios,
        goodsActual: routesPageForm.goodsGroup,
        macroeconomicScenarioActual: routesPageForm.macroeconomicScenario,
        yearActual: routesPageForm.year,
      }));
      state = getState();
      return currentSelectors.asyncGetCurrentProjectRoutes(state);
    },
  };
  return actions;
};
