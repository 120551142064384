import { DEFAULT_SELECTED_LOCALE } from 'src/i18n';
import { Locale } from 'src/types';

export interface LocaleServiceForm {
  selectedLocale: Locale;
}

export default {
  defaults: {
    selectedLocale: DEFAULT_SELECTED_LOCALE,
  },
};
