import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { api, forms } from 'redux-restify';

import {
  ROUTES_AND_OVERSHOT_CALCULATION_MODEL_NAME,
  ROUTES_PAGES_FORM_NAME,
} from 'src/modules/mth/calculations';
import AggloCalcMap from './AggloCalcMap';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetActualParams: () => {
    dispatch(forms.actions[ROUTES_PAGES_FORM_NAME].resetField('from'));
    dispatch(forms.actions[ROUTES_PAGES_FORM_NAME].resetField('to'));
    dispatch(api.actions.entityManager[ROUTES_AND_OVERSHOT_CALCULATION_MODEL_NAME].clearData());
  },
});

export default connect(null, mapDispatchToProps)(AggloCalcMap);
