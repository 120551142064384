import React, {
  FC, useState, Fragment, MouseEvent,
} from 'react';
import classNames from 'classnames';
import { RestifyId } from 'redux-restify';
import { Link } from 'react-router-dom';

import { ProcessStatus } from 'src/types';
import { useSystemsContext } from 'src/constants';
import { useRestifyModel, useRestifyForm } from 'src/helpers';
import { WithTranslate } from 'src/i18n';
import {
  urlSchema,
  getUrl,
  ANALYTIC_PERMISSIONS_MAP,
} from 'src/pages';
import { PROJECTS_PAGES_FORM_NAMES, PROJECTS_MODEL_NAMES } from 'src/modules/general/projects';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import { AuthInfoForm } from 'src/auth/forms/authInfo.form';

import {
  createStyles, Fade, makeStyles, Popover as MuiPopover,
} from '@material-ui/core';
import { ChevronDownIcon } from 'src/components';
import clsx from 'clsx';
import { ProjectModel, PageProjectForm } from '../../interfaces';
import { InjectedProps, OwnProps } from './intrerfaces';

import style from './index.module.css';

export const overridePopover = makeStyles((theme) => createStyles({
  root: {
    borderRadius: 0,
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
    opacity: 1,
    boxShadow: theme.shadows[1],
  },
}), { name: 'MuiPopover' });

type Props = OwnProps & InjectedProps & WithTranslate;

const ProjectSelectorDropDown: FC<Props> = (props: Props) => {
  const {
    className,
    t,
    changeProject,
  } = props;

  overridePopover();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const currentSystem = useSystemsContext();

  const [projectsEntities] = useRestifyModel<ProjectModel>(PROJECTS_MODEL_NAMES[currentSystem]);
  const [pageProjectForm] = useRestifyForm<PageProjectForm>(PROJECTS_PAGES_FORM_NAMES[currentSystem]);
  const projects = projectsEntities.getArray();
  const currentProject = projectsEntities.getById(pageProjectForm.selectedProject);

  const analyticPermission = ANALYTIC_PERMISSIONS_MAP[currentSystem];
  const [{ permissions }] = useRestifyForm<AuthInfoForm>('authInfoForm');
  const addProjectPermission = permissions.indexOf(analyticPermission) >= 0;

  const makeClickHandler = (id: RestifyId) => () => {
    changeProject(id, currentSystem);
    handleClose();
  };

  const validProjects = projects.filter((project) => {
    return !project.loadStatus || project.loadStatus === ProcessStatus.SUCCESS;
  });

  return (
    <Fragment>
      <div className={classNames(style.root, className)}>
        <button
          className={style.currentProject}
          onClick={handleClick}
        >
          <div className={style.projectName}>
            {currentProject.name || t('modules.projects.captions.projectNotSelected')}
          </div>
          <div className={clsx(style.popupIcon, open && style.openedPopupIcon)}>
            <ChevronDownIcon />
          </div>
        </button>
        <MuiPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 60,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Fade}
        >
          <div className={style.popup}>
            <div className={style.projects}>
              {validProjects.map(project => (
                <div
                  key={project.id}
                  className={pageProjectForm.selectedProject === project.id ? style.selectedProject : style.project}
                  onClick={makeClickHandler(project.id)}
                >
                  <div className={style.projectName}>
                    {project.name}
                  </div>
                  {pageProjectForm.selectedProject === project.id && (
                    <PIcon
                      className={style.checkIcon}
                      type={ICONS_TYPES.checkMark}
                    />
                  )}
                </div>
              ))}
            </div>
            {addProjectPermission && (
              <Link
                className={style.loadNewFileButton}
                to={getUrl(urlSchema[currentSystem].projects)}
              >
                {t('modules.projects.captions.loadNewFile')}
              </Link>
            )}
          </div>
        </MuiPopover>
      </div>
    </Fragment>
  );
};

export default ProjectSelectorDropDown;
