import { snakeToCamel, camelToLowerSnake } from 'src/helpers';
const context = require.context('./components', true, /\.([jt])s$/);

export const ICON_COMPS: any = context.keys().reduce((memo, key) => ({
  ...memo,
  [snakeToCamel(camelToLowerSnake(key.replace(/\.\/|\.([jt])s/g, '')))]: context(key).default,
}), {});

export const ICONS_TYPES: any = {};

Object.keys(ICON_COMPS).forEach(item => { ICONS_TYPES[item] = item; });

const UP = 'up';
const RIGHT = 'right';
const DOWN = 'down';
const LEFT = 'left';

enum LabelPositionTypes {
  tooltip = 'tooltip',
  right = 'right',
  left = 'left',
}

export const LABEL_POSITION_TYPES = LabelPositionTypes;

enum RotateTypes {
  up = 'up',
  right = 'right',
  down = 'down',
  left = 'left',
}

export const ROTATE_TYPES = RotateTypes;

interface RotateValues {
  [key: string]: number;
}

export const ROTATE_VALUES: RotateValues = {
  [UP]: 0,
  [RIGHT]: 90,
  [DOWN]: 180,
  [LEFT]: 270,
};
