import React, { ChangeEvent, FC, Fragment } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import {
  Button, SelectField, ToolbarBlock, ToolbarControl, ToolbarControls, WarningIcon,
} from 'src/components';

import { AcEditorCopyNetworkForm, useAcYearOptions } from 'src/cluster/common';
import { useEditorCopyingNetworkScenarioOptions, useEditorCopyingNetworkValues } from 'src/cluster/editor-common';

import { IgnoredRouteDirectionList } from './components';

import useStyles from './styles';

export interface InjectedProps {
  form: AcEditorCopyNetworkForm;
  setYear(event: ChangeEvent<{ value: unknown }>): void;
  setScenario(event: ChangeEvent<{ value: unknown }>): void;
  copy(): void;
  cancel(): void;
  confirm(): void;
  complete(): void;
  switchToNewNetwork(): void;
}

type Props = InjectedProps & WithTranslate;

const ModalEditorCopyNetwork: FC<Props> = (props) => {
  const {
    form: {
      isCopying, isAwaitingConfirmation, isSuccess, isError,
    },
    t,
    setYear,
    setScenario,
    copy,
    cancel,
    confirm,
    complete,
    switchToNewNetwork,
  } = props;

  const classes = useStyles();

  const yearOptions = useAcYearOptions();
  const scenarioOptions = useEditorCopyingNetworkScenarioOptions();

  const [year, scenarioId] = useEditorCopyingNetworkValues();

  const showApply = !isAwaitingConfirmation && !isSuccess && !isError;
  const showWarning = showApply && !isCopying;
  const disableSelects = !showWarning;
  const isReady = showApply && year && scenarioId && !isCopying;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t('systems.editor.captions.copyingNetwork')}</Typography>
        <Typography>{t('systems.editor.messages.selectYearScenarioIntervalForCopying')}</Typography>
      </div>
      <ToolbarBlock className={classes.container}>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <SelectField
              label={t('common.captions.year')}
              value={year}
              options={yearOptions}
              disabled={disableSelects}
              fullWidth
              onChange={setYear}
            />
          </ToolbarControl>
        </ToolbarControls>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <SelectField
              label={t('common.captions.scenario')}
              value={scenarioId}
              options={scenarioOptions}
              disabled={!year || disableSelects}
              fullWidth
              onChange={setScenario}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      {isAwaitingConfirmation && (
        <IgnoredRouteDirectionList />
      )}
      <div className={classes.footer}>
        <div className={classes.status}>
          {showWarning && (
            <div className={classes.warning}>
              <div className={classes.icon}>
                <WarningIcon />
              </div>
              <Typography>
                {t('systems.editor.messages.copyNetworkWarning')}
              </Typography>
            </div>
          )}
          {isCopying && (
            <div className={classes.preloader}>
              <CircularProgress
                size={20}
                thickness={7}
                value={100}
                color="primary"
              />
            </div>
          )}
          {isCopying && (
            <Typography>
              {t('systems.editor.captions.copyingInProgress')}
            </Typography>
          )}
          {isSuccess && (
            <Typography className={classes.success}>
              {t('systems.editor.captions.copiedSuccessfully')}
            </Typography>
          )}
          {isError && (
            <Typography className={classes.error}>
              {t('systems.editor.captions.copyError')}
            </Typography>
          )}
        </div>
        <div className={classes.buttons}>
          {showApply && (
            <Button disabled={!isReady} onClick={copy}>{t('common.captions.apply')}</Button>
          )}
          {isSuccess && (
            <Fragment>
              <Button variant="outlined" onClick={complete}>{t('common.captions.ok')}</Button>
              <Button onClick={switchToNewNetwork}>{t('systems.editor.captions.switchToNewNetwork')}</Button>
            </Fragment>
          )}
          {isAwaitingConfirmation && (
            <Fragment>
              <Button variant="outlined" onClick={cancel}>{t('common.captions.cancel')}</Button>
              <Button onClick={confirm}>{t('common.captions.continue')}</Button>
            </Fragment>
          )}
          {isError && (
            <Button onClick={complete}>{t('common.captions.ok')}</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalEditorCopyNetwork;
