import React, { FC } from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import modals from 'src/modals';
import {
  AuthPage, FREIGHT_PAGE_URL, LOGIN_PAGE_URL, NEW_PROJECT_PAGE_URL, RECOVERY_PAGE_URL,
} from 'src/auth';
import { RouteScheme } from 'src/pages';
import { DEFAULT_SCROLLING_CONTAINER_ID } from 'src/constants';

import ShipmentsRoot from 'src/pages/roots/ShipmentsRoot';
import CitiesRoot from 'src/pages/roots/CitiesRoot';
import MatrixRoot from 'src/pages/roots/MatrixRoot';
import TrafficRoot from 'src/pages/roots/TrafficRoot';
import AgglomerationRoot from 'src/pages/roots/MthRoot';
import VideoRoot from 'src/pages/roots/VideoRoot';
import AcRoot from 'src/pages/roots/AcRoot';

import MainMenu from '../MainMenu';

import useStyles from './styles';

interface Props{
  isAuthenticated?: boolean;
  match?: any;
  resetForms(): void;
}

const App: FC<Props> = (props) => {
  const {
    isAuthenticated,
    match,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {React.createElement(modals.container)}
      {!isAuthenticated && (
        <Switch>
          <Route
            path={LOGIN_PAGE_URL}
            component={AuthPage}
          />
          <Route
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: LOGIN_PAGE_URL,
                  state: {
                    nextUrl: location.pathname,
                  },
                }}
              />
            )}
          />
        </Switch>
      )}
      {isAuthenticated && (
        <div className={classes.mainApp}>
          <Route
            path={LOGIN_PAGE_URL}
            render={() => <Redirect to="/" />}
          />
          <Route
            path={RECOVERY_PAGE_URL}
            render={() => <Redirect to="/" />}
          />
          <Route
            path={NEW_PROJECT_PAGE_URL}
            render={async ({ location }) => {
              if (location.pathname === NEW_PROJECT_PAGE_URL) {
                await window.location.replace(FREIGHT_PAGE_URL);
              }
              return null;
            }}
          />
          <MainMenu
            className={classes.menu}
          />
          <div id={DEFAULT_SCROLLING_CONTAINER_ID} className={classes.content}>
            <RouteScheme
              prevMatch={match}
              renderers={{
                shipments: {
                  component: ShipmentsRoot,
                },
                cities: {
                  component: CitiesRoot,
                },
                matrix: {
                  component: MatrixRoot,
                },
                traffic: {
                  component: TrafficRoot,
                },
                mth: {
                  component: AgglomerationRoot,
                },
                agglomeration: {
                  component: AcRoot,
                },
                video: {
                  component: VideoRoot,
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
