import React, { CSSProperties, FC, MouseEvent } from 'react';
import clsx from 'clsx';

import {
  BusIcon,
  AutomobileIcon,
  TrolleybusIcon,
  TramwayIcon,
  MetroIcon,
  PedestrianIcon,
  UnknownVehicleIcon,
  SuburbanIcon,
  WaterIcon,
  RopeWayIcon,
  FunicularIcon,
  MonorailIcon,
} from 'src/components';

import { VehicleType, VEHICLE_TYPES_COLORS } from 'src/cluster/common';

import useStyles from './styles';

interface Props {
  type?: VehicleType;
  className?: string;
  style?: CSSProperties;
  checked: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const VEHICLE_MAP: Record<VehicleType, JSX.Element> = {
  [VehicleType.bus]: <BusIcon />,
  [VehicleType.auto]: <AutomobileIcon />,
  [VehicleType.trolleybus]: <TrolleybusIcon />,
  [VehicleType.tramway]: <TramwayIcon />,
  [VehicleType.metro]: <MetroIcon />,
  [VehicleType.pedestrian]: <PedestrianIcon />,
  [VehicleType.suburban]: <SuburbanIcon />,
  [VehicleType.water]: <WaterIcon />,
  [VehicleType.ropeway]: <RopeWayIcon />,
  [VehicleType.funicular]: <FunicularIcon />,
  [VehicleType.monorail]: <MonorailIcon />,
  [VehicleType.all]: <UnknownVehicleIcon />,
};

const VehicleToggle: FC<Props> = (props) => {
  const {
    type,
    className,
    style,
    checked,
    disabled = false,
    onClick,
  } = props;

  const classes = useStyles();

  const rootClassName = clsx(classes.root, checked && classes.checked, className);

  if (type && type in VehicleType) {
    const cssProperties = {
      backgroundColor: checked ? VEHICLE_TYPES_COLORS[type] : undefined,
      ...style,
    };
    return (
      <button
        className={rootClassName}
        style={cssProperties}
        disabled={disabled}
        onClick={onClick}
      >
        {VEHICLE_MAP[type]}
      </button>
    );
  }

  return (
    <button
      className={rootClassName}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      <UnknownVehicleIcon />
    </button>
  );
};

export default VehicleToggle;
