/* eslint-disable max-len */
import React from 'react';

const ContactBook = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M467.7,0H97.1C77.5,0,61.6,15.9,61.6,35.6v64.1H34.3c-14.1,0-25.5,11.4-25.5,25.5v12.3c0,14.1,11.4,25.5,25.5,25.5h27.3  v61.2H34.3c-14.1,0-25.5,11.4-25.5,25.5v12.3c0,14.1,11.4,25.5,25.5,25.5h27.3v61.2H34.3c-14.1,0-25.5,11.4-25.5,25.5v12.4  c0,14.1,11.4,25.5,25.5,25.5h27.3v64.1c0,19.7,15.9,35.6,35.6,35.6h370.6c19.7,0,35.6-15.9,35.6-35.6V35.7C503.4,16,487.4,0,467.7,0  z M404.6,390.8H161.8c-14.8-0.6-14.8-0.6-15.5-15.5v-7.2c0-18.8,8.8-36.7,24-47.9c30.4-22.6,59.5-37.2,66.1-40.5  c0.8-0.4,1.4-1.3,1.4-2.2c0,0-15.8-38.1-15.7-67.3c0.5-78.4,49.9-79.1,61.5-79.1c11.2,0,61.1,1.4,61.2,79.1  c-0.2,39.7-15.7,67.3-15.7,67.3c0,0.9,0.5,1.8,1.4,2.2c6.6,3.2,35.8,18,66.1,40.5c15.2,11.2,24,29.1,24,47.9v7.2  C419.4,390.1,419.4,390.1,404.6,390.8z" />
  </svg>
);

export default ContactBook;
