import { Feature, Properties } from '@turf/turf';
import { FeatureCollection } from 'src/interfaces';
import { EditEvent, LayerClickInputHandler } from 'src/cluster/editor-map/interfaces';
import { LayerInputHandler } from '@deck.gl/core/lib/layer';
import { ClickEvent } from '@nebula.gl/edit-modes';

export type LayerProps = {
  id: string;
  data: FeatureCollection;
  pickable?: boolean;
  visible?: boolean;
  pickingRadius?: number;
  selectedFeatureIndexes?: number[];
  pointRadiusMinPixels?: number;
  pointRadiusMaxPixels?: number;
  getLineWidth: (f: any) => any;
  getRadius?: (f: any) => any;
  getLineColor: (feature: any, isSelected: any, mode: any) => number[];
  getFillColor: (feature: any, isSelected: any, mode: any) => number[];
  onClick?: LayerClickInputHandler;
  onHover?: LayerInputHandler;
  onEdit?: (event: EditEvent) => void;
  selectedRadius?: (feature: any, isSelected: any) => any;
  onLayerClick?(event: ClickEvent): void;
  _subLayerProps?: {};
}

export type PolygonLayerProps = {
  id: string;
  data: FeatureCollection;
  pickable?: boolean;
  pickingRadius?: number;
  selectedFeatureIndexes?: number[];
  pointRadiusMinPixels?: number;
  pointRadiusMaxPixels?: number;
  getLineWidth: (f: any) => any;
  getRadius?: (f: any) => any;
  getLineColor: (feature: any, isSelected: any, mode: any) => number[];
  getFillColor: (feature: any, isSelected: any, mode: any) => number[];
  onClick?: LayerClickInputHandler;
  onHover?: LayerInputHandler;
}

export enum EditType {
  movePosition = 'movePosition',
  removePosition = 'removePosition',
  addPosition = 'addPosition',
  finishMovePosition = 'finishMovePosition',
  addFeature = 'addFeature',
  updateTentativeFeature = 'updateTentativeFeature',
  addTentativePosition = 'addTentativePosition',
}

export enum EditorMode {
  view = 'view',
  modify = 'modify',
  graph = 'graph',
  addPoint = 'addPoint',
  removePoint = 'removePoint',
  modifyStop = 'modifyStop',
  moveStop = 'moveStop',
  addStop = 'addStop',
  modifyEdge = 'modifyEdge',
}

export type StopFeature = Feature<any, Properties>

export type RouteFeature = Feature<any, Properties>
