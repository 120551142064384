import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';

import { AcRoutesModel } from 'src/cluster/common';
import { selectEditorRoutesByVehicleType } from 'src/cluster/editor-map';

const useRouteNumbers = (): SelectMenuOption[] => {
  const routeNumbers = useSelector(selectEditorRoutesByVehicleType);

  return useMemo(() => {
    return routeNumbers.orJust([]).map((type: AcRoutesModel) => ({
      id: `${type.id}`,
      value: type.id,
      label: type.routeNumber,
    }));
  }, [routeNumbers]);
};

export default useRouteNumbers;
