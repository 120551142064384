import React, { PureComponent } from 'react';
import classNames from 'classnames';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import LoadingIndicator from 'src/components/etc/LoadingIndicator';
import { WithTranslate } from 'src/i18n';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  disabled?: boolean;
  isExpandable?: boolean;
  fixedSizeOnExpand?: boolean;
  isLoading?: boolean;
  size?: number;
  onClick: () => void;
}

class CalcResultMapButton extends PureComponent<Props> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    disabled: false,
    isExpandable: false,
    fixedSizeOnExpand: true,
    isLoading: false,
    size: 25,
  }

  public render() {
    const {
      className,
      onClick,
      disabled = false,
      isExpandable = false,
      fixedSizeOnExpand = true,
      isLoading,
      size = 5,
      t,
    } = this.props;

    return (
      <div {...{
        className: classNames(
          style.root,
          className,
          disabled && style.disabled,
          isExpandable && style.expandable,
          fixedSizeOnExpand && style.fixedExpandable,
        ),
        style: {
          width: isExpandable ? size * 1.25 : undefined,
          height: isExpandable ? size * 1.25 : undefined,
        },
        title: t('components.captions.showMap'),
        onClick: disabled || isLoading ? undefined : onClick,
      }} >
        <span {...{
          className: fixedSizeOnExpand ? style.fixedWrapper : style.wrapper,
          style: {
            padding: isExpandable ? size * 0.25 / 2 : undefined,
          },
        }}>
          {!isLoading && (
            <PIcon {...{
              type: ICONS_TYPES.calculationMapButton,
              className: style.exportIcon,
              size,
            }} />
          )}
          {isLoading && <LoadingIndicator size={size} />}
        </span>
      </div>
    );
  }
}

export default CalcResultMapButton;
