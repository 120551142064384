import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getVocabularyValue, useCurrentVocabulary } from 'src/i18n';
import routes from 'src/modules/mth/routes';

interface NodeItems {
  fromItems: { value: string; label: string }[];
  toItems: { value: string; label: string }[];
}

export const useNodes = (): NodeItems => {
  const [nodes] = useSelector(routes.selectors.selectCurrentProjectNodes);
  const [locale, vocabulary] = useCurrentVocabulary();

  const fromItems = useMemo(() => nodes.map(n => ({
    value: n.name,
    label: getVocabularyValue(locale, vocabulary, n.name),
  })), [nodes, locale, vocabulary]);

  const toItems = useMemo(() => nodes.map(n => ({
    value: n.name,
    label: getVocabularyValue(locale, vocabulary, n.name),
  })), [nodes, locale, vocabulary]);

  return {
    fromItems,
    toItems,
  };
};
