import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

import style from './index.module.css';

/**
 * @deprecated  Use <Button> instead.
 */
const PButton: React.FC<{
  onClick?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  className?: string;
  type?: 'default' | 'white' | 'transparent' | 'link' | 'icon';
  component?: any;
}> = ({
  onClick,
  disabled = false,
  className,
  type = 'default',
  children,
  component: Component = 'button',
}) => {
  return (
    <Component
      className={classNames(style.root, style[type], disabled && style.disabled, className)}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </Component>
  );
};

export default PButton;
