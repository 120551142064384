import React, { FC, ChangeEvent } from 'react';
import {
  Tab as MuiTab, Tabs as MuiTabs, makeStyles, createStyles,
} from '@material-ui/core';
import { ffOxygen } from 'src/theme/typography';

const overrideTabsStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 3,
  },
}), { name: 'MuiTabs' });

const overrideTabStyles = makeStyles((theme) => createStyles({
  root: {
    fontFamily: ffOxygen,
    fontSize: 14,
    textTransform: 'initial',
    fontWeight: 'normal',

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  selected: {
    fontWeight: 'bold',
  },
}), { name: 'MuiTab' });

interface Props {
  className?: string;
  value: number;
  labels: string[];
  onChange(event: ChangeEvent<object>, newValue: number): void;
}

const Tabs: FC<Props> = (props: Props) => {
  const {
    className,
    value,
    labels,
    onChange,
  } = props;

  overrideTabsStyles();
  overrideTabStyles();

  return (
    <MuiTabs {...{
      className,
      value,
      onChange,
    }}>
      {labels.map((label, index) => (
        <MuiTab {...{
          key: index,
          label,
          disableFocusRipple: true,
          disableRipple: true,
        }} />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
