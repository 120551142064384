import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';

import ModalBalanceShapesShow from './ModalBalanceShapesShow';

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  close: () => dispatch(closeAction),
});

export default connect(null, mapDispatchToProps)(withTranslate(ModalBalanceShapesShow));
