import { connect } from 'react-redux';

import i18n, { withTranslate } from 'src/i18n';

import app from 'src/app';
import { AppState } from 'src/reducer';
import modals from 'src/modals';

import { AcBalanceDataModel, selectAcProjectListForm } from 'src/cluster/common';
import { projectLoadsManager } from 'src/cluster/projects';
import { selectDataFactors } from 'src/cluster/balance-common';
import {
  updateData,
  deleteData,
  createData,
  selectBalanceData,
  AC_DATA_EDIT_MODAL_NAME,
  selectPageForm,
  dataFormActions,
} from 'src/cluster/balance-data';

import DataPage from './DataPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectPageForm(state),
  projectForm: selectAcProjectListForm(state),
  balanceEntities: selectBalanceData(state),
  factors: selectDataFactors(state),
  isLoading: projectLoadsManager.selectIsDownloadingUrl(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateData: (data: AcBalanceDataModel[]) => dispatch(updateData(data)),
  deleteData: (ids: number[]) => dispatch(deleteData(ids)),
  createData: (data: AcBalanceDataModel[]) => dispatch(createData(data)),
  switchToList: () => dispatch(dataFormActions.changeField('isEdit', false)),
  warningNotify: () => dispatch(app.actions.toast.warning(i18n.t('modules.data.messages.warning'))),
  successNotify: () => dispatch(app.actions.toast.success(i18n.t('modules.data.messages.success'))),
  onPageChange: (value: number) => {
    dispatch(dataFormActions.changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(dataFormActions.changeField('pageSize', value));
    dispatch(dataFormActions.resetField('currentPage'));
  },
  showEditModal: (record: AcBalanceDataModel, onEdit: Function) => {
    dispatch(modals.actions.showModal(true, AC_DATA_EDIT_MODAL_NAME, { record, onEdit }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DataPage));
