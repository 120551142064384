import React from 'react';

const TransportTypeCar = () => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 6.3C14 5.42107 13.455 4.69979 12.7529 4.57566L11.6783 1.0045L11.6667 0.969485C11.4158 0.370999 10.9171 0 10.36 0H3.63707C3.08 0 2.58124 0.370999 2.33333 0.969485L1.24475 4.57587C0.541748 4.7009 0 5.4218 0 6.3V10.85H0.875V11.55C0.875 12.131 1.26582 12.6 1.75 12.6C2.23124 12.6 2.625 12.131 2.625 11.55V10.85H11.375V11.55C11.375 12.131 11.7658 12.6 12.25 12.6C12.7312 12.6 13.125 12.131 13.125 11.55V10.85H14V6.3ZM4.07457 1.4H9.92251C10.3104 1.4 10.6458 1.69749 10.7596 2.13849L11.3857 4.55H2.612L3.24041 2.13849C3.35417 1.69749 3.68957 1.4 4.07457 1.4ZM2.625 9.1C1.98041 9.1 1.45833 8.47349 1.45833 7.7C1.45833 6.93 1.98041 6.3 2.625 6.3C3.26667 6.3 3.79167 6.93 3.79167 7.7C3.79167 8.47349 3.26667 9.1 2.625 9.1ZM9.33333 8.4C9.33333 8.78499 9.07083 9.1 8.75 9.1H5.25C4.92625 9.1 4.66667 8.78499 4.66667 8.4V7.42349C4.66667 7.2415 4.7425 7.07699 4.86792 6.979L5.64374 6.384C5.71374 6.32801 5.79541 6.3 5.87999 6.3H8.14041C8.23082 6.3 8.31542 6.33499 8.39126 6.39449L9.14666 7.02801C9.26334 7.12599 9.33333 7.287 9.33333 7.4585V8.4ZM10.2083 7.7C10.2083 6.93 10.7304 6.3 11.375 6.3C12.0167 6.3 12.5417 6.93 12.5417 7.7C12.5417 8.47349 12.0167 9.1 11.375 9.1C10.7304 9.1 10.2083 8.47349 10.2083 7.7Z" fill="#FA10F0" />
  </svg>

);

export default TransportTypeCar;
