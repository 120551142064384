import { useSelector } from 'react-redux';

import { SelectValue } from 'src/interfaces';

import { useAcYearOptions } from 'src/cluster/common';
import { selectEditorCopyNetworkForm, useEditorCopyingNetworkScenarioOptions } from 'src/cluster/editor-common';

const useEditorCopyingNetworkValues = (): [SelectValue, SelectValue] => {
  const { year, scenarioId } = useSelector(selectEditorCopyNetworkForm);

  const yearOptions = useAcYearOptions();
  const scenarioOptions = useEditorCopyingNetworkScenarioOptions();

  const yearValue = yearOptions.find(yearItem => yearItem.value === year);
  const scenarioValue = scenarioOptions.find(scenario => scenario.value === scenarioId);

  return [
    yearValue ? yearValue.value : '',
    scenarioValue ? scenarioValue.value : '',
  ];
};

export default useEditorCopyingNetworkValues;
