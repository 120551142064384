import { AC_INTERVALS_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_INTERVALS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    intervalId: undefined,
    intervalName: undefined,
    dayType: undefined,
    intervalStart: undefined,
    intervalEnd: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
