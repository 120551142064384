import {
  isObject,
  isArray,
  isString,
  isNumber,
} from 'src/helpers';
import { AcDashCategoriesModel } from 'src/cluster/dashboard-common';

export function isAcDashCategoriesModel(record: unknown): record is AcDashCategoriesModel {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    name,
    order,
    indicators,
    subcategories,
  } = record as Partial<AcDashCategoriesModel>;

  const isValidModel = isNumber(id)
    && isString(name)
    && isNumber(order)
    && isArray(indicators)
    && isArray(subcategories);

  if (!isValidModel) {
    console.warn('The record is not a valid AcDashCategoriesModel.');
  }

  return isValidModel;
}
