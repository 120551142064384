import omit from 'lodash/omit';
import { reduceObject } from 'src/helpers';
import { version } from '../package.json';

export const stateKey = 'traffic-cloud-state';
export const metaStateKey = 'traffic-cloud-state-meta';
export const freightStoreKey = 'traffic-freight-store';
export const versionKey = 'traffic-cloud-version';
export const tokenKey = 'traffic-cloud-token';

export const clearStorage = () => {
  window.localStorage.removeItem(stateKey);
  window.localStorage.removeItem(freightStoreKey);
};

const removeTmpKeys = reduceObject(key => key.startsWith('$tmp'));

export const writeStorage = (getState: () => any) => {
  const filter = ['router', 'modals', 'api'];
  const state = getState();
  const filtered = removeTmpKeys(omit(state, filter));
  const serializedState = JSON.stringify(filtered);
  window.localStorage.setItem(stateKey, serializedState);
};

export const clearToken = () => {
  window.localStorage.removeItem(tokenKey);
};

export const setToken = (token: string) => {
  window.localStorage.setItem(tokenKey, token);
};

export const getToken = () => {
  return window.localStorage.getItem(tokenKey);
};

export const readStorage = () => {
  let state: any;
  try {
    state = JSON.parse(window.localStorage.getItem(stateKey) || '{}');
  } catch (err) {
    // saved data is not valid
  }

  const currentVersion = window.localStorage.getItem(versionKey);
  if (currentVersion !== version) {
    clearStorage();
    window.localStorage.setItem(versionKey, version);
  }
  return state || {};
};
