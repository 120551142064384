import { Spacing } from '@material-ui/core/styles/createSpacing';

import i18n from 'src/i18n';
import { ffOxygen } from 'src/theme/typography';

import { AcDashCategoriesModel } from 'src/cluster/dashboard-common';

export function translateCategoryName(name: string): string {
  const key = `systems.dashboard.categories.${name}`;
  return i18n.t(key);
}

export function translateUnit(unit: string): string {
  const key = `common.units.${unit}`;
  return i18n.t(key);
}

function includesSearchString(name: string, search: string) {
  return translateCategoryName(name).toLowerCase().includes(search);
}

export function filterCategories<T extends AcDashCategoriesModel>(categories: T[], search?: string): T[] {
  if (!search) {
    return categories;
  }

  const searchKey = search.toLowerCase();
  return categories.map((category) => {
    return {
      ...category,
      subcategories: filterCategories(category.subcategories, searchKey),
      indicators: category.indicators.filter(indicator => includesSearchString(indicator.name, searchKey)),
    };
  }).filter((item) => {
    return item.subcategories.length > 0
      || includesSearchString(item.name, searchKey)
      || item.indicators.some(indicator => includesSearchString(indicator.name, searchKey));
  });
}

export function hideCategories<T extends AcDashCategoriesModel>(categories: T[], hidden = [] as string[]): T[] {
  if (!hidden || hidden.length === 0) {
    return categories;
  }

  return categories.map((category) => {
    return {
      ...category,
      subcategories: hideCategories(category.subcategories, hidden),
      indicators: category.indicators.filter(indicator => !hidden.includes(indicator.name)),
    };
  }).filter((item) => {
    return (item.subcategories.length > 0 || item.indicators.length > 0) && !hidden.includes(item.name);
  });
}

export function getPrimaryColumnStyles(spacing: Spacing) {
  return {
    width: '55%',
    fontFamily: ffOxygen,

    '&:first-of-type': {
      paddingLeft: (level: number) => spacing(level * 5 + 10),
    },

    '&> div:last-of-type': {
      left: (level: number) => spacing(level * 5 + 4),
    },
  };
}
