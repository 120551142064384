import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableWrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },

  leftTableWrapper: {
    width: '50%',
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },

  middleTableWrapper: {
    width: '50%',
    overflowX: 'scroll',
    height: '100%',
  },
}));
