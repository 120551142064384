import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import container from './container';
import * as selectors from './selectors';

export {
  ModalWrapper,
  ConfirmModal,
} from './components';

export * from './constants';

export { default as registerModal } from './register-modal';

export interface WithModalProps {
  cancelAction: (additionalAction?: any) => void;
  submitAction: (additionalAction?: any) => void;
  closeAction: (additionalAction?: any) => void;
}

export default {
  actions,
  constants,
  reducer,
  selectors,
  container,
};
