import { StrictDict } from 'src/types';
import { ExportTypes } from 'src/modules/cities/calculations/types';

export const CITIES_CALCULATION_MAP_MODAL = 'citiesCalculationMapModal';
export const CALCULATIONS_MODEL_NAME = 'citiesCalculations';
export const CALCULATIONS_PAGES_FORM_NAME = 'pageCitiesCalculationsForm';

export interface PageCalculationForm {
  trafficLimit: number;
  pageSize: number;
  currentPage: number;
  maxAlternativeRoutes: number;
}

export interface CalculationFilters {
  edge: number[];
  from: string[];
  to: string[];
}

export const UNITS = {
  capacity: 'common.units.capacity',
  capacityPerYear: 'common.units.capacityPerYear',
  items: 'common.units.items',
  length: 'common.units.length',
  speed: 'common.units.speed',
  time: 'common.units.time',
  timeMinutes: 'common.units.timeMinutes',
  cost: 'common.units.cost',
  costRub: 'common.units.costRub',
  costPerKm: 'common.units.costPerKm',
  rate: 'common.units.rate',
  unitCost: 'common.units.unitCost',
  unitTimeCost: 'common.units.unitTimeCost',
  costPerTEU: 'common.units.costPerTEU',
  costPerKmPerTEU: 'common.units.costPerKmPerTEU',
  vehicles: 'common.units.vehicles',
  people: 'common.units.people',
  peoplePerHour: 'common.units.peoplePerHour',
};

export const EXPORT_CALCULATION_MODAL = 'exportCityCalculationModal';

export const EXPORT_TYPES = { ...ExportTypes };

export const EXPORT_TYPES_DICT: StrictDict<ExportTypes, string> = {
  [EXPORT_TYPES.origin]: 'systems.cities.exportTypes.origin',
  [EXPORT_TYPES.graph]: 'systems.cities.exportTypes.graph',
  [EXPORT_TYPES.fullResult]: 'systems.cities.exportTypes.fullResult',
};
