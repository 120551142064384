import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { DrawerLegendCheckbox } from 'src/cluster/common';

import { LegendType } from '../../types';
import { selectEditorLegendForm } from '../../store';

const useLegendDistricts = (
  setLegendCheckbox: (key: LegendType, checked: boolean) => void,
  classes: Record<string, string>,
): DrawerLegendCheckbox[] => {
  const { isEdgesOfTransportAreas, isDistrictCentroids } = useSelector(selectEditorLegendForm);

  const onChangeHandler = (field: LegendType) => (
    event: ChangeEvent<{}>,
    checked: boolean,
  ) => setLegendCheckbox(field, checked);

  return [
    {
      type: LegendType.isEdgesOfTransportAreas,
      iconProps: {
        border: '2px dashed #666666',
        width: 16,
        height: 18,
        className: classes.transformIcon,
      },
      checked: isEdgesOfTransportAreas,
      onChange: onChangeHandler(LegendType.isEdgesOfTransportAreas),
    },
    {
      type: LegendType.isDistrictCentroids,
      iconProps: { border: '6px solid #E7DB8B', height: 18, borderRadius: '50%' },
      checked: isDistrictCentroids,
      onChange: onChangeHandler(LegendType.isDistrictCentroids),
    },
  ];
};

export default useLegendDistricts;
