import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectMenuOption } from 'src/interfaces';

import { VehicleType, VEHICLE_TYPES_DICT, useSortedByLabelVehicleTypes } from 'src/cluster/common';

const useDistributionVehicleTypesOptions = (): SelectMenuOption[] => {
  const vehicleTypes = useSortedByLabelVehicleTypes();
  const [t, { language }] = useTranslation();
  return useMemo(() => {
    return vehicleTypes
      .filter(type => type.name in VehicleType && type.isPublic)
      .map((type) => ({
        id: `${type.id}`,
        value: type.id,
        label: t(VEHICLE_TYPES_DICT[type.name]),
      }));
  }, [vehicleTypes, language]);
};

export default useDistributionVehicleTypesOptions;
