import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    submitButtons: {
      padding: spacing(2, 3),
      backgroundColor: palette.common.white,
    },
  });
});
