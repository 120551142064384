import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { selectForecastScenarios } from 'src/cluster/balance-forecast';
import { AcBalanceScenariosModel } from 'src/cluster/common';
import { SelectMenuOption } from 'src/interfaces';

export const useScenarios = (): SelectMenuOption[] => {
  const [scenarios] = useSelector(selectForecastScenarios);

  return useMemo(() => scenarios.map((type: AcBalanceScenariosModel) => ({
    id: String(type.id),
    value: type.id,
    label: type.name,
  })), [JSON.stringify(scenarios)]);
};
