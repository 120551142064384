import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';
import { AcIntervalsModel, selectAcIntervals } from 'src/cluster/common';

const useAcIntervals = (): Model<AcIntervalsModel>[] => {
  const intervals = useSelector(selectAcIntervals);
  return intervals.orJust([]);
};

export default useAcIntervals;
