import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => createStyles({
  root: {
    padding: spacing(4),
  },

  radioGroup: {
    paddingBottom: spacing(4),
  },
}));
