import { RestifyArray } from 'redux-restify';

import { ROUTES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: ROUTES_ENDPOINT,
  parent: 'trafficProjects',
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    freight: undefined,
    isOtlk: undefined,
    offset: undefined,
    direction: undefined,
    distance: undefined,
    edgeType: undefined,
    traffic: new RestifyArray({
      defaults: {
        lat: undefined,
        lng: undefined,
      },
    }),
  },
  allowIdRequests: false,
  pagination: false,
};
