import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';
import { AcScenariosModel, selectAcScenarios } from 'src/cluster/common';

const useAcScenarios = (): Model<AcScenariosModel>[] => {
  const scenarios = useSelector(selectAcScenarios);
  return scenarios.orJust([]);
};

export default useAcScenarios;
