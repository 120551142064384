/* eslint-disable max-len */
import React from 'react';

const Bold = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M350,226.8l-17.6-6.4l17-8c41.5-19.5,64.4-53,64.4-94.5c0-39.1-20.4-72.3-55.8-91.2C325.2,7.2,287.4,0,218.7,0  C167.2,0,111.3,3.9,82,9.5v463.4c18.5,2.6,56.6,6.8,114.2,6.8c88.7,0,149.3-13.9,185.3-42.6c31.7-25.7,48.5-60,48.5-99.3  C430,285.8,400.9,245.4,350,226.8z M184.4,77.4l6.8-0.9c11.2-1.4,24.6-2.1,40.8-2.1c50.2,0,77.9,20.2,77.9,56.9  c0,37.8-33,60.4-88.4,60.4h-37.1L184.4,77.4L184.4,77.4z M227.8,403.9c-14.9,0-26.7,0-36.3-0.8l-7.1-0.6V264.7H223  c62.5,0,98.3,25.1,98.3,68.9C321.3,378.3,287.2,403.9,227.8,403.9z" />
  </svg>
);

export default Bold;
