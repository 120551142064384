import React, { FC } from 'react';

import { RouteScheme } from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';

import { VideoPage } from 'src/modules/video/common';

interface Props extends WithTranslate {
  match?: any;
}

const PageVideoRoot: FC<Props> = (props) => {
  const {
    match,
    t,
  } = props;

  return (
    <PageWithHeader
      basePath={['video']}
      menuRenderers={{
        video: {
          label: t('modules.video.captions.title'),
        },
      }}
    >
      <RouteScheme
        prevMatch={match}
        basePath={['video']}
        renderers={{
          video: {
            component: VideoPage,
          },
        }}
      />
    </PageWithHeader>
  );
};

export default PageVideoRoot;
