import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => {
  return createStyles({
    root: {
      padding: spacing(4, 5),
      borderRadius: 13,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.grey[600],
    },
    header: {
      display: 'flex',
      marginBottom: spacing(2),
    },
    slider: {
      width: '80%',
      margin: 'auto',
    },
    label: {
      fontWeight: 'bold',
      padding: spacing(0, 2),
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-around',
      color: palette.grey[600],
      fontSize: 12,
    },
  });
});
