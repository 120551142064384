import React from 'react';

const TransportTypeTaxi = () => (
  <svg width="15" height="6" viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H15V6H0V0Z" fill="#FFFBDF" />
    <rect width="3" height="3" fill="#333B46" />
    <rect x="3" y="3" width="3" height="3" fill="#333B46" />
    <rect x="6" width="3" height="3" fill="#333B46" />
    <rect x="9" y="3" width="3" height="3" fill="#333B46" />
    <rect x="12" width="3" height="3" fill="#333B46" />
  </svg>

);

export default TransportTypeTaxi;
