import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { ViewMode } from '@nebula.gl/edit-modes';

import { Model } from 'src/interfaces';
import { AcRouteDirection, AcRoutesModel } from 'src/cluster/common';

import { LayerProps } from './types';

export function mapRouteWithDirection(route: Model<AcRoutesModel>, direction: AcRouteDirection) {
  const variants = route.variants.map((variant) => {
    return {
      ...variant,
      routeDirections: variant.routeDirections.map((routeDirection) => {
        return routeDirection.id === direction?.id
          ? direction
          : routeDirection;
      }),
    };
  });
  return {
    ...route,
    variants,
  };
}

export function createLayer({
  pickable = false,
  visible = true,
  selectedFeatureIndexes = [],
  pointRadiusMinPixels = 5,
  pointRadiusMaxPixels = 40,
  getRadius = () => 5,
  ...rest
}: LayerProps) {
  return new EditableGeoJsonLayer({
    /* @ts-ignore */ /* eslint-disable-line @typescript-eslint/ban-ts-ignore */
    mode: ViewMode,
    pickable,
    visible,
    selectedFeatureIndexes,
    pointRadiusScale: 1,
    pointRadiusMinPixels,
    pointRadiusMaxPixels,
    editHandlePointRadiusScale: 1,
    editHandlePointRadiusMinPixels: 5,
    editHandlePointRadiusMaxPixels: 40,
    getRadius,
    filled: true,
    stroked: true,
    lineJointRounded: true,
    ...rest,
  });
}
