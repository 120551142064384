import { ThunkActionResult } from 'src/reducer';
import {
  ACTIONS_TYPES,
  CONFIRM_MODAL_NAME,
  INPUT_MODAL_NAME,
  MODAL_SIZES,
} from './constants';

export const showModal = (open: boolean, name: string, params?: object) => ({
  type: ACTIONS_TYPES.modals.showModal,
  open,
  name,
  params,
});

export const showConfirmModal = ({
  onAccept = () => undefined,
  onDecline = () => undefined,
  ...other
}: {
  onAccept?: Function;
  onDecline?: Function;
  [other: string]: any;
} = {}): ThunkActionResult<void> => (dispatch) => {
  dispatch(showModal(true, CONFIRM_MODAL_NAME, {
    onAny: () => dispatch(showModal(false, CONFIRM_MODAL_NAME)),
    onAccept,
    onDecline,
    size: MODAL_SIZES.small,
    ...other,
  }));
};

export const showInputModal = ({
  onAccept = () => undefined,
  onDecline = () => undefined,
  ...other
}: {
  onAccept?: Function;
  onDecline?: Function;
  [other: string]: any;
} = {}): ThunkActionResult<void> => (dispatch) => {
  dispatch(showModal(true, INPUT_MODAL_NAME, {
    onAny: () => dispatch(showModal(false, INPUT_MODAL_NAME)),
    onAccept,
    onDecline,
    size: MODAL_SIZES.small,
    ...other,
  }));
};

export const showMessageBoxModal = ({
  onAccept = () => undefined,
  ...other
}: {
  onAccept?: Function;
  [other: string]: any;
} = {}): ThunkActionResult<void> => (dispatch) => {
  dispatch(showModal(true, CONFIRM_MODAL_NAME, {
    onAny: () => dispatch(showModal(false, CONFIRM_MODAL_NAME)),
    onAccept,
    showDecline: false,
    size: MODAL_SIZES.small,
    ...other,
  }));
};
