/* eslint-disable max-len */
import React from 'react';

const Settings = () => (
  <svg viewBox="0 0 490.5 490.5" >
    <path xmlns="http://www.w3.org/2000/svg" d="M475.9,204.8l-45.6-7.7c-4.4-17.2-11.3-33.7-20.5-49.3l26.8-37.6c5-7,4.2-16.5-1.9-22.6l-16.2-16.2l-16.2-16.1  c-6.1-5.5-15.6-6.3-22.5-1.4l-37.6,26.9c-15.6-9.2-32.1-16-49.3-20.4l-7.8-45.6c-1.5-8.5-8.8-14.6-17.2-14.6h-22.8h-22.8  c-8.5,0-15.7,6.1-17.2,14.6l-7.7,45.7c-17.5,4.4-34.4,11.4-50.3,21.2l-37.4-26.5c-7-5-16.5-4.2-22.6,1.9l-16,16L54.9,89.3  c-6,6-6.8,15.6-1.9,22.6l27,38c-9.2,15.6-15.7,32.4-20.1,49.3l-45.3,7.2C6.1,207.9,0,215.2,0,223.6v22.8v22.8  c0,8.5,6.1,15.7,14.6,17.2l45.9,7.7c4.5,17.1,11.4,33.5,20.7,49l-26.5,37.4c-5,7-4.2,16.5,1.9,22.6l16.2,16.2L89,435.5  c6,6,15.6,6.8,22.6,1.9l38-27c15.6,9.2,32.4,15.7,49.3,20.1l7.6,45.2c1.5,8.5,8.8,14.6,17.2,14.6h22.8h22.8  c8.5,0,15.7-6.1,17.2-14.6l7.7-45.9c16.6-4.4,33-11.1,48-20l37.6,26.8c7,5,16.5,4.2,22.6-1.9l16.2-16.2l16.2-16.2  c6-6,6.8-15.6,1.9-22.6l-26.9-37.6c9.2-15.6,16-32.1,20.4-49.3l45.6-7.8c8.5-1.5,14.6-8.8,14.6-17.2V245l0.1-23  C490.5,213.5,484.4,206.3,475.9,204.8z M336.9,334.1c-49.8,49.9-130.7,49.9-180.5,0.1c-49.9-49.8-49.9-130.7-0.1-180.5  c49.8-49.8,130.7-49.9,180.5-0.1C386.7,203.4,386.9,284.2,336.9,334.1z M322.1,244c0,41.6-33.7,75.4-75.4,75.4s-75.4-33.7-75.4-75.4  s33.7-75.4,75.4-75.4S322.1,202.4,322.1,244z" />
  </svg>
);

export default Settings;
