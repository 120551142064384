import { createSelector } from 'reselect';

import {
  api,
  forms,
} from 'redux-restify';

import {
  PROJECTS_PAGES_FORM_NAMES,
  PROJECTS_MODEL_NAMES,
  PageProjectForm,
} from 'src/modules/general/projects';

import { PageRoutesForm as PageCityRoutesForm } from 'src/modules/cities/routes/constants';
import { AppState } from 'src/reducer';

import { Systems } from 'src/constants';
import { Writable } from 'src/types';
import {
  NODES_MODEL_NAMES,
  ROUTES_MODEL_NAMES,
  MACRO_CHOICES_MODEL_NAMES,
  ROUTES_PAGES_FORM_NAMES,
  NodeModel,
  RouteModel,
  MacroChoicesModel,
  PageRoutesForm,
  RouteAggregation,
  RouteWithAggregation,
} from '../constants';

const hasCargoRoutesFilters = (pageRoutesForm: PageRoutesForm): pageRoutesForm is Required<PageRoutesForm> => {
  return Boolean(
    pageRoutesForm.fromActual &&
    pageRoutesForm.toActual &&
    pageRoutesForm.lengthPercentMaxActual !== undefined &&
    pageRoutesForm.uniqPercentMaxActual !== undefined &&
    pageRoutesForm.transportScenariosActual !== [] &&
    pageRoutesForm.goodsActual !== undefined &&
    pageRoutesForm.macroeconomicScenarioActual !== undefined &&
    pageRoutesForm.yearActual !== undefined,
  );
};

const getCargoRoutesApiConfig = (
  pageProjectForm: PageProjectForm,
  pageRoutesForm: PageRoutesForm,
  projectsModelName: string,
) => {
  if (
    !pageProjectForm.selectedProject ||
    !hasCargoRoutesFilters(pageRoutesForm)
  ) {
    return undefined;
  }
  return {
    parentEntities: {
      [projectsModelName]: pageProjectForm.selectedProject,
    },
    filter: {
      firstNode: pageRoutesForm.fromActual,
      secondNode: pageRoutesForm.toActual,
      margin: 1 + (pageRoutesForm.lengthPercentMaxActual / 100),
      originality: 1 - (pageRoutesForm.uniqPercentMaxActual / 100),
      goods: pageRoutesForm.goodsActual,
      transportScenarios: pageRoutesForm.transportScenariosActual,
      macroeconomicScenario: pageRoutesForm.macroeconomicScenarioActual,
      year: pageRoutesForm.yearActual,
    },
  };
};

const hasNodeRoutesFilters = (pageRoutesForm: PageRoutesForm): pageRoutesForm is Required<PageRoutesForm> => {
  return Boolean(
    pageRoutesForm.fromActual &&
    pageRoutesForm.toActual &&
    pageRoutesForm.lengthPercentMaxActual !== undefined &&
    pageRoutesForm.uniqPercentMaxActual !== undefined &&
    pageRoutesForm.transportScenariosActual !== [] &&
    pageRoutesForm.goodsActual !== undefined &&
    pageRoutesForm.macroeconomicScenarioActual !== undefined &&
    pageRoutesForm.yearActual !== undefined,
  );
};

const hasCityRoutesFilters = (pageRoutesForm: PageCityRoutesForm): pageRoutesForm is Required<PageCityRoutesForm> => {
  return !!(
    pageRoutesForm.from &&
    pageRoutesForm.to &&
    pageRoutesForm.transportType
  );
};

const getCityRoutesApiConfig = (
  pageProjectForm: PageProjectForm,
  pageRoutesForm: PageCityRoutesForm,
  projectsModelName: string,
) => {
  if (
    !pageProjectForm.selectedProject ||
    !hasCityRoutesFilters(pageRoutesForm)
  ) {
    return undefined;
  }
  return {
    parentEntities: {
      [projectsModelName]: pageProjectForm.selectedProject,
    },
    filter: {
      firstNode: pageRoutesForm.from,
      secondNode: pageRoutesForm.to,
      transportType: pageRoutesForm.transportType,
      maxAlternativeRoutes: pageRoutesForm.maxAlternativeRoutes,
    },
  };
};

const getNodeRoutesApiConfig = (
  pageProjectForm: PageProjectForm,
  pageRoutesForm: PageRoutesForm,
  projectsModelName: string,
) => {
  if (
    !pageProjectForm.selectedProject ||
    !hasCargoRoutesFilters(pageRoutesForm)
  ) {
    return undefined;
  }
  return {
    parentEntities: {
      [projectsModelName]: pageProjectForm.selectedProject,
    },
    filter: {
      firstNode: pageRoutesForm.fromActual,
      secondNode: pageRoutesForm.toActual,
      margin: 1 + (pageRoutesForm.lengthPercentMaxActual / 100),
      originality: 1 - (pageRoutesForm.uniqPercentMaxActual / 100),
      goods: pageRoutesForm.goodsActual,
      transportScenarios: pageRoutesForm.transportScenariosActual,
      macroeconomicScenario: pageRoutesForm.macroeconomicScenarioActual,
      year: pageRoutesForm.yearActual,
    },
  };
};

const ROUTES_API_CONFIG_FUNCTIONS: any = {
  shipments: getCargoRoutesApiConfig,
  cities: getCityRoutesApiConfig,
  agglomeration: getNodeRoutesApiConfig,
};

const HAS_ROUTES_FILTERS: any = {
  shipments: hasCargoRoutesFilters,
  cities: hasCityRoutesFilters,
  agglomeration: hasNodeRoutesFilters,
};

const emptyMacroChoices: MacroChoicesModel = {
  years: [],
  macroeconomicScenarios: [],
  transportScenarios: [],
  goodsGroups: [],
};

export const getRoutesSelectors = (type: Systems) => {
  const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[type];
  const pageRoutesFormName = ROUTES_PAGES_FORM_NAMES[type];
  const projectsModelName = PROJECTS_MODEL_NAMES[type];
  const nodesModelName = NODES_MODEL_NAMES[type];
  const routesModelName = ROUTES_MODEL_NAMES[type];
  const macroChoicesModelName = MACRO_CHOICES_MODEL_NAMES[type];
  const getRoutesApiConfig = ROUTES_API_CONFIG_FUNCTIONS[type];
  const hasRoutesFilters = HAS_ROUTES_FILTERS[type];
  const getEndpoint = createSelector(
    (state) => api.selectors.entityManager.agglomerationProjects.getEndpoint(state),
    (endpoint) => endpoint,
  );

  const getCurrentTransportType = createSelector(
    (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    (pageRoutesForm) => String(pageRoutesForm.transportType),
  );

  const getCurrentProjectId = createSelector(
    (state) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
    (pageProjectForm) => Number(pageProjectForm.selectedProject),
  );

  const getCurrentProjectNodes = createSelector(
    [
      (state) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
      (state) => api.selectors.entityManager[nodesModelName].getEntities<NodeModel>(state),
    ],
    (pageProjectForm, nodesEntities) => {
      if (!pageProjectForm.selectedProject) {
        return {
          currentNodes: [],
          currentNodesIsLoading: false,
        };
      }
      const nodesApiConfig = {
        parentEntities: {
          [projectsModelName]: pageProjectForm.selectedProject,
        },
      };
      return {
        currentNodes: nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A'),
        currentNodesIsLoading: nodesEntities.getIsLoadingArray(nodesApiConfig),
      };
    },
  );

  const getProjectChoices = createSelector(
    [
      (state: AppState) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
      (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
      (state: AppState) => api.selectors.entityManager[macroChoicesModelName].getEntities<MacroChoicesModel>(state),
    ],
    (pageProjectForm, pageRoutesForm, choices): MacroChoicesModel => {
      if (!pageProjectForm.selectedProject) {
        return emptyMacroChoices;
      }
      const apiConfig = {
        parentEntities: {
          [projectsModelName]: pageProjectForm.selectedProject,
        },
        query: {
          macroScenario: pageRoutesForm.macroeconomicScenario,
          year: pageRoutesForm.year,
        },
      };
      const macroChoices = choices.getById('', apiConfig);
      if (choices.getIsLoadingById('', apiConfig)) {
        return emptyMacroChoices;
      }
      return macroChoices;
    },
  );

  const getRoutesSelectorsConst = [
    (state: AppState) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[routesModelName].getEntities<RouteModel>(state),
  ] as const;
  const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

  const getCurrentProjectRoutes = createSelector(
    getProjectRoutesSelectors,
    (pageProjectForm, pageRoutesForm, routesEntities) => {
      const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm, projectsModelName);
      if (!routesApiConfig) {
        return {
          currentRoutes: [],
          currentRoutesIsLoading: false,
        };
      }
      return {
        currentRoutes: routesEntities.getArray(routesApiConfig),
        currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
      };
    },
  );

  const asyncGetCurrentProjectRoutes = createSelector(
    getProjectRoutesSelectors,
    async (pageProjectForm, pageRoutesForm, routesEntities) => {
      const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm, projectsModelName);
      if (!routesApiConfig) {
        return {
          currentRoutes: [],
          currentRoutesIsLoading: false,
        };
      }
      return {
        currentRoutes: await routesEntities.asyncGetArray(routesApiConfig),
        currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
      };
    },
  );
  const getCurrentProjectAggregatedRoutes = createSelector(
    [
      getCurrentProjectRoutes,
      (state) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    ],
    ({ currentRoutes }, pageRoutesForm): {
      summaryTraffic?: number;
      aggregatedRoutes: RouteWithAggregation[];
    } => {
      if (!hasRoutesFilters(pageRoutesForm)) {
        return {
          summaryTraffic: undefined,
          aggregatedRoutes: [],
        };
      }
      const minWeight = currentRoutes[0] ? currentRoutes[0].weight : 1;
      const mappedRoutes = currentRoutes.map(route => {
        return {
          from: pageRoutesForm.fromActual ? pageRoutesForm.fromActual : '',
          to: pageRoutesForm.toActual ? pageRoutesForm.toActual : '',
          weight: route.weight,
          isRail: route.isRail,
          percentFromMinimum: ((route.weight / minWeight) * 100) - 100,
          routeTrafficCargo: route.routeTrafficCargo,
          aggregation: route.traffic.reduce<RouteAggregation>((memo, item, index) => {
            const isNewSegment = (
              !memo.prevItem ||
              memo.prevItem.type !== item.type ||
              memo.prevItem.county !== item.county
            );
            memo.types[item.type] = (memo.types[item.type] || 0) + item.distance;

            if (!memo.segmentTypes.length) {
              memo.segmentTypes.push({
                type: item.type,
                distance: 0,
                percent: 0,
              });
            }
            let lastSegmentType = memo.segmentTypes[memo.segmentTypes.length - 1];
            if (lastSegmentType.type !== item.type) {
              memo.segmentTypes.push({
                type: item.type,
                distance: 0,
                percent: 0,
              });
              lastSegmentType = memo.segmentTypes[memo.segmentTypes.length - 1];
            }
            lastSegmentType.distance += item.distance;
            const newSegment = isNewSegment ? { ...item } : memo.segments[memo.segments.length - 1];
            if (!isNewSegment) {
              newSegment.distance += item.distance;
              newSegment.tariff += item.tariff;
              newSegment.time += item.time;
              newSegment.timeCost += item.timeCost;
              newSegment.totalCost += item.totalCost;
            }
            newSegment.costPerKm += item.costPerKm * item.distance;
            newSegment.speed += item.speed * item.distance;

            if (isNewSegment || index === route.traffic.length - 1) {
              const prevSegment = memo.segments[memo.segments.length - 1];
              if (prevSegment && prevSegment.distance) {
                // divide by total distance of segment
                prevSegment.costPerKm /= prevSegment.distance;
                prevSegment.speed /= prevSegment.distance;
              }
            }
            const oldSegments = isNewSegment ? memo.segments : memo.segments.slice(0, -1);
            const newSegments = [...oldSegments, newSegment];
            return {
              summary: {
                distance: memo.summary.distance + item.distance,
                tariff: memo.summary.tariff + item.tariff,
                time: memo.summary.time + item.time,
                timeCost: memo.summary.timeCost + item.timeCost,
                totalCost: memo.summary.totalCost + item.totalCost,
              },
              segments: newSegments,
              types: memo.types,
              segmentTypes: memo.segmentTypes,
              prevItem: item,
            };
          }, {
            summary: {
              distance: 0,
              tariff: 0,
              time: 0,
              timeCost: 0,
              totalCost: 0,
            },
            segments: [],
            types: {},
            segmentTypes: [],
          }),
        };
      });
      return {
        summaryTraffic: mappedRoutes.reduce((memo, route) => memo + route.routeTrafficCargo, 0),
        aggregatedRoutes: mappedRoutes,
      };
    },
  );

  return {
    getEndpoint,
    getCurrentProjectId,
    getCurrentTransportType,
    getCurrentProjectNodes,
    getCurrentProjectRoutes,
    getProjectChoices,
    asyncGetCurrentProjectRoutes,
    getCurrentProjectAggregatedRoutes,
  };
};
