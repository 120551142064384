import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';
import {
  selectTtcPageForm,
  ttcPageFormActions,
  exportCalculationItem,
  selectTtcSitesResults,
  selectTtcRegionsResults,
} from 'src/cluster/balance-ttc';
import { projectLoadsManager } from 'src/cluster/projects';

import TtcCalcResultsList from './TtcCalcResultsList';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectTtcPageForm(state),
  ttcSitesResults: selectTtcSitesResults(state),
  ttcRegionsResults: selectTtcRegionsResults(state),
  isLoading: projectLoadsManager.selectIsDownloadingUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onPageChange: (value: number) => {
    dispatch(ttcPageFormActions.changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(ttcPageFormActions.resetField('currentPage'));
    dispatch(ttcPageFormActions.changeField('pageSize', value));
  },
  exportCalculationItem: (fromId: number, toId: number, extension: 'xlsx' | 'geojson') => {
    dispatch(exportCalculationItem(fromId, toId, extension));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TtcCalcResultsList);
