import React from 'react';

export const defaultPageManagerContext = {
  match: {
    params: {},
    isExact: false,
    path: '/',
    url: '/',
  },
  basePath: [],
  registeredRoutesPaths: [],
};

export type RoutePermission = string[] | ((url: string) => string[])

export const PageManagerContext = React.createContext(defaultPageManagerContext);

export const MTH_ANALYTIC_PERMISSION = 'users.agglomeration__can_upload_project_file';
export const MTH_USER_PERMISSION = 'users.agglomeration__available';
export const SHIPMENTS_ANALYTIC_PERMISSION = 'users.cargo__can_upload_project_file';
export const SHIPMENTS_USER_PERMISSION = 'users.cargo__available';
export const CITIES_ANALYTIC_PERMISSION = 'users.cities__can_upload_project_file';
export const CITIES_USER_PERMISSION = 'users.cities__available';
export const TRAFFIC_ANALYTIC_PERMISSION = 'users.traffic_flows__can_upload_project_file';
export const TRAFFIC_USER_PERMISSION = 'users.traffic_flows__available';
export const MATRIX_ANALYTIC_PERMISSION = 'users.traffic_matrix__can_upload_project_file';
export const MATRIX_USER_PERMISSION = 'users.traffic_matrix__available';
export const VIDEO_USER_PERMISSION = 'users.transport_video__available';

export const FREIGHT_USER_PERMISSION = 'users.freight__available';
export const FREIGHT_CAN_UPLOAD_PROJECT_FILE_PERMISSION = 'users.freight__can_upload_project_file';
export const FREIGHT_EDITOR_PERMISSION = 'users.freight__editor';

export const AC_CAN_UPLOAD_PROJECT_FILE_PERMISSION = 'users.agglomeration_cluster__can_upload_project_file';
export const AC_EDITOR_PERMISSION = 'users.agglomeration_cluster__editor';
export const AC_BALANCE_PERMISSION = 'users.agglomeration_cluster__balance';
export const AC_DISTRIBUTION_PERMISSION = 'users.agglomeration_cluster__routes_distribution';
export const AC_DASHBOARD_PERMISSION = 'users.agglomeration_cluster__indicators';

export const ANALYTIC_PERMISSIONS_MAP = {
  shipments: SHIPMENTS_ANALYTIC_PERMISSION,
  cities: CITIES_ANALYTIC_PERMISSION,
  matrix: MATRIX_ANALYTIC_PERMISSION,
  traffic: TRAFFIC_ANALYTIC_PERMISSION,
  mth: MTH_ANALYTIC_PERMISSION,
  video: VIDEO_USER_PERMISSION,
};

export const checkPermissionForRoute = (
  currentUrl: string,
  permissions: string[],
  routePermissions: RoutePermission,
) => {
  const permissionsArray = Array.isArray(routePermissions) ? routePermissions : routePermissions(currentUrl);
  return permissionsArray.some((r: string) => permissions.includes(r));
};
