export enum FileType {
  graph = 'GRAPH',
  stops = 'STOPS',
  sites = 'SITE_POINTS',
  siteBorders = 'SITE_POLY',
  districts = 'DISTRICT_POINTS',
  districtBorders = 'DISTRICT_POLY',
  zones = 'ZONE_POINTS',
  zoneBorders = 'ZONE_POLY',
  editorSpreadsheets = 'EDITOR_DATA',
  balanceSpreadsheets = 'BALANCE_DATA',
}

export type FileTypeDictRecord = {
  name: string;
  extension: '.geojson' | '.xlsx' | '.xml';
}

export type FileRecord = {
  type?: FileType;
  name?: string;
  file?: File;
}
