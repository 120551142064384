import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { useVehicleTypeName } from 'src/cluster/editor-map';

import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import {
  Button, NumberFormat, WithUnit,
} from 'src/components';

import { isNil } from 'src/helpers';
import i18n from 'src/i18n/i18n';
import { UNITS } from 'src/constants';
import {
  AcDistDirectionEdgesModel,
  AcDistRoutesModel,
  DirectionRouteDataGrid,
} from 'src/cluster/distribution-table';
import { Maybe } from 'monet';
import useStyles from './styles';

function useTitle(id: number) {
  const getVehicleTypeName = useVehicleTypeName();
  return `common.vehicleTypes.${getVehicleTypeName(id)}`;
}

interface OwnProps {
  record: AcDistRoutesModel;
}

interface InjectedProps extends WithTranslate {
  isLoading: boolean;
  distForwardDirection: Maybe<Model<AcDistDirectionEdgesModel>[]>;
  distBackwardDirection: Maybe<Model<AcDistDirectionEdgesModel>[]>;
  close: () => void;
}

type Props = OwnProps & InjectedProps;

const ModalTableDistribution: FC<Props> = (props) => {
  const {
    record,
    isLoading,
    distForwardDirection,
    distBackwardDirection,
    locale,
    t,
    close,
  } = props;

  const classes = useStyles();

  const forwardData = distForwardDirection.orJust([] as Model<AcDistDirectionEdgesModel>[]);
  const backwardData = distBackwardDirection.orJust([] as Model<AcDistDirectionEdgesModel>[]);
  const tableData = [forwardData, backwardData];

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title} >
        {t(useTitle(record.vehicleTypeId))}
        {' '}
        {t('common.captions.numberSymbol')}
        {' '}
        {record.variantNumber}
      </Typography>
      <Typography className={classes.subTitle}>
        {t('systems.distribution.columns.routeLongName')}
        :
        <span className={classes.subTitleBold}>
          {' '}
          {record.variantName || '-'}
        </span>
      </Typography>
      <Typography className={classes.subTitle}>
        {t('systems.distribution.columns.distance')}
        :
        <span className={classes.subTitleBold}>
          {' '}
          <WithUnit unit={i18n.t(UNITS.length)}>
            {isNil(record.distance) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={record.distance}
                locale={locale}
              />
            )}
          </WithUnit>
        </span>
      </Typography>
      <Typography className={classes.subTitle}>
        {t('systems.distribution.columns.passengerTrafficTotal')}
        :
        {' '}
        <WithUnit unit={i18n.t(UNITS.people)}>
          <span className={classes.subTitleBold}>
            {isNil(record.passengerTrafficTotal) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={record.passengerTrafficTotal}
                locale={locale}
              />
            )}
          </span>
        </WithUnit>
      </Typography>
      <Typography className={classes.subTitle}>
        {t('systems.distribution.columns.passengerTraffic')}
        {' '}
        {t('systems.distribution.columns.inPeakSection')}
        :
        <WithUnit unit={i18n.t(UNITS.people)}>
          <span className={classes.subTitleBold}>
            {' '}
            {isNil(record.passengerTrafficPeak) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={record.passengerTrafficPeak}
                locale={locale}
              />
            )}
          </span>
        </WithUnit>
      </Typography>
      <Typography className={classes.subTitle}>
        {t('systems.distribution.columns.fillingForOneVehicle')}
        {' '}
        {t('systems.distribution.columns.inPeakSection')}
        :
        <span className={classes.subTitleBold}>
          {' '}
          <span >
            {isNil(record.peakFillingPerVehicleAbs) ? '-' : (
              <WithUnit unit={i18n.t(UNITS.people)}>
                <NumberFormat
                  fixedDecimalScale={false}
                  decimalScale={0}
                  value={record.peakFillingPerVehicleAbs}
                  locale={locale}
                />
              </WithUnit>
            )}
          </span>
          <span className={classes.line}>/</span>
          <span >
            {isNil(record.peakFillingPerVehicleNorm) ? '-' : (
              <WithUnit unit={i18n.t(UNITS.ofTheStandard)}>
                <NumberFormat
                  fixedDecimalScale={false}
                  decimalScale={0}
                  value={record.peakFillingPerVehicleNorm}
                  locale={locale}
                />
              </WithUnit>
            )}
          </span>
        </span>
      </Typography>

      <Typography variant="h6" className={classes.titleH6} >
        {t('systems.agglomeration.captions.routeDirection')}
      </Typography>

      <div className={classes.tableWrapper}>
        <div className={classes.scrollWrapper}>
          {tableData.length && tableData.map((data, i) => (
            <DirectionRouteDataGrid
              key={i}
              data={data}
              direction={i === 0}
              isLoading={isLoading}
            />
          ))}
        </div>
      </div>

      <div className={classes.buttons}>
        <Button
          className={classes.button}
          onClick={close}
        >
          {t('common.captions.ok')}
        </Button>
      </div>
    </div>
  );
};

export default ModalTableDistribution;
