import React from 'react';

const ErrorIcon = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.21192 0C5.05896 0 4.90708 0.0699348 4.82954 0.203804L0.0709512 8.46467C-0.108838 8.74735 0.0655126 9.12 0.453326 9.13043H9.97053C10.2792 9.13043 10.5595 8.79254 10.3529 8.46467L5.59431 0.203804C5.51677 0.0699348 5.3649 0 5.21192 0ZM5.21192 1.31793L9.19866 8.24728H1.22517L5.21192 1.31793ZM5.21192 2.60189C4.96163 2.60189 4.75873 2.79654 4.75873 3.03667V6.29755C4.75873 6.53771 4.96163 6.73234 5.21192 6.73234C5.46222 6.73234 5.66513 6.53771 5.66513 6.29755V3.03667C5.66513 2.79654 5.46222 2.60189 5.21192 2.60189ZM5.21192 6.94973C4.96163 6.94973 4.75873 7.1444 4.75873 7.38451C4.75873 7.62464 4.96163 7.81929 5.21192 7.81929C5.46222 7.81929 5.66513 7.62464 5.66513 7.38451C5.66513 7.1444 5.46222 6.94973 5.21192 6.94973Z"
      fill="currentColor"
    />
  </svg>

);

export default ErrorIcon;
