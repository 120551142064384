import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { forms } from 'redux-restify';

import {
  CalculationsTable,
  CALCULATIONS_MODEL_NAMES,
} from 'src/modules/shipments/calculations';
import { CALCULATIONS_STATUSES } from 'src/modules/general/calculations/constants';

import { useSystemsContext } from 'src/constants';
import actions from 'src/modules/shipments/calculations/actions';
import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';

import style from './index.module.css';

interface Props {
  className?: string;
}

const ShipmentsCalcTable: React.FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const calculationModelName = CALCULATIONS_MODEL_NAMES[currentSystem];
  return (
    <CalculationsTable
      className={classNames(className, style.root)}
      buttons={(item: any) => (
        <div>
          {item.status === CALCULATIONS_STATUSES.success &&
            <ExportToExcelButton {...{
              fixedSizeOnExpand: false,
              onClick: () => {
                if (item.isNew) {
                  dispatch(forms.actions.sendQuickForm({
                    model: calculationModelName,
                    id: item.id,
                    values: {
                      isNew: false,
                    },
                  }));
                }
                dispatch(actions[currentSystem].exportCalculation(item.id));
              },
            }} />}
        </div>
      )}
    />
  );
};

export default ShipmentsCalcTable;
