/* eslint-disable max-len */
import React from 'react';

const NewLabel = () => (
  <svg viewBox="0 0 28 12" fill="none">
    <rect width="28" height="12" fill="white"
    />
    <path d="M0 3C0 1.34315 1.34315 0 3 0H25C26.6569 0 28 1.34315 28 3V9C28 10.6569 26.6569 12 25 12H3C1.34315 12 0 10.6569 0 9V3Z" fill="currentColor"
    />
    <path d="M6.616 9V3.784H7.824L9.184 6.376L9.696 7.528H9.728C9.70133 7.25067 9.66933 6.94133 9.632 6.6C9.6 6.25867 9.584 5.93333 9.584 5.624V3.784H10.704V9H9.496L8.136 6.4L7.624 5.264H7.592C7.61867 5.552 7.648 5.86133 7.68 6.192C7.71733 6.52267 7.736 6.84267 7.736 7.152V9H6.616ZM11.9363 9V3.784H15.2003V4.776H13.1123V5.824H14.8883V6.808H13.1123V8.008H15.2803V9H11.9363ZM16.7831 9L15.8151 3.784H17.0231L17.3831 6.224C17.4151 6.496 17.4498 6.77067 17.4871 7.048C17.5245 7.32 17.5591 7.59467 17.5911 7.872H17.6231C17.6765 7.59467 17.7271 7.32 17.7751 7.048C17.8285 6.77067 17.8818 6.496 17.9351 6.224L18.4951 3.784H19.4951L20.0551 6.224C20.1085 6.49067 20.1591 6.76267 20.2071 7.04C20.2605 7.312 20.3138 7.58933 20.3671 7.872H20.3991C20.4365 7.58933 20.4711 7.312 20.5031 7.04C20.5405 6.768 20.5778 6.496 20.6151 6.224L20.9751 3.784H22.1031L21.1751 9H19.6951L19.1831 6.632C19.1405 6.42933 19.1005 6.22667 19.0631 6.024C19.0311 5.816 19.0018 5.616 18.9751 5.424H18.9431C18.9111 5.616 18.8765 5.816 18.8391 6.024C18.8071 6.22667 18.7698 6.42933 18.7271 6.632L18.2391 9H16.7831Z" fill="white"
    />
  </svg>
);

export default NewLabel;
