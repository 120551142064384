import React, { FC } from 'react';
import { Popup } from 'react-map-gl';

import { VocabularyEntity, WithTranslate } from 'src/i18n';
import { NumberFormat } from 'src/components';

import useStyles from './styles';

interface OwnProps {
  lat: number;
  lng: number;
  region?: string;
  title?: string;
  value?: number;
}

type Props = OwnProps & WithTranslate;

const BalanceMapPopup: FC<Props> = (props) => {
  const {
    lat,
    lng,
    region = '',
    title,
    value,
    locale,
    t,
  } = props;

  const classes = useStyles();

  return (
    <Popup
      latitude={lat}
      longitude={lng}
      closeButton={false}
      closeOnClick={false}
      anchor="top-left"
      offsetTop={15}
      offsetLeft={15}
      tipSize={10}
    >
      <div className={classes.root}>
        {title ? (
          <table>
            <tbody>
              <tr>
                <td>
                  {t('common.captions.name')}
                  :
                </td>
                <td className={classes.value}>
                  <VocabularyEntity value={region} />
                </td>
              </tr>
              <tr>
                <td>
                  {title}
                  :
                </td>
                <td className={classes.value}>
                  <NumberFormat
                    value={value}
                    locale={locale}
                    decimalScale={2}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : <VocabularyEntity value={region} />}
      </div>
    </Popup>
  );
};

export default BalanceMapPopup;
