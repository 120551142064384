import React from 'react';

import { useCurrentVocabulary, getVocabularyValue } from '../../helpers';

interface Props {
  className?: string;
  value: string;
}

const VocabularyEntity: React.FC<Props> = ({
  className,
  value,
}) => {
  const [currentLocale, vocabulary] = useCurrentVocabulary();
  const renderValue = getVocabularyValue(currentLocale, vocabulary, value);
  return (
    <span className={className}>
      {renderValue}
    </span>
  );
};

export default VocabularyEntity;
