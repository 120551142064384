import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&:first-of-type': {
      position: 'relative',
      paddingLeft: theme.spacing(5),
    },
  },

  editableCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
