import React, { FC, SyntheticEvent } from 'react';

import { WithTranslate } from 'src/i18n';

import { EditorTreeCaption } from 'src/cluster/editor-map';
import { Button, PlusIcon, SwitchIcon } from 'src/components';

import useStyles from './styles';

interface OwnProps {
  isModifying: boolean;
  isForward: boolean;
  isOnlyDirection: boolean;
  addDirection(): void;
  deleteDirection(): void;
  swapDirections(): void;
}

type Props = OwnProps & WithTranslate;

const RouteDirectionHeader: FC<Props> = (props) => {
  const {
    isModifying,
    isForward,
    isOnlyDirection,
    t,
    addDirection,
    deleteDirection,
    swapDirections,
  } = props;

  const classes = useStyles();

  const handleAddClick = (event: SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    addDirection();
  };

  const handleDeleteClick = (event: SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    deleteDirection();
  };

  const handleSwapClick = (event: SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    swapDirections();
  };

  return (
    <span className={classes.row}>
      <span className={classes.direction}>
        {isForward
          ? t('systems.agglomeration.captions.forward')
          : t('systems.agglomeration.captions.backward')}
      </span>
      <EditorTreeCaption label={t('systems.agglomeration.captions.routeDirection')} />
      {isModifying && (
        <div className={classes.action}>
          {isForward && isOnlyDirection && (
            <Button
              variant="icon"
              title={t('modules.editor.captions.toAddDirection')}
              onClick={handleAddClick}
            >
              <PlusIcon />
            </Button>
          )}
          {isForward && !isOnlyDirection && (
            <Button
              variant="icon"
              title={t('modules.editor.captions.swapDirections')}
              onClick={handleSwapClick}
            >
              <SwitchIcon />
            </Button>
          )}
          {!isForward && !isOnlyDirection && (
            <Button
              variant="link"
              className={classes.delete}
              onClick={handleDeleteClick}
            >
              {t('common.captions.delete')}
            </Button>
          )}
        </div>
      )}
    </span>
  );
};

export default RouteDirectionHeader;
