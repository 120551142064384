import React, { FC } from 'react';
import { Maybe } from 'monet';

import { Model } from 'src/interfaces';
import { DataGridContainer } from 'src/components';
import { TtcRegionsGrid, TtcSitesGrid } from 'src/cluster/balance-ttc';
import { TtcCalcPageForm, TtcRegionsModel, TtcSitesModel } from 'src/cluster/common';

export interface Props {
  pageForm: TtcCalcPageForm;
  ttcSitesResults: [Maybe<Model<TtcSitesModel>[]>, number];
  ttcRegionsResults: [Maybe<Model<TtcRegionsModel>[]>, number];
  isLoading: boolean;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  exportCalculationItem: (siteFromId: number, siteToId: number, extension: 'xlsx' | 'geojson') => void;
}

const TtcCalcResultsList: FC<Props> = (props) => {
  const {
    pageForm: {
      currentPage,
      pageSize,
      isSites,
      isExporting,
    },
    ttcSitesResults: [sites, countSites],
    ttcRegionsResults: [regions, countRegions],
    isLoading,
    onPageChange,
    onPageSizeChange,
    exportCalculationItem,
  } = props;

  const count = isSites ? countSites : countRegions;
  const results = isSites ? sites : regions;

  return (
    <DataGridContainer
      data={results}
      currentPage={currentPage}
      pageSize={pageSize}
      count={count}
      isLoading={isLoading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    >
      {(data: TtcSitesModel[] | TtcRegionsModel[]) => (
        isSites ? (
          <TtcSitesGrid
            data={data as TtcSitesModel[]}
            exportCalculationItem={exportCalculationItem}
            isExporting={isExporting}
          />
        ) : (
          <TtcRegionsGrid
            data={data as TtcRegionsModel[]}
            exportCalculationItem={exportCalculationItem}
            isExporting={isExporting}
          />
        )
      )}
    </DataGridContainer>
  );
};

export default TtcCalcResultsList;
