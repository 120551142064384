import React from 'react';

const UnknownVehicleIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 5.81817H13.9743C13.7603 4.42444 13.5001 2.90901 13.5001 2.90901C13.4332 2.2995 13.0528 1.09232 10.4999 0.86759V0.727329C10.4999 0.325716 10.1641 0 9.74998 0H5.25002C4.83602 0 4.49997 0.325561 4.49997 0.727329V0.866967C1.94254 1.09154 1.55876 2.2995 1.49994 2.90916C1.49994 2.90916 1.23942 4.4246 1.02567 5.81832H0.750048C0.336051 5.81817 0 6.14404 0 6.5455V8C0 8.40146 0.33589 8.72733 0.750209 8.72733C0.750209 10.1935 0.750209 13.091 0.750209 13.091C0.750209 13.6291 1.05219 14.0979 1.5001 14.3493V15.4865C1.5001 15.7701 1.73731 16 2.02965 16H3.97074C4.26308 16 4.50029 15.7701 4.50029 15.4865V14.5453H10.5V15.4865C10.5 15.7701 10.7372 16 11.0296 16H12.9707C13.2632 16 13.5002 15.7701 13.5002 15.4865V14.3493C13.9481 14.0976 14.2501 13.6291 14.2501 13.091C14.2501 13.091 14.2501 10.1935 14.2501 8.72733C14.6641 8.72733 15 8.4013 15 8V6.5455C14.9998 6.14388 14.6641 5.81817 14.25 5.81817ZM11.6249 12.3635C11.004 12.3635 10.4999 11.8749 10.4999 11.2726C10.4999 10.6709 11.004 10.1817 11.6249 10.1817C12.246 10.1817 12.7499 10.6709 12.7499 11.2726C12.7499 11.8749 12.2462 12.3635 11.6249 12.3635ZM5.62496 0.727329H9.37504C9.5822 0.727329 9.75014 0.890188 9.75014 1.09092C9.75014 1.29164 9.5822 1.4545 9.37504 1.4545H5.62496C5.41812 1.4545 5.25018 1.29164 5.25018 1.09092C5.25018 0.890188 5.41796 0.727329 5.62496 0.727329ZM2.69934 4.18491C2.79818 3.57976 3.45678 2.89763 7.5 2.89763C11.5432 2.89763 12.1649 3.48672 12.3007 4.18647C12.4363 4.88606 12.6869 7.27516 12.6869 7.27516C12.7212 7.67444 12.4122 8.00016 12 8.00016L3.00003 8.00171C2.58748 8.00171 2.27859 7.676 2.31298 7.27735C2.31298 7.27703 2.60034 4.78975 2.69934 4.18491ZM3.37498 12.3635C2.75414 12.3635 2.24998 11.8749 2.24998 11.2726C2.24998 10.6709 2.75414 10.1817 3.37498 10.1817C3.99597 10.1817 4.49997 10.6709 4.49997 11.2726C4.49997 11.8749 3.99597 12.3635 3.37498 12.3635Z"
      fill="currentColor"
    />
  </svg>
);

export default UnknownVehicleIcon;
