import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { sortOptionsByNumber } from 'src/helpers';

import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components/toolbar';
import PButton from 'src/components/deprecated/PButton';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';
import PInput, { INPUT_TYPES } from 'src/components/deprecated/PInput';
import PSelect from 'src/components/deprecated/PSelect';
import { getSelectValue, UNITS } from 'src/modules/general/common';
import WithUnit from 'src/components/common/WithUnit';

import NumberFormat from 'src/components/etc/NumberFormat';
import PCheckbox from 'src/components/deprecated/PCheckbox';

import {
  useVideoCities,
  useVideoVehicleTypes,
  useVideoVehicleMakeModels,
  useVideoTransportCompanies,
  useVideoRouteNumbers,
  useVideoRouteTrips,
} from 'src/modules/video/common';

import { Props } from './interfaces';

import style from './index.module.css';

const VideoToolbar: FC<Props> = (props) => {
  const {
    className,
    pageForm: {
      city,
      vehicleType,
      vehicleMakeModel,
      transportCompany,
      routeNumber,
      routeTrip,
      dateAfter,
      dateBefore,
      timeAfter,
      timeBefore,
      weekdays = [],
    },
    totalTraffic: [{ passengerCount }],
    locale,
    t,
    setCity,
    setVehicleType,
    setVehicleMakeModel,
    setTransportCompany,
    setRouteNumber,
    setDateAfter,
    setDateBefore,
    setRouteTrip,
    setTimeAfter,
    setTimeBefore,
    setWeekday,
    setWeekdays,
    setWeekends,
    resetWeekdays,
  } = props;

  useEffect(() => {
    if (!Array.isArray(weekdays)) {
      resetWeekdays();
    }
  }, [weekdays]);

  const [isOpen, setOpen] = useState(true);

  const citiesItems = useVideoCities();
  const vehicleTypesItems = useVideoVehicleTypes();
  const vehicleMakeModelsItems = useVideoVehicleMakeModels();
  const transportCompanyItems = useVideoTransportCompanies();
  const routeNumberItems = useVideoRouteNumbers();
  const routeTripItems = useVideoRouteTrips();

  const makeWeekdaysChangeHandler = (day: number) => (value: any) => {
    setWeekday(value, day);
  };

  return (
    <Toolbar className={classNames(style.root, isOpen && style.open, className)} wrap>
      <div className={style.toggle}>
        <PButton
          type="link"
          onClick={() => setOpen(state => !state)}
        >
          <PIcon
            type={ICONS_TYPES.arrow}
            rotate={isOpen ? ROTATE_TYPES.down : ROTATE_TYPES.up}
          />
        </PButton>
      </div>
      <ToolbarBlock title={t('common.captions.filter')} size="full-width">
        <ToolbarControls position="top">
          <ToolbarControl>
            <PSelect
              label={t('modules.video.captions.city')}
              className={style.select}
              items={citiesItems}
              values={getSelectValue(city)}
              isClearable
              onChange={setCity}
            />
          </ToolbarControl>
          <ToolbarControl>
            <PSelect
              label={t('modules.video.captions.vehicleType')}
              className={style.select}
              items={vehicleTypesItems}
              values={getSelectValue(vehicleType)}
              isClearable
              disabled={!city}
              onChange={setVehicleType}
            />
          </ToolbarControl>
          <ToolbarControl>
            <PSelect
              label={t('modules.video.captions.vehicleModel')}
              className={style.select}
              items={vehicleMakeModelsItems}
              values={getSelectValue(vehicleMakeModel)}
              isClearable
              disabled={!vehicleType}
              onChange={setVehicleMakeModel}
            />
          </ToolbarControl>
        </ToolbarControls>
        <ToolbarControls>
          <ToolbarControl>
            <PSelect
              label={t('modules.video.captions.transportCompany')}
              className={style.select}
              items={transportCompanyItems}
              values={getSelectValue(transportCompany)}
              isClearable
              disabled={!city}
              onChange={setTransportCompany}
            />
          </ToolbarControl>
          <ToolbarControl>
            <PSelect
              label={t('modules.video.captions.routeNumber')}
              className={style.selectTight}
              items={routeNumberItems}
              values={getSelectValue(routeNumber)}
              isClearable
              disabled={!vehicleType}
              sort={sortOptionsByNumber}
              onChange={setRouteNumber}
            />
          </ToolbarControl>
          <ToolbarControl>
            <PSelect
              label={t('modules.video.captions.routeTrip')}
              className={style.select}
              items={routeTripItems}
              values={getSelectValue(routeTrip)}
              isClearable
              disabled={!routeNumber}
              onChange={setRouteTrip}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock
        title={(
          <span>
            {t('modules.video.captions.period')}
          </span>
        )}
      >
        <ToolbarControls position="top">
          <ToolbarControl>
            <PInput
              label={t('modules.video.captions.from')}
              placeholder={t('common.placeholders.date')}
              className={style.date}
              type={INPUT_TYPES.date}
              value={dateAfter || ''}
              onEnter={setDateAfter}
              onBlur={setDateAfter}
            />
          </ToolbarControl>
          <ToolbarControl>
            <PInput
              label={t('modules.video.captions.till')}
              placeholder={t('common.placeholders.date')}
              className={style.date}
              type={INPUT_TYPES.date}
              value={dateBefore || ''}
              onEnter={setDateBefore}
              onBlur={setDateBefore}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock title={t('modules.video.captions.time')}>
        <ToolbarControls position="top">
          <ToolbarControl>
            <PInput
              label={t('modules.video.captions.from')}
              placeholder={t('common.placeholders.time')}
              className={style.time}
              type={INPUT_TYPES.time}
              value={timeAfter || ''}
              onEnter={setTimeAfter}
              onBlur={setTimeAfter}
            />
          </ToolbarControl>
          <ToolbarControl>
            <PInput
              label={t('modules.video.captions.till')}
              placeholder={t('common.placeholders.time')}
              className={style.time}
              type={INPUT_TYPES.time}
              value={timeBefore || ''}
              onEnter={setTimeBefore}
              onBlur={setTimeBefore}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock title={t('modules.video.captions.weekdays')}>
        <ToolbarControls className={style.weekdayRow}>
          <PCheckbox
            label={t('common.weekdays.monday')}
            value={weekdays.includes?.(2)}
            onChange={makeWeekdaysChangeHandler(2)}
          />
          <PCheckbox
            label={t('common.weekdays.tuesday')}
            value={weekdays.includes?.(3)}
            onChange={makeWeekdaysChangeHandler(3)}
          />
          <PCheckbox
            label={t('common.weekdays.wednesday')}
            value={weekdays.includes?.(4)}
            onChange={makeWeekdaysChangeHandler(4)}
          />
          <PCheckbox
            label={t('common.weekdays.thursday')}
            value={weekdays.includes?.(5)}
            onChange={makeWeekdaysChangeHandler(5)}
          />
          <PCheckbox
            label={t('common.weekdays.friday')}
            value={weekdays.includes?.(6)}
            onChange={makeWeekdaysChangeHandler(6)}
          />
          <PCheckbox
            label={t('common.weekdays.weekdays')}
            value={weekdays.length === 5 && weekdays.every((weekday) => [2, 3, 4, 5, 6].includes(weekday))}
            onChange={setWeekdays}
          />
        </ToolbarControls>
        <ToolbarControls className={style.weekdayRow}>
          <PCheckbox
            label={t('common.weekdays.saturday')}
            value={weekdays.includes?.(7)}
            onChange={makeWeekdaysChangeHandler(7)}
          />
          <PCheckbox
            label={t('common.weekdays.sunday')}
            value={weekdays.includes?.(1)}
            onChange={makeWeekdaysChangeHandler(1)}
          />
          <PCheckbox
            label={t('common.weekdays.weekends')}
            value={weekdays.length === 2 && weekdays.every((weekday) => [1, 7].includes(weekday))}
            onChange={setWeekends}
          />
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock className={style.totalBlock}>
        <ToolbarControls position="bottom" align="right">
          <ToolbarControl>
            <div className={style.total}>
              <div>
                {t('modules.video.captions.summaryTraffic')}
                {': '}
                <WithUnit unit={t(UNITS.people)} classes={{ unit: style.totalUnit }}>
                  <span className={style.number}>
                    {passengerCount ? (
                      <NumberFormat
                        value={passengerCount}
                        locale={locale}
                        decimalScale={0}
                        zeroPlaceholder="-"
                      />
                    ) : '...'}
                  </span>
                </WithUnit>
                <div className={style.totalSubtitle}>
                  <span className={style.subtitle}>
                    (
                    {t('modules.video.captions.tripsWithVideoSurveillance')}
                    )
                  </span>
                </div>
              </div>
            </div>
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default VideoToolbar;
