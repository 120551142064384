import React, { ReactNode, useMemo } from 'react';

import i18n from 'src/i18n';
import { DataGridMultiColumnProps } from 'src/components/common/DataGrid';
import { NumberFormat, WithUnit } from 'src/components';
import { UNITS } from 'src/constants';
import { useAcIntervalOptions } from 'src/cluster/common';
import { BottomColumns, RegistryVariant } from 'src/cluster/editor-table';

const useRegistryVariantConfig = (bottomColumns: BottomColumns[], classes: Record<string, string>): {
  type: BottomColumns;
  title?: ReactNode;
  children?: DataGridMultiColumnProps[];
  headClassName?: string;
  className?: string;
  model?: (row: RegistryVariant, index: number, data: RegistryVariant[]) => ReactNode;
}[][] => {
  const intervalsOptions = useAcIntervalOptions();

  return useMemo(() => {
    const translate = 'modules.registries.columns.';

    const intervalsBody = intervalsOptions.map((interval) => (
      {
        type: BottomColumns.intervals,
        className: classes.bigSize,
        model: (result: RegistryVariant) => {
          return result.intervals.find(resInt => resInt.intervalId === interval.value)?.intervalValue || 0;
        },
      }
    ));

    let variantColumns: {
      type: BottomColumns;
      title?: ReactNode;
      children?: DataGridMultiColumnProps[];
      headClassName?: string;
      className?: string;
      model?: (row: RegistryVariant, index: number, data: RegistryVariant[]) => ReactNode;
    }[] = [
      {
        type: BottomColumns.variantNumber,
        className: classes.bigSize,
        title: i18n.t(translate + BottomColumns.variantNumber),
        model: (result: RegistryVariant) => result.variantNumber,
      },
      {
        type: BottomColumns.variantName,
        className: classes.bigSize,
        title: i18n.t(translate + BottomColumns.variantName),
        model: (result: RegistryVariant) => result.variantName,
      },
      {
        type: BottomColumns.intervals,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.timeMinutes)}>
            {i18n.t(translate + BottomColumns.intervals)}
          </WithUnit>
        ),
        children: intervalsOptions.map((interval) => ({
          title: interval.label,
          headClassName: classes.variantHeadBottom,
        })),
      },
      {
        type: BottomColumns.tariff,
        className: classes.bigSize,
        title: (
          <WithUnit unit={`${i18n.t(UNITS.costRub)}.`}>
            {i18n.t(translate + BottomColumns.tariff)}
          </WithUnit>
        ),
        model: (result: RegistryVariant) => result.tariff,
      },
      {
        type: BottomColumns.lengthForward,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.length)}>
            {i18n.t(translate + BottomColumns.lengthForward)}
          </WithUnit>
        ),
        model: (result: RegistryVariant) => <NumberFormat value={result.lengthForward} decimalScale={1} />,
      },
      {
        type: BottomColumns.lengthBackward,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.length)}>
            {i18n.t(translate + BottomColumns.lengthBackward)}
          </WithUnit>
        ),
        model: (result: RegistryVariant) => <NumberFormat value={result.lengthBackward} decimalScale={1} />,
      },
      {
        type: BottomColumns.tripNumberForward,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.items)}>
            {i18n.t(translate + BottomColumns.tripNumberForward)}
          </WithUnit>
        ),
        model: (result: RegistryVariant) => result.tripNumberForward,
      },
      {
        type: BottomColumns.tripNumberBackward,
        className: classes.bigSize,
        title: (
          <WithUnit unit={i18n.t(UNITS.items)}>
            {i18n.t(translate + BottomColumns.tripNumberBackward)}
          </WithUnit>
        ),
        model: (result: RegistryVariant) => result.tripNumberBackward,
      },
    ];

    variantColumns = variantColumns.filter(col => bottomColumns.includes(col.type));

    const variantBodyColumns = [...variantColumns];
    const index = variantBodyColumns.findIndex(col => col.type === BottomColumns.intervals);
    if (index !== -1) {
      variantBodyColumns.splice(index, 1, ...intervalsBody);
    }

    return [variantColumns, variantBodyColumns];
  }, [bottomColumns, intervalsOptions]);
};

export default useRegistryVariantConfig;
