import { useSelector } from 'react-redux';

import {
  VEHICLE_TYPES_COLORS,
  VehicleType,
  selectEditorLegendForm,
  useAcVehicleTypeId,
} from 'src/cluster/common';

const useInteractiveLines = () => {
  const {
    isSuburbanLine,
    isWaterLine,
    isMetroLine,
    isTramwayLine,
    isTrolleybusLine,
  } = useSelector(selectEditorLegendForm);

  const getVehicleTypeId = useAcVehicleTypeId();
  const suburbanVehicleTypeId = getVehicleTypeId(VehicleType.suburban);
  const waterVehicleTypeId = getVehicleTypeId(VehicleType.water);
  const metroVehicleTypeId = getVehicleTypeId(VehicleType.metro);
  const tramwayVehicleTypeId = getVehicleTypeId(VehicleType.tramway);
  const trolleybusVehicleTypeId = getVehicleTypeId(VehicleType.trolleybus);

  return [
    {
      id: 'suburban-line',
      vehicleId: suburbanVehicleTypeId,
      visible: !!suburbanVehicleTypeId && isSuburbanLine,
      color: VEHICLE_TYPES_COLORS[VehicleType.suburban],
      width: 2,
      dashArray: [2, 2],
    },
    {
      id: 'water-line',
      vehicleId: waterVehicleTypeId,
      visible: !!waterVehicleTypeId && isWaterLine,
      color: VEHICLE_TYPES_COLORS[VehicleType.water],
      width: 2,
      dashArray: [2, 2],
    },
    {
      id: 'metro-line',
      vehicleId: metroVehicleTypeId,
      visible: !!metroVehicleTypeId && isMetroLine,
      color: VEHICLE_TYPES_COLORS[VehicleType.metro],
      width: 2,
      dashArray: [1, 2],
    },
    {
      id: 'tramway-line',
      vehicleId: tramwayVehicleTypeId,
      visible: !!tramwayVehicleTypeId && isTramwayLine,
      color: VEHICLE_TYPES_COLORS[VehicleType.tramway],
      width: 1,
      dashArray: [1, 0],
    },
    {
      id: 'trolleybus-line',
      vehicleId: trolleybusVehicleTypeId,
      visible: !!trolleybusVehicleTypeId && isTrolleybusLine,
      color: VEHICLE_TYPES_COLORS[VehicleType.trolleybus],
      width: 1,
      dashArray: [1, 0],
    },
  ];
};

export default useInteractiveLines;
