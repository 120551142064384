import { RestifyForeignKeysArray } from 'redux-restify';

import { ROUTES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: ROUTES_ENDPOINT,
  parent: 'agglomerationCalculations',
  defaults: {
    routes: new RestifyForeignKeysArray('agglomerationCalculationsRoutes'),
    overshot: new RestifyForeignKeysArray('agglomerationCalculationsOvershot'),
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
