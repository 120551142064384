import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Button } from 'src/components';
import palette from 'src/theme/palette';

import useStyles from './styles';

interface OwnProps {
  count: number;
  onMoreClick(): void;
  onClearClick(): void;
}

type Props = OwnProps & WithTranslate;

const RegistryToolbarActions: FC<Props> = (props) => {
  const {
    t,
    count,
    onClearClick,
  } = props;

  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      mb={3}
    >
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        color={palette.primary.main}
        fontSize={10}
        lineHeight="13px"
      />
      {count > 0 && (
        <Box
          display="flex"
          alignItems="center"
          color="#9bb1b7"
          fontSize={10}
          lineHeight="13px"
        >
          {t('modules.registries.captions.applied', { count })}
          <span title={t('modules.registries.messages.clearFilter')}>
            <Button
              className={classes.clearButton}
              variant="link"
              onClick={onClearClick}
            >
              {t('components.captions.clear')}
            </Button>
          </span>
        </Box>
      )}
    </Box>
  );
};

export default RegistryToolbarActions;
