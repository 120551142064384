import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';

import { VehicleIcon, VehicleType } from 'src/cluster/common';

import { Slider } from 'src/components';
import { UNITS } from 'src/constants';
import useStyles from './styles';

const min = -30;
const max = 30;

interface OwnProps {
  id: number;
  type?: VehicleType;
  label?: string;
  value: number;
  onChange(id: number, value: number): void;
}

type Props = OwnProps & WithTranslate;

const VehicleSpeedSlider: FC<Props> = (props) => {
  const {
    id,
    type,
    label,
    value,
    t,
    onChange,
  } = props;

  const classes = useStyles();

  const handleChange = (newValue: number) => onChange(id, newValue);

  const marks = [
    {
      label: `${min} ${t(UNITS.speed)}`,
      value: min,
    }, {
      label: '',
      value: 0,
    }, {
      label: `+${max} ${t(UNITS.speed)}`,
      value: max,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <VehicleIcon type={type} />
        <span className={classes.label}>
          {label}
        </span>
      </div>
      <Slider
        className={classes.slider}
        name={type ?? 'unknown-type'}
        value={value}
        min={min}
        max={max}
        marks={marks}
        onChange={handleChange}
      />
      <div className={classes.footer}>
        <span>{t('modules.editor.captions.deceleration')}</span>
        <span>{t('modules.editor.captions.acceleration')}</span>
      </div>
    </div>
  );
};

export default VehicleSpeedSlider;
