import { createStyles, makeStyles } from '@material-ui/core';
import { MAIN_MENU_WIDTH } from 'src/theme/constants';

export default makeStyles(({ palette, spacing }) => createStyles({
  root: {
    width: 285,
  },

  paper: {
    left: MAIN_MENU_WIDTH,
    width: 285,
    top: 'unset',
    backgroundColor: palette.grey[50],
    borderWidth: 0,
    borderColor: palette.grey[400],
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    padding: spacing(2),
  },
}));
