import { AGGLOMERATION_INDICATORS_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: AGGLOMERATION_INDICATORS_ENDPOINT,
  idField: () => Math.random(),
  defaults: {
    tasks: undefined,
    years: undefined,
    indicatorsCatalog: undefined,
  },
  allowIdRequests: true,
  clearPagesOnRouteChange: false,
};
