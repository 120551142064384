import { Just, Maybe, Nothing } from 'monet';
import { createSelector } from 'reselect';

import {
  createEntitySelector,
  createFormSelector,
  createLoadsManager,
  isArray,
  isNumber,
} from 'src/helpers';
import { Model } from 'src/interfaces';

import {
  AC_TTC_PAGE_FORM_NAME,
  AC_TTC_MODEL_NAME,
  AC_BEHAVIOR_TYPES_MODEL_NAME,
  AC_TTC_YEARS_MODEL_NAME,
  AcPageBalanceTtcForm,
} from 'src/cluster/ttc';

import {
  AC_PROJECT_LIST_FORM_NAME,
  FILTER_MODES,
  AC_SCENARIOS_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_PROJECTS_ENDPOINT,
  AcBehaviorTypesModel,
  AcBalanceTtcModel,
  AcYearModel,
  AcBalanceBehaviorTypesModel,
  AcBalanceScenariosModel,
  AcProjectForm,
} from 'src/cluster/common';

export const projectLoadsManager = createLoadsManager(AC_PROJECTS_ENDPOINT);

export const selectAcTtcPageForm = createFormSelector<AcPageBalanceTtcForm>(AC_TTC_PAGE_FORM_NAME);

export const selectAcTtcEntity = createEntitySelector<AcBalanceTtcModel>(AC_TTC_MODEL_NAME);

export const selectAcTtcScenariosEntity = createEntitySelector<AcBalanceScenariosModel>(AC_SCENARIOS_MODEL_NAME);

export const selectAcTtcYearsEntity = createEntitySelector<AcYearModel>(AC_TTC_YEARS_MODEL_NAME);

export const selectAcTtcBehaviorTypesEntity = createEntitySelector<AcBehaviorTypesModel>(
  AC_BEHAVIOR_TYPES_MODEL_NAME,
);

export const selectAcPageProjectForm = createFormSelector<AcProjectForm>(AC_PROJECT_LIST_FORM_NAME);

export const selectPageForm = createSelector(
  selectAcTtcPageForm,
  (pageDataForm: AcPageBalanceTtcForm) => pageDataForm,
);

export const selectBalanceTtcCount = createSelector(
  [
    selectAcPageProjectForm,
    selectAcTtcPageForm,
    selectAcTtcEntity,
  ],
  (pageProjectsForm, pageTtcForm, ttcEntities): number => {
    const { selectedProject } = pageProjectsForm;
    const {
      scenario,
      behaviorType,
      interval,
      pageSize,
      currentPage,
      filterModeFrom,
      filterModeTo,
      regionFrom,
      regionTo,
      municipalityFrom,
      municipalityTo,
      year,

    } = pageTtcForm;

    if (!isNumber(selectedProject)) {
      return 0;
    }

    const ttcApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        page: currentPage,
        pageSize,
        regionFromId: filterModeFrom === FILTER_MODES.node ? regionFrom : undefined,
        regionToId: filterModeTo === FILTER_MODES.node ? regionTo : undefined,
        municipalityFromId: filterModeFrom === FILTER_MODES.municipality ? municipalityFrom : undefined,
        municipalityToId: filterModeTo === FILTER_MODES.municipality ? municipalityTo : undefined,
        year,
        scenarioId: scenario,
        behaviorId: behaviorType,
        intervalId: interval,
      },
    };
    return ttcEntities.getCount(ttcApiConfig);
  },
);

export const selectBalanceTtc = createSelector(
  [
    selectAcPageProjectForm,
    selectAcTtcPageForm,
    selectAcTtcEntity,
  ],
  (pageProjectsForm, pageTtcForm, ttcEntities): Maybe<Model<AcBalanceTtcModel>[]> => {
    const {
      scenario,
      behaviorType,
      interval,
      pageSize,
      currentPage,
      filterModeFrom,
      filterModeTo,
      regionFrom,
      regionTo,
      municipalityFrom,
      municipalityTo,
      year,
    } = pageTtcForm;

    if (!pageProjectsForm.selectedProject) {
      return Nothing();
    }

    if (!behaviorType) {
      return Nothing();
    }

    const ttcApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
      filter: {
        page: currentPage,
        pageSize,
        regionFromId: filterModeFrom === FILTER_MODES.node ? regionFrom : undefined,
        regionToId: filterModeTo === FILTER_MODES.node ? regionTo : undefined,
        municipalityFromId: filterModeFrom === FILTER_MODES.municipality ? municipalityFrom : undefined,
        municipalityToId: filterModeTo === FILTER_MODES.municipality ? municipalityTo : undefined,
        year,
        scenarioId: scenario,
        behaviorId: behaviorType,
        intervalId: interval,
      },
    };
    const entities = ttcEntities.getArray(ttcApiConfig);
    if (!isArray(entities)) {
      return Nothing();
    }
    return Just(entities);
  },
);

export const selectBalanceBehaviorTypes = createSelector(
  [
    selectAcPageProjectForm,
    selectAcTtcBehaviorTypesEntity,
  ],
  (pageProjectsForm, behaviorTypesEntities): [AcBalanceBehaviorTypesModel[], boolean, number] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
        0,
      ];
    }
    const behaviorTypesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      behaviorTypesEntities.getArray(behaviorTypesApiConfig),
      behaviorTypesEntities.getIsLoadingArray(behaviorTypesApiConfig),
      behaviorTypesEntities.getCount(behaviorTypesApiConfig),
    ];
  },
);

export const selectBalanceScenarios = createSelector(
  [
    selectAcPageProjectForm,
    selectAcTtcPageForm,
    selectAcTtcScenariosEntity,
  ],
  (pageProjectsForm, pageTtcForm, scenariosEntities): [AcBalanceScenariosModel[], boolean, number] => {
    if (!pageProjectsForm.selectedProject || !pageTtcForm.year) {
      return [
        [],
        false,
        0,
      ];
    }
    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
      filter: {
        year: pageTtcForm.year,
      },
    };

    return [
      scenariosEntities.getArray(scenariosApiConfig),
      scenariosEntities.getIsLoadingArray(scenariosApiConfig),
      scenariosEntities.getCount(scenariosApiConfig),
    ];
  },
);

export const selectCurrentProjectYears = createSelector(
  [
    selectAcPageProjectForm,
    selectAcTtcPageForm,
    selectAcTtcYearsEntity,
  ],
  (pageProjectsForm, pageFlowsForm, yearsEntities): [AcYearModel[], boolean] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
      ];
    }
    const yearsApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      yearsEntities.getArray(yearsApiConfig),
      yearsEntities.getIsLoadingArray(yearsApiConfig),
    ];
  },
);
