import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },

  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    color: theme.palette.text.primary,
  },

  subTitle: {
    padding: theme.spacing(3, 0, 3, 0),
  },

  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
    paddingBottom: '10px',
  },

  field: {
    padding: '0 0 15px',
    flexBasis: '100%',
  },

  fieldBottom: {
    paddingTop: '24px',
    borderTop: '1px solid',
    borderColor: 'var(--gray-500)',
  },

  stepContentWrapper: {
    padding: '0 24px',
  },

  stepLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.text.secondary,
  },

  stepLabelActive: {
    color: theme.palette.text.primary,
  },

  stepIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: theme.palette.common.white,
    background: '#80B9BC',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
  },

  stepIconActive: {
    background: theme.palette.primary.main,
  },

  lastCalculate: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    color: 'rgba(51, 59, 70, 0.7)',
    paddingLeft: '37px',
    marginTop: '-6px',
    display: 'block',
  },

  sliderWrapper: {
    maxWidth: 'min-content',
  },

  buttons: {
    display: 'flex',
    margin: '0',
    justifyContent: 'flex-end',
  },

  button: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    margin: '0 10px',
  },

  slider: {
    minWidth: 250,
    '& > #fullRemote': {
      margin: '20px auto',
    },
  },
}));
