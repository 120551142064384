import React from 'react';
import classNames from 'classnames';

import ShipmentsCalcToolbar from '../ShipmentsCalcToolbar';
import ShipmentsCalcTable from '../ShipmentsCalcTable';

import style from './index.module.css';

interface Props {
  className?: string;
}

const ShipmentsCalcPage: React.FC<Props> = ({
  className,
}) => (
  <div className={classNames(style.root, className)}>
    <ShipmentsCalcToolbar />
    <ShipmentsCalcTable />
  </div>
);

export default ShipmentsCalcPage;
