/* eslint-disable max-len */
import React from 'react';

const At = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M256,122.1c-68.3,0-133.9,65.6-133.9,133.9S187.7,389.9,256,389.9c43.6,0,85-20.9,108-60.7c22,22.9,57.1,24.7,57.1,24.7  c50.1,0,90.8-47.8,90.8-97.8C512,106.6,383.4-13.3,231,1.2C109.6,12.8,12.8,109.6,1.2,231C-13.3,383.3,106.6,512,256,512  c58.1,0,113.2-19.2,158.2-54.7c12.9-10.2,14.2-29.2,2.8-40.9l-1.2-1.3c-10-10.3-26.2-11.4-37.5-2.5c-35,27.4-75.6,39.8-122.2,39.8  c-117.9,0-202.3-88.6-196.1-208.8C65,144.6,144.6,65,243.6,59.9c120.2-6.2,208.8,78.2,208.8,196.1c0,22.8-8.5,36.3-31.3,36.3  C408,293,390,276,389.9,256C389.9,187.7,324.3,122.1,256,122.1z M259.5,330.2c-43.7,2-79.7-34-77.7-77.7c1.8-38.1,32.6-69,70.8-70.7  c43.7-2,79.7,34,77.7,77.7C328.5,297.6,297.6,328.5,259.5,330.2z" />
  </svg>
);

export default At;
