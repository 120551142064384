import { useSelector } from 'react-redux';

import i18n from 'src/i18n';

import { selectDashScenarios } from 'src/cluster/dashboard-common';

export const useAcDashScenarioName = (): (id?: number) => string => {
  const scenarios = useSelector(selectDashScenarios).orJust([]);

  return (id?: number) => {
    const record = scenarios.find((scenario) => {
      return scenario.id === id;
    });

    return record ? record.name : i18n.t('components.captions.notSelected');
  };
};

export default useAcDashScenarioName;
