import { useMemo } from 'react';

import { makeMapStyleStopLayer, VEHICLE_TYPES_COLORS, VehicleType } from 'src/cluster/common';
import { useVehicleTypeName } from 'src/cluster/editor-map';

import palette from 'src/theme/palette';
import useRadiusRange from './useRadiusRange';

const useStopsByRoutesLayers = (
  vehicleTypeId: number | undefined,
  variantIds: number[],
  visible: boolean,
  deps: any[],
) => {
  const range = useRadiusRange();

  const getVehicleTypeName = useVehicleTypeName();

  return useMemo(() => {
    return variantIds.map((id) => {
      const color = VEHICLE_TYPES_COLORS[getVehicleTypeName(vehicleTypeId) ?? VehicleType.all];
      return makeMapStyleStopLayer(
        `stops-by-routes-variant-${id}`,
        visible,
        ['has', ['to-string', id], ['get', 'route_variant_traffic']],
        {
          'circle-color': color,
          'circle-radius': [
            'case',
            ...range.reduce<any[]>((memo, item) => {
              return memo.concat([
                [
                  'all',
                  ['>', ['get', ['to-string', id], ['get', 'route_variant_traffic']], item.from],
                  ['<=', ['get', ['to-string', id], ['get', 'route_variant_traffic']], item.to],
                ], item.value,
              ]);
            }, []), 2,
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': palette.common.white,
        },
      );
    });
  }, [...deps, visible, range]);
};

export default useStopsByRoutesLayers;
