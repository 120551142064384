import { useMemo } from 'react';

import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';
import { useAcVehicleTypesOptions, AcDistPageForm } from 'src/cluster/common';
import { sortOptionsByNumber } from 'src/helpers';

import {
  AcDistFieldsConfig,
  AcDistPageFormEnum,
  useDistRoutesNames,
  useDistRoutesNumbers,
} from 'src/cluster/distribution-table';

const useMainFiltersConfig = (
  filters: Partial<AcDistPageForm>,
  setFilter: (val: SelectValue, field: AcDistPageFormEnum) => void,
): AcDistFieldsConfig[] => {
  const { vehicleTypeId, routeVariantId } = filters;

  const vehicleTypeOptions = useAcVehicleTypesOptions();
  const variantsNumbersOptions = useDistRoutesNumbers();
  const variantsNamesOptions = useDistRoutesNames();

  return useMemo(() => {
    const vehicleTypeValue = vehicleTypeOptions.find(opt => opt.value === vehicleTypeId);
    const variantNumberValue = variantsNumbersOptions.find(opt => opt.value === routeVariantId);
    const variantNameValue = variantsNamesOptions.find(opt => opt.value === routeVariantId);

    return [
      {
        label: 'systems.distribution.columns.vehicleTypeFull',
        multiple: false,
        options: vehicleTypeOptions,
        selected: vehicleTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcDistPageFormEnum.vehicleTypeId);
        },
      },
      {
        label: 'systems.distribution.columns.routeVariantName',
        multiple: false,
        options: variantsNamesOptions,
        selected: variantNameValue,
        disabled: !vehicleTypeId,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcDistPageFormEnum.routeVariantId);
        },
      },
      {
        label: 'systems.distribution.columns.routeOptionNumber',
        multiple: false,
        options: variantsNumbersOptions,
        selected: variantNumberValue,
        disabled: !vehicleTypeId,
        sort: sortOptionsByNumber,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcDistPageFormEnum.routeVariantId);
        },
      },
    ];
  }, [
    vehicleTypeId,
    routeVariantId,
    vehicleTypeOptions,
    variantsNumbersOptions,
    variantsNamesOptions,
  ]);
};

export default useMainFiltersConfig;
