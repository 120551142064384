import React from 'react';

const PlusIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4959 6.21392C13.0249 6.21392 13.4216 6.61066 13.4216 7.13965C13.4216 7.40414 13.3224 7.63557 13.1571 7.80088C12.9918 7.96619 12.7604 8.06538 12.4959 8.06538L8.06562 8.06538L8.06562 12.4956C8.06562 12.7601 7.96644 12.9916 7.80113 13.1569C7.63582 13.3222 7.40439 13.4214 7.13989 13.4214C6.61091 13.4214 6.21416 13.0246 6.21416 12.4956L6.21416 8.06538H1.78389C1.25491 8.06538 0.858166 7.66864 0.858166 7.13965C0.858166 6.61066 1.25491 6.21392 1.78389 6.21392H6.21416L6.21416 1.78365C6.21416 1.25466 6.61091 0.857922 7.13989 0.857923C7.66888 0.857922 8.06562 1.25466 8.06562 1.78365L8.06562 6.21392L12.4959 6.21392Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusIcon;
