import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';

import { acBalanceMapFormActions, ViewModes } from 'src/cluster/balance-map';

import BalanceMapSidebar from './BalanceMapSidebar';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setViewMode: (event: ChangeEvent<HTMLInputElement>, value: ViewModes) => {
    dispatch(acBalanceMapFormActions.changeField('viewMode', value));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(BalanceMapSidebar));
