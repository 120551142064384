import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import { toNumber } from 'src/helpers';
import style from './index.module.css';

import { LOADING_INDICATOR_TYPES } from './constants';

const circleRadius = 40;
const circleViewBox = 100;

class LoadingIndicator extends PureComponent<{
  className?: string;
  type: string;
  progress?: number;
  size?: number;
  inline: boolean;
}> {
  // eslint-disable-next-line react/static-property-placement
  public static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.values(LOADING_INDICATOR_TYPES)),
    progress: PropTypes.number,
  }

  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    className: '',
    size: 40,
    inline: false,
    type: LOADING_INDICATOR_TYPES.spinner,
  }

  public render() {
    const {
      className,
      type,
      progress,
      size,
      inline,
    } = this.props;

    let circleDashOffset = 0;
    let circleLength;
    if (type === LOADING_INDICATOR_TYPES.circle) {
      circleLength = Math.PI * circleRadius * 2;
      if (progress !== undefined) {
        circleDashOffset = circleLength * (100 - progress) / 100;
      } else {
        circleDashOffset = Number.NaN;
      }
    }

    return (
      <div {...{
        className: classNames(style.root, className, style[type], inline && style.inline),
      }} >
        {type === LOADING_INDICATOR_TYPES.text && (
          <span>
            loading
            {!!progress && ` (${toNumber(progress)}) `}
            ...
          </span>
        )}
        {type === LOADING_INDICATOR_TYPES.spinner && (
          <div className={style.circleRoot}>
            <CircularProgress
              variant="determinate"
              size={size}
              thickness={7}
              value={100}
              color="secondary"
            />
            <CircularProgress
              className={style.topCircle}
              size={size}
              thickness={7}
            />
          </div>
        )}
        {type === LOADING_INDICATOR_TYPES.circle && (
          <svg className={style.circle} viewBox={`0 0 ${circleViewBox} ${circleViewBox}`}>
            <circle {...{
              className: style.circleNotLoaded,
              r: circleRadius,
              cx: circleViewBox / 2,
              cy: circleViewBox / 2,
              fill: 'transparent',
              strokeDasharray: circleLength,
              strokeDashoffset: 0,
            }} />
            <circle {...{
              className: style.circleLoaded,
              r: circleRadius,
              cx: circleViewBox / 2,
              cy: circleViewBox / 2,
              fill: 'transparent',
              strokeDasharray: circleLength,
              strokeDashoffset: Number.isNaN(circleDashOffset) ? `${circleLength}px` : `${circleDashOffset}px`,
            }} />
          </svg>
        )}
      </div>
    );
  }
}

export default LoadingIndicator;
