import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/colors.css';
import './styles/variables.css';
import './styles/reset.css';
import './styles/fonts.css';
import './styles/global.css';

import { createElement } from 'react';
import ReactDOM from 'react-dom';

import { api, setRestifyStore } from 'redux-restify';

import 'moment/locale/ru';

import history from 'src/history';

import getStore from './store';
import Root from './app/components/Root';
import addStorageWriter from './store-serializer';

import configRestify from './config-restify';

import * as serviceWorker from './service-worker';
import { sagaMiddleware } from './config-store';
import rootSaga from './roots-saga';

if (!process.env.TEST) {
  configRestify();
  const store = getStore(history);
  setRestifyStore(store);

  addStorageWriter(store.getState);

  sagaMiddleware.run(rootSaga);

  store.dispatch({
    type: api.constants.ACTIONS_TYPES.loadsManager.reset,
  });

  const container = document.getElementById('root');
  ReactDOM.render(
    createElement(Root, { store, history }),
    container,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
