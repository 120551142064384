import React from 'react';

const ChevronRightIcon = ({ width = '9', height = '13' }) => (
  <svg width={width} height={height} viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.53636 0.195312L0 1.71198L5.04805 6.69531L0 11.6786L1.53636 13.1953L8.12078 6.69531L1.53636 0.195312Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRightIcon;
