import { useEffect } from 'react';
import { Maybe } from 'monet';

import { ProcessStatus } from 'src/types';
import { ProjectModel } from 'src/interfaces';

import { AcCopyRequestModel } from 'src/cluster/common';

export const useProjectStatusUpdate = <T extends ProjectModel>(
  projects: Maybe<T[]>,
  loadProject: (project: T) => void,
  queryInterval = 5000,
) => {
  useEffect(() => {
    const timer = setInterval(() => {
      const projectsInProgress = projects.orJust([]).filter((project) => {
        return project.loadStatus === ProcessStatus.QUEUED
            || project.loadStatus === ProcessStatus.IN_PROGRESS;
      });
      projectsInProgress.forEach(loadProject);
    }, queryInterval);

    return () => clearInterval(timer);
  }, [JSON.stringify(projects)]);
};

export const useCopyRequestStatusUpdate = <T extends AcCopyRequestModel>(
  copyRequests: Maybe<T[]>,
  loadCopyRequest: (request: T) => void,
  queryInterval = 5000,
) => {
  useEffect(() => {
    const timer = setInterval(() => {
      const requestsInProgress = copyRequests.orJust([]).filter((request) => {
        return request.status === ProcessStatus.IN_PROGRESS;
      });
      requestsInProgress.forEach(loadCopyRequest);
    }, queryInterval);

    return () => clearInterval(timer);
  }, [JSON.stringify(copyRequests)]);
};
