import React, {
  FC,
  ReactNode,
  useCallback, useMemo,
  useState,
} from 'react';
import classNames from 'classnames';

import { Theme, Tooltip, withStyles } from '@material-ui/core';
import { WithTranslate } from 'src/i18n';
import PInput, { INPUT_TYPES, InputTypes } from '../../deprecated/PInput';

import style from './index.module.css';

const ErrorTooltip = withStyles((theme: Theme) => ({
  popper: {
    transform: 'translate-y(-10px)',
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))(Tooltip);

const getInitialValue = (node: any, type: InputTypes): number | string => {
  if (type === INPUT_TYPES.int || type === INPUT_TYPES.float) {
    if (typeof node === 'number') {
      return node;
    }

    if (typeof node === 'object' && node?.props?.value !== undefined) {
      return Number.parseFloat(node.props.value);
    }

    return Number.parseFloat(node);
  }

  if (typeof node === 'string') {
    return node;
  }

  return String(node);
};

interface Props extends WithTranslate {
  className?: string;
  type?: InputTypes;
  formatted?: boolean;
  node: ReactNode;
  row: any;
  always?: boolean;
  icon?: ReactNode;
  autoFocus?: boolean;
  error?: string;
  onChange?: (val: any, row: any) => void;
}

const EditableCell: FC<Props> = (props: Props) => {
  const {
    className,
    type = INPUT_TYPES.text,
    formatted,
    node,
    row,
    always = false,
    icon = null,
    autoFocus,
    error = null,
    onChange,
    t,
  } = props;

  const initialValue = useMemo(() => getInitialValue(node, type), []);
  const [value, setValue] = useState<string | number>(initialValue);
  const [editing, setEditing] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleChange = useCallback((val) => {
    setValue(val);
  }, [setValue]);

  const handleBlur = useCallback(() => {
    setEditing(false);
    if (onChange) {
      onChange(value, row);
    }
  }, [value, setEditing]);

  const handleKeyDown = useCallback(async (event) => {
    if (event.key === 'Escape') {
      setEditing(false);
      setValue(initialValue);
    }
  }, [setEditing]);

  return (
    <div className={classNames(style.root, className)}>
      <ErrorTooltip
        title={error || ''}
        hidden={!error}
        placement="top"
        classes={{ tooltip: style.tooltip }}
        arrow
        PopperProps={{
          modifiers: {
            offset: {
              enable: true, offset: '0, -8',
            },
          },
        }}
      >
        <div
          className={classNames(style.wrapper, className)}
          onKeyDown={handleKeyDown}
        >
          {editing || always ? (
            <PInput
              type={type}
              formatted={formatted}
              className={classNames(style.input, error && style.error)}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus={autoFocus}
            />
          ) : (
            <button
              type="button"
              className={style.button}
              title={t('common.captions.edit')}
              onClick={handleClick}
            >
              {node}
              {icon && (
                <div className={style.icon}>
                  {icon}
                </div>
              )}
            </button>
          )}
        </div>
      </ErrorTooltip>
    </div>
  );
};

export default EditableCell;
