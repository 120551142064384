import React, { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { WithTranslate } from 'src/i18n';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import NumberFormat from 'src/components/etc/NumberFormat';
import { LinkIcon } from 'src/components/icons';
import WithUnit from 'src/components/common/WithUnit';

import { UNITS } from 'src/modules/general/common';
import { VideoVehicleOnRoutesModel } from 'src/modules/video/common';

import style from './index.module.css';

interface OwnProps {
  className?: string;
  entity: VideoVehicleOnRoutesModel;
}

type Props = OwnProps & WithTranslate;

const VideoStops: FC<Props> = (props) => {
  const {
    className,
    entity,
    locale,
    t,
  } = props;

  return (
    <LoadingBlockContainer
      className={classNames(style.root, className)}
      isBlocked={!entity.stops}
    >
      {entity.stops && (
        <table className={style.table}>
          <tbody>
            <tr>
              <td>{t('modules.video.captions.stopsOnRoute')}</td>
              {entity.stops.map(({ stopName }, index) => <td key={index}>{stopName}</td>)}
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.people)}>
                  {t('modules.video.captions.entered')}
                </WithUnit>
              </td>
              {entity.stops.map(({ passengersInDetected }, index) => (
                <td key={index}>{passengersInDetected}</td>
              ))}
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.people)}>
                  {t('modules.video.captions.gotOut')}
                </WithUnit>
              </td>
              {entity.stops.map(({ passengersOutDetected }, index) => (
                <td key={index}>{passengersOutDetected}</td>
              ))}
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.people)}>
                  {t('modules.video.captions.leftInVehicle')}
                </WithUnit>
              </td>
              {entity.stops.map(({ peopleLeftInside }, index) => (
                <td key={index}>{peopleLeftInside}</td>
              ))}
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.peoplePerSquare)}>
                  {t('modules.video.captions.density')}
                </WithUnit>
              </td>
              {entity.stops.map(({ floorDensity }, index) => (
                <td key={index}>
                  <NumberFormat
                    value={floorDensity}
                    locale={locale}
                    decimalScale={1}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('modules.video.captions.arrivalTime')}</td>
              {entity.stops.map(({ startDt }, index) => (
                <td key={index}>
                  {startDt && moment(startDt).utcOffset(7).format('HH:mm')}
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('modules.video.captions.video')}</td>
              {entity.stops.map(({ videosPerChannel }, index) => {
                return (
                  <td key={index}>
                    {Object.values(videosPerChannel).map((videos, i) => (
                      <div key={i}>
                        <span className={style.linkLabel}>
                          {t('modules.video.captions.door')}
                          {' '}
                          {i + 1}
                          {': '}
                        </span>
                        {videos.map((link, j) => (
                          <a
                            key={j}
                            className={style.link}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkIcon />
                          </a>
                        ))}
                      </div>
                    ))}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      )}
    </LoadingBlockContainer>
  );
};

export default VideoStops;
