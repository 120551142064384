export default {
  defaults: {
    city: undefined,
    vehicleType: undefined,
    vehicleMakeModel: undefined,
    transportCompany: undefined,
    routeNumber: undefined,
    routeTrip: undefined,

    dateAfter: undefined,
    dateBefore: undefined,

    timeAfter: undefined,
    timeBefore: undefined,

    weekdays: [],

    selectedRow: undefined,
    selectedRoute: undefined,
    selectedTrip: undefined,

    pageSize: 10,
    currentPage: 1,
  },
};
