import React from 'react';

const GearIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4807 6.00879L12.8177 5.72639C12.7236 5.42831 12.5981 5.14591 12.4569 4.86351L13.4296 3.49859C13.5708 3.29464 13.5551 3.01224 13.3825 2.83966L12.049 1.50612C11.8764 1.33354 11.594 1.30217 11.39 1.45905L10.0251 2.43176C9.7584 2.29056 9.46032 2.16505 9.16223 2.07091L8.89552 0.423596C8.84845 0.172576 8.6445 0 8.39348 0H6.51083C6.25981 0 6.05586 0.172576 6.00879 0.423596L5.72639 2.0866C5.42831 2.18074 5.14591 2.30625 4.86351 2.44744L3.49859 1.47474C3.29464 1.33354 3.01224 1.34923 2.83966 1.52181L1.50612 2.85535C1.33354 3.02793 1.30217 3.31033 1.45905 3.51428L2.43176 4.8792C2.29056 5.14591 2.16505 5.444 2.07091 5.74208L0.423596 6.00879C0.172576 6.05586 0 6.25981 0 6.51083V8.39348C0 8.6445 0.172576 8.84845 0.423596 8.89552L2.0866 9.17792C2.18074 9.476 2.30625 9.7584 2.44744 10.0408L1.47474 11.4057C1.33354 11.6097 1.34923 11.8921 1.52181 12.0646L2.85535 13.3982C3.02793 13.5708 3.31033 13.6021 3.51428 13.4453L4.8792 12.4726C5.14591 12.6138 5.444 12.7393 5.74208 12.8334L6.02448 14.4964C6.07155 14.7474 6.2755 14.92 6.52652 14.92H8.40917C8.66019 14.92 8.86414 14.7474 8.91121 14.4964L9.17792 12.8177C9.476 12.7236 9.7584 12.5981 10.0408 12.4569L11.4057 13.4296C11.6097 13.5708 11.8921 13.5551 12.0646 13.3825L13.3982 12.049C13.5708 11.8764 13.6021 11.594 13.4453 11.39L12.4726 10.0251C12.6138 9.7584 12.7393 9.46032 12.8334 9.16223L14.4964 8.87983C14.7474 8.83277 14.92 8.62881 14.92 8.37779V6.51083C14.9043 6.25981 14.7317 6.05586 14.4807 6.00879ZM7.45216 10.1349C5.96172 10.1349 4.76938 8.9269 4.76938 7.45216C4.76938 5.96172 5.97741 4.76938 7.45216 4.76938C8.9269 4.76938 10.1349 5.96172 10.1349 7.45216C10.1349 8.94259 8.94259 10.1349 7.45216 10.1349Z"
      fill="currentColor"
    />
  </svg>
);

export default GearIcon;
