import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ spacing, palette }: Theme) => createStyles({
  btn: {
    color: palette.primary.main,
    marginRight: spacing(4),
    transition: 'transform .3s ease',
  },
  collapsed: {
    transform: 'rotate(90deg)',
  },
  field: {
    fontFamily: ffSourceSansPro,
    width: 75,
    marginBottom: spacing(6),
    marginRight: spacing(5),
    paddingTop: spacing(1),

    '& label': {
      fontSize: 12,
    },
  },
  fieldMiddle: {
    width: 180,
  },
  fieldBig: {
    width: 300,
  },
  intervalField: {
    width: 200,
  },
  intervalFieldMini: {
    width: 80,
  },
  grid: {
    width: 350,
  },
  deleted: {
    opacity: 0.5,
  },
  restore: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
}));
