import React from 'react';

const TransportTypeMetro = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.8595 15.2362L6.62334 16L11.3138 11.3095C12.5104 10.11 13.2525 8.45453 13.2525 6.62625C13.2525 2.96678 10.2857 0 6.62625 0C2.96678 0 0 2.96678 0 6.62625C0 8.45308 0.742058 10.1129 1.93429 11.3051C2.03594 11.4068 2.16954 11.4663 2.32057 11.4663C2.61826 11.4663 2.85932 11.2253 2.85932 10.9276C2.85932 10.7765 2.79397 10.6386 2.69377 10.5384C1.69759 9.5422 1.07606 8.15248 1.07606 6.62625C1.07606 3.56653 3.56508 1.07751 6.6248 1.07751C9.68452 1.07751 12.175 3.56653 12.175 6.62625C12.175 8.15683 11.552 9.54366 10.5471 10.5486L5.8595 15.2362Z" fill="#E52629" />
    <path d="M7.68497 7.93321V8.57797H10.2451V7.93321H9.74705L8.05237 3.64786L6.62343 6.14995L5.19595 3.64786L3.49982 7.93321H3.00317V8.57797H5.56335V7.93321H5.17998L5.55318 6.86441L6.62343 8.62735L7.69513 6.86441L8.06834 7.93321H7.68497Z" fill="#E52629" />
  </svg>
);

export default TransportTypeMetro;
