import React, { FC, useState } from 'react';

import { WithTranslate } from 'src/i18n';
import { SelectValue } from 'src/interfaces';
import {
  Autocomplete,
  Toolbar,
  ToolbarBlock,
  CollapseWithToggle,
} from 'src/components';
import {
  useRegistryConfig,
  RegistryToolbarTitle,
  RegistryMainFilters,
  RegistrySettingsButton,
} from 'src/cluster/editor-table';

import useStyles from './styles';

export interface InjectedProps {
  registryId?: SelectValue;
  setRegistryFilter(val: SelectValue): void;
  showSettingsModal(): void;
  onExportClick(): void;
}

type Props = InjectedProps & WithTranslate;

const RegistryToolbar: FC<Props> = (props) => {
  const {
    t,
    registryId,
    setRegistryFilter,
    showSettingsModal,
  } = props;

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(value => !value);

  const classes = useStyles();

  const registryFilter = useRegistryConfig(setRegistryFilter, registryId);

  return (
    <Toolbar className={classes.root}>
      <ToolbarBlock size="x-large" title={(<RegistryToolbarTitle />)}>
        <CollapseWithToggle
          collapsed={collapsed}
          toggle={toggle}
          caption={t('common.captions.filter')}
        >
          <RegistryMainFilters />
        </CollapseWithToggle>
        <RegistrySettingsButton showSettingsModal={showSettingsModal} />
      </ToolbarBlock>
    </Toolbar>
  );
};

export default RegistryToolbar;
