import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  exportCaption: {
    color: palette.common.black,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingLeft: spacing(2),
  },
}));
