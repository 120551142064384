import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'src/reducer';
import { withTranslate } from 'src/i18n';

import {
  selectEditorPageForm,
  routeEditorFormSelectors,
  changeVariantNumber,
  deleteVariant,
  copyVariant,
} from 'src/cluster/editor-map';

import EditorRouteVariant from './EditorRouteVariant';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
  isDirty: routeEditorFormSelectors.getIsDirty(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  changeVariantNumber: (id: number, value: string) => {
    dispatch(changeVariantNumber(id, value));
  },
  copyVariant: (variantId: number) => {
    dispatch(copyVariant(variantId));
  },
  deleteVariant: (variantId: number, number: string) => {
    dispatch(deleteVariant(variantId, number));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(EditorRouteVariant));
