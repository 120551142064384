import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { AppState } from 'src/reducer';

import {
  selectDistForwardDirection,
  selectDistBackwardDirection,
  distributionLoadsDirectionEdge,
} from 'src/cluster/distribution-table';

import ModalTableDistribution from './ModalTableDistribution';

const mapStateToProps = (state: AppState) => ({
  distForwardDirection: selectDistForwardDirection(state),
  distBackwardDirection: selectDistBackwardDirection(state),
  isLoading: distributionLoadsDirectionEdge.selectIsDownloadingUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  close: () => {
    dispatch(closeAction);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalTableDistribution));
