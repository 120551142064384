import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    fontSize: 14,
    padding: theme.spacing(2, 4, 2, 4),

    '&:not(:last-of-type) > $cell': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[50],
    },
  },
  cell: {
    fontSize: 14,
    background: 'rgba(212, 212, 212, 0.6)',
    padding: theme.spacing(2, 4, 2, 4),
  },
  deleteIcon: {
    height: 12,
    width: 12,
    marginRight: theme.spacing(1),
    float: 'right',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
  },
  graphFileInput: {
    paddingLeft: theme.spacing(2),
  },
}));
