import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';
import { AC_TTC_ENDPOINT } from 'src/cluster/ttc';

export default {
  endpoint: AC_TTC_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    regionFromId: undefined,
    regionToId: undefined,
    regionFromName: undefined,
    regionToName: undefined,
    year: undefined,
    source: undefined,
    ttcBase: undefined,
    ttcForecast: undefined,
    ttcFromBase: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
};
