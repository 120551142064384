import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import { DataGrid, DataGridColumnProps, NumberFormat } from 'src/components';

import { SubindicatorItem } from 'src/cluster/dashboard-common';

import useStyles from './styles';

interface OwnProps {
  level: number;
  decimalScale: number;
  body: SubindicatorItem[];
}

type Props = OwnProps & WithTranslate;

const SubindicatorsDataGrid: FC<Props> = (props) => {
  const {
    level, body, locale, decimalScale,
  } = props;

  const classes = useStyles(level);

  const columns: DataGridColumnProps<SubindicatorItem>[] = [
    {
      model: (subIndicator) => subIndicator.name,
      className: classes.primaryColumn,
    },
    {
      model: (subIndicator) => (
        <NumberFormat
          value={subIndicator.baseValue}
          locale={locale}
          decimalScale={decimalScale}
        />
      ),
      className: classes.secondaryColumn,
    },
    {
      model: (subIndicator) => (
        <NumberFormat
          value={subIndicator.forecastValue}
          locale={locale}
          decimalScale={decimalScale}
        />
      ),
      className: classes.secondaryColumn,
    },
    {
      model: (subIndicator) => {
        if (!subIndicator.forecastValue || !subIndicator.baseValue) {
          return '-';
        }

        const value = 100 * (subIndicator.forecastValue - subIndicator.baseValue) / subIndicator.baseValue;
        return (
          <span className={value > 0 ? classes.positive : classes.negative}>
            <NumberFormat
              value={Math.abs(value)}
              locale={locale}
              decimalScale={0}
              suffix="%"
            />
          </span>
        );
      },
      className: classes.secondaryColumn,
    },
  ];

  return (
    <DataGrid<SubindicatorItem>
      hideHeader
      body={body}
      columns={columns}
    />
  );
};

export default SubindicatorsDataGrid;
