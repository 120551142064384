import { createSelector } from 'reselect';

import {
  api,
  forms,
} from 'redux-restify';

import { AppState } from 'src/reducer';
import { Systems } from 'src/constants';
import { StrictDict, Writable } from 'src/types';

import { PageProjectForm } from 'src/modules/general/projects';

import {
  NODES_CALCULATIONS_MODEL_NAMES,
  ROUTES_CALCULATION_MODEL_NAMES,
  ROUTES_PAGES_FORM_NAMES,
  NodeModel,
  RouteModel,
  PageRoutesForm,
} from 'src/modules/cities/routes/constants';

import { CALCULATIONS_MODEL_NAME, CALCULATIONS_PAGES_FORM_NAME } from 'src/modules/cities/calculations';

export const getRoutesSelectors = (type: Systems) => {
  const pageRoutesFormName = ROUTES_PAGES_FORM_NAMES[type];
  const nodesModelName = NODES_CALCULATIONS_MODEL_NAMES[type];
  const routesModelName = ROUTES_CALCULATION_MODEL_NAMES[type];
  const getCurrentProjectNodes = createSelector(
    [
      (state) => forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
      (state) => api.selectors.entityManager[nodesModelName].getEntities<NodeModel>(state),
    ],
    (pageCalculationsForm, nodesEntities) => {
      if (!pageCalculationsForm.selectedCalculation) {
        return [];
      }
      const nodesApiConfig = {
        parentEntities: {
          [CALCULATIONS_MODEL_NAME]: pageCalculationsForm.selectedCalculation,
        },
      };
      return nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A');
    },
  );

  const getRoutesApiConfig = (
    pageProjectForm: PageProjectForm,
    pageRoutesForm: PageRoutesForm,
  ) => {
    if (!pageProjectForm.selectedCalculation) {
      return undefined;
    }
    const filters: StrictDict<string, string | undefined> = { transportType: pageRoutesForm.transportType };

    if (pageRoutesForm.from && pageRoutesForm.to) {
      filters.firstNode = pageRoutesForm.from;
      filters.secondNode = pageRoutesForm.to;
    }
    return {
      parentEntities: {
        [CALCULATIONS_MODEL_NAME]: pageProjectForm.selectedCalculation,
      },
      filter: filters,
    };
  };

  const getRoutesSelectorsConst = [
    (state: AppState) => forms.selectors[CALCULATIONS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[routesModelName].getEntities<RouteModel>(state),
  ] as const;
  const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

  const getCurrentProjectRoutes = createSelector(
    getProjectRoutesSelectors,
    (pageCalculationsForm, pageRoutesForm, routesEntities) => {
      const routesApiConfig = getRoutesApiConfig(pageCalculationsForm, pageRoutesForm);
      if (!routesApiConfig) {
        return {
          currentRoutes: [],
          currentRoutesIsLoading: false,
        };
      }
      return {
        currentRoutes: routesEntities.getArray(routesApiConfig),
        currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
      };
    },
  );

  return {
    getCurrentProjectNodes,
    getCurrentProjectRoutes,
  };
};
