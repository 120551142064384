import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { AppState } from 'src/reducer';
import {
  BottomColumns,
  TopColumns,
  selectAcRegistryPageForm,
} from 'src/cluster/editor-table';
import { registryPageFormActions } from 'src/cluster/editor-common';

import RegistryModalTableSettings from './RegistryModalTableSettings';

const mapStateToProps = (state: AppState) => ({
  tableSettings: selectAcRegistryPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  setTableSettings: (top: TopColumns[], bottom: BottomColumns[]) => {
    dispatch(registryPageFormActions.changeField('topLevel', top));
    dispatch(registryPageFormActions.changeField('bottomLevel', bottom));
    dispatch(closeAction);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(RegistryModalTableSettings));
