import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isArray, isNumber } from 'src/helpers';

import { selectMatrixForecastParams, selectAcProjectListForm } from 'src/cluster/common';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  ViewModes,
  loadEdgesExtreme,
  loadStopsExtreme,
  selectDistributionMapPageForm,
} from 'src/cluster/distribution-map';

const useGeojsonData = () => {
  const dispatch = useDispatch();
  const { selectedProject } = useSelector(selectAcProjectListForm);
  const [forecastId] = useSelector(selectMatrixForecastParams);
  const {
    year, scenarioId, intervalId, day,
  } = useSelector(selectDistGraphForm);
  const {
    viewMode, vehicleTypeIds, variantId,
  } = useSelector(selectDistributionMapPageForm);

  const [currentProject, setCurrentProject] = useState<number>();
  const [currentForecast, setCurrentForecast] = useState<number>();
  const [currentYear, setCurrentYear] = useState<number>();
  const [currentScenario, setCurrentScenario] = useState<number>();
  const [currentInterval, setCurrentInterval] = useState<number>();
  const [currentIsDay, setCurrentIsDay] = useState<boolean>();
  const [currentViewMode, setCurrentViewMode] = useState<ViewModes>();
  const [currentVehicleTypes, setCurrentVehicleTypes] = useState<number[]>([]);
  const [currentVariant, setCurrentVariant] = useState<number>();

  useEffect(() => {
    let canSetState = true;

    const isBaseParamsValid = selectedProject && forecastId && year && scenarioId && (intervalId || day);
    const isBaseParamsNew = currentProject !== selectedProject
      || currentForecast !== forecastId
      || currentYear !== year
      || currentScenario !== scenarioId;

    const isExtremeParamsValid = (viewMode === ViewModes.byVehicles && isArray(vehicleTypeIds))
      || (viewMode === ViewModes.byRoutes && isNumber(variantId));

    const isExtremeParamsNew = currentViewMode !== viewMode
      || JSON.stringify(currentVehicleTypes) !== JSON.stringify(vehicleTypeIds)
      || currentVariant !== variantId
      || currentInterval !== intervalId
      || currentIsDay !== day;

    if (isBaseParamsValid && isExtremeParamsValid && (isBaseParamsNew || isExtremeParamsNew)) {
      dispatch(loadEdgesExtreme());
      dispatch(loadStopsExtreme());
      if (canSetState) {
        setCurrentProject(selectedProject);
        setCurrentYear(year);
        setCurrentScenario(scenarioId);
        setCurrentForecast(forecastId);
        setCurrentInterval(intervalId);
        setCurrentIsDay(day);
        setCurrentViewMode(viewMode);
        setCurrentVehicleTypes(vehicleTypeIds);
        setCurrentVariant(variantId);
      }
    }

    return () => {
      canSetState = false;
    };
  }, [selectedProject, forecastId, year, scenarioId, intervalId, day, viewMode, vehicleTypeIds, variantId]);
};

export default useGeojsonData;
