import React, { useMemo } from 'react';

import i18n from 'src/i18n';
import { VehicleIcon, VehicleType } from 'src/cluster/common';
import {
  AcDistTableFieldsConfig,
  AcDistStopsModel,
  TopColumns,
} from 'src/cluster/distribution-table';
import { isNil } from 'src/helpers';
import { NumberFormat } from 'src/components';

const useDistPointConfigLeft = (
  getVehicleTypeName: (vehicleId?: number) => VehicleType | undefined,
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistStopsModel>[] => {
  return useMemo(() => {
    const translate = 'systems.distribution.columns.';
    return [
      {
        type: TopColumns.vehicleTypes,
        title: i18n.t(translate + TopColumns.vehicleTypes),
        className: classes.first,
        model: (result: AcDistStopsModel) => (
          result.vehicleTypes
            ? result.vehicleTypes.map(item => (
              <span key={item} className={classes.mr8}>
                <VehicleIcon type={getVehicleTypeName(item)} />
              </span>
            ))
            : <VehicleIcon type={getVehicleTypeName(result.vehicleTypes)} />
        ),
      },
      {
        type: TopColumns.stopName,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.stopName),
        model: (result: AcDistStopsModel) => result.stopName || '-',
      },
      {
        type: TopColumns.coordinates,
        className: classes.bigSize,
        title: i18n.t(translate + TopColumns.coordinates),
        model: (result: AcDistStopsModel) => (
          <>
            {isNil(result.longitude) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={6}
                value={result.longitude}
                locale="en"
              />
            )}
            <span> , </span>
            {isNil(result.latitude) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={6}
                value={result.latitude}
                locale="en"
              />
            )}
          </>
        ),
      },
    ];
  }, [getVehicleTypeName]);
};

export default useDistPointConfigLeft;
