import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',

      '&:hover $button': {
        opacity: 1,
      },
    },

    input: {
      width: 85,
      fontWeight: 'bold',
      marginRight: 8,
    },

    number: {
      flexShrink: 1,
      fontSize: 12,
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: palette.primary.main,
      marginRight: spacing(1),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    button: {
      opacity: 0,
      marginLeft: spacing(2),

      '&:first-of-type': {
        marginLeft: 'auto',
      },
    },
  });
});
