import app from 'src/app';
import i18n from 'src/i18n';
import { ThunkActionResult } from 'src/reducer';

import { TrackPoint } from 'src/cluster/common';
import { entitiesEditorFormActions, editorFormActions } from 'src/cluster/editor-common';
import {
  Pathfinder,
  findPath,
  mapRouteWithDirection,
  selectEditorSelectedRoute,
  selectEditorSelectedDirection,
  PointAddingDirection,
} from 'src/cluster/editor-map';

export function deletePoint(node: TrackPoint): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const findPathSegment = (firstPoint: number, lastPoint: number) => dispatch(findPath(firstPoint, lastPoint));

    const editableDirection = selectEditorSelectedDirection(state).orUndefined();
    if (!editableDirection) return;
    const pathfinder = new Pathfinder(editableDirection, findPathSegment);
    try {
      const newDirection = await pathfinder.deletePoint(node);
      const editableRoute = selectEditorSelectedRoute(state).orUndefined();
      if (!editableRoute) return;
      const newRoute = mapRouteWithDirection(editableRoute, newDirection);
      dispatch(entitiesEditorFormActions.changeField('editableRoute', newRoute));
    } catch (e) {
      console.error(e);
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.deletePointError')));
    }
  };
}

export function addPoint(
  newNode: TrackPoint,
  index: number | undefined = 0,
  addingDirection?: PointAddingDirection,
): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const findPathSegment = (firstPoint: number, lastPoint: number) => dispatch(findPath(firstPoint, lastPoint));

    const editableDirection = selectEditorSelectedDirection(state).orUndefined();

    if (!editableDirection || (!!editableDirection.nodes.length && !addingDirection)) return;

    const pathfinder = new Pathfinder(editableDirection, findPathSegment);
    try {
      const newDirection = await pathfinder.addPoint(newNode, index);
      const editableRoute = selectEditorSelectedRoute(state).orUndefined();
      if (!editableRoute) return;
      const newRoute = mapRouteWithDirection(editableRoute, newDirection);

      if (addingDirection === PointAddingDirection.after) {
        dispatch(editorFormActions.changeField('addIndex', index + 1));
      }
      if (!editableDirection.nodes.length) {
        dispatch(editorFormActions.changeField('addIndex', 1));
        dispatch(editorFormActions.changeField('addingDirection', PointAddingDirection.after));
      }
      dispatch(entitiesEditorFormActions.changeField('editableRoute', newRoute));
      dispatch(editorFormActions.changeField('nodeId', newNode.nodeId));
      dispatch(editorFormActions.changeField('nodeIndex', index));
    } catch (e) {
      console.error(e);
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.addPointError')));
    }
  };
}
