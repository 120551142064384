import { SelectMenuOption } from 'src/interfaces';
import { selectEditorScenariosByYear } from 'src/cluster/editor-common';
import { useSelector } from 'react-redux';

const useEditorScenarioOptions = (): SelectMenuOption[] => {
  const scenarios = useSelector(selectEditorScenariosByYear);
  return scenarios.orJust([]).map((scenario) => ({
    id: `${scenario.id}`,
    value: scenario.id,
    label: scenario.name,
  }));
};

export default useEditorScenarioOptions;
