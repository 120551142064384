import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import style from '../index.module.css';

interface LegendItem {
  message?: string;
  messageKey?: string;
  icon?: string;
}

const LEGEND_ITEMS: LegendItem[] = [
  { icon: style.correspondencePoint, messageKey: 'systems.mth.captions.correspondencePoint' },

  { messageKey: 'systems.mth.captions.trafficCoefficient' },
  { icon: style.routeLine1, message: '< 5 000' },
  { icon: style.routeLine2, message: '5 000 - 49 999' },
  { icon: style.routeLine3, message: '50 000 - 99 999' },
  { icon: style.routeLine4, message: '100 000 - 199 999' },
  { icon: style.routeLine5, message: '200 000 +' },

  { messageKey: 'systems.mth.captions.trafficCoefficient' },
  { icon: style.routeLoadLegend6, message: '0,9 +' },
  { icon: style.routeLoadLegend5, message: '0,7 - 0,9' },
  { icon: style.routeLoadLegend4, message: '0,55 - 0,7' },
  { icon: style.routeLoadLegend3, message: '0,4 - 0,55' },
  { icon: style.routeLoadLegend2, message: '0,3 - 0,4' },
  { icon: style.routeLoadLegend1, message: '< 0,3' },
];

const CitiesMapLegend: FC<WithTranslate> = ({ t }) => {
  const legendItems = LEGEND_ITEMS.map((item, index) => {
    const message = item.messageKey ? t(item.messageKey) : item.message;
    if (item.icon) {
      return (
        <div key={index} className={style.legendEntry}>
          <div className={style.legendRouteIcon}>
            <div className={item.icon} />
          </div>
          <div className={style.legendTitle}>
            {message}
          </div>
        </div>
      );
    }
    return (
      <div key={index} className={pageLayoutStyle.toolbarTitle}>
        {message}
      </div>
    );
  });
  return (
    <div className={style.info}>
      <PIcon {...{
        type: ICONS_TYPES.info,
        className: style.infoIcon,
      }} />
      <div className={style.mapLegend}>
        {legendItems}
      </div>
    </div>
  );
};

export default CitiesMapLegend;
