import React, { FC } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

import { ChevronDownIcon } from 'src/components';

const useStyles = makeStyles(({ palette, spacing }) => {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: spacing(2),
    },

    toggle: {
      display: 'flex',
      alignItems: 'center',
      border: 0,
      outline: 0,
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },

    arrow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 0,
      color: palette.secondary.main,
      textAlign: 'center',
      overflow: 'hidden',
      width: 16,
      height: 16,
      marginRight: spacing(1),
      transform: 'rotate(0deg)',
      transition: 'transform .3s ease',
    },

    collapsed: {
      transform: 'rotate(-90deg)',
    },

    caption: {
      marginLeft: 4,
      color: '#333b46',
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '1rem',
    },

    progress: {
      display: 'flex',
      justifyContent: 'start',
      flexGrow: 1,
    },

    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexGrow: 1,
    },
  });
});

interface Props {
  collapsed: boolean;
  caption?: string;
  toggle(): void;
  progress?: React.ReactElement;
  buttonsGroup?: React.ReactElement;
}

const CollapseToggle: FC<Props> = (props) => {
  const {
    progress, collapsed, caption, toggle, buttonsGroup,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>
        <button className={classes.toggle} onClick={toggle}>
          <span className={clsx(classes.arrow, collapsed && classes.collapsed)}>
            <ChevronDownIcon />
          </span>
          {caption && (
            <Typography className={classes.caption}>{caption}</Typography>
          )}
        </button>
      </span>

      <span className={classes.buttons}>
        <span>
          {buttonsGroup}
          <span className={classes.progress}>{progress}</span>
        </span>
      </span>
    </div>
  );
};

export default CollapseToggle;
