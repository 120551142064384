import { AC_PROJECTS_ENDPOINT } from 'src/cluster/common';

export default {
  endpoint: AC_PROJECTS_ENDPOINT,
  defaults: {
    id: undefined,
    name: undefined,
    sourceFiles: undefined,
    created: undefined,
    user: undefined,
    lastUsedDate: undefined,
    loadStatus: undefined,
    loadProgress: undefined,
    loadErrorMessage: undefined,
    validationErrors: undefined,
    externalLinks: undefined,
  },
  clearPagesOnRouteChange: false,
};
