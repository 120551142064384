import { useSelector } from 'react-redux';
import {
  VehicleType,
  selectEditorLegendForm,
} from 'src/cluster/common';

const useNetworkVisibility = (): (type: VehicleType) => boolean => {
  const {
    isTrolleybusNetwork,
    isSuburbanNetwork,
    isBusNetwork,
    isTramwayNetwork,
    isWaterNetwork,
  } = useSelector(selectEditorLegendForm);

  return (type: VehicleType) => {
    switch (type) {
      case VehicleType.bus: return isBusNetwork;
      case VehicleType.tramway: return isTramwayNetwork;
      case VehicleType.trolleybus: return isTrolleybusNetwork;
      case VehicleType.suburban: return isSuburbanNetwork;
      case VehicleType.water: return isWaterNetwork;
      default: return false;
    }
  };
};

export default useNetworkVisibility;
