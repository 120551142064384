import { CALCULATIONS_FILTERS_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: CALCULATIONS_FILTERS_ENDPOINT,
  parent: 'shipmentsCalculations',
  defaults: {
    edge: [],
    from: [],
    to: [],
  },
  pagination: false,
};
