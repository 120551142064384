import React, { Fragment, ReactNode } from 'react';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  className?: string;
  color: string;
  isOn: boolean;
  children?: ReactNode;
  toggle?: () => void;
}

const ChartToggle = (props: Props) => {
  const {
    className,
    color,
    isOn,
    children = null,
    toggle,
  } = props;

  return (
    <Fragment>
      <button {...{
        type: 'button',
        className: classNames(style.button, isOn ? null : style.off, className),
        style: { backgroundColor: color },
        onClick: toggle,
      }}
      />
      {children}
    </Fragment>
  );
};

export default ChartToggle;
