import { FILL_COLORS, Scale } from 'src/cluster/common';
import { getRange } from 'src/helpers';
import useBalanceMapData from '../entities/useBalanceMapData';

const useFactorScale = (): Scale<string> => {
  const factorValues = useBalanceMapData();

  const factorRange = getRange(factorValues.map((v) => v.value), 6);
  return factorRange.map((value, i, arr) => (
    {
      from: value,
      to: arr[i + 1] || Infinity,
      value: FILL_COLORS[i],
    }
  )).reverse();
};

export default useFactorScale;
