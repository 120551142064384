import React from 'react';
import classNames from 'classnames';
import { RestifyId } from 'redux-restify';

import { WithModalProps } from 'src/modals';
import { Systems, SystemsContext } from 'src/constants';
import { CalculationRoutesMap } from 'src/modules/cities/calculations';

import style from './index.module.css';

export interface CalculationMapModalProps {
  calculationId: RestifyId;
}

type Props = CalculationMapModalProps & WithModalProps & {
  className?: string;
}

const ModalCalculationMap: React.FC<Props> = ({
  className,
  calculationId,
}) => {
  return (
    <div className={classNames(style.root, className)}>
      <SystemsContext.Provider value={Systems.cities}>
        <CalculationRoutesMap {...{
          className: style.map,
          calculationId,
        }} />
      </SystemsContext.Provider>
    </div>
  );
};

export default ModalCalculationMap;
