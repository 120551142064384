import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import deepEqual from 'deep-equal';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import PLabel from 'src/components/deprecated/PLabel';

import { DISPATCH_DEBOUNCE, SEARCH_DEBOUNCE } from 'src/constants';
import {
  checkTime,
  DEFAULT_MAX_ROWS,
  ERROR_TYPES,
  FULL_ZERO_TIME,
  INNER_INPUT_TYPES,
  INPUT_TYPES,
  InputTypes,
  ROW_HEIGHT,
} from './constants';

import style from './index.module.css';

const KEY_CODES = {
  backspace: 8,
  enter: 13,
  esc: 27,
  arrowLeft: 37,
  arrow: 38,
  arrowRight: 39,
  arrowDown: 40,
  space: 32,
  ctrl: 17,
  cmd: 91,
  alt: 18,
  shift: 16,
  tab: 9,
};

const noopFunc = () => undefined;

interface State {
  formattedValue: string;
  height: number;
  wasBlurred: boolean;
  active: boolean;
  caretPosition?: number;
  prevPropsFormattedValue?: string;
}

export interface InnerInputProps {
  type: InputTypes;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  value: string | number;
  label?: React.ReactNode;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  errors?: string[];
  showTextErrors?: boolean;
  children?: any;
  managedCaretPosition?: number;
  persistEvents: boolean;

  onChange: (...args: any) => void;
  onValid: (...args: any[]) => any;
  onInvalid: (...args: any[]) => any;
  onEnter: (...args: any[]) => any;
  onSearch: (...args: any[]) => any;
  onFocus: (...args: any[]) => any;
  onBlur: (...args: any[]) => any;
  onSelect?: (...args: any[]) => any;

  settings: {
    formatValue: Function;
    getValue: Function;
    requiredError?: string;
    checkOnBlur?: boolean;
    required?: boolean;
    maxLen?: number;
    minLen?: number;
    format?: any;
    include?: any;
    exclude?: any;
  };

  // Only for multi
  minRows: number;
  maxRows: number;

  // Only for number
  min?: number;
  max?: number;
}

class RawInput extends PureComponent<InnerInputProps, State> {
  // eslint-disable-next-line react/static-property-placement
  public static propTypes = {
    type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.node,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    showTextErrors: PropTypes.bool,
    children: PropTypes.node,

    onChange: PropTypes.func,
    onValid: PropTypes.func,
    onInvalid: PropTypes.func,
    onEnter: PropTypes.func,
    onSearch: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    settings: PropTypes.shape({
      formatValue: PropTypes.func.isRequired,
      getValue: PropTypes.func.isRequired,
      checkOnBlur: PropTypes.bool,
      required: PropTypes.bool,
      maxLen: PropTypes.number,
      minLen: PropTypes.number,
      format: PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp),
        PropTypes.shape({
          regexp: PropTypes.instanceOf(RegExp),
          error: PropTypes.string,
        }),
      ]),
      include: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.instanceOf(RegExp),
      ]),
      exclude: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.instanceOf(RegExp),
      ]),
    }),

    validate: PropTypes.shape({
      checkOnBlur: PropTypes.bool,
      required: PropTypes.bool,
      maxLen: PropTypes.number,
      minLen: PropTypes.number,
      format: PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp),
        PropTypes.shape({
          regexp: PropTypes.instanceOf(RegExp),
          error: PropTypes.string,
        }),
      ]),
      include: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.instanceOf(RegExp),
      ]),
      exclude: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.instanceOf(RegExp),
      ]),
    }),

    // Only for multi
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
  }

  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    type: INPUT_TYPES.text,
    value: '',
    showTextErrors: true,
    persistEvents: false,
    onChange: noopFunc,
    onValid: noopFunc,
    onInvalid: noopFunc,
    onEnter: noopFunc,
    onSearch: noopFunc,
    onFocus: noopFunc,
    onBlur: noopFunc,
    settings: {},
    minRows: 1,
    maxRows: DEFAULT_MAX_ROWS,
  }

  public static getDerivedStateFromProps(props: InnerInputProps, state: State) {
    const {
      type,
      value,
      settings: { formatValue },
    } = props;
    let formattedValue = formatValue(value?.toString());
    let stateFormattedValue = state.formattedValue;
    if (type === INPUT_TYPES.time) {
      formattedValue = `${formattedValue}${FULL_ZERO_TIME.substr(formattedValue.length)}`;
      stateFormattedValue = `${stateFormattedValue}${FULL_ZERO_TIME.substr(stateFormattedValue.length)}`;
    }
    if (formattedValue === state.prevPropsFormattedValue) return null;
    if (type === INPUT_TYPES.moneyNumber || type === INPUT_TYPES.int || type === INPUT_TYPES.float) {
      stateFormattedValue = stateFormattedValue || '0';
      const parts = stateFormattedValue.split(/[\u002c\u002e]/);
      const fraction = +(parts[1] || '0');
      if (!fraction) stateFormattedValue = parts[0]; // don`t state change on zero fraction
      stateFormattedValue = stateFormattedValue.replace(/^[0\s]*/g, '') || '0';
    }
    if (formattedValue !== stateFormattedValue) {
      return {
        formattedValue,
        prevPropsFormattedValue: formattedValue,
      };
    }
    return {
      prevPropsFormattedValue: formattedValue,
    };
  }

  private selectionStart = 0;
  private selectionEnd = 0;
  // eslint-disable-next-line react/sort-comp
  private shadow?: any;
  private input?: any;
  private inputWidth?: number;

  private throttledOnChangeEvent: (...args: any[]) => any;
  private debouncedOnSearchEvent: (...args: any[]) => any;

  public constructor(props: InnerInputProps) {
    super(props);
    const {
      value,
      settings: { formatValue },
    } = props;

    this.state = {
      formattedValue: formatValue(String(value)),
      height: props.minRows * ROW_HEIGHT,
      wasBlurred: false,
      active: false,
      caretPosition: undefined,
    };

    this.heightChange = this.heightChange.bind(this);
    this.validate = this.validate.bind(this);
    this.handleValidate = debounce(this.handleValidate.bind(this), DISPATCH_DEBOUNCE);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    if (this.props.persistEvents) {
      this.throttledOnChangeEvent = this.props.onChange;
    } else {
      this.throttledOnChangeEvent = throttle(this.props.onChange, DISPATCH_DEBOUNCE);
    }
    this.debouncedOnSearchEvent = debounce(this.props.onSearch, SEARCH_DEBOUNCE);
  }

  public componentDidMount() {
    this.handleValidate();
  }

  public componentDidUpdate(prevProps: InnerInputProps) {
    // We can not compare functions
    const currentSettings = {
      ...this.props.settings,
      formatValue: undefined,
      getValue: undefined,
    };
    const prevSettings = {
      ...prevProps.settings,
      formatValue: undefined,
      getValue: undefined,
    };
    if (this.props.value !== prevProps.value || !deepEqual(currentSettings, prevSettings)) {
      this.handleValidate();
    }
  }

  private heightChange() {
    const { shadow } = this;
    if (shadow) {
      const { minRows, maxRows } = this.props;
      const { height } = this.state;
      const maxHeight = (maxRows || minRows) * ROW_HEIGHT;
      const minHeight = minRows * ROW_HEIGHT;
      let newHeight = shadow.scrollHeight;
      newHeight -= newHeight % ROW_HEIGHT; // fix for first render

      if (maxHeight >= height) newHeight = Math.min(maxHeight, newHeight);
      newHeight = Math.max(minHeight, newHeight);
      if (height !== newHeight) this.setState({ height: newHeight });
    }
  }

  private validate() {
    const {
      value,
      settings: {
        format,
        required,
        requiredError,
        minLen,
        maxLen,
      },
    } = this.props;

    // Required validation, overrides all other errors
    if (required && /^\s*$/.test(value.toString())) return [requiredError || ERROR_TYPES.required];

    const errors = [];

    const regexpToMatch = format && format.regexp || format;
    if (value && regexpToMatch && !regexpToMatch.test(value)) {
      errors.push(format.error || ERROR_TYPES.format);
    }

    if (maxLen && value.toString().length > maxLen) {
      errors.push(ERROR_TYPES.maxLen + maxLen);
    }

    if (minLen && (required || value.toString().length > 0) && value.toString().length < minLen) {
      errors.push(ERROR_TYPES.minLen + minLen);
    }

    return errors;
  }

  private handleValidate() {
    const {
      errors,
      onValid,
      onInvalid,
    } = this.props;
    const validateErrors = this.validate();
    if (validateErrors !== errors) {
      if (validateErrors && validateErrors.length) {
        onInvalid(validateErrors);
      } else {
        onValid();
      }
    }
  }

  private handleChange(e: any) {
    const {
      type,
      value,
      settings: { getValue, formatValue },
      persistEvents,
    } = this.props;
    let formattedValue = formatValue(e.target.value);
    let newValue = getValue(formattedValue);
    if (type === INPUT_TYPES.time) {
      const isValid = checkTime(formattedValue);
      if (!isValid) {
        e.preventDefault();
        return false;
      }
    }
    if (
      type === INPUT_TYPES.int ||
      type === INPUT_TYPES.float ||
      type === INPUT_TYPES.moneyNumber ||
      type === INPUT_TYPES.percent
    ) {
      const {
        min,
        max,
      } = this.props;
      if (min !== undefined && newValue < min) {
        newValue = min;
        formattedValue = formatValue(min);
      }
      if (max !== undefined && newValue > max) {
        newValue = max;
        formattedValue = formatValue(max);
      }
    }
    if (persistEvents) {
      e.persist();
    }
    this.setState({
      caretPosition: this.selectionStart,
      formattedValue,
    }, () => {
      this.throttledOnChangeEvent(newValue, e);
      if (value !== newValue) {
        this.debouncedOnSearchEvent(newValue);
      }
    });
    return true;
  }

  private handleSelect(e: any) {
    this.selectionStart = e.target.selectionStart;
    this.selectionEnd = e.target.selectionEnd;
    if (this.props.onSelect) {
      this.props.onSelect(e);
    }
  }

  private changeSelection(delta = 0, additional = '') {
    const { formattedValue } = this.state;
    const { type, settings: { getValue, formatValue } } = this.props;
    const splitFormattedValue = `${formattedValue.substr(0, this.selectionStart - delta)}${additional}`;
    let newSplitFormattedValue = formatValue(splitFormattedValue);
    if (type === INPUT_TYPES.money || type === INPUT_TYPES.moneyNumber ||
      type === INPUT_TYPES.int || type === INPUT_TYPES.float) {
      const splitValue = getValue(splitFormattedValue, false);
      const splitValueLength = splitValue.length;
      const newFormattedValue = formatValue(`${splitFormattedValue}${formattedValue.substr(this.selectionStart)}`);
      newSplitFormattedValue = newFormattedValue.substr(0, splitValueLength);
      let i = 0;
      while (getValue(newSplitFormattedValue, false).length < splitValueLength) {
        i += 1;
        newSplitFormattedValue = newFormattedValue.substr(0, splitValueLength + i);
      }
      const lastOfSplitFormattedValue = splitFormattedValue[splitFormattedValue.length - 1];
      if (/[\u002c\u002e]/.test(lastOfSplitFormattedValue)) newSplitFormattedValue += lastOfSplitFormattedValue;
    }
    this.selectionStart = newSplitFormattedValue.length;
    this.selectionEnd = newSplitFormattedValue.length;
  }

  private handleKeyDown(e: any) {
    if (e.keyCode === KEY_CODES.backspace) {
      const delta = +(this.selectionStart === this.selectionEnd);
      this.changeSelection(delta);
    }
    if (e.keyCode === KEY_CODES.enter) {
      const { getValue, formatValue } = this.props.settings;
      const { formattedValue } = this.state;
      const currentValue = formatValue(getValue(formattedValue));
      this.handleBlur();
      this.props.onEnter(currentValue);
      this.props.onSearch(currentValue);
    }
  }

  private handleKeyPress(e: any) {
    const char = String.fromCharCode(e.charCode);
    const {
      type,
      settings: {
        include,
        exclude,
        getValue,
        formatValue,
      },
    } = this.props;
    const { formattedValue } = this.state;
    let shouldPreventDefault: any = false;
    if (type === INPUT_TYPES.money || type === INPUT_TYPES.moneyNumber ||
      type === INPUT_TYPES.int || type === INPUT_TYPES.float) {
      shouldPreventDefault = char === '-' && (this.selectionStart !== 0 || formattedValue.includes('-'));
      shouldPreventDefault += (char === '.' || char === ',') && formattedValue.includes(',');
      const roundValue = Math.floor(getValue(formattedValue)).toString();
      const roundFormattedValue = formatValue(roundValue);
      shouldPreventDefault += roundValue.length >= Number.MAX_SAFE_INTEGER.toString().length - 1 &&
        this.selectionStart === this.selectionEnd &&
        !(char === '.' || char === ',') &&
        this.selectionStart <= roundFormattedValue.length;
    }
    if (exclude && !include) {
      shouldPreventDefault += (Array.isArray(exclude) ? exclude.includes(e.key) : exclude.test(e.key));
    }
    if (include) {
      shouldPreventDefault += (Array.isArray(include) ? !include.includes(e.key) : !include.test(e.key));
    }
    if (shouldPreventDefault) {
      e.preventDefault();
    } else {
      this.changeSelection(0, char);
    }
  }

  private handlePaste(e: any) {
    const pastedText = e.clipboardData.getData('text');
    this.changeSelection(0, pastedText);
  }

  private handleFocus() {
    this.setState({ active: true });
    this.props.onFocus();
  }

  private handleBlur() {
    const {
      settings: { getValue, formatValue },
      onBlur,
    } = this.props;
    const { formattedValue } = this.state;
    const currentValue = formatValue(getValue(formattedValue));
    this.setState({
      wasBlurred: true,
      active: false,
      formattedValue: currentValue,
    });
    this.handleValidate();
    onBlur(currentValue);
  }

  public render() {
    const {
      className,
      labelClassName,
      inputClassName,
      type,
      disabled,
      autoFocus,
      placeholder,
      label,
      errors,
      showTextErrors,
      children,
      settings: {
        required,
        checkOnBlur,
      },
    } = this.props;

    const {
      formattedValue,
      height,
      wasBlurred,
      active,
      caretPosition,
    } = this.state;

    const inputProps = {
      ref: (node: any) => {
        this.input = node;
        if (node) {
          this.inputWidth = node.offsetWidth;
        }
        if (node && caretPosition !== undefined) {
          let caretPositionToSet = caretPosition;
          if (type === INPUT_TYPES.percent && caretPositionToSet >= formattedValue.length) {
            caretPositionToSet = formattedValue.length - 1;
          }
          node.setSelectionRange(caretPositionToSet, caretPositionToSet);
        }
      },
      placeholder,
      disabled,
      autoFocus,
      value: formattedValue,
      onChange: this.handleChange,
      onSelect: this.handleSelect,
      onKeyDown: this.handleKeyDown,
      onKeyPress: this.handleKeyPress,
      onPaste: this.handlePaste,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
    };

    let currentErrors = errors;
    if (checkOnBlur && !wasBlurred || !Array.isArray(currentErrors)) {
      currentErrors = [];
    }

    const getInputComp = () => {
      switch (type) {
        case INPUT_TYPES.multi:
          return (
            <textarea {...{
              className: style.textarea,
              style: {
                lineHeight: `${ROW_HEIGHT}px`,
                height,
              },
              ...inputProps,
            }} />
          );
        default:
          return (
            <input {...{
              type: INNER_INPUT_TYPES[type],
              className: style.input,
              ...inputProps,
            }} />
          );
      }
    };

    return (
      <div className={classNames(
        style.rootWrapper,
        className,
      )}>
        {label &&
          <PLabel {...{
            className: classNames(labelClassName, style.label),
            required,
            label,
          }} />}
        <div className={classNames(
          style.root,
          inputClassName,
          {
            [style.error]: currentErrors.length > 0,
            [style.active]: active,
          },
        )}>
          {(type === INPUT_TYPES.phone || type === INPUT_TYPES.phoneWithExt) &&
            <span className={style.phoneCode}>
              +
            </span>}
          {type === INPUT_TYPES.search &&
            <PIcon {...{
              className: style.phoneCode,
              type: ICONS_TYPES.search,
              size: 20,
            }} />}
          {type === INPUT_TYPES.url &&
            <span className={style.phoneCode}>
              http://
            </span>}
          {type === INPUT_TYPES.multi &&
            <textarea {...{
              ref: (node) => {
                this.shadow = node;
                this.heightChange();
              },
              className: style.shadow,
              style: {
                height: 0,
                width: this.inputWidth,
                lineHeight: `${ROW_HEIGHT}px`,
              },
              value: formattedValue,
              tabIndex: -1,
              readOnly: true,
            }} />}
          {getInputComp()}
          {children}
        </div>
        {showTextErrors &&
          <div className={style.errors}>
            {currentErrors.map((error, index) => (
              <div className={style.errorText} key={index}>
                {error}
              </div>
            ))}
          </div>}
      </div>
    );
  }
}

export { INPUT_TYPES } from './constants';

export default RawInput;
