import React, { ChangeEvent, FC } from 'react';
import { Tooltip, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Button, TextField } from 'src/components';

import useStyles from './styles';

interface OwnProps {
  name: string;
  canDelete: boolean;
  onChange(value: string): void;
  onDelete(): void;
}

type Props = OwnProps & WithTranslate;

const StopNameInput: FC<Props> = (props) => {
  const {
    name,
    canDelete,
    t,
    onChange,
    onDelete,
  } = props;

  const classes = useStyles();

  const handleChange = (e: ChangeEvent<{ value: string }>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography component="span" className={classes.title}>
          {t('common.captions.name')}
        </Typography>
        <Button
          variant="link"
          className={classes.button}
          disabled={!canDelete}
          onClick={onDelete}
        >
          {canDelete
            ? t('common.captions.delete')
            : (
              <Tooltip title={`${t('modules.editor.messages.unableToDeleteStop')}`} arrow>
                <span className={classes.tooltipAnchor}>
                  {t('common.captions.delete')}
                </span>
              </Tooltip>
            )}
        </Button>
      </div>
      <TextField
        value={name}
        fullWidth
        onChange={handleChange}
      />
    </div>
  );
};

export default StopNameInput;
