import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Size } from '@material-ui/core';

import { isFunc } from 'src/helpers';

import EditableGridCell from '../EditableGridCell';
import TableCell from '../TableCell/TableCell';

import useStyles from './styles';

interface Props<T> {
  children: ReactNode;
  className?: string;
  index: number;
  row: T;
  body: T[];
  model?: (row: T, index: number, data: T[]) => ReactNode;
  size?: Size;
  hasInput: boolean;
  onSubmit?: (value: string, row: T, index: number, data: T[]) => void;
  onCancel?: (row: T, index: number, data: T[]) => void;
}

function GridCell<T extends Record<string, any>>(props: Props<T>) {
  const {
    children,
    className,
    index,
    row,
    body,
    model,
    hasInput,
    onCancel,
    onSubmit,
    ...rest
  } = props;

  const classes = useStyles();

  if (hasInput) {
    const handleBlur = () => {
      if (isFunc(onCancel)) {
        onCancel(row, index, body);
      }
    };

    const handleSubmit = (value: string) => {
      if (isFunc(onSubmit)) {
        onSubmit(value, row, index, body);
      }
    };

    const defaultValue = isFunc(model)
      ? (model(row, index, body) || '').toString()
      : '';

    return (
      <EditableGridCell
        {...rest}
        defaultValue={defaultValue}
        className={clsx(classes.root, classes.editableCell, className)}
        onCancel={handleBlur}
        onSubmit={handleSubmit}
      />
    );
  }

  return (
    <TableCell
      {...rest}
      className={clsx(classes.root, className)}
    >
      {children}
    </TableCell>
  );
}

export default GridCell;
