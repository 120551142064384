import {
  Dispatch, MutableRefObject, SetStateAction, useCallback,
} from 'react';
import { StaticMap } from 'react-map-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

type Popup = {content?: MapboxGeoJSONFeature | undefined; lat: number; lng: number};

const useDistributionOnMouseMove = (
  mapRef: MutableRefObject<StaticMap | null>,
  setPopup: Dispatch<SetStateAction<Popup>>,
  canReset: boolean,
) => {
  return useCallback(({ point, lngLat: [lng, lat] }): void => {
    if (!mapRef.current) return;

    const features = mapRef.current.queryRenderedFeatures(point);

    if (!features) {
      return;
    }

    const feature = features.reduce<MapboxGeoJSONFeature | null>((acc, feat) => {
      if (acc) {
        return acc;
      }

      if (feat && (feat.source === 'stops' || feat.source === 'edges')) {
        return feat;
      }

      return null;
    }, null);

    if (feature) {
      setPopup({ content: feature, lng, lat });
    } else if (canReset) {
      setPopup({ content: undefined, lng: 0, lat: 0 });
    }
  }, []);
};

export default useDistributionOnMouseMove;
