import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { AppState } from 'src/reducer';
import { isString } from 'src/helpers';

import {
  dashTablePageFormActions,
  selectDashTablePageForm,
  selectModalDashTableCategories,
  changeHiddenCategories,
} from 'src/cluster/dashboard-table';

import ModalTableDashboardCustomize from './ModalTableDashboardCustomize';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectDashTablePageForm(state),
  categories: selectModalDashTableCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  setSearchKey: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isString(value)) {
      dispatch(dashTablePageFormActions.changeField('searchModal', value));
    }
  },
  setHiddenCategories: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target;
    dispatch(changeHiddenCategories(name, checked));
  },
  close: () => {
    dispatch(closeAction);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ModalTableDashboardCustomize));
