import { ModelDefaults } from 'src/types';

import { AC_PROJECTS_MODEL_NAME, ROUTES_ENDPOINT, AcRoutesModel } from 'src/cluster/common';

const defaults: ModelDefaults<AcRoutesModel> = {
  id: undefined,
  isCircle: undefined,
  routeLongName: undefined,
  routeNumber: undefined,
  variants: undefined,
  vehicleTypeId: undefined,
  vehicleTypeName: undefined,
  vehicleClasses: undefined,
  carrier: undefined,
  communicationType: undefined,
  regularTransportationType: undefined,
  season: undefined,
  year: undefined,
};

export default {
  endpoint: ROUTES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults,
  clearPagesOnRouteChange: false,
  pagination: false,
  allowIdRequests: false,
};
