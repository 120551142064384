import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStylesGrid = makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    container: {
      padding: spacing(2.5, 0, 2.5, 10),
    },

    item: {
      fontSize: 12,
      fontWeight: 'bold',
      color: palette.grey[400],

      '& $title': {
        marginBottom: spacing(2.5),
        color: palette.common.black,
      },
    },

    title: {},
  });
});
