import React from 'react';

const MenuDashboardIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 0.361334C8.25173 0.359331 6.53919 0.857743 5.06485 1.79771C3.5905 2.73767 2.41595 4.07992 1.67989 5.66594C0.943842 7.25196 0.677041 9.01549 0.911 10.7483C1.14496 12.481 1.86991 14.1106 3.00023 15.4447L3.16689 15.6391H16.8336L17.0002 15.4447C18.1305 14.1106 18.8555 12.481 19.0895 10.7483C19.3234 9.01549 19.0566 7.25196 18.3206 5.66594C17.5845 4.07992 16.4099 2.73767 14.9356 1.79771C13.4613 0.857743 11.7487 0.359331 10.0002 0.361334ZM14.778 5.628L11.4891 8.85578C11.6572 9.21116 11.6943 9.61452 11.5938 9.99458C11.4932 10.3746 11.2616 10.7069 10.9398 10.9327C10.618 11.1586 10.2267 11.2633 9.83512 11.2286C9.44353 11.1938 9.07683 11.0217 8.79983 10.7428C8.52283 10.4638 8.35335 10.0959 8.32136 9.70408C8.28936 9.31225 8.3969 8.92172 8.62498 8.60151C8.85306 8.28131 9.18698 8.05203 9.56774 7.9542C9.94851 7.85637 10.3516 7.89628 10.7058 8.06689L13.9891 4.83911L14.778 5.628ZM2.00023 9.05578H3.88911V10.1669H1.978C1.978 9.95022 1.95023 9.73911 1.95023 9.51689C1.95023 9.29467 1.96134 9.21133 1.97245 9.05578H2.00023ZM4.71134 3.50022L6.078 4.86689L5.26134 5.63356L3.88911 4.27244C4.1354 3.99146 4.40085 3.72787 4.68356 3.48356L4.71134 3.50022ZM10.5558 3.43911H9.44467V1.49467H10.0002C10.2058 1.49467 10.3891 1.49467 10.5558 1.52245V3.43911ZM18.0502 9.52244C18.0502 9.73911 18.0502 9.96133 18.0224 10.1724H16.0669V9.06133H18.028C18.0391 9.21133 18.0502 9.36689 18.0502 9.52244Z"
      fill="currentColor"
    />
  </svg>
);
export default MenuDashboardIcon;
