import {
  isBoolean, isObject, isNumber,
} from 'src/helpers';
import {
  AcEdgeSpeed, AcEdgeSpeedChange,
} from 'src/cluster/editor-map';

export function isAcEdgeSpeed(record: unknown): record is AcEdgeSpeed {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    vehicleTypeId,
    edgeId,
    dedicatedLane,
    speed,
    speedDedicatedLane,
  } = record as Partial<AcEdgeSpeed>;

  const isValidModel = isNumber(id)
    && isNumber(vehicleTypeId)
    && isNumber(edgeId)
    && isBoolean(dedicatedLane)
    && isNumber(speed)
    && isNumber(speedDedicatedLane);

  if (!isValidModel) {
    console.warn('The record is not a valid AcEdgeSpeed.');
  }

  return isValidModel;
}

export function isAcEdgeSpeedChange(record: unknown): record is AcEdgeSpeedChange {
  if (!isObject(record)) {
    return false;
  }

  const {
    id,
    speedChange,
    scenarioId,
    intervalId,
    year,
    vehicleTypeId,
  } = record as Partial<AcEdgeSpeedChange>;

  const isValidModel = isNumber(id)
    && isNumber(scenarioId)
    && isNumber(intervalId)
    && isNumber(year)
    && isNumber(speedChange)
    && isNumber(vehicleTypeId);

  if (!isValidModel) {
    console.warn('The record is not a valid AcEdgeSpeedChange.');
  }

  return isValidModel;
}
