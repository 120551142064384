import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import { DropDownValue } from 'src/components/deprecated/PSelect';

import { INDICATORS_PAGE_FORM_NAME } from 'src/modules/mth/indicators';

import AggloRouteToolbar from './AggloIndicatorsToolbar';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onSearchChange: (value: string) => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].changeField('search', String(value).toLowerCase()));
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('taskFilter'));
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('subtaskFilter'));
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('indicatorTypeFilter'));
  },
  onTaskChange: (values: DropDownValue[]) => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].changeField('taskFilter', values[0]));
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('subtaskFilter'));
  },
  onSubtaskChange: (values: DropDownValue[]) => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].changeField('subtaskFilter', values[0]));
  },
  onIndicatorTypeChange: (values: DropDownValue[]) => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].changeField('indicatorTypeFilter', values[0]));
  },
  resetSearch: () => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('search'));
  },
  resetTask: () => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('taskFilter'));
  },
  resetSubtask: () => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('subtaskFilter'));
  },
  resetIndicatorType: () => {
    dispatch(forms.actions[INDICATORS_PAGE_FORM_NAME].resetField('indicatorTypeFilter'));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(AggloRouteToolbar));
