import { useSelector } from 'react-redux';
import { PickInfo } from '@deck.gl/core/lib/deck';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import i18n from 'src/i18n';
import { Feature, MultiLineString, Point } from 'src/interfaces';
import { UNITS } from 'src/constants';

import {
  DeckGlTooltip, selectEditorLegendForm, useVehicleTypesMap, VehicleType,
} from 'src/cluster/common';
import { EditorMode, selectEditorPageForm } from 'src/cluster/editor-map';
import palette from 'src/theme/palette';

const useGetTooltip: () => DeckGlTooltip = () => {
  const { isAverageTravelSpeedCar, isDistrictCentroids } = useSelector(selectEditorLegendForm);
  const { editorMode } = useSelector(selectEditorPageForm);
  const vehicleTypesMap = useVehicleTypesMap();
  const autoId = vehicleTypesMap[VehicleType.auto];

  return (info: PickInfo<Feature<MultiLineString | Point>>) => {
    let html = null;
    let style: CSSProperties = {
      backgroundColor: '#fff',
      color: '#333B46',
      fontSize: 12,
      padding: '8px 12px',
    };
    const { object } = info;

    if (editorMode === EditorMode.addStop && object?.geometry?.type === 'MultiLineString') {
      html = ' ';
      style = {
        backgroundColor: palette.grey[50],
        border: `1px solid ${palette.grey.A200}`,
        borderRadius: '50%',
        padding: '10px',
        top: '-10px',
        left: '-10px',
      };
    } else if (
      isAverageTravelSpeedCar
      && object?.geometry?.type === 'MultiLineString'
      && object?.properties?.vehicleSpeeds?.[autoId]
    ) {
      html = `${Math.round(object?.properties?.vehicleSpeeds?.[autoId] * 100) / 100} ${i18n.t(UNITS.speed)}`;
    } else if (isDistrictCentroids && object?.geometry?.type === 'Point' && object?.properties?.name) {
      html = object?.properties?.name;
    }

    return html ? { html, style } : null;
  };
};

export default useGetTooltip;
