import React, { FC, Fragment } from 'react';

import { Model } from 'src/interfaces';
import { DataGrid, DataGridColumnProps } from 'src/components';

import {
  AcDashCategoriesModel,
  AcDashIndicatorResultsModel,
} from 'src/cluster/dashboard-common';
import { translateCategoryName } from 'src/cluster/dashboard-table';

import IndicatorDataGrid from '../IndicatorDataGrid';

import useStyles from './styles';

function isCategoryExpandable(record: AcDashCategoriesModel): boolean {
  return record && (record.subcategories.length > 0 || record.indicators.length > 0);
}

type Props = {
  level: number;
  categories: AcDashCategoriesModel[];
  baseIndicators: Model<AcDashIndicatorResultsModel>[];
  forecastIndicators: Model<AcDashIndicatorResultsModel>[];
}

const CategoryDataGrid: FC<Props> = (props) => {
  const {
    level, categories, baseIndicators, forecastIndicators,
  } = props;

  const classes = useStyles(level);

  const columns: DataGridColumnProps<AcDashCategoriesModel>[] = [
    {
      model: (category) => translateCategoryName(category.name),
      className: classes.primaryColumn,
    },
    {
      className: classes.secondaryColumn,
    },
    {
      className: classes.secondaryColumn,
    },
    {
      className: classes.secondaryColumn,
    },
  ];

  return (
    <DataGrid<AcDashCategoriesModel>
      hideHeader
      className={classes.root}
      body={categories}
      columns={columns}
      expandable={isCategoryExpandable}
      expand={(category) => (
        <Fragment>
          {category.subcategories.length > 0 && (
            <CategoryDataGrid
              level={level + 1}
              categories={category.subcategories}
              baseIndicators={baseIndicators}
              forecastIndicators={forecastIndicators}
            />
          )}
          {category.indicators.length > 0 && (
            <IndicatorDataGrid
              level={level + 1}
              indicators={category.indicators}
              baseIndicators={baseIndicators}
              forecastIndicators={forecastIndicators}
            />
          )}
        </Fragment>
      )}
    />
  );
};

export default CategoryDataGrid;
