import { SelectMenuOption } from 'src/interfaces';
import { useSelector } from 'react-redux';
import { selectAcYears } from 'src/cluster/common';

const useAcYearOptions = (): SelectMenuOption[] => {
  const years = useSelector(selectAcYears);
  return years.map((value) => value.map((year) => ({
    id: String(year.id),
    value: year.value,
    label: year.value,
  }))).orJust([]);
};

export default useAcYearOptions;
