import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    padding: spacing(1, 2),
    borderColor: palette.grey[400],

    '&:hover': {
      borderColor: palette.grey[400],
    },

    '&:disabled': {
      borderColor: palette.grey[400],
    },

    '&.MuiButton-outlined': {
      backgroundColor: palette.common.white,

      '&:hover': {
        backgroundColor: palette.text.secondary,
      },

      '&:disabled': {
        borderColor: palette.grey[400],
      },
    },
  },
}));
