import React, { FC, Fragment } from 'react';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import { DataGrid, DataGridColumnProps, WithUnit } from 'src/components';

import {
  AcDashCategoriesModel,
  AcDashIndicatorResultsModel,
  useAcDashBaseYear,
  useAcDashForecastYear,
} from 'src/cluster/dashboard-common';
import { translateCategoryName } from 'src/cluster/dashboard-table';

import { CategoryDataGrid, IndicatorDataGrid } from './components';

import useStyles from './styles';

function isCategoryExpandable(record: AcDashCategoriesModel): boolean {
  return record && (record.subcategories.length > 0 || record.indicators.length > 0);
}

interface OwnProps {
  categories: Model<AcDashCategoriesModel>[];
  baseIndicators: Model<AcDashIndicatorResultsModel>[];
  forecastIndicators: Model<AcDashIndicatorResultsModel>[];
  isLoading: boolean;
}

type Props = OwnProps & WithTranslate;

const DashboardDataGrid: FC<Props> = (props) => {
  const {
    categories, baseIndicators, forecastIndicators, t,
  } = props;

  const baseYear = useAcDashBaseYear();
  const forecastYear = useAcDashForecastYear();

  const classes = useStyles();

  const columns: DataGridColumnProps<AcDashCategoriesModel>[] = [
    {
      model: (category) => translateCategoryName(category.name),
      className: classes.primaryColumn,
    },
    {
      title: `${baseYear ?? '-'} ${t('common.captions.year')}`,
      className: classes.secondaryColumn,
    },
    {
      title: `${forecastYear ?? '-'} ${t('common.captions.year')}`,
      className: classes.secondaryColumn,
    },
    {
      title: (
        <span>
          {t('common.captions.change')}
          <WithUnit unit={t('common.units.percent')} />
        </span>
      ),
      className: classes.secondaryColumn,
    },
  ];

  return (
    <div className={classes.root}>
      <DataGrid<AcDashCategoriesModel>
        columns={columns}
        body={categories}
        expandable={isCategoryExpandable}
        expand={(category) => (
          <Fragment>
            {category.subcategories.length > 0 && (
              <CategoryDataGrid
                level={1}
                categories={category.subcategories}
                baseIndicators={baseIndicators}
                forecastIndicators={forecastIndicators}
              />
            )}
            {category.indicators.length > 0 && (
              <IndicatorDataGrid
                level={1}
                indicators={category.indicators}
                baseIndicators={baseIndicators}
                forecastIndicators={forecastIndicators}
              />
            )}
          </Fragment>
        )}
      />
    </div>
  );
};

export default DashboardDataGrid;
