/* eslint-disable max-len */
import React from 'react';

const Logout = () => (
  <svg viewBox="0 0 20 18" fill="none">
    <path
      d="M16 4.92505L14.5 6.32505L16.2 7.92505H8V9.92505H16.2L14.5 11.525L16 12.925L20 8.92505L16 4.92505Z"
      fill="currentColor"
    />
    <path
      d="M4.1 13.825C2.7 12.525 2 10.825 2 8.925C2 7.025 2.7 5.325 4.1 4.025C6.8 1.325 11.3 1.325 14 4.025L15.4 2.625C11.9 -0.875 6.2 -0.875 2.7 2.625C0.9 4.225 0 6.525 0 8.925C0 11.325 0.9 13.625 2.6 15.325C4.4 17.025 6.7 17.925 9 17.925C11.3 17.925 13.6 17.025 15.4 15.325L14 13.825C11.2 16.625 6.8 16.625 4.1 13.825Z"
      fill="currentColor"
    />
  </svg>
);

export default Logout;
