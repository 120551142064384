import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentVocabulary, getVocabularyValue, WithTranslate } from 'src/i18n';
import PButton from 'src/components/deprecated/PButton';
import PSelect from 'src/components/deprecated/PSelect';
import { DropDownValue } from 'src/components/deprecated/PSelect/components/DropDown/constants';

import modals from 'src/modals';

import routes, {
  TRANSPORT_TYPES,
  PageRoutesForm,
  ROUTES_PAGES_FORM_NAMES,
  RoutesMap,
  RoutesTable,
} from 'src/modules/cities/routes';

import { useRestifyForm } from 'src/helpers';

import { useSystemsContext } from 'src/constants';
import PSlider from 'src/components/deprecated/PSlider';
import { INPUT_TYPES } from 'src/components/deprecated/PInput';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const CitiesRoutesPage: FC<Props> = ({
  className,
  t,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [currentLocale, vocabulary] = useCurrentVocabulary();
  const [
    pageRoutesForm,
    pageRoutesFormActions,
  ] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);
  const [routeFrom, setRouteFrom] = React.useState<DropDownValue>(pageRoutesForm.from);
  const [routeTo, setRouteTo] = React.useState<DropDownValue>(pageRoutesForm.to);
  const [routeTransport, setRouteTransport] = React.useState<DropDownValue>(pageRoutesForm.transportType);
  const [maxAlternativeRoutes, setMaxAlternativeRoutes] = React.useState<number>(pageRoutesForm.maxAlternativeRoutes);

  // clear form once loaded
  useEffect(() => {
    dispatch(pageRoutesFormActions.changeField('from', undefined));
    dispatch(pageRoutesFormActions.changeField('to', undefined));
    dispatch(pageRoutesFormActions.changeField('transportType', undefined));
  }, []);

  const currentNodes = useSelector(routes.selectors[currentSystem].getCurrentProjectNodes);
  return (
    <div className={classNames(style.root, className)}>
      <div className={pageLayoutStyle.toolbar}>
        <div className={pageLayoutStyle.toolbarBlock}>
          <div className={pageLayoutStyle.toolbarTitle}>
            {t('common.captions.correspondence')}
          </div>
          <div className={pageLayoutStyle.toolbarControls}>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSelect {...{
                label: t('common.captions.from'),
                className: style.select,
                items: currentNodes.map(n => ({
                  value: n.name,
                  label: getVocabularyValue(currentLocale, vocabulary, n.name),
                })),
                values: routeFrom ? [routeFrom] : undefined,
                onChange: values => setRouteFrom(values[0]),
              }} />
            </div>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSelect {...{
                label: t('common.captions.to'),
                className: style.select,
                items: currentNodes.map(n => ({
                  value: n.name,
                  label: getVocabularyValue(currentLocale, vocabulary, n.name),
                })),
                values: routeTo ? [routeTo] : undefined,
                onChange: values => setRouteTo(values[0]),
              }} />
            </div>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSelect {...{
                label: t('systems.cities.captions.transportType'),
                className: style.select,
                items: Object.keys(TRANSPORT_TYPES).map(transportKey => ({
                  value: TRANSPORT_TYPES[transportKey],
                  label: transportKey,
                })),
                values: routeTransport ? [routeTransport] : undefined,
                onChange: values => setRouteTransport(values[0]),
              }} />
            </div>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSlider {...{
                name: 'maxAlternativeRoutes',
                onChange: (value) => setMaxAlternativeRoutes(value),
                label: t('systems.cities.captions.maxAlternativeRoutes'),
                value: maxAlternativeRoutes,
                valueType: INPUT_TYPES.int,
                min: 1,
                max: 50,
              }} />
            </div>
          </div>
        </div>
        <div className={classNames(pageLayoutStyle.toolbarBlock, style.buttonBlock)}>
          <div className={pageLayoutStyle.toolbarControls}>
            <PButton {...{
              className: style.button,
              onClick: () => {
                dispatch(pageRoutesFormActions.changeField('from', routeFrom));
                dispatch(pageRoutesFormActions.changeField('to', routeTo));
                dispatch(pageRoutesFormActions.changeField('transportType', routeTransport));
                dispatch(pageRoutesFormActions.changeField('maxAlternativeRoutes', maxAlternativeRoutes));
                dispatch(routes.actions[currentSystem].findRoutes()).then(foundRoutes => {
                  if (!foundRoutes.currentRoutes || !foundRoutes.currentRoutes.length) {
                    dispatch(modals.actions.showMessageBoxModal({
                      text: t('systems.cities.captions.noRoutesFound'),
                    }));
                  } else {
                    dispatch(pageRoutesFormActions.changeField('selectedRoute', 0));
                  }
                });
              },
            }}>
              {t('modules.routes.captions.calculateRoute')}
            </PButton>
          </div>
        </div>
      </div>
      <RoutesMap {...{
        className: style.map,
      }} />
      <RoutesTable {...{
        className: style.routesTable,
      }} />
    </div>
  );
};

export default CitiesRoutesPage;
