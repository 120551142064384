import React, {
  FC, SyntheticEvent, useCallback, useMemo,
} from 'react';
import classNames from 'classnames';
import moment from 'moment';

import PButton from 'src/components/deprecated/PButton';
import PTable from 'src/components/deprecated/PTable';
import WithUnit from 'src/components/common/WithUnit';
import { GeotagInverseIcon } from 'src/components/icons';

import { TableContainer, UNITS } from 'src/modules/general/common';
import { VideoStops, VideoVehicleOnRoutesModel } from 'src/modules/video/common';

import { Props } from './interfaces';

import style from './index.module.css';

const VideoTable: FC<Props> = (props: Props) => {
  const {
    className,
    pageForm: {
      selectedRow,
      pageSize,
      currentPage,
    },
    entities: [data, isLoading, count],
    locale,
    t,
    setSelected,
    showModal,
    onPageChange,
    onPageSizeChange,
  } = props;

  const currentEntity = selectedRow ? data.find((model) => model.id === selectedRow) : null;

  const makeGeoTagClickHandler = useCallback((row: VideoVehicleOnRoutesModel) => {
    return (e: SyntheticEvent) => {
      e.stopPropagation();
      showModal(row);
    };
  }, []);

  const header = useMemo(() => ([
    {
      title: t('modules.video.captions.city'),
      model: (model: VideoVehicleOnRoutesModel) => model.city,
    },
    {
      title: t('modules.video.captions.vehicleModel'),
      model: (model: VideoVehicleOnRoutesModel) => model.vehicleMakeModel,
    },
    {
      title: t('modules.video.captions.transportCompany'),
      model: (model: VideoVehicleOnRoutesModel) => model.transportCompany,
    },
    {
      title: t('modules.video.captions.routeNumber'),
      model: (model: VideoVehicleOnRoutesModel) => model.routeNumber,
    },
    {
      title: t('modules.video.captions.routeVariant'),
      model: (model: VideoVehicleOnRoutesModel) => model.routeTrip?.description,
    },
    {
      title: t('modules.video.captions.starTime'),
      model: (model: VideoVehicleOnRoutesModel) => model.startDt && moment(model.startDt).utcOffset(7).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: t('modules.video.captions.endTime'),
      model: (model: VideoVehicleOnRoutesModel) => model.endDt && moment(model.endDt).utcOffset(7).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: <WithUnit unit={t(UNITS.people)}>{t('modules.video.captions.totalTrafficPerTrip')}</WithUnit>,
      model: (model: VideoVehicleOnRoutesModel) => model.passengerCount,
    },
  ]),
  [locale],
  );

  return (
    <TableContainer
      isLoading={isLoading}
      className={classNames(style.root, className)}
      pageSize={pageSize}
      currentPage={currentPage}
      count={count}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    >
      <PTable<VideoVehicleOnRoutesModel>
        rowClassName={(route) => {
          return [
            selectedRow === route.id,
            style.row,
          ];
        }}
        headerCellClassName={style.headerCell}
        header={header}
        body={data}
        expandable
        isExpanded={(row) => selectedRow === row.id}
        rowExtraCell={(row) => {
          if (row.id !== selectedRow || !currentEntity) return null;
          return <VideoStops entity={currentEntity} />;
        }}
        hoveredComponent={
          (row: VideoVehicleOnRoutesModel) => (
            <PButton
              type="link"
              className={style.hovered}
              onClick={makeGeoTagClickHandler(row)}
            >
              <GeotagInverseIcon />
            </PButton>
          )
        }
        onRowClick={setSelected}
      />
    </TableContainer>
  );
};

export default VideoTable;
