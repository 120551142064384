import { AC_PROJECTS_MODEL_NAME, STOPS_ENDPOINT } from 'src/cluster/common';

export default {
  endpoint: STOPS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    stopName: undefined,
    nodeId: undefined,
    routeDirections: undefined,
    vehicleTypes: undefined,
    coordinates: undefined,
    routeVehicleTypes: undefined,
    communicationTypes: undefined,
    regularTransportationTypes: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
  allowIdRequests: false,
};
