import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { DropDownValue } from 'src/components/deprecated/PSelect';
import { selectTotalTraffic, VIDEO_PAGE_FORM_NAME } from 'src/modules/video/common';

import { OwnProps } from './interfaces';
import VideoToolbar from './VideoToolbar';

const mapStateToProps = (state: AppState) => ({
  totalTraffic: selectTotalTraffic(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { pageForm: { weekdays = [] } }: OwnProps) => ({
  setCity: ([value]: DropDownValue[]) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('vehicleType'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('vehicleMakeModel'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('transportCompany'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('routeNumber'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('routeTrip'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('city', value));
  },
  setVehicleType: ([value]: DropDownValue[]) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('vehicleMakeModel'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('routeNumber'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('routeTrip'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('vehicleType', value));
  },
  setVehicleMakeModel: ([value]: DropDownValue[]) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('vehicleMakeModel', value));
  },
  setTransportCompany: ([value]: DropDownValue[]) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('transportCompany', value));
  },
  setRouteNumber: ([value]: DropDownValue[]) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('routeTrip'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('routeNumber', value));
  },
  setRouteTrip: ([value]: DropDownValue[]) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('routeTrip', value));
  },
  setDateAfter: (value: string) => {
    const [day, month, year] = value.split('.');
    const dayNumber = Number.parseInt(day, 10);
    const monthNumber = Number.parseInt(month, 10);
    const yearNumber = Number.parseInt(year, 10);
    if (
      day?.length === 2 && month?.length === 2 && year?.length === 4
      && dayNumber >= 1 && dayNumber <= 31
      && monthNumber >= 1 && monthNumber <= 12
      && yearNumber >= 1900 && yearNumber <= 2100
    ) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('dateAfter', value));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('dateAfter'));
    }
  },
  setDateBefore: (value: string) => {
    const [day, month, year] = value.split('.');
    const dayNumber = Number.parseInt(day, 10);
    const monthNumber = Number.parseInt(month, 10);
    const yearNumber = Number.parseInt(year, 10);
    if (
      day?.length === 2 && month?.length === 2 && year?.length === 4
      && dayNumber >= 1 && dayNumber <= 31
      && monthNumber >= 1 && monthNumber <= 12
      && yearNumber >= 1900 && yearNumber <= 2100
    ) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('dateBefore', value));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('dateBefore'));
    }
  },
  setTimeAfter: (value: string) => {
    const [hours, minutes] = value.split(':');
    const hoursNumber = Number.parseInt(hours, 10);
    const minutesNumber = Number.parseInt(minutes, 10);
    if (
      hours?.length === 2 && minutes?.length === 2
      && hoursNumber >= 0 && hoursNumber < 24
      && minutesNumber >= 0 && minutesNumber < 60
    ) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('timeAfter', value));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('timeAfter'));
    }
  },
  setTimeBefore: (value: string) => {
    const [hours, minutes] = value.split(':');
    const hoursNumber = Number.parseInt(hours, 10);
    const minutesNumber = Number.parseInt(minutes, 10);
    if (
      hours?.length === 2 && minutes?.length === 2
      && hoursNumber >= 0 && hoursNumber < 24
      && minutesNumber >= 0 && minutesNumber < 60
    ) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('timeBefore', value));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('timeBefore'));
    }
  },
  setWeekday: (value: boolean, day: number) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    if (value) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('weekdays', weekdays.concat(day)));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('weekdays', weekdays.filter((weekday) => weekday !== day)));
    }
  },
  setWeekdays: (value: boolean) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    if (value) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('weekdays', [2, 3, 4, 5, 6]));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('weekdays', weekdays.filter((weekday) => (
        weekday !== 2 && weekday !== 3 && weekday !== 4 && weekday !== 5 && weekday !== 6
      ))));
    }
  },
  setWeekends: (value: boolean) => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    if (value) {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('weekdays', [1, 7]));
    } else {
      dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].changeField('weekdays', weekdays.filter((weekday) => (
        weekday !== 1 && weekday !== 7
      ))));
    }
  },
  resetWeekdays: () => {
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('currentPage'));
    dispatch(forms.actions[VIDEO_PAGE_FORM_NAME].resetField('weekdays'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(VideoToolbar));
