import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ChangeEvent } from 'react';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import modals from 'src/modals';
import { isNull, isNumber, isUndefined } from 'src/helpers';

import { AC_EXPORT_SELECTION_MODAL_NAME, selectMatrixForecast, selectMatrixForecastParams } from 'src/cluster/common';
import { selectAcForecastPageForm } from 'src/cluster/balance-common';
import {
  selectTotalTraffic,
  forecastPageFormActions,
  exportForecast,
  handleSwitch,
  reloadBalanceForecast,
} from 'src/cluster/balance-forecast';

import { AC_DATA_FORECAST_MODAL_NAME } from 'src/cluster/balance-data';
import ForecastCorrespondencesToolbar from './ForecastCorrespondencesToolbar';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectAcForecastPageForm(state),
  totalTraffic: selectTotalTraffic(state),
  matrixForecastParams: selectMatrixForecastParams(state),
  matrixForecastEntities: selectMatrixForecast(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleSwitch: () => dispatch(handleSwitch()),
  setFilterModeFrom: (event: ChangeEvent<{ value: number }>) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isUndefined(event.target)) {
      dispatch(forecastPageFormActions.changeField('filterModeFrom', event.target.value));
    }
  },
  setLoadingForecast: (flag: boolean) => dispatch(forecastPageFormActions.changeField('isCalculate', flag)),
  setNeedRefetch: (flag: boolean) => dispatch(forecastPageFormActions.changeField('needRefetch', flag)),
  refetchForecast: () => dispatch(reloadBalanceForecast()),
  setFilterModeTo: (event: ChangeEvent<{ value: number }>) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isUndefined(event.target)) {
      dispatch(forecastPageFormActions.changeField('filterModeTo', event.target.value));
    }
  },
  setRegionFrom: (values: number[]) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isNull(values)) {
      dispatch(forecastPageFormActions.changeField('regionFrom', values));
    }
  },
  setRegionTo: (values: number[]) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isNull(values)) {
      dispatch(forecastPageFormActions.changeField('regionTo', values));
    }
  },
  setMunicipalityFrom: (values: number[]) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    dispatch(forecastPageFormActions.changeField('municipalityFrom', values));
  },
  setMunicipalityTo: (values: number[]) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isNull(values)) {
      dispatch(forecastPageFormActions.changeField('municipalityTo', values));
    }
  },
  setScenario: (event: ChangeEvent<{ value: number }>) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isUndefined(event.target)) {
      dispatch(forecastPageFormActions.changeField('scenario', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(forecastPageFormActions.changeField('scenario', event));
    }
  },
  setYear: (event: ChangeEvent<{ value: number }>) => {
    dispatch(forecastPageFormActions.resetFields(['scenario', 'currentPage']));
    if (!isUndefined(event.target)) {
      dispatch(forecastPageFormActions.changeField('year', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(forecastPageFormActions.changeField('year', event));
    }
  },
  setInterval: (event: ChangeEvent<{ value: number }>) => {
    dispatch(forecastPageFormActions.resetField('currentPage'));
    if (!isUndefined(event.target)) {
      dispatch(forecastPageFormActions.changeField('interval', event.target.value));
    }
    if (isNumber(event)) {
      dispatch(forecastPageFormActions.changeField('interval', event));
    }
  },
  showExportsModal: () => {
    dispatch(modals.actions.showModal(true, AC_EXPORT_SELECTION_MODAL_NAME, {
      exportData: (isFull?: boolean, isModelParams?: boolean) => dispatch(exportForecast(isFull, isModelParams)),
    }));
  },
  showForecastModal: () => {
    dispatch(modals.actions.showModal(true, AC_DATA_FORECAST_MODAL_NAME));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ForecastCorrespondencesToolbar));
