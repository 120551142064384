import { createStyles, makeStyles } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

const leftSide = 300;

export default makeStyles(({ spacing, palette }) => createStyles({
  root: {
    fontFamily: ffSourceSansPro,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 350,
    backgroundColor: palette.grey[50],
    marginBottom: spacing(2),

    '&> *:first-child': {
      width: leftSide,
    },

    '&> *:last-child': {
      width: `calc(100% - ${leftSide}px - 40px)`,
    },
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    '&> *:last-child': {
      marginBottom: spacing(0),
      marginLeft: spacing(5),
    },
  },
  textField: {
    marginBottom: spacing(3),
  },
  textLink: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '15px',
    textDecorationLine: 'underline',
    color: palette.secondary.main,
    marginBottom: spacing(2),
  },
  copying: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: spacing(2),
    marginTop: spacing(5),
    borderColor: palette.grey[500],
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 6,
    padding: spacing(3, 3, 3, 3),
  },
}));
