import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema,
  RouteScheme,
  getUrl,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';
import { Systems, SystemsContext } from 'src/constants';

import { TrafficProjectsPage } from 'src/modules/traffic/projects';
import { TrafficMapPage } from 'src/modules/traffic/map';
import { ProjectSelectorDropDown } from 'src/modules/general/projects';

interface Props extends WithTranslate {
  match?: any;
}

const PageTrafficRoot: FC<Props> = (props) => {
  const {
    match,
    t,
  } = props;

  return (
    <SystemsContext.Provider value={Systems.traffic}>
      <PageWithHeader
        basePath={['traffic']}
        menuRenderers={{
          projects: {
            label: t('modules.projects.captions.title'),
          },
          map: {
            label: t('modules.map.captions.title'),
          },
        }}
        headerChildren={(
          <Route path={getUrl(urlSchema.traffic.projects)}>
            {({ match: currentMatch }) => {
              if (currentMatch) return null;
              return <ProjectSelectorDropDown />;
            }}
          </Route>
        )}
      >
        <RouteScheme
          prevMatch={match}
          basePath={['traffic']}
          renderers={{
            projects: {
              component: TrafficProjectsPage,
            },
            map: {
              component: TrafficMapPage,
            },
          }}
        />
      </PageWithHeader>
    </SystemsContext.Provider>
  );
};

export default PageTrafficRoot;
