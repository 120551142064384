import React from 'react';

const Matrix = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.5" cy="2.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="8.5" cy="2.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="14.5" cy="2.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="2.5" cy="8.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="8.5" cy="8.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="14.5" cy="8.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="2.5" cy="14.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="8.5" cy="14.5" r="0.5" stroke="currentColor" strokeWidth="3" />
      <circle cx="14.5" cy="14.5" r="0.5" stroke="currentColor" strokeWidth="3" />
    </svg>
  );
};

export default Matrix;
