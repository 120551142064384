import React from 'react';
import clsx from 'clsx';
import { Toolbar, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Submit } from 'src/components';

import useStyles from './styles';

interface OwnProps {
  isUploading: boolean;
  name?: string;
  count: number;
  isFull: boolean;
  createProject: () => void;
}

type Props = OwnProps & WithTranslate;

const ProjectCreatorActions = (props: Props) => {
  const {
    isUploading,
    name,
    count,
    isFull,
    t,
    createProject,
  } = props;

  const classes = useStyles();

  const disabled = isUploading || count < 1 || !isFull || !name;

  return (
    <Toolbar className={clsx(classes.root, count > 0 && classes.highlight)}>
      <div>
        {count > 0 && (
          <Typography className={classes.title}>
            {t('modules.projects.captions.filesSelected', { postProcess: 'interval', count })}
          </Typography>)}
      </div>
      <Submit
        className={classes.submit}
        isLoading={isUploading}
        disabled={disabled}
        onClick={createProject}
      >
        {`+ ${t('common.captions.save')}`}
      </Submit>
    </Toolbar>
  );
};

export default ProjectCreatorActions;
