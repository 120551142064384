import { Theme, makeStyles, fade } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ spacing, palette }: Theme) => ({
  titleTable: {
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: spacing(0.5, 0, 2, 0),
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    whiteSpace: 'nowrap',
    height: '22vh',
    marginBottom: 30,
  },
  message: {
    alignSelf: 'center',
    position: 'absolute',
    fontFamily: ffSourceSansPro,
    fontSize: 14,
    fontStyle: 'italic',
    color: palette.grey[700],
  },
  first: {
    fontSize: 14,
  },
  bigSize: {
    fontSize: 14,
    textAlign: 'left',
  },
  variantRow: {
    backgroundColor: fade(palette.warning.light, 0.3),
  },
  cellClassName: {
    minWidth: 200,
    color: palette.text.primary,
    textAlign: 'right',
    '&:first-child': {
      minWidth: 300,
      borderLeft: 0,
      textAlign: 'left',
    },
    '&:nth-child(2)': {
      textAlign: 'right',
    },
    '&:last-child': {
      paddingRight: spacing(4),
    },
  },
  variantHead: {
    minWidth: 200,
    textAlign: 'right',
    padding: spacing(1.5),
    verticalAlign: 'top',
    fontSize: 14,
    backgroundColor: palette.warning.light,
    '&:first-child': {
      minWidth: 300,
      borderLeft: 0,
      textAlign: 'left',
    },
  },
  variantHeadBottom: {
    minWidth: 200,
    fontSize: 14,
    borderTop: '1px solid #DFE3E9',
    padding: spacing(0.25, 0.5),
    '&:first-child': {
      borderLeft: '1px solid #DFE3E9',
      padding: spacing(0.25, 0.5),
    },
  },
}));
