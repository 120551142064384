import React, {
  ChangeEvent, FC, useEffect, useRef, useState,
} from 'react';
import { TreeView } from '@material-ui/lab';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import { Id } from 'src/types';
import { ChevronDownIcon, ChevronRightIcon } from 'src/components';

import { VehicleType, AcRouteVariant } from 'src/cluster/common';
import { AcEditorPageForm, EditorRouteVariant } from 'src/cluster/editor-map';

import { isString } from 'src/helpers';
import useStyles from './styles';

type OwnProps = {
  variants: AcRouteVariant[];
  selectedNodeId?: number;
  selectedNodeIndex?: number;
  vehicleTypeName?: VehicleType;
  disableSelection?: boolean;
};

type InjectedProps = {
  pageForm: AcEditorPageForm;
  setSelectedNode(id: Id, index: number): void;
  setSelectedDirection(id?: number, variantId?: number): void;
  resetSelectedDirection(): void;
  onExportVariant(id: number): void;
  onSelect(): void;
};

type Props = OwnProps & InjectedProps;

const EditorRouteTreeView: FC<Props> = (props) => {
  const {
    pageForm: { editorMode },
    variants,
    selectedNodeId,
    selectedNodeIndex,
    setSelectedDirection,
    resetSelectedDirection,
    setSelectedNode,
    vehicleTypeName,
    disableSelection,
    onExportVariant,
    onSelect,
  } = props;

  const treeRef = useRef<any>(null);
  const classes = useStyles();
  const firstVariantId = variants[0]?.id;
  const firstDirectionId = variants[0]?.routeDirections[0]?.id;
  const [expanded, setExpanded] = useState([
    `variant@@${firstVariantId}`,
    `direction@@${firstDirectionId}@@${firstVariantId}`,
  ]);
  const [selected, setSelected] = useState(`direction@@${firstDirectionId}`);

  useEffect(() => {
    setSelectedDirection(firstDirectionId, firstVariantId);
  }, [firstDirectionId, firstVariantId]);

  useEffect(() => {
    if (selectedNodeId && selectedNodeIndex !== undefined) {
      const nodeIdToSelect = `node@@${selectedNodeId}@@${selectedNodeIndex}`;
      setSelected(nodeIdToSelect);
      if (treeRef.current) {
        const treeItem = treeRef.current.querySelector(
          `[data-scroll-to="${nodeIdToSelect}"]`,
        );
        if (treeItem) {
          if ('scrollIntoView' in treeItem) {
            treeItem.scrollIntoView({ behavior: 'smooth' });
          } else {
            scrollIntoViewIfNeeded(treeItem, {
              scrollMode: 'if-needed',
              behavior: 'smooth',
              block: 'end',
            });
          }
        }
      }
    }
  }, [selectedNodeId, selectedNodeIndex]);

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    onSelect();
    let nodesToExpand = nodeIds;

    // Collapse all other route directions, except toggled
    const lastToggledNodeId = nodeIds[0];
    const [nodeType] = lastToggledNodeId.split('@@');
    if (nodeType === 'direction') {
      nodesToExpand = nodesToExpand.filter((nodeId) => {
        const [currentNodeType] = nodeId.split('@@');
        return currentNodeType !== 'direction' || nodeId === lastToggledNodeId;
      });
    }

    setExpanded(nodesToExpand);
  };

  const handleSelect = (event: ChangeEvent<{}>, nodeId: string) => {
    onSelect();
    const [nodeType, id, varId] = nodeId.split('@@');
    setSelected(nodeId);
    if (!expanded.includes(nodeId)) {
      if (nodeType === 'direction' && isString(id) && isString(varId)) {
        setSelectedDirection(Number(id), Number(varId));
      } else if (nodeType === 'node' && isString(id)) {
        setSelectedNode(Number(id), Number(varId));
      } else if (nodeType === 'variant' && isString(id)) {
        const regex = new RegExp(`direction@@[0-9]+@@${id}$`, 'g');
        const directionNode = expanded.find((a) => a.search(regex) !== -1);
        if (directionNode) {
          const directionId = directionNode.split('@@')[1];
          if (isString(directionId)) {
            setSelectedDirection(Number(directionId), Number(id));
          }
        }
      }
    } else {
      let directionNode;
      if (nodeType === 'direction' && isString(varId)) {
        const regex = new RegExp(`direction@@[0-9]+@@${varId}$`, 'g');
        directionNode =
          expanded.find((a) => a !== nodeId && a.search(regex) !== -1) ||
          expanded.find((a) => a !== nodeId && a.includes('direction@@'));
      }
      if (nodeType === 'variant' && isString(id)) {
        const regex = new RegExp(`direction@@[0-9]+@@(?!${id}$)[0-9]+$`, 'g');
        directionNode = expanded.find((a) => a.search(regex) !== -1);
      }
      if (directionNode) {
        const [, curDirectionId, curVariantId] = directionNode.split('@@');
        if (isString(curDirectionId) && isString(curVariantId)) {
          setSelectedDirection(Number(curDirectionId), Number(curVariantId));
        }
      } else resetSelectedDirection();
    }
  };

  const canDeleteVariant = variants.length > 1;
  const hasNewVariant = variants.some((v) => v.id === -1);

  return (
    <TreeView
      ref={treeRef}
      classes={classes}
      disableSelection={disableSelection}
      defaultCollapseIcon={<ChevronDownIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {variants.map((variant) => {
        const otherNumbers = variants
          .filter((v) => v.id !== variant.id)
          .map((v) => v.variantNumber);
        const hasError =
          otherNumbers.includes(variant.variantNumber) ||
          variant.variantNumber === '';
        return (
          <EditorRouteVariant
            selectedNodeId={selectedNodeId}
            selectedNodeIndex={selectedNodeIndex}
            key={variant.id}
            hasError={editorMode && hasError}
            variant={variant}
            canDeleteVariant={canDeleteVariant}
            canCopyVariant={!hasNewVariant}
            vehicleTypeName={vehicleTypeName}
            exportVariant={onExportVariant}
          />
        );
      })}
    </TreeView>
  );
};

export default EditorRouteTreeView;
