import moment from 'moment';

import { StrictDict } from 'src/types';

export enum SmartDateFormats {
  simpleTime = 'simpleTime',
  simpleDate = 'simpleDate',
  simpleDateWithTime = 'simpleDateWithTime',
}

export const SMART_DATE_FORMATS = SmartDateFormats;

type DateFormatFunction = (value: moment.Moment) => React.ReactNode;

export const SMART_DATE_FORMATS_FUNCTIONS: StrictDict<SmartDateFormats, DateFormatFunction> = {
  [SmartDateFormats.simpleTime]: value => value.format('HH:mm'),
  [SmartDateFormats.simpleDate]: value => value.format('DD.MM.YYYY'),
  [SmartDateFormats.simpleDateWithTime]: value => value.format('DD.MM.YYYY HH:mm'),
};
