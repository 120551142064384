import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    marginTop: spacing(1),
  },
  button: {
    textDecoration: 'none',
    fontSize: 14,
    lineHeight: '18px',
  },
}));
