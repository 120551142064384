import app from 'src/app';
import { Position } from 'src/interfaces';
import i18n from 'src/i18n';
import { isNumber } from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';
import modals from 'src/modals';

import {
  isAcStopModel,
  AC_PROJECTS_MODEL_NAME,
  selectAcProjectListForm,
  stopEntityManager, AC_PROJECTS_ENDPOINT,
} from 'src/cluster/common';
import { selectEditorGraphForm, editorFormActions, entitiesEditorFormActions } from 'src/cluster/editor-common';
import {
  EMPTY_STOP,
  EditorMode,
  routeEntityManager,
  selectEditorPageForm,
  selectEntitiesEditorForm,
  enableViewMode,
} from 'src/cluster/editor-map';
import { api } from 'redux-restify';
import { EDGES_ENDPOINT } from 'src/api-url-schema';
import { createNode } from 'src/cluster/editor-map/store/actions/nodes';

export function loadStop(id: number): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const stopApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      query: {}, // Fix loadsManager records without hash
    };

    dispatch(stopEntityManager.loadById(id, stopApiConfig));
  };
}

export function setSelectedStop(id: number, order?: number): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(editorFormActions.resetField('edgeId'));
    dispatch(editorFormActions.resetField('nodeId'));
    dispatch(editorFormActions.resetField('nodeIndex'));
    dispatch(editorFormActions.changeField('stopId', id));
    dispatch(editorFormActions.changeField('stopOrder', order));
    dispatch(loadStop(id));
  };
}

export function makeNewStop(
  nodeId: number,
  coordinates: Position,
  vehicleTypes: number[],
  edgeId?: number,
): ThunkActionResult<void> {
  return async (dispatch) => {
    const newStop = {
      ...EMPTY_STOP,
      nodeId,
      coordinates,
      vehicleTypes,
      edgeId,
    };
    if (!isAcStopModel(newStop)) {
      return;
    }

    dispatch(editorFormActions.changeField('stopId', -1));
    dispatch(entitiesEditorFormActions.changeField('editableStop', newStop));
    dispatch(editorFormActions.changeField('editorMode', EditorMode.modifyStop));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableStop', true));
  };
}

export function getNearestPoint(edgeId: number, coordinate: Position, vehicleTypes: number[]): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const config = {
      url: `${AC_PROJECTS_ENDPOINT}${selectedProject}/${EDGES_ENDPOINT}${edgeId}/point/`,
      query: {
        lat: coordinate[0],
        lng: coordinate[1],
      },
    };

    try {
      dispatch(editorFormActions.changeField('isLoading', true));
      const { data, status } = await dispatch(api.actions.callGet(config));
      const { coordinates } = data || {};

      if (status >= 400 || !coordinates) {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.getNearestPointError')));
        return;
      }
      dispatch(makeNewStop(-1, coordinates as Position, vehicleTypes, edgeId));
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.getNearestPointError')));
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
    }
  };
}

export function changeStopName(value: string): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableStop } = selectEntitiesEditorForm(state);
    if (!editableStop) {
      return;
    }

    const updatedStop = {
      ...editableStop,
      stopName: value,
    };

    dispatch(entitiesEditorFormActions.changeField('editableStop', updatedStop));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableStop', true));
  };
}

export function changeStopNodeId(
  nodeId: number, coordinates: Position, vehicleTypes: number[],
): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableStop } = selectEntitiesEditorForm(state);
    if (!editableStop || !!editableStop.routeDirections.length) {
      return;
    }

    const updatedStop = {
      ...editableStop,
      nodeId,
      coordinates,
      vehicleTypes,
    };

    dispatch(entitiesEditorFormActions.changeField('editableStop', updatedStop));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableStop', true));
  };
}

export function createStop(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { editableStop } = selectEntitiesEditorForm(state);
    const { year, scenarioId } = selectEditorGraphForm(state);

    if (!isNumber(selectedProject) || !isAcStopModel(editableStop)) {
      return;
    }

    const values = {
      ...editableStop,
      id: undefined,
    };

    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      updateEntity: false,
    };

    try {
      dispatch(editorFormActions.changeField('isLoading', true));

      if (values.nodeId === -1) {
        values.nodeId = await dispatch(createNode(editableStop));
        if (!isNumber(values.nodeId)) {
          dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createStopError')));
          return;
        }
      }

      const { data, status } = await dispatch(stopEntityManager.create(values, apiConfig));

      if (status >= 400 || !isNumber(data?.id)) {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createStopError')));
        return;
      }

      const loadApiConfig = {
        parentEntities: {
          [AC_PROJECTS_MODEL_NAME]: selectedProject,
        },
        filter: {
          scenarioId,
          year,
        },
      };

      await dispatch(stopEntityManager.loadData(loadApiConfig));
      await dispatch(enableViewMode());
      await dispatch(editorFormActions.changeField('stopId', data.id));
      await dispatch(loadStop(data.id));
    } catch (err) {
      console.error(err);
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createStopError')));
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
    }
  };
}

export function updateStop(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { routeId } = selectEditorPageForm(state);
    const { editableStop } = selectEntitiesEditorForm(state);

    if (!isNumber(selectedProject) || !isAcStopModel(editableStop)) {
      return;
    }

    dispatch(editorFormActions.changeField('isLoading', true));

    try {
      const apiConfig = {
        parentEntities: {
          [AC_PROJECTS_MODEL_NAME]: selectedProject,
        },
        forceLoad: true,
      };

      const { data } = await dispatch(stopEntityManager.patch(editableStop.id, editableStop, apiConfig));

      if (!isAcStopModel(data)) {
        dispatch(app.actions.toast.error(i18n.t(
          'systems.agglomeration.messages.updateStopError',
          { id: editableStop.id },
        )));
        return;
      }

      await dispatch(enableViewMode());

      if (routeId) {
        await dispatch(routeEntityManager.loadById(routeId, apiConfig));
      }
    } catch (err) {
      console.error(err);
      dispatch(app.actions.toast.error(i18n.t(
        'systems.agglomeration.messages.updateStopError',
        { id: editableStop.id },
      )));
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
    }
  };
}

export function deleteStop(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { editableStop } = selectEntitiesEditorForm(state);

    if (!isNumber(selectedProject) || !isAcStopModel(editableStop)) {
      return;
    }

    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.editor.captions.deleteStopTitle'),
      text: i18n.t('modules.editor.captions.changesWillBeSaved'),
      acceptButtonText: i18n.t('common.captions.delete'),
      declineButtonText: i18n.t('common.captions.cancel'),
      onAccept: async () => {
        dispatch(editorFormActions.changeField('isLoading', true));

        try {
          const apiConfig = {
            parentEntities: {
              [AC_PROJECTS_MODEL_NAME]: selectedProject,
            },
            forceLoad: true,
          };

          const { status } = await dispatch(stopEntityManager.delete(editableStop.id, apiConfig));

          if (status >= 400) {
            dispatch(app.actions.toast.error(i18n.t(
              'systems.agglomeration.messages.deleteStopError',
              { id: editableStop.id },
            )));
            return;
          }

          dispatch(editorFormActions.resetField('stopId'));
          dispatch(enableViewMode());
        } catch (err) {
          console.error(err);
          dispatch(app.actions.toast.error(i18n.t(
            'systems.agglomeration.messages.deleteStopError',
            { id: editableStop.id },
          )));
        } finally {
          dispatch(editorFormActions.resetField('isLoading'));
        }
      },
    }));
  };
}
