import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import { selectCurrentProjectNodes } from 'src/cluster/common';
import { getVocabularyValue, useCurrentVocabulary } from 'src/i18n';

const useAcNodesOptions = (): SelectMenuOption[] => {
  const [nodes] = useSelector(selectCurrentProjectNodes);
  const [locale, vocabulary] = useCurrentVocabulary();

  return useMemo(() => nodes.map(n => ({
    id: String(n.id),
    value: n.id,
    label: getVocabularyValue(locale, vocabulary, n.name),
  })), [JSON.stringify(nodes), locale, vocabulary]);
};

export default useAcNodesOptions;
