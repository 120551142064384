import { AcEditorLegendForm } from 'src/cluster/common';

const defaults: AcEditorLegendForm = {
  isSrnLine: true,
  isSuburbanLine: false,
  isWaterLine: false,
  isMetroLine: false,
  isTramwayLine: false,
  isTrolleybusLine: false,
  isTracklessStop: false,
  isSuburbanStop: false,
  isWaterStop: false,
  isMetroStop: false,
  isTramwayStop: false,
  isBusNetwork: true,
  isTramwayNetwork: true,
  isTrolleybusNetwork: true,
  isSuburbanNetwork: true,
  isWaterNetwork: true,
  regularTransportationType: undefined,
  communicationType: undefined,
  isPaidParking: false,
  isHighlightedStripes: false,
  isBusAndTrolleybus: false,
  isTram: false,
  isTransportDistrictsAndSites: false,
  isEdgesOfTransportAreas: false,
  isDistrictCentroids: false,
  isAverageTravelSpeedCar: false,
};

export default { defaults };
