import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { Button, GearIcon } from 'src/components';

import { useEditorScenarioName, useEditorGraphParams } from 'src/cluster/editor-common';

import { useStyles, useTypographyStyles } from './styles';

interface InjectedProps {
  showModal(): void;
}

export type Props = InjectedProps & WithTranslate;

const RegistryToolbarTitle: FC<Props> = (props) => {
  const { t, showModal } = props;
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();

  const getScenarioName = useEditorScenarioName();

  const [year, scenarioId] = useEditorGraphParams();

  return (
    <div className={classes.root}>
      <Button variant="icon" onClick={showModal}>
        <GearIcon />
      </Button>
      <Typography
        component="span"
        classes={typographyClasses}
        variant="body2"
        className={clsx(!year && classes.alert)}
      >
        {year || t('components.captions.notSelected')}
      </Typography>
      <Typography
        component="span"
        variant="caption"
        classes={typographyClasses}
      >
        <span>{t('common.captions.year')}</span>
      </Typography>
      <Typography
        component="span"
        classes={typographyClasses}
        className={clsx(!scenarioId && classes.alert)}
      >
        {getScenarioName(Number(scenarioId))}
      </Typography>
      <Typography
        component="span"
        variant="caption"
        classes={typographyClasses}
      >
        {t('common.captions.scenario')}
      </Typography>
    </div>
  );
};

export default RegistryToolbarTitle;
