import { createSelector } from 'reselect';

import { api, forms, GetArrayConfig } from 'redux-restify';

import { PageProjectForm } from 'src/modules/general/projects';

import {
  NodeModel, PageRoutesForm, RouteModel, ROUTES_PAGES_FORM_NAMES,
} from 'src/modules/shipments/routes';

import {
  CALCULATIONS_PAGES_FORM_NAMES,
  CALCULATIONS_MODEL_NAMES,
  NODES_CALCULATIONS_MODEL_NAMES,
  ROUTES_CALCULATION_MODEL_NAMES,
} from 'src/modules/shipments/calculations/constants';

import { AppState } from 'src/reducer';

import { Systems } from 'src/constants';
import { StrictDict, Writable } from 'src/types';

function hasCorrectRouteParams(pageProjectForm: PageProjectForm, pageRoutesForm: PageRoutesForm) {
  return (
    typeof pageProjectForm.selectedCalculation === 'number'
    && pageRoutesForm.from
    && pageRoutesForm.to
  );
}

export const getRoutesSelectors = (type: Systems) => {
  const pageCalculationsFormName = CALCULATIONS_PAGES_FORM_NAMES[type];
  const pageRoutesFormName = ROUTES_PAGES_FORM_NAMES[type];
  const calculationsModelName = CALCULATIONS_MODEL_NAMES[type];
  const nodesModelName = NODES_CALCULATIONS_MODEL_NAMES[type];
  const routesModelName = ROUTES_CALCULATION_MODEL_NAMES[type];

  function getRoutesApiConfig(
    pageProjectForm: PageProjectForm,
    pageRoutesForm: PageRoutesForm,
  ): GetArrayConfig | undefined {
    if (!hasCorrectRouteParams(pageProjectForm, pageRoutesForm)) {
      return undefined;
    }

    const filters: StrictDict<string, string | undefined> = {
      firstNode: pageRoutesForm.from,
      secondNode: pageRoutesForm.to,
    };

    return {
      parentEntities: {
        [calculationsModelName]: Number(pageProjectForm.selectedCalculation),
      },
      filter: filters,
    };
  }

  const getCurrentTransportType = createSelector(
    (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    (pageRoutesForm) => String(pageRoutesForm.transportType),
  );

  const getCurrentCalculationNodes = createSelector(
    [
      (state: AppState) => forms.selectors[pageCalculationsFormName].getFormWithNulls<PageProjectForm>(state),
      (state: AppState) => api.selectors.entityManager[nodesModelName].getEntities<NodeModel>(state),
    ],
    (pageProjectForm, nodesEntities) => {
      if (!pageProjectForm.selectedCalculation) {
        return {
          currentNodes: [],
          currentNodesIsLoading: false,
        };
      }
      const nodesApiConfig = {
        parentEntities: {
          [calculationsModelName]: pageProjectForm.selectedCalculation,
        },
      };
      return {
        currentNodes: nodesEntities.getArray(nodesApiConfig).filter(node => node.name !== 'N/A'),
        currentNodesIsLoading: nodesEntities.getIsLoadingArray(nodesApiConfig),
      };
    },
  );

  const getRoutesSelectorsConst = [
    (state: AppState) => forms.selectors[pageCalculationsFormName].getFormWithNulls<PageProjectForm>(state),
    (state: AppState) => forms.selectors[pageRoutesFormName].getFormWithNulls<PageRoutesForm>(state),
    (state: AppState) => api.selectors.entityManager[routesModelName].getEntities<RouteModel>(state),
  ] as const;

  const getProjectRoutesSelectors: Writable<typeof getRoutesSelectorsConst> = getRoutesSelectorsConst as any;

  const getCurrentProjectRoutes = createSelector(
    getProjectRoutesSelectors,
    (pageProjectForm, pageRoutesForm, routesEntities) => {
      const routesApiConfig = getRoutesApiConfig(pageProjectForm, pageRoutesForm);
      if (!routesApiConfig) {
        return {
          currentRoutes: [],
          currentRoutesIsLoading: false,
        };
      }
      return {
        currentRoutes: routesEntities.getArray(routesApiConfig),
        currentRoutesIsLoading: routesEntities.getIsLoadingArray(routesApiConfig),
      };
    },
  );

  return {
    getCurrentTransportType,
    getCurrentCalculationNodes,
    getCurrentProjectRoutes,
  };
};
