import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  root: {
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
  fileInput: {
    display: 'none',
  },
}));
