import { StrictDict } from 'src/types';

export enum CalculationsStatuses {
  inProgress = 'IN_PROGRESS',
  success = 'SUCCESS',
  error = 'ERROR',
  waiting = 'WAITING',
}

export const CALCULATIONS_STATUSES = { ...CalculationsStatuses };

export const CALCULATIONS_STATUSES_DICT: StrictDict<CalculationsStatuses, string> = {
  [CALCULATIONS_STATUSES.inProgress]: 'common.statuses.inProgress',
  [CALCULATIONS_STATUSES.success]: 'common.statuses.success',
  [CALCULATIONS_STATUSES.error]: 'common.statuses.error',
  [CALCULATIONS_STATUSES.waiting]: 'common.statuses.waiting',
};
