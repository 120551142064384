import { ModelDefaults } from 'src/types';
import { AC_COPY_REQUEST_ENDPOINT, AcCopyRequestModel } from 'src/cluster/common';

const defaults: ModelDefaults<AcCopyRequestModel> = {
  id: undefined,
  type: undefined,
  status: undefined,
  inputData: undefined,
  ignoredMap: undefined,
  createdAt: undefined,
  finishedAt: undefined,
  outputMap: undefined,
  user: undefined,
};

export default {
  endpoint: AC_COPY_REQUEST_ENDPOINT,
  defaults,
  clearPagesOnRouteChange: false,
};
