import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import { isArray, isNumber } from 'src/helpers';

import {
  AcScenariosModel,
  isAcScenariosModel,
  selectAcProjectListForm,
  selectScenarioEntities,
  AC_PROJECTS_MODEL_NAME,
} from 'src/cluster/common';

import { selectAcBalanceMapPageForm } from './common';

export const selectBalanceMapScenariosByYear = createSelector(
  [
    selectAcProjectListForm,
    selectAcBalanceMapPageForm,
    selectScenarioEntities,
  ],
  (projectsListForm, balancePageForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = balancePageForm;

    if (!isNumber(selectedProject) || !isNumber(year)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios)) {
      return Nothing();
    }

    if (!isAcScenariosModel(scenarios[0])) {
      return Nothing();
    }

    return Just(scenarios);
  },
);
