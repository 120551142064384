import { useMemo } from 'react';

import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';
import { AcRegistryFieldsConfig, useRegistriesOptions } from 'src/cluster/editor-table';

const useRegistryConfig = (
  setRegistryFilter: (val: SelectValue) => void,
  registryId?: SelectValue,
): AcRegistryFieldsConfig => {
  const registryOptions = useRegistriesOptions();

  return useMemo(() => {
    const registryValue = registryOptions.find(opt => opt.value === registryId);

    return {
      label: 'modules.registries.captions.dataType',
      multiple: false,
      options: registryOptions,
      selected: registryValue,
      command: (val: AutocompleteOption) => {
        const { value } = val as SelectMenuOption || {};
        setRegistryFilter(value);
      },
    };
  }, [registryId]);
};

export default useRegistryConfig;
