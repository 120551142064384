import React, { FC, Fragment, useState } from 'react';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';

import { ProcessStatus } from 'src/types';
import {
  DataGridColumnProps,
  DataGrid,
  Button,
  ActionButtonGroup,
  FilterIcon,
  TrashIcon,
  ErrorIcon,
  CopyIcon,
  PenIcon,
} from 'src/components';
import { isArray } from 'src/helpers';
import SmartDate, { SMART_DATE_FORMATS } from 'src/components/etc/SmartDate';

import { ProcessStatusLabel } from 'src/cluster/common';
import {
  FILE_TYPE_DICT,
  PROJECT_STATUSES_DICT,
  AcProjectsModel,
  ProjectFile,
  isSuccessful,
  isErroneous,
} from 'src/cluster/projects';

import { ErrorGrid, ColumnFilter } from './components';

import useStyles from './styles';

type OwnProps = {
  data: AcProjectsModel[];
  selectedProject?: number;
  loadStatus?: ProcessStatus;
  changeStatusFilter(value: ProcessStatus): void;
  select(id: number): void;
  remove(project: AcProjectsModel): void;
  rename(id: number, name: string): void;
  copy(project: AcProjectsModel): void;
}

type Props = OwnProps & WithTranslate;

const ProjectsGrid: FC<Props> = (props) => {
  const {
    loadStatus,
    data = [],
    selectedProject,
    t,
    select,
    rename,
    copy,
    remove,
    changeStatusFilter,
  } = props;

  const classes = useStyles();

  const [editableId, setEditableProjectId] = useState<number>();

  const createEditHandler = (project: AcProjectsModel) => () => {
    setEditableProjectId(project.id);
  };

  const createDeleteHandler = (project: AcProjectsModel) => () => {
    remove(project);
  };

  const createCopyHandler = (project: AcProjectsModel) => () => {
    copy(project);
  };

  const handleSubmit = (name: string, project: AcProjectsModel) => {
    rename(project.id, name);
    setEditableProjectId(undefined);
  };

  const handleCancel = () => setEditableProjectId(undefined);

  const columns: DataGridColumnProps<AcProjectsModel>[] = [
    {
      title: t('common.captions.numberSymbol'),
      model: (project) => project.id,
      headClassName: classes.idColumn,
    },
    {
      title: t('modules.projects.captions.project'),
      model: (project) => project.name,
      editable: (project) => project.id === editableId,
      onSubmit: handleSubmit,
      onCancel: handleCancel,
      headClassName: classes.nameColumn,
    },
    {
      title: t('modules.projects.captions.uploadDate'),
      model: (project) => (
        <SmartDate
          format={SMART_DATE_FORMATS.simpleDateWithTime}
          date={project.created}
        />
      ),
      headClassName: classes.dateColumn,
    },
    {
      title: (
        <ColumnFilter
          title={t('modules.projects.captions.status')}
          icon={<FilterIcon />}
          value={loadStatus || ''}
          onChange={changeStatusFilter}
        />
      ),
      headClassName: classes.statusColumn,
      model: (project) => (
        <ProcessStatusLabel
          status={project.loadStatus}
          progress={project.loadProgress}
          dict={PROJECT_STATUSES_DICT}
        />
      ),
    },
    {
      title: t('modules.projects.captions.user'),
      className: classes.lastColumn,
      model: (project) => project.user,
      headClassName: classes.userColumn,
    },
  ];

  const fileColumns = [
    {
      model: (file: ProjectFile) => FILE_TYPE_DICT[file.fileType].name,
      className: classes.fileTypeColumn,
    },
    {
      model: (file: ProjectFile) => file.fileName,
      className: classes.fileNameColumn,
    },
    {
      model: (file: ProjectFile) => (file.errorMessages ? (
        <span className={classes.error}>
          <ErrorIcon />
          {' '}
          {t(PROJECT_STATUSES_DICT.ERROR)}
        </span>
      ) : null),
      className: classes.fileErrorColumn,
    },
  ];

  const rowClassName = (record: AcProjectsModel) => {
    return clsx(
      classes.row,
      record.id === selectedProject && classes.selectedRow,
      isSuccessful(record) && classes.successRow,
      isErroneous(record) && classes.errorRow,
    );
  };

  const handleRowClick = (record: AcProjectsModel) => {
    if (isSuccessful(record)) select(record.id);
  };

  return (
    <DataGrid<AcProjectsModel>
      body={data}
      columns={columns}
      expandable
      headCellClassName={classes.headCell}
      rowClassName={rowClassName}
      expand={(record) => {
        const newSourceFiles = record.sourceFiles.map(item => {
          const errorMessages = record.validationErrors.find(err => err.fileId === item.fileId)?.errorMessages;
          return { ...item, errorMessages };
        });
        return (
          <Fragment>
            {record.loadErrorMessage && (
              <div className={classes.generalErrorRow}>
                <ErrorIcon />
                <span>
                  {record.loadErrorMessage}
                </span>
              </div>
            )}
            <DataGrid<ProjectFile>
              hideHeader
              body={newSourceFiles}
              columns={fileColumns}
              rowClassName={classes.fileRow}
              size="small"
              expandable={(file) => isArray(file.errorMessages)}
              expand={(file) => {
                const { errorMessages } = file;
                if (isArray(errorMessages)) {
                  return <ErrorGrid errors={errorMessages} />;
                }
                return null;
              }}
            />
          </Fragment>
        );
      }}
      actionComponent={(project) => (
        <ActionButtonGroup>
          <Button
            variant="icon"
            title={t('common.captions.edit')}
            onClick={createEditHandler(project)}
          >
            <PenIcon />
          </Button>
          <Button
            variant="icon"
            title={t('common.captions.copy')}
            onClick={createCopyHandler(project)}
          >
            <CopyIcon />
          </Button>
          <Button
            variant="icon"
            title={t('common.captions.delete')}
            onClick={createDeleteHandler(project)}
          >
            <TrashIcon />
          </Button>
        </ActionButtonGroup>
      )}
      onRowClick={handleRowClick}
    />
  );
};

export default ProjectsGrid;
