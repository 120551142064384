import React, { FC, Fragment } from 'react';
import { Tooltip } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { isNil } from 'src/helpers';
import { UNITS } from 'src/constants';
import {
  ActionButtonGroup,
  Button,
  DataGrid,
  ExcelAltIcon,
  GeoJsonIcon,
  WithUnit,
  NumberFormat,
  TimeFormat,
} from 'src/components';
import { TtcSitesModel, useAcSiteName } from 'src/cluster/common';

import useStyles from './styles';

interface Props extends WithTranslate {
  data: TtcSitesModel[];
  exportCalculationItem: (fromId: number, toId: number, extension: 'xlsx' | 'geojson') => void;
  isExporting: boolean;
}

const TtcSitesGrid: FC<Props> = (props) => {
  const {
    locale,
    t,
    data,
    exportCalculationItem,
    isExporting,
  } = props;

  const classes = useStyles();
  const getSiteName = useAcSiteName();

  const columns = [
    {
      title: t('modules.ttcCalculations.captions.siteOfDeparture'),
      model: (result: TtcSitesModel) => getSiteName(result.siteFromId),
    },
    {
      title: t('modules.ttcCalculations.captions.siteOfDestination'),
      model: (result: TtcSitesModel) => getSiteName(result.siteToId),
    },
    {
      title:
        (
          <Fragment>
            <WithUnit unit={t('modules.ttcCalculations.captions.hhMm')}
            >
              {t('modules.ttcCalculations.captions.travelTime')}
            </WithUnit>
            <span className={classes.titleUnit}>
              {t('modules.ttcCalculations.captions.private')}
              <span className={classes.spaceWrap}>/</span>
              {t('modules.ttcCalculations.captions.public')}
            </span>
          </Fragment>
        ),
      model: (result: TtcSitesModel) => {
        if (isNil(result.travelTime)) {
          return (
            <Tooltip
              title={t('modules.ttcCalculations.captions.noRouteFound').toString()}
              arrow
            >
              <span className={classes.tooltipAnchor}>-</span>
            </Tooltip>
          );
        }
        return (
          <Fragment>
            <Tooltip title={`${t('modules.ttcCalculations.captions.private')}`}>
              <span className={classes.tooltipAnchor}>
                <TimeFormat
                  unit="m"
                  value={result.travelTime.privateTransport}
                />
              </span>
            </Tooltip>
            <span className={classes.spaceWrap}>/</span>
            <Tooltip title={`${t('modules.ttcCalculations.captions.public')}`}>
              <span className={classes.tooltipAnchor}>
                <TimeFormat
                  unit="m"
                  value={result.travelTime.publicTransport}
                />
              </span>

            </Tooltip>
          </Fragment>
        );
      },
    },
    {
      title: (
        <Fragment>
          <WithUnit unit={t(UNITS.costRub)}
          >
            {t('modules.ttcCalculations.captions.cost')}
          </WithUnit>
          <span className={classes.titleUnit}>

            {t('modules.ttcCalculations.captions.private')}
            <span className={classes.spaceWrap}>/</span>
            {t('modules.ttcCalculations.captions.public')}
          </span>
        </Fragment>
      ),
      model: (result: TtcSitesModel) => {
        return (
          <Fragment>
            <Tooltip title={`${t('modules.ttcCalculations.captions.private')}`}>
              <span>
                {isNil(result.directCost.privateTransport) ?
                  (
                    <Tooltip
                      title={t('modules.ttcCalculations.captions.noRouteFound').toString()}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  )
                  :
                  (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={result.directCost.privateTransport}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
            <span className={classes.spaceWrap}>/</span>
            <Tooltip title={`${t('modules.ttcCalculations.captions.public')}`}>
              <span>
                {isNil(result.directCost.publicTransport) ?
                  (
                    <Tooltip
                      title={t('modules.ttcCalculations.captions.noRouteFound').toString()}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  )
                  :
                  (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={result.directCost.publicTransport}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
          </Fragment>
        );
      },
    },
    {
      title:
        (
          <Fragment>
            <WithUnit unit={t(UNITS.costRub)}
            >
              {t('modules.ttc.captions.totalTransportCosts')}
            </WithUnit>
            <span className={classes.titleUnit}>

              {t('modules.ttcCalculations.captions.private')}
              <span className={classes.spaceWrap}>/</span>
              {t('modules.ttcCalculations.captions.public')}
            </span>
          </Fragment>
        ),
      model: (result: TtcSitesModel) => {
        return (
          <>
            <Tooltip title={`${t('modules.ttcCalculations.captions.private')}`}>
              <span>
                {isNil(result.ttc.privateTransport) ?
                  (
                    <Tooltip
                      title={t('modules.ttcCalculations.captions.noRouteFound').toString()}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  )
                  :
                  (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={result.ttc.privateTransport}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
            <span className={classes.spaceWrap}>/</span>
            <Tooltip title={`${t('modules.ttcCalculations.captions.public')}`}>
              <span>
                {isNil(result.ttc.publicTransport) ?
                  (
                    <Tooltip
                      title={t('modules.ttcCalculations.captions.noRouteFound').toString()}
                      arrow
                    >
                      <span className={classes.tooltipAnchor}>-</span>
                    </Tooltip>
                  )
                  :
                  (
                    <span className={classes.tooltipAnchor}>
                      <NumberFormat
                        fixedDecimalScale={false}
                        decimalScale={2}
                        value={result.ttc.publicTransport}
                        locale={locale}
                      />
                    </span>
                  )}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const exportGeojson = (result: TtcSitesModel, extension: 'xlsx' | 'geojson') => {
    exportCalculationItem(result.siteFromId, result.siteToId, extension);
  };

  return (
    <DataGrid<TtcSitesModel>
      columns={columns}
      body={data}
      actionComponent={(result) => (
        <ActionButtonGroup>
          <Button
            variant="icon"
            disabled={isExporting}
            onClick={() => exportGeojson(result, 'geojson')}
          >
            <GeoJsonIcon />
          </Button>
          <Button
            variant="icon"
            disabled={isExporting}
            onClick={() => exportGeojson(result, 'xlsx')}
          >
            <ExcelAltIcon />
          </Button>
        </ActionButtonGroup>
      )}
    />
  );
};

export default TtcSitesGrid;
