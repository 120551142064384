/* eslint-disable max-len */
import React from 'react';

const ArrowDouble = () => (
  <svg viewBox="0 0 11 9" fill="none">
    <path d="M0 1.0575L1.0815 0L5.6836 4.5L1.0815 9L0 7.9425L3.51294 4.5L0 1.0575ZM4.60211 1.0575L5.6836 0L10.2857 4.5L5.6836 9L4.60211 7.9425L8.11505 4.5L4.60211 1.0575Z" fill="currentColor"
    />
  </svg>
);

export default ArrowDouble;
