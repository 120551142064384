import { Theme, makeStyles, createStyles } from '@material-ui/core';

export const useTypographyStyles = makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    root: {
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: palette.grey[700],
      marginLeft: spacing(5),
      marginRight: spacing(1),
      fontWeight: 700,
    },
    body2: {
      marginLeft: spacing(2),
    },
    caption: {
      fontSize: 12,
      margin: 0,
      fontWeight: 400,
      color: palette.grey[400],
    },
  });
});

export const useStyles = makeStyles(({ palette, spacing }: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(2),
      paddingBottom: spacing(2),
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: palette.grey[400],
      borderStyle: 'solid',
      width: 'fit-content',

      '& $alert': {
        color: palette.secondary.main,
      },
    },
    alert: {},
  });
});
