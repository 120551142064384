import { ThunkActionResult } from 'src/reducer';
import { createFormActions, isArray, isNumber } from 'src/helpers';

import {
  AC_MATRIX_FORECAST_ENDPOINT, fetchExtreme, selectMatrixForecastParams, selectAcProjectListForm, AC_PROJECTS_ENDPOINT,
} from 'src/cluster/common';
import { DISTRIBUTION_GEOJSON_FORM_NAME, selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  DISTRIBUTION_EDGES_GEOJSON_ENDPOINT,
  DISTRIBUTION_EDGES_DAY_GEOJSON_ENDPOINT,
  DISTRIBUTION_STOPS_GEOJSON_ENDPOINT,
  DISTRIBUTION_STOPS_DAY_GEOJSON_ENDPOINT,
  DISTRIBUTION_EXTREME_ENDPOINT,
  ViewModes,
  AcDistributionGeojsonForm,
  selectDistributionMapPageForm,
} from 'src/cluster/distribution-map';

export const editorGeojsonActions = createFormActions<AcDistributionGeojsonForm>(DISTRIBUTION_GEOJSON_FORM_NAME);

function fetchEdgesExtreme(): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const {
      year, scenarioId, intervalId, day,
    } = selectDistGraphForm(state);
    const { viewMode, vehicleTypeIds, variantId } = selectDistributionMapPageForm(state);
    const [forecastId] = selectMatrixForecastParams(state);

    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || (!isNumber(intervalId) && !day)
      || (viewMode === ViewModes.byVehicles && !isArray(vehicleTypeIds))
      || (viewMode === ViewModes.byRoutes && !isNumber(variantId))
    ) {
      return Promise.reject(new TypeError('Missing required request parameters!'));
    }

    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/`
      + `${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/`
      + `${day ? DISTRIBUTION_EDGES_DAY_GEOJSON_ENDPOINT : DISTRIBUTION_EDGES_GEOJSON_ENDPOINT}`
      + `${DISTRIBUTION_EXTREME_ENDPOINT}`;

    const query = {
      year,
      scenarioId,
      intervalId,
      vehicleTypeId: viewMode === ViewModes.byVehicles ? vehicleTypeIds.filter(id => id > 0) : undefined,
      routeVariantId: viewMode === ViewModes.byRoutes ? variantId : undefined,
    };

    if (day) {
      delete query.intervalId;
    }

    return dispatch(fetchExtreme(url, query));
  };
}

function fetchStopsExtreme(): ThunkActionResult<any> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const {
      year, scenarioId, intervalId, day,
    } = selectDistGraphForm(state);
    const { viewMode, vehicleTypeIds, variantId } = selectDistributionMapPageForm(state);
    const [forecastId] = selectMatrixForecastParams(state);

    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || (!isNumber(intervalId) && !day)
      || (viewMode === ViewModes.byVehicles && !isArray(vehicleTypeIds))
      || (viewMode === ViewModes.byRoutes && !isNumber(variantId))
    ) {
      return Promise.reject(new TypeError('Missing required request parameters!'));
    }

    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/`
      + `${AC_MATRIX_FORECAST_ENDPOINT}${forecastId}/`
      + `${day ? DISTRIBUTION_STOPS_DAY_GEOJSON_ENDPOINT : DISTRIBUTION_STOPS_GEOJSON_ENDPOINT}`
      + `${DISTRIBUTION_EXTREME_ENDPOINT}`;

    const query = {
      year,
      scenarioId,
      intervalId,
      vehicleTypeId: viewMode === ViewModes.byVehicles ? vehicleTypeIds.filter(id => id > 0) : undefined,
      routeVariantId: viewMode === ViewModes.byRoutes ? variantId : undefined,
    };

    if (day) {
      delete query.intervalId;
    }

    return dispatch(fetchExtreme(url, query));
  };
}

export function loadEdgesExtreme(): ThunkActionResult<void> {
  return async (dispatch) => {
    try {
      const extreme = await dispatch(fetchEdgesExtreme());
      dispatch(editorGeojsonActions.changeField('edgesExtreme', extreme));
    } catch (err) {
      console.error(err);
      dispatch(editorGeojsonActions.resetField('edgesExtreme'));
    }
  };
}

export function loadStopsExtreme(): ThunkActionResult<void> {
  return async (dispatch) => {
    try {
      const extreme = await dispatch(fetchStopsExtreme());
      dispatch(editorGeojsonActions.changeField('stopsExtreme', extreme));
    } catch (err) {
      console.error(err);
      dispatch(editorGeojsonActions.resetField('stopsExtreme'));
    }
  };
}
