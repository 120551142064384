import React from 'react';

const MetroIcon = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.8595 15.5096L6.62334 16.2734L11.3138 11.5829C12.5104 10.3834 13.2525 8.72797 13.2525 6.89969C13.2525 3.24022 10.2857 0.273438 6.62625 0.273438C2.96678 0.273438 0 3.24022 0 6.89969C0 8.72651 0.742058 10.3863 1.93429 11.5786C2.03594 11.6802 2.16954 11.7398 2.32057 11.7398C2.61826 11.7398 2.85932 11.4987 2.85932 11.201C2.85932 11.05 2.79397 10.912 2.69377 10.8118C1.69759 9.81564 1.07606 8.42592 1.07606 6.89969C1.07606 3.83997 3.56508 1.35095 6.6248 1.35095C9.68452 1.35095 12.175 3.83997 12.175 6.89969C12.175 8.43027 11.552 9.81709 10.5471 10.822L5.8595 15.5096Z"
      fill="currentColor"
    />
    <path
      d="M7.68472 8.20674V8.8515H10.2449V8.20674H9.7468L8.05212 3.92139L6.62319 6.42347L5.19571 3.92139L3.49957 8.20674H3.00293V8.8515H5.5631V8.20674H5.17973L5.55294 7.13794L6.62319 8.90088L7.69489 7.13794L8.0681 8.20674H7.68472Z"
      fill="currentColor"
    />
  </svg>
);

export default MetroIcon;
