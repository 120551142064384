import React from 'react';

const ExportIcon = () => (
  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 13H10.6364V11.4706H0V13ZM10.6364 4.58824H7.5974V0H3.03896V4.58824H0L5.31818 9.94118L10.6364 4.58824Z"
      fill="currentColor"
    />
  </svg>
);

export default ExportIcon;
