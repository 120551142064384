import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  percent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-2),
    backgroundColor: 'rgba(245, 245, 245, 0.7)',
  },
}));
