import { createStyles, makeStyles } from '@material-ui/core';

import background from '../../../assets/images/background.png';

export default makeStyles(() => createStyles({
  root: {
    background: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    background: 'linear-gradient(151.29deg, rgba(51, 59, 70, 0.92) 2%, rgba(51, 59, 70, 0.74) 83.98%)',
  },
  error: {},
  hiddenError: {
    composes: 'error',
    opacity: '0',
  },
  logo: {
    width: 210,
    height: 145,
    position: 'absolute',
    top: -180,

    '&> svg': {
      width: '100%',
      height: '100%',
    },
  },
  form: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: 200,
    marginBottom: 10,
  },
  button: {
    width: 200,
    marginTop: 15,
  },
}));
