import { MACRO_CHOICES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: MACRO_CHOICES_ENDPOINT,
  parent: 'shipmentsProjects',
  idField: () => '',
  defaults: {
    id: undefined,
    years: [],
    macroeconomicScenarios: [],
    transportScenarios: [],
    goodsGroups: [],
  },
  convertToCamelCase: true,
  allowIdRequests: true,
  pagination: false,
};
