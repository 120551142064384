import { RestifyId } from 'redux-restify';

import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';

import { StrictDict } from 'src/types';

export const NODES_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsNodes',
  [Systems.cities]: 'citiesNodes',
  [Systems.matrix]: 'matrixNodes',
};

export const ROUTES_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsRoutes',
  [Systems.cities]: 'citiesRoutes',
  [Systems.matrix]: 'matrixRoutes',
};

export const MACRO_CHOICES_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsMacroChoice',
};

export const ROUTES_PAGES_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'pageShipmentsRoutesForm',
  [Systems.cities]: 'pageCitiesRoutesForm',
  [Systems.matrix]: 'pageMatrixRoutesForm',
};

export interface PageRoutesForm {
  selectedRoute?: number;
  expandedRoute?: number;
  lengthPercentMax: number;
  uniqPercentMax: number;
  from?: string;
  to?: string;

  lengthPercentMaxActual?: number;
  uniqPercentMaxActual?: number;
  fromActual?: string;
  toActual?: string;

  transportScenarios: string[];
  goodsGroup: string;
  macroeconomicScenario: string;
  year: number;

  transportScenariosActual?: string[];
  goodsActual?: string;
  macroeconomicScenarioActual?: string;
  yearActual: number;
  transportType?: string;
  maxBandwidth?: number;
}

type Coordinates = [number, number]

export interface NodeModel {
  id: RestifyId;
  name: string;
  lat: number;
  lng: number;
}

export interface ChoiceWithDescription {
  label: string;
  description: string;
}

export interface MacroChoicesModel {
  id?: RestifyId;
  years: number[];
  macroeconomicScenarios: ChoiceWithDescription[];
  transportScenarios: string[];
  goodsGroups: string[];
}

export interface EdgeModel {
  id?: RestifyId;
  firstNode: Coordinates;
  secondNode: Coordinates;
  capacity: string;
  costPerKm: number;
  county: string;
  distance: number;
  edgeId: number;
  name: string;
  speed: number;
  trafficLoad: number;
  tariff: number;
  tariffHour: number;
  time: number;
  timeCost: number;
  totalCost: number;
  trafficCargo: number;
  type: string;
  detailed: Coordinates[];
  traffic: number;
  trafficPass: number;
  fuelCost: number;
  timeMoney: number;
  fare: number;
  weight: number;
  ttc: number;
  bandwidth: number;
}

export interface RouteModel {
  id: RestifyId;
  correspondenceType: string;
  isRail: number;
  railPart: number;
  routeTrafficCargo: number;
  tariffHour: number;
  weight: number;
  fuelCost: number;
  traffic: EdgeModel[];
  time: number;
  distance: number;
  trafficSummary: number;
  trafficPass: number;
}

export interface SegmentType {
  type: string;
  distance: number;
  percent: number;
}

export interface TransportTypeDict {
  [key: string]: number;
}

export interface RouteAggregation {
  summary: {
    distance: number;
    tariff: number;
    time: number;
    timeCost: number;
    totalCost: number;
  };
  segments: EdgeModel[];
  types: TransportTypeDict;
  segmentTypes: SegmentType[];
  prevItem?: EdgeModel;
}

export interface RouteWithAggregation {
  from: string;
  to: string;
  weight: number;
  isRail: number;
  percentFromMinimum: number;
  routeTrafficCargo: number;
  aggregation: RouteAggregation;
}

export const SELECTED_ROUTE_COLOR = '#e5293c';
export const SEA_ROUTE_COLOR = '#0066fe';
export const AIR_ROUTE_COLOR = '#00ffe4';
export const RAIL_ROUTE_COLOR = '#c46b00';
export const AUTO_ROUTE_COLOR = '#069b1e';

export enum EdgeTypes {
  auto = 'auto',
  autoBorderCross = 'auto_border_cross',
  ferry = 'ferry',
  port = 'port',
  airport = 'airport',
  air = 'air',
  rail = 'rail',
  railBorderCross = 'rail_border_cross',
  railLoading = 'rail_loading',
  railUnLoading = 'rail_unloading',
  sea = 'sea',
}

export const EDGE_TYPES = { ...EdgeTypes };

export const EDGE_COLORS: StrictDict<EdgeTypes, string> = {
  [EDGE_TYPES.auto]: AUTO_ROUTE_COLOR,
  [EDGE_TYPES.autoBorderCross]: AUTO_ROUTE_COLOR,
  [EDGE_TYPES.ferry]: SEA_ROUTE_COLOR,
  [EDGE_TYPES.port]: SEA_ROUTE_COLOR,
  [EDGE_TYPES.airport]: AIR_ROUTE_COLOR,
  [EDGE_TYPES.air]: AIR_ROUTE_COLOR,
  [EDGE_TYPES.rail]: RAIL_ROUTE_COLOR,
  [EDGE_TYPES.railBorderCross]: RAIL_ROUTE_COLOR,
  [EDGE_TYPES.railLoading]: RAIL_ROUTE_COLOR,
  [EDGE_TYPES.railUnLoading]: RAIL_ROUTE_COLOR,
  [EDGE_TYPES.sea]: SEA_ROUTE_COLOR,
};

export const TRANSPORT_TYPE_COLORS: StrictDict<string, string> = {
  [EDGE_TYPES.sea]: '#1a66b7',
  [EDGE_TYPES.rail]: '#d52a36',
  [EDGE_TYPES.auto]: '#55a023',
  [EDGE_TYPES.air]: '#4bd4ff',
};

export const typeMessages: StrictDict<EdgeTypes, string> = {
  [EDGE_TYPES.auto]: 'systems.shipments.edgeTypes.auto',
  [EDGE_TYPES.autoBorderCross]: 'systems.shipments.edgeTypes.autoBorderCross',
  [EDGE_TYPES.ferry]: 'systems.shipments.edgeTypes.ferry',
  [EDGE_TYPES.port]: 'systems.shipments.edgeTypes.port',
  [EDGE_TYPES.airport]: 'systems.shipments.edgeTypes.airport',
  [EDGE_TYPES.air]: 'systems.shipments.edgeTypes.air',
  [EDGE_TYPES.rail]: 'systems.shipments.edgeTypes.rail',
  [EDGE_TYPES.railBorderCross]: 'systems.shipments.edgeTypes.railBorderCross',
  [EDGE_TYPES.railLoading]: 'systems.shipments.edgeTypes.railLoading',
  [EDGE_TYPES.railUnLoading]: 'systems.shipments.edgeTypes.railUnLoading',
  [EDGE_TYPES.sea]: 'systems.shipments.edgeTypes.sea',
};

export const unitsMessages = {
  capacity: 'common.units.capacity',
  capacityPerYear: 'common.units.capacityPerYear',
  items: 'common.units.items',
  length: 'common.units.length',
  speed: 'common.units.speed',
  time: 'common.units.time',
  timeMinutes: 'common.units.timeMinutes',
  cost: 'common.units.cost',
  costRub: 'common.units.costRub',
  costPerKm: 'common.units.costPerKm',
  rate: 'common.units.rate',
  unitCost: 'common.units.unitCost',
  unitTimeCost: 'common.units.unitTimeCost',
  costPerTEU: 'common.units.costPerTEU',
  costPerKmPerTEU: 'common.units.costPerKmPerTEU',
  vehicles: 'common.units.vehicles',
  people: 'common.units.people',
};
