/* eslint-disable max-len */
import React from 'react';

const MapPort = () => (
  <svg viewBox="0 0 21 25" fill="none">
    <path d="M10.3288 0C4.62402 0 0 4.62441 0 10.3288C0 18.173 10.3288 25 10.3288 25C10.3288 25 20.6575 18.173 20.6575 10.3288C20.6575 4.62441 16.0335 0 10.3288 0ZM10.3288 18.3565C5.89517 18.3565 2.30142 14.7627 2.30142 10.3288C2.30142 5.89517 5.89522 2.30103 10.3288 2.30103C14.7623 2.30103 18.3569 5.89517 18.3569 10.3288C18.3569 14.7628 14.7624 18.3565 10.3288 18.3565Z" fill="white"
    />
    <path d="M10.3288 18.3565C5.89517 18.3565 2.30142 14.7627 2.30142 10.3288C2.30142 5.89517 5.89522 2.30103 10.3288 2.30103C14.7623 2.30103 18.3569 5.89517 18.3569 10.3288C18.3569 14.7628 14.7624 18.3565 10.3288 18.3565Z" fill="white"
    />
    <path d="M10.3288 0C4.62402 0 0 4.62441 0 10.3288C0 18.173 10.3288 25 10.3288 25C10.3288 25 20.6575 18.173 20.6575 10.3288C20.6575 4.62441 16.0335 0 10.3288 0ZM10.3288 18.3565C5.89517 18.3565 2.30142 14.7627 2.30142 10.3288C2.30142 5.89517 5.89522 2.30103 10.3288 2.30103C14.7623 2.30103 18.3569 5.89517 18.3569 10.3288C18.3569 14.7628 14.7624 18.3565 10.3288 18.3565Z" fill="#0066FE"
    />
    <path d="M12.6633 12.8386C12.5372 13.0924 12.37 13.326 12.1641 13.5319C11.7812 13.9152 11.301 14.165 10.7798 14.2603V10.4704H12.2173V9.51255H10.7798V8.47168C11.3376 8.27476 11.738 7.74248 11.738 7.1167C11.738 6.32241 11.0943 5.67871 10.3004 5.67871C9.50659 5.67871 8.86284 6.32246 8.86284 7.1167C8.86284 7.74248 9.26323 8.27476 9.82153 8.47168V9.5125H8.38394V10.4704H9.82153V14.2602C9.29985 14.165 8.81973 13.9152 8.43726 13.5318C8.23135 13.326 8.06372 13.0924 7.93799 12.8385L8.86284 11.9083H6.71484C6.82754 13.6214 8.14102 15.0065 9.82153 15.2303H10.7798C12.4599 15.0065 13.7734 13.6214 13.886 11.9083H11.738L12.6633 12.8386ZM10.3005 7.59521C10.0364 7.59521 9.82158 7.38042 9.82158 7.1167C9.82158 6.8522 10.0364 6.6374 10.3005 6.6374C10.5646 6.6374 10.7798 6.85225 10.7798 7.1167C10.7798 7.38037 10.5646 7.59521 10.3005 7.59521Z" fill="#0066FE"
    />
  </svg>
);

export default MapPort;
