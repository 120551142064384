export enum VehicleClass {
  verySmall = 'verySmall',
  small = 'small',
  middle = 'middle',
  big = 'big',
  veryBig = 'veryBig',
}

export enum VehicleType {
  bus = 'bus',
  auto = 'auto',
  trolleybus = 'trolleybus',
  tramway = 'tramway',
  metro = 'metro',
  pedestrian = 'pedestrian',
  suburban = 'suburban',
  water = 'water',
  ropeway = 'ropeway',
  funicular = 'funicular',
  monorail = 'monorail',
  all = 'all',
}

export enum CopyingType {
  project = 'AGGLOMERATION_CLUSTER_PROJECT',
  network = 'AGGLOMERATION_CLUSTER_NETWORK',
}

export enum ViewModes {
  list = 'list',
  table = 'table',
  map = 'map',
}

export enum LegendType {
  isSrnLine = 'isSrnLine',
  isSuburbanLine = 'isSuburbanLine',
  isWaterLine = 'isWaterLine',
  isMetroLine = 'isMetroLine',
  isTramwayLine = 'isTramwayLine',
  isTrolleybusLine = 'isTrolleybusLine',
  isTracklessStop = 'isTracklessStop',
  isSuburbanStop = 'isSuburbanStop',
  isWaterStop = 'isWaterStop',
  isMetroStop = 'isMetroStop',
  isTramwayStop = 'isTramwayStop',
  isBusNetwork = 'isBusNetwork',
  isTramwayNetwork = 'isTramwayNetwork',
  isTrolleybusNetwork = 'isTrolleybusNetwork',
  isSuburbanNetwork = 'isSuburbanNetwork',
  isWaterNetwork = 'isWaterNetwork',
  regularTransportationType = 'regularTransportationType',
  communicationType = 'communicationType',
  isPaidParking = 'isPaidParking',
  isBusAndTrolleybus = 'isBusAndTrolleybus',
  isTram = 'isTram',
  isEdgesOfTransportAreas = 'isEdgesOfTransportAreas',
  isDistrictCentroids = 'isDistrictCentroids',
  isAverageTravelSpeedCar = 'isAverageTravelSpeedCar',
}

export enum BalanceFilterModes {
  node = 'node',
  municipality = 'municipality',
}

export type ValidationMessages = Record<string, string | number | undefined>;

export type MapStyleID = 'mono' | 'streets' | 'satellite';

export enum MapStyle {
  mono = 'mono',
  streets = 'streets',
  satellite = 'satellite',
}

export enum DistFilterModes {
  route = 'route',
  point = 'point',
}

export type TypeGuard = (record: unknown) => boolean;

export enum ExportTypes {
  full = 'full',
  filtered = 'filtered',
  modelParams = 'modelParams'
}
