import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  className?: string;
  children: ReactElement | string;
}

const ToolbarTitle: FC<Props> = ({
  className,
  children,
}) => (
  <div className={classNames(style.title, className)}>
    {children}
  </div>
);

export default ToolbarTitle;
