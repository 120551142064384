import { Model } from 'src/interfaces';

import { AcDashIndicatorResultsModel, SubindicatorItem } from 'src/cluster/dashboard-common';
import { useAcIntervalName, useAcVehicleClassLabel, useAcVehicleTypeLabel } from 'src/cluster/common';

const useSubindicatorItems = (
  entityName: string | null,
  prefix?: string,
  baseIndicator?: Model<AcDashIndicatorResultsModel>,
  forecastIndicator?: Model<AcDashIndicatorResultsModel>,
): SubindicatorItem[] => {
  const getIntervalName = useAcIntervalName();
  const getVehicleTypeLabel = useAcVehicleTypeLabel();
  const getVehicleClassLabel = useAcVehicleClassLabel();

  function getEntityLabel(subindicator: AcDashIndicatorResultsModel) {
    switch (entityName) {
      case 'interval': {
        return subindicator.intervalId ? getIntervalName(subindicator.intervalId) : '-';
      }
      case 'vehicle_type': {
        return subindicator.vehicleTypeId ? getVehicleTypeLabel(subindicator.vehicleTypeId) : '-';
      }
      case 'vehicle_class': {
        return subindicator.vehicleClassId ? getVehicleClassLabel(subindicator.vehicleClassId) : '-';
      }
      default:
        return '-';
    }
  }
  return (baseIndicator && baseIndicator.subindicators.length)
    ? baseIndicator.subindicators
      .map(
        (subindicator, index) => {
          const label = getEntityLabel(subindicator);
          return {
            name: prefix ? `${prefix} ${label}` : label,
            baseValue: subindicator.value,
            forecastValue: forecastIndicator?.subindicators?.[index]?.value,
            subindicators: [],
          };
        })
      .sort((a, b) => {
        if (entityName === 'vehicle_type') {
          return a.name.localeCompare(b.name);
        }
        return 1;
      })
    : [];
};

export default useSubindicatorItems;
