import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import { AC_DIST_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/common';

import DistGraphSettings from './DistGraphSettings';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showModal: () => dispatch(modals.actions.showModal(true, AC_DIST_GRAPH_VERSION_MODAL_NAME)),
});

export default connect(null, mapDispatchToProps)(withTranslate(DistGraphSettings));
