import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => createStyles({
  filter: {
    width: 215,
    marginBottom: spacing(3),
    marginRight: spacing(5),

    '& label': {
      fontSize: 12,
    },
  },
}));
