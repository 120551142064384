import React from 'react';

export const ROUTER_LOCATION_CHANGE_ACTION = '@@router/LOCATION_CHANGE';
export const STATE_REPLACE_ACTION = 'STATE_REPLACE';

export const EMAIL_REGEXP = /[^@]+@[^@]+\.[^@]+|^/;
export const DEFAULT_PHONE_LENGTH = 10;

export const SEARCH_DEBOUNCE = 500;
export const DISPATCH_DEBOUNCE = 100;

export const DEFAULT_SCROLLING_CONTAINER_ID = 'mainAppScroll';

export const usePopup = <T>() => {
  return React.useState<{
    content?: T;
    lat: number;
    lng: number;
  }>({
    content: undefined,
    lat: 0,
    lng: 0,
  });
};

export const toFilterValue = (value: any) => {
  // We can't use undefined in filter
  return value === undefined ? -1 : value;
};

export const DEFAULT_PERCENT_SLIDER_STEP = 1;
export const DEFAULT_NUMBER_ACCURACY = 2;

export const MAP_BOUND_PADDING = 40;
export const MARKER_SIZE = 35;

export const UNITS = {
  capacity: 'common.units.capacity',
  capacityPerYear: 'common.units.capacityPerYear',
  items: 'common.units.items',
  length: 'common.units.length',
  speed: 'common.units.speed',
  time: 'common.units.time',
  timeMinutes: 'common.units.timeMinutes',
  cost: 'common.units.cost',
  costRub: 'common.units.costRub',
  costPerKm: 'common.units.costPerKm',
  rate: 'common.units.rate',
  unitCost: 'common.units.unitCost',
  unitTimeCost: 'common.units.unitTimeCost',
  costPerTEU: 'common.units.costPerTEU',
  costPerKmPerTEU: 'common.units.costPerKmPerTEU',
  vehicles: 'common.units.vehicles',
  people: 'common.units.people',
  peoplePerHour: 'common.units.peoplePerHour',
  peoplePerDay: 'common.units.peoplePerDay',
  peoplePerSquare: 'common.units.peoplePerSquare',
  peopleOfTheStandard: 'common.units.peopleOfTheStandard',
  ofTheStandard: 'common.units.ofTheStandard',
  coefficient: 'common.units.coefficient',
};
