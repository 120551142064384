export const DEFAULT_API_NAME = 'default';

let apiHost: string;
if (process.env.REACT_APP_PROD) {
  apiHost = process.env.REACT_APP_PROD_API_HOST || '';
} else if (process.env.REACT_APP_TEST) {
  apiHost = process.env.REACT_APP_TEST_API_HOST || '';
} else {
  apiHost = `//${window.location.host}/`;
}

export const DEFAULT_API_HOST = apiHost;
export const DEFAULT_API_PREFIX = 'api/';

export const LOGIN_ENDPOINT = 'auth/login/';
export const PERMISSIONS_ENDPOINT = 'auth/permissions/';

export const DEFAULT_ALLOWED_NO_TOKEN_ENDPOINTS = [
  LOGIN_ENDPOINT,
];

export const SHIPMENTS_PROJECTS_ENDPOINT = 'cargo-routes/projects/';
export const SHIPMENTS_CALCULATIONS_ENDPOINT = 'cargo-routes/calculations/';

export const CITIES_PROJECTS_ENDPOINT = 'cities/projects/';
export const CITIES_CALCULATIONS_ENDPOINT = 'cities/calculations/';

export const MATRIX_PROJECTS_ENDPOINT = 'traffic-matrix/projects/';
export const MATRIX_MACROECONOMICS_ENDPOINT = 'traffic-matrix/grp/';
export const MATRIX_CORRECTION_ENDPOINT = 'traffic-matrix/correction/';
export const MATRIX_TRANSPORT_ENDPOINT = 'traffic-matrix/traffic/';

export const TRAFFIC_PROJECTS_ENDPOINT = 'traffic-flows/projects/';

export const AGGLOMERATION_PROJECTS_ENDPOINT = 'agglomeration/projects/';
export const AGGLOMERATION_CALCULATIONS_ENDPOINT = 'agglomeration/calculations/';
export const AGGLOMERATION_INDICATORS_ENDPOINT = 'agglomeration/indicators/';

export const NODES_ENDPOINT = 'nodes/';
export const MUNICIPALITIES_ENDPOINT = 'municipalities/';
export const ROUTES_POLL_ENDPOINT = 'routes-poll/';
export const ROUTES_ENDPOINT = 'routes/';
export const EDGES_ENDPOINT = 'edges/';
export const EDGE_ROUTES_ENDPOINT = 'edge-routes/';
export const FORECAST_ENDPOINT = 'forecast/';
export const GRAPH_ENDPOINT = 'graph/';
export const POLYGONS_ENDPOINT = 'regions/';
export const GEOJSON_ENDPOINT = 'geojson/';
export const FULL_GEOJSON_ENDPOINT = 'full-geojson/';
export const MACRO_CHOICES_ENDPOINT = 'macro_choices/';
export const CALCULATIONS_FILTERS_ENDPOINT = 'filters/';
