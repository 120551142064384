import React from 'react';

const AutomobileIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 6.93333C16 5.96604 15.3771 5.17226 14.5747 5.03564L13.3467 1.10548L13.3333 1.06695C13.0467 0.408295 12.4767 0 11.84 0H4.15666C3.52 0 2.94999 0.408295 2.66667 1.06695L1.42257 5.03588C0.619141 5.17348 0 5.96684 0 6.93333V11.9407H1V12.7111C1 13.3505 1.44666 13.8667 2 13.8667C2.54999 13.8667 3 13.3505 3 12.7111V11.9407H13V12.7111C13 13.3505 13.4467 13.8667 14 13.8667C14.55 13.8667 15 13.3505 15 12.7111V11.9407H16V6.93333ZM4.65666 1.54074H11.34C11.7833 1.54074 12.1667 1.86814 12.2967 2.35348L13.0123 5.00741H2.98515L3.70333 2.35348C3.83333 1.86814 4.21665 1.54074 4.65666 1.54074ZM3 10.0148C2.26333 10.0148 1.66667 9.32532 1.66667 8.47407C1.66667 7.62666 2.26333 6.93333 3 6.93333C3.73334 6.93333 4.33333 7.62666 4.33333 8.47407C4.33333 9.32532 3.73334 10.0148 3 10.0148ZM10.6667 9.24444C10.6667 9.66814 10.3667 10.0148 10 10.0148H6C5.63 10.0148 5.33333 9.66814 5.33333 9.24444V8.16976C5.33333 7.96948 5.42 7.78843 5.56333 7.68059L6.44999 7.02577C6.52999 6.96416 6.62333 6.93333 6.71999 6.93333H9.30332C9.40666 6.93333 9.50334 6.97184 9.59001 7.03732L10.4533 7.73453C10.5867 7.84236 10.6667 8.01956 10.6667 8.2083V9.24444ZM11.6667 8.47407C11.6667 7.62666 12.2633 6.93333 13 6.93333C13.7333 6.93333 14.3333 7.62666 14.3333 8.47407C14.3333 9.32532 13.7333 10.0148 13 10.0148C12.2633 10.0148 11.6667 9.32532 11.6667 8.47407Z"
      fill="currentColor"
    />
  </svg>
);

export default AutomobileIcon;
