import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';
import { TTC_REGIONS_ENDPOINT } from 'src/cluster/balance-ttc';

export default {
  endpoint: TTC_REGIONS_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    regionFromId: undefined,
    regionToId: undefined,
    ttc: undefined,
    directCost: undefined,
    travelTime: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  allowIdRequests: false,
};
