import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { selectGeojsonEndpoint } from 'src/modules/traffic/map';

import TrafficRoutesMap from './TrafficRoutesMap';

const mapStateToProps = (state: AppState) => ({
  geojsonUrl: selectGeojsonEndpoint(state),
});

export default connect(mapStateToProps)(withTranslate(TrafficRoutesMap));
