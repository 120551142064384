import React, { ReactElement } from 'react';
import classNames from 'classnames';

import ToolbarTitle from 'src/components/toolbar/ToolbarTitle';

import style from './index.module.css';

interface Props {
  className?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'full-width';
  title?: string | ReactElement;
}

const ToolbarBlock: React.FC<Props> = ({
  className,
  size,
  title,
  children,
}) => {
  const sizeClassName = classNames({
    [style.blockXSmall]: size === 'x-small',
    [style.blockSmall]: size === 'small',
    [style.blockMedium]: size === 'medium',
    [style.blockLarge]: size === 'large',
    [style.blockXLarge]: size === 'x-large',
    [style.blockFullWidth]: size === 'full-width',
  });
  return (
    <div className={classNames(style.block, sizeClassName, className)}>
      {title && <ToolbarTitle>{title}</ToolbarTitle>}
      {children}
    </div>
  );
};

export default ToolbarBlock;
