import { forms } from 'redux-restify';

import { registerModal, MODAL_SIZES } from 'src/modals';

import { AppState } from 'src/reducer';
import ModalExportCalculation, { ExportCalculationModalProps } from '../components/ModalAggloCalcExport';
import { ExportAgglomerationCalculationForm } from '../forms/exportAgglomerationCalculation.form';

import { EXPORT_CALCULATION_MODAL, EXPORT_TYPES } from '../constants';

const stateToProps = (state: AppState, props: ExportCalculationModalProps) => {
  const exportForm = forms.selectors.exportCalculationForm.getForm<ExportAgglomerationCalculationForm>(state);
  return {
    ...props,
    fitContent: true,
    size: exportForm.type === EXPORT_TYPES.origin || exportForm.type === undefined
      ? MODAL_SIZES.small
      : MODAL_SIZES.medium,
  };
};

export default registerModal(
  EXPORT_CALCULATION_MODAL,
  stateToProps,
)(ModalExportCalculation);
