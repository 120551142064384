import React from 'react';
import classNames from 'classnames';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import style from './index.module.css';

interface Props {
  className?: string;
  zoomIn: () => void;
  zoomOut: () => void;
}

const AgglomerationMapZoom = ({ className, zoomIn, zoomOut }: Props) => (
  <div className={classNames(style.root, className)}>
    <button {...{
      type: 'button',
      className: style.button,
      onClick: zoomIn,
    }}>
      <PIcon {...{
        type: ICONS_TYPES.redPlus,
        className: style.icon,
      }} />
    </button>
    <button {...{
      type: 'button',
      className: style.button,
      onClick: zoomOut,
    }}>
      <PIcon {...{
        type: ICONS_TYPES.redMinus,
        className: style.icon,
      }} />
    </button>
  </div>
);

export default AgglomerationMapZoom;
