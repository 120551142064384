import React from 'react';

const TransportTypePedestrian = () => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.071404 6.41587L0.391659 5.83359C0.886599 4.93105 1.62901 4.18864 2.53155 3.6937C3.04104 3.41712 3.27396 3.34433 3.50687 3.34433L4.58409 3.41712C4.90435 3.43167 5.13726 3.57724 6.06891 4.77092C6.3455 5.12029 6.66575 5.42599 7.04424 5.67346L7.71386 6.11017C8.01956 6.31397 8.1069 6.70701 7.9031 7.01271C7.6993 7.31841 7.30626 7.40575 7.00056 7.20195L6.33094 6.76524C5.86511 6.45954 5.44296 6.08106 5.09359 5.64435L4.56954 8.1336L5.58853 9.12348C5.99613 9.51652 6.28727 10.026 6.41828 10.5937L7.01512 13.0248C7.11702 13.4469 6.85499 13.8691 6.43284 13.971C6.37461 13.9855 6.30183 14.0001 6.2436 14.0001C5.89423 14.0001 5.57397 13.7672 5.48663 13.4033L4.88979 10.9722C4.81701 10.6956 4.68599 10.4482 4.48219 10.2589L3.4632 9.26905L3.3613 9.95324C3.30307 10.3026 3.20117 10.6374 3.0556 10.9577L1.83281 13.5488C1.70179 13.8254 1.41065 14.0001 1.11951 14.0001C1.00306 14.0001 0.901156 13.971 0.7847 13.9273C0.391659 13.7381 0.231531 13.2722 0.406216 12.8792L1.62901 10.288C1.71635 10.1134 1.77458 9.90956 1.80369 9.72032L2.15306 7.39119L2.40053 5.38232C2.06572 5.68802 1.77458 6.03739 1.54167 6.44498L1.22141 7.02727C1.10496 7.24562 0.872042 7.36208 0.653686 7.36208C0.551787 7.36208 0.43533 7.33296 0.347988 7.28929C0.0277327 7.12917 -0.0887237 6.73612 0.071404 6.41587Z" fill="#333B46" />
    <path d="M5.67699 1.74451C5.76311 0.953285 5.1915 0.242054 4.40027 0.155935C3.60904 0.0698163 2.89781 0.64142 2.81169 1.43265C2.72558 2.22388 3.29718 2.93511 4.08841 3.02123C4.87964 3.10735 5.59087 2.53574 5.67699 1.74451Z" fill="#333B46" />
  </svg>

);

export default TransportTypePedestrian;
