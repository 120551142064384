import { createStyles, makeStyles } from '@material-ui/core';
import { ffOxygen } from 'src/theme/typography';
import { Props } from './SelectField';

export default makeStyles(() => createStyles({
  hidden: {
    display: 'none',
  },
}));

export const menuItemStyles = () => createStyles({
  root: {
    fontSize: 14,
    '& i': {
      fontStyle: 'italic',
    },
  },
});

export const useSelectStyles = makeStyles(({ palette, spacing }) => createStyles({
  root: (props: Props) => ({
    color: palette.text.primary,
    fontFamily: ffOxygen,
    boxSizing: 'border-box',
    padding: spacing(2, 6, 2, 3),
    height: props.size === 'slim' ? 20 : 'auto',
    fontSize: props.size === 'slim' ? 10 : 14,
    lineHeight: props.size === 'slim' ? '10px' : '16px',
  }),
  icon: (props: Props) => ({
    top: props.size === 'slim' ? 'calc(50% - 15px)' : 'calc(50% - 12px)',
    color: palette.text.primary,
  }),
  iconOutlined: (props: Props) => ({
    right: props.size === 'slim' ? 0 : undefined,
  }),
}));

export const useMenuClasses = makeStyles(({ shape, palette }) => createStyles({
  paper: {
    borderRadius: shape.borderRadius,
    boxShadow: 'none',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: palette.grey[300],
  },
}));
