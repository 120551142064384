import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    width: '100%',
  },
  calcDataLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  calcDataLabelTitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: '4px',
  },
  calcDataLabelDescription: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.text.primary,
    marginBottom: '4px',
  },
  calcDataLastResult: {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '15px',
    color: 'rgba(51, 59, 70, 0.7)',
  },
  calcDataCheckbox: {
    padding: 0,
    marginRight: '5px',
  },
  calcDataButtons: {
    marginTop: '22px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  calcDataButton: {
    padding: '8px 32px',
    margin: '0 10px',
  },
  resultList: {
    padding: '12px',
    background: '#EBF2FE',
  },
  resultListItem: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: '12px',

    '&:last-child': {
      marginBottom: '0',
    },

    '&:before': {
      content: `${'""'}`,
      display: 'inline-block',
      width: '18px',
      height: '18px',
      backgroundPosition: 'center',
      marginRight: '7px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url("${"data:image/svg+xml,%3Csvg width='13' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2 6 4 4 6-8' stroke='%233EBB5A' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E"}")`,
    },
  },
}),
);
