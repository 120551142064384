import React, { FC } from 'react';

import { Model } from 'src/interfaces';
import { DataGrid, DataGridColumnProps } from 'src/components';

import { AcDashIndicatorResultsModel, CategoryIndicator } from 'src/cluster/dashboard-common';
import { IndicatorPartition } from 'src/cluster/dashboard-table';

import { useCurrentEntityName, useEntitiesForPartition, useSubindicatorItems } from '../../hooks';

import SubindicatorsDataGrid from '../SubindicatorsDataGrid';

import useStyles from './styles';

interface Props {
  level: number;
  indicator: CategoryIndicator;
  currentEntityId: number | null;
  currentEntityName: string | null;
  nextEntityNames: string[];
  baseIndicators: Model<AcDashIndicatorResultsModel>[];
  forecastIndicators: Model<AcDashIndicatorResultsModel>[];
}

const SubindicatorsPartitionBy: FC<Props> = (props) => {
  const {
    level,
    indicator,
    currentEntityId,
    currentEntityName,
    nextEntityNames,
    baseIndicators,
    forecastIndicators,
  } = props;

  const classes = useStyles(level);

  const baseIndicator = baseIndicators.find((ind) => indicator.id === ind.indicatorId);
  const forecastIndicator = forecastIndicators.find((ind) => indicator.id === ind.indicatorId);
  const [nextEntityName, ...restEntityNames] = nextEntityNames;

  const partitionBody = useEntitiesForPartition(nextEntityName, baseIndicator);
  const currentEntityLabel = useCurrentEntityName(currentEntityName, currentEntityId);
  const subindicatorsBody = useSubindicatorItems(nextEntityName, currentEntityLabel, baseIndicator, forecastIndicator);

  if (restEntityNames.length === 0) {
    return (
      <SubindicatorsDataGrid
        level={level}
        decimalScale={indicator.unit === 'passenger' ? 0 : 2}
        body={subindicatorsBody}
      />
    );
  }

  const columns: DataGridColumnProps<IndicatorPartition>[] = [
    {
      model: (item) => {
        return item.name;
      },
      className: classes.primaryColumn,
    },
    {
      className: classes.secondaryColumn,
    },
    {
      className: classes.secondaryColumn,
    },
    {
      className: classes.secondaryColumn,
    },
  ];

  return (
    <DataGrid<IndicatorPartition>
      hideHeader
      body={partitionBody}
      columns={columns}
      expandable={!!restEntityNames.length}
      expand={(record) => {
        return (
          <SubindicatorsPartitionBy
            level={level + 1}
            indicator={indicator}
            currentEntityId={record.id}
            currentEntityName={nextEntityName}
            nextEntityNames={restEntityNames}
            baseIndicators={baseIndicators}
            forecastIndicators={forecastIndicators}
          />
        );
      }}
    />
  );
};

export default SubindicatorsPartitionBy;
