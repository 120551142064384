import React from 'react';

const AddStopIcon = () => (
  <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 19.4994L13 17.3176V11.7081C14.721 11.2205 16 9.52629 16 7.49938C16 5.09283 14.206 3.13574 12 3.13574C9.794 3.13574 8 5.09283 8 7.49938C8 9.52629 9.279 11.2205 11 11.7081V17.3176L12 19.4994Z"
      fill="currentColor"
    />
    <path
      d="M16.267 12.4777L15.734 14.5809C18.325 15.362 20 16.8642 20 18.4089C20 20.4729 16.715 22.7726 12 22.7726C7.285 22.7726 4 20.4729 4 18.4089C4 16.8642 5.675 15.362 8.267 14.5798L7.734 12.4766C4.197 13.5435 2 15.8158 2 18.4089C2 22.0787 6.393 24.9544 12 24.9544C17.607 24.9544 22 22.0787 22 18.4089C22 15.8158 19.803 13.5435 16.267 12.4777Z"
      fill="currentColor"
    />
  </svg>
);

export default AddStopIcon;
