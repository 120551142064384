import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  toggle: {
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textTransform: 'inherit',
  },

  icon: {
    marginLeft: theme.spacing(2),

    '&> svg': {
      verticalAlign: 'middle',
    },

    '&$active': {
      color: theme.palette.primary.main,
    },
  },

  active: {},

  popup: {
    padding: theme.spacing(1, 1, 1, 3),
    borderTopWidth: 3,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: 150,
  },
}));
