import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { featureCollection, makeGeoJsonPoints } from 'src/helpers';

import {
  selectCurrentProjectNodes, selectPolygonsGeojsonEndpoint, useInteractiveLayers,
} from 'src/cluster/common';

const pointExtras = {
  cluster: true,
  clusterMaxZoom: 14,
  clusterRadius: 50,
};

const useEditorMapStyle = (): [object, string[]] => {
  const polygonsUrl = useSelector(selectPolygonsGeojsonEndpoint);
  const [nodes] = useSelector(selectCurrentProjectNodes);

  const sources = useMemo(() => ({
    points: makeGeoJsonPoints(nodes, pointExtras),
    polygons: {
      type: 'geojson',
      data: polygonsUrl ?? featureCollection([]),
    },
    stops: {
      type: 'geojson',
      data: featureCollection([]),
    },
    edges: {
      type: 'geojson',
      data: featureCollection([]),
    },
  }), [nodes, polygonsUrl]);

  const interactiveLayers = useInteractiveLayers();

  const layers = [
    ...interactiveLayers,
  ];

  const mapStyle = { sources, layers };
  const interactiveLayerIds = [] as string[];

  return [
    mapStyle,
    interactiveLayerIds,
  ];
};

export default useEditorMapStyle;
