import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';
import { AC_REGISTRY_ROUTES_ENDPOINT } from 'src/cluster/editor-table';

export default {
  endpoint: AC_REGISTRY_ROUTES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    vehicleTypeId: undefined,
    routeNumber: undefined,
    routeLongName: undefined,
    regularTransportationType: undefined,
    communicationType: undefined,
    season: undefined,
    vehicleClasses: undefined,
    carrier: undefined,
    variants: undefined,
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
};
