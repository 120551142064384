import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { isNumber } from 'src/helpers';

import { ViewModes, MapModes, distMapFormActions } from 'src/cluster/distribution-map';
import { loadRoute } from 'src/cluster/editor-map';

import DistributionMapSidebar from './DistributionMapSidebar';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  changeMapMode: (value: MapModes) => {
    dispatch(distMapFormActions.changeField('mapMode', value));
  },
  changeViewMode: (value: ViewModes) => {
    dispatch(distMapFormActions.changeField('viewMode', value));
  },
  changeShowEdges: (event: ChangeEvent<{ value: unknown }>, checked: boolean) => {
    dispatch(distMapFormActions.changeField('showEdges', checked));
  },
  changeShowStops: (event: ChangeEvent<{ value: unknown }>, checked: boolean) => {
    dispatch(distMapFormActions.changeField('showStops', checked));
  },
  changeVehicleTypeIds: (values: number[]) => {
    dispatch(distMapFormActions.changeField('vehicleTypeIds', values));
  },
  changeVehicleTypeId: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(distMapFormActions.resetField('variantId'));
      dispatch(distMapFormActions.resetField('routeId'));
      dispatch(distMapFormActions.changeField('vehicleTypeId', value));
    }
  },
  changeRouteId: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(distMapFormActions.resetField('variantId'));
      dispatch(distMapFormActions.changeField('routeId', value));
      dispatch(loadRoute(value));
    }
  },
  changeVariantId: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isNumber(value)) {
      dispatch(distMapFormActions.changeField('variantId', value));
    }
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(DistributionMapSidebar));
