import { CITIES_CALCULATIONS_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: CITIES_CALCULATIONS_ENDPOINT,
  defaults: {
    id: undefined,
    progress: undefined,
    status: undefined,
    correspondencesPerProcess: undefined,
    correspondenceCount: undefined,
    parameters: undefined,
    processorCount: undefined,
    startDate: undefined,
    finishDate: undefined,
    routesProject: undefined,
    file: undefined,
    isNew: undefined,
  },
  pageSize: 1,
};
