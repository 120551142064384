import {
  Theme, createStyles, makeStyles, fade,
} from '@material-ui/core';

export default makeStyles(({ palette, shape, spacing }: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: fade('#9bb1b7', 0.5),
    borderRadius: shape.borderRadius,
    padding: spacing(2),
    minWidth: '50%',
    pointerEvents: 'initial',
  },
  message: {
    paddingRight: spacing(4),
  },
  button: {
    color: palette.common.white,
  },
  hidden: {
    opacity: 0,
    width: 0,
    height: 0,
    padding: 0,
  },
}));
