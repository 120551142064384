import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslate } from 'src/i18n';

import { onShipmentProjectChange } from 'src/modules/shipments/common';

import ShipmentsRoot from './ShipmentsRoot';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onChange: () => {
    dispatch(onShipmentProjectChange());
  },
});

export default connect(null, mapDispatchToProps)(withRouter<any, any>(withTranslate(ShipmentsRoot)));
