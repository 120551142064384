import React from 'react';
import classNames from 'classnames';

import PButton from 'src/components/deprecated/PButton';
import PSlider from 'src/components/deprecated/PSlider';
import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import MultiValueInput from 'src/components/etc/MultiValueInput';

import { useCurrentVocabulary, getVocabularyValue, WithTranslate } from 'src/i18n';

import {
  Toolbar, ToolbarBlock, ToolbarControl, ToolbarControls, ToolbarTitle,
} from 'src/components/toolbar';
import { useValues, useHandlers } from 'src/modules/shipments/routes/components/ShipmentsRoutesToolbar/hooks';
import { useRestifyForm } from 'src/helpers';
import { PageRoutesForm, ROUTES_PAGES_FORM_NAMES } from 'src/modules/shipments/routes';
import { useSystemsContext } from 'src/constants';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const ShipmentsRoutesToolbar: React.FC<Props> = ({
  className,
  t,
}) => {
  const currentSystem = useSystemsContext();
  const [currentLocale, vocabulary] = useCurrentVocabulary();
  const [form, formActions] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);

  const {
    fromItems,
    toItems,
    macroeconomicScenarioItems,
    yearItems,
    transportScenarioValues,
    transportScenarioItems,
    goodsGroupsItems,
    isYearDisabled,
    isGoodsGroupsDisabled,
  } = useValues(currentSystem, form);

  const {
    handleFromChange,
    handleToChange,
    handleLengthPercentMaxChange,
    handleUniqPercentMaxChange,
    handleMacroeconomicScenarioChange,
    handleYearChange,
    handleTransportScenariosMultiValueChange,
    handleTransportScenariosChange,
    handleGoodsGroupChange,
    handleCalculateRouteClick,
  } = useHandlers(form, formActions);

  return (
    <Toolbar className={classNames(style.root, className)}>
      <ToolbarBlock size="small" title={t('common.captions.correspondence')}>
        <ToolbarControls position="top">
          <ToolbarControl size="medium">
            <PSelect {...{
              label: t('common.captions.from'),
              items: fromItems,
              values: form.from === undefined
                ? undefined
                : [form.from],
              onChange: handleFromChange,
            }} />
          </ToolbarControl>

          <ToolbarControl size="medium">
            <PSelect {...{
              label: t('common.captions.to'),
              items: toItems,
              values: form.to === undefined
                ? undefined
                : [form.to],
              onChange: handleToChange,
            }} />
          </ToolbarControl>
        </ToolbarControls>

        <ToolbarTitle>
          {t('modules.routes.captions.routesSettings')}
        </ToolbarTitle>

        <ToolbarControls position="top">
          <ToolbarControl size="medium">
            <PSlider {...{
              name: 'lengthPercentMax',
              onChange: handleLengthPercentMaxChange,
              label: t('systems.shipments.captions.lengthPercentMax'),
              value: form.lengthPercentMax,
            }} />
          </ToolbarControl>

          <ToolbarControl size="medium">
            <PSlider {...{
              label: t('systems.shipments.captions.uniqPercentMax'),
              name: 'uniqPercentMax',
              value: form.uniqPercentMax,
              onChange: handleUniqPercentMaxChange,
            }} />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>

      <ToolbarBlock size="large" title={t('systems.shipments.captions.macroeconomics')}>
        <ToolbarControls position="top">
          <ToolbarControl size="x-large">
            <PSelect {...{
              label: t('systems.shipments.captions.macroeconomicScenario'),
              items: macroeconomicScenarioItems,
              values: form.macroeconomicScenario === undefined
                ? undefined
                : [form.macroeconomicScenario],
              onChange: handleMacroeconomicScenarioChange,
            }} />
          </ToolbarControl>

          <ToolbarControl size="x-small">
            <PSelect {...{
              label: t('systems.shipments.captions.year'),
              items: yearItems,
              disabled: isYearDisabled,
              values: form.year === undefined
                ? undefined
                : [form.year],
              onChange: handleYearChange,
            }} />
          </ToolbarControl>
        </ToolbarControls>

        <ToolbarControls position="top">
          <ToolbarControl size="x-large">
            <MultiValueInput {...{
              values: form.transportScenarios === undefined
                ? []
                : transportScenarioValues,
              renderValue: (v: DropDownValue) => getVocabularyValue(currentLocale, vocabulary, String(v)),
              onChange: handleTransportScenariosMultiValueChange,
            }}>
              <PSelect {...{
                label: t('systems.shipments.captions.transportScenarios'),
                items: transportScenarioItems,
                onChange: handleTransportScenariosChange,
              }} />
            </MultiValueInput>
          </ToolbarControl>

          <ToolbarControl size="large">
            <PSelect {...{
              label: t('systems.shipments.captions.goodsGroup'),
              items: goodsGroupsItems,
              values: form.goodsGroup === undefined
                ? undefined
                : [form.goodsGroup],
              onChange: handleGoodsGroupChange,
              disabled: isGoodsGroupsDisabled,
            }} />

            <PButton {...{
              className: style.button,
              onClick: handleCalculateRouteClick,
            }}>
              {t('modules.routes.captions.calculateRoute')}
            </PButton>
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default ShipmentsRoutesToolbar;
