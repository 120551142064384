import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import {
  Step,
  Stepper,
  Typography,
  StepLabel,
  withStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Nullable, ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';

import { Button, Slider } from 'src/components';
import { AcBalanceForecastForm, MatrixForecastModel } from 'src/cluster/common';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import useStyles from './styles';

interface Props extends WithTranslate {
  pageForecastForm: AcBalanceForecastForm;
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  buildForecast(fullRemote: number, buildTTC: boolean): void;
  setFullRemote(value: number): void;
  activeOptions?: Map<string, boolean>;
  buildRPM: (t: boolean, m: boolean, p: number) => void;
  setLoadingForecast: () => void;
  setLoadingRmp: (f: boolean) => void;
  close: () => void;
}

function StepperIcon(props: {
  active: boolean;
  completed: boolean;
  icon: number;
}) {
  const { active, icon } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.stepIcon, active ? classes.stepIconActive : '')}
    >
      {icon}
    </div>
  );
}

const ColorLibConnector = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#007378',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#80B9BC',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#80B9BC',
    borderRadius: 1,
  },
})(StepConnector);

const stepperDataText = [
  'systems.matrix.captions.massParameters',
  'systems.matrix.captions.distributionByCar',
  'systems.matrix.captions.remoteEmployment',
];

const ModalDataForecast: FC<Props> = (props) => {
  const {
    t,
    matrixForecastEntities: [matrixForecast, isLoading],
    close,
    buildRPM,
    buildForecast,
    setLoadingForecast,
    setLoadingRmp,
    activeOptions,
  } = props;

  const classes = useStyles();
  const [buildTTC, setBuildTTC] = useState(true);
  const [fullRemote, setFullRemote] = useState(0);
  const [activeStep] = useState<number>(2);

  const disabled =
    isLoading || matrixForecast?.ttc.status !== ProcessStatus.SUCCESS;
  const isCalculateRPM = activeOptions && activeOptions.size > 0;

  useEffect(() => {
    if (disabled) {
      setBuildTTC(true);
    }
  }, [disabled]);

  const handleBuild = () => {
    if (isCalculateRPM) {
      setLoadingRmp(true);
      buildRPM(
        Boolean(activeOptions?.get('TTC')),
        Boolean(activeOptions?.get('Matrix')),
        fullRemote,
      );
    } else {
      setLoadingForecast();
      buildForecast(fullRemote, buildTTC);
    }
    close();
  };

  const handleChange = (event: ChangeEvent<{}>, checked: boolean) => {
    setBuildTTC(checked);
  };

  const matrixForecastDate = matrixForecast?.matrix.lastCalculationDate || '0';

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        {t('systems.matrix.captions.forecastOptions')}
      </Typography>

      <Stepper connector={<ColorLibConnector />} activeStep={activeStep}>
        {stepperDataText.map((text, position) => (
          <Step key={text}>
            <StepLabel StepIconComponent={StepperIcon}>
              <span
                className={clsx(
                  classes.stepLabel,
                  position === activeStep ? classes.stepLabelActive : '',
                )}
              >
                {t(text)}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={classes.stepContentWrapper}>
        <Typography className={classes.subTitle}>
          {t('systems.matrix.messages.buildPassengerTraffic')}
        </Typography>

        <div className={classes.fields}>
          <div className={classes.field}>
            <div className={classes.sliderWrapper}>
              <Slider
                className={classes.slider}
                name="fullRemote"
                onChange={setFullRemote}
                label={`${t('systems.balance.captions.fullRemote')}, %`}
                max={100}
                value={fullRemote}
              />
            </div>
          </div>

          {!isCalculateRPM && (
            <div className={clsx([classes.field, classes.fieldBottom])}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={buildTTC}
                    onChange={handleChange}
                  />
                }
                label={t('modules.ttcCalculations.captions.recalculateSTZ')}
              />
              <span className={classes.lastCalculate}>
                {t('systems.matrix.captions.lastCalculate')}
                {' '}
                {new Date(matrixForecastDate).toLocaleDateString('ru-RU')}
              </span>
            </div>
          )}
        </div>

        <div className={classes.buttons}>
          <Button variant="text" className={classes.button} onClick={close}>
            {t('systems.matrix.captions.cancel')}
          </Button>
          <Button variant="outlined" className={classes.button} disabled>
            {t('systems.matrix.captions.back')}
          </Button>
          <Button
            size="medium"
            className={classes.button}
            disabled={isLoading}
            onClick={handleBuild}
          >
            {t('modules.forecast.captions.buildForecast')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalDataForecast;
