import React from 'react';

const CalculationMapButton = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#E5293C"
      fill="none"
      d="M7.00039 8.61499C6.46996 8.61499 5.96125 8.41218 5.58618 8.05117C5.2111 7.69016 5.00039 7.20053 5.00039 6.68999C5.00039 6.17945 5.2111 5.68981 5.58618 5.32881C5.96125 4.9678 6.46996 4.76499 7.00039 4.76499C7.53082 4.76499 8.03953 4.9678 8.4146 5.32881C8.78968 5.68981 9.00039 6.17945 9.00039 6.68999C9.00039 6.94278 8.94866 7.1931 8.84815 7.42665C8.74764 7.66021 8.60032 7.87242 8.4146 8.05117C8.22889 8.22992 8.00841 8.37172 7.76576 8.46846C7.52311 8.5652 7.26303 8.61499 7.00039 8.61499V8.61499ZM7.00039 1.29999C5.51518 1.29999 4.0908 1.86786 3.04059 2.87868C1.99039 3.8895 1.40039 5.26047 1.40039 6.68999C1.40039 10.7325 7.00039 16.7 7.00039 16.7C7.00039 16.7 12.6004 10.7325 12.6004 6.68999C12.6004 5.26047 12.0104 3.8895 10.9602 2.87868C9.90998 1.86786 8.4856 1.29999 7.00039 1.29999V1.29999Z"
    />
  </svg>
);

export default CalculationMapButton;
