import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ProcessStatus } from 'src/types';
import app from 'src/app';
import modals from 'src/modals';
import { ProjectModel } from 'src/modules/general/projects';

import ProjectsTable from './ProjectsTable';
import { OwnProps, ToastType } from './interfaces';

const mapDispatchToProps = (dispatch: Dispatch<any>, {
  form, t, resetSelectedProject, deleteProject, onSuccess,
}: OwnProps) => ({
  showRemoveConfirmation: (project: ProjectModel) => dispatch(modals.actions.showConfirmModal({
    text: t('modules.projects.messages.removeConfirmation', { name: project.name }),
    onAccept: () => {
      if (form.selectedProject === project.id) {
        resetSelectedProject();
      }
      deleteProject(project);
    },
  })),
  onStatusChange: (type: ToastType, message: string, project: ProjectModel) => {
    dispatch(app.actions.toast[type](message));
    if (project.loadStatus === ProcessStatus.SUCCESS && onSuccess) {
      onSuccess(project);
    }
  },
});

export default connect(null, mapDispatchToProps)(ProjectsTable);
