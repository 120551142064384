import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AggloRoutesMap, PAGE_ROUTES_FORM_NAME } from 'src/modules/mth/routes';
import { useRestifyForm } from 'src/helpers';
import { PageRoutesForm } from 'src/modules/mth/common';
import {
  selectCurrentProjectNodes,
  selectCurrentProjectRoutes,
} from '../../selectors';

import style from './index.module.css';
import AggloRoutesToolbar from '../AggloRoutesToolbar';

interface Props {
  className?: string;
  resetActualParams(): void;
}

const AggloRoutesPage: React.FC<Props> = (props: Props) => {
  const {
    className,
    resetActualParams,
  } = props;

  const [isSourceLoading, setIsSourceLoading] = useState<boolean>(true);
  const [form, formActions] = useRestifyForm<PageRoutesForm>(PAGE_ROUTES_FORM_NAME);
  const [nodes, nodesIsLoading] = useSelector(selectCurrentProjectNodes);
  const [routes, overshot, routesIsLoading, hasErrors] = useSelector(selectCurrentProjectRoutes);
  const isLoading = nodesIsLoading || routesIsLoading || form.isBuilding;

  useEffect(() => {
    if (hasErrors) {
      resetActualParams();
    }
  }, [hasErrors]);

  const cancelSourceLoading = () => {
    setIsSourceLoading(false);
  };

  return (
    <div className={classNames(style.root, className)}>
      <AggloRoutesToolbar {...{
        isLoading,
        isSourceLoading,
        form,
        formActions,
        cancelSourceLoading,
      }} />
      <AggloRoutesMap {...{
        nodes,
        routes,
        overshot,
        isLoading,
        onSourceData: setIsSourceLoading,
      }} />
    </div>
  );
};

export default AggloRoutesPage;
