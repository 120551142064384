import { useCallback } from 'react';
import { RestifyFormActions } from 'redux-restify';
import { useDispatch } from 'react-redux';
import { PageCalculationsRoutesForm } from 'src/modules/mth/common/interfaces';

interface Handlers {
  makeHandleClick: (type: string) => () => void;
}

export const useHandlers = (
  currentSystem: string,
  form: PageCalculationsRoutesForm,
  formActions: RestifyFormActions,
): Handlers => {
  const dispatch = useDispatch();

  const makeHandleClick = useCallback(
    (type) => () => {
      const actualTypes = form.showTransportTypes ? {
        ...form.showTransportTypes,
        [type]: !form.showTransportTypes[type],
      } : { [type]: true };
      dispatch(formActions.changeField('showTransportTypes', actualTypes));
    },
    [dispatch, formActions],
  );

  return {
    makeHandleClick,
  };
};
