import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';
import { withTranslate } from 'src/i18n';

import { AcCopyRequestModel, selectCopyingProjectInProgress } from 'src/cluster/common';
import {
  FileRecord,
  selectAcProjectCreateForm,
  projectCreateFormActions,
  projectLoadsManager,
  fileUploadsManager,
  startCreatingProject, reloadCopyRequest,
} from 'src/cluster/projects';

import ProjectsCreateToolbar from './ProjectCreator';

const mapStateToProps = (state: AppState) => ({
  form: selectAcProjectCreateForm(state),
  copyRequests: selectCopyingProjectInProgress(state),
  isUploading: projectLoadsManager.selectIsUploadingUrl(state) || fileUploadsManager.selectIsUploadingUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInit: () => {
    dispatch(projectCreateFormActions.resetField('files'));
  },
  setName: (name: string) => {
    dispatch(projectCreateFormActions.changeField('name', name));
  },
  setFiles: (value: FileRecord[]) => {
    dispatch(projectCreateFormActions.changeField('files', value));
  },
  startCreatingProject: () => {
    dispatch(startCreatingProject());
  },
  reloadCopyRequest: (request: AcCopyRequestModel) => {
    dispatch(reloadCopyRequest(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ProjectsCreateToolbar));
