import React, { FC, Fragment } from 'react';
import { toast } from 'react-toastify';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import style from './index.module.css';

const toastDefaultProps = {
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
};

const CustomError: FC<{ text: string }> = ({ text }) => (
  <Fragment>
    <PIcon {...{
      type: ICONS_TYPES.exclamationMark,
      className: style.icon,
      size: 22,
    }} />
    {text}
  </Fragment>
);

const CustomSuccess: React.FC<{ text: string }> = ({ text }) => (
  <Fragment>
    <PIcon {...{
      type: ICONS_TYPES.ok,
      className: style.icon,
      size: 22,
    }} />
    {text}
  </Fragment>
);

const info = (text: string) => () => {
  toast.success(<CustomError text={text} />, {
    ...toastDefaultProps,
    bodyClassName: style.body,
    className: style.info,
  });
};

const error = (text: string, autoClose?: number | false) => () => {
  toast.error(<CustomError text={text} />, {
    ...toastDefaultProps,
    autoClose,
    bodyClassName: style.body,
    className: style.error,
  });
};

const warning = (text: string, autoClose?: number | false) => () => {
  toast.warn(<CustomError text={text} />, {
    ...toastDefaultProps,
    autoClose,
    bodyClassName: style.body,
    className: style.warning,
  });
};

const success = (text: string) => () => {
  toast.success(<CustomSuccess text={text} />, {
    ...toastDefaultProps,
    bodyClassName: style.body,
    className: style.success,
  });
};

export default {
  info, error, warning, success,
};
