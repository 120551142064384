import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { api, forms, RestifyId } from 'redux-restify';

import { CALCULATIONS_MODEL_NAME, CALCULATIONS_PAGES_FORM_NAME } from 'src/modules/mth/calculations';

import { exportCalculation, showCalculationMap } from 'src/modules/mth/calculations/actions';
import AggloCalcTable from './AggloCalcTable';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  patchCalculationActuality: async (id: RestifyId, value: boolean) => {
    await dispatch(forms.actions.sendQuickForm({
      model: CALCULATIONS_MODEL_NAME,
      id,
      values: {
        isActual: value,
      },
    }));
    await dispatch(api.actions.entityManager[CALCULATIONS_MODEL_NAME].loadById(id, { forceLoad: true }));
  },
  showCalculationsMap: (item: any) => {
    dispatch(forms.actions[CALCULATIONS_PAGES_FORM_NAME].changeField('selectedCalculation', item.id));
    dispatch(showCalculationMap(item.id));
  },
  exportCalculation: (item: any) => {
    if (item.isNew) {
      dispatch(forms.actions.sendQuickForm({
        model: CALCULATIONS_MODEL_NAME,
        id: item.id,
        values: {
          isNew: false,
        },
      }));
    }
    dispatch(exportCalculation(item.id));
  },
});

export default connect(null, mapDispatchToProps)(AggloCalcTable);
