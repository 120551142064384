import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import { TtcCalcResultsList, TtcCalcToolbar, useExportStatusUpdate } from 'src/cluster/balance-ttc';

import useStyles from './styles';

interface Props extends WithTranslate {
  getExportStatus(): void;
}

const TtcCalcPage: FC<Props> = (props) => {
  const { getExportStatus } = props;

  const classes = useStyles();

  useExportStatusUpdate(getExportStatus);

  return (
    <div className={classes.root}>
      <TtcCalcToolbar />
      <TtcCalcResultsList />
    </div>
  );
};

export default TtcCalcPage;
