import React, { FC } from 'react';
import clsx from 'clsx';
import { useAutocomplete } from '@material-ui/lab';

import { AutocompleteOption, SelectMenuOption } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import { isArray, isNil, sortOptionsByDefault } from 'src/helpers';

import {
  Button, Chip, CrossIcon, SelectIndicatorIcon,
} from 'src/components';

import useStyles from './styles';

function getValue<T>(value: T | T[]): T[] {
  return isArray(value) ? value : [value];
}

interface OwnProps {
  label?: string;
  multiple?: boolean;
  disabled?: boolean;
  limitTags?: number;
  placeholder?: string;
  className?: string;
  options: SelectMenuOption[];
  fixedOptions?: SelectMenuOption[];
  selected?: SelectMenuOption[] | SelectMenuOption;
  hideClear?: boolean;
  sort?: ((options: SelectMenuOption[]) => SelectMenuOption[]) | false;
  onChange: (value: AutocompleteOption) => void;
}

export type Props = OwnProps & WithTranslate;

const Autocomplete: FC<Props> = (props) => {
  const {
    className,
    label,
    multiple = true,
    options,
    fixedOptions = [],
    selected = multiple ? [] : null,
    disabled = false,
    t,
    sort = sortOptionsByDefault,
    placeholder,
    hideClear,
    onChange,
  } = props;

  const classes = useStyles();

  const sortedOptions = sort ? sort(options) : options;

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getClearProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    value,
    setAnchorEl,
  } = useAutocomplete<SelectMenuOption, boolean, false, false>({
    autoComplete: true,
    value: selected,
    multiple,
    options: sortedOptions,
    onChange: (e, val) => {
      const newValue = isArray(val) && multiple
        ? [...fixedOptions, ...val.filter(option => !fixedOptions.find(fixed => fixed.id === option.id))]
        : val;
      return onChange(newValue);
    },
    getOptionLabel: (option) => option.label as string,
    getOptionSelected: (option, val) => option.id === val.id,
    getOptionDisabled: (option) => !!fixedOptions.find(fixed => fixed.id === option.id),
  });

  const values = isNil(value) ? [] : getValue(value);

  const actualPlaceholder = sortedOptions.length ? placeholder : t('components.captions.noOptions');

  return (
    <div className={clsx(classes.root, className)}>
      <div {...getRootProps()}>
        <label className={classes.label} {...getInputLabelProps()}>
          {label}
          {!hideClear && values?.length > 0 && (
            <Button className={classes.clearButton} variant="link" {...getClearProps()}>
              {t('components.captions.clear')}
            </Button>
          )}
        </label>
        <div ref={setAnchorEl} className={clsx(classes.inputWrapper, focused && 'focused')}>
          <input {...getInputProps()} disabled={disabled} placeholder={actualPlaceholder} />
          <Button variant="icon" className={classes.selectIndicator} disabled={disabled}>
            <SelectIndicatorIcon />
          </Button>
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listBox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li className={classes.option} {...getOptionProps({ option, index })}>
              <span>{option.label}</span>
              {multiple && <CrossIcon />}
            </li>
          ))}
        </ul>
      ) : null}
      {multiple && (
        <div className={classes.chipWrapper}>
          {values?.map((option, index: number) => (
            <Chip
              key={index}
              item={option}
              {...getTagProps({ index })}
              disabled={!!fixedOptions.find(fixed => fixed.id === option.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
