import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { isString } from 'src/helpers';

import { AppState } from 'src/reducer';
import { dashTablePageFormActions, selectDashTablePageForm, showCustomizeModal } from 'src/cluster/dashboard-table';

import DashTableToolbar from './DashTableToolbar';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectDashTablePageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setSearchKey: (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isString(value)) {
      dispatch(dashTablePageFormActions.changeField('search', value));
    }
  },
  showCustomizeModal: () => dispatch(showCustomizeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DashTableToolbar));
