import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';

import { useSystemsContext } from 'src/constants';
import {
  TRANSPORT_MODEL_NAMES,
  TRANSPORT_FORM_NAMES,
  TransportModel,
  PageTransportForm,
} from 'src/modules/matrix/transport';
import { useRestifyForm, useRestifyModel } from 'src/helpers';
import {
  PageProjectForm, ProjectModel, PROJECTS_MODEL_NAMES, PROJECTS_PAGES_FORM_NAMES,
} from 'src/modules/general/projects';
import actions from 'src/modules/matrix/transport/actions';
import style from './index.module.css';

import TransportToolbar from '../TransportToolbar';
import TransportTable from '../TransportTable';

interface Props {
  className?: string;
}

const TransportPage: React.FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const transportModelName = TRANSPORT_MODEL_NAMES[currentSystem];
  const [
    pageTransportForm,
    pageTransportFormActions,
  ] = useRestifyForm<PageTransportForm>(TRANSPORT_FORM_NAMES[currentSystem]);
  const [projectsEntities] = useRestifyModel<ProjectModel>(PROJECTS_MODEL_NAMES[currentSystem]);
  const [pageProjectForm] = useRestifyForm<PageProjectForm>(PROJECTS_PAGES_FORM_NAMES[currentSystem]);
  const currentProject = projectsEntities.getById(pageProjectForm.selectedProject);
  const [transportEntities] = useRestifyModel<TransportModel>(transportModelName);

  const transportApiConfig = useMemo(() => ({
    filter: {
      project: currentProject.id,
    },
  }), [pageProjectForm]);

  const [transports, transportIsLoading] = useMemo(() => (
    currentProject.id
      ? [transportEntities.getArray(transportApiConfig), transportEntities.getIsLoadingArray(transportApiConfig)]
      : [[], false]
  ), [transportEntities]);

  const data = useMemo(() => {
    return transports.filter((item: TransportModel) => {
      if (!item.year) {
        return false;
      }
      return !(
        (
          pageTransportForm.correspondences.length > 0
          && !pageTransportForm.correspondences.includes(`${item.fromCity} - ${item.toCity}`)
        )
        || pageTransportForm.types.length > 0 && !pageTransportForm.types.includes(item.type)
        || (pageTransportForm.yearFrom && item.year < pageTransportForm.yearFrom)
        || (pageTransportForm.yearTo && item.year > pageTransportForm.yearTo)
        || (pageTransportForm.hsw !== undefined && (pageTransportForm.hsw !== item.hsw))
      );
    });
  }, [transportEntities, pageTransportForm]);

  const count = data.length;

  const { currentPage, pageSize } = pageTransportForm;
  const page = useMemo(() => (
    data.slice((currentPage - 1) * pageSize, currentPage * pageSize)
  ), [data, currentPage, pageSize]);

  const handleChange = useCallback(async (id: number, entity: TransportModel) => {
    dispatch(actions[currentSystem].editTransportField(id, entity));
  }, [dispatch, currentSystem]);

  return (
    <div className={classNames(style.root, className)}>
      <TransportToolbar
        data={transports}
        formActions={pageTransportFormActions}
        form={pageTransportForm}
      />
      <TransportTable
        data={page}
        count={count}
        formActions={pageTransportFormActions}
        form={pageTransportForm}
        isLoading={transportIsLoading}
        onChange={handleChange}
      />
    </div>
  );
};

export default TransportPage;
