import React from 'react';

const WarningIcon = () => (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9691 18.2519C21.9691 18.8721 21.6636 19.4922 21.2053 19.8798C20.6705 20.2674 20.0594 20.5 19.2955 20.5H15.5525H6.53859H2.71914C1.95525 20.5 1.34414 20.345 0.809421 19.8798C0.351088 19.4922 0.0455322 18.8721 0.0455322 18.2519V18.1744C0.0455322 18.0194 0.0455322 17.8643 0.121921 17.7868C0.19831 17.3992 0.351088 17.0116 0.580254 16.624L8.83025 2.05039C9.36498 1.12016 10.1289 0.5 11.0455 0.5C11.9622 0.5 12.7261 1.12016 13.2608 2.05039L21.5872 16.624C21.8164 17.0116 21.9691 17.3992 22.0455 17.7868C21.9691 17.8643 21.9691 18.0194 21.9691 18.2519C21.9691 18.1744 21.9691 18.1744 21.9691 18.2519C21.9691 18.1744 21.9691 18.1744 21.9691 18.2519ZM9.8997 7.32171L10.358 12.6705C10.358 12.9031 10.4344 13.3682 10.8928 13.3682H11.0455C11.5039 13.3682 11.5803 12.9031 11.6566 12.6705L12.115 7.32171C12.1914 6.93411 12.115 6.62403 11.9622 6.39147C11.8094 6.23643 11.5039 6.0814 11.1983 6.0814H10.8164C10.5108 6.0814 10.2053 6.15891 10.0525 6.39147C9.8997 6.62403 9.82331 6.93411 9.8997 7.32171ZM12.1914 16.2364C12.1914 15.5388 11.8094 15.1512 11.1219 15.1512H10.8928C10.2053 15.1512 9.82331 15.5388 9.82331 16.2364V16.469C9.82331 17.1667 10.2053 17.5543 10.8928 17.5543H11.1219C11.8094 17.5543 12.1914 17.1667 12.1914 16.469V16.2364Z"
      fill="currentColor"
    />
  </svg>
);

export default WarningIcon;
