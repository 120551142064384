import { useTranslation } from 'react-i18next';
import {
  VEHICLE_CLASSES_DICT,
  VehicleClass,
  useAcVehicleClassName,
} from 'src/cluster/common';

const useAcVehicleClassLabel = (): (vehicleId?: number) => string => {
  const { t } = useTranslation();
  const getVehicleClassName = useAcVehicleClassName();
  return (vehicleId) => {
    const name = getVehicleClassName(vehicleId);
    return name && name in VehicleClass ? t(VEHICLE_CLASSES_DICT[name]) : t('common.vehicleClasses.unknown');
  };
};

export default useAcVehicleClassLabel;
