import { downloadFile } from './file-download';

const bomStr = '\uFEFF';

interface ConvertToCSVProps {
  data: Record<string, string | number>[];
  columnTitles?: Record<string, string | undefined>;
  columnDelimiter?: string;
  lineDelimiter?: string;
}

export const convertArrayOfObjectsToCSV = ({
  data = [],
  columnTitles = {},
  columnDelimiter = ';',
  lineDelimiter = '\n',
}: ConvertToCSVProps) => {
  const keys = Object.keys(data[0] || {});

  let result = '';
  result += keys
    .map(k => columnTitles[k] || k)
    .join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      result += typeof item[key] === 'number' ? item[key].toString().replace('.', ',') : item[key] || 0;
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return bomStr + result;
};

interface ExportToCSVProps extends ConvertToCSVProps {
  fileName: string;
}

export const exportDataToCsv = ({
  data,
  fileName,
  columnTitles,
}: ExportToCSVProps) => {
  const csvString = convertArrayOfObjectsToCSV({
    columnTitles,
    data,
  });
  const blob = new Blob([csvString], {
    type: 'text/csv;charset=utf8;',
  });

  downloadFile(blob, fileName);
};
