/* eslint-disable max-len */
import React from 'react';

const AirPort = () => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3288 18.3565C5.89517 18.3565 2.30142 14.7627 2.30142 10.3288C2.30142 5.89517 5.89522 2.30103 10.3288 2.30103C14.7623 2.30103 18.3569 5.89517 18.3569 10.3288C18.3569 14.7628 14.7624 18.3565 10.3288 18.3565Z" fill="white" />
    <path d="M9.08931 0C4.06914 0 0 4.06948 0 9.08931C0 15.9923 9.08931 22 9.08931 22C9.08931 22 18.1786 15.9923 18.1786 9.08931C18.1786 4.06948 14.1095 0 9.08931 0ZM9.08931 16.1537C5.18775 16.1537 2.02525 12.9912 2.02525 9.08931C2.02525 5.18775 5.18779 2.0249 9.08931 2.0249C12.9908 2.0249 16.1541 5.18775 16.1541 9.08931C16.1541 12.9913 12.9909 16.1537 9.08931 16.1537Z" fill="#15C1F7" />
    <path d="M9.43829 12.0738C9.45151 12.0771 9.46387 12.079 9.47757 12.0829L10.3787 12.4946C10.4693 12.5196 10.56 12.4521 10.561 12.3582C10.5674 11.8659 10.5865 11.8258 10.5023 11.7697L9.81332 11.3128C9.76193 11.2791 9.57963 11.1963 9.59575 11.137C9.7212 10.6555 9.86375 9.43962 9.90253 9.09502C9.90508 9.07296 9.91769 9.05332 9.9366 9.04193C9.9555 9.03041 9.97878 9.02859 9.99938 9.03659L12.9293 10.1835C12.9514 10.192 12.9762 10.1893 12.9956 10.176C13.015 10.1627 13.0269 10.1406 13.0269 10.1171V9.65381C13.0269 9.45793 12.9291 9.2749 12.7665 9.16581L10.0776 7.36327C10.0373 7.33587 10.0134 7.29018 10.0142 7.24205V5.90606C10.0191 5.5206 9.74193 4.5057 9.31466 4.5C9.30581 4.5 9.2846 4.5 9.26338 4.5C9.24205 4.5 9.22072 4.5 9.21187 4.5C8.78472 4.5057 8.50775 5.5206 8.5126 5.90606V7.24205C8.51345 7.29018 8.48933 7.33587 8.44921 7.36327L5.76036 9.16593C5.59758 9.27502 5.5 9.45805 5.5 9.65393V10.1172C5.5 10.1407 5.51176 10.1628 5.53115 10.1761C5.55067 10.1893 5.57539 10.1921 5.59733 10.1836L8.52727 9.03672C8.54787 9.02872 8.57102 9.03053 8.59005 9.04205C8.60908 9.05344 8.62169 9.07308 8.62411 9.09514C8.66302 9.43975 8.80557 10.6556 8.93102 11.1371C8.94714 11.1965 8.76484 11.2793 8.71345 11.313L8.02448 11.7698C7.94024 11.826 7.95939 11.8662 7.96593 12.3583C7.96678 12.4522 8.05745 12.5197 8.14811 12.4948L9.04908 12.083C9.0629 12.0791 9.07526 12.0772 9.08848 12.0739L9.43829 12.0738Z" fill="#15C1F7" />
  </svg>
);

export default AirPort;
