import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withTranslate } from 'src/i18n';

import AggloMapFilterEdgeTable from './AggloMapFilterEdgeTable';
import { showEdgeRoutes } from '../../../../actions';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showEdgeRoutes: (edgeId: number, type: string) => dispatch(showEdgeRoutes(edgeId, type)),
});

export default connect(null, mapDispatchToProps)(withTranslate(AggloMapFilterEdgeTable));
