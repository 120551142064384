import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      cursor: 'pointer',
      transition: 'color 0.5s ease-out',
      color: theme.palette.secondary.light,
      border: 0,
      outline: 0,
      backgroundColor: 'transparent',

      '&:not(:last-of-type)': {
        marginRight: theme.spacing(3),
      },

      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
      },
    },
  },
}));

type Props = {
  className?: string;
}

const ActionButtonGroup: FC<Props> = (props) => {
  const { className, children } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  );
};

export default ActionButtonGroup;
