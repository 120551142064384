import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { loadMatrixForecast, selectMatrixForecast } from 'src/cluster/common';

import modals from 'src/modals';

import { reloadTtcData } from 'src/cluster/balance-ttc';
import { forecastPageFormActions } from 'src/cluster/balance-forecast';
import TtcCalcProgress from './TtcCalcProgress';

const mapStateToProps = (state: AppState) => ({
  matrixForecastEntities: selectMatrixForecast(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadMatrixForecast: () => dispatch(loadMatrixForecast()),
  calculateTTCDone: () => {
    dispatch(forecastPageFormActions.changeField('needRefetch', true));
    dispatch(reloadTtcData());
    dispatch(
      modals.actions.showMessageBoxModal({
        title: 'Расчет завершен',
        text: 'Совокупные транспортные затраты были успешно рассчитаны.',
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslate(TtcCalcProgress));
