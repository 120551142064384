export enum AcTtcCalcPageFormEnum {
  year = 'year',
  scenarioId = 'scenarioId',
  intervalId = 'intervalId',
  siteFromId = 'siteFromId',
  siteToId = 'siteToId',
  regionFromId = 'regionFromId',
  regionToId = 'regionToId',
  isSites = 'isSites',
}
