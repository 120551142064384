import {
  selectCommunicationTypes,
  selectTransportationTypes,
  useGenerateItems,
  useGenerateItemsByName,
} from 'src/cluster/common';
import {
  selectRegSeasons,
  selectRegCarriers,
  selectRegVehicleClasses,
} from 'src/cluster/editor-table';

export const useTransportTypesOptions = () => useGenerateItems(selectTransportationTypes);
export const useCommunicationTypesOptions = () => useGenerateItems(selectCommunicationTypes);
export const useSeasonOptions = () => useGenerateItems(selectRegSeasons);
export const useCarrierOptions = () => useGenerateItemsByName(selectRegCarriers);
export const useVehicleClassesOptions = () => useGenerateItemsByName(selectRegVehicleClasses);
