import React, { FC } from 'react';
import { Popup } from 'react-map-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { WithTranslate } from 'src/i18n';

import { AcDistGraphForm } from 'src/cluster/common';
import { AcDistributionMapPageForm, MapModes, ViewModes } from 'src/cluster/distribution-map';

import DistributionMapPopupStop from '../DistributionMapPopupStop';
import DistributionMapPopupEdge from '../DistributionMapPopupEdge';

import useStyles from './styles';

interface OwnProps {
  pageForm: AcDistributionMapPageForm;
  graphForm: AcDistGraphForm;
  feature: MapboxGeoJSONFeature;
  lat: number;
  lng: number;
}

type Props = OwnProps & WithTranslate;

const DistributionMapPopup: FC<Props> = (props) => {
  const {
    pageForm,
    pageForm: { mapMode, viewMode },
    graphForm: { day },
    feature: { source, properties },
    lat,
    lng,
  } = props;

  const classes = useStyles();

  if (!properties) {
    return null;
  }

  const {
    stop_name: name = '',
    edge_id: edgeId = '',
    stop_id: stopId = '',
    passenger_traffic_total: trafficTotal,
  } = properties;

  const byVehicleKey = mapMode === MapModes.diagram ? 'vehicle_type_traffic' : 'vehicle_type_traffic_relative';
  const byRouteKey = mapMode === MapModes.diagram ? 'route_variant_traffic' : 'route_variant_traffic_relative';

  const traffic = (viewMode === ViewModes.byVehicles) ? properties[byVehicleKey] : properties[byRouteKey];

  const data = traffic ? JSON.parse(traffic) : {};
  const total = trafficTotal ? JSON.parse(trafficTotal) : 0;

  return (
    <Popup
      latitude={lat}
      longitude={lng}
      closeButton={false}
      closeOnClick={false}
      anchor="top-left"
      offsetTop={15}
      offsetLeft={15}
      tipSize={10}
      className={classes.root}
    >
      <div>
        {source.includes('stops') && (
          <DistributionMapPopupStop
            pageForm={pageForm}
            isDay={day}
            id={stopId}
            name={name}
            data={data}
            total={total}
          />
        )}
        {source.includes('edges') && (
          <DistributionMapPopupEdge
            pageForm={pageForm}
            isDay={day}
            id={edgeId}
            data={data}
            total={total}
          />
        )}
      </div>
    </Popup>
  );
};

export default DistributionMapPopup;
