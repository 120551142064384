import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { loadMatrixForecast, selectMatrixForecast } from 'src/cluster/common';

import RouterDistributionProgress from './RouterDistributionProgress';

const mapStateToProps = (state: AppState) => ({
  matrixForecastEntities: selectMatrixForecast(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadMatrixForecast: () => dispatch(loadMatrixForecast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(RouterDistributionProgress));
