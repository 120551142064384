import { Theme, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => ({
  tooltipAnchor: {
    cursor: 'pointer',
    padding: spacing(0, 4),
    margin: spacing(0, -4),
  },
  titleUnit: {
    color: '#333B46',
    marginTop: 6,
    display: 'block',
    fontWeight: 500,
    cursor: 'pointer',
    padding: spacing(0, 4),
    margin: spacing(0, -4),
    textTransform: 'uppercase',
    fontSize: 10,
  },
  spaceWrap: {
    display: 'inline-block',
    width: 20,
    textAlign: 'center',
  },
  withUnit: {
    display: 'block',
  },
}));
