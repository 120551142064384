/* eslint-disable max-len */
import React from 'react';

const Attachment = () => (
  <svg viewBox="0 0 330 330" >
    <path d="M194.999,0C194.999,0,194.999,0,194.999,0c-20.033,0-38.866,7.801-53.031,21.966  c-14.166,14.166-21.967,33-21.968,53.033v135.004c0,24.813,20.186,44.999,45.001,45c24.813-0.001,44.999-20.189,44.999-45.002  v-77.902c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15v77.902c0,8.272-6.73,15.001-14.999,15.002  c-8.271,0-15.001-6.729-15.001-15V74.999c0-12.02,4.682-23.321,13.181-31.82c8.5-8.5,19.799-13.18,31.818-13.18  c24.814,0,45.001,20.186,45.002,44.998v150.002c-0.002,41.355-33.646,75.001-75,75.001c-20.033,0-38.868-7.8-53.033-21.966  c-14.166-14.165-21.967-33-21.967-53.034L89.999,74.999c0-8.285-6.716-15-15-15s-15,6.716-15,15l0.002,150.001  C60,253.047,70.922,279.415,90.754,299.248c19.832,19.832,46.2,30.754,74.247,30.753c57.895,0,104.998-47.103,105-105V74.998  C270,33.644,236.354,0,194.999,0z" />
  </svg>
);

export default Attachment;
