import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => createStyles({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: spacing(4),
    overflow: 'visible',
    lineHeight: '20px',
  },

  entry: {
    display: 'flex',
    alignItems: 'center',

    '&:not(:last-child)': {
      marginBottom: 7,
    },
  },

  iconWrapper: {
    marginRight: 20,
    flex: '0 0 26px',
    width: 46,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 46,
    height: 14,
  },

  message: {
    flex: 1,
    whiteSpace: 'nowrap',
  },
},
));
