import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  createFormSelector,
  createFormStateSelectors,
  isArray,
  isNumber,
} from 'src/helpers';

import {
  AC_ROUTES_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_STOPS_MODEL_NAME,
  EDITOR_PAGE_FORM_NAME,
  ENTITIES_EDITOR_FORM_NAME,
  AC_PROJECTS_MODEL_NAME,
  AcRoutesModel,
  AcStopsModel,
  AcScenariosModel,
  isAcScenariosModel,
} from 'src/cluster/common';

import { selectEditorGraphForm } from 'src/cluster/editor-common';

import {
  AC_EDGE_SPEED_CHANGES_MODEL_NAME,
  AC_EDGE_SPEEDS_MODEL_NAME,
  AcEditorPageForm,
  AcEntitiesEditorForm,
  AcEdgeSpeed,
  AcEdgeSpeedChange,
} from 'src/cluster/editor-map';

import { selectAcProjectListForm } from 'src/cluster/common/store/selectors/common';

export const selectEditorPageForm = createFormSelector<AcEditorPageForm>(EDITOR_PAGE_FORM_NAME);
export const selectEntitiesEditorForm = createFormSelector<AcEntitiesEditorForm>(ENTITIES_EDITOR_FORM_NAME);

export const routeEditorFormSelectors = createFormStateSelectors<AcEntitiesEditorForm>(ENTITIES_EDITOR_FORM_NAME);

export const selectScenarioEntities = createEntitySelector<AcScenariosModel>(AC_SCENARIOS_MODEL_NAME);
export const selectStopEntities = createEntitySelector<AcStopsModel>(AC_STOPS_MODEL_NAME);
export const selectRouteEntities = createEntitySelector<AcRoutesModel>(AC_ROUTES_MODEL_NAME);
export const selectEdgeSpeedEntities = createEntitySelector<AcEdgeSpeed>(AC_EDGE_SPEEDS_MODEL_NAME);
export const selectEdgeSpeedChangeEntities = createEntitySelector<AcEdgeSpeedChange>(AC_EDGE_SPEED_CHANGES_MODEL_NAME);

export const selectEditorScenarios = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectScenarioEntities,
  ],
  (projectsListForm, editorGraphForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = editorGraphForm;

    if (!isNumber(selectedProject) || !isNumber(year)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios)) {
      return Nothing();
    }

    if (!isAcScenariosModel(scenarios[0])) {
      return Nothing();
    }

    return Just(scenarios);
  },
);
