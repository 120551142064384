import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import SmartDate, { SMART_DATE_FORMATS } from 'src/components/etc/SmartDate';

import { AcBalanceDataModel, AcBalanceShapesModel } from 'src/cluster/common';
import { AC_SHAPE_TYPES_DICT, AC_SHAPE_STATUSES_DICT } from 'src/cluster/shapes';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  shape: AcBalanceShapesModel;
  onEdit: (record: AcBalanceDataModel) => void;
  close: () => void;
}

const ModalBalanceShapesShow: FC<Props> = (props) => {
  const {
    className,
    shape,
    t,
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      <h2 className={style.title}>{t('modules.shapes.captions.shapeView')}</h2>
      <table className={style.table}>
        <tbody>
          <tr>
            <td>
              {t('modules.shapes.captions.name')}
              :
            </td>
            <td>{shape.name}</td>
          </tr>
          <tr>
            <td>
              {t('modules.shapes.captions.type')}
              :
            </td>
            <td>{t(AC_SHAPE_TYPES_DICT[shape.modelType])}</td>
          </tr>
          <tr>
            <td>
              {t('systems.balance.captions.factors')}
              :
            </td>
            <td>
              {shape.factors.map((factor) => {
                if (!factor.name) return null;
                const [name] = factor.name.split(', ');
                return (
                  <span key={name} className={style.factor}>
                    {name}
                  </span>
                );
              })}
            </td>
          </tr>
          <tr>
            <td>
              {t('modules.shapes.captions.created')}
              :
            </td>
            <td>
              <SmartDate
                format={SMART_DATE_FORMATS.simpleDate}
                date={shape.created}
              />
            </td>
          </tr>
          <tr>
            <td>
              {t('common.captions.user')}
              :
            </td>
            <td>{shape.user}</td>
          </tr>
          <tr>
            <td>
              {t('common.captions.status')}
              :
            </td>
            <td>{t(AC_SHAPE_STATUSES_DICT[shape.status])}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ModalBalanceShapesShow;
