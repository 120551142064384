import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import { VideoRouteMap } from 'src/modules/video/common/components';

import { VideoRouteTripsModel, VideoVehicleOnRoutesModel } from 'src/modules/video/common';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  trip?: Model<VideoRouteTripsModel>;
  route?: Model<VideoVehicleOnRoutesModel>;
}

const ModalVideoRouteView: FC<Props> = (props) => {
  const {
    className,
    trip,
    route,
  } = props;

  const nodes = trip?.stops?.map((stop) => ({
    lat: stop.stop.latitude,
    lng: stop.stop.longitude,
    stopId: stop.stop.stopId,
  })).filter((node) => node.lat && node.lng);

  return (
    <div className={classNames(style.root, className)}>
      <VideoRouteMap
        trip={trip}
        geoStops={nodes}
        stops={route?.stops}
      />
    </div>
  );
};

export default ModalVideoRouteView;
