import React, { useMemo } from 'react';

import i18n from 'src/i18n';
import { UNITS } from 'src/constants';
import { NumberFormat, WithUnit } from 'src/components';
import { isNil } from 'src/helpers';
import {
  AcDistRoutesModel,
  AcDistTableFieldsConfig,
  TopColumns,
} from 'src/cluster/distribution-table';
import { VehicleType } from 'src/cluster/common';

const useDistRouteConfigRight = (
  getVehicleTypeName: (vehicleId?: number) => VehicleType | undefined,
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistRoutesModel>[] => {
  return useMemo(() => {
    const translate = 'systems.distribution.columns.';
    return [
      {
        type: TopColumns.passengerTraffic,
        title: (
          <>
            <span className={classes.title}>
              {i18n.t(translate + TopColumns.total)}
            </span>
            <span className={classes.title}>
              <WithUnit unit={i18n.t(UNITS.people)}>
                {i18n.t(translate + TopColumns.passengerTraffic)}
                {' '}
                {i18n.t(translate + TopColumns.atPeakHour)}
              </WithUnit>
            </span>
          </>
        ),
        className: classes.right,
        model: (result: AcDistRoutesModel) => (
          <div className={classes.taRight}>
            {isNil(result.passengerTrafficTotal) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.passengerTrafficTotal}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: TopColumns.passengerTrafficPeak,
        className: classes.right,
        title: (
          <>
            <span className={classes.title}>
              {i18n.t(translate + TopColumns.passengerTraffic)}
            </span>
            <span className={classes.title}>
              <WithUnit unit={i18n.t(UNITS.people)}>
                {i18n.t(translate + TopColumns.inPeakSection)}
                {' '}
                {i18n.t(translate + TopColumns.atPeakHour)}
              </WithUnit>
            </span>
          </>
        ),
        model: (result: AcDistRoutesModel) => (
          <div className={classes.taRight}>
            {isNil(result.passengerTrafficPeak) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.passengerTrafficPeak}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: TopColumns.peakFillingPerVehicleNorm,
        className: classes.right,
        title: (
          <>
            <span className={classes.title}>
              {i18n.t(translate + TopColumns.fillingForOneVehicle)}
            </span>
            <span className={classes.title}>
              {i18n.t(translate + TopColumns.inPeakSection)}
              {' '}
              {i18n.t(translate + TopColumns.atPeakHour)}
            </span>
            <span className={classes.title}>
              <WithUnit unit={i18n.t(UNITS.peopleOfTheStandard)} />
            </span>
          </>
        ),
        model: (result: AcDistRoutesModel) => {
          return (
            <div className={classes.taRight}>
              {isNil(result.peakFillingPerVehicleAbs) ? '-' : (
                <NumberFormat
                  fixedDecimalScale={false}
                  decimalScale={0}
                  value={result.peakFillingPerVehicleAbs}
                  locale="ru"
                />
              )}
              <span> / </span>
              {isNil(result.peakFillingPerVehicleNorm) ? '-' : (
                <NumberFormat
                  fixedDecimalScale={false}
                  decimalScale={0}
                  value={result.peakFillingPerVehicleNorm}
                  locale="ru"
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [getVehicleTypeName]);
};

export default useDistRouteConfigRight;
