import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import { IndicatorsSubtask } from 'src/modules/mth/indicators';

import AggloIndicatorsItem from '../AggloIndicatorsItem';

import style from './index.module.css';

interface Props {
  subtask: IndicatorsSubtask;
}

const AggloIndicatorsSubtask: FC<Props> = (props: Props) => {
  const {
    subtask: {
      name,
      indicators = [],
    },
  } = props;

  const [head, ...tail] = indicators;

  return (
    <Fragment>
      <tr className={classNames(style.topRow, tail.length === 0 && style.bottomRow)}>
        <td className={style.cell}>{head.no}</td>
        <td
          rowSpan={indicators.length}
          className={style.subtaskCell}
        >
          {name}
        </td>
        <AggloIndicatorsItem indicator={head} />
      </tr>
      {tail.map((indicator, index) => (
        <tr
          key={indicator.no}
          className={classNames(style.cell, (index === tail.length - 1) && style.bottomRow)}
        >
          <td>{indicator.no}</td>
          <AggloIndicatorsItem indicator={indicator} />
        </tr>
      ))}
    </Fragment>
  );
};

export default AggloIndicatorsSubtask;
