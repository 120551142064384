import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import Modal from 'react-modal';

import modalsStyle from 'src/styles/modals.module.css';
import fadeInUp from 'src/styles/transitions/fade-in-up.module.css';

import { MODAL_SIZES } from 'src/modals/constants';
import { ICONS_TYPES } from 'src/components/deprecated/PIcon/constants';
import PIcon from 'src/components/deprecated/PIcon';

import style from './index.module.css';

interface Props {
  show: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  closeAction: Function;
  fitContent: boolean;
  afterClose?: () => void;
  className?: string;
  children?: any;
  title?: any;
  size: string;
  order?: number;
  cancelAction?: Function;
  deleteAction?: Function;
  editAction?: Function;
  buttons?: Function;
}

class ModalWrapper extends PureComponent<Props> {
  // eslint-disable-next-line react/static-property-placement
  public static propTypes = {
    show: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    shouldCloseOnEsc: PropTypes.bool,
    className: PropTypes.string,
    cancelAction: PropTypes.func,
    closeAction: PropTypes.func,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    title: PropTypes.node,
    size: PropTypes.oneOf(Object.values(MODAL_SIZES)),
    order: PropTypes.number,
    buttons: PropTypes.func,
  }

  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    show: true,
    fitContent: false,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEsc: true,
    className: '',
    closeAction: () => undefined,
    size: MODAL_SIZES.medium,
  }

  public render() {
    const {
      show,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
      closeAction,
      className,
      fitContent,
      children,
      title,
      size,
      order,
      cancelAction,
      deleteAction,
      editAction,
      buttons,
      afterClose,
    } = this.props;

    const fireAfterClose = () => {
      setTimeout(() => {
        if (afterClose) {
          afterClose();
        }
      }, 0);
    };

    const closeAndCancel = () => {
      if (cancelAction) {
        cancelAction();
      }
      if (!cancelAction && closeAction) {
        closeAction();
      }
      fireAfterClose();
    };

    const close = () => {
      if (closeAction) {
        closeAction();
      }
      fireAfterClose();
    };

    const closeButton = (
      <PIcon {...{
        type: ICONS_TYPES.clear,
        className: title ? style.close : style.closeAbsolute,
        onClick: closeAndCancel,
        size: 25,
      }} />
    );

    const transitionProps = {
      key: 'modalTransitions',
      component: null,
      classNames: fadeInUp,
      timeout: 500,
    };

    const modalProps = {
      key: 'modal',
      contentLabel: 'Modal',
      appElement: document.body,
    };

    if (!show) {
      return (
        <Modal {...{
          ...modalProps,
          isOpen: false,
          shouldFocusAfterRender: false,
          className: style.hiddenModal,
          overlayClassName: style.hiddenOverlay,
        }} >
          <CSSTransition {...transitionProps}>
            <div />
          </CSSTransition>
        </Modal>
      );
    }
    const overlayClass = size !== MODAL_SIZES.full ? style.overlay : style.visibleMenuOverlay;
    const closeOverlayClass = size !== MODAL_SIZES.full ? style.closeOverlay : style.visibleMenuCloseOverlay;

    return (
      <Modal {...{
        ...modalProps,
        isOpen: show,
        onRequestClose: close,
        shouldFocusAfterRender: true,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
        className: classNames(style[size], fitContent && style.fitContent),
        style: {
          overlay: {
            zIndex: 5000 + (order || 0),
          },
        },
        overlayClassName: overlayClass,
      }} >
        <div className={closeOverlayClass} onClick={(shouldCloseOnOverlayClick ? close : undefined)} />
        <CSSTransition {...transitionProps}>
          <div className={classNames(style.root, className)}>
            {title &&
              <div className={style.title}>
                <div className={style.titleText}>{title}</div>
                {editAction &&
                  <Fragment>
                    <PIcon {...{
                      type: ICONS_TYPES.edit,
                      className: style.toolBarIcon,
                      onClick: async () => {
                        await editAction();
                        closeAction();
                      },
                      size: 17,
                    }} />
                    {size !== MODAL_SIZES.full &&
                      <div className={style.delimeter} />}
                  </Fragment>}
                {deleteAction &&
                  <Fragment>
                    <PIcon {...{
                      type: ICONS_TYPES.trashBin,
                      className: style.toolBarIcon,
                      onClick: async () => {
                        await deleteAction();
                        closeAction();
                      },
                      size: 17,
                    }} />
                    {size !== MODAL_SIZES.full &&
                      <div className={style.delimeter} />}
                  </Fragment>}
                {closeAction && closeButton}
                {!!buttons && size === MODAL_SIZES.full &&
                  <div className={style.fullButtons}>
                    {buttons(this.props)}
                  </div>}
              </div>}
            <div className={style.children}>
              {children}
              {!!buttons && size !== MODAL_SIZES.full &&
                <div className={modalsStyle.buttonsContainer}>
                  {buttons(this.props)}
                </div>}
            </div>
            {!title && closeAction && closeButton}
          </div>
        </CSSTransition>
      </Modal>
    );
  }
}

export default ModalWrapper;
