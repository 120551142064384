import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ spacing }: Theme) => createStyles({
  field: {
    fontFamily: ffSourceSansPro,
    width: 80,
    marginBottom: spacing(6),
    marginRight: spacing(5),

    '& label': {
      fontSize: 12,
    },
  },
}));
