import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';

import { AcBalanceShapesModel } from 'src/cluster/common';
import { AC_SHAPE_STATUSES_DICT } from 'src/cluster/shapes';
import style from './index.module.css';

interface Props extends WithTranslate {
  shape: AcBalanceShapesModel;
}

const BalanceShapesTableStatus: FC<Props> = (props) => {
  const { shape, t } = props;

  const className = classNames(style.status, style[shape.status.toLowerCase()]);

  return (
    <span className={className}>
      {t(AC_SHAPE_STATUSES_DICT[shape.status])}
    </span>
  );
};

export default BalanceShapesTableStatus;
