import { TRANSPORT_TYPES, VIEW_MODES } from 'src/modules/mth/common/constants';

export default {
  defaults: {
    viewMode: VIEW_MODES.graph,
    showTraffic: false,
    showRoutes: false,
    showToolbar: true,
    selectedTab: 0,
    from: undefined,
    to: undefined,
    transportType: TRANSPORT_TYPES.auto,
    trafficLimit: 100,
    maxAlternativeRoutes: 2,
    isStartSelected: false,
    isFinishSelected: false,
    selectedRoute: undefined,
    selectedEdges: undefined,
    selectedRegion: undefined,
    expandedRoute: undefined,
    sortingColumn: undefined,
    sortingOrder: 1,
    hasLongPedestrian: false,
    maxPedestrianTime: 30,
    actualAlgorithmType: undefined,
    actualFrom: undefined,
    actualTo: undefined,
    actualTrafficLimit: undefined,
    actualHasLongPedestrian: undefined,
    actualMaxPedestrianTime: undefined,
    uuid: undefined,
    id: undefined,
    queryUuid: undefined,
    isBuilding: false,
  },
};
