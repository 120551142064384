import React, {
  FC,
} from 'react';
import { Chip as MuiChip } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';

import { CrossIcon } from 'src/components';
import { SelectMenuOption } from 'src/interfaces';

import useStyles from './styles';

interface OwnProps {
  item: SelectMenuOption;
  disabled?: boolean;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: SelectMenuOption) => void;
}

type Props = OwnProps & WithTranslate;

const Chip: FC<Props> = (props) => {
  const { item, disabled = false, onDelete } = props;

  const classes = useStyles();

  const onDeleteHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return onDelete ? onDelete(e, item) : undefined;
  };

  return (
    <MuiChip
      classes={classes}
      deleteIcon={<button aria-label="Mute volume">{!disabled && <CrossIcon />}</button>}
      label={item.label}
      disabled={disabled}
      onDelete={onDeleteHandler}
    />
  );
};

export default Chip;
