import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RestifyFormActions } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import { PageAgglomerationForm } from 'src/modules/mth/common/interfaces';
import AggloRoutesAndOvershotTabs from './AggloRoutesAndOvershotTabs';

export interface OwnProps {
  form: PageAgglomerationForm;
  formActions: RestifyFormActions<PageAgglomerationForm, PageAgglomerationForm>;
}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps) => ({
  setShowRoutes: (value: boolean) => dispatch(ownProps.formActions.changeField('showRoutes', value)),
  setSelectedTab: (value: number) => dispatch(ownProps.formActions.changeField('selectedTab', value)),
});

export default connect(null, mapDispatchToProps)(withTranslate(AggloRoutesAndOvershotTabs));
