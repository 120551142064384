import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';

import { BalanceShapesToolbar, BalanceShapesTable } from 'src/cluster/shapes';
import { InjectedProps, OwnProps } from './interfaces';

import style from './index.module.css';

type Props = OwnProps & InjectedProps & WithTranslate;

const BalanceShapesPage: FC<Props> = (props) => {
  const {
    className,
    entities,
    factors,
    projectForm: { selectedProject },
    pageForm,
    t,
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      <BalanceShapesToolbar
        pageForm={pageForm}
        factors={factors}
      />
      <BalanceShapesTable
        entities={entities}
        message={!selectedProject ? t('modules.projects.captions.projectNotSelected') : undefined}
      />
    </div>
  );
};

export default BalanceShapesPage;
