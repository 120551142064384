import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    zIndex: 10,
    display: 'block',
    padding: 0,
  },

  select: {
    width: 200,
  },

  tightSelect: {
    width: 170,
  },

  tightSelectGrow: {
    width: '100%',
  },

  wideSelect: {
    width: 260,
  },

  buttonControls: {
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  btnWithIcon: {
    '& > span': {
      fontWeight: 500,
      fontSize: 14,
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    marginLeft: 10,
    color: `${theme.palette.common.white}`,
  },

  link: {
    '& > span': {
      fontWeight: 500,
      textTransform: 'uppercase',
      fontSize: 14,
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    marginLeft: 10,
    color: theme.palette.text.primary,
  },

  cancelLink: {
    '& > span': {
      textTransform: 'capitalize',
      fontWeight: 'normal',
    },
    verticalAlign: 'middle',
    fontSize: 12,
  },

  button: {
    background: `${theme.palette.common.white}!important`,
    borderRadius: '5px !important',
    margin: 0,
    '& > span': {
      textTransform: 'capitalize',
      fontWeight: 'normal',
    },
  },

  toolBarControlsLine: {
    borderTop: '1px solid #C4C4C4',
    width: '100%',
    paddingTop: theme.spacing(4),
  },

  projectCreateContainer: {
    margin: theme.spacing(3, 5, 0, 3),
  },

  buttonsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },

  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 18,
    color: '#333B46',
  },

  titleWrapper: {
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  // iconButtonRed: {
  //   color: theme.palette.primary.main,
  // },
  //
  toolbarControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    minHeight: 90,
  },

  iconButtonRed: {
    color: theme.palette.primary.main,
    display: 'flex',
    '& > span': {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    fontSize: 12,
    marginLeft: 8,
  },

  block: {
    padding: 0,
  },

  toolbarControl: {
    flexGrow: 1,
  },

  '@media only screen and (min-width: 1520px)': {
    multiSelect: {
      width: 300,
    },
  },

  '@media only screen and (min-width: 1720px)': {
    multiSelect: {
      width: 400,
    },
  },
}));
