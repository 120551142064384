/* eslint-disable max-len */
import React from 'react';

const Cargo = () => (
  <svg viewBox="0 0 402 402">
    <path d="M130.1,229.8h225c8.3,0,15-6.7,15-15v-197c0-8.3-6.7-15-15-15h-225c-8.3,0-15,6.7-15,15v197   C115.1,223.1,121.9,229.8,130.1,229.8z M264.2,2.9V60H221V2.9H264.2z" />
    <path d="M400,302.6c-2.5-6.2-8.3-10.1-15-10.1c-3.3,0-6.7,0.9-10,2.8c-28.7,15.8-65.5,28.4-98.4,33.6c-4.9,0.8-10.5,1.1-16.8,1.1   c-16.1,0-34.8-2.5-49.9-6.7c-15.4-4.3-24.6-9.8-24.8-14.7c0.2-0.1,0.6-0.2,1.5-0.2c3,0,8.8,1.1,16.1,2.5   c12.7,2.4,30.2,5.7,46.4,5.7c7.8,0,14.6-0.8,20.4-2.3c14.1-3.8,19-12.9,18.9-20.1c-0.1-7.1-4.7-13-11.2-14.5   c-3.5-0.8-11.7-3.4-22-6.8c-29.2-9.4-78.1-25.1-102.8-25.8c-0.2,0-0.4,0-0.6,0c-13.8,0-77.6,12.8-115.7,20.5   c-10.1,2-18.1,3.6-23.2,4.6c-5.4,1-12.9,2.5-12.9,9.9c0,14.4,0.4,28.2,0.8,42.9c0.4,14.9,0.8,30.4,0.8,46.7v0.4   c0,3.6,0,6.3,0.9,8.2c1,2.1,3,3,6.5,3c1.9,0,4.3-0.3,7.6-0.8c14.4-2.5,59.2-9.2,75.9-9.2c2.3,0,3.9,0.1,5,0.4   c14.2,3.2,132.1,25,166.3,25c0,0,0,0,0,0c3.1,0,5.4-0.2,7-0.5c26.4-6.1,104.1-51.6,119.4-66.4C399.9,322.2,403.6,311.4,400,302.6z" />
  </svg>
);

export default Cargo;
