import { createSelector } from 'reselect';

import {
  api,
  forms,
} from 'redux-restify';

import {
  PROJECTS_PAGES_FORM_NAMES,
  PROJECTS_MODEL_NAMES,
  PageProjectForm,
} from 'src/modules/general/projects';

import { Systems } from 'src/constants';
import {
  FORECAST_MODEL_NAMES,
  ForecastModel,
} from '../constants';

export const getForecastSelectors = (type: Systems) => {
  const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[type];
  const projectsModelName = PROJECTS_MODEL_NAMES[type];
  const forecastModelName = FORECAST_MODEL_NAMES[type];
  const getCurrentProjectForecast = createSelector(
    [
      (state) => forms.selectors[pageProjectFormName].getFormWithNulls<PageProjectForm>(state),
      (state) => api.selectors.entityManager[forecastModelName].getEntities<ForecastModel>(state),
    ],
    (pageProjectForm, forecastEntities) => {
      if (!pageProjectForm.selectedProject) {
        return [];
      }
      const forecastApiConfig = {
        parentEntities: {
          [projectsModelName]: pageProjectForm.selectedProject,
        },
      };
      return forecastEntities.getArray(forecastApiConfig);
    },
  );

  return {
    getCurrentProjectForecast,
  };
};
