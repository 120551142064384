import React from 'react';

const TrafficNodeRail = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" transform="rotate(-90 11 11)" fill="white" stroke="#E5293C" strokeWidth="2" />
    <path d="M15.5743 4.53379L11.3433 4.0976L7.11234 4.53379C6.96263 4.53379 6.84131 4.65512 6.84131 4.80482V13.5235C6.84131 13.6732 6.96263 13.7945 7.11234 13.7945H15.5743C15.724 13.7945 15.8454 13.6732 15.8454 13.5235V4.80482C15.8454 4.65528 15.724 4.53379 15.5743 4.53379ZM9.24007 12.4049C8.82077 12.4049 8.48087 12.065 8.48087 11.6457C8.48087 11.2264 8.82077 10.8865 9.24007 10.8865C9.65937 10.8865 9.99927 11.2264 9.99927 11.6457C9.99927 12.065 9.65937 12.4049 9.24007 12.4049ZM13.4464 12.4049C13.0271 12.4049 12.6872 12.065 12.6872 11.6457C12.6872 11.2264 13.0271 10.8865 13.4464 10.8865C13.8657 10.8865 14.2056 11.2264 14.2056 11.6457C14.2058 12.065 13.8657 12.4049 13.4464 12.4049ZM15.0123 9.51083C15.0123 9.59214 14.9465 9.65799 14.8652 9.65799H7.82148C7.74017 9.65799 7.67432 9.59214 7.67432 9.51083V5.64836C7.67432 5.56705 7.74017 5.5012 7.82148 5.5012H14.8652C14.9465 5.5012 15.0123 5.56705 15.0123 5.64836V9.51083Z" fill="#333B46" />
    <path d="M15.5727 14.0335H7.11076C6.82953 14.0335 6.60059 13.8045 6.60059 13.5235V4.80479C6.60059 4.52834 6.82171 4.30243 7.09657 4.29494L11.3417 3.85715L15.5868 4.29494C15.8616 4.30243 16.0828 4.52834 16.0828 4.80479V13.5235C16.0829 13.8047 15.854 14.0335 15.5727 14.0335ZM7.11076 4.77306C7.09322 4.77306 7.07887 4.78725 7.07887 4.80495V13.5236C7.07887 13.541 7.09306 13.5553 7.11076 13.5553H15.5727C15.5903 13.5553 15.6046 13.541 15.6046 13.5236V4.80479C15.6046 4.78725 15.5903 4.7729 15.5727 4.7729L15.5482 4.77163L11.3417 4.33798L7.11076 4.77306ZM13.4449 12.6441C12.8943 12.6441 12.4465 12.1962 12.4465 11.6457C12.4465 11.0952 12.8943 10.6472 13.4449 10.6472C13.9954 10.6472 14.4434 11.0952 14.4434 11.6457C14.4434 12.1962 13.9954 12.6441 13.4449 12.6441ZM13.4449 11.1257C13.158 11.1257 12.9248 11.3591 12.9248 11.6459C12.9248 11.9327 13.158 12.1659 13.4449 12.1659C13.7317 12.1659 13.9651 11.9327 13.9651 11.6459C13.9651 11.3591 13.7317 11.1257 13.4449 11.1257ZM9.23849 12.6441C8.68798 12.6441 8.24015 12.1962 8.24015 11.6457C8.24015 11.0952 8.68798 10.6472 9.23849 10.6472C9.789 10.6472 10.2368 11.0952 10.2368 11.6457C10.2368 12.1962 9.789 12.6441 9.23849 12.6441ZM9.23849 11.1257C8.95168 11.1257 8.71844 11.3591 8.71844 11.6459C8.71844 11.9327 8.95168 12.1659 9.23849 12.1659C9.5253 12.1659 9.75855 11.9327 9.75855 11.6459C9.75855 11.3591 9.5253 11.1257 9.23849 11.1257ZM14.8635 9.89694H7.8199C7.6069 9.89694 7.4336 9.72364 7.4336 9.51064V5.64833C7.4336 5.43533 7.6069 5.26203 7.8199 5.26203H14.8636C15.0766 5.26203 15.2499 5.43533 15.2499 5.64833V9.5108C15.2497 9.72364 15.0764 9.89694 14.8635 9.89694ZM7.91189 9.41865H14.7715V5.74032H7.91189V9.41865Z" fill="#333B46" />
    <path d="M17.0958 18.1429C16.9878 18.1429 16.8825 18.088 16.8226 17.9889L14.4799 14.109C14.3889 13.9582 14.4372 13.7621 14.588 13.6712C14.7387 13.5799 14.9348 13.6285 15.0258 13.7793L17.3683 17.6593C17.4593 17.8101 17.411 18.0062 17.2602 18.0971C17.2089 18.1282 17.1518 18.1429 17.0958 18.1429Z" fill="#333B46" />
    <path d="M5.60444 18.1428C5.54832 18.1428 5.49157 18.128 5.43991 18.0969C5.28909 18.0061 5.24062 17.81 5.33182 17.6591L7.67446 13.7791C7.76534 13.6285 7.96143 13.58 8.11225 13.671C8.26307 13.7619 8.31154 13.958 8.22035 14.1088L5.8777 17.989C5.81792 18.0882 5.71253 18.1428 5.60444 18.1428Z" fill="#333B46" />
    <path d="M15.2486 15.1393H7.50098C7.41298 15.1393 7.34155 15.0681 7.34155 14.9799C7.34155 14.8917 7.41298 14.8205 7.50098 14.8205H15.2487C15.3369 14.8205 15.4082 14.8917 15.4082 14.9799C15.4082 15.0681 15.3367 15.1393 15.2486 15.1393Z" fill="#333B46" />
    <path d="M16.0737 16.5718H6.72816C6.59599 16.5718 6.48901 16.4647 6.48901 16.3326C6.48901 16.2006 6.59599 16.0935 6.72816 16.0935H16.0737C16.2057 16.0935 16.3128 16.2006 16.3128 16.3326C16.3128 16.4647 16.2057 16.5718 16.0737 16.5718Z" fill="#333B46" />
  </svg>
);

export default TrafficNodeRail;
