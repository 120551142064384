import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';
import { AC_SHAPES_ENDPOINT } from 'src/cluster/shapes';

export default {
  endpoint: AC_SHAPES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
    user: undefined,
    created: undefined,
    modelType: undefined,
    status: undefined,
    precision: undefined,
    factors: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
};
