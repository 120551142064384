import { Dispatch } from 'redux';

import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';

import {
  AC_EDITOR_COPY_NETWORK_MODAL_NAME,
  ModalEditorCopyNetwork,
  copyingNetworkFormActions,
} from 'src/cluster/editor-common';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.small,
  };
};

const dispatchToProps = (dispatch: Dispatch<any>) => ({
  afterClose: () => {
    dispatch(copyingNetworkFormActions.resetForm());
  },
});

export default registerModal(
  AC_EDITOR_COPY_NETWORK_MODAL_NAME,
  stateToProps,
  dispatchToProps,
)(ModalEditorCopyNetwork);
