import { FILTER_MODES } from 'src/cluster/common';

export default {
  defaults: {
    filterModeFrom: FILTER_MODES.node,
    filterModeTo: FILTER_MODES.node,
    regionFrom: undefined,
    regionTo: undefined,
    municipalityFrom: undefined,
    municipalityTo: undefined,
    year: undefined,
    scenario: undefined,
    behaviorType: undefined,
    interval: undefined,
    mapRegion: undefined,
    mapYear: undefined,
    isEdit: false,
    isCreate: false,

    pageSize: 10,
    currentPage: 1,
  },
};
