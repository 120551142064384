import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';
import { deletePoint } from 'src/cluster/editor-map';
import { TrackPoint } from 'src/cluster/common';

import EditorModalSelectNode from './EditorModalSelectNode';

const mapDispatchToProps = (dispatch: Dispatch<any>, { closeAction }: WithModalProps) => ({
  onDeletePoint: (node: TrackPoint) => dispatch(deletePoint(node)),
  onCloseModal: () => dispatch(closeAction),
});

export default connect(null, mapDispatchToProps)(withTranslate(EditorModalSelectNode));
