import { MapModes, ViewModes } from '../../types';
import { AcDistributionMapPageForm } from '../../interfaces';

const defaults: AcDistributionMapPageForm = {
  mapMode: MapModes.diagram,
  viewMode: ViewModes.byVehicles,

  showEdges: true,
  showStops: true,

  vehicleTypeIds: [],

  vehicleTypeId: undefined,
  routeId: undefined,
  variantId: undefined,
};

export default { defaults };
