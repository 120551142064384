const on = (el: Document, type: string, callback: EventListenerOrEventListenerObject) => {
  el.addEventListener(type, callback, false);
};

const off = (el: Document, type: string, callback: EventListenerOrEventListenerObject) => {
  el.removeEventListener(type, callback, false);
};

export const bind = (events: string[], callback: EventListenerOrEventListenerObject) => {
  events.forEach(event => on(document, event, callback));
};

export const unbind = (events: string[], callback: EventListenerOrEventListenerObject) => {
  events.forEach(event => off(document, event, callback));
};
