import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Model } from 'src/interfaces';
import i18n, { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import modals from 'src/modals';

import {
  AcEdgeSpeed,
  AcEdgeSpeedChange,
  selectEditorSelectedStop,
  selectEditorPageForm,
  selectEditorSelectedRoute,
  selectEditorSelectedEdge,
  selectEditorEdgeSpeeds,
  selectEditorEdgeSpeedChanges,
  deleteRoute,
  enableModifyRouteMode,
  enableViewMode,
  enableModifyEdgeMode,
  modifyEdgeSpeed,
  updateEdgeSpeeds,
  modifyEdgeSpeedChange,
  updateEdgeSpeedChanges,
  cancelEdgeSpeedChanges,
  enableModifyStopMode,
  leaveEdge,
} from 'src/cluster/editor-map';

import EditorSidebar from './EditorSidebar';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
  selectedRoute: selectEditorSelectedRoute(state),
  selectedStop: selectEditorSelectedStop(state),
  selectedEdge: selectEditorSelectedEdge(state),
  edgeSpeeds: selectEditorEdgeSpeeds(state),
  edgeSpeedChanges: selectEditorEdgeSpeedChanges(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  enableModifyRouteMode: () => dispatch(enableModifyRouteMode()),
  enableModifyStopMode: () => dispatch(enableModifyStopMode()),
  enableModifyEdgeMode: () => dispatch(enableModifyEdgeMode()),
  enableViewMode: () => dispatch(enableViewMode()),
  leaveEdge: () => dispatch(leaveEdge()),
  modifyEdgeSpeed: (id: number, values: Model<AcEdgeSpeed>) => dispatch(modifyEdgeSpeed(id, values)),
  saveEdgeSpeeds: () => dispatch(updateEdgeSpeeds()),
  modifyEdgeSpeedChange: (id: number, values: Model<AcEdgeSpeedChange>) => dispatch(modifyEdgeSpeedChange(id, values)),
  saveEdgeSpeedChanges: () => dispatch(updateEdgeSpeedChanges()),
  cancelEdgeSpeedChanges: () => dispatch(cancelEdgeSpeedChanges()),
  deleteRoute: (id: number, number: string) => {
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.editor.captions.deleteRouteTitle'),
      text: i18n.t('modules.editor.messages.deleteRouteInfo', { number }),
      acceptButtonText: i18n.t('common.captions.delete'),
      declineButtonText: i18n.t('common.captions.cancel'),
      onAccept: () => dispatch(deleteRoute(id)),
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(EditorSidebar));
