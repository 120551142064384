import React from 'react';

import PIcon from 'src/components/deprecated/PIcon';
import { TransportTypeDict } from 'src/modules/mth/common/interfaces';
import { ICON_TYPES } from 'src/modules/mth/common/constants';
import style from './index.module.css';

interface Props {
  types: TransportTypeDict;
  total: number;
}

const AggloTransportTypeIcons: React.FC<Props> = ({
  types, total,
}) => {
  const typePercent: any = {};
  let percentSum = 0;
  Object.keys(types)
    .sort((a, b) => types[b] - types[a]) // sort by distance
    .filter(type => !!ICON_TYPES[type]) // exclude types without icons
    .forEach((type, index) => {
      typePercent[type] = Math.round((types[type] / total) * 100);
      percentSum += typePercent[type];
      if (index === Object.keys(type).length) {
        // increase last number to match 100% in total
        typePercent[type] += 100 - percentSum;
      }
    });
  return (
    <span className={style.root}>
      {Object.keys(typePercent)
        .map((type, index) => {
          return (
            <span className={style.iconWrapper} key={index}>
              <PIcon {...{
                type: ICON_TYPES[type],
                className: style.icon,
              }} />
              <span className={style.percent}>
                {typePercent[type]}
                %
              </span>
            </span>
          );
        })}
    </span>
  );
};

export default AggloTransportTypeIcons;
