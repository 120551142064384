import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ spacing }: Theme) => createStyles({
  field: {
    fontFamily: ffSourceSansPro,
    width: 215,
    marginBottom: spacing(6),
    marginRight: spacing(5),

    '& label': {
      fontSize: 12,
    },
  },

  input: {
    width: 70,
  },

  inputName: {
    width: 300,
  },
}));
