import { VIDEO_VEHICLE_ON_ROUTES_ENDPOINT } from 'src/modules/video/common';

export default {
  endpoint: VIDEO_VEHICLE_ON_ROUTES_ENDPOINT,
  defaults: {
    id: undefined,
    city: undefined,
    vehicleType: undefined,
    vehicleMakeModel: undefined,
    transportCompany: undefined,
    startDt: undefined,
    endDt: undefined,
    moveStartDt: undefined,
    moveEndDt: undefined,
    routeNumber: undefined,
    routeTrip: undefined,
    passengerCount: undefined,
    stops: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  allowIdRequests: true,
};
