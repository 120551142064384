import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { SelectValue } from 'src/interfaces';
import { withTranslate } from 'src/i18n';

import { LegendType, editorLegendActions } from 'src/cluster/common';

import MapLegendDrawer from './MapLegendDrawer';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setLegendField: (field: LegendType, value: boolean | SelectValue) => {
    dispatch(editorLegendActions.changeField(field, value));
  },
  clearLegend: () => {
    dispatch(editorLegendActions.resetForm());
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(MapLegendDrawer));
