import React from 'react';

const Info = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" fill="white" stroke="#c4c4c4" />
    <path d="M9.88147 15.9414V9.44141H12.1175V15.9414H9.88147ZM10.9995 8.63541C10.6355 8.63541 10.3365 8.53141 10.1025 8.32341C9.86847 8.11541 9.75147 7.84674 9.75147 7.51741C9.75147 7.18807 9.86847 6.91941 10.1025 6.71141C10.3365 6.50341 10.6355 6.39941 10.9995 6.39941C11.3635 6.39941 11.6625 6.50341 11.8965 6.71141C12.1305 6.91941 12.2475 7.18807 12.2475 7.51741C12.2475 7.84674 12.1305 8.11541 11.8965 8.32341C11.6625 8.53141 11.3635 8.63541 10.9995 8.63541Z" fill="#333B46" />
  </svg>
);

export default Info;
