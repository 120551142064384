import { PageRoutesForm } from 'src/modules/mth/common';

export function hasAggloRoutesFilters(form: PageRoutesForm): form is Required<PageRoutesForm> {
  return Boolean(
    form.from !== undefined
    && form.to !== undefined
    && form.from !== form.to
    && form.trafficLimit !== undefined
    && form.maxAlternativeRoutes !== undefined
    && form.hasLongPedestrian !== undefined,
  );
}
