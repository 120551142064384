import React, { FC } from 'react';

import {
  ActionButtonGroup,
  Button,
  DataGrid,
  EditPencilIcon,
  TrashIcon,
} from 'src/components';

import {
  TopColumns,
  BottomColumns,
  AcRegRoutesResultsModel,
  RegistryVariant,
  useRegistryRouteConfig,
  useRegistryVariantConfig,
} from 'src/cluster/editor-table';

import useStyles from './styles';

interface OwnProps {
  data: AcRegRoutesResultsModel[];
  topColumns: TopColumns[];
  bottomColumns: BottomColumns[];
  showEditingModal(record: AcRegRoutesResultsModel): void;
  loadRegistryRoute(id: number): void;
  onDeleteRoute(id: number, num: string): void;
}

const RegistryDataGrid: FC<OwnProps> = (props) => {
  const {
    data,
    topColumns,
    bottomColumns,
    showEditingModal,
    loadRegistryRoute,
    onDeleteRoute,
  } = props;

  const classes = useStyles();

  const onExpandClick = (record: AcRegRoutesResultsModel) => {
    if (!record.variants) loadRegistryRoute(record.id);
  };

  const [columns, bodyColumns] = useRegistryRouteConfig(topColumns, classes);
  const [variantColumns, variantBodyColumns] = useRegistryVariantConfig(bottomColumns, classes);

  return (
    <DataGrid<AcRegRoutesResultsModel>
      headerColumns={columns}
      columns={bodyColumns}
      body={data}
      headCellClassName={classes.bigSize}
      rowClassName={classes.routeRow}
      expandable
      onExpandClick={onExpandClick}
      expand={record => !!record.variants?.length && (
        <DataGrid<RegistryVariant>
          headerColumns={variantColumns}
          columns={variantBodyColumns}
          headCellClassName={classes.variantHead}
          rowClassName={classes.variantRow}
          cellClassName={classes.cellClassName}
          body={record.variants}
          size="small"
        />
      )}
      actionComponent={(record) => (
        <ActionButtonGroup>
          <Button variant="icon" onClick={() => showEditingModal(record)}>
            <EditPencilIcon />
          </Button>
          <Button variant="icon" onClick={() => onDeleteRoute(record.id, record.routeNumber)}>
            <TrashIcon />
          </Button>
        </ActionButtonGroup>
      )}
      actionClass={classes.actions}
    />
  );
};

export default RegistryDataGrid;
