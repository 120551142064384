import { createStyles, makeStyles, Theme } from '@material-ui/core';

const createProjectHeight = 550;

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',

    '&> :first-child': {
      maxHeight: createProjectHeight,
    },

    '&> :last-child': {
      flex: '1 1 auto',
    },
  },

  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    color: theme.palette.primary.main,

    '&> svg': {
      width: '100%',
      height: '100%',
      transform: 'rotate(0deg)',
      transition: 'transform 0.3s ease',
    },
  },

  arrowDown: {
    '&> svg': {
      transform: 'rotate(180deg)',
    },
  },

  animated: {
    transform: 'translateY(-100%)',
    transition: 'transform 1s linear',
  },

  projectCreateContainer: {
    margin: theme.spacing(3, 5, 0, 7),
  },

  toggle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    cursor: 'pointer',
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',

    '&> :last-child': {
      marginBottom: 0,
      marginLeft: theme.spacing(2),
    },
  },

  projects: {
    backgroundColor: theme.palette.common.white,
  },
}));
