import { Model } from 'src/interfaces';

import { AcDashIndicatorResultsModel } from 'src/cluster/dashboard-common';
import { IndicatorPartition } from 'src/cluster/dashboard-table';

import useIntervalForPartition from './useIntervalForPartition';
import useVehicleClassesForPartition from './useVehicleClassesForPartition';
import useVehicleTypesForPartition from './useVehicleTypesForPartition';

const useEntitiesForPartition = (
  entityName: string,
  baseIndicator?: Model<AcDashIndicatorResultsModel>,
): IndicatorPartition[] => {
  const intervals = useIntervalForPartition();
  const vehicleClasses = useVehicleClassesForPartition();
  const vehicleTypes = useVehicleTypesForPartition();

  switch (entityName) {
    case 'interval': {
      return intervals.filter((interval) => {
        return !!baseIndicator?.subindicators.some((indicator) => indicator.intervalId === interval.id);
      });
    }
    case 'vehicle_class': {
      return vehicleClasses.filter((vehicleClass) => {
        return !!baseIndicator?.subindicators.some((indicator) => indicator.vehicleClassId === vehicleClass.id);
      });
    }
    case 'vehicle_type': {
      return vehicleTypes.filter((vehicleType) => {
        return !!baseIndicator?.subindicators.some((indicator) => indicator.vehicleTypeId === vehicleType.id);
      });
    }
    default: return [];
  }
};

export default useEntitiesForPartition;
