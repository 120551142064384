import { createFormActions } from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';

import { AcBalanceMapPageForm } from '../../interfaces';
import { AC_BALANCE_MAP_PAGE_FORM_NAME } from '../../constants';

export const acBalanceMapFormActions = createFormActions<AcBalanceMapPageForm>(AC_BALANCE_MAP_PAGE_FORM_NAME);

export function onBalanceMapProjectChange(): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(acBalanceMapFormActions.resetForm());
  };
}
