import { useMemo } from 'react';

import palette from 'src/theme/palette';

import { LEGEND_VEHICLE_TYPES_COLORS } from 'src/cluster/common';
import { useVehicleTypes } from 'src/cluster/editor-map';

const useVehicleColor = (vehicleId?: number): [string, string] => {
  const vehicleTypes = useVehicleTypes();

  return useMemo(() => {
    if (!vehicleId) return [palette.grey.A200, palette.grey.A100];

    const vehicleName = vehicleTypes.find((item) => item.id === vehicleId)?.name;

    if (!vehicleName) return [palette.grey.A200, palette.grey.A100];

    return [LEGEND_VEHICLE_TYPES_COLORS[vehicleName].color, LEGEND_VEHICLE_TYPES_COLORS[vehicleName].colorDark];
  }, [vehicleTypes]);
};

export default useVehicleColor;
