import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import { SelectField } from 'src/components';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';

import clsx from 'clsx';
import useStyles from './styles';

const AVAILABLE_PAGE_SIZES = [
  10,
  20,
  50,
  100,
];

const PAGE_DELTA_SIZE = 2;

interface Props extends WithTranslate {
  className?: string;
  fixed?: boolean;
  disabled?: boolean;
  availablePageSizes?: number[];
  pageSize: number;
  currentPage: number;
  count: number;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const Pagination: FC<Props> = (props) => {
  const {
    children,
    className,
    fixed = true,
    disabled = false,
    availablePageSizes = AVAILABLE_PAGE_SIZES,
    pageSize = AVAILABLE_PAGE_SIZES[0],
    currentPage = 1,
    count,
    t,
    onPageChange,
    onPageSizeChange,
  } = props;

  const classes = useStyles();

  const allPagesCount = Math.ceil(count / pageSize);
  const allPagesArray = (new Array(allPagesCount)).fill(0).map((_, index) => index + 1);
  const pagesArray = allPagesArray.filter(p => Math.abs(p - currentPage) <= PAGE_DELTA_SIZE);

  const currentPageIndex = pagesArray.indexOf(currentPage);
  let additionalStartPages = [];
  if (currentPageIndex < PAGE_DELTA_SIZE) {
    additionalStartPages = new Array(PAGE_DELTA_SIZE - currentPageIndex).fill(0);
  }
  let additionalEndPages = [];
  if (pagesArray.length - (currentPageIndex + 1) < PAGE_DELTA_SIZE) {
    additionalEndPages = new Array(PAGE_DELTA_SIZE - (pagesArray.length - (currentPageIndex + 1))).fill(0);
  }

  const startPage = pagesArray[0];
  const endPage = pagesArray[pagesArray.length - 1];
  const hasStart = startPage !== currentPage;
  const hasEnd = endPage !== currentPage;

  const firstItemIndex = (currentPage - 1) * pageSize + 1;
  const lastItemIndex = currentPage * pageSize;

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    onPageSizeChange(Number(value));
  };

  return (
    <div className={className}>
      {children}
      <div className={classNames(classes.pagination, { [classes.fixed]: fixed })}>
        <div className={classes.pageSize}>
          <span>
            {t('components.captions.pageSize')}
          </span>
          <SelectField
            className={classes.select}
            options={availablePageSizes.map(s => ({ value: s, label: s, id: String(s) }))}
            value={pageSize}
            disabled={disabled}
            onChange={handleChange}
          />
        </div>
        {!!count && (
          <div className={classes.pages}>
            <PIcon
              className={clsx(classes.page, classes.arrowIcon, !hasStart && classes.iconHidden)}
              type={ICONS_TYPES.arrowDouble}
              rotate={ROTATE_TYPES.down}
              disabled={disabled}
              onClick={() => onPageChange(1)}
              size={10}
            />
            <PIcon
              className={clsx(classes.page, classes.arrowIcon, !hasStart && classes.iconHidden)}
              type={ICONS_TYPES.arrowSingle}
              rotate={ROTATE_TYPES.down}
              disabled={disabled}
              onClick={() => onPageChange(currentPage - 1)}
              size={10}
            />
            {additionalStartPages.map((page, index) => (
              <div
                key={`start${index}`}
                className={classes.page}
              />
            ))}
            {pagesArray.map(page => (
              <button
                type="button"
                key={page}
                disabled={disabled}
                onClick={() => onPageChange(page)}
                className={clsx(classes.page, page === currentPage && classes.currentPage)}
              >
                {page}
              </button>
            ))}
            {additionalEndPages.map((page, index) => (
              <div
                key={`end${index}`}
                className={classes.page}
              />
            ))}
            <PIcon
              className={clsx(classes.page, classes.arrowIcon, !hasEnd && classes.iconHidden)}
              type={ICONS_TYPES.arrowSingle}
              rotate={ROTATE_TYPES.up}
              disabled={disabled}
              onClick={() => onPageChange(currentPage + 1)}
              size={10}
            />
            <PIcon
              className={clsx(classes.page, classes.arrowIcon, !hasEnd && classes.iconHidden)}
              type={ICONS_TYPES.arrowDouble}
              rotate={ROTATE_TYPES.up}
              disabled={disabled}
              onClick={() => onPageChange(allPagesCount)}
              size={10}
            />
          </div>
        )}
        {count > 0 && (
          <div className={classes.summaryCount}>
            <span>{firstItemIndex}</span>
            <span> - </span>
            <span>{lastItemIndex > count ? count : lastItemIndex}</span>
            <span>&nbsp;</span>
            <span>
              {t('components.captions.outOf')}
            </span>
            <span>&nbsp;</span>
            <span>{count}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
