import { StrictDict } from 'src/types';

import {
  ViewModes, BalanceFilterModes, VehicleType,
} from 'src/cluster/common/types';
import { hexToArray } from 'src/cluster/common/helpers';

export const VIEW_MODES = { ...ViewModes };
export const FILTER_MODES = { ...BalanceFilterModes };

export const VEHICLE_TYPES_COLORS: StrictDict<VehicleType, string> = {
  [VehicleType.bus]: '#38bd09',
  [VehicleType.trolleybus]: '#1a66b7',
  [VehicleType.tramway]: '#e5293c',
  [VehicleType.metro]: '#BB86FE',
  [VehicleType.suburban]: '#000000',
  [VehicleType.water]: '#29ADE5',
  [VehicleType.ropeway]: '#41EAE0',
  [VehicleType.funicular]: '#E86506',
  [VehicleType.monorail]: '#09BDA7',
  [VehicleType.auto]: '#BB06E8',
  [VehicleType.pedestrian]: '#525252',
  [VehicleType.all]: '#a2c0c3',
};

export const INITIAL_VIEW_STATE = {
  latitude: 45,
  longitude: 45,
  height: 0,
  width: 0,
  zoom: 3,
  bearing: 0,
  pitch: 0,
};

export const SPEED_COLORS: StrictDict<number, string> = {
  1: '#932102',
  2: '#ff430e',
  3: '#feda08',
  4: '#27b309',
};

export const FILL_COLORS: StrictDict<number, string> = {
  0: '#fff5eb',
  1: '#fee6ce',
  2: '#fdd0a2',
  3: '#fdae6b',
  4: '#fd8d3c',
  5: '#f16913',
  6: '#d94801',
  7: '#8c2d04',
  8: '#8c2d04',
  9: '#8c2d04',
};

export const TRANSPARENT_COLOR = [0, 0, 0, 0];
export const GREEN_COLOR = [0x27, 0xae, 0x60];
export const LIGHT_GREY_COLOR = [0xad, 0xad, 0xad];
export const BLUE_COLOR = [0x3f, 0xa2, 0xf7];

export const HEAT_DARK_RED_COLOR = [0x93, 0x21, 0x02, 0x90];
export const HEAT_RED_COLOR = [0xff, 0x43, 0x0e, 0x90];
export const HEAT_YELLOW_COLOR = [0xfe, 0xda, 0x08, 0x90];
export const HEAT_GREEN_COLOR = [0x27, 0xb3, 0x09, 0x90];

export const LEGEND_VEHICLE_TYPES_COLORS: StrictDict<VehicleType, {
  color: string;
  colorDark: string;
  hex: number[];
  hexDark: number[];
}> = {
  [VehicleType.bus]: {
    color: '#38bd09',
    colorDark: '#277d09',
    hex: hexToArray('#38bd09'),
    hexDark: hexToArray('#277d09'),
  },
  [VehicleType.auto]: {
    color: '#BB06E8',
    colorDark: '#6a0384',
    hex: hexToArray('#BB06E8'),
    hexDark: hexToArray('#6a0384'),
  },
  [VehicleType.trolleybus]: {
    color: '#1a66b7',
    colorDark: '#104073',
    hex: hexToArray('#1a66b7'),
    hexDark: hexToArray('#104073'),
  },
  [VehicleType.tramway]: {
    color: '#e5293c',
    colorDark: '#881a25',
    hex: hexToArray('#e5293c'),
    hexDark: hexToArray('#881a25'),
  },
  [VehicleType.metro]: {
    color: '#BB86FE',
    colorDark: '#BB86FE',
    hex: hexToArray('#BB86FE'),
    hexDark: hexToArray('#BB86FE'),
  },
  [VehicleType.pedestrian]: {
    color: '#525252',
    colorDark: '#232323',
    hex: hexToArray('#525252'),
    hexDark: hexToArray('#232323'),
  },
  [VehicleType.suburban]: {
    color: '#000000',
    colorDark: '#717171',
    hex: hexToArray('#000000'),
    hexDark: hexToArray('#717171'),
  },
  [VehicleType.water]: {
    color: '#29ADE5',
    colorDark: '#1a6a8a',
    hex: hexToArray('#29ADE5'),
    hexDark: hexToArray('#1a6a8a'),
  },
  [VehicleType.ropeway]: {
    color: '#41EAE0',
    colorDark: '#41EAE0',
    hex: hexToArray('#41EAE0'),
    hexDark: hexToArray('#41EAE0'),
  },
  [VehicleType.funicular]: {
    color: '#E86506',
    colorDark: '#E86506',
    hex: hexToArray('#E86506'),
    hexDark: hexToArray('#E86506'),
  },
  [VehicleType.monorail]: {
    color: '#09BDA7',
    colorDark: '#09BDA7',
    hex: hexToArray('#09BDA7'),
    hexDark: hexToArray('#09BDA7'),
  },
  [VehicleType.all]: {
    color: '#525252',
    colorDark: '#232323',
    hex: hexToArray('#525252'),
    hexDark: hexToArray('#232323'),
  },
};
