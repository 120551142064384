import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { Button, GearIcon } from 'src/components';

import { useAcIntervalName } from 'src/cluster/common';

import { useAcDistGraphParams, useAcDistScenarioName } from 'src/cluster/distribution-common';
import { useStyles, useCaptionStyles, useValueStyles } from './styles';

interface OwnProps {
  canShowModal: boolean;
}

interface InjectedProps {
  showModal(): void;
}

type Props = OwnProps & InjectedProps & WithTranslate;

const DistGraphSettings: FC<Props> = (props) => {
  const {
    canShowModal,
    t,
    showModal,
  } = props;

  const classes = useStyles();
  const captionClasses = useCaptionStyles();
  const valueClasses = useValueStyles();

  const [year, scenarioId, intervalId, day] = useAcDistGraphParams();

  const getScenarioName = useAcDistScenarioName();
  const getIntervalName = useAcIntervalName(day);

  return (
    <div>
      <div className={classes.container}>
        <Button
          variant="icon"
          className={classes.toggle}
          disabled={!canShowModal}
          onClick={showModal}
        >
          <GearIcon />
        </Button>
        <Typography
          classes={valueClasses}
          className={clsx(!year && classes.alert)}
        >
          {year || t('components.captions.notSelected')}
        </Typography>
        <Typography classes={captionClasses}>{t('common.captions.year')}</Typography>
        <Typography
          classes={valueClasses}
          className={clsx(!scenarioId && classes.alert)}
        >
          {getScenarioName(Number(scenarioId))}
        </Typography>
        <Typography classes={captionClasses}>{t('common.captions.scenario')}</Typography>
      </div>
      <div className={classes.container}>
        <Typography
          classes={valueClasses}
          className={clsx(!intervalId && !day && classes.alert)}
        >
          {getIntervalName(Number(intervalId))}
        </Typography>
        <Typography classes={captionClasses}>{t('common.captions.interval')}</Typography>
      </div>
    </div>
  );
};

export default DistGraphSettings;
