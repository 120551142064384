import React, { FC, ChangeEvent } from 'react';
import classNames from 'classnames';
import { RestifyFormActions } from 'redux-restify';

import { WithTranslate } from 'src/i18n';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';
import Tabs from 'src/components/common/Tabs';
import TabPanel from 'src/components/common/TabPanel';
import {
  PageAgglomerationForm,
  OvershotModel,
  RouteWithAggregation,
} from 'src/modules/mth/common/interfaces';

import AggloRouteTable from './components/AggloRouteTable';
import AggloOvershotTable from './components/AggloOvershotTable';

import style from './index.module.css';

export interface Props extends WithTranslate{
  className?: string;
  routes: RouteWithAggregation[];
  overshot: OvershotModel[];
  form: PageAgglomerationForm;
  formActions: RestifyFormActions<PageAgglomerationForm, PageAgglomerationForm>;
  setShowRoutes(value: boolean): void;
  setSelectedTab(value: number): void;
}

const AggloRoutesAndOvershotTabs: FC<Props> = (props: Props) => {
  const {
    className,
    routes,
    overshot,
    form,
    formActions,
    setShowRoutes,
    setSelectedTab,
    t,
  } = props;

  const disabled = routes.length === 0 && overshot.length === 0;

  const {
    selectedTab,
    showRoutes,
  } = form;

  const handleChange = (event: ChangeEvent<object>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleToggle = (event: ChangeEvent<object>) => {
    event.stopPropagation();
    if (!disabled) {
      setShowRoutes(!showRoutes);
    }
  };

  const rootClassname = classNames(style.root, {
    [style.show]: showRoutes && !disabled,
  }, className);

  return (
    <div className={rootClassname}>
      <div className={style.toggleWrapper}>
        <button
          type="button"
          className={style.toggle}
          disabled={disabled}
          onClick={handleToggle}
        >
          <div className={style.toggleIcon}>
            <PIcon {...{
              type: ICONS_TYPES.arrow,
              rotate: showRoutes && !disabled ? ROTATE_TYPES.up : ROTATE_TYPES.down,
              className: style.expandIcon,
              disabled,
            }} />
          </div>
          {!showRoutes && <span className={style.showRoutes}>{t('systems.mth.captions.showRoutes')}</span>}
        </button>
      </div>
      {showRoutes && !disabled && (
        <div className={style.tabsWrapper}>
          <Tabs {...{
            value: selectedTab || 0,
            labels: [
              t('systems.mth.captions.allocatedTraffic'),
              t('systems.mth.captions.unallocatedTraffic'),
            ],
            onChange: handleChange,
          }} />
          <TabPanel {...{
            value: selectedTab,
            index: 0,
            className: style.scrollWrapper,
          }}>
            <AggloRouteTable {...{
              routes,
              form,
              formActions,
            }} />
          </TabPanel>
          <TabPanel {...{
            value: selectedTab,
            index: 1,
            className: style.scrollWrapper,
          }}>
            <AggloOvershotTable {...{
              overshot,
              form,
              formActions,
            }} />
          </TabPanel>
        </div>
      )}
    </div>
  );
};

export default AggloRoutesAndOvershotTabs;
