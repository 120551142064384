import { ModelDefaults } from 'src/types';
import { AC_CATEGORIES_TREE_ENDPOINT, AcDashCategoriesModel } from 'src/cluster/dashboard-common';

const defaults: ModelDefaults<AcDashCategoriesModel> = {
  id: undefined,
  name: undefined,
  order: undefined,
  indicators: undefined,
  subcategories: undefined,
};

export default {
  endpoint: AC_CATEGORIES_TREE_ENDPOINT,
  defaults,
  clearPagesOnRouteChange: false,
  pagination: false,
};
