import { Model } from 'src/interfaces';
import { ThunkActionResult } from 'src/reducer';
import {
  createModelActions, isFeature, isFeatureCollection, isNumber,
} from 'src/helpers';
import app from 'src/app';
import i18n from 'src/i18n';
import modals from 'src/modals';

import {
  AC_PROJECTS_MODEL_NAME,
  geojsonActions,
  selectGeojsonForm,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { editorFormActions, entitiesEditorFormActions } from 'src/cluster/editor-common';
import {
  AC_EDGE_SPEED_CHANGES_MODEL_NAME,
  AC_EDGE_SPEEDS_MODEL_NAME,
  AcEdgeSpeed,
  AcEdgeSpeedChange,
  selectEditorEdgeSpeedChanges,
  selectEditorEdgeSpeeds,
  selectEditorPageForm,
  enableViewMode,
  fetchEdges,
  loadEdges,
} from 'src/cluster/editor-map';

export const edgeSpeedModelActions = createModelActions(AC_EDGE_SPEEDS_MODEL_NAME);
export const edgeSpeedChangeModelActions = createModelActions(AC_EDGE_SPEED_CHANGES_MODEL_NAME);

export function setSelectedEdge(id: number): ThunkActionResult<void> {
  return async (dispatch) => {
    dispatch(editorFormActions.resetField('nodeId'));
    dispatch(editorFormActions.resetField('stopId'));
    dispatch(editorFormActions.changeField('edgeId', id));
  };
}

export function modifyEdgeSpeed(id: number, values: Model<AcEdgeSpeed>): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    selectEditorEdgeSpeeds(state).forEach((edgeSpeeds) => {
      const newEdgeSpeed = {
        ...values,
        $edited: true,
      };

      const newEdgeSpeeds = edgeSpeeds.map(speed => (speed.id === id ? newEdgeSpeed : speed));

      dispatch(entitiesEditorFormActions.changeField('editableEdgeSpeeds', newEdgeSpeeds));
    });
  };
}

export function updateEdgeSpeeds(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { edgeId } = selectEditorPageForm(state);
    const { edges } = selectGeojsonForm(state);
    if (!isNumber(edgeId)) {
      return;
    }

    selectEditorEdgeSpeeds(state).forEach(async (edgeSpeeds) => {
      const edgeSpeedsApiConfig = {
        parentEntities: {
          [AC_PROJECTS_MODEL_NAME]: selectedProject,
        },
      };

      try {
        dispatch(editorFormActions.changeField('isLoading', true));
        await Promise.all(edgeSpeeds.filter(speed => speed.$edited).map(async (speed) => {
          return dispatch(edgeSpeedModelActions.patch(speed.id, speed, edgeSpeedsApiConfig));
        }));

        dispatch(enableViewMode());

        const newEdgeCollection = await dispatch(fetchEdges(edgeId));

        if (!isFeatureCollection(edges)) {
          console.error('Wrong a feature collection received.');
          return;
        }

        const [newEdge] = newEdgeCollection.features;

        if (!isFeature(newEdge)) {
          console.error('Wrong a feature received.');
          return;
        }

        const edgeIndex = edges.features.findIndex(edge => edge.properties?.id === edgeId);

        edges.features[edgeIndex] = newEdge;

        dispatch(geojsonActions.changeField('edges', edges));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(editorFormActions.resetField('isLoading'));
      }
    });
  };
}

export function modifyEdgeSpeedChange(id: number, values: Model<AcEdgeSpeedChange>): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    selectEditorEdgeSpeedChanges(state).forEach(
      (speedChanges) => {
        const newEdgeSpeedChange = {
          ...values,
          $edited: true,
        };

        const newEdgeSpeeds = speedChanges.map(speed => (speed.id === id ? newEdgeSpeedChange : speed));

        dispatch(entitiesEditorFormActions.changeField('editableEdgeSpeedChanges', newEdgeSpeeds));
      });
  };
}

export function updateEdgeSpeedChanges(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(getState());
    selectEditorEdgeSpeedChanges(state).forEach(
      async (edgeSpeedChanges) => {
        const edgeSpeedChangesApiConfig = {
          parentEntities: {
            [AC_PROJECTS_MODEL_NAME]: selectedProject,
          },
        };

        try {
          dispatch(editorFormActions.changeField('isLoading', true));
          await Promise.all(edgeSpeedChanges.filter(speedChange => speedChange.$edited).map(async (speed) => {
            return dispatch(edgeSpeedChangeModelActions.patch(speed.id, speed, edgeSpeedChangesApiConfig));
          }));

          dispatch(entitiesEditorFormActions.resetField('editableEdgeSpeedChanges'));
          dispatch(editorFormActions.resetField('editorMode'));
          dispatch(app.actions.toast.success(i18n.t('modules.editor.messages.saveSuccess')));
          dispatch(edgeSpeedModelActions.clearData());
          dispatch(loadEdges());
        } catch (err) {
          dispatch(app.actions.toast.error(i18n.t('modules.editor.messages.saveError')));
          console.error(err);
        } finally {
          dispatch(editorFormActions.resetField('isLoading'));
        }
      },
    );
  };
}

export function cancelEdgeSpeedChanges(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    selectEditorEdgeSpeedChanges(state).forEach(
      (edgeSpeedChanges) => {
        const hasChanges = edgeSpeedChanges.some(speedChange => speedChange.$edited);
        if (!hasChanges) {
          dispatch(enableViewMode());
          return;
        }

        dispatch(modals.actions.showConfirmModal({
          title: i18n.t('modules.editor.captions.exitWithoutSaving'),
          text: i18n.t('modules.editor.captions.changesWillNotBeSaved'),
          acceptButtonText: i18n.t('common.captions.exit'),
          declineButtonText: i18n.t('common.captions.save'),
          onAccept: () => {
            dispatch(entitiesEditorFormActions.resetField('editableEdgeSpeedChanges'));
            dispatch(enableViewMode());
          },
          onDecline: () => dispatch(updateEdgeSpeedChanges()),
        }));
      },
    );
  };
}

export function edgeCutting(edgeIds: number[], edgeId: number): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const { edges } = selectGeojsonForm(getState());
    const newEdges = await dispatch(fetchEdges(edgeIds));

    if (!isFeatureCollection(edges) || !isFeatureCollection(newEdges)) {
      console.error('Wrong a feature collection received.');
      return;
    }

    const updatedEdges = { ...edges };

    const edgeIndex = updatedEdges.features.findIndex(edge => edge.properties?.id === edgeId);
    if (edgeIndex !== -1) {
      updatedEdges.features.splice(edgeIndex, 1, ...newEdges.features);
      dispatch(geojsonActions.changeField('edges', updatedEdges));
    }
  };
}
