export default {
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    type: undefined,
    overshot: undefined,
    overshotPercent: undefined,
    routes: undefined,
    routesPercent: undefined,
    total: undefined,
    totalPercent: undefined,
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
  pagination: false,
};
