import {
  createStyles, Theme, withStyles,
} from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

const EditorPrimaryTreeItem = withStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    backgroundColor: palette.text.secondary,
    borderWidth: 0,
    borderColor: palette.common.white,
    borderStyle: 'solid',

    '&:not(:last-of-type)': {
      borderBottomWidth: 1,
    },

    '&:hover > $content': {
      backgroundColor: 'transparent',
    },

    '&:focus > $content $label, &:hover > $content $label': {
      backgroundColor: 'transparent',
    },

    '&$selected > $content $label': {
      backgroundColor: 'transparent',
    },

    '&$selected > $content $label:hover': {
      backgroundColor: 'transparent',
    },

    '&$selected:focus > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 28,
    color: palette.text.primary,
    paddingLeft: spacing(4),
    paddingRight: spacing(1),
    marginRight: 0,

    '& svg': {
      width: 12,
      height: 12,
    },
  },
  group: {
    marginLeft: 0,
  },
  label: {
    padding: spacing(1, 4, 1, 2),
    width: 'calc(100% - 32px)',
  },
  selected: {},
  content: {},
}))(TreeItem);

export default EditorPrimaryTreeItem;
