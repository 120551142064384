import { RestifyArray } from 'redux-restify';

import { ROUTES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: ROUTES_ENDPOINT,
  parent: 'shipmentsProjects',
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    correspondenceType: undefined,
    isRail: undefined,
    railPart: undefined,
    routeTrafficCargo: undefined,
    tariffHour: undefined,
    weight: undefined,
    traffic: new RestifyArray({
      defaults: {
        capacity: undefined,
        costPerKm: undefined,
        county: undefined,
        distance: undefined,
        detailed: undefined,
        edgeId: undefined,
        firstNode: undefined,
        name: undefined,
        secondNode: undefined,
        speed: undefined,
        tariff: undefined,
        tariffHour: undefined,
        time: undefined,
        timeCost: undefined,
        totalCost: undefined,
        trafficCargo: undefined,
        type: undefined,
      },
    }),
  },
  allowIdRequests: false,
  pagination: false,
};
