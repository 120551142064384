import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';

import { editorFormActions } from 'src/cluster/editor-common';
import {
  routeEditorFormSelectors,
  changeStopName,
  updateStop,
  deleteStop,
  createStop,
  enableModifyStopMode,
  enableViewMode,
  loadRoute,
} from 'src/cluster/editor-map';

import EditorStop from './EditorStop';

const mapStateToProps = (state: AppState) => ({
  isDirty: routeEditorFormSelectors.getIsDirty(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  enableModifyMode: () => {
    dispatch(enableModifyStopMode());
  },
  enableViewMode: () => {
    dispatch(enableViewMode());
  },
  changeStopName: (value: string) => {
    dispatch(changeStopName(value));
  },
  leaveStop: () => {
    dispatch(editorFormActions.resetField('stopId'));
  },
  updateStop: () => {
    dispatch(updateStop());
  },
  createStop: () => {
    dispatch(createStop());
  },
  deleteStop: () => {
    dispatch(deleteStop());
  },
  setSelectedDirection: (
    id: number,
    variantId: number,
    routeId: number,
    vehicleTypeId: number,
  ) => {
    dispatch(editorFormActions.changeField('vehicleTypeId', vehicleTypeId));
    dispatch(editorFormActions.changeField('routeId', routeId));
    dispatch(loadRoute(routeId));
    dispatch(editorFormActions.changeField('variantId', variantId));
    dispatch(editorFormActions.changeField('directionId', id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorStop);
