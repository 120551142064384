import { Nothing } from 'monet';
import { api } from 'redux-restify';

import app from 'src/app';
import i18n from 'src/i18n';
import {
  createFormActions, createModelActions, downloadFile, getFilename, isNumber,
} from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';
import { BinaryApiResponse } from 'src/interfaces';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_BALANCE_ENDPOINT,
  AC_PROJECTS_ENDPOINT,
  AC_DATA_PAGE_FORM_NAME,
  AC_DATA_MODEL_NAME,
  AC_DATA_FACTORS_MODEL_NAME,
  AC_DATA_REGION_TYPES_MODEL_NAME,
  AcBalanceDataModel,
  AcPageBalanceDataForm,
  BalanceFilterModes,
  isDataModel,
  matrixForecastEntityManager,
  selectMatrixForecastParams,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { selectAcDataPageForm } from 'src/cluster/balance-data';

import modals from 'src/modals';

export const dataFormActions = createFormActions<AcPageBalanceDataForm>(AC_DATA_PAGE_FORM_NAME);

export const dataEntityManager = createModelActions(AC_DATA_MODEL_NAME);
export const dataFactorsEntityManager = createModelActions(AC_DATA_FACTORS_MODEL_NAME);
export const dataRegionTypesEntityManager = createModelActions(AC_DATA_REGION_TYPES_MODEL_NAME);

export function createData(records: AcBalanceDataModel[]): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);

    if (!isNumber(selectedProject)) {
      return Nothing();
    }

    const dataApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    return Promise.all(records.map(async (record: AcBalanceDataModel) => {
      try {
        const { status } = await dispatch(dataEntityManager.create(record, dataApiConfig));
        if (status >= 400) {
          dispatch(app.actions.toast.error(i18n.t('modules.data.messages.createError')));
          return Promise.reject();
        }
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }));
  };
}

export function updateData(records: AcBalanceDataModel[]): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const {
      year,
      scenarioId,
      intervalId,
    } = selectAcDataPageForm(state);
    const [matrixForecastId] = selectMatrixForecastParams(state);
    const dataApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      query: {
        year,
        scenarioId,
        intervalId,
        matrixForecastId,
      },
    };

    return Promise.all(records.map(async (record: AcBalanceDataModel) => {
      try {
        const { status, data } = await dispatch(dataEntityManager.patch(record.id, record, dataApiConfig));
        if (status >= 400 || !isDataModel(data)) {
          dispatch(app.actions.toast.error(i18n.t('modules.data.messages.updateError', { id: record.id })));
          return Promise.reject();
        }
        dispatch(dataEntityManager.clearData());
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }));
  };
}

export function deleteData(ids: number[]): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);

    const dataApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    return Promise.all(ids.map(async (id: number) => {
      try {
        const { status } = await dispatch(dataEntityManager.delete(id, dataApiConfig));
        if (status >= 400) {
          dispatch(app.actions.toast.error(i18n.t('modules.data.messages.deleteError', { id })));
          return Promise.reject();
        }
        dispatch(dataEntityManager.clearData());
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }));
  };
}

export function exportData(isFull?: boolean): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const {
      regions,
      filterMode,
      regionType,
      municipalities,
      year,
      scenarioId,
      intervalId,
    } = selectAcDataPageForm(state);
    const [matrixForecastId] = selectMatrixForecastParams(state);
    if (
      !isNumber(selectedProject)
      || !isFull && (
        !isNumber(year)
        || !isNumber(scenarioId)
        || !isNumber(intervalId)
      )
    ) {
      return Promise.reject();
    }
    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${AC_BALANCE_ENDPOINT}export/`;
    const query = isFull ? { matrixForecastId } : {
      regionId: filterMode === BalanceFilterModes.node ? regions : undefined,
      municipalityId: filterMode === BalanceFilterModes.municipality ? municipalities : undefined,
      regionType,
      year,
      scenarioId,
      intervalId,
      matrixForecastId,
    };
    const config = { url, isBinary: true, query };

    try {
      dispatch(dataFormActions.changeField('isExporting', true));
      const { data, status, api: xhr }: BinaryApiResponse = await dispatch(api.actions.callGet(config));
      console.log(data);
      if (status === 200 && data !== undefined) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadFile(blob, getFilename(xhr));
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      dispatch(dataFormActions.resetField('isExporting'));
    }
  };
}

export function buildTTC(): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);

    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const values = {
      calculateFromLevel: 'TTC',
      calculateToLevel: 'TTC',
      matrix: {
        remoteJobsPercent: 0,
      },
    };

    try {
      const { status } = await dispatch(matrixForecastEntityManager.create(values, apiConfig));

      if (status >= 400) {
        dispatch(app.actions.toast.error(i18n.t('modules.forecast.messages.createError')));
        return Promise.reject();
      }
      dispatch(matrixForecastEntityManager.clearData());
      return Promise.resolve();
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('modules.forecast.messages.createError')));

      return Promise.reject(err);
    }
  };
}

export function buildForecast(fullRemote: number, calculateTtc?: boolean): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();

    const { selectedProject } = selectAcProjectListForm(state);
    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const values = {
      calculateFromLevel: calculateTtc ? 'TTC' : 'MATRIX',
      calculateToLevel: 'MATRIX',
      matrix: {
        remoteJobsPercent: fullRemote / 100,
      },
    };

    try {
      const { status } = await dispatch(matrixForecastEntityManager.create(values, apiConfig));

      if (status >= 400) {
        dispatch(app.actions.toast.error(i18n.t('modules.forecast.messages.createError')));
        return Promise.reject();
      }
      dispatch(matrixForecastEntityManager.clearData());
      return Promise.resolve();
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('modules.forecast.messages.createError')));

      return Promise.reject(err);
    }
  };
}

export function buildRPM(ttc?: boolean, matrix?: boolean, percent?: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();

    const { selectedProject } = selectAcProjectListForm(state);

    const apiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    let levelFrom = 'ROUTES_DISTRIBUTION';
    if (matrix) levelFrom = 'MATRIX';
    if (ttc) levelFrom = 'TTC';

    const values = {
      calculateFromLevel: levelFrom,
      calculateToLevel: 'ROUTES_DISTRIBUTION',
      matrix: {
        remoteJobsPercent: percent || 0,
      },
    };

    try {
      const { status } = await dispatch(matrixForecastEntityManager.create(values, apiConfig));
      if (status >= 400) {
        dispatch(app.actions.toast.error(i18n.t('modules.forecast.messages.createError')));
        return Promise.reject();
      }
      dispatch(matrixForecastEntityManager.clearData());
      dispatch(modals?.actions?.showModal(true, 'acDistributionModalDataCalc'));
      return Promise.resolve();
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('modules.forecast.messages.createError')));

      return Promise.reject(err);
    }
  };
}
