import { CORRESPONDENCES_ENDPOINT } from 'src/cluster/balance-forecast';
import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: CORRESPONDENCES_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  defaults: {
    id: undefined,
    regionFromId: undefined,
    regionFromName: undefined,
    municipalityFromId: undefined,
    municipalityToId: undefined,
    regionToId: undefined,
    regionToName: undefined,
    scenarioId: undefined,
    baseTraffic: undefined,
    traffic: undefined,
    trafficFromBase: undefined,
    trafficFromMax: undefined,
    trafficIncrease: undefined,
    trafficPass: undefined,
    trafficPassUncut: undefined,
    baseYear: undefined,
    year: undefined,
    trafficCar: undefined,
    baseTtc: undefined,
    forecastTtc: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  allowIdRequests: false,
};
