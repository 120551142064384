import React, { PureComponent } from 'react';
import classNames from 'classnames';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import style from './index.module.css';

import EnhancedSwitch, { SwitchProps } from '../../internal/EnhancedSwitch';

import { CHECK_COLORS, CHECK_VIEW_TYPES } from './constants';

interface Props extends Partial<SwitchProps> {
  value: boolean;
  disabled: boolean;
  iconType?: string;
  viewType: string;
  color: string;

  labelClassName?: string;
}

class PCheckbox extends PureComponent<Props> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    disabled: false,
    value: false,
    viewType: CHECK_VIEW_TYPES.checkbox,
    color: CHECK_COLORS.red,
  }

  public render() {
    const {
      disabled,
      value,
      iconType,
      viewType,
      color,

      labelClassName,

      ...other
    } = this.props;

    const checkboxComp = (
      <div {...{
        className: classNames(
          style.root,
          !iconType ? style.boxRoot : style.iconRoot,
          style[viewType],
          style[color],
          value && style.checked,
          disabled && style.disabled,
        ),
      }}>
        <PIcon {...{
          type: iconType || ICONS_TYPES.check,
          size: 16,
          className: iconType || value ? style.icon : style.iconHide,
        }} />
      </div>
    );

    return (
      <EnhancedSwitch {...{
        disabled,
        switched: value,
        switchedComponent: checkboxComp,
        labelClassName: classNames(
          color === CHECK_COLORS.black && !value ? style.grayLabel : '',
          labelClassName,
        ),
        ...other,
      }} />
    );
  }
}

export { CHECK_COLORS, CHECK_VIEW_TYPES };

export default PCheckbox;
