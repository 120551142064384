import { useMemo } from 'react';

import { VehicleType, useAcVehicleTypes } from 'src/cluster/common';

const useVehicleTypesMap = () => {
  const vehicleTypes = useAcVehicleTypes();
  return useMemo(() => {
    const vehicleTypesMap: Record<string, number> = {};
    vehicleTypes.map((type) => {
      if (type.name in VehicleType) {
        vehicleTypesMap[type.name] = type.id;
      }
      return undefined;
    });
    return vehicleTypesMap;
  }, [vehicleTypes]);
};

export default useVehicleTypesMap;
