import React from 'react';

const AddPointIcon = () => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 9.82617C2.795 9.82617 1 8.03117 1 5.82617C1 3.62117 2.795 1.82617 5 1.82617C7.205 1.82617 9 3.62117 9 5.82617C9 8.03117 7.205 9.82617 5 9.82617ZM5 0.826172C4.34339 0.826172 3.69321 0.955501 3.08658 1.20677C2.47995 1.45805 1.92876 1.82635 1.46447 2.29064C0.526784 3.22832 0 4.50009 0 5.82617C0 7.15225 0.526784 8.42402 1.46447 9.36171C1.92876 9.826 2.47995 10.1943 3.08658 10.4456C3.69321 10.6968 4.34339 10.8262 5 10.8262C6.32608 10.8262 7.59785 10.2994 8.53553 9.36171C9.47322 8.42402 10 7.15225 10 5.82617C10 5.16956 9.87067 4.51938 9.6194 3.91275C9.36812 3.30613 8.99983 2.75493 8.53553 2.29064C8.07124 1.82635 7.52005 1.45805 6.91342 1.20677C6.30679 0.955501 5.65661 0.826172 5 0.826172ZM5.5 3.32617H4.5V5.32617H2.5V6.32617H4.5V8.32617H5.5V6.32617H7.5V5.32617H5.5V3.32617Z" fill="currentColor" />
  </svg>

);

export default AddPointIcon;
