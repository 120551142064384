import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing, zIndex }) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: spacing(2),
      zIndex: zIndex.drawer,
    },

    tools: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      bottom: 25,
      right: 10,
    },

    button: {
      width: 32,
      height: 32,
      color: palette.text.primary,
      background: palette.common.white,
      fontSize: '1em',
      fontWeight: 400,
      border: '1px solid transparent',
      borderRadius: '0.25em',
      margin: '0.05em',
      padding: 0,
      outline: 0,
      cursor: 'pointer',

      '&:hover': {
        background: 'rgb(128, 137, 133)',
      },
    },

    activeButton: {
      color: palette.common.white,
      background: palette.primary.main,
    },

    image: {
      height: 30,
      width: 30,
    },
  });
});
