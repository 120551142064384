import React, { Fragment } from 'react';

import { WithTranslate } from 'src/i18n';

import style from './index.module.css';

export interface LegendItem {
  messageKey: string;
  icon: string;
}

const legendItems: LegendItem[] = [
  { icon: style.departurePoint, messageKey: 'modules.video.captions.departurePoint' },
  { icon: style.destinationPoint, messageKey: 'modules.video.captions.destinationPoint' },
];

const VideoMapLegend = (props: WithTranslate) => {
  const { t } = props;

  return (
    <Fragment>
      {legendItems.map((item, index) => {
        const message = t(item.messageKey);
        return (
          <div key={index} className={style.legendEntry}>
            <div className={style.legendRouteIcon}>
              <div className={item.icon} />
            </div>
            <div className={style.legendTitle}>
              {message}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default VideoMapLegend;
