import { SelectMenuOption } from 'src/interfaces';
import { useSelector } from 'react-redux';
import { selectAcIntervals } from 'src/cluster/common';

const useAcIntervalOptions = (): SelectMenuOption[] => {
  const intervals = useSelector(selectAcIntervals);

  return intervals.map((value) => value.map((interval) => ({
    id: `${interval.id}`,
    value: interval.id,
    label: interval.intervalName,
  }))).orJust([]);
};

export default useAcIntervalOptions;
