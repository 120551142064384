import { useSelector } from 'react-redux';

import { LegendType, VehicleType } from '../../types';
import { DrawerLegendToggle } from '../../interfaces/common';
import { selectEditorLegendForm } from '../../store';

const useLegendNetworks = (
  setLegendCheckbox: (key: LegendType, checked: boolean) => void,
  vehicleTypesNames: VehicleType[],
): DrawerLegendToggle[] => {
  const {
    isBusNetwork,
    isTramwayNetwork,
    isTrolleybusNetwork,
    isSuburbanNetwork,
    isWaterNetwork,
  } = useSelector(selectEditorLegendForm);

  return [
    {
      type: LegendType.isBusNetwork,
      icon: VehicleType.bus,
      checked: isBusNetwork,
      onClick: () => setLegendCheckbox(LegendType.isBusNetwork, !isBusNetwork),
    },
    {
      type: LegendType.isTramwayNetwork,
      icon: VehicleType.tramway,
      checked: isTramwayNetwork,
      onClick: () => setLegendCheckbox(LegendType.isTramwayNetwork, !isTramwayNetwork),
    },
    {
      type: LegendType.isTrolleybusNetwork,
      icon: VehicleType.trolleybus,
      checked: isTrolleybusNetwork,
      onClick: () => setLegendCheckbox(LegendType.isTrolleybusNetwork, !isTrolleybusNetwork),
    },
    {
      type: LegendType.isSuburbanNetwork,
      icon: VehicleType.suburban,
      checked: isSuburbanNetwork,
      onClick: () => setLegendCheckbox(LegendType.isSuburbanNetwork, !isSuburbanNetwork),
    },
    {
      type: LegendType.isWaterNetwork,
      icon: VehicleType.water,
      checked: isWaterNetwork,
      onClick: () => setLegendCheckbox(LegendType.isWaterNetwork, !isWaterNetwork),
    },
  ].filter(item => vehicleTypesNames.includes(item.icon));
};

export default useLegendNetworks;
