import React, { useMemo } from 'react';

import { WithTranslate } from 'src/i18n';
import palette from 'src/theme/palette';

import {
  BalanceLegendItem, FILL_COLORS, ScaleDivision,
} from 'src/cluster/common';
import {
  AcBalanceMapPageForm, MapModes, useFactorScale, useTrafficScales, ViewModes,
} from 'src/cluster/balance-map';

import useStyles from './styles';

interface OwnProps {
  pageForm: AcBalanceMapPageForm;
}

type Props = OwnProps & WithTranslate;

const BalanceMapLegend = (props: Props) => {
  const { pageForm: { viewMode, mapMode }, t } = props;

  const classes = useStyles();

  // eslint-disable-next-line no-nested-ternary
  const title = viewMode === ViewModes.data
    ? t('systems.balance.legend.factorValue')
    : mapMode === MapModes.group
      ? t('systems.balance.legend.districts')
      : t('systems.balance.legend.flowValue');

  const factorScale = useFactorScale();
  const [/* values */, scale] = useTrafficScales();

  const items = useMemo((): BalanceLegendItem[] => {
    if (viewMode === ViewModes.data) {
      return factorScale.map((item: ScaleDivision<string>, index, array) => {
        const lastIndex = array.length - 1;
        return {
          style: {
            backgroundColor: FILL_COLORS[lastIndex - index],
            border: `1px solid ${FILL_COLORS[lastIndex]}`,
            borderRadius: 3,
          },
          message: item.to === Infinity ? `> ${item.from}` : `${item.from} - ${item.to}`,
        };
      });
    }
    return mapMode === MapModes.group ? [
      { style: { backgroundColor: '#e7d88b' }, message: t('systems.balance.legend.departure') },
      { style: { backgroundColor: palette.error.light }, message: t('systems.balance.legend.arrival') },
    ] : scale.map((item: ScaleDivision<number>, index, array) => {
      const lastIndex = array.length - 1;
      return {
        style: mapMode === MapModes.heat
          ? {
            backgroundColor: FILL_COLORS[lastIndex - index],
            border: `1px solid ${FILL_COLORS[lastIndex]}`,
            borderRadius: 3,
          } : {
            height: 10 * item.value,
            backgroundColor: '#843ef3',
          },
        message: item.to === Infinity ? `> ${item.from}` : `${item.from} - ${item.to}`,
      };
    });
  }, [viewMode, factorScale, scale]);

  return (
    <div>
      <div className={classes.title}>{title}</div>
      {items.map((item, index) => (
        <div key={index} className={classes.entry}>
          <div className={classes.iconWrapper}>
            <div className={classes.icon} style={item.style} />
          </div>
          <div className={classes.message}>
            {item.message}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BalanceMapLegend;
