import React, { ChangeEvent, FC, Fragment } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import {
  Button, CopyIcon, PenIcon, TextField, TrashIcon,
} from 'src/components';

import { VehicleIcon, VehicleType } from 'src/cluster/common';
import { EditorTreeCaption, EditorErrorMessage } from 'src/cluster/editor-map';

import useStyles from './styles';

interface OwnProps {
  isLoading: boolean;
  isModifying: boolean;
  id: number;
  routeNumber: string;
  vehicleTypeName?: VehicleType;
  hasError: boolean;
  copyRoute(): void;
  deleteRoute(id: number, num: string): void;
  enableModifyMode(): void;
  changeRouteNumber(value: string): void;
}

type Props = OwnProps & WithTranslate;

const RouteHeader: FC<Props> = (props) => {
  const {
    isLoading,
    isModifying,
    id,
    routeNumber,
    vehicleTypeName,
    hasError,
    t,
    copyRoute,
    deleteRoute,
    enableModifyMode,
    changeRouteNumber,
  } = props;

  const classes = useStyles({ vehicleTypeName });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    changeRouteNumber(String(event.target.value));
  };

  const handleDeleteClick = () => {
    deleteRoute(id, routeNumber);
  };

  return (
    <div className={clsx(classes.root, isModifying && classes.editing, hasError && classes.withError)}>
      <span className={classes.icon}>
        <VehicleIcon type={vehicleTypeName} />
      </span>
      {isModifying ? (
        <TextField
          key={id}
          id={`route-number-${id}`}
          aria-describedby={hasError ? `route-number-error-${routeNumber}` : undefined}
          readOnly={isLoading}
          value={routeNumber}
          className={clsx(classes.input, classes.vehicle)}
          error={hasError}
          onChange={handleChange}
        />
      ) : (
        <Typography component="span" className={clsx(classes.title, classes.vehicle)}>
          {routeNumber}
        </Typography>
      )}
      <EditorTreeCaption label={t('systems.agglomeration.captions.route')} />
      {isModifying ? (
        <Fragment>
          <Button
            variant="icon"
            className={classes.button}
            disabled={id < 1}
            title={t('common.captions.copy')}
            onClick={copyRoute}
          >
            <CopyIcon />
          </Button>
          <Button
            variant="icon"
            className={classes.button}
            disabled={id < 1}
            title={t('common.captions.delete')}
            onClick={handleDeleteClick}
          >
            <TrashIcon />
          </Button>
        </Fragment>
      ) : (
        <Button
          variant="icon"
          className={classes.button}
          title={t('common.captions.edit')}
          onClick={enableModifyMode}
        >
          <PenIcon />
        </Button>
      )}
      {hasError && (
        <div className={classes.error}>
          <EditorErrorMessage
            id={`route-number-error-${routeNumber}`}
            text={t('modules.editor.messages.emptyRouteNumberError')}
          />
        </div>
      )}
    </div>
  );
};

export default RouteHeader;
