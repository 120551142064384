import { StrictDict } from 'src/types';
import {
  VehicleClass, VehicleType, MapStyle, MapStyleID, LegendType,
} from 'src/cluster/common';
import monochromeMapStyle from 'src/helpers/monochrome-map-style';
import streetsMapStyle from 'src/helpers/streets-map-style';
import satelliteMapStyle from 'src/helpers/satellite-map-style';

export const VEHICLE_CLASSES_DICT: StrictDict<VehicleClass, string> = {
  [VehicleClass.verySmall]: 'common.vehicleClasses.verySmall',
  [VehicleClass.small]: 'common.vehicleClasses.small',
  [VehicleClass.middle]: 'common.vehicleClasses.middle',
  [VehicleClass.big]: 'common.vehicleClasses.big',
  [VehicleClass.veryBig]: 'common.vehicleClasses.veryBig',
};

export const VEHICLE_TYPES_DICT: StrictDict<VehicleType, string> = {
  [VehicleType.bus]: 'common.vehicleTypes.bus',
  [VehicleType.auto]: 'common.vehicleTypes.auto',
  [VehicleType.trolleybus]: 'common.vehicleTypes.trolleybus',
  [VehicleType.tramway]: 'common.vehicleTypes.tramway',
  [VehicleType.metro]: 'common.vehicleTypes.metro',
  [VehicleType.pedestrian]: 'common.vehicleTypes.pedestrian',
  [VehicleType.suburban]: 'common.vehicleTypes.suburban',
  [VehicleType.water]: 'common.vehicleTypes.water',
  [VehicleType.ropeway]: 'common.vehicleTypes.ropeway',
  [VehicleType.funicular]: 'common.vehicleTypes.funicular',
  [VehicleType.monorail]: 'common.vehicleTypes.monorail',
  [VehicleType.all]: 'common.vehicleTypes.all',
};

export const LEGEND_TYPES_DICT: StrictDict<LegendType, string> = {
  [LegendType.isSrnLine]: 'modules.editor.drawer.srn',
  [LegendType.isSuburbanLine]: 'modules.editor.drawer.suburban',
  [LegendType.isWaterLine]: 'modules.editor.drawer.water',
  [LegendType.isMetroLine]: 'modules.editor.drawer.metro',
  [LegendType.isTramwayLine]: 'modules.editor.drawer.tramway',
  [LegendType.isTrolleybusLine]: 'modules.editor.drawer.trolleybus',
  [LegendType.isTracklessStop]: 'modules.editor.drawer.trackless',
  [LegendType.isSuburbanStop]: 'modules.editor.drawer.suburban',
  [LegendType.isWaterStop]: 'modules.editor.drawer.waterCarriage',
  [LegendType.isMetroStop]: 'modules.editor.drawer.metro',
  [LegendType.isTramwayStop]: 'modules.editor.drawer.tramway',
  [LegendType.isBusNetwork]: 'modules.editor.drawer.bus',
  [LegendType.isTramwayNetwork]: 'modules.editor.drawer.tramway',
  [LegendType.isTrolleybusNetwork]: 'modules.editor.drawer.trolleybus',
  [LegendType.isSuburbanNetwork]: 'modules.editor.drawer.suburban',
  [LegendType.isWaterNetwork]: 'modules.editor.drawer.waterCarriage',
  [LegendType.regularTransportationType]: 'modules.editor.drawer.regularTransportationType',
  [LegendType.communicationType]: 'modules.editor.drawer.communicationType',
  [LegendType.isPaidParking]: 'modules.editor.drawer.paidParking',
  [LegendType.isBusAndTrolleybus]: 'modules.editor.drawer.busAndTrolleybus',
  [LegendType.isTram]: 'modules.editor.drawer.tram',
  [LegendType.isEdgesOfTransportAreas]: 'modules.editor.drawer.edgesOfTransportAreas',
  [LegendType.isDistrictCentroids]: 'modules.editor.drawer.districtCentroids',
  [LegendType.isAverageTravelSpeedCar]: 'modules.editor.drawer.averageTravelSpeedCar',
};

export const MAP_STYLE_DICT: StrictDict<MapStyle, {
  id: MapStyleID;
  getStyle: (locale: string) => {};
  src: string;
  title: string;
}> = {
  [MapStyle.mono]: {
    id: 'mono',
    getStyle: monochromeMapStyle,
    src: '/static/images/mono.png',
    title: 'Monochrome Light',
  },
  [MapStyle.streets]: {
    id: 'streets',
    getStyle: streetsMapStyle,
    src: '/static/images/streets.png',
    title: 'Streets',
  },
  [MapStyle.satellite]: {
    id: 'satellite',
    getStyle: satelliteMapStyle,
    src: '/static/images/satellite.png',
    title: 'Satellite',
  },
};
