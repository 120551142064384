import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  createFormSelector,
  isArray,
  isNumber,
} from 'src/helpers';

import {
  AC_ROUTES_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_REGISTRY_PAGE_FORM_NAME,
  AC_REGISTRY_FILTERS_NAME,
  AC_REGISTRY_ROUTES_MODEL_NAME,
  AC_REGISTRY_CARRIERS_MODEL_NAME,
  AC_REGISTRY_SEASONS_MODEL_NAME,
  AC_REGISTRY_VEHICLE_CLASSES_MODEL_NAME,
  AcRoutesModel,
  DefaultLibraryModel,
  selectAcProjectListForm,
  isAcRoutesModel,
} from 'src/cluster/common';
import { selectEditorGraphForm } from 'src/cluster/editor-common';
import {
  AcRegistryPageForm,
  AcRegistryFiltersForm,
  AcRegRoutesResultsModel,
  AcRegCarriersModel,
  AcRegSeasonsModel,
  AcRegVehicleClassModel,
  isAcRegRoutesResultsModel,
  isAcRegCarriersModel,
  isAcRegSeasonsModel,
  isAcRegVehicleClassesModel,
} from 'src/cluster/editor-table';

export const selectAcRegistryPageForm = createFormSelector<AcRegistryPageForm>(AC_REGISTRY_PAGE_FORM_NAME);
export const selectRegistryFilters = createFormSelector<AcRegistryFiltersForm>(AC_REGISTRY_FILTERS_NAME);

export const selectRouteEntities = createEntitySelector<AcRoutesModel>(AC_ROUTES_MODEL_NAME);
export const selectRoutesRegEntity = createEntitySelector<AcRegRoutesResultsModel>(AC_REGISTRY_ROUTES_MODEL_NAME);
export const selectCarriersRegEntity = createEntitySelector<AcRegCarriersModel>(AC_REGISTRY_CARRIERS_MODEL_NAME);
export const selectSeasonsRegEntity = createEntitySelector<AcRegSeasonsModel>(AC_REGISTRY_SEASONS_MODEL_NAME);
export const selectVehicleClassesRegEntity =
  createEntitySelector<AcRegVehicleClassModel>(AC_REGISTRY_VEHICLE_CLASSES_MODEL_NAME);

export const selectRegistryRoutes = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectAcRegistryPageForm,
    selectRegistryFilters,
    selectRoutesRegEntity,
  ],
  (
    pageProjectsForm,
    graphForm,
    pageRegistryForm,
    registryFilters,
    registryEntities,
  ): Maybe<Model<AcRegRoutesResultsModel>[]> => {
    const { selectedProject } = pageProjectsForm;
    const { year, scenarioId } = graphForm;
    if (!isNumber(selectedProject) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const { pageSize, currentPage } = pageRegistryForm;
    const { mainFilters } = registryFilters;
    const filters = mainFilters ? { ...mainFilters } : {};

    const regRoutesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        ...filters,
      },
    };

    const routes = registryEntities.getArray(regRoutesApiConfig);

    if (!isArray(routes)) {
      return Nothing();
    }

    if (routes.length > 0 && !isAcRegRoutesResultsModel(routes[0])) {
      return Nothing();
    }

    return Just(routes);
  },
);

export const selectRegistryRoutesCount = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectAcRegistryPageForm,
    selectRegistryFilters,
    selectRoutesRegEntity,
  ],
  (
    pageProjectsForm,
    graphForm,
    pageRegistryForm,
    registryFilters,
    registryEntities,
  ): number => {
    const { selectedProject } = pageProjectsForm;
    const { year, scenarioId } = graphForm;
    if (!isNumber(selectedProject) || !isNumber(year) || !isNumber(scenarioId)) {
      return 0;
    }

    const { pageSize, currentPage } = pageRegistryForm;
    const { mainFilters } = registryFilters;
    const filters = mainFilters ? { ...mainFilters } : {};

    const regRoutesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        ...filters,
      },
    };

    return registryEntities.getCount(regRoutesApiConfig);
  },
);

/** TODO change to real data */
export const selectRegistries = createSelector(
  [],
  (): Maybe<Model<DefaultLibraryModel>[]> => {
    const registries: Model<DefaultLibraryModel>[] = [{
      $loading: false,
      $error: false,
      $old: false,
      $modelType: 'registries',
      id: 1,
      name: 'Маршруты',
    }];

    if (!isArray(registries)) {
      return Nothing();
    }

    return Just(registries);
  },
);

export const selectRegCarriers = createSelector(
  [
    selectAcProjectListForm,
    selectCarriersRegEntity,
  ],
  (projectForm, carriersEntities): Maybe<Model<AcRegCarriersModel>[]> => {
    const { selectedProject } = projectForm;

    if (!isNumber(selectedProject)) return Nothing();

    const carriersApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const carriers = carriersEntities.getArray(carriersApiConfig);
    if (!isArray(carriers)) {
      return Nothing();
    }

    if (!isAcRegCarriersModel(carriers[0])) {
      return Nothing();
    }

    return Just(carriers);
  },
);

export const selectRegSeasons = createSelector(
  [
    selectAcProjectListForm,
    selectSeasonsRegEntity,
  ],
  (projectForm, seasonsEntities): Maybe<Model<AcRegSeasonsModel>[]> => {
    const { selectedProject } = projectForm;

    if (!isNumber(selectedProject)) return Nothing();

    const seasonsApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const seasons = seasonsEntities.getArray(seasonsApiConfig);
    if (!isArray(seasons)) {
      return Nothing();
    }

    if (!isAcRegSeasonsModel(seasons[0])) {
      return Nothing();
    }

    return Just(seasons);
  },
);

export const selectRegVehicleClasses = createSelector(
  [
    selectAcProjectListForm,
    selectVehicleClassesRegEntity,
  ],
  (projectForm, vehicleClassesEntities): Maybe<Model<AcRegVehicleClassModel>[]> => {
    const { selectedProject } = projectForm;

    if (!isNumber(selectedProject)) return Nothing();

    const vehicleClassesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
    };

    const vehicleClasses = vehicleClassesEntities.getArray(vehicleClassesApiConfig);
    if (!isArray(vehicleClasses)) {
      return Nothing();
    }

    if (!isAcRegVehicleClassesModel(vehicleClasses[0])) {
      return Nothing();
    }

    return Just(vehicleClasses);
  },
);

export const selectRegRoutesNumbers = createSelector(
  [
    selectAcProjectListForm,
    selectEditorGraphForm,
    selectRegistryFilters,
    selectRouteEntities,
  ],
  (
    projectForm,
    graphForm,
    registryFilters,
    routesEntities,
  ): Maybe<Model<AcRoutesModel>[]> => {
    const { selectedProject } = projectForm;
    const { year, scenarioId } = graphForm;
    const { mainFilters } = registryFilters;
    const { vehicleTypeId } = mainFilters || {};
    if (!isNumber(selectedProject) || !isNumber(vehicleTypeId) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        vehicleTypeId,
        year,
        scenarioId,
      },
    };

    const routes = routesEntities.getArray(routeApiConfig);
    const isLoading = routesEntities.getIsLoadingArray(routeApiConfig);

    if (isLoading || !isArray(routes)) {
      return Nothing();
    }

    if (routes.length > 0 && !isAcRoutesModel(routes[0])) {
      return Nothing();
    }

    return Just(routes);
  },
);
