import React from 'react';

const MonorailIcon = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4742 16.7212V17.1143H2.99023V15.4487H4.65714L5.09673 12.5171H8.37706L8.81665 15.4487H10.4836V16.7212H10.4742Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.86842C0 0.921053 3.36842 0.5 6.73684 0.5C10.4589 0.5 13.4737 0.921053 13.4737 3.86842V11.8684C13.4737 12.6501 13.1632 13.3998 12.6104 13.9525C12.0577 14.5053 11.308 14.8158 10.5263 14.8158H9.02974L8.6316 12.1606H4.84243L4.44429 14.8158H2.94737C2.16568 14.8158 1.416 14.5053 0.863264 13.9525C0.310526 13.3998 0 12.6501 0 11.8684V3.86842ZM2.94724 13.1316C2.61223 13.1316 2.29094 12.9985 2.05405 12.7616C1.81716 12.5247 1.68408 12.2034 1.68408 11.8684C1.68408 11.5334 1.81716 11.2121 2.05405 10.9752C2.29094 10.7383 2.61223 10.6053 2.94724 10.6052C3.28225 10.6053 3.60354 10.7383 3.84043 10.9752C4.07732 11.2121 4.2104 11.5334 4.2104 11.8684C4.2104 12.2034 4.07732 12.5247 3.84043 12.7616C3.60354 12.9985 3.28225 13.1316 2.94724 13.1316ZM5.89461 7.23683H1.68408V3.86841H5.89461V7.23683ZM7.57882 7.23683V3.86841H11.7893V7.23683H7.57882ZM10.5262 13.1316C10.1912 13.1316 9.86989 12.9985 9.633 12.7616C9.39611 12.5247 9.26303 12.2034 9.26303 11.8684C9.26303 11.5334 9.39611 11.2121 9.633 10.9752C9.86989 10.7383 10.1912 10.6053 10.5262 10.6052C10.8612 10.6053 11.1825 10.7383 11.4194 10.9752C11.6563 11.2121 11.7893 11.5334 11.7893 11.8684C11.7893 12.2034 11.6563 12.5247 11.4194 12.7616C11.1825 12.9985 10.8612 13.1316 10.5262 13.1316Z"
      fill="currentColor"
    />
  </svg>

);

export default MonorailIcon;
