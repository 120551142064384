import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing, palette }: Theme) => ({

  tooltipAnchor: {
    cursor: 'pointer',
    padding: spacing(0, 4),
    margin: spacing(0, -4),
  },

  increaseTitle: {
    cursor: 'pointer',
    '&:hover $questionMark': {
      color: palette.primary.main,
    },
  },
  increaseBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  questionMark: {
    color: palette.grey[400],
    paddingRight: spacing(1),
    marginTop: spacing(0.5),
    marginLeft: spacing(-4),
  },

  titleUnit: {
    color: '#333B46',
    marginTop: 6,
    display: 'block',
    fontWeight: 500,
    cursor: 'pointer',
    padding: spacing(0, 4),
    margin: spacing(0, -4),
    textTransform: 'uppercase',
    fontSize: 10,
  },

  spaceWrap: {
    display: 'inline-block',
    width: 20,
    textAlign: 'center',
  },

  withUnit: {
    display: 'block',
  },

  headerCell: {
    whiteSpace: 'nowrap',
  },

  numberColumn: {
    textAlign: 'right',
  },

}));
