import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  row: {
    '&:hover': {
      '& $actionContainer': {
        display: 'flex',
      },
    },
  },

  collapseCell: {
    padding: 0,
    margin: 0,
    borderBottom: 0,
  },

  cellWithArrow: {
    '&:first-of-type': {
      position: 'relative',
      paddingLeft: theme.spacing(11),
    },
  },

  cellWithActions: {
    position: 'relative',
  },

  actionContainer: {
    display: 'none',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(0, 5),
  },
}));
