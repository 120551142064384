import React, { FC, ChangeEvent } from 'react';

import { WithTranslate } from 'src/i18n';
import {
  Button, GearIcon, TextField, Toolbar, ToolbarBlock, ToolbarControls, ToolbarControl,
} from 'src/components';

import { AcDashboardTablePageForm } from 'src/cluster/common';
import { DashGraphSettings } from 'src/cluster/dashboard-table';

import useStyles from './styles';

interface OwnProps {
  pageForm: AcDashboardTablePageForm;
  setSearchKey(event: ChangeEvent<{ value: unknown }>): void;
  showCustomizeModal(): void;
}

type Props = OwnProps & WithTranslate;

const DashTableToolbar: FC<Props> = (props) => {
  const {
    pageForm: { search },
    t,
    setSearchKey,
    showCustomizeModal,
  } = props;
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <ToolbarBlock size="large">
        <ToolbarControls>
          <ToolbarControl>
            <DashGraphSettings canShowModal />
          </ToolbarControl>
          <ToolbarControl>
            <TextField
              fullWidth
              placeholder={t('common.captions.search')}
              value={search}
              onChange={setSearchKey}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock size="small">
        <ToolbarControls align="right">
          <Button
            startIcon={<GearIcon />}
            variant="text"
            onClick={showCustomizeModal}
          >
            <span className={classes.customize}>
              {t('systems.dashboard.captions.customize')}
            </span>
          </Button>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default DashTableToolbar;
