import { createStyles, makeStyles } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';
import { VehicleType, LEGEND_VEHICLE_TYPES_COLORS } from 'src/cluster/common';

export default makeStyles(({ palette, shadows, spacing }) => {
  return createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      width: '100%',
      backgroundColor: palette.common.white,
      padding: spacing(2, 4),
      zIndex: 2,
      boxShadow: shadows[1],

      '&> *': {
        lineHeight: '16px',
      },

      '&$editing': {
        padding: spacing(1, 4),
      },

      '&$withError': {
        paddingBottom: spacing(4),
      },

      '&:hover $button': {
        opacity: 1,
      },
    },

    editing: {},

    withError: {},

    error: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },

    input: {
      width: 85,
      fontWeight: 'bold',
      marginRight: 8,
    },

    icon: {
      marginRight: spacing(2),
    },

    title: {
      fontWeight: 'bold',
      paddingRight: spacing(1),
    },

    vehicle: ({ vehicleTypeName }: { vehicleTypeName?: VehicleType }) => ({
      color: vehicleTypeName ? LEGEND_VEHICLE_TYPES_COLORS[vehicleTypeName].color : palette.grey[400],
    }),

    button: {
      fontFamily: ffSourceSansPro,
      fontSize: 10,
      marginLeft: spacing(2),
      lineHeight: 1,
      opacity: 0,

      '&:first-of-type ': {
        marginLeft: 'auto',
      },
    },
  });
});
