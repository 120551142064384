import React, { ChangeEvent, FC, useEffect } from 'react';
import { CircularProgress, Link } from '@material-ui/core';
import { Maybe } from 'monet';

import { WithTranslate } from 'src/i18n';
import { TextField } from 'src/components';

import { AcCopyRequestModel } from 'src/cluster/common';
import {
  AcProjectCreateForm,
  ProjectFileList,
  ProjectCreatorActions,
  FileRecord,
  FileType,
  FILE_TYPES,
  ProjectCreatorDND,
  useCopyRequestStatusUpdate,
} from 'src/cluster/projects';

import useStyles from './styles';

const instructionHref = '/static/docs/instruktsiia_k_formatam_failov.docx';
const tmplHref = '/static/docs/project_files_sample.zip';

type OwnProps = {
  form: AcProjectCreateForm;
  copyRequests: Maybe<AcCopyRequestModel[]>;
  isUploading: boolean;
  onInit: () => void;
  setName: (name: string) => void;
  setFiles: (files: FileRecord[]) => void;
  startCreatingProject: () => void;
  reloadCopyRequest(project: AcCopyRequestModel): void;
}

type Props = OwnProps & WithTranslate;

const ProjectCreator: FC<Props> = (props) => {
  const {
    form: { name = '', files },
    copyRequests,
    isUploading,
    t,
    onInit,
    setName,
    setFiles,
    startCreatingProject,
    reloadCopyRequest,
  } = props;

  useEffect(onInit, []);

  useCopyRequestStatusUpdate(copyRequests, reloadCopyRequest);

  const classes = useStyles();

  const createFileChangeHandler = (type?: FileType) => (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }
    const newFiles = Array.from(fileList)
      .map((file) => ({
        file,
        name: file.name,
        type,
      }))
      .filter((file) => !files.find(f => f.name === file.name));
    setFiles(files.concat(newFiles));
  };

  const handleTypeChange = (filename: string, type: FileType) => {
    const modifiedFiles = files.map(file => (file.name === filename ? { ...file, type } : file));
    setFiles(modifiedFiles);
  };

  const deleteFile = (fileName: string) => {
    const modifiedFiles = files.filter(file => file.name !== fileName);
    setFiles(modifiedFiles);
  };

  const typedFiles = files.filter(file => file.type);
  const untypedFiles = files.filter(file => !file.type);

  const mergedTypedList = FILE_TYPES.flatMap((type) => {
    if (type === FileType.graph) {
      const graphFiles = typedFiles.filter(file => file.type === type);
      return graphFiles.length > 0 ? graphFiles : { type } as FileRecord;
    }
    const typedFile = typedFiles.find(file => file.type === type);
    return typedFile || { type } as FileRecord;
  });

  const commonList = untypedFiles.concat(mergedTypedList);

  const isProjectFull = commonList.every(item => !!item.file);

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(String(event.target.value));
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.textField}>
          <TextField
            placeholder={t('modules.projects.captions.projectName')}
            disabled={isUploading}
            fullWidth
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div>
          <Link
            className={classes.textLink}
            color="inherit"
            href={instructionHref}
          >
            {t('modules.projects.captions.fileFormatInstructions')}
          </Link>
          <Link
            className={classes.textLink}
            color="inherit"
            href={tmplHref}
          >
            {t('modules.projects.captions.downloadAllFileTemplates')}
          </Link>
        </div>
        <ProjectCreatorDND
          isUploading={isUploading}
          setFiles={setFiles}
          createFileChangeHandler={createFileChangeHandler}
        />
        {copyRequests.orJust([]).length > 0 && (
          <div className={classes.copying}>
            <CircularProgress thickness={7} size={20} />
            {t('modules.projects.captions.copyingInProgress')}
          </div>
        )}
      </div>
      <div>
        <ProjectFileList
          files={commonList}
          isUploading={isUploading}
          createFileChangeHandler={createFileChangeHandler}
          setType={handleTypeChange}
          deleteFile={deleteFile}
        />
        <ProjectCreatorActions
          isUploading={isUploading}
          name={name}
          count={files.length}
          isFull={isProjectFull}
          createProject={startCreatingProject}
        />
      </div>
    </div>
  );
};

export default ProjectCreator;
