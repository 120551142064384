import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    zIndex: 10,
  },

  select: {
    width: 250,
  },

  tightSelect: {
    width: 200,
  },

  multiSelect: {
    width: 200,
  },

  buttonControls: {
    marginBottom: 0,
  },

  link: {
    width: '100% !important',
    alignSelf: 'flex-end !important',
    fontSize: '12px !important',
    border: '1px solid transparent !important',
    borderRadius: '5px !important',
  },

  button: {
    background: `${theme.palette.common.white}!important`,
    borderRadius: '5px !important',

  },

  iconButton: {
    textAlign: 'center',
    height: 26,
    width: 32,
    minWidth: 'auto',
    lineHeight: '100%',
    padding: 5,
  },

  iconButtonWhite: {
    textAlign: 'center',
    height: 26,
    width: 32,
    minWidth: 'auto',
    lineHeight: '100%',
    padding: 5,
    backgroundColor: theme.palette.common.white,
  },

  iconButtonRed: {
    color: theme.palette.primary.main,
  },

  exportCaption: {
    display: 'inline-block',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    fontSize: 12,
    marginLeft: 10,
    color: theme.palette.text.primary,
  },

  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    backgroundColor: 'transparent',
    marginRight: 15,
    cursor: 'pointer',
  },

  toolbarControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  '@media only screen and (min-width: 1520px)': {
    multiSelect: {
      width: 300,
    },
  },

  '@media only screen and (min-width: 1720px)': {
    multiSelect: {
      width: 400,
    },
  },
}));
