import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { login } from 'src/auth/actions';
import { AppState } from 'src/reducer';
import { withTranslate } from 'src/i18n';

import AuthPage from './AuthPage';

const mapStateToProps = (state: AppState, props: any) => ({
  ...props,
  authInfoForm: forms.selectors.authInfoForm.getForm(state),
  form: forms.selectors.authForm.getForm(state),
  formErrors: forms.selectors.authForm.getErrors(state),
  formIsValid: forms.selectors.authForm.getIsValid(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  login: (nextUrl: string) => dispatch(login(nextUrl)),
  changeField: bindActionCreators(forms.actions.authForm, dispatch).changeField,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(AuthPage));
