import React, {
  Dispatch, Fragment, SetStateAction, useMemo,
} from 'react';
import classNames from 'classnames';
import min from 'lodash/min';
import max from 'lodash/max';
import times from 'lodash/times';

import PTable from 'src/components/deprecated/PTable';
import ChartToggle from 'src/components/etc/ChartToggle';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import { ForecastTableRecord } from 'src/modules/matrix/forecast';
import Tooltip from 'src/components/etc/Tooltip';

import tooltipStyle from 'src/styles/tooltip.module.css';
import NumberFormat from 'src/components/etc/NumberFormat';
import { useCurrentVocabulary, WithTranslate } from 'src/i18n';
import style from './index.module.css';

const colors: { [key: string]: string } = {
  airTransport: '#0066fe',
  busTransport: '#c46b00',
  motorTransport: '#9b273f',
  speedMotorTransport: '#c30808',
  railSuburbanTransport: '#00adc4',
  railCoupeTransport: '#9b198c',
  railHighSpeedTransport: '#17cb82',
  railPlatzTransport: '#dec90f',
  railSpeedTransport: '#069b1e',
  allTransport: '#555357',
};

interface ForecastTableProps {
  buttons?: any;
  data: ForecastTableRecord[];
  isLoading: boolean;
  showAirTransport: boolean;
  showBusTransport: boolean;
  showMotorTransport: boolean;
  showRailCoupeTransport: boolean;
  showRailHighSpeedTransport: boolean;
  showRailPlatzTransport: boolean;
  showRailSpeedTransport: boolean;
  showRailSuburbanTransport: boolean;
  showSpeedMotorTransport: boolean;
  setShowAirTransport: Dispatch<SetStateAction<boolean>>;
  setShowBusTransport: Dispatch<SetStateAction<boolean>>;
  setShowMotorTransport: Dispatch<SetStateAction<boolean>>;
  setShowRailCoupeTransport: Dispatch<SetStateAction<boolean>>;
  setShowRailHighSpeedTransport: Dispatch<SetStateAction<boolean>>;
  setShowRailPlatzTransport: Dispatch<SetStateAction<boolean>>;
  setShowRailSpeedTransport: Dispatch<SetStateAction<boolean>>;
  setShowRailSuburbanTransport: Dispatch<SetStateAction<boolean>>;
  setShowSpeedMotorTransport: Dispatch<SetStateAction<boolean>>;
}

type Props = ForecastTableProps & WithTranslate & {
  className?: string;
}

const ForecastTable: React.FC<Props> = (props: Props) => {
  const {
    className,
    data,
    isLoading,
    showAirTransport,
    showBusTransport,
    showMotorTransport,
    showRailCoupeTransport,
    showRailHighSpeedTransport,
    showRailPlatzTransport,
    showRailSpeedTransport,
    showRailSuburbanTransport,
    showSpeedMotorTransport,
    setShowAirTransport,
    setShowBusTransport,
    setShowMotorTransport,
    setShowRailCoupeTransport,
    setShowRailHighSpeedTransport,
    setShowRailPlatzTransport,
    setShowRailSpeedTransport,
    setShowRailSuburbanTransport,
    setShowSpeedMotorTransport,
    t,
    Trans,
  } = props;

  const [currentLocale] = useCurrentVocabulary();

  const isOn: { [key: string]: boolean } = {
    airTransport: showAirTransport,
    busTransport: showBusTransport,
    motorTransport: showMotorTransport,
    speedMotorTransport: showSpeedMotorTransport,
    railSuburbanTransport: showRailSuburbanTransport,
    railPlatzTransport: showRailPlatzTransport,
    railCoupeTransport: showRailCoupeTransport,
    railHighSpeedTransport: showRailHighSpeedTransport,
    railSpeedTransport: showRailSpeedTransport,
  };

  const handlers: { [key: string]: Dispatch<SetStateAction<boolean>> } = {
    airTransport: setShowAirTransport,
    busTransport: setShowBusTransport,
    motorTransport: setShowMotorTransport,
    speedMotorTransport: setShowSpeedMotorTransport,
    railSuburbanTransport: setShowRailSuburbanTransport,
    railPlatzTransport: setShowRailPlatzTransport,
    railCoupeTransport: setShowRailCoupeTransport,
    railHighSpeedTransport: setShowRailHighSpeedTransport,
    railSpeedTransport: setShowRailSpeedTransport,
  };

  const [startYear, endYear] = useMemo(() => {
    const years = Object.keys(data[0] || {})
      .map((key: string) => Number.parseInt(key, 10))
      .filter((year: number) => year > 0);
    return [min(years) || 0, max(years) || 0];
  }, [data]);

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading,
      className: classNames(style.root, className),
    }}>
      <PTable<ForecastTableRecord> {...{
        className: style.table,
        hoverable: true,
        header: [
          {
            title: (
              <Fragment>
                <Tooltip align="left" className={style.tooltipAnchor}>
                  <span className={tooltipStyle.tooltipAnchor}>?</span>
                  <span className={tooltipStyle.tooltipPopup}>
                    <Trans
                      i18nKey="systems.matrix.messages.forecastTooltip"
                      components={{ button: <ChartToggle color="#069b1e" isOn /> }}
                    />
                  </span>
                </Tooltip>
                {t('systems.matrix.captions.meanOfTransport')}
              </Fragment>
            ),
            model: (item) => {
              const { type } = item;
              const handler = handlers[type];
              const handleClick = handler ? () => handler((v: boolean) => !v) : undefined;
              return (
                <Fragment>
                  <ChartToggle
                    className={classNames(style.chartToggle, style[type])}
                    color={colors[type]}
                    isOn={isOn[type]}
                    toggle={handleClick}
                  >
                    {t(`systems.matrix.meansOfTransport.${type}`)}
                  </ChartToggle>
                </Fragment>
              );
            },
            className: style.typeColumn,
          },
          ...times(endYear - startYear + 1, (index) => ({
            title: startYear + index,
            model: (item: ForecastTableRecord) => (
              <NumberFormat {...{
                locale: currentLocale,
                value: Math.round(item[startYear + index]),
                zeroPlaceholder: '-',
              }} />
            ),
            className: style.alignRight,
          })),
        ],
        body: data,
      }} />
    </LoadingBlockContainer>
  );
};

export default ForecastTable;
