import { createStyles, makeStyles } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';
import { TRAFFIC_COLOR } from 'src/cluster/distribution-map';

const gradient = `${Object.values(TRAFFIC_COLOR).reduce((string: string, color: string) => {
  return `${string}, ${color}`;
}, 'linear-gradient(to right')})`;

export default makeStyles(({ palette, spacing }) => createStyles({
  root: {},

  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },

  desc: {
    fontSize: 12,
    fontFamily: ffSourceSansPro,
    color: palette.grey[700],
    padding: spacing(2, 0),
  },

  diagram: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-end',
    paddingTop: spacing(12),
    gap: 20,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 17,
      left: 0,
      width: 285,
      height: 16,
      background: gradient,
      borderRadius: 8,
    },
  },

  item: {
    position: 'relative',
    width: 20,
    fontSize: 12,
    textAlign: 'center',

    '&::before, &:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: palette.grey[600],
      width: 1,
      whiteSpace: 'nowrap',
      fontSize: 12,
    },

    '&::before': {
      top: -40,
      left: '50%',
      height: 35,
    },

    '&:not(:last-of-type)::after': {
      top: -10,
      right: '-50%',
      height: 5,
    },
  },
}));
