/* eslint-disable max-len */
import React from 'react';

const EditorLeadBlock = () => (
  <svg viewBox="0 0 40 40" >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="currentColor" x="9" y="19" width="22" height="2" />
    </g>
  </svg>
);

export default EditorLeadBlock;
