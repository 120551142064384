import {
  isNumber, isId, isString,
} from 'src/helpers/type-guards';
import { AcBalanceTtcModel } from 'src/cluster/common';

export function isTtcModel(record: unknown): record is AcBalanceTtcModel {
  if (record === null || typeof record !== 'object') {
    return false;
  }

  const {
    id,
    regionFromId,
    regionToId,
    year,
    ttcBase,
    ttcForecast,
    source,
  } = record as Partial<AcBalanceTtcModel>;

  return isId(id)
    && isNumber(regionFromId)
    && isNumber(regionToId)
    && isNumber(year)
    && isNumber(ttcBase)
    && isNumber(ttcForecast)
    && isString(source);
}
