import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import {
  AC_EDITOR_GRAPH_VERSION_MODAL_NAME,
  graphFormActions,
  resetEditorGraph,
  clearRegistryPage,
} from 'src/cluster/editor-common';

import ModalEditorGraphVersion from './ModalEditorGraphVersion';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setYear: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(graphFormActions.resetSomeFields(['scenarioId', 'intervalId']));
    dispatch(graphFormActions.changeField('year', Number(event.target.value)));
    dispatch(resetEditorGraph());
    dispatch(clearRegistryPage());
  },
  setScenario: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(graphFormActions.resetField('intervalId'));
    dispatch(graphFormActions.changeField('scenarioId', Number(event.target.value)));
    dispatch(resetEditorGraph());
    dispatch(clearRegistryPage());
  },
  setInterval: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(graphFormActions.changeField('intervalId', Number(event.target.value)));
    dispatch(resetEditorGraph(true));
  },
  close: () => {
    dispatch(modals.actions.showModal(false, AC_EDITOR_GRAPH_VERSION_MODAL_NAME));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(ModalEditorGraphVersion));
