import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  createFormSelector,
  createLoadsManager,
  isArray,
  isNumber,
} from 'src/helpers';

import {
  DistFilterModes,
  AC_MATRIX_FORECAST_INFO_MODEL_NAME,
  AC_STOPS_MODEL_NAME,
  AC_ROUTES_VARIANTS_MODEL_NAME,
  AC_DISTRIBUTION_PAGE_FORM_NAME,
  AC_PROJECT_LIST_FORM_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_DIST_DIRECTION_EDGES_MODEL_NAME as EDGES_MODEL_NAME,
  AcProjectForm,
  AcStopsModel,
  AcDistPageForm,
  isAcStopModel,
  selectMatrixForecastParams,
} from 'src/cluster/common';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  AC_DIST_ROUTES_DAY_MODEL_NAME,
  AC_DIST_ROUTES_MODEL_NAME,
  AC_DIST_STOPS_DAY_MODEL_NAME,
  AC_DIST_STOPS_MODEL_NAME,
  DISTRIBUTION_DIRECTION_EDGES_ENDPOINT,
  AcDistRoutesModel,
  AcDistStopsModel,
  AcDistDirectionEdgesModel,
  AcRoutesVariantsModel,
  AcDistRoutesDayModel,
  isAcDistRoutesModel,
  isAcDistStopsModel,
  isAcDistDirectionEdgeModel,
  isAcRoutesVariantsModel,
  isAcDistRoutesDayModel,
} from 'src/cluster/distribution-table';

export const selectAcDistPageForm = createFormSelector<AcDistPageForm>(AC_DISTRIBUTION_PAGE_FORM_NAME);
export const selectAcProjectPageForm = createFormSelector<AcProjectForm>(AC_PROJECT_LIST_FORM_NAME);

export const selectStopEntities = createEntitySelector<AcStopsModel>(AC_STOPS_MODEL_NAME);
export const selectRoutesVariantsEntities = createEntitySelector<AcRoutesVariantsModel>(AC_ROUTES_VARIANTS_MODEL_NAME);

export const selectDistRoutesEntities = createEntitySelector<AcDistRoutesModel>(AC_DIST_ROUTES_MODEL_NAME);
export const selectDistRoutesDayEntities = createEntitySelector<AcDistRoutesDayModel>(AC_DIST_ROUTES_DAY_MODEL_NAME);
export const selectDistStopsEntities = createEntitySelector<AcDistStopsModel>(AC_DIST_STOPS_MODEL_NAME);
export const selectDistStopsDayEntities = createEntitySelector<AcDistStopsModel>(AC_DIST_STOPS_DAY_MODEL_NAME);
export const distributionLoadsDirectionEdge = createLoadsManager(DISTRIBUTION_DIRECTION_EDGES_ENDPOINT);

export const selectDistDirectionEdgeEntities = createEntitySelector<AcDistDirectionEdgesModel>(EDGES_MODEL_NAME);

const selectDistRoutesInterval = createSelector(
  [
    selectAcProjectPageForm,
    selectMatrixForecastParams,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectDistRoutesEntities,
  ],
  (
    pageProjectsForm,
    matrixForecastParams,
    graphForm,
    distPageForm,
    distRoutesEntities,
  ): [Maybe<Model<AcDistRoutesModel>[]>, number] => {
    const { selectedProject } = pageProjectsForm;
    const [forecastId] = matrixForecastParams;
    const { year, scenarioId, intervalId } = graphForm;
    const {
      vehicleTypeId,
      routeVariantId,
      filterMode,
      pageSize,
      currentPage,
    } = distPageForm;

    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || !isNumber(intervalId)
      || filterMode !== DistFilterModes.route
    ) return [Nothing(), 0];

    const distApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        vehicleTypeId,
        routeVariantId,
      },
    };

    const results = distRoutesEntities.getArray(distApiConfig);

    const isLoading = distRoutesEntities.getIsLoadingArray(distApiConfig);

    if (isLoading || !isArray(results) || !isAcDistRoutesModel(results[0])) {
      return [Nothing(), 0];
    }

    const count = distRoutesEntities.getCount(distApiConfig);

    return [Just(results), count];
  },
);

const selectDistRoutesDay = createSelector(
  [
    selectAcProjectPageForm,
    selectMatrixForecastParams,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectDistRoutesDayEntities,
  ],
  (
    pageProjectsForm,
    matrixForecastParams,
    graphForm,
    distPageForm,
    distRoutesDayEntities,
  ): [Maybe<Model<AcDistRoutesDayModel>[]>, number] => {
    const { selectedProject } = pageProjectsForm;
    const [forecastId] = matrixForecastParams;
    const { year, scenarioId } = graphForm;
    const {
      vehicleTypeId,
      routeVariantId,
      filterMode,
      pageSize,
      currentPage,
    } = distPageForm;
    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || filterMode !== DistFilterModes.route
    ) return [Nothing(), 0];

    const distApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        vehicleTypeId,
        routeVariantId,
      },
    };

    const results = distRoutesDayEntities.getArray(distApiConfig);

    const isLoading = distRoutesDayEntities.getIsLoadingArray(distApiConfig);

    if (isLoading || !isArray(results) || !isAcDistRoutesDayModel(results[0])) {
      return [Nothing(), 0];
    }

    const count = distRoutesDayEntities.getCount(distApiConfig);

    return [Just(results), count];
  },
);

export const selectDistRoutes = createSelector(
  [
    selectDistGraphForm,
    selectAcDistPageForm,
    selectDistRoutesInterval,
    selectDistRoutesDay,
  ],
  (
    graphForm,
    pageProjectsForm,
    intervalResults,
    dayResults,
  ): [Maybe<Model<AcDistRoutesModel | AcDistRoutesDayModel>[]>, number] => {
    const { day } = graphForm;
    return day ? dayResults : intervalResults;
  },
);

export const selectDistStops = createSelector(
  [
    selectAcProjectPageForm,
    selectMatrixForecastParams,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectDistStopsEntities,
    selectDistStopsDayEntities,
  ],
  (
    pageProjectsForm,
    matrixForecastParams,
    graphForm,
    distPageForm,
    distStopsEntities,
    distStopsDayEntities,
  ): [Maybe<Model<AcDistStopsModel>[]>, number] => {
    const { selectedProject } = pageProjectsForm;
    const [forecastId] = matrixForecastParams;
    const {
      year, scenarioId, intervalId, day,
    } = graphForm;
    const {
      vehicleTypeIdStop: vehicleTypeId,
      stopId,
      pageSize,
      currentPage,
      filterMode,
    } = distPageForm;

    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || filterMode !== DistFilterModes.point
    ) {
      return [Nothing(), 0];
    }

    const distApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        stopId,
        vehicleTypeId,
      },
    };

    const results = day
      ? distStopsDayEntities.getArray(distApiConfig)
      : distStopsEntities.getArray(distApiConfig);

    const isLoading = day
      ? distStopsDayEntities.getIsLoadingArray(distApiConfig)
      : distStopsEntities.getIsLoadingArray(distApiConfig);

    if (isLoading || !isArray(results) || !isAcDistStopsModel(results[0])) return [Nothing(), 0];

    const count = day
      ? distStopsDayEntities.getCount(distApiConfig)
      : distStopsEntities.getCount(distApiConfig);

    return [Just(results), count];
  },
);

export const selectDistForwardDirection = createSelector(
  [
    selectAcProjectPageForm,
    selectMatrixForecastParams,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectDistDirectionEdgeEntities,
  ],
  (
    pageProjectsForm,
    matrixForecastParams,
    graphForm,
    distPageForm,
    distDirEdgeEntities,
  ): Maybe<Model<AcDistDirectionEdgesModel>[]> => {
    const { selectedProject } = pageProjectsForm;
    const [forecastId] = matrixForecastParams;
    const { year, scenarioId, intervalId } = graphForm;
    const { pageSize, currentPage, forwardDirectionId: routeDirectionId } = distPageForm;

    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || !isNumber(intervalId)
      || !isNumber(routeDirectionId)
    ) {
      return Nothing();
    }

    const distApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        routeDirectionId,
      },
    };

    const routes = distDirEdgeEntities.getArray(distApiConfig);

    if (!isArray(routes) || !isAcDistDirectionEdgeModel(routes[0])) return Nothing();

    return Just(routes);
  },
);

export const selectDistBackwardDirection = createSelector(
  [
    selectAcProjectPageForm,
    selectMatrixForecastParams,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectDistDirectionEdgeEntities,
  ],
  (
    pageProjectsForm,
    matrixForecastParams,
    graphForm,
    distPageForm,
    distDirEdgeEntities,
  ): Maybe<Model<AcDistDirectionEdgesModel>[]> => {
    const { selectedProject } = pageProjectsForm;
    const [forecastId] = matrixForecastParams;
    const { year, scenarioId, intervalId } = graphForm;
    const { pageSize, currentPage, backwardDirectionId: routeDirectionId } = distPageForm;

    if (
      !isNumber(selectedProject)
      || !isNumber(forecastId)
      || !isNumber(year)
      || !isNumber(scenarioId)
      || !isNumber(intervalId)
      || !isNumber(routeDirectionId)
    ) {
      return Nothing();
    }

    const distApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
        [AC_MATRIX_FORECAST_INFO_MODEL_NAME]: forecastId,
      },
      filter: {
        page: currentPage,
        pageSize,
        year,
        scenarioId,
        intervalId,
        routeDirectionId,
      },
    };

    const routes = distDirEdgeEntities.getArray(distApiConfig);

    if (!isArray(routes) || !isAcDistDirectionEdgeModel(routes[0])) return Nothing();

    return Just(routes);
  },
);

export const selectDistVariants = createSelector(
  [
    selectAcProjectPageForm,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectRoutesVariantsEntities,
  ],
  (
    projectForm,
    graphForm,
    distPageForm,
    variantsEntities,
  ): Maybe<Model<AcRoutesVariantsModel>[]> => {
    const { selectedProject } = projectForm;
    const { year, scenarioId, intervalId } = graphForm;
    const { vehicleTypeId } = distPageForm;
    if (!isNumber(selectedProject) || !isNumber(vehicleTypeId) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        vehicleTypeId,
        year,
        scenarioId,
        intervalId,
      },
    };

    const variants = variantsEntities.getArray(routeApiConfig);
    const isLoading = variantsEntities.getIsLoadingArray(routeApiConfig);

    if (isLoading || !isArray(variants) || !variants.length || !isAcRoutesVariantsModel(variants[0])) {
      return Nothing();
    }

    return Just(variants);
  },
);

export const selectDistStopsInfo = createSelector(
  [
    selectAcProjectPageForm,
    selectDistGraphForm,
    selectAcDistPageForm,
    selectStopEntities,
  ],
  (
    projectForm,
    graphForm,
    distPageForm,
    stopsEntities,
  ): Maybe<Model<AcStopsModel>[]> => {
    const { selectedProject } = projectForm;
    const { year, scenarioId } = graphForm;

    if (!isNumber(selectedProject) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
        scenarioId,
      },
    };

    const stops = stopsEntities.getArray(routeApiConfig);
    const isLoading = stopsEntities.getIsLoadingArray(routeApiConfig);

    if (isLoading || !isArray(stops)) {
      return Nothing();
    }

    if (stops.length > 0 && !isAcStopModel(stops[0])) {
      return Nothing();
    }

    return Just(stops);
  },
);
