import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';

import { bind, unbind } from 'src/helpers';

const clickEvents = ['click', 'touchend'];

interface Props {
  onClick: () => void;
}

class PClickOutside extends PureComponent<Props> {
  // eslint-disable-next-line react/static-property-placement
  public static propTypes = {
    onClick: PropTypes.func,
  }

  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    onClick: () => undefined,
  }

  public constructor(props: Props) {
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  public componentDidMount() {
    bind(clickEvents, this.handleOutsideClick);
  }

  public componentWillUnmount() {
    unbind(clickEvents, this.handleOutsideClick);
  }

  public handleOutsideClick(e: any) {
    const path = e.path || e.composedPath();
    // eslint-disable-next-line react/no-find-dom-node
    if (!path.includes(findDOMNode(this))) {
      this.props.onClick();
    }
  }

  public render() {
    return this.props.children;
  }
}

export default PClickOutside;
