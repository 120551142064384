import { registerModal, MODAL_SIZES } from 'src/modals';

import { AppState } from 'src/reducer';
import ModalExportCityCalculation, { ExportCalculationModalProps } from '../components/ModalCitiesCalcExport';

import { EXPORT_CALCULATION_MODAL } from '../constants';

const stateToProps = (state: AppState, props: ExportCalculationModalProps) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.small,
  };
};

export default registerModal(
  EXPORT_CALCULATION_MODAL,
  stateToProps,
)(ModalExportCityCalculation);
