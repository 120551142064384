import { RestifyId } from 'redux-restify';

import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';
import { mergeAndConcatArrays } from 'src/helpers/nested-objects';

import baseStyle from 'src/styles/basic-map-style.json';

import { StrictDict } from 'src/types';

export const NODES_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsNodes',
  [Systems.cities]: 'citiesNodes',
  [Systems.matrix]: 'matrixNodes',
};

export const ROUTES_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsRoutes',
  [Systems.cities]: 'citiesRoutes',
  [Systems.matrix]: 'matrixRoutes',
};

export const ROUTES_CALCULATION_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'citiesCalculationRoutes',
  [Systems.cities]: 'citiesCalculationRoutes',
  [Systems.matrix]: 'matrixCalculationRoutes',
};

export const NODES_CALCULATIONS_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'citiesCalculationNodes',
  [Systems.cities]: 'citiesCalculationNodes',
  [Systems.matrix]: 'matrixCalculationNodes',
};

export const ROUTES_PAGES_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'pageShipmentsRoutesForm',
  [Systems.cities]: 'pageCitiesRoutesForm',
  [Systems.matrix]: 'pageMatrixRoutesForm',
  [Systems.traffic]: 'pageTrafficMapForm',
};

export interface PageRoutesForm {
  selectedPoint?: number;
  selectedRoute?: number;
  expandedRoute?: number;
  selectedEdge?: number;
  lengthPercentMax: number;
  uniqPercentMax: number;
  from?: string;
  to?: string;
  transportType?: string;
  maxAlternativeRoutes: number;
  lengthPercentMaxActual?: number;
  uniqPercentMaxActual?: number;
  fromActual?: string;
  toActual?: string;
}

export interface NodeModel {
  id: RestifyId;
  name: string;
  lat: number;
  lng: number;
}

type Coordinates = [number, number]

export interface EdgeModel {
  capacity: string;
  costPerKm: number;
  distance: number;
  edgeId: number;
  firstNode: Coordinates;
  name: string;
  secondNode: Coordinates;
  speed: number;
  time: number;
  timeMoney: number;
  fare: number;
  traffic: number;
  trafficLoad: number;
  weight: number;
  fuelCost: number;
  type: string;
  detailed: Coordinates[];
}

export interface RouteModel {
  id: RestifyId;
  correspondenceType: string;
  isRail: number;
  railPart: number;
  trafficSummary: number;
  tariffHour: number;
  weight: number;
  distance: number;
  time: number;
  fuelCost: number;
  traffic: EdgeModel[];
}

export const getExtendedMapStyle = (style: any) => {
  return mergeAndConcatArrays({}, baseStyle, style);
};

export const TRANSPORT_TYPES: StrictDict<string, string> = {
  Пасс: 'pass',
  'Груз 1': 'cargo1',
  'Груз 2': 'cargo2',
  'Груз 3': 'cargo3',
  'Груз 4': 'cargo4',
};

export const SELECTED_ROUTE_COLOR = '#000';

export const INACTIVE_ROUTE_COLOR = '#c0c0c0';
export const SELECTED_EDGE_COLOR = '#000';

export const EDGE_TRAFFIC_COLOR: StrictDict<number, string> = {
  1: '#FF0801',
  2: '#FC7705',
  3: '#FAB208',
  4: '#F8FC0B',
  5: '#97F81D',
  6: '#1EF133',
};
