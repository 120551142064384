import escapeRegExp from 'lodash/escapeRegExp';

export const DEFAULT_MAX_ROWS = 5;

export type DropDownValue = string | number | boolean | undefined | null;
export type ChangeFunction = (values: DropDownValue[]) => void;

export interface DropDownItem {
  value: DropDownValue;
  label: React.ReactNode;
  description?: string;
  selectedLabel?: any;
  searchText?: string;
  titleLabel?: string;
}

export const defaultFilterFunction = (search: any, array: DropDownItem[]) => {
  return array.filter(item => {
    if (!search) {
      return true;
    }
    const textToCheck = item.searchText || item.titleLabel || item.label || item.value;
    if (typeof textToCheck !== 'string') {
      return false;
    }
    const regExp = new RegExp(escapeRegExp(search), 'im');
    return regExp.test(textToCheck);
  });
};
