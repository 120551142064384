import React, { FC, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { isNumber } from 'src/helpers';
import {
  Button,
  CircularProgressWrapper,
  ExcelIcon,
  GearIcon,
} from 'src/components';
import {
  DistFilterModes,
  AcDistGraphForm,
  AcDistPageForm,
  useAcIntervalName,
  MatrixForecastModel,
} from 'src/cluster/common';
import { useAcDistScenarioName } from 'src/cluster/distribution-common';

import { Model } from 'src/interfaces';
import { Nullable, ProcessStatus } from 'src/types';
import { useStyles, useTypographyStyles } from './styles';

interface InjectedProps {
  pageForm: AcDistPageForm;
  graphForm: AcDistGraphForm;
  matrixForecastParams: [
    number | undefined,
    Model<MatrixForecastModel> | undefined
  ];
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  showModal(): void;
  exportRoutes(): void;
  exportRoutesDay(): void;
  exportStops(): void;
  exportStopsDay(): void;
  showExportsModal(exportData: () => void): void;
  showModalProcessDataCalc: () => void;
  setLoadingCalc(f: boolean): void;
}

export type Props = InjectedProps & WithTranslate;

const DistributionTableToolbarTitle: FC<Props> = (props) => {
  const {
    pageForm: { filterMode, isExporting },
    graphForm: {
      year, scenarioId, intervalId, day, isCalculate,
    },
    matrixForecastParams: [forecastId],
    matrixForecastEntities: [entity],
    t,
    showModal,
    exportRoutes,
    exportRoutesDay,
    exportStops,
    exportStopsDay,
    showExportsModal,
    showModalProcessDataCalc,
    setLoadingCalc,
  } = props;
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();

  const getScenarioName = useAcDistScenarioName();
  const getIntervalName = useAcIntervalName(day);

  const openExportsModal = () => {
    let exportData = day ? exportStopsDay : exportStops;
    if (filterMode === DistFilterModes.route) {
      exportData = day ? exportRoutesDay : exportRoutes;
    }
    showExportsModal(exportData);
  };

  const disabled =
    isExporting ||
    !isNumber(forecastId) ||
    !isNumber(year) ||
    !isNumber(scenarioId);
  const { status } = entity?.routesDistribution || {};

  useEffect(() => {
    setLoadingCalc(
      !(status === ProcessStatus.SUCCESS || status === ProcessStatus.ERROR || status === null),
    );
  }, [status]);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button variant="icon" onClick={showModal}>
          <GearIcon />
        </Button>
        <Typography
          component="span"
          classes={typographyClasses}
          variant="body2"
          className={clsx(!year && classes.alert)}
        >
          {year || t('components.captions.notSelected')}
        </Typography>
        <Typography
          component="span"
          variant="caption"
          classes={typographyClasses}
        >
          <span>{t('common.captions.year')}</span>
        </Typography>
        <Typography
          component="span"
          classes={typographyClasses}
          className={clsx(!scenarioId && classes.alert)}
        >
          {getScenarioName(Number(scenarioId))}
        </Typography>
        <Typography
          component="span"
          variant="caption"
          classes={typographyClasses}
        >
          {t('common.captions.scenario')}
        </Typography>
        <Typography
          component="span"
          classes={typographyClasses}
          className={clsx(!scenarioId && classes.alert)}
        >
          {getIntervalName(Number(intervalId))}
        </Typography>
        <Typography
          component="span"
          variant="caption"
          classes={typographyClasses}
        >
          {t('common.captions.interval')}
        </Typography>
      </div>
      <div className={classes.export}>
        <div className={classes.calcInformer}>
          <Button
            variant="contained"
            onClick={showModalProcessDataCalc}
            className={classes.buttonCalc}
            disabled={
              status === ProcessStatus.QUEUED ||
              status === ProcessStatus.IN_PROGRESS ||
              isCalculate
            }
            startIcon={<GearIcon />}
          >
            {t('modules.forecast.captions.startCalculation')}
          </Button>
        </div>
        <CircularProgressWrapper loading={isExporting}>
          <Button
            startIcon={
              <span className={classes.iconButtonRed}>
                <ExcelIcon />
              </span>
            }
            className={classes.link}
            variant="text"
            disabled={disabled}
            onClick={openExportsModal}
          >
            {t('common.captions.export')}
          </Button>
        </CircularProgressWrapper>
      </div>
    </div>
  );
};

export default DistributionTableToolbarTitle;
