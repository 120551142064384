import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import { AC_DIST_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/common';
import { acDistGraphFormActions } from 'src/cluster/distribution-common';
import { distMapFormActions } from 'src/cluster/distribution-map';

import ModalDistributionGraphVersion from './ModalDistributionGraphVersion';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setYear: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(acDistGraphFormActions.resetSomeFields(['scenarioId', 'intervalId']));
    dispatch(distMapFormActions.resetSomeFields(['vehicleTypeId', 'routeId', 'variantId']));
    dispatch(acDistGraphFormActions.changeField('year', Number(event.target.value)));
  },
  setScenario: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(acDistGraphFormActions.resetField('intervalId'));
    dispatch(acDistGraphFormActions.changeField('scenarioId', Number(event.target.value)));
  },
  setInterval: (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(acDistGraphFormActions.changeField('intervalId', Number(event.target.value)));
  },
  setIsDay: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(acDistGraphFormActions.changeField('day', checked));
  },
  close: () => {
    dispatch(modals.actions.showModal(false, AC_DIST_GRAPH_VERSION_MODAL_NAME));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(ModalDistributionGraphVersion));
