import React from 'react';
import clsx from 'clsx';

import { ChevronDownIcon } from 'src/components';

import useStyles from './styles';

interface Props<T> {
  open: boolean;
  toggle(): void;
}

function GridArrow<T extends Record<string, any>>(props: Props<T>) {
  const {
    open,
    toggle,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <button
        className={classes.toggle}
        onClick={toggle}
      >
        <span className={clsx(classes.arrow, open && classes.arrowDown)}>
          <ChevronDownIcon />
        </span>
      </button>
    </div>
  );
}

export default GridArrow;
