import React, {
  FC, useState, Fragment, MouseEvent,
} from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  createStyles, Fade, makeStyles, Popover as MuiPopover,
} from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { ProcessStatus } from 'src/types';
import { CheckMarkIcon, ChevronDownIcon } from 'src/components';

import { useLinkToAdd } from 'src/cluster/common';

import { InjectedProps, OwnProps } from './intrerfaces';
import useStyles from './styles';

export const overridePopover = makeStyles((theme) => createStyles({
  root: {
    borderRadius: 0,
    marginLeft: 1,
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
    opacity: 1,
    boxShadow: theme.shadows[1],
  },
}), { name: 'MuiPopover' });

type Props = OwnProps & InjectedProps & WithTranslate;

const ProjectSelector: FC<Props> = (props: Props) => {
  const {
    className,
    projects,
    projectForm: { selectedProject },
    t,
    changeProject,
  } = props;

  overridePopover();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const currentProject = projects.orJust([]).find(project => project.id === selectedProject);

  const makeClickHandler = (id: number) => () => {
    changeProject(id);
    setAnchorEl(null);
  };

  const validProjects = projects.map((just) => just.filter((project) => {
    return !project.loadStatus || project.loadStatus === ProcessStatus.SUCCESS;
  })).orJust([]);

  const toAdding = useLinkToAdd();

  return (
    <Fragment>
      <div className={clsx(classes.root, className)}>
        <button
          className={classes.currentProject}
          onClick={handleClick}
        >
          <div className={classes.projectName}>
            {currentProject ? currentProject.name : t('modules.projects.captions.projectNotSelected')}
          </div>
          <div className={clsx(classes.popupIcon, open && classes.openedPopupIcon)}>
            <ChevronDownIcon />
          </div>
        </button>
        <MuiPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 60,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Fade}
        >
          <div className={classes.popup}>
            <div className={classes.projects}>
              {validProjects.map(project => (
                <div
                  key={project.id}
                  className={clsx(classes.project, selectedProject === project.id && classes.selectedProject)}
                  onClick={makeClickHandler(project.id)}
                >
                  <div className={classes.projectName}>
                    {project.name}
                  </div>
                  {selectedProject === project.id && (
                    <div className={classes.projectIcon}>
                      <CheckMarkIcon />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {toAdding && (
              <Link
                className={classes.loadNewFileButton}
                to={toAdding}
                replace
              >
                {t('modules.projects.captions.loadNewFile')}
              </Link>
            )}
          </div>
        </MuiPopover>
      </div>
    </Fragment>
  );
};

export default ProjectSelector;
