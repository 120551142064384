import {
  call,
  delay,
  put,
  putResolve,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { RestifyId } from 'redux-restify';

import { objectToCamel } from 'src/helpers';

import { PageRoutesForm } from '../../common';

import { hasAggloRoutesFilters } from '../helpers';
import { RUN_FIND_ROUTES_SAGA } from '../constants';
import {
  getFindingRoutesStatus,
  startFindingRoutes,
  setActualFilters,
  setIsBuilding,
  resetActualFilters,
} from '../actions';
import {
  selectAggloProjectPageForm,
  selectAggloRoutesPageForm,
} from '../selectors';

function* startFindingRoutesAsync(id: RestifyId, query: any) {
  return yield putResolve(startFindingRoutes(id, query));
}

function* getFoundRoutesStatusAsync(id: RestifyId, query: any) {
  return yield putResolve(getFindingRoutesStatus(id, query));
}

function makeQuery(form: PageRoutesForm) {
  return {
    firstNode: form.from,
    secondNode: form.to,
    trafficLimit: form.trafficLimit / 100,
    maxAlternativeRoutes: form.maxAlternativeRoutes,
    maxPedestrianTime: form.maxPedestrianTime,
    hasLongPedestrian: form.hasLongPedestrian,
  };
}

function getQueryUuid(res: any) {
  const { api: { response } } = res;
  const { queryUuid } = objectToCamel(JSON.parse(response));
  return queryUuid;
}

function* findRoutes() {
  const state = yield select();
  const projectsPageForm = yield call(selectAggloProjectPageForm, state);
  const projectId = projectsPageForm.selectedProject;
  if (projectId === undefined) {
    return Promise.reject();
  }

  const routesPageForm = yield call(selectAggloRoutesPageForm, state);
  if (!hasAggloRoutesFilters(routesPageForm)) {
    return Promise.reject();
  }

  yield put(setIsBuilding(true));

  const query = makeQuery(routesPageForm);
  const res = yield call(startFindingRoutesAsync, projectId, query);
  const queryUuid = getQueryUuid(res);

  let keepPolling = true;
  while (keepPolling) {
    yield delay(2000);
    try {
      const { status } = yield call(getFoundRoutesStatusAsync, projectId, { queryUuid });
      if (status !== 204) {
        keepPolling = false;
      }
    } catch (e) {
      yield put(resetActualFilters());
      keepPolling = false;
    }
  }

  yield put(setIsBuilding(false));
  return yield putResolve(setActualFilters(routesPageForm, queryUuid));
}

export function* watchFindRoutes() {
  yield takeLatest(RUN_FIND_ROUTES_SAGA, findRoutes);
}
