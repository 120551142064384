import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { DrawerLegendCheckbox, LEGEND_VEHICLE_TYPES_COLORS } from 'src/cluster/common';

import { LegendType, VehicleType } from '../../types';
import { selectEditorLegendForm } from '../../store';

const useLegendInfrastructures = (
  setLegendCheckbox: (key: LegendType, checked: boolean) => void,
  classes: Record<string, string>,
  vehicleTypesNames: VehicleType[],
): { title: string; items: DrawerLegendCheckbox[] }[] => {
  const {
    isSrnLine,
    isSuburbanLine,
    isWaterLine,
    isMetroLine,
    isTramwayLine,
    isTrolleybusLine,
    isTracklessStop,
    isSuburbanStop,
    isWaterStop,
    isMetroStop,
    isTramwayStop,
  } = useSelector(selectEditorLegendForm);

  const onChangeHandler = (field: LegendType) => (
    event: ChangeEvent<{}>,
    checked: boolean,
  ) => setLegendCheckbox(field, checked);

  return [
    {
      title: 'modules.editor.drawer.lines',
      items: [
        {
          type: LegendType.isSrnLine,
          iconProps: { borderBottom: '3px solid #c4c4c4' },
          checked: isSrnLine,
          onChange: onChangeHandler(LegendType.isSrnLine),
        },
        {
          vehicleType: VehicleType.suburban,
          type: LegendType.isSuburbanLine,
          iconProps: {
            borderBottom: `2px dashed ${LEGEND_VEHICLE_TYPES_COLORS[VehicleType.suburban].color}`,
          },
          checked: isSuburbanLine,
          onChange: onChangeHandler(LegendType.isSuburbanLine),
        },
        {
          vehicleType: VehicleType.water,
          type: LegendType.isWaterLine,
          iconProps: {
            borderBottom: `2px dashed ${LEGEND_VEHICLE_TYPES_COLORS[VehicleType.water].color}`,
          },
          checked: isWaterLine,
          onChange: onChangeHandler(LegendType.isWaterLine),
        },
        {
          vehicleType: VehicleType.metro,
          type: LegendType.isMetroLine,
          iconProps: {
            borderBottom: `2px solid ${LEGEND_VEHICLE_TYPES_COLORS[VehicleType.metro].color}`,
          },
          checked: isMetroLine,
          onChange: onChangeHandler(LegendType.isMetroLine),
        },
        {
          vehicleType: VehicleType.tramway,
          type: LegendType.isTramwayLine,
          iconProps: {
            borderBottom: `1px solid ${LEGEND_VEHICLE_TYPES_COLORS[VehicleType.tramway].color}`,
          },
          checked: isTramwayLine,
          onChange: onChangeHandler(LegendType.isTramwayLine),
        },
        {
          vehicleType: VehicleType.trolleybus,
          type: LegendType.isTrolleybusLine,
          iconProps: {
            borderBottom: `1px solid ${LEGEND_VEHICLE_TYPES_COLORS[VehicleType.trolleybus].color}`,
          },
          checked: isTrolleybusLine,
          onChange: onChangeHandler(LegendType.isTrolleybusLine),
        },
      ].filter(item => !item.vehicleType || vehicleTypesNames.includes(item.vehicleType)),
    },
    {
      title: 'modules.editor.drawer.stops',
      items: [
        {
          type: LegendType.isTracklessStop,
          iconProps: {
            width: 13, height: 13, ml: 0.625, border: '3px solid #c4c4c4', borderRadius: '50%',
          },
          checked: isTracklessStop,
          onChange: onChangeHandler(LegendType.isTracklessStop),
        },
        {
          vehicleType: VehicleType.suburban,
          type: LegendType.isSuburbanStop,
          iconProps: {
            icon: VehicleType.suburban,
            filled: false,
            className: classes.vehicleIconSmall,
          },
          checked: isSuburbanStop,
          onChange: onChangeHandler(LegendType.isSuburbanStop),
        },
        {
          vehicleType: VehicleType.water,
          type: LegendType.isWaterStop,
          iconProps: {
            icon: VehicleType.water,
            filled: false,
            className: classes.vehicleIconSmall,
          },
          checked: isWaterStop,
          onChange: onChangeHandler(LegendType.isWaterStop),
        },
        {
          vehicleType: VehicleType.metro,
          type: LegendType.isMetroStop,
          iconProps: {
            icon: VehicleType.metro,
            filled: false,
            className: classes.vehicleIconSmall,
          },
          checked: isMetroStop,
          onChange: onChangeHandler(LegendType.isMetroStop),
        },
        {
          vehicleType: VehicleType.tramway,
          type: LegendType.isTramwayStop,
          iconProps: {
            icon: VehicleType.tramway,
            filled: false,
            className: classes.vehicleIconSmall,
          },
          checked: isTramwayStop,
          onChange: onChangeHandler(LegendType.isTramwayStop),
        },
      ].filter(item => !item.vehicleType || vehicleTypesNames.includes(item.vehicleType)),
    },
  ];
};

export default useLegendInfrastructures;
