import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { InteractiveMap, Popup } from 'react-map-gl';
import { WebMercatorViewportOptions } from 'viewport-mercator-project';
import { Feature, Point } from 'src/interfaces';

import { usePopup } from 'src/constants';
import { VocabularyEntity } from 'src/i18n';
import { Map } from 'src/modules/general/common';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';

import { getAggregatedRoutes } from 'src/modules/mth/common/helpers/aggregation';
import {
  VIEW_MODES,
  AGGLOMERATION_EDGE_TYPES,
} from '../../constants';

import AggloMapLegend from '../AggloMapLegend';
import AggloMapToggle from '../AggloMapToggle';
import AggloRoutesAndOvershotTabs from '../AggloRoutesAndOvershotTabs';

import { Props } from './interfaces';
import { useMapStyle, useMapFittedToEdges, useMapFittedToNodes } from './hooks';
import style from './index.module.css';

const AggloMap: FC<Props> = (props: Props) => {
  const {
    className,
    graphUrl,
    polygonsUrl,
    form,
    formActions,
    currentTransportType,
    nodes,
    routes,
    overshot,
    isLoading = false,
    toggle,
    resetForm,
    onSourceData,
    onClick,
  } = props;
  const [nodePopup, setNodePopup] = usePopup<string>();
  const [viewport, setViewport] = useState<WebMercatorViewportOptions>({
    latitude: 55.75,
    longitude: 37.625,
    zoom: 7,
  });

  const aggregatedRoutes = useMemo(() => getAggregatedRoutes(routes, form), [routes, form]);

  const mapRef = useRef<InteractiveMap | null>(null);

  useEffect(() => (): void => {
    resetForm();
  }, []);

  useMapFittedToNodes(nodes, isLoading, viewport, setViewport, form);
  useMapFittedToEdges(routes, isLoading, viewport, setViewport, form);

  const showGraph = form.viewMode === VIEW_MODES.graph;

  const startPolygonUrl = routes[0]?.startRegion?.polygonUrl;

  const finishPolygonUrl = routes[0]?.finishRegion?.polygonUrl;

  const mapStyle = useMapStyle(
    nodes,
    routes,
    currentTransportType,
    form.selectedRoute,
    form.selectedEdges,
    form.selectedRegion,
    graphUrl,
    polygonsUrl,
    startPolygonUrl,
    finishPolygonUrl,
    {
      showGraph,
      showTraffic: form.showTraffic,
    },
  );

  const isFullHeight = !!(form.selectedEdges || form.isStartSelected || form.isFinishSelected) && (
    !showGraph || form.transportType === AGGLOMERATION_EDGE_TYPES.auto
  );

  const tableClassName = classNames({
    [style.leftMargin]: isFullHeight,
    [style.invisible]: showGraph,
  });

  const handleClick = useCallback((e): void => {
    if (!mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point) as Feature[];
    onClick(features);
  }, [form]);

  const handleMouseMove = useCallback((e): void => {
    if (!mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point);
    if (!features) return;
    const pointFeature = features.find(f => f.properties?.isPoint) as Feature<Point> | undefined;
    if (pointFeature?.properties) {
      const { geometry } = pointFeature;
      setNodePopup({
        content: pointFeature.properties.name,
        lat: geometry?.coordinates[1] || 0,
        lng: geometry?.coordinates[0] || 0,
      });
    } else {
      setNodePopup({
        content: undefined,
        lat: 0,
        lng: 0,
      });
    }
  }, []);

  const showTable = !showGraph && form.showRoutes && (routes.length > 0 || overshot.length > 0);

  return (
    <LoadingBlockContainer {...{
      className: classNames(style.root, className),
      isBlocked: isLoading,
    }}>
      <Map {...{
        className: classNames(style.map, showTable && style.withRoutes, showGraph && style.fullHeight),
        mapRef,
        interactiveLayerIds: [
          'polygons',
          'selected-polygon',
          'routes-hover',
          'routes-selected',
          'points-unclustered',
          'correspondence-points',
          'start-polygon',
          'finish-polygon',
        ],
        legend: <AggloMapLegend />,
        controls: showGraph ? undefined : (
          <AggloMapToggle {...{
            className: style.toggle,
            showTraffic: form.showTraffic || false,
            toggle,
          }} />
        ),
        viewport,
        setViewport,
        onSourceData,
        onMouseMove: handleMouseMove,
        onClick: handleClick,
        mapStyle,
      }}>
        {nodePopup.content ? (
          <Popup {...{
            latitude: nodePopup.lat,
            longitude: nodePopup.lng,
            closeButton: false,
            closeOnClick: false,
            anchor: 'left',
          }}>
            <div>
              <VocabularyEntity value={nodePopup.content} />
            </div>
          </Popup>
        ) : undefined}
      </Map>
      <AggloRoutesAndOvershotTabs {...{
        className: tableClassName,
        form,
        formActions,
        routes: aggregatedRoutes,
        overshot,
      }} />
    </LoadingBlockContainer>
  );
};

export default AggloMap;
