import { connect } from 'react-redux';

import { AppState } from 'src/reducer';
import { selectIndicators } from 'src/modules/mth/indicators';

import AggloIndicatorsItem from './AggloIndicatorsItem';

const mapStateToProps = (state: AppState) => ({
  entities: selectIndicators(state),
});

export default connect(mapStateToProps)(AggloIndicatorsItem);
