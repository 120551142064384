import { MATRIX_TRANSPORT_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: MATRIX_TRANSPORT_ENDPOINT,
  name: 'данные по трафику',
  defaults: {
    id: undefined,
    type: undefined,
    year: undefined,
    price: undefined,
    time: undefined,
    frequency: undefined,
    fromCity: undefined,
    toCity: undefined,
    comfortElasticity: undefined,
    hsw: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
