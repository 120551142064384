import { EDGE_ROUTES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: EDGE_ROUTES_ENDPOINT,
  parent: 'agglomerationCalculations',
  idField: () => Math.random(),
  defaults: {},
  transformEntityResponse: (response: any) => {
    return { data: { routes: response } };
  },
  pagination: false,
};
