import { api, forms, RestifyId } from 'redux-restify';

import modals from 'src/modals';
import { BinaryApiResponse } from 'src/interfaces';
import { downloadFile, getFilename } from 'src/helpers/file-download';
import { ThunkActionResult } from 'src/reducer';
import { Systems } from 'src/constants';
import { PageProjectForm, PROJECTS_PAGES_FORM_NAMES } from 'src/modules/general/projects';

import { PageCalculationsRoutesForm } from '../../common';
import { CALCULATION_MAP_MODAL } from '../../calculations';
import {
  CALCULATIONS_ENDPOINTS,
  CALCULATIONS_MODEL_NAME,
  CALCULATIONS_PAGES_FORM_NAME,
  EXPORT_CALCULATION_MODAL, ROUTES_PAGES_FORM_NAME,
} from '../constants';
import { ExportAgglomerationCalculationForm } from '../forms/exportAgglomerationCalculation.form';
import { PageAgglomerationCalculationForm } from '../interfaces';
import { asyncSelectCurrentProjectRoutes } from '../selectors';

const pageProjectFormName = PROJECTS_PAGES_FORM_NAMES[Systems.mth];

export const startCalculation = (): ThunkActionResult<Promise<any>> => (dispatch) => {
  return dispatch(forms.actions.sendQuickForm({
    baseConfig: 'baseEditCalculationForm',
    model: CALCULATIONS_MODEL_NAME,
  }));
};

export const startAgglomerationCalculation = (): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const pageProjectForm = forms.selectors[pageProjectFormName]
    .getFormWithNulls<PageProjectForm>(state);
  const calculationPageForm = forms.selectors[CALCULATIONS_PAGES_FORM_NAME]
    .getFormWithNulls<PageAgglomerationCalculationForm>(state);
  return dispatch(forms.actions.sendQuickForm({
    baseConfig: 'baseEditCalculationForm',
    model: CALCULATIONS_MODEL_NAME,
    values: {
      trafficLimit: calculationPageForm.trafficLimit / 100,
      maxAlternativeRoutes: calculationPageForm.maxAlternativeRoutes,
      routesProject: pageProjectForm.selectedProject,
    },
  }));
};

export const showCalculationMap = (id: RestifyId): ThunkActionResult<void> => (dispatch) => {
  dispatch(modals.actions.showModal(true, CALCULATION_MAP_MODAL, {
    calculationId: id,
    system: Systems.mth,
  }));
};

export const exportCalculation = (id: RestifyId): ThunkActionResult<void> => (dispatch) => {
  dispatch(modals.actions.showModal(true, EXPORT_CALCULATION_MODAL, {
    calculationId: id,
    system: Systems.mth,
  }));
};

export const downloadCalculationExport = (id: RestifyId): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const exportForm = forms.selectors
    .exportAgglomerationCalculationForm.getFormWithNulls<ExportAgglomerationCalculationForm>(state);
  return dispatch(api.actions.callGet({
    url: `${CALCULATIONS_ENDPOINTS}${id}/export/`,
    query: exportForm,
    isBinary: true,
  })).then(({
    data,
    status,
    api: xhr,
  }: BinaryApiResponse) => {
    if (status === 200 && data !== undefined) {
      const blob = new Blob([data], {
        type: 'octet/stream',
      });
      const filename = getFilename(xhr);
      downloadFile(blob, filename);
    }
  });
};

export const findRoutes = (): ThunkActionResult<ReturnType<typeof asyncSelectCurrentProjectRoutes>> => (
  dispatch,
  getState,
) => {
  let state = getState();
  const routesPageForm = forms.selectors[ROUTES_PAGES_FORM_NAME].getFormWithNulls<PageCalculationsRoutesForm>(state);
  dispatch(forms.actions[ROUTES_PAGES_FORM_NAME].changeSomeFields({
    actualFrom: routesPageForm.from,
    actualTo: routesPageForm.to,
    actualTrafficLimit: routesPageForm.trafficLimit,
    actualMaxAlternativeRoutes: routesPageForm.maxAlternativeRoutes,
  }));
  state = getState();
  return asyncSelectCurrentProjectRoutes(state);
};
