import React from 'react';

const Pointer = () => (
  <svg viewBox="0 0 512 512" >
    <polygon points="429.742,319.31 82.489,0 82.258,471.744 187.633,370.918 249.523,512 346.083,469.642 284.193,328.56"
    />
  </svg>
);

export default Pointer;
