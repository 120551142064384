import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Feature } from 'src/interfaces';

import { AppState } from 'src/reducer';
import { selectCurrentTransportType } from 'src/modules/mth/common/selectors';
import { selectGeoJsonFeature } from 'src/modules/mth/common/actions';
import { OwnProps } from './interfaces';
import AggloMap from './AggloMap';

const mapStateToProps = (state: AppState) => ({
  currentTransportType: selectCurrentTransportType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { form, formActions }: OwnProps) => {
  return ({
    toggle: () => dispatch(formActions.changeField('showTraffic', !form.showTraffic)),
    resetForm: () => {
      dispatch(formActions.resetField('selectedRoute'));
      dispatch(formActions.resetField('selectedEdges'));
      dispatch(formActions.resetField('selectedRegion'));
      dispatch(formActions.changeField('isStartSelected', false));
      dispatch(formActions.changeField('isFinishSelected', false));
    },
    onClick: (features: Feature[]): void => dispatch(selectGeoJsonFeature(features, form, formActions)),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(AggloMap);
