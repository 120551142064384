import React from 'react';
import classNames from 'classnames';

import PSelect from 'src/components/deprecated/PSelect';
import PButton from 'src/components/deprecated/PButton';
import { getVocabularyValue, useCurrentVocabulary } from 'src/i18n';

import { Props } from './interfaces';

import style from './index.module.css';

const AggloMapFilterCorrespondences: React.FC<Props> = (props: Props) => {
  const {
    className,
    nodes,
    form,
    onFromChange,
    onToChange,
    onCalculateRouteClick,
    t,
  } = props;

  const [locale, vocabulary] = useCurrentVocabulary();

  return (
    <div className={classNames(style.root, className)}>
      <PSelect {...{
        label: t('common.captions.from'),
        className: style.select,
        items: nodes.map(n => ({
          value: n.name,
          label: getVocabularyValue(locale, vocabulary, n.name),
        })),
        isClearable: true,
        values: form.from === undefined ? undefined : [form.from],
        onChange: onFromChange,
      }} />

      <PSelect {...{
        label: t('common.captions.to'),
        className: style.select,
        items: nodes.map(n => ({
          value: n.name,
          label: getVocabularyValue(locale, vocabulary, n.name),
        })),
        isClearable: true,
        placeholder: 'Select',
        values: form.to === undefined ? undefined : [form.to],
        onChange: onToChange,
      }} />

      <div className={style.buttonWrapper}>
        <PButton {...{
          className: style.button,
          onClick: onCalculateRouteClick,
        }}>
          {t('systems.mth.captions.calculateRoute')}
        </PButton>
      </div>
    </div>
  );
};

export default AggloMapFilterCorrespondences;
