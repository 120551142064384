import React, { FC } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Autocomplete, TextField } from 'src/components';
import { SelectValue } from 'src/interfaces';
import { AcRegRoutesResultsModel, useEditMainFieldsConfig } from 'src/cluster/editor-table';

import useStyles from './styles';

export interface OwnProps {
  routeFields: Partial<AcRegRoutesResultsModel>;
  onChangeFields(routeFields: Partial<AcRegRoutesResultsModel>): void;
}

type Props = OwnProps & WithTranslate;

const RegistryEditRouteFields: FC<Props> = (props) => {
  const {
    t,
    routeFields,
    onChangeFields,
  } = props;

  const classes = useStyles();

  const onChangeField = (field: string, val?: SelectValue) => {
    onChangeFields({ [field]: val });
  };

  const {
    vehicleType,
    routeNumber,
    routeLongName,
    carrier,
    ...typeProps
  } = useEditMainFieldsConfig(routeFields, onChangeField);

  return (
    <Box display="flex" flexWrap="wrap">
      <Autocomplete
        placeholder={t('common.captions.startTyping')}
        multiple={vehicleType.multiple}
        label={t(vehicleType.label)}
        options={vehicleType.options}
        selected={vehicleType.selected}
        className={classes.field}
        onChange={vehicleType.command}
      />
      <TextField
        label={t(routeNumber.label)}
        value={routeNumber.value}
        className={clsx(classes.field, classes.input)}
        onChange={routeNumber.command}
      />
      <TextField
        label={t(routeLongName.label)}
        value={routeLongName.value}
        className={clsx(classes.field, classes.inputName)}
        onChange={routeLongName.command}
      />
      {Object.values(typeProps).map(filter => (
        <Autocomplete
          key={filter.label}
          placeholder={t('common.captions.startTyping')}
          multiple={filter.multiple}
          label={t(filter.label)}
          options={filter.options}
          selected={filter.selected}
          className={classes.field}
          onChange={filter.command}
        />
      ))}
      <TextField
        label={t(carrier.label)}
        value={carrier.value}
        className={classes.field}
        onChange={carrier.command}
      />
    </Box>
  );
};

export default RegistryEditRouteFields;
