import React, { ChangeEventHandler, FC } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import { Model } from 'src/interfaces';
import { UNITS } from 'src/constants';
import { WithTranslate } from 'src/i18n';
import { NumberFormat, TextField } from 'src/components';

import {
  EditorPrimaryTreeItem, useVehicleTypeLabel, AcEdgeSpeed, MIN_EDGE_SPEED,
} from 'src/cluster/editor-map';

import { useCommonStyles, useGridStyles } from './styles';

interface OwnProps {
  isModifying: boolean;
  edgeSpeed: Model<AcEdgeSpeed>;
  onChange(id: number, values: Model<AcEdgeSpeed>): void;
}

type Props = OwnProps & WithTranslate;

const EditorEdgeTreeItem: FC<Props> = (props) => {
  const {
    isModifying,
    edgeSpeed,
    locale,
    t,
    onChange,
  } = props;

  const classes = useCommonStyles();
  const gridClasses = useGridStyles();
  const getVehicleTypeLabel = useVehicleTypeLabel();
  const vehicleTypeLabel = getVehicleTypeLabel(edgeSpeed.vehicleTypeId);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newSpeed = Number(event.target.value) < 0 ? 0 : Number(event.target.value);
    if (edgeSpeed.dedicatedLane) {
      onChange(edgeSpeed.id, {
        ...edgeSpeed,
        speedDedicatedLane: newSpeed,
      });
    } else {
      onChange(edgeSpeed.id, {
        ...edgeSpeed,
        speed: newSpeed,
      });
    }
  };

  const inputKey = edgeSpeed.dedicatedLane
    ? `edge-dedicated-speed-${edgeSpeed.id}`
    : `edge-speed-${edgeSpeed.id}`;

  const inputValue = edgeSpeed.dedicatedLane
    ? edgeSpeed.speedDedicatedLane
    : edgeSpeed.speed;

  return (
    <EditorPrimaryTreeItem
      key={edgeSpeed.vehicleTypeId}
      nodeId={`${edgeSpeed.vehicleTypeId}`}
      label={<span className={classes.label}>{vehicleTypeLabel}</span>}
      className={clsx(isModifying && classes.editing)}
    >
      <Grid container classes={gridClasses}>
        <Grid item xs={isModifying ? 12 : 6} classes={gridClasses} className={classes.speedLabel}>
          {isModifying ? (
            <label htmlFor={inputKey}>
              {t('modules.editor.captions.actualSpeed')}
              :
            </label>
          ) : (
            <span>
              {t('modules.editor.captions.actualSpeed')}
              :
            </span>
          )}
        </Grid>
        <Grid item xs={isModifying ? 12 : 6} classes={gridClasses}>
          {isModifying ? (
            <TextField
              key={inputKey}
              className={classes.input}
              id={inputKey}
              type="number"
              decimalScale={2}
              value={inputValue}
              error={inputValue < MIN_EDGE_SPEED}
              errorText={[t('modules.editor.messages.minSpeed')]}
              onChange={handleChange}
            />
          ) : (
            <span className={classes.value}>
              <NumberFormat value={inputValue} locale={locale} />
            </span>
          )}
          {' '}
          <span className={classes.unit}>{t(UNITS.speed)}</span>
        </Grid>
      </Grid>
    </EditorPrimaryTreeItem>
  );
};

export default EditorEdgeTreeItem;
