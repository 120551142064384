import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ModalWrapper from './components/ModalWrapper';
import * as actions from './actions';

const getDisplayName = (WrappedComponent: any) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const defaultCloseModalActions = ['cancelAction', 'submitAction', 'closeAction'];
const defaultMapToProps = () => undefined;
const defaultMergeProps = (stateProps: any, dispatchProps: any) => ({ ...stateProps, ...dispatchProps });

const registerModal = (
  name: string,
  stateToProps: (state: any, props: any) => any = defaultMapToProps,
  dispatchToProps: (dispatch: any) => any = defaultMapToProps,
  mergeProps = defaultMergeProps,
) => (WrappedModal: any) => {
  const modalStateToProps = (state: any, props: any) => {
    if (!props.show) {
      // Do not calculate props for closed modals
      return {};
    }
    return stateToProps(state, props);
  };
  const modalDispatchToProps = (dispatch: any) => ({
    ...dispatchToProps(dispatch),
    dispatch,
  });

  const modalMergeProps = (stateProps: any, dispatchProps: any, props: any) => {
    if (!props.show) {
      // For closed modals only modal props(line show) matters
      return props;
    }
    const {
      dispatch,
    } = dispatchProps;

    const mergedProps = mergeProps(stateProps, dispatchProps);
    const closingActions = defaultCloseModalActions.reduce((memo: any, action: string) => ({
      ...memo,
      [action]: () => {
        const additionalAction = mergedProps[action];
        const finallyAction = () => {
          dispatch(actions.showModal(false, name));
          setTimeout(() => {
            if (mergedProps.afterClose) {
              mergedProps.afterClose();
            }
          }, 0);
        };
        if (typeof additionalAction === 'function') {
          const actionResult = additionalAction();
          if (actionResult instanceof Promise) {
            actionResult.then((res = true) => {
              if (res) {
                finallyAction();
              }
            });
          } else {
            finallyAction();
          }
        } else {
          finallyAction();
        }
      },
    }), {});
    return {
      ...props,
      ...mergedProps,
      model: mergedProps.model || {}, // Some workaround for deleted forms, so we don't check in every modal
      ...closingActions,
    };
  };
  const reduxModal: any = connect(modalStateToProps, modalDispatchToProps, modalMergeProps);
  const ConnectedModal = reduxModal(WrappedModal);
  const ConnectedModalWrapper = reduxModal(ModalWrapper);

  return class extends PureComponent<{
    openByNameMap: any;
    paramsByNameMap: any;
    modalsOrderByNameMap: any;
    [other: string]: any;
  }> {
    // eslint-disable-next-line react/static-property-placement
    public static displayName = `registerModal(${getDisplayName(WrappedModal)})`

    // eslint-disable-next-line react/static-property-placement
    public static propTypes = {
      openByNameMap: PropTypes.object,
      modalsOrderByNameMap: PropTypes.object,
    }

    // eslint-disable-next-line react/static-property-placement
    public static defaultProps = {
      openByNameMap: {},
      modalsOrderByNameMap: {},
    }

    public render() {
      const {
        openByNameMap,
        paramsByNameMap,
        modalsOrderByNameMap,
        ...other
      } = this.props;
      return (
        <ConnectedModalWrapper {...{
          key: 'modal',
          show: !!openByNameMap[name],
          ...paramsByNameMap[name],
          order: modalsOrderByNameMap[name],
        }}>
          <ConnectedModal {...{
            show: !!openByNameMap[name],
            ...paramsByNameMap[name],
            ...other,
          }} />
        </ConnectedModalWrapper>
      );
    }
  };
};

export default registerModal;
