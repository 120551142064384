import { ExportTypes } from '../constants';

export interface ExportCalculationForm {
  type?: ExportTypes;
  from: string[];
  to: string[];
  edge: number[];
}

export default {
  defaults: {
    type: undefined,
    from: [],
    to: [],
    edge: [],
  },
};
