import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { initDistributionMap, selectPageForm } from 'src/cluster/distribution-map';

import DistributionMapPage from './DistributionMapPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInit: () => dispatch(initDistributionMap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DistributionMapPage));
