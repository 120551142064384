import React, {
  useCallback, useState, KeyboardEvent,
} from 'react';

import { TextField } from 'src/components';

import TableCell from '../TableCell/TableCell';

interface Props<T> {
  className?: string;
  defaultValue: string;
  onSubmit: (value: string) => void;
  onCancel: () => void;
}

function EditableGridCell<T extends Record<string, any>>(props: Props<T>) {
  const {
    defaultValue,
    onCancel,
    onSubmit,
    ...rest
  } = props;

  const [value, setValue] = useState<string>(defaultValue);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, [setValue]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      onCancel();
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(value);
    }
  };

  return (
    <TableCell {...rest}>
      <TextField
        autoFocus
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
        onBlur={onCancel}
      />
    </TableCell>
  );
}

export default EditableGridCell;
