import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    fontFamily: ffSourceSansPro,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    color: palette.text.primary,
    padding: spacing(0, 5),
  },
  submitButtons: {
    padding: spacing(2, 3),
    backgroundColor: palette.common.white,
  },
}));

export const useTitleStyles = makeStyles(({ palette, spacing }: Theme) => {
  return createStyles({
    root: {
      fontFamily: ffSourceSansPro,
      fontWeight: 600,
      color: palette.common.black,
      paddingTop: spacing(2),
      paddingBottom: spacing(3),
    },
    body2: {
      textTransform: 'uppercase',
    },
  });
});
