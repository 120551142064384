import React from 'react';

const RemoveIcon = () => (
  <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3L0.866667 19L5.2 14.2432L8.66667 19L9.96667 18.1351L6.5 13.3784L13 12.5135L0 3Z"
      fill="currentColor"
    />
    <rect
      x="4"
      width="8"
      height="3"
      fill="currentColor"
    />
  </svg>
);

export default RemoveIcon;
