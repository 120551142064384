import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import i18n, { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { SelectValue } from 'src/interfaces';
import modals from 'src/modals';
import {
  AC_REGISTRY_TABLE_FILTERS_MODAL,
  AcRegistryFiltersEnum,
  setFilterAction,
  selectRegistryFilters,
} from 'src/cluster/editor-table';
import { registryFiltersActions } from 'src/cluster/editor-common';

import RegistryMainFilters from './RegistryMainFilters';

const mapStateToProps = (state: AppState) => ({
  tableFilters: selectRegistryFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setFilter: (val: SelectValue, field: AcRegistryFiltersEnum) => dispatch(setFilterAction(val, field)),
  clearFilters: () => {
    dispatch(registryFiltersActions.resetForm());
  },
  showFiltersModal: () => {
    dispatch(modals.actions.showModal(true, AC_REGISTRY_TABLE_FILTERS_MODAL, {
      title: i18n.t('modules.registries.captions.filteringRoutes'),
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(RegistryMainFilters));
