import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import {
  selectEditorPageForm, addDirection, deleteDirection, swapDirections, setSelectedStop, PointAddingDirection,
} from 'src/cluster/editor-map';

import { editorFormActions } from 'src/cluster/editor-common';
import RouteDirection from './RouteDirection';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setSelectedStop: (id: number) => {
    dispatch(setSelectedStop(id));
  },
  setAddIndex: (index?: number) => {
    dispatch(editorFormActions.changeField('addIndex', index));
  },
  setPointAddingDirection: (addingDirection?: PointAddingDirection) => {
    dispatch(editorFormActions.changeField('addingDirection', addingDirection));
  },
  addDirection: (variantId: number) => {
    dispatch(addDirection(variantId));
  },
  deleteDirection: (variantId: number, directionId: number) => {
    dispatch(deleteDirection(variantId, directionId));
  },
  swapDirections: (variantId: number) => {
    dispatch(swapDirections(variantId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(RouteDirection));
