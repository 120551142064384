import { createModelActions, isNumber } from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';
import modals from 'src/modals';

import {
  AC_INTERVALS_MODEL_NAME,
  AC_ROUTES_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_PROJECTS_MODEL_NAME,
  selectAcProjectListForm,
} from 'src/cluster/common';
import {
  selectEditorGraphForm,
  AC_EDITOR_GRAPH_VERSION_MODAL_NAME,
  entitiesEditorFormActions,
  editorFormActions,
  copyingNetworkFormActions,
} from 'src/cluster/editor-common';
import {
  EditorMode,
  selectEditorEdgeSpeeds,
  selectEditorPageForm,
  selectEditorSelectedRoute,
  selectEditorSelectedStop,
} from 'src/cluster/editor-map';

export const scenarioEntityManager = createModelActions(AC_SCENARIOS_MODEL_NAME);
export const intervalEntityManager = createModelActions(AC_INTERVALS_MODEL_NAME);
export const routeEntityManager = createModelActions(AC_ROUTES_MODEL_NAME);

export function unsetEditableFields(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { routeId } = selectEditorPageForm(state);
    const {
      stopId, variantId, directionId,
    } = selectEditorPageForm(state);
    dispatch(entitiesEditorFormActions.resetForm());
    if (stopId === -1) {
      dispatch(editorFormActions.resetField('stopId'));
    }
    if (directionId === -1) {
      dispatch(editorFormActions.resetField('directionId'));
      if (variantId === -1) {
        dispatch(editorFormActions.resetField('variantId'));
        if (routeId === -1) {
          dispatch(editorFormActions.resetField('routeId'));
        }
      }
    }
  };
}

export function loadRoute(id: number): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const routeApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      query: {}, // Fix loadsManager records without hash
    };

    dispatch(routeEntityManager.loadById(id, routeApiConfig));
  };
}

export function initEditor(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    dispatch(editorFormActions.resetFields([
      'isLoading',
      'isWaiting',
      'editorMode',
      'message',
      'stopId',
      'edgeId',
    ]));
    dispatch(copyingNetworkFormActions.resetForm());
    dispatch(unsetEditableFields());

    const { selectedProject } = selectAcProjectListForm(getState());
    if (!isNumber(selectedProject)) {
      return;
    }

    const { routeId } = selectEditorPageForm(getState());

    const { year, scenarioId, intervalId } = selectEditorGraphForm(getState());

    if (!year || !scenarioId || !intervalId) {
      dispatch(modals.actions.showModal(true, AC_EDITOR_GRAPH_VERSION_MODAL_NAME));
    }

    if (isNumber(routeId) && routeId > 0) {
      dispatch(loadRoute(routeId));
    }
  };
}

export function onEditorProjectChange(): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(modals.actions.showModal(true, AC_EDITOR_GRAPH_VERSION_MODAL_NAME));
  };
}

export function enableViewMode(): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(editorFormActions.resetFields(['editorMode', 'message', 'addIndex', 'addingDirection']));
    dispatch(entitiesEditorFormActions.resetForm());
    dispatch(unsetEditableFields());
  };
}

export function enableModifyRouteMode(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(editorFormActions.changeField('editorMode', EditorMode.addPoint));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
    const selectedRoute = selectEditorSelectedRoute(state).orUndefined();
    dispatch(entitiesEditorFormActions.changeField('editableRoute', selectedRoute));
  };
}

export function enableModifyStopMode(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(editorFormActions.changeField('editorMode', EditorMode.modifyStop));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
    const selectedStop = selectEditorSelectedStop(state).orUndefined();
    dispatch(entitiesEditorFormActions.changeField('editableStop', selectedStop));
  };
}

export function enableAddStopMode(): ThunkActionResult<void> {
  return async (dispatch) => {
    dispatch(editorFormActions.changeField('editorMode', EditorMode.addStop));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
  };
}

export function enableMoveStopMode(): ThunkActionResult<void> {
  return async (dispatch) => {
    dispatch(editorFormActions.changeField('editorMode', EditorMode.moveStop));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
  };
}

export function enableGraphMode(): ThunkActionResult<void> {
  return async (dispatch) => {
    dispatch(editorFormActions.changeField('editorMode', EditorMode.graph));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
  };
}

export function enableModifyEdgeMode(): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const edgeSpeeds = selectEditorEdgeSpeeds(state).orUndefined();
    dispatch(entitiesEditorFormActions.changeField('editableEdgeSpeeds', edgeSpeeds));
    dispatch(editorFormActions.changeField('editorMode', EditorMode.modifyEdge));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
  };
}

export function leaveEdge(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(editorFormActions.resetField('edgeId'));
    const { editorMode } = selectEditorPageForm(state);
    if (EditorMode.modifyEdge === editorMode) {
      dispatch(enableGraphMode());
    }
  };
}

export function enableModifyMode(): ThunkActionResult<void> {
  return async (dispatch) => {
    dispatch(entitiesEditorFormActions.resetForm());
    dispatch(editorFormActions.resetFields([
      'nodeId',
      'edgeId',
      'stopId',
      'directionId',
      'variantId',
      'routeId',
      'message',
      'addIndex',
      'addingDirection',
    ]));
    dispatch(editorFormActions.changeField('editorMode', EditorMode.modify));
  };
}
