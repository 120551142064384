import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { FileInput } from 'src/components';
import { FileRecord, FileType } from 'src/cluster/projects';

import useStyles from './styles';

type OwnProps = {
  isUploading: boolean;
  setFiles: (files: FileRecord[]) => void;
  createFileChangeHandler: (type?: FileType) => (fileList: FileList | null) => void;
}

type Props = OwnProps & WithTranslate;

const ProjectCreatorDND: FC<Props> = (props) => {
  const {
    isUploading,
    t,
    createFileChangeHandler,
  } = props;

  const classes = useStyles();

  const handleFileChange = createFileChangeHandler();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.message}
      >
        {t('modules.projects.captions.dragFilesMessage')}
      </Typography>
      <FileInput
        label={t('modules.projects.captions.selectFilesToUpload')}
        fullWidth
        useProgressAsLabel={false}
        useFileNameAsLabel={false}
        isUploading={isUploading}
        loadingProgress={10}
        accept=".xlsx,.geojson"
        onChange={handleFileChange}
        multiple
      />
    </div>
  );
};

export default ProjectCreatorDND;
