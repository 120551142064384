import React from 'react';

const GeotagInverseIcon = () => (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.4913 7.65C6.00909 7.65 5.54663 7.46563 5.20565 7.13744C4.86468 6.80925 4.67312 6.36413 4.67312 5.9C4.67312 5.43587 4.86468 4.99075 5.20565 4.66256C5.54663 4.33437 6.00909 4.15 6.4913 4.15C6.97351 4.15 7.43597 4.33437 7.77695 4.66256C8.11792 4.99075 8.30948 5.43587 8.30948 5.9C8.30948 6.12981 8.26245 6.35738 8.17108 6.5697C8.07971 6.78202 7.94578 6.97493 7.77695 7.13744C7.60811 7.29994 7.40768 7.42884 7.18709 7.51679C6.9665 7.60474 6.73007 7.65 6.4913 7.65ZM6.4913 1C5.14111 1 3.84621 1.51625 2.89148 2.43518C1.93675 3.3541 1.40039 4.60044 1.40039 5.9C1.40039 9.575 6.4913 15 6.4913 15C6.4913 15 11.5822 9.575 11.5822 5.9C11.5822 4.60044 11.0458 3.3541 10.0911 2.43518C9.13638 1.51625 7.84149 1 6.4913 1Z"
      stroke="currentColor"
    />
  </svg>

);

export default GeotagInverseIcon;
