import React, {
  FC, ElementType,
} from 'react';
import {
  Box,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { FILE_TYPE_DICT, FileType } from 'src/cluster/projects';

const useStyles = makeStyles((theme: Theme) => createStyles({
  caption: {
    marginRight: theme.spacing(1),
    fontStyle: 'italic',
  },
  expansion: {
    color: theme.palette.grey[600],
  },
}));

type Props = {
  type: FileType;
  component? : ElementType;
}

const FileTypeLabel: FC<Props> = (props) => {
  const {
    type,
    component = 'span',
  } = props;

  const classes = useStyles();

  return (
    <Box component={component}>
      <span className={classes.caption}>{FILE_TYPE_DICT[type]?.name}</span>
      <span className={classes.expansion}>
        (
        {FILE_TYPE_DICT[type]?.extension}
        )
      </span>
    </Box>
  );
};

export default FileTypeLabel;
