import { useMemo } from 'react';

import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';
import { useVehicleTypesOptions } from 'src/cluster/editor-map';
import {
  AcRegistryFilters,
  AcRegistryFieldsConfig,
  AcRegistryFiltersEnum,
  useCarrierOptions,
  useCommunicationTypesOptions,
  useTransportTypesOptions,
  useSeasonOptions,
  useRegRouteNumbers,
} from 'src/cluster/editor-table';
import { sortOptionsByNumber } from 'src/helpers';

const useMainFiltersConfig = (
  filters: Partial<AcRegistryFilters>,
  setFilter: (val: SelectValue, field: AcRegistryFiltersEnum) => void,
): AcRegistryFieldsConfig[] => {
  const {
    vehicleTypeId,
    routeNumber,
    regularTransportationType,
    communicationType,
    season,
    carrier,
  } = filters;

  const vehicleTypeOptions = useVehicleTypesOptions();
  const numberOfRouteOptions = useRegRouteNumbers();
  const transportTypesOptions = useTransportTypesOptions();
  const communicationTypeOptions = useCommunicationTypesOptions();
  const seasonOptions = useSeasonOptions();
  const carrierOptions = useCarrierOptions();

  return useMemo(() => {
    const vehicleTypeValue = vehicleTypeOptions.find(opt => opt.value === vehicleTypeId);
    const numberOfRouteValue = numberOfRouteOptions.find(opt => opt.value === routeNumber);
    const transportTypeValue = transportTypesOptions.find(opt => opt.value === regularTransportationType);
    const communicationTypeValue = communicationTypeOptions.find(opt => opt.value === communicationType);
    const seasonValue = seasonOptions.find(opt => opt.value === season);
    const carrierValue = carrierOptions.find(opt => opt.value === carrier);

    return [
      {
        label: 'modules.registries.columns.vehicleTypeFull',
        multiple: false,
        options: vehicleTypeOptions,
        selected: vehicleTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcRegistryFiltersEnum.vehicleTypeId);
        },
      },
      {
        label: 'modules.registries.columns.routeNumberFull',
        multiple: false,
        options: numberOfRouteOptions,
        selected: numberOfRouteValue,
        disabled: !vehicleTypeId,
        sort: sortOptionsByNumber,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcRegistryFiltersEnum.routeNumber);
        },
      },
      {
        label: 'modules.registries.columns.regularTransportationType',
        multiple: false,
        options: transportTypesOptions,
        selected: transportTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcRegistryFiltersEnum.regularTransportationType);
        },
      },
      {
        label: 'modules.registries.columns.communicationType',
        multiple: false,
        options: communicationTypeOptions,
        selected: communicationTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcRegistryFiltersEnum.communicationType);
        },
      },
      {
        label: 'modules.registries.columns.season',
        multiple: false,
        options: seasonOptions,
        selected: seasonValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcRegistryFiltersEnum.season);
        },
      },
      {
        multiple: false,
        last: true,
        label: 'modules.registries.columns.carrier',
        options: carrierOptions,
        selected: carrierValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcRegistryFiltersEnum.carrier);
        },
      },
    ];
  }, [
    filters,
    vehicleTypeOptions,
    numberOfRouteOptions,
    transportTypesOptions,
    communicationTypeOptions,
    seasonOptions,
    carrierOptions,
  ]);
};

export default useMainFiltersConfig;
