export default {
  defaults: {
    transportType: undefined,
    edgeId: undefined,
    pageSize: 10,
    currentPage: 1,
    search: undefined,
  },
  onRouteChangeReset: {
    pageSize: 10,
    currentPage: 1,
  },
};
