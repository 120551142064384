import React from 'react';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { WithUnit } from 'src/components';

import { useWidthRange, useRadiusRange } from 'src/cluster/distribution-map';

import useStyles from './styles';
import useDistributionUnit from '../../../hooks/common/useDistributionUnit';

const DistributionDiagramMapLegend = (props: WithTranslate) => {
  const { t } = props;

  const classes = useStyles();
  const widthRange = useWidthRange();
  const radiusRange = useRadiusRange();
  const unit = useDistributionUnit();

  return (
    <div className={classes.root}>
      <WithUnit
        unit={unit}
        className={classes.label}
      >
        {t('systems.distribution.captions.trafficByInterStopRuns')}
      </WithUnit>
      <div className={clsx(classes.diagram, classes.edges)}>
        {widthRange.sort((a, b) => b.from - a.from).map((item, index) => (
          <div
            key={item.value}
            className={classes.item}
            style={{ height: item.value, width: `${1 / widthRange.length * 100}%` }}
            data-label={index === 0 ? `> ${item.from}` : item.to}
          />
        ))}
      </div>
      <WithUnit
        unit={unit}
        className={classes.label}
      >
        {t('systems.distribution.captions.trafficByStoppingPoints')}
      </WithUnit>
      <div className={clsx(classes.diagram, classes.stops)}>
        {radiusRange.sort((a, b) => b.from - a.from).map((item, index) => (
          <div
            key={item.value}
            className={classes.item}
            style={{ height: 2 * item.value, width: 2 * item.value }}
            data-label={index === 0 ? `> ${Math.round(item.from)}` : Math.round(item.to)}
          />
        ))}
      </div>
    </div>
  );
};

export default DistributionDiagramMapLegend;
