import React from 'react';

import { Button } from 'src/components';

import useStyles from './styles';

const ConfirmModal = ({
  onAny = () => undefined,
  onAccept = () => undefined,
  onDecline = () => undefined,

  showAccept = true,
  showDecline = true,

  ...other
}) => {
  const classes = useStyles();

  const text = other.text || 'Вы уверены?';

  const acceptButtonText = other.acceptButtonText || 'Ок';

  const declineButtonText = other.declineButtonText || 'Отмена';

  const handleAny = (action: Function) => {
    onAny();
    action();
  };

  return (
    <div className={classes.root}>
      {
        other.title &&
        <div className={classes.title}>
          {other.title}
        </div>
      }
      <div className={classes.text}>
        {text}
      </div>
      {
        (showDecline || showAccept) &&
        <div className={classes.buttonsContainer}>
          {showDecline && (
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => handleAny(onDecline)}
            >
              {declineButtonText}
            </Button>
          )}
          {showAccept && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => handleAny(onAccept)}
            >
              {acceptButtonText}
            </Button>
          )}
        </div>
      }
    </div>
  );
};

export default ConfirmModal;
