import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    lineHeight: '23px',
    margin: 15,
    position: 'relative',
  },
  text: {
    color: theme.palette.text.primary,
    fontFamily: ffSourceSansPro,
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '18px',
    padding: 15,
    whiteSpace: 'pre-line',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(8),
  },
  button: {
    padding: '5px 50px',
    marginLeft: theme.spacing(4),
  },
}));
