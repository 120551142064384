import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.grey[500],
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 6,
    padding: theme.spacing(3, 3, 3, 3),
  },
  message: {
    fontSize: 14,
    lineHeight: '15px',
    fontWeight: 400,
    flexDirection: 'column',
    textAlign: 'center',
    color: theme.palette.grey[900],
    padding: theme.spacing(4, 0, 5),
  },
}));
