import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => createStyles({
  root: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: palette.grey[800],
  },
  topLevel: {
    maxWidth: 400,
    marginBottom: spacing(6),
    marginLeft: spacing(5),
  },
  bottomLevel: {
    maxWidth: 400,
    marginBottom: spacing(15),
    marginLeft: spacing(5),
  },
  btn: {
    width: 190,
  },
}));
