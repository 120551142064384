import { api } from 'redux-restify';

import app from 'src/app';
import i18n from 'src/i18n';
import { ThunkActionResult } from 'src/reducer';
import { isNumber, createFormActions, createModelActions } from 'src/helpers';
import modals from 'src/modals';
import { RequestStatus } from 'src/types';

import {
  AC_COPY_REQUESTS_MODEL_NAME,
  AC_PROJECT_LIST_FORM_NAME,
  AC_PROJECTS_MODEL_NAME,
  AcCopyRequestModel,
  AcProjectListForm, CopyingType,
  isAcCopyRequestsModel,
} from 'src/cluster/common';
import {
  AC_PROJECT_CREATE_FORM_NAME,
  AC_UPLOADING_FILE_ENDPOINT,
  AcProjectCreateForm,
  AcProjectsModel,
  FileRecord,
  selectAcProjectCreateForm,
  ProjectFile,
  FileType,
} from 'src/cluster/projects';

export const projectCreateFormActions = createFormActions<AcProjectCreateForm>(AC_PROJECT_CREATE_FORM_NAME);

export const projectListFormActions = createFormActions<AcProjectListForm>(AC_PROJECT_LIST_FORM_NAME);

export const projectsModelActions = createModelActions<AcProjectsModel>(AC_PROJECTS_MODEL_NAME);

export const copyRequestsModelActions = createModelActions<AcCopyRequestModel>(AC_COPY_REQUESTS_MODEL_NAME);

export function postFile(data: FileRecord) {
  return api.actions.callPost({
    url: AC_UPLOADING_FILE_ENDPOINT,
    data,
  });
}

export function startCreatingProject(): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { name, files } = selectAcProjectCreateForm(state);
    try {
      const responses = await Promise.all(files.map((file) => {
        return dispatch(postFile(file));
      }));

      const isSuccess = responses.every(res => res.status === 201 && isNumber(res.data.id));

      if (isSuccess) {
        const sourceFiles: ProjectFile[] = responses.map((res, index) => ({
          fileId: res.data.id as number, fileName: res.data.name as string, fileType: files[index].type as FileType,
        }));
        const values: Partial<AcProjectsModel> = {
          name,
          sourceFiles,
        };
        await dispatch(projectsModelActions.create(values));
        dispatch(projectCreateFormActions.resetForm());
      } else {
        dispatch(app.actions.toast.error(i18n.t('modules.projects.messages.fileUploadDefaultError')));
      }
    } catch (e) {
      dispatch(app.actions.toast.error(i18n.t('modules.projects.messages.fileUploadDefaultError')));
      console.error(e);
    }
  };
}

export function reloadProject(project: AcProjectsModel): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(projectsModelActions.loadById(project.id));
  };
}

export function reloadCopyRequest(request: AcCopyRequestModel): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    const { status } = await dispatch(copyRequestsModelActions.loadById(request.id));
    if (status !== RequestStatus.IN_PROGRESS) {
      dispatch(projectsModelActions.clearData());
      dispatch(copyRequestsModelActions.clearData());
    }
  };
}

export function renameProject(id: number, name: string): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    try {
      await dispatch(projectsModelActions.patch(id, { name }));
    } catch (e) {
      dispatch(app.actions.toast.error(i18n.t('modules.projects.messages.renameError')));
      console.error(e);
    }
  };
}

export function copyProject(id: number): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    try {
      const { status, data } = await dispatch(copyRequestsModelActions.create({
        type: CopyingType.project,
        inputData: {
          projectId: id,
        },
      }));

      if (status === 201 && isAcCopyRequestsModel(data)) {
        dispatch(copyRequestsModelActions.clearData());
      }

      dispatch(projectsModelActions.clearData());
    } catch (e) {
      dispatch(app.actions.toast.error(i18n.t('modules.projects.messages.copyError')));
      console.error(e);
    }
  };
}

export function confirmDeletingProject(project: AcProjectsModel): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('common.captions.delete'),
      text: i18n.t('modules.projects.messages.removeConfirmation', { name: project.name }),
      onAccept: () => {
        dispatch(projectListFormActions.resetField('selectedProject'));
        dispatch(projectsModelActions.delete(project.id));
      },
    }));
  };
}

export function confirmCopyingProject(project: AcProjectsModel): ThunkActionResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.projects.captions.copy'),
      text: i18n.t('modules.projects.messages.copyConfirmation', { name: project.name }),
      onAccept: () => {
        dispatch(copyProject(project.id));
        dispatch(projectListFormActions.resetField('selectedProject'));
      },
    }));
  };
}
