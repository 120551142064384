import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';

import { DISTRIBUTION_DIRECTION_EDGES_ENDPOINT } from 'src/cluster/distribution-table';

export default {
  endpoint: DISTRIBUTION_DIRECTION_EDGES_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    order: undefined,
    firstStopName: undefined,
    lastStopName: undefined,
    boardingSum: undefined,
    boardingPerVehicle: undefined,
    getOffSum: undefined,
    getOffPerVehicle: undefined,
    passengerTraffic: undefined,
    fillingPerVehicleAbs: undefined,
    fillingPerVehicleNorm: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
  allowIdRequests: false,
};
