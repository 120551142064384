import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffOxygen } from 'src/theme/typography';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: ffOxygen,
    fontSize: 12,

    '& td': {
      padding: '2px 4px',
      textAlign: 'left',

      '& td:first-of-type': {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.grey[500],
      },
    },

    '& tr:not(:last-of-type)': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[500],
    },
  },

  value: {
    fontWeight:
      'bold',
  },
}));
