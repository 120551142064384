import React, { ChangeEvent, FC, Fragment } from 'react';
import { Typography } from '@material-ui/core';

import { AcDashCategoriesModel } from 'src/cluster/dashboard-common';
import { translateCategoryName } from 'src/cluster/dashboard-table';

import { IndicatorItem } from '../../components';

import useStyles from './styles';

type Props = {
  level: number;
  category: AcDashCategoriesModel;
  hiddenCategories: string[];
  setHiddenCategories(value: ChangeEvent<HTMLInputElement>, checked: boolean): void;
}

const CategoryItem: FC<Props> = (props) => {
  const {
    level, category, hiddenCategories, setHiddenCategories,
  } = props;

  const classes = useStyles(level);

  return (
    <Fragment key={category.id}>
      <div className={classes.category}>
        <Typography className={level === 1 ? classes.firstLevel : classes.secondLevel}>
          {translateCategoryName(category.name)}
        </Typography>
      </div>
      {category.subcategories.map((subcategory) => {
        return (
          <CategoryItem
            key={subcategory.id}
            category={subcategory}
            hiddenCategories={hiddenCategories}
            level={level + 1}
            setHiddenCategories={setHiddenCategories}
          />
        );
      })}
      {category.indicators.map((indicator) => {
        return (
          <IndicatorItem
            key={indicator.id}
            level={level + 1}
            name={indicator.name}
            checked={!hiddenCategories.includes(indicator.name)}
            setHiddenCategories={setHiddenCategories}
          />
        );
      })}
    </Fragment>
  );
};

export default CategoryItem;
