import React from 'react';

const ChevronLeftIcon = () => (
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 8.1212708,1.3752501 3.6,5.8505003 8.1212708,10.32575 6.7225302,11.7005 0.8123708,5.8505003 6.7225302,5e-4 Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronLeftIcon;
