import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'src/auth';
import { withTranslate } from 'src/i18n';
import NotFound from './NotFound';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(withTranslate(NotFound));
