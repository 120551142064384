import React from 'react';

const PedestrianIcon = () => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.52208 15.2163C8.36233 14.3576 7.74332 11.642 7.60355 10.9431C7.58358 10.8433 7.52367 10.7434 7.46377 10.6635L5.38708 8.60682V5.01256C5.38708 4.83284 5.34714 4.51335 5.2473 4.37358L4.54842 3.57485C4.26887 3.27533 3.82957 3.19545 3.47014 3.4151L0.874281 5.01256C0.734504 5.09243 0.654631 5.25217 0.654631 5.41192L0.594727 8.40714C0.594727 9.10603 1.79282 9.14596 1.79282 8.40714V6.21064C1.79282 5.89115 1.83275 5.79131 1.9925 5.71144L2.89106 5.1124C2.95097 5.07246 2.9909 5.1124 2.9909 5.21224V8.20746C2.9909 8.34724 3.09074 8.48701 3.19059 8.58685L6.16584 11.642C6.20578 11.6819 6.22574 11.7219 6.22574 11.7618C6.34555 12.241 7.04444 15.0166 7.22415 15.5757C7.4438 16.1747 8.72176 16.1947 8.52208 15.2163Z"
      fill="currentColor"
    />
    <path
      d="M5.98535 6.41013V7.8079C5.98535 7.8079 7.06363 8.90615 7.28328 9.1258C7.72258 9.5651 8.48137 8.80631 8.04207 8.34704C7.66268 7.94768 6.50452 6.56988 6.18503 6.21045C6.12513 6.21045 5.98535 6.25039 5.98535 6.41013Z"
      fill="currentColor"
    />
    <path
      d="M3.07034 9.48534C2.65101 10.2441 0.334704 14.5572 0.115054 14.8967C-0.384149 15.6954 0.873843 16.4343 1.35308 15.6954C1.6526 15.2362 4.2884 10.4837 4.2884 10.4837L3.23008 9.46537C3.19015 9.40546 3.11028 9.42543 3.07034 9.48534Z"
      fill="currentColor"
    />
    <path
      d="M5.14691 3.13549C5.78589 2.83597 5.88573 1.59795 5.44643 0.739316C5.12694 0.100336 4.36815 -0.179218 3.7092 0.120304C3.07022 0.419826 2.81063 1.19858 3.09019 1.85753C3.48955 2.71616 4.50793 3.45498 5.14691 3.13549Z"
      fill="currentColor"
    />
  </svg>
);

export default PedestrianIcon;
