import React, {
  ChangeEvent, FC, ReactText, useState,
} from 'react';

import i18n, { WithTranslate } from 'src/i18n';
import { isString } from 'src/helpers/type-guards';

import { Button, SelectField, TextField } from 'src/components';
import { useFactors } from 'src/cluster/balance-data';

import {
  AcBalanceDataModel, ValidationMessages, getSelectValue, useAcNodesOptions,
} from 'src/cluster/common';
import useFactorsEdit from './hooks';

import useStyles from './styles';

function validate(record: Partial<AcBalanceDataModel>): [boolean, ValidationMessages, ValidationMessages] {
  const fieldMessages = {
    region: record.regionId
      ? undefined
      : i18n.t('modules.ttc.validation.nonEmpty'),
    year: (Number(record.year) > 1900 && Number(record.year) < 2100)
      ? undefined
      : i18n.t('modules.ttc.validation.year'),
  };

  const factorMessages = record.factors ? Object.fromEntries(record.factors.map((factor) => ([
    factor.id,
    (Number(factor.value) >= 0)
      ? undefined
      : i18n.t('modules.ttc.validation.nonNegative'),
  ]))) as ValidationMessages : {};

  const areFieldsValid = Object.values(fieldMessages).every((message) => !message);
  const areFactorsValid = Object.values(factorMessages).every((message) => !message);

  return [areFieldsValid && areFactorsValid, fieldMessages, factorMessages];
}

interface Props extends WithTranslate {
  record: AcBalanceDataModel;
  onEdit: (record: Partial<AcBalanceDataModel>) => void;
  close: () => void;
}

const ModalDataEdit: FC<Props> = (props) => {
  const {
    record,
    onEdit,
    close,
    t,
  } = props;

  const classes = useStyles();

  const nodesItems = useAcNodesOptions();
  const factorsEntities = useFactors();

  const [regionId, setRegionId] = useState<number | undefined>(record.regionId);
  const [year, setYear] = useState<number>(record.year);
  const [source, setSource] = useState<string>(record.source);
  const [factors, dispatchFactorEdit] = useFactorsEdit(record.factors);

  const handleRegionChange = (event: ChangeEvent<{ value: unknown }>): void => {
    if (event) setRegionId(Number(event.target.value));
  };

  const handleYearChange = (event: ChangeEvent<{ value: unknown }>): void => {
    if (event) setYear(Number(event.target.value));
  };

  const handleSourceChange = (event: ChangeEvent<{ value: unknown }>): void => {
    if (event) setSource(String(event.target.value));
  };

  const handleFactorChange = (
    factor: { id: ReactText; name: string },
    event: ChangeEvent<{ value: unknown }>) => {
    if (event) dispatchFactorEdit({ id: factor.id, value: Number(event.target.value) });
  };

  const handleSave = () => {
    const nodeItem = nodesItems.find((item) => item.value === regionId);
    const regionName = nodeItem ? String(nodeItem.label) : undefined;
    onEdit({
      ...record,
      regionId,
      regionName,
      year,
      source,
      factors,
    });
    close();
  };

  const [isValid, errors, factorErrors] = validate({
    ...record,
    regionId,
    year,
    factors,
  });

  const isChanged = record.regionId !== regionId
    || record.year !== year
    || record.source !== source
    || record.factors.some(
      (tccItem) => tccItem.value !== factors.find((ti) => ti.id === tccItem.id)?.value,
    );

  return (
    <div className={classes.root}>
      <div className={classes.fields}>
        <div className={classes.field}>
          <SelectField
            label={t('systems.balance.captions.district')}
            options={nodesItems}
            value={getSelectValue(regionId)}
            fullWidth
            disabled
            onChange={handleRegionChange}
          />
        </div>
        <div className={classes.field}>
          <TextField
            label={t('common.captions.year')}
            type="number"
            thousandSeparator=""
            fullWidth
            defaultValue={year}
            error={Boolean(errors.year)}
            disabled
            errorText={errors.year ? [String(errors.year)] : undefined}
            onChange={handleYearChange}

          />
        </div>
        {factorsEntities.map((factorEntity) => {
          const [label] = factorEntity.name.split(', ');
          const factor = record.factors.find((f) => f.id === factorEntity.id) || { value: undefined };
          const { value } = factor;
          const message = factorErrors[factorEntity.id];
          const errorMessages = isString(message) ? [message] : undefined;
          return (
            <div key={factorEntity.id} className={classes.field}>
              <TextField
                label={label}
                type="number"
                fullWidth
                value={value}
                error={Boolean(errorMessages)}
                thousandSeparator=" "
                errorText={errorMessages || undefined}
                onChange={(event) => handleFactorChange(factorEntity, event)}
              />
            </div>
          );
        })}
        <div className={classes.field}>
          <TextField
            label={t('systems.balance.captions.comment')}
            type="text"
            fullWidth
            value={source}
            onChange={handleSourceChange}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={close}
        >
          {t('common.captions.cancel')}
        </Button>
        <Button
          className={classes.button}
          onClick={handleSave}
          disabled={!isValid || !isChanged}
        >
          {t('common.captions.save')}
        </Button>
      </div>
    </div>
  );
};

export default ModalDataEdit;
