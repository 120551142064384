import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { selectAcBalanceMapPageForm } from 'src/cluster/balance-map';

import BalanceMapPage from './BalanceMapPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectAcBalanceMapPageForm(state),
});

export default connect(mapStateToProps)(withTranslate(BalanceMapPage));
