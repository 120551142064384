import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import {
  selectEditorPageForm,
  useRouteNumbers,
  useVehicleTypesOptions,
} from 'src/cluster/editor-map';

const useFilterValues = (): [SelectMenuOption?, SelectMenuOption?] => {
  const { vehicleTypeId, routeId } = useSelector(selectEditorPageForm);

  const vehicleTypeOptions = useVehicleTypesOptions();
  const routeNumbers = useRouteNumbers();

  const vehicleTypeValue = vehicleTypeOptions.find(vehicle => vehicle.value === vehicleTypeId);
  const routeNumberValue = routeNumbers.find(route => route.value === routeId);

  return [
    vehicleTypeValue,
    routeNumberValue,
  ];
};

export default useFilterValues;
