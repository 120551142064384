import React, { FC, Fragment } from 'react';

import i18n from 'src/i18n';

import { ErrorIcon } from 'src/components';
import useStyles from './styles';

const ERROR_LENGTH = 600;
const ENDING = '...';

function textSlice(str: string, length: number) {
  if (str.length > length) {
    return str.substring(0, length - ENDING.length) + ENDING;
  }
  return str;
}

interface OwnProps {
  errors: string[];
}

interface InjectedProps {
  showModal(error: string): void;
}

type Props = OwnProps & InjectedProps;

const ErrorGrid: FC<Props> = (props) => {
  const { errors, showModal } = props;
  const classes = useStyles();

  const handleClick = (error: string) => {
    showModal(error);
  };

  return (
    <table className={classes.root}>
      <tbody>
        {errors.map((error) => {
          return (
            <tr className={classes.row} key={error}>
              <td className={classes.firstColumn} />
              <td className={classes.icon}>
                <ErrorIcon />
              </td>
              <td className={classes.message}>
                <span>
                  {error.length > ERROR_LENGTH ? (
                    <Fragment>
                      <span>{textSlice(error, ERROR_LENGTH)}</span>
                      <span className={classes.modalLink} onClick={() => handleClick(error)}>
                        {i18n.t('systems.agglomeration.captions.fullTextError')}
                        {' '}
                      </span>
                    </Fragment>
                  ) : error}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ErrorGrid;
