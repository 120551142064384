import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
  },

  radioGroup: {
    padding: theme.spacing(4),
  },

  buttons: {
    display: 'flex',
    margin: '0',
    justifyContent: 'center',
  },

  button: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    margin: '0 10px',
  },
}));
