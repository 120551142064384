import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.grey[800],
  },
}));
