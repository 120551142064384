import React, { useState } from 'react';
import classNames from 'classnames';
import { RestifyModel } from 'redux-restify';

import pageLayoutStyle from 'src/styles/page-layout.module.css';

import { RouteEdgeModel } from 'src/modules/mth/common/interfaces';
import { AgglomerationEdgeTypes } from 'src/modules/mth/common/types';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import { AGGLOMERATION_EDGE_TYPES, TRANSPORT_TYPE_ITEMS } from 'src/modules/mth/common/constants';

import { WithTranslate } from 'src/i18n';
import EdgeTable from '../AggloMapFilterEdgeTable';

import style from './index.module.css';

const transportTypes: string[] = Object.keys(AGGLOMERATION_EDGE_TYPES).filter(
  (edgeType) => edgeType !== AGGLOMERATION_EDGE_TYPES.taxi
    && edgeType !== AGGLOMERATION_EDGE_TYPES.auto,
);

const autoTypes: string[] = Object.keys(AGGLOMERATION_EDGE_TYPES).filter(
  (edgeType) => edgeType === AGGLOMERATION_EDGE_TYPES.taxi
    || edgeType === AGGLOMERATION_EDGE_TYPES.car
    || edgeType === AGGLOMERATION_EDGE_TYPES.cargo,
);

interface Props extends WithTranslate {
  className?: string;
  edges?: RestifyModel<RouteEdgeModel>[];
  transportType?: AgglomerationEdgeTypes;
  isLoading: boolean;
  isGraph: boolean;
  showCorrespondences?: boolean;
}

const AggloMapFilterEdges: React.FC<Props> = (props: Props) => {
  const {
    className,
    edges = [],
    isLoading = false,
    isGraph,
    transportType,
    showCorrespondences = false,
    t,
  } = props;
  const [visibleTypes, setVisibleTypes] = useState<{ [key: string]: boolean }>(
    transportTypes.reduce((acc: { [key: string]: boolean }, type: string) => {
      const newAcc = { ...acc };
      newAcc[type] = false;
      return acc;
    }, {}),
  );

  const renderItems = (routeEdges: RouteEdgeModel[], types: string[]) => types.map((edgeType: string) => {
    const expanded = visibleTypes[edgeType];
    const show = !isLoading && routeEdges[0]?.[edgeType].id;
    return show ? (
      <li key={edgeType}>
        <button {...{
          type: 'button',
          className: style.typeSwitch,
          onClick: () => setVisibleTypes({
            ...visibleTypes,
            [edgeType]: !visibleTypes[edgeType],
          }),
        }}>
          <PIcon {...{
            className: style.expandIcon,
            type: ICONS_TYPES.arrow,
            rotate: expanded ? ROTATE_TYPES.up : ROTATE_TYPES.left,
          }} />
          {t(TRANSPORT_TYPE_ITEMS[edgeType])}
        </button>
        { expanded && routeEdges.map((edge: RouteEdgeModel, index) => (
          <EdgeTable {...{
            key: index,
            type: edgeType,
            edge: isLoading ? undefined : edge[edgeType],
            showCorrespondences,
          }}
          />
        ))}
      </li>
    ) : null;
  });

  const renderAutoGraphEdge = (routeEdges: RouteEdgeModel[]) => {
    if (transportType) {
      return transportType === AGGLOMERATION_EDGE_TYPES.auto
        ? !!routeEdges.length && renderItems(routeEdges, autoTypes)
        : routeEdges.map((edge: RouteEdgeModel, index) => (
          <EdgeTable {...{
            key: index,
            type: transportType,
            edge: isLoading ? undefined : edge[transportType],
          }} />
        ));
    }
    return null;
  };

  return (
    <div className={classNames(style.root, className)}>
      <div className={pageLayoutStyle.toolbarTitle}>
        {t('systems.mth.captions.edgeInfo')}
      </div>
      <LoadingBlockContainer {...{
        className: classNames(style.edgeTableList, isLoading && style.edgeTableListLoading),
        isBlocked: isLoading,
        component: 'ul',
      }}>
        {isGraph
          ? renderAutoGraphEdge(edges)
          : renderItems(edges, transportTypes)}
      </LoadingBlockContainer>
    </div>
  );
};

export default AggloMapFilterEdges;
