import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { featureCollection, makeGeoJsonPoints } from 'src/helpers';

import {
  NodeModel,
  selectPolygonsGeojsonEndpoint,
  useInteractiveLayers,
} from 'src/cluster/common';
import {
  MapModes,
  ViewModes,
  selectDistributionMapPageForm,
  selectDistributionGeojsonEndpoint,
} from 'src/cluster/distribution-map';

import useHeatByVehicleTypesLayers from './useHeatByVehicleTypesLayer';
import useDiagramByVehicleTypesLayers from './useDiagramByVehicleTypesLayers';
import useDiagramByRoutesLayers from './useDiagramByRoutesLayers';
import useStopsByVehicleTypesLayers from './useStopsByVehicleTypesLayers';
import useStopsByRoutesLayers from './useStopsByRoutesLayers';
import useHeatByRoutesLayer from './useHeatByRoutesLayer';

const useDistributionMapStyle = (nodes: NodeModel[]): [object, string[]] => {
  const [edgesGeojsonUrl, stopsGeoJsonUrl] = useSelector(selectDistributionGeojsonEndpoint);
  const polygonsUrl = useSelector(selectPolygonsGeojsonEndpoint);
  const {
    mapMode,
    viewMode,
    showEdges,
    showStops,
    vehicleTypeIds,
    vehicleTypeId,
    variantId,
  } = useSelector(selectDistributionMapPageForm);

  const pointExtras = {
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50,
  };

  const sources = useMemo(() => ({
    points: makeGeoJsonPoints(nodes, pointExtras),
    polygons: {
      type: 'geojson',
      data: polygonsUrl ?? featureCollection([]),
    },
    stops: {
      type: 'geojson',
      data: stopsGeoJsonUrl ?? featureCollection([]),
    },
    edges: {
      type: 'geojson',
      data: edgesGeojsonUrl ?? featureCollection([]),
    },
  }), [nodes, polygonsUrl, stopsGeoJsonUrl, edgesGeojsonUrl]);

  const byVehicles = viewMode === ViewModes.byVehicles;
  const byDiagrams = mapMode === MapModes.diagram;

  const depsByVehicleTypes = [viewMode, mapMode, edgesGeojsonUrl, vehicleTypeIds];
  const diagramByVehicleTypesLayers = useDiagramByVehicleTypesLayers(
    vehicleTypeIds, byDiagrams && byVehicles && showEdges, depsByVehicleTypes,
  );
  const stopsByVehicleTypesLayers = useStopsByVehicleTypesLayers(
    vehicleTypeIds, byDiagrams && byVehicles && showStops, depsByVehicleTypes,
  );

  const depsByRoutes = [viewMode, mapMode, edgesGeojsonUrl, variantId];
  const variantIds = variantId ? [variantId] : [];

  const diagramByRoutesLayers = useDiagramByRoutesLayers(
    vehicleTypeId, variantIds, byDiagrams && !byVehicles && showEdges, depsByRoutes,
  );

  const stopsByRoutesLayers = useStopsByRoutesLayers(
    vehicleTypeId, variantIds, byDiagrams && !byVehicles && showStops, depsByRoutes,
  );

  const heatByVehicleTypesLayer = useHeatByVehicleTypesLayers(
    vehicleTypeIds[0], !byDiagrams && byVehicles, depsByVehicleTypes,
  );

  const heatByRoutesLayer = useHeatByRoutesLayer(
    variantIds, !byDiagrams && !byVehicles, depsByVehicleTypes,
  );

  const interactiveLayers = useInteractiveLayers();

  const layers = [
    ...interactiveLayers,
    heatByVehicleTypesLayer,
    heatByRoutesLayer,
    ...diagramByVehicleTypesLayers,
    ...diagramByRoutesLayers,
    ...stopsByVehicleTypesLayers,
    ...stopsByRoutesLayers,
  ];

  const mapStyle = { sources, layers };
  const interactiveLayerIds = [
    heatByVehicleTypesLayer.id,
    heatByRoutesLayer.id,
    ...diagramByVehicleTypesLayers.map(layer => layer.id),
    ...diagramByRoutesLayers.map(layer => layer.id),
    ...diagramByVehicleTypesLayers.map(layer => layer.id),
    ...stopsByVehicleTypesLayers.map(layer => layer.id),
    ...stopsByRoutesLayers.map(layer => layer.id),
  ];

  return [
    mapStyle,
    interactiveLayerIds,
  ];
};

export default useDistributionMapStyle;
