import { useSelector } from 'react-redux';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';

import { featureCollection, point } from 'src/helpers';

import { selectNodeGeojson, TrackPoint } from 'src/cluster/common';
import {
  selectEditorPageForm, selectEditorSelectedDirection,
} from 'src/cluster/editor-map';

import useNodeLayer from './useNodeLayer';
import useStopsLayer from './useStopsLayer';

const usePointsLayers = (): [EditableGeoJsonLayer, EditableGeoJsonLayer] => {
  const editableDirection = useSelector(selectEditorSelectedDirection).orUndefined();
  const { nodeId } = useSelector(selectEditorPageForm);

  const points = editableDirection
    ? editableDirection.nodes.map((node: TrackPoint) => point(node.coordinates, {
      id: node.nodeId,
      stopId: node.stopId,
      stopName: node.stopName,
      order: node.order,
    }))
    : [];

  const pointGeoJson = featureCollection(points);
  const nodeGeoJson = useSelector(selectNodeGeojson);
  const selectedIndex = pointGeoJson.features.findIndex((feature) => Number(feature.properties?.id) === nodeId);
  const selectedPointIndexes = selectedIndex === -1 ? [] : [selectedIndex];
  const [index] = selectedPointIndexes;

  const stopLayer = useStopsLayer(index);
  const nodeLayer = useNodeLayer(nodeGeoJson, index);

  return [
    nodeLayer,
    stopLayer,
  ];
};

export default usePointsLayers;
