import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslate } from 'src/i18n';

import { clearProjectData } from 'src/cluster/common';
import { onBalanceMapProjectChange } from 'src/cluster/balance-map';

import BalanceRoot from './BalanceRoot';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onChange: () => {
    dispatch(clearProjectData());
    dispatch(onBalanceMapProjectChange());
  },
});

export default connect(null, mapDispatchToProps)(withRouter<any, any>(withTranslate(BalanceRoot)));
