import React, {
  FC, ReactElement, RefObject, useState,
} from 'react';
import clsx from 'clsx';
import { DeckGL, Layer, DeckProps } from 'deck.gl';
import ReactMapGL, { InteractiveMapProps } from 'react-map-gl';

import { FeatureCollection, Position } from 'src/interfaces';
import { ExcludedTypes, Nullable } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { mergeAndConcatArrays } from 'src/helpers';
import { LoadingBlockContainer } from 'src/components';

import {
  MapStyleID,
  INITIAL_VIEW_STATE,
  MAP_STYLE_DICT,
  MapZoom,
  MapButton,
  useMapFittedToPosition,
  useMapFittedToPositions,
} from 'src/cluster/common';

import { DeckGLMapToggle } from './components';

import useStyles from './styles';

interface OwnProps {
  children?: ReactElement | (ReactElement | undefined | null | false)[];
  mapRef: RefObject<ReactMapGL>;
  isBlocked?: boolean;
  canBeFitted: boolean;
  mapStyle?: object;
  positionForFit?: Nullable<Position>;
  positionsForFit?: Position[];
  layers?: Layer<FeatureCollection>[];
  showLegend?: () => void;
}

type Props = Partial<ExcludedTypes<InteractiveMapProps, DeckProps>> & Partial<DeckProps> & OwnProps & WithTranslate;

const Map: FC<Props> = (props) => {
  const {
    children,
    mapRef,
    isBlocked = false,
    canBeFitted = false,
    positionForFit = null,
    positionsForFit = null,
    layers,
    mapStyle,
    height = '100%',
    width = '100%',
    controller = {
      dragRotate: false,
      touchRotate: false,
      scrollZoom: true,
      dragPan: true,
      doubleClickZoom: false,
      touchZoom: true,
      keyboard: true,
    },
    interactiveLayerIds,
    locale,
    t,
    getCursor,
    getTooltip,
    showLegend,
    onMouseMove,
  } = props;

  const classes = useStyles();

  const [viewport, setViewport] = useState(INITIAL_VIEW_STATE);
  const [styleId, setStyleId] = useState<MapStyleID>('mono');

  useMapFittedToPosition(positionForFit, canBeFitted, viewport, setViewport);
  useMapFittedToPositions(positionsForFit, canBeFitted, viewport, setViewport);

  let currentStyle = MAP_STYLE_DICT[styleId].getStyle(locale);
  if (mapStyle) {
    currentStyle = mergeAndConcatArrays({}, currentStyle, mapStyle);
  }

  const zoomIn = () => {
    if (viewport.zoom && viewport.zoom <= 20) {
      setViewport({
        ...viewport,
        zoom: viewport.zoom + 1,
      });
    }
  };

  const zoomOut = () => {
    if (viewport.zoom && viewport.zoom >= 2) {
      setViewport({
        ...viewport,
        zoom: viewport.zoom - 1,
      });
    }
  };

  return (
    <LoadingBlockContainer
      className={clsx(classes.root, showLegend && classes.withLegend)}
      blocking={false}
      isBlocked={isBlocked}
    >
      {layers ? (
        <DeckGL
          viewState={viewport}
          layers={layers}
          controller={controller}
          pickingRadius={10}
          getCursor={getCursor}
          getTooltip={getTooltip}
          onViewStateChange={({ viewState }) => setViewport(viewState)}
        >
          <ReactMapGL
            height={height}
            width={width}
            ref={mapRef}
            mapStyle={currentStyle}
            interactiveLayerIds={interactiveLayerIds}
            onViewStateChange={({ viewState }) => setViewport(viewState)}
          >
            {children}
          </ReactMapGL>
        </DeckGL>
      ) : (
        <ReactMapGL
          {...viewport}
          height={height}
          width={width}
          ref={mapRef}
          clickRadius={10}
          mapStyle={currentStyle}
          interactiveLayerIds={interactiveLayerIds}
          onViewStateChange={({ viewState }) => setViewport(viewState)}
          onMouseMove={onMouseMove}
        >
          {children}
        </ReactMapGL>
      )}

      <div className={clsx(classes.tools, classes.rightTools)}>
        <MapZoom zoomIn={zoomIn} zoomOut={zoomOut} />
        <DeckGLMapToggle styleId={styleId} setStyleId={setStyleId} />
      </div>

      {showLegend && (
        <div className={clsx(classes.tools, classes.bottomTools)}>
          <MapButton
            className={classes.legendToggle}
            onClick={showLegend}
          >
            {t('modules.editor.drawer.legend')}
          </MapButton>
        </div>
      )}
    </LoadingBlockContainer>
  );
};

export default Map;
