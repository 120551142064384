import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getVocabularyValue, useCurrentVocabulary, WithTranslate } from 'src/i18n';
import PSelect from 'src/components/deprecated/PSelect';
import { PageRoutesForm, ROUTES_PAGES_FORM_NAMES, TRANSPORT_TYPES } from 'src/modules/cities/routes';
import { useRestifyForm } from 'src/helpers';
import { useSystemsContext } from 'src/constants';
import { NodeModel, RouteModel, EdgeModel } from 'src/modules/cities/routes/constants';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import style from '../CitiesCalcRoutesMap/index.module.css';

interface Props extends WithTranslate {
  currentNodes: NodeModel[];
  currentRoutes: RouteModel[];
}

const CitiesMapFilterPanel: FC<Props> = ({
  currentNodes,
  currentRoutes,
  t,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [
    routesPageForm,
    routesPageFormActions,
  ] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);
  const [currentLocale, vocabulary] = useCurrentVocabulary();
  let selectedEdge: EdgeModel | undefined;
  let selectedEdgeTotalTraffic = 0;
  if (routesPageForm.selectedEdge !== undefined) {
    // find edge first appearance in routes
    selectedEdge = currentRoutes.reduce((memo: EdgeModel | undefined, route) => {
      if (memo) {
        return memo;
      }
      return route.traffic.find(el => el.edgeId === routesPageForm.selectedEdge);
    }, undefined);
    // sum all edges traffic
    selectedEdgeTotalTraffic = currentRoutes.reduce((memo, route) => {
      return memo + route.traffic.reduce((trafficMemo, edge) => {
        return (edge.edgeId === routesPageForm.selectedEdge) ? trafficMemo + edge.traffic : trafficMemo;
      }, 0);
    }, 0);
  }
  const formatNumber = (num: number | undefined) => (num ? num.toFixed(2) : num);

  useEffect(() => {
    dispatch(routesPageFormActions.changeField('transportType', undefined));
    dispatch(routesPageFormActions.changeField('from', undefined));
    dispatch(routesPageFormActions.changeField('to', undefined));
    dispatch(routesPageFormActions.resetField('selectedRoute'));
    dispatch(routesPageFormActions.resetField('selectedEdges'));
  }, []);

  return (
    <div className={style.mapFilterPanel}>
      <div className={style.toolbarSection}>
        <div className={pageLayoutStyle.toolbarTitle}>
          {t('common.captions.parameters')}
        </div>
        <PSelect {...{
          label: t('systems.cities.captions.transportType'),
          className: style.select,
          items: Object.keys(TRANSPORT_TYPES).map(transportKey => ({
            value: TRANSPORT_TYPES[transportKey],
            label: transportKey,
          })),
          isClearable: true,
          values: routesPageForm.transportType === undefined ? undefined : [routesPageForm.transportType],
          onChange: values => {
            dispatch(routesPageFormActions.changeField('transportType', values[0]));
            dispatch(routesPageFormActions.resetField('selectedRoute'));
            if (selectedEdge === undefined) {
              dispatch(routesPageFormActions.resetField('selectedEdges'));
            }
            if (values[0] === undefined) {
              dispatch(routesPageFormActions.changeField('from', undefined));
              dispatch(routesPageFormActions.changeField('to', undefined));
            }
          },
        }} />
      </div>
      {routesPageForm.transportType !== undefined &&
        <div className={style.toolbarSection}>
          <div className={pageLayoutStyle.toolbarTitle}>
            {t('common.captions.correspondence')}
          </div>
          <PSelect {...{
            label: t('common.captions.from'),
            className: style.select,
            items: currentNodes.map(n => ({
              value: n.name,
              label: getVocabularyValue(currentLocale, vocabulary, n.name),
            })),
            isClearable: true,
            values: routesPageForm.from === undefined ? undefined : [routesPageForm.from],
            onChange: values => {
              dispatch(routesPageFormActions.changeField('from', values[0]));
              if (selectedEdge === undefined) {
                dispatch(routesPageFormActions.resetField('selectedEdges'));
              }
              dispatch(routesPageFormActions.resetField('selectedRoute'));
            },
          }} />

          <PSelect {...{
            label: t('common.captions.to'),
            className: style.select,
            items: currentNodes.map(n => ({
              value: n.name,
              label: getVocabularyValue(currentLocale, vocabulary, n.name),
            })),
            isClearable: true,
            placeholder: 'Select',
            values: routesPageForm.to === undefined ? undefined : [routesPageForm.to],
            onChange: values => {
              dispatch(routesPageFormActions.changeField('to', values[0]));
              if (selectedEdge === undefined) {
                dispatch(routesPageFormActions.resetField('selectedEdges'));
              }
              dispatch(routesPageFormActions.resetField('selectedRoute'));
            },
          }} />
        </div>}
      {selectedEdge &&
        <div className={style.toolbarSection}>
          <div className={pageLayoutStyle.toolbarTitle}>
            {t('systems.cities.captions.edgeInfo')}
          </div>
          <table className={style.edgeInfoTable}>
            <tbody>
              <tr>
                <td>
                  {t('systems.cities.captions.traffic')}
                </td>
                <td>
                  {formatNumber(selectedEdgeTotalTraffic)}
                  &nbsp;
                  {t('common.units.items')}
                </td>
              </tr>
              <tr>
                <td>
                  {t('systems.cities.captions.edgeId')}
                </td>
                <td>
                  {selectedEdge.edgeId}
                </td>
              </tr>
              <tr>
                <td>
                  {t('systems.cities.captions.length')}
                </td>
                <td>
                  {formatNumber(selectedEdge.distance)}
                  &nbsp;
                  {t('common.units.length')}
                </td>
              </tr>
              {routesPageForm.transportType !== undefined &&
                <tr>
                  <td>
                    {t('systems.cities.captions.fuelCost')}
                  </td>
                  <td>
                    {formatNumber(selectedEdge.fuelCost)}
                    &nbsp;
                    {t('common.units.costRub')}
                  </td>
                </tr>}
              <tr>
                <td>
                  {t('systems.cities.captions.time')}
                </td>
                <td>
                  {formatNumber(selectedEdge.time * 60)}
                  &nbsp;
                  {t('common.units.timeMinutes')}
                </td>
              </tr>
              {routesPageForm.transportType !== undefined &&
                <tr>
                  <td>
                    {t('systems.cities.captions.timeMoney')}
                  </td>
                  <td>
                    {formatNumber(selectedEdge.timeMoney)}
                    &nbsp;
                    {t('common.units.costRub')}
                  </td>
                </tr>}
              <tr>
                <td>
                  {t('systems.cities.captions.fare')}
                </td>
                <td>
                  {formatNumber(selectedEdge.fare)}
                  &nbsp;
                  {t('common.units.costRub')}
                </td>
              </tr>
              {routesPageForm.transportType !== undefined &&
                <tr>
                  <td>
                    {t('systems.cities.captions.ttc')}
                  </td>
                  <td>
                    {formatNumber(selectedEdge.weight)}
                    &nbsp;
                    {t('common.units.costRub')}
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>}
    </div>
  );
};

export default CitiesMapFilterPanel;
