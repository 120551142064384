import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { forms, RestifyId } from 'redux-restify';
import classNames from 'classnames';

import { useRestifyForm, useRestifyModel } from 'src/helpers';
import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';
import {
  CalculationModel,
  CALCULATIONS_STATUSES,
  CalculationsTable,
  CalcResultMapButton,
} from 'src/modules/general/calculations';
import {
  CALCULATIONS_MODEL_NAME,
  CALCULATIONS_PAGES_FORM_NAME,
  PageAgglomerationCalculationForm,
} from 'src/modules/mth/calculations';

import style from './index.module.css';

interface Props {
  className?: string;
  patchCalculationActuality(id: RestifyId, value: boolean): void;
  showCalculationsMap: (id: number) => void;
  exportCalculation: (id: number) => void;
}

const AggloCalcTable: React.FC<Props> = (props) => {
  const {
    className,
    patchCalculationActuality,
    showCalculationsMap,
    exportCalculation,
  } = props;

  const dispatch = useDispatch();
  const [calculationsEntities] = useRestifyModel<CalculationModel>(CALCULATIONS_MODEL_NAME);
  const [form, formActions] = useRestifyForm<PageAgglomerationCalculationForm>(CALCULATIONS_PAGES_FORM_NAME);
  const calculationsApiConfig = {
    filter: {
      page: form.currentPage,
      pageSize: form.pageSize,
      isActual: form.isActual ? true : undefined,
      version: form.version,
    },
  };
  const calculations = calculationsEntities.getArray(calculationsApiConfig);
  const isLoading = calculationsEntities.getIsLoadingArray(calculationsApiConfig);
  const count = calculationsEntities.getCount(calculationsApiConfig);

  useEffect(() => {
    return () => {
      calculations.forEach((calculation) => {
        if (calculation.isNew) {
          dispatch(forms.actions.sendQuickForm({
            model: CALCULATIONS_MODEL_NAME,
            id: calculation.id,
            values: {
              isNew: false,
            },
          }));
        }
      });
    };
  }, []);

  return (
    <CalculationsTable {...{
      className: classNames(className, style.root),
      calculations,
      isLoading,
      count,
      form,
      formActions,
      hasActuality: true,
      onPatch: patchCalculationActuality,
      buttons: (item: any) => (
        item.status !== CALCULATIONS_STATUSES.error && (
          <div className={style.buttonsWrapper}>
            <CalcResultMapButton {...{
              onClick: () => showCalculationsMap(item),
            }} />
            <ExportToExcelButton {...{
              label: '',
              onClick: () => exportCalculation(item),
            }} />
          </div>
        )
      ),
    }}
    />
  );
};

export default AggloCalcTable;
