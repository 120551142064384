import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';

import { AcVehicleClassesModel, selectVehicleClasses } from 'src/cluster/common';

const useAcVehicleClasses = (): Model<AcVehicleClassesModel>[] => {
  const vehicleClasses = useSelector(selectVehicleClasses);
  return vehicleClasses.orJust([]);
};

export default useAcVehicleClasses;
