import { useDispatch, useSelector } from 'react-redux';

import { Feature, MultiLineString, Position } from 'src/interfaces';
import { isNumber } from 'src/helpers';

import { useLegendLineGenerate, selectEdgeGeojson, TRANSPARENT_COLOR } from 'src/cluster/common';
import { editorFormActions, selectEditorGraphForm } from 'src/cluster/editor-common';
import {
  EditorMode,
  PickInfo,
  selectEditorPageForm,
  createLayer,
  setSelectedEdge,
  enableModifyEdgeMode,
  getNearestPoint,
} from 'src/cluster/editor-map';

const useEdgeLayer = () => {
  const dispatch = useDispatch();
  const { editorMode, edgeId } = useSelector(selectEditorPageForm);
  const { intervalId } = useSelector(selectEditorGraphForm);

  const roadGeoJson = useSelector(selectEdgeGeojson);

  const isGraphView = [
    EditorMode.graph,
    EditorMode.modifyEdge,
    EditorMode.addStop,
  ].includes(editorMode);

  const pickable = !!intervalId && [
    EditorMode.view,
    EditorMode.graph,
    EditorMode.modify,
    EditorMode.addStop,
  ].includes(editorMode);

  const { getLineColor, getLineWidth } = useLegendLineGenerate(isGraphView, edgeId);

  const handleClick = (info: PickInfo<Feature<MultiLineString>>, { tapCount }: { tapCount: number }) => {
    const { object } = info;
    const coordinate = info.coordinate as Position;
    dispatch(editorFormActions.changeField('canBePositioned', false));
    const { properties } = object;
    const { id, vehicleTypes } = properties || {};
    if (isNumber(id)) {
      if (coordinate?.length === 2 && editorMode === EditorMode.addStop) {
        dispatch(getNearestPoint(id, coordinate, vehicleTypes));
        return;
      }
      if (tapCount === 1 && id === edgeId) {
        dispatch(editorFormActions.resetField('edgeId'));
      } else {
        dispatch(setSelectedEdge(id));
        if (editorMode === EditorMode.modify) {
          dispatch(enableModifyEdgeMode());
        }
      }
    }
  };

  return createLayer({
    id: 'roads',
    data: roadGeoJson,
    pickable,
    selectedFeatureIndexes: [],
    getLineColor,
    getLineWidth,
    getFillColor: () => TRANSPARENT_COLOR,
    onClick: handleClick,
  });
};

export default useEdgeLayer;
