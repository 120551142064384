/* eslint-disable max-len */
import React from 'react';

const EditorLinkBlock = () => (
  <svg viewBox="0 0 16 16" >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g strokeWidth="2" stroke="currentColor">
        <path d="M8.75216924,3.86132325 L10.9145397,1.69806136 C11.8466878,0.765529035 13.3647816,0.767571874 14.2994814,1.70265696 C15.2340605,2.63762126 15.2330395,4.16081261 14.2992174,5.09501965 L11.0970519,8.2985052 C10.2748357,9.12106036 9.00029114,9.22000515 8.06934712,8.59655187 L8.75216924,3.86132325 Z" />
        <path d="M7.28587191,11.1028482 L5.09650692,13.2938326 C4.15748885,14.2335453 2.63457228,14.235035 1.70063424,13.3004061 C0.765900839,12.3649813 0.766922049,10.8409058 1.70089825,9.90623872 L4.9030638,6.70170459 C5.55876526,6.04551812 6.52400274,5.83345802 7.38652716,6.13245183 C7.57997297,6.19950976 7.76357736,6.29099898 7.93383833,6.40557073 L7.28587191,11.1028482 Z" />
      </g>
    </g>
  </svg>
);

export default EditorLinkBlock;
