import React from 'react';

const LinkIcon = () => (
  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.045 2.75C1.045 1.8095 1.8095 1.045 2.75 1.045H4.95V0H2.75C1.232 0 0 1.232 0 2.75C0 4.268 1.232 5.5 2.75 5.5H4.95V4.455H2.75C1.8095 4.455 1.045 3.6905 1.045 2.75ZM3.3 3.3H7.7V2.2H3.3V3.3ZM8.25 0H6.05V1.045H8.25C9.1905 1.045 9.955 1.8095 9.955 2.75C9.955 3.6905 9.1905 4.455 8.25 4.455H6.05V5.5H8.25C9.768 5.5 11 4.268 11 2.75C11 1.232 9.768 0 8.25 0Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkIcon;
