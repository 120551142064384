import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { AcBalanceShapesModel } from 'src/cluster/common';
import {
  AC_SHAPES_PAGE_FORM_NAME,
  selectPageForm,
  selectShape,
  showShape,
} from 'src/cluster/shapes';
import BalanceShapesTable from './BalanceShapesTable';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  selectShape: (shape: AcBalanceShapesModel) => dispatch(selectShape(shape)),
  showShape: (shape: AcBalanceShapesModel) => dispatch(showShape(shape)),
  onPageChange: (value: number) => dispatch(forms.actions[AC_SHAPES_PAGE_FORM_NAME].changeField('currentPage', value)),
  onPageSizeChange: (value: number) => {
    dispatch(forms.actions[AC_SHAPES_PAGE_FORM_NAME].changeField('pageSize', value));
    dispatch(forms.actions[AC_SHAPES_PAGE_FORM_NAME].resetField('currentPage'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(BalanceShapesTable));
