import React, { FC } from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

import useStyles from './styles';

type OwnProps = {
  loading?: boolean;
  size?: number;
  marginLeft?: string;
}

type Props = OwnProps & CircularProgressProps

const CircularProgressWrapper: FC<Props> = (props) => {
  const {
    loading, children, marginLeft = '0px', value, size = 20, ...rest
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
      {loading && (
        <>
          <CircularProgress
            className={classes.loader}
            style={{ marginTop: `-${size / 2}px`, marginLeft }}
            size={size}
            thickness={7}
            {...rest}
            value={value}
          />
          {loading && (
            <span className={classes.percent}>
              {`${Math.round(value || 0)}%`}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default CircularProgressWrapper;
