import React, { FC, ReactNode } from 'react';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { ArrowRightIcon, Button } from 'src/components';

import useStyles from './styles';

type Props = WithTranslate & {
  title: string;
  canModify: boolean;
  icon?: ReactNode;
  leave?: () => void;
  edit?: () => void;
};

const EditorTreeCaption: FC<Props> = (props) => {
  const {
    title,
    icon,
    canModify = true,
    t,
    leave,
    edit,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {leave ? (
        <Button
          variant="icon"
          className={classes.icon}
          onClick={leave}
        >
          <ArrowRightIcon />
        </Button>
      ) : <span className={classes.icon}>{icon}</span>}
      <Typography component="span" className={classes.title}>
        {title}
      </Typography>
      {edit && (
        <Button
          variant="link"
          className={classes.button}
          disabled={!canModify}
          onClick={edit}
        >
          {t('common.captions.edit')}
        </Button>
      )}
    </div>
  );
};

export default EditorTreeCaption;
