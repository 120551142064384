import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import { isUndefined } from 'src/helpers';

import useStyles from './styles';

interface Props extends WithTranslate{
  className?: string;
  message?: string;
  percent?: number;
  showPercent?: boolean;
}

const DataProcessingRecords: FC<Props> = (props: Props) => {
  const {
    t,
    message = `${t('modules.projects.statuses.inProgress')}. ${t('common.captions.completed')} `,
    percent,
    showPercent = true,
  } = props;

  const classes = useStyles();

  if (isUndefined(percent) || percent >= 100) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div>
        {message}
        {showPercent && (
          <span className={classes.percentValue}>
            {percent}
            %
          </span>
        )}
      </div>
    </div>
  );
};

export default DataProcessingRecords;
