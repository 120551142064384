import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => createStyles({
  root: {},

  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    padding: spacing(4),
  },

  group: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing(6),
  },

  subGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  selectLabel: {
    textTransform: 'uppercase',
    marginLeft: spacing(2),
    marginBottom: spacing(4),
    color: palette.grey[700],
    fontSize: 10,
  },

  select: {
    width: 110,
    marginBottom: spacing(4),
  },

  buttonGroup: {
    width: '100%',
    marginBottom: spacing(4),

    '& button': {
      width: '50%',
      fontSize: 12,
      fontWeight: 'bold',
      padding: spacing(2),
    },
  },
}));
