export default {
  defaults: {
    selectedPoint: undefined,
    selectedRoute: undefined,
    expandedRoute: undefined,
    selectedEdge: undefined,
    from: undefined,
    to: undefined,
    transportType: undefined,
    maxAlternativeRoutes: 10,
    lengthPercentMaxActual: undefined,
    uniqPercentMaxActual: undefined,
    fromActual: undefined,
    toActual: undefined,
  },
  onRouteChangeReset: {
    selectedRoute: true,
    expandedRoute: true,
    lengthPercentMaxActual: true,
    uniqPercentMaxActual: true,
    fromActual: true,
    toActual: true,
  },
};
