import React from 'react';

const MenuVideoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1103 5.09961C13.02 5.09961 13.8925 5.46099 14.5358 6.10427C15.179 6.74755 15.5404 7.62002 15.5404 8.52975C15.5404 9.43948 15.179 10.3119 14.5358 10.9552C13.8925 11.5985 13.02 11.9599 12.1103 11.9599C11.2006 11.9599 10.3281 11.5985 9.6848 10.9552C9.04153 10.3119 8.68014 9.43948 8.68014 8.52975C8.68014 7.62002 9.04153 6.74755 9.6848 6.10427C10.3281 5.46099 11.2006 5.09961 12.1103 5.09961ZM12.1103 13.6749C15.9006 13.6749 18.9706 15.2099 18.9706 17.1051V18.8201H5.25V17.1051C5.25 15.2099 8.31998 13.6749 12.1103 13.6749Z"
      fill="currentColor"
    />
    <path d="M17.5 23H23V17.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M23 6.5L23 1L17.5 1" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M6.5 1L1 1L1 6.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M1 17.5L1 23L6.5 23" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
  </svg>
);

export default MenuVideoIcon;
