import React, { FC } from 'react';

import { DataGrid } from 'src/components';

import {
  AcDistRoutesDayModel,
  useDistRouteDayConfigLeft,
  useDistRouteDayConfigRight,
} from 'src/cluster/distribution-table';
import { useAcVehicleTypeName } from 'src/cluster/common';

import useStyles from './styles';

interface OwnProps {
  data: AcDistRoutesDayModel[];
}

const DistributionRouteDayDataGrid: FC<OwnProps> = (props) => {
  const { data } = props;

  const classes = useStyles();

  const getVehicleTypeName = useAcVehicleTypeName();
  const columnsLeft = useDistRouteDayConfigLeft(getVehicleTypeName, classes);
  const columnsRight = useDistRouteDayConfigRight(getVehicleTypeName, classes);

  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.leftTableWrapper}>
          <DataGrid<AcDistRoutesDayModel>
            columns={columnsLeft}
            body={data}
            headCellClassName={classes.headerCell}
            rowClassName={classes.routeRow}
            cellClassName={classes.cell}
          />
        </div>
        <div className={classes.middleTableWrapper}>
          <DataGrid<AcDistRoutesDayModel>
            cellClassName={classes.cell}
            columns={columnsRight}
            body={data}
            headCellClassName={classes.headerCellFixed}
            rowClassName={classes.routeRow}
          />
        </div>
      </div>
    </>
  );
};

export default DistributionRouteDayDataGrid;
