import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    padding: theme.spacing(4, 5),
  },

  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
    paddingBottom: '10px',
  },

  field: {
    padding: '0 10px 15px',
    flexBasis: '33.3333%',
  },

  errorSelect: {
    '& > div > div > div:first-of-type': {
      borderColor: theme.palette.primary.main,
    },
  },

  buttons: {
    display: 'flex',
    margin: '0 -10px',
    justifyContent: 'flex-end',
  },

  button: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    margin: '0 10px',
  },
}));
