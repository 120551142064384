import React, { FC, useEffect, useRef } from 'react';

import { Nullable, ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import { DataProcessingRecords } from 'src/components';
import {
  MatrixForecastModel,
  useMatrixForecastStatusUpdate,
} from 'src/cluster/common';
import { isNumber } from 'src/helpers';

interface Props extends WithTranslate {
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  loadMatrixForecast(): void;
  calculateTTCDone(): void;
}

let wasCalculateInSameSession = false;
let wasShowModal = false;

const TtcCalcProgress: FC<Props> = (props) => {
  const {
    matrixForecastEntities: [matrixForecast, isLoading],
    t,
    loadMatrixForecast,
    calculateTTCDone,
  } = props;

  const lastStatus = useRef<string | undefined>(undefined);

  const { ttc } = matrixForecast || {};
  const { progress, status } = ttc || {};

  useEffect(() => {
    if (
      status === ProcessStatus.QUEUED ||
      status === ProcessStatus.IN_PROGRESS
    ) {
      wasCalculateInSameSession = true;
      wasShowModal = false;
    }

    const changeStatusTheSameScreen = status === ProcessStatus.SUCCESS &&
    (lastStatus.current !== ProcessStatus.SUCCESS && lastStatus.current);
    const changeStatusAfterSwipeScreen = wasCalculateInSameSession && status === ProcessStatus.SUCCESS;

    if (
      (changeStatusTheSameScreen || changeStatusAfterSwipeScreen) && !wasShowModal
    ) {
      calculateTTCDone();
      wasShowModal = true;
      return;
    }
    lastStatus.current = status;
  }, [status, progress]);

  useMatrixForecastStatusUpdate(
    loadMatrixForecast,
    isLoading && matrixForecast?.ttc.status === ProcessStatus.SUCCESS,
    (matrixForecast?.ttc || {}) as MatrixForecastModel,
    3000,
  );

  const percent = isNumber(progress)
    ? Math.round(progress * 10000) / 100
    : undefined;

  let message;
  let showPercent = true;
  if (status === ProcessStatus.QUEUED) {
    showPercent = false;
    message = t('systems.balance.messages.calculationStart');
  } else if (status === ProcessStatus.ERROR) {
    message = 'Ошибка расчета, попробуйте снова!';
    showPercent = false;
  }

  if (status === null) return null;

  return (
    <DataProcessingRecords
      percent={percent}
      message={message}
      showPercent={showPercent}
    />
  );
};

export default TtcCalcProgress;
