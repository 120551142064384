import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RestifyEntityList, RestifyFormActions } from 'redux-restify';

import actions from 'src/modules/matrix/macroeconomics/actions';
import { Systems } from 'src/constants';
import { useRestifyForm, useRestifyModel } from 'src/helpers';
import {
  MACROECONOMICS_FORM_NAMES,
  MACROECONOMICS_MODEL_NAMES,
  MacroeconomicsModel,
  MacroeconomicsRecord,
  PageMacroeconomicsForm,
} from 'src/modules/matrix/macroeconomics';
import { PageProjectForm, PROJECTS_PAGES_FORM_NAMES } from 'src/modules/general/projects';

import { toPercents, toYearRange } from 'src/modules/matrix/common/utils/helpers';
import { exportDataToCsv } from 'src/helpers/csv';

interface Values {
  entities: RestifyEntityList<MacroeconomicsModel>;
  project?: number | string;
  form: PageMacroeconomicsForm;
  formActions: RestifyFormActions<PageMacroeconomicsForm>;
}

interface Handlers {
  applyCorrection: () => void;
  exportData: (data: any, fileName: any) => void;
  convertToChartData: (values: any) => MacroeconomicsRecord[];
}

export const useValues = (currentSystem: Systems): Values => {
  const macroeconomicsModelName = MACROECONOMICS_MODEL_NAMES[currentSystem];

  const [form, formActions] = useRestifyForm<PageMacroeconomicsForm>(MACROECONOMICS_FORM_NAMES[currentSystem]);
  const [entities] = useRestifyModel<MacroeconomicsModel>(macroeconomicsModelName);
  const [pageProjectForm] = useRestifyForm<PageProjectForm>(PROJECTS_PAGES_FORM_NAMES[currentSystem]);

  return {
    entities,
    project: pageProjectForm.selectedProject,
    form,
    formActions,
  };
};

export const useHandlers = (currentSystem: Systems): Handlers => {
  const dispatch = useDispatch();

  const macroeconomicsModelName = MACROECONOMICS_MODEL_NAMES[currentSystem];

  const [/* entities */, modelActions] = useRestifyModel<MacroeconomicsModel>(macroeconomicsModelName);
  const [pageProjectForm] = useRestifyForm<PageProjectForm>(PROJECTS_PAGES_FORM_NAMES[currentSystem]);

  const macroeconomicsApiConfig = {
    filter: {
      project: pageProjectForm.selectedProject,
    },
  };

  const applyCorrection = useCallback(async () => {
    await dispatch(actions[currentSystem].applyCorrections());
    dispatch(modelActions.loadData(macroeconomicsApiConfig));
  }, [currentSystem]);

  return {
    applyCorrection,
    exportData: (data, fileName) => exportDataToCsv({ data, fileName }),
    convertToChartData: (data: MacroeconomicsModel[]): MacroeconomicsRecord[] => (
      data.map(toPercents).reduce(toYearRange, [])
    ),
  };
};
