import React from 'react';

const MenuStreamsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 6.05669C3.59092 6.18678 8.26163 7.35712 11.8824 10.6788M16 17C15.0898 14.3263 13.6049 12.2591 11.8824 10.6788M11.8824 10.6788C11.6863 7.6022 12.2353 1.56417 16 2.02494"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

export default MenuStreamsIcon;
