import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import i18n from 'src/i18n/i18n';
import modals from 'src/modals';
import { AppState } from 'src/reducer';
import { SelectValue } from 'src/interfaces';
import {
  initRegistries,
  loadRegistryRoute,
  deleteRegistryRoute,
  selectAcRegistryPageForm,
  selectRegistryRoutes,
  selectRegistryRoutesCount,
  AC_REGISTRY_ROUTE_EDITING_MODAL,
  AC_REGISTRY_TABLE_SETTINGS_MODAL,
  AcRegRoutesResultsModel,
} from 'src/cluster/editor-table';
import { registryPageFormActions } from 'src/cluster/editor-common';

import RegistriesPage from './RegistriesPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectAcRegistryPageForm(state),
  results: selectRegistryRoutes(state),
  count: selectRegistryRoutesCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInit: () => dispatch(initRegistries()),
  onPageChange: (value: number) => {
    dispatch(registryPageFormActions.changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(registryPageFormActions.resetField('currentPage'));
    dispatch(registryPageFormActions.changeField('pageSize', value));
  },
  showEditingModal: async (record: AcRegRoutesResultsModel) => {
    let updatedRecord = { ...record };
    if (!record.variants) updatedRecord = await dispatch(loadRegistryRoute(record.id));

    dispatch(modals.actions.showModal(true, AC_REGISTRY_ROUTE_EDITING_MODAL, {
      title: i18n.t('modules.registries.captions.modalEditTitle'),
      record: updatedRecord,
    }));
  },
  showSettingsModal: () => {
    dispatch(modals.actions.showModal(true, AC_REGISTRY_TABLE_SETTINGS_MODAL, {
      title: i18n.t('modules.registries.captions.customizeColumns'),
    }));
  },
  onExportClick: () => ({}),
  setRegistryFilter: (val: SelectValue) => {
    dispatch(registryPageFormActions.changeField('registryId', val));
  },
  loadRegistryRoute: (id: number) => {
    dispatch(loadRegistryRoute(id));
  },
  deleteRegistryRoute: (id: number, number: string) => {
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.registries.captions.deleteRouteTitle'),
      text: i18n.t('modules.registries.messages.deleteRouteInfo', { number }),
      acceptButtonText: i18n.t('common.captions.delete'),
      declineButtonText: i18n.t('common.captions.cancel'),
      onAccept: () => dispatch(deleteRegistryRoute(id)),
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistriesPage);
