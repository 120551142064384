import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import Pagination from 'src/components/etc/Pagination';

import style from './index.module.css';

interface OwnProps {
  children: ReactElement | string | false | null | (ReactElement | string | false | null)[];
  className?: string;
  message?: string;
  isLoading?: boolean;
  loadingLabel?: string;
  currentPage: number;
  pageSize: number;
  count: number;
  onPageChange(value: number): void;
  onPageSizeChange(value: number): void;
}

type Props = OwnProps & WithTranslate;

const TableContainer: FC<Props> = (props: Props) => {
  const {
    children,
    className,
    message,
    isLoading = false,
    loadingLabel,
    count,
    pageSize,
    currentPage,
    t,
    onPageChange,
    onPageSizeChange,
  } = props;

  const [currentCount, setCurrentCount] = useState(count);

  useEffect(() => {
    if (!isLoading) {
      setCurrentCount(count);
    }
  }, [isLoading, count]);

  const hasNoData = !isLoading && count === 0;

  return (
    <LoadingBlockContainer
      isBlocked={isLoading}
      label={loadingLabel}
      className={classNames(style.root, hasNoData && style.hasNoData, className)}
    >
      <Pagination
        disabled={isLoading}
        pageSize={pageSize}
        currentPage={currentPage}
        count={currentCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {children}
        {(message || hasNoData) && (
          <div className={style.message}>
            {message || t('common.captions.noData')}
          </div>
        )}
      </Pagination>
    </LoadingBlockContainer>
  );
};

export default TableContainer;
