import React, { FC } from 'react';
import classNames from 'classnames';

import {
  PageVideoForm,
  VideoTable,
  VideoToolbar,
  VideoVehicleOnRoutesModel,
} from 'src/modules/video/common';

import style from './index.module.css';

interface Props {
  className?: string;
  pageForm: PageVideoForm;
  entities: [VideoVehicleOnRoutesModel[], boolean, number];
}

const VideoPage: FC<Props> = (props) => {
  const {
    className,
    pageForm,
    entities,
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      <VideoToolbar pageForm={pageForm} />
      <VideoTable
        pageForm={pageForm}
        entities={entities}
      />
    </div>
  );
};

export default VideoPage;
