import React, { FC } from 'react';
import { Popup } from 'react-map-gl';
import moment from 'moment';

import { WithTranslate } from 'src/i18n';
import { Stop } from 'src/modules/video/common';
import NumberFormat from 'src/components/etc/NumberFormat';

import WithUnit from 'src/components/common/WithUnit';
import { UNITS } from 'src/modules/general/common';
import style from './index.module.css';

interface Props extends WithTranslate {
  lat: number;
  lng: number;
  stop: Stop;
}

const VideoRouteMapPopup: FC<Props> = (props) => {
  const {
    lat,
    lng,
    stop,
    locale,
    t,
  } = props;

  return (
    <Popup
      latitude={lat}
      longitude={lng}
      closeButton={false}
      closeOnClick={false}
      anchor="top-left"
      offsetTop={15}
      offsetLeft={15}
      tipSize={10}
    >
      <div className={style.root}>
        <h6 className={style.title}>
          {stop.stopName}
        </h6>
        <table className={style.table}>
          <tbody>
            <tr>
              <td>
                {t('modules.video.captions.arrivalTime')}
                :
              </td>
              <td className={style.value}>
                {stop.startDt && moment(stop.startDt).utcOffset(7).format('HH:mm')}
              </td>
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.people)}>
                  {t('modules.video.captions.entered')}
                </WithUnit>
              </td>
              <td className={style.value}>
                {stop.passengersInDetected}
              </td>
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.people)}>
                  {t('modules.video.captions.gotOut')}
                </WithUnit>
              </td>
              <td className={style.value}>
                {stop.passengersOutDetected}
              </td>
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.people)}>
                  {t('modules.video.captions.leftInVehicle')}
                </WithUnit>
              </td>
              <td className={style.value}>
                {stop.peopleLeftInside}
              </td>
            </tr>
            <tr>
              <td>
                <WithUnit unit={t(UNITS.peoplePerSquare)}>
                  {t('modules.video.captions.density')}
                </WithUnit>
              </td>
              <td className={style.value}>
                <NumberFormat
                  value={stop.floorDensity}
                  locale={locale}
                  decimalScale={1}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Popup>
  );
};

export default VideoRouteMapPopup;
