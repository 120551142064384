import React from 'react';

const RopeWayIcon = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00055 3.92857C6.68117 3.92857 6.36896 3.82803 6.10341 3.63966C5.83786 3.45129 5.63088 3.18356 5.50866 2.87032C5.38644 2.55707 5.35446 2.21238 5.41677 1.87985C5.47908 1.54731 5.63287 1.24185 5.85871 1.0021C6.08454 0.762356 6.37227 0.599086 6.68551 0.53294C6.99876 0.466794 7.32344 0.500743 7.6185 0.630493C7.91357 0.760243 8.16577 0.979968 8.34321 1.26188C8.52064 1.54379 8.61535 1.87523 8.61535 2.21429C8.61535 2.66894 8.44522 3.10498 8.14239 3.42647C7.83955 3.74796 7.42882 3.92857 7.00055 3.92857ZM7.00055 1.64286C6.89409 1.64286 6.79002 1.67637 6.7015 1.73916C6.61298 1.80195 6.54399 1.8912 6.50325 1.99561C6.46251 2.10003 6.45185 2.21492 6.47262 2.32577C6.49339 2.43661 6.54466 2.53843 6.61993 2.61835C6.69521 2.69826 6.79112 2.75269 6.89554 2.77474C6.99995 2.79678 7.10818 2.78547 7.20653 2.74222C7.30489 2.69897 7.38895 2.62573 7.4481 2.53175C7.50725 2.43778 7.53881 2.3273 7.53881 2.21429C7.53881 2.06273 7.4821 1.91739 7.38116 1.81023C7.28021 1.70306 7.1433 1.64286 7.00055 1.64286Z"
      fill="currentColor"
    />
    <path
      d="M7.00018 6.78564C6.85742 6.78564 6.72051 6.72544 6.61957 6.61828C6.51862 6.51111 6.46191 6.36577 6.46191 6.21422V3.35707C6.46191 3.20552 6.51862 3.06018 6.61957 2.95301C6.72051 2.84585 6.85742 2.78564 7.00018 2.78564C7.14294 2.78564 7.27985 2.84585 7.38079 2.95301C7.48174 3.06018 7.53845 3.20552 7.53845 3.35707V6.21422C7.53845 6.36577 7.48174 6.51111 7.38079 6.61828C7.27985 6.72544 7.14294 6.78564 7.00018 6.78564Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.61732 16.5001H12.3827C12.4801 16.5001 12.5758 16.4721 12.6594 16.4189C12.743 16.3658 12.8114 16.2896 12.8574 16.1984C13.6075 14.7092 14 13.0465 14 11.3578C14 9.66905 13.6075 8.0064 12.8574 6.51721C12.8116 6.4258 12.7432 6.34933 12.6596 6.29598C12.5759 6.24264 12.4802 6.21442 12.3827 6.21436H1.61732C1.51987 6.21431 1.42424 6.24236 1.34063 6.29549C1.25702 6.34863 1.18857 6.42487 1.14257 6.51607C0.392452 8.00526 0 9.66791 0 11.3566C0 13.0454 0.392452 14.708 1.14257 16.1972C1.18842 16.2886 1.25681 16.3651 1.34044 16.4184C1.42406 16.4718 1.51976 16.5 1.61732 16.5001ZM5.92364 13.0714H2.69403C2.55128 13.0714 2.41437 13.0112 2.31342 12.9041C2.21248 12.7969 2.15577 12.6516 2.15577 12.5V9.07143C2.15577 8.91988 2.21248 8.77453 2.31342 8.66737C2.41437 8.5602 2.55128 8.5 2.69403 8.5H5.92364C6.0664 8.5 6.20331 8.5602 6.30425 8.66737C6.4052 8.77453 6.46191 8.91988 6.46191 9.07143V12.5C6.46191 12.6516 6.4052 12.7969 6.30425 12.9041C6.20331 13.0112 6.0664 13.0714 5.92364 13.0714ZM3.2323 11.9286H5.38537V9.64286H3.2323V11.9286ZM8.07678 13.0714H11.3064C11.4491 13.0714 11.5861 13.0112 11.687 12.9041C11.7879 12.7969 11.8447 12.6516 11.8447 12.5V9.07143C11.8447 8.91988 11.7879 8.77453 11.687 8.66737C11.5861 8.5602 11.4491 8.5 11.3064 8.5H8.07678C7.93402 8.5 7.79711 8.5602 7.69616 8.66737C7.59522 8.77453 7.53851 8.91988 7.53851 9.07143V12.5C7.53851 12.6516 7.59522 12.7969 7.69616 12.9041C7.79711 13.0112 7.93402 13.0714 8.07678 13.0714ZM10.7681 11.9286H8.61505V9.64286H10.7681V11.9286Z"
      fill="currentColor"
    />
    <path
      d="M8.05395 2.55726C7.92046 2.5574 7.79169 2.50488 7.69263 2.4099C7.59356 2.31492 7.53128 2.18425 7.51786 2.04326C7.50445 1.90227 7.54086 1.76101 7.62003 1.64692C7.6992 1.53283 7.81548 1.45403 7.94629 1.42583L12.274 0.511545C12.4139 0.481235 12.5594 0.511165 12.6785 0.594753C12.7976 0.678341 12.8806 0.808738 12.9091 0.957259C12.9377 1.10578 12.9095 1.26026 12.8307 1.38671C12.752 1.51317 12.6292 1.60123 12.4893 1.63155L8.1616 2.54583C8.12619 2.55359 8.09011 2.55742 8.05395 2.55726Z"
      fill="currentColor"
    />
    <path
      d="M1.61742 3.92835C1.48394 3.9285 1.35516 3.87598 1.2561 3.781C1.15704 3.68601 1.09475 3.55534 1.08134 3.41435C1.06792 3.27336 1.10434 3.13211 1.18351 3.01801C1.26267 2.90392 1.37895 2.82512 1.50977 2.79692L5.83744 1.88264C5.97735 1.85233 6.12286 1.88226 6.24197 1.96585C6.36109 2.04943 6.44405 2.17983 6.4726 2.32835C6.50115 2.47687 6.47296 2.63135 6.39422 2.75781C6.31548 2.88426 6.19265 2.97233 6.05275 3.00264L1.72508 3.91692C1.68966 3.92468 1.65358 3.92851 1.61742 3.92835Z"
      fill="currentColor"
    />
  </svg>

);

export default RopeWayIcon;
