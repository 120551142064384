import React, { Fragment } from 'react';

import { WithTranslate } from 'src/i18n';
import style from './index.module.css';

export interface LegendItem {
  message?: string;
  messageKey?: string;
  icon?: string;
}

const legendItems: LegendItem[] = [
  { icon: style.correspondencePoint, messageKey: 'systems.mth.captions.correspondencePoint' },
  { messageKey: 'systems.mth.captions.trafficCoefficient' },
  { icon: style.routeLoadLegend6, message: '< 0,3' },
  { icon: style.routeLoadLegend5, message: '0,3 - 0,4' },
  { icon: style.routeLoadLegend4, message: '0,4 - 0,55' },
  { icon: style.routeLoadLegend3, message: '0,55 - 0,7' },
  { icon: style.routeLoadLegend2, message: '0,7 - 0,9' },
  { icon: style.routeLoadLegend1, message: '0,9 +' },
];

const AggloMapLegend = (props: WithTranslate) => {
  const { t } = props;

  return (
    <Fragment>
      {legendItems.map((item, index) => {
        const message = item.messageKey ? t(item.messageKey) : item.message;
        if (item.icon) {
          return (
            <div key={index} className={style.legendEntry}>
              <div className={style.legendRouteIcon}>
                <div className={item.icon} />
              </div>
              <div className={style.legendTitle}>
                {message}
              </div>
            </div>
          );
        }
        return (
          <div key={index} className={style.title}>
            {message}
          </div>
        );
      })}
    </Fragment>
  );
};

export default AggloMapLegend;
