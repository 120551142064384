import { useTranslation } from 'react-i18next';
import { useAcVehicleTypeName, VehicleType, VEHICLE_TYPES_DICT } from 'src/cluster/common';

const useAcVehicleTypeLabel = (): (vehicleId?: number) => string => {
  const { t } = useTranslation();
  const getVehicleTypeName = useAcVehicleTypeName();
  return (vehicleId) => {
    const name = getVehicleTypeName(vehicleId);
    return name && name in VehicleType ? t(VEHICLE_TYPES_DICT[name]) : t('common.vehicleTypes.unknown');
  };
};

export default useAcVehicleTypeLabel;
