import { Systems } from 'src/constants';
import { StrictDict } from 'src/types';
import { getRoutesSelectors } from './selectors';

const selectors: StrictDict<Systems, ReturnType<typeof getRoutesSelectors>> =
  Object.values(Systems).reduce((memo, value) => ({
    ...memo,
    [value]: getRoutesSelectors(value),
  }), {} as any);

export default selectors;
