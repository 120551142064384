import { createSelector } from 'reselect';
import { api, RestifyModel } from 'redux-restify';

import { AppState } from 'src/reducer';

import { INDICATORS_MODEL_NAME } from 'src/modules/mth/indicators';
import { IndicatorsModel } from 'src/modules/mth/indicators/interfaces';

export const selectIndicators = createSelector(
  [
    (state: AppState) => api.selectors.entityManager[INDICATORS_MODEL_NAME]
      .getEntities<IndicatorsModel>(state),
  ],
  (
    indicatorsEntities,
  ): [RestifyModel<IndicatorsModel>, boolean] => {
    const indicatorsApiConfig = {};
    const indicators = indicatorsEntities.getById('', indicatorsApiConfig);
    const isLoading = indicatorsEntities.getIsLoadingById('', indicatorsApiConfig);
    return [
      indicators,
      isLoading,
    ];
  },
);
