import React, { useCallback } from 'react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import { RestifyFormActions } from 'redux-restify';
import { useDispatch } from 'react-redux';

import { getVocabularyValue, useCurrentVocabulary, WithTranslate } from 'src/i18n';
import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import { MacroeconomicsModel, PageMacroeconomicsForm } from 'src/modules/matrix/macroeconomics';
import PButton from 'src/components/deprecated/PButton';
import PSlider from 'src/components/deprecated/PSlider';
import Tooltip from 'src/components/etc/Tooltip';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import tooltipStyle from 'src/styles/tooltip.module.css';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  macroeconomics: MacroeconomicsModel[];
  form: PageMacroeconomicsForm;
  formActions: RestifyFormActions<PageMacroeconomicsForm, PageMacroeconomicsForm>;
  applyCorrection: () => void;
}

const MacroToolbar: React.FC<Props> = (props: Props) => {
  const {
    className,
    macroeconomics,
    form,
    formActions,
    applyCorrection,
    t,
  } = props;

  const dispatch = useDispatch();
  const [currentLocale, vocabulary] = useCurrentVocabulary();
  const years = uniq(macroeconomics.map(n => n.year));
  const allRegions: string[] = uniq(macroeconomics.map(n => String(n.region)));

  const yearsFrom = years.filter(year => {
    if (!year) {
      return false;
    }
    if (!form.yearTo) {
      return true;
    }
    return year < form.yearTo;
  });

  const yearsTo = years.filter(year => {
    if (!year) {
      return false;
    }
    if (!form.yearFrom) {
      return true;
    }
    return year > form.yearFrom;
  });

  const yearFromOptions = yearsFrom.map(year => ({
    value: year,
    label: year,
  }));
  const yearToOptions = yearsTo.map(year => ({
    value: year,
    label: year,
  }));
  const regionOptions = allRegions.map(regionItem => ({
    value: regionItem,
    label: getVocabularyValue(currentLocale, vocabulary, regionItem),
  })).sort((a, b) => (a.label < b.label ? -1 : 1));

  const handleYearFromChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('yearFrom', value[0]));
  }, []);

  const handleYearToChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('yearTo', value[0]));
  }, []);

  const handleRegionChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('regions', value));
  }, []);

  const handleCorrectionChange = useCallback((value: number) => {
    dispatch(formActions.changeField('grpCorrection', value));
  }, []);

  const handleResetForm = useCallback(() => {
    dispatch(formActions.resetForm());
  }, []);

  const handleResetRegions = useCallback(() => {
    dispatch(formActions.resetField('regions'));
  }, []);

  return (
    <div className={classNames(pageLayoutStyle.toolbar, className)}>
      <div className={classNames(pageLayoutStyle.toolbarBlock, style.toolbarBlockLarge)}>
        <div className={pageLayoutStyle.toolbarTitle}>
          {t('systems.matrix.captions.filter')}
          <PButton
            className={style.clear}
            type="link"
            onClick={handleResetForm}
          >
            {t('systems.matrix.captions.clearAll')}
          </PButton>
        </div>
        <div className={style.subBlockWrapper}>
          <div className={style.toolbarSubBlock}>
            <div className={style.fieldTitle}>
              {t('systems.matrix.captions.period')}
            </div>
            <div className={pageLayoutStyle.toolbarControls}>
              <div className={classNames(pageLayoutStyle.toolbarControl, style.yearRange)}>
                <PSelect {...{
                  label: t('systems.matrix.captions.from'),
                  className: style.yearSelect,
                  items: yearFromOptions,
                  values: form.yearFrom ? [form.yearFrom] : undefined,
                  onChange: handleYearFromChange,
                }} />
                <PSelect {...{
                  label: t('systems.matrix.captions.till'),
                  className: style.yearSelect,
                  items: yearToOptions,
                  values: form.yearTo ? [form.yearTo] : undefined,
                  onChange: handleYearToChange,
                }} />
              </div>
            </div>
          </div>

          <div className={style.toolbarSubBlock}>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSelect {...{
                label: (
                  <span className={style.spaceBetween}>
                    {t('systems.matrix.captions.center')}
                    <PButton
                      className={style.clear}
                      type="link"
                      onClick={handleResetRegions}
                    >
                      {t('systems.matrix.captions.clear')}
                    </PButton>
                  </span>
                ),
                className: style.regionSelect,
                multi: true,
                isClearable: false,
                items: regionOptions,
                values: Array.isArray(form.regions) ? form.regions : undefined,
                onChange: handleRegionChange,
              }} />
            </div>
          </div>
        </div>
        <Tooltip className={classNames(style.tooltip, style.filterTooltip)}>
          <span className={tooltipStyle.tooltipAnchor}>?</span>
          <span className={tooltipStyle.tooltipPopup}>
            {t('systems.matrix.messages.filterTooltip')}
          </span>
        </Tooltip>
      </div>

      <div className={classNames(pageLayoutStyle.toolbarBlock, style.toolbarBlockSmall)}>
        <div className={pageLayoutStyle.toolbarTitle}>
          {t('systems.matrix.captions.adjustment')}
        </div>
        <div className={classNames(pageLayoutStyle.toolbarControl, style.slider)}>
          <PSlider {...{
            name: 'grpCorrection',
            onChange: handleCorrectionChange,
            label: t('systems.matrix.captions.deviation'),
            leftMark: '-5%',
            rightMark: '+5%',
            value: form.grpCorrection,
            min: -5,
            max: 5,
          }} />
        </div>
        <div className={classNames(pageLayoutStyle.toolbarControl, style.buttonBlock)}>
          <PButton {...{
            type: 'transparent',
            className: style.button,
            onClick: applyCorrection,
          }}>
            {t('systems.matrix.captions.apply')}
          </PButton>
        </div>
        <Tooltip className={style.tooltip} align="right">
          <span className={tooltipStyle.tooltipAnchor}>?</span>
          <span className={tooltipStyle.tooltipPopup}>
            {t('systems.matrix.messages.adjustmentTooltip')}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default MacroToolbar;
