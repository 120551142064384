import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createSpacing from '@material-ui/core/styles/createSpacing';

import typography from './typography';
import palette from './palette';
import shadows from './shadows';

const index = createMuiTheme({
  zIndex: {
    modal: 6000,
  },
  typography,
  palette,
  shadows,
  spacing: createSpacing(4),
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar-track': {
          display: 'none',
          borderColor: 'transparent',
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar *': {
          background: 'transparent',
        },
        '*::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '*::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: palette.grey.A700,
          '*-webkit-box-shadow': 'inset 0 0 0',
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: palette.grey[400],
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: palette.grey[300],
        },
        '&:hover $notchedOutline': {
          borderColor: palette.grey[300],
        },
        '&$focused $notchedOutline': {
          borderColor: palette.grey[300],
        },
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
        fontSize: 15,
        fontWeight: 'normal',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -9,
      },
    },
  },
});

export default index;
