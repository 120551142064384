import React, { FC } from 'react';
import classNames from 'classnames';

import { getSelectValue } from 'src/modules/general/common';

import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import PButton from 'src/components/deprecated/PButton';
import PInput from 'src/components/deprecated/PInput';
import PSelect from 'src/components/deprecated/PSelect';
import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components/toolbar';

import { Props } from './interfaces';

import style from './index.module.css';

const AggloRoutesToolbar: FC<Props> = (props: Props) => {
  const {
    isLoading = false,
    pageForm: {
      search, taskFilter, subtaskFilter, indicatorTypeFilter,
    },
    tasks,
    indicatorTypes,
    t,
    onSearchChange,
    onTaskChange,
    onSubtaskChange,
    onIndicatorTypeChange,
    resetSearch,
    resetTask,
    resetSubtask,
    resetIndicatorType,
  } = props;

  const taskItems = tasks.map((task) => ({
    label: task.name,
    value: task.no,
  }));

  const subtasksItems = tasks
    .filter((task) => (taskFilter ? task.no === taskFilter : true))
    .flatMap((task) => {
      return task.subtasks.map((subtask) => ({
        label: subtask.name,
        value: subtask.no,
      }));
    });

  const indicatorTypesItems = Object.keys(indicatorTypes).map((key: string) => ({
    label: indicatorTypes[Number(key)],
    value: key,
  }));

  return (
    <LoadingBlockContainer isBlocked={isLoading}>
      <Toolbar className={style.root}>
        <ToolbarBlock title={' '} size="x-small">
          <ToolbarControls>
            <ToolbarControl size="full-width" className={style.control}>
              <PInput
                label={t('modules.indicators.captions.textSearch')}
                value={search}
                placeholder={t('common.captions.search')}
                onChange={onSearchChange}
              />
              <PButton
                className={style.clear}
                type="link"
                onClick={resetSearch}
              >
                {t('modules.indicators.captions.clear')}
              </PButton>
            </ToolbarControl>
          </ToolbarControls>
        </ToolbarBlock>
        <ToolbarBlock
          title={t('common.captions.filter')}
          size="x-large"
        >
          <ToolbarControls>
            <ToolbarControl size="medium" className={style.control}>
              <PSelect
                label={t('modules.indicators.captions.task')}
                items={taskItems}
                values={getSelectValue(taskFilter)}
                onChange={onTaskChange}
              />
              <PButton
                className={style.clear}
                type="link"
                onClick={resetTask}
              >
                {t('modules.indicators.captions.clear')}
              </PButton>
            </ToolbarControl>
            <ToolbarControl size="medium" className={style.control}>
              <PSelect
                label={t('modules.indicators.captions.subtask')}
                items={subtasksItems}
                values={getSelectValue(subtaskFilter)}
                onChange={onSubtaskChange}
              />
              <PButton
                className={style.clear}
                type="link"
                onClick={resetSubtask}
              >
                {t('modules.indicators.captions.clear')}
              </PButton>
            </ToolbarControl>
            <ToolbarControl size="x-small" className={classNames(style.control, style.fullWidth)}>
              <PSelect
                label={t('modules.indicators.captions.indicatorType')}
                items={indicatorTypesItems}
                values={getSelectValue(indicatorTypeFilter)}
                onChange={onIndicatorTypeChange}
              />
              <PButton
                className={style.clear}
                type="link"
                onClick={resetIndicatorType}
              >
                {t('modules.indicators.captions.clear')}
              </PButton>
            </ToolbarControl>
          </ToolbarControls>
        </ToolbarBlock>
      </Toolbar>
    </LoadingBlockContainer>
  );
};

export default AggloRoutesToolbar;
