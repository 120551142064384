import { createStyles, makeStyles } from '@material-ui/core';
import { ffOxygen } from 'src/theme/typography';

export default makeStyles(({ palette }) => createStyles({
  root: {
    fontFamily: ffOxygen,
    fontSize: 12,
    lineHeight: 1.5,
  },

  name: {
    fontWeight: 'bold',
  },

  desc: {
    fontSize: 10,
    textTransform: 'uppercase',
    lineHeight: 2.5,
    color: palette.grey[600],
  },

  value: {
    fontWeight: 'bold',
    textAlign: 'right',
  },

  stopId: {
    fontSize: 10,
    textAlign: 'right',
    color: palette.grey[600],
  },
}));
