import { useEffect } from 'react';
import { LinearInterpolator } from 'react-map-gl';
import WebMercatorViewport, { WebMercatorViewportOptions } from 'viewport-mercator-project';

import { bbox, lineString } from 'src/helpers';
import { Position } from 'src/interfaces';
import { MAP_BOUND_PADDING } from 'src/constants';
import { Nullable } from 'src/types';

interface SetViewportProps {
  (currentViewport: (currentViewport: any) => any): void;
}

const useMapFittedToPositions = (
  positions: Nullable<Position[]>,
  canBePositioned: boolean,
  viewport: WebMercatorViewportOptions,
  setViewport: SetViewportProps,
) => {
  const hasSizes = viewport.height && viewport.width;
  useEffect(() => {
    if (!hasSizes || !canBePositioned || !positions || positions.length < 2) return;

    const [minLng, minLat, maxLng, maxLat] = bbox(lineString(positions));
    const newViewport = new WebMercatorViewport(viewport);
    try {
      const { latitude, longitude, zoom } = newViewport.fitBounds(
        [[minLng, minLat], [maxLng, maxLat]],
        { padding: MAP_BOUND_PADDING },
      );
      setViewport(currentViewport => ({
        ...currentViewport,
        latitude,
        longitude,
        zoom,
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 1000,
      }));
    } catch (e) {
      console.error('Error fitting map to bounds', e);
    }
  }, [
    hasSizes,
    JSON.stringify(positions),
    canBePositioned,
  ]);
};

export default useMapFittedToPositions;
