export const DISTRIBUTION_ROUTES_ENDPOINT = 'routes-distribution/aggregations/route-variants-traffic/';
export const DISTRIBUTION_ROUTES_DAY_ENDPOINT = 'routes-distribution/aggregations/route-variants-day-traffic/';
export const DISTRIBUTION_STOPS_ENDPOINT = 'routes-distribution/aggregations/stop-passenger-traffic/';
export const DISTRIBUTION_STOPS_DAY_ENDPOINT = 'routes-distribution/aggregations/stop-day-passenger-traffic/';
export const DISTRIBUTION_DIRECTION_EDGES_ENDPOINT = 'routes-distribution/aggregations/route-direction-edges-traffic/';

export const AC_DIST_ROUTES_MODEL_NAME = 'acDistributionRoutes';
export const AC_DIST_ROUTES_DAY_MODEL_NAME = 'acDistributionRoutesDay';
export const AC_DIST_STOPS_MODEL_NAME = 'acDistributionStops';
export const AC_DIST_STOPS_DAY_MODEL_NAME = 'acDistributionStopsDay';

export const AC_DISTRIBUTION_MODAL_NAME = 'acDistributionModal';
export const AC_DISTRIBUTION_MODAL_DATA_CALC = 'acDistributionModalDataCalc';
