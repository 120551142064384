import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';

import { AcStopsModel } from 'src/cluster/common';
import { selectDistStopsInfo } from 'src/cluster/distribution-table';

const useDistStopsNames = (): SelectMenuOption[] => {
  const variants = useSelector(selectDistStopsInfo);

  return useMemo(() => {
    return variants.orJust([]).map((stop: AcStopsModel) => ({
      id: `${stop.id}`,
      value: stop.id,
      label: stop.stopName,
    }));
  }, [variants]);
};

export default useDistStopsNames;
