import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms, RestifyId } from 'redux-restify';

import { withTranslate } from 'src/i18n';

import { PROJECTS_PAGES_FORM_NAMES } from 'src/modules/general/projects';

import { Systems } from 'src/constants';
import ProjectSelectorDropDown from './ProjectSelectorDropDown';
import { OwnProps } from './intrerfaces';

const mapDispatchToProps = (dispatch: Dispatch<any>, { onChange }: OwnProps) => ({
  changeProject: (id: RestifyId, currentSystem: Systems) => {
    if (onChange) onChange();
    dispatch(forms.actions[PROJECTS_PAGES_FORM_NAMES[currentSystem]].changeField('selectedProject', id));
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(ProjectSelectorDropDown));
