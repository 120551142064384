import React, { Fragment } from 'react';
import { LinearProgress } from '@material-ui/core';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { ProjectModel } from 'src/interfaces';
import { ProcessStatus, StrictDict } from 'src/types';
import { ErrorIcon } from 'src/components';

import useStyles from './styles';

interface Props extends WithTranslate {
  progress: number;
  status: ProcessStatus;
  dict: StrictDict<ProcessStatus, string>;
}

function ProcessStatusLabel<T extends ProjectModel>(props: Props) {
  const {
    progress,
    status,
    dict,
    t,
  } = props;

  const classes = useStyles();

  if (status === ProcessStatus.IN_PROGRESS) {
    const percentageProgress = progress * 100;
    return (
      <Fragment>
        <div className={classes.progress}>
          <span>{t(dict.IN_PROGRESS)}</span>
          {percentageProgress > 0 && (
            <span className={classes.percentage}>
              {percentageProgress}
              %
            </span>
          )}
        </div>
        <LinearProgress
          variant={progress > 0 ? 'determinate' : 'indeterminate'}
          value={percentageProgress}
        />
      </Fragment>
    );
  }

  if (status === ProcessStatus.ERROR) {
    return (
      <span className={classes.error}>
        <ErrorIcon />
        {' '}
        {t(dict.ERROR)}
      </span>
    );
  }

  const className = clsx({
    [classes.success]: status === ProcessStatus.SUCCESS,
    [classes.queued]: status === ProcessStatus.QUEUED,
  });

  return (
    <span className={className}>{t(dict[status])}</span>
  );
}

export default ProcessStatusLabel;
