import {
  createStyles, InputLabel, withStyles,
} from '@material-ui/core';
import { ffOxygen } from 'src/theme/typography';

const CommonLabel = withStyles((theme) => createStyles({
  root: {
    fontSize: 14,
    color: theme.palette.grey[800],
    fontFamily: ffOxygen,
    transform: 'translate(0, 1.5px)',
    '&.Mui-focused': {
      color: theme.palette.grey[800],
    },
  },
}))(InputLabel);

export default CommonLabel;
