import React, { FC, ReactNode } from 'react';
import {
  Box, Divider, Drawer, Grid, Typography,
} from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { SelectValue } from 'src/interfaces';
import {
  Autocomplete, Button, CrossIcon, FormControlLabel,
} from 'src/components';

import { LEGEND_TYPES_DICT } from 'src/cluster/common';

import { LegendType } from '../../../types';
import { useLegendDrawer } from '../../../hooks';

import VehicleIcon from '../VehicleIcon';
import VehicleToggle from '../VehicleToggle';

import useStyles from './styles';

interface OwnProps {
  isShowLegend: boolean;
  variableContent?: ReactNode;
  toggleDrawer(flag?: boolean): void;
  clearLegend(): void;
  setLegendField(field: LegendType, value: boolean | SelectValue): void;
}

type Props = OwnProps & WithTranslate;

const MapLegendDrawer: FC<Props> = (props) => {
  const {
    isShowLegend,
    variableContent = null,
    t,
    toggleDrawer,
    clearLegend,
    setLegendField,
  } = props;

  const classes = useStyles();

  const handleDrawerClose = () => toggleDrawer();

  const [
    infrastructures,
    networks,
    selectTypes,
    parking,
    highlights,
    districts,
    srn,
  ] = useLegendDrawer(setLegendField, classes);

  const getIcon = ({ icon, ...otherProps }: any, width = 18, mr = 0) => (
    <Box width={width} mr={mr}>
      {icon
        ? <VehicleIcon type={icon} {...otherProps} />
        : <Box {...otherProps} />}
    </Box>
  );

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isShowLegend}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        mx={5}
      >
        <Typography className={classes.legend} variant="h6">
          {t('modules.editor.drawer.legend')}
        </Typography>
        <Button
          variant="icon"
          className={classes.closeButton}
          onClick={handleDrawerClose}
        >
          <CrossIcon />
        </Button>
      </Box>
      {variableContent && (
        <Box mb={2} mx={5}>
          {variableContent}
        </Box>
      )}
      <Box mb={2} mx={5}>
        <Typography variant="subtitle1">
          {t('modules.editor.drawer.infrastructure')}
        </Typography>
        <Grid container spacing={3}>
          {infrastructures.map((infrastructure, i) => (
            <Grid key={infrastructure.title} item xs={!i ? 5 : 7}>
              <Typography variant="h6">
                {t(infrastructure.title)}
              </Typography>
              {infrastructure.items.map(item => (
                <FormControlLabel
                  key={item.type}
                  label={t(LEGEND_TYPES_DICT[item.type])}
                  checked={item.checked}
                  onChange={item.onChange}
                  beforeIcon={getIcon(item.iconProps)}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
      {networks.length > 0 && (
        <Box mx={5} my={2}>
          <Typography variant="subtitle1">
            {t('modules.editor.drawer.routeNetwork')}
          </Typography>
          <div className={classes.icons}>
            {networks.map((network) => (
              <span
                key={network.type}
              >
                <VehicleToggle
                  type={network.icon}
                  checked={network.checked}
                  onClick={network.onClick}
                />
              </span>
            ))}
          </div>
          <Box mt={2}>
            {selectTypes.map(type => (
              <Autocomplete
                key={type.label}
                placeholder={t('components.captions.all')}
                multiple={type.multiple}
                disabled={type.disabled}
                label={t(LEGEND_TYPES_DICT[type.label])}
                options={type.items}
                selected={type.selected}
                className={classes.filter}
                onChange={type.command}
              />
            ))}
          </Box>
        </Box>
      )}
      <Divider />
      <Box mx={5} my={2}>
        <Typography variant="subtitle1">
          {t('modules.editor.drawer.parking')}
        </Typography>
        <FormControlLabel
          key={parking.type}
          label={t(LEGEND_TYPES_DICT[parking.type])}
          checked={parking.checked}
          onChange={parking.onChange}
          beforeIcon={getIcon(parking.iconProps)}
        />
      </Box>
      {highlights.length > 0 && (
        <Box mb={2} mx={5}>
          <Typography variant="subtitle1">
            {t('modules.editor.drawer.highlightedStripes')}
          </Typography>
          {highlights.map(h => (
            <FormControlLabel
              key={h.type}
              label={t(LEGEND_TYPES_DICT[h.type])}
              checked={h.checked}
              onChange={h.onChange}
              beforeIcon={getIcon(h.iconProps)}
            />
          ))}
        </Box>
      )}
      <Box mb={2} mx={5}>
        <Typography variant="subtitle1">
          {t('modules.editor.drawer.transportDistrictsAndSites')}
        </Typography>
        {districts.map(d => (
          <FormControlLabel
            key={d.type}
            label={t(LEGEND_TYPES_DICT[d.type])}
            checked={d.checked}
            onChange={d.onChange}
            beforeIcon={getIcon(d.iconProps)}
          />
        ))}
      </Box>
      <Box mb={2} mx={5}>
        <Typography variant="subtitle1">
          {t('modules.editor.drawer.srn')}
        </Typography>
        <FormControlLabel
          key={srn.type}
          label={t(LEGEND_TYPES_DICT[srn.type])}
          checked={srn.checked}
          onChange={srn.onChange}
          beforeIcon={getIcon(srn.iconProps)}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={10} mx={5}>
        <Button variant="link" className={classes.clear} onClick={clearLegend}>
          {t('components.captions.clear')}
        </Button>
      </Box>
    </Drawer>
  );
};

export default MapLegendDrawer;
