import { ThunkActionResult } from 'src/reducer';

import { selectGeojsonForm, geojsonActions, AcRouteVariant } from 'src/cluster/common';
import {
  selectEditorPageForm,
  selectEntitiesEditorForm,
} from 'src/cluster/editor-map';
import { isArray, isNumber } from 'src/helpers';

function reduceRouteToEdgeIdRecord(acc: Record<string, number[]>, variant: AcRouteVariant) {
  variant.routeDirections.forEach((direction) => {
    // eslint-disable-next-line no-param-reassign
    acc[direction.id] = direction.path.flatMap(segment => segment.edges.map((edge => edge.id)));
  });
  return acc;
}

export function refreshGraph(isDeleting?: boolean): ThunkActionResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const { vehicleTypeId } = selectEditorPageForm(state);
    const { edges } = selectGeojsonForm(state);
    const { editableRoute } = selectEntitiesEditorForm(state);

    if (
      !editableRoute || !isArray(editableRoute?.variants)
      || !edges || !isArray(edges.features)
    ) {
      return Promise.reject();
    }

    const directionEdges = editableRoute.variants.reduce(reduceRouteToEdgeIdRecord, {});
    const directionIds = Object.keys(directionEdges).map(id => +id);
    edges.features.forEach((edge, index) => {
      const { properties } = edge;
      if (
        !properties
        || !isNumber(properties?.id)
        || !isArray(properties?.routeVehicleTypes)
        || !isArray(properties?.routeDirections)
      ) {
        return;
      }
      let directionsAfterDel = properties.routeDirections as number[];
      let vehicleTypesAfterDel = properties.routeVehicleTypes as number[];

      directionIds.forEach((id) => {
        if (directionEdges[id].includes(properties.id)) {
          if (isDeleting && properties.routeVehicleTypes.includes(vehicleTypeId)) {
            directionsAfterDel = directionsAfterDel.filter((directionId: number) => directionId !== id);
            if (!directionsAfterDel.length) {
              vehicleTypesAfterDel = vehicleTypesAfterDel.filter((typeId: number) => typeId !== vehicleTypeId);
            }
          } else if (!properties.routeVehicleTypes.includes(vehicleTypeId)) {
            const newRouteVehicleTypes = properties.routeVehicleTypes.concat(vehicleTypeId);
            const newRouteDirections = properties.routeDirections.concat(id);
            edges.features[index].properties = {
              ...properties,
              routeVehicleTypes: newRouteVehicleTypes,
              routeDirections: newRouteDirections,
            };
          }
        } else if (
          properties.routeVehicleTypes.includes(vehicleTypeId)
          && properties.routeDirections.length === 1
          && properties.routeDirections[0] === id
        ) {
          const newRouteVehicleTypes = properties.routeVehicleTypes
            .filter((routeVehicleType: number) => routeVehicleType !== vehicleTypeId);
          const newRouteDirections = properties.routeDirections
            .filter((routeDirectionId: number) => routeDirectionId !== id);
          edges.features[index].properties = {
            ...properties,
            routeVehicleTypes: newRouteVehicleTypes,
            routeDirections: newRouteDirections,
          };
        }
      });

      if (isDeleting) {
        edges.features[index].properties = {
          ...properties,
          routeDirections: directionsAfterDel,
          routeVehicleTypes: vehicleTypesAfterDel,
        };
      }
    });

    dispatch(geojsonActions.changeField('edges', edges));

    return Promise.resolve();
  };
}
