import { useReducer } from 'react';
import { AcBalanceDataModel } from 'src/cluster/common';

function hasDefinedValues(obj: Record<string, any>) {
  return Object.values(obj).filter((value) => {
    return value !== undefined;
  }).length > 0;
}

const EDIT = 'EDIT';
const RESET = 'RESET';

const initialEditState: AcBalanceDataModel[] = [];

const editingReducer =
  (state: AcBalanceDataModel[], { type, payload }: { type: string; payload?: AcBalanceDataModel }) => {
    switch (type) {
      case EDIT: {
        if (!payload) {
          throw new Error(`Unexpected payload: ${payload}`);
        }
        const record = state.find((item: AcBalanceDataModel) => item.id === payload.id);
        if (record) {
          return state.map((item: AcBalanceDataModel) => {
            if (item.id === record.id) {
              return {
                ...record,
                ...payload,
              };
            }

            return item;
          }).filter((item: AcBalanceDataModel) => hasDefinedValues(item));
        }
        return hasDefinedValues(payload) ? [...state, payload] : [...state];
      }
      case RESET: {
        return initialEditState;
      }
      default: {
        throw new Error(`Unexpected action: ${type}`);
      }
    }
  };

function editAction(payload: AcBalanceDataModel) {
  return {
    type: EDIT,
    payload,
  };
}

function resetAction() {
  return {
    type: RESET,
  };
}

export default function useEdit(): [AcBalanceDataModel[], (record: AcBalanceDataModel) => void, () => void] {
  const [state, dispatch] = useReducer(editingReducer, initialEditState);

  const edit = (record: AcBalanceDataModel) => {
    dispatch(editAction(record));
  };

  const reset = () => dispatch(resetAction());

  return [state, edit, reset];
}
