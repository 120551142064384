import React, { FC, useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';

import { AcEdgeSpeed, useVehicleTypeName, useVehicleTypes } from 'src/cluster/editor-map';
import { VehicleButton } from 'src/cluster/common';

import useStyles from './styles';

interface OwnProps {
  isModifying: boolean;
  speeds: Model<AcEdgeSpeed>[];
  onToggle(id: number): void;
}

type Props = OwnProps & WithTranslate;

const EditorDedicatedLaneToggles: FC<Props> = (props) => {
  const {
    isModifying,
    speeds,
    t,
    onToggle,
  } = props;

  const classes = useStyles();
  const getVehicleTypeName = useVehicleTypeName();
  const vehicleTypes = useVehicleTypes();

  const actualSpeeds = useMemo(() => {
    return speeds.filter(speed => vehicleTypes.find(type => type.isPublic && type.id === speed.vehicleTypeId));
  }, [speeds, vehicleTypes]);

  return (
    <div className={classes.root}>
      <Typography className={classes.caption}>
        {t('modules.editor.captions.dedicatedVehicleTypesShort')}
      </Typography>
      <div className={classes.icons}>
        {actualSpeeds.length > 0 ? actualSpeeds.map((speed) => {
          const vehicleTypeName = getVehicleTypeName(speed.vehicleTypeId);
          if (!vehicleTypeName) return null;
          const handleClick = () => onToggle(speed.id);
          return (
            <span
              key={speed.vehicleTypeId}
            >
              <VehicleButton
                type={vehicleTypeName}
                checked={speed.dedicatedLane}
                disabled={!isModifying}
                onClick={handleClick}
              />
            </span>
          );
        }) : (
          <span>
            <VehicleButton
              disabled
              checked={false}
              onClick={() => undefined}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default EditorDedicatedLaneToggles;
