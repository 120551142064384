import React from 'react';

const SuburbanIcon = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.73684 0.5C3.36842 0.5 0 0.921053 0 3.86842V11.8684C0 12.6501 0.310526 13.3998 0.863264 13.9525C1.416 14.5053 2.16568 14.8158 2.94737 14.8158L1.68421 16.0789V16.5H3.5621L5.24632 14.8158H8.42105L10.1053 16.5H11.7895V16.0789L10.5263 14.8158C11.308 14.8158 12.0577 14.5053 12.6104 13.9525C13.1632 13.3998 13.4737 12.6501 13.4737 11.8684V3.86842C13.4737 0.921053 10.4589 0.5 6.73684 0.5ZM2.94737 13.1316C2.61236 13.1316 2.29107 12.9985 2.05418 12.7616C1.81729 12.5247 1.68421 12.2034 1.68421 11.8684C1.68421 11.5334 1.81729 11.2121 2.05418 10.9752C2.29107 10.7383 2.61236 10.6053 2.94737 10.6053C3.28238 10.6053 3.60367 10.7383 3.84056 10.9752C4.07744 11.2121 4.21053 11.5334 4.21053 11.8684C4.21053 12.2034 4.07744 12.5247 3.84056 12.7616C3.60367 12.9985 3.28238 13.1316 2.94737 13.1316ZM5.89474 7.23684H1.68421V3.86842H5.89474V7.23684ZM7.57895 7.23684V3.86842H11.7895V7.23684H7.57895ZM10.5263 13.1316C10.1913 13.1316 9.87002 12.9985 9.63313 12.7616C9.39624 12.5247 9.26316 12.2034 9.26316 11.8684C9.26316 11.5334 9.39624 11.2121 9.63313 10.9752C9.87002 10.7383 10.1913 10.6053 10.5263 10.6053C10.8613 10.6053 11.1826 10.7383 11.4195 10.9752C11.6564 11.2121 11.7895 11.5334 11.7895 11.8684C11.7895 12.2034 11.6564 12.5247 11.4195 12.7616C11.1826 12.9985 10.8613 13.1316 10.5263 13.1316Z"
      fill="currentColor"
    />
  </svg>
);

export default SuburbanIcon;
