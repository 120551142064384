import {
  createStyles, fade, makeStyles, Theme,
} from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  idColumn: {
    width: 72,
  },

  nameColumn: {
    width: 'calc(30% - 72px)',
  },

  dateColumn: {
    width: '20%',
  },

  statusColumn: {
    width: '20%',

    '& button:hover': {
      color: theme.palette.primary.main,
    },
  },

  userColumn: {
    width: '30%',
  },

  lastColumn: {
    paddingRight: theme.spacing(20),
  },

  fileTypeColumn: {
    width: '30%',
    fontStyle: 'italic',

    '&:first-of-type': {
      paddingLeft: 88,
    },

    '& > div': {
      left: 45,
    },
  },

  fileNameColumn: {
    width: '20%',
  },

  fileErrorColumn: {
    width: '50%',
  },

  row: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],

      '&> td:first-of-type::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: 3,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },

  headCell: {
    whiteSpace: 'nowrap',
  },

  successRow: {
    cursor: 'pointer',
  },

  selectedRow: {
    backgroundColor: theme.palette.warning.light,

    '&:hover': {
      backgroundColor: fade(theme.palette.warning.light, 0.3),
    },
  },

  errorRow: {
    backgroundColor: theme.palette.error.light,
    cursor: 'default',

    '&:hover': {
      backgroundColor: fade(theme.palette.error.light, 0.3),
    },
  },

  fileRow: {
    backgroundColor: fade(theme.palette.grey[300], 0.3),
  },

  hidden: {
    display: 'none',
  },

  error: {
    outline: 0,
    padding: 0,
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },

  generalErrorRow: {
    padding: 10,
    fontStyle: 'italic',
    color: theme.palette.common.white,
    fontSize: 12,
    lineHeight: '12px',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[400],

    '& :first-child': {
      marginRight: 5,
      verticalAlign: 'top',
      paddingTop: 10,
    },
  },
}));
