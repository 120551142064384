import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

type Props = {
  className?: string;
  label: string;
};

const EditorTreeCaption: FC<Props> = (props) => {
  const {
    className,
    label,
  } = props;

  const classes = useStyles();

  return (
    <Typography
      component="span"
      classes={classes}
      className={className}
      title={label}
    >
      {label}
    </Typography>
  );
};

export default EditorTreeCaption;
