import React from 'react';

const SwitchIcon = () => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1183 6.69481L13 3.82467L10.1183 0.954545L10.1183 3.10714L5.05556 3.10714L5.05556 4.54221L10.1183 4.54221L10.1183 6.69481ZM-2.50915e-07 8.12987L2.88167 11L2.88167 8.8474L7.94444 8.8474L7.94444 7.41234L2.88167 7.41234L2.88167 5.25974L-2.50915e-07 8.12987Z"
      fill="#E5293C"
    />
  </svg>
);

export default SwitchIcon;
