import { useTranslation } from 'react-i18next';
import { FILTER_MODES } from 'src/cluster/common';
import { SelectMenuOption } from 'src/interfaces';

const useAcFilterModesOptions: () => SelectMenuOption[] = () => {
  const { t } = useTranslation();
  return [{
    id: FILTER_MODES.node,
    label: t('systems.balance.filterModes.node'),
    value: FILTER_MODES.node,
  }, {
    id: FILTER_MODES.municipality,
    label: t('systems.balance.filterModes.municipality'),
    value: FILTER_MODES.municipality,
  }];
};

export default useAcFilterModesOptions;
