import { makeStyles } from '@material-ui/core';

import { getPrimaryColumnStyles } from 'src/cluster/dashboard-table';

export default makeStyles(({ spacing }) => ({
  root: {
    height: 'unset',
  },

  primaryColumn: getPrimaryColumnStyles(spacing),

  secondaryColumn: {
    width: '15%',
  },
}));
