import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RestifyFormActions } from 'redux-restify';

import i18n, { getVocabularyValue, useCurrentVocabulary } from 'src/i18n';
import modals from 'src/modals';
import { useSystemsContext } from 'src/constants';
import routes, { PageRoutesForm } from 'src/modules/shipments/routes';

interface Values {
  fromItems: { value: string; label: string }[];
  toItems: { value: string; label: string }[];
  macroeconomicScenarioItems: { value: string; label: string }[];
  yearItems: { value: number; label: number }[];
  transportScenarioValues: string[];
  transportScenarioItems: { value: string; label: string }[];
  goodsGroupsItems: { value: string; label: string }[];
  isYearDisabled: boolean;
  isGoodsGroupsDisabled: boolean;
}

interface Handlers {
  handleFromChange: (values: any) => void;
  handleToChange: (values: any) => void;
  handleLengthPercentMaxChange: (values: any) => void;
  handleUniqPercentMaxChange: (value: any) => void;
  handleMacroeconomicScenarioChange: (values: any) => void;
  handleYearChange: (values: any) => void;
  handleTransportScenariosMultiValueChange: (values: any) => void;
  handleTransportScenariosChange: (values: any) => void;
  handleGoodsGroupChange: (values: any) => void;
  handleCalculateRouteClick: () => void;
}

export const useValues = (currentSystem: string, form: PageRoutesForm): Values => {
  const { currentNodes } = useSelector(routes.selectors[currentSystem].getCurrentProjectNodes);
  const projectChoices = useSelector(routes.selectors[currentSystem].getProjectChoices);
  const [currentLocale, vocabulary] = useCurrentVocabulary();

  const fromItems = useMemo(() => currentNodes.map(n => ({
    value: n.name,
    label: getVocabularyValue(currentLocale, vocabulary, n.name),
  })), [currentNodes, currentLocale, vocabulary]);

  const toItems = useMemo(() => currentNodes.map(n => ({
    value: n.name,
    label: getVocabularyValue(currentLocale, vocabulary, n.name),
  })), [currentNodes, currentLocale, vocabulary]);

  const macroeconomicScenarioItems = useMemo(() => projectChoices.macroeconomicScenarios.map(n => ({
    value: n.label,
    label: n.label,
    description: n.description,
  })), [projectChoices]);

  const yearItems = useMemo(() => projectChoices.years.map(n => ({
    value: n,
    label: n,
  })), [projectChoices]);

  const transportScenarioValues = useMemo(() => form.transportScenarios.filter(
    node => (
      projectChoices.transportScenarios.includes(node)
    ),
  ), [form, projectChoices]);

  const transportScenarioItems = useMemo(() => projectChoices.transportScenarios.filter(
    node => (
      form.transportScenarios === undefined
      || !form.transportScenarios.includes(node)
    ),
  ).map(n => ({
    value: n,
    label: n,
  })), [form, projectChoices]);

  const goodsGroupsItems = useMemo(() => projectChoices.goodsGroups.map(n => ({
    value: n,
    label: n,
  })), [projectChoices]);

  const isYearDisabled = useMemo(() => !projectChoices.years.length, [projectChoices]);
  const isGoodsGroupsDisabled = useMemo(() => !projectChoices.goodsGroups.length, [projectChoices]);

  return {
    fromItems,
    toItems,
    macroeconomicScenarioItems,
    yearItems,
    transportScenarioValues,
    transportScenarioItems,
    goodsGroupsItems,
    isYearDisabled,
    isGoodsGroupsDisabled,
  };
};

export const useHandlers = (form: PageRoutesForm, formActions: RestifyFormActions): Handlers => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();

  const handleFromChange = useCallback(
    (values) => dispatch(formActions.changeField('from', values[0])),
    [dispatch, formActions],
  );

  const handleToChange = useCallback(
    (values) => dispatch(formActions.changeField('to', values[0])),
    [dispatch, formActions],
  );

  const handleLengthPercentMaxChange = useCallback(
    (value) => dispatch(formActions.changeField('lengthPercentMax', value)),
    [dispatch, formActions],
  );

  const handleUniqPercentMaxChange = useCallback(
    (value) => dispatch(formActions.changeField('uniqPercentMax', value)),
    [dispatch, formActions],
  );

  const handleMacroeconomicScenarioChange = useCallback(
    (values) => dispatch(formActions.changeField('macroeconomicScenario', values[0])),
    [dispatch, formActions],
  );

  const handleYearChange = useCallback(
    (values) => dispatch(formActions.changeField('year', values[0])),
    [dispatch, formActions],
  );

  const handleTransportScenariosMultiValueChange = useCallback(
    (values) => dispatch(formActions.changeField('transportScenarios', values)),
    [dispatch, formActions],
  );

  const handleTransportScenariosChange = useCallback(
    (values) => {
      if (form.transportScenarios === undefined) {
        // eslint-disable-next-line no-param-reassign
        form.transportScenarios = [];
      }
      dispatch(
        formActions.changeField(
          'transportScenarios', form.transportScenarios.concat(values.toString()),
        ),
      );
    },
    [form, dispatch, formActions],
  );

  const handleGoodsGroupChange = useCallback(
    (values) => dispatch(
      formActions.changeField('goodsGroup', values[0]),
    ),
    [dispatch, formActions],
  );

  const handleCalculateRouteClick = useCallback(
    () => {
      dispatch(routes.actions[currentSystem].findRoutes()).then(foundRoutes => {
        if (!foundRoutes.currentRoutes || !foundRoutes.currentRoutes.length) {
          dispatch(modals.actions.showMessageBoxModal({
            text: i18n.t('systems.cities.captions.noRoutesFound'),
          }));
        }
      });
    },
    [currentSystem, dispatch, formActions],
  );

  return {
    handleFromChange,
    handleToChange,
    handleLengthPercentMaxChange,
    handleUniqPercentMaxChange,
    handleMacroeconomicScenarioChange,
    handleYearChange,
    handleTransportScenariosMultiValueChange,
    handleTransportScenariosChange,
    handleGoodsGroupChange,
    handleCalculateRouteClick,
  };
};
