import { RestifyId } from 'redux-restify';
import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';
import {
  MATRIX_CORRECTION_ENDPOINT,
  MATRIX_MACROECONOMICS_ENDPOINT,
} from 'src/api-url-schema';

export const MACROECONOMICS_ENDPOINTS = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: MATRIX_MACROECONOMICS_ENDPOINT,
};

export const CORRECTION_ENDPOINTS = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: MATRIX_CORRECTION_ENDPOINT,
};

export const MACROECONOMICS_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: 'pageMatrixMacroeconomicsForm',
};

export const MACROECONOMICS_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: 'matrixMacroeconomics',
};

export interface MacroeconomicsModel {
  id?: RestifyId;
  year?: number;
  region?: string;
  macroeconomics?: number;
  mobilityIncrease?: number;
  nonInflationaryIncomeGrowth?: number;
}

export interface MacroeconomicsRecord {
  [key: string]: string | number;
}

export interface PageMacroeconomicsForm {
  grpCorrection: number;
  yearFrom: number;
  yearTo: number;
  regions: string[];
}
