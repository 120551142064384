import { AcProjectListForm } from 'src/cluster/common';

const defaults: AcProjectListForm = {
  selectedProject: undefined,
  pageSize: 10,
  currentPage: 1,
  loadStatus: undefined,
};

export default { defaults };
