import { VIDEO_ROUTE_TRIPS_ENDPOINT } from '../constants';

export default {
  endpoint: VIDEO_ROUTE_TRIPS_ENDPOINT,
  defaults: {
    id: undefined,
    description: undefined,
    geometries: undefined,
    stops: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  pagination: false,
};
