import { createStyles, makeStyles } from '@material-ui/core';
import { ffOxygen } from 'src/theme/typography';

export default makeStyles(() => createStyles({
  root: {
    opacity: 0.9,
    fontFamily: ffOxygen,
    fontSize: 12,
  },
}));
