import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    paddingTop: spacing(6),
  },

  header: {
    paddingRight: spacing(7),

    '&> h5': {
      lineHeight: 1.2,
      marginBottom: spacing(2),
    },
  },

  container: {
    padding: spacing(4, 20),

    '&:not(:last-of-type)': {
      margin: 0,
    },
  },

  footer: {
    paddingBottom: 0,
  },

  select: {
    marginBottom: spacing(4),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
