import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      padding: spacing(2, 0),
      backgroundColor: palette.common.white,
      overflowY: 'auto',

      '&> $subtitle': {
        marginBottom: spacing(2),
        padding: spacing(0, 4),
      },
    },
    routeGroup: {
      paddingBottom: spacing(2),

      '&:not(:last-of-type)': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.grey[200],
        marginBottom: spacing(2),
      },
    },
    route: {
      padding: spacing(0, 4),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
    routeIcon: {
      verticalAlign: 'middle',
      marginRight: spacing(1),
    },
    routeName: {
      cursor: 'pointer',
      fontSize: 12,
      fontFamily: ffSourceSansPro,
      lineHeight: 1.75,
      color: palette.grey[800],
      verticalAlign: 'middle',
    },
    selected: {
      cursor: 'auto',
      fontWeight: 'bold',
    },
    subtitle: {
      fontWeight: 600,
      fontSize: 12,
      color: palette.text.primary,
      fontFamily: ffSourceSansPro,
    },
  });
});
