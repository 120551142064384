import { isNumber, isObject } from 'src/helpers';

import { AcDistributionExtreme } from 'src/cluster/common';

export function isAcDistributionExtreme(record: unknown): record is AcDistributionExtreme {
  if (!isObject(record)) {
    return false;
  }

  const { minimum, maximum } = record as Partial<AcDistributionExtreme>;

  const isValidRecord = isNumber(minimum) && isNumber(maximum);

  if (!isValidRecord) {
    console.warn('The record is not a valid AcDistributionExtreme.');
  }

  return isValidRecord;
}
