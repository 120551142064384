import React, { FC, Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  RestifyEntitiesArray,
  RestifyFormActions,
  RestifyId,
  RestifyModel,
  WithId,
} from 'redux-restify';

import { WithTranslate } from 'src/i18n';

import PTable from 'src/components/deprecated/PTable';
import PCheckbox from 'src/components/deprecated/PCheckbox';
import Pagination from 'src/components/etc/Pagination';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import SmartDate, { SMART_DATE_FORMATS } from 'src/components/etc/SmartDate';
import {
  CALCULATIONS_STATUSES,
  CALCULATIONS_STATUSES_DICT,
} from 'src/modules/general/calculations/constants';
import { CalculationModel, PageCalculationForm } from 'src/modules/general/calculations/interfaces';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  buttons?: any;
  calculations: RestifyEntitiesArray<RestifyModel<CalculationModel>>;
  isLoading?: boolean;
  count: number;
  form: PageCalculationForm;
  formActions: RestifyFormActions<PageCalculationForm, PageCalculationForm>;
  hasActuality?: boolean;
  statusPlaceholder?: ReactNode;
  onPatch?: (id: RestifyId, value: boolean) => void;
}

const CalculationsTable: FC<Props> = (props: Props) => {
  const {
    className,
    buttons,
    calculations,
    isLoading = false,
    count,
    form,
    formActions,
    hasActuality = false,
    statusPlaceholder = <Fragment>&mdash;</Fragment>,
    onPatch = () => undefined,
    t,
  } = props;
  const dispatch = useDispatch();

  const handleActivityChange = (id?: RestifyId) => (value: boolean) => {
    if (id) {
      onPatch(id, value);
    }
  };

  const createActualityFilterChangeHandler = (value: boolean) => () => {
    dispatch(formActions.changeField('isActual', value));
    dispatch(formActions.changeField('currentPage', 1));
    dispatch(formActions.changeField('version', form.version + 1));
  };

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading,
      className: classNames(style.root, className),
    }}>
      <Pagination {...{
        pageSize: form.pageSize,
        currentPage: form.currentPage,
        count,
        onPageChange: (page: number) => dispatch(formActions.changeField('currentPage', page)),
        onPageSizeChange: (pageSize: number) => {
          dispatch(formActions.resetField('currentPage'));
          dispatch(formActions.changeField('pageSize', pageSize));
        },
      }}>
        <PTable<WithId<CalculationModel>> {...{
          className: style.table,
          headerCellClassName: style.headerCell,
          tableCellClassName: style.cell,
          rowClassName: (item) => {
            return [
              hasActuality && !item.isActual && style.notActualRow,
              style.row,
            ];
          },
          hoverable: true,
          isNew: (item) => item.isNew || false,
          hoveredComponent: (item) => {
            return buttons(item);
          },
          header: [
            {
              title: t('common.captions.numberSymbol'),
              model: (model: CalculationModel) => model.id,
            },
            {
              title: t('common.captions.startDate'),
              model: (model: CalculationModel) => (
                <SmartDate format={SMART_DATE_FORMATS.simpleDateWithTime} date={model.startDate} />
              ),
            },
            {
              title: t('common.captions.endDate'),
              model: (model: CalculationModel) => (
                <SmartDate format={SMART_DATE_FORMATS.simpleDateWithTime} date={model.finishDate} />
              ),
            },
            {
              title: t('common.captions.correspondenceCount'),
              model: (model: CalculationModel) => model.correspondenceCount,
            },
            {
              title: t('common.captions.parameters'),
              model: (model: CalculationModel) => model.parameters,
            },
            {
              title: t('common.captions.file'),
              model: (model: CalculationModel) => model.file,
            },
            {
              title: t('common.captions.status'),
              model: (model: CalculationModel) => {
                if (!model.status) return statusPlaceholder;
                if (model.status === CALCULATIONS_STATUSES.inProgress) {
                  const progress = Math.floor((model.progress || 0) * 100);
                  return (
                    <div className={style.progress}>
                      <div>
                        {t(CALCULATIONS_STATUSES_DICT[model.status])}
                        <span className={style.percent}>{progress}</span>
                        <span>%</span>
                      </div>
                      <div className={style.progressBar}>
                        <div {...{
                          className: style.progressBarInner,
                          style: {
                            width: `${progress}%`,
                          },
                        }} />
                      </div>
                    </div>
                  );
                }
                return t(CALCULATIONS_STATUSES_DICT[model.status]);
              },
            },
            {
              title: t('modules.calculations.captions.actuality'),
              className: style.actualColumn,
              model: (model: CalculationModel) => (
                <div className={style.actualCell}>
                  <PCheckbox
                    className={style.checkbox}
                    value={model.isActual}
                    onChange={handleActivityChange(model.id)}
                  />
                </div>
              ),
              withActuality: true,
            },
            {
              title: hasActuality ? (
                <div className={style.actualityButtonWrapper}>
                  (
                  <button
                    className={style.actualityButton}
                    disabled={!form.isActual}
                    onClick={createActualityFilterChangeHandler(false)}
                  >
                    {t('modules.calculations.captions.all')}
                  </button>
                  |
                  <button
                    className={style.actualityButton}
                    disabled={form.isActual}
                    onClick={createActualityFilterChangeHandler(true)}
                  >
                    {t('modules.calculations.captions.actual')}
                  </button>
                  )
                </div>
              ) : '',
              className: style.lastColumn,
              model: () => '',
            },
          ].filter(item => !item.withActuality || hasActuality),
          body: calculations,
        }} />
      </Pagination>
    </LoadingBlockContainer>
  );
};

export default CalculationsTable;
