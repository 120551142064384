import { useAcIntervalName, useAcVehicleClassLabel, useAcVehicleTypeLabel } from 'src/cluster/common';

const useEntitiesForPartition = (entityName: string | null, entityId: number | null): string | undefined => {
  const getIntervalName = useAcIntervalName();
  const getVehicleClassLabel = useAcVehicleClassLabel();
  const getVehicleTypeLabel = useAcVehicleTypeLabel();

  if (!entityName || !entityId) {
    return undefined;
  }

  switch (entityName) {
    case 'interval': return getIntervalName(entityId);
    case 'vehicle_class': return getVehicleClassLabel(entityId);
    case 'vehicle_type': return getVehicleTypeLabel(entityId);
    default: return '-';
  }
};

export default useEntitiesForPartition;
