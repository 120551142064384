import { useMemo } from 'react';

import {
  makeMapStyleStopLayer, VEHICLE_TYPES_COLORS, VehicleType,
} from 'src/cluster/common';
import { useVehicleTypeName } from 'src/cluster/editor-map';

import palette from 'src/theme/palette';

import useRadiusRange from './useRadiusRange';

const useStopsByVehicleTypesLayers = (
  ids: number[],
  visible: boolean,
  deps: any[],
) => {
  const range = useRadiusRange();

  const getVehicleTypeName = useVehicleTypeName();

  return useMemo(() => {
    const allTypesLayer = makeMapStyleStopLayer(
      'stops-total-vehicle-types',
      visible && ids.includes(-1),
      ['has', 'passenger_traffic_total'],
      {
        'circle-color': VEHICLE_TYPES_COLORS.all,
        'circle-radius': [
          'case',
          ...range.reduce<any[]>((memo, item) => {
            return memo.concat([
              [
                'all',
                ['>', ['get', 'passenger_traffic_total'], item.from],
                ['<=', ['get', 'passenger_traffic_total'], item.to],
              ], item.value,
            ]);
          }, []), 2,
        ],
        'circle-stroke-width': 1,
        'circle-stroke-color': palette.common.white,
      },
    );

    const layers = ids.filter(id => !!getVehicleTypeName(id)).map((id) => {
      const color = VEHICLE_TYPES_COLORS[getVehicleTypeName(id) ?? VehicleType.all];
      const name = getVehicleTypeName(id);
      return makeMapStyleStopLayer(
        `stops-by-vehicle-type-${name}`,
        visible,
        ['has', ['to-string', id], ['get', 'vehicle_type_traffic']],
        {
          'circle-color': color,
          'circle-radius': [
            'case',
            ...range.reduce<any[]>((memo, item) => {
              return memo.concat([
                [
                  'all',
                  ['>', ['get', ['to-string', id], ['get', 'vehicle_type_traffic']], item.from],
                  ['<=', ['get', ['to-string', id], ['get', 'vehicle_type_traffic']], item.to],
                ], item.value,
              ]);
            }, []), 2,
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': palette.common.white,
        },
      );
    });

    return [allTypesLayer, ...layers];
  }, [...deps, visible, range]);
};

export default useStopsByVehicleTypesLayers;
