import { useEffect, useState } from 'react';
import { RestifyId } from 'redux-restify';

import { ProjectModel } from 'src/modules/general/projects';
import { ToastType } from 'src/modules/general/projects/components/ProjectsTable/interfaces';
import { ProcessStatus } from 'src/types';

type StatusRecord = {
  id: RestifyId;
  loadStatus: string;
};

export const useStatusUpdate = <T extends ProjectModel>(
  projects: T[],
  loadProject: (project: T) => void,
  hasStatus: boolean,
  onStatusChange: (type: ToastType, message: string, project: ProjectModel) => void,
  t: (key: string) => string,
  queryInterval = 10000,
) => {
  const [records, setRecords] = useState<StatusRecord[]>([]);

  useEffect(() => {
    if (hasStatus) {
      records.forEach((record) => {
        const project = projects.find((item) => item.id === record.id);
        if (!project) {
          return;
        }
        if (project.loadStatus !== record.loadStatus) {
          if (project.loadStatus === ProcessStatus.ERROR) {
            onStatusChange(
              'error',
              `${t('common.captions.numberSymbol')}${project.id}: ${project.loadErrorMessage}`,
              project,
            );
          }
          if (project.loadStatus === ProcessStatus.SUCCESS) {
            onStatusChange(
              'success',
              `${t('common.captions.numberSymbol')}${project.id}: ${t('modules.projects.messages.success')}`,
              project,
            );
          }
          if (project.loadStatus === ProcessStatus.IN_PROGRESS) {
            onStatusChange(
              'info',
              `${t('common.captions.numberSymbol')}${project.id}: ${t('modules.projects.messages.processingStarted')}`,
              project,
            );
          }
        }
      });
      const newRecords = projects
        .map((project) => ({ id: project.id, loadStatus: project.loadStatus }))
        .filter((project) => (
          project.loadStatus === ProcessStatus.QUEUED
          || project.loadStatus === ProcessStatus.IN_PROGRESS
        ));
      setRecords(newRecords);
    }
    const timer = setInterval(() => {
      if (hasStatus) {
        const projectsInProgress = projects.filter((project) => {
          return project.loadStatus === ProcessStatus.QUEUED
            || project.loadStatus === ProcessStatus.IN_PROGRESS;
        });
        projectsInProgress.forEach(loadProject);
      }
    }, queryInterval);

    return () => clearInterval(timer);
  }, [JSON.stringify(projects)]);
};
