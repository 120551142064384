import React, {
  FC, Fragment, useEffect, useMemo, useRef,
} from 'react';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import PTable from 'src/components/deprecated/PTable';
import NumberFormat from 'src/components/etc/NumberFormat';
import TimeFormat from 'src/components/etc/TimeFormat';

import { UNITS } from 'src/modules/general/common';

import TransportTypePercent from './components/AggloTransportTypePercent';
import AggloTransportTypeIcons from './components/AggloTransportTypeIcons';
import AggloSingleRouteTable from './components/AggloSingleRouteTable';

import { Props } from './interfaces';
import { RouteWithAggregation } from '../../../../interfaces';

import style from './index.module.css';

function getSpeed(a: RouteWithAggregation) {
  return a.aggregation.summary.distance / a.aggregation.summary.time;
}

const AggloRouteTable: FC<Props> = (props: Props) => {
  const {
    form,
    routes,
    locale,
    t,
    onRowClick,
    onExpanded,
    onSort,
  } = props;

  const [vehicleTraffic, passTraffic]: [number, number] = useMemo(() => {
    return routes.reduce(([traffic, trafficPass], route) => {
      return [
        traffic + route.aggregation.summary.traffic,
        trafficPass + route.aggregation.summary.trafficPass,
      ];
    }, [0, 0]);
  }, [JSON.stringify(routes)]);

  // scroll to selected route
  const rowRefs = useRef<any[]>([]);
  useEffect(() => {
    if (form.selectedRoute !== undefined) {
      const index = routes.findIndex((route) => form.selectedRoute === route.id);
      const currentRow = rowRefs.current[index];
      if (currentRow) {
        scrollIntoViewIfNeeded(currentRow, {
          scrollMode: 'always',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [form.selectedRoute]);

  if (routes.length === 0) {
    return (
      <div className={style.noRoutes}>
        <span>{t('common.messages.noRoutesFoundMessage')}</span>
      </div>
    );
  }

  return (
    <PTable<typeof routes[0]> {...{
      rowClassName: (route) => {
        return [
          form.selectedRoute === route.id,
          form.expandedRoute === route.id && style.expandedRow,
          style.row,
        ];
      },
      headerCellClassName: style.headerCell,
      tableCellClassName: style.tableCell,
      expandable: true,
      isExpanded: (route) => form.expandedRoute === route.id,
      rowExtraCell: (route) => {
        if (route.id !== form.expandedRoute) return null;
        const { segments } = route.aggregation;
        return <AggloSingleRouteTable segments={segments} />;
      },
      rowRef: (node, item, index) => {
        if (node) {
          rowRefs.current[index] = node;
        }
      },
      sortingColumn: form.sortingColumn,
      sortingOrder: form.sortingOrder,
      header: [
        {
          title: '#',
          className: style.numberColumn,
          model: (model, modelIndex) => modelIndex + 1,
        },
        {
          name: 'speed',
          title: (
            <span>
              {t('systems.mth.captions.speed')}
              &nbsp;
              <span className={style.units}>
                (
                {t(UNITS.speed)}
                )
              </span>
            </span>
          ),
          className: style.speedColumn,
          model: (model) => (
            <NumberFormat {...{
              value: getSpeed(model),
              locale,
              decimalScale: 2,
              zeroPlaceholder: '-',
            }} />
          ),
          sortable: true,
          sort: (a: RouteWithAggregation, b: RouteWithAggregation) => getSpeed(b) - getSpeed(a),
        },
        {
          name: 'ttc',
          title: (
            <span>
              {t('systems.mth.captions.ttcShort')}
              &nbsp;
              <span className={style.units}>
                (
                {t(UNITS.costRub)}
                )
              </span>
            </span>
          ),
          className: style.ttcColumn,
          model: (model) => (
            <NumberFormat {...{
              value: model.weight,
              locale,
              decimalScale: 2,
              zeroPlaceholder: '-',
            }} />
          ),
          sortable: true,
          sort: (a: RouteWithAggregation, b: RouteWithAggregation) => {
            return b.weight - a.weight;
          },
        },
        {
          name: 'time',
          title: (
            <span>
              {t('systems.mth.captions.time')}
              &nbsp;
              <span className={style.units}>
                (
                {t(UNITS.time)}
                )
              </span>
            </span>
          ),
          className: style.timeColumn,
          model: (model) => {
            const totalTime = model.aggregation.summary.time;
            return (
              <span className={style.cellWithIcons}>
                <span className={style.valueBeforeIcons}>
                  <TimeFormat {...{
                    value: totalTime,
                  }} />
                </span>
                <span className={style.iconsBlock}>
                  <AggloTransportTypeIcons
                    types={model.aggregation.timeTypes}
                    total={totalTime}
                  />
                  <TransportTypePercent
                    quantity="time"
                    segmentTypes={model.aggregation.segmentTypes}
                    total={totalTime}
                  />
                </span>
              </span>
            );
          },
          sortable: true,
          sort: (a: RouteWithAggregation, b: RouteWithAggregation) => {
            return b.aggregation.summary.time - a.aggregation.summary.time;
          },
        },
        {
          name: 'length',
          title: (
            <span>
              {t('systems.mth.captions.length')}
              &nbsp;
              <span className={style.units}>
                (
                {t(UNITS.length)}
                )
              </span>
            </span>
          ),
          className: style.lengthColumn,
          model: (model) => {
            const totalDistance = model.aggregation.summary.distance;
            return (
              <span className={style.cellWithIcons}>
                <span className={style.valueBeforeIcons}>
                  <NumberFormat {...{
                    value: totalDistance,
                    locale,
                    decimalScale: 2,
                    zeroPlaceholder: '-',
                  }} />
                </span>
                <span className={style.iconsBlock}>
                  <AggloTransportTypeIcons
                    types={model.aggregation.distanceTypes}
                    total={totalDistance}
                  />
                  <TransportTypePercent
                    quantity="distance"
                    segmentTypes={model.aggregation.segmentTypes}
                    total={totalDistance}
                  />
                </span>
              </span>
            );
          },
          sortable: true,
          sort: (a: RouteWithAggregation, b: RouteWithAggregation) => {
            return b.aggregation.summary.distance - a.aggregation.summary.distance;
          },
        },
        {
          name: 'traffic',
          title: (
            <div className={style.trafficHeader}>
              <div>
                {t('systems.mth.captions.traffic')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.vehicles)}
                  )
                </span>
              </div>
              <div className={style.trafficSum}>
                {t('systems.mth.captions.sum')}
                :
                &nbsp;
                <NumberFormat {...{
                  value: vehicleTraffic,
                  locale,
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              </div>
            </div>
          ),
          model: (model) => {
            return (
              <NumberFormat {...{
                value: model.aggregation.summary.traffic,
                locale,
                decimalScale: 2,
                zeroPlaceholder: '-',
              }} />
            );
          },
          sortable: true,
          sort: (a: RouteWithAggregation, b: RouteWithAggregation) => {
            if (a.aggregation.summary.traffic && b.aggregation.summary.traffic) {
              return b.aggregation.summary.traffic - a.aggregation.summary.traffic;
            }
            if (a.aggregation.summary.traffic) {
              return -1;
            }
            if (b.aggregation.summary.traffic) {
              return 1;
            }
            return b.aggregation.summary.trafficPass - a.aggregation.summary.trafficPass;
          },
        },
        {
          name: 'trafficPass',
          title: (
            <div className={style.trafficHeader}>
              <div>
                {t('systems.mth.captions.passengerFlowShort')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.peoplePerHour)}
                  )
                </span>
              </div>
              <div className={style.trafficSum}>
                {t('systems.mth.captions.sum')}
                :
                &nbsp;
                <NumberFormat {...{
                  value: passTraffic,
                  locale,
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              </div>
            </div>
          ),
          model: (model) => {
            const { trafficPass } = model.aggregation.summary;
            return (
              <Fragment>
                <NumberFormat {...{
                  value: trafficPass,
                  locale,
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
                {
                  trafficPass > 0 && (
                    <Fragment>
                      {' '}
                      <span className={style.trafficPercent}>
                        (
                        <NumberFormat {...{
                          value: 100 * trafficPass / passTraffic,
                          locale,
                          decimalScale: 2,
                          suffix: '%',
                          zeroPlaceholder: '-',
                        }} />
                        )
                      </span>
                    </Fragment>
                  )
                }
              </Fragment>
            );
          },
          sortable: true,
          sort: (a: RouteWithAggregation, b: RouteWithAggregation) => {
            if (a.aggregation.distanceTypes.cargo && b.aggregation.distanceTypes.cargo) {
              return b.aggregation.summary.traffic - a.aggregation.summary.traffic;
            }
            if (a.aggregation.distanceTypes.cargo) {
              return 1;
            }
            if (b.aggregation.distanceTypes.cargo) {
              return -1;
            }
            return b.aggregation.summary.trafficPass - a.aggregation.summary.trafficPass;
          },
        },
      ],
      body: routes,
      onRowClick,
      onExpanded,
      onSort,
    }} />
  );
};

export default AggloRouteTable;
