import { api, forms, RestifyId } from 'redux-restify';
import { ActionCreator } from 'redux';

import { AGGLOMERATION_PROJECTS_ENDPOINT, ROUTES_POLL_ENDPOINT } from 'src/api-url-schema';
import { objectToLowerSnake } from 'src/helpers/naming-notation';
import {
  PAGE_ROUTES_FORM_NAME,
  ROUTES_AND_OVERSHOT_MODEL_NAME,
  RUN_FIND_ROUTES_SAGA,
} from 'src/modules/mth/routes';
import { PageRoutesForm } from 'src/modules/mth/common';
import { ThunkActionResult } from 'src/reducer';

function getRoutesEndpoint(id: RestifyId) {
  return `${AGGLOMERATION_PROJECTS_ENDPOINT}${id}/${ROUTES_POLL_ENDPOINT}`;
}

export const startFindingRoutes = (id: RestifyId, query: any) => api.actions.callPost({
  url: getRoutesEndpoint(id),
  data: objectToLowerSnake(query),
});

export const getFindingRoutesStatus = (id: RestifyId, query: any) => api.actions.callGet({
  url: getRoutesEndpoint(id),
  query: objectToLowerSnake(query),
});

export const runFindRoutesSaga = () => ({ type: RUN_FIND_ROUTES_SAGA });

export const setIsBuilding: ActionCreator<any> = (value: boolean) => {
  return forms.actions[PAGE_ROUTES_FORM_NAME].changeField('isBuilding', value);
};

export const setActualFilters: ActionCreator<any> = (pageRoutesForm: PageRoutesForm, uuid: string) => {
  return forms.actions[PAGE_ROUTES_FORM_NAME].changeSomeFields({
    actualFrom: pageRoutesForm.from,
    actualTo: pageRoutesForm.to,
    actualTrafficLimit: pageRoutesForm.trafficLimit,
    actualMaxAlternativeRoutes: pageRoutesForm.maxAlternativeRoutes,
    actualMaxPedestrianTime: pageRoutesForm.maxPedestrianTime,
    actualHasLongPedestrian: pageRoutesForm.hasLongPedestrian,
    uuid,
  });
};

export const resetActualFilters: ActionCreator<any> = (): ThunkActionResult<void> => (dispatch) => {
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('actualFrom'));
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('actualTo'));
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('actualTrafficLimit'));
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('actualMaxAlternativeRoutes'));
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('actualMaxPedestrianTime'));
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('actualHasLongPedestrian'));
  dispatch(forms.actions[PAGE_ROUTES_FORM_NAME].resetField('uuid'));
  dispatch(api.actions.entityManager[ROUTES_AND_OVERSHOT_MODEL_NAME].clearData());
};

export const setCurrentFiltersFromActual: ActionCreator<any> = (pageRoutesForm: PageRoutesForm) => {
  return forms.actions[PAGE_ROUTES_FORM_NAME].changeSomeFields({
    from: pageRoutesForm.actualFrom,
    to: pageRoutesForm.actualTo,
    trafficLimit: pageRoutesForm.actualTrafficLimit,
    maxAlternativeRoutes: pageRoutesForm.actualMaxAlternativeRoutes,
    maxPedestrianTime: pageRoutesForm.actualMaxPedestrianTime,
    actualHasLongPedestrian: pageRoutesForm.actualHasLongPedestrian,
  });
};
