import { api, forms, RestifyId } from 'redux-restify';

import i18n from 'src/i18n';
import { ThunkActionResult } from 'src/reducer';
import { Systems } from 'src/constants';

import { getProjectFormName, PROJECTS_MODEL_NAMES, PROJECTS_PAGES_FORM_NAMES } from '../constants';

export const getProjectsActions = (type: Systems) => {
  const modelName = PROJECTS_MODEL_NAMES[type];
  const pageFormName = PROJECTS_PAGES_FORM_NAMES[type];

  return {
    createProjectForm: (id?: RestifyId): ThunkActionResult<Promise<any>> => async (dispatch, getState) => {
      let state = getState();
      const newForm = forms.createFormConfig({
        baseConfig: 'baseEditProjectForm',
        model: modelName,
        defaults: {
          id,
        },
      });
      const projectToEdit = await api.selectors.entityManager[modelName].getEntities(state).asyncGetById(id, {
        forceLoad: true,
      });

      const formName = getProjectFormName(type, id);
      dispatch(forms.actions.createForm(formName, newForm, true));
      const formActions = forms.getFormActions(formName);

      if (projectToEdit) {
        dispatch(formActions.applyServerData(projectToEdit));
      }

      state = getState();
      return forms.selectors.getForm(formName)(state);
    },
    saveProject: (id?: RestifyId): ThunkActionResult<Promise<any>> => (dispatch) => {
      const formName = getProjectFormName(type, id);
      const formActions = forms.getFormActions(formName);
      return dispatch(formActions.submit())
        .then(() => {
          dispatch(forms.actions[pageFormName].changeField('selectedProject', id));
        })
        .catch((res: any) => {
          dispatch(formActions.changeField(
            'errors', (
              (res.data && (res.data.sourceFile || res.data.detail))
              || i18n.t('modules.projects.messages.fileUploadDefaultError')),
          ));
          throw res;
        });
    },
  };
};
