import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { AppState } from 'src/reducer';
import {
  INDICATORS_PAGE_FORM_NAME,
  PageAggloIndicatorsForm,
  selectIndicators,
} from 'src/modules/mth/indicators';

import AggloIndicatorsPage from './AggloIndicatorsPage';

const mapStateToProps = (state: AppState) => ({
  entities: selectIndicators(state),
  pageForm: forms.selectors[INDICATORS_PAGE_FORM_NAME].getForm<PageAggloIndicatorsForm>(state),
});

export default connect(mapStateToProps)(AggloIndicatorsPage);
