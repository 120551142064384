import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/reducer';
import { withTranslate } from 'src/i18n';

import { selectAcProjectListForm } from 'src/cluster/common';
import { projectLoadsManager } from 'src/cluster/projects';
import { selectAcForecastPageForm } from 'src/cluster/balance-common';
import {
  forecastPageFormActions,
  forecastCorrespondencesEntityManager,
  selectSelectedForecastCorrespondences,
  forecastTrafficSumEntityManager,
} from 'src/cluster/balance-forecast';

import ForecastPage from './ForecastPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectAcForecastPageForm(state),
  projectForm: selectAcProjectListForm(state),
  correspondencesResults: selectSelectedForecastCorrespondences(state),
  isLoading: projectLoadsManager.selectIsDownloadingUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetPageForm: () => {
    dispatch(forecastPageFormActions.resetFields([
      'currentPage',
      'regionFrom',
      'regionTo',
      'municipalityFrom',
      'municipalityTo',
    ]));
    dispatch(forecastCorrespondencesEntityManager.clearData());
    dispatch(forecastTrafficSumEntityManager.clearData());
  },
  onPageChange: (value: number) => dispatch(forecastPageFormActions.changeField('currentPage', value)),
  onPageSizeChange: (value: number) => {
    dispatch(forecastPageFormActions.changeField('pageSize', value));
    dispatch(forecastPageFormActions.resetField('currentPage'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ForecastPage));
