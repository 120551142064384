import { getRange } from 'src/helpers';

import { FILL_COLORS, Scale } from 'src/cluster/common';
import { TrafficValue, useTrafficValuesFromCenter } from 'src/cluster/balance-map';

const useTrafficScales = (): [TrafficValue[], Scale<number>, Scale<string>] => {
  const values = useTrafficValuesFromCenter();

  const trafficFlowRange = getRange(values.map((v) => v.value), 6);

  const widthScale = trafficFlowRange.map((value, i, arr) => ({
    from: value,
    to: arr[i + 1] || Infinity,
    value: (i + 1) / arr.length,
  })).reverse();

  const colorScale = trafficFlowRange.map((value, i, arr) => ({
    from: value,
    to: arr[i + 1] || Infinity,
    value: FILL_COLORS[i],
  })).reverse();

  return [values, widthScale, colorScale];
};

export default useTrafficScales;
