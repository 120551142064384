import { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import i18n, { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import modals from 'src/modals';

import { selectEditorGraphForm, editorFormActions } from 'src/cluster/editor-common';
import {
  EditorMode,
  selectEditorPageForm,
  selectEditorSelectedStop,
  enableViewMode,
  enableGraphMode,
  enableModifyMode,
  enableAddStopMode,
  enableMoveStopMode,
  enableModifyRouteMode,
  PointAddingDirection,
} from 'src/cluster/editor-map';

import EditorHeadToolbar from './EditorHeadToolbar';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
  graphForm: selectEditorGraphForm(state),
  selectedStop: selectEditorSelectedStop(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showMessage: (message: ReactNode) => dispatch(editorFormActions.changeField('message', message)),
  clearMessage: () => dispatch(editorFormActions.resetField('message')),
  enableViewMode: () => {
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.editor.captions.exitWithoutSaving'),
      text: i18n.t('modules.editor.captions.changesWillNotBeSaved'),
      acceptButtonText: i18n.t('common.captions.exit'),
      declineButtonText: i18n.t('common.captions.cancel'),
      onAccept: () => dispatch(enableViewMode()),
    }));
  },
  createNewRoute: () => {
    dispatch(editorFormActions.changeField('canBePositioned', true));
    dispatch(editorFormActions.changeField('routeId', -1));
    dispatch(editorFormActions.changeField('directionId', -1));
    dispatch(editorFormActions.changeField('variantId', -1));
    dispatch(enableModifyRouteMode());
    dispatch(editorFormActions.changeField('addIndex', 0));
    dispatch(editorFormActions.changeField('addingDirection', PointAddingDirection.after));
  },
  enableAddPointMode: () => {
    dispatch(editorFormActions.changeField('editorMode', EditorMode.addPoint));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
  },
  enableRemovePointMode: () => {
    dispatch(editorFormActions.changeField('editorMode', EditorMode.removePoint));
    dispatch(editorFormActions.resetFields(['message', 'addIndex', 'addingDirection']));
  },
  enableGraphMode: () => dispatch(enableGraphMode()),
  enableModifyMode: () => dispatch(enableModifyMode()),
  enableAddStopMode: () => dispatch(enableAddStopMode()),
  enableMoveStopMode: () => dispatch(enableMoveStopMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(EditorHeadToolbar));
