import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import modals from 'src/modals';

import { AC_EDITOR_GRAPH_VERSION_MODAL_NAME } from 'src/cluster/editor-common';

import EditorSettings from './EditorSettings';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  showModal: () => dispatch(modals.actions.showModal(true, AC_EDITOR_GRAPH_VERSION_MODAL_NAME)),
});

export default connect(null, mapDispatchToProps)(withTranslate(EditorSettings));
