import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { VehicleType } from 'src/cluster/common';

import { DrawerLegendCheckbox } from '../../interfaces/common';
import { LegendType } from '../../types';
import { selectEditorLegendForm } from '../../store';

const useLegendHighlighted = (
  setLegendCheckbox: (key: LegendType, checked: boolean) => void,
  vehicleTypesNames: VehicleType[],
): DrawerLegendCheckbox[] => {
  const { isBusAndTrolleybus, isTram } = useSelector(selectEditorLegendForm);

  const onChangeHandler = (field: LegendType) => (
    event: ChangeEvent<{}>,
    checked: boolean,
  ) => setLegendCheckbox(field, checked);

  return [
    {
      vehicleType: VehicleType.bus,
      type: LegendType.isBusAndTrolleybus,
      iconProps: { borderBottom: '2px solid #38BD09' },
      checked: isBusAndTrolleybus,
      onChange: onChangeHandler(LegendType.isBusAndTrolleybus),
    },
    {
      vehicleType: VehicleType.tramway,
      type: LegendType.isTram,
      iconProps: { borderBottom: '2px solid #E5293C' },
      checked: isTram,
      onChange: onChangeHandler(LegendType.isTram),
    },
  ].filter((item, i) => {
    let filterFlag = vehicleTypesNames.includes(item.vehicleType);
    if (i === 0 && !filterFlag) {
      filterFlag = vehicleTypesNames.includes(VehicleType.trolleybus);
    }
    return filterFlag;
  });
};

export default useLegendHighlighted;
