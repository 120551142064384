import { AC_YEARS_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_YEARS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    value: undefined,
  },
  transformArrayResponse: (response: number[]) => {
    return {
      data: response.map((year: number, index) => ({
        id: index + 1,
        value: year,
      })),
    };
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
  pagination: false,
};
