import { registerModal, MODAL_SIZES } from 'src/modals';

import { FORECAST_EXPORT_MODAL } from 'src/modules/matrix/forecast';

import { AppState } from 'src/reducer';
import ModalExportForecast, { ExportForecastModalProps } from '../components/ForecastModalExport';

const stateToProps = (state: AppState, props: ExportForecastModalProps) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.small,
  };
};

export default registerModal(
  FORECAST_EXPORT_MODAL,
  stateToProps,
)(ModalExportForecast);
