import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { forms, WithId } from 'redux-restify';

import { WithTranslate } from 'src/i18n';
import PTable from 'src/components/deprecated/PTable';
import Pagination from 'src/components/etc/Pagination';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import SmartDate, { SMART_DATE_FORMATS } from 'src/components/etc/SmartDate';
import { CALCULATIONS_STATUSES, CALCULATIONS_STATUSES_DICT } from 'src/modules/general/calculations/constants';

import { CalculationModel } from 'src/modules/general/calculations/interfaces';

import { useRestifyModel, useRestifyForm } from 'src/helpers';
import { useSystemsContext } from 'src/constants';
import {
  CALCULATIONS_MODEL_NAMES,
  CALCULATIONS_PAGES_FORM_NAMES,
  PageCalculationForm,
} from '../../constants';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  buttons?: any;
}

const CalculationsTable: React.FC<Props> = ({
  className, buttons, t,
}) => {
  const currentSystem = useSystemsContext();
  const calculationModelName = CALCULATIONS_MODEL_NAMES[currentSystem];
  const [calculationsEntities] = useRestifyModel<CalculationModel>(calculationModelName);
  const calculationFormName = CALCULATIONS_PAGES_FORM_NAMES[currentSystem];
  const [pageForm, pageFormActions] = useRestifyForm<PageCalculationForm>(calculationFormName);
  const dispatch = useDispatch();
  const calculationsApiConfig = {
    filter: {
      page: pageForm.currentPage,
      pageSize: pageForm.pageSize,
    },
  };
  const calculations = calculationsEntities.getArray(calculationsApiConfig);
  const calculationsIsLoading = calculationsEntities.getIsLoadingArray(calculationsApiConfig);
  // Use ref, so we can save data about objects count, cause page and pageSize doesn't change it in array requests
  const initialApiConfig = React.useRef(calculationsApiConfig);
  React.useEffect(() => {
    return () => {
      calculations.forEach((calculation) => {
        if (calculation.isNew) {
          dispatch(forms.actions.sendQuickForm({
            model: calculationModelName,
            id: calculation.id,
            values: {
              isNew: false,
            },
          }));
        }
      });
    };
  }, []);
  return (
    <LoadingBlockContainer {...{
      isBlocked: calculationsIsLoading,
      className: classNames(style.root, className),
    }}>
      <Pagination {...{
        pageSize: pageForm.pageSize,
        currentPage: pageForm.currentPage,
        count: calculationsEntities.getCount(initialApiConfig.current),
        onPageChange: (page: number) => dispatch(pageFormActions.changeField('currentPage', page)),
        onPageSizeChange: (pageSize: number) => {
          dispatch(pageFormActions.resetField('currentPage'));
          dispatch(pageFormActions.changeField('pageSize', pageSize));
        },
      }}>
        <PTable<WithId<CalculationModel>> {...{
          className: style.table,
          hoverable: true,
          isNew: (item) => item.isNew || false,
          hoveredComponent: (item) => {
            return buttons(item);
          },
          header: [
            {
              title: t('common.captions.numberSymbol'),
              model: (item) => item.id,
            },
            {
              title: t('common.captions.startDate'),
              model: (item) => <SmartDate format={SMART_DATE_FORMATS.simpleDateWithTime} date={item.startDate} />,
            },
            {
              title: t('common.captions.endDate'),
              model: (item) => <SmartDate format={SMART_DATE_FORMATS.simpleDateWithTime} date={item.finishDate} />,
            },
            {
              title: t('common.captions.correspondenceCount'),
              model: (item) => item.correspondenceCount,
            },
            {
              title: t('common.captions.parameters'),
              model: (item) => item.parameters,
            },
            {
              title: t('common.captions.file'),
              model: (item) => item.file,
            },
            {
              title: t('common.captions.status'),
              className: style.nameColumn,
              model: (item) => {
                if (!item.status) return null;
                if (item.status === CALCULATIONS_STATUSES.inProgress) {
                  const progress = Math.floor((item.progress || 0) * 100);
                  return (
                    <div className={style.progress}>
                      <div>
                        {t(CALCULATIONS_STATUSES_DICT[item.status])}
                        <span className={style.percent}>{progress}</span>
                        <span>%</span>
                      </div>
                      <div className={style.progressBar}>
                        <div {...{
                          className: style.progressBarInner,
                          style: {
                            width: `${progress}%`,
                          },
                        }} />
                      </div>
                    </div>
                  );
                }
                return t(CALCULATIONS_STATUSES_DICT[item.status]);
              },
            },
          ],
          body: calculations,
        }} />
      </Pagination>
    </LoadingBlockContainer>
  );
};

export default CalculationsTable;
