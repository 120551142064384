import { TRANSPORT_TYPES, VIEW_MODES } from 'src/modules/mth/common/constants';

export default {
  defaults: {
    viewMode: VIEW_MODES.graph,
    showTraffic: false,
    showRoutes: false,
    selectedTab: 0,
    from: undefined,
    to: undefined,
    actualFrom: undefined,
    actualTo: undefined,
    transportType: TRANSPORT_TYPES.auto,
    selectedRoute: undefined,
    selectedEdges: undefined,
    selectedRegion: undefined,
    isStartSelected: undefined,
    isFinishSelected: undefined,
    expandedRoute: undefined,
    sortingColumn: undefined,
    sortingOrder: 1,
    showTransportTypes: {
      car: true,
      tat: true,
      metro: true,
      suburban: true,
      express: true,
      taxi: true,
      cargo: true,
    },
  },
};
