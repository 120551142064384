import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AcBalanceDataFactorsModel } from 'src/cluster/common';
import { selectDataFactors } from 'src/cluster/balance-common';

const useFactors = (): { id: string; name: string }[] => {
  const [factors] = useSelector(selectDataFactors);

  return useMemo(() => factors.map((factor: AcBalanceDataFactorsModel) => ({
    id: factor.id,
    name: factor.name,
  })), [JSON.stringify(factors)]);
};

export default useFactors;
