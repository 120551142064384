import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  toggle: {
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textTransform: 'inherit',
  },

  popup: {
    padding: theme.spacing(1, 1, 1, 3),
    borderTopWidth: 3,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: 150,
  },

  popupTitle: {
    padding: 6,
    fontWeight: 'bold',
  },

  userFilerItem: {
    padding: 6,
    cursor: 'pointer',
    textAlign: 'right',
    fontWeight: 'normal',
    textTransform: 'none',
  },

  userFilerSelectedItem: {
    padding: 6,
    cursor: 'pointer',
    textAlign: 'right',
    fontWeight: 'bold',
    textTransform: 'none',
    color: theme.palette.primary.main,
    position: 'relative',
  },

  clearFilterIcon: {
    width: 14,
    height: 14,
    color: theme.palette.primary.main,
    display: 'inline-block',
    position: 'absolute',
    left: 2,
    top: 6,
  },

  filterIcon: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    width: 15,
    height: 15,
    color: theme.palette.grey[800],
    display: 'inline-block',
    verticalAlign: 'bottom',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  filterIconSelected: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    width: 15,
    height: 15,
    color: theme.palette.primary.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
  },

}));
