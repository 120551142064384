import { AcDistributionGeojsonForm } from 'src/cluster/distribution-map';

const defaults: AcDistributionGeojsonForm = {
  centroids: undefined,
  districts: undefined,
  nodes: undefined,
  edges: undefined,
  edgesExtreme: { minimum: 10, maximum: 1000 },
  stopsExtreme: { minimum: 10, maximum: 1000 },
};

const values: Partial<AcDistributionGeojsonForm> = {
  edgesExtreme: { minimum: 10, maximum: 1000 },
  stopsExtreme: { minimum: 10, maximum: 1000 },
};

export default { defaults, values };
