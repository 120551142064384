import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import lodashGet from 'lodash/get';

import { NotAllowed, NotFound } from 'src/app';

import { AuthInfoForm } from 'src/auth/forms/authInfo.form';

import urlSchema, { getBaseUrl, urlSchemaConfig } from '../../url-schema';

import {
  PageManagerContext,
  defaultPageManagerContext,
  checkPermissionForRoute,
} from '../../constants';

const extraSlashRegex = /\/\/+/g;

interface Props {
  prevMatch?: any;
  basePath?: any;
  renderers?: any;
  registeredRoutesPaths?: any;
  useSwitch?: boolean;
  authInfoForm: AuthInfoForm;
}

const RouteScheme: FC<Props> = (props) => {
  const {
    prevMatch,
    basePath = [],
    renderers = {},
    registeredRoutesPaths,
    useSwitch = true,
    authInfoForm,
  } = props;

  const currentBaseUrl = lodashGet(urlSchema, basePath, urlSchema);

  const currentSchemaConfig = lodashGet(
    urlSchemaConfig,
    ([] as string[]).concat(basePath)
      .map(name => [name, 'pages'])
      .reduce((a, b) => a.concat(b), []),
    urlSchemaConfig,
  );
  const mappedRoutes = Object.keys(renderers).map((key: string) => {
    const currentRenderer = lodashGet(renderers, key) || renderers[key[key.length - 1]];
    if (!currentRenderer) return null;
    const CurrentComponent = currentRenderer.component;
    const currentRenderFunction = currentRenderer.render;
    let currentPath = `/${getBaseUrl(lodashGet(currentBaseUrl, key))}`;
    let currentUrl = currentPath;
    if (prevMatch) {
      currentPath = (prevMatch.path === '/' ? '' : prevMatch.path) + currentPath;
      currentUrl = (prevMatch.url === '/' ? '' : prevMatch.url) + currentUrl;
    }
    const currentConfig = currentSchemaConfig[key];

    // Prevent cases, when user inputs extra slash at the end of url
    currentPath = currentPath.replace(extraSlashRegex, '/');
    currentUrl = currentUrl.replace(extraSlashRegex, '/');

    return {
      key,
      currentPath,
      currentUrl,
      CurrentComponent,
      currentRenderFunction,
      currentRenderer,
      permissions: currentConfig.permissions,
    };
  });

  const redirectRoute = mappedRoutes.find((route: any) => {
    return (
      !route.currentRenderer.isExtra &&
      checkPermissionForRoute(route.currentUrl, authInfoForm.permissions, route.permissions)
    );
  });
  const allowRedirect = mappedRoutes.every(r => {
    return r && r.currentUrl !== prevMatch.url && r.currentUrl.replace(/\/$/, '') !== prevMatch.url;
  });

  const SwitchComponent = useSwitch ? Switch : React.Fragment;
  return (
    <SwitchComponent>
      {redirectRoute && allowRedirect && (
        <Redirect
          push={false}
          exact
          from={prevMatch.path}
          to={redirectRoute.currentUrl}
        />
      )}
      {mappedRoutes.map(({
        key,
        currentPath,
        currentUrl,
        CurrentComponent,
        currentRenderFunction,
        currentRenderer,
        permissions,
      }: any) => {
        return (
          <Route
            key={key}
            path={currentPath}
            render={({ match }) => {
              const routeProps = { ...currentRenderer.props, match };
              if (permissions && !checkPermissionForRoute(currentUrl, authInfoForm.permissions, permissions)) {
                return <NotAllowed />;
              }
              return (
                <PageManagerContext.Provider
                  value={{
                    ...defaultPageManagerContext, match, registeredRoutesPaths, basePath: basePath.concat(key),
                  }}
                >
                  {CurrentComponent && <CurrentComponent key={key} {...routeProps} />}
                  {currentRenderFunction && currentRenderFunction(routeProps)}
                </PageManagerContext.Provider>
              );
            }}
          />
        );
      })}
      {useSwitch && <Route component={NotFound} />}
    </SwitchComponent>
  );
};

export default RouteScheme;
