export const INNER_INPUT_CHECKBOX = 'checkbox';
export const INNER_INPUT_RADIO = 'radio';

export const INNER_INPUT_TYPES = {
  [INNER_INPUT_CHECKBOX]: INNER_INPUT_CHECKBOX,
  [INNER_INPUT_RADIO]: INNER_INPUT_RADIO,
};

export const LABEL_RIGHT = 'right';
export const LABEL_LEFT = 'left';

export const LABEL_POSITION_TYPES = {
  [LABEL_RIGHT]: LABEL_RIGHT,
  [LABEL_LEFT]: LABEL_LEFT,
};

export const ERROR_TYPES = {
  required: 'Это поле обязательно!',
};
