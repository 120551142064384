import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {},

  select: {
    width: 267,
    marginBottom: 10,
  },

  tightSelect: {
    width: '85%',
    marginBottom: 10,
  },

  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    '& button': {
      marginRight: 10,
    },
  },

  year: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },

  buttonGroup: {
    width: '100%',
    marginBottom: theme.spacing(4),
    '& button': {
      width: '50%',
      fontSize: 12,
      fontWeight: 'bold',
    },
  },

  total: {
    '& div > span': {
      fontSize: '10px',
      fontWeight: 'normal',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
    },

    '& div > span:last-of-type': {
      color: theme.palette.primary.main,
    },
  },
}));
