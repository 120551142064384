import { createStyles, makeStyles } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({
  palette, spacing, zIndex, shadows,
}) => createStyles({
  root: {
    position: 'relative',
  },

  blockingArea: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: zIndex.drawer,
    pointerEvents: 'none',
  },

  blocking: {
    backgroundColor: 'rgba(51, 59, 70, 0.6)',
    pointerEvents: 'initial',
  },

  labelWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  label: {
    color: palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    textShadow: shadows[0],
    marginBottom: 6,
  },

  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    backgroundColor: palette.common.white,
    borderRadius: 5,
  },

  indicatorWithButton: {
    padding: spacing(2, 4, 1),
  },

  button: {
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    color: palette.primary.main,
    fontFamily: ffSourceSansPro,
    fontSize: 10,
    fontWeight: 600,
    cursor: 'pointer',
  },

  content: {
    height: '100%',
  },

  blocked: {
    overflow: 'hidden',
  },
}));
