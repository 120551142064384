import React from 'react';

const AddIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd" clipRule="evenodd" d="M6.38186 0.666504C8.33367 0.666504 10.3072 0.666504 12.259 0.666504C12.259 2.68337 12.259 4.72193 12.259 6.73879C14.2758 6.73879 16.2927 6.73879 18.3096 6.73879C18.3096 8.6906 18.3096 10.6641 18.3096 12.6159C16.2927 12.6159 14.2758 12.6159 12.259 12.6159C12.259 14.6328 12.259 16.6496 12.259 18.6665C10.3072 18.6665 8.33367 18.6665 6.38186 18.6665C6.38186 16.6496 6.38186 14.6328 6.38186 12.6159C4.36499 12.6159 2.32644 12.6159 0.30957 12.6159C0.30957 10.6641 0.30957 8.6906 0.30957 6.73879C2.32644 6.73879 4.36499 6.73879 6.38186 6.73879C6.38186 4.72193 6.38186 2.68337 6.38186 0.666504Z"
      fill="currentColor"
    />
  </svg>
);

export default AddIcon;
