import React from 'react';
import classNames from 'classnames';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import style from './index.module.css';

interface Props {
  className?: string;
  opacityBackground?: boolean;
}

const ErrorBox: React.FC<Props> = ({
  className,
  opacityBackground = false,
  children,
}) => {
  return (
    <div className={classNames(opacityBackground ? style.opacityRoot : style.root, className)}>
      <PIcon {...{
        type: ICONS_TYPES.exclamationMark,
        className: style.icon,
        size: 22,
      }} />
      <span>{children}</span>
    </div>
  );
};

export default ErrorBox;
