import React, { useCallback } from 'react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import { RestifyFormActions } from 'redux-restify';
import { useDispatch } from 'react-redux';

import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import PCheckbox from 'src/components/deprecated/PCheckbox';
import PButton from 'src/components/deprecated/PButton';
import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';
import { useSystemsContext } from 'src/constants';
import { getVocabularyValue, useCurrentVocabulary, WithTranslate } from 'src/i18n';

import forecast, { ForecastChartRecord, ForecastModel, PageForecastForm } from 'src/modules/matrix/forecast';
import Tooltip from 'src/components/etc/Tooltip';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import tooltipStyle from 'src/styles/tooltip.module.css';
import style from './index.module.css';

interface ForecastToolbarProps {
  data: ForecastModel[];
  chartData: ForecastChartRecord[];
  currentProjectId: number;
  form: PageForecastForm;
  formActions: RestifyFormActions<PageForecastForm>;
  onBuildClick: () => void;
}

type Props = ForecastToolbarProps & WithTranslate & {
  className?: string;
}

const ForecastToolbar: React.FC<Props> = (props: Props) => {
  const {
    className,
    data,
    chartData,
    currentProjectId,
    form,
    formActions,
    onBuildClick,
    t,
  } = props;
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [currentLocale, vocabulary] = useCurrentVocabulary();

  const correspondences = uniq(data.map((transport: ForecastModel) => {
    return `${transport.fromCity} - ${transport.toCity}`;
  }));

  const correspondencesOptions = correspondences.map((correspondence) => {
    const [fromRaw, toRaw] = correspondence.split(' - ');
    const fromCity = getVocabularyValue(currentLocale, vocabulary, fromRaw);
    const toCity = getVocabularyValue(currentLocale, vocabulary, toRaw);
    return ({
      value: correspondence,
      label: `${fromCity} - ${toCity}`,
    });
  }).sort((a, b) => (a.label < b.label ? -1 : 1));

  const handleCorrespondencesChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('correspondences', value));
  }, [formActions, dispatch]);

  const handleAllTypesChange = useCallback((value: boolean) => {
    dispatch(formActions.changeField('allTransport', value));
  }, [formActions, dispatch]);

  const handleHSRChange = useCallback((value: boolean) => {
    dispatch(formActions.changeField('hsw', value ? 1 : 0));
  }, [formActions, dispatch]);

  const handleExport = useCallback(() => {
    dispatch(forecast.actions[currentSystem].exportForecast(currentProjectId, chartData));
  }, [currentSystem, currentProjectId, chartData]);

  const handleResetCorrespondences = useCallback(() => {
    dispatch(formActions.resetField('correspondences'));
  }, [formActions, dispatch]);

  return (
    <div className={classNames(pageLayoutStyle.toolbar, style.root, className)}>
      <div className={classNames(pageLayoutStyle.toolbarBlock, style.toolbarBlockLarge)}>
        <div className={classNames(pageLayoutStyle.toolbarTitle, style.toolbarTitle)}>
          {t('systems.matrix.captions.analysis')}
        </div>
        <div className={style.subBlockWrapper}>
          <PSelect {...{
            label: (
              <span className={style.spaceBetween}>
                {t('systems.matrix.captions.correspondence')}
                <PButton
                  className={style.clear}
                  type="link"
                  onClick={handleResetCorrespondences}
                >
                  {t('components.captions.clear')}
                </PButton>
              </span>
            ),
            className: style.correspondencesSelect,
            multi: true,
            isClearable: false,
            items: correspondencesOptions,
            values: Array.isArray(form.correspondences) ? form.correspondences : undefined,
            onChange: handleCorrespondencesChange,
          }} />
        </div>
      </div>

      <div className={classNames(pageLayoutStyle.toolbarBlock, style.toolbarBlockSmall)}>
        <div className={style.subBlockWrapper}>
          <div className={style.checkboxBlock}>
            <div className={style.checkbox}>
              <PCheckbox {...{
                label: t('systems.matrix.captions.allMeansOfTransport'),
                value: form.allTransport,
                onChange: handleAllTypesChange,
              }} />
            </div>
            <div className={style.checkbox}>
              <PCheckbox {...{
                label: t('systems.matrix.captions.hsr'),
                value: Boolean(form.hsw),
                onChange: handleHSRChange,
              }} />
            </div>
          </div>

          <div className={style.buttonBlock}>
            <PButton {...{
              className: style.button,
              onClick: onBuildClick,
            }}>
              {t('systems.matrix.captions.buildForecast')}
            </PButton>
          </div>
          <Tooltip className={style.tooltip} align="right">
            <span className={tooltipStyle.tooltipAnchor}>?</span>
            <span className={tooltipStyle.tooltipPopup}>
              {t('systems.matrix.messages.analysisTooltip')}
            </span>
          </Tooltip>
          <div className={style.exportBlock}>
            <ExportToExcelButton {...{
              size: 20,
              onClick: handleExport,
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForecastToolbar;
