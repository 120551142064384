import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { api } from 'redux-restify';

import { selectIsAuthenticated } from 'src/auth';

import App from './App';

const stateToProps = (state: any) => ({
  isAuthenticated: selectIsAuthenticated(state),
  isLoading: api.selectors.loadsManager.getIsLoading(state),
});

export default withRouter<any, any>(connect<any>(stateToProps)(App));
