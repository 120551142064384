import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { SelectValue } from 'src/interfaces';
import {
  AcDistPageFormEnum,
  distPageFormActions,
  selectAcDistPageForm,
} from 'src/cluster/distribution-table';

import DistributionPointFilter from './DistributionPointFilter';

const mapStateToProps = (state: AppState) => ({
  tableFilters: selectAcDistPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setFilter: (val: SelectValue, field: AcDistPageFormEnum) => {
    dispatch(distPageFormActions.resetField('currentPage'));
    dispatch(distPageFormActions.changeField(field, val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DistributionPointFilter));
