import React from 'react';

const TrashIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2551 2.98497V3.89622C16.2551 3.99935 16.1696 4.08372 16.0651 4.08372H0.790131C0.685613 4.08372 0.600098 3.99935 0.600098 3.89622V2.98497C0.600098 2.4581 1.03528 2.02872 1.56927 2.02872H4.75424L5.20842 0.94685C5.29583 0.736851 5.50297 0.599976 5.73291 0.599976H11.1223C11.3522 0.599976 11.5594 0.736851 11.6468 0.94685L12.1009 2.02872H15.2859C15.8199 2.0306 16.2551 2.4581 16.2551 2.98497Z"
      fill="currentColor"
    />
    <path
      d="M15.0599 4.83386H1.7955C1.69098 4.83386 1.60547 4.91824 1.60547 5.02136V15.4482C1.60547 16.1007 2.14326 16.6332 2.80648 16.6332H14.0489C14.7102 16.6332 15.2499 16.1026 15.2499 15.4482V5.02136C15.2499 4.91824 15.1644 4.83386 15.0599 4.83386ZM12.1828 8.11886V13.3464H11.4226V8.11886H12.1828ZM8.80775 6.99386V14.4714H8.04762V6.99386H8.80775ZM5.43275 8.11886V13.3464H4.67261V8.11886H5.43275Z"
      fill="currentColor"
    />
  </svg>
);

export default TrashIcon;
