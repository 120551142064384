import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({
  palette, spacing, transitions, shape, shadows,
}: Theme) => ({
  root: {
    display: 'flex',
    maxWidth: 120,
    alignItems: 'center',
    borderRadius: shape.borderRadius,
    margin: spacing(2, 3, 0, 0),
    position: 'relative',
    backgroundColor: palette.primary.light,
    fontSize: 12,
    lineHeight: '12px',
    color: palette.text.primary,
    transition: transitions.create(['border-color', 'box-shadow']),
    height: 'auto',

    '&:hover': {
      boxShadow: shadows[2],
    },

    '&[aria-disabled="true"]': {
      opacity: 1,
    },
  },

  deleteIcon: {
    whiteSpace: 'nowrap',
    border: 'none',
    background: 'none',
    outline: 'none',
    fontSize: 'initial',
    margin: spacing(0, 1, 0, 0),

    '& > svg': {
      width: 7,
      color: palette.text.primary,
    },
  },

  label: {
    padding: spacing(1.5, 2),
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: palette.common.black,
  },

  disabled: {
    backgroundColor: palette.grey[200],
  },
}));
