export const VIDEO_CITIES_ENDPOINT = 'transport-video/cities/';
export const VIDEO_VEHICLE_TYPES_ENDPOINT = 'transport-video/vehicle-types/';
export const VIDEO_ROUTE_TRIPS_ENDPOINT = 'transport-video/route-trips/';
export const VIDEO_VEHICLE_MAKE_MODELS_ENDPOINT = 'transport-video/vehicle-make-models/';
export const VIDEO_TRANSPORT_COMPANIES_ENDPOINT = 'transport-video/transport-companies/';
export const VIDEO_ROUTE_NUMBERS_ENDPOINT = 'transport-video/route-numbers/';
export const VIDEO_VEHICLE_ON_ROUTES_ENDPOINT = 'transport-video/vehicle-on-routes/';
export const VIDEO_TRAFFIC_SUM_ENDPOINT = 'transport-video/vehicle-on-routes/passenger-count/';

export const VIDEO_CITIES_MODAL_NAME = 'videoCities';
export const VIDEO_VEHICLE_TYPES_MODAL_NAME = 'videoVehicleTypes';
export const VIDEO_VEHICLE_MAKE_MODELS_MODAL_NAME = 'videoVehicleMakeModels';
export const VIDEO_TRANSPORT_COMPANIES_MODAL_NAME = 'videoTransportCompanies';
export const VIDEO_ROUTE_NUMBERS_MODAL_NAME = 'videoRouteNumbers';
export const VIDEO_VEHICLE_ON_ROUTES_MODAL_NAME = 'videoVehicleOnRoutes';
export const VIDEO_ROUTE_TRIPS_MODEL_NAME = 'videoRouteTrips';
export const VIDEO_TRAFFIC_SUM_MODEL_NAME = 'videoTrafficSum';

export const VIDEO_PAGE_FORM_NAME = 'pageVideoForm';

export const VIDEO_ROUTE_VIEW_MODAL_NAME = 'videoRouteViewModal';
