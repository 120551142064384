import { useSelector } from 'react-redux';
import { selectAcIntervals } from 'src/cluster/common';
import { IndicatorPartition } from 'src/cluster/dashboard-table';

const useIntervalsForPartition = (): IndicatorPartition[] => {
  const intervals = useSelector(selectAcIntervals);

  return intervals.map((value) => value.map((interval) => ({
    id: interval.id,
    name: interval.intervalName,
    entityName: 'interval',
  }))).orJust([]);
};

export default useIntervalsForPartition;
