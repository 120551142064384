import { useSelector } from 'react-redux';
import { Feature } from 'src/interfaces';

import {
  LEGEND_VEHICLE_TYPES_COLORS,
  TRANSPARENT_COLOR,
  GREEN_COLOR,
  HEAT_DARK_RED_COLOR,
  HEAT_RED_COLOR,
  HEAT_YELLOW_COLOR,
  HEAT_GREEN_COLOR,
  BLUE_COLOR,
  LIGHT_GREY_COLOR,
  VehicleType,
  selectEditorLegendForm,
  useVehicleTypesMap,
} from 'src/cluster/common';

const useLegendLineGenerate: (isGraphView?: boolean, edgeId?: number) => {
  getLineColor: (f: Feature) => number[];
  getLineWidth: (f: Feature) => any;
} = (isGraphView = false, edgeId) => {
  const vehicleTypesMap = useVehicleTypesMap();
  const checkArrayByType = (
    isFlag: boolean,
    array: number[],
    type: VehicleType,
  ): boolean => (isFlag && array.includes(vehicleTypesMap[type]));

  const {
    isSrnLine,
    isSuburbanLine,
    isWaterLine,
    isMetroLine,
    isTramwayLine,
    isTrolleybusLine,
    isBusNetwork,
    isTramwayNetwork,
    isTrolleybusNetwork,
    isSuburbanNetwork,
    isWaterNetwork,
    regularTransportationType,
    communicationType,
    isPaidParking,
    isBusAndTrolleybus,
    isTram,
    isAverageTravelSpeedCar,
  } = useSelector(selectEditorLegendForm);

  const canFilteredNetworks = isBusNetwork ||
    isTramwayNetwork ||
    isTrolleybusNetwork ||
    isSuburbanNetwork ||
    isWaterNetwork;

  const getLineColor = (feature: Feature) => {
    const {
      id,
      communicationTypes = [],
      dedicatedLaneVehicleTypes = [],
      paidParking,
      regularTransportationTypes = [],
      routeVehicleTypes = [],
      vehicleTypes = [],
      vehicleSpeeds = [],
    } = feature.properties || {};

    if (!id) return TRANSPARENT_COLOR;
    if (id === edgeId) return GREEN_COLOR;

    /** Average Car speed */
    const speed = vehicleSpeeds[vehicleTypesMap[VehicleType.auto]];
    if (isAverageTravelSpeedCar && speed) {
      if (speed <= 5) return HEAT_DARK_RED_COLOR;
      if (speed <= 10) return HEAT_RED_COLOR;
      if (speed <= 25) return HEAT_YELLOW_COLOR;
      return HEAT_GREEN_COLOR;
    }

    /** Parking */
    if (isPaidParking && paidParking) return BLUE_COLOR;

    /** Dedicated lane */
    if (checkArrayByType(isTram, dedicatedLaneVehicleTypes, VehicleType.tramway)) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.tramway].hex;
    }
    if (checkArrayByType(isBusAndTrolleybus, dedicatedLaneVehicleTypes, VehicleType.trolleybus)
      || checkArrayByType(isBusAndTrolleybus, dedicatedLaneVehicleTypes, VehicleType.bus)
    ) return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.bus].hex;

    if (canFilteredNetworks) {
      /** Filtered routes if regularTransportationType or communicationType */
      const routes = regularTransportationType || communicationType
        ? routeVehicleTypes.filter((vhId: number) => (
          (!regularTransportationType || regularTransportationTypes[vhId]?.includes(regularTransportationType))
          && (!communicationType || communicationTypes[vhId]?.includes(communicationType))
        ))
        : [...routeVehicleTypes];

      /** Networks */
      if (checkArrayByType(isWaterNetwork, routes, VehicleType.water)) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.water].hex;
      }
      if (checkArrayByType(isSuburbanNetwork, routes, VehicleType.suburban)) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.suburban].hex;
      }
      if (checkArrayByType(isTramwayNetwork, routes, VehicleType.tramway)) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.tramway].hex;
      }
      if (checkArrayByType(isTrolleybusNetwork, routes, VehicleType.trolleybus)) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.trolleybus].hex;
      }
      if (checkArrayByType(isBusNetwork, routes, VehicleType.bus)) {
        return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.bus].hex;
      }
    }

    /** Infrastructures */
    if (checkArrayByType(isWaterLine, vehicleTypes, VehicleType.water)) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.water].hex;
    }
    if (checkArrayByType(isSuburbanLine, vehicleTypes, VehicleType.suburban)) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.suburban].hex;
    }
    if (checkArrayByType(isMetroLine, vehicleTypes, VehicleType.metro)) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.metro].hex;
    }
    if (checkArrayByType(isTramwayLine, vehicleTypes, VehicleType.tramway)) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.tramway].hex;
    }
    if (checkArrayByType(isTrolleybusLine, vehicleTypes, VehicleType.trolleybus)) {
      return LEGEND_VEHICLE_TYPES_COLORS[VehicleType.trolleybus].hex;
    }
    if (isSrnLine) return LIGHT_GREY_COLOR;

    return TRANSPARENT_COLOR;
  };

  const getLineWidth = (feature: Feature) => {
    const {
      id,
      communicationTypes = [],
      dedicatedLaneVehicleTypes = [],
      paidParking,
      regularTransportationTypes = [],
      routeVehicleTypes = [],
      vehicleTypes = [],
      vehicleSpeeds = [],
    } = feature.properties || {};

    if (id === edgeId) return 8;

    /** Average Car speed */
    const speed = vehicleSpeeds[vehicleTypesMap[VehicleType.auto]];
    if (isAverageTravelSpeedCar && speed) return 5;

    /** GraphView | Parking | Dedicated lane */
    if (
      isGraphView
      || (isPaidParking && paidParking)
      || checkArrayByType(isTram, dedicatedLaneVehicleTypes, VehicleType.tramway)
      || checkArrayByType(isBusAndTrolleybus, dedicatedLaneVehicleTypes, VehicleType.trolleybus)
      || checkArrayByType(isBusAndTrolleybus, dedicatedLaneVehicleTypes, VehicleType.bus)
    ) {
      return 4;
    }

    if (canFilteredNetworks) {
      /** Filtered routes if regularTransportationType or communicationType */
      const routes = regularTransportationType || communicationType
        ? routeVehicleTypes.filter((vhId: number) => (
          (!regularTransportationType || regularTransportationTypes[vhId]?.includes(regularTransportationType))
          && (!communicationType || communicationTypes[vhId]?.includes(communicationType))
        ))
        : [...routeVehicleTypes];

      /** Networks */
      if (checkArrayByType(isWaterNetwork, routes, VehicleType.water)
        || checkArrayByType(isSuburbanNetwork, routes, VehicleType.suburban)
        || checkArrayByType(isTramwayNetwork, routes, VehicleType.tramway)
        || checkArrayByType(isTrolleybusNetwork, routes, VehicleType.trolleybus)
        || checkArrayByType(isBusNetwork, routes, VehicleType.bus)
      ) return 3;
    }

    /** Infrastructures */
    if (
      checkArrayByType(isWaterLine, vehicleTypes, VehicleType.water)
      || checkArrayByType(isSuburbanLine, vehicleTypes, VehicleType.suburban)
      || checkArrayByType(isMetroLine, vehicleTypes, VehicleType.metro)
      || checkArrayByType(isTramwayLine, vehicleTypes, VehicleType.tramway)
      || checkArrayByType(isTrolleybusLine, vehicleTypes, VehicleType.trolleybus)) {
      return 1;
    }

    return 2;
  };

  return { getLineColor, getLineWidth };
};

export default useLegendLineGenerate;
