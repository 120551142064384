import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ProcessStatus } from 'src/types';
import { AppState } from 'src/reducer';
import { withTranslate } from 'src/i18n';

import {
  clearProjectData,
  selectAcProjectListForm,
} from 'src/cluster/common';
import {
  AcProjectsModel,
  projectListFormActions,
  selectAcProjects,
  selectAcProjectsCount,
  projectLoadsManager,
  reloadProject,
  renameProject,
  confirmDeletingProject,
  confirmCopyingProject,
} from 'src/cluster/projects';

import { onBalanceMapProjectChange } from 'src/cluster/balance-map';
import ProjectsPage from './ProjectsPage';

const mapStateToProps = (state: AppState) => ({
  projects: selectAcProjects(state),
  count: selectAcProjectsCount(state),
  isLoading: projectLoadsManager.selectIsDownloadingUrl(state),
  form: selectAcProjectListForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onPageChange: (value: number) => {
    dispatch(projectListFormActions.changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(projectListFormActions.resetField('currentPage'));
    dispatch(projectListFormActions.changeField('pageSize', value));
  },
  changeStatusFilter: (value: ProcessStatus) => {
    dispatch(projectListFormActions.changeField('loadStatus', value));
  },
  select: (value: number) => {
    dispatch(clearProjectData());
    dispatch(onBalanceMapProjectChange());
    dispatch(projectListFormActions.changeField('selectedProject', value));
  },
  rename: (id: number, name: string) => {
    dispatch(renameProject(id, name));
  },
  remove: (project: AcProjectsModel) => {
    dispatch(confirmDeletingProject(project));
  },
  copy: (project: AcProjectsModel) => {
    dispatch(confirmCopyingProject(project));
  },
  reloadProject: (project: AcProjectsModel) => {
    dispatch(reloadProject(project));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ProjectsPage));
