import React, { FC, Fragment } from 'react';
import { Box } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Autocomplete } from 'src/components';
import { SelectValue } from 'src/interfaces';
import {
  AcRegistryFiltersEnum,
  AcRegistryFiltersForm,
  RegistryToolbarActions,
  useMainFiltersConfig,
} from 'src/cluster/editor-table';

import useStyles from './styles';

export interface InjectedProps {
  tableFilters: AcRegistryFiltersForm;
  setFilter(val: SelectValue, field: AcRegistryFiltersEnum): void;
  clearFilters(): void;
  showFiltersModal(): void;
}

type Props = InjectedProps & WithTranslate;

const RegistryMainFilters: FC<Props> = (props) => {
  const {
    t,
    tableFilters: { mainFilters = {} },
    setFilter,
    clearFilters,
    showFiltersModal,
  } = props;

  const classes = useStyles();

  const filters = useMainFiltersConfig(mainFilters, setFilter);

  return (
    <Box display="flex" flexWrap="wrap">
      {filters.map(filter => (
        <Fragment key={filter.label}>
          <Autocomplete
            placeholder={t('components.captions.select')}
            multiple={filter.multiple}
            label={t(filter.label)}
            options={filter.options}
            selected={filter.selected}
            disabled={filter.disabled}
            className={classes.filter}
            sort={filter.sort}
            onChange={filter.command}
          />
          {filter.last && (
            <RegistryToolbarActions
              count={Object.keys(mainFilters).length}
              onClearClick={clearFilters}
              onMoreClick={showFiltersModal}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default RegistryMainFilters;
