import { NodeModel } from 'src/modules/general/common';
import { NODES_ENDPOINT } from 'src/api-url-schema';

export default {
  endpoint: NODES_ENDPOINT,
  parent: 'agglomerationCalculations',
  idField: (model: NodeModel) => `${model.lat}_${model.lng}`,
  defaults: {
    id: undefined,
    lat: undefined,
    lng: undefined,
    name: undefined,
    region: undefined,
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
  pagination: false,
};
