import React from 'react';

const MenuDistributionIcon = () => (
  <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.52325 0.658203C6.23996 0.658203 5.1846 1.71081 5.1846 2.99076C5.1846 4.02831 5.87544 4.92208 6.82165 5.22127V7.01442C5.28968 7.2468 3.99423 8.22624 3.3283 9.56566C3.11885 9.98694 2.84595 10.393 2.84595 11.6212C2.84595 12.8813 3.35632 14.0311 4.17606 14.8722L3.27714 16.1551C3.06631 16.0921 2.84237 16.0531 2.61209 16.0531C1.3288 16.0531 0.273438 17.1057 0.273438 18.3856C0.273438 19.6656 1.3288 20.7182 2.61209 20.7182C3.89538 20.7182 4.95074 19.6656 4.95074 18.3856C4.95074 17.8386 4.76006 17.3346 4.43916 16.9351L5.29422 15.7251C5.95685 16.085 6.71859 16.2863 7.52325 16.2863C8.3279 16.2863 9.08964 16.085 9.75227 15.7251L10.6073 16.9351C10.2864 17.3346 10.0958 17.8386 10.0958 18.3856C10.0958 19.6656 11.1511 20.7182 12.4344 20.7182C13.7177 20.7182 14.7731 19.6656 14.7731 18.3856C14.7731 17.1057 13.7177 16.0531 12.4344 16.0531C12.2065 16.0531 11.9855 16.0934 11.7767 16.1551L10.8704 14.8722C11.6902 14.0311 12.2005 12.8813 12.2005 11.6212C12.2005 11.3755 12.0836 10.2573 11.7182 9.56566C11.0523 8.22624 9.75682 7.2468 8.22484 7.01442V5.22127C9.17106 4.92208 9.8619 4.02831 9.8619 2.99076C9.8619 1.71081 8.80654 0.658203 7.52325 0.658203ZM8.45871 2.99076C8.45871 2.46718 8.0482 2.05774 7.52325 2.05774C6.9983 2.05774 6.58779 2.46718 6.58779 2.99076C6.58779 3.51435 6.9983 3.92378 7.52325 3.92378C8.0482 3.92378 8.45871 3.51435 8.45871 2.99076ZM10.7974 11.6212C10.7974 9.8094 9.3398 8.35564 7.52325 8.35564C5.7067 8.35564 4.24914 9.8094 4.24914 11.6212C4.24914 13.433 5.7067 14.8868 7.52325 14.8868C9.3398 14.8868 10.7974 13.433 10.7974 11.6212ZM3.54755 18.3856C3.54755 17.8621 3.13704 17.4526 2.61209 17.4526C2.08714 17.4526 1.67663 17.8621 1.67663 18.3856C1.67663 18.9092 2.08714 19.3187 2.61209 19.3187C3.13704 19.3187 3.54755 18.9092 3.54755 18.3856ZM13.3699 18.3856C13.3699 17.8621 12.9594 17.4526 12.4344 17.4526C11.9095 17.4526 11.499 17.8621 11.499 18.3856C11.499 18.9092 11.9095 19.3187 12.4344 19.3187C12.9594 19.3187 13.3699 18.9092 13.3699 18.3856Z"
      fill="currentColor"
    />
  </svg>
);

export default MenuDistributionIcon;
