import React from 'react';

const EditIcon = () => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 18.5H15.5789"
      stroke="currentColor"
    />
    <path
      d="M14.368 3.27964C14.6844 2.96324 14.6844 2.4359 14.368 2.13572L12.4695 0.237303C12.1694 -0.0791009 11.642 -0.0791009 11.3256 0.237303L9.83284 1.72196L12.8752 4.76431L14.368 3.27964ZM0 11.5629V14.6053H3.04234L12.0152 5.62427L8.97288 2.58193L0 11.5629Z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
