import React from 'react';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  className?: string;
  position?: 'top' | 'bottom';
  align?: 'left' | 'center' | 'right';
}

const ToolbarControls: React.FC<Props> = ({
  className,
  position,
  align,
  children,
}) => {
  const positionClassName = classNames({
    [style.controlsTop]: position === 'top',
    [style.controlsBottom]: position === 'bottom',
    [style.alignLeft]: align === 'left',
    [style.alignCenter]: align === 'center',
    [style.alignRight]: align === 'right',
  });
  return (
    <div className={classNames(style.controls, positionClassName, className)}>
      {children}
    </div>
  );
};

export default ToolbarControls;
