import React, {
  FC, KeyboardEvent, ReactNode, useMemo, useRef, useState, ChangeEvent,
} from 'react';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { Slider as MuiSlider, SliderProps } from '@material-ui/core';

import { CommonInput, CommonLabel, NumberInput } from 'src/components';

import { useStyles, useSliderStyles } from './styles';

export interface Props extends Omit<SliderProps, 'onChange'> {
  className?: string;
  label?: ReactNode;
  name: string;
  value: number;
  disabled?: boolean;
  decimalScale?: number;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  onChange: (value: number) => void;
}

const Slider: FC<Props> = ({
  className,
  style,
  label,
  value,
  marks,
  name,
  min = 0,
  max = 100,
  step,
  disabled = false,
  decimalScale,
  prefix,
  suffix,
  thousandSeparator,
  decimalSeparator,
  onChange,
  ...other
}) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  // Use inner state for lag optimization
  const [sliderValue, setSliderValue] = useState<number>(value);
  const onSliderChange = useMemo(() => {
    return throttle((v: number) => {
      onChange(v);
    }, 100);
  }, []);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (inputRef.current && event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = Number(event.target.value);
    if (newValue < min) {
      onSliderChange(min);
      setSliderValue(min);
    } else if (newValue > max) {
      onSliderChange(max);
      setSliderValue(max);
    } else {
      onSliderChange(newValue);
      setSliderValue(newValue);
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      style={style}
    >
      {label && (
        <div id={name}>
          <CommonLabel className={classes.label}>{label}</CommonLabel>
        </div>
      )}
      <CommonInput
        className={classes.inputWrapper}
        id={name}
        inputRef={inputRef}
        value={sliderValue}
        inputComponent={NumberInput}
        inputProps={{
          allowNegative: min < 0,
          decimalScale,
          prefix,
          suffix,
          thousandSeparator,
          decimalSeparator,
        }}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <div className={classes.sliderWrapper}>
        <MuiSlider
          aria-labelledby={name}
          {...other}
          marks={marks}
          min={min}
          max={max}
          value={sliderValue}
          classes={sliderClasses}
          disabled={disabled}
          onChange={(e, v) => {
            const newValue = Array.isArray(v) ? v[0] : v;
            onSliderChange(Number(newValue));
            setSliderValue(Number(newValue));
          }}
          getAriaValueText={(number) => (number ? number.toString() : '')}
          step={step}
        />
      </div>
    </div>
  );
};

export default Slider;
