import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  label: {
    fontSize: 12,
    marginBottom: spacing(2),
  },

  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  leftMark: {
    marginRight: 10,
  },

  rightMark: {
    marginLeft: 10,
  },

  inputWrapper: {
    width: 50,
    alignSelf: 'center',

    '&> input': {
      fontSize: 12,
      height: 20,
      padding: spacing(0, 2),
      textAlign: 'center',
    },
  },
}));

export const useSliderStyles = makeStyles(({ spacing, palette }: Theme) => createStyles({
  rail: {
    height: 3,
    backgroundColor: palette.grey[300],
  },

  markLabel: {
    top: 20,
    fontSize: 12,
  },

  marked: {
    marginBottom: spacing(3),
  },
}));
