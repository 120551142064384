import { AC_MATRIX_FORECAST_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_MATRIX_FORECAST_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    balanceForecast: undefined,
    progress: undefined,
    remoteJobsPercent: undefined,
    status: undefined,
    ttc: undefined,
    matrix: undefined,
    routesDistribution: undefined,
    ttcCalculations: undefined,
    ttcCalculationsSuccess: undefined,
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
};
