import React, { useMemo } from 'react';

import i18n from 'src/i18n';
import { NumberFormat, WithUnit } from 'src/components';
import { UNITS } from 'src/constants';
import { isNil } from 'src/helpers';
import {
  AcDistStopsModel,
  AcDistTableFieldsConfig,
  TopColumns,
} from 'src/cluster/distribution-table';
import { VehicleType } from 'src/cluster/common';

const useDistPointConfigDown = (
  getVehicleTypeName: (vehicleId?: number) => VehicleType | undefined,
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistStopsModel>[] => {
  return useMemo(() => {
    const translate = 'systems.distribution.columns.';
    return [
      {
        type: TopColumns.endOfTrip,
        title: (
          <>
            <span className={classes.title}>
              <WithUnit unit={i18n.t(UNITS.people)}>
                {i18n.t(translate + TopColumns.downPassenger)}
              </WithUnit>
            </span>
            <span className={classes.subtitle}>
              {i18n.t(translate + TopColumns.endOfTrip)}
            </span>
          </>
        ),
        className: classes.first,
        model: (result: AcDistStopsModel) => (
          <div className={classes.taRight}>
            {isNil(result.getOffTripEnd) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.getOffTripEnd}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: TopColumns.withSubsequentTransfer,
        title: (
          <>
            <span className={classes.titleVisible}>
              <WithUnit unit={i18n.t(UNITS.people)}>
                {i18n.t(translate + TopColumns.downPassenger)}
              </WithUnit>
            </span>
            <span className={classes.subtitle}>
              {i18n.t(translate + TopColumns.withSubsequentTransfer)}
            </span>
          </>
        ),
        className: classes.first,
        model: (result: AcDistStopsModel) => (
          <div className={classes.taRight}>
            {isNil(result.getOffTransfer) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.getOffTransfer}
                locale="ru"
              />
            )}
          </div>
        ),
      },
      {
        type: TopColumns.allBoarding,
        title: (
          <>
            <span className={classes.titleVisible}>
              <WithUnit unit={i18n.t(UNITS.people)}>
                {i18n.t(translate + TopColumns.downPassenger)}
              </WithUnit>
            </span>
            <span className={classes.subtitle}>
              {i18n.t(translate + TopColumns.allBoarding)}
            </span>
          </>
        ),
        className: classes.first,
        model: (result: AcDistStopsModel) => (
          <div className={classes.taRight}>
            {isNil(result.getOffOverall) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.getOffOverall}
                locale="ru"
              />
            )}
          </div>
        ),
      },
    ];
  }, [getVehicleTypeName]);
};

export default useDistPointConfigDown;
