import React, { FC, ReactNode } from 'react';
import { MapButton } from 'src/cluster/common';

import useClasses from './styles';

type Props = {
  caption: string;
  icon: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const HeadToolbarButton: FC<Props> = (props) => {
  const {
    caption,
    icon,
    active = false,
    disabled = false,
    onClick,
  } = props;

  const classes = useClasses();

  return (
    <MapButton
      variant={active ? 'contained' : 'outlined'}
      className={classes.button}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={classes.icon}>
        {icon}
      </span>
      <span className={classes.caption}>
        {caption}
      </span>
    </MapButton>
  );
};

export default HeadToolbarButton;
