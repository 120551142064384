import { RestifyArray } from 'redux-restify';
import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';

import { DISTRIBUTION_ROUTES_ENDPOINT } from 'src/cluster/distribution-table';

export default {
  endpoint: DISTRIBUTION_ROUTES_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  idField: () => Math.random(),
  defaults: {
    id: undefined,
    vehicleTypeId: undefined,
    variantNumber: undefined,
    variantName: undefined,
    distance: undefined,
    directions: new RestifyArray({
      defaults: {
        id: undefined,
        direction: undefined,
      },
    }),
    passengerTrafficTotal: undefined,
    passengerTrafficPeak: undefined,
    peakFillingPerVehicleAbs: undefined,
    peakFillingPerVehicleNorm: undefined,
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
  allowIdRequests: false,
};
