import actions from 'src/modules/shipments/routes/actions';
import selectors from './selectors';

export * from './components';
export * from './constants';

export default {
  actions,
  selectors,
};
