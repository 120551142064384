import React, { FC, useRef } from 'react';
import { InteractiveState } from '@deck.gl/core/lib/deck';
import InteractiveMap from 'react-map-gl';

import { Map } from 'src/cluster/common';
import {
  EditorMode,
  EditorHeadToolbar,
  AcEditorPageForm,
  useGetTooltip,
  useEditorLayers,
  usePositionForFit,
  usePositionsForFit,
  useEditorMapStyle,
} from 'src/cluster/editor-map';

import useStyles from './styles';

interface Props {
  pageForm: AcEditorPageForm;
  showLegend(): void;
}

const EditorMap: FC<Props> = (props) => {
  const {
    pageForm: {
      editorMode,
      isWaiting,
      canBePositioned,
      isLoading,
    },
    showLegend,
  } = props;

  const classes = useStyles();
  const mapRef = useRef<InteractiveMap | null>(null);

  const [mapStyle, interactiveLayerIds] = useEditorMapStyle();
  const layers = useEditorLayers();
  const positionsForFit = usePositionsForFit();
  const positionForFit = usePositionForFit();

  const isRouteModifying = [EditorMode.addPoint, EditorMode.removePoint].includes(editorMode);
  const isEditing = [EditorMode.graph, EditorMode.modifyStop].includes(editorMode) || isRouteModifying;

  const getTooltip = useGetTooltip();

  const getCursor = ({ isDragging }: InteractiveState) => {
    if (isWaiting) return 'wait';
    if (isEditing) return 'crosshair';
    if (isDragging) return 'move';
    return 'default';
  };

  const controller = {
    dragRotate: false,
    touchRotate: false,
    doubleClickZoom: false,
  };

  const canBeFitted = canBePositioned && !isLoading;

  return (
    <div className={classes.map}>
      <EditorHeadToolbar />
      <Map
        mapRef={mapRef}
        isBlocked={isLoading}
        canBeFitted={canBeFitted}
        mapStyle={mapStyle}
        interactiveLayerIds={interactiveLayerIds}
        layers={layers}
        getCursor={getCursor}
        getTooltip={getTooltip}
        positionForFit={positionForFit}
        positionsForFit={positionsForFit}
        controller={controller}
        showLegend={showLegend}
      />
    </div>
  );
};

export default EditorMap;
