import { useMemo, useEffect } from 'react';
import { bbox, lineString } from '@turf/turf';
import WebMercatorViewport from 'viewport-mercator-project';
import { LinearInterpolator } from 'react-map-gl';

import { MAP_BOUND_PADDING, toFilterValue } from 'src/constants';
import palette from 'src/theme/palette';
import { useCurrentVocabulary } from 'src/i18n';
import {
  getExtendedMapStyle,
  makeMapStyleEdgeLayer,
  makeMapStyleClusterLayers,
  makeMapStylePointLayer,
} from 'src/helpers';

import { NodeModel, RouteModel } from 'src/modules/general/common';
import {
  AGGLOMERATION_EDGE_COLORS,
  EDGE_TRAFFIC_COLOR,
  INACTIVE_ROUTE_COLOR,
  SELECTED_EDGE_COLOR,
  VIEW_MODES,
  getMapStylePolygonLayer,
  getMapStyleSourceByUrl,
  getMapStyleSourcesWithVirtualEdges,
} from 'src/modules/mth/common';

import { AgglomerationEdgeTypes } from '../../types';
import { PageAgglomerationForm } from '../../interfaces';

function getTrafficColors() {
  const routeLoadColors = [
    { from: 0, to: 0.3, color: EDGE_TRAFFIC_COLOR[6] },
    { from: 0.3, to: 0.4, color: EDGE_TRAFFIC_COLOR[5] },
    { from: 0.4, to: 0.55, color: EDGE_TRAFFIC_COLOR[4] },
    { from: 0.55, to: 0.7, color: EDGE_TRAFFIC_COLOR[3] },
    { from: 0.7, to: 0.9, color: EDGE_TRAFFIC_COLOR[2] },
    { from: 0.9, to: Infinity, color: EDGE_TRAFFIC_COLOR[1] },
  ];
  return [
    'case',
    ['==', ['get', 'maxTraffic'], false],
    'transparent',
    ['!', ['has', 'trafficLoad']],
    EDGE_TRAFFIC_COLOR[6],
    ...routeLoadColors.reduce<any[]>((memo, item) => {
      return memo.concat([
        [
          'all',
          ['>=', ['get', 'trafficLoad'], item.from],
          ['<', ['get', 'trafficLoad'], item.to],
        ],
        item.color,
      ]);
    }, []),
    EDGE_TRAFFIC_COLOR[6],
  ];
}

function getTransportTypeColors() {
  const routeLoadColors = Object.keys(AGGLOMERATION_EDGE_COLORS).map((key) => (
    { transportType: key, color: AGGLOMERATION_EDGE_COLORS[key] }
  ));
  return [
    'case',
    ...routeLoadColors.reduce<any[]>((memo, item) => {
      return memo.concat([
        ['==', ['get', 'transportType'], item.transportType],
        item.color,
      ]);
    }, []),
    AGGLOMERATION_EDGE_COLORS.pedestrian,
  ];
}

interface SetViewportProps {
  (currentViewport: (currentViewport: any) => any): void;
}

export const useMapStyle = (
  nodes: NodeModel[],
  routes: RouteModel[],
  transportType: AgglomerationEdgeTypes | undefined,
  selectedRouteId?: number,
  selectedEdgeIds?: number[],
  selectedRegionId?: string,
  graphUrl?: string,
  polygonsUrl?: string,
  startPolygonUrl?: string,
  finishPolygonUrl?: string,
  {
    showGraph = false,
    showTraffic = false,
  } = {},
) => {
  const [locale] = useCurrentVocabulary();
  return useMemo(() => {
    const pointExtras = {
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    };
    const sources = showGraph
      ? getMapStyleSourceByUrl(graphUrl, polygonsUrl, nodes, pointExtras)
      : getMapStyleSourcesWithVirtualEdges(routes, startPolygonUrl, finishPolygonUrl);

    const isSelectedRouteFilter = [
      '==',
      ['get', 'routeId'],
      toFilterValue(selectedRouteId),
    ];

    const isSelectedEdgesFilter = ['any',
      ...(selectedEdgeIds
        ? selectedEdgeIds.map((edgeId) => ([
          '==',
          'edgeId',
          toFilterValue(edgeId),
        ]))
        : []),
    ];

    const inactiveRoutesColor = showGraph ? getTrafficColors() : INACTIVE_ROUTE_COLOR;

    const layers: any[] = [
      ...getMapStylePolygonLayer(showGraph, selectedRegionId),
      makeMapStyleEdgeLayer(
        'routes-hover', ['all'],
        { 'line-color': 'transparent', 'line-width': 6 },
      ),
      makeMapStyleEdgeLayer(
        'routes-inactive', ['!', isSelectedRouteFilter],
        { 'line-color': inactiveRoutesColor, 'line-width': showGraph ? 3 : 5 },
      ),
      makeMapStyleEdgeLayer(
        'routes-selected', isSelectedRouteFilter,
        {
          'line-color': showGraph || showTraffic ? getTrafficColors() : getTransportTypeColors(),
          'line-width': 5,
        },
      ),
      makeMapStyleEdgeLayer(
        'edges-selected', isSelectedEdgesFilter,
        { 'line-color': SELECTED_EDGE_COLOR, 'line-width': 6 },
      ),
      ...makeMapStyleClusterLayers(true),
      makeMapStylePointLayer(
        'points-unclustered',
        true,
        ['!', ['has', 'point_count']],
        palette.text.primary,
      ),
      {
        id: 'correspondence-points',
        type: 'circle',
        source: 'edgesPoints',
        filter: ['==', 'isCorrespondence', true],
        paint: {
          'circle-color': '#fff',
          'circle-radius': 5,
          'circle-stroke-width': 6,
          'circle-stroke-color': '#e5293c',
        },
      },
    ];

    return getExtendedMapStyle({
      sources,
      layers,
    }, locale);
  }, [
    nodes,
    routes,
    graphUrl,
    polygonsUrl,
    transportType,
    selectedEdgeIds,
    selectedRouteId,
    selectedRegionId,
    startPolygonUrl,
    finishPolygonUrl,
    showGraph,
    showTraffic,
    locale,
  ]);
};

export const useMapFittedToEdges = (
  currentRoutes: any[],
  currentRoutesIsLoading: boolean,
  viewport: any,
  setViewport: SetViewportProps,
  routeForm: PageAgglomerationForm,
) => {
  const hasSizes = viewport.height && viewport.width;
  useEffect(() => {
    if (!hasSizes) {
      return;
    }
    if (routeForm.viewMode === VIEW_MODES.correspondences && !currentRoutesIsLoading && currentRoutes.length) {
      const summaryRoute = currentRoutes.reduce<number[][]>(
        (memo, route) => {
          return memo.concat(
            route.traffic
              .filter((edge: any) => edge.edgeId !== -1)
              .flatMap((edge: any) => ([edge.firstNode, edge.secondNode])),
          );
        },
        [],
      );
      if (!summaryRoute.length) {
        return;
      }
      const [minLng, minLat, maxLng, maxLat] = bbox(lineString(summaryRoute));
      const newViewport = new WebMercatorViewport(viewport);
      try {
        const {
          latitude,
          longitude,
          zoom,
        } = newViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: MAP_BOUND_PADDING,
        });
        setViewport(currentViewport => ({
          ...currentViewport,
          latitude,
          longitude,
          zoom,
          transitionInterpolator: new LinearInterpolator(),
          transitionDuration: 1000,
        }));
      } catch (e) {
        console.error('Error fitting map to bounds', e);
      }
    }
  }, [
    hasSizes,
    JSON.stringify(currentRoutes),
    currentRoutesIsLoading,
    routeForm.viewMode,
  ]);
};

export const useMapFittedToNodes = (
  currentNodes: any[],
  currentNodesIsLoading: boolean,
  viewport: any,
  setViewport: SetViewportProps,
  routeForm: PageAgglomerationForm,
) => {
  const hasSizes = viewport.height && viewport.width;
  useEffect(() => {
    if (!hasSizes) {
      return;
    }
    if (routeForm.viewMode === VIEW_MODES.graph && !currentNodesIsLoading && currentNodes.length) {
      const summaryNodeArray = currentNodes.reduce<number[][]>(
        (memo, node) => {
          return memo.concat([[node.lng, node.lat]]);
        },
        [],
      );
      if (!summaryNodeArray.length) {
        return;
      }
      const [minLng, minLat, maxLng, maxLat] = bbox(lineString(summaryNodeArray));
      const newViewport = new WebMercatorViewport(viewport);
      try {
        const {
          latitude,
          longitude,
          zoom,
        } = newViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: MAP_BOUND_PADDING,
        });
        setViewport(currentViewport => ({
          ...currentViewport,
          latitude,
          longitude,
          zoom,
          transitionInterpolator: new LinearInterpolator(),
          transitionDuration: 1000,
        }));
      } catch (e) {
        console.error('Error fitting map to bounds', e);
      }
    }
  }, [
    hasSizes,
    JSON.stringify(currentNodes),
    currentNodesIsLoading,
    routeForm.viewMode,
  ]);
};
