import {
  CONFIRM_MODAL_NAME,
} from './constants';

export const getModalOpen = (modalName: string) => (state: any) => !!state.modals[modalName];

export const getModal = (modalName: string) => (state: any) => state.modals[modalName];

export const getOpenByNameMap = (state: any) => Object.keys(state.modals).reduce((memo: any, key: string) => ({
  ...memo,
  [key]: !!state.modals[key],
}), {});

export const getModalsPropsByNameMap = (state: any) => Object.keys(state.modals).reduce((memo: any, key: string) => {
  if (!state.modals[key]) {
    return memo;
  }
  return {
    ...memo,
    [key]: state.modals[key].params,
  };
}, {});

export const getModalsOrderByNameMap = (state: any) => Object.keys(state.modals).reduce((memo: any, key: string) => {
  if (!state.modals[key]) {
    return memo;
  }
  return {
    ...memo,
    [key]: state.modals[key].order,
  };
}, {});

export const getConfirmModalOpen = getModalOpen(CONFIRM_MODAL_NAME);
export const getConfirmModal = (state: any) => {
  return state.modals[CONFIRM_MODAL_NAME] && state.modals[CONFIRM_MODAL_NAME].params;
};
