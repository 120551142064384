import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ palette, spacing, zIndex }: Theme) => createStyles({
  root: {
    position: 'relative',
  },

  showBar: {
    '& $topBar': {
      display: 'flex',
    },

    '& $bottomBar': {
      top: spacing(14),
    },
  },

  topBar: {
    display: 'none',
    width: '100%',
    justifyContent: 'space-between',
    padding: spacing(3, 5),
  },

  bottomBar: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    top: spacing(4),
    right: spacing(4),
    left: spacing(4),
    zIndex: zIndex.appBar,
    pointerEvents: 'none',
  },

  label: {
    color: palette.grey[600],
  },

  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > button:not(:last-of-type)': {
      marginRight: spacing(2),
    },
  },

  stop: {
    display: 'inline-block',
    width: 14,
    height: 14,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: palette.success.dark,
    borderRadius: '50%',
    backgroundColor: palette.grey.A100,
    verticalAlign: 'middle',
  },

  hidden: {
    display: 'none',
  },
}));
