import { createFormActions, isNumber } from 'src/helpers';
import { ThunkActionResult } from 'src/reducer';
import modals from 'src/modals';

import {
  AC_DIST_GRAPH_VERSION_MODAL_NAME,
  AC_DISTRIBUTION_MAP_PAGE_FORM_NAME,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { loadRoute } from 'src/cluster/editor-map';
import { selectDistGraphForm } from 'src/cluster/distribution-common';
import {
  AcDistributionMapPageForm,
  editorGeojsonActions,
  selectDistributionMapPageForm,
} from 'src/cluster/distribution-map';

export const distMapFormActions = createFormActions<AcDistributionMapPageForm>(AC_DISTRIBUTION_MAP_PAGE_FORM_NAME);

export function initDistributionMap(): ThunkActionResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    if (!isNumber(selectedProject)) return;

    const { routeId } = selectDistributionMapPageForm(state);

    if (isNumber(routeId)) {
      dispatch(loadRoute(routeId));
    }

    const {
      year, scenarioId, intervalId, day,
    } = selectDistGraphForm(getState());

    if (!year || !scenarioId || !(day || intervalId)) {
      dispatch(modals.actions.showModal(true, AC_DIST_GRAPH_VERSION_MODAL_NAME));
    }
  };
}

export function onDistributionMapProjectChange(): ThunkActionResult<void> {
  return (dispatch) => {
    dispatch(distMapFormActions.resetForm());
    dispatch(editorGeojsonActions.resetForm());
  };
}
