import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useRestifyForm } from 'src/helpers';
import { GEOJSON_ENDPOINT, GRAPH_ENDPOINT, POLYGONS_ENDPOINT } from 'src/api-url-schema';
import { AGGLOMERATION_EDGE_TYPES, VIEW_MODES } from 'src/modules/mth/common/constants';
import { NodeModel, RouteModel } from 'src/modules/general/common';

import AggloMapFilter from 'src/modules/mth/common/components/AggloMapFilter';
import {
  selectEndpoint,
  selectCurrentProjectId,
  selectCurrentTransportType,
  selectSelectedRouteEdges,
} from '../../selectors';
import { PAGE_ROUTES_FORM_NAME } from '../../constants';
import { OvershotModel, PageCalculationsRoutesForm } from '../../../common/interfaces';
import AggloMap from '../../../common/components/AggloMap';

import style from './index.module.css';

function useGeojsonUrl(): (string | undefined)[] {
  const { apiHost, apiPrefix, endpoint } = useSelector(selectEndpoint);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentTransportType = useSelector(selectCurrentTransportType);
  if (Number.isNaN(currentProjectId)) {
    return [undefined];
  }

  const projectEndpoint = `${apiHost}${apiPrefix}${endpoint}${currentProjectId}/`;
  const polygonsUrl = `${projectEndpoint}${POLYGONS_ENDPOINT}${GEOJSON_ENDPOINT}`;
  const graphEndpoint = `${projectEndpoint}${GRAPH_ENDPOINT}${GEOJSON_ENDPOINT}`;
  const graphUrl = `${graphEndpoint}?transport_type=${currentTransportType}`;

  return [polygonsUrl, graphUrl];
}

interface Props {
  className?: string;
  nodes: NodeModel[];
  routes: RouteModel[];
  overshot: OvershotModel[];
  isLoading?: boolean;
  onSourceData: (onLoading: boolean) => void;
}

const AggloRoutesMap: FC<Props> = (props: Props) => {
  const {
    nodes,
    routes,
    overshot,
    isLoading,
    onSourceData,
  } = props;
  const [polygonsUrl, graphUrl] = useGeojsonUrl();
  const [selectedEdges, selectedEdgesIsLoading] = useSelector(selectSelectedRouteEdges);
  const [form, formActions] = useRestifyForm<PageCalculationsRoutesForm>(PAGE_ROUTES_FORM_NAME);

  const showGraph = form.viewMode === VIEW_MODES.graph;
  const isFullHeight = !!(
    form.selectedEdges?.length
    || form.isStartSelected
    || form.isFinishSelected
  ) && (
    !showGraph
    || form.transportType === AGGLOMERATION_EDGE_TYPES.auto
  );

  return (
    <div className={style.root}>
      <AggloMap {...{
        graphUrl,
        polygonsUrl,
        form,
        formActions,
        nodes,
        routes,
        overshot,
        selectedEdges,
        selectedEdgesIsLoading,
        isLoading,
        onSourceData,
      }} />
      <AggloMapFilter {...{
        className: classNames(style.filter, isFullHeight && style.fullHeight),
        nodes,
        routes,
        selectedEdges,
        selectedEdgesIsLoading,
        form,
        formActions,
        cancelSourceLoading: () => undefined,
      }} />
    </div>
  );
};

export default AggloRoutesMap;
