import { connect } from 'react-redux';
import { api, forms } from 'redux-restify';
import { Dispatch } from 'redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { PageProjectForm, ProjectModel } from 'src/modules/general/projects';
import { PROJECTS_PAGES_FORM_NAME, PROJECTS_MODEL_NAME } from 'src/modules/matrix/projects';

import { ProcessStatus } from 'src/types';
import MatrixProjectsPage from './MatrixProjectsPage';

const mapStateToProps = (state: AppState) => ({
  form: forms.selectors[PROJECTS_PAGES_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
  entities: api.selectors.entityManager[PROJECTS_MODEL_NAME].getEntities<ProjectModel>(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadProject: (project: ProjectModel) => {
    dispatch(api.actions.entityManager[PROJECTS_MODEL_NAME].loadById(project.id, {
      preventLoad: false,
      forceLoad: true,
    }));
  },
  selectProject: (project: ProjectModel) => {
    if (project.loadStatus === ProcessStatus.SUCCESS) {
      dispatch(forms.actions[PROJECTS_PAGES_FORM_NAME].changeField('selectedProject', project.id));
    }
  },
  resetSelectedProject: () => {
    dispatch(forms.actions[PROJECTS_PAGES_FORM_NAME].resetField('selectedProject'));
  },
  deleteProject: (project: ProjectModel) => {
    dispatch(api.actions.entityManager[PROJECTS_MODEL_NAME].deleteById(project.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(MatrixProjectsPage));
