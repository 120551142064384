import { AC_REGISTRY_CARRIERS_ENDPOINT } from 'src/cluster/editor-table';
import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_REGISTRY_CARRIERS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
  },
  transformArrayResponse: (response: string[]) => {
    return {
      data: response.map((carrier: string, index) => ({
        id: index + 1,
        name: carrier,
      })),
    };
  },
  pagination: false,
  allowIdRequests: false,
};
