import React, { FC } from 'react';

import PTable from 'src/components/deprecated/PTable';
import NumberFormat from 'src/components/etc/NumberFormat';

import { TRAFFIC_TYPES, TRAFFIC_TYPES_DICT } from 'src/modules/mth/common/constants';
import { OvershotModel } from 'src/modules/mth/common/interfaces';
import { WithTranslate } from 'src/i18n';
import style from './index.module.css';

export interface Props extends WithTranslate{
  overshot: OvershotModel[];
}

const AggloOvershotTable: FC<Props> = (props: Props) => {
  const {
    locale,
    overshot,
    t,
  } = props;

  return (
    <PTable<typeof overshot[0]> {...{
      headerCellClassName: style.headerCell,
      header: [
        {
          name: 'type',
          title: t('systems.mth.captions.transportType'),
          model: (model) => (
            <span>
              {t(TRAFFIC_TYPES_DICT[model.type])}
              &nbsp;
              <span className={style.unit}>
                (
                {model.type === TRAFFIC_TYPES.passenger && t('common.units.peoplePerHour')}
                {model.type === TRAFFIC_TYPES.cargo && t('common.units.vehiclesPerHour')}
                )
              </span>
            </span>
          ),
        },
        {
          name: 'type',
          title: t('systems.mth.captions.unallocatedCapacity'),
          model: (model) => (
            <span>
              <span className={style.bold}>
                <NumberFormat {...{
                  value: model.overshot,
                  locale,
                  decimalScale: 0,
                  zeroPlaceholder: '-',
                }} />
              </span>
              &nbsp;
              {!!model.overshotPercent && (
                <span>
                  (
                  <NumberFormat {...{
                    value: model.overshotPercent,
                    locale,
                    suffix: '%',
                    decimalScale: 0,
                    zeroPlaceholder: '-',
                  }} />
                  )
                </span>
              )}
            </span>
          ),
        },
        {
          name: 'type',
          title: t('systems.mth.captions.allocatedCapacity'),
          model: (model) => (
            <span>
              <span className={style.bold}>
                <NumberFormat {...{
                  value: model.routes,
                  locale,
                  decimalScale: 0,
                  zeroPlaceholder: '-',
                }} />
              </span>
              &nbsp;
              {!!model.routesPercent && (
                <span>
                  (
                  <NumberFormat {...{
                    value: model.routesPercent,
                    locale,
                    suffix: '%',
                    decimalScale: 0,
                    zeroPlaceholder: '-',
                  }} />
                  )
                </span>
              )}
            </span>
          ),
        },
        {
          name: 'type',
          title: t('systems.mth.captions.total'),
          className: style.totalColumn,
          model: (model) => (
            <span className={style.bold}>
              <NumberFormat {...{
                value: model.total,
                locale,
                decimalScale: 0,
                zeroPlaceholder: '-',
              }} />
            </span>
          ),
        },
      ],
      body: overshot,
    }} />
  );
};

export default AggloOvershotTable;
