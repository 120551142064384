import { ModelDefaults } from 'src/types';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_ROUTES_DIRECTIONS_ENDPOINT,
  AcRouteDirectionModel,
} from 'src/cluster/common';

const defaults: ModelDefaults<AcRouteDirectionModel> = {
  id: undefined,
  longName: undefined,
  length: undefined,
  direction: undefined,
  vehicleTypeId: undefined,
  routeNumber: undefined,
  variantNumber: undefined,
  routeLongName: undefined,
  variantName: undefined,
};

export default {
  endpoint: AC_ROUTES_DIRECTIONS_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults,
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
  pagination: false,
};
