import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';

import { NumberFormat, WithUnit } from 'src/components';

import { UNITS } from 'src/constants';
import { useVehicleTypeLabel } from 'src/cluster/editor-map';
import {
  AcDistributionMapPageForm,
  useDistributionMapRouteVariantNumber,
  ViewModes,
} from 'src/cluster/distribution-map';

import useStyles from './styles';

interface OwnProps {
  pageForm: AcDistributionMapPageForm;
  isDay: boolean;
  id: string | number | undefined;
  name: string;
  data: Record<number, number>;
  total: number;
}

type Props = OwnProps & WithTranslate;

const DistributionMapPopupStop: FC<Props> = (props) => {
  const {
    id,
    name,
    data,
    total,
    pageForm: {
      viewMode,
      variantId,
    },
    isDay,
    locale,
    t,
  } = props;

  const classes = useStyles();

  const vehicleTypesIds = Object.keys(data).map(typeId => Number(typeId));

  const getVehicleTypeLabel = useVehicleTypeLabel();
  const routeVariantNumber = useDistributionMapRouteVariantNumber(variantId);

  const unit = isDay ? t(UNITS.people) : t(UNITS.peoplePerHour);

  return (
    <table className={classes.root}>
      <tbody>
        <tr>
          <td colSpan={2} className={classes.name}>
            {name}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={classes.desc}>
            <WithUnit unit={unit}>
              {t('systems.distribution.captions.passengerTraffic')}
            </WithUnit>
          </td>
        </tr>
        {viewMode === ViewModes.byRoutes && (
          <tr>
            <td>{routeVariantNumber}</td>
            <td className={classes.value}>
              <NumberFormat
                value={data[variantId ?? -1]}
                locale={locale}
                decimalScale={0}
              />
            </td>
          </tr>
        )}
        {viewMode === ViewModes.byVehicles && (
          vehicleTypesIds.map((typeId) => {
            const typeName = getVehicleTypeLabel(typeId);
            return (
              <tr key={typeId}>
                <td>{typeName}</td>
                <td className={classes.value}>
                  <NumberFormat
                    value={data[typeId]}
                    locale={locale}
                    decimalScale={0}
                  />
                </td>
              </tr>
            );
          })
        )}
        {viewMode === ViewModes.byVehicles && (
          <tr>
            <td>{t('systems.distribution.captions.allVehicleTypes')}</td>
            <td className={classes.value}>
              <NumberFormat
                value={total}
                locale={locale}
                decimalScale={0}
              />
            </td>
          </tr>
        )}
        <tr>
          <td
            colSpan={2}
            className={classes.stopId}
          >
            {`${t('systems.distribution.captions.stopId')}: ${id}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DistributionMapPopupStop;
