import React from 'react';

const TableIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="4.875" y1="0.875" x2="4.875" y2="13.125" stroke="currentColor" />
    <line x1="9.25" y1="0.875" x2="9.25" y2="13.125" stroke="currentColor" />
    <line x1="0.875" y1="9.125" x2="13.125" y2="9.125" stroke="currentColor" />
    <line x1="0.875" y1="4.75" x2="13.125" y2="4.75" stroke="currentColor" />
    <rect x="0.5" y="0.5" width="13" height="13" stroke="currentColor" />
  </svg>
);

export default TableIcon;
