import { api } from 'redux-restify';

import { BinaryApiResponse } from 'src/interfaces';
import { ThunkActionResult } from 'src/reducer';
import { downloadFile, getFilename, isNumber } from 'src/helpers';
import modals from 'src/modals';
import i18n from 'src/i18n';

import { selectAcProjectListForm, AC_PROJECTS_ENDPOINT } from 'src/cluster/common';
import {
  EMPTY_VARIANT,
  EXPORT_VARIANT_ENDPOINT,
  selectEntitiesEditorForm,
} from 'src/cluster/editor-map';
import { entitiesEditorFormActions, editorFormActions } from 'src/cluster/editor-common';

export function changeVariantNumber(id: number, value: string): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);

    if (!editableRoute) {
      return;
    }

    const updatedVariants = editableRoute.variants.map((v) => {
      if (v.id === id) {
        return {
          ...v,
          variantNumber: value,
        };
      }

      return v;
    });

    const updatedRoute = {
      ...editableRoute,
      variants: updatedVariants,
    };

    dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}

export function addVariant(): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }
    const updatedVariants = [EMPTY_VARIANT].concat(editableRoute.variants);
    const updatedRoute = {
      ...editableRoute,
      variants: updatedVariants,
    };
    dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}

export function copyVariant(id: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }

    const variant = editableRoute.variants.find(item => item.id === id);

    if (!variant) {
      return;
    }

    const variantNumber = `${i18n.t('systems.editor.captions.copy')} ${variant.variantNumber}`;

    const newVariant = {
      ...variant,
      variantNumber,
      id: -1,
    };

    const newRoute = {
      ...editableRoute,
      variants: [...editableRoute.variants, newVariant],
    };

    dispatch(entitiesEditorFormActions.changeField('editableRoute', newRoute));
    dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
  };
}

export function deleteVariant(id: number, number: string): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { editableRoute } = selectEntitiesEditorForm(state);
    if (!editableRoute) {
      return;
    }
    dispatch(modals.actions.showConfirmModal({
      title: i18n.t('modules.editor.captions.deleteRouteVariantTitle'),
      text: i18n.t('modules.editor.messages.deleteRouteVariantInfo', { number }),
      acceptButtonText: i18n.t('common.captions.delete'),
      declineButtonText: i18n.t('common.captions.cancel'),
      onAccept: () => {
        const updatedVariants = editableRoute.variants.filter((v) => v.id !== id);
        const updatedRoute = {
          ...editableRoute,
          variants: updatedVariants,
        };
        dispatch(entitiesEditorFormActions.changeField('editableRoute', updatedRoute));
        dispatch(entitiesEditorFormActions.setFieldDirtyState('editableRoute', true));
      },
    }));
  };
}

export function exportVariant(id: number): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    if (!isNumber(selectedProject) || !isNumber(id)) {
      return Promise.reject();
    }
    const config = {
      url: `${AC_PROJECTS_ENDPOINT}${selectedProject}/${EXPORT_VARIANT_ENDPOINT}${id}/export/`,
      isBinary: true,
    };

    dispatch(editorFormActions.changeField('isLoading', true));
    try {
      const { data, status, api: xhr }: BinaryApiResponse = await dispatch(api.actions.callGet(config));
      if (status === 200 && data !== undefined) {
        const blob = new Blob([data], { type: 'octet/stream' });
        downloadFile(blob, `${id}_${getFilename(xhr)}`);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      dispatch(editorFormActions.resetField('isLoading'));
    }
  };
}
