import React, { FC } from 'react';
import { Tooltip, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';

import { useVehicleTypeLabel, useVehicleTypeName } from 'src/cluster/editor-map';
import { VehicleIcon } from 'src/cluster/common';

import useStyles from './styles';

interface OwnProps {
  types: number[];
}

type Props = OwnProps & WithTranslate;

const EditorVehicleList: FC<Props> = (props) => {
  const {
    types,
    t,
  } = props;

  const classes = useStyles();
  const getVehicleTypeName = useVehicleTypeName();
  const getVehicleTypeLabel = useVehicleTypeLabel();

  return (
    <div className={classes.root}>
      <Typography className={classes.caption}>
        {t('modules.editor.captions.vehicleTypesShort')}
      </Typography>
      <div className={classes.icons}>
        {types.length > 0 ? types.map((typeId) => {
          const vehicleTypeName = getVehicleTypeName(typeId);
          if (!vehicleTypeName) return null;
          const vehicleTypeLabel = getVehicleTypeLabel(typeId);
          return (
            <Tooltip key={typeId} title={vehicleTypeLabel} arrow>
              <span>
                <VehicleIcon type={vehicleTypeName} />
              </span>
            </Tooltip>
          );
        }) : <span><VehicleIcon /></span>}
      </div>
    </div>
  );
};

export default EditorVehicleList;
