import { FC } from 'react';
import { compose } from 'redux';
import { withRestify } from 'src/helpers';
import withTranslate from 'src/i18n/hoc/withTranslate';
import LocaleSwitch, { OwnProps } from './LocaleSwitch';

const enhance = compose<FC<OwnProps>>(
  withTranslate,
  withRestify([], ['localeServiceForm']),
);

export default enhance(LocaleSwitch);
