import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { Editable, Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';

import { SubmitButtons } from 'src/cluster/common';
import {
  AcEdgeSpeedChange, isAcEdgeSpeedChange, useVehicleTypeLabel, useVehicleTypeName,
} from 'src/cluster/editor-map';

import { VehicleSpeedSlider } from './components';
import useStyles from './styles';

interface OwnProps {
  isLoading: boolean;
  isModifying: boolean;
  speedChanges: Editable<Model<AcEdgeSpeedChange>>[];
  cancel(): void;
  modify(id: number, values: Model<AcEdgeSpeedChange>): void;
  save(): void;
}

type Props = OwnProps & WithTranslate;

const EditorGraphOptions: FC<Props> = (props) => {
  const {
    isLoading,
    isModifying,
    speedChanges,
    t,
    cancel,
    modify,
    save,
  } = props;

  const classes = useStyles();

  const getVehicleTypeLabel = useVehicleTypeLabel();
  const getVehicleTypeName = useVehicleTypeName();

  const handleChange = (id: number, value: number) => {
    const values = speedChanges.find(speed => speed.id === id);
    if (isAcEdgeSpeedChange(values)) {
      modify(id, {
        ...values,
        speedChange: value,
      });
    }
  };

  const hasChanges = speedChanges.some(record => record.$edited);

  const sortedSpeedChanges = speedChanges.sort((a, b) => {
    const aLabel = getVehicleTypeLabel(a.vehicleTypeId);
    const bLabel = getVehicleTypeLabel(b.vehicleTypeId);
    return aLabel.localeCompare(bLabel);
  });

  return (
    <section className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle1">
          {t('modules.editor.captions.editingSpeeds')}
        </Typography>
      </div>
      <div className={classes.list}>
        {sortedSpeedChanges.map((speed) => (
          <VehicleSpeedSlider
            key={speed.id}
            id={speed.id}
            value={speed.speedChange}
            type={getVehicleTypeName(speed.vehicleTypeId)}
            label={getVehicleTypeLabel(speed.vehicleTypeId)}
            onChange={handleChange}
          />
        ))}
      </div>
      {isModifying && (
        <SubmitButtons
          isLoading={isLoading}
          disabled={!hasChanges}
          onCancel={cancel}
          onSubmit={save}
        />
      )}
    </section>
  );
};

export default EditorGraphOptions;
