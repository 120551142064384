import { BalanceFilterModes } from 'src/cluster/common';

import { MapModes, ViewModes } from '../../types';
import { AcBalanceMapPageForm } from '../../interfaces';

const defaults: AcBalanceMapPageForm = {
  viewMode: ViewModes.data,
  mapMode: MapModes.star,

  year: undefined,
  scenarioId: undefined,
  factor: undefined,

  minTraffic: 50,

  filterModeFrom: BalanceFilterModes.node,
  filterModeTo: BalanceFilterModes.node,
  regionFrom: [],
  regionTo: [],
  municipalityFrom: [],
  municipalityTo: [],
};

export default { defaults };
