export default {
  defaults: {
    trafficLimit: 100,
    maxAlternativeRoutes: 2,

    pageSize: 10,
    currentPage: 1,
    isActual: false,
    version: 0,
  },
};
