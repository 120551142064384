import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { DataGrid } from 'src/components';

import { AcDistDirectionEdgesModel, useDirectionRouteConfig } from 'src/cluster/distribution-table';

import useStyles from './styles';

interface OwnProps {
  data: AcDistDirectionEdgesModel[];
  direction: boolean;
  isLoading: boolean;
}

type Props = OwnProps & WithTranslate

const DirectionRouteDataGrid: FC<Props> = (props) => {
  const {
    data, direction, isLoading, t,
  } = props;

  const classes = useStyles();

  const columns = useDirectionRouteConfig(classes);
  const title = `systems.agglomeration.captions.${direction ? 'forward' : 'backward'}`;

  return (
    <div>
      <Typography variant="h6" className={classes.titleTable} >
        {t(title)}
      </Typography>
      <div className={classes.tableWrapper}>
        <DataGrid<AcDistDirectionEdgesModel>
          columns={columns}
          body={data}
          headCellClassName={classes.variantHead}
          rowClassName={classes.variantRow}
          cellClassName={classes.cellClassName}
        />
        {data && data.length === 0 && !isLoading ? (
          <div className={classes.message} >
            {t('common.captions.noData')}
          </div>
        ) : null}
        {(isLoading) && (
          <div className={classes.message}>
            {t('common.captions.noData')}
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectionRouteDataGrid;
