import React, {
  FC, useCallback, useEffect,
} from 'react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';

import { RestifyFormActions, WithId } from 'redux-restify';

import { useDispatch } from 'react-redux';
import { getVocabularyValue, useCurrentVocabulary, WithTranslate } from 'src/i18n';

import NumberFormat from 'src/components/etc/NumberFormat';
import Pagination from 'src/components/etc/Pagination';
import PTable from 'src/components/deprecated/PTable';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import Tooltip from 'src/components/etc/Tooltip';
import { INPUT_TYPES } from 'src/components/deprecated/PInput/PInput';
import { TransportModel, PageTransportForm } from 'src/modules/matrix/transport';

import tooltipStyle from 'src/styles/tooltip.module.css';
import style from './index.module.css';

interface TransportTableProps {
  buttons?: any;
  data: WithId<TransportModel>[];
  count: number;
  isLoading: boolean;
  form: PageTransportForm;
  formActions: RestifyFormActions<PageTransportForm>;
  onChange: (id: number, value: any) => void;
}

type Props = TransportTableProps & WithTranslate & {
  className?: string;
}

const TransportTable: FC<Props> = (props: Props) => {
  const {
    className,
    data,
    count,
    isLoading,
    form,
    formActions,
    onChange,
    t,
  } = props;

  const dispatch = useDispatch();
  const [currentLocale, vocabulary] = useCurrentVocabulary();

  useEffect(() => {
    const countOnCurrentPage = form.pageSize * (form.currentPage - 1);
    if (countOnCurrentPage > count) {
      dispatch(formActions.changeField('currentPage', 1));
    }
  }, [count]);

  const handlePageSizeChange = useCallback((value: number) => {
    dispatch(formActions.changeField('pageSize', value));
  }, []);

  const handleCurrentPageChange = useCallback((value: number) => {
    dispatch(formActions.changeField('currentPage', value));
  }, []);

  const handlePriceChange = useCallback(async (val: any, row: any) => (
    row && onChange(row.id, { ...row, price: val })
  ), [onChange]);

  const handleTimeChange = useCallback(async (val: any, row: any) => (
    row && onChange(row.id, { ...row, time: val })
  ), [onChange]);

  const handleFrequencyChange = useCallback(async (val: any, row: any) => (
    row && onChange(row.id, { ...row, frequency: val })
  ), [onChange]);

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading,
      className: classNames(style.root, className),
    }}>
      <Pagination {...{
        pageSize: form.pageSize,
        currentPage: form.currentPage,
        count,
        onPageChange: handleCurrentPageChange,
        onPageSizeChange: handlePageSizeChange,
      }}
      >
        <PTable<WithId<TransportModel>> {...{
          className: style.table,
          hoverable: true,
          header: [
            {
              title: t('common.captions.from'),
              model: (item) => getVocabularyValue(currentLocale, vocabulary, String(item.fromCity)),
            },
            {
              title: t('common.captions.to'),
              model: (item) => getVocabularyValue(currentLocale, vocabulary, String(item.toCity)),
            },
            {
              title: t('systems.matrix.captions.meanOfTransport'),
              model: (item) => {
                const type = camelCase(item.type);
                return t(`systems.matrix.meansOfTransport.${type}`);
              },
            },
            {
              title: t('systems.matrix.captions.year'),
              model: (item) => item.year,
            },
            {
              title: (
                <span>
                  {t('systems.matrix.captions.rate')}
                  &nbsp;
                  <span className={style.units}>
                    (
                    {t('common.units.costRub')}
                    )
                  </span>
                </span>
              ),
              model: (item) => (
                <NumberFormat {...{
                  locale: currentLocale,
                  value: item.price || '-',
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              ),
              editable: true,
              type: INPUT_TYPES.float,
              className: style.editableCell,
              onChange: handlePriceChange,
            },
            {
              title: (
                <span>
                  {t('systems.matrix.captions.travelTime')}
                  &nbsp;
                  <span className={style.units}>
                    (
                    {t('common.units.timeHours')}
                    )
                  </span>
                </span>
              ),
              model: (item) => (
                <NumberFormat {...{
                  locale: currentLocale,
                  value: item.time || '-',
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              ),
              editable: true,
              type: INPUT_TYPES.float,
              className: style.editableCell,
              onChange: handleTimeChange,
            },
            {
              title: (
                <span>
                  {t('systems.matrix.captions.transportationFrequency')}
                  &nbsp;
                  <span className={style.units}>
                    (
                    {t('common.units.frequency')}
                    )
                  </span>
                </span>
              ),
              model: (item) => (
                <NumberFormat {...{
                  locale: currentLocale,
                  value: item.frequency || '-',
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              ),
              editable: true,
              type: INPUT_TYPES.float,
              className: style.editableCell,
              onChange: handleFrequencyChange,
            },
            {
              title: t('systems.matrix.captions.comfort'),
              model: (item) => (
                <NumberFormat {...{
                  locale: currentLocale,
                  value: item.comfortElasticity || '-',
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              ),
            },
            {
              title: (
                <Tooltip align="right">
                  <span className={tooltipStyle.tooltipAnchor}>?</span>
                  <div className={tooltipStyle.tooltipPopup}>
                    {t('systems.matrix.messages.transportTableTooltip')}
                  </div>
                </Tooltip>
              ),
              model: () => null,
              className: style.tooltipColumn,
            },
          ],
          body: data,
        }} />
      </Pagination>
    </LoadingBlockContainer>
  );
};

export default TransportTable;
