import { api, forms, RestifyId } from 'redux-restify';

import app from 'src/app';
import i18n from 'src/i18n';
import { ThunkActionResult } from 'src/reducer';
import {
  objectToLowerSnake, createFormActions, downloadFile, getFilename,
} from 'src/helpers';
import { BinaryApiResponse } from 'src/interfaces';

import {
  AC_PROJECT_LIST_FORM_NAME, AcBalanceTtcModel, AcProjectForm, AC_PROJECTS_ENDPOINT,
} from 'src/cluster/common';
import {
  AC_TTC_PAGE_FORM_NAME,
  AC_TTC_ENDPOINT,
  AC_TTC_MODEL_NAME,
  isTtcModel,
  AcPageBalanceTtcForm,
} from 'src/cluster/ttc';

export const balanceTtcFormActions = createFormActions<AcPageBalanceTtcForm>(AC_TTC_PAGE_FORM_NAME);

export const deleteMany = (ids: RestifyId[]): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const { selectedProject } = forms.selectors[AC_PROJECT_LIST_FORM_NAME].getForm(state);
  return Promise.all(ids.map(async (id) => {
    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${AC_TTC_ENDPOINT}${id}`;
    const { status } = await dispatch(api.actions.callDel({ url }));
    if (status >= 400) {
      dispatch(app.actions.toast.error(i18n.t('modules.ttc.messages.deleteError', { id })));
      return Promise.reject();
    }
    return Promise.resolve();
  }));
};

export const updateMany = (ttcList: AcBalanceTtcModel[]): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const { selectedProject } = forms.selectors[AC_PROJECT_LIST_FORM_NAME].getForm<AcProjectForm>(state);
  return Promise.all(ttcList.map(async (ttc: AcBalanceTtcModel) => {
    const { status, data } = await dispatch(forms.actions.sendQuickForm({
      endpoint: `${AC_PROJECTS_ENDPOINT}${selectedProject}/${AC_TTC_ENDPOINT}`,
      values: ttc,
    }));
    if (status >= 400 || !isTtcModel(data)) {
      dispatch(app.actions.toast.error(i18n.t('modules.ttc.messages.updateError', { id: ttc.id })));
      return Promise.reject();
    }

    dispatch(api.actions.entityManager[AC_TTC_MODEL_NAME].updateById(ttc.id, data));
    return Promise.resolve();
  }));
};

export const createMany = (ttcList: AcBalanceTtcModel[]): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const { selectedProject } = forms.selectors[AC_PROJECT_LIST_FORM_NAME].getForm<AcProjectForm>(state);
  return Promise.all(ttcList.map(async (ttc: AcBalanceTtcModel) => {
    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${AC_TTC_ENDPOINT}`;
    const data = objectToLowerSnake(ttc);
    const { status } = await dispatch(api.actions.callPost({ url, data }));
    if (status >= 400) {
      dispatch(app.actions.toast.error(i18n.t('modules.ttc.messages.createError')));
      return Promise.reject();
    }
    return Promise.resolve();
  }));
};

export const exportTtcData = (id: RestifyId): ThunkActionResult<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const { regionFrom, regionTo, year } = forms.selectors[AC_TTC_PAGE_FORM_NAME].getForm<AcPageBalanceTtcForm>(state);
  return dispatch(api.actions.callGet({
    url: `${AC_PROJECTS_ENDPOINT}${id}/${AC_TTC_ENDPOINT}export/`,
    query: {
      regionFrom,
      regionTo,
      year,
    },
    isBinary: true,
  })).then(({
    data,
    status,
    api: xhr,
  }: BinaryApiResponse) => {
    if (status === 200 && data !== undefined) {
      const blob = new Blob([data], {
        type: 'octet/stream',
      });
      const filename = getFilename(xhr);
      downloadFile(blob, filename);
    }
  });
};
