import React, { Fragment, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  InteractiveMap,
  Popup,
  Marker,
} from 'react-map-gl';
import { useSelector, useDispatch } from 'react-redux';
import { WebMercatorViewportOptions } from 'viewport-mercator-project';

import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import { useCurrentVocabulary, VocabularyEntity } from 'src/i18n';
import { useRestifyForm } from 'src/helpers';
import palette from 'src/theme/palette';
import {
  MARKER_SIZE,
  usePopup,
  useSystemsContext,
  toFilterValue,
} from 'src/constants';
import {
  useMapFittedToBounds,
  makeMapStyleSources,
  makeMapStyleEdgeLayer,
  makeMapStylePointLayer,
  makeMapStyleClusterLayers,
  getExtendedMapStyle,
} from 'src/helpers/map';
import { Map } from 'src/modules/general/common';
import ShipmentsMapLegend from 'src/modules/shipments/routes/components/ShipmentsMapLegend';
import {
  EdgePopup,
  EDGE_COLORS,
  SELECTED_ROUTE_COLOR,
  EDGE_TYPES,
  ROUTES_PAGES_FORM_NAMES,
  PageRoutesForm,
  EdgeModel,
} from '../../index';
import selectors from '../../selectors';
import style from './index.module.css';

interface Props {
  className?: string;
}

const ShipmentsRoutesMap: React.FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [locale] = useCurrentVocabulary();
  const [viewport, setViewport] = useState<WebMercatorViewportOptions>({
    latitude: 60,
    longitude: 93,
    zoom: 3,
  });
  const [nodePopup, setNodePopup] = usePopup<string>();
  const [edgePopup, setEdgePopup] = usePopup<EdgeModel>();
  const [markerPopup, setMarkerPopup] = usePopup<EdgeModel>();
  const mapRef = React.useRef<InteractiveMap | null>(null);
  const { currentNodes } = useSelector(selectors[currentSystem].getCurrentProjectNodes);
  const {
    currentRoutes,
    currentRoutesIsLoading,
  } = useSelector(selectors[currentSystem].getCurrentProjectRoutes);
  const [
    routesPageForm,
    routesPageFormActions,
  ] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);

  useMapFittedToBounds(currentRoutes, currentRoutesIsLoading, viewport, setViewport);

  const mapStyle = useMemo(() => {
    const pointExtras = {
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    };
    const sources = makeMapStyleSources(currentNodes, currentRoutes, pointExtras, {});
    const isSelectedRouteFilter = [
      '==',
      'routeIndex',
      toFilterValue(routesPageForm.selectedRoute),
    ];
    const layers: any[] = [
      ...Object.keys(EDGE_COLORS).map(edgeType => (
        makeMapStyleEdgeLayer(
          `routes-${edgeType}`,
          ['==', 'type', edgeType],
          {
            'line-color': EDGE_COLORS[edgeType],
            'line-width': 1,
            'line-dasharray': [3, 3],
          },
        )
      )),
      makeMapStyleEdgeLayer('routes-hover', ['all'],
        { 'line-color': 'transparent', 'line-width': 3 }),
      ...makeMapStyleClusterLayers(!currentRoutes.length),
      makeMapStylePointLayer(
        'unclustered-point',
        !currentRoutes.length,
        ['!', ['has', 'point_count']],
        palette.text.primary,
      ),
      makeMapStyleEdgeLayer('routes-selected', isSelectedRouteFilter,
        { 'line-color': SELECTED_ROUTE_COLOR, 'line-width': 2 }),
      {
        id: 'route-points',
        type: 'circle',
        source: 'edgesPoints',
        filter: ['all', ['==', 'isCorrespondence', false], isSelectedRouteFilter],
        paint: {
          'circle-color': '#fff',
          'circle-radius': 5,
          'circle-stroke-width': 4,
          'circle-stroke-color': '#023f7d',
        },
      },
      {
        id: 'correspondence-points',
        type: 'circle',
        source: 'edgesPoints',
        filter: ['==', 'isCorrespondence', true],
        paint: {
          'circle-color': '#fff',
          'circle-radius': 5,
          'circle-stroke-width': 6,
          'circle-stroke-color': SELECTED_ROUTE_COLOR,
        },
      },
    ];
    return getExtendedMapStyle({
      sources,
      layers,
    }, locale);
  }, [currentNodes, currentRoutes, routesPageForm]);
  let selectedRoute;
  if (routesPageForm.selectedRoute !== undefined) {
    selectedRoute = currentRoutes[routesPageForm.selectedRoute];
  }
  return (
    <LoadingBlockContainer {...{
      className: classNames(style.root, className),
      isBlocked: currentRoutesIsLoading,
    }}>
      <div className={style.mapWrapper}>
        <Map {...{
          mapRef,
          interactiveLayerIds: [
            'routes-hover',
            'unclustered-point',
            'routes-selected',
          ],
          viewport,
          setViewport,
          mapStyle,
          controlsPosition: 'top',
          legend: <ShipmentsMapLegend />,
          onMouseMove: (e) => {
            if (!mapRef.current) return;
            const features = mapRef.current.queryRenderedFeatures(e.point);
            if (!features) return;
            const cityFeature = features.find(f => f.properties && f.properties.isPoint);
            if (cityFeature && cityFeature.properties) {
              const { geometry }: any = cityFeature;
              setNodePopup({
                content: cityFeature.properties.name,
                lat: geometry.coordinates[1],
                lng: geometry.coordinates[0],
              });
            } else {
              setNodePopup({
                content: undefined,
                lat: 0,
                lng: 0,
              });
            }
            const edgeFeature = features.find(f => f.properties && f.properties.isEdge);
            if (edgeFeature && edgeFeature.properties) {
              const { geometry }: any = edgeFeature;
              const latCenter = (geometry.coordinates[0][1] + geometry.coordinates[1][1]) / 2;
              const lngCenter = (geometry.coordinates[0][0] + geometry.coordinates[1][0]) / 2;
              setEdgePopup({
                content: (edgeFeature.properties as any),
                lat: latCenter,
                lng: lngCenter,
              });
            } else {
              setEdgePopup({
                content: undefined,
                lat: 0,
                lng: 0,
              });
            }
          },
          onClick: (e) => {
            if (!mapRef.current) return;
            const features = mapRef.current.queryRenderedFeatures(e.point);
            const edgeFeature = features.find(f => f.properties && f.properties.isEdge);
            if (edgeFeature && edgeFeature.properties) {
              dispatch(routesPageFormActions.changeField('selectedRoute', edgeFeature.properties.routeIndex));
            }
          },
        }}>
          <Fragment>
            {
              nodePopup.content &&
              <Popup {...{
                latitude: nodePopup.lat,
                longitude: nodePopup.lng,
                closeButton: false,
                closeOnClick: false,
                anchor: 'top',
              }}>
                <div>
                  <VocabularyEntity value={nodePopup.content} />
                </div>
              </Popup>
            }
            {selectedRoute && selectedRoute.traffic.map(edge => {
              if (
                edge.type === EDGE_TYPES.port
                || edge.type === EDGE_TYPES.airport
                || edge.type === EDGE_TYPES.railBorderCross
                || edge.type === EDGE_TYPES.autoBorderCross
              ) {
                const latitude = edge.firstNode[1];
                const longitude = edge.firstNode[0];
                let iconType;
                if (edge.type === EDGE_TYPES.port) {
                  iconType = ICONS_TYPES.mapPort;
                } else if (edge.type === EDGE_TYPES.airport) {
                  iconType = ICONS_TYPES.airPort;
                } else {
                  iconType = ICONS_TYPES.mapBorderCross;
                }
                return (
                  <Marker {...{
                    key: `${latitude}_${longitude}`,
                    latitude,
                    longitude,
                    offsetLeft: -MARKER_SIZE / 2,
                    offsetTop: -MARKER_SIZE,
                  }}>
                    <PIcon {...{
                      type: iconType,
                      size: MARKER_SIZE,
                      className: style.marker,
                      onMouseEnter: () => {
                        setMarkerPopup({
                          content: edge,
                          lat: latitude,
                          lng: longitude,
                        });
                      },
                      onMouseLeave: () => {
                        setMarkerPopup({
                          content: undefined,
                          lat: 0,
                          lng: 0,
                        });
                      },
                    }} />
                  </Marker>
                );
              }
              return null;
            })}
            {
              (markerPopup.content || edgePopup.content) &&
              <Popup {...{
                className: style.popup,
                latitude: markerPopup.content ? markerPopup.lat : edgePopup.lat,
                longitude: markerPopup.content ? markerPopup.lng : edgePopup.lng,
                closeButton: false,
                closeOnClick: false,
                anchor: 'right',
              }}>
                <EdgePopup {...{
                  model: markerPopup.content || edgePopup.content,
                }} />
              </Popup>
            }
          </Fragment>
        </Map>
      </div>
    </LoadingBlockContainer>
  );
};

export default ShipmentsRoutesMap;
