import { RestifyId } from 'redux-restify';
import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';
import { StrictDict } from 'src/types';

export const FORECAST_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: 'pageMatrixForecastForm',
};

export const FORECAST_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.matrix]: 'matrixForecast',
};

export interface ForecastChartRecord {
  year: number;
  [key: string]: number;
}

export interface ForecastTableRecord {
  type: string;
  [key: number]: number;
}

export interface ForecastModel {
  id?: RestifyId;
  type: string;
  year: number;
  fromCity: string;
  toCity: string;
  trafficHsw: number;
  trafficNoHsw: number;
}

export interface PageForecastForm {
  isForecastBuilding: boolean;
  correspondences: string;
  allTransport: boolean;
  hsw: number;
}

export const FORECAST_EXPORT_MODAL = 'exportMatrixForecastModal';

export enum ExportTypes {
  chart = 'CHART',
  fullResult = 'FULL_RESULT',
}

export const EXPORT_TYPES = { ...ExportTypes };

export const EXPORT_TYPES_DICT: StrictDict<ExportTypes, string> = {
  [EXPORT_TYPES.chart]: 'systems.matrix.exportTypes.chart',
  [EXPORT_TYPES.fullResult]: 'systems.matrix.exportTypes.fullResult',
};
