import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';

import { SelectorType } from 'src/cluster/common';

const vehicleClassesPriority: Record<string, number> = {
  verySmall: 1,
  small: 2,
  middle: 3,
  big: 4,
  veryBig: 5,
};

function useGenerateItemsByName(selector: SelectorType): SelectMenuOption[] {
  const options = useSelector(selector);

  return useMemo(() => (
    options.map((opt) => opt.map((value) => ({
      id: `${value.id}`,
      priority: vehicleClassesPriority[value.name],
      value: value.name,
      label: value.name,
    })).sort((a, b) => a.priority - b.priority)).orJust([])
  ), [options]);
}

export default useGenerateItemsByName;
