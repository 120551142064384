import palette from 'src/theme/palette';
import { Scale } from 'src/cluster/common';

export function getFillColor(
  factors: { region: number; value: number }[],
  scale: Scale<string>,
) {
  const regionColors = factors.map((factor) => {
    const alpha = scale.find((scaleDivision) => {
      return factor.value >= scaleDivision.from && factor.value < scaleDivision.to;
    });
    const color = alpha?.value ?? palette.common.black;
    return {
      id: factor.region,
      color,
    };
  });
  return [
    'case',
    ['!', ['has', 'region_id']],
    'transparent',
    ...regionColors.reduce<any[]>((memo, item) => {
      return memo.concat([
        [
          'all',
          ['==', ['get', 'region_id'], item.id],
        ],
        item.color,
      ]);
    }, []),
    'transparent',
  ];
}

export function makeMapStylePolygonBorderLayer(visibility: boolean) {
  return {
    id: 'polygons-borders',
    filter: ['all'],
    layout: {
      visibility: visibility ? 'visible' : 'none',
    },
    source: 'polygons',
    type: 'line',
    paint: {
      'line-color': palette.grey[700],
      'line-width': 1.5,
    },
  };
}

export function makeMapStylePolygonLayer(visibility: boolean) {
  return {
    id: 'polygons',
    filter: ['all'],
    layout: {
      visibility: visibility ? 'visible' : 'none',
    },
    source: 'polygons',
    type: 'fill',
    paint: {
      'fill-color': '#aaaaaa',
      'fill-opacity': 0.2,
    },
  };
}

export function makeHeatMapStyleLayer(
  id: string,
  visibility: boolean,
  factors: { region: number; value: number }[],
  scale: Scale<string>,
) {
  return {
    id,
    filter: ['all'],
    layout: {
      visibility: visibility ? 'visible' : 'none',
    },
    source: 'polygons',
    type: 'fill',
    paint: {
      'fill-color': getFillColor(factors, scale),
    },
  };
}
