import { MODAL_SIZES, registerModal } from 'src/modals';
import { AppState } from 'src/reducer';
import { AC_TTC_EDIT_MODAL_NAME, ModalTtcEdit } from 'src/cluster/ttc';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.large,
  };
};

export default registerModal(
  AC_TTC_EDIT_MODAL_NAME,
  stateToProps,
)(ModalTtcEdit);
