import { api, forms } from 'redux-restify';
import { AppState } from 'src/reducer';
import { Dispatch } from 'redux';

export function createFormSelector<T>(formName: string) {
  return (state: AppState) => {
    if (!forms.selectors[formName]) {
      console.warn(`Form '${formName}' is not exist.`);
    }
    return forms.selectors[formName].getFormWithNulls<T>(state);
  };
}

export function createFormStateSelectors<T>(formName: string) {
  return {
    getDirtyFields: (state: AppState) => forms.selectors[formName].getDirtyFields<T>(state),
    getIsDirty: (state: AppState) => forms.selectors[formName].getIsDirty<T>(state),
    getEditingFields: (state: AppState) => forms.selectors[formName].getEditingFields<T>(state),
    getErrors: (state: AppState) => forms.selectors[formName].getErrors<T>(state),
    getIsValid: (state: AppState) => forms.selectors[formName].getIsValid(state),
  };
}

export function createEntitySelector<M>(modelName: string) {
  return (state: AppState) => api.selectors.entityManager[modelName].getEntities<M>(state);
}

export function createEndpointSelector(modelName: string) {
  return (state: AppState) => api.selectors.entityManager[modelName].getEndpoint(state);
}

export function createFormActions<F extends Record<string, any>>(formName: string) {
  type FormField = Extract<keyof F, string>;

  return {
    changeField: (field: FormField, value: F[FormField]) => forms.actions[formName].changeField(field, value),
    changeSomeFields: (values: Partial<F>) => forms.actions[formName].changeSomeFields(values),
    resetField: (field: FormField) => forms.actions[formName].resetField(field),
    resetSomeFields: (fields: FormField[]) => (dispatch: Dispatch<any>) => fields.forEach((field) => {
      dispatch(forms.actions[formName].resetField(field));
    }),
    resetFields: (fields: FormField[]) => (dispatch: Dispatch<any>) => {
      fields.forEach((field) => dispatch(forms.actions[formName].resetField(field)));
    },
    resetForm: () => forms.actions[formName].resetForm(),
    setFieldDirtyState: (field: FormField, value: boolean) => forms.actions[formName].setFieldDirtyState(field, value),
    resetFieldDirtyState: (field: FormField) => forms.actions[formName].resetFieldDirtyState(field),
    resetDirtyState: () => forms.actions[formName].resetDirtyState(),
  };
}

export function createModelActions<M extends Record<string, any>>(modelName: string) {
  return {
    clearData: (clearOldSingleEntities?: boolean) => {
      return api.actions.entityManager[modelName].clearData(clearOldSingleEntities);
    },
    clearPages: (clearOldPages?: boolean) => {
      return api.actions.entityManager[modelName].clearPages(clearOldPages);
    },
    loadData: (config?: {}) => {
      return api.actions.entityManager[modelName].loadData(config);
    },
    loadById: (id: number, config?: {}) => {
      return api.actions.entityManager[modelName].loadById(id, config);
    },
    refresh: (id: number, data: M, query?: {}) => {
      return api.actions.entityManager[modelName].updateById(id, data, query);
    },
    patch: (id: number, values: Partial<M>, config?: {}) => {
      return forms.actions.sendQuickForm({
        ...config,
        model: modelName,
        method: 'patch',
        id,
        values,
        updateEntity: true,
      });
    },
    put: (id: number, values: M, config?: {}) => {
      return forms.actions.sendQuickForm({
        ...config,
        model: modelName,
        method: 'put',
        id,
        values,
        updateEntity: true,
      });
    },
    create: (values: Partial<M>, config?: {}) => {
      return forms.actions.sendQuickForm({
        ...config,
        model: modelName,
        values,
      });
    },
    action: (id: number, action: string, values: Partial<M>, config?: {}) => {
      return forms.actions.sendQuickForm({
        ...config,
        model: modelName,
        method: 'post',
        id,
        values,
        specialAction: action,
        updateEntity: true,
      });
    },
    delete: (id: number, config?: {}) => {
      return api.actions.entityManager[modelName].deleteById(id, config);
    },
  };
}

export function createLoadsManager<F extends Record<string, any>>(endpoint: string) {
  return {
    selectIsUploadingUrl: (state: AppState) => api.selectors.loadsManager.getIsUploadingUrl(endpoint)(state),
    selectIsDownloadingUrl: (state: AppState) => api.selectors.loadsManager.getIsDownloadingUrl(endpoint)(state),
    selectLoadingProgress: (state: AppState) => api.selectors.loadsManager.getLoadingProgress(endpoint)(state),
  };
}
