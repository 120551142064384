import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';
import AgglomerationEdgeCorrespondence from '../../calculations/components/ModalAggloCalcEdgeRoutes';

import { EDGE_ROUTES_MODAL } from '../constants';

const stateToProps = (state: AppState, props: any) => {
  return {
    ...props,
    fitContent: true,
    size: MODAL_SIZES.medium,
  };
};

export default registerModal(
  EDGE_ROUTES_MODAL,
  stateToProps,
)(AgglomerationEdgeCorrespondence);
