import React, { FC, Fragment } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';

import { RestifyModel } from 'redux-restify';
import { IndicatorsItem, IndicatorsModel } from 'src/modules/mth/indicators';

import style from './index.module.css';

interface Props {
  indicator: IndicatorsItem;
  entities: [RestifyModel<IndicatorsModel>, boolean];
}

const AggloIndicatorsItem: FC<Props> = (props: Props) => {
  const {
    indicator: {
      no,
      isGroup,
      name,
      type,
      yearValues = [],
      parent,
    },
    entities: [{ indicatorsCatalog = {} }],
  } = props;

  return (
    <Fragment>
      {isGroup ? (
        <td colSpan={yearValues.length + 2}>
          {name}
        </td>
      ) : (
        <Fragment>
          <td className={classNames(parent !== '' && style.children)}>
            {parent !== '' && '- '}
            {name}
          </td>
          <td className={style.typeColumn}>
            <Tooltip title={indicatorsCatalog[type]} arrow>
              <span className={style.type}>{type}</span>
            </Tooltip>
          </td>
          {yearValues.map((year, i) => (
            <td key={no + i}>
              {year}
            </td>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export default AggloIndicatorsItem;
