import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    padding: '0px !important',
    boxShadow: 'none',
    marginBottom: 0,
    position: 'relative',
    zIndex: 10,
  },

  projectCreateContainer: {
    margin: theme.spacing(3, 5, 0, 3),
  },

  calculationButton: {
    '&:hover': {
      boxShadow: 'none',
    },
    '& > span': {
      fontWeight: 500,
      fontSize: 14,
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    color: `${theme.palette.common.white}`,
  },

  toolbarStatusWrapper: {
    marginTop: '2px',
  },

  tooltipInformer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '12px',
  },

  buttonGroup: {
    display: 'flex',
    marginBottom: '6px',
  },

  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    backgroundColor: 'transparent',
    marginRight: 15,
    cursor: 'pointer',
  },

  link: {
    '& > span': {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'uppercase',
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    marginLeft: 10,
    color: theme.palette.text.primary,
  },

  iconButtonRed: {
    color: theme.palette.primary.main,
    display: 'flex',
    '& > span': {
      fontWeight: 500,
      fontSize: 14,

      textTransform: 'uppercase',
    },
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    marginLeft: 10,
  },

  exportCaption: {
    display: 'inline-block',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    fontSize: 12,
    marginLeft: 10,
    color: theme.palette.text.primary,
  },

  toolBarControlsLine: {
    borderBottom: '1px solid #C4C4C4',
    width: '100%',
    marginBottom: '16px',
    paddingBottom: '24px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  toolbarControls: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },

  slider: {
    minWidth: 198,
    '& > #fullRemote': {
      margin: '0 auto',
    },
  },

  block: {
    padding: `0 ${theme.spacing(4)}`,
    width: '100%',
  },

  select: {
    minWidth: 260,
  },

  arrows: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '35px',
  },

  tightSelect: {
    minWidth: 100,
  },

  multiSelect: {
    minWidth: 360,
  },

  buttonControl: {
    marginLeft: theme.spacing(6),
  },

  accordionStyles: {
    borderRadius: '5px',
    borderColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },

  accordionTitle: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
  },

  total: {
    '& + &': {
      marginLeft: '40px',
    },
    '& div ': {
      marginBottom: '8px',
    },

    '& div > span': {
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: '20px',
    },

    '& div > span:last-of-type': {
      color: theme.palette.primary.main,
    },

    '& div:first-child': {
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 400,
      marginTop: 6,
    },
  },

  number: {
    whiteSpace: 'nowrap',
  },

  traffic: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  '@media only screen and (min-width: 1520px)': {
    '& $tightSelect': { width: 170 },
    '& $multiSelect': { width: 300 },
  },

  '@media only screen and (min-width: 1720px)': {
    '& $multiSelect': { width: 400 },
  },
}),
);
