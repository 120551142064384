import { AcPageBalanceDataForm, BalanceFilterModes } from 'src/cluster/common';

const defaults: AcPageBalanceDataForm = {
  filterMode: BalanceFilterModes.node,
  regions: undefined,
  municipalities: undefined,
  year: undefined,
  scenarioId: undefined,
  intervalId: undefined,
  isEdit: false,
  isExporting: false,

  pageSize: 10,
  currentPage: 1,
};

export default { defaults };
