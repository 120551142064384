import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dropTarget: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileInput: {
    display: 'none',
  },
  inputContainer: {
    position: 'relative',
    width: '100%',
    height: 100,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 20,
    fontSize: 20,
    cursor: 'pointer',
    transition: 'background 0.2s cubic-bezier(0.35, 0, 0.25, 1)',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '& svg': {
      fill: theme.palette.primary.main,
      transition: 'fill 0.2s cubic-bezier(0.35, 0, 0.25, 1)',
    },

    '&:hover .subLabel': {
      color: theme.palette.common.white,
    },
  },
  fileOver: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.3,
    backgroundColor: theme.palette.common.black,
  },
  label: {
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
}));
