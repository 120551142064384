import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema,
  RouteScheme,
  getUrl,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';

import { ProjectSelector, selectAcProjectListForm } from 'src/cluster/common';
import { projectListFormActions, selectAcProjects } from 'src/cluster/projects';
import { DistributionMapPage } from 'src/cluster/distribution-map';
import { DistributionTablePage } from 'src/cluster/distribution-table';

interface Props extends WithTranslate {
  match?: any;
  clearCache: () => void;
}

const DistributionRoot: FC<Props> = (props) => {
  const { match, t, clearCache } = props;

  return (
    <PageWithHeader
      basePath={['agglomeration', 'distribution']}
      menuRenderers={{
        map: {
          label: t('modules.map.captions.title'),
        },
        table: {
          label: t('modules.table.captions.title'),
        },
      }}
      headerChildren={(
        <Route path={getUrl(urlSchema.agglomeration.distribution)}>
          <ProjectSelector
            selectProjects={selectAcProjects}
            selectProjectForm={selectAcProjectListForm}
            formActions={projectListFormActions}
            onChange={clearCache}
          />
        </Route>
      )}
    >
      <RouteScheme
        prevMatch={match}
        basePath={['agglomeration', 'distribution']}
        renderers={{
          table: {
            component: DistributionTablePage,
          },
          map: {
            component: DistributionMapPage,
          },
        }}
      />
    </PageWithHeader>
  );
};

export default DistributionRoot;
