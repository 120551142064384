import React, { FC, Fragment } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import { Autocomplete } from 'src/components';
import { SelectValue } from 'src/interfaces';

import { AcDistPageForm } from 'src/cluster/common';
import {
  AcDistPageFormEnum,
  useMainFiltersConfig,
} from 'src/cluster/distribution-table';

import useStyles from './styles';

export interface InjectedProps {
  tableFilters: AcDistPageForm;
  setFilter(val: SelectValue, field: AcDistPageFormEnum): void;
}

type Props = InjectedProps & WithTranslate;

const DistributionRouteFilter: FC<Props> = (props) => {
  const {
    t,
    tableFilters,
    setFilter,
  } = props;

  const classes = useStyles();

  const filters = useMainFiltersConfig(tableFilters, setFilter);
  const autocomleteWith = (item: string) => {
    return item === 'systems.distribution.columns.routeVariantName';
  };
  const classN = (item: string) => classNames(autocomleteWith(item) ? classes.longWidth : classes.filter);
  return (
    <Box display="flex" flexWrap="wrap">
      {filters.map(filter => (
        <Fragment key={filter.label}>
          <Autocomplete
            placeholder={t('components.captions.select')}
            multiple={filter.multiple}
            label={t(filter.label)}
            options={filter.options}
            selected={filter.selected}
            disabled={filter.disabled}
            className={classN(filter.label)}
            sort={filter.sort}
            onChange={filter.command}
          />
        </Fragment>
      ))}
    </Box>
  );
};

export default DistributionRouteFilter;
