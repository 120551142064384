import React, { FC } from 'react';

import { RouteScheme } from 'src/pages';

import { WithTranslate } from 'src/i18n';

import {
  ProjectsRoot, EditorRoot, BalanceRoot, DistributionRoot, DashboardRoot,
} from './components';

interface Props extends WithTranslate {
  className?: string;
  match?: any;
  location?: any;
}

const AcRoot: FC<Props> = (props) => {
  const {
    className,
    match,
  } = props;

  return (
    <RouteScheme
      prevMatch={match}
      className={className}
      basePath={['agglomeration']}
      renderers={{
        projects: {
          component: ProjectsRoot,
        },
        editor: {
          component: EditorRoot,
        },
        balance: {
          component: BalanceRoot,
        },
        distribution: {
          component: DistributionRoot,
        },
        dashboard: {
          component: DashboardRoot,
        },
      }}
    />
  );
};

export default AcRoot;
