import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'src/reducer';

import {
  selectEditorPageForm,
  initEditor,
} from 'src/cluster/editor-map';

import EditorPage from './EditorPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInit: () => dispatch(initEditor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorPage);
