import { AcEditorCopyNetworkForm } from 'src/cluster/common';

const defaults: AcEditorCopyNetworkForm = {
  year: undefined,
  scenarioId: undefined,
  requestId: undefined,
  directionIds: undefined,
  isCopying: false,
  isSuccess: false,
  isError: false,
  isAwaitingConfirmation: false,
};

export default { defaults };
