import { AppState } from 'src/reducer';
import { RestifyEntityList } from 'redux-restify';
import { createSelector } from 'reselect';
import { Just, Maybe, Nothing } from 'monet';

import { Model } from 'src/interfaces';
import { isArray } from 'src/helpers';

import { TypeGuard } from 'src/cluster/common';

import { AC_PROJECTS_MODEL_NAME } from '../constants';

export function getApiConfigWithProject(projectId: number, filter = {}) {
  return {
    parentEntities: {
      [AC_PROJECTS_MODEL_NAME]: projectId,
    },
    filter,
  };
}

export function createEntityListSelector<T>(
  selectEntities: (state: AppState) => RestifyEntityList<T>,
  isValidModel: TypeGuard,
  filter: Record<string, any> = {},
) {
  return createSelector(
    selectEntities,
    (entities): Maybe<Model<T>[]> => {
      const entitiesArray = entities.getArray({ filter });

      if (!isArray(entitiesArray)) {
        return Nothing();
      }

      if (!isValidModel(entitiesArray[0])) {
        return Nothing();
      }

      return Just(entitiesArray);
    },
  );
}
