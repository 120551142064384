import React, { FC, useRef, useState } from 'react';
import InteractiveMap from 'react-map-gl';

import { Position } from 'src/interfaces';
import { usePopup } from 'src/constants';
import { WithTranslate } from 'src/i18n';

import { AcBalanceDataModel, Map, NodeModel } from 'src/cluster/common';
import {
  AcBalanceMapPageForm,
  PopupContent,
  useDataOnMouseMove,
  useBalanceMapStyle,
} from 'src/cluster/balance-map';

import { MapModes } from 'src/cluster/balance-map/types';
import BalanceDataMapPopup from './components';

export interface OwnProps {
  pageForm: AcBalanceMapPageForm;
  showLegend(): void;
}

export interface InjectedProps {
  polygonsUrl?: string;
  nodesEntities: [NodeModel[], boolean];
  dataEntities: [AcBalanceDataModel[], boolean, number];
}

export type Props = OwnProps & InjectedProps & WithTranslate;

const BalanceMap: FC<Props> = (props) => {
  const {
    nodesEntities: [nodes, isNodesLoading = false],
    dataEntities: [data, isDataLoading = false],
    pageForm,
    locale,
    showLegend,
  } = props;

  const {
    viewMode, factor, region, regionFrom, mapMode, regionTo,
  } = pageForm;

  const isLoading = isNodesLoading || isDataLoading;

  const mapRef = useRef<InteractiveMap | null>(null);

  const [popup, setPopup] = usePopup<PopupContent>();
  const [hoveredRegion, setHoveredRegion] = useState<
  string | number | undefined
  >();

  const mapStyle = useBalanceMapStyle(locale, region, hoveredRegion);

  const positions = nodes.reduce<Position[]>((memo, node) => {
    return memo.concat([[node.lng, node.lat]]);
  }, []);

  const regionId = MapModes.group === mapMode ? regionFrom : region;
  const handleMouseMove = useDataOnMouseMove(
    mapRef,
    data,
    setPopup,
    setHoveredRegion,
    hoveredRegion,
    regionId,
    regionTo,
    factor,
    viewMode,
  );

  return (
    <Map
      mapRef={mapRef}
      canBeFitted={!isLoading}
      mapStyle={mapStyle}
      interactiveLayerIds={[
        'heat-map-data',
        'heat-map-forecast',
        'heat-map-polygons-from',
        'heat-map-polygons-to',
        'star',
      ]}
      positionsForFit={positions}
      showLegend={showLegend}
      onMouseMove={handleMouseMove}
    >
      {popup.content && (
        <BalanceDataMapPopup
          lat={popup.lat}
          lng={popup.lng}
          region={popup.content.region}
          title={popup.content.title}
          value={popup.content.value}
        />
      )}
    </Map>
  );
};

export default BalanceMap;
