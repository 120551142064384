import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { isFunc } from 'src/helpers';

import ProjectSelector from './ProjectSelector';
import { OwnProps } from './intrerfaces';

const mapStateToProps = (state: AppState, { selectProjects, selectProjectForm }: OwnProps) => ({
  projects: selectProjects(state),
  projectForm: selectProjectForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>, { formActions, onChange }: OwnProps) => ({
  changeProject: (id: number) => {
    if (isFunc(onChange)) {
      onChange();
    }
    dispatch(formActions.changeField('selectedProject', id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ProjectSelector));
