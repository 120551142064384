import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { Location } from 'history';

import { WithTranslate } from 'src/i18n';
import ErrorBox from 'src/components/etc/ErrorBox';
import { LogoIcon, Button, TextField } from 'src/components';

import { AuthForm } from '../../forms/auth.form';
import { AuthInfoForm } from '../../forms/authInfo.form';

import useStyles from './styles';

interface Props extends WithTranslate {
  form: AuthForm;
  authInfoForm: AuthInfoForm;
  location: Location<{ nextUrl: string }>;
  login(url: string): void;
  changeField(path: keyof AuthForm, value: unknown): void;
}

const AuthPage: FC<Props> = (props) => {
  const {
    authInfoForm,
    form,
    location,
    login,
    changeField,
    t,
  } = props;

  const classes = useStyles();

  const { nextUrl } = location.state ?? {};
  const handleClick = () => login(nextUrl);

  const defaultInputProps = (path: keyof AuthForm) => ({
    value: form[path],
    className: classes.input,
    onChange: (event: ChangeEvent<HTMLInputElement>) => changeField(path, event.target.value),
    onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') handleClick();
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
      <div className={classes.form}>
        <div className={classes.logo}>
          <LogoIcon />
        </div>
        <ErrorBox opacityBackground className={authInfoForm.errors ? classes.error : classes.hiddenError}>
          {authInfoForm.errors}
        </ErrorBox>
        <TextField
          {...defaultInputProps('username')}
          placeholder={t('common.captions.loginName')}
        />
        <TextField
          {...defaultInputProps('password')}
          type="password"
          placeholder={t('common.captions.password')}
        />
        <Button
          color="primary"
          className={classes.button}
          onClick={handleClick}
        >
          {t('common.captions.login')}
        </Button>
      </div>
    </div>
  );
};

export default AuthPage;
