import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    height: '80px',
  },
  highlight: {
    color: theme.palette.grey[300],
  },
  title: {
    textAlign: 'right',
    marginRight: theme.spacing(3),
    flex: '1 1 100%',
    flexGrow: 1,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '13px',
    color: '#666666',
  },
  submit: {
    minWidth: 180,
  },
}));
