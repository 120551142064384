import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { RestifyId } from 'redux-restify';

import { WithTranslate } from 'src/i18n';
import { WithModalProps } from 'src/modals';

import { useRestifyForm } from 'src/helpers';
import { Systems } from 'src/constants';

import PSelect from 'src/components/deprecated/PSelect';
import PButton from 'src/components/deprecated/PButton';

import modalsStyle from 'src/styles/modals.module.css';
import { ExportAgglomerationCalculationForm } from '../../forms/exportAgglomerationCalculation.form';
import { EXPORT_TYPES_DICT } from '../../constants';
import { downloadCalculationExport } from '../../actions';

import style from './index.module.css';

export interface ExportCalculationModalProps {
  calculationId: RestifyId;
  system: Systems;
}

interface Props extends ExportCalculationModalProps, WithModalProps, WithTranslate {
  className?: string;
}

const ModalAggloCalcExport: React.FC<Props> = (props: Props) => {
  const {
    className,
    calculationId,
    cancelAction,
    submitAction,
    t,
  } = props;

  const dispatch = useDispatch();
  const [form, formActions] = useRestifyForm<ExportAgglomerationCalculationForm>('exportAgglomerationCalculationForm');

  useEffect(() => {
    dispatch(formActions.resetForm());
    return () => {
      dispatch(formActions.resetForm());
    };
  }, []);

  return (
    <div className={classNames(style.root, className)}>
      <div className={style.headerLabel}>
        {t('common.captions.exportType')}
      </div>
      <div className={style.selectsRow}>
        <PSelect {...{
          className: style.select,
          items: Object.keys(EXPORT_TYPES_DICT).map(type => ({
            value: type,
            label: t(EXPORT_TYPES_DICT[type]),
          })),
          values: form.type === undefined ? undefined : [form.type],
          onChange: values => dispatch(formActions.changeField('type', values[0])),
        }} />
      </div>

      <div className={modalsStyle.buttonsContainer}>
        <PButton {...{
          className: modalsStyle.button,
          onClick: cancelAction,
          type: 'white',
        }}>
          {t('common.captions.cancel')}
        </PButton>
        <PButton {...{
          className: modalsStyle.button,
          disabled: !form.type,
          onClick: () => {
            dispatch(downloadCalculationExport(calculationId)).then(() => {
              submitAction();
            });
          },
        }}>
          {t('common.captions.export')}
        </PButton>
      </div>
    </div>
  );
};

export default ModalAggloCalcExport;
