import React, {
  FC, useRef, useState, ChangeEventHandler, KeyboardEventHandler,
} from 'react';
import { FormControl, InputBaseProps, FormHelperText } from '@material-ui/core';

import { CommonInput, CommonLabel, NumberInput } from 'src/components';

import { useStyles } from './styles';

interface OwnProps {
  label?: string;
  autoBlur?: boolean;
  decimalScale?: number;
  thousandSeparator?: string;
  allowNegative?: boolean;
  prefix?: string;
  decimalSeparator?: string;
  errorText?: string[];
}

export type Props = OwnProps & InputBaseProps;

const TextField: FC<Props> = (props) => {
  const {
    id,
    className,
    type = 'text',
    value,
    defaultValue,
    label,
    decimalScale = 0,
    thousandSeparator,
    decimalSeparator,
    prefix = '',
    allowNegative = true,
    error,
    errorText,
    autoBlur = true,
    fullWidth,
    onKeyPress,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const [currentValue, setCurrentValue] = useState(value ?? defaultValue);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) {
      onChange(event);
    }
    setCurrentValue(event.target.value);
  };

  const handleKeypress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (onKeyPress) {
      onKeyPress(event);
    }

    if (autoBlur && inputRef.current && event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  return (
    <FormControl fullWidth={fullWidth} error={error} className={className}>
      {label && (
        <CommonLabel
          shrink
          htmlFor={id}
        >
          {label}
        </CommonLabel>
      )}
      <CommonInput
        {...rest}
        type={type === 'number' ? undefined : type}
        id={id}
        inputRef={inputRef}
        value={currentValue}
        inputComponent={type === 'number' ? NumberInput as any : undefined}
        inputProps={type === 'number' ? {
          allowNegative,
          decimalScale,
          prefix,
          thousandSeparator,
          decimalSeparator,
        } : undefined}
        onChange={handleChange}
        onKeyPress={handleKeypress}
      />
      {error && errorText && (
        errorText.map(text => (
          <FormHelperText className={classes.error} key={text} >
            {errorText || null}
          </FormHelperText>
        ))
      )}
    </FormControl>

  );
};

export default TextField;
