export default {
  defaults: {
    pageSize: 10,
    currentPage: 1,
    yearFrom: undefined,
    yearTo: undefined,
    correspondences: [],
    types: [],
    hsw: 0,
  },
};
