import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      minWidth: 400,
      marginRight: theme.spacing(4),
      height: '100%',
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.grey[200],
      display: 'flex',
      zIndex: 1000,
    },

    currentProject: {
      padding: theme.spacing(0, 4),
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.grey[200],
      justifySelf: 'flex-end',
      width: '100%',
      height: '100%',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      border: 0,
      outline: 0,
      backgroundColor: 'transparent',
      color: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textTransform: 'inherit',
      textAlign: 'left',
    },

    projectName: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },

    projectIcon: {
      width: 20,
      height: 20,
      color: theme.palette.primary.main,
    },

    popupIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(4),
      width: 30,
      height: 30,
      color: theme.palette.grey[700],
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&> svg': {
        transition: 'transform 0.3s ease',
        transform: 'rotate(0)',
      },
    },

    openedPopupIcon: {
      '&> svg': {
        transform: 'rotate(180deg)',
        color: theme.palette.primary.main,
      },
    },

    popup: {
      backgroundColor: theme.palette.common.white,
      minWidth: 399,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopWidth: 3,
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.primary.main,
    },

    projects: {
      overflow: 'auto',
      maxHeight: 220,
      width: '100%',
    },

    project: {
      display: 'flex',
      cursor: 'pointer',
      height: 40,
      padding: theme.spacing(0, 4),
      alignItems: 'center',
      fontSize: 14,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.grey[200],
      marginTop: -1,
    },

    checkIcon: {
      height: 15,
      width: 20,
      color: theme.palette.primary.main,
      marginLeft: 'auto',
    },

    selectedProject: {
      fontWeight: 'bold',
    },

    loadNewFileButton: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 15,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.grey[200],
    },
  });
});
