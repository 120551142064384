import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';

import { AcRoutesModel } from 'src/cluster/common';
import { selectRegRoutesNumbers } from 'src/cluster/editor-table';

const useRegRouteNumbers = (): SelectMenuOption[] => {
  const routeNumbers = useSelector(selectRegRoutesNumbers);

  return useMemo(() => {
    return routeNumbers.orJust([]).map((type: AcRoutesModel) => ({
      id: `${type.id}`,
      value: type.routeNumber,
      label: type.routeNumber,
    }));
  }, [routeNumbers]);
};

export default useRegRouteNumbers;
