import { useMemo } from 'react';

import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';

import { AcDistPageForm } from 'src/cluster/common';
import {
  AcDistFieldsConfig,
  AcDistPageFormEnum,
} from 'src/cluster/distribution-table';
import { useVehicleTypesOptions } from 'src/cluster/editor-map';

import { useDistStopsNames } from '..';

const usePointFiltersConfig = (
  filters: Partial<AcDistPageForm>,
  setFilter: (val: SelectValue, field: AcDistPageFormEnum) => void,
): AcDistFieldsConfig[] => {
  const {
    stopId,
    vehicleTypeIdStop: vehicleTypeId,
  } = filters;

  const vehicleTypeOptions = useVehicleTypesOptions();
  const stopsNamesOptions = useDistStopsNames();

  return useMemo(() => {
    const vehicleTypeValue = vehicleTypeOptions.find(opt => opt.value === vehicleTypeId);
    const stopsNamesValue = stopsNamesOptions.find(opt => opt.value === stopId);

    return [
      {
        label: 'systems.distribution.columns.stopName',
        multiple: false,
        options: stopsNamesOptions,
        selected: stopsNamesValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcDistPageFormEnum.stopId);
        },
      },
      {
        label: 'systems.distribution.columns.vehicleTypeFull',
        multiple: false,
        options: vehicleTypeOptions,
        selected: vehicleTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          setFilter(value, AcDistPageFormEnum.vehicleTypeIdStop);
        },
      },
    ];
  }, [
    stopId,
    vehicleTypeId,
    vehicleTypeOptions,
    stopsNamesOptions,
  ]);
};

export default usePointFiltersConfig;
