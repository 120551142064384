import React, { FC } from 'react';
import clsx from 'clsx';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget, ConnectDropTarget } from 'react-dnd';

import { WithTranslate } from 'src/i18n';

import { Button } from 'src/components';

import useStyles from './styles';

type OwnerProps = {
  className?: string;

  label?: React.ReactNode;

  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
  useFileNameAsLabel?: boolean;
  useProgressAsLabel?: boolean;

  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;

  isUploading: boolean;
  loadingProgress: number;

  onChange: (files: FileList | null) => void;
  selectedFiles?: File[];

  errors?: string[];
}

type InjectedProps = {
  isOver: boolean;
  connectDropTarget: ConnectDropTarget;
}

type Props = OwnerProps & WithTranslate & InjectedProps;

const FileInput: FC<Props> = (props) => {
  const {
    className,
    accept,
    multiple = false,
    disabled = false,
    isUploading,
    loadingProgress,
    selectedFiles,
    useFileNameAsLabel,
    useProgressAsLabel,
    variant = 'outlined',
    fullWidth = false,
    errors = [],
    isOver,
    connectDropTarget,
    onChange,
    t,
  } = props;

  const classes = useStyles();

  let { label } = props;
  if (useFileNameAsLabel && selectedFiles && selectedFiles.length && selectedFiles[0].name) {
    label = selectedFiles[0].name;
  }
  if (isOver) {
    label = t('components.captions.dropFileHere');
  }
  if (useProgressAsLabel && isUploading && loadingProgress !== 0) {
    label = `${loadingProgress.toFixed()}%`;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <label className={classes.label}>
        {isOver && connectDropTarget(<div className={classes.fileOver} />)}
        <Button
          color="primary"
          variant={variant}
          disabled={disabled || isUploading}
          component="div"
          fullWidth={fullWidth}
        >
          {label}
        </Button>
        <input
          className={classes.fileInput}
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled || isUploading}
          onChange={(e) => onChange(e.target.files)}
        />
        {isUploading && loadingProgress !== 0 && (
          <div style={{ width: `${loadingProgress}%` }} />
        )}
      </label>
      {!!errors.length && (
        <div>
          {errors.map((error, index) => (
            <div key={index}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropTarget(
  NativeTypes.FILE,
  {
    drop: (props: Props, monitor) => {
      props.onChange(monitor.getItem().files);
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: !!monitor.getItem(),
  }),
)(FileInput);
