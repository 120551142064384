import React from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';

import PTable from 'src/components/deprecated/PTable';
import PIcon from 'src/components/deprecated/PIcon';
import TimeFormat from 'src/components/etc/TimeFormat';
import NumberFormat from 'src/components/etc/NumberFormat';

import { UNITS } from 'src/modules/general/common';
import { EdgeModel, ICON_TYPES } from 'src/modules/mth/common';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  segments: EdgeModel[];
}

const AggloSingleRouteTable: React.FC<Props> = (props: Props) => {
  const {
    className,
    segments,
    t,
    locale,
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      <PTable<EdgeModel> {...{
        tableCellClassName: style.tableCell,
        headerCellClassName: style.headerCell,
        header: [
          {
            title: '',
            className: style.numberColumn,
            model: (segment, index) => index + 1,
          },
          {
            title: t('systems.mth.captions.type'),
            className: style.typeColumn,
            model: (segment) => {
              if (!ICON_TYPES[segment.transportType]) {
                return (
                  <div>
                    <span />
                  </div>
                );
              }
              return (
                <PIcon {...{
                  type: ICON_TYPES[segment.transportType],
                  className: style.transportIcon,
                }} />
              );
            },
          },
          {
            title: (
              <span>
                {t('systems.mth.captions.speed')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.speed)}
                  )
                </span>
              </span>
            ),
            className: style.speedColumn,
            model: (segment) => {
              const speed = segment.distance / segment.time;
              return (
                <NumberFormat {...{
                  value: speed,
                  locale,
                  decimalScale: 2,
                  zeroPlaceholder: '-',
                }} />
              );
            },
          },
          {
            title: (
              <span>
                {t('systems.mth.captions.ttcShort')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.costRub)}
                  )
                </span>
              </span>
            ),
            className: style.ttcColumn,
            model: (segment) => (
              <NumberFormat {...{
                value: segment.ttc,
                locale,
                decimalScale: 2,
                zeroPlaceholder: '-',
              }} />
            ),
          },
          {
            title: (
              <span>
                {t('systems.mth.captions.time')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.time)}
                  )
                </span>
              </span>
            ),
            className: style.timeColumn,
            model: (segment) => (
              <TimeFormat {...{
                value: segment.time,
              }} />
            ),
          },
          {
            title: (
              <span>
                {t('systems.mth.captions.length')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.length)}
                  )
                </span>
              </span>
            ),
            className: style.lengthColumn,
            model: (segment) => (
              <NumberFormat {...{
                value: segment.distance,
                locale,
                decimalScale: 2,
                zeroPlaceholder: '-',
              }} />
            ),
          },
          {
            title: (
              <span>
                {t('systems.mth.captions.tariff')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(UNITS.costRub)}
                  )
                </span>
              </span>
            ),
            className: style.fareColumn,
            model: (segment) => (
              <NumberFormat {...{
                value: segment.weight,
                locale,
                decimalScale: 2,
                zeroPlaceholder: '-',
              }} />
            ),
          },
          {
            title: <span />,
            model: () => <span />,
          },
          {
            title: <span />,
            model: () => <span />,
          },
        ],
        body: segments,
        summaryData: true,
      }} />
    </div>
  );
};

export default AggloSingleRouteTable;
