import { CompositeLayer } from 'deck.gl';
import { CompositeLayerProps } from '@deck.gl/core/lib/composite-layer';
// @ts-ignore
import { PathStyleExtension } from '@deck.gl/extensions';

import { FeatureCollection } from 'src/interfaces';

import palette from 'src/theme/palette';

import { hexToArray } from 'src/cluster/common';
import { createLayer } from 'src/cluster/editor-map';

export interface DistrictsLayerProps<D> extends CompositeLayerProps<D> {
  pointsData: D;
  polygonsData: D;
  pointsVisible: boolean;
  polygonsVisible: boolean;
}

class DistrictsLayer extends CompositeLayer<FeatureCollection, DistrictsLayerProps<FeatureCollection>> {
  renderLayers() {
    return [
      createLayer({
        id: 'district-polygons',
        data: this.props.polygonsData,
        visible: this.props.polygonsVisible,
        getLineWidth: () => 2,
        getLineColor: () => hexToArray(palette.grey[700]),
        getFillColor: () => hexToArray(palette.warning.light, 0.4),
        _subLayerProps: {
          geojson: {
            extensions: [new PathStyleExtension({
              dash: true,
            })],
            getDashArray: [6, 4],
            dashJustified: true,
            dashGapPickable: true,
          },
        },
      }),
      createLayer({
        id: 'district-centroids',
        pickable: true,
        data: this.props.pointsData,
        visible: this.props.pointsVisible,
        pointRadiusMinPixels: 7,
        getRadius: () => 20,
        getLineWidth: () => 6,
        getLineColor: () => hexToArray('#e7d88b'),
        getFillColor: () => hexToArray(palette.common.white),
      }),
    ];
  }
}

export default DistrictsLayer;
