import React, { ChangeEvent, FC, useEffect } from 'react';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { usePreloaderTimer } from 'src/helpers';
import { LoadingBlockContainer, SelectField } from 'src/components';

import { ToolbarControl, ToolbarControls } from 'src/components/toolbar';

import { useAcIntervalOptions, useAcYearOptions } from 'src/cluster/common';
import { AcBalanceMapPageForm, useBalanceMapScenarioOptions, useFactorOptions } from 'src/cluster/balance-map';

import useStyles from './styles';

export interface Props extends WithTranslate {
  className?: string;
  pageForm: AcBalanceMapPageForm;
  isLoading?: boolean;
  isSourceLoading?: boolean;
  setYear(event: ChangeEvent<{ value: unknown }>): void;
  setScenario(val: ChangeEvent<{ value: unknown }>): void;
  setFactor(event: ChangeEvent<{ value: unknown }>): void;
  setInterval(val: number): void;
}

const BalanceMapFilter: FC<Props> = (props) => {
  const {
    className,
    pageForm: { year, scenarioId, factor },
    isLoading = false,
    isSourceLoading = false,
    t,
    setYear,
    setScenario,
    setFactor,
    setInterval,
  } = props;

  const classes = useStyles();

  const yearsOptions = useAcYearOptions();
  const scenariosOptions = useBalanceMapScenarioOptions();
  const intervalOptions = useAcIntervalOptions();
  const factorOptions = useFactorOptions();

  const isLoadingByTime = usePreloaderTimer(isSourceLoading);
  const isOnlySourceLoading = isLoadingByTime && !isLoading;

  useEffect(() => {
    if (scenarioId && intervalOptions.length) {
      setInterval(intervalOptions[0].value as number);
    }
  }, [intervalOptions.length, scenarioId]);

  return (
    <LoadingBlockContainer
      className={clsx(classes.root, className)}
      isBlocked={isLoadingByTime}
      caption={isOnlySourceLoading ? t('common.captions.cancel') : undefined}
    >
      <ToolbarControls className={classes.select}>
        <ToolbarControl size="full-width">
          <SelectField
            label={t('common.captions.year')}
            options={yearsOptions}
            value={year}
            fullWidth
            onChange={setYear}
          />
        </ToolbarControl>
      </ToolbarControls>
      <ToolbarControls className={classes.select}>
        <ToolbarControl size="full-width">
          <SelectField
            label={t('systems.balance.captions.scenario')}
            options={scenariosOptions}
            value={scenarioId}
            fullWidth
            onChange={setScenario}
          />
        </ToolbarControl>
      </ToolbarControls>
      <ToolbarControls className={classes.select}>
        <ToolbarControl size="full-width">
          <SelectField
            label={t('systems.balance.captions.index')}
            options={factorOptions}
            value={factor}
            fullWidth
            onChange={setFactor}
          />
        </ToolbarControl>
      </ToolbarControls>
    </LoadingBlockContainer>
  );
};

export default BalanceMapFilter;
