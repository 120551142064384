import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectMenuOption } from 'src/interfaces';
import { AcRoutesVariantsModel, selectDistVariants } from 'src/cluster/distribution-table';

const useDistRoutesNames = (): SelectMenuOption[] => {
  const variants = useSelector(selectDistVariants);

  return useMemo(() => {
    return variants.orJust([]).map((stop: AcRoutesVariantsModel) => ({
      id: `${stop.id}`,
      value: stop.id,
      label: stop.variantName,
    }));
  }, [variants]);
};

export default useDistRoutesNames;
