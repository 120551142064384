import React, { FC, ReactElement } from 'react';

interface Props {
  className?: string;
  children: ReactElement;
  value: number;
  index: number;
}

const TabPanel: FC<Props> = (props: Props) => {
  const {
    className, children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      className={className}
      hidden={value !== index}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
