import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing, zIndex }) => createStyles({
  root: {
    position: 'absolute',
    left: 0,
    top: '50%',
    height: 100,
    width: spacing(4),
    border: 0,
    borderRadius: '0 4px 4px 0',
    backgroundColor: palette.common.white,
    transitionProperty: 'left',
    transitionDuration: '175ms',
    transitionDelay: '0ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    zIndex: zIndex.drawer,
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    outline: 0,
    boxShadow: '5px 4px 12px rgba(0, 0, 0, 0.12)',
  },

  open: {
    left: 285,
    transitionDelay: '50ms',
  },
}));
