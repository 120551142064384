/* eslint-disable max-len */
import React from 'react';

const TransportTypeRail = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5233 0.757832L6.78441 0.269287L2.04574 0.757832C1.87807 0.757832 1.74219 0.893717 1.74219 1.06139V10.8264C1.74219 10.9941 1.87807 11.1299 2.04574 11.1299H11.5233C11.6909 11.1299 11.8268 10.9941 11.8268 10.8264V1.06139C11.8268 0.893896 11.6909 0.757832 11.5233 0.757832ZM4.42883 9.5736C3.95921 9.5736 3.57851 9.1929 3.57851 8.72329C3.57851 8.25367 3.95921 7.87298 4.42883 7.87298C4.89844 7.87298 5.27913 8.25367 5.27913 8.72329C5.27913 9.1929 4.89844 9.5736 4.42883 9.5736ZM9.13999 9.5736C8.67038 9.5736 8.28968 9.1929 8.28968 8.72329C8.28968 8.25367 8.67038 7.87298 9.13999 7.87298C9.60961 7.87298 9.9903 8.25367 9.9903 8.72329C9.99048 9.1929 9.60961 9.5736 9.13999 9.5736ZM10.8938 6.33217C10.8938 6.42323 10.8201 6.49698 10.729 6.49698H2.83998C2.74892 6.49698 2.67517 6.42323 2.67517 6.33217V2.00616C2.67517 1.91509 2.74892 1.84134 2.83998 1.84134H10.729C10.8201 1.84134 10.8938 1.91509 10.8938 2.00616V6.33217Z" fill="#D52A36" />
    <path d="M11.5216 11.3976H2.04405C1.72907 11.3976 1.47266 11.1412 1.47266 10.8264V1.06137C1.47266 0.751744 1.72032 0.498723 2.02816 0.49033L6.78272 0L11.5373 0.49033C11.8451 0.498723 12.0928 0.751744 12.0928 1.06137V10.8264C12.093 11.1414 11.8365 11.3976 11.5216 11.3976ZM2.04405 1.02584C2.02441 1.02584 2.00834 1.04173 2.00834 1.06155V10.8265C2.00834 10.846 2.02423 10.8621 2.04405 10.8621H11.5216C11.5412 10.8621 11.5573 10.846 11.5573 10.8265V1.06137C11.5573 1.04173 11.5412 1.02566 11.5216 1.02566L11.4941 1.02423L6.78272 0.538542L2.04405 1.02584ZM9.13831 9.84142C8.52173 9.84142 8.02015 9.33984 8.02015 8.72327C8.02015 8.1067 8.52173 7.60494 9.13831 7.60494C9.75488 7.60494 10.2566 8.1067 10.2566 8.72327C10.2566 9.33984 9.75488 9.84142 9.13831 9.84142ZM9.13831 8.1408C8.81707 8.1408 8.55584 8.40222 8.55584 8.72345C8.55584 9.04468 8.81707 9.30592 9.13831 9.30592C9.45954 9.30592 9.72095 9.04468 9.72095 8.72345C9.72095 8.40222 9.45954 8.1408 9.13831 8.1408ZM4.42714 9.84142C3.81056 9.84142 3.30898 9.33984 3.30898 8.72327C3.30898 8.1067 3.81056 7.60494 4.42714 7.60494C5.04371 7.60494 5.54529 8.1067 5.54529 8.72327C5.54529 9.33984 5.04371 9.84142 4.42714 9.84142ZM4.42714 8.1408C4.1059 8.1408 3.84467 8.40222 3.84467 8.72345C3.84467 9.04468 4.1059 9.30592 4.42714 9.30592C4.74837 9.30592 5.0096 9.04468 5.0096 8.72345C5.0096 8.40222 4.74837 8.1408 4.42714 8.1408ZM10.7271 6.76463H2.8383C2.59974 6.76463 2.40564 6.57053 2.40564 6.33197V2.00614C2.40564 1.76758 2.59974 1.57348 2.8383 1.57348H10.7273C10.9659 1.57348 11.16 1.76758 11.16 2.00614V6.33215C11.1598 6.57053 10.9657 6.76463 10.7271 6.76463ZM2.94133 6.22894H10.6241V2.10917H2.94133V6.22894Z" fill="#D52A36" />
    <path d="M13.2274 16.0001C13.1064 16.0001 12.9885 15.9387 12.9214 15.8276L10.2976 11.4822C10.1956 11.3133 10.2497 11.0936 10.4187 10.9918C10.5874 10.8895 10.807 10.944 10.909 11.1129L13.5326 15.4586C13.6346 15.6275 13.5804 15.8471 13.4115 15.9489C13.354 15.9837 13.2901 16.0001 13.2274 16.0001Z" fill="#D52A36" />
    <path d="M0.356918 16.0001C0.294064 16.0001 0.230496 15.9835 0.172643 15.9487C0.00372325 15.8469 -0.0505596 15.6273 0.0515777 15.4584L2.67536 11.1127C2.77714 10.944 2.99677 10.8897 3.16569 10.9916C3.33461 11.0934 3.38889 11.3131 3.28676 11.482L0.662973 15.8278C0.596012 15.9389 0.477983 16.0001 0.356918 16.0001Z" fill="#D52A36" />
    <path d="M11.1567 12.6362H2.47934C2.38078 12.6362 2.30078 12.5564 2.30078 12.4576C2.30078 12.3589 2.38078 12.2791 2.47934 12.2791H11.1569C11.2556 12.2791 11.3355 12.3589 11.3355 12.4576C11.3355 12.5564 11.2555 12.6362 11.1567 12.6362Z" fill="#D52A36" />
    <path d="M12.0826 14.2405H1.6155C1.46747 14.2405 1.34766 14.1205 1.34766 13.9727C1.34766 13.8248 1.46747 13.7048 1.6155 13.7048H12.0826C12.2304 13.7048 12.3504 13.8248 12.3504 13.9727C12.3504 14.1205 12.2304 14.2405 12.0826 14.2405Z" fill="#D52A36" />
  </svg>
);

export default TransportTypeRail;
