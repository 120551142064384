import React, { FC, Fragment, useMemo } from 'react';
import classNames from 'classnames';
import {
  RestifyEntitiesArray,
  RestifyId,
  RestifyModel,
} from 'redux-restify';

import { WithTranslate } from 'src/i18n';
import {
  Button,
  DataGrid,
  NumberFormat,
  PenIcon,
  CrossIcon,
  DataGridColumnProps, WithUnit,
} from 'src/components';
import {
  AcBalanceDataModel, AcBalanceDataFactorsModel, useAcNodesOptions,
} from 'src/cluster/common';

import { isNil } from 'src/helpers';
import { UNITS } from 'src/constants';
import useStyles from './styles';

interface Props extends WithTranslate {
  data: AcBalanceDataModel[];
  factors: [RestifyEntitiesArray<RestifyModel<AcBalanceDataFactorsModel>>, boolean, number];
  isEdit: boolean;
  deleted: RestifyId[];
  created: AcBalanceDataModel[];
  edited: AcBalanceDataModel[];
  deleteData(id: RestifyId): void;
  editData(payload: AcBalanceDataModel): void;
  showEditModal(record: Partial<AcBalanceDataModel>, cb: Function): void;
}

const DataDataGrid: FC<Props> = (props: Props) => {
  const {
    data,
    factors: [columns],
    isEdit,
    deleted,
    created,
    edited,
    locale,
    t,
    deleteData,
    editData,
    showEditModal,
  } = props;

  const classes = useStyles();

  const makeEditHandler = (model: AcBalanceDataModel) => () => showEditModal(model, editData);
  const makeDeleteHandler = (model: AcBalanceDataModel) => () => deleteData(model.id);

  const nodesItems = useAcNodesOptions();

  const leftHeader = useMemo(() => ([
    {
      title: t('systems.balance.captions.district'),
      model: (model: AcBalanceDataModel) => model.regionName,
      className: classes.correspondenceColumn,
    },
    {
      title: t('systems.balance.captions.districtType'),
      className: classes.correspondenceColumn,
      model: (model: AcBalanceDataModel) => model.regionType,
    },
    {
      title: (
        <Fragment>
          <div className={classes.shortTitle}>
            <span>
              {t('systems.balance.captions.passengerTrafficFor')}
            </span>
            <WithUnit unit={t(UNITS.people)}>
              {t('systems.balance.captions.baseYear')}
            </WithUnit>
          </div>
          <span className={classes.titleUnit}>
            {t('systems.balance.captions.departure')}
            <span className={classes.spaceWrap}>/</span>
            {t('systems.balance.captions.arrival')}
          </span>
        </Fragment>

      ),
      className: classes.correspondenceColumn,

      model: (model: AcBalanceDataModel) => (
        <Fragment>
          <span>
            {isNil(model.baseOutTrafficSum) ?
              (
                <span className={classes.tooltipAnchor}>-</span>
              )
              :
              (
                <span className={classes.tooltipAnchor}>
                  <NumberFormat
                    fixedDecimalScale={false}
                    decimalScale={2}
                    value={model.baseOutTrafficSum}
                    locale={locale}
                  />
                </span>
              )}
          </span>

          <span className={classes.spaceWrap}>/</span>
          <span>
            {isNil(model.baseInTrafficSum) ?
              (
                <span className={classes.tooltipAnchor}>-</span>
              )
              :
              (
                <span className={classes.tooltipAnchor}>
                  <NumberFormat
                    fixedDecimalScale={false}
                    decimalScale={2}
                    value={model.baseInTrafficSum}
                    locale={locale}
                  />
                </span>
              )}
          </span>
        </Fragment>

      ),
    },
  ]),
  [locale, isEdit, deleted, data, created, nodesItems],
  );

  const middleHeader = useMemo(() => ([
    {
      title: (
        <Fragment>
          <div className={classes.shortTitle}>
            <span>
              {t('systems.balance.captions.predicated')}
            </span>
            <WithUnit unit={t(UNITS.people)}>
              {t('systems.balance.captions.passengerTraffic')}
            </WithUnit>
          </div>
          <span className={classes.titleUnit}>
            {t('systems.balance.captions.departure')}
            <span className={classes.spaceWrap}>/</span>
            {t('systems.balance.captions.arrival')}
          </span>
        </Fragment>

      ),
      className: classes.correspondenceColumn,

      model: (model: AcBalanceDataModel) => (
        <Fragment>
          <span>
            {isNil(model.forecastOutTrafficSum) ?
              (
                <span className={classes.tooltipAnchor}>-</span>
              )
              :
              (
                <span className={classes.tooltipAnchor}>
                  <NumberFormat
                    fixedDecimalScale={false}
                    decimalScale={2}
                    value={model.forecastOutTrafficSum}
                    locale={locale}
                  />
                </span>
              )}
          </span>

          <span className={classes.spaceWrap}>/</span>
          <span>
            {isNil(model.forecastInTrafficSum) ?
              (
                <span className={classes.tooltipAnchor}>-</span>
              )
              :
              (
                <span className={classes.tooltipAnchor}>
                  <NumberFormat
                    fixedDecimalScale={false}
                    decimalScale={2}
                    value={model.forecastInTrafficSum}
                    locale={locale}
                  />
                </span>
              )}
          </span>
        </Fragment>

      ),
    },
    ...columns.map((factor) => {
      const [title, unit] = factor.name.split(', ');
      return {
        title: (
          <span>
            {title}
            {unit && (
              <span className={classes.units}>
                &nbsp;
                (
                {unit}
                )
              </span>
            )}
          </span>
        ),
        model: (model: AcBalanceDataModel) => {
          const record = model.factors?.find((item) => item.id === factor.id);
          const value = record ? record.value : undefined;
          return (
            <NumberFormat
              value={value}
              locale={locale}
              decimalScale={0}
            />
          );
        },
      };
    }),
  ]),
  [locale, isEdit, deleted, data, created, nodesItems, columns],
  );

  const rightHeader: DataGridColumnProps<AcBalanceDataModel>[] = useMemo(() => ([
    {
      model: (model: AcBalanceDataModel) => (
        isEdit && (
          <span style={{ width: 60, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="icon"
              title={t('common.captions.delete')}
              className={classes.deleteButton}
              onClick={makeDeleteHandler(model)}
            >
              <CrossIcon />
            </Button>
            <Button
              variant="icon"
              title={t('common.captions.edit')}
              className={classes.deleteButton}
              onClick={makeEditHandler(model)}
            >
              <PenIcon />
            </Button>
          </span>
        )
      ),
    },
  ]),
  [locale, isEdit, deleted, data, created, nodesItems],
  );

  const body = useMemo(
    () => (isEdit ? (data.map((record: AcBalanceDataModel) => {
      const editedItem = edited.find(({ id }) => record.id === id);
      if (!editedItem) return record;
      return {
        ...record,
        ...editedItem,
      };
    })) : data

    ), [isEdit, data, created, edited]);

  return (
    <div className={classNames(classes.tableWrapper, isEdit && classes.edit)}>
      <div className={classes.leftTableWrapper}>
        <DataGrid<AcBalanceDataModel>
          headCellClassName={classes.headerCell}
          body={body}
          columns={leftHeader}
          cellClassName={classes.cell}
          rowClassName={(record) => (deleted.includes(record.id) ? classes.deletedRow : '')}
        />
      </div>
      <div className={classes.middleTableWrapper}>
        <DataGrid<AcBalanceDataModel>
          body={body}
          columns={middleHeader}
          headCellClassName={classes.headerCellFixed}
          cellClassName={classes.cell}
          rowClassName={(record) => (deleted.includes(record.id) ? classes.deletedRow : '')}
        />
      </div>
      {isEdit && (
        <div className={classes.rightTableWrapper}>
          <DataGrid<AcBalanceDataModel>
            rowClassName={(record) => (deleted.includes(record.id) ? classes.deletedRow : '')}
            headCellClassName={classes.headerCellAction}
            cellClassName={classes.actionCell}
            columns={rightHeader}
            body={body}
          />
        </div>
      )}
    </div>
  );
};

export default DataDataGrid;
