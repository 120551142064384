import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { DropDownItem } from 'src/components/deprecated/PSelect';
import {
  VideoCitiesModel,
  VideoVehicleTypesModel,
  VideoVehicleMakeModelsModel,
  VideoTransportCompaniesModel,
  VideoRouteNumbersModel,
  VideoRouteTripsModel,
  selectVideoCities,
  selectVideoVehicleTypes,
  selectVideoVehicleMakeModels,
  selectVideoTransportCompanies,
  selectVideoRouteNumbers,
  selectVideoRouteTrips,
} from 'src/modules/video/common';

export const useVideoCities = (): DropDownItem[] => {
  const [cities] = useSelector(selectVideoCities);

  return useMemo(() => cities.map((year: VideoCitiesModel) => ({
    value: year.name,
    label: year.name,
  })), [JSON.stringify(cities)]);
};

export const useVideoVehicleTypes = (): DropDownItem[] => {
  const [types] = useSelector(selectVideoVehicleTypes);

  return useMemo(() => types.map((year: VideoVehicleTypesModel) => ({
    value: year.id,
    label: year.name,
  })), [JSON.stringify(types)]);
};

export const useVideoVehicleMakeModels = (): DropDownItem[] => {
  const [makeModels] = useSelector(selectVideoVehicleMakeModels);

  return useMemo(() => makeModels.map((makeModel: VideoVehicleMakeModelsModel) => ({
    value: makeModel.id,
    label: makeModel.name,
  })), [JSON.stringify(makeModels)]);
};

export const useVideoTransportCompanies = (): DropDownItem[] => {
  const [transportCompanies] = useSelector(selectVideoTransportCompanies);

  return useMemo(() => transportCompanies.map((company: VideoTransportCompaniesModel) => ({
    value: company.id,
    label: company.name,
  })), [JSON.stringify(transportCompanies)]);
};

export const useVideoRouteNumbers = (): DropDownItem[] => {
  const [routeNumbers] = useSelector(selectVideoRouteNumbers);

  return useMemo(() => routeNumbers.map((route: VideoRouteNumbersModel) => ({
    value: route.id,
    label: route.name,
  })), [JSON.stringify(routeNumbers)]);
};

export const useVideoRouteTrips = (): DropDownItem[] => {
  const [routeTrips] = useSelector(selectVideoRouteTrips);

  return useMemo(() => routeTrips.map((trip: VideoRouteTripsModel) => ({
    value: trip.id,
    label: trip.description,
  })), [JSON.stringify(routeTrips)]);
};
