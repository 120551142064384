import { createSelector } from 'reselect';
import { api, forms } from 'redux-restify';

import {
  VIDEO_CITIES_MODAL_NAME,
  VIDEO_PAGE_FORM_NAME,
  VIDEO_ROUTE_TRIPS_MODEL_NAME,
  PageVideoForm,
  VideoCitiesModel,
  VideoVehicleOnRoutesModel,
  VIDEO_VEHICLE_ON_ROUTES_MODAL_NAME,
  VIDEO_VEHICLE_TYPES_MODAL_NAME,
  VIDEO_VEHICLE_MAKE_MODELS_MODAL_NAME,
  VIDEO_TRANSPORT_COMPANIES_MODAL_NAME,
  VIDEO_ROUTE_NUMBERS_MODAL_NAME,
  VideoVehicleMakeModelsModel,
  VideoTransportCompaniesModel,
  VideoRouteNumbersModel,
  VideoVehicleTypesModel,
  VideoRouteTripsModel,
  VIDEO_TRAFFIC_SUM_MODEL_NAME,
} from 'src/modules/video/common';

function convertDate(date: string) {
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}`;
}

export const selectVideoCities = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_CITIES_MODAL_NAME].getEntities<VideoCitiesModel>(state),
  ],
  (pageVideoForm, citiesEntities): [VideoCitiesModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }
    const citiesApiConfig = {
      filter: {},
    };
    return [
      citiesEntities.getArray(citiesApiConfig),
      citiesEntities.getIsLoadingArray(citiesApiConfig),
      citiesEntities.getCount(citiesApiConfig),
    ];
  },
);

export const selectVideoVehicleTypes = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_VEHICLE_TYPES_MODAL_NAME].getEntities<VideoVehicleTypesModel>(state),
  ],
  (pageVideoForm, vehicleTypesEntities): [VideoVehicleTypesModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }
    const vehicleTypesApiConfig = {
      filter: {},
    };
    return [
      vehicleTypesEntities.getArray(vehicleTypesApiConfig),
      vehicleTypesEntities.getIsLoadingArray(vehicleTypesApiConfig),
      vehicleTypesEntities.getCount(vehicleTypesApiConfig),
    ];
  },
);

export const selectVideoVehicleMakeModels = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_VEHICLE_MAKE_MODELS_MODAL_NAME]
      .getEntities<VideoVehicleMakeModelsModel>(state),
  ],
  (pageVideoForm, vehicleMakeModelEntities): [VideoVehicleMakeModelsModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }
    const { vehicleType } = pageVideoForm;

    const vehicleMakeModelsApiConfig = {
      filter: {
        vehicleType,
      },
    };
    return [
      vehicleMakeModelEntities.getArray(vehicleMakeModelsApiConfig),
      vehicleMakeModelEntities.getIsLoadingArray(vehicleMakeModelsApiConfig),
      vehicleMakeModelEntities.getCount(vehicleMakeModelsApiConfig),
    ];
  },
);

export const selectVideoTransportCompanies = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_TRANSPORT_COMPANIES_MODAL_NAME]
      .getEntities<VideoTransportCompaniesModel>(state),
  ],
  (pageVideoForm, transportCompanyEntities): [VideoTransportCompaniesModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }
    const { city, vehicleType } = pageVideoForm;

    const transportCompaniesApiConfig = {
      filter: {
        city,
        vehicleType,
      },
    };
    return [
      transportCompanyEntities.getArray(transportCompaniesApiConfig),
      transportCompanyEntities.getIsLoadingArray(transportCompaniesApiConfig),
      transportCompanyEntities.getCount(transportCompaniesApiConfig),
    ];
  },
);

export const selectVideoRouteNumbers = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_ROUTE_NUMBERS_MODAL_NAME]
      .getEntities<VideoRouteNumbersModel>(state),
  ],
  (pageVideoForm, routeNumberEntities): [VideoRouteNumbersModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }
    const { city, vehicleType } = pageVideoForm;

    const routeNumbersApiConfig = {
      filter: {
        city,
        vehicleType,
      },
    };
    return [
      routeNumberEntities.getArray(routeNumbersApiConfig),
      routeNumberEntities.getIsLoadingArray(routeNumbersApiConfig),
      routeNumberEntities.getCount(routeNumbersApiConfig),
    ];
  },
);

export const selectVideoRouteTrips = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_ROUTE_TRIPS_MODEL_NAME]
      .getEntities<VideoRouteTripsModel>(state),
  ],
  (pageVideoForm, routeTripEntities): [VideoRouteTripsModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }
    const { city, vehicleType, routeNumber } = pageVideoForm;

    const routeTripsApiConfig = {
      filter: {
        city,
        vehicleType,
        route: routeNumber,
      },
    };
    return [
      routeTripEntities.getArray(routeTripsApiConfig),
      routeTripEntities.getIsLoadingArray(routeTripsApiConfig),
      routeTripEntities.getCount(routeTripsApiConfig),
    ];
  },
);

export const selectVideoData = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_VEHICLE_ON_ROUTES_MODAL_NAME]
      .getEntities<VideoVehicleOnRoutesModel>(state),
  ],
  (pageVideoForm, dataEntities): [VideoVehicleOnRoutesModel[], boolean, number] => {
    if (!pageVideoForm.currentPage) {
      return [
        [],
        false,
        0,
      ];
    }

    const {
      city,
      vehicleType,
      vehicleMakeModel,
      transportCompany,
      routeNumber,
      routeTrip,
      dateAfter,
      dateBefore,
      timeAfter,
      timeBefore,
      weekdays,
      currentPage,
      pageSize,
    } = pageVideoForm;

    const hasDateFilter = dateAfter && dateBefore;
    const hasTimeFilter = timeAfter && timeBefore;

    const dataApiConfig = {
      filter: {
        city,
        vehicleType,
        makeModel: vehicleMakeModel,
        transportCompany,
        route: routeNumber,
        routeTrip,
        dateAfter: hasDateFilter ? convertDate(dateAfter) : undefined,
        dateBefore: hasDateFilter ? convertDate(dateBefore) : undefined,
        timeRouteStartAfter: hasTimeFilter ? timeAfter : undefined,
        timeRouteStartBefore: hasTimeFilter ? timeBefore : undefined,
        timeRouteEndAfter: hasTimeFilter ? timeAfter : undefined,
        timeRouteEndBefore: hasTimeFilter ? timeBefore : undefined,
        weekday: weekdays,
        page: currentPage,
        pageSize,
      },
    };
    return [
      dataEntities.getArray(dataApiConfig),
      dataEntities.getIsLoadingArray(dataApiConfig),
      dataEntities.getCount(dataApiConfig),
    ];
  },
);

export const selectVideoDataById = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_VEHICLE_ON_ROUTES_MODAL_NAME]
      .getEntities<VideoVehicleOnRoutesModel>(state),
  ],
  (videoForm, dataEntities) => {
    if (!videoForm.selectedRoute) {
      return undefined;
    }

    return dataEntities.getById(videoForm.selectedRoute, {});
  },
);

export const selectRouteTripById = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_ROUTE_TRIPS_MODEL_NAME].getEntities<VideoRouteTripsModel>(state),
  ],
  (videoForm, tripsEntities) => {
    if (!videoForm.selectedTrip) {
      return undefined;
    }

    return tripsEntities.getById(videoForm.selectedTrip, {});
  },
);

export const selectTotalTraffic = createSelector(
  [
    (state) => forms.selectors[VIDEO_PAGE_FORM_NAME].getFormWithNulls<PageVideoForm>(state),
    (state) => api.selectors.entityManager[VIDEO_TRAFFIC_SUM_MODEL_NAME].getEntities<any>(state),
  ],
  (pageVideoForm, trafficSumEntities): [any, boolean] => {
    if (!pageVideoForm.currentPage) {
      return [
        undefined,
        false,
      ];
    }

    const {
      city,
      vehicleType,
      vehicleMakeModel,
      transportCompany,
      routeNumber,
      routeTrip,
      dateAfter,
      dateBefore,
      timeAfter,
      timeBefore,
      weekdays,
    } = pageVideoForm;

    const hasDateFilter = dateAfter && dateBefore;
    const hasTimeFilter = timeAfter && timeBefore;

    const dataApiConfig = {
      query: {
        city,
        vehicleType,
        makeModel: vehicleMakeModel,
        transportCompany,
        route: routeNumber,
        routeTrip,
        dateAfter: hasDateFilter ? convertDate(dateAfter) : undefined,
        dateBefore: hasDateFilter ? convertDate(dateBefore) : undefined,
        timeRouteStartAfter: hasTimeFilter ? timeAfter : undefined,
        timeRouteStartBefore: hasTimeFilter ? timeBefore : undefined,
        timeRouteEndAfter: hasTimeFilter ? timeAfter : undefined,
        timeRouteEndBefore: hasTimeFilter ? timeBefore : undefined,
        weekday: weekdays,
      },
    };

    return [
      trafficSumEntities.getById('', dataApiConfig),
      trafficSumEntities.getIsLoadingById('', dataApiConfig),
    ];
  },
);
