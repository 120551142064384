export enum ViewModes {
  graph = 'graph',
  correspondences = 'correspondences',
}

export enum TrafficTypes {
  passenger = 'passenger',
  cargo = 'cargo',
}

export enum AgglomerationEdgeTypes {
  auto = 'auto',
  car = 'car',
  tat = 'tat',
  metro = 'metro',
  express = 'express',
  suburban = 'suburban',
  cargo = 'cargo',
  taxi = 'taxi',
  pedestrian = 'pedestrian',
}

export enum EdgeTypes {
  pedestrian = 'pedestrian',
  car = 'car',
  tat = 'tat',
  metro = 'metro',
  suburban = 'suburban',
  express = 'express',
  taxi = 'taxi',
  cargo = 'cargo',
}

export enum ExportTypes {
  origin = 'ORIGIN',
  graph = 'GRAPH',
  fullResult = 'FULLRESULT',
  trafficSum = 'TRAFFICSUM',
  timeSum = 'TIMESUM',
}
