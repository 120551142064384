import { selectTtcPageForm, selectTtcScenarios } from 'src/cluster/balance-ttc';
import { ProjectModel, SelectMenuOption } from 'src/interfaces';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useTtcScenarioOptions = (): SelectMenuOption[] => {
  const scenarios = useSelector(selectTtcScenarios);

  return scenarios.map((value) => value.map((scenario) => ({
    id: `${scenario.id}`,
    value: scenario.id,
    label: scenario.name,
  }))).orJust([]);
};

export const useExportStatusUpdate = <T extends ProjectModel>(
  exportData: () => void,
  queryInterval = 10000,
) => {
  const { exportUrl } = useSelector(selectTtcPageForm);
  useEffect(() => {
    const timer = setInterval(() => {
      if (!exportUrl) {
        clearInterval(timer);
      } else {
        exportData();
      }
    }, queryInterval);

    return () => clearInterval(timer);
  }, [exportUrl]);
};
