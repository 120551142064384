import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'src/auth';
import { withRestify } from 'src/helpers';
import { withTranslate } from 'src/i18n';
import PageWithHeader from './PageWithHeader';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(
  withRestify([], ['authInfoForm'])(
    withTranslate(PageWithHeader),
  ),
);
