import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import palette from 'src/theme/palette';

export const ffOxygen = ['Oxygen', 'SourceSansPro', 'sans-serif'].join(', ');
export const ffSourceSansPro = ['SourceSansPro', 'sans-serif'].join(', ');

const typography: TypographyOptions = {
  fontSize: 14,
  fontFamily: ffSourceSansPro,
  allVariants: {
    color: palette.text.primary,
  },
  body1: {
    fontSize: 14,
  },
  h5: {
    fontFamily: ffOxygen,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 1.5,
  },
  h6: {
    fontFamily: ffOxygen,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 1.25,
  },
  subtitle1: {
    fontWeight: 'bold',
    fontSize: 14,
  },
};

export default typography;
