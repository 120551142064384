import { GeojsonResponse } from 'src/types';
import { MultiLineString, Point } from 'src/interfaces';
import { ThunkActionResult } from 'src/reducer';
import { isNumber } from 'src/helpers';

import {
  EDGES_GEOJSON_ENDPOINT,
  NODES_GEOJSON_ENDPOINT,
  AC_PROJECTS_ENDPOINT,
  fetchGeojson,
  geojsonActions,
  selectAcProjectListForm,
} from 'src/cluster/common';
import { selectEditorGraphForm } from 'src/cluster/editor-common';

export function fetchNodes(id?: number): ThunkActionResult<GeojsonResponse<Point>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const { scenarioId, year } = selectEditorGraphForm(getState());
    if (!isNumber(selectedProject) || !isNumber(scenarioId) || !isNumber(year)) {
      return Promise.reject(new TypeError('Missing required request parameters!'));
    }

    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${NODES_GEOJSON_ENDPOINT}`;

    const query = {
      scenarioId,
      year,
      id,
    };

    return dispatch(fetchGeojson<Point>(url, query));
  };
}

export function loadNodes(): ThunkActionResult<void> {
  return async (dispatch) => {
    try {
      const nodes = await dispatch(fetchNodes());
      dispatch(geojsonActions.changeField('nodes', nodes));
    } catch (err) {
      console.error(err);
      dispatch(geojsonActions.resetField('nodes'));
    }
  };
}

export function fetchEdges(id?: number | number[]): ThunkActionResult<GeojsonResponse<MultiLineString>> {
  return async (dispatch, getState) => {
    const { selectedProject } = selectAcProjectListForm(getState());
    const { scenarioId, year, intervalId } = selectEditorGraphForm(getState());

    if (!isNumber(selectedProject) || !isNumber(scenarioId) || !isNumber(year) || !isNumber(intervalId)) {
      return Promise.reject(new TypeError('Missing required request parameters!'));
    }

    const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${EDGES_GEOJSON_ENDPOINT}`;

    const query = {
      scenarioId,
      year,
      intervalId,
      id,
    };

    return dispatch(fetchGeojson<MultiLineString>(url, query));
  };
}

export function loadEdges(id?: number): ThunkActionResult<void> {
  return async (dispatch) => {
    try {
      const edges = await dispatch(fetchEdges(id));
      dispatch(geojsonActions.changeField('edges', edges));
    } catch (err) {
      console.error(err);
      dispatch(geojsonActions.resetField('edges'));
    }
  };
}
