import React, { FC, Fragment, useMemo } from 'react';
import classNames from 'classnames';
import { RestifyFormActions, RestifyModel } from 'redux-restify';

import PSelect from 'src/components/deprecated/PSelect';
import { NodeModel, RouteModel } from 'src/modules/general/common';

import { WithTranslate } from 'src/i18n';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import { usePreloaderTimer } from 'src/helpers';
import { PageCalculationsRoutesForm, RouteEdgeModel } from '../../interfaces';
import {
  VIEW_MODE_ITEMS,
  AGGLOMERATION_EDGE_TYPES,
  TRANSPORT_TYPE_ITEMS,
  VIEW_MODES,
} from '../../constants';

import AggloMapFilterCorrespondences from './components/AggloMapFilterCorrespondences';
import AggloMapFilterTransportTypes from './components/AggloMapFilterTransportTypes';
import AggloMapFilterEdges from './components/AggloMapFilterEdges';
import RegionInfo from './components/AggloMapFilterDistrict';
import { useHandlers } from './hooks';

import style from './index.module.css';

function getSelectedRegion(form: PageCalculationsRoutesForm, routes: RouteModel[], nodes: NodeModel[]) {
  if (form.selectedRegion !== undefined) {
    const selectedNode = nodes.find((node) => form.selectedRegion === node.name);
    return selectedNode?.region;
  }
  if (routes[0] === undefined) {
    return undefined;
  }
  if (form.isStartSelected) {
    return routes[0].startRegion;
  }
  if (form.isFinishSelected) {
    return routes[0].finishRegion;
  }
  return undefined;
}

export interface Props {
  className?: string;
  nodes: NodeModel[];
  routes: RouteModel[];
  selectedEdges?: RestifyModel<RouteEdgeModel>[];
  selectedEdgesIsLoading?: boolean;
  form: PageCalculationsRoutesForm;
  formActions: RestifyFormActions<PageCalculationsRoutesForm, PageCalculationsRoutesForm>;
  showFilter?: boolean;
  isLoading?: boolean;
  isSourceLoading?: boolean;
  cancelSourceLoading: () => void;
}

const AggloMapFilter: FC<Props & WithTranslate> = (props: Props & WithTranslate) => {
  const {
    className,
    nodes,
    routes,
    selectedEdges,
    selectedEdgesIsLoading = false,
    form,
    formActions,
    showFilter = false,
    isLoading = false,
    isSourceLoading = false,
    cancelSourceLoading,
    t,
  } = props;
  const { transportType, viewMode } = form;
  const isGraph = viewMode === VIEW_MODES.graph;
  const {
    handleViewModeChange,
    handleTransportTypeChange,
  } = useHandlers(formActions);

  const viewModesItems = useMemo(() => Object.values(VIEW_MODES).map(mode => ({
    value: mode,
    label: t(VIEW_MODE_ITEMS[mode]),
  })), []);

  const transportTypesItems = useMemo(() => Object.keys(TRANSPORT_TYPE_ITEMS)
    .filter((key) => key !== AGGLOMERATION_EDGE_TYPES.car
      && key !== AGGLOMERATION_EDGE_TYPES.cargo
      && key !== AGGLOMERATION_EDGE_TYPES.taxi)
    .map((key) => ({
      value: key,
      label: t(TRANSPORT_TYPE_ITEMS[key]),
    })), []);

  const selectedRegion = getSelectedRegion(form, routes, nodes);

  const showEdges = !!selectedEdges?.length || selectedEdgesIsLoading;

  const rootClassName = classNames(
    style.root,
    { [style.show]: showFilter || showEdges || selectedRegion },
    className,
  );

  const isLoadingByTime = usePreloaderTimer(isSourceLoading);

  const isOnlySourceLoading = isLoadingByTime && !isLoading;

  return (
    <LoadingBlockContainer {...{
      className: rootClassName,
      isBlocked: isLoadingByTime,
      caption: isOnlySourceLoading ? t('common.captions.cancel') : undefined,
      onClick: isOnlySourceLoading ? cancelSourceLoading : undefined,
    }}>
      <div>
        {showFilter && (
          <Fragment>
            <div className={style.toolbarSection}>
              <PSelect {...{
                label: t('systems.mth.captions.viewMode'),
                className: style.select,
                items: viewModesItems,
                values: form.viewMode ? [form.viewMode] : undefined,
                disabled: isLoading,
                onChange: handleViewModeChange,
              }} />
            </div>
            {form.viewMode === VIEW_MODES.correspondences
              ? (
                <AggloMapFilterCorrespondences {...{
                  className: style.toolbarSection,
                  nodes,
                  form,
                  formActions,
                }} />
              ) : (
                <div className={style.toolbarSection}>
                  <PSelect {...{
                    label: t('systems.mth.captions.transportType'),
                    className: style.select,
                    items: transportTypesItems,
                    values: form.transportType === undefined ? undefined : [form.transportType],
                    disabled: isLoading,
                    onChange: handleTransportTypeChange,
                  }} />
                </div>
              )}
            {form.viewMode === VIEW_MODES.correspondences && (
              <AggloMapFilterTransportTypes {...{
                className: style.toolbarSection,
                form,
                formActions,
                disabled: isLoading,
              }} />
            )}
          </Fragment>
        )}
        {showEdges && (
          <AggloMapFilterEdges {...{
            className: style.toolbarSection,
            edges: selectedEdges,
            isLoading: selectedEdgesIsLoading,
            transportType,
            isGraph,
            showCorrespondences: showFilter,
          }} />
        )}
        {selectedRegion && (
          <RegionInfo {...{
            className: style.toolbarSection,
            region: selectedRegion,
          }} />
        )}
      </div>
    </LoadingBlockContainer>
  );
};

export default AggloMapFilter;
