import { SelectMenuOption } from 'src/interfaces';

import useDistributionMapRouteVariants from './useDistributionMapRouteVariants';

const useDistributionMapRouteVariantsOptions = (): SelectMenuOption[] => {
  const variants = useDistributionMapRouteVariants();
  return variants.map((variant) => ({
    id: `${variant.id}`,
    value: variant.id,
    label: variant.variantNumber,
  }));
};

export default useDistributionMapRouteVariantsOptions;
