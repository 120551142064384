import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import AggloRoutePage from './AggloRoutePage';
import { resetActualFilters } from '../../actions';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetActualParams: () => dispatch(resetActualFilters()),
});

export default connect(null, mapDispatchToProps)(AggloRoutePage);
