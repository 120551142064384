import React from 'react';
import classNames from 'classnames';
import { RestifyFormActions } from 'redux-restify';

import { WithTranslate } from 'src/i18n';
import { PageCalculationsRoutesForm } from 'src/modules/mth/common/interfaces';
import { useSystemsContext } from 'src/constants';
import PIcon from 'src/components/deprecated/PIcon';
import { TRANSPORT_TYPES, ICON_TYPES } from 'src/modules/mth/common/constants';
import { useHandlers } from './hooks';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  disabled: boolean;
  form: PageCalculationsRoutesForm;
  formActions: RestifyFormActions<PageCalculationsRoutesForm, PageCalculationsRoutesForm>;
}

const AggloMapFilterTransportTypes: React.FC<Props> = (props: Props) => {
  const {
    className,
    disabled,
    form,
    formActions,
    t,
  } = props;
  const currentSystem = useSystemsContext();
  const { makeHandleClick } = useHandlers(currentSystem, form, formActions);

  const ACTUAL_TRANSPORT_TYPES = Object.keys(ICON_TYPES).filter((type) => type !== TRANSPORT_TYPES.pedestrian);

  return (
    <div>
      <div className={style.label}>
        {t('systems.mth.captions.usedTransportMeans')}
      </div>
      <div className={classNames(style.root, className)}>
        {
          ACTUAL_TRANSPORT_TYPES.map((type) => {
            const buttonClassName = classNames(style.button, {
              [style.active]: form.showTransportTypes && form.showTransportTypes[type],
            });
            return (
              <button {...{
                type: 'button',
                className: buttonClassName,
                key: type,
                disabled,
                onClick: makeHandleClick(type),
              }}>
                <PIcon {...{
                  className: style.icon,
                  type: ICON_TYPES[type],
                }} />
              </button>
            );
          })
        }
      </div>
    </div>
  );
};

export default AggloMapFilterTransportTypes;
