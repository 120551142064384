import { Just, Maybe, Nothing } from 'monet';
import { createSelector } from 'reselect';

import { Model } from 'src/interfaces';
import {
  createEntitySelector,
  createFormSelector,
  isArray,
  isNumber,
} from 'src/helpers';

import {
  AC_PROJECTS_MODEL_NAME,
  AC_SCENARIOS_MODEL_NAME,
  AC_DASH_CATEGORIES_MODEL_NAME,
  AC_DASH_INDICATOR_RESULT_MODEL_NAME as INDICATOR_RESULT_MODEL_NAME,
  AcScenariosModel,
  AcDashGraphForm,
  isAcScenariosModel,
  selectAcYears,
  selectAcBaseScenario,
  selectAcProjectListForm,
} from 'src/cluster/common';
import {
  AC_DASH_GRAPH_FORM_NAME,
  AcDashCategoriesModel,
  AcDashIndicatorResultsModel as IndicatorResultsModel, isAcDashCategoriesModel,
} from 'src/cluster/dashboard-common';

export const selectDashGraphForm = createFormSelector<AcDashGraphForm>(AC_DASH_GRAPH_FORM_NAME);
export const selectScenarioEntities = createEntitySelector<AcScenariosModel>(AC_SCENARIOS_MODEL_NAME);
export const selectDashCategoriesEntities = createEntitySelector<AcDashCategoriesModel>(AC_DASH_CATEGORIES_MODEL_NAME);
export const selectIndicatorResultsEntities = createEntitySelector<IndicatorResultsModel>(INDICATOR_RESULT_MODEL_NAME);

export const selectDashScenarios = createSelector(
  [
    selectAcProjectListForm,
    selectDashGraphForm,
    selectScenarioEntities,
  ],
  (projectsListForm, dashGraphForm, scenarioEntities): Maybe<Model<AcScenariosModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year } = dashGraphForm;

    if (!isNumber(selectedProject) || !isNumber(year)) {
      return Nothing();
    }

    const scenariosApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
      },
    };

    const scenarios = scenarioEntities.getArray(scenariosApiConfig);

    if (!isArray(scenarios)) {
      return Nothing();
    }

    if (!isAcScenariosModel(scenarios[0])) {
      return Nothing();
    }

    return Just(scenarios);
  },
);

export const selectDashCategories = createSelector(
  selectDashCategoriesEntities,
  (categoriesTreeEntities): Maybe<Model<AcDashCategoriesModel>[]> => {
    const categoriesApiConfig = {};

    const categories = categoriesTreeEntities.getArray(categoriesApiConfig);

    if (!isArray(categories)) {
      return Nothing();
    }

    if (!isAcDashCategoriesModel(categories[0])) {
      return Nothing();
    }

    return Just(categories);
  },
);

export const selectAcDashBaseYear = createSelector(
  selectAcYears,
  (maybeYears): number | undefined => {
    return maybeYears.map((years) => years.map((year) => year.value).sort()).orJust([])[0];
  },
);

export const selectBaseIndicatorResults = createSelector(
  [
    selectAcProjectListForm,
    selectAcBaseScenario,
    selectDashGraphForm,
    selectIndicatorResultsEntities,
  ],
  (
    projectsListForm,
    maybeBaseScenario,
    dashGraphForm,
    indicatorResultsEntities,
  ): Maybe<Model<IndicatorResultsModel>[]> => {
    return maybeBaseScenario.flatMap(
      (baseScenario) => {
        const { selectedProject } = projectsListForm;

        if (!isNumber(selectedProject)) {
          return Nothing();
        }

        const indicatorResultsApiConfig = {
          parentEntities: {
            [AC_PROJECTS_MODEL_NAME]: selectedProject,
          },
          filter: {
            year: baseScenario.year,
            scenarioId: baseScenario.scenarioId,
          },
        };

        const indicatorResults = indicatorResultsEntities.getArray(indicatorResultsApiConfig);

        if (!isArray(indicatorResults)) {
          return Nothing();
        }

        return Just(indicatorResults);
      });
  },
);

export const selectForecastIndicatorResults = createSelector(
  [
    selectAcProjectListForm,
    selectDashGraphForm,
    selectIndicatorResultsEntities,
  ],
  (projectsListForm, dashGraphForm, indicatorResultsEntities): Maybe<Model<IndicatorResultsModel>[]> => {
    const { selectedProject } = projectsListForm;
    const { year, scenarioId } = dashGraphForm;

    if (!isNumber(selectedProject) || !isNumber(year) || !isNumber(scenarioId)) {
      return Nothing();
    }

    const indicatorResultsApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: selectedProject,
      },
      filter: {
        year,
        scenarioId,
      },
    };

    const indicatorResults = indicatorResultsEntities.getArray(indicatorResultsApiConfig);

    if (!isArray(indicatorResults)) {
      return Nothing();
    }

    return Just(indicatorResults);
  },
);
