/* eslint-disable max-len */
import React from 'react';

const Clock = () => {
  return (
    <svg viewBox="0 0 510 510">
      <g>
        <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z     M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z" />
        <polygon points="267.75,127.5 229.5,127.5 229.5,280.5 362.1,362.1 382.5,328.95 267.75,260.1   " />
      </g>
    </svg>
  );
};

export default Clock;
