import React, { FC, useEffect, useRef } from 'react';

import { Nullable, ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { Model } from 'src/interfaces';
import { DataProcessingRecords } from 'src/components';
import {
  MatrixForecastModel,
  useMatrixForecastStatusUpdate,
} from 'src/cluster/common';
import { isNumber } from 'src/helpers';

interface Props extends WithTranslate {
  matrixForecastEntities: [Nullable<Model<MatrixForecastModel>>, boolean];
  loadMatrixForecast(): void;
}

const RouterDistributionProgress: FC<Props> = (props) => {
  const {
    matrixForecastEntities: [matrixForecast, isLoading],
    t,
    loadMatrixForecast,
  } = props;

  const { progress, status } = matrixForecast?.routesDistribution || {};

  useMatrixForecastStatusUpdate(
    loadMatrixForecast,
    isLoading && status === ProcessStatus.SUCCESS,
    (matrixForecast?.routesDistribution || {}) as MatrixForecastModel,
    3000,
  );

  const percent = isNumber(progress)
    ? Math.round(progress * 10000) / 100
    : undefined;

  let message;
  let showPercent = true;

  if (status === ProcessStatus.QUEUED) {
    showPercent = false;
    message = t('systems.balance.messages.calculationStart');
  } else if (status === ProcessStatus.ERROR) {
    message = 'Ошибка расчета, попробуйте снова!';
    showPercent = false;
  }

  if (status === null) return null;

  return (
    <DataProcessingRecords
      percent={percent}
      message={message}
      showPercent={showPercent}
    />
  );
};

export default RouterDistributionProgress;
