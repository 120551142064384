import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import app from 'src/app';
import i18n, { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import modals from 'src/modals';
import {
  selectPageForm,
  selectAcPageProjectForm,
  selectBalanceTtc,
  selectBalanceTtcCount,
  AC_TTC_EDIT_MODAL_NAME,
  balanceTtcFormActions,
  updateMany,
  deleteMany,
  createMany,
  exportTtcData,
} from 'src/cluster/ttc';

import { projectLoadsManager } from 'src/cluster/projects';

import { AcBalanceTtcModel } from 'src/cluster/common';
import BalanceTtcPage from './BalanceTtcPage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectPageForm(state),
  projectForm: selectAcPageProjectForm(state),
  ttcEntities: selectBalanceTtc(state),
  count: selectBalanceTtcCount(state),
  isLoading: projectLoadsManager.selectIsDownloadingUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  exportTtcData: (id: number) => dispatch(exportTtcData(id)),
  updateMany: (ttcList: AcBalanceTtcModel[]) => dispatch(updateMany(ttcList)),
  deleteMany: (ids: number[]) => dispatch(deleteMany(ids)),
  createMany: (ttcList: AcBalanceTtcModel[]) => dispatch(createMany(ttcList)),
  switchToList: () => dispatch(balanceTtcFormActions.changeField('isEdit', false)),
  warningNotify: () => dispatch(app.actions.toast.warning(i18n.t('modules.ttc.messages.warning'))),
  successNotify: () => dispatch(app.actions.toast.success(i18n.t('modules.ttc.messages.success'))),
  onPageChange: (value: number) => {
    dispatch(balanceTtcFormActions.changeField('currentPage', value));
  },
  onPageSizeChange: (value: number) => {
    dispatch(balanceTtcFormActions.changeField('pageSize', value));
    dispatch(balanceTtcFormActions.resetField('currentPage'));
  },
  showEditModal: (record: AcBalanceTtcModel, onEdit: Function) => {
    dispatch(modals.actions.showModal(true, AC_TTC_EDIT_MODAL_NAME, { record, onEdit }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(BalanceTtcPage));
