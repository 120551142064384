import { createSelector } from 'reselect';
import { forms } from 'redux-restify';

import { AppState } from 'src/reducer';
import { PageCalculationsRoutesForm } from 'src/modules/mth/common/interfaces';

import { ROUTES_PAGES_FORM_NAME } from '../../calculations/constants';

export const selectCurrentTransportType = createSelector(
  (state: AppState) => (
    forms.selectors[ROUTES_PAGES_FORM_NAME].getFormWithNulls<PageCalculationsRoutesForm>(state)
  ),
  (pageRoutesForm) => pageRoutesForm.transportType,
);
