import React from 'react';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  className?: string;
  required?: boolean;
  label: React.ReactNode;
}

const PLabel: React.FC<Props> = ({
  className,
  required = false,
  label,
}) => {
  return (
    <span className={classNames(style.root, className)}>
      {label}
      {required && ' *'}
    </span>
  );
};

export default PLabel;
