import React from 'react';

const PenIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.41189 11.761L1.27913 11.6285L1.24313 11.8126L0.701665 14.5807L0.673131 14.7266L0.819002 14.6981L3.58715 14.1566L3.77118 14.1206L3.63876 13.9878L2.52687 12.873L2.52668 12.8729L1.41189 11.761ZM14.1734 1.22673L14.173 1.22639C13.8343 0.889292 13.3759 0.700049 12.898 0.700049C12.4201 0.700049 11.9616 0.889292 11.6229 1.22639L11.6227 1.22656L11.087 1.76224L11.0163 1.83295L11.087 1.90366L12.2916 3.10821L13.4961 4.31276L13.5668 4.38348L13.6375 4.31276L14.1732 3.77709L14.1734 3.77692C14.5105 3.4382 14.6998 2.97974 14.6998 2.50182C14.6998 2.02391 14.5105 1.56545 14.1734 1.22673ZM2.48673 12.7075L2.55743 12.7782L2.62814 12.7075L12.126 3.21258L12.1553 3.18329V3.14186V3.13896V3.09746L12.1259 3.06816L11.0227 1.96785L10.952 1.89732L10.8813 1.96795L10.1574 2.69201L10.227 2.76156L10.1574 2.69201L1.3862 11.4658L1.3155 11.5365L1.38622 11.6072L2.48673 12.7075ZM2.69232 12.7716L2.62159 12.8423L2.69231 12.9131L3.79262 14.0134L3.86332 14.0841L3.93403 14.0134L12.708 5.24251L12.708 5.2425L13.4319 4.51861L13.4612 4.48932V4.4479V4.445V4.40351L13.4318 4.3742L12.3315 3.27678L12.2608 3.20627L12.1902 3.27687L2.69232 12.7716Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

export default PenIcon;
