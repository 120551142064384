import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { selectCurrentProjectNodes } from 'src/cluster/common';
import { selectBalanceMapData, selectPolygonsGeojsonEndpoint } from 'src/cluster/balance-map';

import BalanceMap from './BalanceMap';

const mapStateToProps = (state: AppState) => ({
  nodesEntities: selectCurrentProjectNodes(state),
  dataEntities: selectBalanceMapData(state),
  polygonsUrl: selectPolygonsGeojsonEndpoint(state),
});

export default connect(mapStateToProps)(withTranslate(BalanceMap));
