import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import PButton from 'src/components/deprecated/PButton';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import style from './index.module.css';

type MultiValueInputValues = (string | number)[]

interface Props extends WithTranslate {
  className?: string;
  values: MultiValueInputValues;
  renderValue?: (value: MultiValueInputValues[0]) => React.ReactNode;
  onChange: (values: MultiValueInputValues) => void;
}

const MultiValueInput: FC<Props> = (props) => {
  const {
    className,
    children,
    values,
    onChange,
    renderValue = (v) => v,
    t,
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      {Array.isArray(values) && !!values.length && (
        <PButton
          className={style.clearButton}
          type="link"
          onClick={() => onChange([])}
        >
          {t('systems.matrix.captions.clear')}
        </PButton>
      )}
      <div className={style.childrenContainer}>
        {children}
      </div>
      <div className={style.values}>
        {Array.isArray(values) && values.map((value, index) => (
          <div key={index} className={style.value}>
            {renderValue(value)}
            <PIcon
              type={ICONS_TYPES.clear}
              className={style.clearIcon}
              onClick={() => onChange(values.filter((v) => v !== value))}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiValueInput;
