import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { selectIsAuthenticated } from 'src/auth';
import { getPermissions } from 'src/auth/actions';

import Root from './Root';

const mapStateToProps = (state: any) => ({
  isAuthenticated: selectIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getPermissions: () => dispatch(getPermissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
