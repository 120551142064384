import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RestifyFormActions } from 'redux-restify';
import { uniq, isEqual } from 'lodash';
import { Feature } from 'src/interfaces';

import modals from 'src/modals';
import { ThunkActionResult } from 'src/reducer';
import { PageAgglomerationForm } from 'src/modules/mth/common';

import { EDGE_ROUTES_MODAL, VIEW_MODES } from '../constants';

export const showEdgeRoutes = (edgeId: number, transportType: string): ThunkActionResult<void> => (dispatch) => {
  dispatch(modals.actions.showModal(true, EDGE_ROUTES_MODAL, { transportType, edgeId }));
};

export const selectGeoJsonFeature = (
  features: Feature[],
  form: PageAgglomerationForm,
  formActions: RestifyFormActions<PageAgglomerationForm, PageAgglomerationForm>,
): ThunkActionResult<void> => (dispatch: ThunkDispatch<any, null, Action<string>>) => {
  const showGraph = form.viewMode === VIEW_MODES.graph;
  const correspondenceFeature = features.find(f => f.properties?.isCorrespondence);
  const pointFeature = features.find(f => f.properties?.isPoint);
  const edgeFeatures = features.filter(f => f.properties?.isEdge);
  const regionFeature = features.find(f => f.properties?.isRegion);

  function selectCorrespondencePoint(correspondence: Feature) {
    if (!showGraph) {
      dispatch(formActions.resetField('selectedEdges'));
      dispatch(formActions.resetField('selectedRegion'));
      if (correspondence.properties?.isStart) {
        dispatch(formActions.changeField('isFinishSelected', false));
        dispatch(formActions.changeField('isStartSelected', !form.isStartSelected));
      }
      if (correspondence.properties?.isFinish) {
        dispatch(formActions.changeField('isStartSelected', false));
        dispatch(formActions.changeField('isFinishSelected', !form.isFinishSelected));
      }
    }
  }

  function selectEdges(edges: Feature[]) {
    if (!showGraph && edges[0]?.properties) {
      dispatch(formActions.changeField('selectedRoute', edges[0]?.properties.routeId));
    }
    dispatch(formActions.resetField('selectedRegion'));
    dispatch(formActions.changeField('isStartSelected', false));
    dispatch(formActions.changeField('isFinishSelected', false));
    const newSelectedEdges = uniq(edges.map((edge) => edge?.properties?.edgeId));
    if (form.selectedEdges && isEqual(form.selectedEdges, newSelectedEdges)) {
      dispatch(formActions.resetField('selectedEdges'));
    } else {
      dispatch(formActions.changeField('selectedEdges', newSelectedEdges));
    }
  }

  function selectRegion(region: Feature) {
    if (!showGraph) {
      dispatch(formActions.resetField('selectedEdges'));
      dispatch(formActions.resetField('selectedRegion'));
      if (region.properties?.isStart) {
        dispatch(formActions.changeField('isFinishSelected', false));
        dispatch(formActions.changeField('isStartSelected', !form.isStartSelected));
      }
      if (region.properties?.isFinish) {
        dispatch(formActions.changeField('isStartSelected', false));
        dispatch(formActions.changeField('isFinishSelected', !form.isFinishSelected));
      }
    } else if (form.selectedRegion) {
      if (region.properties?.name === form.selectedRegion) {
        dispatch(formActions.resetField('selectedRegion'));
      } else {
        dispatch(formActions.changeField('selectedRegion', region.properties?.name));
      }
    } else {
      dispatch(formActions.resetField('selectedEdges'));
      dispatch(formActions.changeField('selectedRegion', region.properties?.name));
    }
  }

  function resetAllSelections() {
    dispatch(formActions.resetField('selectedEdges'));
    dispatch(formActions.resetField('selectedRegion'));
    dispatch(formActions.changeField('isStartSelected', false));
    dispatch(formActions.changeField('isFinishSelected', false));
  }

  if (correspondenceFeature) {
    selectCorrespondencePoint(correspondenceFeature);
  } else if (pointFeature) {
    selectRegion(pointFeature);
  } else if (edgeFeatures.length > 0) {
    selectEdges(edgeFeatures);
  } else if (regionFeature) {
    selectRegion(regionFeature);
  } else {
    resetAllSelections();
  }
};
