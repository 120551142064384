import React from 'react';

const EditPencilIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.41185 11.7611L1.27909 11.6286L1.24309 11.8127L0.70163 14.5808L0.673096 14.7267L0.818967 14.6982L3.58711 14.1567L3.77114 14.1207L3.63872 13.9879L2.52683 12.8731L2.52664 12.873L1.41185 11.7611ZM14.1734 1.22688L14.173 1.22654C13.8343 0.889438 13.3759 0.700195 12.898 0.700195C12.4201 0.700195 11.9616 0.889438 11.6229 1.22654L11.6227 1.22671L11.087 1.76239L11.0163 1.8331L11.087 1.90381L12.2916 3.10836L13.4961 4.31291L13.5668 4.38363L13.6375 4.31291L14.1732 3.77724L14.1734 3.77707C14.5105 3.43835 14.6998 2.97989 14.6998 2.50197C14.6998 2.02406 14.5105 1.5656 14.1734 1.22688ZM2.48669 12.7076L2.55739 12.7783L2.6281 12.7076L12.126 3.21273L12.1553 3.18344V3.14201V3.13911V3.09761L12.1259 3.06831L11.0227 1.968L10.952 1.89747L10.8813 1.9681L10.1574 2.69216L10.227 2.76171L10.1574 2.69216L1.38616 11.4659L1.31546 11.5366L1.38618 11.6073L2.48669 12.7076ZM2.69228 12.7717L2.62155 12.8424L2.69227 12.9132L3.79258 14.0135L3.86328 14.0842L3.93399 14.0135L12.708 5.24266V5.24265L13.4319 4.51876L13.4612 4.48947V4.44805V4.44515V4.40366L13.4318 4.37435L12.3315 3.27693L12.2608 3.20642L12.1902 3.27702L2.69228 12.7717Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>

);

export default EditPencilIcon;
