import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as selectors from './selectors';

import { registeredModals } from './constants';
import ModalWrapper from './components/ModalWrapper';
import ConfirmModal from './components/ConfirmModal';

const context = require.context('../', true, /.*\/modals\/[A-z0-9-_]*\.modal\.(j|t)s/);

interface Props {
  openByNameMap: any;
  paramsByNameMap: any;
  modalsOrderByNameMap: any;
  confirmModalOpen: boolean;
  confirmModalProps: any;
  [other: string]: any;
}

class ModalsComp extends Component<Props> {
  private registeredModalsCount = 0;

  public shouldComponentUpdate(prevProps: Props) {
    return prevProps.openByNameMap !== this.props.openByNameMap ||
      prevProps.paramsByNameMap !== this.props.paramsByNameMap ||
      this.registeredModalsCount !== registeredModals.length;
  }

  public render() {
    const {
      openByNameMap,
      paramsByNameMap,
      modalsOrderByNameMap,
      confirmModalOpen = false,
      confirmModalProps = {},
      ...other
    } = this.props;

    this.registeredModalsCount = registeredModals.length;

    return (
      <span>
        {context.keys().concat(registeredModals).map((key, index) => {
          let ModalComp = key;
          if (typeof ModalComp !== 'function') {
            ModalComp = context(key).default;
          }
          return (
            <ModalComp {...{
              key: typeof key === 'function' ? index : key,
              openByNameMap,
              paramsByNameMap,
              modalsOrderByNameMap,
              ...other,
            }} />
          );
        })}
        <ModalWrapper {...{
          order: 999999,
          show: confirmModalOpen,
          showClose: confirmModalProps.showClose,
          closeAction: () => {
            if (confirmModalProps.onAny) {
              confirmModalProps.onAny();
            }
            if (confirmModalProps.onDecline) {
              confirmModalProps.onDecline();
            }
          },
          size: confirmModalProps.size,
          ...other,
        }}>
          <ConfirmModal {...confirmModalProps} />
        </ModalWrapper>
      </span>
    );
  }
}

const stateToProps = (state: any, props: any) => {
  return ({
    ...props,
    openByNameMap: selectors.getOpenByNameMap(state),
    paramsByNameMap: selectors.getModalsPropsByNameMap(state),
    modalsOrderByNameMap: selectors.getModalsOrderByNameMap(state),
    confirmModalOpen: selectors.getConfirmModalOpen(state),
    confirmModalProps: selectors.getConfirmModal(state),
  });
};

export default connect(stateToProps)(ModalsComp);
