import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(6),
      position: 'fixed',
      right: 50,
      bottom: 100,
      borderRadius: '10px',
      filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1))',
    },
    pointer: {
      cursor: 'pointer',
    },
    title: {
      fontSize: 12,
      lineHeight: '15px',
      color: theme.palette.text.primary,
    },
    caption: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(3),
      color: theme.palette.text.primary,
    },
  });
});
