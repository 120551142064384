import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { AutocompleteOption, SelectMenuOption } from 'src/interfaces';
import { isNumber, isObject } from 'src/helpers';

import {
  startCopyingNetwork, startExportingNetwork, editorFormActions, entitiesEditorFormActions,
} from 'src/cluster/editor-common';
import { selectEditorPageForm, loadRoute } from 'src/cluster/editor-map';

import EditorFilters from './EditorFilters';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectEditorPageForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  startExport: () => {
    dispatch(startExportingNetwork());
  },
  startCopying: () => {
    dispatch(startCopyingNetwork());
  },
  setVehicleType: (option: AutocompleteOption) => {
    dispatch(editorFormActions.changeField('canBePositioned', true));
    dispatch(editorFormActions.resetSomeFields([
      'routeId',
      'directionId',
      'variantId',
      'nodeId',
      'stopId',
    ]));

    if (!isObject(option)) {
      dispatch(editorFormActions.resetField('vehicleTypeId'));
      return;
    }

    const { value } = option as SelectMenuOption || {};

    if (!isNumber(value)) {
      dispatch(editorFormActions.resetField('vehicleTypeId'));
      return;
    }

    dispatch(editorFormActions.changeField('vehicleTypeId', value));
  },
  setSelectedRoute: (option: AutocompleteOption) => {
    dispatch(editorFormActions.changeField('canBePositioned', true));
    dispatch(entitiesEditorFormActions.resetField('editableRoute'));
    dispatch(editorFormActions.resetSomeFields([
      'editorMode',
      'message',
      'directionId',
      'variantId',
      'nodeId',
      'stopId',
      'edgeId',
      'routeId',
    ]));

    if (!option) {
      return;
    }

    const { value } = option as SelectMenuOption || {};

    if (!isNumber(value)) {
      return;
    }

    dispatch(editorFormActions.changeField('routeId', value));
    dispatch(loadRoute(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(EditorFilters));
