import { ChangeEvent, useMemo } from 'react';

import { StrictDict } from 'src/types';
import { AutocompleteOption, SelectMenuOption, SelectValue } from 'src/interfaces';
import {
  AcRegRoutesResultsModel,
  AcRegistryFieldsConfig,
  useCommunicationTypesOptions,
  useTransportTypesOptions,
  useSeasonOptions,
} from 'src/cluster/editor-table';
import { useVehicleTypesOptions } from 'src/cluster/editor-map';

const useEditMainFieldsConfig = (
  routeFields: Partial<AcRegRoutesResultsModel>,
  onChangeField: (field: string, val: SelectValue) => void,
): StrictDict<string, AcRegistryFieldsConfig> => {
  const {
    vehicleTypeId,
    routeNumber,
    routeLongName,
    regularTransportationType,
    communicationType,
    season,
    carrier,
  } = routeFields;

  const vehicleTypeOptions = useVehicleTypesOptions();
  const transportTypesOptions = useTransportTypesOptions();
  const communicationTypeOptions = useCommunicationTypesOptions();
  const seasonOptions = useSeasonOptions();

  return useMemo(() => {
    const vehicleTypeValue = vehicleTypeOptions.find(opt => opt.value === vehicleTypeId);
    const regularTransportValue = transportTypesOptions.find(opt => opt.value === regularTransportationType);
    const communicationTypeValue = communicationTypeOptions.find(opt => opt.value === communicationType);
    const seasonValue = seasonOptions.find(opt => opt.value === season);

    return {
      vehicleType: {
        label: 'modules.registries.columns.vehicleTypeFull',
        multiple: false,
        options: vehicleTypeOptions,
        selected: vehicleTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField('vehicleTypeId', value);
        },
      },
      routeNumber: {
        label: 'modules.registries.columns.routeNumberFull',
        options: [],
        value: routeNumber,
        command: (event: ChangeEvent<{ value: string }>) => {
          const { value } = event.target;
          onChangeField('routeNumber', value);
        },
      },
      routeLongName: {
        label: 'modules.registries.columns.routeLongName',
        options: [],
        value: routeLongName,
        command: (event: ChangeEvent<{ value: string }>) => {
          const { value } = event.target;
          onChangeField('routeLongName', value);
        },
      },
      regularTransportationType: {
        label: 'modules.registries.columns.regularTransportationType',
        multiple: false,
        options: transportTypesOptions,
        selected: regularTransportValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField('regularTransportationType', value);
        },
      },
      communicationType: {
        label: 'modules.registries.columns.communicationType',
        multiple: false,
        options: communicationTypeOptions,
        selected: communicationTypeValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField('communicationType', value);
        },
      },
      season: {
        label: 'modules.registries.columns.season',
        multiple: false,
        options: seasonOptions,
        selected: seasonValue,
        command: (val: AutocompleteOption) => {
          const { value } = val as SelectMenuOption || {};
          onChangeField('season', value);
        },
      },
      carrier: {
        label: 'modules.registries.columns.carrier',
        options: [],
        value: carrier,
        command: (event: ChangeEvent<{ value: string }>) => {
          const { value } = event.target;
          onChangeField('carrier', value);
        },
      },
    };
  }, [routeFields]);
};

export default useEditMainFieldsConfig;
