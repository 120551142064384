import React, { FC } from 'react';
import classNames from 'classnames';

import { WithTranslate } from 'src/i18n';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import style from '../../index.module.css';

interface LegendItem {
  message?: string;
  messageKey?: string;
  icon?: string;
  picon?: string;
}

const NODE_TYPES: LegendItem[] = [
  { messageKey: 'systems.traffic.captions.transportNodes' },
  { picon: ICONS_TYPES.trafficNodeSea, messageKey: 'systems.traffic.captions.nodeSea' },
  { picon: ICONS_TYPES.trafficNodeRail, messageKey: 'systems.traffic.captions.nodeRail' },
];

const ROUTES_TYPES: LegendItem[] = [
  { messageKey: 'systems.traffic.captions.carsPerDay' },
  { icon: style.routeLine6, message: '300,0 +' },
  { icon: style.routeLine5, message: '200,1 - 300,0' },
  { icon: style.routeLine4, message: '100,1 - 200,0' },
  { icon: style.routeLine3, message: '50,1 - 100,0' },
  { icon: style.routeLine2, message: '30,1 - 50,0' },
  { icon: style.routeLine1, message: '< 30,0' },
];

const ROUTES_TYPES_OTLK: LegendItem[] = [
  { messageKey: 'systems.traffic.captions.carsPerDayOtlk' },
  { icon: style.routeLineOtlk6, message: '300,0 +' },
  { icon: style.routeLineOtlk5, message: '200,1 - 300,0' },
  { icon: style.routeLineOtlk4, message: '100,1 - 200,0' },
  { icon: style.routeLineOtlk3, message: '50,1 - 100,0' },
  { icon: style.routeLineOtlk2, message: '30,1 - 50,0' },
  { icon: style.routeLineOtlk1, message: '< 30,0' },
];

const EDGE_TYPES: LegendItem[] = [
  { messageKey: 'systems.traffic.captions.edgeTypes' },
  { icon: classNames(style.routeLineType, style.routeLineAir), messageKey: 'systems.traffic.captions.airRoute' },
  { icon: classNames(style.routeLineType, style.routeLineSea), messageKey: 'systems.traffic.captions.seaRoute' },
  { icon: classNames(style.routeLineType, style.routeLineRail), messageKey: 'systems.traffic.captions.railRoute' },
  { icon: classNames(style.routeLineType, style.routeLineAuto), messageKey: 'systems.traffic.captions.autoRoute' },
];

type Props = WithTranslate;

const TrafficMapLegend: FC<Props> = (props) => {
  const { t } = props;
  const mapLegendItems = (legendItems: LegendItem[]) => {
    return legendItems.map((item, index) => {
      const message = item.messageKey ? t(item.messageKey) : item.message;
      if (item.icon) {
        return (
          <div key={index} className={style.legendEntry}>
            <div className={style.legendRouteIcon}>
              <div className={item.icon} />
            </div>
            <div className={style.legendTitle}>
              {message}
            </div>
          </div>
        );
      } if (item.picon) {
        return (
          <div key={index} className={style.legendEntry}>
            <div className={style.legendRouteIcon}>
              <PIcon {...{
                type: item.picon,
                size: 22,
                className: style.marker,
              }} />
            </div>
            <div className={style.legendTitle}>
              {message}
            </div>
          </div>
        );
      }
      return (
        <div key={index} className={style.toolbarTitle}>
          {message}
        </div>
      );
    });
  };

  const routeInfo = (
    <div className={style.legendEntry}>
      <div className={style.legendRouteIcon}>
        <div className={style.legendRouteInfoWrapper}>
          <div className={style.legendRouteInfoBack} />
          <div className={style.legendRouteInfoForth} />
          <div className={style.legendRouteInfoValue}>
            <PIcon {...{
              type: ICONS_TYPES.arrowLeft,
              size: 10,
              className: style.routeFreightArrow,
            }} />
            <div className={style.legendRouteInfoValueForth}>211,8</div>
            <PIcon {...{
              type: ICONS_TYPES.arrowRight,
              size: 10,
              className: classNames(style.routeFreightArrow, style.routeFreightArrowBack),
            }} />
            <div className={style.legendRouteInfoValueBack}>201,3</div>
          </div>
        </div>
      </div>
      <div className={style.legendTitle}>
        {t('systems.traffic.captions.routeInfo')}
      </div>
    </div>
  );

  return (
    <div className={style.mapLegend}>
      <div className={style.mapLegendRow}>
        <div className={style.mapLegendColumn}>
          { mapLegendItems(ROUTES_TYPES_OTLK) }
        </div>
        <div className={style.mapLegendColumn}>
          { mapLegendItems(NODE_TYPES) }
          <div className={style.toolbarTitle} />
          { routeInfo }
        </div>
      </div>
      <div className={style.mapLegendRow}>
        <div className={style.mapLegendColumn}>
          { mapLegendItems(ROUTES_TYPES) }
        </div>
        <div className={style.mapLegendColumn}>
          { mapLegendItems(EDGE_TYPES) }
        </div>
      </div>
    </div>
  );
};

export default TrafficMapLegend;
