import { useMemo } from 'react';

import { SelectMenuOption } from 'src/interfaces';

import useDistributionMapRoutes from './useDistributionMapRoutes';

const useDistributionMapRoutesOptions = (): SelectMenuOption[] => {
  const routes = useDistributionMapRoutes();
  return useMemo(() => {
    return routes.map((route) => ({
      id: `${route.id}`,
      value: route.id,
      label: route.routeNumber,
    }));
  }, [routes]);
};

export default useDistributionMapRoutesOptions;
