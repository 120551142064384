import React, { ReactElement } from 'react';
import classNames from 'classnames';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import style from './index.module.css';

interface Props {
  className?: string;
  content: ReactElement | (ReactElement)[];
  position?: 'top' | 'bottom';
}

const MapLegend = (props: Props) => {
  const { className, content, position = 'bottom' } = props;
  return (
    <div className={classNames(style.root, className)}>
      <PIcon {...{
        type: ICONS_TYPES.info,
        className: style.icon,
      }} />
      <div className={classNames(style.wrapper, position === 'top' && style.wrapperTop)}>
        { content }
      </div>
    </div>
  );
};

export default MapLegend;
