import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import camelCase from 'lodash/camelCase';
import { RestifyFormActions } from 'redux-restify';
import { useDispatch } from 'react-redux';
import { getVocabularyValue, useCurrentVocabulary, WithTranslate } from 'src/i18n';

import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import { TransportModel, PageTransportForm } from 'src/modules/matrix/transport';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import PCheckbox from 'src/components/deprecated/PCheckbox';
import PButton from 'src/components/deprecated/PButton';
import Tooltip from 'src/components/etc/Tooltip';

import style from './index.module.css';
import tooltipStyle from '../../../../../styles/tooltip.module.css';

const transportTypes = [
  'air_transport',
  'bus_transport',
  'motor_transport',
  'speed_motor_transport',
  'rail_suburban_transport',
  'rail_platz_transport',
  'rail_coupe_transport',
  'rail_speed_transport',
  'rail_high_speed_transport',
];

interface TransportToolbarProps {
  data: TransportModel[];
  form: PageTransportForm;
  formActions: RestifyFormActions<PageTransportForm>;
}

type Props = TransportToolbarProps & WithTranslate & {
  className?: string;
}

const TransportToolbar: React.FC<Props> = (props: Props) => {
  const {
    className,
    data,
    form,
    formActions,
    t,
  } = props;
  const dispatch = useDispatch();
  const [currentLocale, vocabulary] = useCurrentVocabulary();

  const years = uniq(data.map(transport => transport.year));
  const correspondences = uniq(data.map((transport: TransportModel) => {
    return `${transport.fromCity} - ${transport.toCity}`;
  }));
  const dataTypes = uniq(data.map(transport => transport.type));
  const types = transportTypes.filter(type => dataTypes.includes(type));

  const yearsFrom = years.filter(year => {
    if (!year) {
      return false;
    }
    if (!form.yearTo) {
      return true;
    }
    return year < form.yearTo;
  });

  const yearsTo = years.filter(year => {
    if (!year) {
      return false;
    }
    if (!form.yearFrom) {
      return true;
    }
    return year > form.yearFrom;
  });

  const correspondencesOptions = correspondences.map((correspondence) => {
    const [fromRaw, toRaw] = correspondence.split(' - ');
    const fromCity = getVocabularyValue(currentLocale, vocabulary, fromRaw);
    const toCity = getVocabularyValue(currentLocale, vocabulary, toRaw);
    return ({
      value: correspondence,
      label: `${fromCity} - ${toCity}`,
    });
  }).sort((a, b) => (a.label < b.label ? -1 : 1));

  const typesOptions = useMemo(() => types.map((type) => {
    const transportType = camelCase(type);
    return {
      value: type,
      label: t(`systems.matrix.meansOfTransport.${transportType}`),
    };
  }), [types]);

  const yearFromOptions = useMemo(() => yearsFrom.map((year) => ({
    value: year,
    label: year,
  })), [yearsFrom]);

  const yearToOptions = useMemo(() => yearsTo.map((year) => ({
    value: year,
    label: year,
  })), [yearsTo]);

  const handleCorrespondencesChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('correspondences', value));
  }, []);

  const handleTypesChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('types', value));
  }, []);

  const handleYearFromChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('yearFrom', value[0]));
  }, []);

  const handleYearToChange = useCallback((value: DropDownValue[]) => {
    dispatch(formActions.changeField('yearTo', value[0]));
  }, []);

  const handleHSRChange = useCallback((value: boolean) => {
    dispatch(formActions.changeField('hsw', value ? 1 : 0));
  }, []);

  const handleResetForm = useCallback(() => {
    dispatch(formActions.resetForm());
  }, []);

  const handleResetCorrespondences = useCallback(() => {
    dispatch(formActions.resetField('correspondences'));
  }, []);

  const handleResetTypes = useCallback(() => {
    dispatch(formActions.resetField('types'));
  }, []);

  return (
    <div className={classNames(pageLayoutStyle.toolbar, className)}>
      <div className={classNames(pageLayoutStyle.toolbarBlock, style.filters)}>
        <div className={pageLayoutStyle.toolbarTitle}>
          {t('systems.matrix.captions.filter')}
          <PButton
            className={style.clear}
            type="link"
            onClick={handleResetForm}
          >
            {t('systems.matrix.captions.clearAll')}
          </PButton>
        </div>
        <div className={style.subBlockWrapper}>
          <div className={classNames(style.toolbarSubBlock, style.toolbarSubBlockCorrespondences)}>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSelect {...{
                label: (
                  <span className={style.spaceBetween}>
                    {t('systems.matrix.captions.correspondences')}
                    <PButton
                      className={style.clear}
                      type="link"
                      onClick={handleResetCorrespondences}
                    >
                      {t('systems.matrix.captions.clear')}
                    </PButton>
                  </span>
                ),
                className: style.correspondencesSelect,
                multi: true,
                isClearable: false,
                items: correspondencesOptions,
                values: Array.isArray(form.correspondences) ? form.correspondences : undefined,
                onChange: handleCorrespondencesChange,
              }} />
            </div>
          </div>

          <div className={classNames(style.toolbarSubBlock, style.toolbarSubBlockTypes)}>
            <div className={pageLayoutStyle.toolbarControl}>
              <PSelect {...{
                label: (
                  <span className={style.spaceBetween}>
                    {t('systems.matrix.captions.meanOfTransport')}
                    <PButton
                      className={style.clear}
                      type="link"
                      onClick={handleResetTypes}
                    >
                      {t('systems.matrix.captions.clear')}
                    </PButton>
                  </span>
                ),
                className: style.typesSelect,
                multi: true,
                isClearable: false,
                items: typesOptions,
                values: Array.isArray(form.types) ? form.types : undefined,
                onChange: handleTypesChange,
              }} />
            </div>
          </div>

          <div className={style.toolbarSubBlock}>
            <div className={style.fieldTitle}>
              {t('systems.matrix.captions.period')}
            </div>
            <div className={pageLayoutStyle.toolbarControls}>
              <div className={classNames(pageLayoutStyle.toolbarControl, style.yearRange)}>
                <PSelect {...{
                  label: t('systems.matrix.captions.from'),
                  className: style.yearSelect,
                  items: yearFromOptions,
                  values: form.yearFrom ? [form.yearFrom] : undefined,
                  onChange: handleYearFromChange,
                }} />
                <PSelect {...{
                  label: t('systems.matrix.captions.till'),
                  className: style.yearSelect,
                  items: yearToOptions,
                  values: form.yearTo ? [form.yearTo] : undefined,
                  onChange: handleYearToChange,
                }} />
              </div>
            </div>
          </div>

          <div className={classNames(style.toolbarSubBlock, style.HSRBlock)}>
            <div className={pageLayoutStyle.toolbarControls}>
              <div className={classNames(pageLayoutStyle.toolbarControl, style.yearRange)}>
                <PCheckbox {...{
                  label: t('systems.matrix.captions.hsr'),
                  value: Boolean(form.hsw),
                  onChange: handleHSRChange,
                }} />
              </div>
            </div>
          </div>
        </div>
        <Tooltip className={classNames(tooltipStyle.tooltip, style.tooltip)} align="right">
          <span className={tooltipStyle.tooltipAnchor}>?</span>
          <span className={tooltipStyle.tooltipPopup}>
            {t('systems.matrix.messages.transportToolbarTooltip')}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default TransportToolbar;
