import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { forms } from 'redux-restify';

import { withTranslate } from 'src/i18n';
import { DropDownValue } from 'src/components/deprecated/PSelect';

import { AcBalanceShapesModel } from 'src/cluster/common';
import { AC_SHAPES_PAGE_FORM_NAME, addShape } from 'src/cluster/shapes';
import BalanceShapesToolbar from './BalanceShapesToolbar';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setName: (value: string) => {
    dispatch(forms.actions[AC_SHAPES_PAGE_FORM_NAME].changeField('name', value));
  },
  setFactors: (values: DropDownValue[]) => {
    dispatch(forms.actions[AC_SHAPES_PAGE_FORM_NAME].changeField('factors', values));
  },
  addShape: (shape: AcBalanceShapesModel) => dispatch(addShape(shape)),
});

export default connect(null, mapDispatchToProps)(withTranslate(BalanceShapesToolbar));
