import React, { FC, useEffect } from 'react';
import { Maybe } from 'monet';

import { Model, SelectValue } from 'src/interfaces';
import { DataGridContainer } from 'src/components';
import {
  RegistryToolbar,
  AcRegistryPageForm,
  AcRegRoutesResultsModel,
  RegistryDataGrid,
} from 'src/cluster/editor-table';

import useStyles from './styles';

export interface InjectedProps {
  pageForm: AcRegistryPageForm;
  results: Maybe<Model<AcRegRoutesResultsModel>[]>;
  count: number;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  showEditingModal(record: AcRegRoutesResultsModel): void;
  showSettingsModal(): void;
  setRegistryFilter(val: SelectValue): void;
  loadRegistryRoute(id: number): void;
  deleteRegistryRoute(id: number, num: string): void;
  onExportClick(): void;
  onInit(): void;
}

const RegistriesPage: FC<InjectedProps> = (props) => {
  const {
    pageForm: {
      registryId,
      topLevel,
      bottomLevel,
      currentPage,
      pageSize,
    },
    results,
    count,
    setRegistryFilter,
    showSettingsModal,
    onExportClick,
    showEditingModal,
    onPageChange,
    onPageSizeChange,
    onInit,
    loadRegistryRoute,
    deleteRegistryRoute,
  } = props;

  useEffect(onInit, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RegistryToolbar
        registryId={registryId}
        setRegistryFilter={setRegistryFilter}
        showSettingsModal={showSettingsModal}
        onExportClick={onExportClick}
      />
      <DataGridContainer
        data={results}
        currentPage={currentPage}
        pageSize={pageSize}
        count={count}
        isLoading={false}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      >
        {(data: AcRegRoutesResultsModel[]) => (
          <RegistryDataGrid
            data={data}
            showEditingModal={showEditingModal}
            loadRegistryRoute={loadRegistryRoute}
            topColumns={topLevel}
            bottomColumns={bottomLevel}
            onDeleteRoute={deleteRegistryRoute}
          />
        )}
      </DataGridContainer>
    </div>
  );
};

export default RegistriesPage;
