/* eslint-disable max-len */
import React from 'react';

const TransportTypeSea = () => (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.96437 11.9936C9.7531 12.4188 9.47296 12.8101 9.12804 13.1549C8.48662 13.7971 7.68236 14.2155 6.80922 14.375V8.02662H9.21728V6.4221H6.80922V4.67853C7.7437 4.34866 8.4144 3.45704 8.4144 2.40879C8.4144 1.07827 7.33605 0 6.00627 0C4.67649 0 3.59813 1.07835 3.59813 2.40879C3.59813 3.45704 4.26883 4.34866 5.20405 4.67853V6.42202H2.79591V8.02654H5.20405V14.3749C4.33018 14.2155 3.52591 13.797 2.88523 13.1548C2.54031 12.81 2.25952 12.4187 2.0489 11.9935L3.59813 10.4353H0C0.188777 13.3049 2.38899 15.6251 5.20405 16H6.80922C9.62354 15.6251 11.8238 13.3049 12.0125 10.4353H8.4144L9.96437 11.9936ZM6.00635 3.21036C5.56402 3.21036 5.20413 2.85055 5.20413 2.40879C5.20413 1.96572 5.56402 1.60591 6.00635 1.60591C6.44877 1.60591 6.80931 1.9658 6.80931 2.40879C6.80922 2.85047 6.44868 3.21036 6.00635 3.21036Z" fill="#1A66B7" />
  </svg>
);

export default TransportTypeSea;
