import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import { Button, SettingsIcon, ToolbarControls } from 'src/components';

import useStyles from './styles';

export interface OwnProps {
  showSettingsModal(): void;
}

type Props = OwnProps & WithTranslate;

const RegistrySettingsButton: FC<Props> = (props) => {
  const { t, showSettingsModal } = props;

  const classes = useStyles();

  return (
    <ToolbarControls className={classes.root}>
      <Button
        className={classes.button}
        variant="link"
        startIcon={<SettingsIcon />}
        onClick={showSettingsModal}
      >
        {t('modules.registries.captions.customizeTable')}
      </Button>
    </ToolbarControls>
  );
};

export default RegistrySettingsButton;
