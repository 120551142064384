import React from 'react';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import clsx from 'clsx';

import {
  GridRow, GridHead,
} from './components';
import { DataGridProps } from './interfaces';

import useStyles from './styles';

type Props<T> = DataGridProps<T>;

function DataGrid<T extends Record<string, any>>(props: Props<T>) {
  const {
    className,
    columns,
    headerColumns,
    body,
    expandable = false,
    expand,
    hideHeader = false,
    size,
    rowClassName,
    headCellClassName,
    cellClassName,
    actionComponent,
    actionClass,
    onRowClick,
    onExpandClick,
    onRowEnter,
    onRowLeave,
  } = props;

  const classes = useStyles();

  return (
    <TableContainer className={clsx(classes.root, className)}>
      <Table stickyHeader size={size}>
        {!hideHeader && (
          <GridHead<T>
            columns={headerColumns ?? columns}
            multiple={!!headerColumns}
            expandable={expandable}
            headCellClassName={headCellClassName}
          />
        )}
        <TableBody className={classes.tableBody}>
          {body.map((record: T, index) => (
            <GridRow<T>
              key={index}
              columns={columns}
              body={body}
              row={record}
              expandable={expandable}
              expand={expand}
              rowClassName={rowClassName}
              cellClassName={cellClassName}
              actionComponent={actionComponent}
              actionClass={actionClass}
              onClick={onRowClick}
              onMouseEnter={onRowEnter}
              onMouseLeave={onRowLeave}
              onExpandClick={onExpandClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DataGrid;
