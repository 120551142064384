import { SelectMenuOption } from 'src/interfaces';
import { useSelector } from 'react-redux';
import { selectAcSites } from 'src/cluster/common';

const useAcSiteOptions = (): SelectMenuOption[] => {
  const sites = useSelector(selectAcSites);

  return sites.map((value) => value.map((site) => ({
    id: `${site.id}`,
    value: site.id,
    label: site.name,
  }))).orJust([]);
};

export default useAcSiteOptions;
