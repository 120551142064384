import { AC_BASE_SCENARIO_ENDPOINT, AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_BASE_SCENARIO_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME],
  idField: () => 1,
  defaults: {
    year: undefined,
    scenarioId: undefined,
  },
  clearPagesOnRouteChange: false,
  pagination: false,
};
