import { CITIES_CALCULATIONS_ENDPOINT, SHIPMENTS_CALCULATIONS_ENDPOINT } from 'src/api-url-schema';
import { DEFAULT_SYSTEMS_VALUES, Systems } from 'src/constants';
import { StrictDict } from 'src/types';

export const EXPORT_CALCULATION_MODAL = 'exportCalculationModal';

export const NODES_CALCULATIONS_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
};

export const ROUTES_CALCULATION_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
};

export const CALCULATIONS_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsCalculations',
  [Systems.cities]: 'citiesCalculations',
};

export const CALCULATION_FILTERS_MODEL_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'shipmentsCalculationsFilters',
  [Systems.cities]: 'citiesCalculationsFilters',
};

export const CALCULATIONS_PAGES_FORM_NAMES = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: 'pageShipmentsCalculationsForm',
  [Systems.cities]: 'pageCitiesCalculationsForm',
};

export const CALCULATIONS_ENDPOINTS = {
  ...DEFAULT_SYSTEMS_VALUES,
  [Systems.shipments]: SHIPMENTS_CALCULATIONS_ENDPOINT,
  [Systems.cities]: CITIES_CALCULATIONS_ENDPOINT,
};

export interface PageCalculationForm {
  lengthPercentMax: number;
  uniqPercentMax: number;
  pageSize: number;
  currentPage: number;
}

export interface CalculationFilters {
  edge: number[];
  from: string[];
  to: string[];
}

export enum ExportTypes {
  origin = 'ORIGIN',
  graph = 'GRAPH',
  routes = 'ROUTES',
  fullResult = 'FULLRESULT',
}
export const EXPORT_TYPES = { ...ExportTypes };

export const EXPORT_TYPES_DICT: StrictDict<ExportTypes, string> = {
  [EXPORT_TYPES.origin]: 'systems.shipments.exportTypes.origin',
  [EXPORT_TYPES.graph]: 'systems.shipments.exportTypes.graph',
  [EXPORT_TYPES.routes]: 'systems.shipments.exportTypes.routes',
  [EXPORT_TYPES.fullResult]: 'systems.shipments.exportTypes.fullResult',
};
