import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ zIndex }) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 66,
    zIndex: zIndex.drawer,
  },

  button: {
    width: 30,
    height: 30,
    minWidth: 30,

    '&> svg': {
      verticalAlign: 'middle',
    },
  },

  icon: {
    display: 'inline',
  },
}));
