import React, {
  SyntheticEvent, FC, ElementType, ReactNode,
} from 'react';
import { Button as MuiButton, Link as MuiLink } from '@material-ui/core';

import {
  useButtonStyles, useIconStyles, useLinkStyles,
} from './styles';

export type Props = {
  className?: string;
  disabled?: boolean;
  variant?: 'contained' | 'outlined' | 'text' | 'link' | 'icon';
  size?: 'small' | 'medium' | 'large';
  textLabel?: string;
  fullWidth?: boolean;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  component?: ElementType;
  title?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: (e: SyntheticEvent) => void;
}

const Button: FC<Props> = (props) => {
  const {
    children,
    disabled = false,
    className,
    size = 'medium',
    variant = 'contained',
    fullWidth,
    color = 'primary',
    component = 'button',
    title,
    onClick,
    startIcon,
    endIcon,
  } = props;

  const buttonClasses = useButtonStyles();
  const linkClasses = useLinkStyles({ disabled });
  const iconClasses = useIconStyles();

  if (variant === 'link') {
    return (
      <MuiLink
        classes={linkClasses}
        className={className}
        color={disabled ? 'secondary' : 'primary'}
        underline={disabled ? 'none' : 'always'}
        title={title}
        onClick={disabled ? undefined : onClick}
      >
        {startIcon}
        {children}
        {endIcon}
      </MuiLink>
    );
  }

  if (variant === 'icon') {
    return (
      <MuiButton
        classes={iconClasses}
        className={className}
        size={size}
        color={color}
        disabled={disabled}
        disableRipple
        title={title}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={disabled ? undefined : onClick}
      >
        {children}
      </MuiButton>
    );
  }

  return (
    <MuiButton
      classes={buttonClasses}
      className={className}
      component={component}
      variant={variant}
      size={size}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      title={title}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </MuiButton>
  );
};

Button.displayName = 'Button';

export default Button;
