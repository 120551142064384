import { useTranslation } from 'react-i18next';
import { VehicleType, VEHICLE_TYPES_DICT } from 'src/cluster/common';
import { useVehicleTypeName } from 'src/cluster/editor-map';

const useVehicleTypeLabel = (): (vehicleId?: number) => string => {
  const { t } = useTranslation();
  const getVehicleTypeName = useVehicleTypeName();
  return (vehicleId) => {
    const name = getVehicleTypeName(vehicleId);
    return name && name in VehicleType ? t(VEHICLE_TYPES_DICT[name]) : t('common.vehicleTypes.unknown');
  };
};

export default useVehicleTypeLabel;
