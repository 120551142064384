import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import { loadMatrixForecast, selectMatrixForecast } from 'src/cluster/common';

import modals from 'src/modals';
import {
  forecastTrafficSumEntityManager,
  reloadBalanceForecast,
} from 'src/cluster/balance-forecast';
import BalanceForecastProgress from './BalanceForecastProgress';

const mapStateToProps = (state: AppState) => ({
  matrixForecastEntities: selectMatrixForecast(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadMatrixForecast: () => dispatch(loadMatrixForecast()),
  calculateDone: () => {
    dispatch(forecastTrafficSumEntityManager.clearData());
    dispatch(reloadBalanceForecast());
    dispatch(
      modals.actions.showMessageBoxModal({
        title: 'Расчет завершен',
        text: 'Пассажиропотоки были успешно рассчитаны.',
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslate(BalanceForecastProgress));
