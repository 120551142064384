import { ExportTypes } from 'src/modules/mth/common/types';

export interface ExportAgglomerationCalculationForm {
  type?: ExportTypes;
}

export default {
  defaults: {
    type: undefined,
  },
};
