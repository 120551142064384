import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { VEHICLE_TYPES_DICT, VehicleType, useAcVehicleTypes } from 'src/cluster/common';
import { IndicatorPartition } from 'src/cluster/dashboard-table';

const useVehicleTypesForPartition = (): IndicatorPartition[] => {
  const vehicleTypes = useAcVehicleTypes();

  const [t, { language }] = useTranslation();
  return useMemo(() => {
    return vehicleTypes
      .filter(type => type.name in VehicleType && type.isPublic)
      .map((type) => ({
        id: type.id,
        name: t(VEHICLE_TYPES_DICT[type.name]),
        entityName: 'vehicle_type',
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [vehicleTypes, language]);
};

export default useVehicleTypesForPartition;
