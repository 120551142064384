/* eslint-disable max-len */
import React from 'react';

const Skype = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M257.4,402.7c-85.8,0-124.2-42.2-124.2-73.9c0-16.3,12-27.6,28.4-27.6c36.8,0,27.2,52.8,95.7,52.8c35,0,54.4-19.1,54.4-38.6  c0-11.8-5.8-24.8-28.9-30.5l-76.3-19.1c-61.5-15.4-72.6-48.8-72.6-80c0-65,61-89.4,118.4-89.4c52.7,0,115,29.3,115,68.3  c0,16.7-14.7,26.4-31,26.4c-31.3,0-25.6-43.5-88.8-43.5c-31.3,0-48.9,14.2-48.9,34.5s24.6,26.8,46,31.7l56.2,12.5  c61.7,13.8,77.3,50,77.3,84.1c0,52.8-40.6,92.2-122.1,92.2 M492.8,298.5c0,0-0.6-0.6-0.3,0.4c2.6-14.4,3.8-29.1,3.8-43.8  c0-32.6-6.4-64.2-19.2-94.3c-12.1-28.8-29.7-54.6-51.8-76.7C403,62,377.4,44.5,348.7,32.3c-28.1-13.5-59.7-19.8-92.3-19.8  c-15.3,0-30.8,1.5-45.7,4.4l0.1,0.1C190.1,5.8,167,0,143.6,0c-38.2,0-74,14.9-101.1,42S0.7,105,0.7,143.3c0,24.4,6.2,48.3,18,69.5  v-0.1c-2.4,13.7-3.7,27.7-3.7,41.7c0,32.7,6.4,64.4,18.9,94.2c12.1,29.1,29.4,55,51.8,77c22.1,22.4,47.9,39.6,76.7,52.1  c29.7,12.8,61.4,19.2,94,19.2c14.1,0,28.4-1.3,42.2-3.8l-0.4,0.3c21.4,12.1,45.4,18.5,70.3,18.5c38,0,73.8-14.7,101-41.9  c26.8-26.8,41.9-63,41.9-101.3c0-24.3-6.4-48.3-18.2-69.7" />
  </svg>
);

export default Skype;
