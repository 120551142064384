import { AC_REGISTRY_VEHICLE_CLASSES_ENDPOINT, AcRegistriesVCModel } from 'src/cluster/editor-table';
import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_REGISTRY_VEHICLE_CLASSES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
  },
  transformArrayResponse: (response: AcRegistriesVCModel[]) => {
    return {
      data: response.map((carrier) => ({
        id: carrier.vehicleClassId,
        name: carrier.vehicleClassName,
      })),
    };
  },
  pagination: false,
  allowIdRequests: false,
};
