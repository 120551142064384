import { useReducer } from 'react';
import { AcBalanceTtcModel } from 'src/cluster/common';

const emptyFlow: AcBalanceTtcModel = {
  id: 'new',
  traffic: 0,
  year: 1900,
  source: '',
  ttcBase: 0,
  ttcForecast: 0,
};

const CREATE = 'CREATE';
const UNCREATE = 'UNCREATE';
const RESET = 'RESET';

const initialCreateState: AcBalanceTtcModel[] = [];

const creatingReducer = (
  state: AcBalanceTtcModel[],
  { type, payload }: { type: string; payload?: Partial<AcBalanceTtcModel> },
) => {
  switch (type) {
    case CREATE: {
      if (!payload) {
        throw new Error(`Unexpected payload: ${payload}`);
      }
      const record = state.find((item: AcBalanceTtcModel) => item.id === payload.id);
      if (record) {
        return state.map((item: AcBalanceTtcModel) => {
          if (item.id === record.id) {
            return {
              ...record,
              ...payload,
            };
          }

          return item;
        });
      }
      const newFlow = { ...emptyFlow, ...payload };
      return [...state, newFlow];
    }
    case UNCREATE: {
      if (!payload) {
        throw new Error(`Unexpected payload: ${payload}`);
      }
      return state.filter((item: AcBalanceTtcModel) => item.id !== payload.id);
    }
    case RESET: {
      return initialCreateState;
    }
    default: {
      throw new Error(`Unexpected action: ${type}`);
    }
  }
};

function createAction(payload: Partial<AcBalanceTtcModel>) {
  return {
    type: CREATE,
    payload,
  };
}

function uncreateAction(payload: AcBalanceTtcModel) {
  return {
    type: UNCREATE,
    payload,
  };
}

function resetAction() {
  return {
    type: RESET,
  };
}

type HookResponse = [
  AcBalanceTtcModel[],
  (flow: Partial<AcBalanceTtcModel>) => void,
  (flow: AcBalanceTtcModel) => void,
  () => void,
]

export default function useEdit(): HookResponse {
  const [state, dispatch] = useReducer(creatingReducer, initialCreateState);

  const create = (flow: Partial<AcBalanceTtcModel>) => dispatch(createAction(flow));

  const uncreate = (flow: AcBalanceTtcModel) => dispatch(uncreateAction(flow));

  const reset = () => dispatch(resetAction());

  return [state, create, uncreate, reset];
}
