import { Model } from 'src/interfaces';
import { useAcVehicleTypes, AcVehicleTypesModel, useAcVehicleTypeLabel } from 'src/cluster/common';

const useSortedByLabelVehicleTypes = (): Model<AcVehicleTypesModel>[] => {
  const vehicleTypes = useAcVehicleTypes();
  const getVehicleTypeLabel = useAcVehicleTypeLabel();

  return vehicleTypes.filter(type => type.isPublic && type.hasRoutes).sort((a, b) => {
    const aLabel = getVehicleTypeLabel(a.id);
    const bLabel = getVehicleTypeLabel(b.id);
    return aLabel.localeCompare(bLabel);
  });
};

export default useSortedByLabelVehicleTypes;
