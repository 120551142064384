import React from 'react';

import classNames from 'classnames';
import { forms } from 'redux-restify';
import { useDispatch } from 'react-redux';
import { WithTranslate } from 'src/i18n';
import { useRestifyForm, useRestifyModel } from 'src/helpers';

import { CalculationModel, CALCULATIONS_STATUSES, CalculationsTable } from 'src/modules/general/calculations';
import CalculationResultMapButton from 'src/modules/general/calculations/components/CalcResultMapButton';
import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';

import {
  CALCULATIONS_MODEL_NAME,
  CALCULATIONS_PAGES_FORM_NAME, PageCalculationForm,
  PageCitiesCalculationForm,
} from 'src/modules/cities/calculations';
import actions from 'src/modules/cities/calculations/actions';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import modals from 'src/modals';
import { useSystemsContext } from 'src/constants';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const CitiesCalcTable: React.FC<Props> = ({
  className, t,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const [/**/, pageFormActions] = useRestifyForm<PageCalculationForm>(CALCULATIONS_PAGES_FORM_NAME);
  const [/**/, calculationsApiActions] = useRestifyModel<CalculationModel>(CALCULATIONS_MODEL_NAME);
  const [calculationsEntities] = useRestifyModel<CalculationModel>(CALCULATIONS_MODEL_NAME);
  const [form, formActions] = useRestifyForm<PageCitiesCalculationForm>(CALCULATIONS_PAGES_FORM_NAME);
  const calculationsApiConfig = {
    filter: {
      page: form.currentPage,
      pageSize: form.pageSize,
      isActual: form.isActual ? true : undefined,
      version: form.version,
    },
  };
  const calculations = calculationsEntities.getArray(calculationsApiConfig);
  const isLoading = calculationsEntities.getIsLoadingArray(calculationsApiConfig);
  const count = calculationsEntities.getCount(calculationsApiConfig);

  return (
    <CalculationsTable {...{
      className: classNames(className, style.root),
      calculations,
      isLoading,
      count,
      form,
      formActions,
      buttons: (item: any) => (
        <div className={style.buttonsWrapper}>
          {item.status === CALCULATIONS_STATUSES.success &&
          <CalculationResultMapButton {...{
            onClick: () => {
              dispatch(pageFormActions.changeField('selectedCalculation', item.id));
              dispatch(actions[currentSystem].showCalculationMap(item.id));
            },
          }} />}
          {item.status === CALCULATIONS_STATUSES.success && (
            <ExportToExcelButton {...{
              label: '',
              onClick: () => {
                if (item.isNew) {
                  dispatch(forms.actions.sendQuickForm({
                    model: CALCULATIONS_MODEL_NAME,
                    id: item.id,
                    values: {
                      isNew: false,
                    },
                  }));
                }
                dispatch(actions[currentSystem].exportCalculation(item.id));
              },
            }} />)}
          <PIcon {...{
            type: ICONS_TYPES.clear,
            className: style.clearIcon,
            onClick: () => {
              dispatch(modals.actions.showConfirmModal({
                text: t('modules.calculations.messages.removeConfirmation', { id: item.id }),
                onAccept: () => {
                  dispatch(calculationsApiActions.deleteById(item.id));
                },
              }));
            },
          }} />
        </div>
      ),
    }}
    />
  );
};

export default CitiesCalcTable;
