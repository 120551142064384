import { useAcIntervals } from 'src/cluster/common';
import i18n from 'src/i18n';

const useAcIntervalName = (day?: boolean): (id: number) => string => {
  const intervals = useAcIntervals();

  return (id: number) => {
    if (day) {
      return i18n.t('common.captions.day');
    }
    const record = intervals.find((scenario) => {
      return scenario.id === id;
    });
    return record ? record.intervalName : i18n.t('components.captions.notSelected');
  };
};

export default useAcIntervalName;
