import { StrictDict } from 'src/types';
import { ShapeStatuses, ShapeTypes } from './types';

export const AC_SHAPES_MODEL_NAME = 'acShapes';
export const AC_SHAPES_MODAL = 'acShapesModal';
export const AC_SHAPES_PAGE_FORM_NAME = 'pageAcShapesForm';

export const AC_SHAPES_ENDPOINT = 'balance/shapes/';

export const AC_SHAPE_STATUSES = { ...ShapeStatuses };

export const AC_SHAPE_STATUSES_DICT: StrictDict<ShapeStatuses, string> = {
  [AC_SHAPE_STATUSES.QUEUED]: 'modules.projects.statuses.queued',
  [AC_SHAPE_STATUSES.IN_PROGRESS]: 'modules.projects.statuses.inProgress',
  [AC_SHAPE_STATUSES.SUCCESS]: 'modules.projects.statuses.success',
  [AC_SHAPE_STATUSES.ERROR]: 'modules.projects.statuses.error',
};

export const AC_SHAPE_TYPES = { ...ShapeTypes };

export const AC_SHAPE_TYPES_DICT: StrictDict<ShapeTypes, string> = {
  [AC_SHAPE_TYPES.GRAVITATIONAL]: 'modules.shapes.types.gravitational',
};
