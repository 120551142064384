import React from 'react';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  className?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'full-width';
}

const ToolbarControl: React.FC<Props> = ({
  className,
  size,
  children,
}) => {
  const sizeClassName = classNames({
    [style.controlXSmall]: size === 'x-small',
    [style.controlSmall]: size === 'small',
    [style.controlMedium]: size === 'medium',
    [style.controlLarge]: size === 'large',
    [style.controlXLarge]: size === 'x-large',
    [style.controlFullWidth]: size === 'full-width',
  });
  return (
    <div className={classNames(style.control, sizeClassName, className)}>
      {children}
    </div>
  );
};

export default ToolbarControl;
