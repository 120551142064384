import { AcGeojsonForm } from 'src/cluster/common';

const defaults: AcGeojsonForm = {
  centroids: undefined,
  districts: undefined,
  nodes: undefined,
  edges: undefined,
};

export default { defaults };
