import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { Button, CrossIcon } from 'src/components';

import useClasses from './styles';

type Props = {
  message?: ReactNode;
  onClick: () => void;
}

const HeadToolbarMessage: FC<Props> = (props) => {
  const {
    message,
    onClick,
  } = props;

  const classes = useClasses();

  return (
    <div className={clsx(classes.root, !message && classes.hidden)}>
      <Typography className={classes.message}>
        {message}
      </Typography>
      <Button variant="icon" color="inherit" className={classes.button} onClick={onClick}>
        <CrossIcon />
      </Button>
    </div>
  );
};

export default HeadToolbarMessage;
