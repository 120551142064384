import React, {
  FC, useState, Fragment, ChangeEvent, MouseEvent,
} from 'react';
import clsx from 'clsx';
import {
  createStyles, makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, Popover as MuiPopover, Fade,
} from '@material-ui/core';

import { ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { PROJECT_STATUSES_DICT } from 'src/cluster/projects';

import useStyles from './styles';

export const overridePopover = makeStyles((theme) => createStyles({
  root: {
    borderRadius: 0,
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
    opacity: 1,
    boxShadow: theme.shadows[1],
  },
}), { name: 'MuiPopover' });

type OwnProps = {
  value: any;
  title: string;
  icon: any;
  onChange(value: string): void;
}

type Props = OwnProps & WithTranslate;

const ColumnFilter: FC<Props> = (props) => {
  const {
    value,
    title,
    icon,
    t,
    onChange,
  } = props;

  overridePopover();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(String(event.target.value));
    handleClose();
  };
  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <button className={classes.toggle} onClick={handleClick}>
        <span>{title}</span>
        <span className={clsx(classes.icon, value !== '' && classes.active)} >
          {icon}
        </span>
      </button>
      <MuiPopover
        id="filter-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 32,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
      >
        <FormControl component="fieldset" className={classes.popup}>
          <RadioGroup
            name="status"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value=""
              control={<Radio color="primary" size="small" />}
              label={t('systems.agglomeration.captions.projectStatus')}
            />
            {Object.keys(ProcessStatus).map((item) => {
              const labelKey = `${PROJECT_STATUSES_DICT[item]}`;
              return (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio color="primary" size="small" />}
                  label={t(labelKey)}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </MuiPopover>
    </Fragment>
  );
};

export default ColumnFilter;
