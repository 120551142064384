import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import flow from 'lodash/flow';

import {
  api,
  forms,
  ApiActions,
  RestifyFormActions,
  RestifyFormErrors,
  RestifyEntityList,
  FormPath,
  FormSelectors,
} from 'redux-restify';

// TODO by @deylak make more strict typing for injected restify entities,
// we need to somehow resolve problem with string entities names
// Now we don't have check for obligatory fields to be passed into components,
// also we don't infer restify names from passed strings(don't know, if this is possible)
type WithRestifyModels<T> = React.ComponentType<Partial<T> & Record<string, RestifyEntityList<any>>>
type WithRestifyForms<T> = React.ComponentType<Partial<T> & Record<string, any>>

export function withRestifyModels<T>(
  modelName: string | string[],
): (WrappedComp: React.ComponentType<T>) => WithRestifyModels<T> {
  const modelNamesArray = Array.isArray(modelName) ? modelName : [modelName];
  const mapStateToProps = (state: any) => {
    return modelNamesArray.reduce((memo: any, name: string) => ({
      ...memo,
      [`${name}Entities`]: api.selectors.entityManager[name].getEntities(state),
    }), {});
  };

  const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
    return modelNamesArray.reduce((memo: any, name: string) => ({
      ...memo,
      [`${name}ApiActions`]: bindActionCreators(api.actions.entityManager[name], dispatch),
    }), {});
  };
  return connect(mapStateToProps, mapDispatchToProps) as any;
}

export function withRestifyForm<T>(
  formName: string | string[],
): (WrappedComp: React.ComponentType<T>) => WithRestifyForms<T> {
  const formNamesArray = Array.isArray(formName) ? formName : [formName];
  const mapStateToProps = (state: any) => {
    return formNamesArray.reduce((memo: any, name: string) => ({
      ...memo,
      [name]: forms.selectors[name].getFormWithNulls(state),
      [`${name}Errors`]: forms.selectors[name].getErrors(state),
      [`${name}IsValid`]: forms.selectors[name].getIsValid(state),
    }), {});
  };

  const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
    return formNamesArray.reduce((memo: any, name: string) => ({
      ...memo,
      [`${name}Actions`]: bindActionCreators(forms.actions[name], dispatch),
    }), {});
  };
  return connect(mapStateToProps, mapDispatchToProps) as any;
}

export function withRestify(
  modelName: string | string[],
  formName: string | string[],
): <T extends {}>(WrappedComp: React.ComponentType<T>) => React.ComponentType<Partial<T> & Record<string, any>> {
  return flow(
    withRestifyModels(modelName),
    withRestifyForm(formName),
  );
}

export const createNestedFormActions = (formActions: RestifyFormActions, formPath: FormPath): RestifyFormActions => {
  const arrayFormPath = Array.isArray(formPath) ? formPath : [formPath];
  return Object.keys(formActions).reduce<RestifyFormActions>((memo, action) => {
    return {
      ...memo,
      [action]: (...args: any[]) => {
        const newArgs = [...args];
        newArgs[0] = arrayFormPath.concat(args[0]);
        return formActions[action](...newArgs);
      },
    };
  }, {} as any);
};

export function useRestifyForm<T>(formName: string): [
  T,
  RestifyFormActions<T>,
  RestifyFormErrors<T>,
  boolean,
] {
  const getFormSelector = React.useRef<FormSelectors['getFormWithNulls']>(forms.selectors.getFormWithNulls(formName));
  const getFormErrorsSelector = React.useRef<FormSelectors['getErrors']>(forms.selectors.getErrors(formName));
  const getFormIsValidSelector = React.useRef<FormSelectors['getIsValid']>(forms.selectors.getIsValid(formName));
  return [
    useSelector((state) => getFormSelector.current<T>(state)),
    forms.getFormActions(formName),
    useSelector((state) => getFormErrorsSelector.current<T>(state)),
    useSelector(getFormIsValidSelector.current),
  ];
}

export function useRestifyModel<T>(modelName: string): [
  RestifyEntityList<T>,
  ApiActions,
] {
  return [
    useSelector((state) => api.selectors.entityManager[modelName].getEntities<T>(state)),
    api.actions.entityManager[modelName],
  ];
}
