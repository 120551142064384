/* eslint-disable max-len */
import React from 'react';

const TransportTypeAuto = () => (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6667 7.5C16.6667 6.45365 16.0178 5.59499 15.182 5.44721L13.9028 1.19583L13.8889 1.15415C13.5903 0.441666 12.9965 0 12.3333 0H4.32985C3.66667 0 3.07291 0.441666 2.77778 1.15415L1.48184 5.44746C0.644938 5.59631 0 6.45452 0 7.5V12.9167H1.04167V13.75C1.04167 14.4417 1.50693 15 2.08333 15C2.65624 15 3.125 14.4417 3.125 13.75V12.9167H13.5417V13.75C13.5417 14.4417 14.0069 15 14.5833 15C15.1562 15 15.625 14.4417 15.625 13.75V12.9167H16.6667V7.5ZM4.85068 1.66667H11.8125C12.2743 1.66667 12.6736 2.02082 12.809 2.54583L13.5544 5.41667H3.10953L3.85763 2.54583C3.99306 2.02082 4.39235 1.66667 4.85068 1.66667ZM3.125 10.8333C2.35763 10.8333 1.73611 10.0875 1.73611 9.16667C1.73611 8.24999 2.35763 7.5 3.125 7.5C3.88889 7.5 4.51389 8.24999 4.51389 9.16667C4.51389 10.0875 3.88889 10.8333 3.125 10.8333ZM11.1111 10C11.1111 10.4583 10.7986 10.8333 10.4167 10.8333H6.25C5.86459 10.8333 5.55556 10.4583 5.55556 10V8.83748C5.55556 8.62083 5.64584 8.42499 5.79514 8.30833L6.71874 7.6C6.80207 7.53334 6.8993 7.5 6.99999 7.5H9.69096C9.7986 7.5 9.89931 7.54166 9.98959 7.61248L10.8889 8.36667C11.0278 8.48333 11.1111 8.675 11.1111 8.87917V10ZM12.1528 9.16667C12.1528 8.24999 12.7743 7.5 13.5417 7.5C14.3056 7.5 14.9306 8.24999 14.9306 9.16667C14.9306 10.0875 14.3056 10.8333 13.5417 10.8333C12.7743 10.8333 12.1528 10.0875 12.1528 9.16667Z" fill="#55A023" />
  </svg>
);

export default TransportTypeAuto;
