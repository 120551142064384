import React, { FC } from 'react';
import classNames from 'classnames';
import { RestifyEntityList } from 'redux-restify';

import { WithTranslate } from 'src/i18n';
import {
  ProjectCreator,
  PageProjectForm,
  ProjectModel,
  ProjectsTable,
} from 'src/modules/general/projects';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  form: PageProjectForm;
  entities: RestifyEntityList<ProjectModel>;
  loadProject(project: ProjectModel): void;
  selectProject(project: ProjectModel): void;
  resetSelectedProject(): void;
  deleteProject(project: ProjectModel): void;
  onSuccess(project: ProjectModel): void;
}

const TrafficProjectsPage: FC<Props> = (props) => {
  const {
    className,
    form,
    entities,
    t,
    onSuccess,
    ...rest
  } = props;

  return (
    <div className={classNames(style.root, className)}>
      <div className={style.loadFileMessage}>
        <span>{t('systems.traffic.captions.loadFileForMessagePart1')}</span>
        <span>{t('systems.traffic.captions.loadFileForMessagePart2')}</span>
      </div>
      <ProjectCreator
        className={style.projectCreator}
      />
      <span className={style.title}>{t('modules.projects.captions.tableTitle')}</span>
      <ProjectsTable
        hasStatus
        form={form}
        entities={entities}
        t={t}
        onSuccess={onSuccess}
        {...rest}
      />
    </div>
  );
};

export default TrafficProjectsPage;
