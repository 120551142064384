import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { api, forms } from 'redux-restify';

import { ProcessStatus } from 'src/types';
import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';
import history from 'src/history';
import { PageProjectForm, ProjectModel } from 'src/modules/general/projects';
import { PROJECTS_PAGE_FORM_NAME, PROJECTS_MODEL_NAME } from 'src/modules/traffic/projects';

import TrafficProjectsPage from './TrafficProjectsPage';

const mapStateToProps = (state: AppState) => ({
  form: forms.selectors[PROJECTS_PAGE_FORM_NAME].getFormWithNulls<PageProjectForm>(state),
  entities: api.selectors.entityManager[PROJECTS_MODEL_NAME].getEntities<ProjectModel>(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadProject: (project: ProjectModel) => {
    dispatch(api.actions.entityManager[PROJECTS_MODEL_NAME].loadById(project.id, {
      preventLoad: false,
      forceLoad: true,
    }));
  },
  selectProject: (project: ProjectModel) => {
    if (project.loadStatus === ProcessStatus.SUCCESS) {
      dispatch(forms.actions[PROJECTS_PAGE_FORM_NAME].changeField('selectedProject', project.id));
    }
  },
  resetSelectedProject: () => {
    dispatch(forms.actions[PROJECTS_PAGE_FORM_NAME].resetField('selectedProject'));
  },
  deleteProject: (project: ProjectModel) => {
    dispatch(api.actions.entityManager[PROJECTS_MODEL_NAME].deleteById(project.id));
  },
  onSuccess: (project: ProjectModel) => {
    dispatch(forms.actions[PROJECTS_PAGE_FORM_NAME].changeField('selectedProject', project.id));
    history.push('/traffic/map');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(TrafficProjectsPage));
