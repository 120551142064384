import React from 'react';
import { useSelector } from 'react-redux';
import { api } from 'redux-restify';

import PButton from 'src/components/deprecated/PButton';
import PSlider from 'src/components/deprecated/PSlider';

import { WithTranslate } from 'src/i18n';
import { INPUT_TYPES } from 'src/components/deprecated/PInput';
import { CALCULATIONS_PAGES_FORM_NAME } from 'src/modules/mth/calculations';
import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components/toolbar';
import { useRestifyForm } from 'src/helpers';
import { AGGLOMERATION_CALCULATIONS_ENDPOINT } from 'src/api-url-schema';
import { PageProjectForm, PROJECTS_PAGES_FORM_NAMES } from 'src/modules/general/projects';

import { Systems } from 'src/constants';
import { PageAgglomerationCalculationForm } from '../../interfaces';
import { useHandlers } from './hooks';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const AggloCalcToolbar: React.FC<Props> = (props: Props) => {
  const { className, t } = props;
  const [projectForm] = useRestifyForm<PageProjectForm>(PROJECTS_PAGES_FORM_NAMES[Systems.mth]);
  const [form, formActions] = useRestifyForm<PageAgglomerationCalculationForm>(CALCULATIONS_PAGES_FORM_NAME);
  const { downloading } = useSelector(state => {
    return api.selectors.loadsManager.getUrl(AGGLOMERATION_CALCULATIONS_ENDPOINT)(state);
  });
  const {
    handleTrafficLimitChange,
    handleMaxAlternativeRoutesChange,
    handleStartCalculationClick,
  } = useHandlers(form, formActions);

  return (
    <Toolbar className={className}>
      <ToolbarBlock title={t('common.captions.calculation')} size="x-small">
        <ToolbarControls>
          <ToolbarControl>
            <PSlider {...{
              name: 'trafficLimit',
              onChange: handleTrafficLimitChange,
              label: t('systems.mth.captions.maxBandwidth'),
              value: form.trafficLimit,
            }} />
          </ToolbarControl>
          <ToolbarControl>
            <PSlider {...{
              name: 'maxAlternativeRoutes',
              onChange: handleMaxAlternativeRoutesChange,
              label: t('systems.mth.captions.maxAlternativeRoutes'),
              value: form.maxAlternativeRoutes,
              valueType: INPUT_TYPES.int,
              min: 0,
              max: 5,
            }} />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock size="x-large">
        <ToolbarControls align="right">
          <ToolbarControl>
            <PButton {...{
              className: style.button,
              onClick: handleStartCalculationClick,
              disabled: !projectForm.selectedProject || downloading,
            }}>
              {t('common.captions.start')}
            </PButton>
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default AggloCalcToolbar;
