import { useMemo } from 'react';

import { makeMapStyleEdgeLayer } from 'src/cluster/common';
import { TRAFFIC_COLOR } from 'src/cluster/distribution-map';

import useHeatRange from './useHeatRange';

const useHeatByVehicleTypesLayers = (
  id: number,
  visible: boolean,
  deps: any[],
) => {
  const range = useHeatRange();
  return useMemo(() => {
    if (!id) {
      return makeMapStyleEdgeLayer(
        'edges-heat-hidden-vehicle-types',
        false,
        ['all'],
        {
          'line-color': 'transparent',
          'line-width': 0,
          'line-offset': 0,
        },
      );
    }

    if (id === -1) {
      return makeMapStyleEdgeLayer(
        'edges-heat-total-vehicle-types',
        visible,
        ['>=', ['get', 'passenger_traffic_relative'], 0],
        {
          'line-color': [
            'case',
            ...range.reduce<any[]>((memo, item) => {
              return memo.concat([
                [
                  'all',
                  ['>', ['get', 'passenger_traffic_relative'], item.from],
                  ['<=', ['get', 'passenger_traffic_relative'], item.to],
                ],
                item.value,
              ]);
            }, []),
            TRAFFIC_COLOR[1],
          ],
          'line-width': 8,
          'line-offset': 4,
        },
      );
    }

    return makeMapStyleEdgeLayer(
      'edges-heat-vehicle-types',
      visible,
      ['has', ['to-string', id], ['get', 'vehicle_type_traffic_relative']],
      {
        'line-color': [
          'case',
          ...range.reduce<any[]>((memo, item) => {
            return memo.concat([
              [
                'all',
                ['>', ['get', ['to-string', id], ['get', 'vehicle_type_traffic_relative']], item.from],
                ['<=', ['get', ['to-string', id], ['get', 'vehicle_type_traffic_relative']], item.to],
              ],
              item.value,
            ]);
          }, []),
          TRAFFIC_COLOR[1],
        ],
        'line-width': 8,
        'line-offset': 4,
      },
    );
  }, [...deps, visible, id]);
};

export default useHeatByVehicleTypesLayers;
