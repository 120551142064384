import { StrictDict } from 'src/types';

export const DISTRIBUTION_EDGES_GEOJSON_ENDPOINT = 'routes-distribution/aggregations/edge-traffic/geojson/';
export const DISTRIBUTION_EDGES_DAY_GEOJSON_ENDPOINT = 'routes-distribution/aggregations/edge-day-traffic/geojson/';
export const DISTRIBUTION_STOPS_GEOJSON_ENDPOINT = 'routes-distribution/aggregations/stop-traffic/geojson/';
export const DISTRIBUTION_STOPS_DAY_GEOJSON_ENDPOINT = 'routes-distribution/aggregations/stop-day-traffic/geojson/';
export const DISTRIBUTION_EXTREME_ENDPOINT = 'extreme/';

export const TRAFFIC_COLOR: StrictDict<number, string> = {
  1: '#2ac048',
  2: '#62cc42',
  3: '#f0e934',
  4: '#f0e933',
  5: '#f16a6a',
  6: '#b60c0c',
  7: '#790000',
};

export const TRAFFIC_WIDTH: StrictDict<number, number> = {
  1: 3,
  2: 5,
  3: 8,
  4: 12,
  5: 16,
  6: 22,
  7: 28,
};

export const TRAFFIC_RADIUS: StrictDict<number, number> = {
  1: 5,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
};
