import React, { FC } from 'react';
import { ErrorText, ErrorIcon } from 'src/components';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    backgroundColor: 'rgba(229, 41, 60, 0.2)',
    padding: theme.spacing(0, 2),
  },
  icon: {
    padding: theme.spacing(0.5),
    lineHeight: '10px',
    color: theme.palette.primary.main,
  },
}));

interface Props {
  id: string;
  text: string;
}

const EditorErrorMessage: FC<Props> = (props) => {
  const { id, text } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.icon}>
        <ErrorIcon />
      </span>
      <ErrorText id={id} errorText={text} />
    </div>
  );
};

export default EditorErrorMessage;
