import { createStyles, makeStyles, Theme } from '@material-ui/core';

const filterHeight = 275;
const graphToggleHeight = 56;

export default makeStyles(({ spacing, palette }: Theme) => createStyles({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },

  filterContainer: {
    height: filterHeight,
    transitionProperty: 'height',
    transitionDuration: '0.2s',
    transitionDelay: '0.1s',
  },

  graphToggleContainer: {
    height: graphToggleHeight,
    display: 'flex',
    padding: spacing(4),
    alignItems: 'center',
  },

  icon: {
    marginRight: spacing(2),
  },

  treeContainer: {
    display: 'none',
    maxHeight: `calc(100% - ${filterHeight}px)`,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#9bb1b7',
    backgroundColor: palette.common.white,
    transitionProperty: 'max-height',
    transitionDuration: '0.2s',
    transitionDelay: '0.1s',
  },

  edgeTree: {
    maxHeight: `calc(100% - ${graphToggleHeight}px)`,
  },

  show: {
    display: 'block',
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 175,
  },
}));
