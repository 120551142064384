import React from 'react';

const FilterIcon = () => (
  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0H10.0455V1.24342H10.0008L6.13889 5.54567V13L3.90657 10.5132V5.53945L0.0502272 1.24342H0V0Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterIcon;
