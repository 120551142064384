import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipPayload,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import map from 'lodash/map';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import NumberFormat from 'src/components/etc/NumberFormat';
import { getRange } from 'src/helpers';

import { ForecastChartRecord } from 'src/modules/matrix/forecast';
import { WithTranslate, TFunction } from 'src/i18n';

import style from './index.module.css';

const lines = [
  {
    dataKey: 'airTransport',
    stroke: '#0066fe',
  },
  {
    dataKey: 'busTransport',
    stroke: '#c46b00',
  },
  {
    dataKey: 'motorTransport',
    stroke: '#9b273f',
  },
  {
    dataKey: 'speedMotorTransport',
    stroke: '#c30808',
  },
  {
    dataKey: 'railSuburbanTransport',
    stroke: '#00adc4',
  },
  {
    dataKey: 'railPlatzTransport',
    stroke: '#dec90f',
  },
  {
    dataKey: 'railCoupeTransport',
    stroke: '#9b198c',
  },
  {
    dataKey: 'railSpeedTransport',
    stroke: '#069b1e',
  },
  {
    dataKey: 'railHighSpeedTransport',
    stroke: '#17cb82',
  },
  {
    dataKey: 'allTransport',
    stroke: '#555357',
  },
];

interface ForecastChartProps {
  buttons?: any;
  data: ForecastChartRecord[];
  isLoading: boolean;
  showAirTransport: boolean;
  showBusTransport: boolean;
  showMotorTransport: boolean;
  showRailCoupeTransport: boolean;
  showRailHighSpeedTransport: boolean;
  showRailPlatzTransport: boolean;
  showRailSpeedTransport: boolean;
  showRailSuburbanTransport: boolean;
  showSpeedMotorTransport: boolean;
}

type Props = ForecastChartProps & WithTranslate & {
  className?: string;
}

type DataKey = (
  'airTransport' | 'busTransport' | 'motorTransport' | 'railCoupeTransport' | 'railHighSpeedTransport'
  | 'railPlatzTransport' | 'railSpeedTransport' | 'railSuburbanTransport' | 'speedMotorTransport'
)

interface ExtTooltipPayload extends TooltipPayload {
  dataKey: DataKey;
  value: number;
}

function getYTicks(data: ForecastChartRecord[]) {
  const values = data.flatMap(n => ([
    n.airTransport,
    n.busTransport,
    n.motorTransport,
    n.speedMotorTransport,
    n.railSuburbanTransport,
    n.railPlatzTransport,
    n.railCoupeTransport,
    n.railSpeedTransport,
    n.railHighSpeedTransport,
    n.allTransport,
  ])).filter(v => v !== undefined);
  return getRange(values, 9);
}

const renderLineLabel = (locale: string, t: TFunction) => (payload: ExtTooltipPayload) => {
  const { dataKey, color, value } = payload;
  const title = t(`systems.matrix.meansOfTransport.${dataKey}`);
  return (
    <p key={dataKey} style={{ color }}>
      <span>{title}</span>
      {': '}
      <NumberFormat
        value={value}
        locale={locale}
        decimalScale={0}
      />
    </p>
  );
};

const renderTooltip = (locale: string, t: TFunction) => (props: TooltipProps) => {
  const { label, payload } = props;
  return (
    <div className={style.tooltip}>
      <p className={style.tooltipLabel}>{label}</p>
      {map(payload, renderLineLabel(locale, t))}
    </div>
  );
};

const ForecastChart: FC<Props> = (props: Props) => {
  const {
    className,
    data,
    isLoading,
    showAirTransport,
    showBusTransport,
    showMotorTransport,
    showRailCoupeTransport,
    showRailHighSpeedTransport,
    showRailPlatzTransport,
    showRailSpeedTransport,
    showRailSuburbanTransport,
    showSpeedMotorTransport,
    locale,
    t,
  } = props;

  const visibleLines = lines.filter((line) => {
    if (line.dataKey === 'airTransport') return showAirTransport;
    if (line.dataKey === 'busTransport') return showBusTransport;
    if (line.dataKey === 'motorTransport') return showMotorTransport;
    if (line.dataKey === 'speedMotorTransport') return showSpeedMotorTransport;
    if (line.dataKey === 'railSuburbanTransport') return showRailSuburbanTransport;
    if (line.dataKey === 'railPlatzTransport') return showRailPlatzTransport;
    if (line.dataKey === 'railCoupeTransport') return showRailCoupeTransport;
    if (line.dataKey === 'railSpeedTransport') return showRailSpeedTransport;
    if (line.dataKey === 'railHighSpeedTransport') return showRailHighSpeedTransport;
    return true;
  }, []);

  const tooltipContent = useMemo(() => renderTooltip(locale, t), [locale, t]);

  const yTicks = getYTicks(data);

  return (
    <LoadingBlockContainer
      isBlocked={isLoading}
      className={classNames(style.root, className)}
    >
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={data}>
          <XAxis
            domain={['dataMin', 'dataMax']}
            padding={{ left: 20, right: 20 }}
            dataKey="year"
            angle={30}
            height={48}
            tickMargin={20}
            tickSize={0}
          />
          <YAxis
            allowDecimals={false}
            type="number"
            domain={[dataMax => Math.floor(dataMax), dataMax => Math.ceil(dataMax)]}
            padding={{ top: 20, bottom: 20 }}
            tickSize={0}
            tickMargin={10}
            ticks={yTicks}
          />
          <CartesianGrid strokeWidth="1px" />
          <Tooltip content={tooltipContent} />
          {visibleLines.map((line) => (
            <Line
              key={line.dataKey}
              legendType="rect"
              dataKey={line.dataKey}
              stroke={line.stroke}
              strokeWidth={3}
              dot={{
                r: 4, stroke: line.stroke, fill: '#ffffff', strokeWidth: 3,
              }}
              activeDot={{
                r: 6, stroke: line.stroke, fill: '#ffffff', strokeWidth: 4,
              }}
              isAnimationActive={false}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </LoadingBlockContainer>
  );
};

export default ForecastChart;
