import { ModelDefaults } from 'src/types';

import { AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME } from 'src/cluster/common';

import { DISTRIBUTION_ROUTES_DAY_ENDPOINT, AcDistRoutesDayModel } from 'src/cluster/distribution-table';

const defaults: ModelDefaults<AcDistRoutesDayModel> = {
  id: undefined,
  routeVariantId: undefined,
  vehicleTypeId: undefined,
  variantNumber: undefined,
  variantName: undefined,
  distance: undefined,
  passengerTrafficTotal: undefined,
  peakFillingPerVehicleAbs: undefined,
};

export default {
  endpoint: DISTRIBUTION_ROUTES_DAY_ENDPOINT,
  parent: [AC_PROJECTS_MODEL_NAME, AC_MATRIX_FORECAST_INFO_MODEL_NAME],
  idField: () => Math.random(),
  defaults,
  clearPagesOnRouteChange: false,
  forceLoad: true,
  allowIdRequests: false,
};
