import { createSelector } from 'reselect';
import { api, forms, RestifyModel } from 'redux-restify';

import { createEntitySelector, createFormSelector } from 'src/helpers';

import {
  AcBalanceShapesModel,
  AcBalanceDataFactorsModel,
  AcPageBalanceShapeForm,
  AcProjectForm,
  AC_PROJECT_LIST_FORM_NAME,
  AC_PROJECTS_MODEL_NAME,
  AC_DATA_FACTORS_MODEL_NAME,
} from 'src/cluster/common';
import {
  AC_SHAPES_MODEL_NAME,
  AC_SHAPES_PAGE_FORM_NAME,
} from 'src/cluster/shapes';

export const selectAcProjectPageForm = createFormSelector<AcProjectForm>(AC_PROJECT_LIST_FORM_NAME);
export const selectAcDataShapeForm = createFormSelector<AcPageBalanceShapeForm>(AC_SHAPES_PAGE_FORM_NAME);
export const selectAcDataShapesEntity = createEntitySelector<AcBalanceShapesModel>(AC_SHAPES_MODEL_NAME);
export const selectAcDataFactorsEntity = createEntitySelector<AcBalanceDataFactorsModel>(AC_DATA_FACTORS_MODEL_NAME);

export const selectPageForm = createSelector(
  selectAcDataShapeForm,
  (pageShapesForm: AcPageBalanceShapeForm) => pageShapesForm,
);

export const selectAcShapes = createSelector(
  [
    selectAcProjectPageForm,
    selectAcDataShapeForm,
    selectAcDataShapesEntity,
  ],
  (pageProjectsForm, pageShapesForm, shapesEntities): [RestifyModel<AcBalanceShapesModel>[], boolean, number] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
        0,
      ];
    }
    const shapesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
      filter: {
        page: pageShapesForm.currentPage,
        pageSize: pageShapesForm.pageSize,
      },
    };
    return [
      shapesEntities.getArray(shapesApiConfig),
      shapesEntities.getIsLoadingArray(shapesApiConfig),
      shapesEntities.getCount(shapesApiConfig),
    ];
  },
);

export const selectAcShapesFactors = createSelector(
  [
    selectAcProjectPageForm,
    selectAcDataFactorsEntity,
  ],
  (pageProjectsForm, factorsEntities): [RestifyModel<AcBalanceDataFactorsModel>[], boolean, number] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
        0,
      ];
    }
    const factorsApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
    };
    return [
      factorsEntities.getArray(factorsApiConfig),
      factorsEntities.getIsLoadingArray(factorsApiConfig),
      factorsEntities.getCount(factorsApiConfig),
    ];
  },
);

export const selectBalanceShapes = createSelector(
  [
    (state) => forms.selectors[AC_PROJECT_LIST_FORM_NAME].getFormWithNulls<AcProjectForm>(state),
    (state) => forms.selectors[AC_SHAPES_PAGE_FORM_NAME].getFormWithNulls<AcPageBalanceShapeForm>(state),
    (state) => api.selectors.entityManager[AC_SHAPES_MODEL_NAME].getEntities<AcBalanceShapesModel>(state),
  ],
  (pageProjectsForm, pageShapesForm, shapesEntities): [RestifyModel<AcBalanceShapesModel>[], boolean, number] => {
    if (!pageProjectsForm.selectedProject) {
      return [
        [],
        false,
        0,
      ];
    }
    const shapesApiConfig = {
      parentEntities: {
        [AC_PROJECTS_MODEL_NAME]: pageProjectsForm.selectedProject,
      },
      filter: {
        page: pageShapesForm.currentPage,
        pageSize: pageShapesForm.pageSize,
      },
    };
    return [
      shapesEntities.getArray(shapesApiConfig),
      shapesEntities.getIsLoadingArray(shapesApiConfig),
      shapesEntities.getCount(shapesApiConfig),
    ];
  },
);
