export enum TopColumns {
  vehicleType = 'vehicleType',
  vehicleTypes = 'vehicleTypes',
  routeNumber = 'routeNumber',
  routeLongName = 'routeLongName',
  distance = 'distance',
  regularTransportationType = 'regularTransportationType',
  communicationType = 'communicationType',
  season = 'season',
  carrier = 'carrier',
  total = 'total',
  passengerTraffic = 'passengerTraffic',
  passengerTrafficTotal = 'passengerTrafficTotal',
  peakFillingPerVehicleNorm = 'peakFillingPerVehicleNorm',
  peakFillingPerVehicleAbs = 'peakFillingPerVehicleAbs',
  passengerTrafficPeak = 'passengerTrafficPeak',
  inPeakSection = 'inPeakSection',
  fillingForOneVehicle = 'fillingForOneVehicle',
  stopName = 'stopName',
  coordinates = 'coordinates',
  startTrip = 'startTrip',
  afterTransfer = 'afterTransfer',
  allBoarding = 'allBoarding',
  boarding = 'boarding',
  downPassenger = 'downPassenger',
  endOfTrip = 'endOfTrip',
  withSubsequentTransfer = 'withSubsequentTransfer',
  transfer = 'transfer',
  atPeakHour = 'atPeakHour'
}

export enum ModalTopColumns {
  interstopFerry = 'interstopFerry',
  boardingSum = 'boardingSum',
  boardingPerVehicle = 'boardingPerVehicle',
  getOffSum = 'getOffSum',
  getOffPerVehicle = 'getOffPerVehicle',
  passengerTraffic = 'passengerTraffic',
  fillingTheVehicle = 'fillingTheVehicle'
}

export enum AcDistPageFormEnum {
  vehicleTypeId = 'vehicleTypeId',
  routeVariantId = 'routeVariantId',
  stopId = 'stopId',
  vehicleTypeIdStop = 'vehicleTypeIdStop',
  forwardDirectionId = 'forwardDirectionId',
  backwardDirectionId = 'backwardDirectionId',
}
