/* eslint-disable max-len */
import React from 'react';

const Instructions = () => (
  <svg viewBox="0 0 96 96">
    <g>
      <path d="M76,0H20C13.37,0,8,5.37,8,12v72c0,6.63,5.37,12,12,12h56c6.63,0,12-5.37,12-12V12C88,5.37,82.63,0,76,0z M80,84    c0,2.21-1.79,4-4,4H20c-2.21,0-4-1.79-4-4V12c0-2.21,1.79-4,4-4h56c2.21,0,4,1.79,4,4V84z M64,68H32c-2.209,0-4,1.791-4,4    s1.791,4,4,4h32c2.209,0,4-1.791,4-4S66.209,68,64,68z M64,52H32c-2.209,0-4,1.791-4,4s1.791,4,4,4h32c2.209,0,4-1.791,4-4    S66.209,52,64,52z M64,36H32c-2.209,0-4,1.791-4,4s1.791,4,4,4h32c2.209,0,4-1.791,4-4S66.209,36,64,36z M32,28h16    c2.209,0,4-1.791,4-4s-1.791-4-4-4H32c-2.209,0-4,1.791-4,4S29.791,28,32,28z" />
    </g>
  </svg>
);

export default Instructions;
