import React, { FC, ReactNode } from 'react';
import { RestifyModel } from 'redux-restify';

import { WithTranslate } from 'src/i18n';

import { TableIcon } from 'src/components/icons';
import PButton from 'src/components/deprecated/PButton';
import PTable from 'src/components/deprecated/PTable';
import SmartDate, { SMART_DATE_FORMATS } from 'src/components/etc/SmartDate';

import { AcBalanceShapesModel, AcPageBalanceShapeForm, TableContainer } from 'src/cluster/common';
import { AC_SHAPE_TYPES_DICT, AC_SHAPE_STATUSES } from 'src/cluster/shapes';
import BalanceShapesTableStatus from './components/BalanceShapesTableStatus';

import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  message?: string;
  buttons?: any;
  entities: [RestifyModel<AcBalanceShapesModel>[], boolean, number];
  isLoading?: boolean;
  pageForm: AcPageBalanceShapeForm;
  statusPlaceholder?: ReactNode;
  selectShape(model: AcBalanceShapesModel): void;
  showShape(model: AcBalanceShapesModel): void;
  onPageChange(value: number): void;
  onPageSizeChange(value: number): void;
}

const BalanceShapesTable: FC<Props> = (props: Props) => {
  const {
    className,
    message,
    entities: [shapes, isLoading, count],
    pageForm: {
      selectedShape,
      pageSize,
      currentPage,
    },
    t,
    selectShape,
    showShape,
    onPageChange,
    onPageSizeChange,
  } = props;

  const header = [
    {
      title: t('common.captions.numberSymbol'),
      model: (model: AcBalanceShapesModel) => model.id,
    },
    {
      title: t('common.captions.name'),
      model: (model: AcBalanceShapesModel) => model.name,
    },
    {
      title: t('common.captions.date'),
      model: (model: AcBalanceShapesModel) => (
        <SmartDate
          format={SMART_DATE_FORMATS.simpleDate}
          date={model.created}
        />
      ),
    },
    {
      title: t('modules.shapes.captions.type'),
      model: (model: AcBalanceShapesModel) => t(AC_SHAPE_TYPES_DICT[model.modelType]),
    },
    {
      title: t('common.captions.status'),
      model: (model: AcBalanceShapesModel) => (
        <BalanceShapesTableStatus shape={model} />
      ),
    },
    {
      title: t('common.captions.user'),
      model: (model: AcBalanceShapesModel) => model.user,
      className: style.lastColumn,
    },
  ];

  return (
    <TableContainer
      isLoading={isLoading}
      className={className}
      message={message}
      pageSize={pageSize}
      currentPage={currentPage}
      count={count}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    >
      <PTable<AcBalanceShapesModel>
        rowClassName={(shape) => [
          selectedShape === shape.id,
          AC_SHAPE_STATUSES.SUCCESS === shape.status && style.successRow,
        ]}
        header={header}
        body={shapes}
        hoverable
        hoveredComponent={
          (shape) => (
            <div className={style.buttonWrapper}>
              <div title={t('modules.shapes.captions.shapeView')}>
                <PButton
                  type="link"
                  onClick={() => showShape(shape)}
                >
                  <TableIcon />
                </PButton>
              </div>
            </div>
          )
        }
        onRowClick={selectShape}
      />
    </TableContainer>
  );
};

export default BalanceShapesTable;
