import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { LegendType } from '../../types';
import { DrawerLegendCheckbox } from '../../interfaces/common';
import { selectEditorLegendForm } from '../../store';

const useLegendParking = (
  setLegendCheckbox: (key: LegendType, checked: boolean) => void,
): DrawerLegendCheckbox => {
  const { isPaidParking } = useSelector(selectEditorLegendForm);

  return {
    type: LegendType.isPaidParking,
    iconProps: { borderBottom: '3px solid #3FA2F7' },
    checked: isPaidParking,
    onChange: (event: ChangeEvent<{}>, checked: boolean) => {
      setLegendCheckbox(LegendType.isPaidParking, checked);
    },
  };
};

export default useLegendParking;
