import { featureCollection, lineString, point } from '@turf/turf';
import { head, last } from 'lodash';
import { RouteModel } from 'src/modules/general/common';

export function makeGeoJsonEdgesWithVirtualEdges(currentRoutes: RouteModel[], edgesExtras: any) {
  return {
    type: 'geojson',
    ...edgesExtras,
    data: featureCollection(currentRoutes.reduce<any[]>((memo, route) => {
      const edges = route.traffic.reduce<any[]>((trafficMemo, edge) => {
        if (edge.edgeId < 0) {
          return trafficMemo;
        }
        const edgePath = edge.detailed && edge.detailed.length ? edge.detailed : [edge.firstNode, edge.secondNode];
        const routeEdges = [];
        for (let detailedIndex = 0; detailedIndex < edgePath.length - 1; detailedIndex += 1) {
          const firstNode = edgePath[detailedIndex];
          const secondNode = edgePath[detailedIndex + 1];
          routeEdges.push(lineString(
            [firstNode, secondNode],
            { isEdge: true, routeId: route.id, ...edge },
          ));
        }
        return trafficMemo.concat(routeEdges);
      }, []);
      return memo.concat(edges);
    }, [])),
  };
}

export function makeGeoJsonEdgesPointsWithVirtualEdges(routes: RouteModel[]) {
  return {
    type: 'geojson',
    data: featureCollection(
      routes.reduce(
        (memo: any[], route: RouteModel, routeIndex) => {
          const edges = route.traffic.filter((edge: any) => edge.edgeId !== -1);
          const points = [];
          const firstEdge = head(edges);
          if (firstEdge) {
            points.push(point(firstEdge.firstNode, {
              ...firstEdge,
              routeIndex,
              isCorrespondence: true,
              isStart: true,
            }));
          }
          const lastEdge = last(edges);
          if (lastEdge) {
            points.push(point(lastEdge.secondNode, {
              ...lastEdge,
              routeIndex,
              isCorrespondence: true,
              isFinish: true,
            }));
          }
          return memo.concat(points);
        }, [],
      ),
    ),
  };
}
