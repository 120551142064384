import { MODAL_SIZES, registerModal } from 'src/modals';

import { AppState } from 'src/reducer';
import {
  AC_DISTRIBUTION_MODAL_NAME,
  distPageFormActions,
  ModalTableDistribution,
} from 'src/cluster/distribution-table';
import { Dispatch } from 'redux';

const stateToProps = (state: AppState, props: any) => ({
  ...props,
  fitContent: true,
  size: MODAL_SIZES.large,
});

const dispatchToProps = (dispatch: Dispatch<any>) => ({
  afterClose: () => {
    dispatch(distPageFormActions.resetSomeFields(['forwardDirectionId', 'backwardDirectionId']));
  },
});

export default registerModal(
  AC_DISTRIBUTION_MODAL_NAME,
  stateToProps,
  dispatchToProps,
)(ModalTableDistribution);
