import { useReducer } from 'react';
import { RestifyId } from 'redux-restify';

const DELETE = 'DELETE';
const UNDELETE = 'UNDELETE';
const RESET = 'RESET';

const initialDeleteState: RestifyId[] = [];

const deletingReducer = (state: RestifyId[], { type, payload }: { type: string; payload?: RestifyId }) => {
  switch (type) {
    case DELETE: {
      if (!payload) {
        throw new Error('Unexpected payload');
      }
      return [...state, payload];
    }
    case UNDELETE: {
      if (!payload) {
        throw new Error('Unexpected payload');
      }
      return state.filter((id) => id !== payload);
    }
    case RESET: {
      return initialDeleteState;
    }
    default: {
      throw new Error(`Unexpected action: ${type}`);
    }
  }
};

function deleteDataAction(id: RestifyId) {
  return {
    type: DELETE,
    payload: id,
  };
}

function resetAction() {
  return {
    type: RESET,
  };
}

export default function useDelete(): [RestifyId[], (id: RestifyId) => void, () => void] {
  const [state, dispatch] = useReducer(deletingReducer, initialDeleteState);

  const deleteData = (id: RestifyId) => dispatch(deleteDataAction(id));
  const reset = () => dispatch(resetAction());

  return [state, deleteData, reset];
}
