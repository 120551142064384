import React from 'react';

const FunicularIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33677 12.4542L13.6568 10.4694C13.7502 10.4514 13.837 10.4085 13.908 10.3451C13.9789 10.2817 14.0313 10.2002 14.0595 10.1094C14.52 8.62633 14.6074 7.0526 14.3141 5.52767C14.0209 4.00274 13.3559 2.57372 12.3782 1.36728C12.3183 1.29318 12.2395 1.23674 12.1501 1.20399C12.0606 1.17124 11.964 1.16341 11.8704 1.18133L1.55042 3.16617C1.45699 3.1841 1.37019 3.22705 1.29927 3.29045C1.22834 3.35385 1.17596 3.43531 1.1477 3.52615C0.687249 5.00919 0.599789 6.58292 0.893077 8.10785C1.18636 9.63278 1.85134 11.0618 2.82906 12.2682C2.88889 12.3423 2.96773 12.3988 3.05716 12.4315C3.14659 12.4643 3.24324 12.4721 3.33677 12.4542ZM6.86961 8.56414L3.7736 9.15959C3.63675 9.18591 3.49504 9.15679 3.37966 9.07863C3.26428 9.00047 3.18468 8.87968 3.15836 8.74283L2.5629 5.64682C2.53658 5.50997 2.56571 5.36827 2.64386 5.25289C2.72202 5.13751 2.84281 5.0579 2.97966 5.03158L6.07567 4.43613C6.21252 4.40981 6.35423 4.43893 6.46961 4.51709C6.58499 4.59524 6.66459 4.71604 6.69092 4.85289L7.28637 7.9489C7.31269 8.08575 7.28356 8.22745 7.20541 8.34283C7.12725 8.45821 7.00646 8.53782 6.86961 8.56414ZM4.09112 8.02835L6.15512 7.63138L5.75815 5.56737L3.69415 5.96434L4.09112 8.02835ZM8.93351 8.1673L12.0295 7.57185C12.1664 7.54553 12.2872 7.46592 12.3653 7.35054C12.4435 7.23516 12.4726 7.09346 12.4463 6.95661L11.8508 3.8606C11.8245 3.72375 11.7449 3.60295 11.6295 3.5248C11.5141 3.44664 11.3724 3.41752 11.2356 3.44384L8.13957 4.03929C8.00272 4.06561 7.88193 4.14522 7.80377 4.2606C7.72561 4.37598 7.69649 4.51768 7.72281 4.65453L8.31826 7.75054C8.34458 7.88739 8.42419 8.00818 8.53957 8.08634C8.65495 8.1645 8.79665 8.19362 8.93351 8.1673ZM11.315 6.63909L9.25102 7.03606L8.85406 4.97205L10.9181 4.57508L11.315 6.63909Z"
      fill="currentColor"
    />
    <path
      d="M5.28676 15.1552C4.97498 15.1552 4.6702 15.0627 4.41097 14.8895C4.15174 14.7163 3.94969 14.4701 3.83038 14.1821C3.71106 13.894 3.67985 13.5771 3.74067 13.2713C3.8015 12.9655 3.95163 12.6846 4.17209 12.4642C4.39255 12.2437 4.67343 12.0936 4.97922 12.0327C5.28501 11.9719 5.60196 12.0031 5.89001 12.1224C6.17805 12.2417 6.42425 12.4438 6.59746 12.703C6.77068 12.9623 6.86313 13.267 6.86313 13.5788C6.86313 13.9969 6.69705 14.3979 6.40142 14.6935C6.10579 14.9891 5.70484 15.1552 5.28676 15.1552ZM5.28676 13.0534C5.18283 13.0534 5.08124 13.0842 4.99483 13.1419C4.90842 13.1997 4.84107 13.2817 4.8013 13.3777C4.76152 13.4737 4.75112 13.5794 4.77139 13.6813C4.79167 13.7833 4.84171 13.8769 4.9152 13.9504C4.98869 14.0239 5.08231 14.0739 5.18424 14.0942C5.28617 14.1145 5.39182 14.104 5.48784 14.0643C5.58385 14.0245 5.66592 13.9572 5.72366 13.8707C5.7814 13.7843 5.81221 13.6827 5.81221 13.5788C5.81221 13.4395 5.75685 13.3058 5.65831 13.2073C5.55977 13.1087 5.42612 13.0534 5.28676 13.0534Z"
      fill="currentColor"
    />
    <path
      d="M12.611 13.7568C12.2992 13.7568 11.9944 13.6643 11.7352 13.4911C11.476 13.3179 11.2739 13.0717 11.1546 12.7836C11.0353 12.4956 11.0041 12.1786 11.0649 11.8728C11.1257 11.5671 11.2758 11.2862 11.4963 11.0657C11.7168 10.8453 11.9977 10.6951 12.3034 10.6343C12.6092 10.5735 12.9262 10.6047 13.2142 10.724C13.5023 10.8433 13.7485 11.0454 13.9217 11.3046C14.0949 11.5638 14.1873 11.8686 14.1873 12.1804C14.1873 12.5985 14.0213 12.9994 13.7256 13.295C13.43 13.5907 13.0291 13.7568 12.611 13.7568ZM12.611 11.6549C12.507 11.6549 12.4055 11.6857 12.319 11.7435C12.2326 11.8012 12.1653 11.8833 12.1255 11.9793C12.0857 12.0753 12.0753 12.181 12.0956 12.2829C12.1159 12.3848 12.1659 12.4784 12.2394 12.5519C12.3129 12.6254 12.4065 12.6755 12.5085 12.6957C12.6104 12.716 12.716 12.7056 12.8121 12.6658C12.9081 12.6261 12.9901 12.5587 13.0479 12.4723C13.1056 12.3859 13.1364 12.2843 13.1364 12.1804C13.1364 12.041 13.0811 11.9074 12.9825 11.8088C12.884 11.7103 12.7503 11.6549 12.611 11.6549Z"
      fill="currentColor"
    />
    <path
      d="M2.67319 16.1176L15.9286 13.4044"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>

);

export default FunicularIcon;
