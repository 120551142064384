import React, { FC } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';

import { CheckboxCheckedIcon, CheckboxIcon } from 'src/components';

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    color = 'primary',
    ...rest
  } = props;

  return (
    <MuiCheckbox
      color={color}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      {...rest}
    />
  );
};

export default Checkbox;
