import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    overflow: 'auto',
    backgroundColor: theme.palette.common.white,
    marginBottom: 60,
    height: '100%',
    flex: 1,
    zIndex: 1,

    '& $hasNoData': {
      marginBottom: 0,
    },
  },

  hasNoData: {},

  message: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: ffSourceSansPro,
    fontSize: 14,
    fontStyle: 'italic',
    color: theme.palette.grey[700],
    height: '100%',
    margin: theme.spacing(10),
  },

  loader: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    zIndex: 10,
  },

  topProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
