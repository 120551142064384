import { useMemo } from 'react';

import { SPEED_COLORS } from 'src/cluster/common';

const useSpeedRange = () => {
  return useMemo(() => [0, 5, 10, 25].map((value, i, arr) => (
    {
      from: value,
      to: arr[i + 1] || Infinity,
      value: SPEED_COLORS[i + 1],
    }
  )), []);
};

export default useSpeedRange;
