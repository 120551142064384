import React from 'react';

const ArticlesSidebar = () => (
  <svg viewBox="0 0 18 14">
    <rect fill="currentColor" x="0" y="0" width="18" height="2" />
    <rect fill="currentColor" x="0" y="6" width="18" height="2" />
    <rect fill="currentColor" x="0" y="12" width="18" height="2" />
  </svg>
);

export default ArticlesSidebar;
