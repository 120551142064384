import React, { ChangeEvent, FC } from 'react';
import { Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import {
  Button,
  SelectField,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components';

import {
  useAcIntervalOptions,
  useAcYearOptions,
} from 'src/cluster/common';
import { useEditorGraphParams, useEditorScenarioOptions } from 'src/cluster/editor-common';

import useStyles from './styles';

export interface InjectedProps {
  setYear(event: ChangeEvent<{ value: unknown }>): void;
  setScenario(event: ChangeEvent<{ value: unknown }>): void;
  setInterval(event: ChangeEvent<{ value: unknown }>): void;
  close(): void;
}

type Props = InjectedProps & WithTranslate;

const ModalEditorGraphVersion: FC<Props> = (props) => {
  const {
    t,
    setYear,
    setScenario,
    setInterval,
    close,
  } = props;

  const classes = useStyles();

  const yearOptions = useAcYearOptions();
  const scenarioOptions = useEditorScenarioOptions();
  const intervalOptions = useAcIntervalOptions();

  const [year, scenarioId, intervalId] = useEditorGraphParams();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t('systems.editor.captions.graphVersion')}</Typography>
        <Typography>{t('systems.editor.messages.selectYearScenarioInterval')}</Typography>
      </div>
      <ToolbarBlock className={classes.container}>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <SelectField
              label={t('common.captions.year')}
              value={year}
              options={yearOptions}
              fullWidth
              onChange={setYear}
            />
          </ToolbarControl>
        </ToolbarControls>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <SelectField
              label={t('common.captions.scenario')}
              value={scenarioId}
              options={scenarioOptions}
              disabled={!year}
              fullWidth
              onChange={setScenario}
            />
          </ToolbarControl>
        </ToolbarControls>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <SelectField
              label={t('common.captions.interval')}
              value={intervalId}
              options={intervalOptions}
              disabled={!year || !scenarioId}
              fullWidth
              sort={false}
              onChange={setInterval}
            />
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock className={classes.footer}>
        <ToolbarControls className={classes.select} align="right">
          <Button onClick={close}>{t('common.captions.apply')}</Button>
        </ToolbarControls>
      </ToolbarBlock>
    </div>
  );
};

export default ModalEditorGraphVersion;
