import React, { useCallback } from 'react';
import clsx from 'clsx';
import { RestifyFormActions } from 'redux-restify';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';

import { AVAILABLE_LOCALES } from 'src/i18n';
import { WithTranslate } from 'src/i18n/hoc/withTranslate';
import { capitalize } from 'src/helpers';
import { LocaleServiceForm } from '../../forms/localeService.form';

import useStyles from './styles';

export interface OwnProps {
  className?: string;
}

interface Props extends OwnProps, WithTranslate {
  localeServiceForm: LocaleServiceForm;
  localeServiceFormActions: RestifyFormActions<LocaleServiceForm>;
}

const LocaleSwitch: React.FC<Props> = ({
  className,
  localeServiceForm,
  localeServiceFormActions,
  setLocale,
}) => {
  const classes = useStyles();

  const currentLocale = localeServiceForm.selectedLocale;
  const sortedLocales = AVAILABLE_LOCALES.sort((a, b) => {
    if (a === currentLocale) {
      return -1;
    }
    if (b === currentLocale) {
      return 1;
    }
    return 0;
  });

  const handleClick = useCallback(() => {
    const newLocale = sortedLocales[1];
    localeServiceFormActions.changeField('selectedLocale', newLocale);
    if (AVAILABLE_LOCALES.includes(newLocale)) {
      setLocale(newLocale);
    }
  }, [localeServiceFormActions]);

  return (
    <div
      className={clsx(classes.root, className)}
      onClick={handleClick}
    >
      <PIcon
        type={ICONS_TYPES[`lang${capitalize(currentLocale)}`]}
        className={classes.localeIcon}
      />
      <div className={classes.locales}>
        {sortedLocales.map(locale => (
          <div
            key={locale}
            className={classes.locale}
          >
            {capitalize(locale)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocaleSwitch;
