import React, {
  FC,
  useCallback,
  useState,
} from 'react';
import classNames from 'classnames';

import { ProcessStatus } from 'src/types';
import PTable from 'src/components/deprecated/PTable';
import LoadingBlockContainer from 'src/components/etc/LoadingBlockContainer';
import SmartDate, { SMART_DATE_FORMATS } from 'src/components/etc/SmartDate';
import { EditIcon } from 'src/components/icons';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import UserFilter from 'src/components/etc/UserFilter';

import {
  ProjectModel, ProjectsTableStatus, useStatusUpdate,
} from 'src/modules/general/projects';

import { Props } from './interfaces';

import style from './index.module.css';

const ProjectsTable: FC<Props> = (props: Props) => {
  const {
    className,
    hasStatus = false,
    editable = false,
    form,
    entities,
    loadProject,
    showRemoveConfirmation,
    selectProject,
    renameProject,
    onStatusChange,
    t,
  } = props;

  const { SUCCESS, ERROR } = ProcessStatus;

  const currentProjects = entities.getArray();
  const isLoading = entities.getIsLoadingArray();

  const [userFilter, setUserFilter] = useState<string | undefined>(undefined);
  const users: string[] = currentProjects
    .map(item => item.user)
    .filter((user, index, self) => self.indexOf(user) === index);
  const projects = userFilter ? currentProjects.filter(item => item.user === userFilter) : currentProjects;

  useStatusUpdate(projects, loadProject, hasStatus, onStatusChange, t);

  const createRemoveClickHandler = useCallback(
    (project: ProjectModel) => () => showRemoveConfirmation(project),
    [showRemoveConfirmation],
  );

  return (
    <LoadingBlockContainer {...{
      isBlocked: isLoading,
      className: classNames(style.root, className),
    }}>
      <PTable {...{
        className: style.table,
        headerCellClassName: style.cell,
        tableCellClassName: style.cell,
        rowClassName: (project) => {
          return [
            form.selectedProject === project.id,
            (!hasStatus || SUCCESS === project.loadStatus) && style.successRow,
            (hasStatus && ERROR === project.loadStatus) && style.errorRow,
            style.row,
          ];
        },
        hoverable: true,
        hoveredComponent: (project) => (
          (!hasStatus || SUCCESS === project.loadStatus || ERROR === project.loadStatus) ? (
            <PIcon {...{
              type: ICONS_TYPES.clear,
              className: style.clearIcon,
              onClick: createRemoveClickHandler(project),
            }} />
          ) : null
        ),
        onRowClick: selectProject,
        header: [
          {
            title: t('common.captions.numberSymbol'),
            model: (project) => project.id,
          },
          {
            title: t('common.captions.name'),
            className: style.nameColumn,
            model: (project) => project.name,
            editable,
            icon: <EditIcon />,
            onChange: renameProject,
          },
          {
            title: t('modules.projects.captions.uploadDate'),
            className: style.dateColumn,
            model: (project) => <SmartDate format={SMART_DATE_FORMATS.simpleDateWithTime} date={project.created} />,
          },
          {
            title: t('common.captions.status'),
            className: hasStatus ? undefined : style.hidden,
            model: (project) => (hasStatus ? <ProjectsTableStatus project={project} /> : null),
          },
          {
            title: t('modules.projects.captions.lastUsedDate'),
            className: style.dateColumn,
            model: (project) => (
              <SmartDate
                format={SMART_DATE_FORMATS.simpleDateWithTime}
                date={project.lastUsedDate}
              />
            ),
          },
          {
            title: (
              <span>
                {t('common.captions.user')}
                <UserFilter {...{
                  users,
                  userFilter,
                  setUserFilter,
                }} />
              </span>
            ),
            model: (project) => project.user,
          },
        ],
        body: projects,
      }} />
    </LoadingBlockContainer>
  );
};

export default ProjectsTable;
