import {
  Dispatch, MutableRefObject, SetStateAction, useCallback,
} from 'react';
import { StaticMap } from 'react-map-gl';

import i18n from 'src/i18n';
import { Feature, Point, Polygon } from 'src/interfaces';

import { AcBalanceDataModel } from 'src/cluster/common';
import {
  PopupContent,
  selectBalanceMapCorrespondences,
  useFactors,
  ViewModes,
} from 'src/cluster/balance-map';
import { useSelector } from 'react-redux';
import { isArray, isNumber } from 'lodash';

const useDataOnMouseMove = (
  mapRef: MutableRefObject<StaticMap | null>,
  data: AcBalanceDataModel[],
  setPopup: Dispatch<
  SetStateAction<{
    content?: PopupContent | undefined;
    lat: number;
    lng: number;
  }>
  >,
  setHoveredRegion: Dispatch<SetStateAction<string | number | undefined>>,
  hoveredRegion: string | number | undefined,
  regionFrom: number | number[] | undefined,
  regionTo: number[] | undefined,
  mapFactor: string | undefined,
  mode: ViewModes,
) => {
  const correspondences = useSelector(selectBalanceMapCorrespondences).orJust(
    [],
  );
  const factors = useFactors();

  return useCallback(
    ({ point, lngLat: [lng, lat] }): void => {
      if (!mapRef.current) return;

      const features = mapRef.current.queryRenderedFeatures(point);
      if (!features) return;

      const starFeature = features.find((f) => f.properties?.isStar) as
        | Feature<Point>
        | undefined;
      const regionFeature = features.find((f) => f.properties?.is_region) as
        | Feature<Polygon>
        | undefined;

      if (starFeature?.properties) {
        setPopup({
          content: {
            region: starFeature.properties?.regionTo,
            title: i18n.t('systems.balance.captions.trafficFlow'),
            value: starFeature.properties?.traffic,
          },
          lat,
          lng,
        });
      } else if (regionFeature?.properties) {
        if (mode === ViewModes.data) {
          const record = data.find(
            (p) => p.regionId === regionFeature.properties?.region_id,
          );
          const factor = record?.factors.find((f) => f.id === mapFactor);
          const { name } = factors.find((f) => factor?.id === f.id) ?? {};
          setPopup({
            content: {
              region: regionFeature?.properties?.name,
              title: name,
              value: factor?.value,
            },
            lat,
            lng,
          });
        } else {
          const { region_id: regionIdMap } = regionFeature?.properties;
          let correspondenceValue: number | undefined;

          const isOnlyRegionToSelect = Boolean(regionTo?.length);
          const isOnlyRegionFromSelect = isArray(regionFrom)
            ? Boolean(regionFrom.length)
            : Boolean(regionFrom);
          const isBothRegionSelect =
            isOnlyRegionFromSelect && isOnlyRegionToSelect;
          const isMouseOnRegionFrom = isArray(regionFrom)
            ? regionFrom.includes(regionIdMap)
            : regionIdMap === regionFrom;
          const isMouseOnRegionTo = regionTo?.includes(regionIdMap);

          // heartMap case
          if (isNumber(regionFrom)) {
            correspondenceValue = correspondences
              .filter((flow) => flow.regionFromId === regionFrom)
              .find(
                (p) => p.regionToId === regionFeature.properties?.region_id ||
                  p.regionFromId === regionFeature.properties?.region_id,
              )?.traffic;
          } else {
            correspondenceValue = correspondences
              .filter((flow) => {
                if (isBothRegionSelect) return flow;
                return isOnlyRegionToSelect
                  ? regionTo?.includes(flow.regionToId)
                  : regionFrom?.includes(flow.regionFromId);
              })
              .filter((flow) => {
                if (isOnlyRegionFromSelect) {
                  return isMouseOnRegionFrom
                    ? flow.regionFromId === regionIdMap
                    : flow.regionToId === regionIdMap;
                }
                if (isOnlyRegionToSelect) {
                  return isMouseOnRegionTo
                    ? flow.regionToId === regionIdMap
                    : flow.regionFromId === regionIdMap;
                }
                return flow;
              })
              .reduce((acc, flow) => acc + flow.traffic, 0);
          }

          setPopup({
            content: {
              region: regionFeature?.properties?.name,
              title: i18n.t('systems.balance.captions.trafficFlow'),
              value: correspondenceValue,
            },
            lat,
            lng,
          });
        }
        setHoveredRegion(regionFeature.properties.region_id);
      } else {
        if (hoveredRegion) setHoveredRegion(undefined);
        setPopup({ content: undefined, lat: 0, lng: 0 });
      }
    },
    [mode, mapFactor, regionFrom, setHoveredRegion, correspondences, regionTo],
  );
};

export default useDataOnMouseMove;
