import React from 'react';
import classNames from 'classnames';

import pageLayoutStyle from 'src/styles/page-layout.module.css';

import { Region, UNITS } from 'src/modules/general/common';
import NumberFormat from 'src/components/etc/NumberFormat';
import { WithTranslate } from 'src/i18n';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
  region: Region;
  empty?: string;
}

const AggloMapFilterDistrict: React.FC<Props> = (props: Props) => {
  const {
    className,
    region,
    empty = '-',
    t,
  } = props;
  return (
    <div className={classNames(style.root, className)}>
      <div className={pageLayoutStyle.toolbarTitle}>
        {t('systems.mth.captions.districtInfo')}
      </div>
      <table className={style.table}>
        <tbody>
          <tr>
            <td>
              <span>{t('common.captions.name')}</span>
            </td>
            <td>
              {region.name}
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('systems.mth.captions.passengerFlow')}</span>
            </td>
            <td>
              <NumberFormat {...{
                value: region.trafficPass,
                decimalScale: 0,
                suffix: ` ${t(UNITS.peoplePerHour)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('systems.mth.captions.population')}</span>
            </td>
            <td>
              <NumberFormat {...{
                value: region.population,
                decimalScale: 0,
                suffix: ` ${t(UNITS.people)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('systems.mth.captions.jobs')}</span>
            </td>
            <td>
              <NumberFormat {...{
                value: region.workplaces,
                decimalScale: 0,
                suffix: ` ${t(UNITS.people)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('systems.mth.captions.parkingFare')}</span>
            </td>
            <td>
              <NumberFormat {...{
                value: region.parking,
                decimalScale: 0,
                suffix: ` ${t(UNITS.costRub)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AggloMapFilterDistrict;
