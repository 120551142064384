import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';

import { AcForecastCorrespondenceModel } from 'src/cluster/common';
import { selectAcBalanceMapPageForm, useCorrespondences } from 'src/cluster/balance-map';

const useCorrespondencesFromCenter = (): Model<AcForecastCorrespondenceModel>[] => {
  const correspondences = useCorrespondences();
  const { region } = useSelector(selectAcBalanceMapPageForm);
  return correspondences.filter(flow => flow.regionFromId === region && flow.traffic > 0);
};

export default useCorrespondencesFromCenter;
