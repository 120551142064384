export enum ShapeStatuses {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum ShapeTypes {
  GRAVITATIONAL = 'gravitational',
}
