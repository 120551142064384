import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { WithTranslate } from 'src/i18n';
import modals from 'src/modals';
import { useRestifyForm } from 'src/helpers';

import PButton from 'src/components/deprecated/PButton';
import PSelect from 'src/components/deprecated/PSelect';
import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';

import {
  MAP_PAGES_FORM_NAME,
  PageRoutesForm,
  TrafficRoutesMap,
  selectProjectChoices,
  exportData,
  findRoutes,
} from 'src/modules/traffic/map';

import pageLayoutStyle from 'src/styles/page-layout.module.css';
import style from './index.module.css';

interface Props extends WithTranslate {
  className?: string;
}

const TrafficMapPage: FC<Props> = ({
  className,
  t,
}) => {
  const dispatch = useDispatch();
  const [
    pageForm,
    pageFormActions,
  ] = useRestifyForm<PageRoutesForm>(MAP_PAGES_FORM_NAME);

  // clear form once loaded
  useEffect(() => {
    dispatch(pageFormActions.changeField('macroeconomicScenarioActual', undefined));
    dispatch(pageFormActions.changeField('transportScenarioActual', undefined));
    dispatch(pageFormActions.changeField('yearActual', undefined));
    dispatch(pageFormActions.changeField('macroeconomicScenario', undefined));
    dispatch(pageFormActions.changeField('transportScenario', undefined));
    dispatch(pageFormActions.changeField('year', undefined));
  }, []);
  const projectChoices = useSelector(selectProjectChoices);

  return (
    <div className={classNames(style.root, className)}>
      <div className={pageLayoutStyle.toolbar}>
        <div className={classNames(pageLayoutStyle.toolbarBlock, pageLayoutStyle.toolbarBlockLarge)}>

          <div className={pageLayoutStyle.toolbarTitle}>
            {t('systems.traffic.captions.macroeconomicsTitle')}
          </div>
          <div className={classNames(pageLayoutStyle.toolbarControls, pageLayoutStyle.toolbarControlsTop)}>
            <div className={classNames(pageLayoutStyle.toolbarControl, pageLayoutStyle.toolbarControlXLarge)}>
              <PSelect {...{
                label: t('systems.traffic.captions.macroeconomicScenario'),
                className: style.select,
                items: projectChoices.macroeconomicScenarios.map(n => ({
                  value: n.label,
                  label: n.label,
                  description: n.description,
                })),
                values: pageForm.macroeconomicScenario === undefined
                  ? undefined
                  : [pageForm.macroeconomicScenario],
                onChange: values => dispatch(pageFormActions.changeField('macroeconomicScenario', values[0])),
              }} />
            </div>
            <div className={classNames(pageLayoutStyle.toolbarControl, pageLayoutStyle.toolbarControlXLarge)}>
              <PSelect {...{
                label: t('systems.traffic.captions.transportScenarios'),
                className: style.select,
                items: projectChoices.transportScenarios.map(n => ({
                  value: n,
                  label: n,
                })),
                disabled: !pageForm.macroeconomicScenario,
                values: pageForm.transportScenario === undefined ? undefined : [pageForm.transportScenario],
                onChange: values => {
                  dispatch(
                    pageFormActions.changeField('transportScenario', values[0]),
                  );
                },
              }} />
            </div>
            <div className={classNames(pageLayoutStyle.toolbarControl, pageLayoutStyle.toolbarControlXSmall)}>
              <PSelect {...{
                label: t('systems.traffic.captions.year'),
                className: style.select,
                items: projectChoices.years.map(n => ({
                  value: n,
                  label: n,
                })),
                disabled: !projectChoices.years.length,
                values: pageForm.year === undefined ? undefined : [pageForm.year],
                onChange: values => dispatch(pageFormActions.changeField('year', values[0])),
              }} />
            </div>
          </div>
        </div>
        <div className={classNames(pageLayoutStyle.toolbarBlock, pageLayoutStyle.toolbarBlockSmall)}>
          <div className={classNames(pageLayoutStyle.toolbarControls, pageLayoutStyle.toolbarControlsBottom)}>
            <PButton {...{
              className: style.button,
              disabled: !projectChoices.years.length || !pageForm.year,
              onClick: () => {
                dispatch(findRoutes()).then(foundRoutes => {
                  if (!foundRoutes.currentRoutes || !foundRoutes.currentRoutes.length) {
                    dispatch(modals.actions.showMessageBoxModal({
                      text: t('common.messages.noRoutesFoundMessage'),
                    }));
                  } else {
                    dispatch(pageFormActions.changeField('selectedRoute', 0));
                  }
                });
              },
            }}>
              {t('systems.traffic.captions.calculateRoute')}
            </PButton>
            <ExportToExcelButton {...{
              className: style.exportButton,
              size: 20,
              fixedSizeOnExpand: false,
              disabled: (
                pageForm.macroeconomicScenarioActual === undefined
                || pageForm.transportScenarioActual === undefined
                || pageForm.yearActual === undefined
              ),
              onClick: () => {
                dispatch(exportData());
              },
            }} />
          </div>
        </div>

      </div>
      <TrafficRoutesMap {...{
        className: style.map,
      }} />
    </div>
  );
};

export default TrafficMapPage;
