import React, { FC } from 'react';
import { Divider } from '@material-ui/core';

import { Model } from 'src/interfaces';

import { SubmitButtons, AcStopsModel } from 'src/cluster/common';
import { EditorTreeHeader, EditorVehicleList } from 'src/cluster/editor-map';

import { StopNameInput, StopRoutesList } from './components';

import useStyles from './styles';

interface OwnProps {
  stop: Model<AcStopsModel>;
  isLoading: boolean;
  isModifying: boolean;
  canModify: boolean;
  directionId?: number;
}

interface InjectedProps {
  isDirty: boolean;
  enableModifyMode(): void;
  enableViewMode(): void;
  leaveStop(): void;
  changeStopName(value: string): void;
  updateStop(): void;
  createStop(): void;
  deleteStop(): void;
  setSelectedDirection: (
    id: number,
    variantId: number,
    routeId: number,
    vehicleTypeId: number,
  ) => void;
}

type Props = OwnProps & InjectedProps;

const EditorStop: FC<Props> = (props) => {
  const {
    stop,
    directionId,
    stop: {
      id, stopName, routeDirections, vehicleTypes,
    },
    isLoading,
    isModifying,
    isDirty,
    canModify,
    enableModifyMode,
    enableViewMode,
    leaveStop,
    changeStopName,
    updateStop,
    createStop,
    deleteStop,
    setSelectedDirection,
  } = props;

  const classes = useStyles();

  function handleSave() {
    if (id === -1) {
      createStop();
    } else {
      updateStop();
    }
  }

  const toggleEditMode = () => (isModifying ? enableViewMode() : enableModifyMode());

  const showRoutesList = !!vehicleTypes.length && !!routeDirections.length;

  return (
    <section className={classes.root}>
      {isModifying ? (
        <StopNameInput
          name={stopName}
          canDelete={!routeDirections.length}
          onChange={changeStopName}
          onDelete={deleteStop}
        />
      ) : (
        <EditorTreeHeader
          title={stopName}
          canModify={canModify}
          leave={leaveStop}
          edit={toggleEditMode}
        />
      )}
      <EditorVehicleList types={vehicleTypes} />
      <Divider />
      {showRoutesList && (
        <StopRoutesList
          stop={stop}
          setSelectedDirection={setSelectedDirection}
          directionId={directionId}
        />
      )}
      {isModifying && (
        <SubmitButtons
          className={classes.submitButtons}
          isLoading={isLoading}
          disabled={!isDirty || !stopName.length}
          onCancel={enableViewMode}
          onSubmit={handleSave}
        />
      )}
    </section>
  );
};

export default EditorStop;
