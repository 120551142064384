import { useMemo } from 'react';

import { makeMapStyleEdgeLayer } from 'src/cluster/common';
import { TRAFFIC_COLOR } from 'src/cluster/distribution-map';
import useHeatRange from './useHeatRange';

const useHeatByVehicleTypesLayers = (
  variantIds: number[],
  visible: boolean,
  deps: any[],
) => {
  const range = useHeatRange();
  const [id = null] = variantIds;

  return useMemo(() => {
    return makeMapStyleEdgeLayer(
      'edges-heat-routes',
      visible,
      ['has', ['to-string', id], ['get', 'route_variant_traffic_relative']],
      {
        'line-color': [
          'case', ...range.reduce<any[]>((memo, item) => {
            return memo.concat([
              [
                'all',
                ['>', ['get', ['to-string', id], ['get', 'route_variant_traffic_relative']], item.from],
                ['<=', ['get', ['to-string', id], ['get', 'route_variant_traffic_relative']], item.to],
              ], item.value,
            ]);
          }, []), TRAFFIC_COLOR[1],
        ],
        'line-width': 8,
        'line-offset': 4,
      },
    );
  }, [...deps, visible, variantIds]);
};

export default useHeatByVehicleTypesLayers;
