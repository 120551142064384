import { createSelector } from 'reselect';

import { createFormSelector } from 'src/helpers';
import { Model } from 'src/interfaces';

import { AcDashboardTablePageForm } from 'src/cluster/common';
import { AcDashCategoriesModel, selectDashCategories } from 'src/cluster/dashboard-common';
import { AC_DASH_TABLE_PAGE_FORM_NAME, filterCategories, hideCategories } from 'src/cluster/dashboard-table';

export const selectDashTablePageForm = createFormSelector<AcDashboardTablePageForm>(AC_DASH_TABLE_PAGE_FORM_NAME);

export const selectDashTableCategories = createSelector(
  [
    selectDashCategories,
    selectDashTablePageForm,
  ],
  (maybeCategories, pageForm): Model<AcDashCategoriesModel>[] => {
    const { search, hiddenCategories } = pageForm;
    return maybeCategories.cata(
      () => [],
      (categories) => {
        return hideCategories(filterCategories(categories, search), hiddenCategories);
      },
    );
  },
);

export const selectModalDashTableCategories = createSelector(
  [
    selectDashCategories,
    selectDashTablePageForm,
  ],
  (maybeCategories, pageForm): Model<AcDashCategoriesModel>[] => {
    const { searchModal } = pageForm;
    return maybeCategories.cata(
      () => [],
      (categories) => {
        return filterCategories(categories, searchModal);
      },
    );
  },
);
