import React, { PureComponent } from 'react';
import Select from 'react-select';

import { WithTranslate } from 'src/i18n';

import { sortOptionsByDefault } from 'src/helpers';
import {
  DEFAULT_MAX_ROWS,
  defaultFilterFunction,
  DropDownValue,
  DropDownItem,
  ChangeFunction,
} from './constants';

import style from './index.module.css';

export interface DropDownProps extends WithTranslate {
  controlClassName?: string;

  multi?: boolean;
  items: DropDownItem[];
  values: DropDownValue[];
  label?: any;
  placeHolder?: string;
  showSearch?: boolean;
  noOptionsMessage?: string;
  autoFocus?: boolean;
  defaultMenuIsOpen?: boolean;
  isClearable?: boolean;

  defaultOpen: boolean;
  allowOverflowingDropDown: boolean;
  maxRows?: number;
  autoScroll?: boolean;
  disabled?: boolean;
  errors: string[];
  isLoading?: boolean;

  validate: {
    required?: boolean;
    checkOnBlur?: boolean;
  };

  sort?: ((options: DropDownItem[]) => DropDownItem[]) | false;

  onChange: ChangeFunction;
  onBlur: Function;
  onFocus: Function;
  onSearch?: Function;
  onAdd?: Function;
  missingValueResolver: Function;
}

interface DropDownState {
  innerSearch?: string;
}

class DropDown extends PureComponent<DropDownProps, DropDownState> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    items: [],
    values: [],

    defaultOpen: false,
    allowOverflowingDropDown: true,
    maxRows: DEFAULT_MAX_ROWS,
    autoScroll: true,
    errors: [],

    sort: sortOptionsByDefault,

    onChange: () => undefined,
    onBlur: () => undefined,
    onFocus: () => undefined,
    missingValueResolver: (v: any) => v,
  }

  public constructor(props: DropDownProps) {
    super(props);
    this.state = {
      innerSearch: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearchValue = this.handleChangeSearchValue.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getItems = this.getItems.bind(this);
  }

  private getItems() {
    const { items, sort, onSearch } = this.props;
    const { innerSearch } = this.state;

    const sortedItems = sort ? sort(items) : items;

    if (innerSearch && !onSearch) {
      return defaultFilterFunction(innerSearch, sortedItems);
    }
    return sortedItems;
  }

  private handleChangeSearchValue(value: string) {
    this.setState({ innerSearch: value });
  }

  private handleSearch(value?: string) {
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(value);
    }
  }

  private handleChange(value: DropDownValue) {
    const { onChange } = this.props;
    onChange(Array.isArray(value) ? value : [value]);
  }

  public render() {
    const {
      values,
      label,
      multi,
      isLoading,
      validate,
      autoFocus,
      defaultMenuIsOpen,
      isClearable,
      onSearch,
      disabled,
      t,
    } = this.props;
    const items = this.getItems();

    let InputLabelProps;
    if (values.length) {
      InputLabelProps = {
        shrink: true,
      };
    }

    // We override filtering outside
    let filterOption;
    if (onSearch) {
      filterOption = () => {
        return true;
      };
    }
    return (
      <Select
        className={style.root}
        autoFocus={autoFocus}
        menuPlacement="auto"
        defaultMenuIsOpen={defaultMenuIsOpen}
        value={values.map(value => items.find(item => item.value === value))}
        isMulti={multi}
        options={items}
        filterOption={filterOption}
        isLoading={isLoading}
        isClearable={isClearable}
        noOptionsMessage={() => t('components.captions.noOptions')}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#e5293c',
            primary25: 'rgba(229, 41, 60, 0.25)',
          },
        })}
        styles={{
          control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            border: state.isFocused ? '1px solid hsl(0,0%,80%)' : provided.border,
            '&:hover': {
              border: '1px solid hsl(0,0%,80%)',
            },
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 10,
          }),
        }}
        placeholder={t('components.captions.select')}
        onChange={(e: any) => {
          let changedValue;
          if (multi) {
            changedValue = e.map((item: any) => item.value);
          } else if (e) {
            changedValue = e.value;
          }
          this.handleChange(changedValue);
        }}
        textFieldProps={{
          label,
          InputLabelProps,
        }}
        searchInputProps={{
          validate,
          onSearch: this.handleSearch,
        }}
        isDisabled={disabled}
      />
    );
  }
}

export default DropDown;
