import { BottomColumns, TopColumns } from 'src/cluster/editor-table';

export const AC_REGISTRY_ROUTES_ENDPOINT = 'registry/routes/';
export const AC_REGISTRY_CARRIERS_ENDPOINT = 'registry/carriers/';
export const AC_REGISTRY_SEASONS_ENDPOINT = 'registry/seasons/';
export const AC_REGISTRY_VEHICLE_CLASSES_ENDPOINT = 'registry/vehicle-classes/';

export const AC_REGISTRY_TABLE_SETTINGS_MODAL = 'registryTableSettingsModal';
export const AC_REGISTRY_ROUTE_EDITING_MODAL = 'registryRouteEditingModal';
export const AC_REGISTRY_TABLE_FILTERS_MODAL = 'registryTableFiltersModal';

export const TOP_COLUMNS: TopColumns[] = Object.values(TopColumns).map(col => col);
export const BOTTOM_COLUMNS: BottomColumns[] = Object.values(BottomColumns).map(col => col);

export const TOP_FIXED_COLUMNS: TopColumns[] = [
  TopColumns.vehicleType,
  TopColumns.routeNumber,
  TopColumns.routeLongName,
];

export const BOTTOM_FIXED_COLUMNS: BottomColumns[] = [
  BottomColumns.variantNumber,
  BottomColumns.variantName,
];
