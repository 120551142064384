import { RestifyArray } from 'redux-restify';
import { AC_PROJECTS_MODEL_NAME, AC_BALANCE_ENDPOINT } from 'src/cluster/common';

export default {
  endpoint: AC_BALANCE_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    regionId: undefined,
    regionType: undefined,
    regionName: undefined,
    scenarioId: undefined,
    year: undefined,
    source: undefined,
    baseInTrafficSum: undefined,
    baseOutTrafficSum: undefined,
    forecastOutTrafficSum: undefined,
    forecastInTrafficSum: undefined,
    factors: new RestifyArray({
      defaults: {
        id: undefined,
        value: undefined,
        valueFromMax: undefined,
      },
    }),
  },
  clearPagesOnRouteChange: false,
  forceLoad: true,
};
