import React, {
  FC,
  Fragment,
  useState,
  SyntheticEvent,
} from 'react';
import {
  Fade,
  LinearProgress,
  Paper,
  Popper,
} from '@material-ui/core';
import classNames from 'classnames';

import { ProcessStatus } from 'src/types';
import { WithTranslate } from 'src/i18n';
import { PROJECT_STATUSES_DICT, ProjectModel } from 'src/modules/general/projects';

import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import style from './index.module.css';

interface Props extends WithTranslate {
  project: ProjectModel;
}

const ProjectsTableStatus: FC<Props> = (props) => {
  const {
    project,
    t,
  } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  if (project.loadStatus === ProcessStatus.IN_PROGRESS) {
    const percentageProgress = project.loadProgress * 100;
    return (
      <Fragment>
        <div className={style.progress}>
          <span>{t(PROJECT_STATUSES_DICT.IN_PROGRESS)}</span>
          {percentageProgress > 0 && (
            <span className={style.percentage}>
              {percentageProgress}
              %
            </span>
          )}
        </div>
        <LinearProgress
          variant={project.loadProgress > 0 ? 'determinate' : 'indeterminate'}
          value={percentageProgress}
        />
      </Fragment>
    );
  }

  if (project.loadStatus === ProcessStatus.ERROR) {
    const handleOpen = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <Fragment>
        <button
          className={classNames(style.errorToggle, open && style.errorToggleOpen)}
          onClick={handleOpen}
        >
          {t(PROJECT_STATUSES_DICT.ERROR)}
        </button>
        <Popper
          open={open}
          anchorEl={anchorEl}
          className={style.popper}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper className={style.paper} elevation={3}>
                <div className={style.inner}>
                  <span className={style.errorText}>
                    {`${project.loadErrorMessage}`}
                  </span>
                  <button
                    className={style.close}
                    onClick={handleClose}
                  >
                    <PIcon {...{
                      type: ICONS_TYPES.clear,
                      className: style.clearIcon,
                    }} />
                  </button>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Fragment>
    );
  }

  return t(PROJECT_STATUSES_DICT[project.loadStatus]);
};

export default ProjectsTableStatus;
