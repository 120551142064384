import { ReactNode } from 'react';
import type { PickInfo } from '@deck.gl/core/lib/deck';

import {
  Position,
  FeatureCollection,
  Properties,
  Model,
  Editable,
} from 'src/interfaces';

import { AcStopsModel, AcRoutesModel } from 'src/cluster/common';

import { EditorMode, EditType } from './types';

export type { PickInfo };

export enum PointAddingDirection {
  before = 'before',
  after = 'after',
}

export interface AcEditorPageForm {
  addIndex?: number;
  addingDirection?: PointAddingDirection;
  vehicleTypeId?: number;
  routeId?: number;
  directionId?: number;
  variantId?: number;
  nodeId?: number;
  nodeIndex?: number;
  edgeId?: number;
  stopId?: number;
  stopOrder?: number;
  editorMode: EditorMode;
  message?: ReactNode;
  isLoading: boolean;
  isWaiting: boolean;
  canBePositioned: boolean;
}

export interface AcEntitiesEditorForm {
  editableRoute?: Editable<Model<AcRoutesModel>>;
  editableEdgeSpeeds?: Editable<Model<AcEdgeSpeed>>[];
  editableEdgeSpeedChanges?: Editable<Model<AcEdgeSpeedChange>>[];
  editableStop?: Editable<Model<AcStopsModel>>;
}

export interface AcEdgeSpeed {
  id: number;
  vehicleTypeId: number;
  intervalId: number;
  scenarioId: number;
  edgeId: number;
  speed: number;
  speedDedicatedLane: number;
  dedicatedLane: boolean;
  time: number;
}

export interface AcEdgeSpeedChange {
  id: number;
  projectId: number;
  vehicleTypeId: number;
  intervalId: number;
  scenarioId: number;
  year: number;
  speedChange: number;
}

export interface EditContext {
  position: Position;
  featureIndexes: number[];
  positionIndexes: number[];
}

export interface EditEvent {
  updatedData: FeatureCollection<any, Properties>;
  editType: EditType;
  editContext: EditContext;
}

export interface LayerClickInputHandler<D=any> {
  (o: PickInfo<D>, e: HammerInput & { tapCount: number }): any;
}

export interface LayerHoverInputHandler<D=any> {
  (o: PickInfo<D>, e: HammerInput): any;
}
