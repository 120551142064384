import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { AppState } from 'src/reducer';

import { selectForecastIndicatorResults, selectBaseIndicatorResults } from 'src/cluster/dashboard-common';
import { initDashboardTable, selectDashTableCategories, selectDashTablePageForm } from 'src/cluster/dashboard-table';

import DashTablePage from './DashTablePage';

const mapStateToProps = (state: AppState) => ({
  pageForm: selectDashTablePageForm(state),
  categories: selectDashTableCategories(state),
  baseIndicators: selectBaseIndicatorResults(state),
  forecastIndicators: selectForecastIndicatorResults(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInit: () => dispatch(initDashboardTable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DashTablePage));
