const SELECT_DROPDOWN = 'dropdown';
const SELECT_FILTER_DROPDOWN = 'filterDropdown';

export const SELECT_TYPES = {
  [SELECT_DROPDOWN]: SELECT_DROPDOWN,
  [SELECT_FILTER_DROPDOWN]: SELECT_FILTER_DROPDOWN,
};

export const ERROR_TYPES = {
  required: 'common.validation.required',
};
