import React from 'react';

const TransportTypeSuburban = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0828 0.663101L5.93634 0.235626L1.79002 0.663101C1.64331 0.663101 1.52441 0.782 1.52441 0.92871V9.47305C1.52441 9.61976 1.64331 9.73866 1.79002 9.73866H10.0828C10.2295 9.73866 10.3484 9.61976 10.3484 9.47305V0.92871C10.3484 0.782156 10.2295 0.663101 10.0828 0.663101ZM3.87521 8.37686C3.4643 8.37686 3.13119 8.04376 3.13119 7.63285C3.13119 7.22193 3.4643 6.88883 3.87521 6.88883C4.28613 6.88883 4.61923 7.22193 4.61923 7.63285C4.61923 8.04376 4.28613 8.37686 3.87521 8.37686ZM7.99747 8.37686C7.58656 8.37686 7.25345 8.04376 7.25345 7.63285C7.25345 7.22193 7.58656 6.88883 7.99747 6.88883C8.40838 6.88883 8.74149 7.22193 8.74149 7.63285C8.74164 8.04376 8.40838 8.37686 7.99747 8.37686ZM9.53207 5.54062C9.53207 5.62031 9.46754 5.68483 9.38786 5.68483H2.48498C2.4053 5.68483 2.34077 5.62031 2.34077 5.54062V1.75538C2.34077 1.6757 2.4053 1.61117 2.48498 1.61117H9.38786C9.46754 1.61117 9.53207 1.6757 9.53207 1.75538V5.54062Z" fill="#1042F2" />
    <path d="M10.0813 9.97285H1.78854C1.51294 9.97285 1.28857 9.74849 1.28857 9.47303V0.928695C1.28857 0.657774 1.50528 0.436381 1.77464 0.429037L5.93486 0L10.0951 0.429037C10.3644 0.436381 10.5811 0.657774 10.5811 0.928695V9.47303C10.5813 9.74864 10.3569 9.97285 10.0813 9.97285ZM1.78854 0.897603C1.77136 0.897603 1.7573 0.911509 1.7573 0.928851V9.47319C1.7573 9.49022 1.7712 9.50428 1.78854 9.50428H10.0813C10.0985 9.50428 10.1126 9.49022 10.1126 9.47319V0.928695C10.1126 0.911509 10.0985 0.897447 10.0813 0.897447L10.0573 0.896197L5.93486 0.471222L1.78854 0.897603ZM7.99599 8.61121C7.45649 8.61121 7.01761 8.17233 7.01761 7.63283C7.01761 7.09333 7.45649 6.65429 7.99599 6.65429C8.53549 6.65429 8.97453 7.09333 8.97453 7.63283C8.97453 8.17233 8.53549 8.61121 7.99599 8.61121ZM7.99599 7.12317C7.71491 7.12317 7.48633 7.35191 7.48633 7.63299C7.48633 7.91406 7.71491 8.14264 7.99599 8.14264C8.27707 8.14264 8.5058 7.91406 8.5058 7.63299C8.5058 7.35191 8.27707 7.12317 7.99599 7.12317ZM3.87373 8.61121C3.33423 8.61121 2.89535 8.17233 2.89535 7.63283C2.89535 7.09333 3.33423 6.65429 3.87373 6.65429C4.41323 6.65429 4.85211 7.09333 4.85211 7.63283C4.85211 8.17233 4.41323 8.61121 3.87373 8.61121ZM3.87373 7.12317C3.59266 7.12317 3.36408 7.35191 3.36408 7.63299C3.36408 7.91406 3.59266 8.14264 3.87373 8.14264C4.15481 8.14264 4.38339 7.91406 4.38339 7.63299C4.38339 7.35191 4.15481 7.12317 3.87373 7.12317ZM9.38622 5.91902H2.4835C2.27477 5.91902 2.10493 5.74919 2.10493 5.54045V1.75537C2.10493 1.54663 2.27477 1.37679 2.4835 1.37679H9.38638C9.59511 1.37679 9.76495 1.54663 9.76495 1.75537V5.54061C9.76479 5.74919 9.59496 5.91902 9.38622 5.91902ZM2.57365 5.4503H9.29607V1.84552H2.57365V5.4503Z" fill="#1042F2" />
    <path d="M11.574 14.0001C11.4681 14.0001 11.3649 13.9463 11.3062 13.8491L9.01039 10.0469C8.92118 9.89905 8.96852 9.70687 9.11632 9.61782C9.26397 9.52829 9.45615 9.57594 9.54536 9.72375L11.841 13.5262C11.9302 13.674 11.8829 13.8662 11.7351 13.9552C11.6848 13.9857 11.6288 14.0001 11.574 14.0001Z" fill="#1042F2" />
    <path d="M0.312302 14C0.257305 14 0.201684 13.9855 0.151062 13.955C0.00325783 13.866 -0.0442394 13.6738 0.0451303 13.526L2.34093 9.72357C2.42999 9.57593 2.62217 9.52843 2.76997 9.61764C2.91777 9.7067 2.96527 9.89888 2.8759 10.0467L0.580099 13.8493C0.521508 13.9464 0.418233 14 0.312302 14Z" fill="#1042F2" />
    <path d="M9.76357 11.0566H2.17089C2.08464 11.0566 2.01465 10.9868 2.01465 10.9004C2.01465 10.8139 2.08464 10.7441 2.17089 10.7441H9.76372C9.85012 10.7441 9.91996 10.8139 9.91996 10.9004C9.91996 10.9868 9.84997 11.0566 9.76357 11.0566Z" fill="#1042F2" />
    <path d="M10.5722 12.4604H1.41356C1.28404 12.4604 1.1792 12.3554 1.1792 12.226C1.1792 12.0967 1.28404 11.9917 1.41356 11.9917H10.5722C10.7016 11.9917 10.8066 12.0967 10.8066 12.226C10.8066 12.3554 10.7016 12.4604 10.5722 12.4604Z" fill="#1042F2" />
  </svg>
);

export default TransportTypeSuburban;
