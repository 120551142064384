import { useSelector } from 'react-redux';

import { Model } from 'src/interfaces';

import { AcForecastCorrespondenceModel } from 'src/cluster/common';
import { selectBalanceMapCorrespondences } from 'src/cluster/balance-map';

const useCorrespondences = (): Model<AcForecastCorrespondenceModel>[] => {
  const maybeCorrespondences = useSelector(selectBalanceMapCorrespondences);
  return maybeCorrespondences.orJust([]);
};

export default useCorrespondences;
