import app from 'src/app';
import i18n from 'src/i18n';
import { ThunkActionResult } from 'src/reducer';

import {
  AC_PROJECTS_ENDPOINT, AcStopsModel, geojsonActions, selectAcProjectListForm, selectGeojsonForm,
} from 'src/cluster/common';
import { editorFormActions } from 'src/cluster/editor-common';
import { edgeCutting, fetchNodes } from 'src/cluster/editor-map';
import { NODES_ENDPOINT } from 'src/api-url-schema';
import { isFeatureCollection, isNumber, objectToLowerSnake } from 'src/helpers';
import { api } from 'redux-restify';

export function createNode(editableStop: AcStopsModel): ThunkActionResult<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedProject } = selectAcProjectListForm(state);
    const { nodes } = selectGeojsonForm(state);
    const { coordinates, edgeId } = editableStop;

    if (!isNumber(edgeId)) {
      return Promise.reject();
    }

    const config = {
      url: `${AC_PROJECTS_ENDPOINT}${selectedProject}/${NODES_ENDPOINT}`,
      data: objectToLowerSnake({
        coordinates,
        edgeId,
      }),
    };

    try {
      dispatch(editorFormActions.changeField('isLoading', true));
      const { data, status } = await dispatch(api.actions.callPost(config));
      if (status >= 400 || !data?.id || data.edges.length !== 2) {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createNodeError')));
        dispatch(editorFormActions.resetField('isLoading'));
        return Promise.reject();
      }

      const newNodes = await dispatch(fetchNodes(data.id));
      if (!isFeatureCollection(nodes) || !isFeatureCollection(newNodes)) {
        dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createNodeError')));
        return Promise.reject();
      }

      const updatedNodes = { ...nodes };

      updatedNodes.features.push(newNodes.features[0]);
      dispatch(geojsonActions.changeField('nodes', updatedNodes));

      dispatch(edgeCutting(data.edges, edgeId));
      return Promise.resolve(data.id);
    } catch (err) {
      dispatch(app.actions.toast.error(i18n.t('systems.agglomeration.messages.createNodeError')));
      return Promise.reject();
    }
  };
}
