export enum ProcessStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum RequestStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  IN_PROGRESS_CONFIRMED = 'IN_PROGRESS_CONFIRMED'
}
