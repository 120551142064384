import React, { FC } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

import { Model } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';

import { VehicleIcon, AcStopsModel, AcStopRouteDirection } from 'src/cluster/common';
import { useVehicleTypeName } from 'src/cluster/editor-map';

import useStyles from './styles';

interface OwnProps {
  stop: Model<AcStopsModel>;
  directionId?: number;
  setSelectedDirection: (
    id: number,
    variantId: number,
    routeId: number,
    vehicleTypeId: number,
  ) => void;
}

type Props = OwnProps & WithTranslate;

const StopRoutesList: FC<Props> = (props) => {
  const {
    stop: { vehicleTypes, routeDirections },
    t,
    setSelectedDirection,
    directionId,
  } = props;

  const classes = useStyles();

  const getVehicleTypeName = useVehicleTypeName();

  const changeDirection = (direction: AcStopRouteDirection) => {
    const {
      id, routeVariantId, routeId, vehicleTypeId,
    } = direction;
    if (id !== directionId) {
      setSelectedDirection(id, routeVariantId, routeId, vehicleTypeId);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        {t('systems.agglomeration.captions.routes')}
      </div>
      <div>
        {vehicleTypes.map((typeId) => {
          const directions = routeDirections.filter(direction => direction.vehicleTypeId === typeId);
          if (!directions.length) return null;
          return (
            <div key={typeId} className={classes.routeGroup}>
              {directions.map((direction, i) => {
                const type = getVehicleTypeName(direction.vehicleTypeId);
                return (
                  <div key={`${direction.id}_${i}`} className={classes.route}>
                    <span className={classes.routeIcon}>
                      <VehicleIcon type={type} />
                    </span>
                    <Tooltip title={`${t('common.captions.numberSymbol')}${direction.longName}`} arrow>
                      <span
                        className={clsx(classes.routeName, direction.id === directionId && classes.selected)}
                        onClick={() => changeDirection(direction)}
                      >
                        {t('common.captions.numberSymbol')}
                        {direction.longName}
                      </span>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StopRoutesList;
