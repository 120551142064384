import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { WithTranslate } from 'src/i18n';
import { PageNavLink } from 'src/pages';

import { isString } from 'lodash';
import useStyles from './styles';

interface Props extends WithTranslate {
  className?: string;
  label: string;
  name?: string;
  icon?: ReactNode | string;
  url?: string;
  navKey: string | string[];
  baseUrl: string;
  newWindow?: boolean;
}

const HeaderMenu: FC<Props> = (props) => {
  const {
    className,
    label,
    name,
    icon,
    navKey,
    baseUrl,
    t,
    url,
    newWindow,
  } = props;

  const title = name || label;

  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <PageNavLink
        navKey={navKey}
        baseUrl={baseUrl}
        extraLinkUrl={url}
        activeClassName={classes.active}
        className={classes.url}
        newWindow={newWindow}
      >
        <div className={classes.linkContent}>
          <span className={classes.icon}>
            {isString(icon) ? <IconTemplate url={icon} /> : icon}
          </span>
          <div>{t(title)}</div>
        </div>
      </PageNavLink>
    </div>
  );
};

const IconTemplate = ({ url }: { url: string }) => (
  <img src={url} alt="icon" style={{ width: '100%', height: '100%' }} />
);

export default HeaderMenu;
