import React, { ChangeEvent, FC, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { TextField } from 'src/components';
import { SelectValue } from 'src/interfaces';
import {
  AcRegRoutesResultsModel,
  AcRegistriesVCModel,
  useVehicleClassesOptions,
} from 'src/cluster/editor-table';

import useStyles from './styles';

const getTitle = (vc: SelectValue) => `modules.registries.vehicleClasses.${vc}`;

export interface OwnProps {
  titleClasses: Record<'root', string>;
  vehicleClasses: AcRegistriesVCModel[];
  onChangeFields(routeFields: Partial<AcRegRoutesResultsModel>): void;
}

type Props = OwnProps & WithTranslate;

const RegistryEditVehicleClasses: FC<Props> = (props) => {
  const {
    titleClasses,
    vehicleClasses,
    t,
    onChangeFields,
  } = props;

  const classes = useStyles();

  const vehicleClassesOptions = useVehicleClassesOptions();
  const availableClasses = vehicleClasses.map(({ vehicleClassId }) => vehicleClassId);

  const onChangeClass = (id: number, i: number, event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (!Number.isNaN(Number(value))) {
      const newVehicleClasses = [...vehicleClasses];
      const index = newVehicleClasses.findIndex(vc => vc.vehicleClassId === id);
      if (index !== -1) {
        newVehicleClasses[index].count = Number(value) || 0;
      } else {
        newVehicleClasses.splice(i, 0, {
          vehicleClassId: id,
          vehicleClassName: getTitle(vehicleClassesOptions[i].value),
          count: Number(value) || 0,
        });
      }
      onChangeFields({ vehicleClasses: newVehicleClasses });
    }
  };

  return (
    <Fragment>
      <Typography classes={titleClasses}>{t(getTitle('title'))}</Typography>
      <Box display="flex" flexWrap="wrap">
        {vehicleClassesOptions.filter(
          (option) => availableClasses.includes(Number(option.id)),
        ).map((vc, i) => (
          <TextField
            key={vc.id}
            type="number"
            label={t(getTitle(vc.value))}
            value={vehicleClasses.find(vcls => vcls.vehicleClassId === Number(vc.id))?.count || 0}
            className={classes.field}
            onChange={(val) => onChangeClass(Number(vc.id), i, val)}
          />
        ))}
      </Box>
    </Fragment>
  );
};

export default RegistryEditVehicleClasses;
