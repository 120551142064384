import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(2, 4),
      zIndex: 2,

      '&> *': {
        lineHeight: '16px',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.grey[800],
      paddingRight: theme.spacing(1),
      fontFamily: ffSourceSansPro,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    button: {
      fontSize: 10,
      marginLeft: 'auto',
    },
  });
});
