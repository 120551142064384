import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';

import { NumberFormat, WithUnit } from 'src/components';

import { UNITS } from 'src/constants';

import {
  AcDistributionMapPageForm, MapModes, useDistributionMapRouteVariantNumber, ViewModes,
} from 'src/cluster/distribution-map';
import { useVehicleTypeLabel } from 'src/cluster/editor-map';

import useStyles from './styles';

interface OwnProps {
  pageForm: AcDistributionMapPageForm;
  isDay: boolean;
  id: string | number | undefined;
  data: Record<number, number>;
  total: number;
}

type Props = OwnProps & WithTranslate;

const DistributionMapPopupEdge: FC<Props> = (props) => {
  const {
    id,
    data,
    total,
    pageForm: {
      mapMode,
      viewMode,
      variantId,
    },
    isDay,
    locale,
    t,
  } = props;

  const classes = useStyles();

  const ids = Object.keys(data).map(typeId => Number(typeId));

  const getVehicleTypeLabel = useVehicleTypeLabel();
  const routeVariantNumber = useDistributionMapRouteVariantNumber(variantId);

  const isDiagram = mapMode === MapModes.diagram;
  const byVehicles = viewMode === ViewModes.byVehicles;

  const diagramUnit = isDay ? t(UNITS.people) : t(UNITS.peoplePerHour);
  const heatUnit = t(UNITS.coefficient);
  const unit = isDiagram ? diagramUnit : heatUnit;

  return (
    <table className={classes.root}>
      <tbody>
        <tr>
          <td colSpan={2} className={classes.desc}>
            <WithUnit unit={unit}>
              {t('systems.distribution.captions.passengerTraffic')}
            </WithUnit>
          </td>
        </tr>
        {!byVehicles && (
          <tr>
            <td>{routeVariantNumber}</td>
            <td className={classes.value}>
              <NumberFormat
                value={data[variantId ?? -1]}
                locale={locale}
                decimalScale={isDiagram ? 0 : 2}
              />
            </td>
          </tr>
        )}
        {byVehicles && (
          ids.map((typeId) => (
            <tr key={typeId}>
              <td>{getVehicleTypeLabel(typeId)}</td>
              <td className={classes.value}>
                <NumberFormat
                  value={data[typeId]}
                  locale={locale}
                  decimalScale={isDiagram ? 0 : 2}
                />
              </td>
            </tr>
          ))
        )}
        {byVehicles && isDiagram && (
          <tr>
            <td>{t('systems.distribution.captions.allVehicleTypes')}</td>
            <td className={classes.value}>
              <NumberFormat
                value={total}
                locale={locale}
                decimalScale={0}
              />
            </td>
          </tr>
        )}
        <tr>
          <td
            colSpan={2}
            className={classes.stopId}
          >
            {`${t('systems.distribution.captions.edgeId')}: ${id}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DistributionMapPopupEdge;
