import { useSelector } from 'react-redux';

import { AcBalanceDataFactorsValue } from 'src/cluster/common';
import { selectAcBalanceMapPageForm, selectBalanceMapData } from 'src/cluster/balance-map';

const useBalanceMapData = (): { region: number; value: number }[] => {
  const [data] = useSelector(selectBalanceMapData);
  const { factor } = useSelector(selectAcBalanceMapPageForm);

  return factor
    ? data.reduce<{ region: number; value: number }[]>(
      (memo, record) => {
        const selectedFactor = record.factors.find((f: AcBalanceDataFactorsValue) => f.id === factor);

        return selectedFactor ? memo.concat([{ region: record.regionId, value: selectedFactor.value }]) : memo;
      }, [])
    : [];
};

export default useBalanceMapData;
