import { useEffect, useMemo } from 'react';
import { head, last } from 'lodash';
import {
  bbox, featureCollection, lineString, point,
} from '@turf/turf';
import WebMercatorViewport from 'viewport-mercator-project';
import { LinearInterpolator } from 'react-map-gl';

import { MAP_BOUND_PADDING } from 'src/constants';
import { getExtendedMapStyle } from 'src/helpers';

import { GeoStop } from 'src/modules/video/common';

interface SetViewportProps {
  (currentViewport: (currentViewport: any) => any): void;
}

export const useMapFittedToNodes = (
  nodes: GeoStop[],
  isLoading: boolean,
  viewport: any,
  setViewport: SetViewportProps,
) => {
  const hasSizes = viewport.height && viewport.width;
  useEffect(() => {
    if (!hasSizes) {
      return;
    }
    if (!isLoading && nodes.length) {
      const summaryNodeArray = nodes.reduce<number[][]>(
        (memo, node) => {
          return memo.concat([[node.lng, node.lat]]);
        },
        [],
      );
      if (!summaryNodeArray.length) {
        return;
      }
      const [minLng, minLat, maxLng, maxLat] = bbox(lineString(summaryNodeArray));
      const newViewport = new WebMercatorViewport(viewport);
      try {
        const {
          latitude,
          longitude,
          zoom,
        } = newViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: MAP_BOUND_PADDING,
        });
        setViewport(currentViewport => ({
          ...currentViewport,
          latitude,
          longitude,
          zoom,
          transitionInterpolator: new LinearInterpolator(),
          transitionDuration: 1000,
        }));
      } catch (e) {
        console.error('Error fitting map to bounds', e);
      }
    }
  }, [
    hasSizes,
    nodes,
    isLoading,
  ]);
};

export function makeGeoJsonRoute(geometry: any[]) {
  return {
    type: 'geojson',
    data: {
      geometry,
      type: 'Feature',
    },
  };
}

export function makeGeoJsonPoints(nodes: GeoStop[], pointsExtras = {}) {
  return {
    type: 'geojson',
    ...pointsExtras,
    data: featureCollection(nodes.map((node) => (
      point(
        [node.lng, node.lat],
        {
          isPoint: true, stopId: node.stopId,
        },
      )
    ))),
  };
}

export const makeMapStylePointLayer = (
  id: string,
  visible: boolean,
  filter: any[],
) => {
  return {
    id,
    filter,
    layout: {
      visibility: visible ? 'visible' : 'none',
    },
    paint: {
      'circle-color': '#e5293c',
      'circle-radius': 3,
      'circle-stroke-width': 0,
      'circle-stroke-color': '#e5293c',
    },
    type: 'circle',
    source: 'points',
  };
};

export const useMapStyle = (
  nodes: any[],
  route: any[],
  locale: string,
) => {
  return useMemo(() => {
    const pointExtras = {
      cluster: false,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    };

    const firstNode = head(nodes);
    const lastNode = last(nodes);

    const sources = {
      edges: makeGeoJsonRoute(route),
      points: makeGeoJsonPoints(nodes, pointExtras),
      firstPoint: makeGeoJsonPoints(firstNode ? [firstNode] : [], pointExtras),
      lastPoint: makeGeoJsonPoints(lastNode ? [lastNode] : [], pointExtras),
    };

    const layers = [
      {
        id: 'edges',
        filter: ['all'],
        source: 'edges',
        type: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#a0a0a0',
          'line-width': 5,
        },
      },
      {
        id: 'points',
        filter: ['all'],
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': '#e5293c',
          'circle-radius': 5,
        },
        type: 'circle',
        source: 'points',
      },
      {
        id: 'first-point',
        filter: ['all'],
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': 'white',
          'circle-radius': 8,
          'circle-stroke-width': 5,
          'circle-stroke-color': '#e5293c',
        },
        type: 'circle',
        source: 'firstPoint',
      },
      {
        id: 'first-point-marker',
        filter: ['all'],
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': 'black',
          'circle-radius': 4,
        },
        type: 'circle',
        source: 'firstPoint',
      },
      {
        id: 'last-point',
        filter: ['all'],
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': 'white',
          'circle-radius': 8,
          'circle-stroke-width': 5,
          'circle-stroke-color': '#e5293c',
        },
        type: 'circle',
        source: 'lastPoint',
      },
    ];

    return getExtendedMapStyle({ sources, layers }, locale);
  }, [nodes, route, locale]);
};
