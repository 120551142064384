import React, { ChangeEvent, FC } from 'react';
import { Divider, Typography } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';

import { TextField } from 'src/components';
import { Model } from 'src/interfaces';

import { AcDashboardTablePageForm } from 'src/cluster/common';
import { AcDashCategoriesModel } from 'src/cluster/dashboard-common';

import { CategoryItem } from './components';

import useStyles from './styles';

interface InjectedProps extends WithTranslate {
  pageForm: AcDashboardTablePageForm;
  categories: Model<AcDashCategoriesModel>[];
  setSearchKey(event: ChangeEvent<{ value: unknown }>): void;
  setHiddenCategories(value: ChangeEvent<HTMLInputElement>, checked: boolean): void;
  close: () => void;
}

type Props = InjectedProps;

const ModalTableDashboardCustomize: FC<Props> = (props) => {
  const {
    pageForm: { searchModal, hiddenCategories = [] },
    categories,
    t,
    setHiddenCategories,
    setSearchKey,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t('systems.dashboard.captions.customize')}</Typography>
        <div className={classes.search}>
          <Typography>{t('systems.dashboard.captions.customizeDesc')}</Typography>
          <TextField
            placeholder={t('common.captions.search')}
            value={searchModal ?? ''}
            onChange={setSearchKey}
          />
        </div>
      </div>
      <Divider />
      <div className={classes.list}>
        {categories.map((category) => {
          return (
            <CategoryItem
              key={category.id}
              category={category}
              hiddenCategories={hiddenCategories}
              level={1}
              setHiddenCategories={setHiddenCategories}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ModalTableDashboardCustomize;
