/* eslint-disable max-len */
import React from 'react';

const Cash = () => {
  return (
    <svg viewBox="0 0 442.003 442.003">
      <path d="M337.897,189.476c-0.01-0.082-0.016-0.165-0.028-0.246c-0.058-0.404-0.135-0.807-0.244-1.205 c-0.001-0.004-0.003-0.008-0.004-0.013c-0.102-0.372-0.232-0.739-0.379-1.102c-0.042-0.104-0.088-0.204-0.133-0.306 c-0.116-0.262-0.245-0.52-0.385-0.774c-0.061-0.111-0.12-0.222-0.186-0.331c-0.035-0.059-0.063-0.12-0.1-0.179 c-0.148-0.235-0.31-0.456-0.474-0.675c-0.031-0.041-0.056-0.084-0.087-0.124c-0.25-0.324-0.52-0.625-0.803-0.91 c-0.053-0.054-0.111-0.104-0.166-0.156c-0.246-0.237-0.501-0.459-0.767-0.668c-0.068-0.053-0.134-0.106-0.204-0.157 c-0.356-0.264-0.723-0.51-1.106-0.723L185.506,98.164l84.825-53.414l142.207,80.832l-59.891,37.713 c-4.674,2.942-6.077,9.117-3.134,13.79c2.942,4.674,9.116,6.077,13.79,3.134l74.026-46.613c2.976-1.873,4.749-5.172,4.669-8.688 c-0.079-3.516-1.998-6.73-5.056-8.468L275.009,24.404c-3.203-1.822-7.15-1.732-10.27,0.231L160.783,90.096 c-0.021,0.013-0.043,0.027-0.064,0.04l-51.947,32.711c-0.024,0.015-0.048,0.03-0.071,0.045L4.674,188.398 c-2.976,1.873-4.749,5.172-4.669,8.688c0.079,3.516,1.998,6.73,5.056,8.468l161.934,92.046c1.534,0.873,3.238,1.307,4.941,1.307 c1.853,0,3.703-0.515,5.328-1.538l74.026-46.614c4.674-2.942,6.077-9.117,3.134-13.79c-2.941-4.674-9.117-6.078-13.79-3.134 l-68.961,43.424L29.466,196.421l84.826-53.415l151.67,86.212v114.182c0,3.642,1.979,6.995,5.167,8.755 c1.507,0.832,3.171,1.245,4.833,1.245c1.854,0,3.704-0.515,5.328-1.538l52.014-32.753c2.908-1.831,4.672-5.026,4.672-8.462v-120 c0-0.059-0.01-0.115-0.012-0.174C337.958,190.141,337.936,189.809,337.897,189.476z M285.962,325.287V223.401 c0-3.597-1.932-6.916-5.059-8.693l-147.411-83.791l32.813-20.663l142.206,80.833l-12.451,7.84 c-4.674,2.942-6.077,9.117-3.134,13.79c1.9,3.02,5.149,4.673,8.472,4.673c1.82,0,3.664-0.497,5.318-1.539l11.259-7.089v96.366 L285.962,325.287z"
      />
      <path d="M426.674,156.681l-74.026,46.613c-4.674,2.942-6.077,9.117-3.134,13.79c1.9,3.02,5.149,4.673,8.472,4.673 c1.82,0,3.664-0.497,5.318-1.539l74.026-46.613c4.674-2.942,6.077-9.117,3.134-13.79 C437.522,155.141,431.347,153.737,426.674,156.681z"
      />
      <path d="M240.633,273.83l-68.961,43.424L14.943,228.167c-4.798-2.728-10.906-1.052-13.635,3.752 c-2.729,4.801-1.05,10.906,3.752,13.635l161.934,92.046c1.534,0.873,3.238,1.307,4.941,1.307c1.853,0,3.703-0.515,5.328-1.538 l74.026-46.614c4.674-2.942,6.077-9.117,3.134-13.79C251.482,272.29,245.306,270.886,240.633,273.83z"
      />
      <path d="M426.674,196.681l-74.026,46.613c-4.674,2.942-6.077,9.117-3.134,13.79c1.9,3.02,5.149,4.673,8.472,4.673 c1.82,0,3.664-0.497,5.318-1.539l74.026-46.613c4.674-2.942,6.077-9.117,3.134-13.79 C437.522,195.14,431.347,193.737,426.674,196.681z"
      />
      <path d="M240.633,313.83l-68.961,43.424L14.943,268.167c-4.798-2.728-10.906-1.052-13.635,3.752 c-2.729,4.801-1.05,10.906,3.752,13.635l161.934,92.046c1.534,0.873,3.238,1.307,4.941,1.307c1.853,0,3.703-0.515,5.328-1.538 l74.026-46.614c4.674-2.942,6.077-9.117,3.134-13.79C251.482,312.289,245.306,310.886,240.633,313.83z"
      />
      <path d="M426.674,236.681l-74.026,46.613c-4.674,2.942-6.077,9.117-3.134,13.79c1.9,3.02,5.149,4.673,8.472,4.673 c1.82,0,3.664-0.497,5.318-1.539l74.026-46.613c4.674-2.942,6.077-9.117,3.134-13.79 C437.522,235.141,431.347,233.738,426.674,236.681z"
      />
      <path d="M240.633,353.83l-68.961,43.424L14.943,308.167c-4.798-2.728-10.906-1.052-13.635,3.752 c-2.729,4.801-1.05,10.906,3.752,13.635l161.934,92.046c1.534,0.873,3.238,1.307,4.941,1.307c1.853,0,3.703-0.515,5.328-1.538 l74.026-46.614c4.674-2.942,6.077-9.117,3.134-13.79C251.482,352.29,245.306,350.886,240.633,353.83z"
      />
    </svg>
  );
};

export default Cash;
