import React, { ChangeEvent, MouseEvent, FC } from 'react';

import { WithTranslate } from 'src/i18n';
import { isString } from 'src/helpers';
import {
  Button, CopyIcon, ExportIcon, TextField, TrashIcon,
} from 'src/components';

import { EditorTreeCaption } from 'src/cluster/editor-map';

import useStyles from './styles';

interface OwnProps {
  isModifying: boolean;
  canDelete: boolean;
  canCopy: boolean;
  variantNumber: string;
  hasError: boolean;
  changeVariantNumber(value: string): void;
  exportVariant(): void;
  copyVariant(): void;
  deleteVariant(): void;
}

type Props = OwnProps & WithTranslate;

const RouteVariantHeader: FC<Props> = (props) => {
  const {
    isModifying,
    canDelete,
    canCopy,
    variantNumber,
    hasError,
    t,
    changeVariantNumber,
    exportVariant,
    copyVariant,
    deleteVariant,
  } = props;

  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (isString(value)) {
      changeVariantNumber(value);
    }
  };

  const handleClick = (event: MouseEvent<{}>) => {
    event.stopPropagation();
  };

  return (
    <div className={classes.root}>
      {isModifying ? (
        <TextField
          id={`variant-number-${variantNumber}`}
          aria-describedby={hasError ? `variant-number-error-${variantNumber}` : undefined}
          value={variantNumber}
          className={classes.input}
          error={hasError}
          onChange={handleChange}
          onClick={handleClick}
        />
      ) : <span className={classes.number} title={variantNumber}>{variantNumber}</span>}
      <EditorTreeCaption label={t('systems.agglomeration.captions.routeVariant')} />
      {isModifying ? (
        <Button
          variant="icon"
          className={classes.button}
          title={t('common.captions.copy')}
          disabled={!canCopy}
          onClick={copyVariant}
        >
          <CopyIcon />
        </Button>
      ) : (
        <Button
          variant="icon"
          className={classes.button}
          title={t('common.captions.export')}
          onClick={exportVariant}
        >
          <ExportIcon />
        </Button>
      )}
      {isModifying && (
        <Button
          variant="icon"
          className={classes.button}
          title={t('common.captions.delete')}
          disabled={!canDelete}
          onClick={deleteVariant}
        >
          <TrashIcon />
        </Button>
      )}
    </div>
  );
};

export default RouteVariantHeader;
