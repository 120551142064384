import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { WithTranslate } from 'src/i18n';
import { Autocomplete } from 'src/components';
import { SelectValue } from 'src/interfaces';

import { AcDistPageForm } from 'src/cluster/common';
import {
  AcDistPageFormEnum,
  usePointFiltersConfig,
} from 'src/cluster/distribution-table';

import useStyles from './styles';

export interface InjectedProps {
  tableFilters: AcDistPageForm;
  setFilter(val: SelectValue, field: AcDistPageFormEnum): void;
}

type Props = InjectedProps & WithTranslate;

const DistributionPointFilter: FC<Props> = (props) => {
  const {
    t,
    tableFilters,
    setFilter,
  } = props;

  const classes = useStyles();

  const filters = usePointFiltersConfig(tableFilters, setFilter);

  return (
    <Box display="flex" flexWrap="wrap">
      {filters.map(filter => (
        <Autocomplete
          key={filter.label}
          placeholder={t('components.captions.select')}
          multiple={filter.multiple}
          label={t(filter.label)}
          options={filter.options}
          selected={filter.selected}
          disabled={filter.disabled}
          className={classes.filter}
          onChange={filter.command}
        />
      ))}
    </Box>
  );
};

export default DistributionPointFilter;
