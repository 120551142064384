import { SelectValue } from 'src/interfaces';

import { useAcVehicleTypes } from 'src/cluster/common';

import { DrawerLegendToggle, DrawerLegendSelect, DrawerLegendCheckbox } from '../../interfaces/common';
import { LegendType } from '../../types';

import useLegendInfrastructures from './useLegendInfrastructures';
import useLegendNetworks from './useLegendNetworks';
import useLegendTypes from './useLegendTypes';
import useLegendParking from './useLegendParking';
import useLegendHighlighted from './useLegendHighlighted';
import useLegendDistricts from './useLegendDistricts';
import useLegendSrnSpeed from './useLegendSrnSpeed';

const useLegendDrawer = (
  setLegendField: (key: LegendType, checked: boolean | SelectValue) => void,
  classes: Record<string, string>,
): [
  { title: string; items: DrawerLegendCheckbox[] }[],
  DrawerLegendToggle[],
  DrawerLegendSelect[],
  DrawerLegendCheckbox,
  DrawerLegendCheckbox[],
  DrawerLegendCheckbox[],
  DrawerLegendCheckbox,
] => {
  const vehicleTypesNames = useAcVehicleTypes().flatMap(vh => vh.name);
  const vehicleTypesWithRoutes = useAcVehicleTypes().filter(vh => vh.hasRoutes).flatMap(vh => vh.name);

  const infrastructures = useLegendInfrastructures(setLegendField, classes, vehicleTypesNames);
  const networks = useLegendNetworks(setLegendField, vehicleTypesWithRoutes);
  const selectTypes = useLegendTypes(setLegendField);
  const parking = useLegendParking(setLegendField);
  const highlights = useLegendHighlighted(setLegendField, vehicleTypesNames);
  const districts = useLegendDistricts(setLegendField, classes);
  const srn = useLegendSrnSpeed(setLegendField);
  return [
    infrastructures,
    networks,
    selectTypes,
    parking,
    highlights,
    districts,
    srn,
  ];
};

export default useLegendDrawer;
