import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { selectBalanceScenarios, selectBalanceBehaviorTypes, selectCurrentProjectYears } from 'src/cluster/ttc';
import { SelectMenuOption } from 'src/interfaces';
import { AcBehaviorTypesModel, AcBalanceScenariosModel, AcYearModel } from 'src/cluster/common';

export const useScenarios = (): SelectMenuOption[] => {
  const [scenarios] = useSelector(selectBalanceScenarios);
  return useMemo(() => scenarios.map((type: AcBalanceScenariosModel) => ({
    id: String(type.id),
    value: type.id,
    label: type.name,
  })), [JSON.stringify(scenarios)]);
};

export const useBehaviorTypes = (): SelectMenuOption[] => {
  const [behaviorTypes] = useSelector(selectBalanceBehaviorTypes);
  return useMemo(() => behaviorTypes.map((type: AcBehaviorTypesModel) => ({
    id: String(type.id),
    value: type.id,
    label: String(type.name),
  })), [JSON.stringify(behaviorTypes)]);
};

export const useTtcYears = (): SelectMenuOption[] => {
  const [years] = useSelector(selectCurrentProjectYears);

  return useMemo(() => years.map((year: AcYearModel) => ({
    id: String(year.id),
    value: year.value,
    label: year.value,
  })), [JSON.stringify(years)]);
};
