import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { RouteWithAggregation } from 'src/modules/mth/common';
import AggloRouteTable from './AggloRouteTable';
import { OwnProps } from './interfaces';

const mapDispatchToProps = (dispatch: Dispatch<any>, { form, formActions }: OwnProps) => ({
  onRowClick: (route: RouteWithAggregation) => {
    dispatch(formActions.resetField('expandedRoute'));
    if (form.selectedRoute === route.id) {
      dispatch(formActions.resetField('selectedRoute'));
    } else {
      dispatch(formActions.changeField('selectedRoute', route.id));
    }
  },
  onExpanded: (route: RouteWithAggregation) => {
    dispatch(formActions.changeField('selectedRoute', route.id));
    if (form.expandedRoute === route.id) {
      dispatch(formActions.resetField('expandedRoute'));
    } else {
      dispatch(formActions.changeField('expandedRoute', route.id));
    }
  },
  onSort: (name?: string, order?: 1 | -1) => {
    const newOrder = (order && order > 0) ? -1 : 1;
    if (form.sortingColumn === name) {
      dispatch(formActions.changeField('sortingOrder', newOrder));
    } else {
      dispatch(formActions.changeField('sortingColumn', name));
    }
  },
});

export default connect(null, mapDispatchToProps)(withTranslate(AggloRouteTable));
