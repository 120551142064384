import React, { FC, useEffect } from 'react';

import { WithTranslate } from 'src/i18n';

import { MapPage } from 'src/cluster/common';
import {
  MapModes,
  AcDistributionMapPageForm,
  useGeojsonData,
  DistributionMap,
  DistributionMapSidebar,
  DistributionDiagramMapLegend,
  DistributionHeatMapLegend,
} from 'src/cluster/distribution-map';

export interface InjectedProps {
  pageForm: AcDistributionMapPageForm;
  onInit(): void;
}

type Props = InjectedProps & WithTranslate;

const DistributionMapPage: FC<Props> = (props) => {
  const {
    pageForm,
    pageForm: { mapMode },
    onInit,
  } = props;

  useEffect(onInit, []);

  useGeojsonData();

  return (
    <MapPage<AcDistributionMapPageForm>
      pageForm={pageForm}
      sidebar={DistributionMapSidebar}
      map={DistributionMap}
      legend={mapMode === MapModes.diagram ? DistributionDiagramMapLegend : DistributionHeatMapLegend}
    />
  );
};

export default DistributionMapPage;
