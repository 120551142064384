/* eslint-disable max-len */
import React from 'react';

const Building = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <path d="M500,504c6.6,0,12-5.4,12-12v-24c0-6.6-5.4-12-12-12h-20V180c0-6.6-5.4-12-12-12H368V20c0-6.6-5.4-12-12-12H156  c-6.6,0-12,5.4-12,12v68H44c-6.6,0-12,5.4-12,12v356H12c-6.6,0-12,5.4-12,12v24c0,6.6,5.4,12,12,12H500z M128,424H80v-48h48V424z   M128,344H80v-48h48V344z M128,264H80v-48h48V264z M128,184H80v-48h48V184z M240,424h-48v-48h48V424z M240,344h-48v-48h48V344z   M240,264h-48v-48h48V264z M240,184h-48v-48h48V184z M240,104h-48V56h48V104z M320,424h-48v-48h48V424z M320,344h-48v-48h48V344z   M320,264h-48v-48h48V264z M320,184h-48v-48h48V184z M320,104h-48V56h48V104z M432,424h-48v-48h48V424z M432,344h-48v-48h48V344z   M432,264h-48v-48h48V264z" />
  </svg>
);

export default Building;
