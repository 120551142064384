import { AcBalanceForecastForm, BalanceFilterModes } from 'src/cluster/common';
import { isNumber } from 'src/helpers';

export function getCorrespondencesFilter(form: AcBalanceForecastForm) {
  const {
    filterModeTo,
    filterModeFrom,
    regionFrom,
    regionTo,
    municipalityFrom,
    municipalityTo,
    year,
    scenario: scenarioId,
    currentPage,
    pageSize,
    interval: intervalId,
  } = form;

  if (!isNumber(year) || !isNumber(scenarioId) || !isNumber(intervalId)) return undefined;

  return {
    page: currentPage,
    pageSize,
    regionFromId: filterModeFrom === BalanceFilterModes.node ? regionFrom : undefined,
    regionToId: filterModeTo === BalanceFilterModes.node ? regionTo : undefined,
    municipalityFromId: filterModeFrom === BalanceFilterModes.municipality ? municipalityFrom : undefined,
    municipalityToId: filterModeTo === BalanceFilterModes.municipality ? municipalityTo : undefined,
    scenarioId,
    year,
    intervalId,
  };
}
