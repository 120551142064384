import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => createStyles({
  root: {},

  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },

  diagram: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: spacing(3, 0),

    '&$stops': {
      gap: spacing(8),
    },
  },

  edges: {
    paddingBottom: spacing(8),
  },

  stops: {
    paddingBottom: spacing(5),

    '& $item': {
      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
    },
  },

  item: {
    backgroundColor: palette.grey[300],
    position: 'relative',
    borderColor: palette.grey[400],

    '&::before': {
      content: 'attr(data-label)',
      position: 'absolute',
      bottom: -20,
      left: '50%',
      transform: 'translate(-50%)',
      fontSize: 12,
      whiteSpace: 'nowrap',
    },
  },
}));
