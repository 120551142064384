import { AcEntitiesEditorForm } from 'src/cluster/editor-map';

const defaults: AcEntitiesEditorForm = {
  editableRoute: undefined,
  editableEdgeSpeeds: undefined,
  editableEdgeSpeedChanges: undefined,
  editableStop: undefined,
};

export default { defaults };
