import React, { FC, Fragment } from 'react';
import { Divider } from '@material-ui/core';

import { AutocompleteOption } from 'src/interfaces';
import { WithTranslate } from 'src/i18n';
import {
  ActionButtonGroup, Autocomplete, Button, CopyIcon, ExportIcon, ToolbarControl, ToolbarControls, ToolbarTitle,
} from 'src/components';
import { sortOptionsByNumber } from 'src/helpers';

import { useEditorGraphParams } from 'src/cluster/editor-common';
import {
  AcEditorPageForm, EditorMode, EditorSettings, useFilterValues, useRouteNumbers, useVehicleTypesOptions,
} from 'src/cluster/editor-map';

import useStyles from './styles';

export interface InjectedProps {
  pageForm: AcEditorPageForm;
  startCopying(): void;
  startExport(): void;
  setVehicleType(option: AutocompleteOption): void;
  setSelectedRoute(option: AutocompleteOption): void;
}

type Props = InjectedProps & WithTranslate;

const EditorFilters: FC<Props> = (props) => {
  const {
    pageForm: {
      routeId,
      editorMode,
    },
    t,
    startCopying,
    startExport,
    setVehicleType,
    setSelectedRoute,
  } = props;

  function getTitle() {
    if (routeId && routeId < 0) {
      return t('modules.editor.captions.createRouteTitle');
    }

    return (editorMode === EditorMode.view)
      ? t('modules.editor.captions.routeCatalog')
      : t('modules.editor.captions.editRouteTitle');
  }

  const classes = useStyles();

  const vehicleTypeOptions = useVehicleTypesOptions();
  const routeNumbers = useRouteNumbers();

  const [year, scenario] = useEditorGraphParams();
  const [vehicleTypeOption, routeNumberOption] = useFilterValues();

  const toolbarTitle = getTitle();

  const isEditing = [EditorMode.addPoint, EditorMode.removePoint].includes(editorMode);

  return (
    <Fragment>
      <div className={classes.container}>
        <EditorSettings canShowModal={editorMode === EditorMode.view} />
      </div>
      <Divider />
      <div className={classes.container}>
        <ActionButtonGroup className={classes.buttons}>
          <Button
            variant="icon"
            title={t('common.captions.export')}
            disabled={!year || !scenario}
            onClick={startExport}
          >
            <ExportIcon />
          </Button>
          <Button
            variant="icon"
            title={t('common.captions.copy')}
            disabled={!year || !scenario}
            onClick={startCopying}
          >
            <CopyIcon />
          </Button>
        </ActionButtonGroup>
        <ToolbarTitle>{toolbarTitle}</ToolbarTitle>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <Autocomplete
              label={t('modules.editor.captions.vehicleType')}
              placeholder={t('components.captions.notSelected')}
              multiple={false}
              hideClear={isEditing}
              options={vehicleTypeOptions}
              selected={vehicleTypeOption}
              disabled={isEditing}
              onChange={setVehicleType}
            />
          </ToolbarControl>
        </ToolbarControls>
        <ToolbarControls className={classes.select}>
          <ToolbarControl size="full-width">
            <Autocomplete
              label={t('modules.editor.captions.routeNumber')}
              placeholder={t('components.captions.notSelected')}
              multiple={false}
              options={routeNumbers}
              selected={routeNumberOption}
              hideClear={isEditing}
              disabled={!vehicleTypeOption || !year || !scenario || isEditing}
              sort={sortOptionsByNumber}
              onChange={setSelectedRoute}
            />
          </ToolbarControl>
        </ToolbarControls>
      </div>
    </Fragment>
  );
};

export default EditorFilters;
