import React from 'react';

import useStyles from './styles';

const RegistryModalTableFilters = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>Дополнительные фильтры отсутствуют</div>
  );
};

export default RegistryModalTableFilters;
