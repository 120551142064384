import { AC_REGION_TYPES_ENDPOINT } from 'src/cluster/balance-data';
import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: AC_REGION_TYPES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  idField: () => Math.random(),
  defaults: {
    name: undefined,
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
  pagination: false,
};
