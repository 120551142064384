import { last } from 'lodash';

function getUrlFilename(xhr: XMLHttpRequest): string | undefined {
  const urlFilename = last(xhr.responseURL.split('/'));
  if (urlFilename === undefined) {
    return undefined;
  }
  return decodeURI(urlFilename);
}

export function getFilenameFromPath(path?: string): string | undefined {
  const urlFilename = last(path?.split('/'));
  if (urlFilename === undefined) {
    return undefined;
  }
  return decodeURI(urlFilename);
}

export function getFilename(xhr: XMLHttpRequest): string | undefined {
  const contentDisposition = xhr.getResponseHeader('content-disposition');
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  if (contentDisposition === null) {
    return getUrlFilename(xhr);
  }
  const matches = filenameRegex.exec(contentDisposition);
  if (matches === null) {
    return getUrlFilename(xhr);
  }
  return matches[1].replace(/['"]/g, '');
}

export const downloadFile = (blob: Blob | string, fileName?: string) => {
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', typeof blob === 'string' ? blob : window.URL.createObjectURL(blob));
  if (fileName) {
    element.setAttribute('download', fileName);
  }
  element.style.display = 'none';
  element.click();
  document.body.removeChild(element);
};
