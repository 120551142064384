import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Position } from 'src/interfaces';

import { selectCurrentProjectNodes, selectAcProjectListForm } from 'src/cluster/common';
import { selectEditorSelectedDirection } from 'src/cluster/editor-map';

const usePositionsForFit = (): Position[] => {
  const [canFitByNodes, setCanFitNodes] = useState(true);
  const { selectedProject } = useSelector(selectAcProjectListForm);
  const [nodes, areNodesLoading] = useSelector(selectCurrentProjectNodes);
  const selectedDirection = useSelector(selectEditorSelectedDirection);

  useEffect(() => setCanFitNodes(true), [selectedProject]);

  return useMemo(() => selectedDirection.cata(
    () => {
      if (canFitByNodes && !areNodesLoading) {
        setCanFitNodes(false);
        return nodes.map(node => [node.lng, node.lat]);
      }

      return [];
    },
    (value) => value.nodes.map(node => node.coordinates),
  ), [selectedDirection, nodes]);
};

export default usePositionsForFit;
