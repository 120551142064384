import { Theme, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }: Theme) => ({
  exportCaption: {
    color: palette.common.black,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingLeft: spacing(2),
  },
  moreButton: {
    textDecoration: 'none',
  },
  clearButton: {
    marginLeft: spacing(2),
    fontSize: 10,
    textDecoration: 'none',
  },
}));
