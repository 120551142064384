import { AcBalanceTtcModel } from 'src/cluster/common';

export function isRecordValid(record: AcBalanceTtcModel) {
  return record.regionFromId && record.regionToId
    && (record.year > 1900 && record.year < 2100)
    && record.source.length > 0
    && record.ttcBase >= 0;
}

export function areRecordsValid(newData: AcBalanceTtcModel[]) {
  return newData.every(isRecordValid);
}
