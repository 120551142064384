import React, { FC } from 'react';

import useStyles from './styles';

interface Props {
  errorMessage: string;
}

const ModalErrorDataShow: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    errorMessage,
  } = props;
  return (
    <div className={classes.root}>
      {errorMessage}
    </div>
  );
};

export default ModalErrorDataShow;
