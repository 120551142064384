import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withTranslate } from 'src/i18n';
import { DropDownValue } from 'src/components/deprecated/PSelect';

import { findRoutes } from 'src/modules/mth/calculations/actions';
import AggloMapFilterCorrespondences from './AggloMapFilterCorrespondences';
import { OwnProps } from './interfaces';

const mapDispatchToProps = (dispatch: Dispatch<any>, { formActions }: OwnProps) => ({
  onFromChange: (values: DropDownValue[]) => {
    dispatch(formActions.resetField('selectedEdges'));
    dispatch(formActions.resetField('selectedRoute'));
    dispatch(formActions.resetField('expandedRoute'));
    dispatch(formActions.changeField('from', values[0]));
  },
  onToChange: (values: DropDownValue[]) => {
    dispatch(formActions.resetField('selectedEdges'));
    dispatch(formActions.resetField('selectedRoute'));
    dispatch(formActions.resetField('expandedRoute'));
    dispatch(formActions.changeField('to', values[0]));
  },
  onCalculateRouteClick: () => dispatch(findRoutes()),
});

export default connect(null, mapDispatchToProps)(withTranslate(AggloMapFilterCorrespondences));
