import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import Slider, { SliderProps } from '@material-ui/core/Slider';

import { DEFAULT_PERCENT_SLIDER_STEP, DISPATCH_DEBOUNCE } from 'src/constants';
import PLabel from 'src/components/deprecated/PLabel';
import PInput, { InputTypes, INPUT_TYPES } from 'src/components/deprecated/PInput';

import style from './index.module.css';

interface Props extends Omit<SliderProps, 'onChange'> {
  className?: string;
  label?: ReactNode;
  leftMark?: ReactNode;
  rightMark?: ReactNode;
  name: string;
  value: number;
  valueType?: InputTypes;
  disabled?: boolean;
  onChange: (value: number) => void;
}

/**
 * @deprecated  Use <Slider> instead.
 */
const PSlider: FC<Props> = ({
  className,
  label,
  leftMark,
  rightMark,
  value,
  valueType,
  name,
  min = 0,
  max = 100,
  disabled = false,
  onChange,
  ...other
}) => {
  // Use inner state for lag optimization
  const [sliderValue, setSliderValue] = useState<number | undefined>(undefined);
  const onSliderChange = useMemo(() => {
    return throttle((v: any) => {
      onChange(v);
    }, DISPATCH_DEBOUNCE);
  }, []);

  return (
    <div className={classNames(style.root, className)}>
      {label && (
        <div id={name}>
          <PLabel label={label} />
        </div>
      )}
      <PInput {...{
        className: style.inputWrapper,
        inputClassName: style.input,
        type: valueType === undefined ? INPUT_TYPES.percent : valueType,
        value: sliderValue === undefined ? value : sliderValue,
        disabled,
        onChange: (v: any) => {
          onChange(v);
          setSliderValue(undefined);
        },
        min,
        max,
      }} />
      <div className={style.sliderWrapper}>
        {leftMark && <span className={style.leftMark}>{leftMark}</span>}
        <Slider aria-labelledby={name} {...{
          ...other,
          min,
          max,
          value: sliderValue === undefined ? value : sliderValue,
          classes: {
            rail: style.sliderRail,
          },
          disabled,
          onChange: (e, v) => {
            const newValue = Array.isArray(v) ? v[0] : v;
            onSliderChange(newValue);
            setSliderValue(newValue);
          },
          getAriaValueText: (number) => (number ? number.toString() : ''),
          step: DEFAULT_PERCENT_SLIDER_STEP,
        }} />
        {rightMark && <span className={style.rightMark}>{rightMark}</span>}
      </div>
    </div>
  );
};

export default PSlider;
