import React, { FC } from 'react';
import moment from 'moment';

import { WithTranslate } from 'src/i18n';
import { isNumber } from 'src/helpers';
import {
  SmartDateFormats,
  SMART_DATE_FORMATS,
  SMART_DATE_FORMATS_FUNCTIONS,
} from './constants';

interface Props extends WithTranslate {
  className?: string;
  date: number | string | moment.Moment | undefined;
  format?: SmartDateFormats;
  defaultEmptyMessage?: string;
}

const UNIX_TIMESTAMP_CHECKER = 1544399;
const MS_IN_SEC = 1000;

const SmartDate: FC<Props> = (props) => {
  const {
    className,
    date,
    format = SMART_DATE_FORMATS.simpleDate,
    defaultEmptyMessage,
    t,
  } = props;

  const isDateInMilliseconds = isNumber(date) && (date / MS_IN_SEC < UNIX_TIMESTAMP_CHECKER);

  const realDate = isDateInMilliseconds ? Number(date) * MS_IN_SEC : date;

  const dateToDisplay = moment(realDate);
  const hasDate = realDate && dateToDisplay.isValid();

  return (
    <div className={className}>
      {hasDate
        ? SMART_DATE_FORMATS_FUNCTIONS[format](dateToDisplay)
        : defaultEmptyMessage || t('components.captions.unknownDate')}
    </div>
  );
};

export default SmartDate;
