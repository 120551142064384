import i18n from 'src/i18n';
import { useAcScenarios } from 'src/cluster/common';

export const useEditorScenarioName = (): (id?: number) => string => {
  const scenarios = useAcScenarios();

  return (id?: number) => {
    const record = scenarios.find((scenario) => {
      return scenario.id === id;
    });

    return record ? record.name : i18n.t('components.captions.notSelected');
  };
};

export default useEditorScenarioName;
