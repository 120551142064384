import React from 'react';

import style from '../index.module.css';
import { TRANSPORT_TYPE_COLORS, SegmentType } from '../../../constants';

interface Props {
  segmentTypes: SegmentType[];
  totalDistance: number;
}
const PERCENT_BAR_WIDTH = 135;
const PERCENT_MIN = 0.05;

const TransportTypePercent: React.FC<Props> = ({
  segmentTypes, totalDistance,
}) => {
  const segmentTypesPercents = (segmentTypes
    .filter(segment => !!TRANSPORT_TYPE_COLORS[segment.type])
    .reduce((memo: any[], segment) => {
      // collapse segments with same type
      const prev = memo.length ? memo[memo.length - 1] : undefined;
      if (prev && prev.type === segment.type) {
        prev.distance += segment.distance;
      } else {
        memo.push(segment);
      }
      return memo;
    }, [])
    .map(segment => {
      return {
        ...segment,
        // Air routes has no distance
        percent: segment.type === 'air' ? 1 : (segment.distance / totalDistance),
      };
    })
  );
  // round up small values
  for (let i = 0; i < segmentTypesPercents.length; i += 1) {
    let roundDiff = 0;
    if (segmentTypesPercents[i].percent < PERCENT_MIN) {
      roundDiff = PERCENT_MIN - segmentTypesPercents[i].percent;
      segmentTypesPercents[i].percent = PERCENT_MIN;
      let max = 0;
      let maxJ = 0;
      for (let j = 0; j < segmentTypesPercents.length; j += 1) {
        // find max and substract diff used to round small segment up
        if (j !== i && segmentTypesPercents[j].percent > max) {
          max = segmentTypesPercents[j].percent;
          maxJ = j;
        }
        if (j === (segmentTypesPercents.length - 1)) {
          segmentTypesPercents[maxJ].percent -= roundDiff;
        }
      }
    }
  }
  return (
    <span className={style.transportTypePercentWrapper}>
      {segmentTypesPercents
        .map((segmentType, index, segments) => {
          const segmentClasses = [style.transportTypePercent];
          const segmentStyle = {
            width: segmentType.percent * PERCENT_BAR_WIDTH,
            backgroundColor: TRANSPORT_TYPE_COLORS[segmentType.type],
          };
          if (index === 0) {
            segmentClasses.push(style.transportTypePercentLeft);
          }
          if (index === (segments.length - 1)) {
            segmentClasses.push(style.transportTypePercentRight);
          }
          return (
            <span
              className={segmentClasses.join(' ')}
              style={segmentStyle}
              key={index}
            >
            </span>
          );
        })}
    </span>
  );
};

export default TransportTypePercent;
