import React, { FC, useCallback } from 'react';

import { WithTranslate } from 'src/i18n';
import { EdgeModel } from 'src/modules/mth/common/interfaces';
import { AGGLOMERATION_EDGE_TYPES } from 'src/modules/mth/common/constants';
import PIcon, { ICONS_TYPES, ROTATE_TYPES } from 'src/components/deprecated/PIcon';
import NumberFormat from 'src/components/etc/NumberFormat';

import { UNITS } from 'src/modules/general/common';
import style from './index.module.css';

const RIGHT_ARROW = '\u21e8';
const RIGHT_UP_ARROW = '\u2b00';
const UP_ARROW = '\u21e7';
const LEFT_UP_ARROW = '\u2b01';
const LEFT_ARROW = '\u21e6';
const LEFT_DOWN_ARROW = '\u2b03';
const DOWN_ARROW = '\u21e9';
const RIGHT_DOWN_ARROW = '\u2b02';

function getDirection(edge: EdgeModel): string {
  const oppositeLeg = edge.secondNode[1] - edge.firstNode[1];
  const adjacentLeg = edge.secondNode[0] - edge.firstNode[0];
  const tan = oppositeLeg / adjacentLeg;
  if (adjacentLeg > 0) {
    if (tan <= 0.4142 && tan > -0.4142) return RIGHT_ARROW;
    if (tan >= 0.4142 && tan < 2.4142) return RIGHT_UP_ARROW;
    if (tan <= -0.4142 && tan > -2.4142) return RIGHT_DOWN_ARROW;
  }

  if (tan <= 0.4142 && tan > -0.4142) return LEFT_ARROW;
  if (tan >= 0.4142 && tan < 2.4142) return LEFT_DOWN_ARROW;
  if (tan <= -0.4142 && tan > -2.4142) return LEFT_UP_ARROW;

  if (oppositeLeg > 0) return UP_ARROW;
  return DOWN_ARROW;
}

interface Props extends WithTranslate {
  type: string;
  edge?: EdgeModel;
  placeholder?: string;
  empty?: string;
  showCorrespondences?: boolean;
  showEdgeRoutes(edgeId: number, type: string): void;
}

const AggloMapFilterEdgeTable: FC<Props> = (props) => {
  const {
    type,
    edge,
    placeholder = '...',
    empty = '-',
    showCorrespondences = false,
    t,
    showEdgeRoutes,
  } = props;

  const isAuto = type === AGGLOMERATION_EDGE_TYPES.car
    || type === AGGLOMERATION_EDGE_TYPES.cargo
    || type === AGGLOMERATION_EDGE_TYPES.taxi;

  const isPedestrian = type === AGGLOMERATION_EDGE_TYPES.pedestrian;

  const handleDetailsClick = useCallback(() => {
    const edgeId = Number(edge?.id);
    if (edgeId) {
      showEdgeRoutes(edgeId, type);
    }
  }, [edge, type]);

  const direction = edge ? getDirection(edge) : '';

  return (
    <table className={style.root}>
      <tbody>
        <tr>
          <td>
            <span>ID</span>
          </td>
          <td>
            {edge ? edge.id || empty : placeholder}
            <span className={style.direction}>{direction}</span>
          </td>
        </tr>
        {isAuto && (
          <tr>
            <td>
              {t('common.captions.traffic')}
            </td>
            <td>
              {edge ? (
                <NumberFormat {...{
                  value: edge.traffic,
                  decimalScale: 0,
                  suffix: ` ${t(UNITS.vehicles)}`,
                  defaultValue: empty,
                  zeroPlaceholder: '-',
                }} />
              ) : placeholder}
            </td>
          </tr>
        )}
        <tr>
          <td>
            {t('systems.mth.captions.passengerFlow')}
          </td>
          <td>
            {edge ? (
              <NumberFormat {...{
                value: edge.trafficPass,
                decimalScale: 0,
                suffix: ` ${t(UNITS.peoplePerHour)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            ) : placeholder}
          </td>
        </tr>
        {
          showCorrespondences && (
            <tr>
              <td>
                {t('common.captions.correspondence')}
              </td>
              <td className={style.details}>
                <button {...{
                  className: style.button,
                  onClick: handleDetailsClick,
                  disabled: !edge || !edge.id,
                }}>
                  <span>{t('common.captions.details')}</span>
                  <span className={style.icon}>
                    <PIcon {...{
                      type: ICONS_TYPES.arrow,
                      rotate: ROTATE_TYPES.left,
                    }} />
                  </span>
                </button>
              </td>
            </tr>
          )
        }
        <tr>
          <td>
            {t('systems.mth.captions.length')}
          </td>
          <td>
            {edge ? (
              <NumberFormat {...{
                value: edge.distance,
                decimalScale: 2,
                suffix: ` ${t(UNITS.length)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            ) : placeholder}
          </td>
        </tr>
        {isAuto && (
          <tr>
            <td>
              {t('systems.mth.captions.fuelCost')}
            </td>
            <td>
              {edge ? (
                <NumberFormat {...{
                  value: edge.fuelCost,
                  decimalScale: 1,
                  suffix: ` ${t(UNITS.costRub)}`,
                  defaultValue: empty,
                  zeroPlaceholder: '-',
                }} />
              ) : placeholder}
            </td>
          </tr>
        )}
        <tr>
          <td>
            {t('systems.mth.captions.time')}
          </td>
          <td>
            {edge ? (
              <NumberFormat {...{
                value: edge.time ? edge.time * 60 : empty,
                decimalScale: 2,
                suffix: ` ${t(UNITS.timeMinutes)}`,
                zeroPlaceholder: '-',
              }} />
            ) : placeholder}
          </td>
        </tr>
        <tr>
          <td>
            {t('systems.mth.captions.timeMoney')}
          </td>
          <td>
            {edge ? (
              <NumberFormat {...{
                value: edge.timeMoney,
                decimalScale: 1,
                suffix: ` ${t(UNITS.costRub)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            ) : placeholder}
          </td>
        </tr>
        {isAuto && (
          <tr>
            <td>
              {t('systems.mth.captions.fare')}
            </td>
            <td>
              {edge ? (
                <NumberFormat {...{
                  value: edge.fare,
                  decimalScale: 1,
                  suffix: ` ${t(UNITS.costRub)}`,
                  defaultValue: empty,
                  zeroPlaceholder: '-',
                }} />
              ) : placeholder}
            </td>
          </tr>
        )}
        <tr>
          <td>
            {t('systems.mth.captions.ttc')}
          </td>
          <td>
            {edge ? (
              <NumberFormat {...{
                value: edge.ttc,
                decimalScale: 1,
                suffix: ` ${t(UNITS.costRub)}`,
                defaultValue: empty,
                zeroPlaceholder: '-',
              }} />
            ) : placeholder}
          </td>
        </tr>
        {
          !isPedestrian && (
            <tr>
              <td>
                {t(isAuto ? 'common.captions.bandwidth' : 'common.captions.capacity')}
              </td>
              <td>
                {edge ? (
                  <NumberFormat {...{
                    value: edge.bandwidth,
                    decimalScale: 0,
                    suffix: ` ${t(isAuto ? UNITS.vehicles : UNITS.peoplePerHour)}`,
                    defaultValue: empty,
                    zeroPlaceholder: '-',
                  }} />
                ) : placeholder}
              </td>
            </tr>
          )
        }
      </tbody>
    </table>
  );
};

export default AggloMapFilterEdgeTable;
