import { createStyles, makeStyles } from '@material-ui/core';

import { MAIN_MENU_WIDTH } from 'src/theme/constants';

export default makeStyles(({ palette }) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: palette.grey[50],
  },
  mainApp: {
    display: 'flex',
    height: '100%',
  },
  menu: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: MAIN_MENU_WIDTH,
    height: '100%',
  },
  content: {
    flex: 1,
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));
