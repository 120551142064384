export const AC_PROJECTS_MODEL_NAME = 'acProjects';
export const AC_YEARS_MODEL_NAME = 'acYears';
export const AC_SCENARIOS_MODEL_NAME = 'acScenarios';
export const AC_INTERVALS_MODEL_NAME = 'acIntervals';
export const AC_BEHAVIOR_TYPES_MODEL_NAME = 'acBehaviorTypes';
export const AC_BASE_SCENARIO_MODEL_NAME = 'acBaseScenario';
export const AC_MATRIX_FORECAST_INFO_MODEL_NAME = 'acMatrixForecastInfo';
export const AC_VEHICLE_CLASSES_MODEL_NAME = 'acVehicleClasses';
export const AC_VEHICLE_TYPES_MODEL_NAME = 'acVehicleTypes';
export const AC_ROUTES_MODEL_NAME = 'acRoutes';
export const AC_STOPS_MODEL_NAME = 'acStops';
export const AC_SITES_MODEL_NAME = 'acSites';
export const AC_COMMUNICATION_TYPES_MODEL_NAME = 'acCommunicationTypes';
export const AC_REGULAR_TRANSPORTATION_TYPES_MODEL_NAME = 'acRegularTransportationTypes';
export const AC_MUNICIPALITIES_MODEL_NAME = 'acMunicipalities';
export const AC_NODES_MODEL_NAME = 'acNodes';

export const AC_ROUTES_VARIANTS_MODEL_NAME = 'acRoutesVariants';
export const AC_ROUTES_DIRECTIONS_MODEL_NAME = 'acRoutesDirections';

export const AC_COPY_REQUESTS_MODEL_NAME = 'acCopyRequests';

export const AC_DATA_MODEL_NAME = 'acData';
export const AC_DATA_REGION_TYPES_MODEL_NAME = 'acDataRegionTypes';
export const AC_DATA_FACTORS_MODEL_NAME = 'acDataFactors';

export const TTC_SITES_MODEL_NAME = 'acTtcSites';
export const TTC_REGIONS_MODEL_NAME = 'acTtcRegions';

export const AC_FORECAST_CORRESPONDENCES_MODEL_NAME = 'acForecastCorrespondences';
export const AC_FORECAST_TRAFFIC_SUM_MODEL_NAME = 'acForecastTrafficSum';

export const AC_REGISTRY_CARRIERS_MODEL_NAME = 'acRegistryCarriers';
export const AC_REGISTRY_ROUTES_MODEL_NAME = 'acRegistryRoutes';
export const AC_REGISTRY_SEASONS_MODEL_NAME = 'acRegistrySeasons';
export const AC_REGISTRY_VEHICLE_CLASSES_MODEL_NAME = 'acRegistryVehicleClasses';

export const AC_DIST_GRAPH_VERSION_MODAL_NAME = 'acDistGraphVersion';
export const AC_DIST_DIRECTION_EDGES_MODEL_NAME = 'acDistributionDirectionEdges';

export const AC_DASH_GRAPH_VERSION_MODAL_NAME = 'acDashGraphVersion';
export const AC_DASH_CATEGORIES_MODEL_NAME = 'acDashCategories';
export const AC_DASH_INDICATOR_RESULT_MODEL_NAME = 'acDashIndicatorResults';
