import React, { FC } from 'react';
import { WithTranslate } from 'src/i18n';

import { VehicleType, isAcRouteDirection, AcRouteVariant } from 'src/cluster/common';
import {
  AcEditorPageForm,
  EditorErrorMessage,
  EditorMode,
  EditorPrimaryTreeItem,
  EditorRouteDirection,
} from 'src/cluster/editor-map';

import { RouteVariantHeader } from './components';

import { useCommonStyles } from './styles';

interface OwnProps {
  selectedNodeId?: number;
  selectedNodeIndex?: number;
  pageForm: AcEditorPageForm;
  isDirty: boolean;
  hasError: boolean;
  canDeleteVariant: boolean;
  canCopyVariant: boolean;
  variant: AcRouteVariant;
  vehicleTypeName?: VehicleType;
  changeVariantNumber(id: number, value: string): void;
  exportVariant(id: number): void;
  copyVariant(variantId: number): void;
  deleteVariant(variantId: number, variantNumber: string): void;
}

type Props = OwnProps & WithTranslate;

const EditorRouteVariant: FC<Props> = (props) => {
  const {
    pageForm: { editorMode },
    isDirty,
    hasError,
    variant,
    canDeleteVariant,
    canCopyVariant,
    vehicleTypeName,
    t,
    changeVariantNumber,
    exportVariant,
    copyVariant,
    deleteVariant,
    selectedNodeId,
    selectedNodeIndex,
  } = props;

  const commonClasses = useCommonStyles();

  const { id, variantNumber, routeDirections } = variant;

  const isEditing = [EditorMode.addPoint, EditorMode.removePoint].includes(editorMode);

  const handleDeleteVariant = () => {
    if (isEditing) deleteVariant(id, variantNumber);
  };

  const handleExportVariant = () => {
    exportVariant(id);
  };

  const handleCopyVariant = () => {
    copyVariant(id);
  };

  const handleVariantNumberChange = (value: string) => {
    changeVariantNumber(id, value);
  };

  const errorText = variantNumber === ''
    ? t('modules.editor.messages.emptyVariantNumberError')
    : t('modules.editor.messages.uniqVariantNumberError');

  return (
    <EditorPrimaryTreeItem
      nodeId={`variant@@${id}`}
      label={(
        <RouteVariantHeader
          isModifying={editorMode === EditorMode.addPoint}
          variantNumber={variantNumber}
          hasError={isDirty && hasError}
          canDelete={canDeleteVariant}
          canCopy={canCopyVariant}
          changeVariantNumber={handleVariantNumberChange}
          copyVariant={handleCopyVariant}
          exportVariant={handleExportVariant}
          deleteVariant={handleDeleteVariant}
        />
      )}
    >
      {editorMode && isDirty && hasError && (
        <div className={commonClasses.error}>
          <EditorErrorMessage
            id={`variant-number-error-${variantNumber}`}
            text={errorText}
          />
        </div>
      )}
      {routeDirections.sort((direction) => (direction.direction ? -1 : 1)).map((direction) => (
        isAcRouteDirection(direction)
          ? (
            <EditorRouteDirection
              selectedNodeId={selectedNodeId}
              selectedNodeIndex={selectedNodeIndex}
              key={direction.id}
              direction={direction}
              isOnlyDirection={variant.routeDirections.length === 1}
              variantId={id}
              vehicleTypeName={vehicleTypeName}
            />
          ) : null))}
    </EditorPrimaryTreeItem>
  );
};

export default EditorRouteVariant;
