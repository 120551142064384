import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { urlSchema, getUrl } from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';
import { ProjectsPage } from 'src/cluster/projects';

type Props = WithTranslate;

const ProjectsRoot: FC<Props> = ({ t }) => (
  <PageWithHeader
    basePath={['agglomeration']}
    menuRenderers={{
      projects: {
        label: t('modules.projects.captions.title'),
      },
    }}
    headerChildren={<Route path={getUrl(urlSchema.agglomeration.editor)} />}
  >
    <ProjectsPage />
  </PageWithHeader>
);

export default ProjectsRoot;
