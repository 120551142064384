import React from 'react';

const MenuBalanceIcon = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6769 14.4791C17.9188 14.4113 18.1739 14.375 18.4375 14.375C19.9908 14.375 21.25 15.6342 21.25 17.1875C21.25 18.7408 19.9908 20 18.4375 20C17.099 20 15.9789 19.065 15.6947 17.8125H5.55531C5.2711 19.065 4.15099 20 2.8125 20C1.2592 20 0 18.7408 0 17.1875C0 15.6342 1.2592 14.375 2.8125 14.375C3.07609 14.375 3.3312 14.4113 3.57314 14.4791L8.76642 4.92342C8.18151 4.40803 7.8125 3.65336 7.8125 2.8125C7.8125 1.2592 9.0717 0 10.625 0C12.1783 0 13.4375 1.2592 13.4375 2.8125C13.4375 3.65336 13.0685 4.40803 12.4836 4.92342L17.6769 14.4791ZM11.3856 5.5208C11.1405 5.58949 10.8851 5.625 10.625 5.625C10.3649 5.625 10.1095 5.58949 9.86444 5.5208L4.67119 15.0764C5.11239 15.4648 5.42351 15.984 5.55503 16.5625H15.695C15.8265 15.984 16.1376 15.4648 16.5788 15.0764L11.3856 5.5208ZM10.625 4.375C11.4879 4.375 12.1875 3.67545 12.1875 2.8125C12.1875 1.94955 11.4879 1.25 10.625 1.25C9.76205 1.25 9.0625 1.94955 9.0625 2.8125C9.0625 3.67545 9.76205 4.375 10.625 4.375ZM18.4375 18.75C19.3004 18.75 20 18.0504 20 17.1875C20 16.3246 19.3004 15.625 18.4375 15.625C17.5746 15.625 16.875 16.3246 16.875 17.1875C16.875 18.0504 17.5746 18.75 18.4375 18.75ZM2.8125 18.75C3.67545 18.75 4.375 18.0504 4.375 17.1875C4.375 16.3246 3.67545 15.625 2.8125 15.625C1.94955 15.625 1.25 16.3246 1.25 17.1875C1.25 18.0504 1.94955 18.75 2.8125 18.75Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

export default MenuBalanceIcon;
