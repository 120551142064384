import { MUNICIPALITIES_ENDPOINT } from 'src/api-url-schema';
import { AC_PROJECTS_MODEL_NAME } from 'src/cluster/common';

export default {
  endpoint: MUNICIPALITIES_ENDPOINT,
  parent: AC_PROJECTS_MODEL_NAME,
  defaults: {
    id: undefined,
    name: undefined,
  },
  clearPagesOnRouteChange: false,
  allowIdRequests: false,
  pagination: false,
};
