import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { uniq } from 'lodash';

import {
  Toolbar,
  ToolbarBlock,
  ToolbarControl,
  ToolbarControls,
} from 'src/components/toolbar';
import PButton from 'src/components/deprecated/PButton';
import PInput from 'src/components/deprecated/PInput';
import PSelect, { DropDownValue } from 'src/components/deprecated/PSelect';
import MultiValueInput from 'src/components/etc/MultiValueInput';

import { AcBalanceDataFactorsModel } from 'src/cluster/common';
import { Props } from './interfaces';

import style from './index.module.css';

const BalanceShapesToolbar: FC<Props> = (props) => {
  const {
    className,
    pageForm: {
      name,
      factors = [],
    },
    factors: [factorEntities],
    t,
    setName,
    setFactors,
    addShape,
  } = props;

  const factorItems = factorEntities.map((factor) => ({
    label: factor.name.split(', ')[0],
    value: factor.id,
  }));

  const renderMultiSelectValue = useCallback((v: string | number) => {
    const factor = factorEntities.find((f: AcBalanceDataFactorsModel) => f.id === v);
    if (!factor) {
      return '...';
    }
    const [factorName] = factor.name.split(', ');
    const shortFactorName = factorName.length < 20 ? factorName : `${factorName.slice(0, 17)}...`;
    return <span title={factorName}>{shortFactorName}</span>;
  }, [factorEntities]);

  const handleFactorsChange = useCallback((values: DropDownValue[]) => {
    setFactors(uniq(factors.concat(values as string[])));
  }, [factors]);

  const handleAddClick = () => addShape({
    name,
    modelType: 'gravitational',
    factors: factors.map((id: any) => ({ id })),
  });

  return (
    <Toolbar className={classNames(style.root, className)}>
      <ToolbarBlock title={t('modules.shapes.captions.addingNewShape')}>
        <ToolbarControls
          className={style.controls}
          position="top"
        >
          <ToolbarControl>
            <PInput
              label={t('common.captions.name')}
              className={style.input}
              placeholder={t('common.captions.name')}
              value={name}
              onChange={setName}
            />
          </ToolbarControl>
          <ToolbarControl>
            <MultiValueInput
              className={style.multiSelect}
              values={factors}
              renderValue={renderMultiSelectValue}
              onChange={setFactors}
            >
              <PSelect
                label={t('systems.balance.captions.factors')}
                multi
                items={factorItems}
                onChange={handleFactorsChange}
              />
            </MultiValueInput>
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
      <ToolbarBlock title={' '}>
        <ToolbarControls
          position="top"
          className={style.buttonControls}
        >
          <ToolbarControl>
            <PButton
              disabled={!name || name === ''}
              onClick={handleAddClick}
            >
              {t('common.captions.add')}
            </PButton>
          </ToolbarControl>
        </ToolbarControls>
      </ToolbarBlock>
    </Toolbar>
  );
};

export default BalanceShapesToolbar;
