import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import ExportToExcelButton from 'src/components/etc/ExportToExcelButton';
import PTable from 'src/components/deprecated/PTable';
import {
  toNumber, useRestifyForm,
} from 'src/helpers';

import { exportDataToCsv } from 'src/helpers/csv';
import { DEFAULT_NUMBER_ACCURACY, useSystemsContext } from 'src/constants';

import {
  VocabularyEntity,
  getVocabularyValue,
  useCurrentVocabulary,
  WithTranslate,
} from 'src/i18n';

import selectors from '../../selectors';
import {
  ROUTES_PAGES_FORM_NAMES,
  PageRoutesForm,
  typeMessages,
  EdgeModel,
  EDGE_TYPES,
  unitsMessages,
} from '../../constants';
import TransportTypePercent from './TransportTypePercent';
import TransportTypeIcons from './TransportTypeIcons';

import style from './index.module.css';

export const roundNumber = (value: number, accuracy?: number) => toNumber(
  value,
  accuracy !== undefined ? accuracy : DEFAULT_NUMBER_ACCURACY,
);

const columnMessages = {
  routeIndex: 'systems.shipments.captions.routeIndex',
  type: 'systems.shipments.captions.type',
  country: 'systems.shipments.captions.country',
  distance: 'systems.shipments.captions.length',
  unitCost: 'systems.shipments.captions.unitCost',
  tariff: 'systems.shipments.captions.rate',
  speed: 'systems.shipments.captions.speed',
  time: 'systems.shipments.captions.time',
  unitTimeCost: 'systems.shipments.captions.costPerKm',
  timeCost: 'systems.shipments.captions.timeCost',
  cargoCost: 'systems.shipments.captions.cargoCost',
  routeTrafficCargo: 'systems.shipments.captions.routeTrafficCargo',
};

interface Props extends WithTranslate {
  className?: string;
}

const RoutesTable: FC<Props> = ({
  className,
  t,
}) => {
  const dispatch = useDispatch();
  const currentSystem = useSystemsContext();
  const {
    currentRoutesIsLoading,
  } = useSelector(selectors[currentSystem].getCurrentProjectRoutes);
  const {
    summaryTraffic,
    aggregatedRoutes,
  } = useSelector(selectors[currentSystem].getCurrentProjectAggregatedRoutes);
  const [
    routesPageForm,
    routesPageFormActions,
  ] = useRestifyForm<PageRoutesForm>(ROUTES_PAGES_FORM_NAMES[currentSystem]);
  const [currentLocale, currentVocabulary] = useCurrentVocabulary();

  // Scroll to selected route
  const rowRefs = useRef<any[]>([]);
  useEffect(() => {
    if (routesPageForm.selectedRoute !== undefined) {
      const currentRow = rowRefs.current[routesPageForm.selectedRoute];
      if (currentRow) {
        scrollIntoViewIfNeeded(currentRow, {
          scrollMode: 'if-needed',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [routesPageForm.selectedRoute]);

  if (!aggregatedRoutes.length || currentRoutesIsLoading) return null;
  // Important to return string, cause we use it in csv export
  const renderSegmentType = (item: EdgeModel, index: number, array: EdgeModel[]): string => {
    const showCity = (
      item.type === EDGE_TYPES.autoBorderCross ||
      item.type === EDGE_TYPES.railBorderCross ||
      item.type === EDGE_TYPES.port ||
      item.type === EDGE_TYPES.railLoading
    );
    const railLoadingUnbalanced = array.reduce((memo, segment, segmentIndex) => {
      if (segmentIndex >= index) {
        return memo;
      }
      if (item.type === EDGE_TYPES.railLoading) {
        return !memo;
      }
      return memo;
    }, false);

    let result;
    if (railLoadingUnbalanced && item.type === EDGE_TYPES.railLoading) {
      result = t(typeMessages[EDGE_TYPES.railUnLoading]);
    } else {
      result = t(typeMessages[item.type]);
    }
    if (showCity) {
      result += `: ${getVocabularyValue(currentLocale, currentVocabulary, item.name)}`;
    }
    return result;
  };
  const getRouteExportableArray = (routeIndex: number) => {
    return aggregatedRoutes[routeIndex].aggregation.segments.map((segment, index, array) => ({
      routeIndex: routeIndex + 1,
      type: renderSegmentType(segment, index, array),
      country: getVocabularyValue(currentLocale, currentVocabulary, segment.county),
      distance: segment.distance,
      unitCost: segment.costPerKm,
      tariff: segment.tariff,
      speed: segment.speed,
      time: segment.time,
      unitTimeCost: segment.tariffHour,
      timeCost: segment.timeCost,
      cargoCost: segment.totalCost,
      routeTrafficCargo: aggregatedRoutes[routeIndex].routeTrafficCargo,
    }));
  };
  const exportColumnTitles = Object.entries(columnMessages).reduce<Record<string, string>>((memo, [key, value]) => ({
    ...memo,
    [key]: t(value),
  }), {});

  return (
    <div className={classNames(style.root, className)}>
      <PTable<typeof aggregatedRoutes[0]> {...{
        className: style.table,
        rowClassName: (item, index) => {
          return [
            routesPageForm.selectedRoute === index,
            routesPageForm.expandedRoute === index && style.expandedRow,
            style.row,
          ];
        },
        headerCellClassName: style.headerCell,
        tableCellClassName: style.tableCell,
        onRowClick: (item, index) => {
          dispatch(routesPageFormActions.resetField('expandedRoute'));
          if (routesPageForm.selectedRoute === index) {
            dispatch(routesPageFormActions.resetField('selectedRoute'));
          } else {
            dispatch(routesPageFormActions.changeField('selectedRoute', index));
          }
        },
        expandable: true,
        headerHoveredComponent: (
          <ExportToExcelButton {...{
            size: 18,
            className: style.headerExportButton,
            fixedSizeOnExpand: false,
            onClick: () => {
              if (!routesPageForm.fromActual || !routesPageForm.toActual) {
                return;
              }
              const from = getVocabularyValue(currentLocale, currentVocabulary, routesPageForm.fromActual);
              const to = getVocabularyValue(currentLocale, currentVocabulary, routesPageForm.toActual);
              exportDataToCsv({
                data: aggregatedRoutes.reduce<ReturnType<typeof getRouteExportableArray>>((memo, item, index) => {
                  return memo.concat(getRouteExportableArray(index));
                }, []),
                fileName: `${from}-${to}.csv`,
                columnTitles: exportColumnTitles,
              });
            },
          }} />
        ),
        hoveredComponent: (item, index) => (
          <ExportToExcelButton {...{
            size: 18,
            fixedSizeOnExpand: false,
            onClick: () => {
              const from = getVocabularyValue(currentLocale, currentVocabulary, item.from);
              const to = getVocabularyValue(currentLocale, currentVocabulary, item.to);
              exportDataToCsv({
                data: getRouteExportableArray(index),
                fileName: `${from}-${to} ${index + 1}.csv`,
                columnTitles: exportColumnTitles,
              });
            },
          }} />
        ),
        isExpanded: (item, index) => routesPageForm.expandedRoute === index,
        onExpanded: (item, index) => {
          dispatch(routesPageFormActions.changeField('selectedRoute', index));
          if (routesPageForm.expandedRoute === index) {
            dispatch(routesPageFormActions.resetField('expandedRoute'));
          } else {
            dispatch(routesPageFormActions.changeField('expandedRoute', index));
          }
        },
        rowExtraCell: (item, index) => {
          if (index !== routesPageForm.expandedRoute) return null;
          const { aggregation } = aggregatedRoutes[index];
          return (
            <div className={style.extraTable}>
              <PTable<EdgeModel> {...{
                tableCellClassName: style.aggregationTableCell,
                headerCellClassName: style.aggregationHeaderCell,
                header: [
                  {
                    title: '',
                    model: (model, modelIndex) => modelIndex + 1,
                    className: style.rowNumberColumn,
                  },
                  {
                    title: t('systems.shipments.captions.type'),
                    summaryModel: t('systems.shipments.captions.summary'),
                    className: style.typeColumn,
                    model: (model, modelIndex, array) => renderSegmentType(model, modelIndex, array),
                  },
                  {
                    title: t('systems.shipments.captions.country'),
                    model: (model) => <VocabularyEntity value={model.county} />,
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.length')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.length)}
                          )
                        </span>
                      </span>
                    ),
                    model: (model) => roundNumber(model.distance, 0),
                    summaryModel: roundNumber(aggregation.summary.distance, 0),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.unitCost')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.costPerKmPerTEU)}
                          )
                        </span>
                      </span>
                    ),
                    model: (model) => roundNumber(model.costPerKm),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.rate')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.costPerTEU)}
                          )
                        </span>
                      </span>
                    ),
                    model: (model) => roundNumber(model.tariff, 0),
                    summaryModel: roundNumber(aggregation.summary.tariff, 0),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.speed')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.speed)}
                          )
                        </span>
                      </span>
                    ),
                    model: (model) => roundNumber(model.speed, 0),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.time')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.time)}
                          )
                        </span>
                      </span>
                    ),
                    className: style.beforeRowSpanColumn,
                    model: (model) => roundNumber(model.time, 1),
                    summaryModel: roundNumber(aggregation.summary.time, 1),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.costPerKmShort')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.unitTimeCost)}
                          )
                        </span>
                      </span>
                    ),
                    rowSpan: (model, modelIndex, data) => {
                      if (modelIndex === 0) {
                        return data.length;
                      }
                      return undefined;
                    },
                    className: () => style.rowSpanColumn,
                    model: (model) => (
                      <div className={style.stickyCellContent}>
                        {roundNumber(model.tariffHour)}
                      </div>
                    ),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.timeCostShort')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.cost)}
                          )
                        </span>
                      </span>
                    ),
                    className: style.afterRowSpanColumn,
                    model: (model) => roundNumber(model.timeCost, 1),
                    summaryModel: roundNumber(aggregation.summary.timeCost, 1),
                  },
                  {
                    title: (
                      <span>
                        {t('systems.shipments.captions.cargoCostShort')}
                        &nbsp;
                        <span className={style.units}>
                          (
                          {t(unitsMessages.costPerTEU)}
                          )
                        </span>
                      </span>
                    ),
                    model: (model) => roundNumber(model.totalCost, 1),
                    summaryModel: roundNumber(aggregation.summary.totalCost, 1),
                  },
                ],
                body: aggregation.segments,
                summaryData: true,
              }} />
            </div>
          );
        },
        rowRef: (node, item, index) => {
          if (node) {
            rowRefs.current[index] = node;
          }
        },
        header: [
          {
            title: t('common.captions.from'),
            model: (item) => <VocabularyEntity value={item.from} />,
          },
          {
            title: t('common.captions.to'),
            model: (item) => <VocabularyEntity value={item.to} />,
          },
          {
            title: (
              <span>
                {t('systems.shipments.captions.weightColumn')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(unitsMessages.costPerTEU)}
                  )
                </span>
              </span>
            ),
            model: (item) => roundNumber(item.weight, 1),
          },
          {
            title: t('systems.shipments.captions.percentFromMinimumColumn'),
            model: (item) => {
              if (!item.percentFromMinimum) {
                return '0';
              }
              return `+${roundNumber(Math.floor(item.percentFromMinimum))}%`;
            },
          },
          {
            title: (
              <span>
                {t('systems.shipments.captions.routeTrafficCargoColumn')}
                &nbsp;
                <span className={style.units}>
                  (
                  {t(unitsMessages.capacityPerYear)}
                  )
                </span>
              </span>
            ),
            model: (item) => (
              <span>
                {roundNumber(item.routeTrafficCargo)}
                {!item.isRail &&
                <span>
                  &nbsp;
                  (
                  {t('systems.shipments.captions.routeTrafficCargoColumn')}
                  )
                </span>}
              </span>
            ),
          },
          {
            title: (
              <span>
                {t('systems.shipments.captions.transport')}
              </span>
            ),
            model: (item) => {
              const totalDistance = item.aggregation.summary.distance;
              return (
                <span>
                  <TransportTypeIcons
                    types={item.aggregation.types}
                    totalDistance={totalDistance}
                  />
                  <TransportTypePercent
                    segmentTypes={item.aggregation.segmentTypes}
                    totalDistance={totalDistance}
                  />
                </span>
              );
            },
          },
          {
            className: style.summaryTrafficColumn,
            title: (
              <span className={style.summaryTrafficColumnTitle}>
                {t('systems.shipments.captions.summaryCargoTrafficColumn')}
                <span>&nbsp;</span>
                <span className={style.summaryTraffic}>
                  {summaryTraffic &&
                    <span>
                      {roundNumber(summaryTraffic)}
                      &nbsp;
                      <span className={style.units}>
                        {t(unitsMessages.capacityPerYear)}
                      </span>
                    </span>}
                </span>
              </span>
            ),
            model: () => '',
          },
        ],
        body: aggregatedRoutes,
      }} />
    </div>
  );
};

export default RoutesTable;
