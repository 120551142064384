import React, { FC } from 'react';

import { WithTranslate } from 'src/i18n';
import {
  ExcelIcon, Submit, ToolbarBlock, ToolbarControls,
} from 'src/components';

import useStyles from './styles';

export interface OwnProps {
  onExportClick(): void;
}

type Props = OwnProps & WithTranslate;

const RegistryExportButton: FC<Props> = (props) => {
  const { t, onExportClick } = props;

  const classes = useStyles();

  return (
    <ToolbarBlock
      size="x-small"
      title={(
        <ToolbarControls align="right" position="top">
          <Submit variant="icon" isLoading={false} onClick={onExportClick}>
            <ExcelIcon />
            <span className={classes.exportCaption}>
              {t('common.captions.export')}
            </span>
          </Submit>
        </ToolbarControls>
      )}
    />
  );
};

export default RegistryExportButton;
