import { useRestifyForm } from 'src/helpers';

import { AC_CAN_UPLOAD_PROJECT_FILE_PERMISSION, getUrl, urlSchema } from 'src/pages';

import { AuthInfoForm } from 'src/auth/forms/authInfo.form';

const useLinkToAdd = () => {
  const [{ permissions }] = useRestifyForm<AuthInfoForm>('authInfoForm');
  const canAddProject = permissions.indexOf(AC_CAN_UPLOAD_PROJECT_FILE_PERMISSION) >= 0;
  return canAddProject ? getUrl(urlSchema.agglomeration.projects) : undefined;
};

export default useLinkToAdd;
