import React from 'react';
import clsx from 'clsx';

import { MAP_STYLE_DICT, MapStyleID } from 'src/cluster/common';

import useStyles from './styles';

export type Props = {
  styleId: string;
  setStyleId: (mode: MapStyleID) => void;
};

function ModeButton({ buttonConfig, modeId, onSetMode }: any) {
  const classes = useStyles();
  return (
    <button
      className={clsx(classes.button, buttonConfig.id === modeId && classes.activeButton)}
      title={buttonConfig.title}
      onClick={onSetMode}
    >
      <img className={classes.image} src={buttonConfig.src} alt="" />
    </button>
  );
}

function DeckGLMapToggle({ styleId, setStyleId }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {Object.values(MAP_STYLE_DICT).map((buttonConfig, i) => (
        <ModeButton
          key={i}
          buttonConfig={buttonConfig}
          modeId={styleId}
          onSetMode={() => setStyleId(buttonConfig.id)}
        />
      ))}
    </div>
  );
}

export default DeckGLMapToggle;
