import React, { FC, MouseEvent, useState } from 'react';
import classNames from 'classnames';
import clsx from 'clsx';
import {
  createStyles, Fade, makeStyles, Popover as MuiPopover,
} from '@material-ui/core';

import { LocaleSwitch, WithTranslate } from 'src/i18n';
import { HeaderMenu, MenuRenderers } from 'src/pages';
import { ChevronDownIcon } from 'src/components';
import PIcon, { ICONS_TYPES } from 'src/components/deprecated/PIcon';
import { AuthInfoForm } from 'src/auth/forms/authInfo.form';

import style from './index.module.css';

export const overridePopover = makeStyles((theme) => createStyles({
  root: {
    borderRadius: 0,
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
    opacity: 1,
    boxShadow: theme.shadows[1],

  },
}), { name: 'MuiPopover' });

interface HocProps extends WithTranslate {
  className?: string;
  menuRenderers: MenuRenderers;
  headerChildren: React.ReactNode;
  basePath: string[];
}

interface Props extends HocProps {
  authInfoForm: AuthInfoForm;
  logout(): void;
}

const MainMenu: FC<Props> = (props) => {
  const {
    className,
    basePath,
    menuRenderers,
    headerChildren,
    children,
    authInfoForm,
    logout,
    t,
  } = props;

  overridePopover();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div className={classNames(style.root, className)}>
      <header className={style.menu}>
        <HeaderMenu
          className={style.headerMenu}
          basePath={basePath}
          menuRenderers={menuRenderers}
        />
        {headerChildren}
        <div className={style.usernameContainer}>
          <button
            onClick={handleClick}
            className={style.toggle}
          >
            <div className={style.username}>{authInfoForm.username}</div>
            <div className={clsx(style.popupIcon, open && style.openedPopupIcon)}>
              <ChevronDownIcon />
            </div>
          </button>

          <MuiPopover
            open={open}
            style={{ marginLeft: 30 }}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 60,
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            TransitionComponent={Fade}
          >
            <div className={style.popup}>
              <LocaleSwitch className={style.popupItem} />
              <div
                className={style.popupItem}
                onClick={logout}
              >
                <PIcon
                  type={ICONS_TYPES.logout}
                  className={style.logoutIcon}
                />
                <div className={style.popupItemText}>
                  {t('common.captions.signOut')}
                </div>
              </div>
            </div>
          </MuiPopover>
        </div>
      </header>
      <main className={style.content}>
        {children}
      </main>
    </div>
  );
};

export default MainMenu;
