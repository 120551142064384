import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getExportStatus } from 'src/cluster/balance-ttc';

import { withTranslate } from 'src/i18n';
import TtcCalcPage from './TtcCalcPage';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getExportStatus: () => dispatch(getExportStatus()),
});

export default connect(null, mapDispatchToProps)(withTranslate(TtcCalcPage));
