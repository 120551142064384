import createPalette from '@material-ui/core/styles/createPalette';

const palette = createPalette({
  type: 'light',
  primary: {
    light: '#e6f1f2',
    main: '#007378',
    dark: '#008b8b',
  },
  secondary: {
    light: '#e4cada',
    main: '#9B1469',
    dark: '#880e4f',
  },
  success: {
    light: '#7adc8a',
    main: '#56c568',
    dark: '#38bd09',
  },
  warning: {
    light: '#fffbdf',
    main: '#ffc400',
    dark: '#c9b240',
  },
  error: {
    light: '#d59d9d',
    main: '#e5293c',
    dark: '#c71218',
  },
  grey: {
    50: '#f5f5f5',
    100: '#eeeeee',
    200: '#eaeaea',
    300: '#d4d4d4',
    400: '#c4c4c4',
    500: '#bdbdbd',
    600: '#959595',
    700: '#666666',
    800: '#333333',
    900: '#222222',
    A100: '#dbdbdb',
    A200: '#adadad',
    A400: '#f5f9fa',
    A700: '#7E7E82',
  },
  common: {
    white: '#ffffff',
    black: '#000000',
  },
  text: {
    primary: '#333b46',
    secondary: '#d6dee0',
    // disable: 'rgba(51, 59, 70, 0.38)',
  },
});

export default palette;
