import { StrictDict } from 'src/types';
import {
  AgglomerationEdgeTypes,
  EdgeTypes,
  ExportTypes,
  TrafficTypes,
  ViewModes,
} from 'src/modules/mth/common/types';
import { ICONS_TYPES } from 'src/components/deprecated/PIcon';

export const VIEW_MODES = { ...ViewModes };
export const TRANSPORT_TYPES = { ...AgglomerationEdgeTypes };
export const TRAFFIC_TYPES = { ...TrafficTypes };
export const AGGLOMERATION_EDGE_TYPES = { ...AgglomerationEdgeTypes };
export const EXPORT_TYPES = { ...ExportTypes };
export const EDGE_TYPES = { ...EdgeTypes };

export const CALCULATIONS_PAGES_FORM_NAME = 'pageAgglomerationCalculationsForm';
export const EDGE_ROUTES_MODAL = 'edgeCorrespondenceModal';

export const VIEW_MODE_ITEMS: StrictDict<ViewModes, string> = {
  [VIEW_MODES.graph]: 'systems.mth.viewModes.graph',
  [VIEW_MODES.correspondences]: 'systems.mth.viewModes.correspondences',
};

export const TRAFFIC_TYPES_DICT: StrictDict<TrafficTypes, string> = {
  [TRAFFIC_TYPES.passenger]: 'systems.mth.trafficTypeItems.passenger',
  [TRAFFIC_TYPES.cargo]: 'systems.mth.trafficTypeItems.cargo',
};

export const EXPORT_TYPES_DICT: StrictDict<ExportTypes, string> = {
  [EXPORT_TYPES.origin]: 'systems.mth.exportTypes.origin',
  [EXPORT_TYPES.graph]: 'systems.mth.exportTypes.graph',
  [EXPORT_TYPES.fullResult]: 'systems.mth.exportTypes.fullResult',
  [EXPORT_TYPES.trafficSum]: 'systems.mth.exportTypes.trafficSum',
  [EXPORT_TYPES.timeSum]: 'systems.mth.exportTypes.timeSum',
};

export const TRANSPORT_TYPE_ITEMS: StrictDict<AgglomerationEdgeTypes, string> = {
  [TRANSPORT_TYPES.auto]: 'systems.mth.transportTypeItems.auto',
  [TRANSPORT_TYPES.car]: 'systems.mth.transportTypeItems.car',
  [TRANSPORT_TYPES.tat]: 'systems.mth.transportTypeItems.tat',
  [TRANSPORT_TYPES.metro]: 'systems.mth.transportTypeItems.metro',
  [TRANSPORT_TYPES.express]: 'systems.mth.transportTypeItems.express',
  [TRANSPORT_TYPES.suburban]: 'systems.mth.transportTypeItems.suburban',
  [TRANSPORT_TYPES.cargo]: 'systems.mth.transportTypeItems.cargo',
  [TRANSPORT_TYPES.taxi]: 'systems.mth.transportTypeItems.taxi',
  [TRANSPORT_TYPES.pedestrian]: 'systems.mth.transportTypeItems.pedestrian',
};

export const ICON_TYPES: {[key: string]: string} = {
  pedestrian: ICONS_TYPES.transportTypePedestrian,
  car: ICONS_TYPES.transportTypeCar,
  tat: ICONS_TYPES.transportTypePublic,
  metro: ICONS_TYPES.transportTypeMetro,
  suburban: ICONS_TYPES.transportTypeSuburban,
  express: ICONS_TYPES.transportTypeExpress,
  taxi: ICONS_TYPES.transportTypeTaxi,
  cargo: ICONS_TYPES.transportTypeCargo,
};

export const INACTIVE_ROUTE_COLOR = '#c0c0c0';
export const SELECTED_EDGE_COLOR = '#3fa2f7';
export const CAR_ROUTE_COLOR = '#fa10f0';
export const CARGO_ROUTE_COLOR = '#e89b06';
export const TAXI_ROUTE_COLOR = '#fbd825';
export const PUBLIC_ROUTE_COLOR = '#38bd09';
export const METRO_ROUTE_COLOR = '#ff0013';
export const SUBURBAN_ROUTE_COLOR = '#1042f2';
export const EXPRESS_ROUTE_COLOR = '#00e0ff';
export const PEDESTRIAN_ROUTE_COLOR = '#778490';
export const PEDESTRIAN_LIGHT_COLOR = '#a9b7c4';

export const TRANSPORT_TYPE_COLORS: StrictDict<string, string> = {
  [EDGE_TYPES.pedestrian]: PEDESTRIAN_LIGHT_COLOR,
  [EDGE_TYPES.car]: CAR_ROUTE_COLOR,
  [EDGE_TYPES.tat]: PUBLIC_ROUTE_COLOR,
  [EDGE_TYPES.metro]: METRO_ROUTE_COLOR,
  [EDGE_TYPES.suburban]: SUBURBAN_ROUTE_COLOR,
  [EDGE_TYPES.express]: EXPRESS_ROUTE_COLOR,
  [EDGE_TYPES.taxi]: TAXI_ROUTE_COLOR,
  [EDGE_TYPES.cargo]: CARGO_ROUTE_COLOR,
};

export const AGGLOMERATION_EDGE_COLORS: StrictDict<AgglomerationEdgeTypes, string> = {
  [AGGLOMERATION_EDGE_TYPES.auto]: CAR_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.car]: CAR_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.tat]: PUBLIC_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.metro]: METRO_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.express]: EXPRESS_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.suburban]: SUBURBAN_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.cargo]: CARGO_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.taxi]: TAXI_ROUTE_COLOR,
  [AGGLOMERATION_EDGE_TYPES.pedestrian]: PEDESTRIAN_ROUTE_COLOR,
};

export const EDGE_TRAFFIC_COLOR: StrictDict<number, string> = {
  1: '#ff0801',
  2: '#fc7705',
  3: '#fab208',
  4: '#f8fc0b',
  5: '#97f81d',
  6: '#1ef133',
};
