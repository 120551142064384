import React from 'react';

const TransportTypeExpress = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5741 15.4274C11.4681 15.4274 11.365 15.3737 11.3063 15.2765L8.50012 11C8.41091 10.8522 8.3523 10.5891 8.5001 10.5C8.64775 10.4105 8.91093 10.3522 9.00014 10.5L11.8411 14.9536C11.9303 15.1014 11.883 15.2935 11.7352 15.3826C11.6848 15.4131 11.6289 15.4274 11.5741 15.4274Z" fill="#00E0FF" />
    <path d="M0.312302 15.4274C0.257305 15.4274 0.201684 15.4129 0.151062 15.3824C0.00325784 15.2934 -0.0442394 15.1012 0.0451303 14.9534L3 10.5C3.08906 10.3524 3.3522 10.4108 3.5 10.5C3.6478 10.5891 3.58937 10.8522 3.5 11L0.580099 15.2767C0.521508 15.3738 0.418233 15.4274 0.312302 15.4274Z" fill="#00E0FF" />
    <path d="M9.76357 12.484H2.17089C2.08464 12.484 2.01465 12.4142 2.01465 12.3277C2.01465 12.2413 2.08464 12.1715 2.17089 12.1715H9.76372C9.85012 12.1715 9.91996 12.2413 9.91996 12.3277C9.91996 12.4142 9.84997 12.484 9.76357 12.484Z" fill="#00E0FF" />
    <path d="M10.5722 13.8878H1.41356C1.28404 13.8878 1.1792 13.7828 1.1792 13.6534C1.1792 13.5241 1.28404 13.4191 1.41356 13.4191H10.5722C10.7016 13.4191 10.8066 13.5241 10.8066 13.6534C10.8066 13.7828 10.7016 13.8878 10.5722 13.8878Z" fill="#00E0FF" />
    <path d="M6 1C3.73767 1 1 1.79268 1 4.85185C1 6.44095 1.13827 8.74324 2.22223 10.4814C2.32037 10.6377 2.41854 10.7945 2.52224 10.9431C2.67409 11.1594 2.83272 11.3636 2.99692 11.5588C3.87099 12.6017 4.90494 14 6 14C7.09506 14 8.12965 12.6022 9.00372 11.5588C9.1673 11.363 9.32529 11.1589 9.47776 10.9431C9.58146 10.794 9.68024 10.6377 9.77778 10.4814C10.8617 8.74324 11 6.44095 11 4.85185C11 1.79268 8.26233 1 6 1ZM3.73767 10.7411C3.62471 10.7767 3.49693 10.7528 3.36792 10.6889C3.18026 10.5965 2.99199 10.413 2.83582 10.1666C2.76421 10.0526 2.69752 9.92852 2.64382 9.79281C2.62209 9.73625 2.60567 9.68234 2.5888 9.62694L2.58765 9.62317C2.41357 9.05194 2.50742 8.53688 2.82964 8.43454C3.07347 8.35611 3.37903 8.53688 3.62841 8.86171C3.74261 9.00966 3.84567 9.1832 3.92345 9.38344L3.92409 9.38566C4.00495 9.5909 4.04692 9.79114 4.06112 9.97469C4.08889 10.359 3.97717 10.6638 3.73767 10.7411ZM9.35554 9.79281C9.30184 9.92852 9.23579 10.0526 9.16418 10.1666C9.00801 10.413 8.81915 10.5965 8.63273 10.6889C8.50371 10.7528 8.37591 10.7767 8.26233 10.7411C8.02283 10.6638 7.91113 10.359 7.93952 9.97413C7.95249 9.79058 7.99505 9.5909 8.07591 9.3851L8.07655 9.38288C8.15495 9.1832 8.25865 9.0091 8.37223 8.86115C8.62223 8.53632 8.92779 8.35611 9.171 8.43398C9.49261 8.53632 9.58765 9.05082 9.41296 9.62317C9.39506 9.6799 9.37838 9.73496 9.35554 9.79281ZM6 7.5C4.13395 7.5 2.64382 6.13571 2.64382 4.20955C2.64382 3.06208 3.77778 2.44444 6 2.29729C8.22222 2.44444 9.35554 3.06208 9.35554 4.20955C9.35554 6.13627 7.86543 7.5 6 7.5Z" fill="#00E0FF" />
    <path d="M3.73767 10.7411C3.62471 10.7767 3.49693 10.7528 3.36792 10.6889C3.18026 10.5965 2.99199 10.413 2.83582 10.1666C2.76421 10.0526 2.69752 9.92852 2.64382 9.79281C2.62209 9.73625 2.60567 9.68234 2.5888 9.62694L2.58765 9.62317C2.41357 9.05194 2.50742 8.53688 2.82964 8.43454C3.07347 8.35611 3.37903 8.53688 3.62841 8.86171C3.74261 9.00966 3.84567 9.1832 3.92345 9.38344L3.92409 9.38566C4.00495 9.5909 4.04692 9.79114 4.06112 9.97469C4.08889 10.359 3.97717 10.6638 3.73767 10.7411Z" fill="#00E0FF" />
    <path d="M9.35554 9.79281C9.30184 9.92852 9.23579 10.0526 9.16418 10.1666C9.00801 10.413 8.81915 10.5965 8.63273 10.6889C8.50371 10.7528 8.37591 10.7767 8.26233 10.7411C8.02283 10.6638 7.91113 10.359 7.93952 9.97413C7.95249 9.79058 7.99505 9.5909 8.07591 9.3851L8.07655 9.38288C8.15495 9.1832 8.25865 9.0091 8.37223 8.86115C8.62223 8.53632 8.92779 8.35611 9.171 8.43398C9.49261 8.53632 9.58765 9.05082 9.41296 9.62317C9.39506 9.6799 9.37838 9.73496 9.35554 9.79281Z" fill="#00E0FF" />
    <circle cx="3.5" cy="9" r="0.5" fill="white" />
    <circle cx="8.5" cy="9" r="0.5" fill="white" />
    <circle cx="7.75" cy="10.75" r="0.75" fill="white" />
    <circle cx="4.25" cy="10.75" r="0.75" fill="white" />
  </svg>
);

export default TransportTypeExpress;
