export default {
  defaults: {
    name: undefined,
    factors: undefined,
    selectedShape: undefined,

    pageSize: 10,
    currentPage: 1,
  },
};
