import { useMemo } from 'react';

import {
  VEHICLE_TYPES_COLORS,
  VehicleType,
  makeMapStyleEdgeLayer,
} from 'src/cluster/common';
import { useVehicleTypeName } from 'src/cluster/editor-map';

import useEdgePaint from './useEdgePaint';

const useDiagramByRoutesLayers = (
  vehicleTypeId: number | undefined,
  variantIds: number[],
  visible: boolean,
  deps: any[],
) => {
  const getEdgePaint = useEdgePaint();
  const getVehicleTypeName = useVehicleTypeName();

  return useMemo(() => {
    const layers = variantIds.map((id) => {
      const color = VEHICLE_TYPES_COLORS[getVehicleTypeName(vehicleTypeId) ?? VehicleType.all];
      return makeMapStyleEdgeLayer(
        `edges-by-routes-variant-${id}`,
        visible,
        ['has', ['to-string', id], ['get', 'route_variant_traffic']],
        getEdgePaint((from: number, to: number) => [
          'all',
          ['>', ['get', ['to-string', id], ['get', 'route_variant_traffic']], from],
          ['<=', ['get', ['to-string', id], ['get', 'route_variant_traffic']], to],
        ], color),
      );
    });

    const transparentLayers = variantIds.map((id) => {
      return makeMapStyleEdgeLayer(
        `edges-by-routes-variant-${id}-transparent`,
        visible,
        ['has', ['to-string', id], ['get', 'route_variant_traffic']],
        {
          'line-color': 'transparent',
          'line-width': 20,
          'line-offset': 10,
        },
      );
    });

    return [...layers, ...transparentLayers];
  }, [...deps, visible, getEdgePaint]);
};

export default useDiagramByRoutesLayers;
