/* eslint-disable max-len */
import React from 'react';

const LangEn = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M10.037 0C4.54366 0 0.0745688 4.46935 0.0745688 9.96272C0.0745688 11.865 0.61267 13.7104 1.63205 15.3088L0.0182649 19.5483C-0.021842 19.6534 0.00541002 19.7719 0.0871665 19.8491C0.139871 19.8989 0.208516 19.9252 0.277932 19.9252C0.316239 19.9252 0.35506 19.9175 0.391568 19.901L4.34082 18.1335C6.01811 19.3063 7.98437 19.9252 10.0373 19.9252C15.5309 19.9252 20 15.4558 20 9.96272C19.9997 4.46935 15.5306 0 10.037 0ZM10.037 18.4554C8.24173 18.4554 6.52407 17.9 5.0702 16.849C4.86555 16.7014 4.62413 16.6233 4.37218 16.6233C4.20301 16.6233 4.03924 16.6583 3.8855 16.7272L2.41465 17.3856L3.05585 15.7001C3.19237 15.343 3.14815 14.9398 2.9381 14.6213C2.02695 13.2358 1.54516 11.6251 1.54516 9.96272C1.54516 7.69822 2.42931 5.56664 4.03538 3.96056C5.6412 2.35448 7.77304 1.47007 10.0375 1.47007C14.7205 1.47007 18.5302 5.27997 18.5302 9.96272C18.5297 14.6455 14.72 18.4554 10.037 18.4554Z"
      fill="currentColor"
    />
    <path
      d="M9.27521 8.17001H6.33764V9.34879H9.03406V10.3129H6.33764V11.7403H9.4107V12.7381H5.2041V7.18713H9.27521V8.17001Z"
      fill="currentColor"
    />
    <path
      d="M10.2959 7.18713H11.5117L13.7155 11.0577V7.18713H14.7961V12.7381H13.6366L11.3765 8.79938V12.7381H10.2959V7.18713Z"
      fill="currentColor"
    />
  </svg>
);

export default LangEn;
