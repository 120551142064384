import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(2, 4),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    caption: {
      fontWeight: 600,
      fontSize: 12,
      color: theme.palette.text.primary,
      fontFamily: ffSourceSansPro,
    },
    icons: {
      '&> *': {
        padding: theme.spacing(0, 0.5),
      },
    },
  });
});
