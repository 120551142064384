import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ffSourceSansPro } from 'src/theme/typography';

export default makeStyles(({ palette, shadows, spacing }: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    header: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      backgroundColor: palette.common.white,
      boxShadow: shadows[1],
      padding: spacing(2, 4),
      zIndex: 2,

      '&> *': {
        lineHeight: '16px',
      },
    },

    title: {
      fontWeight: 'bold',
      fontSize: 12,
      color: palette.grey[800],
      paddingRight: spacing(1),
      fontFamily: ffSourceSansPro,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    id: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: spacing(1, 2),
      fontFamily: ffSourceSansPro,
      color: palette.grey[400],
      fontWeight: 600,
      fontSize: 12,
    },

    treeContainer: {
      height: '100%',
      overflowY: 'auto',
    },

    submitButtons: {
      padding: spacing(3),
    },
  });
});
