import { useCallback } from 'react';
import { RestifyFormActions } from 'redux-restify';
import { useDispatch } from 'react-redux';

interface Handlers {
  handleViewModeChange: (values: any) => void;
  handleTransportTypeChange: (values: any) => void;
}

export const useHandlers = (formActions: RestifyFormActions): Handlers => {
  const dispatch = useDispatch();

  const handleViewModeChange = useCallback(
    ([values]) => {
      dispatch(formActions.resetField('selectedEdges'));
      dispatch(formActions.changeField('viewMode', values));
    },
    [dispatch, formActions],
  );

  const handleTransportTypeChange = useCallback(
    (values) => {
      dispatch(formActions.resetField('selectedEdges'));
      dispatch(formActions.resetField('selectedRoute'));
      dispatch(formActions.changeField('transportType', values[0]));
      if (values[0] === undefined) {
        dispatch(formActions.resetField('from'));
        dispatch(formActions.resetField('to'));
      }
    },
    [dispatch, formActions],
  );

  return {
    handleViewModeChange,
    handleTransportTypeChange,
  };
};
