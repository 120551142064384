import React from 'react';

const TramwayIcon = () => (
  <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.80439 3.20764H7.32328C7.22454 3.20764 7.14225 3.14182 7.1258 3.04307C7.05997 2.7304 6.7802 2.5 6.45107 2.5H3.81798C3.48884 2.5 3.20908 2.7304 3.14325 3.04307C3.12679 3.14182 3.04451 3.20764 2.94577 3.20764H1.46466C0.641815 3.20764 0 3.86591 0 4.68875V14.6451C0 15.4515 0.641815 16.1262 1.4482 16.1262L0.559531 17.4428C0.345593 17.7555 0.427877 18.1669 0.740556 18.3808C1.05324 18.5948 1.46466 18.5125 1.67859 18.1998L3.04451 16.1262H7.19162L8.59045 18.1998C8.80439 18.5125 9.21581 18.5948 9.52849 18.3808C9.84117 18.1669 9.92345 17.7555 9.70951 17.4428L8.82085 16.1262C9.62723 16.1098 10.2855 15.4515 10.2855 14.6451V4.68875C10.2855 3.86591 9.62723 3.20764 8.80439 3.20764ZM4.08129 3.61906C4.08129 3.50387 4.18003 3.40512 4.29523 3.40512H5.95736C6.07256 3.40512 6.1713 3.50387 6.1713 3.61906V4.03048C6.1713 4.14568 6.07256 4.24442 5.95736 4.24442H4.29523C4.18003 4.24442 4.08129 4.14568 4.08129 4.03048V3.61906ZM3.09388 13.9375C3.07742 14.3654 2.73183 14.7109 2.30395 14.7274C1.81025 14.7603 1.39883 14.3489 1.41529 13.8387C1.43174 13.4109 1.77733 13.0653 2.20521 13.0488C2.69892 13.0324 3.11034 13.4438 3.09388 13.9375ZM7.14225 13.8552C7.15871 13.4273 7.5043 13.0817 7.93218 13.0653C8.42588 13.0324 8.8373 13.4438 8.82085 13.9539C8.80439 14.3818 8.4588 14.7274 8.03092 14.7439C7.52076 14.7603 7.10934 14.3489 7.14225 13.8552ZM9.38038 8.49028C9.38038 9.08272 8.90313 9.55997 8.32714 9.55997H1.92545C1.34946 9.55997 0.855754 9.08272 0.855754 8.49028V6.44963C0.855754 5.85719 1.34946 5.37994 1.92545 5.37994H8.32714C8.90313 5.37994 9.38038 5.85719 9.38038 6.44963V8.49028Z"
      fill="currentColor"
    />
    <path
      d="M5.37249 1.023V2.5H4.81336L4.81365 1.023H2.63164C2.4007 1.023 2.21909 1.10817 2.07588 1.28339L1.50139 1.98583C1.40774 2.1005 1.23252 2.12245 1.10994 2.03462C0.987126 1.94701 0.963654 1.78288 1.05754 1.66821L1.6318 0.965777C1.88099 0.661026 2.22667 0.5 2.63164 0.5H7.49167C7.50234 0.5 7.51277 0.500665 7.5232 0.501774C7.5334 0.500665 7.54407 0.5 7.55474 0.5C7.95994 0.5 8.30563 0.661026 8.55458 0.965777L9.12907 1.66821C9.22272 1.78288 9.19925 1.94679 9.07691 2.03462C9.02617 2.071 8.96642 2.08852 8.90715 2.08852C8.82298 2.08852 8.73975 2.05325 8.68475 1.98583L8.1105 1.28339C7.96705 1.10817 7.78543 1.023 7.5545 1.023C7.54383 1.023 7.53316 1.02234 7.52297 1.02123C7.51254 1.02234 7.5021 1.023 7.49143 1.023H5.37249Z"
      fill="currentColor"
    />
  </svg>

);

export default TramwayIcon;
