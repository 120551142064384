import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',

      '&> *': {
        flexBasis: '47%',
      },
    },
  });
});
