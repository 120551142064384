import {
  featureCollection, lineString, multiLineString, point, bbox,
} from '@turf/turf';

export {
  featureCollection,
  lineString,
  multiLineString,
  point,
  bbox,
};

export const emptyCollection = {
  type: 'geojson',
  data: featureCollection([]),
};
