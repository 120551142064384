import { api, forms } from 'redux-restify';

import app from 'src/app';
import i18n from 'src/i18n';
import modals from 'src/modals';
import { ThunkActionResult } from 'src/reducer';
import { objectToLowerSnake } from 'src/helpers';

import {
  AC_PROJECTS_ENDPOINT, AcBalanceShapesModel, AcProjectForm, AC_DATA_PAGE_FORM_NAME,
} from 'src/cluster/common';
import {
  AC_SHAPES_ENDPOINT,
  AC_SHAPES_MODEL_NAME,
  AC_SHAPES_PAGE_FORM_NAME,
  AC_SHAPES_MODAL,
  AC_SHAPE_STATUSES,
} from 'src/cluster/shapes';

export const addShape = (shape: AcBalanceShapesModel): ThunkActionResult<void> => (dispatch, getState) => {
  const state = getState();
  const { selectedProject } = forms.selectors[AC_DATA_PAGE_FORM_NAME].getForm<AcProjectForm>(state);
  const url = `${AC_PROJECTS_ENDPOINT}${selectedProject}/${AC_SHAPES_ENDPOINT}`;
  const data = objectToLowerSnake(shape);
  const { status } = dispatch(api.actions.callPost({ url, data }));
  if (status >= 400) {
    dispatch(app.actions.toast.error(i18n.t('modules.ttc.messages.createError')));
  } else {
    dispatch(api.actions.entityManager[AC_SHAPES_MODEL_NAME].clearData());
  }
};

export const selectShape = (shape: AcBalanceShapesModel): ThunkActionResult<void> => (dispatch) => {
  if (shape.status === AC_SHAPE_STATUSES.SUCCESS) {
    dispatch(forms.actions[AC_SHAPES_PAGE_FORM_NAME].changeField('selectedShape', shape.id));
  }
};

export const showShape = (shape: AcBalanceShapesModel): ThunkActionResult<void> => (dispatch) => {
  dispatch(modals.actions.showModal(true, AC_SHAPES_MODAL, {
    shape,
  }));
};
