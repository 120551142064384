import React from 'react';

const AddPointDropIcon = () => (
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4 8.19141C11.4 8.94917 11.0839 9.67589 10.5213 10.2117C9.95871 10.7475 9.19565 11.0485 8.4 11.0485C7.60435 11.0485 6.84129 10.7475 6.27868 10.2117C5.71607 9.67589 5.4 8.94917 5.4 8.19141C5.4 7.43365 5.71607 6.70692 6.27868 6.1711C6.84129 5.63528 7.60435 5.33426 8.4 5.33426C8.79397 5.33426 9.18407 5.40817 9.54805 5.55175C9.91203 5.69533 10.2427 5.90579 10.5213 6.1711C10.7999 6.43641 11.0209 6.75138 11.1716 7.09802C11.3224 7.44467 11.4 7.8162 11.4 8.19141ZM-3.49691e-07 8.19141C-2.56947e-07 10.3131 0.884997 12.348 2.4603 13.8483C4.03561 15.3486 6.17218 16.1914 8.4 16.1914C14.7 16.1914 24 8.19141 24 8.19141C24 8.19141 14.7 0.191407 8.4 0.191407C6.17218 0.191407 4.03561 1.03426 2.4603 2.53455C0.884997 4.03484 -4.42435e-07 6.06967 -3.49691e-07 8.19141Z" fill="currentColor" />
    <circle cx="8" cy="8.19141" r="5" fill="white" />
    <path d="M8 12.1914C5.795 12.1914 4 10.3964 4 8.19141C4 5.98641 5.795 4.19141 8 4.19141C10.205 4.19141 12 5.98641 12 8.19141C12 10.3964 10.205 12.1914 8 12.1914ZM8 3.19141C7.34339 3.19141 6.69321 3.32074 6.08658 3.57201C5.47995 3.82328 4.92876 4.19158 4.46447 4.65587C3.52678 5.59355 3 6.86532 3 8.19141C3 9.51749 3.52678 10.7893 4.46447 11.7269C4.92876 12.1912 5.47995 12.5595 6.08658 12.8108C6.69321 13.0621 7.34339 13.1914 8 13.1914C9.32608 13.1914 10.5979 12.6646 11.5355 11.7269C12.4732 10.7893 13 9.51749 13 8.19141C13 7.5348 12.8707 6.88462 12.6194 6.27799C12.3681 5.67136 11.9998 5.12017 11.5355 4.65587C11.0712 4.19158 10.52 3.82328 9.91342 3.57201C9.30679 3.32074 8.65661 3.19141 8 3.19141ZM8.5 5.69141H7.5V7.69141H5.5V8.69141H7.5V10.6914H8.5V8.69141H10.5V7.69141H8.5V5.69141Z" fill="currentColor" />
  </svg>

);

export default AddPointDropIcon;
