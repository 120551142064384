import { makeActionsBundle } from 'src/helpers/actions';

export const NAME = 'modals';

const actionsTypesModals = [
  'SHOW_MODAL',
];

export const ACTIONS_TYPES = {
  modals: makeActionsBundle(NAME, 'modals', actionsTypesModals),
};

export const CONFIRM_MODAL_NAME = 'confirmModal';
export const INPUT_MODAL_NAME = 'inputModal';

export enum ModalSizes {
  full = 'full',
  large = 'large',
  medium = 'medium',
  half = 'half',
  small = 'small',
}

export const MODAL_SIZES = ModalSizes;

export const registeredModals: any[] = [];

export const addRegisteredModal = (modal: any) => {
  registeredModals.push(modal);
};
