import React, { useMemo } from 'react';

import i18n from 'src/i18n';
import { NumberFormat, WithUnit } from 'src/components';
import { UNITS } from 'src/constants';
import {
  AcDistStopsModel,
  AcDistTableFieldsConfig,
  TopColumns,
} from 'src/cluster/distribution-table';
import { VehicleType } from 'src/cluster/common';
import { isNil } from 'src/helpers';

const useDistPointConfigTransfer = (
  getVehicleTypeName: (vehicleId?: number) => VehicleType | undefined,
  classes: Record<string, string>,
): AcDistTableFieldsConfig<AcDistStopsModel>[] => {
  return useMemo(() => {
    const translate = 'systems.distribution.columns.';
    return [
      {
        type: TopColumns.transfer,
        title: (
          <>
            <span className={classes.title}>
              <WithUnit unit={i18n.t(UNITS.people)}>
                {i18n.t(translate + TopColumns.transfer)}
              </WithUnit>
            </span>
          </>
        ),
        className: classes.first,
        model: (result: AcDistStopsModel) => (
          <div className={classes.taRight}>
            {isNil(result.transfer) ? '-' : (
              <NumberFormat
                fixedDecimalScale={false}
                decimalScale={0}
                value={result.transfer}
                locale="ru"
              />
            )}
          </div>
        ),
      },

    ];
  }, [getVehicleTypeName]);
};

export default useDistPointConfigTransfer;
