import { ExportTypes } from '../types';

export interface ExportCityCalculationForm {
  type?: ExportTypes;
  from: string[];
  to: string[];
  edges: number[];
}

export default {
  defaults: {
    type: undefined,
    from: [],
    to: [],
    edges: [],
  },
};
