import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    padding: spacing(6, 3, 0),
  },

  header: {
    paddingRight: spacing(7),
  },

  container: {
    padding: spacing(4, 0),
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
